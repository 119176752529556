import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { customDecode } from "../../../utils/envScript/envCrypto";

const ReCaptchaWrapper = ({ children }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={customDecode(process.env.REACT_APP_RECAPTCHA_V3)}
    scriptProps={{ appendTo: "body" }}
  >
    {children}
  </GoogleReCaptchaProvider>
);

export default ReCaptchaWrapper;
