import apiConstants from "../constants/apiConstants";

export const loginUser = (data) => ({
  type: apiConstants.LOGIN_USER,
  payload: data
});

export const loginWithEmailOtp = (user) => ({
  type: apiConstants.LOGIN_WITH_EMAIL_OTP,
  payload: user
});

export const twoFa = (user) => ({
  type: apiConstants.LOGIN_WITH_TWO_FA,
  payload: user
});

export const registerWithEmailOTP = (user) => ({
  type: apiConstants.REGISTER_EMAIL,
  payload: user
});

export const resetRegisterAuthCode = (user) => ({
  type: apiConstants.RESET_REGISTER_AUTH_CODE,
  payload: user
});

export const registerWithEmail = (user) => ({
  type: apiConstants.REGISTER_WITH_EMAIL,
  payload: user
});

export const isUserLoggedIn = () => ({
  type: apiConstants.CHECK_IS_USER_LOGGED_IN
});

export const getAuthCode = (domain) => ({
  type: apiConstants.GET_AUTH_CODE,
  payload: domain
});

export const generateAuthCode = (domain) => ({
  type: apiConstants.GENERATE_AUTH_CODE,
  payload: domain
});

export const changePassword = (user) => ({
  type: apiConstants.CHANGE_PASSWORD,
  payload: user
});

export const forgotPassword = (user) => ({
  type: apiConstants.FORGOT_PASSWORD,
  payload: user
});

export const resetPassword = (user) => ({
  type: apiConstants.RESET_PASSWORD,
  payload: user
});

export const verifyEmail = (user) => ({
  type: apiConstants.VERIFY_EMAIL,
  payload: user
});

export function logout() {
  return {
    type: apiConstants.LOGOUT_USER
  };
}

export const clearAuthErrors = () => ({
  type: apiConstants.CLEAR_AUTH_ERRORS
});

export const clearTwoAuth = () => ({
  type: apiConstants.CLEAR_TWO_AUTH
});

export const removeProfileStatus = () => ({
  type: apiConstants.REMOVE_PROFILE_STATUS
});

export const clearOTP = () => ({
  type: apiConstants.CLEAR_OTP
});

export const recaptchaLoginOtp = (user) => ({
  type: apiConstants.LOGIN_WITH_RECAPTCHA_EMAIL_OTP,
  payload: user
});

export const twoFaAndrecaptchaLoginOtp = (user) => ({
  type: apiConstants.TWO_FA_AND_LOGIN_WITH_RECAPTCHA_EMAIL_OTP,
  payload: user
});

export const verifyRedirectUrl = (url) => ({
  type: apiConstants.VERIFY_REDIRECT_URL,
  payload: url
});

export const clearRedirectToVerifyEmail = () => ({
  type: apiConstants.CLEAR_REDIRECT_TO_VERIFY_EMAIL
});
