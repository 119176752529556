/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import CopyToClipboard from "react-copy-to-clipboard";
// import QRCode from "qrcode.react";
import { QRCode } from "react-qrcode-logo";
import { setup2FA } from "../../../lib/api";
import { trackPage } from "../../../tracking/track";
import ProgressBarComponent from "../ProgressBar";
import ConfirmModal from "./ConfirmModal";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection,
  MainElement
} from "../../../assets/StyleComponents/Exchange.style";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { CautionTriangleIcon, ChevronLeftBlueIcon, CopyBlueIcon } from "../../../assets/svgs";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ImgElement } from "../../../components/Reusable";
import { LightTheme2fa } from "../../../assets/png";
import { HeadingFourth } from "../../../assets/StyleComponents/FontSize/headingFourth.style";
import { connect } from "react-redux";

function Security(props) {
  let { user, isTheme, userData } = props;
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [disableStep, setDisableStep] = useState(1);
  const [twoFaCode, setTwoFaCode] = useState("");
  const [userMain, setUserMain] = useState(props.user);
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState(false);
  const { width } = useWindowSize();
  const [auth, setAuth] = useState("");
  const [authloader, set_authloader] = useState("");
  const history = useHistory();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    let page = props.location.pathname + props.location.search;
    trackPage(page);
    if (props.user.twoFactorAuth && !props.user.twoFactorAuth.status) {
      props.generate2AuthToken();
    }

    return () => props.getUserDetails();
  }, []);

  function process2FA() {
    if (auth === "") {
      showErrorMsg("Please fill the 2FA", { autoClose: 7000 });
      return false;
    }
    if (auth && auth.length === 6) {
      set_authloader(true);
      let data = {
        token: Number(auth),
        status: true
      };
      setup2FA(data)
        .then((res) => {
          if (res.data.status === "success") {
            showSuccessMsg("2FA Enabled");
            history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/setting/security`);
          }
        })
        .catch((err) => {
          setAuth("");
          showErrorMsg(err.response.data.message, { autoClose: 7000 });
        });
    } else {
      showErrorMsg("Incorrect auth string", { autoClose: 7000 });
    }
  }

  const onClose = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    setShowModal(false);
    setStep(step + 1);
  };

  const selectText = (copyText) => {
    showSuccessMsg("Text Copied!", { autoClose: 5000 });
  };

  const handleChange = (otp) => {
    setAuth(otp);
  };

  const setSteps = (step) => {
    setStep(step);
  };

  useEffect(() => {
    if (props.error.message && props.error) {
      props.alert.error(props.error.message);
      props.clearUserErrors();
    }
  }, [props.error]);

  useEffect(() => {
    if (props.twoAuthToken) {
      let { data } = props.twoAuthToken;
      setCode(data.secret);
    }
  }, [props.twoAuthToken]);

  useEffect(() => {
    if (props.changePasswordSuccess) {
      showSuccessMsg(props.changePasswordSuccess, { autoClose: 7000 });
    }
  }, [props.changePasswordSuccess]);

  useEffect(() => {
    if (props.apiData.message) {
      showSuccessMsg(props.apiData.message, { autoClose: 7000 });
    }
  }, [props.apiData]);

  useEffect(() => {
    if (userData.twoFactorAuth.status) {
      history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/setting/security`);
    }
  }, []);

  const OtpInputStyle = {
    width: width > 767 ? "50px" : "40px",
    height: width > 767 ? "48px" : "44px",
    background: isTheme ? "#1f2c38" : "#f5f6fa",
    color: isTheme ? "#f5f6fa" : "#000",
    border: "none",
    borderRadius: "5px 5px 0 0",
    fontSize: width > 767 ? "24px" : "16px",
    borderBottom: isTheme ? "1px solid #969799" : "1px solid #8c9797",
    outline: "none"
  };

  const StepsData = [
    {
      number: 1,
      title: "Download Authenticator Android / iOS",
      value:
        "Authenticator can be downloaded from the App store or Google Play. search “Authenticator” and proceed to download any popular authenticator, for eg: Safe Auth, DUO, Google, etc."
    },
    {
      number: 2,
      title: "Download the Verification App and Scan the given code",
      value:
        "Open your Authenticator, scan the QR code below or manually enter the key phrase and press on Confirm to activate the verification token."
    }
  ];

  // const codeValue = code.split("=")[1];

  return (
    <MainElement paddingMd="20px 16px" padding="0px 16px">
      {showModal === true && (
        <ConfirmModal show={showModal} onClose={onClose} handleOk={handleOk} />
      )}
      <BannerBox width="100%">
        <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/security`} replace={true}>
          <ButtonComponent OutlineOnlyPrimary display="flex" alignItems="center">
            <ChevronLeftBlueIcon />
            Back
          </ButtonComponent>
        </Link>
      </BannerBox>

      <BannerBox
        position="relative"
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        directionMd="column-reverse"
        gapMd="20px"
      >
        <BannerBox width="80%" widthMd="100%">
          <BannerHeading size={"24px"} sizeLg={"20px"} sizeMd={"18px"} mb={"8px"} weight="bold">
            2 Factor Authentication
          </BannerHeading>
          <BannerHeading txtGrey size={"16px"} sizeLg={"14px"} sizeMd={"12px"} weight="400">
            Two-Factor Authentication (2FA) enhances security by requiring two verification methods,
            typically a password and a mobile-generated code.
          </BannerHeading>
        </BannerBox>
        <BannerBox>
          <ImgElement src={LightTheme2fa} width="124px" height="78px" />
        </BannerBox>
      </BannerBox>

      <BannerSection mt="20px" mb="24px">
        {StepsData.map((data) => {
          return (
            <>
              <BannerBox display="flex" alignItems="start" gap="8px">
                <BannerBox
                  width="5%"
                  justifyContent="center"
                  display="flex"
                  widthMd="8%"
                  direction="column"
                  alignItems="center"
                >
                  <BannerHeadingSpan
                    width="32px"
                    height="32px"
                    BorderRadius="50%"
                    TabBoxBg
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    size="16px"
                    weight="500"
                    widthMd="24px"
                    heightMd="24px"
                    sizemd="14px"
                  >
                    {data.number}
                  </BannerHeadingSpan>
                  {data.number === 1 && (
                    <hr
                      style={{
                        minHeight: width > 767 ? "64px" : "120px",
                        height: "calc(100% - 24px)",
                        width: "0",
                        borderLeft: "1px dashed rgba(0, 0, 0, 0.16)",
                        margin: "0",
                        background: "transparent"
                      }}
                    />
                  )}
                </BannerBox>
                <BannerBox width="95%" display="flex" direction="column" gap="8px" widthMd="92%">
                  <HeadingFourth size="20px" weight="600" sizeLg="18px" sizeMd="16px">
                    {data.title}
                  </HeadingFourth>
                  <ParaTag size="14px" weight="300" sizeMd="12px">
                    {data.value}
                  </ParaTag>
                </BannerBox>
              </BannerBox>
            </>
          );
        })}
      </BannerSection>
      <BannerBox
        display="flex"
        alignItems="center"
        alignItemsMd="start"
        gap="8px"
        border="1px solid #E8B500"
        padding="10px"
        borderRadius="5px"
        width="90%"
        widthLg="100%"
      >
        <CautionTriangleIcon />
        <HeadingFourth size="14px" weight="400" color="#E8B500" sizeMd="12px">
          {width > 767
            ? "Ensure the safekeeping of the key phrase, used for Authenticator recovery in case of device loss or change, prior to Authenticator setup."
            : "Keep your key phrase safe to recover the Authenticator if you lose or change your device."}
        </HeadingFourth>
      </BannerBox>

      <BannerBox
        display="flex"
        gap="20px"
        alignItems="end"
        alignItemsMd="center"
        mt="24px"
        directionMd="column"
        width="90%"
        widthMd="100%"
        topBorder
        pt="24px"
      >
        <BannerBox
          padding="5px 5px 0px 5px"
          // bck="white"
          display="inline-block"
          width="22%"
          widthLg="35%"
          widthMd="55%"
          textAlign="center"
        >
          {/* <QRCode
            value={code}
            className="exch-qr-code"
            style={{
              maxWidth: width > 1024 ? "187px" : "155px",
              width: width > 1024 ? "187px" : "155px",
              maxHeight: width > 1024 ? "187px" : "155px",
              height: width > 1024 ? "187px" : "155px",
              margin: "0 auto"
            }}
          /> */}
          <QRCode
            value={code}
            // size={100}
            logoHeight={30}
            logoWidth={30}
            ecLevel="H"
            logoImage="https://lcx-exchange.s3.amazonaws.com/coins/dark/svg/lcx.svg"
            logoOpacity={1}
            qrStyle="dots"
            eyeRadius={10}
            bgColor={isTheme ? "#151e26" : "#FFFFFF"} // Dark or white background
            fgColor={isTheme ? "#FFFFFF" : "#000000"} // Light or dark foreground
            id={"QR"}
            style={{
              borderRadius: "13px",
              border: `1px solid ${isTheme ? "#ffffff" : "#CCCED0"}`,
              maxWidth: width > 1024 ? "200px" : "155px",
              width: width > 1024 ? "200px" : "155px",
              maxHeight: width > 1024 ? "200px" : "155px",
              height: width > 1024 ? "200px" : "155px",
              margin: "0 auto"
            }}
          />
        </BannerBox>

        {step === 1 && (
          <BannerBox display="flex" direction="column" width="70%" widthMd="100%">
            <BannerBox
              settingInputBackupKey
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              widthLg="100%"
              gap="10px"
            >
              <BannerHeading
                size="16px"
                sizeLg="12px"
                sizeMd="12px"
                BodyColor
                weight="500"
                // width="200px"
                display="flex"
                style={{ overflowWrap: "break-word", whiteSpace: "preWrap", overflowY: "scroll" }}
              >
                {" "}
                {code && code.split("=")[1]}{" "}
              </BannerHeading>
              <BannerHeadingSpan
                textAlign="right"
                cursorPointer
                onClick={() => selectText(code && code.split("=")[1])}
              >
                <CopyToClipboard text={code && code.split("=")[1]}>
                  <CopyBlueIcon />
                </CopyToClipboard>
              </BannerHeadingSpan>
            </BannerBox>
            <ButtonComponent width="100%" mt="16px" onClick={() => setStep(2)}>
              Enter 2FA Code
            </ButtonComponent>
          </BannerBox>
        )}
        {step === 2 && (
          <BannerBox
            display="flex"
            direction="column"
            width="70%"
            widthMd="100%"
            justifyContentMd="center"
            alignItemsMd="center"
          >
            <OtpInput
              inputStyle={OtpInputStyle}
              value={auth}
              onChange={handleChange}
              numInputs={6}
              isInputNum={true}
              separator={
                <BannerHeadingSpan mr="8px" mrLg="10px">
                  {" "}
                </BannerHeadingSpan>
              }
            />
            <ButtonComponent width="52%" mt="16px" widthLg="100%" onClick={() => process2FA()}>
              Enable 2FA
            </ButtonComponent>
          </BannerBox>
        )}
      </BannerBox>
    </MainElement>
  );
}

const maptoStateProps = (state) => ({
  userData: state.dashboard.getUserData
});

export default connect(maptoStateProps, null)(Security);
