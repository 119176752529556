import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import SkeletonLoader from "../../../components/SkeletonLoader/index";
// import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { ChevronRightIcon } from "../../../assets/svgs";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { Table, Tbody, Td, Tr } from "../../../components/Reusable";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";

const RecentActivity = (props) => {
  const { isFetchingRecentActivity, recentActivity, levelStatus } = props;
  const prevLang = localStorage.getItem("ljs-lang");

  return (
    <BannerBox
      flex="0 0 33.33%"
      flexLg="1"
      padding={levelStatus === "LEVEL2" ? "16px 0 0 8px" : "0px"}
      paddingMd={levelStatus === "LEVEL2" ? "10px 0 12px 0px" : "12px 0px"}
      paddingLg={levelStatus === "LEVEL2" ? "10px 0px" : "20px 0 0 0"}
      plLg="0px"
      order="6"
      height="264px"
    >
      <BannerBox
        CardWhite
        DashboardBordersdark
        widh="100%"
        display={levelStatus !== "LEVEL2" && "flex"}
        justifyContent={levelStatus !== "LEVEL2" && "space-between"}
        direction={levelStatus !== "LEVEL2" && "column"}
        padding="0.8rem 16px"
        height="264px"
        heightLg="287px"
        heightMd="264px"
        overflow="hidden"
        borderRadius="10px"
      >
        <BannerBox display="flex" justifyContent="space-between" mb="24px" mbMd="30px">
          <BannerHeading BodyColor weight="500" size="18px" sizeMd="16px">
            Your Recent Activities
          </BannerHeading>
          <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/recent_actvity`}>
            <ChevronRightIcon />
          </Link>
        </BannerBox>

        <BannerBox FlexColumnBetweenSpace width="100%" flex="1" height="70%" overflow="scroll">
          <BannerBox width="100%" overflow="visible" marginBottom="1rem">
            <Table>
              <Tbody>
                {isFetchingRecentActivity &&
                  [0, 1, 2].map((item, index) => (
                    <BannerBox
                      key={index}
                      display="flex"
                      width="100%"
                      mt="1rem"
                      justifyContent="space-between"
                    >
                      <BannerBox display="flex" direction="column">
                        <SkeletonLoader width={80} />
                        <SkeletonLoader width={80} />
                      </BannerBox>

                      <BannerBox display="flex" direction="column">
                        <SkeletonLoader width={80} />
                        <SkeletonLoader width={80} />
                      </BannerBox>
                    </BannerBox>
                  ))}

                {!isFetchingRecentActivity && recentActivity && (
                  <>
                    {recentActivity.length > 0 &&
                      recentActivity.slice(0, 4).map((item, index) => {
                        let { userAgent, region, ipAddress, createdAt } = item;
                        return (
                          <Tr key={index} border height="60px" margin="8px 0 0 0" heightMd="60px">
                            <Td textAlign="left">
                              <ParaTag
                                weight="500"
                                BodyColor
                                size="16px"
                                textTransform="capitalize"
                                style={{ textTransform: "capitalize" }}
                              >
                                {userAgent.device}
                              </ParaTag>
                              <BannerHeadingSpan
                                weight="300"
                                txtGrey
                                size="14px"
                                textTransform="uppercase"
                              >
                                {region?.fullCountry || ""}
                              </BannerHeadingSpan>
                            </Td>

                            <Td textAlign="right">
                              <ParaTag
                                weight="500"
                                BodyColor
                                size="16px"
                                textTransform="capitalize"
                              >
                                {ipAddress ? (
                                  ipAddress.length > 20 ? (
                                    <>
                                      {ipAddress.slice(0, 9) +
                                        "..." +
                                        ipAddress.slice(ipAddress.length - 4, ipAddress.length)}
                                    </>
                                  ) : (
                                    ipAddress
                                  )
                                ) : (
                                  ""
                                )}
                              </ParaTag>
                              <BannerHeadingSpan
                                weight="300"
                                txtGrey
                                size="14px"
                                textTransform="uppercase"
                              >
                                {moment(createdAt).format("DD/MM/YY, HH:mm")}
                              </BannerHeadingSpan>
                            </Td>
                          </Tr>
                        );
                      })}
                    {recentActivity.length === 0 && (
                      <Tr colSpan="2" textAlign="center">
                        No Record Found
                      </Tr>
                    )}
                  </>
                )}
              </Tbody>
            </Table>
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
};

export default RecentActivity;
