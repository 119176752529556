import { compose } from "redux";
import DivElement from "../DivElement";
import QRCode from "qrcode.react";
import { connect } from "react-redux";
import { OuterBorderIcon } from "../../../assets/svgs";

const QrContainer = ({ width = "164px", height = "164px", gap = "12px", value, isDarkTheme }) => {
  return (
    <DivElement width={width} height={height} relative>
      <OuterBorderIcon width={width} height={height} />
      <DivElement absolute top={gap} left={gap}>
        <QRCode
          style={{
            width: `calc(${width} - ${gap} - ${gap})`,
            height: `calc(${height} - ${gap} - ${gap})`,
            outline: "2px solid #fff"
          }}
          value={value}
        />
      </DivElement>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(QrContainer);
