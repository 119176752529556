import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { IMAGE_PREFIX } from "../../constants/Images/image";
import { updateMarketAlertConfirmation } from "../../Modules/Trades/action";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { ImgElement, ModalWrapper } from "../../components/Reusable";
import { DivElement } from "../../components/Reusable";
import { CloseIcon } from "../../assets/svgs";

const MarketOrderAlert = (props) => {
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();

  const onClose = () => {
    props.onClose();
    setOpen(false);
  };

  const onApprove = () => {
    dispatch(updateMarketAlertConfirmation(true));
    props.onApprove();
    setOpen(false);
  };

  return (
    <div>
      {open && (
        <ModalWrapper>
          <DivElement ModalContainer>
            <DivElement display="flex" justifyContent="flex-end">
              <CloseIcon pointer onClick={onClose} />
            </DivElement>

            <BannerBox
              padding="0px 12px"
              display="flex"
              justifyContent="center"
              direction="column"
              alignItems="center"
              gap="20px"
            >
              <>
                <div>
                  <ImgElement alt="img" src={IMAGE_PREFIX.AlertTriangle} width="70" height="50" />
                </div>

                <BannerHeading textAlign="left" size="16px" sizeMd="14px" BodyColor>
                  Market order executes at best available price in the order book. Low liquidity
                  might trigger large price movement. Please try a small order size and double check
                  order book.
                </BannerHeading>

                <BannerBox display="flex" justifyContent="center" mt="1px" mb="5px" gap="10px">
                  <ButtonComponent
                    OutlineDanger
                    minWidth={"145px"}
                    // givenMargn="0 20px 0 0"
                    onClick={() => onClose()}
                  >
                    Cancel
                  </ButtonComponent>

                  <ButtonComponent
                    SuccessButton
                    minWidth={"145px"}
                    minWidthMd={"100px"}
                    onClick={() => onApprove()}
                  >
                    {props.marketAlert.Side === "SELL" ? "Confirm and Sell" : "Confirm and Buy"}
                  </ButtonComponent>
                </BannerBox>
              </>
            </BannerBox>
          </DivElement>
        </ModalWrapper>
      )}
    </div>
  );
};

export default MarketOrderAlert;
