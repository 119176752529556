import React, { useState } from "react";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
// import { debounce } from "../../../utils/helpers";
import { CloseIcon } from "../../../assets/svgs";
import { DeleteBoxIllustration } from "../../../assets/svgs/illustrations";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { deleteBeneficieryData } from "../../../lib/api";
import { showErrorMsg } from "../../../utils/notification";

const ConfirmDeleteBenficiary = (props) => {
  const { setName, setModal, getBeneficiary, id } = props;
  const [disabled, setDisabled] = useState(false);

  const Submit = () => {
    setDisabled(true);
    let data = {
      id: id
    };

    deleteBeneficieryData(data)
      .then((res) => {
        if (res.status) {
          getBeneficiary();
          setName("");
          setModal(false);
        }
      })
      .catch((err) => {
        showErrorMsg(err, { autoClose: 7000 });
        setName("");
        setModal(false);
      });
  };

  return (
    <BannerBox
      id="onboardingTextModal"
      role="dialog"
      tabIndex="-1"
      zIndex="10"
      opacity="1"
      position="fixed"
      top="0px"
      left="0px"
      right="0px"
      bottom="0px"
      overflow="hidden"
    >
      <BannerBox
        role="document"
        overflow="scroll"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
        maxWidthMd="330px"
        marginMd="1.75rem auto"
      >
        <BannerBox
          textAlign="center"
          padding="1rem 0.8rem"
          CardLevel3
          maxWidth="450px"
          overflow="scroll"
        >
          <BannerBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 0.5rem"
          >
            <BannerHeading BodyColor weight="bold" size="1.25rem" id="exampleModalLabel">
              Delete Confirmation
            </BannerHeading>
            <ButtonComponent
              onClick={() => props.onClose()}
              aria-label="Close"
              data-dismiss="modal"
              type="button"
              bck="transparent"
            >
              <CloseIcon />
            </ButtonComponent>
          </BannerBox>

          <BannerBox margin="1.1rem 0px">
            <DeleteBoxIllustration width={"25%"} />
          </BannerBox>

          <BannerBox padding="0px 0.5rem">
            <ParaTag size="14px" BodyColor mt="1rem" textAlign="left" weight="300">
              {props.name.nameOnAccount} Beneficiary Account will be deleted permanently from our
              system.
            </ParaTag>

            <BannerBox display="flex" justifyContent="space-between" mt="1rem">
              <ButtonComponent
                OutlinePrimary
                onClick={() => props.onClose()}
                btnHeight="48px"
                width="48%"
              >
                Go Back
              </ButtonComponent>

              <ButtonComponent
                OutlineDanger
                onClick={() => Submit()}
                btnHeight="48px"
                width="48%"
                disabled={disabled}
              >
                Delete
              </ButtonComponent>
            </BannerBox>
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
};

export default ConfirmDeleteBenficiary;
