import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  maintenanceData: "",
  maintenanceError: "",
  isLoading: "",
  isSiteUnderMaintenance: false
};

let initialState = initialStateSchema;
const localData = localStorage.getItem("accessToken");

if (localData && localData !== undefined) {
  try {
    initialState.authToken = localData;
  } catch (e) {
    initialState = initialStateSchema;
  }
}

const maintenanceReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.SITE_MAINTENANCE:
      newState = {
        ...state,
        isLoading: true
      };

      return newState;

    case apiConstants.SITE_MAINTENANCE_SUCCESS:
      newState = {
        ...state,
        isLoading: false,
        maintenanceData: action.response.data
      };

      return newState;

    case apiConstants.SITE_MAINTENANCE_FAIL:
      newState = {
        ...state,
        isLoading: false,
        maintenanceError: action.response?.data
      };

      return newState;

    case apiConstants.SITE_UNDER_MAINTENANCE:
      newState = {
        ...state,
        isLoading: false,
        maintenanceError: action.response.data,
        isSiteUnderMaintenance: true
      };

      return newState;

    default:
      return state;
  }
};

export default maintenanceReducer;
