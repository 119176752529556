/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { H1Tag } from "../../../assets/StyleComponents/FontSize/fonts.style";
import { Button } from "../../../components/Reusable";
import {
  ArrowIllustration,
  CopyRoundedGreyIcon,
  CopyRoundedIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  LinkIcon,
  ReferralIllustration
} from "../../../assets/svgs";
import { HeadingThird } from "../../../assets/StyleComponents/FontSize/headingThird.style";
import { FormInput } from "../../../assets/StyleComponents/Input.style";
import { getInviteFriends, getReferralCode } from "../../../lib/api";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import HowItWorks from "./HowItWorks";
import { BarCodeIllustrationReferal } from "../../../assets/svgs/illustrations";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";
import QrCodeModal from "./Modals/QrCode";
import InviteFriends from "./Modals/InviteFriends";
import CopyToClipboard from "react-copy-to-clipboard";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { formatAPIErrors } from "../../../utils/general";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { customDecode } from "../../../utils/envScript/envCrypto";

function TopBanner({ isLoggedIn, KYCStatus }) {
  // eslint-disable-next-line no-unused-vars
  const [referralCode, setReferralCode] = useState("...");
  const [hide, setHide] = useState(true);
  const { width } = useWindowSize();
  const [qrcodeModal, setQrCodeModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const ReferalLink = `${customDecode(process.env.REACT_APP_EXCHANGE_URL)}/join/${referralCode}`;
  const history = useHistory();
  const isTheme = useSelector((state) => state.theme.isTheme);
  const prevLang = localStorage.getItem("ljs-lang");
  const userData = useSelector((state) => state.dashboard.userData);

  useEffect(() => {
    if (isLoggedIn && KYCStatus) {
      getCode();
      ImageArrayFunction();
    }
    // eslint-disable-next-lines
  }, []);

  const getCode = () => {
    getReferralCode()
      .then((res) => {
        setReferralCode(res && res.data && res.data.data.code);
      })
      .catch((err) => {});
  };

  const ImageArrayFunction = () => {
    getInviteFriends()
      .then((res) => setImageArray(res.data && res.data.data.data))
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const onCloseQrcodeModal = () => setQrCodeModal(false);
  const onCloseInviteModal = () => setInviteModal(false);
  return (
    <>
      {qrcodeModal && (
        <QrCodeModal
          qrcodeModal={qrcodeModal}
          referalCode={referralCode}
          referalLink={ReferalLink}
          onClose={onCloseQrcodeModal}
        />
      )}
      {inviteModal && (
        <InviteFriends
          onClose={onCloseInviteModal}
          referalCode={referralCode}
          referalLink={ReferalLink}
          ImageArray={imageArray}
        />
      )}
      <BannerBox CardWhite position="relative" padding="40px" radius="8px" paddingMd="24px">
        <BannerBox bottomBorder>
          <ArrowIllustration
            displaySm="none"
            absolute
            width="156px"
            height="226px"
            top="-58px"
            left="-9px"
            leftMd="-19px"
          />
          <BannerBox display="flex" justifyContent="space-between" directionMd="column-reverse">
            <BannerBox width="60%" widthMd="100%">
              <H1Tag
                size="128px"
                weight="600"
                txtPrimary
                sizeLg="88px"
                sizeMd="40px"
                textAlignMd="center"
              >
                40%
              </H1Tag>
              <BannerHeading
                size="24px"
                weight="400"
                txtGrey
                sizeLg="16px"
                sizeMd="14px"
                textAlignMd="center"
              >
                Earnings on Your Friends' Every Trade at LCX!
              </BannerHeading>
              <HeadingThird
                size="36px"
                weight="500"
                mt="20px"
                mb="20px"
                sizeLg="24px"
                sizeMd="20px"
                textAlignMd="center"
              >
                Your Friends !! Your Rewards
              </HeadingThird>
              {!KYCStatus && isLoggedIn ? null : (
                <Button
                  width="256px"
                  btnHeight="44px"
                  primaryBlue
                  mt="20px"
                  widthMd="100%"
                  onClick={() => {
                    if (isLoggedIn) {
                      setInviteModal(true);
                    } else {
                      history.push(
                        `${
                          prevLang
                            ? `/${prevLang}`
                            : userData.preferredLanguage
                            ? `/${userData.preferredLanguage}`
                            : ""
                        }/register`,
                        {
                          fromReferral: true
                        }
                      );
                    }
                  }}
                >
                  Invite Friends
                </Button>
              )}
              {!KYCStatus && isLoggedIn && (
                <Button
                  width="256px"
                  btnHeight="44px"
                  primaryBlue
                  mt="20px"
                  widthMd="100%"
                  onClick={() => {
                    history.push("/setting/profile");
                  }}
                >
                  Complete KYC
                </Button>
              )}
            </BannerBox>
            <BannerBox width="40%" widthMd="100%" displayMd="flex" justifyContentMd="center">
              <ReferralIllustration
                width="345px"
                height="345px"
                widthMd="220px"
                heightMd="220px"
                widthSm="80px"
                heightSm="80px"
              />
            </BannerBox>
          </BannerBox>
          {isLoggedIn && (
            <>
              <BannerBox
                display="flex"
                justifyContent="space-between"
                mt="48px"
                mb="40px"
                mtMd="24px"
                mbMd="24px"
                alignItems="flex-end"
                flexWrapMd="wrap"
                gapMd="16px"
              >
                {/* ++++++++++++++++++++++++++++++++ */}
                <BannerBox>
                  <Label size="12px" weight="500">
                    Referral Link
                  </Label>
                  <BannerBox display="flex" position="relative">
                    <LinkIcon absolute top="12px" left="6px" transform="rotate(135deg)" />
                    <FormInput
                      type="text"
                      sizeMd="12px"
                      required="required"
                      padding="0px 0px 0px 35px"
                      radius="5px 0px 0px 5px"
                      size="14px"
                      weight="500"
                      width="457px"
                      widthLg="200px"
                      widthMd="248px"
                      value={
                        KYCStatus
                          ? `${customDecode(process.env.REACT_APP_EXCHANGE_URL)}/join/${referralCode}`
                          : "..."
                      }
                      BodyColor
                      style={{ borderBottom: "0px" }}
                    />
                    <CopyToClipboard
                      text={`${customDecode(process.env.REACT_APP_EXCHANGE_URL)}/join/${referralCode}`}
                      onCopy={() => showSuccessMsg("Referral link copied")}
                    >
                      <ButtonComponent
                        width="86px"
                        display="flex"
                        widthMd="48px"
                        justifyContent="space-evenly"
                        radius="0px 5px 5px 0px"
                        DisabledBTNWhiteText={!KYCStatus}
                        size="14px"
                        alignItems="center"
                        style={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
                      >
                        {isTheme && !KYCStatus ? <CopyRoundedGreyIcon /> : <CopyRoundedIcon />}

                        {width > 767 && `Copy`}
                      </ButtonComponent>
                    </CopyToClipboard>
                  </BannerBox>
                </BannerBox>
                <BannerBox leftBorder={width > 767} pl="40px" plLg="12px" plMd="0px">
                  <Label size="12px" weight="500">
                    Referral Code
                  </Label>
                  <BannerBox display="flex">
                    <FormInput
                      type="text"
                      sizeMd="12px"
                      required="required"
                      radius="5px 0px 0px 5px"
                      size="14px"
                      weight="500"
                      widthLg="127px"
                      widthMd="184px"
                      value={referralCode}
                      BodyColor
                      style={{ borderBottom: "0px" }}
                    />
                    <CopyToClipboard
                      text={`${referralCode}`}
                      onCopy={() => showSuccessMsg("Referral code copied")}
                    >
                      <ButtonComponent
                        width="86px"
                        display="flex"
                        justifyContent="space-evenly"
                        radius="0px 5px 5px 0px"
                        DisabledBTNWhiteText={!KYCStatus}
                        widthMd="48px"
                        alignItems="center"
                        size="14px"
                        style={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
                      >
                        {isTheme && !KYCStatus ? <CopyRoundedGreyIcon /> : <CopyRoundedIcon />}
                        {width > 767 && `Copy`}
                      </ButtonComponent>
                    </CopyToClipboard>
                  </BannerBox>
                </BannerBox>

                {/* +++++++++++++++++++++++++++++++++++++ */}
                {/* {width > 1024 && ( */}
                <BannerBox>
                  <ButtonComponent
                    width="64px"
                    btnHeight="64px"
                    widthLg="48px"
                    btnHeightLg="48px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    QRCODEBTN={KYCStatus}
                    DisabledBTNWhiteText={!KYCStatus}
                    onClick={() => {
                      if (KYCStatus) {
                        setQrCodeModal(true);
                      }
                    }}
                  >
                    <BarCodeIllustrationReferal
                      width="40px"
                      height="40px"
                      widthMd="32px"
                      heightMd="32px"
                    />
                  </ButtonComponent>
                </BannerBox>
                {/* )} */}
              </BannerBox>
            </>
          )}
        </BannerBox>
        {hide && <HowItWorks />}

        <BannerBox
          justifyContent="center"
          alignItems="center"
          display="flex"
          gap="10px"
          height="42px"
          onClick={() => setHide(!hide)}
        >
          {hide ? (
            <ButtonComponent
              bck="transparent"
              border="0px"
              display="flex"
              txtGrey
              mt="32px"
              sizeLg="16px"
            >
              Hide Tip <ExpandLessIcon />
            </ButtonComponent>
          ) : (
            <ButtonComponent
              bck="transparent"
              border="0px"
              display="flex"
              txtGrey
              mt="32px"
              sizeLg="16px"
            >
              Find out how your referral works <ExpandMoreIcon />
            </ButtonComponent>
          )}
        </BannerBox>
      </BannerBox>
    </>
  );
}

export default TopBanner;
