import Skeleton from "./Skeleton";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";

const BuyTokenBannerLoader = () => {
  return (
    <BannerBox display="flex" gap="16px">
      <BannerBox
        width="256px"
        height="172px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        CardGrey
      >
        <Skeleton className="buytokenloader__coin" circle={true} width="24px" />
      </BannerBox>
      <BannerBox>
        <Skeleton width={200} height={20} />
        <Skeleton width={100} height={20} />
        <br />
        <Skeleton width={100} height={30} />
        <BannerBox mt="30px">
          <Skeleton width={400} height={30} />
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
};

export default BuyTokenBannerLoader;
