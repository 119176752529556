import React from "react";
import SkeletonLoader from "../../../components/SkeletonLoader/index";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
// import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
// import TableLoader from "./TableLoader";
import TableLoader from "../../../components/Loader/TableLoader";

const OpenOrderPageLoader = (props) => {
  const { status } = props;
  let { width } = useWindowSize();

  return (
    <BannerBox width="100%" display="flex" direction="column" position="relative" zIndex="0">
      {/* {status === "openOrder" && ( */}
      <BannerBox
        flex="1"
        padding="0px 1rem"
        width="100%"
        display="flex"
        direction="column"
        gap="30px"
        margin="25px 0px"
      >
        <SkeletonLoader width={100} height={width > 767 ? 30 : 20} />
        <SkeletonLoader width={200} height={width > 767 ? 30 : 20} />
        {status === "openHistory" && <SkeletonLoader height={width > 767 ? 30 : 20} />}
      </BannerBox>

      <TableLoader rows={5} webCol={8} tabletCol={4} mobileCol={3} />
    </BannerBox>
  );
};

export default OpenOrderPageLoader;
