import { useState, useEffect, useCallback } from "react";

export const useWindowSize = () => {
  const [windowSize, setWindowSizeRes] = useState({
    width: undefined,
    height: undefined
  });

  const setWindowSize = useCallback(
    (props) =>
      setWindowSizeRes({
        width: props.width,
        height: props.height
      }),
    []
  );

  useEffect(() => {
    const handleWindowSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener("resize", handleWindowSize);

    handleWindowSize();
    return () => window.removeEventListener("resize", handleWindowSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};
