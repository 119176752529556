import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import moment from "moment";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import CountTimerInWords from "../../../components/CountDownTimer/countDownTimer";
import { TimerGreenIcon } from "../../../assets/svgs";
import { ImgElement } from "../../../components/Reusable";

const SingleCompetitionList = ({ data, activeState }) => {
  let { Name, From, To, Id } = data;
  const isTheme = useSelector((state) => state.theme.isTheme);
  // const userData = useSelector((state) => state.dashboard.userData);
  const history = useHistory();
  const prevLang = localStorage.getItem("ljs-lang");
  
  const handleCompetitionLink = () => {
    // if (activeState !== "completed") {
    history.push(`${prevLang ? `/${prevLang}` : "/en"}/trading-competition/${Id}`);
    // }
  };

  return (
    <BannerBox
      CardWhite2
      CardWhiteShadow
      CardLevel2BorderColor
      width="336px"
      // height="256px"
      radius="10px"
      widthLg="100%"
      heightLg="auto"
      // widthMd="296px"
      // heightMd="238px"
      cursorPointer={"pointer"}
      onClick={handleCompetitionLink}
    >
      <BannerBox overflow="hidden" BorderRadiusLR="5px" BorderRadiusTR="5px">
        <ImgElement
          src={data.ImageCard ? data.ImageCard : require("../../../assets/img/Card_5-03.jpg")}
          alt="Lcx Competition"
          width="100%"
          height="166px"
          ObjectFit="cover"
          // className="img-fluid"
        />
      </BannerBox>

      <BannerBox padding="0 12px 19px">
        <ParaTag size="18px" mt="16px">
          {Name?.length > 1 ? Name : "No Name"}
          {/* {Participated && <BadgeComponent title="Participated" borderColor="success" />} */}
        </ParaTag>

        <BannerBox display="inline-flex" alignItems="center" mt="13px">
          <TimerGreenIcon width="20px" height="20px" />
          <ParaTag
            size="12px"
            ml="6px"
            display="flex"
            alignItems="center"
            color={isTheme ? "#A7A9AB" : "#606F80"}
          >
            {activeState === "ongoing"
              ? "Ends in "
              : activeState === "upcoming"
              ? "Starts in "
              : "Ended on "}

            <BannerHeading ml="4px" display="flex" alignItems="center">
              {activeState === "completed" ? (
                <BannerHeadingSpan size="12px" weight="400">
                  {moment(To * 1000).format("DD/MM/YYYY")}
                </BannerHeadingSpan>
              ) : (
                <CountTimerInWords
                  eventTime={activeState === "ongoing" ? To : From}
                  type="Cardcounter"
                />
              )}
            </BannerHeading>
          </ParaTag>
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
};

export default SingleCompetitionList;
