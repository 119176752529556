import React from "react";

import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../../constants/Images/imageIndex";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { ImgElement } from "../../../components/Reusable";
import { AnchorTag, LiTag } from "../../../assets/StyleComponents/FontSize/para.style";
// import { KrakenDarkIcon, KrakenLightIcon } from "../../../assets/svgs";

const ExchangeListing = (props) => {
  const { width } = useWindowSize();
  let { isTheme } = props;
  const CurrentListing = [
    {
      title: "LCX Token is listed at Coinbase Pro Exchange with three trading pairs:",
      tokens: ["LCX/USD", "LCX/EUR", "LCX/USDT"],
      btnText: "TRADE $LCX AT COINBASE PRO",
      imgLinkLight: IMAGE_PREFIX.CoinBaseProIcon,
      imgLinkdark: IMAGE_PREFIX_DARK.CoinBaseProIcon,
      btnLink: "https://pro.coinbase.com/trade/LCX-USD",
      company: "coinbase"
    },
    {
      title: "LCX Token is listed at Kraken with two trading pairs:",
      tokens: ["LCX/USD", "LCX/EUR"],
      btnText: "TRADE $LCX AT KRAKEN.COM",
      imgLinkLight: IMAGE_PREFIX.KrakenIcon,
      imgLinkdark: IMAGE_PREFIX_DARK.KrakenIcon,
      btnLink: "https://www.kraken.com/prices/lcx?interval=1m&quote=usd",
      company: "kraken"
    },
    {
      title: "LCX Token is listed at Uniswap Exchange with two trading pairs:",
      tokens: ["LCX/ETH", "LCX/USDC"],
      btnText: "TRADE $LCX AT UNISWAP",
      imgLinkLight: IMAGE_PREFIX.UniswapIcon,
      imgLinkdark: IMAGE_PREFIX_DARK.UniswapIcon,
      btnLink: "https://v2.info.uniswap.org/token/0x037a54aab062628c9bbae1fdb1583c195585fe41",
      company: "uniswap"
    },
    {
      title: "LCX Token is listed at CoinDCX with following trading pair:",
      tokens: ["LCX/INR"],
      btnText: "TRADE $LCX AT COINDCX.COM",
      imgLinkLight: IMAGE_PREFIX.CoinDCX,
      imgLinkdark: IMAGE_PREFIX_DARK.CoinDCXDark,
      btnLink: "https://coindcx.com/trade/LCXINR",
      company: "coindcx"
    }
  ];

  const FindTokenLink = (company, tokens) => {
    switch (company) {
      case "coinbase":
        return `https://pro.coinbase.com/trade/${tokens}`;
      case "kraken":
        return `https://pro.kraken.com/app/trade/${tokens}`;
      default:
        return "https://pro.coinbase.com/trade/LCX-USD";
    }
  };

  return (
    <BannerBox display="flex" direction="column" width="100%">
      {width > 1024 && (
        <BannerHeading size="24px" weight="bold" lineHeight="1.7" BodyColor mt="20px">
          LCX Token: Current Exchange Listings
        </BannerHeading>
      )}

      <BannerBox display="grid" width="100%" gridCols="1fr 1fr" gap="20px" gridColsMd="1fr">
        {CurrentListing.map((values) => {
          return (
            <>
              <BannerBox
                width="373px"
                widthLg="329px"
                widthMd="100%"
                display="flex"
                direction="column"
                mt="1.2rem"
                borderRadius="10px"
                CardLevel2
                padding="20px"
              >
                {/* {isTheme ? <KrakenDarkIcon width="60%"/> : <KrakenLightIcon width="60%"/>} */}
                <ImgElement
                  loading="lazy"
                  src={!isTheme ? values.imgLinkLight : values.imgLinkdark}
                  alt="Icons"
                  width={values.company !== "coindcx" ? "60%" : "45%"}
                />

                <BannerHeading
                  size="18px"
                  sizeLg="16px"
                  sizeMd="14px"
                  paddTop={values.company !== "coindcx" ? "20px" : "33px"}
                  mb="0px"
                  mt="15px"
                  weight="300"
                >
                  {values.title}
                </BannerHeading>

                <ul
                  style={{
                    paddingTop: "12px",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    paddingLeft: "20px",
                    textDecorationColor: "white"
                  }}
                >
                  {values.tokens.map((tokens) => {
                    return (
                      <>
                        <LiTag mt="0.6rem" BodyColor>
                          <AnchorTag
                            rel="noreferrer"
                            size="18px"
                            sizeLg="16px"
                            BodyColor
                            sizemd="14px"
                            weight="300"
                            target="_blank"
                            href={FindTokenLink(values.company, tokens)}
                          >
                            {tokens}
                          </AnchorTag>
                        </LiTag>
                      </>
                    );
                  })}
                </ul>

                <BannerHeading mt="8px" mb="20px">
                  <AnchorTag
                    rel="noreferrer"
                    size="16px"
                    sizeLg="14px"
                    weight="bold"
                    txtPrimary
                    target="_blank"
                    href={values.btnLink}
                  >
                    <u> {values.btnText} </u>
                  </AnchorTag>
                </BannerHeading>
              </BannerBox>
            </>
          );
        })}
      </BannerBox>
    </BannerBox>
  );
};

export default ExchangeListing;
