/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { CircleLoader, LoadingButton } from "../../../../../../../components/Loader";
import { compose } from "redux";
import { connect } from "react-redux";
import { numberWithCommas } from "../../../../../../../utils/general";
import { setDecimal, sleep } from "../../../../../../../utils";
import {
  DivElement,
  TextWrapper,
  Checkbox,
  Button,
  LinkButton
} from "../../../../../../../components/Reusable";
import { RestrictedUser } from "../../../../../../../components/Alert";
import InsufficientBalance from "../../../../../../../components/InsufficientBalance";
import { TimerBlueIcon } from "../../../../../../../assets/svgs";
import { useWindowSize } from "../../../../../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../../../../../constants/styledConstants";
import Countdown from "react-countdown";
import * as Yup from "yup";
import AmountInput from "../../../../Components/AmountInput";

const Step2Timer = () => {
  const { width } = useWindowSize();
  const isMobile = width <= styledConstants.BREAKING_POINT_MOBILE;

  const renderer = ({ seconds }) => {
    let string = seconds + "s";
    return (
      <DivElement flexAlignCenter>
        <TimerBlueIcon marginRight="8px" />
        <TextWrapper blue>
          {isMobile ? "" : "Rate refreshes in"} {string}
        </TextWrapper>
      </DivElement>
    );
  };

  return <Countdown autoStart renderer={renderer} date={Date.now() + 30000} />;
};

const validationSchema = (min, max) =>
  Yup.object().shape({
    lcxAmount: Yup.number()
      .nullable(true)
      .min(min, `Min Amount is ${numberWithCommas(min)}`)
      .max(max, `Max Amount is ${numberWithCommas(max)}`)
      .required("Amount to purchase"),
    lcxPayment: Yup.number()
      .nullable(true)
      .positive("Only positive number allowed")
      .min(0, "Min value is 0")
      .required("Please enter the valid amount "),
    isChecked: Yup.bool().oneOf([true], "* Accept Terms & Conditions is required")
  });

const PrivateStep2 = ({
  saleDetails,
  onBuyHandler,
  paymentCoinBalance,
  selectedPaymentCoin,
  onCoinChangeHandler,
  selectedCoinRate,
  feeCoinBalance,
  purchaseFee,
  purchaseAmt,
  paymentAmt,
  userData,
  isLoggedIn,
  onAmountChangeHandler
}) => {
  const [showRestrictedModal, setShowRestrictedModal] = useState(false);
  const [showAddFunds, setShowAddFunds] = useState(false);
  const [coinWithLowBalance, setCoinWithLowBalance] = useState("");
  const [requiredFunds, setRequiredFunds] = useState("");
  const min = saleDetails?.round ? saleDetails.round.minAmount : 0;
  const max = saleDetails?.round ? saleDetails.round.maxAmount : 0;
  const isFeeEnough = feeCoinBalance >= purchaseFee;
  const isPurchaseBalanceEnough = paymentCoinBalance >= paymentAmt;
  const isRestricted = userData?.status === "RESTRICTED";
  let prevLang = localStorage.getItem("ljs-lang");

  const getInitialForm = () => {
    return {
      lcxAmount: purchaseAmt || "",
      lcxPayment: paymentAmt || "",
      isChecked: false
    };
  };

  useEffect(() => {
    if (isRestricted) {
      setShowRestrictedModal(true);
    } else {
      setShowRestrictedModal(false);
    }
  }, [isRestricted]);

  const onClickAddFunds = (type) => {
    if (type === "FEE_COIN") {
      setCoinWithLowBalance(saleDetails?.round?.feeCoin || "LCX");
      setRequiredFunds(purchaseFee);
    } else {
      setCoinWithLowBalance(selectedCoinRate?.coin);
      setRequiredFunds(paymentAmt);
    }
    setShowAddFunds(true);
  };

  const validateAmount = (value) => {
    return sleep(0).then(() => {
      if (value < 0) {
        return "Only positive number allowed";
      }
      if (value < min) {
        return `Min Amount is ${numberWithCommas(min)}`;
      }
      if (value > max) {
        return `Max Amount is ${numberWithCommas(max)}`;
      }
      if (value === null) {
        return "Please enter the value";
      }
    });
  };

  const errorHandling = async (value, data) => {
    if (value) {
      let error = await validateAmount(value, min, max);
      if (error && data.errors.lcxAmount !== error) {
        if (!data.touched.lcxAmount) {
          data.setTouched({ ...data.touched, lcxAmount: true });
        }
        data.setFieldError("lcxAmount", error);
      } else if (data.errors.lcxAmount && !error) {
        data.setFieldError("lcxAmount", undefined);
      }
    }
  };

  return (
    <Fragment>
      <DivElement flexJustifyBtwAlignCenter>
        <TextWrapper fontWeight="bold" fontSize="20px">
          Buy <var>{saleDetails.coin}</var>
        </TextWrapper>
        <Step2Timer />
      </DivElement>
      <DivElement marginTop="32px">
        <Formik
          initialValues={getInitialForm()}
          validationSchema={validationSchema(min, max)}
          onSubmit={onBuyHandler}
        >
          {(data) => {
            const { isSubmitting, values, setFieldValue, errors } = data;
            errorHandling(values["lcxAmount"], data);
            return (
              <Form noValidate>
                <AmountInput
                  name="lcxAmount"
                  title="Amount to purchase"
                  onAmountChangeHandler={onAmountChangeHandler}
                  setFieldValue={setFieldValue}
                  saleDetails={saleDetails}
                  balance={saleDetails?.coinBalance}
                  errors={errors}
                />

                <AmountInput
                  name="lcxPayment"
                  title="Currency for payment"
                  onAmountChangeHandler={onAmountChangeHandler}
                  setFieldValue={setFieldValue}
                  saleDetails={saleDetails}
                  balance={paymentCoinBalance}
                  onCoinChangeHandler={onCoinChangeHandler}
                  selectedPaymentCoin={selectedPaymentCoin}
                  showRedBorder={!isPurchaseBalanceEnough && saleDetails.status === "ONGOING"}
                  onClickAddFunds={onClickAddFunds}
                  isPurchaseBalanceEnough={isPurchaseBalanceEnough}
                  errors={errors}
                  containerProps={{ paddingBottom: "0px" }}
                />

                <DivElement minHeight="122px">
                  {saleDetails.status === "ONGOING" && !saleDetails.tokenSoldOut && (
                    <DivElement marginTop="6px" flex justifyContent="flex-end" marginBottom="24px">
                      <CircleLoader />
                      <TextWrapper marginLeft="6px" secondary>
                        <var>
                          {`1 ${saleDetails.coin} ≈ ${
                            selectedCoinRate
                              ? selectedCoinRate.rate < 1
                                ? setDecimal(selectedCoinRate.rate, 8)
                                : +selectedCoinRate.rate
                              : ""
                          } ${selectedCoinRate.coin || ""}`}
                        </var>
                      </TextWrapper>
                    </DivElement>
                  )}

                  {purchaseAmt && saleDetails.status === "ONGOING" && !saleDetails.tokenSoldOut && (
                    <DivElement>
                      <DivElement displayBtw>
                        <TextWrapper fontSize="14px" lineHeight="normal" secondary>
                          Fee (in {selectedCoinRate.feeCoin || "LCX"})
                        </TextWrapper>
                        <TextWrapper fontSize="14px" lineHeight="normal">
                          {numberWithCommas(purchaseFee)} {selectedCoinRate.feeCoin || "LCX"}
                        </TextWrapper>
                      </DivElement>
                      {isLoggedIn && (
                        <DivElement displayBtw marginTop="9px">
                          <TextWrapper fontSize="14px" lineHeight="normal" secondary>
                            Available
                          </TextWrapper>
                          <DivElement dAlignCenter>
                            {!isFeeEnough && (
                              <DivElement bdRight paddingRight="9px">
                                <Button
                                  type="button"
                                  onClick={() => onClickAddFunds("FEE_COIN")}
                                  blueTextBtn
                                  height="initial"
                                  fontSize="14px"
                                >
                                  Add {selectedCoinRate.feeCoin || "LCX"}
                                </Button>
                              </DivElement>
                            )}

                            <TextWrapper
                              fontSize="14px"
                              lineHeight="normal"
                              marginLeft="9px"
                              red={isFeeEnough ? null : true}
                            >
                              {setDecimal(feeCoinBalance)} {selectedCoinRate.feeCoin || "LCX"}
                            </TextWrapper>
                          </DivElement>
                        </DivElement>
                      )}
                    </DivElement>
                  )}
                </DivElement>

                {isLoggedIn ? (
                  <Fragment>
                    <DivElement display="flex">
                      <Checkbox
                        checked={values.isChecked}
                        setChecked={(val) => setFieldValue("isChecked", val)}
                      />
                      <TextWrapper fontWeight="300" fontSize="14px" marginLeft="12px">
                        I have read the{" "}
                        <a
                          className="blue-font"
                          href={saleDetails.publicData.legal.downloadLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Basic Information
                        </a>{" "}
                        and accept the{" "}
                        <a
                          className="blue-font"
                          href={saleDetails.publicData.legal.termsOfSale}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Token Sale.
                        </a>
                      </TextWrapper>
                    </DivElement>
                    <ErrorMessage
                      name="isChecked"
                      component="div"
                      className="red-font font-weight-500 ml-2"
                    />

                    <Button
                      type="submit"
                      width="100%"
                      height="48px"
                      marginTop="16px"
                      primaryBlue
                      primaryRed={saleDetails.tokenSoldOut ? true : null}
                      primaryGrey={
                        saleDetails.status === "UPCOMING" ||
                        !isPurchaseBalanceEnough ||
                        !isFeeEnough ||
                        isRestricted
                          ? true
                          : null
                      }
                      disabled={
                        saleDetails.status !== "ONGOING" ||
                        saleDetails.tokenSoldOut ||
                        isSubmitting ||
                        !isPurchaseBalanceEnough ||
                        !isFeeEnough ||
                        !values.lcxPayment ||
                        isRestricted
                      }
                    >
                      {saleDetails.tokenSoldOut ? (
                        "SOLD OUT"
                      ) : saleDetails.status === "UPCOMING" ? (
                        "Sale has not started yet"
                      ) : !isPurchaseBalanceEnough || !isFeeEnough ? (
                        "Insufficient Balance"
                      ) : isSubmitting ? (
                        <LoadingButton />
                      ) : saleDetails.status !== "ONGOING" ? (
                        "Sale Ended"
                      ) : (
                        <>
                          Buy<var>{` ${saleDetails.coin}`}</var>
                        </>
                      )}
                    </Button>
                  </Fragment>
                ) : (
                  <LinkButton
                    to={`${prevLang ? `/${prevLang}` : ""}/login`}
                    width="100%"
                    height="48px"
                    secondaryBlue
                  >
                    Log in or Register
                  </LinkButton>
                )}
              </Form>
            );
          }}
        </Formik>
      </DivElement>

      {showRestrictedModal && (
        <RestrictedUser open={showRestrictedModal} onClose={() => setShowRestrictedModal(false)} />
      )}

      {showAddFunds && (
        <InsufficientBalance
          onClose={() => setShowAddFunds(false)}
          coin={coinWithLowBalance}
          requiredFunds={requiredFunds}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData,
  isLoggedIn: state.auth.isLoggedIn
});

export default compose(connect(mapStateToProps))(PrivateStep2);
