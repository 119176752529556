import React, { useEffect, useState } from "react";
import { getClientSaleProgress } from "../../../../lib/api";
import { getAllClientSales } from "../../../../actions/tokenSale";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { DivElement } from "../../../../components/Reusable";
import {
  SaleAnalyticsRow1,
  SaleAnalyticsRow2,
  SaleAnalyticsRow3,
  SaleAnalyticsRow4
} from "./SaleAdminDashboard";
// import { TokenSaleTransactions } from "../../../../components/Modules/Wallets/TokenSaleTransactions";
import { TokenSaleAdminContainer } from "../../../../assets/StyleComponents/TokenSaleAdmin.style";
import "../../../../style/accounts/main-accounts.scss";

const SaleAnalytics = (props) => {
  const [saleDetails, setSaleDetails] = useState("");
  const [loader, setLoader] = useState(false);
  const prevLang = localStorage.getItem("ljs-lang");


  useEffect(() => {
    if (props.allClientSales && !props.allClientSales.showLink) {
      props.history.push(`/${prevLang ? prevLang : props.userData.preferredLanguage ? `${props.userData.preferredLanguage}` : ""}`);
    }

    if (!props.allClientSales) {
      props.getAllAdminSales();
    }
    getSaleAnalytics();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.allClientSales && !props.allClientSales.showLink) {
      props.history.push(`/${prevLang ? prevLang : props.userData.preferredLanguage ? `${props.userData.preferredLanguage}` : ""}`);
    }
    // eslint-disable-next-line
  }, [props.allClientSales]);

  const getSaleAnalytics = () => {
    setLoader(true);
    const tokenSaleId = props.match.params.id;
    getClientSaleProgress({ tokenSaleId })
      .then((res) => {
        setSaleDetails(res && res.data.data);
      })
      .catch((err) => {
        return;
      })
      .finally(() => setLoader(false));
  };
  return (
    <DivElement pageContainer>
      <TokenSaleAdminContainer className="saleanalytics">
        <div className="saleanalytics__title">
          {`Admin Dashboard(${saleDetails ? saleDetails.tokenSaleName : ""})`}
        </div>
        <SaleAnalyticsRow1 saleDetails={saleDetails} loader={loader} />

        <SaleAnalyticsRow2 saleDetails={saleDetails} loader={loader} />

        <SaleAnalyticsRow3 saleDetails={saleDetails} loader={loader} />

        <SaleAnalyticsRow4 saleDetails={saleDetails} loader={loader} />
      </TokenSaleAdminContainer>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  allClientSales: state.sale.allClientSales,
  userData: state.dashboard.userData
});

const mapDispatchToProps = (dispatch) => ({
  getAllAdminSales: () => dispatch(getAllClientSales())
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SaleAnalytics);
