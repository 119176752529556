import React from "react";
import { Helmet } from "react-helmet";
import { DynamicScript, Script1 } from "./PersonalizedScripts";

const SetHeaderTitleComponent = ({ Content }) => {
  return (
    <Helmet>
      {/* Main */}
      <title>{Content?.title}</title>
      <meta name="description" content={Content?.description} data-react-helmet="true" />
      <meta name="keywords" content={Content?.keywords} data-react-helmet="true" />
      <link
        rel="canonical"
        href={
          Content?.canonicalUrl && Content?.afterLink
            ? `${Content?.canonicalUrl}${Content?.afterLink}`
            : Content.canonical
        }
      />
      {Content.alternate &&
        Content.alternate.length > 0 &&
        Content.alternate.map((item) => (
          <link
            key={item?.hrefLang}
            rel="alternate"
            hrefLang={item?.hrefLang}
            href={`${Content?.canonicalUrl}${item.lastParam}`}
          />
        ))}

      {/* OGS */}
      <meta property="og:title" content={Content?.OgTitle} />
      <meta property="og:description" content={Content?.OgDescription} />
      <meta property="og:url" content={Content?.OgURL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="LCX" />
      <meta property="og:image" content={Content?.OgImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={Content?.TwTitle} />
      <meta name="twitter:description" content={Content?.TwDescription} />
      <meta name="twitter:site" content="@lcx" />
      <meta name="twitter:image" content="https://exchange.lcx.com/img/demo.jpg" />
      <meta name="twitter:image:width" content="1200" />
      <meta name="twitter:image:height" content="675" />

      {/* JSON-LD Script */}
      <script type="application/ld+json">{JSON.stringify(Script1)}</script>
      {/* JSON-LD Script */}
      {Content.name && (
        <script type="application/ld+json">
          {JSON.stringify(DynamicScript(Content?.name, Content?.link))}
        </script>
      )}
    </Helmet>
  );
};

export default SetHeaderTitleComponent;
