import apiConstants from "../constants/apiConstants";

export const getTransactions = (data) => ({
  type: apiConstants.GET_TRANSACTIONS,
  payload: data
});

export const getAllWallets = () => ({
  type: apiConstants.GET_ALL_WALLETS
});

export const clearWalletErrors = () => ({
  type: apiConstants.CLEAR_WALLET_ERRORS
});