/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, DivElement, TextWrapper } from "../../../../../components/Reusable";
import { CopyGreyIcon } from "../../../../../assets/svgs";
import { showErrorMsg, showSuccessMsg } from "../../../../../utils/notification";
import { createWtDepositRequest } from "../../../../../lib/api";
import { useState } from "react";
import { LoadingButton } from "../../../../../components/Loader";
import { formatAPIErrors } from "../../../../../utils/general";
import { DepositContext } from "../../../../../contexts/WalletPage/DepositPage/depositContext";
import { useContext } from "react";

const BankDetails = ({ details, amount, beneficiary, coin, disabled }) => {
  const [loader, setLoader] = useState(false);
  const { setSelectedProvider, setSelectedCoin, callGetDeposits } = useContext(DepositContext);

  const DATA = [
    { label: "Account Name", value: details?.accountName },
    { label: "IBAN", value: details?.iban, showCopy: true },
    { label: "SWIFT", value: details?.swift, showCopy: true },
    { label: "Bank Name", value: details?.bankName }
  ];

  const onCopy = (label) => {
    showSuccessMsg(`Successfully copied ${label}!`);
  };

  const onRequestDeposit = () => {
    setLoader(true);
    let data = {
      coin: coin,
      amount: Number(amount),
      beneficiaryId: beneficiary?.id
    };

    createWtDepositRequest(data)
      .then((res) => {
        showSuccessMsg(res?.data?.message);
        getDeposits();
        setSelectedProvider("");
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setLoader(false));
  };

  const getDeposits = () => {
    let data = {
      coin: coin,
      order: "DESC",
      type: "DEPOSIT",
      page: 0
    };

    callGetDeposits(data);
  };

  return (
    <DivElement width="100%" padding="16px" bgInputActive borderRadius="5px">
      <DivElement paddingBottom="12px" bdGrey borderBottom="1px solid">
        <TextWrapper fontWeight="300" fontSize="16px" lineHeight="22px">
          Please consider the following bank details for the amount transfer:
        </TextWrapper>
      </DivElement>
      {DATA.map((data) => (
        <DivElement key={data.label} flexJustifyBtwAlignCenter marginTop="16px">
          <TextWrapper secondary lineHeight="20px">
            {data.label}:
          </TextWrapper>
          {data.showCopy ? (
            <CopyToClipboard text={data.value}>
              <DivElement flexAlignCenter onClick={() => onCopy(data.label)} cursor>
                <TextWrapper fontSize="16px" lineHeight="24px">
                  {data.value}
                </TextWrapper>
                <CopyGreyIcon width="16px" height="16px" marginLeft="4px" />
              </DivElement>
            </CopyToClipboard>
          ) : (
            <TextWrapper fontSize="16px" lineHeight="24px">
              {data.value}
            </TextWrapper>
          )}
        </DivElement>
      ))}

      <Button
        primaryBlue
        width="100%"
        height="32px"
        marginTop="20px"
        onClick={onRequestDeposit}
        disabled={loader || disabled}
      >
        {loader ? <LoadingButton color="#fff" /> : "Request Deposit"}
      </Button>
    </DivElement>
  );
};

export default BankDetails;
