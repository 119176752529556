import styled, { css } from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { MarginStyle } from "../../../assets/StyleComponents/Reusable/Margin.style";
import { PaddingStyle } from "../../../assets/StyleComponents/Reusable/Padding.style";

const Td = styled.td`
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  vertical-align: ${(props) => (props.verticalAlign ? props.verticalAlign : "")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")} !important;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")} !important;
  padding: ${(props) => (props.padding ? props.padding : "0px")};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : "")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "")};
  color: ${(props) => (props.isDarkTheme ? "#dedfe0" : "#012243")};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "")};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "")};
  gap: ${(props) => (props.gap ? props.gap : "")};
  text-decoration: ${(props) => (props.textDecoration ? props.textDecoration : "")};
  display: ${(props) => (props.display ? props.display : "")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "")};
  ${MarginStyle}
  ${PaddingStyle}

  @media (max-width: 1024px) {
    width: ${(props) => (props.widthMd ? props.widthMd : "")};
    height: ${(props) => (props.heightMd ? props.heightMd : "")};
    vertical-align: ${(props) => (props.verticalAlignMd ? props.verticalAlignMd : "")};
    text-align: ${(props) => (props.textAlignMd ? props.textAlignMd : "")};
    font-weight: ${(props) => (props.fontWeightMd ? props.fontWeightMd : "")};
    font-size: ${(props) => (props.fontSizeMd ? props.fontSizeMd : "")};
    padding: ${(props) => (props.paddingMd ? props.paddingMd : "")};
    padding-top: ${(props) => (props.paddingTopMd ? props.paddingTopMd : "")};
    padding-right: ${(props) => (props.paddingRightMd ? props.paddingRightMd : "")};
    padding-bottom: ${(props) => (props.paddingBottomMd ? props.paddingBottomMd : "")};
    padding-left: ${(props) => (props.paddingLeftMd ? props.paddingLeftMd : "")};
  }
  @media (max-width: 767px) {
    width: ${(props) => (props.widthSm ? props.widthSm : "")};
    height: ${(props) => (props.heightSm ? props.heightSm : "")};
    vertical-align: ${(props) => (props.verticalAlignSm ? props.verticalAlignSm : "")};
    text-align: ${(props) => (props.textAlignSm ? props.textAlignSm : "")};
    font-weight: ${(props) => (props.fontWeightSm ? props.fontWeightSm : "")};
    font-size: ${(props) => (props.fontSizeSm ? props.fontSizeSm : "")};
    padding: ${(props) => (props.paddingSm ? props.paddingSm : "")};
    padding-top: ${(props) => (props.paddingTopSm ? props.paddingTopSm : "")};
    padding-right: ${(props) => (props.paddingRightSm ? props.paddingRightSm : "")};
    padding-bottom: ${(props) => (props.paddingBottomSm ? props.paddingBottomSm : "")};
    padding-left: ${(props) => (props.paddingLeftSm ? props.paddingLeftSm : "")};
  }

  ${MarginStyle}
  ${PaddingStyle}

  ${(props) =>
    props.orange &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#ffb83e" : "#ffb83e")};
    `}

  ${(props) =>
    props.green &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#68bf60" : "#008000")} !important;
    `}

  ${(props) =>
    props.blue &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#70a6e0" : "#0470e1")};
    `}

  ${(props) =>
    props.red &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#e66767" : "#e65252")} !important;
    `}

  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `}

  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.subText} !important;
    `};

  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText};
    `};

  ${(props) =>
    props.txtWarning &&
    css`
      color: ${(props) => props.color || props.theme.warningTxt} !important;
    `};

  ${(props) =>
    props.txtSuccess &&
    css`
      color: ${(props) => props.color || props.theme.success} !important;
    `};

  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
`;

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isTheme
});

export default compose(connect(mapStateToProps))(Td);
