/* eslint-disable no-unused-vars */
import React, { createContext, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { filterCoin } from "../../../utils/general";
import { getTransactions } from "../../../lib/api";

export const DepositContext = createContext();

const DepositProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [page, setPageRes] = useState(0);
  const [selectedCoin, setSelectedCoinRes] = useState("");
  const [selectedNetwork, setSelectedNetworkRes] = useState("");
  const [selectedProvider, setSelectedProviderRes] = useState("");
  const [isCardSelected, setIsCardSelectedRes] = useState(false);
  const [from, setFromRes] = useState("");
  const [to, setToRes] = useState("");
  const [currencyList, setCurrencyListRes] = useState([]);
  const [providerList, setProviderListRes] = useState([]);
  const [networkList, setNetworkListRes] = useState([]);
  const [beneficaryList, setBeneficaryListRes] = useState([]);
  const [activeTab, setActiveTabRes] = useState("all");
  const [mtc, setMtc] = useState(null);
  const [fqa, setFqaRes] = useState("");
  const [isLoader, setIsLoaderRes] = useState(false);
  const [isFqaLoading, setIsFqaLoadingRes] = useState(false);
  const [importantPoint, setImportantPointRes] = useState(false);
  const [loadingImportantPoint, setLoadingImportantPointRes] = useState(false);
  const [isTopModalActive, setisTopModalActive] = useState(false);

  const [initialDepositLoad, setInitialDepositLoadRes] = useState(false);
  const [initialAllLoad, setInitialAllLoadRes] = useState(false);
  const [initialWithdrawalLoad, setInitialWithdrawalLoadRes] = useState(false);

  const [depositList, setDepositListRes] = useState("");
  const [allList, setAllListRes] = useState("");
  const [withdrawalList, setWithdrawalListRes] = useState("");

  const [depositCount, setDepositCountRes] = useState(0);
  const [allCount, setAllCountRes] = useState(0);
  const [withdrawalCount, setWithdrawalCountRes] = useState(0);

  const resetValue = (props) => {
    setPageRes(0);
    setSelectedCoinRes("");
    setSelectedNetworkRes("");
    setSelectedProviderRes("");
    setNetworkListRes([]);
    setCurrencyListRes([]);
    setProviderListRes([]);
    setBeneficaryListRes([]);
    setFrom("");
    setToDate("");
    setMtc(null);
    setIsFqaLoading(false);
    setFqa("");
    setInitialAllLoad(false);
    setInitialDepositLoad(false);
    setInitialWithdrawalLoad(false);
    setDepositListRes("");
    setAllListRes("");
    setWithdrawalListRes("");
  };

  const setPage = useCallback((props) => setPageRes(props), []);
  const setSelectedCoin = useCallback((props) => {
    setSelectedNetwork("");
    setSelectedCoinRes(props);
  }, []);

  const setIsCardSelected = useCallback((props) => {
    setIsCardSelectedRes(props);
  }, []);

  const setSelectedNetwork = useCallback((props) => setSelectedNetworkRes(props), []);
  const setSelectedProvider = useCallback((props) => setSelectedProviderRes(props), []);
  const setNetworkList = useCallback((props) => setNetworkListRes(props), []);
  const setActiveTab = useCallback((props) => setActiveTabRes(props), []);
  const setFrom = useCallback((props) => setFromRes(props), []);
  const setToDate = useCallback((props) => setToRes(props), []);
  const setFqa = useCallback((props) => setFqaRes(props), []);
  const setIsFqaLoading = useCallback((props) => setIsFqaLoadingRes(props), []);
  const setImportantPoint = useCallback((props) => setImportantPointRes(props), []);
  const setLoadingImportantPoint = useCallback((props) => setLoadingImportantPointRes(props), []);
  const setIsLoader = useCallback((props) => setIsLoaderRes(props), []);

  const setDepositList = useCallback((props) => setDepositListRes(props), "");
  const setDepositCount = useCallback((props) => setDepositCountRes(props), []);
  const setInitialDepositLoad = useCallback((props) => setInitialDepositLoadRes(props), []);
  const setInitialWithdrawalLoad = useCallback((props) => setInitialWithdrawalLoadRes(props), []);
  const setInitialAllLoad = useCallback((props) => setInitialAllLoadRes(props), []);

  const setWithdrawalList = useCallback((props) => setWithdrawalListRes(props), "");
  const setWithdrawalCount = useCallback((props) => setWithdrawalCountRes(props), []);

  const setAllList = useCallback((props) => setAllListRes(props), "");
  const setAllCount = useCallback((props) => setAllCountRes(props), []);

  const setCurrencyList = useCallback((props, status) => {
    if (props && props.length > 0) {
      let optionList = filterCoin(props, status);
      setCurrencyListRes(optionList);
    }
    return [];
  }, []);

  const coinChanged = useCallback((props) => {
    setNetworkList([]);
    setProviderList([]);
    setSelectedNetwork("");
    setSelectedProvider("");
    setSelectedCoin(props);
  }, []);

  const FilterCallApi = (props) => {
    setIsLoader(true);
    setDepositCountRes(0);
    setWithdrawalCountRes(0);
    setAllCountRes(0);
    setDepositListRes("");
    setWithdrawalListRes("");
    setAllListRes("");
    let params = props;
    params.order = "DESC";
    params.page = page;
    getTransactions(params)
      .then((res) => {
        if (res.status === 200) {
          if (props.type === "DEPOSIT") {
            setActiveTab("Deposit");
            setDepositCountRes(res.data.count);
            setDepositListRes(res.data.data);
          } else if (props.type === "WITHDRAWAL") {
            setActiveTab("Withdrawal");
            setWithdrawalCountRes(res.data.count);
            setWithdrawalListRes(res.data.data);
          } else if (!props.type) {
            setActiveTab("All");
            setAllListRes(res.data.data);
            setAllCountRes(res.data.count);
          }
          setIsLoader(false);
        }
      })
      .catch((er) => {
        setIsLoader(false);
      });
  };

  const callGetDeposits = (props) => {
    setIsLoader(true);
    setDepositCountRes(0);
    setDepositListRes("");
    let data = {
      order: "DESC",
      type: "DEPOSIT",
      page: props.page
    };
    if (props.coins && props.coins !== "") data.coins = props.coins;
    if (props.from && props.from !== "") data.fromDate = props.from;
    if (props.to && props.to !== "") data.toDate = props.to;
    getTransactions(data)
      .then((res) => {
        if (res.status === 200) {
          setDepositCountRes(res.data.count);
          setDepositListRes(res.data.data);
          setIsLoader(false);
        }
      })
      .catch((er) => {
        setIsLoader(false);
      });
  };

  const callGetWithdrawal = (props) => {
    setIsLoader(true);
    setWithdrawalCountRes(0);
    setWithdrawalListRes("");
    let data = {
      order: "DESC",
      type: "WITHDRAWAL",
      page: props.page
    };
    if (props.coins && props.coins !== "") data.coins = props.coins;
    if (props.from && props.from !== "") data.fromDate = props.from;
    if (props.to && props.to !== "") data.toDate = props.to;
    getTransactions(data)
      .then((res) => {
        if (res.status === 200) {
          setWithdrawalCountRes(res.data.count);
          setWithdrawalListRes(res.data.data);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        setIsLoader(false);
      });
  };

  const callGetAllList = (props) => {
    setIsLoader(true);
    setAllListRes("");
    setAllCountRes(0);
    let data = {
      order: "DESC",
      page: props.page
    };
    if (props.coins && props.coins !== "") data.coins = props.coins;
    if (props.from && props.from !== "") data.fromDate = props.from;
    if (props.to && props.to !== "") data.toDate = props.to;
    getTransactions(data)
      .then((res) => {
        if (res.status === 200) {
          setAllListRes(res.data.data);
          setAllCountRes(res.data.count);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        setIsLoader(false);
      });
  };

  const setProviderList = useCallback((props) => setProviderListRes(props || []), []);
  const setBeneficaryList = useCallback((props) => setBeneficaryListRes(props || []), []);

  const resetOnSuccess = () => {
    setPageRes(0);
    setSelectedCoinRes("");
    setSelectedNetworkRes("");
    setSelectedProviderRes("");
    setFrom("");
    setToDate("");
  };

  return (
    <DepositContext.Provider
      value={{
        selectedCoin,
        setSelectedCoin,
        selectedNetwork,
        setSelectedNetwork,
        setisTopModalActive,
        isTopModalActive,
        networkList,
        setNetworkList,
        selectedProvider,
        setSelectedProvider,
        isCardSelected,
        setIsCardSelected,
        providerList,
        setProviderList,
        page,
        setPage,
        activeTab,
        setActiveTab,
        from,
        setFrom,
        to,
        setToDate,
        currencyList,
        setCurrencyList,
        coinChanged,
        callGetDeposits,
        callGetWithdrawal,
        callGetAllList,
        FilterCallApi,
        beneficaryList,
        setBeneficaryList,
        resetValue,
        setMtc,
        mtc,
        resetOnSuccess,
        setFqa,
        fqa,
        isFqaLoading,
        setIsFqaLoading,
        initialDepositLoad,
        setInitialDepositLoad,
        initialAllLoad,
        setInitialAllLoad,
        initialWithdrawalLoad,
        setInitialWithdrawalLoad,
        depositList,
        setDepositList,
        allList,
        setAllList,
        withdrawalList,
        setWithdrawalList,
        depositCount,
        setDepositCount,
        allCount,
        setAllCount,
        withdrawalCount,
        setWithdrawalCount,
        setImportantPoint,
        setLoadingImportantPoint,
        loadingImportantPoint,
        importantPoint,
        setIsLoader,
        isLoader
      }}
    >
      {children}
    </DepositContext.Provider>
  );
};

export default DepositProvider;
