import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TokenInfo from "../../Components/TokenInfo";
// import PrivateSaleDetails from "./Components/PrivateSaleDetails";
// import PrivateBuyTokenBanner from "./Components/PrivateBuyTokenBanner";
import { DivElement, ElementBox } from "../../../../../components/Reusable";
import { GetKycVerified } from "../../../../../components/Alert";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../../../constants/styledConstants";
// import PrivateSteps from "./Components/PrivateSteps";
import BuyTokenBanner from "../../Components/BuyTokenBanner";
import SaleDetails from "../../Components/SaleDetails";
// import Steps from "../Steps";
import PrivateSteps from "./Components/PrivateSteps";

const PrivateTokenSale = () => {
  const [isPrivate, setIsPrivate] = useState(true);
  const [showKycModal, setShowKycModal] = useState(false);
  const [saleDetails, setSaleDetails] = useState("");
  const userData = useSelector((state) => state.dashboard.userData);
  const { width } = useWindowSize();

  useEffect(() => {
    if (userData && userData?.KYC.STATUS !== "LEVEL2") {
      setShowKycModal(true);
    }
  }, [userData]);

  const isDesktop = width > styledConstants.BREAKING_POINT_TABLET;

  return (
    <DivElement pageContainer>
      <DivElement width="100%" justifyContent="center">
        <DivElement
          display="flex"
          flexDirectionMd="column"
          justifyContent="space-between"
          minWidth="1124px"
          minWidthMd="644px"
          minWidthSm="328px"
          displayCenter={isPrivate ? true : null}
        >
          {!isPrivate && width > 1024 && (
            <DivElement
              width="calc(66.66% - 8px)"
              widthMd="100%"
              padding="0"
              marginBottomMd="0px"
              marginBottomSm="16px"
              borderRadiusMd="5px 5px 0 0"
              borderRadiusSm="5px"
              paddingBottomSm="24px"
            >
              <BuyTokenBanner saleDetails={saleDetails} loader={false} />
              {isDesktop && <SaleDetails saleDetails={saleDetails} />}
            </DivElement>
          )}

          {!isPrivate && width < 1024 && (
            <ElementBox paddingMd="24px" paddingSm="8px">
              <SaleDetails saleDetails={saleDetails} />
            </ElementBox>
          )}

          <DivElement width="calc(33.33% - 8px)" widthMd="100%">
            <PrivateSteps
              saleDetails={saleDetails}
              setSaleDetails={setSaleDetails}
              loader={false}
              isPrivate={isPrivate}
              setIsPrivate={setIsPrivate}
            />

            {!isPrivate && isDesktop && <TokenInfo saleDetails={saleDetails} loader={false} />}
          </DivElement>

          {/* {!isPrivate && !isDesktop && (
              <SaleDetails saleDetails={saleDetails} />
            </ElementBox>
          )} */}
        </DivElement>
      </DivElement>

      {showKycModal && (
        <GetKycVerified open={showKycModal} onClose={() => setShowKycModal(false)} />
      )}
    </DivElement>
  );
};

export default PrivateTokenSale;
