import React, { useEffect, useMemo, useState } from "react";
import RegisterStep1 from "./Step1";
import RegisterStep2 from "./Step2";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { useDispatch, useSelector } from "react-redux";
import { showErrorMsg } from "../../../utils/notification";
import { formatAPIErrors } from "../../../utils/general";
import { resetRegisterAuthCode } from "../../../actions/auth";
import SetHeaderTitleComponent from "../../../components/ChangeHeaderTitle";
import { useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "../../../Modules/Generic/selectors";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

function RegisterMain() {
  const [step, setStep] = useState(1);
  const { width } = useWindowSize();
  let authCode = useSelector((state) => state.auth.regisgterOtpCode);
  let registerEmailError = useSelector((state) => state.auth.registerEmailError);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const location = useLocation();
  const { transalationList } = useSelector(GenericReducer);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    setStep(1);

    return () => {
      if (authCode) dispatch(resetRegisterAuthCode());
    };
  }, []);

  useEffect(() => {
    if (authCode && step === 1) setStep(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode]);

  useEffect(() => {
    if (registerEmailError) {
      setStep(1);
      showErrorMsg(formatAPIErrors(registerEmailError));
    }
  }, [registerEmailError]);

  const SEOContents = {
    name: `REGISTER`,
    link: `register`,
    title: "Sign Up | Register | LCX Exchange",
    description: "Sign up with LCX Exchange, and get your first crypto today!",
    keywords: ``,
    canonical: `https://exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/register`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}/register`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: "Sign Up | Register | LCX Exchange",
    OgDescription: "Sign up with LCX Exchange, and get your first crypto today! ",
    OgURL: `https://www.exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/register`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Sign Up | Register | LCX Exchange",
    TwDescription: "Sign up with LCX Exchange, and get your first crypto today! "
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/register`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      {width > 767 ? (
        step === 1 && (
          <RegisterStep1
            setStep={setStep}
            step={step}
            email={email}
            setEmail={setEmail}
            refer={location?.state?.auth}
          />
        )
      ) : (
        <RegisterStep1
          setStep={setStep}
          step={step}
          setEmail={setEmail}
          email={email}
          refer={location?.state?.auth}
        />
      )}
      {step === 2 && width > 767 && <RegisterStep2 email={email} setStep={setStep} step={step} />}
    </>
  );
}

export default RegisterMain;
