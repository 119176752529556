export const AllFaqs1 = [
  {
    title: "How Can I Change My Registered Email ID?",
    link: "https://www.lcx.com/info/faq/account-management/how-can-i-change-my-registered-email-id/"
  },
  {
    title: "Why LCX requires proof of funds ensuring security and compliance?",
    link: "https://www.lcx.com/why-lcx-requires-proof-of-funds-ensuring-security-and-compliance/"
  }
];
export const AllFaqs2 = [
  {
    title: "How Can I Change My Registered Email ID?",
    link: "https://www.lcx.com/info/faq/account-management/how-can-i-change-my-registered-email-id/"
  },
  {
    title: "Why LCX requires proof of funds ensuring security and compliance?",
    link: "https://www.lcx.com/why-lcx-requires-proof-of-funds-ensuring-security-and-compliance/"
  }
];
export const AllFaqs3 = [
  {
    title: "How to Complete Verification on LCX Exchange?",
    link: "https://www.lcx.com/info/faq/kyc-verification/how-to-complete-verification-on-lcx-exchange/"
  },
  {
    title: "What are the documents required for account verification?",
    link: "https://www.lcx.com/info/faq/kyc-verification/what-are-the-documents-required-for-account-verification-kyc/"
  },
  {
    title: "How to Upload My ID Document?",
    link: "https://www.lcx.com/info/faq/kyc-verification/how-to-upload-my-id-document/"
  },
  {
    title: "Why Documents Are Getting Rejected?",
    link: "https://www.lcx.com/info/faq/kyc-verification/why-documents-are-getting-rejected/"
  },
  {
    title: "How long does it take to approve KYC after submitting documents?",
    link: "https://www.lcx.com/info/faq/kyc-verification/how-long-does-it-take-to-approve-kyc-after-submitting-documents/"
  },
  {
    title: "How to Verify Your Selfie",
    link: "https://www.lcx.com/info/faq/kyc-verification/how-to-verify-your-selfie/"
  },
  {
    title: "Why was my selfie not recognized?",
    link: "https://www.lcx.com/info/faq/kyc-verification/why-was-my-selfie-not-recognized/"
  },
  {
    title: "How to Upload My Proof of Address ?",
    link: "https://www.lcx.com/info/faq/kyc-verification/how-to-upload-my-proof-of-address/"
  }
];
