import { useState } from "react";
import { Checkbox, DivElement, InputSwitch, TextWrapper } from "../../../../../components/Reusable";
import { ThreeDotsIcon } from "../../../../../assets/svgs";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderBookConfig } from "../../../../../actions/trading";
import { useRef } from "react";
import { useEffect } from "react";

const CategoryWiseFilter = ({ label, children }) => {
  return (
    <DivElement
      width="100%"
      bdGrey
      borderBottom="1px solid"
      paddingBottom="12px"
      marginBottom="12px"
    >
      <TextWrapper secondary fontSize="12px" fontWeight="500" lineHeight="18px">
        {label}
      </TextWrapper>
      {children}
    </DivElement>
  );
};

const CustomCheckbox = ({ checked, setChecked, label }) => {
  return (
    <DivElement displayBtw marginTop="8px">
      <TextWrapper fontSize="12px" fontWeight="400" lineHeight="18px">
        {label}
      </TextWrapper>
      <Checkbox
        checked={checked}
        setChecked={setChecked}
        containerProps={{ width: "12px", height: "12px", padding: "0" }}
        checkMarkProps={{
          width: "12px",
          height: "12px",
          borderWidth: "1px",
          borderRadius: "2px",
          borderWidthMark: "0 2px 2px 0 !important"
        }}
      />
    </DivElement>
  );
};

const CustomSwitch = ({ checked, setChecked, label }) => {
  return (
    <DivElement displayBtw marginTop="8px">
      <TextWrapper fontSize="12px" fontWeight="400" lineHeight="18px">
        {label}
      </TextWrapper>
      <InputSwitch
        checked={checked}
        setChecked={setChecked}
        isBlue={true}
        containerProps={{ width: "20px", height: "12px" }}
        handleProps={{ width: "12px", height: "12px", top: "1px" }}
        labelProps={{ height: "8px", boxShadow: "none" }}
      />
    </DivElement>
  );
};

const MoreMenu = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const orderBookConfig = useSelector((state) => state.trading.orderBookConfig);
  const dispatch = useDispatch();
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const onChangeHandler = (type, value) => {
    let config = { ...orderBookConfig };
    config[type] = value;
    dispatch(updateOrderBookConfig(config));
  };

  return (
    <DivElement width="20px" height="20px" relative ref={ref}>
      <ThreeDotsIcon
        pointer
        marginLeft="8px"
        width="20px"
        height="20px"
        onClick={() => setShowDropdown(!showDropdown)}
      />
      {showDropdown && (
        <DivElement
          bgCard
          bdGrey
          border="1px solid"
          absolute
          width="155px"
          minHeight="264px"
          padding="8px"
          borderRadius="5px"
          right="0"
          zIndex="5"
        >
          <CategoryWiseFilter label="Display">
            <CustomCheckbox
              label="Display Avg.& Sum"
              checked={orderBookConfig["displayAvgSum"]}
              setChecked={(value) => onChangeHandler("displayAvgSum", value)}
            />
            <CustomCheckbox
              label="Show Buy/Sell Ratio"
              checked={orderBookConfig["buySellRatio"]}
              setChecked={(value) => onChangeHandler("buySellRatio", value)}
            />
          </CategoryWiseFilter>

          <CategoryWiseFilter label="Preferences">
            <CustomSwitch
              label="Cumulative"
              checked={orderBookConfig["cumulative"]}
              setChecked={(e) => onChangeHandler("cumulative", e.target.checked)}
            />
          </CategoryWiseFilter>

          <CategoryWiseFilter label="Data">
            <CustomSwitch
              label="Spread"
              checked={orderBookConfig["spread"]}
              setChecked={(e) => onChangeHandler("spread", e.target.checked)}
            />
          </CategoryWiseFilter>

          <CustomSwitch
            label="Animation"
            checked={orderBookConfig["animation"]}
            setChecked={(e) => onChangeHandler("animation", e.target.checked)}
          />
        </DivElement>
      )}
    </DivElement>
  );
};

export default MoreMenu;
