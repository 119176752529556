import React, { useEffect, useState } from "react";
// import { Field } from "formik";
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";
import InputFieldFormik from "../../../components/Reusable/Field/index";
import { Fragment } from "react";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";
import { ExpandLessIcon, ExpandMoreIcon } from "../../../assets/svgs";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";

const TextInput = ({
  title,
  name,
  value,
  inputProps,
  errorProps,
  placeholder,
  error,
  optional,
  children,
  disabled,
  type,
  paddingRight,
  paddingRightMd,
  expandable = false
}) => {
  const [expand, setExpand] = useState(true);
  const { width } = useWindowSize();

  useEffect(() => {
    if (optional) {
      setExpand(false);
    }
  }, []);

  return (
    <BannerBox mb="32px" width="100%" widthMd="100%" mbMd="24px">
      {title && (
        <Label
          size="14px"
          weight="500"
          sizeMd="12px"
          txtGrey
          display="flex"
          alignItems="center"
          gap="5px"
        >
          {title}{" "}
          {optional && (
            <Fragment>
              <BannerHeadingSpan txtDanger> (optional)</BannerHeadingSpan>
            </Fragment>
          )}
          {expandable && (
            <>
              {expand ? (
                <ExpandLessIcon onClick={() => setExpand(!expand)} />
              ) : (
                <ExpandMoreIcon onClick={() => setExpand(!expand)} />
              )}
            </>
          )}
        </Label>
      )}
      {expand && (
        <BannerBox position="relative" mt="8px">
          <InputFieldFormik
            autocomplete="off"
            type={type || "text"}
            component={type === "textarea" ? type || "text" : null}
            name={name}
            disabled={disabled}
            rows={type === "textarea" ? "1" : null}
            cols={type === "textarea" ? "5" : null}
            value={value}
            style={type === "textarea" ? { resize: "none", height: "54px" } : {}}
            placeholder={placeholder || ""}
            height="44px"
            width="100%"
            AddBenificaryInput={type !== "textarea"}
            TextAreaSpecialKYC={type === "textarea"}
            fontSize={width > 767 ? "14px" : "12px"}
            fontWeight="500"
            BodyColor
            BorderBottomRed={error ? true : null}
            paddingRight={paddingRight || 0}
            paddingRightMd={paddingRightMd || 0}
            padding="5px 8px"
            {...inputProps}
          />
        </BannerBox>
      )}
      {children}
      <ErrorMessageDiv
        name={name}
        component="div"
        mt="3px"
        size="14px"
        sizeMd="12px"
        weight="300"
        txtDanger
        {...errorProps}
      />
    </BannerBox>
  );
};

export default TextInput;
