import React, { useState, useEffect } from "react";
import { ThreeDotsIcon } from "../../../assets/svgs";
import styled, { css } from "styled-components";
import DivElement from "../DivElement";

const ThreeDotOption = styled.li`
  height: 40px;
  font-weight: 500;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: ${(props) => props.justifyContent || ""};
  font-size: ${(props) => props.fontSize || "16px"};
  padding: ${(props) => props.padding || "0 24px"};
  color: ${(props) => props.theme.primaryText};
  margin: ${(props) => props.margin || ""};

  &:hover {
    background-image: ${(props) => props.theme.hoverBg};
    color: ${(props) => props.theme.primaryBlue};
  }

  ${(props) =>
    props.sideBorder &&
    css`
      border-right: 4px solid transparent;

      &:hover {
        border-right: 4px solid ${(props) => props.theme.primaryBlue};
      }
    `}
`;

const Container = styled(DivElement)`
  position: relative;
  cursor: pointer;
  display: ${(props) => (props.display ? props.display : "inline-block")};

  @media (max-width: 1024px) {
    display: ${(props) => (props.displayMd ? props.displayMd : "")};
  }
  @media (max-width: 767px) {
    display: ${(props) => (props.displaySm ? props.displaySm : "")};
  }
`;

const DropdownContainer = styled(DivElement)`
  position: absolute;
  z-index: 100;
  border-radius: 5px;
  background-color: ${(props) => props.theme.popupBg};
  top: ${(props) => (props.top ? props.top : "")};
  right: ${(props) => (props.right ? props.right : "0")};
  bottom: ${(props) => (props.bottom ? props.bottom : "32px")};
  left: ${(props) => (props.left ? props.left : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  outline: 1px solid ${(props) => props.theme.borderColor};
  width: fit-content;
  height: fit-content;

  ${(props) =>
    props.bdGreen &&
    css`
      box-shadow: none;
      outline: 1px solid ${(props) => props.theme.primaryGreen};
    `}

  ${(props) =>
    props.bdRed &&
    css`
      outline: 1px solid ${(props) => props.theme.primaryRed};
    `}

  ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    z-index: 100;
    background-color: ${(props) => props.theme.popupBg};
  }
`;

const ThreeDotDropdown = ({ containerProps, activeContainerProps, children, imgProps, label }) => {
  const [showDropdown, setShowDropDown] = useState(false);
  let dropdown = React.createRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleClickOutside = (event) => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  const handleDropdown = () => {
    setShowDropDown((showDropdown) => {
      return !showDropdown;
    });
  };

  return (
    <Container {...containerProps}>
      <div onClick={handleDropdown} ref={dropdown}>
        <DivElement relative flexAlignCenter borderRadius="5px" display="inline-block">
          {showDropdown && (
            <DropdownContainer {...activeContainerProps}>
              <ul>{children}</ul>
            </DropdownContainer>
          )}
          {label ? label : <ThreeDotsIcon {...imgProps} />}
        </DivElement>
      </div>
    </Container>
  );
};

ThreeDotDropdown.ThreeDotOption = ThreeDotOption;

export default ThreeDotDropdown;
