/* eslint-disable no-console */
/* eslint-disable semi */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { addBeneficiary, getBeneficiarySingle, editBeneficiary } from "../../../lib/api";
import { formatAPIErrors } from "../../../utils/general";
import iban from "iban";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import AddEditAccountLoader from "./ManageLoader/addEditAccountLoader";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { RouterLink, ToolTip } from "../../Reusable";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
} from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import InputFieldFormik from "../../../components/Reusable/Field/index";
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";
import { useSelector } from "react-redux";
// import { useWindowSize } from "../../../Hooks/CheckWidthHeight";

const BeneficiarySchema = Yup.object().shape({
  beneficiaryName: Yup.string().required("Please Enter Description"),
  nameOnAccount: Yup.string().required("Please Enter Account Name"),
  iban: Yup.string().required("Please Enter Iban Number"),
  bankName: Yup.string().required("Please Enter Bank Name"),
  bic: Yup.string().required("Please Enter BIC Number"),
  bankAddress: Yup.string().required("Please Enter Bank Address")
});

// Async validation function
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function AddEditBeneficiary(props) {
  const [isLoader, setIsLoader] = useState(true);
  const [mode, setMode] = useState(null);
  const [userData, setUserData] = useState();
  const apiUserData = useSelector(state => state.dashboard.userData);
  // const { width } = useWindowSize();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    let getId = new URLSearchParams(window.location.search).get("id");
    if (getId) {
      let data = {
        id: Number(getId)
      };
      setIsLoader(true);
      getBeneficiarySingle(data)
        .then((res) => {
          if (res.data.stutus == "SUCCESS") {
            if (res.data.data !== null) {
              setUserData(res.data.data);
              setMode("edit");
              setIsLoader(false);
            } else {
              showErrorMsg("Something Went Wrong", { autoClose: 7000 });
              setIsLoader(false);
              props.history.push(`${prevLang ? `/${prevLang}` : apiUserData.preferredLanguage ? `/${apiUserData.preferredLanguage}` : ""}/wallet/bank_accounts`);
            }
          }
        })
        .catch((err) => {
          showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
          setIsLoader(false);
        });
    } else {
      setMode("");
      setIsLoader(false);
    }
  }, []);

  const getFormikInitVal = (userData) => {
    let fullName = `${props.user.firstName} ${props.user.lastName}`;
    if (mode === "edit") {
      let { beneficiaryName, nameOnAccount, iban, bankName, bic, bankAddress } = userData || {};
      return {
        beneficiaryName,
        nameOnAccount,
        iban,
        bankName,
        bic,
        bankAddress
      };
    } else {
      return {
        beneficiaryName: "",
        nameOnAccount: fullName.toUpperCase(),
        iban: "",
        bankName: "",
        bic: "",
        bankAddress: ""
      };
    }
  };

  // Validate Async IBAN Number
  const validateAsync = (value) => {
    let checkValidNumber = iban.isValid(value);
    return sleep(0).then(() => {
      if (!checkValidNumber) {
        return "IBAN Number is Invalid!";
      }
    });
  };

  const addEditRequest = (values, { setSubmitting, resetForm }) => {
    let checkValidNumber = iban.isValid(values.iban);
    if (!checkValidNumber) {
      showErrorMsg("IBAN Number is Invalid!", { autoClose: 7000 });
      setSubmitting(false);
    } else {
      if (mode === "edit") {
        let params = {
          id: userData.id,
          beneficiaryName: values.beneficiaryName,
          nameOnAccount: values.nameOnAccount,
          iban: values.iban,
          bankName: values.bankName,
          bic: values.bic,
          bankAddress: values.bankAddress
        };
        editBeneficiary(params)
          .then((res) => {
            if (res.data.stutus === "SUCCESS") {
              showSuccessMsg(res.data.message, { autoClose: 7000 });
              props.history.push(`${prevLang ? `/${prevLang}` : apiUserData.preferredLanguage ? `/${apiUserData.preferredLanguage}` : ""}/wallet/bank_accounts`);
              setSubmitting(false);
            }
          })
          .catch((err) => {
            showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
            setSubmitting(false);
          });
      } else {
        let params = {
          beneficiaryName: values.beneficiaryName,
          nameOnAccount: values.nameOnAccount,
          iban: values.iban,
          bankName: values.bankName,
          bic: values.bic,
          bankAddress: values.bankAddress
        };
        addBeneficiary(params)
          .then((res) => {
            if (res.data.stutus === "SUCCESS") {
              showSuccessMsg(res.data.message, { autoClose: 7000 });
              props.history.push(`${prevLang ? `${prevLang}` : apiUserData.preferredLanguage ? `/${apiUserData.preferredLanguage}` : ""}/wallet/bank_accounts`);
              setSubmitting(false);
            }
          })
          .catch((err) => {
            showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
            setSubmitting(false);
          });
      }
    }
  };

  if (isLoader) return <AddEditAccountLoader />;
  return (
    <BannerSection paddMd="20px 0px" width="50%" widthLg="100%">
      <BannerBox
        padding={"0 0px 0px 16px"}
        paddingLg={"0 16px"}
        paddingMd={"0 8px"}
        position="relative"
      >
        <BannerHeading size={"24px"} sizeLg={"20px"} sizeMd={"18px"} mb={"12px"}>
          {mode === "edit" ? "Edit" : "Add"} Bank Account
        </BannerHeading>
      </BannerBox>

      <BannerBox padding={"0 0px 0px 16px"} paddingLg={"0 16px"} paddingMd={"0 8px"}>
        <Formik
          initialValues={getFormikInitVal(userData)}
          validationSchema={BeneficiarySchema}
          onSubmit={addEditRequest}
        >
          {({ isSubmitting, setFieldValue, values, errors }) => (
            <Form>
              <BannerBox display="flex" direction="column">
                <BannerBox mt="5px">
                  <BannerHeading size="14px" sizeMd="12px" BodyColor margin="10px 0px">
                    {" "}
                    Description{" "}
                  </BannerHeading>
                  <InputFieldFormik
                    type="text"
                    name="beneficiaryName"
                    placeholder="Description"
                    height="44px"
                    width="100%"
                    AddBenificaryInput
                    fontSize="14px"
                    fontWeight="500"
                    BodyColor
                  />
                  {errors.beneficiaryName ? (
                    <ErrorMessageDiv
                      name="beneficiaryName"
                      component="div"
                      mt="6px"
                      size="14px"
                      sizemd="12px"
                      weight="300"
                      txtDanger
                    />
                  ) : (
                    <BannerHeadingSpan
                      id="passwordHelpBlock"
                      display="block"
                      txtGrey
                      size="14px"
                      sizemd="12px"
                      weight="300"
                      mt="6px"
                    >
                      Enter a unique name to identify your account.
                    </BannerHeadingSpan>
                  )}
                </BannerBox>

                <BannerBox mt="5px">
                  <BannerHeading size="14px" sizeMd="12px" BodyColor margin="15px 0px">
                    {" "}
                    Name on Account{" "}
                  </BannerHeading>

                  <InputFieldFormik
                    type="text"
                    name="nameOnAccount"
                    placeholder="Enter Account Name"
                    height="44px"
                    width="100%"
                    AddBenificaryInput
                    fontSize="14px"
                    fontWeight="500"
                    BodyColor
                  />
                  <BannerHeadingSpan
                    id="passwordHelpBlock"
                    display="block"
                    txtGrey
                    size="14px"
                    sizemd="12px"
                    weight="300"
                    mt="6px"
                  >
                    The names on your bank account and LCX account must match.
                  </BannerHeadingSpan>

                  <ErrorMessageDiv
                    name="nameOnAccount"
                    component="div"
                    mt="6px"
                    size="14px"
                    sizeMd="12px"
                    weight="300"
                    txtDanger
                  />
                </BannerBox>

                <BannerBox mt="5px">
                  <ToolTip
                    id="withdrawal"
                    title="IBAN"
                    containerProps={{ marginTop: "24px", marginBottom: "8px" }}
                    titleProps={{ fontSizeSm: "12px" }}
                    imgProps={{ width: "20px", height: "20px", top: "0px" }}
                    tipContent="IBAN The International Bank Account Number is an agreed system of identifying bank accounts"
                  />

                  <InputFieldFormik
                    type="text"
                    validate={() => validateAsync(values["iban"])}
                    name="iban"
                    height="44px"
                    width="100%"
                    AddBenificaryInput
                    fontSize="14px"
                    fontWeight="500"
                    BodyColor
                    placeholder="Enter IBAN Number"
                    onChange={(e) => setFieldValue("iban", e.target.value.toUpperCase())}
                  />

                  <ErrorMessageDiv
                    name="iban"
                    component="div"
                    mt="6px"
                    size="14px"
                    sizemd="12px"
                    weight="300"
                    txtDanger
                  />
                </BannerBox>

                <BannerBox mt="5px">
                  <BannerHeading size="14px" sizeMd="12px" BodyColor margin="15px 0px">
                    {" "}
                    Bank Name{" "}
                  </BannerHeading>

                  <InputFieldFormik
                    type="text"
                    name="bankName"
                    placeholder="Enter Bank Name"
                    height="44px"
                    width="100%"
                    AddBenificaryInput
                    fontSize="14px"
                    fontWeight="500"
                    BodyColor
                  />

                  <ErrorMessageDiv
                    name="bankName"
                    component="div"
                    mt="6px"
                    size="14px"
                    sizemd="12px"
                    weight="300"
                    txtDanger
                  />
                </BannerBox>

                <BannerBox mt="5px">
                  <BannerHeading size="14px" sizeMd="12px" BodyColor margin="15px 0px">
                    BIC
                  </BannerHeading>

                  <InputFieldFormik
                    type="text"
                    name="bic"
                    height="44px"
                    width="100%"
                    AddBenificaryInput
                    fontSize="14px"
                    fontWeight="500"
                    placeholder="Enter BIC Number"
                  />

                  <ErrorMessageDiv
                    name="bic"
                    component="div"
                    mt="6px"
                    size="14px"
                    sizemd="12px"
                    weight="300"
                    txtDanger
                  />
                </BannerBox>

                <BannerBox mt="5px">
                  <BannerHeading size="14px" sizeMd="12px" BodyColor margin="15px 0px">
                    {" "}
                    Address{" "}
                  </BannerHeading>

                  <InputFieldFormik
                    type="text"
                    name="bankAddress"
                    placeholder="Enter Bank Address"
                    height="44px"
                    width="100%"
                    AddBenificaryInput
                    fontSize="14px"
                    fontWeight="500"
                  />

                  <BannerHeadingSpan
                    id="passwordHelpBlock"
                    display="block"
                    txtGrey
                    size="14px"
                    sizemd="12px"
                    weight="300"
                    mt="6px"
                  >
                    {"Enter your complete bank address "}
                  </BannerHeadingSpan>

                  <ErrorMessageDiv
                    name="bankAddress"
                    component="div"
                    mt="6px"
                    size="14px"
                    sizemd="12px"
                    weight="300"
                    txtDanger
                  />
                </BannerBox>

                <BannerBox
                  width="100%"
                  mt="2rem"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <RouterLink to="/wallet/bank_accounts" width={"48%"}>
                    <ButtonComponent
                      OutlineDanger
                      disabled={isSubmitting}
                      btnHeight={"48px"}
                      btnHeightMd={"46px"}
                      width={"100%"}
                    >
                      Cancel
                    </ButtonComponent>
                  </RouterLink>

                  <ButtonComponent
                    PrimaryButton
                    disabled={isSubmitting}
                    btnHeight={"48px"}
                    btnHeightMd={"46px"}
                    width={"48%"}
                  >
                    {mode === "edit" ? "Update" : "Save"} bank account
                  </ButtonComponent>
                </BannerBox>
              </BannerBox>
            </Form>
          )}
        </Formik>
      </BannerBox>
    </BannerSection>
  );
}

export default withRouter(AddEditBeneficiary);
