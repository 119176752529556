import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { TableLoader } from "../../Loader";
import { formatNumbers } from "../../../utils/helpers";
import { capitalize } from "lodash";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import FiatTransactionStatus from "./FiatTransactionStatus";
import {
  NoRecord,
  Table,
  Tbody,
  Td,
  TextWrapper,
  Th,
  ThDropdown,
  Thead,
  Tr,
  Pagination,
  ImgElement
} from "../../Reusable";
import config from "../../../constants/config";
import { connect } from "react-redux";
import { noExponents, numberWithCommas } from "../../../utils/general";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";

const TransactionsTable = ({ transactionsList, page, setPage, totalCount, tab, isDarkTheme }) => {
  const [activeCol, setActiveCol] = useState("Amount");
  const [txnDetails, setTxnDetails] = useState("");
  let { width } = useWindowSize();

  useEffect(() => {
    if (width >= 1024 && activeCol !== "Amount") {
      setActiveCol("Amount");
    }
  }, [width]);

  return (
    <Fragment>
      {transactionsList ? (
        <Table bRadiusTop="5px" bRadiusBottom="5px" borderCollapse="collapse">
          <Thead fontSize="14px" fontSizeSm="12px">
            <Tr>
              <Th textAlign="left" paddingLeft="20px" paddingLeftMd="16px" widthMd="33.33%">
                Status
              </Th>
              <Th
                textAlign="left"
                paddingLeft="47px"
                paddingLeftMd="31px"
                paddingLeftSm="22px"
                widthMd="33.33%"
              >
                Asset
              </Th>
              {width > 1025 && (
                <Fragment>
                  <Th textAlign="right" paddingRight="86px">
                    Amount
                  </Th>
                  <Th textAlign="right" paddingRight="32px">
                    Fee
                  </Th>
                  {!tab && (
                    <Th textAlign="left" width="152px" paddingLeft="0">
                      Type
                    </Th>
                  )}
                  <Th textAlign="left">Source</Th>
                  <Th textAlign="right" paddingRight="32px">
                    Transaction Date
                  </Th>
                </Fragment>
              )}
              {width <= 1024 && (
                <ThDropdown
                  OPTIONS={
                    tab
                      ? ["Amount", "Source", "Transaction Date", "Fee"]
                      : ["Amount", "Type", "Source", "Transaction Date", "Fee"]
                  }
                  activeCol={activeCol}
                  selectOption={setActiveCol}
                  textAlign="right"
                  paddingRight="16px"
                />
              )}
            </Tr>
          </Thead>
          <Tbody
            fontSizeMd="14px"
            fontSizeSm="12px"
            verticalAlign="top"
            paddingTop="18px"
            paddingTopMd="14px"
            paddingTopSm="10px"
          >
            {transactionsList &&
              transactionsList.map((data, i) => (
                <Tr
                  key={i}
                  height="64px"
                  heightMd="58.8px"
                  heightSm="55.8px"
                  border={i === transactionsList.length - 1 ? null : true}
                >
                  <Td
                    orange
                    green={
                      (data.txStatus?.toLowerCase().includes("confirm") &&
                        data.txStatus !== "Partially Confirmed") ||
                      data.txStatus?.toLowerCase().includes("approve")
                        ? true
                        : null
                    }
                    red={
                      data.txStatus?.toLowerCase().includes("fail") ||
                      data.txStatus?.toLowerCase().includes("reject")
                        ? true
                        : null
                    }
                    textAlign="left"
                    paddingLeft="20px"
                    paddingLeftMd="16px"
                    paddingLeftSm="5px"
                  >
                    <div
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => setTxnDetails(data)}
                    >
                      {data.txStatus}
                    </div>
                  </Td>
                  <Td textAlign="left">
                    <BannerBox display="flex" alignItems="center">
                      <ImgElement
                        src={`${
                          isDarkTheme ? config.darkCoinUrl : config.lightCoinUrl
                        }${data.coin.toLowerCase()}.svg`}
                        margin="0"
                        width="32px"
                        widthMd="24px"
                        widthSm="16px"
                        height="32px"
                        heightMd="24px"
                        heightSm="16px"
                        marginRight="13.3px"
                        marginRightMd="8px"
                        marginRightSm="6px"
                      />
                      <BannerHeadingSpan>{data.coin}</BannerHeadingSpan>
                    </BannerBox>
                  </Td>

                  {(activeCol === "Amount" || width > 1025) && (
                    <Td
                      textAlign="right"
                      paddingRight={
                        activeCol === "Amount" && width <= 1024
                          ? width < 768
                            ? "5px"
                            : "5px"
                          : "86px"
                      }
                    >
                      <TextWrapper fontSize="inherit">
                        <var>{numberWithCommas(noExponents(formatNumbers(data.amount, 8)))}</var>
                      </TextWrapper>
                      <TextWrapper secondary fontSizeSm="12px" lineHeight="29px">
                        ${data.amountInUSD}
                      </TextWrapper>
                    </Td>
                  )}

                  {(activeCol === "Fee" || width > 1024) && (
                    <Td
                      textAlign="right"
                      paddingRight={activeCol === "Fee" ? (width < 768 ? "5px" : "10px") : "32px"}
                    >
                      {data.userFee ? data.userFee : 0}
                    </Td>
                  )}

                  {!tab && (activeCol === "Type" || width > 1025) && (
                    <Td
                      textAlign={activeCol === "Type" ? "right" : "left"}
                      paddingLeft="0"
                      paddingRight={activeCol === "Type" ? (width < 768 ? "5px" : "10px") : null}
                    >
                      {capitalize(data.txType)}
                    </Td>
                  )}

                  {(activeCol === "Source" || width > 1024) && (
                    <Td
                      textAlign={activeCol === "Source" ? "right" : "left"}
                      paddingRight={activeCol === "Source" ? (width < 768 ? "5px" : "10px") : null}
                    >
                      {data.txSource}
                    </Td>
                  )}

                  {(activeCol === "Transaction Date" || width > 1024) && (
                    <Td
                      textAlign="right"
                      paddingRight={
                        activeCol === "Transaction Date" ? (width < 768 ? "5px" : "10px") : "32px"
                      }
                    >
                      {moment(data.createdAt).format("DD/MM/YY, hh:mm a")}
                    </Td>
                  )}
                </Tr>
              ))}
          </Tbody>
        </Table>
      ) : (
        <TableLoader rows={10} webCol={6} tabletCol={3} mobileCol={3} />
      )}

      {transactionsList && transactionsList.length < 1 && <NoRecord />}

      {transactionsList && transactionsList.length > 1 && (
        <Pagination page={page} setPage={setPage} totalCount={totalCount} />
      )}

      {txnDetails && (
        <FiatTransactionStatus txnDetails={txnDetails} onClose={() => setTxnDetails("")} />
      )}
    </Fragment>
  );
};

const maptoStateProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default connect(maptoStateProps)(TransactionsTable);
