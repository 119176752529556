import styled from "styled-components";
import { TableRowComponent } from "../TableHead.style";

const ManageBankRow = styled(TableRowComponent)`
  th,
  td {
    display: flex;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 18px;
    text-right: right;
    &.symb-padd {
      justify-content: center;
    }
    &.text-right {
      justify-content: flex-end;
    }

    color: ${({ theme }) => theme.bodyText};
    flex: none;
    flex-grow: 0;
    align-items: center;
    box-sizing: border-box;

    &:nth-child(1) {
      flex: 0 0 325px;
      justify-content: flex-start;
      padding-left: 16px !important;
    }

    &:nth-child(2) {
      flex: 0 0 215px;
      justify-content: flex-start;
      padding-left: 8px !important;
    }
    &:nth-child(3) {
      flex: 1 1 310px;
      justify-content: flex-start;
      padding-left: 8px !important;
    }

    &:nth-child(4) {
      flex: 0 0 144px;
      justify-content: center;
      padding-left: 32px !important;
      padding-right: 32px !important;
    }

    &:nth-child(3) {
      padding-left: 8px;
    }

    @media (max-width: 1024px) {
      &:nth-child(1) {
        flex: 0 0 280px;
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
      &:nth-child(2) {
        flex: 1 1 200px;
        justify-content: flex-start;
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
      &:nth-child(3) {
        flex: 0 0 50px;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
    @media (max-width: 767px) {
      &:nth-child(1) {
        flex: 0 0 130px;
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
      &:nth-child(2) {
        flex: 0 0 145px;
        justify-content: flex-start;
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
      &:nth-child(3) {
        flex: 1 1 25px;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
  }
  th {
    line-height: 16px;
    justify-content: center;
    text-transform: capitalize !important;
    cursor: pointer;
    border: none !important;
    font-weight: 500 !important;
    color: ${({ theme }) => theme.tableHeaderColor} !important;
    p,
    h5,
    button,
    span {
      font-weight: 500 !important;
      color: ${({ theme }) => theme.bodyText} !important;
    }
  }
  td {
    font-size: 16px !important;
    @media (max-width: 1024px) {
      font-size: 14px !important;
    }
    @media (max-width: 767px) {
      font-size: 12px !important;
    }
    font-weight: 500;
    h5,
    button {
      flex-grow: 1;
    }

    @media (max-width: 1024px) {
      &:nth-child(3) {
        flex-direction: row;
        justify-content: flex-end;
      }
      &:nth-child(4) {
        align-items: center;
        justify-content: center;
      }
    }
    @media (max-width: 767px) {
      &:nth-child(3) {
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export { ManageBankRow };
