/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form } from "formik";
import { TextInput, PasswordInput } from "../../../Reusable/FormInput";
import { loginUser } from "../../../../actions/auth";
import NonAuthButton from "../NonAuthButton";
// import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import config from "../../../../constants/config";
// import { formatAPIErrors } from "../../../../utils/general";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as Yup from "yup";
// import { showErrorMsg } from "../../../../utils/notification";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { RouterLink } from "../../../Reusable";
import { HeadingSix } from "../../../../assets/StyleComponents/FontSize/HeadingSix.style";

const signInValidation = Yup.object().shape({
  email: Yup.string()
    .email("Email Address must be a valid email")
    .required("Email Address is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must be at most 32 characters")
});

const getInitialValues = () => {
  return {
    email: "",
    password: ""
  };
};

const LoginStep1 = ({ loginUser, googleReCaptchaProps, alert, authLoader }) => {
  const [domainName, setDomainName] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem("ljs-lang");


  useEffect(() => {
    let domain = new URLSearchParams(window.location.search).get("location");
    let url = new URLSearchParams(window.location.search).get("redirectUrl");

    setDomainName(domain);
    setRedirectUrl(url);

    // eslint-disable-next-line
  }, []);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("login");
    return token || undefined;
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const onSubmit = async (value) => {
    // const { executeRecaptcha } = googleReCaptchaProps;
    // if (!executeRecaptcha) {
    //   return;
    // }
    if (authLoader) return;
    let data = {
      email: value.email,
      password: value.password,
      domain: domainName ? domainName : "exchange",
      redirectUrl
    };

    if (config.debug) data.debug = "true";
    if (!executeRecaptcha) {
      return;
    }
    const token = await handleReCaptchaVerify();
    if (!token) return;
    await dispatch(loginUser({ ...data, "g-recaptcha-response": token }));
    // executeRecaptcha("login")
    //   .then((token) => {
    //     loginUser({ ...data, "g-recaptcha-response": token });
    //   })
    //   .catch((err) => {
    //     showErrorMsg(formatAPIErrors(err));
    //   });
  };
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      validateOnMount={true}
      validationSchema={signInValidation}
    >
      {({ values }) => (
        <Form>
          <TextInput
            title="Email Address"
            name="email"
            value={values.email}
            placeholder="satoshi@lcx.com"
          />
          <PasswordInput
            title="Password"
            name="password"
            value={values.password}
            placeholder="**********"
          />

          <BannerBox display="flex" justifyContent="flex-end">
            <RouterLink to={`${prevLang ? `/${prevLang}` : "/en"}/forgotpassword`} id="passreset" txtPrimary underline>
              Forgot password ?
            </RouterLink>
          </BannerBox>

          <NonAuthButton loader={authLoader} label="Log in" />

          <BannerBox display="flex" justifyContent="center" mt="24px">
            <HeadingSix size="14px">{"Don't have an account?"}&nbsp;</HeadingSix>
            <RouterLink to={`${prevLang ? `/${prevLang}` : "/en"}/register`} txtPrimary underline>
              Register
            </RouterLink>
          </BannerBox>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  authLoader: state.auth.authLoader
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (data) => dispatch(loginUser(data))
});

// export default compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   withGoogleReCaptcha
// )(LoginStep1);

export default connect(mapStateToProps, mapDispatchToProps)(LoginStep1);
