import React from "react";
import { DivElement, ModalWrapper, TextWrapper } from "../../../../components/Reusable";
import { CloseIcon, CopyBlueIcon } from "../../../../assets/svgs";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { QRCode } from "react-qrcode-logo";
import { useSelector } from "react-redux";
import { HeadingThird } from "../../../../assets/StyleComponents/FontSize/headingThird.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import CopyToClipboard from "react-copy-to-clipboard";
import { showSuccessMsg } from "../../../../utils/notification";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";

function QrCodeModal({ qrcodeModal, onClose, referalCode, referalLink }) {
  const isTheme = useSelector((state) => state.theme.isTheme);
  const { width } = useWindowSize();
  const onCopy = () => {
    showSuccessMsg("Referral Code copied");
  };

  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <DivElement displayBtw>
          <TextWrapper fontSize="16px" fontSizeSm="16px" lineHeight="24px" fontWeight="500">
            Scan QR Code
          </TextWrapper>
          <CloseIcon pointer onClick={onClose} />
        </DivElement>

        <BannerBox display="flex" alignItems="center" justifyContent="center" direction="column">
          <BannerBox margin="15px auto">
            {qrcodeModal &&
              width &&
              (width > 767 ? (
                <QRCode
                  value={referalLink}
                  size={349}
                  logoHeight={100}
                  logoWidth={100}
                  ecLevel="H"
                  logoImage="https://lcx-exchange.s3.amazonaws.com/coins/dark/svg/lcx.svg"
                  logoOpacity={1}
                  qrStyle="dots"
                  eyeRadius={10}
                  bgColor={isTheme ? "#151e26" : "#FFFFFF"} // Dark or white background
                  fgColor={isTheme ? "#FFFFFF" : "#000000"} // Light or dark foreground
                  id={"QR"}
                  style={{
                    borderRadius: "13px",
                    border: `1px solid ${isTheme ? "#ffffff" : "#CCCED0"}`
                  }}
                />
              ) : (
                <QRCode
                  value={referalLink}
                  size={248}
                  logoHeight={60}
                  logoWidth={60}
                  ecLevel="H"
                  logoImage="https://lcx-exchange.s3.amazonaws.com/coins/dark/svg/lcx.svg"
                  logoOpacity={1}
                  qrStyle="dots"
                  eyeRadius={10}
                  bgColor={isTheme ? "#151e26" : "#FFFFFF"} // Dark or white background
                  fgColor={isTheme ? "#FFFFFF" : "#000000"} // Light or dark foreground
                  id={"QR"}
                  style={{
                    borderRadius: "13px",
                    border: `1px solid ${isTheme ? "#ffffff" : "#CCCED0"}`
                  }}
                />
              ))}
          </BannerBox>

          <BannerBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            direction="column"
            gap="8px"
          >
            <BannerHeading size="16px" weight="500" txtGrey>
              Referral Code:
            </BannerHeading>
            <CopyToClipboard text={referalCode} onCopy={onCopy}>
              <HeadingThird size="24px" weight="500" BodyColor textAlign="center" cursor="pointer">
                {referalCode} <CopyBlueIcon />
              </HeadingThird>
            </CopyToClipboard>
          </BannerBox>
        </BannerBox>
      </DivElement>
    </ModalWrapper>
  );
}

export default QrCodeModal;
