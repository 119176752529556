import { checkMillion, checkThousand, numberWithCommas } from "../../utils/general";

function NumberWithCommaComponent({ millionStatus, thousandStatus, value }) {
  let result = "";
  if(millionStatus) result = numberWithCommas(checkMillion(value || "0"));
  else if(!millionStatus && thousandStatus) result = numberWithCommas(checkThousand(value || "0"));
  else result = numberWithCommas(value || "0");
  return result;
}

export default NumberWithCommaComponent;