import { connect } from "react-redux";
import SettingPage from "../views/settingPages";
import { getMarketLists } from "../actions/marketLists";
import { selectedPair, tradeSetting, resetTradeTable, cancelTradeSetting } from "../actions/trade";
import { getUserDetails } from "../actions/dashboard";
import {
  setup2FA,
  generate2AuthToken,
  changePassword,
  clearUserErrors,
  getFeesList,
  updateFeeList
} from "../actions/setting";
import { logout } from "../actions/auth";
import { getCountriesList } from "../actions/kyc";

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  isThemeFecthing: state.theme.isThemeFetching,
  isMarketLists: state.market.isMarketLists,
  isMarketListFetching: state.market.isMarketListFetching,
  selectedPairName: state.trade.selectedPair,
  isSelectedPairFecthing: state.trade.isSelectedPairFecthing,
  isTradeSetting: state.trade.isTradeSetting,
  isFetchingTradeSetting: state.trade.isFetchingTradeSetting,
  isCancelData: state.trade.isCancelData,
  isFetchingCancelTradeData: state.trade.isFetchingCancelTradeData,
  apiData: state.setting.apiData,
  apiLoader: state.setting.apiLoader,
  user: state.dashboard.getUserData,
  loader: state.setting.apiLoader,
  error: state.setting.error,
  twoAuthToken: state.setting.twoAuthToken,
  changePasswordSuccess: state.setting.changePasswordSuccess,
  feeListData: state.setting.feeListData,
  isFetchingFeeListData: state.setting.isFetchingFeeListData,
  isLoggedIn: state.auth.isLoggedIn,
  userData: state.dashboard.getUserData,
  isWholeLoading: state.dashboard.isWholeLoading,
  isLogoutFetching: state.dashboard.isLogoutFetching,
  marketErrorGet: state.market.errorGet,
  tradeErrorGet: state.trade.errorGet,
  dashErrorGet: state.dashboard.errorGet,
  settingErrorGet: state.setting.errorGet,
  countriesList: state.kyc.countriesList
});

const mapDispatchToProps = (dispatch) => ({
  getMarketLists: (user) => dispatch(getMarketLists(user)),
  selectedPair: (user) => dispatch(selectedPair(user)),
  tradeSetting: (status) => dispatch(tradeSetting(status)),
  resetTradeTable: (status) => dispatch(resetTradeTable(status)),
  cancelTradeSetting: (status) => dispatch(cancelTradeSetting(status)),
  getUserDetails: () => dispatch(getUserDetails()),
  setup2FA: (user) => dispatch(setup2FA(user)),
  generate2AuthToken: () => dispatch(generate2AuthToken()),
  changePassword: (user) => dispatch(changePassword(user)),
  clearUserErrors: () => dispatch(clearUserErrors()),
  updateFeeList: (params) => dispatch(updateFeeList(params)),
  getFeesList: () => dispatch(getFeesList()),
  logoutUser: () => dispatch(logout()),
  getListOfCountries: () => dispatch(getCountriesList())
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
