/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, lazy, Suspense, useState, useContext, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { createStructuredSelector } from "reselect";

import { trackPage } from "../../tracking/track";
import { BannerBox, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import IntroPart from "./introPart";
import ActiveTraderPart from "./ActiveTraderPart";
import BannerSectionMain from "../NewIndex/BannerSection";
import MarketHOC from "../marketPage/MarketHOC";
import { DivElement } from "../../components/Reusable";
import { getLocalStorage } from "../../utils/helpers";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";
import PermotionAll from "./Permotion/PermotionAll";

// import MarqueeComponent from "./subHeader";
const MarketTableComponent = lazy(() => import("../../components/MarketTable/marketTable"));
const IndexFooter = lazy(() => import("../../components/Footer/footer"));

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const LandingPageComponent = (props) => {
  const { isTheme, isDarkTheme } = props;
  const location = useLocation();
  const { transalationList } = useSelector(GenericReducer);

  let prevLang = getLocalStorage("ljs-lang");

  useEffect(() => {
    let page = location.pathname + location.search;
    trackPage(page);
  }, []);
  
  const SEOContents = {
    name: "",
    link: "",
    title: "Buy, Sell & Trade Bitcoin and Altcoins | Cryptocurrency Exchange",
    description:
      "LCX Exchange: Your secure, fast, and reliable crypto exchange platform for trading Bitcoin, Ethereum, and 75+ listings with 24/7 live support.",
    keywords: `crypto exchange, uk regulated crypto exchanges, crypto exchange regulation, crypto exchanges regulated in uk, regulated exchanges crypto, regulated crypto exchanges uk, crypto asset exchange, cryptoassets exchange, european crypto exchange, crypto exchange with lowest fees`,
    canonical: `https://exchange.lcx.com/`,
    canonicalUrl: "https://exchange.lcx.com",
    afterLink: `/${prevLang || ""}`,
    lang: `/${prevLang || ""}`,
    OgTitle: "Buy, Sell & Trade Bitcoin, and Altcoins  | Cryptocurrency Exchange - LCX",
    OgDescription:
      "LCX Exchange: Your secure, fast, and reliable crypto exchange platform for trading Bitcoin, Ethereum, and 75+ listings with 24/7 live support.",
    OgURL: "https://www.exchange.lcx.com",
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Buy, Sell & Trade Bitcoin, and Altcoins | Cryptocurrency Exchange - LCX",
    TwDescription:
      "LCX Exchange: Your secure, fast, and reliable crypto exchange platform for trading Bitcoin, Ethereum, and 75+ listings with 24/7 live support."
  };

  let memorizedList = useMemo(() => {
    if(transalationList && transalationList.length > 0){
      return (
        transalationList.filter((data) => data.code !== (prevLang || "en"))
      );
    }
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}`
      };
      return obj;
    });
  }

  // const handleOnClickMarquee = () => {
  //   window.open("https://www.lcx.com/cardano-summit-dubai/");
  // };

  return (
    <main style={{ background: !isTheme ? "white" : "#0a0d12" }}>
      <SetHeaderTitleComponent Content={SEOContents} />
      
      <BannerSectionMain promotion={false} />
      
      <PermotionAll />

      <BannerSection
        MainPageSectionBg
        position={"relative"}
        zIndex={0}
        paddTop="128px"
        paddBottom="24px"
        paddBottomLg="15px"
        paddTopLg="64px"
        paddTopMd="75px"
      >
        <BannerBox>
          <BannerBox width={"100%"} position={"absolute"} bottom="0">
            {isDarkTheme ? (
              <DivElement>
                <video
                  autoPlay
                  loop
                  muted
                  preload="metadata"
                  type="video/mp4"
                  style={{ width: "100%", height: "601px", objectFit: "cover" }}
                  src={"https://lcx-exchange.s3.amazonaws.com/landing_banner/darkbglanding.mp4"}
                  playsInline
                  webkit-playsinline
                ></video>
              </DivElement>
            ) : (
              <DivElement>
                <video
                  autoPlay
                  loop
                  muted
                  preload="metadata"
                  type="video/mp4"
                  style={{ width: "100%", height: "601px", objectFit: "cover" }}
                  src={"https://lcx-exchange.s3.amazonaws.com/landing_banner/lightbglanding.mp4"}
                  playsInline
                  webkit-playsinline
                ></video>
              </DivElement>
            )}
          </BannerBox>

          <BannerSection
            zIndex="2"
            left="0.3%"
            margin="0 auto"
            ExchangemarketWidth
            position="relative"
            BorderTop="0px"
          >
            <Suspense fallback={null}>
              <MarketTableComponent />
            </Suspense>
          </BannerSection>
        </BannerBox>
      </BannerSection>
      <BannerSection
        paddTop="128px"
        paddBottom="64px"
        paddTopLg="120px"
        paddBottomLg="60px"
        paddTopMd="118px"
        paddBottomMd="52px"
      >
        <IntroPart isTheme={isTheme} />
      </BannerSection>

      <BannerSection
        paddTop="64px"
        paddBottom="64px"
        paddTopLg="60px"
        paddBottomLg="60px"
        paddTopMd="52px"
        paddBottomMd="52px"
      >
        <ActiveTraderPart {...props} />
      </BannerSection>
      <BannerSection paddTop="64px" paddTopLg="60px" paddTopMd="52px">
        <Suspense fallback={null}>
          <IndexFooter {...props} />
        </Suspense>
      </BannerSection>
    </main>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  isTheme: state.theme.isTheme
});

export default connect(mapStateToProps)(MarketHOC(LandingPageComponent));
