import styled, { css } from "styled-components";

export const KycBioIdConatiner = styled.div`
  ${(props) =>
    props.KYCBioId &&
    css`
      width: 100%;
      font-weight: 500;
      padding: 40px;
      color: ${(props) => props.theme.bodyText};

      .bioid_instruction {
        font-size: 16px;
        font-weight: 300;
        height: fit-content;
        margin-top: 0;
        margin-bottom: 32px;
        color: ${(props) => props.theme.bodyText} ! !important;
      }

      /* Extra small devices (portrait phones, less than 740px) */
      @media (max-width: 575.98px) and (max-height: 739.98px) {
        .bioid_navigation {
          top: 60px;
          left: 0;
          width: 60px;
        }

        .bioid_canvasview {
          max-width: 580px;
          max-height: 380px;

          @media (max-width: 767px) {
            min-width: 100%;
            max-height: 370px;
          }
        }

        .bioid_instruction {
          margin-top: 30px;
          font-size: 1.4rem;
          height: 50px;
        }

        .bioid_promt {
          font-size: 1.3rem;
        }

        .bioid_uploadstatus-single {
          display: none;
        }

        .bioid_col-right-border {
          border: none;
        }

        .bioid_col-8 {
          flex: 0 0 85%;
          max-width: 85%;
        }

        .bioid_intro-title {
          margin-top: 30px;
        }

        .bioid_demo-video {
          max-width: 250px;
        }

        .bioid_abortbutton {
          left: 20px;
        }

        .bioid_logo {
          width: 11rem;
        }

        .bioid_header {
          height: 4rem;
          box-shadow: 0 1rem 0 0 rgb(111, 118, 127);
        }
      }

      /* Extra small devices (portrait phones, less than 576px) */
      @media (max-width: 575.98px) and (min-height: 740px) {
        .bioid_navigation {
          top: 10px;
          left: 0;
          width: 60px;
        }

        .bioid_instruction {
          margin-top: 30px;
          margin-bottom: 30px;
          font-size: 16px;
          height: 50px;
        }

        .bioid_promt {
          font-size: 1.3rem;
        }

        .bioid_uploadstatus-single {
          display: none;
        }

        .bioid_col-right-border {
          border: none;
        }

        .bioid_col-8 {
          flex: 0 0 85%;
          max-width: 85%;
        }

        .bioid_intro-title {
          margin-top: 30px;
        }

        .bioid_float-right {
          margin: 0;
          float: none;
        }

        .bioid_float-left {
          margin: 0;
          float: none;
        }

        .bioid_demo-video {
          max-width: 3500px;
        }

        .bioid_abortbutton {
          left: 20px;
        }

        .bioid_logo {
          width: 11rem;
        }

        .bioid_header {
          height: 4rem;
          box-shadow: 0 1rem 0 0 rgb(111, 118, 127);
        }
      }

      /* Small devices (landscape phones, 576px and up) */
      @media (min-width: 576px) {
        .bioid_navigation {
          top: 10px;
          left: 10px;
          width: 60px;
        }

        .bioid_uploadstatus-single {
          display: none;
        }

        .bioid_col-right-border {
          border: none;
        }

        .bioid_col-8 {
          flex: 0 0 85%;
          max-width: 85%;
        }

        .bioid_demo-video {
          max-width: 400px;
        }

        .bioid_intro-title {
          margin-top: 30px;
        }

        .bioid_instruction {
          font-size: 1.6rem;
          margin-top: 30px;
          // height: 80px;
        }

        .bioid_abortbutton {
          top: 30px;
          left: 30px;
        }

        .bioid_logo {
          width: 15rem;
        }

        .bioid_header {
          height: 5rem;
          box-shadow: 0 1.5rem 0 0 rgb(111, 118, 127);
        }
      }

      /* Medium devices (tablets, 767px and up) */
      @media (min-width: 745px) {
        .bioid_navigation {
          top: 40px;
          left: 35px;
          width: 120px;
        }

        .bioid_uploadstatus-single {
          display: none;
        }

        .bioid_col-right-border {
          border: none;
        }

        .bioid_col-8 {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      @media (max-width: 896px) {
        .bioid_float-left,
        .bioid_float-right {
          margin: 0 auto;
        }
      }

      @media (min-width: 897px) {
        .bioid_col-right-border {
          border-right: 1px solid black;
        }
      }

      /* Large devices (desktops, 992px and up) */
      @media (min-width: 992px) {
        .bioid_uploadstatus-single {
          display: block;
        }

        .bioid_float-left {
          float: left !important;
          margin-left: 80px;
        }

        .bioid_float-right {
          float: right !important;
          margin-right: 80px;
        }
      }

      .bioid_logo {
        position: absolute;
        top: 0;
        left: 0;
      }

      .bioid_header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        padding: 0;
        background-color: rgb(48, 55, 66);
        z-index: 3;
      }

      .bioid_main {
        position: absolute;
        box-sizing: border-box;
        top: 3rem;
        right: 0;
        left: 0;
        width: 100%;
      }

      .bioid_abortbutton {
        position: absolute;
      }

      .bioid_red {
        color: #dc3545;
        font-weight: 600;
      }

      .bioid_blue {
        color: #3a61a0;
        font-weight: 600;
      }

      .bioid_uppercase {
        text-transform: uppercase;
      }

      .bioid_button {
        border: none;
        border-radius: 12px;
        padding: 10px 32px;
        color: white;
        background-color: #58b999;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        cursor: pointer;
      }

      .bioid_button:hover {
        background-color: #46967d;
      }

      .bioid_button:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(165, 206, 192, 1);
      }

      .bioid_button:disabled {
        background-color: gray;
        color: lightgray;
        cursor: default;
      }

      .bioid_button-accept {
        width: 300px;
      }

      .bioid_button-ok {
        border: 1px solid #be0000;
        background-color: white;
        color: #be0000;
        padding: 10px 70px;
        margin: 2px 30px;
      }

      .bioid_button-ok:hover {
        background-color: #e0e0e0;
      }

      .bioid_button-ok:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(190, 0, 0, 0.5);
      }

      .bioid_button-info {
        display: none;
        background: rgb(52, 152, 219);
        margin: 10px 10px 10px 0;
        float: left;
      }

      .bioid_button-info:hover {
        background: rgb(60, 176, 253);
      }

      .bioid_button-info:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(165, 206, 192, 0.5);
      }

      a .bioid_button-back {
        width: 18px;
        height: auto;
        vertical-align: middle;
      }

      .bioid_navigation {
        position: absolute;
        top: 200px;
        left: 300px;
        z-index: 2;
      }

      .bioid_hidden {
        display: none;
      }

      .bioid_title {
        margin-top: 1.5rem;
        text-align: center;
        color: white;
        font-size: 1.4rem;
      }

      .bioid_prompt {
        font-size: 1rem;
      }

      .bioid_webapp {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .bioid_instruction {
        z-index: 1;
        font-weight: 600;
      }

      .bioid_liveview {
        display: block;
        z-index: -2;
      }

      .bioid_live {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: auto;
        z-index: -2;
      }

      .bioid_canvasview {
        position: relative;
        max-width: 580px;
        max-height: 380px;
        opacity: 1;
        z-index: -1;
        border-radius: 10px;
        -webkit-transform: scaleX(-1);
        @media (max-width: 768px) {
          min-width: 100%;
          max-height: 370px;
        }
      }

      .bioid_modal {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.3rem;
        background-color: #ffffff;
        color: #dc3545;
        padding: 50px;
        opacity: 0.8;
      }

      .bioid_modal-footer {
        margin-top: 2rem;
      }

      .bioid_head {
        position: relative;
        text-align: center;
        opacity: 0.5;
        z-index: 1;
      }

      .bioid_stats {
        display: block;
        position: fixed;
        bottom: 20px;
        left: 10px;
        color: black;
        font-size: 1.2rem;
        text-align: center;
        z-index: 1;
      }

      .bioid_uploadstatus-single {
        position: fixed;
        bottom: 0;
        right: 0;
        color: black;
        text-align: right;
      }

      .bioid_uploadstatus-compact {
        position: fixed;
        bottom: 5px;
        left: 5px;
        right: 5px;
      }

      .bioid_image {
        display: none;
        box-sizing: border-box;
        border: 1px solid rgb(44, 100, 162);
        float: right;
        margin: 4px;
        width: 90px;
        height: 120px;
        margin-bottom: 25px;
        opacity: 0.4;
      }

      .bioid_image-uploaded {
        text-align: center;
        width: 90px;
        height: 120px;
      }

      .bioid_progress-single {
        display: none;
        margin-top: 5px;
        width: 90px;
        background-color: #e9ecef;
        border-radius: 0.25rem;
      }

      .bioid_progress-compact {
        display: none;
        width: 100%;
        background-color: #e9ecef;
        border-radius: 0.25rem;
      }

      .bioid_progressbar {
        display: block;
        height: 10px;
        width: 0;
        background-color: #007bff;
        border-radius: 0.25rem;
      }

      .bioid_transparent-background {
        background-color: rgb(255, 255, 255);
        background-color: rgba(255, 255, 255, 0.9);
      }

      .bioid_alert-danger {
        color: rgb(255, 80, 80);
      }

      .bioid_intro-title {
        font-size: 2.4rem;
        font-weight: 700;
        color: #3a61a0;
        text-transform: uppercase;
        text-align: center;
      }

      .bioid_intro-subtitle {
        font-size: 2.4rem;
        font-weight: 700;
        color: #3a61a0;
        text-transform: uppercase;
        text-align: center;
      }

      .bioid_intro-description {
        font-size: 1.6rem;
        font-weight: 500;
        color: #3a61a0;
        text-align: center;
      }

      .bioid_intro-attention {
        font-size: 1rem;
        text-align: center;
      }

      .bioid_image-wrong {
        font-size: 1rem;
        text-align: center;
        color: red;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .bioid_image-right {
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        color: #3a61a0;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .bioid_intro-skip {
        font-size: 1.6rem;
        text-align: center;
        line-height: 1.6rem;
      }

      input.bioid_intro-checkbox {
        width: 20px;
        height: 20px;
      }

      .bioid_row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: 0;
        margin-left: 0;
      }

      .bioid_col {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }

      .bioid_col-fix-size {
        max-width: 590px;
      }

      .bioid_col-8 {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
      }

      .bioid_justify-content-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
      }

      .bioid_checkbox {
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .bioid_center {
        text-align: center;
      }

      .bioid_mt-3 {
        margin-top: 1rem !important;
      }

      .bioid_mt-9 {
        margin-top: 3rem !important;
      }

      .bioid_mb-3 {
        margin-bottom: 1rem !important;
      }

      .bioid_ml-3 {
        margin-left: 1rem !important;
      }

      .bioid_ml-5 {
        margin-left: 2rem !important;
      }

      .bioid_m-3 {
        margin: 1rem !important;
      }

      .bioid_mb-5 {
        margin-bottom: 2rem !important;
      }

      .bioid_checkbox input {
        vertical-align: middle;
      }

      .bioid_checkbox span {
        vertical-align: middle;
        font-size: 0.9rem;
        padding-left: 10px;
      }

      .bioid_action-title {
        color: #3a61a0;
        font-weight: 500;
      }

      .bioid_spinner {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .bioid_spinner:before,
      .bioid_spinner:after {
        content: "";
        position: absolute;
        border-width: 4px;
        border-style: solid;
        border-radius: 50%;
      }

      .bioid_spinner-wait:before {
        top: 40px;
        left: 20px;
        width: 40px;
        height: 40px;
        border-color: rgb(33, 33, 33);
        animation: scale 1.5s linear 0s infinite alternate;
      }

      .bioid_spinner-wait:after {
        top: 40px;
        left: 20px;
        width: 40px;
        height: 40px;
        border-color: rgb(33, 33, 33);
        animation: scale 1.5s linear 0s infinite alternate-reverse;
      }

      .bioid_spinner-upload:before {
        top: 40px;
        left: 20px;
        width: 40px;
        height: 40px;
        border-bottom-color: rgb(33, 33, 33);
        border-right-color: rgb(33, 33, 33);
        border-top-color: rgb(33, 33, 33);
        border-top-color: rgba(33, 33, 33, 0);
        border-left-color: rgb(33, 33, 33);
        border-left-color: rgba(33, 33, 33, 0);
        animation: rotate-animation 1s linear 0s infinite;
      }

      .bioid_spinner-upload:after {
        top: 50px;
        left: 30px;
        width: 20px;
        height: 20px;
        border-bottom-color: rgb(33, 33, 33);
        border-right-color: rgb(33, 33, 33);
        border-top-color: rgb(33, 33, 33);
        border-top-color: rgba(33, 33, 33, 0);
        border-left-color: rgb(33, 33, 33);
        border-left-color: rgba(33, 33, 33, 0);
        animation: anti-rotate-animation 0.85s linear 0s infinite;
      }

      @keyframes scale {
        0% {
          transform: scale(1);
          border-style: solid;
        }

        100% {
          transform: scale(0);
          border-style: dashed;
        }
      }

      @keyframes rotate-animation {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes anti-rotate-animation {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(-360deg);
        }
      }
    `}
`;
