import React, { useState } from "react";
import ModalWrapper from "../../../../components/Reusable/ModalWrapper";
import { DivElement } from "../../../../components/Reusable";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { cancelReportRequest } from "../../../../lib/api";
import { debounce } from "../../../../utils/helpers";
import { CloseIcon } from "../../../../assets/svgs";
import { DeleteBoxIllustration } from "../../../../assets/svgs/illustrations";

const CancelReportsModal = ({ modal, closemodal, id }) => {
  const [timer, setTimer] = useState(false);

  const cancelReportRequestFunc = debounce(() => {
    if (id?.length > 1 && modal && !timer) {
      const data = { Id: id };
      cancelReportRequest(data)
        .then((res) => {
          showSuccessMsg(res.data.message);
          closemodal();
          setTimer(true);
        })
        .catch((e) => showErrorMsg(e.response?.data?.message));
    }
    setTimer(false);
  }, 800);

  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <BannerBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="26px"
          mbMd="16px"
        >
          <BannerHeading size="20px" weight="500" sizeMd="16px">
            Cancel Report
          </BannerHeading>
          <BannerHeadingSpan onClick={() => closemodal()} cursorPointer>
            <CloseIcon className="img-fluid" />
          </BannerHeadingSpan>
        </BannerBox>

        <BannerBox display="flex" alignItems="center" direction="column">
          <DeleteBoxIllustration width={"25%"} />
          <BannerHeading size="14px" weight="500" mt="24px" mb="24px" mbMd="16px" mtMd="16px">
            Are you sure you want to cancel generating this report? Any progress will be lost.
          </BannerHeading>
        </BannerBox>

        <BannerBox display="flex" justifyContent="space-between" alignItems="center">
          <ButtonComponent
            OutlinePrimary
            width={"193px"}
            widthLg={"172px"}
            widthMd={"140px"}
            btnHeight={"48px"}
            size={"14px"}
            onClick={() => closemodal()}
          >
            Go Back
          </ButtonComponent>
          {!timer ? (
            <ButtonComponent
              OutlinePrimary
              width={"193px"}
              widthLg={"172px"}
              widthMd={"140px"}
              btnHeight={"48px"}
              size={"14px"}
              onClick={cancelReportRequestFunc}
            >
              Continue
            </ButtonComponent>
          ) : (
            <ButtonComponent
              DisabledBTN
              width={"193px"}
              widthLg={"172px"}
              widthMd={"140px"}
              btnHeight={"48px"}
              size={"14px"}
              onClick={cancelReportRequestFunc}
            >
              Continue
            </ButtonComponent>
          )}
        </BannerBox>
      </DivElement>
    </ModalWrapper>
  );
};

export default CancelReportsModal;
