/* eslint-disable */
import { Fragment, useState, useEffect } from "react";
import NoRecord from "../../../components/Reusable/NoRecord";
import { Pagination } from "../../../components/Reusable";
import { TableLoader } from "../../../components/Loader";
import moment from "moment";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { formatNumbers } from "../../../utils/helpers";
import { formatAPIErrors, numberWithCommas } from "../../../utils/general";
import { getReferralAmountGained } from "../../../lib/api";
import {
  SpanElement,
  TextWrapper,
  DivElement,
  ThDropdown,
  SearchBar,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td
} from "../../../components/Reusable";
import { showErrorMsg } from "../../../utils/notification";
import { ReferralNotFoundIcon, UpDownIcon } from "../../../assets/svgs";
import FilterDetailsEarning from "../../../components/Modules/Referral/FilterDetailsEarning";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";

const DetailedEarningsTab = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [referralData, setRefferalData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ascending, setAscending] = useState(true);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [page, setPage] = useState(1);
  const [activeCol, setActiveCol] = useState("Coin Earned");
  const { width } = useWindowSize();

  useEffect(() => {
    getReferralData();
    // eslint-disable-next-line
  }, [page]);

  const getReferralData = () => {
    setIsLoading(true);
    // let data = {
    //   Offset: page,
    //   Detailed: true
    // };
    let data = appliedFilters;
    data.Offset = page;
    data.Detailed = true;
    getReferralAmountGained(data)
      .then((res) => {
        setRefferalData(res && res.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onApplyFilterHandler = (givenFilter) => {
    setIsLoading(true);

    setAppliedFilters(givenFilter);
    getReferralAmountGained(givenFilter)
      .then((res) => {
        setRefferalData(res && res.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BannerBox
      CardWhite
      padding="40px"
      paddingLg="40.5px 30px 65px 30px"
      paddingMd="24px"
      radius="8px"
    >
      <BannerBox
        display="flex"
        justifyContent="space-between"
        directionMd="column"
        width="100%"
        alignItems="center"
        alignItemsMd="flex-start"
        mb="32px"
        gapMd="20px"
      >
        <BannerHeading size="24px" weight="500" sizeLg="20px" sizeMd="16px">
          Referral History
        </BannerHeading>

        <FilterDetailsEarning onApplyFilterHandler={onApplyFilterHandler} />
      </BannerBox>

      <DivElement minHeight="300px">
        {!isLoading && (
          <Table bRadiusTop="5px" bRadiusBottom="5px">
            <Thead fontSize="14px" fontSizeMd="12px" paddingTopMd="8px" verticalAlignMd="top">
              <Tr height="42px">
                <Th
                  width="179px"
                  widthMd="137px"
                  widthSm="88px"
                  textAlign="left"
                  paddingLeft="23px"
                  paddingLeftMd="15px"
                  paddingLeftSm="12px"
                >
                  User ID
                </Th>
                <Th textAlign="left" textAlignMd="center">
                  <DivElement display="inline-flex" alignItems="center">
                    <DivElement widthMd="85px">
                      Trading Date{" "}
                      <SpanElement fontSize="12px" fontSizeMd="10px">
                        (DD/MM/YY, 24h)
                      </SpanElement>
                    </DivElement>

                    <UpDownIcon
                      width="19px"
                      height="19px"
                      pointer
                      onClick={() => setAscending(!ascending)}
                    />
                  </DivElement>
                </Th>
                {width >= 768 && (
                  <Fragment>
                    <Th textAlign="right">Asset Earned</Th>
                    <Th
                      width="358px"
                      widthMd="188px"
                      textAlign="right"
                      paddingRight="32px"
                      paddingRightMd="20px"
                    >
                      Value
                    </Th>
                  </Fragment>
                )}
                {width < 768 && (
                  <ThDropdown
                    textAlign="right"
                    OPTIONS={["Coin Earned", "Value"]}
                    activeCol={activeCol}
                    selectOption={setActiveCol}
                    paddingRightSm="0"
                  />
                )}
              </Tr>
            </Thead>
            <Tbody fontSize="16px" fontSizeMd="14px" fontSizeSm="12px">
              {referralData &&
                referralData.data &&
                referralData.data
                  .sort((a, b) =>
                    ascending
                      ? a.CreatedAt.valueOf() - b.CreatedAt.valueOf()
                      : b.CreatedAt.valueOf() - a.CreatedAt.valueOf()
                  )
                  .map(
                    (item, i) =>
                      ((searchQuery &&
                        item.Tsource.toUpperCase().includes(searchQuery.toUpperCase())) ||
                        !searchQuery) && (
                        <Tr key={i} height="56px">
                          <Td textAlign="left" paddingLeft="23px" paddingLeftMd="15px">
                            {item.Tsource || ""}
                          </Td>
                          <Td textAlign="left" textAlignMd="center">
                            <TextWrapper fontSize="16px" fontSizeMd="14px" fontSizeSm="12px">
                              {moment(item.CreatedAt * 1000).format("DD/MM/YY")}
                            </TextWrapper>
                            <TextWrapper fontSize="14px" fontSizeMd="12px" secondary>
                              {moment(item.CreatedAt * 1000).format("HH:mm")}
                            </TextWrapper>
                          </Td>
                          {(activeCol === "Coin Earned" || width >= 768) && (
                            <Td
                              textAlign="right"
                              paddingRightSm={activeCol === "Coin Earned" ? "10px" : null}
                            >
                              <TextWrapper fontSize="16px" fontSizeMd="14px" fontSizeSm="12px">
                                {numberWithCommas(item.Amount || 0)}
                              </TextWrapper>
                              <TextWrapper fontSize="14px" fontSizeMd="12px" secondary>
                                {item.Coin || ""}
                              </TextWrapper>
                            </Td>
                          )}
                          {(activeCol === "Value" || width >= 768) && (
                            <Td
                              textAlign="right"
                              paddingRight="32px"
                              paddingRightMd="20px"
                              paddingRightSm={activeCol === "Value" ? "10px" : null}
                            >
                              <span style={{ marginRight: "2px" }}>$</span>
                              {formatNumbers(item.AmountUsd)}
                            </Td>
                          )}
                        </Tr>
                      )
                  )}
            </Tbody>
          </Table>
        )}

        {!isLoading && referralData && referralData?.data?.length === 0 && (
          <BannerBox display="flex" alignItems="center" justifyContent="center" mtMd="20px">
            <ReferralNotFoundIcon
              height={"180px"}
              width={"160px"}
              heightMd={"120px"}
              widthMd={"120px"}
              widthSm={"80px"}
              heightSm={"60px"}
            />
          </BannerBox>
        )}

        {isLoading && <TableLoader rows={5} webCol={4} tabletCol={4} mobileCol={3} />}
      </DivElement>
      {referralData && referralData.data && (
        <DivElement width="100%">
          <Pagination
            page={page}
            totalCount={referralData.count || 0}
            setPage={setPage}
            itemPerPage={5}
            marginTop="47px"
            marginTopMd="36px"
            marginTopSm="32px"
          />
        </DivElement>
      )}
    </BannerBox>
  );
};

export default DetailedEarningsTab;
