import React, { useEffect } from "react";
import Lottie from "lottie-web";
// import successAnimation from "./successful.json";
import rewardsBoxAnimation from "./rewardsBoxAnimation.json";

const BoxAnimation = ({ type, width }) => {
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector(`#animation-${type}`),
      animationData: rewardsBoxAnimation,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: rewardsBoxAnimation
    });
  }, [type]);

  return <div id={`animation-${type}`} style={{ width: width ? width : "100%" }} />;
};

export default BoxAnimation;
