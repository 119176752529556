/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { BannerBox, BannerSection } from "../../../../../assets/StyleComponents/Exchange.style";
import { Label, ParaTag } from "../../../../../assets/StyleComponents/FontSize/para.style";
import { BannerHeadingSpan } from "../../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../../assets/StyleComponents/FontSize/Heading.style";
import { ButtonComponent } from "../../../../../assets/StyleComponents/Button.style";
import {
  BankIcon,
  BankWhiteIcon,
  CableIcon,
  CableWhiteIcon,
  CameraBlueIcon,
  ChevronLeftIcon,
  ElectricityBIllIcon,
  ElectricityBIllWhiteIcon,
  OtherUtilityIcon,
  OtherUtilityWhiteIcon,
  UploadBlueIcon
} from "../../../../../assets/svgs";
import { ImgElement } from "../../../../Reusable";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import WebCamCapture from "../../WebCamCapture";
import { dataURLtoFile } from "../../../../../utils";
import { residentialProof } from "../../../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../../../utils/notification";
import { useDispatch } from "react-redux";
import { formatAPIErrors } from "../../../../../utils/general";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SubmittedModal from "../../SubmittedModal";
import { connect } from "react-redux";
import { getUserDetails } from "../../../../../actions/dashboard";

function ResidenceProof({ userData }) {
  const [uploaded, setUploaded] = useState(false);
  const [webcamStatus, setWebcamStatus] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [showVerficationPlus, setShowVerficationPlus] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [addressProof, setAddressProof] = useState("");
  const [selectType, setSelectType] = useState("");
  const [documentType, setDocumentType] = useState("pdf");
  const [img, setImg] = useState({});
  const [capturedImg, setCapturedImg] = useState("");

  const fileInputRef = useRef(null);
  const { width } = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem("ljs-lang");

  const handleOnChange = (e) => {
    setSelectType(e.target.value);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
    imageSrc?.length > 1 && setUploaded(true);
  };

  // useEffect(() => {
  //   function CheckStatus() {
  //     getUserDetails()
  //       .then((res) => {
  //         // const LVL = res.data?.data?.KYC?.STATUS;
  //         const LVL2Status = res.data?.data?.KYC?.LEVEL2?.status;
  //         const UserHighRisk = res.data?.data?.KYC?.isHighRiskCountry;
  //         setLvl2Status(LVL2Status);
  //       })
  //       .catch((err) => {
  //       });
  //   }

  //   CheckStatus();
  // }, [uploaded]);

  // const Lvl2Check = userData.KYC.LEVEL2.status;

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setFileName(file.name);
  //   }
  // };

  const Options = [
    {
      name: "Bank Statement",
      value: "bankStatement",
      icon: <BankIcon width="20px" height="20px" />,
      iconWhite: <BankWhiteIcon width="20px" height="20px" />
    },
    {
      name: "Electricity Bill",
      value: "electricityBill",
      icon: <ElectricityBIllIcon width="20px" height="20px" />,
      iconWhite: <ElectricityBIllWhiteIcon width="20px" height="20px" />
    },
    {
      name: "Cable Bill",
      value: "cableBill",
      icon: <CableIcon width="20px" height="20px" />,
      iconWhite: <CableWhiteIcon width="20px" height="20px" />
    },
    {
      name: "Other Utility Bill",
      value: "otherUtilityBill",
      icon: <OtherUtilityIcon width="20px" height="20px" />,
      iconWhite: <OtherUtilityWhiteIcon width="20px" height="20px" />
    }
  ];

  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImageSrc(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const onUpload = async (img) => {
    let imgUrl = URL.createObjectURL(img.target.files[0]);
    if (
      img.target.files[0].name.includes("jpg") ||
      img.target.files[0].name.includes("png") ||
      img.target.files[0].name.includes("jpeg") ||
      img.target.files[0].name.includes("pdf")
    ) {
      setDocumentType(img.target.files[0].name.includes("pdf") ? "pdf" : "image");
      setImg(img.target.files[0]);
      setImageSrc(imgUrl);
      setUploaded(true);
      setAddressProof(imgUrl);
    } else {
      showErrorMsg("Invalid file format");
    }
  };

  const onCapture = () => {
    let image = dataURLtoFile(capturedImg, "selfie.jpeg");
    setDocumentType("image");
    setImg(image);
    setImageSrc(capturedImg);
    setCapturedImg(image);
    setUploaded(true);
    setAddressProof(capturedImg);
  };

  const callUserApi = () => {
    dispatch(getUserDetails());
  };

  const submitData = () => {
    setUploaded(true);
    residentialProof(img, "ADDRESSPROOF", selectType, (progress) => {})
      .then((res) => {
        // if (userData?.KYC?.isHighRiskCountry) setShowVerficationPlus(true);
        if (!userData?.KYC?.isHighRiskCountry) setSuccessStatus(true);
        showSuccessMsg("Address proof uploaded successfully");
        callUserApi();
        setUploaded(false);
      })
      .catch((error) => {
        setUploaded(false);
        showErrorMsg(formatAPIErrors(error));
      });
  };

  useEffect(() => {
    if (userData?.KYC?.isHighRiskCountry) {
      if (
        (userData.KYC.STATUS === "LEVEL0" || userData.KYC.STATUS === "LEVEL1") &&
        (userData.KYC.LEVEL2.status === "PARTIALLY_SUBMITTED" ||
          userData.KYC.LEVEL2.status === "APPROVED")
      ) {
        setShowVerficationPlus(true);
      }
    } else {
      if (
        (userData.KYC.STATUS === "LEVEL0" ||
          userData.KYC.STATUS === "LEVEL1" ||
          userData.KYC.STATUS === "LEVEL2") &&
        (userData.KYC.LEVEL2.status === "SUBMITTED" || userData.KYC.LEVEL2.status === "APPROVED")
      ) {
        setSuccessStatus(true);
      }
    }
  }, [userData]);

  const onClose = () => {
    setSuccessStatus(false);
    // dispatch(getUserDetails());
    history.push(`/${prevLang ? `${prevLang}` : userData.preferredLanguage ? `${userData.preferredLanguage}` : ""}`);
  };

  const onCaptureAgain = () => {
    if (uploaded) {
      return;
    }
    const inputs = document.querySelectorAll(".kycsteps__uploader__circle input");
    inputs.forEach((input) => {
      input.value = "";
    });
    setAddressProof("");
    setImageSrc("");
    setImg("");
  };

  useEffect(() => {
    if (showVerficationPlus) history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/verify/verification-plus`);
  }, [showVerficationPlus]);

  return (
    <>
      {!webcamStatus && !uploaded && (
        <BannerBox width="100%">
          <Label txtGrey size="14px">
            Document for Residence Verification
          </Label>
          <BannerBox
            InputSelectKyc
            mt="8px"
            display="flex"
            direction="column"
            gap="24px"
            minHeightMd="50vh"
          >
            {Options.map((data) => {
              return (
                <>
                  <BannerBox
                    display="flex"
                    justifyContent="space-between"
                    border="1px solid #8C9797"
                    radius="5px"
                    padding="12px 16px"
                    PrimaryBorderCard={selectType === data.name}
                    onClick={() => setSelectType(data.name)}
                    key={data.name}
                  >
                    <BannerBox display="flex" gap="8px" alignItems="center">
                      <BannerHeadingSpan
                        width="32px"
                        height="32px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        BorderRadius="50%"
                        RadioBtnIconBg
                        RadioBtnIconBgSelected={selectType === data.name}
                      >
                        {selectType !== data.name ? data.icon : data.iconWhite}
                      </BannerHeadingSpan>
                      <BannerHeading size="14px" weight="500">
                        {data.name}
                      </BannerHeading>
                    </BannerBox>
                    <input
                      type="radio"
                      name="verification"
                      id={data.name}
                      value={data.name}
                      checked={selectType === data.name}
                      onChange={handleOnChange}
                      style={{ accentColor: "#706F6F" }}
                    />
                  </BannerBox>
                </>
              );
            })}
          </BannerBox>

          <BannerBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="32px"
            directionMd="column"
            gapMd="20px"
          >
            <ButtonComponent
              height="48px"
              width="48%"
              widthMd="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="8px"
              position="relative"
              OutlinePrimaryWithoutHover
              cursor={selectType ? "pointer" : "not-allowed"}
              disabled={!selectType}
              onClick={() => {
                if (!selectType) return;
                handleButtonClick();
              }}
            >
              <input
                type="file"
                // accept="image/*"
                onChange={onUpload}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <UploadBlueIcon />
              Upload Attachment
            </ButtonComponent>

            {/* {width > 767 && ( */}
            <ButtonComponent
              height="48px"
              width="48%"
              widthMd="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="8px"
              OutlinePrimaryWithoutHover
              cursor={selectType ? "pointer" : "not-allowed"}
              disabled={!selectType}
              onClick={() => {
                if (!selectType) return;
                setWebcamStatus("webcam");
              }}
            >
              <CameraBlueIcon />
              Take photo using webcam
            </ButtonComponent>
            {/* )} */}
          </BannerBox>
        </BannerBox>
      )}
      {uploaded && (
        <BannerSection>
          {width > 767 && (
            <BannerBox display="flex" gap="4px">
              <ButtonComponent bck="transparent" onClick={() => setUploaded(false)}>
                <ChevronLeftIcon />
              </ButtonComponent>
              <BannerBox>
                <BannerHeading size="18px" weight="500">
                  {selectType}
                </BannerHeading>
                <ParaTag size="14px" weight="500" mt="6px">
                  The statement date must be within 3 months.
                </ParaTag>
              </BannerBox>
            </BannerBox>
          )}
          {width < 767 && (
            <ParaTag size="14px" weight="500" mt="6px">
              <BannerHeadingSpan txtDanger>Note </BannerHeadingSpan> - The statement date must be
              within 3 months.
            </ParaTag>
          )}
          <BannerBox
            radius
            BufferArea
            height="304px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            // width="524px"
            margin="32px auto"
          >
            {imageSrc &&
              (documentType === "pdf" ? (
                <iframe
                  style={{ width: "94%", height: "272px" }}
                  src={encodeURI(addressProof)}
                  title="Address proof document"
                  allowFullScreen
                  allow="fullscreen"
                ></iframe>
              ) : (
                <ImgElement
                  src={imageSrc}
                  alt="Uploaded"
                  style={{ maxWidth: "96%", height: "272px" }}
                />
              ))}
          </BannerBox>
          <BannerBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="32px"
            directionMd="column"
            gapMd="24px"
          >
            <ButtonComponent
              height="48px"
              width="48%"
              widthMd="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="8px"
              position="relative"
              OutlinePrimaryWithoutHover
              onClick={() => {
                setUploaded(false);
                setImageSrc("");
                setCapturedImg("");
                onCaptureAgain();
              }}
            >
              Re-upload Image
            </ButtonComponent>

            <ButtonComponent
              height="48px"
              width="48%"
              widthMd="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="8px"
              cursor={!selectType || !addressProof ? "not-allowed" : "pointer"}
              onClick={() => {
                if (selectType.length > 0) {
                  submitData();
                } else {
                  setUploaded(false);
                  showErrorMsg("Sorry, Your Attachment was not retrieved.");
                }
              }}
              disabled={!selectType || !addressProof}
              // loader={}
              // OutlinePrimary
            >
              Submit
            </ButtonComponent>
            {/* <KycStepButton
              onClick={submitData}
              disabled={!selectType || !addressProof}
              loader={uploaded}
              label="Submit"
            /> */}
          </BannerBox>
        </BannerSection>
      )}

      {/* {successStatus && <SubmittedModal onClose={onClose} />} */}

      {/* {successStatus && userData?.KYC?.isHighRiskCountry && history.push("/setting/profile")} */}
      {/* {showVerficationPlus && history.push("/verify/verification-plus")} */}

      {webcamStatus && (
        <WebCamCapture
          documentName={"capturedImg"}
          setCapturedImg={setCapturedImg}
          captureImages={onCapture}
          setOpenWebcam={setWebcamStatus}
          kycStep="STEP4"
          type="residence"
          setStepDocUpload={setUploaded}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData
});

export default connect(mapStateToProps)(ResidenceProof);
