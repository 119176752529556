import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { useSelector } from "react-redux";
import { checkPairName } from "../../../utils/trade";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";

const OverviewText = () => {
  let location = useLocation();
  let selectedPairName = useSelector((state) => state.trade.selectedPair);
  const prevLang = localStorage.getItem("ljs-lang");

  const [selectedPairActive, setSelectedPairActive] = useState("");

  useEffect(() => {
    if (location.pathname.includes("/trade") && location.pathname !== "/order/trade-history") {
      let pairActive =
        checkPairName(selectedPairName || { base_pair: "LCX", quote_pair: "EUR" }, location) ||
        "LCX/EUR";
      setSelectedPairActive("/" + pairActive.split("/").join("-"));
    }
  }, [location]);

  return (
    <>
      <BannerBox display="flex" direction="column" width="100%">
        <BannerHeading size="20px" sizeLg="16px" weight="bold" BodyColor>
          The LCX Token ($LCX) is the fuel of the LCX.com blockchain platform and LCX Cryptocurrency
          Exchange.
        </BannerHeading>

        <BannerHeading size="16px" sizeLg="14px" weight="bold" BodyColor mt="20px" lineHeight="1.7">
          LCX Token works as a long-term sustainable incentive mechanism to motivate various
          stakeholders to participate in the ecosystem.
        </BannerHeading>

        <BannerHeading size="16px" sizeLg="14px" BodyColor mt="20px" weight="300" lineHeight="1.7">
          The LCX Token is an exchange based token and may be used to pay all fees associated with
          the services offered by LCX AG. The LCX Token can be used as a voucher to pay fees, such
          as fees for custodian solutions LCX Vault; fees for exchange transactions for all crypto
          assets; exchange fees for fiat- crypto-fiat transactions; processing fees; and other fees
          within the LCX ecosystem.
        </BannerHeading>

        <BannerHeading size="16px" sizeLg="14px" BodyColor mt="20px" weight="300" lineHeight="1.7">
          The LCX Token has been issued by LCX AG in full compliance with applicable laws and
          regulations in Liechtenstein. As per legal assessment performed by qualified legal firms,
          LCX Token can be legally classified as a utility token according to the US, Singapore,
          European and Liechtenstein law.
        </BannerHeading>

        <BannerHeading size="16px" sizeLg="14px" BodyColor mt="20px" weight="300" lineHeight="1.7">
          LCX Token Smart Contract: 0x037a54aab062628c9bbae1fdb1583c195585fe41; Ethereum ERC20
          utility token standard $LCX Token at Etherscan; Coingecko; CoinMarketCap; Coinbase Price
          Charts
        </BannerHeading>
      </BannerBox>

      <BannerBox
        CardLevel2
        padding="27px 78px 27px 38px"
        paddingLg="24px"
        margin="1.2rem 0px"
        borderRadius="10px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        alignItemsLg="start"
        directionLg="column"
        gapLg="20px"
      >
        <BannerHeading BannerHeading size="20px" sizeLg="16px" weight="bold" BodyColor>
          You can buy the LCX Token directly at LCX.com
        </BannerHeading>
        <Link to={`${prevLang ? `/${prevLang}`: "/en"}/trade${selectedPairActive}`}>
          <ButtonComponent
            PrimaryButton
            width="168px"
            btnHeight="48px"
            givenMargnMd="16px auto auto auto"
          >
            Buy LCX
          </ButtonComponent>
        </Link>
      </BannerBox>
    </>
  );
};

export default OverviewText;
