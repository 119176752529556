import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import * as THREE from "three";
import $ from "jquery";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import headObj from "./head.obj";
import { Button } from "../../Reusable";
import { KycBioIdConatiner } from "../../../assets/StyleComponents/kycBioId.style";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";

class Bioid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstImage: null,
      secondImage: null,
      bwsCapture: null,
      active: false,
      cancel: false
    };
  }

  componentDidMount = () => {
    // BEGIN OF CONFIGURATION
    var token = "test";
    var returnURL = "https://accounts-v2.lcx.com/";
    var state = "test";
    var apiurl = "https://bws.bioid.com/extension/";
    var task = "livenessdetection";
    var trait = "Face";
    // var executions = "3";
    var recordings = "2";
    var threshold = "30";
    var challengeResponse = false;
    var challenges = [];
    var skipIntro = true;
    // END OF CONFIGURATION

    // BWS capture jQuery plugin
    var bwsCapture = null;

    // counter for current execution
    var currentExecution = 0;

    var currentTag = "";
    var parentTag = "";

    // enrollment without challenge response
    var enrollmentTags = ["left", "right", "right", "left", "up", "down", "down", "up"];

    // map for the compact upload
    var progressMap = new Map();

    // localized messages (english defaults, might get overloaded in initialize())
    var localizedData = {
      titleEnrollment: "Enrollment",
      titleVerification: "Verification",
      titleIdentification: "Identification",
      titleLiveDetection: "Liveness Detection",

      introductionTitle: "How it works",
      introductionStep1: "Step 1",
      introductionStep1Desc1: "Before you start",
      introductionStep1Desc2: "come closer to the camera",
      introductionTooFarAway: "Too far away!",
      introductionTooClose: "Too close!",
      introductionPerfect: "Perfect!",
      introductionStep2: "Step 2",
      introductionStep2Desc1: "For Liveness Detection",
      introductionStep2Desc2: "nod your head slightly",
      introductionDontMoveDevice: "Please don't move the device!",
      introductionDontShowAgain: "GOT IT! Don't show the instruction again",

      buttonCancel: "back",
      "buttonCancel.title": "Abort and navigate back to caller",
      buttonReadyToStart: "I'm ready to start",
      buttonContinue: "Skip biometrics",
      buttonMobileApp: "Start BioID app",

      prompt:
        "It seems like we don't have access to the camera. For performing biometric operations, please try the following:<br/>- Grant access to the camera.<br/>- Use a different browser (e.g. the most recent versions of Opera, Firefox, Chrome or Edge).",
      mobileapp:
        "If you have installed the BioID App on your mobile device, you can use this app for enrollment or verification.",

      uploadInfo: "Uploading...",

      "capture-error":
        "We could not capture an image.<br />Sorry, but without access to the camera, facial recognition and liveness detection aren't possible!",
      nogetUserMedia:
        "Your browser does not support the HTML5 Media Capture and Streams API. Please use a different browser or the BioID mobile app.",
      permissionDenied: "Permission Denied!",
      "webgl-error":
        "WebGL is disabled or unavailable. If possible activate WebGL or use another browser.",

      "UserInstruction-CloseUp":
        "Before you start, make sure your face is fully enclosed in circle",
      "UserInstruction-NodYourHead": "Please nod your head",
      "UserInstruction-FollowMe": "Follow the blue head",
      "UserInstruction-NoMovement": "Follow the head's movement",
      "UserInstruction-PleaseWait": "Please wait",

      "Perform-enrollment": "Training...",
      "Perform-verification": "Verifying...",
      "Perform-identification": "Identifying...",
      "Perform-livenessdetection": "Processing...",

      NoMotionDetected:
        "We could not detect any motion.<br/>For Liveness Detection please nod your head slightly.",
      NoFaceFound:
        "We could not find a suitable face.<br/>Come close and look straight before you start.",
      MultipleFacesFound:
        "We found multiple faces or a strongly uneven background distracted us.<br/>Your face should fill the circle completely.",
      LiveDetectionFailed:
        "Liveness Detection failed.<br/>Look straight into the camera, then nod your head slightly.<br/>Please ensure constant lighting.",
      ChallengeResponseFailed: "Challenge-Response failed!<br/>Slowly follow the head's movement.",
      NotRecognized:
        "You have not been recognized!<br/>Please ensure constant lighting. For improving recognition, please enroll again."
    };

    /* ----------------- Set button functionality ------------------------------------------*/

    $(() => {
      initialize();

      // set navigation for the buttons
      $("#uuicancel").attr(
        "href",
        returnURL + "?error=user_abort&access_token=" + token + "&state=" + state
      );
      $("#uuiskip").attr(
        "href",
        returnURL + "?error=user_skip&access_token=" + token + "&state=" + state
      );

      // set url for the BioID mobile app
      if (task === "verification") {
        $("#uuimobileapp").attr(
          "href",
          "bioid-verify://?access_token=" + token + "&return_url=" + returnURL + "&state=" + state
        );
      } else if (task === "enrollment") {
        $("#uuimobileapp").attr(
          "href",
          "bioid-enroll://?access_token=" + token + "&return_url=" + returnURL + "&state=" + state
        );
      }

      $("#uuiinstruction").attr("data-res", "UserInstruction-CloseUp");

      // hide button after first click
      $("#uuimobileapp").click(function () {
        $("#uuimobileapp").hide();
      });

      // Check if cookie is set
      // let skipCookie = false;
      // var cookie = document.cookie;
      // if (cookie != "") {
      //    skipCookie = cookie.includes('true');
      // }

      if (skipIntro /* || skipCookie */) {
        showIntroduction(false);
      } else {
        showIntroduction(true);
      }
    });

    const showIntroduction = (show) => {
      if (show) {
        $("#uuiintroduction").show();
      } else {
        $("#uuiintroduction").hide();
        $("#uuiwepapp").show();
        initCapture();

        let checked = $("#introskip").prop("checked");
        if (checked) {
          skipIntro = true;
        }
      }
    };

    const initCapture = () => {
      // init BWS capture jQuery plugin (see bws.capture.js)
      bwsCapture = window.bws.initcapture(
        document.getElementById("uuicanvas"),
        document.getElementById("livevideo"),
        token,
        {
          apiurl: apiurl,
          task: task,
          trait: trait,
          threshold: threshold,
          challengeResponse: challengeResponse,
          recordings: recordings
        }
      );
      this.setState({ bwsCapture });
      let success = initHead();
      if (success) {
        onStart();
      } else {
        $("#uuierror").html(formatText("webgl-error"));
        $("#uuiskip").show();
      }
    };

    // called from Start button and onStart to initiate a new recording
    function startRecording() {
      $("#uuistart").attr("disabled", "disabled");
      var tags =
        challengeResponse &&
        challenges.length > currentExecution &&
        challenges[currentExecution].length > 0
          ? challenges[currentExecution]
          : [];
      bwsCapture.startRecording(tags);
    }

    // called from Mirror button to mirror the captured image
    // function mirror() {
    //   bwsCapture.mirror();
    // }

    /* ---------------- Localization of strings ----------------------------------------------*/

    // localization of displayed strings
    function localize() {
      // loops through all HTML elements that must be localized.
      let resourceElements = $("[data-res]");
      for (let i = 0; i < resourceElements.length; i++) {
        let element = resourceElements[i];
        let resourceKey = $(element).attr("data-res");
        if (resourceKey) {
          // Get all the resources that start with the key.
          for (let key in localizedData) {
            if (key.indexOf(resourceKey) === 0) {
              let value = localizedData[key];
              // Dot notation in resource key - assign the resource value to the elements property
              if (key.indexOf(".") > -1) {
                let attrKey = key.substring(key.indexOf(".") + 1);
                $(element).attr(attrKey, value);
              }
              // No dot notation in resource key, assign the resource value to the element's innerHTML.
              else if (key === resourceKey) {
                $(element).html(value);
              }
            }
          }
        }
      }
    }

    // localization and string formatting (additional arguments replace {0}, {1}, etc. in localizedData[key])
    function formatText(key) {
      var formatted = key;
      if (localizedData[key] !== undefined) {
        formatted = localizedData[key];
      }
      for (let i = 1; i < arguments.length; i++) {
        formatted = formatted.replace("{" + (i - 1) + "}", arguments[i]);
      }
      return formatted;
    }

    /* ----------------- Initialize BWS capture jQuery plugin --------------------------------*/

    // initialize - load content in specific language and initialize bws capture
    function initialize() {
      // change title if task is enrollment
      if (task === "enrollment") {
        $("#uuititle").attr("data-res", "titleEnrollment");
      }
      // change title if task is identification
      else if (task === "identification") {
        $("#uuititle").attr("data-res", "titleIdentification");
      } else if (task === "livenessdetection") {
        $("#uuititle").attr("data-res", "titleLiveDetection");
      }

      // try to get language info from the browser.
      let userLangAttribute =
        navigator.language || navigator.userLanguage || navigator.browserLanguage || "en";
      let userLang = userLangAttribute.slice(0, 2);
      // let userLocation = userLangAttribute.slice(-2) || 'us';

      $.getJSON("./language/" + userLang + ".json")
        .done(function (data) {
          localizedData = data;
        })
        .fail(function (textStatus, error) {})
        .always(function () {
          localize();
        });
    }

    /* ------------------ Start BWS capture jQuery plugin -----------------------------------*/

    // startup code
    const onStart = () => {
      bwsCapture.start(
        function () {
          captureStarted();
          $("#uuicanvas").show();
        },
        function (error) {
          // hide uuiwebapp
          $("#uuiwebapp").hide();

          // show default information about general issues
          $("#uuisplash").show();

          // show button for continue without biometrics (skip biometric task)
          $("#uuiskip").show();
          // show button for BioID app (interapp communication)
          if (task === "verification" || task === "enrollment") {
            $("#uuimobileapp").show();
          }
          if (error !== undefined) {
            // different browsers use different errors
            if (error.code === 1 || error.name === "PermissionDeniedError") {
              // in the spec we find code == 1 and name == PermissionDeniedError for the permission denied error
              $("#uuierror").html(formatText("capture-error", formatText("PermissionDenied")));
            } else {
              // otherwise try to print the error
              $("#uuierror").html(formatText("capture-error", error));
            }
          } else {
            // no error info typically says that browser doesn't support getUserMedia
            $("#uuierror").html(formatText("nogetUserMedia"));
          }
        },
        (error, retry) => {
          stopRecording();
          currentExecution++;
        },
        (status, message, dataURL) => {
          let $msg;
          if (status === "UploadProgress") {
            // for single upload status
            let id = message.id;
            let modId = ((id - 1) % 4) + 1;
            // for compact upload status
            let progresscompact = 0;
            progressMap.set(id, message.progress);
            progressMap.forEach(function (value) {
              return (progresscompact += value);
            });
            progresscompact = Math.ceil(progresscompact / recordings);

            if (progresscompact > 100) {
              progresscompact = 100;
            }

            // css media query decision
            if ($("#uuisingleupload").is(":visible") === true) {
              $("#uuiprogress" + modId).show();
              $("#uuiprogressbar" + modId).width(message.progress + "%");
              // if the window size changed
              $("#uuiprogresscompact").hide();
            } else {
              $("#uuiprogresscompact").show();
              $("#uuiprogressbarcompact").width(progresscompact + "%");
            }
          } else if (status === "DisplayTag") {
            setCurrentTag(message);
            $msg = $("#uuiinstruction");
            if (challengeResponse || task === "enrollment") {
              $msg.html(formatText("UserInstruction-FollowMe"));
            } else {
              $msg.html(formatText("UserInstruction-NodYourHead"));
            }
            $msg.stop(true).fadeIn();
          } else {
            // report a message on the screen
            let msg = formatText(status);

            // user instructions
            if (status.indexOf("UserInstruction") > -1) {
              $msg = $("#uuiinstruction");
              if (status === "UserInstruction-Start") {
                let counter = recordings;
                if (counter > 4) {
                  counter = 4;
                }
                for (let i = 1; i <= counter; i++) {
                  $("#uuiuploaded" + i).hide();
                  $("#uuiupload" + i).hide();
                  $("#uuiwait" + i).show();
                  $("#uuiimage" + i).show();
                  $("#uuiprogress" + i).hide();
                  $("#uuiprogressbar" + i).width(0);
                }
                progressMap.clear();
                $("#uuiprogresscompact").hide();
                $("#uuiprogressbarcompact").width(0);
                resetHeadDisplay();
              } else {
                $msg.html(msg);
                $msg.stop(true).fadeIn();
              }
            }

            // perform tasks
            if (status.indexOf("Perform") > -1 || status.indexOf("Retry") > -1) {
              // hide compact upload progress
              $("#uuiprogresscompact").hide();
            }

            // results of uploading or perform task
            if (
              status.indexOf("Failed") > -1 ||
              status.indexOf("NotRecognized") > -1 ||
              status.indexOf("NoFaceFound") > -1 ||
              status.indexOf("MultipleFacesFound") > -1
            ) {
              changeLiveView(true);

              // show message
              $("#uuiinstruction").text("");
              $("#uuistatus").show();
              $msg = $("#uuimessage");
              $msg.html(formatText(msg));
              $msg.stop(true).fadeIn();
            }

            // display some animations/images depending on the status
            let uploaded = bwsCapture.getUploaded();
            let recording = uploaded + bwsCapture.getUploading();
            // use modulo calculation for images more than 4
            let modRecording = ((recording - 1) % 4) + 1;
            let modUploaded = ((uploaded - 1) % 4) + 1;

            if (status === "Uploading") {
              // begin an upload - current image
              $("#uuiwait" + modRecording).hide();
              $("#uuiupload" + modRecording).show();
              $("#uuiuploaded" + modRecording).hide();

              // if uuiuploaded is not visible -> mobile view
              if (recording >= recordings) {
                $("#uuiinstruction").html(formatText("UserInstruction-PleaseWait"));
                changeLiveView(true);
              }
            } else if (status === "Uploaded") {
              if (!this.state.firstImage) {
                this.setState({ firstImage: dataURL });
              } else {
                bwsCapture.stop();
                this.props.bioid_completed(this.state.firstImage, dataURL);
              }
              // successfull upload (we should have a dataURL)
              if (dataURL) {
                $("#uuiupload" + modUploaded).hide();
                $("#uuiprogress" + modUploaded).hide();
                let $image = $("#uuiuploaded" + modUploaded);
                $image.attr("src", dataURL);
                $image.show();
              }
            } else if (status === "NoFaceFound" || status === "MultipleFacesFound") {
              // upload failed
              recording++;
              modRecording = ((recording - 1) % 4) + 1;
              $("#uuiupload" + modRecording).hide();
              $("#uuiwait" + modRecording).show();
            }
          }
        }
      );
    };

    // switch between liveview and displayed messages
    function changeLiveView(blur) {
      if (blur) {
        // hide head and blur canvas
        hideHead();
        $("#uuicanvas").css("filter", "none");
        $("#uuiprogresscompact").hide();
      } else {
        $("#uuicanvas").css("filter", "none");
        showHead();
      }
    }

    // called by onStart to update GUI
    function captureStarted() {
      $("#uuiwebapp").show();
      $("#uuimessage").show();
      $("#uuiinstruction").show();

      // Currently not neccessary - therefore the button is not shown!
      // $('#uuimirror').show().click(mirror);

      $("#uuistart")
        .show()
        .click(function () {
          startRecording(task === "enrollment");
        });

      $("#uuiok")
        .show()
        .click(function () {
          $("#uuistatus").hide();
          $("#uuistart").prop("disabled", false);
          $("#uuiinstruction").html(formatText("UserInstruction-CloseUp"));
          changeLiveView(false);
        });

      setTimeout(function () {
        showHead();
      }, 50);
    }

    // called from onStart when recording is done
    function stopRecording() {
      hideHead();

      bwsCapture.stopRecording();

      for (let i = 1; i <= 4; i++) {
        $("#uuiimage" + i).hide();
      }
    }

    /* -------------------- Displaying head ---------------------------------------------------*/

    var camera, scene, renderer, id;
    var startTime;
    var resetHead = false;
    const maxVertical = 0.2;
    const maxHorizontal = 0.25;

    function initHead() {
      // renderer
      try {
        renderer = new THREE.WebGLRenderer({ alpha: true });
      } catch (e) {
        return false;
      }

      let container = document.getElementById("uuihead");
      // document.body.appendChild(container);

      let width = $("#uuihead").width();
      let height = $("#uuihead").height();
      let uuihead = $("#uuihead");
      $("#uuiliveview").append(uuihead);

      // camera
      camera = new THREE.PerspectiveCamera(0, width / height, 1, 1000);
      camera.position.set(0, 0, 5.5);

      // scene
      scene = new THREE.Scene();
      let ambientLight = new THREE.AmbientLight(0x4953ff, 0.4);
      scene.add(ambientLight);
      let pointLight = new THREE.PointLight(0x3067ff, 0.8);
      camera.add(pointLight);
      scene.add(camera);

      // texture
      let manager = new THREE.LoadingManager();
      manager.onProgress = function (item, loaded, total) {};

      // model
      let onProgress = function (xhr) {
        if (xhr.lengthComputable) {
          // let percentComplete = (xhr.loaded / xhr.total) * 100;
        }
      };
      let onError = function (xhr) {};
      let loader = new OBJLoader(manager);
      // let material = new THREE.MeshLambertMaterial({ transparent: false, opacity: 0.8 });

      loader.load(
        headObj,
        function (head) {
          head.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              //   child.material = material;
            }
          });
          head.name = "BioIDHead";
          head.position.y = 0;
          scene.add(head);
        },
        onProgress,
        onError
      );

      renderer.setClearColor(0x000000, 0); // the default
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);
      renderer.domElement.style.display = "inline";

      container.appendChild(renderer.domElement);
      document.addEventListener("uuiresize", onHeadResize, false);

      return true;
    }

    function onHeadResize() {
      let canvasWidth = parseInt($("#uuicanvas").width());
      let canvasHeight = parseInt($("#uuicanvas").height());

      $("#uuihead").css({ "margin-top": -canvasHeight, "margin-left": "0" });
      $("#uuihead").attr("width", canvasWidth);
      $("#uuihead").attr("height", canvasHeight + 50);

      camera.aspect = canvasWidth / canvasHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(canvasWidth, canvasHeight);
      renderer.render(scene, camera);
    }

    function resetHeadDisplay() {
      currentTag = "";
      parentTag = "";
      resetHead = true;
      cancelAnimationFrame(id);
      $("#uuihead").css("opacity", "0.6");
    }

    function setCurrentTag(tag) {
      if (currentTag !== "") {
        parentTag = currentTag;
      }

      currentTag = tag;
      startTime = new Date().getTime();

      if (currentTag === "any" && task !== "enrollment") {
        constantAnimation();
      } else {
        animateHead();
      }
    }

    function constantAnimation() {
      // change css class 'head'
      $("#uuihead").css("opacity", "0.8");

      // animation time
      let delta = 0.005;
      let head = scene.getObjectByName("BioIDHead");
      showHead();

      let direction = "down";
      var animate = function () {
        if (direction === "up") {
          if (head.rotation.x >= -maxVertical) {
            head.rotation.x -= delta;
          } else {
            direction = "down";
          }
        }

        if (direction === "down") {
          if (head.rotation.x <= maxVertical) {
            head.rotation.x += delta;
          } else {
            direction = "up";
          }
        }

        id = requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };
      animate();
    }

    function animateHead() {
      // animation time
      let speed = 0.000005;
      let endTime = new Date().getTime();
      let deltaTime = endTime - startTime;
      let delta = deltaTime * speed;

      let head = scene.getObjectByName("BioIDHead");
      let doAnimation = false;

      if (head) {
        if (resetHead) {
          // reset head rotation to center
          head.rotation.x = 0.9;
          head.rotation.y = 0.9;
          resetHead = false;
          doAnimation = true;
          // change css class 'head'
          $("#uuihead").css("opacity", "0");
          showHead();
        } else {
          if (currentTag === "any") {
            if (task === "enrollment") {
              // get predefined direction for better enrollment
              let recording = bwsCapture.getUploaded() + bwsCapture.getUploading() - 1;
              currentTag = enrollmentTags[recording];
            } else {
              if (head.rotation.x >= -maxVertical && head.rotation.x <= 0) {
                head.rotation.x -= delta;
                doAnimation = true;
              } else {
                head.rotation.x += delta;
                doAnimation = true;
              }
            }
          }
          if (currentTag === "down") {
            head.rotation.y = 0;
            if (parentTag === "up") {
              if (head.rotation.x <= 0) {
                head.rotation.x += delta;
                doAnimation = true;
              }
            } else {
              if (head.rotation.x >= 0 && head.rotation.x < maxVertical) {
                head.rotation.x += delta;
                doAnimation = true;
              }
            }
          } else if (currentTag === "up") {
            head.rotation.y = 0;
            if (parentTag === "down") {
              if (head.rotation.x >= 0) {
                head.rotation.x -= delta;
                doAnimation = true;
              }
            } else {
              if (head.rotation.x >= -maxVertical && head.rotation.x <= 0) {
                head.rotation.x -= delta;
                doAnimation = true;
              }
            }
          } else if (currentTag === "left") {
            head.rotation.x = 0;
            if (parentTag === "right") {
              if (head.rotation.y >= 0) {
                head.rotation.y -= delta;
                doAnimation = true;
              }
            } else {
              if (head.rotation.y >= -maxHorizontal && head.rotation.y <= 0) {
                head.rotation.y -= delta;
                doAnimation = true;
              }
            }
          } else if (currentTag === "right") {
            head.rotation.x = 0;
            if (parentTag === "left") {
              if (head.rotation.y <= 0) {
                head.rotation.y += delta;
                doAnimation = true;
              }
            } else {
              if (head.rotation.y >= 0 && head.rotation.y <= maxHorizontal) {
                head.rotation.y += delta;
                doAnimation = true;
              }
            }
          }
        }

        if (doAnimation) {
          id = requestAnimationFrame(animateHead);
        }
        renderer.render(scene, camera);
      }
    }

    function showHead() {
      $("#uuihead").show();
      onHeadResize();
    }

    function hideHead() {
      $("#uuihead").hide();
      resetHeadDisplay();
    }
  };

  render() {
    return (
      <KycBioIdConatiner KYCBioId>
        <section id="uuiwebapp" className="bioid_hidden">
          {/* <BannerBox display="flex" direction="column">
            <div className="bioid_center">
              <div id="uuiinstruction" className="bioid_instruction"></div>
            </div>
          </BannerBox> */}

          {/* <div className="row"> */}
          <BannerBox display="flex" justifyContent="center">
            <div id="uuiliveview" className="bioid_liveview">
              <div id="uuihead" className="bioid_head"></div>
              <canvas id="uuicanvas" className="bioid_canvasview"></canvas>
            </div>
            <video id="livevideo" className="bioid_live" style={{ visibility: "hidden" }}></video>
          </BannerBox>

          {/* </div> */}
          <BannerBox
            className="kycWebcam__btns"
            display="flex"
            justifyContent="center"
            mt="40px"
            mtLg="48px"
          >
            <Button
              secondaryRed
              width="115px"
              height="48px"
              marginRight="24px"
              PulseEffect
              className={this.state.cancel === true && "active"}
              onClick={() => {
                this.props.bioid_cancel();
                this.state.bwsCapture.stop();
                this.setState({
                  cancel: true
                });
              }}
            >
              Cancel
            </Button>
            {!this.state.active && (
              <Button
                id="uuistart"
                PulseEffectWebCamSubmit
                data-res="buttonStart"
                Pulse
                width="115px"
                height="48px"
                className={this.state.active === true && "active"}
                onClick={() =>
                  this.setState(() => ({
                    active: true
                  }))
                }
              >
                Start
              </Button>
            )}
          </BannerBox>

          <div className="row">
            <div className="col bioid_center">
              <div id="uuistatus" className="bioid_modal">
                <div id="uuimessage"></div>
                <div className="bioid_modal-footer">
                  <button id="uuiok" className="button button-ok">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id="uuicompactupload" className="bioid_uploadstatus-compact">
            <div id="uuiprogresscompact" className="bioid_progress-compact">
              <div id="uuiprogressbarcompact" className="bioid_progressbar"></div>
            </div>
          </div>
        </section>

        <section id="uuisplash" className="bioid_hidden">
          <div className="row mt-9 d-flex justify-content-center">
            <div className="col-8">
              <div id="uuiprompt" className="bioid_promt">
                <p data-res="prompt" className="mt-9"></p>
                <p id="uuierror" className="bioid_alert-danger"></p>
              </div>
            </div>
          </div>
        </section>
      </KycBioIdConatiner>
    );
  }
}

const maptoStateProps = (state) => ({
  levelStatus: state.kyc.levelStatus,
  isLevelStatusFetching: state.kyc.isLevelStatusFetching,
  isLevelStatusError: state.kyc.isLevelStatusError
});

const mapDispatchToProps = (dispatch) => ({});

export default compose(connect(maptoStateProps, mapDispatchToProps))(Bioid);
