/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import LevelProgressBarComponent from "./LevelProgress";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ChevronRightIcon, ChevronRightBlueIcon, SuccessIcon } from "../../assets/svgs";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
} from "../../assets/StyleComponents/Exchange.style";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { Link } from "react-router-dom";
import { getUserDetails } from "../../lib/api";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../lib/User/UserApi";

function ProfileSection(props) {
  const { isTheme, getUserData, levelStatus, userData } = props;
  const { width } = useWindowSize();
  const StatusLVL2 = userData?.KYC?.LEVEL2?.status;
  const prevLang = localStorage.getItem("ljs-lang");

  const WhenVerficationRejectedLVL3 =
    userData.KYC.isHighRiskCountry &&
    StatusLVL2 === "REJECTED" &&
    (userData?.KYC?.transactionRiskInfo?.status === "SUBMITTED" ||
      userData?.KYC?.transactionRiskInfo?.status === "RESUBMITTED");

  return (
    <>
      {Object.keys(getUserData).length > 0 && levelStatus !== "LEVEL2" && width > 1024 && (
        <BannerSection
          flex="1"
          order="1"
          height="360px"
          paddRight="8px"
          display={levelStatus !== "LEVEL2" ? "flex" : "none"}
        >
          <BannerBox
            CardWhite
            DashboardBordersdark={isTheme && true}
            borderRadius="10px"
            padding="1rem 1.5rem"
            height="100%"
            display="flex"
            width="100%"
            direction="column"
          >
            <BannerBox
              display="flex"
              justifyContent="space-between"
              margin="0.5rem 0px"
              paddBottom="16px"
            >
              <BannerHeading BodyColor weight="500" mb="0px" size="18px">
                My Profile
              </BannerHeading>
              <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/profile`}>
                <ChevronRightIcon />
              </Link>
            </BannerBox>

            <BannerBox display="flex" width="100%" height="100%">
              {Object.keys(getUserData).length > 0 && (
                <LevelProgressBarComponent
                  userData={getUserData}
                  twoAuthToken={getUserData.twoFactorAuth.status}
                  WhenVerficationRejectedLVL3={WhenVerficationRejectedLVL3}
                />
              )}
            </BannerBox>
          </BannerBox>
        </BannerSection>
      )}
      {Object.keys(getUserData).length > 0 && levelStatus !== "LEVEL2" && width < 1025 && (
        <BannerSection
          flex="1"
          order="1"
          mbLg="10px"
          mbMd="12px"
          display={levelStatus !== "LEVEL2" ? "flex" : "none"}
        >
          <BannerBox
            CardWhite
            DashboardBordersdark={isTheme && true}
            borderRadius="10px"
            padding="1rem 1.5rem"
            height="100%"
            display="flex"
            width="100%"
            direction="column"
          >
            <BannerHeading BodyColor weight="500" mb="0px" size="18px">
              {" "}
              My Profile{" "}
            </BannerHeading>

            <BannerBox display="flex" justifyContent="space-between" margin="25px 0px">
              <BannerBox display="flex" direction="column" paddRight="12px">
                <BannerHeading weight="500" BodyColor size="1rem">
                  {" "}
                  Register{" "}
                </BannerHeading>
                <BannerHeading size="1rem" weight="300" txtGrey paddTop="16px">
                  Set up your LCX account
                </BannerHeading>
              </BannerBox>
              {/* NOTE height and width needs to be reviewed */}
              <SuccessIcon width="30px" height="30px" />
            </BannerBox>

            <BannerBox style={{ border: "solid 1px #ccced0" }} />

            <BannerBox display="flex" justifyContent="space-between" margin="25px 0px">
              <BannerBox display="flex" direction="column" paddRight="12px">
                <BannerHeading weight="500" BodyColor size="1rem">
                  {userData.KYC.LEVEL2.status === "SUBMITTED" ||
                  userData.KYC.LEVEL2.status === "PARTIALLY_SUBMITTED" ||
                  userData.KYC.LEVEL2.status === "RESUBMITTED" ? (
                    "Under Process"
                  ) : userData.KYC.LEVEL2.status === "REJECTED" || WhenVerficationRejectedLVL3 ? (
                    <BannerHeadingSpan txtGrey>
                      KYC Rejected,&nbsp;
                      <Link
                        to={"/setting/profile"}
                        className="txt-primary"
                        style={{
                          textDecoration: "underline",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Try Again
                      </Link>
                    </BannerHeadingSpan>
                  ) : (
                    <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/profile`}>
                      <BannerHeadingSpan txtPrimary textDecoration="underline">
                        Get Verified
                      </BannerHeadingSpan>
                    </Link>
                  )}
                </BannerHeading>
                <BannerHeading size="1rem" weight="300" txtGrey paddTop="20px">
                  {userData.KYC.LEVEL2.status === "PARTIALLY_SUBMITTED" ||
                  userData.KYC.LEVEL2.status === "SUBMITTED" ||
                  userData.KYC.LEVEL2.status === "RESUBMITTED"
                    ? "We are verifying your documents and will update the status soon"
                    : userData.KYC.LEVEL2.status === "REJECTED" || WhenVerficationRejectedLVL3
                    ? "Find out documents which were not accepted and only rectify those"
                    : "Upload Documents and verify your IDs"}
                </BannerHeading>
              </BannerBox>

              <BannerBox display="flex" direction="column" mb="16px" mt="12px">
                <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/profile`}>
                  {/* NOTE width and height needs to be reviewed */}
                  <ChevronRightBlueIcon />
                </Link>
              </BannerBox>
            </BannerBox>
          </BannerBox>
        </BannerSection>
      )}
    </>
  );
}

export default ProfileSection;
