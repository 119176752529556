/**
 * Import Other Modules
 */
import apiConstants from "../constants/apiConstants";

/**
 *
 *  @param {Logout The User} action
 */
export function getTradingCompetition() {
  return {
    type: apiConstants.GET_TRADING_COMPETITION
  };
};

/**
 *
 *  @param {Logout The User} action
 */
export function getContestList() {
  return {
    type: apiConstants.GET_CONTEST_USER_LIST
  };
};
/**
 *
 *  @param {Get Leaderboad} action
 */
export function getLeaderBoard(data) {
  return {
    type: apiConstants.LEADERBOARD_PAIR_LIST,
    payload:data
  };
};
export function getPrivateLeaderBoard(data) {
  return {
    type: apiConstants.LEADERBOARD_PRIVATE_PAIR_LIST,
    payload:data
  };
};
