import { useCallback, useMemo } from 'react';
import Countdown from 'react-countdown';
import { BannerBox } from '../../../../assets/StyleComponents/Exchange.style';
import { ParaTag } from '../../../../assets/StyleComponents/FontSize/para.style';
import { TimerGreyIcon } from '../../../../assets/svgs';


const CountDownTimeComponent = ({ item, setTimerRunning }) => {
  const TimeCheck = useCallback((expiresAt) => {
    const previousTime = new Date(expiresAt);
    const currentTime = new Date();
    const timeDifference = Math.abs(currentTime - previousTime);
    return timeDifference / (1000 * 60 * 60); // Convert to hours
  }, []);

  const hoursDifference = useMemo(() => TimeCheck(item?.expiresAt), [item?.expiresAt, TimeCheck]);

  return (
    <Countdown
      date={item?.expiresAt}
      renderer={({ days, hours, minutes, seconds, completed }) => {
        if (completed) return null;

        if (hoursDifference <= 48) {
          return (
            <BannerBox display="flex" alignItems="center">
              <TimerGreyIcon width="16px" height="16px" mr={"6px"} />
              <ParaTag size="12px" txtGrey> Ends in </ParaTag>
              {/* {days > 0 && <ParaTag size="12px" txtGrey> Ends in {days} </ParaTag>} */}
              {hoursDifference > 0 && (
                <ParaTag size="12px" txtGrey ml="6px">
                  {hoursDifference < 10 ? `0${Math.floor(hoursDifference)}` : Math.floor(hoursDifference)}h : 
                </ParaTag>
              )}
              {minutes >= 0 && (
                <ParaTag size="12px" txtGrey ml="2px">
                  {minutes < 10 ? `0${minutes}` : minutes}m :
                </ParaTag>
              )}
              {seconds >= 0 && (
                <ParaTag size="12px" txtGrey ml="2px">
                  {seconds < 10 ? `0${seconds}` : seconds}s
                </ParaTag>
              )}
            </BannerBox>
          );
        } else return null;
      }}
      onComplete={() => setTimerRunning(false)}
    />
  );
};

export default CountDownTimeComponent;