/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { useEffect } from "react";
import SkeletonLoader from "../../../../../components/SkeletonLoader";
import { CloseIcon } from "../../../../../assets/svgs";
import { DivElement } from "../../../../../components/Reusable";

const Stepfirst = (props) => {
  useEffect(() => {
    props.openNotabean();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseHandler = () => {
    if (props?.notabeneInstance?.current) {
      props.notabeneInstance.current.destroyWidget();
      props.notabeneInstance.current = null;
    }
    props.onClose();
  };
  return (
    <Fragment>
      <DivElement displayBtw>
        <span></span>
        <CloseIcon onClick={onCloseHandler} />
      </DivElement>
      <div id="widget">
        {props.notabeneLoading && <SkeletonLoader height="250px" width="100%" />}
      </div>
    </Fragment>
  );
};

export default Stepfirst;
