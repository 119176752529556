/* eslint-disable no-console */
import { css } from "styled-components";
import { createResponsiveStyles } from "../GlobalStyle/DynamicResponsiveDesign/DynamicResponsiveDesign.style";

const BackgroundCommanStyle = css`
  ${({ backgorund, backgorundXl, backgorundLg, backgorundMd, backgorundSm }) => (backgorund || backgorundXl || backgorundLg || backgorundMd || backgorundSm) && 
    createResponsiveStyles("backgorund", "backgorund", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : null 
    )
  }

  ${({ backgroundColor, backgroundColorXl, backgroundColorLg, backgroundColorMd, backgroundColorSm }) => (backgroundColor || backgroundColorXl || backgroundColorLg || backgroundColorMd || backgroundColorSm) && 
    createResponsiveStyles("backgroundColor", "background-color", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : null 
    )
  }

  ${({ LightPrimaryBackground, theme }) => LightPrimaryBackground && css `background: ${theme.level3CardTC} !important;`}
  ${({ PrimaryBackground, theme }) => PrimaryBackground && css `background: ${theme.primary} !important;`}
`;

export { BackgroundCommanStyle };