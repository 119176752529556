import React, { useState } from "react";
import { useSelector } from "react-redux";
import { formatDistanceToNow } from "date-fns";
import { useLocation } from "react-router-dom";

import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { HeadingSix } from "../../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { NoRewardsFoundIcon, QuestionMark } from "../../../../assets/svgs";
import { Skeleton } from "../../../../components/Loader";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import ShowSingleButton from "../ShowSingleButton/ShowSingleButton";
import ShowRulesModal from "../RewardsTimeOffer/ShowRulesModal";
import CountDownTimeComponent from "./CountDownTime";
import { DivElement, RouterLink, TextWrapper } from "../../../../components/Reusable";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { HeadingFourth } from "../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { H1Tag } from "../../../../assets/StyleComponents/FontSize/fonts.style";
import { checkThousandWithoutPercision, numberWithCommas } from "../../../../utils/general";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";

const formatTokens = {
  lessThanXSeconds: (count) => `${count} seconds ago`,
  xSeconds: (count) => `${count} seconds ago`,
  halfAMinute: () => '30 seconds ago',
  lessThanXMinutes: (count) => `${count} minutes ago`,
  xMinutes: (count) => `${count} minutes ago`,
  aboutXHours: (count) => `${count} hours ago`,
  xHours: (count) => `${count} hours ago`,
  xDays: (count) => `${count} days ago`,
  aboutXWeeks: (count) => `${count} weeks ago`,
  xWeeks: (count) => `${count} weeks ago`,
  aboutXMonths: (count) => `${count} months ago`,
  xMonths: (count) => `${count} months ago`,
  aboutXYears: (count) => `${count} years ago`,
  xYears: (count) => `${count} years ago`,
  overXYears: (count) => `${count} years ago`,
  almostXYears: (count) => `${count} years ago`
};

const optionsToken = {
  addSuffix: true,
  includeSeconds: true,
  locale: {
    formatDistance: (token, count) => {
      const formatter = formatTokens[token];
      return formatter ? formatter(count) : token;
    }
  }
};

const SingleRewardTask = () => {
  const rewardsTaskList = useSelector((state) => state.rewards.rewardsRegularList);
  // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isFetchingRewardsRegular = useSelector((state) => state.rewards.isFetchingRewardsRegular);
  const [showModal, setShowModal] = useState(false);
  const [timeRunning, setTimerRunning] = useState(true);
  const [singleRecords, setSingleRecords] = useState("");
  const { width } = useWindowSize();
  const location = useLocation();
  const prevLang = localStorage.getItem("ljs-lang");

  const handleRulesStatus = (givenItem) => {
    setSingleRecords(givenItem);
    setShowModal(true);
  };

  const onClose = () => {
    setSingleRecords("");
    setShowModal(false);
  };

  if (isFetchingRewardsRegular) {
    return (
      <BannerBox
        display="grid"
        gridCols={width > 768 ? "repeat(4,1fr)" : "repeat(1,1fr)"}
        gap="24px"
        alignItems="center"
      >
        {[0, 1, 2, 3].map((item) => (
          <BannerBox
            key={item}
            CardGrey
            padding="40px 28px"
            width="230px"
            height="352px"
            radius="8px"
            display="flex"
            justifyContent="space-between"
            direction="column"
            alignItems="center"
          >
            <Skeleton />
          </BannerBox>
        ))}
      </BannerBox>
    );
  }
  
  if (rewardsTaskList && rewardsTaskList.length === 0) {
    return (
      <BannerBox
        display="flex"
        direction="column"
        justifyContent="center"
        alignItems="center"
        margin="80px 0px 15px"
        gap="12px"
        minHeight="150px"
      >
        <NoRewardsFoundIcon width="200px" height="200px" />
        <ParaTag size="16px" textAlignMd="center">
          We're sorry, but there are no tasks available to display at the moment
        </ParaTag>
        {location.pathname.includes(`/rewards/bonus`) && 
          <RouterLink to={`/${prevLang}/rewards`} width="60%" cursor="pointer" textDecoration="none">
            <ButtonComponent width="100%" size="16px" mt="12px">
              View All Tasks
            </ButtonComponent>
          </RouterLink>
        }
      </BannerBox>
    );
  } else {
    return (
      <BannerBox
        display="grid"
        gridCols={width > 768 ? "repeat(auto-fill, minmax(230px, 1fr))" : "repeat(auto-fill, minmax(238px, 1fr))"}
        gap="24px"
        mb="24px"
        // alignItems="center"
      >
        <>
          {showModal && <ShowRulesModal onClose={onClose} rulesRecords={singleRecords} />}
          {rewardsTaskList && rewardsTaskList.length > 0 && rewardsTaskList?.map((item, index) => (
            <BannerBox
              position="relative"
              key={index}
              CardGrey
              padding="12px 20px 40px"
              minWidth="230px"
              minHeight="450px"
              radius="8px"
              display="flex"
              gap="8px"
              // justifyContent="space-between"
              direction="column"
              alignItems="center"
            >
              <ParaTag height="24px" mb="4px">
                {(item?.userRewardStatus === "expired" || item?.userRewardStatus === "claimed") ? null : timeRunning && (
                  <CountDownTimeComponent item={item} setTimerRunning={setTimerRunning} />
                )}
              </ParaTag>
              <ParaTag flexCenter weight="500" width="100%" py="8px" minHeight="52px"  textAlign="center" backgroundSky>
                {item.name}
              </ParaTag>

              <HeadingFourth PrimaryGradientColor textAlign="center" weight="500" mt="16px">
                {item.rewardCoin}
              </HeadingFourth>
              <H1Tag PrimaryGradientColor textAlign="center" weight="500">
                {numberWithCommas(checkThousandWithoutPercision(item?.rewardAmount || 0))}
              </H1Tag>

              <BannerBox bottomBorder width="100%" pb="16px" minHeight="54px">
                <BannerHeading textAlign="center" size="14px" weight="500">
                  {item?.ruleDescription}
                </BannerHeading>
              </BannerBox>
              
              <HeadingSix
                size="16px"
                weight="500"
                txtGrey
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="16px"
                pt="16px"
                width="100%"
              >
                {(item?.userRewardStatus === "expired" || item?.userRewardStatus === "claimed") ? null :
                  <>
                    {item?.rewardType === "fixed" ? "Fixed Amount" : "Mystery box"}
                    {(item?.description || item?.additionalRules?.length > 0) && (
                      <BannerBox
                        display="flex"
                        alignItems="center"
                        onClick={() => handleRulesStatus(item)}
                      >
                        <QuestionMark width="18px" height="18px" />
                      </BannerBox>
                    )}
                  </>
                }
              </HeadingSix>
              
              {(item?.userRewardStatus === "expired" || item?.userRewardStatus === "claimed") ? null :
                <BannerBox mt={"40px"} width="100%">
                  <ShowSingleButton records={item} cardStatus={true} />
                </BannerBox>
              }

              {item?.userRewardStatus === "expired" && (
                <DivElement
                  isDarkTheme={isDarkTheme}
                  bgLightRed
                  flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="16px"
                  padding="40px 32px"
                  position="absolute"
                  minWidth="230px"
                  minWidthLg="100%"
                  minWidthMd="100%"
                  bottom="0"
                  borderRadius="0px 0px 8px 8px"
                  width="100%"
                >
                  <TextWrapper red>Expired</TextWrapper>
                  <TextWrapper red>{formatDistanceToNow(item?.effectiveExpiresAt, optionsToken)}</TextWrapper>
                </DivElement>
              )}

              {item?.userRewardStatus === "claimed" && (
                <DivElement
                  isDarkTheme={isDarkTheme}
                  rewardExpired
                  flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="16px"
                  padding="40px 32px"
                  position="absolute"
                  minWidth="230px"
                  minWidthLg="100%"
                  minWidthMd="100%"
                  bottom="0"
                  borderRadius="0px 0px 8px 8px"
                  width="100%"
                >
                  <TextWrapper green>Claimed</TextWrapper>
                  <TextWrapper green>{formatDistanceToNow(item?.claimData?.claimedAt, optionsToken)}</TextWrapper>
                </DivElement>
              )}
            </BannerBox>
          ))}
        </>
      </BannerBox>
    );
  }
};

export default SingleRewardTask;
