import React, { useState } from "react";
import { withRouter } from "react-router";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import Export from "./component/Export";
import { AnchorTag, ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { InfoIcon } from "../../../assets/svgs";
import { Fragment } from "react";
import BlockPitModal from "./BlockPitModal";

const ReportsPage = () => {
  const [showBlockPitModal, setShowBlockPitModal] = useState(false);
  const { width } = useWindowSize();

  const onClickTaxRepotByBlockpit = (e) => {
    e.preventDefault();
    setShowBlockPitModal(true);
  };

  return (
    <Fragment>
      <BannerSection paddMd="20px 16px" padd="0px 16px">
        <BannerBox display="flex" justifyContent="space-between" direction="column">
          <BannerBox width="100%">
            <BannerBox display="flex" width="100%" justifyContent="space-between">
              <BannerBox display="flex" direction="column" width="75%" widthMd="100%">
                <BannerHeading size={"24px"} sizeLg={"20px"} sizeMd={"18px"} mb={"8px"}>
                  Generate Report <sup> (Beta) </sup>
                </BannerHeading>
                <BannerHeading size={"16px"} sizeLg={"14px"} sizeMd={"12px"}>
                  Reports will be prepared within a brief timeframe. Once ready, you will be able to
                  use the download button.
                </BannerHeading>
              </BannerBox>

              {width > 767 && (
                <AnchorTag
                  // href="https://blockpit.io/lp/lcx"
                  target="_blank"
                  cursor="pointer"
                  textDecoration="none"
                  OutlinePrimaryWithoutHover
                  // padding="0 16px"
                  radius="5px"
                  size="16px"
                  sizeLg="14px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minWidth="200px"
                  // maxWidth="320px"
                  padding="0px 7px"
                  height="46px"
                  heightLg="42px"
                  onClick={onClickTaxRepotByBlockpit}
                >
                  Tax Report via Blockpit
                </AnchorTag>
              )}
            </BannerBox>

            {width < 768 && (
              <AnchorTag
                // href="https://blockpit.io/lp/lcx"
                target="_blank"
                cursor="pointer"
                textDecoration="none"
                OutlinePrimaryWithoutHover
                radius="5px"
                size="16px"
                sizeLg="14px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                myMd="12px"
                // width="188px"
                height="46px"
                heightLg="42px"
                onClick={onClickTaxRepotByBlockpit}
              >
                Tax Report via Blockpit
              </AnchorTag>
            )}

            <ParaTag
              mt="8px"
              txtWarning
              size={"14px"}
              sizeLg={"14px"}
              sizeMd={"12px"}
              display="flex"
              alignItems="center"
              alignItemsLg="flex-start"
            >
              <InfoIcon
                width={width > 767 ? "20px" : "30px"}
                height={width > 767 ? "20px" : "13px"}
                marginRight="5px"
                marginTop={width > 1024 ? "0px" : "2px"}
              />
              Note: Download the generated report within 24 hours, as it will be automatically
              deleted thereafter.
            </ParaTag>
          </BannerBox>
          <BannerBox>
            <Export />
          </BannerBox>
        </BannerBox>
      </BannerSection>

      {showBlockPitModal && <BlockPitModal onClose={() => setShowBlockPitModal(false)} />}
    </Fragment>
  );
};

export default withRouter(ReportsPage);
