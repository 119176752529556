import React, { Fragment, memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { daysLeft } from "../../../utils/helpers";
import { getAntiPhisingCode } from "../../../actions/Setting/setting";
import ApiKeysComponent from "../ApiKeys/apiKeys";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { SpanElement, ToolTip } from "../../../components/Reusable";
import { ChevronRightIcon } from "../../../assets/svgs";
import moment from "moment";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import DisableStepModal from "../../settingPages/Security/DisableStep";
import { getUserDetails } from "../../../actions/dashboard";

const SecurityComponent = (props) => {
  const { width } = useWindowSize();
  const { userData, antiPhising } = props;
  const { passwordLastUpdated } = userData || "";
  const [TwoFaModal, setTwofamodal] = useState(false);
  const prevLang = localStorage.getItem("ljs-lang");

  const closeModal = () => {
    setTwofamodal(false);
  };

  useEffect(() => {
    props.getAntiPhisingCode();
  }, []);

  let lastDateUpdate = daysLeft(passwordLastUpdated);
  let faStatus = userData?.twoFactorAuth?.status;
  let antiStatus = !antiPhising ? antiPhising : antiPhising;
  let count =
    faStatus && antiStatus && lastDateUpdate < 90
      ? 3
      : (faStatus && antiStatus && lastDateUpdate > 90) ||
        (faStatus && !antiStatus && lastDateUpdate < 90) ||
        (!faStatus && antiStatus && lastDateUpdate < 90)
      ? 2
      : 1;

  return (
    <Fragment>
      {TwoFaModal && <DisableStepModal onClose={closeModal} {...props} />}
      {userData && (
        <BannerBox
          flex="0 0 33.33%"
          flexLg="1 1 0"
          order="2"
          orderLg="5"
          padding="0 0 0 0.5rem"
          paddingLg="10px 0px 10px 0"
          paddingMd="12px 0px 12px 0"
        >
          <BannerBox
            CardWhite
            DashboardBordersdark
            height="267px"
            heightMd="271px"
            heightLg="318px"
            width="100%"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            borderRadius="10px"
            padding="0.8rem 16px"
            paddingMd="1rem 16px"
          >
            <BannerBox
              display="flex"
              justifyContent="space-between"
              mb="23px"
              mbMd="27px"
              mbLg="45px"
            >
              <BannerHeading
                BodyColor
                weight="500"
                mb="0px"
                size="1.125rem"
                sizeLg="18px"
                sizeMd="16px"
              >
                Security
              </BannerHeading>

              <BannerBox display="flex" alignItems="center" sizeLg="16px">
                <ToolTip
                  id="securityTip"
                  title={
                    <SpanElement>
                      <SpanElement red={count < 3 ? true : null} green={count === 2 ? true : null}>
                        {count}
                      </SpanElement>
                      /3
                    </SpanElement>
                  }
                  containerProps={{ margin: "0 8px" }}
                  imgProps={{ width: "18px", height: "18px", top: "2px", right: "-20px" }}
                  tipContent="Your security score is low. Please enable the security measures."
                />
                <BannerHeadingSpan ml="12px">
                  <Link to={`${prevLang ? `/${prevLang}` : "/en"}/setting/security`}>
                    <ChevronRightIcon
                      width={width > 1024 ? "24px" : "18px"}
                      height={width > 1024 ? "24px" : "18px"}
                    />
                  </Link>
                </BannerHeadingSpan>
              </BannerBox>
            </BannerBox>
            {/* 3 card div here */}
            <BannerBox display="flex" direction="column" gap="24px" gapMd="28px" gapLg="40px">
              <BannerBox display="flex" margin="0px" width="100%">
                <BannerBox width="70%">
                  <ToolTip
                    id="twoFa"
                    title="2 Factor Authentication"
                    titleProps={{ fontSize: "16px", fontSizeMd: "14px", right: "-29px" }}
                    imgProps={{ widthSm: "16px", heightSm: "16px" }}
                    tipContent="With 2-Step Verification you have an increased security to your account."
                  />
                  <BannerHeading txtGrey mb="0px" weight="300" size="14px" sizeMd="12px">
                    Currently {userData?.twoFactorAuth?.status ? "enable" : "disable"}
                  </BannerHeading>
                </BannerBox>

                <BannerBox width="30%" textAlign="right">
                  {userData.twoFactorAuth.status ? (
                    <ButtonComponent
                      OutlineOnlyDanger
                      btnHeight="auto"
                      sizeMd="14"
                      onClick={() => setTwofamodal(!TwoFaModal)}
                    >
                      <u> Disable </u>
                    </ButtonComponent>
                  ) : (
                    <Link
                      style={{ textDecoration: "underline" }}
                      to={`${prevLang ? `/${prevLang}` : "/en"}/setting/security/auth`}
                    >
                      <ButtonComponent OutlineOnlyPrimary btnHeight="auto" sizeMd="14">
                        <u> Enable </u>
                      </ButtonComponent>
                    </Link>
                  )}
                </BannerBox>
              </BannerBox>

              <BannerBox display="flex" width="100%">
                <BannerBox width="70%">
                  <ToolTip
                    id="antiphising"
                    title="Anti-Phishing Code"
                    titleProps={{ fontSize: "16px", fontSizeMd: "14px", right: "-29px" }}
                    imgProps={{ widthSm: "16px", heightSm: "16px" }}
                    tipContent="Secures your account from web-based threats."
                  />
                  <BannerHeading txtGrey mb="0px" weight="300" size="14px" sizeMd="12px">
                    Currently {antiPhising.exists ? "enable" : "disable"}
                  </BannerHeading>
                </BannerBox>

                <BannerBox width="30%" textAlign="right">
                  <Link
                    style={{ textDecoration: "underline" }}
                    to={`${prevLang ? `/${prevLang}` : "/en"}/setting/security/phishing`}
                  >
                    {antiStatus.exists ? (
                      <ButtonComponent OutlineOnlyDanger btnHeight="auto" sizeMd="14">
                        <u> Disable </u>
                      </ButtonComponent>
                    ) : (
                      <ButtonComponent OutlineOnlyPrimary btnHeight="auto" sizeMd="14">
                        <u> Enable </u>
                      </ButtonComponent>
                    )}
                  </Link>
                </BannerBox>
              </BannerBox>

              <BannerBox display="flex" margin="0px" width="100%">
                <BannerBox width="70%">
                  <ToolTip
                    id="loginPassword"
                    title="Login Password"
                    titleProps={{ fontSize: "16px", fontSizeMd: "14px", right: "-29px" }}
                    imgProps={{ widthSm: "16px", heightSm: "16px" }}
                    tipContent="Change your password periodically to protect your account."
                  />
                  <BannerHeading txtGrey mb="0px" weight="300" size="14px" sizeMd="12px">
                    Last changed
                    <var>
                      {` ${
                        userData.passwordLastUpdated
                          ? moment(moment(userData.passwordLastUpdated).unix() * 1000).fromNow()
                          : "a long ago"
                      }`}
                    </var>
                  </BannerHeading>
                </BannerBox>

                <BannerBox width="30%" textAlign="right">
                  <Link
                    style={{ textDecoration: "underline" }}
                    to={`${prevLang ? `/${prevLang}` : "/en"}/setting/password`}
                  >
                    <ButtonComponent OutlineOnlyPrimary btnHeight="auto" sizeMd="14">
                      <u> Change </u>
                    </ButtonComponent>
                  </Link>
                </BannerBox>
              </BannerBox>
            </BannerBox>
          </BannerBox>

          <ApiKeysComponent />
        </BannerBox>
      )}
    </Fragment>
  );
};

const maptoStateProps = (state) => ({
  userData: state.dashboard.userData,
  antiPhising: state.setting.antiPhising
});

const mapDispatchToProps = (dispatch) => ({
  getAntiPhisingCode: (data) => dispatch(getAntiPhisingCode(data)),
  getUserData: (data) => dispatch(getUserDetails())
});

export default connect(maptoStateProps, mapDispatchToProps)(withRouter(memo(SecurityComponent)));
