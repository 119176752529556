import { connect } from "react-redux";
import WalletPage from "../views/walletPageCopy/index.jsx";
import { getWalletBalance, getUserDetails } from "../actions/dashboard";
import { updatePrivateSocketBalance } from "../actions/trade";
import { logout } from "../actions/auth";
import {
  getAllWallets,
  createWallet,
  getTransactions,
  withdrawToken,
  clearWalletErrors
} from "../actions/wallet";
import { getBeneficiary } from "../actions/Wallet/bankAccount.js";

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  isThemeFecthing: state.theme.isThemeFetching,
  // walletBalance: state.dashboard.walletBalance,
  isFetchingGetWalletBalance: state.dashboard.isFetchingGetWalletBalance,
  loader: state.wallet.apiLoader,
  error: state.wallet.error,
  apiData: state.wallet.apiData,
  wallets: state.wallet.wallets,
  allList: state.wallet.allList,
  getBeneficiaryAll: state.bank.getBeneficiaryAll,
  getBeneficiaryCount: state.bank.getBeneficiaryCount,
  isfetchingBeneficiary: state.bank.isfetchingBeneficiary,
  getSingleBeneficiary: state.bank.getSingleBeneficiary,
  isfetchingSingleBeneficiary: state.bank.isfetchingSingleBeneficiary,
  walletBalanceCopy: state.trade.walletBalanceCopy,
  walletBalance: state.trade.walletBalance,
  deposits: state.wallet.deposits,
  withdrawals: state.wallet.withdrawals,
  errorGet: state.wallet.errorGet,
  userData: state.dashboard.getUserData,
  isWholeLoading: state.dashboard.isWholeLoading,
  isLogoutFetching: state.dashboard.isLogoutFetching,
  walletErrorGet: state.wallet.errorGet,
  marketErrorGet: state.market.errorGet,
  tradeErrorGet: state.trade.errorGet,
  dashErrorGet: state.dashboard.errorGet,
  settingErrorGet: state.setting.errorGet,
  totalDepositCount: state.wallet.totalDepositCount,
  totalWithrawalCount: state.wallet.totalWithrawalCount,
  totalAllCount: state.wallet.totalAllCount,
  isFetchingWallet: state.wallet.isFetchingWallet
});

const mapDispatchToProps = (dispatch) => ({
  getWalletBalance: () => dispatch(getWalletBalance()),
  updatePrivateSocketBalance: (data) => dispatch(updatePrivateSocketBalance(data)),
  getAllWallets: () => dispatch(getAllWallets()),
  createWallet: (data) => dispatch(createWallet(data)),
  withdrawToken: (data) => dispatch(withdrawToken(data)),
  getBeneficiary: (data) => dispatch(getBeneficiary(data)),
  getTransactions: (data) => dispatch(getTransactions(data)),
  clearWalletErrors: () => dispatch(clearWalletErrors()),
  logoutUser: () => dispatch(logout()),
  getUserDetails: () => dispatch(getUserDetails())
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletPage);
