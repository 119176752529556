import { useSelector } from "react-redux";
import { CloseIcon, PlusIcon, SuccessBlueIcon } from "../../../assets/svgs";
import {
  ModalWrapper,
  TextWrapper,
  DivElement,
  Button,
  RouterLink,
  ImgElement
} from "../../../components/Reusable";
import { LcxDarkLogo, LcxLightLogo } from "../../../assets/png";
import { BlockpitIllustration } from "../../../assets/svgs/illustrations";

const BulletPoint = ({ title, subtitle }) => {
  return (
    <DivElement flex marginBottom="18px">
      <SuccessBlueIcon width="20px" height="20px" />
      <DivElement marginLeft="8px">
        <TextWrapper fontSize="16px" fontSizeSm="14px" lineheight="24px" lineheightSm="20px">
          {title}
        </TextWrapper>
        <TextWrapper
          marginTop="4px"
          fontSizeSm="12px"
          fontWeight="400"
          lineheight="20px"
          lineheightSm="18px"
        >
          {subtitle}
        </TextWrapper>
      </DivElement>
    </DivElement>
  );
};

const BlockPitModal = ({ onClose }) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const onReadMore = (e) => {
    e.preventDefault();
    window.open("https://www.blockpit.io/lp/lcx#Explore", "_blank");
  };

  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <DivElement displayBtw marginBottom="4px">
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineheight="24px">
            Tax Report via Blockpit
          </TextWrapper>
          <CloseIcon onClick={onClose} />
        </DivElement>
        <TextWrapper
          fontSize="16px"
          fontSizeSm="12px"
          fontWeight="400"
          lineheight="24px"
          lineheightSm="18px"
        >
          Generate your tax report fast and easy
        </TextWrapper>
        <DivElement marginTop="32px">
          <BulletPoint
            title="25% discount"
            subtitle="As a customer at LCX, you receive a discount on your tax report"
          />
          <BulletPoint
            title="Country-specific tax reports"
            subtitle="Receive your tax report tailored to your specific country tax system"
          />
          <BulletPoint
            title="Portfolio tracking"
            subtitle="All of your digital assets in one report"
          />

          <a
            href="https://blockpit.io/lp/lcx"
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer" }}
          >
            <Button primaryBlue width="100%" height="48px" marginTop="32px">
              Generate your tax report
            </Button>
          </a>
          <DivElement displayCenter marginTop="16px">
            <RouterLink fontSize="14px" fontWeight="400" lineheight="20px" onClick={onReadMore}>
              Read here how to set it up
            </RouterLink>
          </DivElement>
          <DivElement displayCenter marginTop="16px">
            <ImgElement
              maxWidth="54px"
              maxHeight="26px"
              src={isDarkTheme ? LcxLightLogo : LcxDarkLogo}
              alt="lcx"
            />
            <PlusIcon width="16px" height="16px" fillDark margin="0 6px" />
            <BlockpitIllustration />
          </DivElement>
        </DivElement>
      </DivElement>
    </ModalWrapper>
  );
};

export default BlockPitModal;
