import React, { Fragment, useEffect, useState } from "react";
import { numberWithCommas } from "../../../../../utils/general";
import { getClientSaleOrderHistory } from "../../../../../lib/api";
// import { Table } from "react-bootstrap";
import moment from "moment";
import NoRecord from "../../../../../components/Reusable/NoRecord";
import { TableLoader } from "../../../../../components/Loader";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../../../assets/svgs";
import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";
import { Table, Tbody, Td, Thead, Tr } from "../../../../../components/Reusable";

const RecentOrders = ({ saleType, id }) => {
  const [ordersData, setOrdersData] = useState("");
  const [recentOrders, setRecentOrders] = useState([]);
  const [recentOrdersPage, setRecentOrdersPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const length = saleType === "PRIVATE" ? 5 : 7;

  useEffect(() => {
    getOrders(true, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil((ordersData.totalCount || 0) / length));
    // eslint-disable-next-line
  }, [ordersData]);

  const handlePagination = (page) => {
    let totalPages = Math.ceil((ordersData.totalCount || 0) / length);
    if (page >= totalPages || page < 0) {
      return;
    }
    setRecentOrdersPage(page);
    getOrders(true, page);
  };

  const getOrders = (force, page) => {
    setIsLoading(true);
    let tokenSaleId = id;
    if (!recentOrders.length || force) {
      let data = {
        page: page,
        length: length,
        order: "DESC",
        tokenSaleId
      };
      getClientSaleOrderHistory(data)
        .then((res) => {
          setOrdersData(res && res.data);
          setRecentOrders(res && res.data && res.data.data ? res.data.data : []);
        })
        .catch((err) => {
          return;
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <BannerBox className="element-wrapper mb-0 pb-0">
      <Fragment>
        <BannerBox className="row4__col2__header">
          <BannerBox className="row4__col2__title">Recent User Orders</BannerBox>
          <BannerBox className="row4__col2__pagination">
            <span>Page</span>
            <BannerBox className="col2__pagination__currPage">{recentOrdersPage + 1}</BannerBox>
            <span>of {Math.ceil((ordersData.totalCount || 0) / length)}</span>
            <BannerBox
              className={`col2__pagination__btns ${
                recentOrdersPage <= 0 ? "col2__pagination__btns--disable" : ""
              }`}
              onClick={() => {
                handlePagination(recentOrdersPage - 1);
              }}
            >
              <ChevronLeftIcon />
            </BannerBox>
            <BannerBox
              className={`col2__pagination__btns ${
                recentOrdersPage >= totalPages - 1 ? "col2__pagination__btns--disable" : ""
              }`}
              onClick={() => {
                handlePagination(recentOrdersPage + 1);
              }}
            >
              <ChevronRightIcon />
            </BannerBox>
          </BannerBox>
        </BannerBox>
        <BannerBox className="row4__col2__chart">
          {!isLoading && (
            <Table responsive="sm">
              <Thead>
                <Tr>
                  <th>Trans Id</th>
                  <th>Amount</th>
                  {saleType === "PRIVATE" && <th>Round</th>}
                  {saleType === "PRIVATE" && <th>Access Code</th>}
                  <th>Time</th>
                </Tr>
              </Thead>
              <Tbody>
                {recentOrders &&
                  recentOrders.map((data, i) => {
                    return (
                      <Tr key={i}>
                        <Td>{data.id}</Td>
                        <Td>{numberWithCommas((+data.amount).toFixed(1))}</Td>
                        {saleType === "PRIVATE" && <Td>{data.roundName}</Td>}
                        {saleType === "PRIVATE" && <Td>{data.accessCode}</Td>}
                        <Td>{moment(data.timestamp).format("DD-MM-YYYY HH:mm")}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          )}
          {isLoading && <TableLoader rows={5} webCol={4} tabletCol={4} mobileCol={4} />}
          {!isLoading && recentOrders && recentOrders.length === 0 && <NoRecord />}
        </BannerBox>
      </Fragment>
    </BannerBox>
  );
};

export default RecentOrders;
