import styled, { css } from "styled-components";
import { TableRowComponent } from "../TableHead.style";

const FeesRow = styled(TableRowComponent)`
  th, td {
    display: flex;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 18px;
    text-right: right;

    &.symb-padd {
      justify-content: center !important;
    }
    &.text-right {
      justify-content: flex-end !important;
    }

    ${(props) =>
      props.FeesBannerBg &&
      css`
        background: ${({ theme }) => theme.bgActiveTable} !important;
      `}

    color: ${({ theme }) => theme.bodyText};
    flex: none;
    flex-grow: 0;
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start !important;

    &:nth-child(1) {
      flex:  0 0 450px;
      padding-left: 16px !important;
    }

    &:nth-child(2){
      flex: 0 0 400px;
      padding-left: 8px !important;
    }

    &:nth-child(4){
      flex: 0 0 150px !important;
      padding-left: 8px !important;
      justify-content: flex-start !important;
    }

    &:nth-child(3) {
      flex: 0 0 209px;
      padding-left: 8px;
    }

    &:nth-child(5) {
      flex: 0 0 120px;
      padding-left: 8px !important;
    }

    &:nth-child(6) {
      flex: 0 0 155px;
      padding-left: 8px !important;
      padding-right: 16px !important;
    }

    &.text-center {
      justify-content: center !important;
    }

    &.activeclass{
      background: #1F2C38;
      borderBottom: "0.5px solid #70A6E0";
    }
    #activeclass2{
      background: #1F2C38;
      borderBottom: "0.5px solid #70A6E0";
    }

    @media (max-width: 1024px) {
      &:nth-child(1) {
        flex: 0 0 211px;
      }

      &:nth-child(2){
        justify-content: flex-start !important;
        flex: 0 0 211px;
      }
      &:nth-child(3){
        justify-content: flex-start !important;
        flex: 0 0 211px !important;
      }
      &:nth-child(4){
        justify-content: flex-start !important;
        flex: 0 0 48px !important;
      }
    }
    
    @media (max-width: 767px) {
      &:nth-child(1){   
        flex: 0 0 113px;     
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
      &:nth-child(2){   
        flex: 0 0 93px;     
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
      &:nth-child(3){ 
        flex: 0 0 102px !important;         
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
    @media (max-width: 380px){
      &:nth-child(1){   
        flex: 0 0 107px;     
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
  }
  th{
    line-height: 16px;
    justify-content: center;
    text-transform: capitalize !important;
    cursor: pointer;
    border: none !important;
    font-weight: 500!important;

    color: ${({ theme }) => theme.bodyText} !important;
    @media (max-width: 767px) {
      font-size:12px !important;
    }
  }
    p, h5, button, span {
      font-weight: 500!important;
      color: ${({ theme }) => theme.bodyText} !important;
    }
  }
  td {
    font-size: 16px !important;
    @media (max-width: 1024px){
      font-size: 14px !important;
    }
    @media (max-width: 767px){
      font-size: 12px !important;
    }
    font-weight: 500;
    h5, button{
      flex-grow: 1;
    }
    height: 100%;

    @media (max-width: 1024px) {
      align-items: center !important;
    }
  }
`;

export { FeesRow };
