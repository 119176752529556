import styled from "styled-components";
import { DisplayStyle } from "./Reusable/Display.style";
import { MarginStyle } from "./Reusable/Margin.style";
import { PaddingStyle } from "./Reusable/Padding.style";
import { PositionStyleCommon } from "./Position/Position";
import { CommanWidthStyle } from "./Reusable/Width.style";
import { BorderCommanStyle } from "./Reusable/Border.style";
import { CommanHeightStyle } from "./Reusable/Height.style";
import { BackgroundCommanStyle } from "./Reusable/Background.style";

const FlexCard = styled.div`
  display: flex;
  
  ${CommanHeightStyle}
  ${CommanWidthStyle}
  ${PositionStyleCommon}
  ${DisplayStyle}
  ${MarginStyle}
  ${PaddingStyle}
  ${BorderCommanStyle}
  ${BackgroundCommanStyle}
`;

export { FlexCard };