export const Script1 = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "LCX",
  url: "https://exchange.lcx.com/",
  email: "hello@lcx.com",
  logo: "https://www.lcx.com/wp-content/uploads/LCX_logo_black-trademark%C2%AE.png",
  address: {
    "@type": "PostalAddress",
    addressCountry: "Liechtenstein",
    addressLocality: "Vaduz",
    addressRegion: "Vaduz",
    streetAddress: "Herrengasse 6 9490"
  },
  sameAs: [
    "https://twitter.com/lcx",
    "https://www.youtube.com/@LCXcom",
    "https://facebook.com/lcx.exchange",
    "https://www.linkedin.com/company/lcx/",
    "https://www.instagram.com/lcx.exchange/"
  ]
};

export const DynamicScript = (title, link) => {
  if (!title) {
    return {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://exchange.lcx.com/"
        }
      ]
    };
  }
  return {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://exchange.lcx.com/"
      },
      {
        "@type": "ListItem",
        position: 2,
        name: String(title).toUpperCase(),
        item: `https://exchange.lcx.com/${link}`
      }
    ]
  };
};
