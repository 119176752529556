/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { ModalWrapper, DivElement, TextWrapper } from "../../../components/Reusable";

import { getApiKeyManagment } from "../../../actions/setting";
import { CloseIcon } from "../../../assets/svgs";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { HeadingSecond } from "../../../assets/StyleComponents/FontSize/headsecond.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";


function CreateApiModalComponent(props) {
  const [mode, setMode] = useState(props.mode === "edit" ? "Edit" : "Create");
  const [editData, setEditdata] = useState(props.editData);
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [tokenData, setTokendata] = useState({});
  const prevLang = localStorage.getItem("ljs-lang");

  return (
    <>
      <ModalWrapper>
        <DivElement ModalContainer>
          <DivElement displayBtw>
            <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
              Create API Key
            </TextWrapper>
            <CloseIcon pointer onClick={() => props.onClose()} />
          </DivElement>

          <DivElement mt="20px">
            {step === 1 && (
              <BannerBox>
                  <HeadingSecond size="24px" mb="`1rem">No 2FA setup</HeadingSecond>
                  <ParaTag size="14px">
                    Please setup Two-factor authentication
                    <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/security`}> here. </Link>
                  </ParaTag>
              </BannerBox>
            )}
          </DivElement>
        </DivElement >
      </ModalWrapper >
    </>
  );
}

const maptoStateProps = (state) => ({
  keyManagementData: state.setting.keyManagementData,
  isFetchingKeyManagementData: state.setting.isFetchingKeyManagementData
});

const mapDispatchToProps = (dispatch) => ({
  getApiKeyManagment: (data) => dispatch(getApiKeyManagment(data))
});

export default connect(maptoStateProps, mapDispatchToProps)(CreateApiModalComponent);
