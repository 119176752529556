import apiConstants from "../../constants/apiConstants";

// Get Anti-phising Code
export const getAntiPhisingCode = () => ({
  type: apiConstants.GET_ANTIPHISING_CODE
});

// Change Anti-phising Code
export const changeAntiPhisingCode = (data) => ({
  type: apiConstants.UPDATE_ANTIPHISING_CODE,
  payload: data
});

//Clear Api Data
export const clearAntiphisingApidata = (data) => ({
  type: apiConstants.CLEAR_ANTIPHISING_API_DATA
});

export const updateUserData = (data) => ({
  type: apiConstants.UPDATE_USER_DATA,
  payload: data
});
