/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { TableBody } from "../../assets/StyleComponents/Table.style";
import { getDepositWithdrawalFee } from "../../lib/api";
import { formatAPIErrors } from "../../utils/general";
import configs from "../../constants/config";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { showErrorMsg } from "../../utils/notification";
import { NoRecordIllustration } from "../../assets/svgs/illustrations";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { Table, Thead, Tr, Th, Tbody, Td, ImgElement } from "../../components/Reusable";

const DepositWithrawal = (props) => {
  const [feeDetails, setFeeDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDepositWithdrawalFee()
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setFeeDetails(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      });
  }, []);

  return (
    <BannerBox
      CardWhite
      width="100%"
      padding="1.5rem 35px"
      paddingMd="1rem 20px"
      BorderRadius="10px"
      margin="0 auto"
      position="relative"
      maxWidth="839px"
    >
      <BannerBox pt="3px" ptMd="11px">
        <BannerHeading size="22px" weight="bold" BodyColor mb="0px">
          {" "}
          Cryptocurrency Deposit and Withdrawal Fees{" "}
        </BannerHeading>
        <BannerBox LeftBoxBadge position="absolute"></BannerBox>
      </BannerBox>

      <BannerBox pt="29px">
        <ParaTag size="18px" weight="300" BodyColor mb="0px">
          Each deposit requires a minimum requirement along with an address setup fee and/or deposit
          fee for certain cryptocurrencies. Deposit of most of the cryptocurrencies doesn’t charge a
          deposit fee. Funds won’t be credited to your LCX account in case of deposit amount being
          below the minimum requirement. Refer to the below mentioned list to see exceptions as well
          as a summary of the minimum requirements and fees.
        </ParaTag>
      </BannerBox>

      <BannerBox pt="22px">
        <BannerBox overflow="visible">
          <Table>
            <Thead borderRadius="5px" BodyColor height="58px">
              <Tr>
                <Th txtGrey textAlign="start" fontSize="14px" paddingLeft="8px">
                  {" "}
                  Coin{" "}
                </Th>
                <Th txtGrey textAlign="end" fontSize="14px">
                  {" "}
                  Deposit Fee{" "}
                </Th>
                <Th txtGrey textAlign="end" fontSize="14px">
                  {" "}
                  Withdrawal fee{" "}
                </Th>
                <Th txtGrey textAlign="end" fontSize="14px" paddingRight="8px">
                  {" "}
                  Min Withdrawal amount{" "}
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {!isLoading && feeDetails && feeDetails.length === 0 && (
                <Tr>
                  <Td colSpan="4" textAlign="center" padding="20px" fontSizeSm="12px">
                    <NoRecordIllustration paddingTop="32px" />
                    <br />
                    No Records Found
                  </Td>
                </Tr>
              )}
              {!isLoading &&
                feeDetails &&
                feeDetails.length > 0 &&
                feeDetails.map((data, index) => (
                  <Tr
                    key={data.coin}
                    height="56px"
                    border={feeDetails.length - 1 !== index ? true : false}
                  >
                    <Td
                      fontSize="16px"
                      fontSizeSm="12px"
                      txtGrey
                      padding="10px 8px"
                      textAlign="start"
                      paddingLeft="10px"
                      display="flex"
                      alignItems="center"
                    >
                      <ImgElement
                        alt={data.coin}
                        top="-2px"
                        position="relative"
                        marginRight="8px"
                        border="0"
                        width="18"
                        height="18"
                        src={`${
                          !props.isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                        }${data.coin.toLowerCase()}.svg`}
                        loading="lazy"
                      />
                      <BannerHeadingSpan> {data.coin} </BannerHeadingSpan>
                    </Td>
                    <Td textAlign="end" txtGrey fontSizeSm="12px">
                      {data.depositFee}
                    </Td>
                    <Td textAlign="end" txtGrey fontSizeSm="12px">
                      {" "}
                      {`${data.withdrawalFee} ${data.coin}`}{" "}
                    </Td>
                    <Td textAlign="end" txtGrey fontSizeSm="12px" paddingRight="8px">
                      {" "}
                      {`${data.minWithdrawalLimit} ${data.coin}`}{" "}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
};

export default DepositWithrawal;
