import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { BannerBox } from "../../assets/StyleComponents/Exchange.style";

const SecondRoot = ({ children, isTheme }) => {
  return ReactDOM.createPortal(
    <BannerBox position="fixed" width="100%" height="100vh" top="0" left="0" zIndex="1000000">
      {children}
    </BannerBox>,
    document.getElementById("secondRoot")
  );
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme
});

export default compose(connect(mapStateToProps))(SecondRoot);
