import React, { Fragment, useState } from "react";
import { DivElement, ElementBox, TextWrapper } from "../../../../../components/Reusable";
import InvestmentHistoryTable from "../../Components/InvestmentHistoryTable";
import ArticleContainer from "./ArticleContainer";
import BondDetailPoints from "./BondDetailPoints";
import ReturnCalculator from "../ReturnCalculator";
import Prospectus from "../Prospectus";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";

const Title = styled(TextWrapper)`
  padding-left: 4px;
  span {
    font-size: 36px;
    @media (max-width: 767px) {
      font-size: 28px;
    }
  }
`;

const NonDesktopView = ({ bondDetails, bondSummary, isLoggedIn }) => {
  const [activeTab, setActiveTab] = useState("Summary");
  const { bondName, isin, aboutIssuanceAmount, coin, min, max } = bondSummary;
  const TABS = ["Summary", "About"];
  if (isLoggedIn) {
    TABS.push("Investment History");
  }
  return (
    <ElementBox
      padding="0"
      minHeight="640px"
      minHeightSm="560px"
      width="100%"
      border
      EarnPageHeader
    >
      <DivElement
        padding="10px 25px 0 25px"
        paddingSm="9px 11px 0 11px"
        bgCream
        borderRadius="6px 6px 0 0"
      >
        <Title fontSize="24px" fontSizeSm="20px">
          <span>{bondSummary.bondName}</span> Tokenized Bond
        </Title>
        <DivElement className="bonddetail__header" marginTop="14px" padding="0">
          {TABS.map((tab, i) => (
            <DivElement
              key={i}
              className={`bonddetail__navlink ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </DivElement>
          ))}
        </DivElement>
      </DivElement>
      <DivElement
        padding="32px 30px"
        paddingSm="38px 16px"
        height="532px"
        heightSm="507px"
        scrollY={activeTab === "About" || activeTab === "Investment History" ? true : null}
      >
        {activeTab === "Summary" && (
          <Fragment>
            <BondDetailPoints bondSummary={bondSummary} />
            <ArticleContainer />
          </Fragment>
        )}

        {activeTab === "About" && (
          <Fragment>
            <ReturnCalculator bondDetails={bondDetails} />
            <TextWrapper
              fontSize="16px"
              fontSizeSm="14px"
              lineHeight="1.5"
              lineHeightSm="1.71"
              marginTop="28px"
            >
              LCX issues a {aboutIssuanceAmount}Tokenized Bond. Security prospectus published and
              endorsed by the regulator. Available in the European Economic Area (EEA), making the
              new fixed-yield tokenized bond available to verified LCX users in 30 countries
              including Austria, Belgium, Bulgaria, Croatia, Cyprus, Denmark, Estonia, Finland,
              France, Germany, Greece, Hungary, Iceland, Ireland, Italy, Latvia, Liechtenstein,
              Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania,
              Slovakia, Slovenia, Spain, Sweden, and Czech Republic.
              <br />
              <br />
              {bondName} Tokenized Bond Summary Issuer: <br />
              LCX AG, Liechtenstein Issuance Amount: {max} <br />
              {coin} Coupon: 7% per annum <br />
              ISIN: {isin} <br />
              Denomination: {coin} {min}. The minimum consideration is {coin} {min}. <br />1{" "}
              {bondName} = {min} {coin}, Purchase only in whole, no fractional. <br />
              Maturity / Term: 7 Years max. Quarterly termination possible. <br />
              Governing Law: Liechtenstein <br />
              Blockchain: Ethereum public blockchain <br />
            </TextWrapper>
            <Prospectus bondName={bondDetails ? bondDetails.bondName : ""} />
          </Fragment>
        )}

        {activeTab === "Investment History" && isLoggedIn && (
          <InvestmentHistoryTable bondDetails={bondDetails} />
        )}
      </DivElement>
    </ElementBox>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default compose(connect(mapStateToProps))(NonDesktopView);
