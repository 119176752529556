/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
// import { submitIdProof, validateDocument } from "../../../../lib/api";
import { getUserDetails } from "../../../../actions/dashboard";
import { formatAPIErrors } from "../../../../utils/general";
// import WebCamCapture from "../WebCamCapture";
// import Uploader from "../Uploader";
// import KycFAQ from "../KycFAQ";
import "react-day-picker/dist/style.css";
import "react-phone-input-2/lib/style.css";
// import KycStepButton from "./KycStepBtn";
// import { TextWrapper } from "../../../Reusable";
// import { getIdValueFromLabel } from "../../../../utils";
import { showErrorMsg } from "../../../../utils/notification";
// import { ErrorIcon } from "../../../../assets/svgs";
import Step1b from "./KycStep1b";
import { currentSubmissionList, getSourceList } from "../../../../lib/api";
import ErrorList from "./ErrorList";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import AccordianErrorList from "./AccordianErrorList";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { KycSection } from "../../../../assets/StyleComponents/kyc.style";
import SkeletonLoader from "../../../SkeletonLoader";

const KycStep2 = ({ match, getUserData, isDarkTheme, guidlinesModal, userData }) => {
  const [step, setStep] = useState(+localStorage.getItem("kycStepLastLevel") || 1);
  const [sourceList, setSourceList] = useState("");
  const [submissionList, setSubmissionList] = useState("");
  const { width } = useWindowSize();
  let rejectionListLength =
    submissionList?.transactionRisk?.submittedInfo?.action?.partialRejectionReason || [];

  useEffect(() => {
    let step = localStorage.getItem("kycStepLastLevel");

    if (!step) {
      localStorage.setItem("kycStepLastLevel", 1);
    }

    currentSubmissionList()
      .then((res) => {
        setSubmissionList(res.data.data);
      })
      .catch((err) => {});

    getSourceList()
      .then((res) => {
        setSourceList(res && res.data.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });

    // eslint-disable-next-line
  }, []);

  const increaseStep = () => {
    localStorage.setItem("kycStepLastLevel", step + 1);
    setStep(step + 1);
  };

  return (
    <>
      {width < 768 && rejectionListLength.length > 0 && (
        <AccordianErrorList
          errorType="sof"
          submissionList={submissionList?.transactionRisk?.submittedInfo?.action || {}}
        />
      )}
      <KycSection StepWrapperSection>
        <BannerBox
          className={`element-box ${
            rejectionListLength.length > 0
              ? "stepWrapper__container"
              : "stepWrapper__containerLastLevel"
          } `}
          maxWidth={rejectionListLength.length > 0 ? "926px" : "604px"}
          margin="0 auto"
        >
          <KycSection StepContainerLVL3>
            <div className="stepWrapper__form" style={{ width: "100%" }}>
              <KycSection KYCS1>
                <div className="kycS1">
                  {width > 767 && width < 1024 && rejectionListLength.length > 0 && (
                    <AccordianErrorList
                      errorType="sof"
                      submissionList={submissionList?.transactionRisk?.submittedInfo?.action || {}}
                    />
                  )}

                  {sourceList && (
                    <Step1b
                      currentStep={step}
                      increaseStep={increaseStep}
                      submissionList={submissionList}
                      sourceList={sourceList}
                    />
                  )}
                </div>
              </KycSection>
            </div>
          </KycSection>

          {rejectionListLength.length > 0 && (
            <ErrorList
              errorType="sof"
              submissionList={submissionList?.transactionRisk?.submittedInfo?.action || {}}
            />
          )}
        </BannerBox>
      </KycSection>

      {/* <BannerBox
        className={`element-box ${
          rejectionListLength.length > 0
            ? "stepWrapper__container"
            : "stepWrapper__containerLastLevel"
        } `}
        maxWidth={rejectionListLength.length > 0 ? "926px" : "604px"}
        margin="0 auto"
      >
        <div className="stepWrapper__form w-100">
          <div className="kycS1">
            {width > 767 && width < 1024 && rejectionListLength.length > 0 && (
              <AccordianErrorList
                errorType="sof"
                submissionList={submissionList?.transactionRisk?.submittedInfo?.action || {}}
              />
            )}
            <Step1b
              currentStep={step}
              increaseStep={increaseStep}
              submissionList={submissionList}
              sourceList={sourceList}
            />
          </div>
        </div>

        {rejectionListLength.length > 0 && (
          <ErrorList
            errorType="sof"
            submissionList={submissionList?.transactionRisk?.submittedInfo?.action || {}}
          />
        )}
      </BannerBox> */}
    </>
  );
};

const maptoStateProps = (state) => ({
  userData: state.dashboard.userData,
  isDarkTheme: state.theme.isDarkTheme
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: () => dispatch(getUserDetails())
});

export default compose(connect(maptoStateProps, mapDispatchToProps))(withRouter(KycStep2));
