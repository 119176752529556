/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { getWalletBalance, getAllWallets } from "../../../lib/api";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { WalletContext } from "../../../contexts/WalletPage/BalancePage/wallet";
import { debounce } from "../../../utils/helpers";
import { sortNumberData } from "../../../utils/general";
import HideToggleComponent from "../../../components/ReusedComponent/HideEyeToggle";
import TotalBalance from "./totalBalance";
import DashboardTableList from "./dahboardTable";
import DashboardChartData from "./dashboardChart";
import { useDispatch, useSelector } from "react-redux";
import { bool } from "prop-types";
import { createStructuredSelector } from "reselect";
import { getAccountWallet, getExchangeWallet, getisFetchingAccountWallet, getisFetchingExchangeWallet } from "../../../Modules/Wallet/WalletBalance/selectors";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";

const DashboardBalance = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [activePlan, setActivePlan] = useState("list");
  const [isLoaderWallet, setisLoaderWallet] = useState(false);
  const [isLoaderExch, setisLoaderExch] = useState(false);
  let { hideStatus, setHideStatus } = useContext(WalletContext);

  const { isFetchingAccounts, isFetchingExchange, apiAccountList, apiExchangeList } = useSelector(mapStateToProps);

  const hideStringValue = debounce(() => {
    setHideStatus(!hideStatus);
  }, 700);
  
  // if(isLoaderExch || isLoaderWallet) return null;
  return (
    <BannerBox
      CardWhite
      DashboardBordersdark
      radius={"10px"}
      padding={"32px 32px 32px"}
      paddingLg={"24px 20px 48px"}
      paddingMd={"16px 12px 40px"}
      mb={"16px"}
    >
      <HideToggleComponent {...props} />

      <BannerBox
        gridTemplateColums
        gridColumn="minmax(55%, 776px) 1fr"
        gridColumnLg="1fr"
        gridGap="0 10px"
        mt="27px"
        mtLg="24px"
        mtMd="16px"
      >
        <TotalBalance isLoaderWallet={isLoaderWallet} isLoaderExch={isLoaderExch} {...props} />

        <BannerBox
          radd={"5px"}
          padd="19px 32px"
          mr="32px"
          mb="8px"
          mrLg="8px"
          mrMd="4px"
          mtLg="18px"
          mtMd="8px"
          display="flex"
          alignItems="flex-end"
          justifyContent={"flex-end"}
        >
          <ParaTag 
            cursor="pointer" 
            size="14px" 
            mr="20px" 
            padding="0 8px"
            activeBorder={activePlan === "chart" ? true : null}
            onClick={() => setActivePlan("chart")}
          >
            Chart
          </ParaTag>

          <ParaTag cursor="pointer" size="14px" 
            padding="0 8px"
            activeBorder={activePlan === "list" ? true : null}
            onClick={() => setActivePlan("list")}
          >
            List
          </ParaTag>
        </BannerBox>
      </BannerBox>

      <BannerBox
        gridTemplateColums
        gridColumn="1fr 1fr"
        gridColumnMd="1fr"
        mt="16px"
        mtLg="6px"
        gridColumnGap="20px"
        gridColumnGapLg="16px"
        gridGapMd="55px"
      >
        {activePlan === "list" && (
          <>
            <DashboardTableList
              sent="account"
              title={"Main"}
              rowData={apiAccountList}
              activePlan={activePlan}
              isLoaderWallet={isLoaderWallet}
              isLoaderExch={isLoaderExch}
              {...props}
            />

            <DashboardTableList
              sent="exchange"
              title={"Exchange"}
              rowData={apiExchangeList}
              // rowData={[]}
              activePlan={activePlan}
              isLoaderWallet={isLoaderWallet}
              isLoaderExch={isLoaderExch}
              {...props}
            />
          </>
        )}

        {activePlan === "chart" && (
          <>
            <DashboardChartData
              sent="account"
              title={"Main"}
              rowData={apiAccountList}
              activePlan={activePlan}
              isLoaderWallet={isFetchingAccounts}
              isLoaderExch={isFetchingExchange}
              {...props}
            />

            <DashboardChartData
              sent="exchange"
              title={"Exchange"}
              rowData={apiExchangeList}
              activePlan={activePlan}
              isLoaderWallet={isFetchingAccounts}
              isLoaderExch={isFetchingExchange}
              {...props}
            />
          </>
        )}
      </BannerBox>
    </BannerBox>
  );
};

DashboardBalance.propTypes = {
  isFetchingAccounts: bool.isRequired,
  isFetchingExchange: bool.isRequired
}; 

const mapStateToProps = createStructuredSelector({
  isFetchingAccounts: getisFetchingAccountWallet(),
  isFetchingExchange: getisFetchingExchangeWallet(),
  apiAccountList: getAccountWallet(),
  apiExchangeList: getExchangeWallet()
});

export default DashboardBalance;
