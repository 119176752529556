import { ModalWrapper, DivElement, TextWrapper } from "../../../Reusable";
import { logoutfromdevice } from "../../../../lib/api";
import { formatAPIErrors } from "../../../../utils/general";
import { useState } from "react";
import { capitalize } from "lodash";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";

const CloseSessionModal = ({ onClose, activeSessionData, getSessions }) => {
  const [loader, setLoader] = useState(false);

  const onCloseSession = () => {
    if (loader) return;
    setLoader(true);
    let data = {};

    if (activeSessionData.allOther) {
      data.allOther = true;
    } else {
      data.sessionId = activeSessionData.sessionId;
    }
    logoutfromdevice(data)
      .then((res) => {
        showSuccessMsg((res && res.data.message) || "");
        getSessions();
        onClose();
      })
      .catch((err) => showErrorMsg(formatAPIErrors(err)))
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <TextWrapper fontSize="20px" fontWeight="bold">
          Close{" "}
          {activeSessionData?.allOther
            ? "all sessions"
            : `session on ${capitalize(
                activeSessionData?.recentLogin?.userAgent?.device || "Unknown"
              )}?`}
        </TextWrapper>

        <TextWrapper fontSize="16px" secondary marginTop="20px">
          This will remove access to{" "}
          {activeSessionData?.allOther
            ? "all other devices except your current device"
            : "your LCX Account from the device"}
        </TextWrapper>

        <DivElement flex justifyContent="flex-end" marginTop="24px">
          <TextWrapper cursor fontSize="16px" marginRight="24px" onClick={onClose}>
            Cancel
          </TextWrapper>
          <TextWrapper cursor fontSize="16px" blue onClick={onCloseSession}>
            {activeSessionData?.allOther ? "Close All Sessions" : "Close Session"}
          </TextWrapper>
        </DivElement>
      </DivElement>
    </ModalWrapper>
  );
};

export default CloseSessionModal;
