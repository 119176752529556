/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  DateRangePicker,
  DivElement,
  ReactMultiSelectDropdown,
  TextWrapper
} from "../../../../components/Reusable";
import Select from "react-select";
import { reactDropdownStyles } from "../../../../utils";
import { useSelector } from "react-redux";
import { DropdownIndicator } from "../../../../components/SelectDropDown/selectDropDown";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../../constants/styledConstants";

const BONDS = [
  { label: "LCXt7", value: "LCXt7", quoteCoin: "LCX" },
  { label: "BTCt7", value: "BTCt7", quoteCoin: "BTC" },
  { label: "EURt7", value: "EURt7", quoteCoin: "EUR" },
  { label: "ETHt7", value: "ETHt7", quoteCoin: "ETH" }
];

const TYPES = [
  { label: "Investment", value: "INVESTMENT" },
  { label: "Cancellation", value: "CANCELLATION" }
];

const HistoryTableFilter = ({ onApplyFilterHandler }) => {
  const [selectedBonds, setSelectedBonds] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [date, setDate] = useState({ fromDate: null, toDate: null });
  const [showClearAll, setShowClearAll] = useState(false);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const { width } = useWindowSize();

  useEffect(() => {
    if (selectedBonds) onApplyFilter();
  }, [selectedBonds]);

  useEffect(() => {
    if (selectedType) onApplyFilter();
  }, [selectedType]);

  useEffect(() => {
    if (date.fromDate && date.toDate) onApplyFilter();
  }, [date]);

  useEffect(() => {
    let flag = false;
    if (
      (selectedBonds && selectedBonds.length > 0) ||
      selectedType ||
      date.fromDate ||
      date.toDate
    ) {
      flag = true;
    }
    setShowClearAll(flag);
  }, [selectedBonds, selectedType, date]);

  const onClearAll = () => {
    setSelectedBonds("");
    setSelectedType("");
    setDate({ fromDate: null, toDate: null });
    setShowClearAll(false);
    onApplyFilterHandler({ page: 0 });
  };

  const onApplyFilter = () => {
    let filters = {
      page: 0
    };

    if (selectedBonds && selectedBonds.length > 0) {
      let bondNames = [];
      selectedBonds.forEach((item) => bondNames.push(item.value));
      filters.bondName = bondNames;
    }

    if (selectedType) {
      filters.type = selectedType.value;
    }

    if (date.fromDate && date.toDate) {
      filters.fromDate = date.fromDate;
      filters.toDate = date.toDate;
    }

    if (Object.keys(filters).length > 1) {
      onApplyFilterHandler(filters);
    }
  };

  const isTablet = width <= styledConstants.BREAKING_POINT_TABLET;

  return (
    <DivElement marginBottom="32px" maxWidth="792px" maxWidthMd="376px" maxWidthSm="100%">
      <DivElement flexJustifyBtwAlignCenter marginBottom="4px">
        <TextWrapper
          secondary
          fontSize="14px"
          fontSizeSm="12px"
          lineHeight="20px"
          lineHeightSm="18px"
        >
          Filters
        </TextWrapper>
        {showClearAll && (
          <TextWrapper
            red
            cursor
            fontSize="14px"
            fontSizeSm="12px"
            lineHeight="20px"
            lineHeightSm="18px"
            textDecoration="underline"
            onClick={onClearAll}
          >
            Clear all
          </TextWrapper>
        )}
      </DivElement>
      <DivElement flexJustifyBtw flexWrap="wrap">
        <DivElement width="180px" widthSm="calc(50% - 8px)" marginBottomMd="15px">
          <ReactMultiSelectDropdown
            setSelectedOptions={setSelectedBonds}
            options={BONDS}
            containerProps={{ width: "100%" }}
            placeholder="Select Bond"
            value={selectedBonds}
          />
        </DivElement>
        {!isTablet && (
          <DateRangePicker
            date={date}
            setDate={setDate}
            containerProps={{ width: "180px", widthSm: "calc(50% - 8px)" }}
          />
        )}
        <DivElement width="180px" widthSm="calc(50% - 8px)" marginBottomMd="15px">
          <Select
            defaultValue={[]}
            placeholder="Select Type"
            options={TYPES}
            value={selectedType}
            components={{ DropdownIndicator }}
            styles={reactDropdownStyles({
              isDarkTheme,
              width: "100%",
              borderBottom: true,
              placeholderProps: { fontSize: "14px" },
              valueContainerProps: { fontWeight: "500" },
              menuProps: { zIndex: 2 },
              showActiveState: selectedType ? true : false
            })}
            onChange={setSelectedType}
          />
        </DivElement>

        {isTablet && (
          <DateRangePicker
            date={date}
            setDate={setDate}
            containerProps={{ width: "180px", widthSm: "calc(50% - 8px)" }}
          />
        )}
      </DivElement>
    </DivElement>
  );
};

export default HistoryTableFilter;
