import React, { Fragment } from "react";
import { TimerBlueIcon, TimerOrangeIcon } from "../../../../../assets/svgs";

const TimerWrapper = (props) => {
  return (
    <div className="col1__saletimer__timebox">
      <span className="saletimer__timebox__value">{props.value}</span>
      <span className="saletimer__timebox__title">{props.title}</span>
    </div>
  );
};

const CountdownRenderer = ({ hours, minutes, days, seconds, completed }, status) => {
  return (
    <Fragment>
      <div className="col1__saletimer__title">
        {completed ? <TimerOrangeIcon /> : <TimerBlueIcon />}
        <span>
          {`SALE ${
            completed
              ? status === "UPCOMING"
                ? "STARTED"
                : "ENDED"
              : status === "UPCOMING"
              ? " STARTS IN"
              : " ENDS IN"
          }`}
        </span>
      </div>
      <div className="col1__saletimer__container" style={{ color: "#3e4b5b" }}>
        <TimerWrapper value={completed ? "00" : days} title="DAY" />
        <TimerWrapper value={completed ? "00" : hours} title="HOUR" />
        <TimerWrapper value={completed ? "00" : minutes} title="MIN" />
        <TimerWrapper value={completed ? "00" : seconds} title="SEC" />
      </div>
    </Fragment>
  );
};

export default CountdownRenderer;
