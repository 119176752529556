import React, { useEffect, useState } from "react";
import { DivElement } from "../../../components/Reusable";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
} from "../../../assets/StyleComponents/Exchange.style";
import { HeadingSecond } from "../../../assets/StyleComponents/FontSize/headsecond.style";
import { Label, ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { ChevronLeftIcon, NotificationOTPIcon } from "../../../assets/svgs";
import OtpInput from "react-otp-input";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { useSelector, useDispatch } from "react-redux";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import BottomBarComponent from "../../../components/Reusable/ModalWrapper/bottomBarModule";
import { recaptchaLoginOtp } from "../../../actions/auth";
import { config } from "../../../constants";
import { getEmailOtp } from "../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { formatAPIErrors } from "../../../utils/general";
import { debounce } from "../../../utils/helpers";
import Countdown from "react-countdown";
// import { recaptchaLoginOtp } from "../../../lib/api";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { HeadingSix } from "../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { RegisterLightMarketingBanner, LightThemeRegister } from "../../../assets/png";
// import Countdown from "react-countdown";
// import BottomBar from "../../../components/Reusable/ModalWrapper/BottomBar";

function RegisterStep2(props) {
  const { width } = useWindowSize();
  const isTheme = useSelector((state) => state.theme.isTheme);
  const [twoFaCode, setTwoFaCode] = useState("");
  const [timer, setTimer] = useState(30);
  const [resend, setResend] = useState(false);
  const [loading, setLoading] = useState(false);
  // const history = useHistory();
  const dispatch = useDispatch();
  let authCode = useSelector((state) => state.auth.regisgterOtpCode);
  let givenError = useSelector((state) => state.auth.error);

  useEffect(() => {
    if (givenError) {
      showErrorMsg(formatAPIErrors(givenError));
    }
  }, [givenError]);

  const OtpInputStyle = {
    width: width > 767 ? "50px" : "40px",
    height: width > 767 ? "48px" : "44px",
    background: isTheme ? "#1f2c38" : "#f5f6fa",
    color: isTheme ? "#f5f6fa" : "#000",
    border: "none",
    borderRadius: "5px 5px 0 0",
    fontSize: width > 767 ? "24px" : "16px",
    borderBottom: isTheme ? "1px solid #969799" : "1px solid #8c9797",
    outline: "none"
  };

  const handleSubmit = () => {
    if (!twoFaCode || twoFaCode.length < 6) return;

    setLoading(true);

    let params = {
      otpCode: authCode,
      otp: twoFaCode,
      domain: "exchange",
      debug: config.debug ? "true" : null
    };

    dispatch(recaptchaLoginOtp(params));
    // recaptchaLoginOtp(params)
    //   .then((res) => {

    //     history.push("/dashboard");
    //   })
    //   .catch((err) => {
    //     showErrorMsg(formatAPIErrors(err));
    //   });

    setLoading(false);
  };

  const TimerFunc = () => {
    setTimer(Date.now() + 30000);
    setResend(true);
  };

  useEffect(() => {
    TimerFunc();
  }, []);

  const callApi = debounce(() => {
    // if (resend) {
    let data = {
      otpCode: authCode
    };
    TimerFunc();
    getEmailOtp(data)
      .then((res) => {
        showSuccessMsg(res.data.message);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  }, 1000);

  if (!authCode) return null;
  const CommonFunction = (setStep) => {
    return (
      <BannerSection display="flex" gap="20px">
        <BannerBox CardWhite radius="10px" height={"700px"} width="55%" displayLg="none">
          <BannerBox
            display="flex"
            height="100%"
            direction="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            margin="0px auto"
            gap="20px"
            style={{
              background: `url(${isTheme ? RegisterLightMarketingBanner : LightThemeRegister})`
            }}
          ></BannerBox>
        </BannerBox>
        <BannerBox
          width="45%"
          widthLg="100%"
          CardWhite
          radius="10px"
          height={"700px"}
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems="space-between"
          position="relative"
        >
          <BannerBox
            position="absolute"
            top="0px"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bottomBorder
            padding="18px 0px"
          >
            <ButtonComponent
              DisabledCancelBtn2
              width="86px"
              btnHeight="28px"
              display="flex"
              alignItems="center"
              position="absolute"
              top="20px"
              left="10px"
              onClick={() => setStep(1)}
            >
              <ChevronLeftIcon />
              back
            </ButtonComponent>
            <HeadingSecond size="24px" weight="500">
              Verify Email
            </HeadingSecond>
            <BannerBox></BannerBox>
          </BannerBox>
          <ParaTag width="80%" margin="0 auto">
            We sent an email to{" "}
            <BannerHeadingSpan weight="bold">
              <var>{props.email}</var>
            </BannerHeadingSpan>
            . To activate your account, please open your email and paste the code below.
          </ParaTag>

          <BannerBox display="flex" alignItems="center" justifyContent="center">
            <NotificationOTPIcon width="200px" height="200px" />
          </BannerBox>

          {/* {width < 767 && ( */}
          <Label mb="8px" sizeMd="14px" txtGrey weight="500" size="16px" ml="15%" mlMd="10%">
            {" "}
            Enter OTP Code{" "}
          </Label>
          {/* )} */}
          <BannerBox
            display="flex"
            direction="column"
            mt="24px"
            justifyContent="center"
            alignItems="center"
            alignItemsMd="center"
            margin="0px auto"
            width="80%"
            widthLg="64%"
            widthMd="90%"
          >
            {/* {width > 767 && (
              <Label mb="8px" sizeMd="14px" txtGrey weight="500" size="16px">
                {" "}
                Enter OTP Code{" "}
              </Label>
            )} */}
            <OtpInput
              inputStyle={OtpInputStyle}
              value={twoFaCode}
              onChange={(e) => setTwoFaCode(e)}
              numInputs={6}
              isInputNum={true}
              style={{ width: "48px", height: "48px" }}
              separator={
                <BannerHeadingSpan mr="8px" mrLg="10px">
                  {" "}
                </BannerHeadingSpan>
              }
            />
            {resend ? (
              <ParaTag mt="24px" weight="500" size="14px" textAlign="center">
                <Countdown
                  date={timer}
                  renderer={({ seconds }) => (
                    <HeadingSix textAlign="center" size="14px" weight="500">
                      Didn't receive email?{" "}
                      <BannerHeadingSpan textDecoration="underline" txtGrey={seconds !== 30}>
                        resend code
                      </BannerHeadingSpan>{" "}
                      in {seconds} seconds
                    </HeadingSix>
                  )}
                  onComplete={() => setResend(false)}
                />
              </ParaTag>
            ) : (
              <ParaTag mt="24px" weight="500" size="14px" textAlign="center">
                Didn't receive email?{" "}
                <BannerHeadingSpan
                  txtPrimary
                  cursorPointer="pointer"
                  onClick={callApi}
                  textDecoration="underline"
                >
                  resend code
                </BannerHeadingSpan>{" "}
                now
              </ParaTag>
            )}
            <ButtonComponent
              cursor={!twoFaCode || twoFaCode.length < 6 ? "not-allowed" : "pointer"}
              width="100%"
              mt="16px"
              PrimaryButton
              disabled={loading || !twoFaCode || twoFaCode.length < 6 ? true : false}
              onClick={handleSubmit}
            >
              Activate Account
            </ButtonComponent>
          </BannerBox>
        </BannerBox>
      </BannerSection>
    );
  };

  const CommonFunction2 = () => {
    return (
      <BannerBox mt="32px">
        <ParaTag>
          We sent an email to{" "}
          <BannerHeadingSpan weight="bold" className="email_address_user_localise">
            <var>{props.email}</var>
          </BannerHeadingSpan>
          . To verify your account, please open your email and paste the code below.
        </ParaTag>

        <BannerBox display="flex" alignItems="center" justifyContent="center">
          {/* <ImgElement src={RegisterOTP} width="200px" height="200px" /> */}
          <NotificationOTPIcon width="200px" height="200px" />
        </BannerBox>

        {width < 767 && (
          <Label mb="8px" sizeMd="14px" txtGrey weight="500" size="16px" ml="10%">
            {" "}
            Enter OTP Code{" "}
          </Label>
        )}
        <BannerBox
          display="flex"
          direction="column"
          mt="24px"
          justifyContent="center"
          alignItemsMd="center"
          alignItems="center"
          margin="0px auto"
          width="52%"
          widthLg="64%"
          widthMd="90%"
        >
          {width > 767 && (
            <Label mb="8px" sizeMd="14px" txtGrey weight="500" size="16px">
              {" "}
              Enter OTP Code{" "}
            </Label>
          )}
          <OtpInput
            inputStyle={OtpInputStyle}
            value={twoFaCode}
            onChange={(e) => setTwoFaCode(e)}
            numInputs={6}
            isInputNum={true}
            style={{ width: "48px", height: "48px" }}
            separator={
              <BannerHeadingSpan mr="8px" mrLg="10px">
                {" "}
              </BannerHeadingSpan>
            }
          />
          {resend ? (
            <ParaTag mt="24px" weight="500" size="14px" textAlign="center">
              <Countdown
                date={timer}
                renderer={({ seconds }) => (
                  <HeadingSix textAlign="center" size="14px" weight="500">
                    Didn't receive email?{" "}
                    <BannerHeadingSpan textDecoration="underline" txtGrey={seconds !== 30}>
                      resend code
                    </BannerHeadingSpan>{" "}
                    in {seconds} seconds
                  </HeadingSix>
                )}
                onComplete={() => setResend(false)}
              />
            </ParaTag>
          ) : (
            <ParaTag mt="24px" weight="500" size="14px" textAlign="center">
              Didn't receive email?{" "}
              <BannerHeadingSpan
                txtPrimary
                cursorPointer="pointer"
                onClick={callApi}
                textDecoration="underline"
              >
                resend code
              </BannerHeadingSpan>{" "}
              now
            </ParaTag>
          )}
          <ButtonComponent
            cursor={!twoFaCode || twoFaCode.length < 6 ? "not-allowed" : "pointer"}
            width="100%"
            mt="16px"
            PrimaryButton
            disabled={loading || !twoFaCode || twoFaCode.length < 6 ? true : false}
            onClick={handleSubmit}
          >
            Verify Email
          </ButtonComponent>
        </BannerBox>
      </BannerBox>
    );
  };

  return (
    <>
      {width > 767 && (
        <DivElement pageContainer display="flex" flexDirection="column" alignItems="center">
          <BannerBox width="100%">
            {CommonFunction(props.setStep)}
            {/* ++++++++++++++++ */}
          </BannerBox>
        </DivElement>
      )}
      {width < 767 && (
        <BottomBarComponent heading="Activate Account" onClose={props.onClose}>
          {CommonFunction2()}
        </BottomBarComponent>
      )}
    </>
  );
}

export default RegisterStep2;
