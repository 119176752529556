import React, { Fragment, useState } from "react";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import OtpInput from "react-otp-input";
import { BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { DivElement, ImgElement, ModalWrapper, TextWrapper } from "../../../components/Reusable";
import { CloseIcon } from "../../../assets/svgs";
import { LightTheme2fa } from "../../../assets/png";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { useSelector } from "react-redux";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { setup2FA } from "../../../lib/api";

function DisableStepModal(props) {
  const { width } = useWindowSize();
  const isTheme = useSelector((state) => state.theme.isTheme);
  const [code, setCode] = useState("");

  const OtpInputStyle = {
    width: width > 767 ? "53px" : "40px",
    height: width > 767 ? "48px" : "44px",
    background: isTheme ? "#1f2c38" : "#f5f6fa",
    color: isTheme ? "#f5f6fa" : "#000",
    border: "none",
    borderRadius: "5px 5px 0 0",
    fontSize: width > 767 ? "24px" : "16px",
    borderBottom: isTheme ? "1px solid #969799" : "1px solid #8c9797",
    outline: "none"
  };

  function handleDisable2fa() {
    if (!code) {
      showErrorMsg("Please enter 2FA code", { autoClose: 7000 });
      return false;
    }
    let data = {
      token: Number(code),
      status: false
    };

    setup2FA(data)
      .then((res) => {
        if (res.data.status === "success") {
          // eslint-disable-next-line react/no-direct-mutation-state
          showSuccessMsg("2FA Disabled");
          props.onClose();
          props.getUserData();
        }
      })
      .catch((err) => {
        setCode("");
        showErrorMsg(err.response.data.message, { autoClose: 7000 });
      });
  }

  return (
    <Fragment>
      <ModalWrapper>
        <DivElement ModalContainer>
          <DivElement displayBtw>
            <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
              Disabling 2FA
            </TextWrapper>
            <CloseIcon pointer onClick={props.onClose} />
          </DivElement>

          <BannerBox display="flex" direction="column" mt="24px">
            <BannerBox margin="32px auto">
              <ImgElement src={LightTheme2fa} width="160px" height="100px" />
            </BannerBox>
            <BannerHeading mb="8px" sizeMd="14px" txtGrey weight="300" size="16px">
              {" "}
              Enter 2FA Code{" "}
            </BannerHeading>
            <OtpInput
              inputStyle={OtpInputStyle}
              value={code}
              onChange={(e) => setCode(e)}
              numInputs={6}
              isInputNum={true}
              style={{ width: "48px", height: "48px" }}
              separator={
                <BannerHeadingSpan mr="8px" mrLg="10px">
                  {" "}
                </BannerHeadingSpan>
              }
            />
          </BannerBox>

          <BannerBox
            display="flex"
            alignItems="center"
            justifyContent="start"
            gap="32px"
            gapMd="16px"
            mt="32px"
          >
            <ButtonComponent
              OutlineDanger
              onClick={() => handleDisable2fa()}
              width="100%"
              sizeMd="14"
              btnHeight="44px"
              type="button"
            >
              Disable 2FA
            </ButtonComponent>
          </BannerBox>
        </DivElement>
      </ModalWrapper>
    </Fragment>
  );
}

export default DisableStepModal;
