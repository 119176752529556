import { Route, Switch, Redirect } from "react-router-dom";
import MobileTvChart from "../views/MobileTvChart";
import MobileDepthChart from "../views/MobileDepthChart/MobileDepthChart";

const MobileChartRouter = () => (
  <Switch>
    <Route exact path="/mobile-depth-chart" render={() => <Redirect to="/mobile-depth-chart/LCX-EUR" />} />
    <Route exact path="/mobile-trade" render={() => <Redirect to="/mobile-trade/LCX-EUR" />} />
    <Route exact path="/mobile-trade/:pair" component={MobileTvChart} />
    <Route exact path="/:lang/mobile-trade/:pair" component={MobileTvChart} />
    <Route exact path="/mobile-depth-chart/:pair" component={MobileDepthChart} />
    <Route exact path="/:lang/mobile-depth-chart/:pair" component={MobileDepthChart} />
    <Route path="*" render={() => <Redirect to="/mobile-trade/" />} />
  </Switch>
);

export default MobileChartRouter;
