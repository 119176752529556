import React from "react";
import { DivElement, TextWrapper, Checkbox } from "../../../../components/Reusable";
import { AnchorTag } from "../../../../assets/StyleComponents/FontSize/para.style";

const TAndC = ({ checked, setChecked }) => {
  return (
    <DivElement display="flex" margin="20px 14px" marginSm="20px 8px">
      <Checkbox checked={checked} setChecked={setChecked} />
      <TextWrapper fontWeight="300" marginLeft="8px" fontSizeSm="12px" lineHeightSm="1.5">
        By clicking invest, you read and agree to the prospectus issuance{" "}
        <AnchorTag
          className="blue-font"
          txtPrimary
          href="https://www.lcx.com/wp-content/uploads/EN_2023-02-01_Endgultige_Bedingungen_Nr_01_LCX-EURt7.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms and conditions
        </AnchorTag>
        ,{" "}
        <AnchorTag
          className="blue-font"
          txtPrimary
          href="https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Registrierungsformular_LCX-EURt7.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          registration document
        </AnchorTag>
        ,{" "}
        <AnchorTag
          className="blue-font"
          txtPrimary
          href=" https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Wertpapierbeschreibung_LCX-EURt7_signed.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          security note
        </AnchorTag>
        .
      </TextWrapper>
    </DivElement>
  );
};

export default TAndC;
