/* eslint-disable no-console */
import React from "react";
// import { Field } from "formik";
import { useState } from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import Select, { components } from "react-select";
import { reactDropdownStyles } from "../../../utils";
import { DropdownIndicator } from "../../ReusedComponent/SelectReused";
import { compose } from "redux";
import { connect } from "react-redux";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";
import InputFieldFormik from "../../../components/Reusable/Field/index";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";

const getOtherFromValue = (value) => {
  if (value && value.includes("Other")) return "Other";
  return value;
};

const DropdownWithSpecifyOtherInput = ({
  title,
  name,
  value,
  errorProps,
  setFieldValue,
  placeholder,
  error,
  containerClass,
  isOtherTitle,
  isPlaceHolderTitle,
  options,
  disabled,
  isMultiStatus,
  isDarkTheme
}) => {
  const [isOther, setIsOther] = useState(false);
  const [selected, setSelected] = useState(
    value ? { label: getOtherFromValue(value), value: getOtherFromValue(value) } : ""
  );
  const { width: ScreenWidth } = useWindowSize();

  useEffect(() => {
    if (!value && !isOther) {
      setIsOther(false);
    } else if (value === "Other") {
      setIsOther(true);
      setFieldValue(name, "");
    } else if (value.includes("Other")) {
      setIsOther(true);
    } else {
      let index = options.findIndex((val) => val.value === value);
      if (index < 0) {
        setIsOther(true);
      } else {
        setIsOther(false);
      }
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (!selected && options) {
      setSelected(options.find((option) => option.value === value));
    }
    // eslint-disable-next-line
  }, [value, options]);

  useEffect(() => {
    if (isOther && !selected) {
      setSelected({ label: "Other", value: "Other" });
    }
    // eslint-disable-next-line
  }, [isOther, selected]);

  const onChangeHandler = (value) => {
    if (isMultiStatus) {
      if (Array.isArray(value)) {
        let resultData = [];
        value.map((item, index) => {
          let quotient = Math.floor(100 / value.length);
          let remainder = 100 % value.length;
          let resultArray = Array(value.length).fill(quotient);

          for (let i = 0; i < remainder; i++) {
            value.sourceWealth = item.value;
            value.percentage = resultArray[i];
            value.imgUrl = "";
            resultArray[i]++;
          }

          let obj = {
            label: item.label,
            value: item.value,
            sourceWealth: item.value,
            percentage: resultArray[index],
            imgUrl: ""
          };

          resultData.push(obj);
        });

        setSelected(resultData || value);
        setFieldValue(name, resultData || value);
      }
    } else {
      setSelected(value);
      setFieldValue(name, value.label);
    }
  };

  const removeOther = (val) => {
    if (isMultiStatus) {
      if (val && val.length > 0) {
        // const otherValue = val.value.split(" - ");
        // if (otherValue.length <= 1) {
        //   return otherValue[0] === "Other" ? "" : otherValue[0];
        // } else {
        //   return otherValue[1];
        // }
      }
    } else {
      const otherValue = val.split(" - ");
      if (otherValue.length <= 1) {
        return otherValue[0] === "Other" ? "" : otherValue[0];
      } else {
        return otherValue[1];
      }
    }
  };

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      display: "flex "
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        <BannerHeadingSpan ml="8px"> {children} </BannerHeadingSpan>
      </components.Option>
    );
  };

  return (
    <Fragment>
      <BannerBox mb="32px" mbMd="25px" width={"100%"} className={`${containerClass || ""}`}>
        <Label txtGrey size="14px" width="100%">
          {title}
        </Label>
        <BannerBox mt="8px">
          <Select
            className={`${
              error && !isOther ? "fInput__form__input--bRed" : "fInput__form__input--bGrey"
            } ${disabled ? "fInput__form__input--Disabled" : ""}`}
            components={
              isMultiStatus
                ? {
                    DropdownIndicator,
                    Option: InputOption
                  }
                : { DropdownIndicator }
            }
            name={name}
            isSearchable={true}
            placeholder={placeholder}
            onChange={onChangeHandler}
            styles={reactDropdownStyles({ isDarkTheme, disabled, ScreenWidth })}
            options={options}
            value={selected || value || ""}
            isDisabled={disabled}
            minMenuHeight={200}
            maxMenuHeight={200}
            menuPlacement="auto"
            closeMenuOnSelect={isMultiStatus ? !isMultiStatus : true}
            hideSelectedOptions={isMultiStatus ? !isMultiStatus : true}
            isMulti={isMultiStatus ? isMultiStatus : false}
            isClearable={isMultiStatus ? !isMultiStatus : false}
          />
        </BannerBox>
        {!isOther && (
          <ErrorMessageDiv
            name={name}
            component="div"
            mt="6px"
            size="14px"
            sizemd="12px"
            weight="300"
            txtDanger
            {...errorProps}
          />
        )}
      </BannerBox>

      {isOther && (
        <BannerBox width={isOther ? "100%" : ""} className={` ${containerClass || ""}`}>
          <Label txtGrey size="14px" width="100%">
            {isOtherTitle || title}
          </Label>
          <BannerBox
            className={`${isOtherTitle ? "fInput__form__textAreaInput" : "fInput__form__input"} ${
              disabled ? "fInput__form__input--Disabled" : ""
            } ${error ? "fInput__form__input--bRed" : ""}`}
            mt="8px"
          >
            <InputFieldFormik
              BodyColor
              type={isOtherTitle ? "textarea" : "text"}
              as={isOtherTitle ? "textarea" : "text"}
              className="form-control"
              name={name}
              disabled={disabled}
              value={removeOther(value)}
              style={
                isOtherTitle
                  ? {
                      resize: "none",
                      height: "100%",
                      border: "none"
                      // , color: disabled ? `${isDarkTheme ? "#dedfe0" : "#012243"}` : ""
                    }
                  : {}
              }
              placeholder={isPlaceHolderTitle || "Enter the source"}
              onChange={(e) =>
                setFieldValue(name, e.target.value ? `Other - ${e.target.value}` : "")
              }
            />
          </BannerBox>
          <ErrorMessageDiv
            name={name}
            component="div"
            mt="6px"
            size="14px"
            sizemd="12px"
            weight="300"
            txtDanger
            {...errorProps}
          />
        </BannerBox>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(DropdownWithSpecifyOtherInput);
