import { connect } from "react-redux";
import * as ReactUserAvatar from "react-user-avatar";
import { compose } from "redux";

const UserAvatar = ({ isDarkTheme, userData }) => {
  const FinalizeNames = () => {
    if (!userData.firstName) {
      return `${userData?.email?.includes(" ") ? userData?.email.split(" ")[1] : userData?.email}`;
    }
    return `${
      userData?.firstName?.includes(" ") ? userData?.firstName.split(" ")[1] : userData?.firstName
    } ${userData?.lastName?.includes(" ") ? userData?.lastName.split(" ")[1] : userData?.lastName}`;
  };
  return (
    <ReactUserAvatar
      size="32"
      colors={[isDarkTheme ? "#70a6e0" : "#0470e1"]}
      name={userData ? FinalizeNames().toLocaleUpperCase() : "-"}
      style={{
        color: isDarkTheme ? "#012243" : "#fff",
        fontSize: "14px",
        fontWeight: "500"
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  userData: state.dashboard.userData
});

export default compose(connect(mapStateToProps))(UserAvatar);
