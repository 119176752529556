import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { FlexCard } from "../../../assets/StyleComponents/FlexDiv.style";
import { ChevronRightBlueIcon, PromotionIcon } from "../../../assets/svgs";
import { RouterLink } from "../../../components/Reusable";

// Styled components
const AnimatedText = styled(FlexCard)`
  position: absolute;
  animation: slideUp 500ms ease-in-out;

  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ExitingText = styled(FlexCard)`
  position: absolute;
  transition: all 500ms ease-in-out;
  opacity: 0;
  transform: translateY(-100%);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AnimationContainer = styled(FlexCard)`
  position: relative;
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

const PromotionText = () => {
  // State management
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const prevLang = localStorage.getItem("ljs-lang");
  
  // Redux selector
  const promotionTextList = useSelector(state => state.promotion.promotionList);

  // Memoized index update function
  const updateIndex = useCallback(() => {
    setPrevIndex(currentIndex);
    setCurrentIndex(prev => (prev + 1) % promotionTextList.length);
  }, [currentIndex, promotionTextList.length]);

  // Animation interval effect
  useEffect(() => {
    if (!promotionTextList?.length) return;

    const interval = setInterval(updateIndex, 10000);
    return () => clearInterval(interval);
  }, [promotionTextList, updateIndex]);

  // Guard clause for empty list
  if (!promotionTextList?.length) return null;

  return (
    <BannerBox flexJustifyBetween>
      <FlexCard alignItems="center" justifyContent="space-between" width="80%">
        <PromotionIcon fillBlue />
        <AnimationContainer ml="6px">
          <ExitingText key={`prev-${prevIndex}`}>
            <ParaTag size="16px" sizeMd="14px">{promotionTextList[prevIndex]?.topbar_text}</ParaTag>
          </ExitingText>
          
          <AnimatedText as="a" href={promotionTextList[currentIndex]?.topbar_link} key={`current-${currentIndex}`}>
            <ParaTag size="16px" sizeMd="14px">{promotionTextList[currentIndex]?.topbar_text}</ParaTag>
          </AnimatedText>
        </AnimationContainer>
      </FlexCard>
      
      <RouterLink to={`/${prevLang}/promotions`} display="flex" alignItems="center">
        More <ChevronRightBlueIcon />
      </RouterLink>
    </BannerBox>
  );
};

export default React.memo(PromotionText);
