/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState, useContext } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { formatAPIErrors } from "../../../../utils/general";
import { getTokenSaleTransactions } from "../../../../lib/api";
import configs from "../../../../constants/config";
import { TableLoader } from "../../../../components/Loader";
import {
  Pagination,
  NoRecord,
  DivElement,
  Table,
  Th,
  Td,
  Tr,
  Tbody,
  ImgElement,
  ThDropdown,
  Thead
} from "../../../../components/Reusable";
import { numberWithCommas } from "../../../../utils/general";
import { showErrorMsg } from "../../../../utils/notification";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../../constants/styledConstants";
import { capitalize, setPrecision } from "../../../../utils";
import ParticipatedFilterComponent from "./Filters";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyBlueIcon } from "../../../../assets/svgs";
import { showSuccessMsg } from "../../../../utils/notification";
import { BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";

const ParticipatedHistoryTable = ({ isDarkTheme }) => {
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [transactionsData, setTransactionsData] = useState("");
  const [activeCol, setActiveCol] = useState("Status");
  const [options, setOptions] = useState(["Status", "Fees", "Transaction ID"]);
  const { width } = useWindowSize();
  const isDesktop = width > styledConstants.BREAKING_POINT_TABLET;
  const isTabletAndDesktop = width > styledConstants.BREAKING_POINT_MOBILE;
  const isTabletAndMobile = width <= styledConstants.BREAKING_POINT_TABLET;
  const isMobile = width <= styledConstants.BREAKING_POINT_MOBILE;
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [date, setDate] = useState({ fromDate: null, toDate: null });

  let { setToDate, setFrom } = useContext(DepositContext);

  useEffect(() => {
    getTransactionsHistory();
    // eslint-disable-next-line
  }, [page, date, selectedAssets]);

  useEffect(() => {
    if (isMobile) {
      setOptions([...options, "Amount", "Token Paid"]);
    }
    // eslint-disable-next-line
  }, [width]);

  const getTransactionsHistory = () => {
    setIsFetching(true);
    let data = {
      page: String(page),
      order: "DESC",
      coins: selectedAssets?.map((val) => val?.value) || []
    };

    if (date.fromDate) data.fromDate = date.fromDate;

    if (date.toDate) data.toDate = date.toDate;

    getTokenSaleTransactions(data)
      .then((res) => {
        setTransactionsData(res?.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const clearAll = () => {
    setSelectedAssets([]);
    setDate({ fromDate: null, toDate: null });
    setToDate("");
    setFrom("");
  };

  const onCopy = () => {
    showSuccessMsg(`"Transaction Id copied!`);
  };
  return (
    <DivElement marginTop="24px" marginTopMd="48px" marginTopSm="32px">
      <ParticipatedFilterComponent
        selectedAssets={selectedAssets}
        setSelectedAssets={setSelectedAssets}
        date={date}
        setDate={setDate}
        clearAll={clearAll}
      />
      {isFetching && <TableLoader rows={5} webCol={4} tabletCol={3} mobileCol={2} />}
      {!isFetching && (
        <DivElement>
          <DivElement>
            <Table bRadius borderCollapse="collapse">
              <Thead theadHeight="40px" fontSize="14px" fontSizeSm="12px">
                <Tr>
                  <Th
                    textAlign="left"
                    width="7%"
                    widthSm="30%"
                    widthMd="12%"
                    paddingLeftSm="7px"
                    paddingLeft="5px"
                  >
                    Token
                  </Th>
                  <Th textAlign="left" width="12%" widthSm="35%">
                    Date
                  </Th>
                  {isTabletAndDesktop && (
                    <Th textAlign="left" width="21%" widthLg="25%">
                      Amount
                    </Th>
                  )}
                  {isTabletAndDesktop && (
                    <Th textAlign="left" width="21%" widthMd="27%">
                      Token Paid
                    </Th>
                  )}
                  {isDesktop && (
                    <Th textAlign="left" width="15%">
                      Transaction ID
                    </Th>
                  )}
                  {isDesktop && (
                    <Th textAlign="left" width="18%">
                      Fees
                    </Th>
                  )}
                  {isDesktop && (
                    <Fragment>
                      <Th textAlign="left" width="7%">
                        Status
                      </Th>
                    </Fragment>
                  )}
                  {isTabletAndMobile && (
                    <ThDropdown
                      textAlign="left"
                      // paddingLeftSm={activeCol === "From" ? "30px" : ""}
                      activeCol={activeCol}
                      OPTIONS={options}
                      selectOption={setActiveCol}
                    />
                  )}
                </Tr>
              </Thead>
              <Tbody fontSize="16px" fontSizeSm="12px">
                {transactionsData &&
                  transactionsData.data.map((data, i) => (
                    <Tr
                      key={i}
                      height="56px"
                      border={i === transactionsData.data.length - 1 ? null : true}
                    >
                      <Td textAlign="left" paddingLeftSm="7px" paddingLeft="5px">
                        {data?.coin}
                      </Td>

                      <Td textAlign="left">{moment(data?.timestamp).format("DD/MM/YYYY")}</Td>
                      {(activeCol === "Amount" || isTabletAndDesktop) && (
                        <Td textAlign={activeCol === "Amount" ? "right" : "left"}>
                          {/* {width > 1024 && (
                          <ImgElement
                            alt="coin"
                            width="28px"
                            widthSm="16px"
                            height="28px"
                            heightSm="16px"
                            marginRight="5px"
                            marginRightSm="6px"
                            src={`${
                              isDarkTheme ? configs.darkCoinUrl : configs.lightCoinUrl
                            }${data.coin.toLowerCase()}.svg`}
                          />
                        )} */}
                          {`${numberWithCommas(
                            data.amount.length > 12 ? setPrecision(+data.amount, 4) : +data.amount
                          )} ${data.coin}`}
                        </Td>
                      )}
                      {(activeCol === "Token Paid" || isTabletAndDesktop) && (
                        <Td textAlign={activeCol === "Token Paid" ? "right" : "left"}>
                          {/* {width > 1024 && (
                          <ImgElement
                            alt="coin"
                            width="28px"
                            widthSm="16px"
                            height="28px"
                            heightSm="16px"
                            marginRight="5px"
                            marginRightSm="6px"
                            src={`${
                              isDarkTheme ? configs.darkCoinUrl : configs.lightCoinUrl
                            }${data.fromCoin.toLowerCase()}.svg`}
                          />
                        )} */}
                          {`${numberWithCommas(+data.quoteAmount)} ${data.fromCoin}`}
                        </Td>
                      )}
                      {(activeCol === "Transaction ID" || isDesktop) && (
                        <CopyToClipboard onCopy={() => onCopy()} text={data.transactionId}>
                          <Td textAlign={activeCol === "Transaction ID" ? "right" : "left"}>
                            {data?.transactionId?.length > 15
                              ? data.transactionId.slice(0, 4) +
                                ".." +
                                data.transactionId.slice(12, 15)
                              : data.transactionId}{" "}
                            <BannerHeadingSpan style={{ verticalAlign: "sub" }}>
                              <CopyBlueIcon width="17px" />
                            </BannerHeadingSpan>
                          </Td>
                        </CopyToClipboard>
                      )}
                      {(activeCol === "Fees" || isDesktop) && (
                        <Td textAlign={activeCol === "Fees" ? "right" : "left"}>
                          {setPrecision(data.fees, 7)} LCX
                        </Td>
                        // <Td textAlign="left">{setPrecision(data.fees, 5)} LCX</Td>
                      )}
                      {(activeCol === "Status" || isDesktop) && (
                        <Td
                          textAlign={activeCol === "Status" ? "right" : "left"}
                          red={data.status === "FAILED"}
                          green={data.status === "SUCCESS"}
                          paddingRight="5px"
                        >
                          {capitalize(data.status)}
                        </Td>
                      )}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </DivElement>
          {transactionsData && transactionsData.data.length === 0 && <NoRecord />}

          {transactionsData && transactionsData.data.length !== 0 && (
            <Pagination
              page={page + 1}
              totalCount={transactionsData.count}
              setPage={(page) => setPage(page - 1)}
            />
          )}
        </DivElement>
      )}
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(ParticipatedHistoryTable);
