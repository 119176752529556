/**
 * Intialize Theme Reducer
*/

import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  isFavWatchList: "",
  isFavWatchListFetching: false
};

let initialState = initialStateSchema;
const getThemeFromLocalStorage = localStorage.getItem("fav");

if (getThemeFromLocalStorage || getThemeFromLocalStorage !== undefined) {
  try {
    initialState.isFavWatchList = getThemeFromLocalStorage;
  } catch (e) {
    initialState = initialStateSchema;
  }
}

const WatchListReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GET_WATTCHLIST_DATA:
      newState = {
        ...state,
        isFavWatchListFetching: true
      };
      return newState;

    case apiConstants.GET_WATTCHLIST_DATA_SUCCESS:
      newState = {
        ...state,
        isFavWatchList: action.response,
        isFavWatchListFetching: false
      };
      return newState;

    case apiConstants.GET_WATTCHLIST_DATA_FAIL:
      newState = {
        ...state,
        isFavWatchListFetching: false
      };
      return newState;
    default:
      return state;
  }
};

export default WatchListReducer;
