/**
 * Import Node Modules
 */
import { withRouter } from "react-router-dom";
import { IMAGE_PREFIX } from "../../constants/Images/imageIndex";
import HalfFooter from "./halfFooter";
import { FooterPart } from "../../assets/StyleComponents/Fotter.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { AnchorTag, LiTag, ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { ImgElement, RouterLink } from "../Reusable";
import { FigureComponent } from "../../assets/StyleComponents/Figure.style";
import MobilePartFooter from "./MobilePartFooter";

const IndexFooter = (props) => {
  let { isTheme } = props;
  const { width } = useWindowSize();

  return (
    <>
      {width > 1024 && (
        <FooterPart FooterWhite>
          <BannerBox padding={"56px 32px 8px"}>
            <BannerBox ExchangeContainer>
              <BannerBox gridTemplateColums mb="5rem" gridColumn="2fr 1fr 1fr 1fr 1fr">
                <BannerBox width="100%" display="flex" direction="column" wrap="flex-fill">
                  <ImgElement
                    src={!isTheme ? IMAGE_PREFIX.logo_grey : IMAGE_PREFIX.logo_white}
                    alt="LCX"
                    width="130px"
                    height="auto"
                  />
                  <ParaTag
                    txtGrey
                    lineHeight="1.5"
                    size="18px"
                    weight="300"
                    textGrey
                    width="90%"
                    mt="20px"
                  >
                    <BannerHeadingSpan weight="500">
                      The Liechtenstein Cryptoassets Exchange -{" "}
                    </BannerHeadingSpan>{" "}
                    A Blockchain Ecosystem For The New Financial World.
                  </ParaTag>
                </BannerBox>

                <BannerBox display="flex" mb="20px" direction="column">
                  <BannerHeading>
                    <b> Contact </b>
                  </BannerHeading>

                  <BannerBox display="flex" alignItems="center" mt="20px">
                    <FigureComponent width="24px" margin="0px">
                      <ImgElement
                        src={!isTheme ? IMAGE_PREFIX.MailIcon : IMAGE_PREFIX.MailIcon}
                        alt=""
                        width="100%"
                        height="auto"
                      />
                    </FigureComponent>

                    <ParaTag
                      txtGrey
                      size={"14px"}
                      weight="500"
                      lineHeight="25px"
                      textGrey
                      ml="16px"
                    >
                      <AnchorTag href="mailto:hello@lcx.com" textGrey>
                        hello@lcx.com
                      </AnchorTag>
                    </ParaTag>
                  </BannerBox>

                  <BannerBox display="flex" alignItems="center" mb="12px" mt="20px">
                    <BannerHeadingSpan size="18px">
                      <ImgElement src={IMAGE_PREFIX.MapPinIcon} alt="" width="100%" />
                    </BannerHeadingSpan>
                    <ParaTag
                      txtGrey
                      size={"14px"}
                      weight="300"
                      lineHeight="25px"
                      textGrey
                      ml="16px"
                    >
                      LCX AG <br />
                      Herrengasse 6 <br />
                      9490 Vaduz <br />
                      Liechtenstein <br />
                    </ParaTag>
                  </BannerBox>
                </BannerBox>

                <BannerBox>
                  <BannerBox display="flex" direction="column" flexWrap="flex-wrap">
                    <BannerHeading>
                      <b> Content </b>
                    </BannerHeading>

                    <ul style={{ listStyle: "none", margin: "0px", padding: "0px" }}>
                      <LiTag size="14px" BodyColor mt="16px" mb="8px" weight="500">
                        <AnchorTag
                          href="https://www.lcx.com/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          Home
                        </AnchorTag>
                      </LiTag>

                      <LiTag size="14px" BodyColor mt="8px" mb="8px" weight="500">
                        <AnchorTag
                          href="https://www.lcx.com/about/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          About
                        </AnchorTag>
                      </LiTag>

                      <LiTag size="14px" BodyColor mt="8px" mb="8px" weight="500">
                        <AnchorTag
                          href="https://www.lcx.com/partners/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          Partner
                        </AnchorTag>
                      </LiTag>

                      <LiTag size="14px" mt="8px" mb="8px" BodyColor weight="500">
                        <AnchorTag
                          href="https://www.lcx.com/insights/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          Insights
                        </AnchorTag>
                      </LiTag>

                      <LiTag size="14px" mt="8px" mb="8px" BodyColor weight="500">
                        <AnchorTag
                          href="https://www.lcx.com/careers/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          Careers
                        </AnchorTag>
                      </LiTag>

                      <LiTag size="14px" mt="7px" mb="7px" BodyColor weight="500">
                        <AnchorTag
                          href="https://www.lcx.com/cryptocurrency-reference-price-services/"
                          target="_blank"
                          lineHeight="25px"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          Cryptocurrency <br />
                          Reference Price
                        </AnchorTag>
                      </LiTag>
                    </ul>
                  </BannerBox>
                </BannerBox>
                <BannerBox>
                  <BannerBox display="flex" direction="column" flexWrap="flex-wrap">
                    <BannerHeading>
                      <b> Resources </b>
                    </BannerHeading>

                    <ul style={{ listStyle: "none", margin: "0px", padding: "0px" }}>
                      <LiTag size="14px" textGrey mt="16px" mb="8px" weight="500" BodyColor>
                        <RouterLink to="/lcx-token" primary cursor="pointer" textDecoration="none">
                          LCX Token
                        </RouterLink>
                      </LiTag>

                      <LiTag size="14px" mt="8px" mb="8px" weight="500" BodyColor>
                        <RouterLink to="/fee" primary cursor="pointer" textDecoration="none">
                          LCX Fee
                        </RouterLink>
                      </LiTag>

                      <LiTag size="14px" mt="8px" mb="8px" weight="500" BodyColor>
                        <RouterLink to="/assets" primary cursor="pointer" textDecoration="none">
                          Digital Assets
                        </RouterLink>
                      </LiTag>

                      <LiTag size="14px" mt="8px" mb="8px" weight="500" BodyColor>
                        <RouterLink to="/tutorials" primary cursor="pointer" textDecoration="none">
                          Tutorials
                        </RouterLink>
                      </LiTag>

                      <LiTag size="14px" mt="8px" mb="8px" weight="500" BodyColor>
                        <RouterLink to="/security" primary cursor="pointer" textDecoration="none">
                          Security
                        </RouterLink>
                      </LiTag>
                      <LiTag size="14px" mt="8px" mb="8px" weight="500" BodyColor>
                        <AnchorTag
                          href="https://www.lcx.com/documents/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          Documents
                        </AnchorTag>
                      </LiTag>
                      <LiTag size="14px" mt="8px" mb="8px" weight="500" BodyColor>
                        <AnchorTag
                          href="https://www.lcx.com/brand-and-trademarks/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          Brand and Trademarks
                        </AnchorTag>
                      </LiTag>
                      <LiTag size="14px" mt="8px" mb="8px" weight="500" BodyColor>
                        <AnchorTag
                          href="https://www.lcx.com/info/faq/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          FAQ & Support
                        </AnchorTag>
                      </LiTag>
                      <LiTag size="14px" mt="8px" mb="8px" weight="500" BodyColor>
                        <AnchorTag
                          href={"https://docs.lcx.com/"}
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          API Docs
                        </AnchorTag>
                      </LiTag>
                    </ul>
                  </BannerBox>
                </BannerBox>
                <BannerBox>
                  <BannerBox display="flex" direction="column" flexWrap="flex-wrap">
                    <BannerHeading>
                      <b> Legal </b>
                    </BannerHeading>

                    <ul style={{ listStyle: "none", margin: "0px", padding: "0px" }}>
                      <LiTag size="14px" weight="500" BodyColor mt="16px" mb="8px">
                        <AnchorTag
                          href="https://www.lcx.com/privacy-policy/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          Privacy Policy
                        </AnchorTag>
                      </LiTag>

                      <LiTag size="14px" weight="500" BodyColor mt="8px" mb="8px">
                        <AnchorTag
                          href="https://www.lcx.com/terms-of-service/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          Terms of Service
                        </AnchorTag>
                      </LiTag>

                      <LiTag size="14px" weight="500" BodyColor mt="8px" mb="8px">
                        <AnchorTag
                          href="https://www.lcx.com/imprint/"
                          target="_blank"
                          cursor="pointer"
                          textDecoration="none"
                          BodyColor
                        >
                          Imprint
                        </AnchorTag>
                      </LiTag>
                    </ul>
                  </BannerBox>
                </BannerBox>
              </BannerBox>
              <HalfFooter />
            </BannerBox>
          </BannerBox>
        </FooterPart>
      )}

      {width < 1025 && <MobilePartFooter {...props} />}
    </>
  );
};

export default withRouter(IndexFooter);
