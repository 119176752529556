/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { DivElement, ElementBox, ImgElement } from "../../Reusable";
import { FlipCameraIcon } from "../../../assets/svgs";
import styled from "styled-components";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { LiTag } from "../../../assets/StyleComponents/FontSize/para.style";
import CameraComponent from "./CameraComponents";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const Container = styled(DivElement)`
  position: relative;
  width: fit-content;
  height: fit-content;
  box-shadow: none !important;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    height: auto !important;
    width: 100vw !important;
    margin-bottom: 20vh !important;
  }
  video {
    @media (max-width: 767px) {
      width: 80vw !important;
      height: 50vh !important;
      border-radius: 10px;
    }
    transform: ${(props) => (props.mode === "environment" ? `scaleX(1)` : `scaleX(-1)`)};
  }
`;

const WebCamCapture = ({
  captureImages,
  documentName,
  setOpenWebcam,
  setFirstImage,
  setSecondImage,
  kycStep,
  setCapturedImg,
  selectType,
  setStepDocUpload,
  type
}) => {
  const [documentData, setDocumentData] = useState("");
  const [isError, setIsError] = useState(false);
  const [docName, setDocName] = useState("");
  const [retake, setRetake] = useState(false);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [showWebcam, setShowWebcam] = useState(true);
  const { width } = useWindowSize();

  useEffect(() => {
    setDocName(documentName);

    // eslint-disable-next-line
  }, []);

  const webcam = React.createRef();
  const handleFaceCapture = () => {
    const imageSrc = webcam.current.getScreenshot();

    if (docName === "firstImage") {
      setFirstImage(imageSrc);
    } else if (docName === "secondImage") {
      setSecondImage(imageSrc);
    } else if (docName === "capturedImg") {
      setCapturedImg(imageSrc);
    }

    setRetake(true);
    setDocumentData(imageSrc);
  };

  const onCancelOrRetake = () => {
    if (retake) {
      if (docName === "firstImage") {
        setFirstImage("");
      } else if (docName === "secondImage") {
        setSecondImage("");
      } else if (docName === "capturedImg") {
        setCapturedImg("");
      }
      setRetake(false);
    } else {
      setOpenWebcam(false);
      type !== "residence" ? setStepDocUpload(1) : setStepDocUpload(false);
    }
  };

  const onDoneOrTake = () => {
    if (retake) {
      if (kycStep !== "STEP2") captureImages();
      if (setStepDocUpload)
        type !== "residence"
          ? setStepDocUpload((cam) => (selectType === "passport" ? cam + 2 : cam + 1))
          : setStepDocUpload(true);
      setOpenWebcam(false);
      setRetake(false);
    } else {
      handleFaceCapture();
    }
  };

  const onFlipCamera = () => {
    setShowWebcam(false);
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    );

    setTimeout(() => {
      setShowWebcam(true);
    }, 100);
  };

  return (
    <ElementBox padding="0px 16px" minHeight="500px" boxShadow="none" paddingSm="10px 0px">
      <DivElement dAlignCenter column>
        <DivElement
          marginTop="31.5px"
          width="540px"
          widthSm="100%"
          height="303.75px"
          // heightSm="53vh"
          // display="flex"
          // flexDirectionSm="column"
          // justifyContentSm="center"
        >
          {isError ? (
            <DivElement displayCenter width="100%" height="100%">
              <CameraComponent />
            </DivElement>
          ) : retake ? (
            <>
              <ImgElement
                width="100%"
                height="100%"
                heightSm="100%"
                widthSm="100%"
                minWidthSm="60%"
                maxWidthSm="100%"
                borderRadius="8px"
                ObjectFitSm="contain"
                src={documentData}
                alt="id_placeholder"
              />
              {retake && width < 767 && (
                <DivElement justifyContent="start" width="100%" marginLeft="7%" marginLeftSm="0%">
                  <ul>
                    <LiTag txtGrey size="14px" weight="500">
                      All 4 corners inside the box?
                    </LiTag>
                    <LiTag txtGrey size="14px" weight="500">
                      Can you read the details?
                    </LiTag>
                    <LiTag txtGrey size="14px" weight="500">
                      ID is valid?
                    </LiTag>
                  </ul>
                </DivElement>
              )}
            </>
          ) : (
            showWebcam && (
              <Container mode={facingMode}>
                <Webcam
                  audio={false}
                  ref={webcam}
                  screenshotFormat="image/jpeg"
                  width={width < 768 ? "100vw" : "540px"}
                  height={width < 768 ? "100%" : "303.75px"}
                  videoConstraints={{
                    facingMode: facingMode,
                    aspectRatio: width < 768 ? 4 / 3 : 16 / 9
                  }}
                  onUserMediaError={() => setIsError(true)}
                  onUserMedia={() => setIsError(false)}
                  style={{ borderRadius: "8px" }}
                />
                {width < 1024 && (
                  <DivElement className="kycWebcam__flip">
                    <FlipCameraIcon width="28px" height="28px" onClick={onFlipCamera} />
                  </DivElement>
                )}
              </Container>
            )
          )}
        </DivElement>

        {retake && width > 767 && (
          <DivElement justifyContent="start" width="100%" marginLeft="7%" marginLeftSm="0%">
            <ul>
              <LiTag txtGrey size="14px" weight="500">
                All 4 corners inside the box?
              </LiTag>
              <LiTag txtGrey size="14px" weight="500">
                Can you read the details?
              </LiTag>
              <LiTag txtGrey size="14px" weight="500">
                ID is valid?
              </LiTag>
            </ul>
          </DivElement>
        )}

        {!isError && (
          <DivElement
            displayCenter
            // positionSm="absolute"
            // bottomSm="0"
            // zIndexSm="10"
            marginTop="40px"
            marginTopSm="100px"
            gap="32px"
            gapMd="24px"
            flexDirectionSm="column"
            widthMd="100%"
            marginTopMd="20px"
          >
            <ButtonComponent
              primaryBlue
              width="246px"
              widthMd="100%"
              height="48px"
              onClick={() => {
                onDoneOrTake();
                // retake && setStepDocUpload((cam) => (selectType === "passport" ? cam + 2 : cam + 1));
              }}
            >
              {retake ? "Done" : "Take"}
            </ButtonComponent>
            <ButtonComponent
              // secondaryRed={retake}
              OutlinePrimaryWithoutHover
              width="246px"
              widthMd="100%"
              height="48px"
              marginRight="24px"
              padding="0 16px"
              onClick={onCancelOrRetake}
            >
              {retake ? "Retake Photo" : "Cancel"}
            </ButtonComponent>
            {/* {selectType === "passport" && ( */}

            {/* )} */}
          </DivElement>
        )}
      </DivElement>
    </ElementBox>
  );
};

export default WebCamCapture;
