import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { getAllClientSales } from "../../../../actions/tokenSale";
import { withRouter } from "react-router-dom";
import SaleDashboard from "../Components/SaleDashboard";

const AdminDashboard = (props) => {
  const userData = useSelector(state => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    props.getAllClientSales();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.allClientSales && !props.allClientSales.showLink) {
      props.history.push(`/${prevLang ? `${prevLang}` : userData.preferredLanguage ? `${userData.preferredLanguage}` : ""}`);
    }
    // eslint-disable-next-line
  }, [props.allClientSales]);

  return (
    <SaleDashboard
      salesData={props.allClientSales && props.allClientSales.data}
      loader={props.isAllClientSalesFetching}
      dashboardType="ADMIN"
    />
  );
};

const mapStateToProps = (state) => ({
  allClientSales: state.sale.allClientSales,
  isAllClientSalesFetching: state.sale.isAllClientSalesFetching
});

const mapDispatchToProps = (dispatch) => ({
  getAllClientSales: () => dispatch(getAllClientSales())
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AdminDashboard);
