import { connect } from "react-redux";
import { compose } from "redux";
const LoadingSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || "100"}
    height={props?.height || "13"}
    viewBox="0 0 120 30"
    fill={
      props.blue ? (props.isDarkTheme ? "#70a6e0" : "#0470e1") : props.color ? props.color : "#fff"
    }
    style={{ marginTop: "2px" }}
  >
    <circle cx="15" cy="15" r="10.2481">
      <animate
        attributeName="r"
        from="15"
        to="15"
        begin="0s"
        dur="0.8s"
        values="15;9;15"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fillOpacity"
        from="1"
        to="1"
        begin="0s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="60" cy="15" r="13.7519" fillOpacity="0.3">
      <animate
        attributeName="r"
        from="9"
        to="9"
        begin="0s"
        dur="0.8s"
        values="9;15;9"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fillOpacity"
        from="0.5"
        to="0.5"
        begin="0s"
        dur="0.8s"
        values=".5;1;.5"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="105" cy="15" r="10.2481">
      <animate
        attributeName="r"
        from="15"
        to="15"
        begin="0s"
        dur="0.8s"
        values="15;9;15"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fillOpacity"
        from="1"
        to="1"
        begin="0s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

const mapStateToProps = (state, passedProps) => ({
  isDarkTheme: passedProps.isDarkTheme != null ? passedProps.isDarkTheme : state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(LoadingSVG);
