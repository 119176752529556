import styled, { css } from "styled-components";

const Img = styled.img`
  width: ${(props) => (props.width ? props.width : "")};
  display: ${(props) => (props.display ? props.display : "")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
  height: ${(props) => (props.height ? props.height : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "")};
  border-top-left-radius: ${(props) =>
    props.borderTopLeftRadius ? props.borderTopLeftRadius : ""};
  border-top-right-radius: ${(props) =>
    props.borderTopRightRadius ? props.borderTopRightRadius : ""};
  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  ${(props) =>
    props.objectFit &&
    css`
      object-fit: ${(props) => props.objectFit};
    `};
  aspect-ratio: ${(props) => (props.aspectRatio ? props.aspectRatio : "")};

  @media (max-width: 1024px) {
    width: ${(props) => (props.widthMd ? props.widthMd : "")};
    height: ${(props) => (props.heightMd ? props.heightMd : "")};
    margin: ${(props) => (props.marginMd ? props.marginMd : "")};
    margin-top: ${(props) => (props.marginTopMd ? props.marginTopMd : "")};
    margin-right: ${(props) => (props.marginRightMd ? props.marginRightMd : "")};
    margin-bottom: ${(props) => (props.marginBottomMd ? props.marginBottomMd : "")};
    margin-left: ${(props) => (props.marginLeftMd ? props.marginLeftMd : "")};
    border-radius: ${(props) => (props.borderRadiusMd ? props.borderRadiusMd : "")};
    padding: ${(props) => (props.paddingMd ? props.paddingMd : "")};
    padding-right: ${(props) => (props.paddingRightMd ? props.paddingRightMd : "")};
    padding-left: ${(props) => (props.paddingLeftMd ? props.paddingLeftMd : "")};
    object-fit: ${(props) => (props.ObjectFitMd ? props.ObjectFitMd : "")};
    position: ${(props) => (props.positionMd ? props.positionMd : "")};
    top: ${(props) => (props.topMd ? props.topMd : "")};
  }
  @media (max-width: 767px) {
    width: ${(props) => (props.widthSm ? props.widthSm : "")};
    height: ${(props) => (props.heightSm ? props.heightSm : "")} !important;
    margin: ${(props) => (props.marginSm ? props.marginSm : "")};
    margin-top: ${(props) => (props.marginTopSm ? props.marginTopSm : "")};
    margin-right: ${(props) => (props.marginRightSm ? props.marginRightSm : "")};
    margin-bottom: ${(props) => (props.marginBottomSm ? props.marginBottomSm : "")};
    margin-left: ${(props) => (props.marginLeftSm ? props.marginLeftSm : "")};
    border-radius: ${(props) => (props.borderRadiusSm ? props.borderRadiusSm : "")};
    padding: ${(props) => (props.paddingSm ? props.paddingSm : "")};
    padding-right: ${(props) => (props.paddingRightSm ? props.paddingRightSm : "")};
    object-fit: ${(props) => (props.ObjectFitSm ? props.ObjectFitSm : "")} !important;
    position: ${(props) => (props.positionSm ? props.positionSm : "")};
    top: ${(props) => (props.topSm ? props.topSm : "")};
    max-width: ${(props) => (props.maxWidthSm ? props.maxWidthSm : "")}!important;
    min-width: ${(props) => (props.minWidthSm ? props.minWidthSm : "")} !important;
    display: ${(props) => (props.displaySm ? props.displaySm : "")};
  }

  ${(props) =>
    props.cursor &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.FrontPageBanners &&
    css`
      width: 40%;
      transition: all 0.5s linear;
      &:hover {
        width: 45%;
      }
    `}
`;

const ImgElement = (props) => {
  return <Img {...props} />;
};

export default ImgElement;
