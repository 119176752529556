/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import { formatAPIErrors } from "../../../../utils/general";
import { acceptTCMonerium, createWallet } from "../../../../lib/api";
import { WalletContext } from "../../../../contexts/WalletPage/BalancePage/wallet";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { useWindowSize } from "./../../../../Hooks/CheckWidthHeight";
import { CopyBlueIcon } from "../../../../assets/svgs";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { AnchorTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import InputSwitch from "../../../../components/Reusable/InputSwitch";

const FaitAddressComponent = () => {
  let { exchWallet, callAllWallet } = useContext(WalletContext);
  const { width } = useWindowSize();

  let { selectedCoin, selectedProvider, providerList } = useContext(DepositContext);

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [server_val, setServer_val] = useState(false);
  const [statusMTC, setStatusMTC] = useState(false);
  // const [ isFetchingData, setIsFetchingData ] = useState(false);
  // const [ isFetchingData, setIsFetchingData ] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validateConfirmBtnLoading, setValidateConfirmBtnLoading] = useState(false);

  useEffect(() => {
    if (providerList && providerList.length > 0) {
      if (selectedCoin && selectedCoin.coin && !selectedCoin.address) {
        setStatusMTC(false);
        walletDeposit();
      } else {
        setIsDeclarationChecked(true);
        setIsTermsChecked(true);
        setServer_val(true);
        setStatusMTC(true);
      }
    }
  }, []);

  const selectTextFait = (type) => {
    if (type === "reference" || type === "iban") {
      showSuccessMsg(`${type === "reference" ? "Reference" : "IBAN"} Copied!`, { autoClose: 5000 });
    } else {
      showSuccessMsg("BLINC ID copied", { autoClose: 5000 });
    }
  };

  const toggleTermsAndConditions = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const toggleSelfDeclaration = () => {
    setIsDeclarationChecked(!isDeclarationChecked);
  };

  useEffect(() => {
    if (!selectedCoin.address || !selectedCoin.memo) {
      let result = exchWallet?.data?.filter((coin) => selectedCoin.coin === coin.coin);
      selectedCoin.address = (result && result[0]?.address) || "";
      selectedCoin.memo = (result && result[0]?.memo) || "";
    }
  }, [exchWallet]);

  const walletDeposit = () => {
    setValidateConfirmBtnLoading(true);
    acceptTCMonerium()
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          if (selectedCoin && selectedCoin.coin && !selectedCoin.address) {
            createWallet({ coin: selectedCoin.coin, network_id: 0 })
              .then((res) => {
                callAllWallet("updateAddress");
                setStatusMTC(true);
              })
              .catch((err) => {
                showErrorMsg(formatAPIErrors(err), { autoClose: 5000 });
              });
          }
          setIsTermsChecked(true);
          setIsDeclarationChecked(true);
          setServer_val(true);
        }
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      })
      .finally((f) => {
        setValidateConfirmBtnLoading(false);
      });
  };

  return (
    <BannerBox mtMd={"22px"} mt="40px">
      {selectedCoin.coinType === "fiat_token" && (
        <BannerBox
          display="flex"
          direction="column"
          margin="auto 0px"
          border="1px solid #ccced0"
          padding="1rem"
          BorderRadius="2px"
        >
          {providerList && providerList.length > 0 && (
            <>
              <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                <BannerBox width="40%">
                  <BannerHeading size="14px" txtGrey sizeMd="12px">
                    {" "}
                    Account Name{" "}
                  </BannerHeading>
                </BannerBox>

                <BannerBox width="60%">
                  <BannerHeading BodyColor textAlign="right" size="16px">
                    <var>
                      {selectedProvider.label === "BLINC"
                        ? selectedProvider.value.accountName
                        : `${selectedProvider.value.name} `}
                    </var>
                  </BannerHeading>
                </BannerBox>
              </BannerBox>

              <BannerBox
                display="flex"
                mt="1rem"
                justifyContent="space-between"
                alignItems="center"
              >
                <BannerBox width="30%">
                  <BannerHeading size="14px" txtGrey sizeMd="12px">
                    {" "}
                    {selectedProvider?.label === "BLINC" ? "Account ID" : "Address"}{" "}
                  </BannerHeading>
                </BannerBox>

                <BannerBox width="70%">
                  <BannerHeading
                    BodyColor
                    textAlign="right"
                    size="16px"
                    sizeLg="14px"
                    sizeMd="12px"
                  >
                    {selectedProvider.label === "BLINC"
                      ? selectedProvider.value.accountId
                      : selectedProvider.value.address}
                  </BannerHeading>
                </BannerBox>
              </BannerBox>

              <BannerBox
                display="flex"
                mt="1rem"
                justifyContent="space-between"
                alignItems="center"
              >
                <BannerBox width="30%">
                  <BannerHeading size="14px" txtGrey sizeMd="12px">
                    {" "}
                    {selectedProvider?.label === "BLINC" ? "BLINC ID" : "IBAN"}{" "}
                  </BannerHeading>
                </BannerBox>

                <BannerBox width="70%" display="flex" alignItems="start" justifyContent="flex-end">
                  {statusMTC ? (
                    <>
                      <BannerHeading
                        BodyColor
                        size="16px"
                        sizeLg="14px"
                        sizeMd="12px"
                        textAlign="right"
                        overflow="hidden"
                      >
                        {selectedProvider?.label === "BLINC"
                          ? selectedProvider.value.blincId
                          : selectedProvider.value.iban}
                      </BannerHeading>

                      <CopyToClipboard
                        text={
                          selectedProvider?.label === "BLINC"
                            ? selectedProvider.value.blincId
                            : selectedProvider.value.iban
                        }
                        onCopy={() => {
                          if (selectedProvider?.label !== "BLINC") {
                            selectTextFait("iban");
                          } else {
                            selectTextFait("blinc");
                          }
                        }}
                      >
                        <ButtonComponent
                          OutlineOnlyPrimary
                          btnHeight="auto"
                          padd={"0px 0 0 6px"}
                          style={{ marginTop: "-3px" }}
                        >
                          <CopyBlueIcon
                            width={width < 768 ? "16px" : "24px"}
                            height={width < 768 ? "16px" : "24px"}
                          />
                        </ButtonComponent>
                      </CopyToClipboard>
                    </>
                  ) : (
                    <BannerHeadingSpan txtGrey size="14px" sizeMd="12px">
                      Not Provided
                    </BannerHeadingSpan>
                  )}
                </BannerBox>
              </BannerBox>

              <BannerBox
                display="flex"
                mt="1rem"
                justifyContent="space-between"
                alignItems="center"
              >
                <BannerBox width="30%">
                  <BannerHeading size="14px" txtGrey sizeMd="12px">
                    {" "}
                    Reference{" "}
                  </BannerHeading>
                </BannerBox>

                <BannerBox width="70%" display="flex" alignItems="start" justifyContent="flex-end">
                  {statusMTC ? (
                    <>
                      <BannerHeading
                        BodyColor
                        size="16px"
                        sizeLg="14px"
                        sizeMd="12px"
                        textAlign="right"
                        overflow="hidden"
                      >
                        <var>{`${selectedCoin.address}`}</var>
                      </BannerHeading>

                      <CopyToClipboard
                        text={selectedCoin.address}
                        onCopy={() => selectTextFait("reference")}
                      >
                        <ButtonComponent
                          OutlineOnlyPrimary
                          btnHeight="auto"
                          padd={"0px 0 0 6px"}
                          style={{ marginTop: "-3px" }}
                        >
                          <CopyBlueIcon
                            width={width < 768 ? "16px" : "24px"}
                            height={width < 768 ? "16px" : "24px"}
                          />
                        </ButtonComponent>
                      </CopyToClipboard>
                    </>
                  ) : (
                    <BannerHeadingSpan txtGrey size="14px" sizeMd="12px">
                      Not Provided
                    </BannerHeadingSpan>
                  )}
                </BannerBox>
              </BannerBox>
              <BannerBox
                display="flex"
                mt="1rem"
                justifyContent="space-between"
                alignItems="center"
              >
                <BannerBox width="30%">
                  <BannerHeading size="14px" txtGrey sizeMd="12px">
                    {" "}
                    LCX Fee{" "}
                  </BannerHeading>
                </BannerBox>

                <BannerBox width="70%" display="flex" alignItems="start" justifyContent="flex-end">
                  <>
                    <BannerHeading
                      BodyColor
                      size="16px"
                      sizeLg="14px"
                      sizeMd="12px"
                      textAlign="right"
                      overflow="hidden"
                    >
                      <var>{`${selectedProvider?.id.depositFee?.LCX?.value}`}</var>
                    </BannerHeading>
                  </>
                </BannerBox>
              </BannerBox>
              <BannerBox
                display="flex"
                mt="1rem"
                justifyContent="space-between"
                alignItems="center"
              >
                <BannerBox width="30%">
                  <BannerHeading size="14px" txtGrey sizeMd="12px">
                    {" "}
                    Provider Fee{" "}
                  </BannerHeading>
                </BannerBox>

                <BannerBox width="70%" display="flex" alignItems="start" justifyContent="flex-end">
                  <>
                    <BannerHeading
                      BodyColor
                      size="16px"
                      sizeLg="14px"
                      sizeMd="12px"
                      textAlign="right"
                      overflow="hidden"
                    >
                      <var>{`${selectedProvider?.id.depositFee?.PROVIDER?.value}`}</var>
                    </BannerHeading>
                  </>
                </BannerBox>
              </BannerBox>

              {selectedProvider?.label === "Monerium" && (
                <BannerBox mt="1.2rem" display="flex">
                  <BannerBox width="17%">
                    <InputSwitch
                      checked={isTermsChecked}
                      setChecked={toggleTermsAndConditions}
                      disabled={null}
                    />
                  </BannerBox>

                  <BannerBox width="85%">
                    <BannerHeading BodyColor size="14px" weight="300">
                      I hereby confirm the LCX Terms of Service and acknowledge the{" "}
                      <AnchorTag
                        rel="noreferrer"
                        target="_blank"
                        txtPrimary
                        href="https://monerium.com/policies/business-terms-of-service/"
                      >
                        <var>{`${selectedProvider.label} `}</var> Terms of service.
                      </AnchorTag>
                    </BannerHeading>
                  </BannerBox>
                </BannerBox>
              )}

              {selectedProvider?.label === "Monerium" && (
                <BannerBox display="flex" margin="1.2rem 0px">
                  <BannerBox width="17%">
                    <InputSwitch
                      checked={isDeclarationChecked}
                      setChecked={toggleSelfDeclaration}
                      disabled={null}
                    />
                  </BannerBox>

                  <BannerBox width="85%">
                    <BannerHeading BodyColor size="14px" weight="300">
                      I hereby confirm that I perform this transaction on my own account. I was
                      neither contacted nor instructed by a third party to do this transaction.
                    </BannerHeading>
                  </BannerBox>
                </BannerBox>
              )}

              {!server_val && (
                <BannerBox display="flex" margin="1.2rem 0px">
                  <ButtonComponent
                    PrimaryButton
                    width="100%"
                    btnHeight="48px"
                    onClick={walletDeposit}
                    // disabled={isDeclarationChecked && isTermsChecked ? false : true}
                  >
                    Confirm
                  </ButtonComponent>
                </BannerBox>
              )}
            </>
          )}
        </BannerBox>
      )}
    </BannerBox>
  );
};

// Call To Map To Props
const maptoStateProps = (state) => ({
  isTheme: state.theme.isTheme,
  userData: state.dashboard.getUserData,
  isDarkTheme: state.theme.isTheme
});

export default connect(maptoStateProps)(FaitAddressComponent);
