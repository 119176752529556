import styled, { css } from "styled-components";

const Label = styled.label`
  position: relative;
  display: block;
  width: ${(props) => props.width || "34px"};
  height: ${(props) => props.height || "20px"};
  padding-top: 3px;
  border-radius: 18px;
  cursor: pointer;
  box-sizing: content-box;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;
`;

const SwitchLabel = styled.span`
  position: relative;
  display: block;
  height: ${(props) => props.height || "14px"};
  font-size: 10px;
  text-transform: uppercase;
  background: ${(props) => props.theme.sepColor};
  border-radius: inherit;
  box-sizing: content-box;
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    box-sizing: content-box;
  }

  &::before {
    content: attr(data-off);
    right: 11px;
    color: #aaaaaa;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
  }

  &::after {
    content: attr(data-on);
    left: 11px;
    color: #ffffff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
  }

  ${(props) =>
    props.checked &&
    css`
      background: ${(props) => (props.isBlue ? "#70A6E04D" : props.theme.primaryGreen)};
      box-shadow: ${(props) =>
        props.boxShadow || "inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2)"};

      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    `}
`;

const SwitchHandel = styled.span`
  position: absolute;
  left: 0;
  top: ${(props) => props.top || "0px"};
  width: ${(props) => props.width || "20px"};
  height: ${(props) => props.height || "20px"};
  background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: -1.5px 1.5px 4px 0 rgba(0, 0, 0, 0.16);
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;

  ${(props) =>
    props.checked &&
    css`
      right: 0;
      left: initial;
      box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
    `}

  ${(props) =>
    props.isBlue &&
    props.checked &&
    css`
      background-color: #70a6e0;
      background-image: initial;
    `}
`;

const InputSwitch = ({
  checked,
  setChecked,
  disabled,
  containerProps,
  isBlue,
  handleProps,
  labelProps
}) => {
  return (
    <Label disabled={disabled} {...containerProps}>
      <Input type="checkbox" checked={checked} onChange={setChecked} disabled={disabled} />
      <SwitchLabel checked={checked} isBlue={isBlue} {...labelProps} />
      <SwitchHandel checked={checked} isBlue={isBlue} {...handleProps} />
    </Label>
  );
};

export default InputSwitch;
