import apiConstants from "../constants/apiConstants";

export const getTokenSales = () => ({
  type: apiConstants.GET_TOKEN_SALES
});

// Token Sale Admin Dashboard
export const getAllClientSales = () => ({
  type: apiConstants.GET_ALL_CLIENT_SALES
});
