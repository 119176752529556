/* eslint-disable no-unused-vars */
import {
  UPDATE_LAST_PRICE,
  UPDATE_ACTIVE_TAB,
  UPDATE_ACTIVE_KEY,
  UPDATE_SELECTED_PAIR_NAME,
  UPDATE_ORDER_AMOUNT_BUY,
  UPDATE_TOTAL_AMOUNT_BUY,
  UPDATE_SILDER_PERCENTAGE,
  KYC_STATUS,
  RESTRICTED_STATUS,
  ORDER_LOADING_STATUS,
  GET_ERROR_MSG_STATUS,
  MARKET_ALERT_CONFIRMATION,
  UPDATE_PRICE_AMOUNT_ORDERBOOK,
  MARKET_CONFIRM_MODAL,
  UPDATE_LAST_PRICE_SELL,
  UPDATE_ORDER_AMOUNT_SELL,
  UPDATE_TOTAL_AMOUNT_SELL,
  UPDATE_SILDER_PERCENTAGE_SELL,
  GET_ERROR_MSG_STATUS_SELL,
  TABLET_UPDATE_ACTIVE_TAB,
  MARKET_ALERT_CONFIRMATION_SELL,
  UPDATE_CHART_VALUE,
  FAV_TAB_LIST,
  UPDATE_FEE_STATUS
} from "./const";

// Update Last Price...................
export const updateLastPrice = (getValue) => {
  return {
    type: UPDATE_LAST_PRICE,
    payload: getValue
  };
};

// Update Last Price...................
export const updateLastPriceSell = (getValue) => {
  return {
    type: UPDATE_LAST_PRICE_SELL,
    payload: getValue
  };
};

// Update ORDER Amount Value ...................
export const updateOrderAmount = (getValue) => {
  return {
    type: UPDATE_ORDER_AMOUNT_BUY,
    payload: getValue
  };
};

// Update ORDER Amount Value ...................
export const updateOrderAmountSell = (getValue) => {
  return {
    type: UPDATE_ORDER_AMOUNT_SELL,
    payload: getValue
  };
};

// Update Selected Amount from Order Book  Value ...................
export const updatePriceAmountFromOrderBook = (getValue) => {
  return {
    type: UPDATE_PRICE_AMOUNT_ORDERBOOK,
    payload: getValue
  };
};

// Update TOTAL Amount Value ...................
export const updateTotalAmount = (getValue) => {
  return {
    type: UPDATE_TOTAL_AMOUNT_BUY,
    payload: getValue
  };
};

// Update Sell TOTAL Amount Value ...................
export const updateTotalAmountSell = (getValue) => {
  return {
    type: UPDATE_TOTAL_AMOUNT_SELL,
    payload: getValue
  };
};

// Update Percentage ...................
export const updatePercentage = (getValue) => {
  return {
    type: UPDATE_SILDER_PERCENTAGE,
    payload: getValue
  };
};

// Update Percentage ...................
export const updatePercentageSell = (getValue) => {
  return {
    type: UPDATE_SILDER_PERCENTAGE_SELL,
    payload: getValue
  };
};

// Update Active Tab...................
export const updateActiveTab = (getValue) => {
  getValue.dispatch(updateLastPrice(getValue.lastPrice));
  getValue.dispatch(updateOrderAmount(""));
  getValue.dispatch(updatePercentage(0));
  getValue.dispatch(updateTotalAmount(""));
  return {
    type: UPDATE_ACTIVE_TAB,
    payload: getValue.activeTab
  };
};

// Update Active Tab Tablet...................
export const tabUpdateActiveTab = (getValue) => {
  return {
    type: TABLET_UPDATE_ACTIVE_TAB,
    payload: getValue.activeTab
  };
};

// Update Default Key Tab (Limit or Market)...................
export const updateDefaultActiveKey = (getValue) => {
  updateLastPrice(getValue.lastPrice);
  return {
    type: UPDATE_ACTIVE_KEY,
    payload: getValue
  };
};

// Update Selected Pair Name (Limit or Market)...................
export const updateSelectedPairName = (getValue) => {
  return {
    type: UPDATE_SELECTED_PAIR_NAME,
    payload: getValue
  };
};

// SE ORDER LOADING STATUS (Limit or Market)...................
export const setOrderLoading = (getValue) => {
  return {
    type: ORDER_LOADING_STATUS,
    payload: getValue
  };
};

// Update Open Kyc Modal (Limit or Market)...................
export const setKYCStatus = (getValue) => {
  return {
    type: KYC_STATUS,
    payload: getValue
  };
};

// Update Open Restricted Mode (Limit or Market)...................
export const setRestrictedMode = (getValue) => {
  return {
    type: RESTRICTED_STATUS,
    payload: getValue
  };
};

// Update Trade Page Error Msg State (Limit or Market)...................
export const updateErrorMsgState = (getValue) => {
  return {
    type: GET_ERROR_MSG_STATUS,
    payload: getValue
  };
};

// Update Trade Page Error Msg State (Limit or Market)...................
export const updateErrorMsgStateSell = (getValue) => {
  return {
    type: GET_ERROR_MSG_STATUS_SELL,
    payload: getValue
  };
};

// Update Trade Page Error Msg State (Limit or Market)...................
export const updateMarketAlertConfirmation = (getValue) => {
  return {
    type: MARKET_ALERT_CONFIRMATION,
    payload: getValue
  };
};

// Update Trade Page Error Msg State (Limit or Market)...................
export const updateMarketAlertConfirmationSell = (getValue) => {
  return {
    type: MARKET_ALERT_CONFIRMATION_SELL,
    payload: getValue
  };
};

// Update Chart Value...................
export const updateChartValue = (getValue) => {
  return {
    type: UPDATE_CHART_VALUE,
    payload: getValue
  };
};

// Update Fav Tab List...................
export const updateFavTabList = (getValue) => {
  return {
    type: FAV_TAB_LIST,
    payload: getValue
  };
};

// Update Fee Status...................
export const updateFeeStatus = (getValue) => {
  return {
    type: UPDATE_FEE_STATUS,
    payload: getValue
  };
};
