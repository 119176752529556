/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { WalletContext } from "../../../contexts/WalletPage/BalancePage/wallet";
import { DepositContext } from "../../../contexts/WalletPage/DepositPage/depositContext";
import DepositFormComponent from "./Component/depositForm";
import ImportPointFaq from "./Web/importantPointFaq";
import LightningBanner from "../../../components/Wallets/Components/LightningBanner";
import TransactionsTable from "../../../components/Wallets/Components/TransactionsTable";
import { customDecode } from "../../../utils/envScript/envCrypto";

const DepositPageComponent = () => {
  let { callAllWallet } = useContext(WalletContext);
  const history = useHistory();
  let {
    page,
    resetValue,
    selectedCoin,
    selectedProvider,
    setSelectedCoin,
    setInitialDepositLoad,
    setDepositCount,
    setDepositList,
    setIsLoader,
    setFqa,
    depositList,
    depositCount,
    setPage,
    callGetDeposits,
    selectedNetwork,
    setSelectedNetwork
  } = useContext(DepositContext);

  useEffect(() => {
    setInitialDepositLoad(true);
    setIsLoader(true);
    resetValue();
    setSelectedCoin("");
    setSelectedNetwork("");
    setFqa("");
    setDepositList("");
    setDepositCount(0);
    callAllWallet();
    if (history.location.state?.state) {
      let coinValue = history.location.state?.state?.getValue;
      setSelectedCoin(coinValue);
    }
  }, []);

  useEffect(() => {
    getDeposits();
  }, [page]);

  const getDeposits = () => {
    let data = {
      coin: selectedCoin.coin || "",
      order: "DESC",
      type: "DEPOSIT",
      page: page
    };

    callGetDeposits(data);
  };

  return (
    <BannerSection paddMd="20px 0px">
      <BannerBox display="flex" justifyContent="space-between" directionLg="column">
        <BannerBox
          padding={"0 0px 0px 16px"}
          paddingMd={"0 16px"}
          position="relative"
          width="50%"
          widthLg="100%"
        >
          <BannerHeading size={"24px"} sizeLg={"20px"} sizeMd={"18px"} mb={"12px"}>
            Deposit
          </BannerHeading>
          <BannerHeading txtGrey size={"16px"} sizeLg={"18px"} sizeMd={"12px"}>
            Manage your digital asset deposits here.
          </BannerHeading>

          <DepositFormComponent />
        </BannerBox>

        <BannerBox
          padding={"0 16px"}
          paddingMd={"0 16px"}
          mtLg={"40px"}
          width="50%"
          widthLg="100%"
          widthMd="100%"
        >
          {selectedCoin &&
          (selectedNetwork || selectedProvider) &&
          (selectedCoin.coin !== "BTC" || (selectedCoin.coin === "BTC" && selectedNetwork)) ? (
            <ImportPointFaq />
          ) : (
            <Fragment>
              {customDecode(process.env.REACT_APP_SHOW_BANNER_OF_BTC_LIGHTNING) === "true" && (
                <LightningBanner type="Deposit" />
              )}
            </Fragment>
          )}
        </BannerBox>
      </BannerBox>

      <BannerBox mt={"48px"} padding={"0 16px"} paddingMd={"0 16px 16px"}>
        <BannerHeading size="24px" sizeMd={"18px"} mb={"40px"} mbLg={"20px"}>
          Recent Deposit History
        </BannerHeading>

        <TransactionsTable
          transactionsList={depositList}
          page={page + 1}
          setPage={(page) => setPage(page - 1)}
          totalCount={depositCount}
          tab="Deposits"
        />
      </BannerBox>
    </BannerSection>
  );
};

export default DepositPageComponent;
