import React, { Fragment } from "react";
import { ProgressBar } from "react-bootstrap";
import Countdown from "react-countdown";
import { numberWithCommas } from "../../../../../utils/general";
import CountdownRenderer from "./CountdownRenderer";
import RangeSlider from "./RangeSlider";
import DoughnutChart from "./DoughnutChart";
import { TokenSaleAdminContainer } from "../../../../../assets/StyleComponents/TokenSaleAdmin.style";
import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../../../../assets/StyleComponents/FontSize/headingFourth.style";

const COLORS = [
  "#e63946",
  "#ffb703",
  "#219ebc",
  "#8d99ae",
  "#003049",
  "#a7c957",
  "#0077b6",
  "#ffb4a2",
  "#4361ee",
  "#540b0e",
  "#70e000",
  "#f4f0bb",
  "#e0aaff",
  "#feeafa",
  "#f5f0f6",
  "#ffb3c6",
  "#f3de2c",
  "#f15bb5",
  "#e7c6ff",
  "#48bfe3"
];

const SaleAnalyticsRow3 = (props) => {
  let { loader, saleDetails } = props;
  let roundData = saleDetails && saleDetails.totalRaisedInRounds.map((data) => +data.amountUSD);
  let roundLabels = saleDetails && saleDetails.totalRaisedInRounds.map((data) => data.name);
  let isLoaded = !loader && saleDetails;
  return (
    <TokenSaleAdminContainer SaleAnalyticsRow3>
      <BannerBox
        width="100%"
        height="145px"
        heightLg="239px"
        heightMd="218px"
        mt="35px"
        display="flex"
        directionLg="column"
        className="saleanalytics__row3"
      >
        <BannerBox
          CardWhite
          width="60%"
          widthLg="100%"
          widthMd="100%"
          padding="16px 18px 84px"
          paddingLg="22px 39px"
          paddingMd="30px 16px 24px 16px"
          mr="8px"
          mrLg="0"
          className="saleanalytics__row3__col1 element-box"
        >
          <HeadingFourth
            size="18px"
            sizeLg="16px"
            mb="16px"
            weight="bold"
            txtGrey
            className="row3__col1__title"
          >
            Token Sale Progress
          </HeadingFourth>
          {isLoaded && (
            <Fragment>
              <BannerBox className="row3__col1__slider">
                <RangeSlider
                  totalToken={numberWithCommas(saleDetails.totalCap)}
                  percentage={saleDetails.tokenSaleProgressPercentage}
                  total_tokens_sold={numberWithCommas(saleDetails.totalSoldToken)}
                  tokenSaleCoin={saleDetails.tokenSaleCoin}
                />
              </BannerBox>
              <BannerBox className="row3__col1__container">
                <BannerBox className="row3__col1__rounddetails">
                  {saleDetails.totalRaisedInRounds.map((data, i) => {
                    return (
                      <BannerBox key={i} className="col1__rounddetails__box">
                        <BannerBox className="rounddetails__box__percent">
                          {+data.percentage}%
                        </BannerBox>
                        <ProgressBar
                          style={{
                            height: "32px",
                            backgroundColor: "#d9ebfe"
                          }}
                          now={+data.percentage}
                          // label={`${+data.percentage}%`}
                        />
                        <p className="rounddetails__box__info">
                          {data.name}
                          <br />$ {numberWithCommas(+data.amountUSD)} Raised
                        </p>
                      </BannerBox>
                    );
                  })}
                </BannerBox>
                <BannerBox className="row3__col1__saletimer">
                  <Countdown
                    date={
                      (+saleDetails.tokenSaleStartAt * 1000 < Date.now()
                        ? +saleDetails.tokenSaleEndsAt
                        : +saleDetails.tokenSaleStartAt) * 1000
                    }
                    autoStart={true}
                    renderer={(e) => CountdownRenderer(e, "COMPLETED")}
                  />
                </BannerBox>
              </BannerBox>
            </Fragment>
          )}
        </BannerBox>
        <BannerBox CardWhite className="saleanalytics__row3__col2 element-box">
          <BannerBox className="row3__col2__title">Token Sale Distribution</BannerBox>
          {isLoaded && (
            <BannerBox className="row3__col2__chart">
              <DoughnutChart
                chartData={{
                  labels: roundLabels,
                  datasets: [
                    {
                      label: "Token Sale Distribution",
                      data: roundData,
                      backgroundColor: COLORS,
                      hoverBackgroundColor: COLORS,
                      borderColor: COLORS,
                      hoverBorderColor: COLORS,
                      hoverOffset: 4
                    }
                  ]
                }}
              />
            </BannerBox>
          )}
        </BannerBox>
      </BannerBox>
    </TokenSaleAdminContainer>
  );
};

export default SaleAnalyticsRow3;
