import React, { useMemo } from "react";
import { BannerHeading } from "../assets/StyleComponents/FontSize/Heading.style";

const DropDownListLabel = ({ First, Second, availStaus = true }) => {
  return useMemo(() => {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <BannerHeading size={"12px"} txtGrey>
          {" "}
          {First}{" "}
        </BannerHeading>
        {availStaus && (
          <BannerHeading size={"12px"} txtGrey>
            {" "}
            {Second}{" "}
          </BannerHeading>
        )}
      </div>
    );
  }, [First, Second, availStaus]);
};

export default DropDownListLabel;
