/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect } from "react";
import { usePrivateWebSocket, usePublicWebSocket } from "../../Hooks/TradeWebSocket";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPairsCategories,
  updateAllAsksAndBids,
  updateBestAsk,
  updateBestBid,
  updateOpenOrdersFromSocket,
  updateOrderBookGraphData,
  updateSelectedPair,
  updateSelectedPairTicker
} from "../../actions/trading";
import { getAllWallets } from "../../actions/wallet";
import { getWalletBalance } from "../../actions/dashboard";
import { getAllAsks, getAllBids, getOrderBookGraphData } from "../../utils/trading";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const publicSocket = usePublicWebSocket();
  const privateSocket = usePrivateWebSocket();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const allBuyOrders = useSelector((state) => state.trading.allBuyOrders);
  const allSellOrders = useSelector((state) => state.trading.allSellOrders);
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const tickersData = useSelector((state) => state.trading.tickersData);
  const userOrderUpdate = useSelector((state) => state.trading.userOrderUpdate);
  const openOrdersData = useSelector((state) => state.trading.openOrdersData);
  const orderHistoryData = useSelector((state) => state.trading.orderHistoryData);
  const showAllPairsOrder = useSelector((state) => state.trading.showAllPairsOrder);
  const selectedPairConfigErr = useSelector((state) => state.trading.selectedPairConfigErr);
  const selectedPairTicker = useSelector((state) => state.trading.selectedPairTicker);
  const selectedPricePrecision = useSelector((state) => state.trading.selectedPricePrecision);
  const { width } = useWindowSize();
  const isTablet = width > 767 && width <= 1024 ? true : false;

  useEffect(() => {
    dispatch(getAllPairsCategories());
  }, []);

  useEffect(() => {
    if (selectedPairConfigErr && selectedPair.symbol !== "LCX/EUR") {
      dispatch(updateSelectedPair("LCX/EUR"));
    }
  }, [selectedPairConfigErr]);

  useEffect(() => {
    if (selectedPair) {
      let data = tickersData ? tickersData[selectedPair?.symbol] : "";
      dispatch(updateSelectedPairTicker(data));
    }
  }, [selectedPair]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getAllWallets());
      dispatch(getWalletBalance());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    let bids = getAllBids(allBuyOrders, selectedPricePrecision);
    if (bids && bids.length > 0) {
      let bestBidPrice = bids[0][0];
      dispatch(updateBestBid(bestBidPrice));
    } else {
      dispatch(updateBestBid(selectedPairTicker?.bestBid || ""));
    }

    dispatch(updateAllAsksAndBids(bids, "orderBookBids"));
    let graphData = getOrderBookGraphData(bids, "buy");
    dispatch(updateOrderBookGraphData(graphData));
  }, [allBuyOrders, selectedPricePrecision]);

  useEffect(() => {
    let sellOrders = getAllAsks(allSellOrders, selectedPricePrecision);
    let asks = [...sellOrders];

    if (!isTablet) {
      asks.reverse();
    }

    if (sellOrders && sellOrders.length > 0) {
      let bestAskPrice = sellOrders[0][0];
      dispatch(updateBestAsk(bestAskPrice));
    } else {
      dispatch(updateBestAsk(selectedPairTicker?.bestAsk || ""));
    }

    dispatch(updateAllAsksAndBids(asks, "orderBookAsks"));
    let graphData = getOrderBookGraphData(sellOrders, "sell");
    dispatch(updateOrderBookGraphData(graphData));
  }, [allSellOrders, selectedPricePrecision, isTablet]);

  useEffect(() => {
    if (!userOrderUpdate) return;

    updateUserOrders();
  }, [userOrderUpdate]);

  const updateUserOrders = () => {
    let orderData = userOrderUpdate;

    if (!showAllPairsOrder && orderData.Pair !== selectedPair.symbol) {
      return;
    }

    let orders = openOrdersData?.data || [];
    let totalCount = openOrdersData?.totalCount || 0;
    let orderHistory = orderHistoryData?.data || [];
    let orderHistoryTotal = orderHistoryData?.totalCount || 0;

    if (orderData.Status === "OPEN" || orderData.Status === "PARTIAL") {
      const orderIndex = orders.findIndex((order) => order.Id === orderData.Id);
      if (orderIndex === -1) {
        orders.unshift(orderData);
        if (totalCount >= 25) {
          orders.pop();
        } else {
          totalCount += 1;
        }
      } else {
        orders[orderIndex] = orderData;
      }

      let data = {
        data: orders,
        totalCount: totalCount,
        type: "openOrders"
      };

      dispatch(updateOpenOrdersFromSocket(data));
    } else {
      orders = orders.filter((order) => order.Id !== orderData.Id);
      totalCount -= 1;

      const orderHistoryIndex = orderHistory.findIndex((order) => order.Id === orderData.Id);
      if (orderHistoryIndex === -1) {
        orderHistory.unshift(orderData);
        if (orderHistoryTotal >= 25) {
          orderHistory.pop();
        } else {
          orderHistoryTotal += 1;
        }
      } else {
        orderHistory[orderHistoryIndex] = orderData;
      }

      let data = {
        data: orderHistory,
        totalCount: orderHistoryTotal,
        type: "orderHistory"
      };

      dispatch(updateOpenOrdersFromSocket(data));
    }

    let data = {
      data: orders,
      totalCount: totalCount,
      type: "openOrders"
    };

    dispatch(updateOpenOrdersFromSocket(data));
  };

  return (
    <WebSocketContext.Provider value={{ public: publicSocket, private: privateSocket }}>
      {children}
    </WebSocketContext.Provider>
  );
};
