import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createRef } from "react";
import { CameraBlueIcon, UploadBlueIcon } from "../../../assets/svgs";
// import { KycDivBox } from "../../../assets/StyleComponents/kyc.style";

const Uploader = ({ onUpload, handleCaptureId, userData, step }) => {
  const attempts = userData && userData.KYC.LEVEL2.step2.attempts;
  const showUpload = attempts >= 3 || step === 4;
  const inputRef = createRef();

  const uploadHandler = (e) => {
    onUpload(e);
    inputRef.current.value = "";
  };

  return (
    // <KycDivBox KycStepsContainerCommon>
      <div className="kycsteps__uploader">
        {showUpload && (
          <>
            <div className="kycsteps__uploader__box">
              <label>
                <div className="kycsteps__uploader__circle">
                  <UploadBlueIcon width="28px" height="28px" />
                  <input
                    ref={inputRef}
                    type="file"
                    onChange={uploadHandler}
                    className="btn btn-primary"
                    style={{ display: "none" }}
                  />
                </div>
              </label>

              <div className="kycsteps__uploader__title">Upload Image</div>
            </div>
            <div className="kycsteps__seperator">
              <div className="kycsteps__seperator__line" />
              or
              <div className="kycsteps__seperator__line" />
            </div>
          </>
        )}

        <div className="kycsteps__uploader__box">
          <div className="kycsteps__uploader__circle" onClick={handleCaptureId}>
            <CameraBlueIcon width="28px" height="28px" />
          </div>
          <div className="kycsteps__uploader__title">Use Web Camera</div>
        </div>
      </div>
    // </KycDivBox>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.getUserData
});

export default compose(connect(mapStateToProps))(Uploader);
