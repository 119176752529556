import React, { createContext, useCallback, useState } from "react";
import { getAllWallets, getWalletBalance } from "../../../lib/api";
import { formatAPIErrors, sortNumberData } from "../../../utils/general";
import { useLocalStorage } from "../../../Hooks/LocalStorage/useLocalStorage.js";
import { showErrorMsg } from "../../../utils/notification";
import { useDispatch } from "react-redux";
import { updatePrivateSocketBalance } from "../../../actions/trade.js";

export const WalletContext = createContext();

const WalletProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [exchWallet, setExchWallet] = useState("");
  const [exchWalletCopy, setExchWalletCopy] = useState("");
  const [mainBalance, setMainBalance] = useState("");
  const [mainBalanceCopy, setMainBalanceCopy] = useState("");
  const [hideStatus, setHideStatusRes] = useLocalStorage("hideStatus", false);
  const [activeWallet, setActiveWalletRes] = useState("account");
  const [filterValue, setFilterValueRes] = useState("");
  const [filterZero, setFilterZeroRes] = useState(false);
  const [exchWalletError, setExchWalletErrorRes] = useState(false);
  const [mainWalletError, setMainWalletErrorRes] = useState(false);
  const [activeMobileTab, setActiveMobileTabRes] = useState("Total");

  const updateExchWallet = useCallback((props) => {
    setExchWallet(props);
  }, []);

  const updateExchWalletCopy = useCallback((props) => setExchWalletCopy(props), []);

  const updateMainWallet = useCallback((props) => {
    setMainBalance(props);
  }, []);

  const updateMainWalletCopy = useCallback((props) => setMainBalanceCopy(props), []);

  const updateMainWalletError = useCallback((props) => setMainWalletErrorRes(props), []);
  const updateExchWalletError = useCallback((props) => setExchWalletErrorRes(props), []);

  const setHideStatus = useCallback((props) => setHideStatusRes(props), []);
  const setActiveWallet = useCallback((props) => setActiveWalletRes(props), []);
  const setFilterValue = useCallback((props) => setFilterValueRes(props), []);
  const setFilterZero = useCallback((props) => setFilterZeroRes(props), []);
  const setActiveMobileTab = useCallback((props) => setActiveMobileTabRes(props), []);

  const callAllWallet = () => {
    getAllWallets()
      .then((res) => {
        updateExchWallet(sortNumberData(res.data, true));
        updateExchWalletCopy(sortNumberData(res.data, true));
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      });
  };

  const callMainWallet = () => {
    getWalletBalance()
      .then((res) => {
        updateMainWallet(sortNumberData(res.data, true));
        updateMainWalletCopy(sortNumberData(res.data, true));
        dispatch(
          updatePrivateSocketBalance({
            walletBalance: res.data,
            walletBalanceCopy: res.data,
            wallet: true
          })
        );
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      });
  };

  const updateWallet = async () => {
    await callAllWallet();
    await callMainWallet();
  };

  return (
    <WalletContext.Provider
      value={{
        exchWallet,
        updateExchWallet,
        exchWalletCopy,
        updateExchWalletCopy,
        mainBalance,
        updateMainWallet,
        mainBalanceCopy,
        updateMainWalletCopy,
        hideStatus,
        setHideStatus,
        activeWallet,
        setActiveWallet,
        filterValue,
        setFilterValue,
        filterZero,
        setFilterZero,
        activeMobileTab,
        setActiveMobileTab,
        updateWallet,
        callAllWallet,
        callMainWallet,
        updateMainWalletError,
        updateExchWalletError,
        exchWalletError,
        mainWalletError
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
