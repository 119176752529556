import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import { CloseIcon, CopyBlueIcon, LinkBlueIcon } from "../../../../../assets/svgs";
import { AnchorTag, ParaTag } from "../../../../../assets/StyleComponents/FontSize/para.style";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection,
  MainElement
} from "../../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../../assets/StyleComponents/FontSize/Heading.style";
import { HeadingSix } from "../../../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { Button, DivElement, ImgElement, TextWrapper } from "../../../../../components/Reusable";
import moment from "moment";

import WithdrawalLoader from "./withdrawalLoader";
import { showErrorMsg, showSuccessMsg } from "../../../../../utils/notification";
import { formatAPIErrors } from "../../../../../utils/general";
// import { DepositContext } from "../../../../../contexts/WalletPage/DepositPage/depositContext";
import { getTransactionStatus } from "../../../../../lib/api";
import { HourGlassIllustration } from "../../../../../assets/svgs/illustrations";
import configs from "../../../../../constants/config";
import { DepositContext } from "../../../../../contexts/WalletPage/DepositPage/depositContext";
import { getAllWallets } from "../../../../../lib/api";
import { connect } from "react-redux";
import { compose } from "redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { WalletContext } from "../../../../../contexts/WalletPage/BalancePage/wallet";

const capitalize = (str) => {
  str = str?.toLowerCase();
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

function WithdrawalStatus(props) {
  const { selectedCoin, txDetails, selectedNetwork, isTheme, getTransactions, onCloseModal } =
    props;
  const [statusDetails, setStatusDetails] = useState("");
  const [isFetchingStatus, setisFetchingStatus] = useState(false);
  // const [getInterval, setGetInterval] = useState();
  const { setWithdrawalCount, setWithdrawalList, resetOnSuccess } = useContext(DepositContext);
  const { updateWallet } = useContext(WalletContext);

  const intervalRef = useRef(null);
  const history = useHistory();

  // const userData = useSelector(state => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");
  const close = () => {
    getTransactions({
      order: "DESC",
      type: "WITHDRAWAL",
      page: 0,
      coin: selectedCoin.coin
    })
      .then((res) => {
        if (res.status === 200) {
          setWithdrawalCount(res.data.count);
          setWithdrawalList(res.data.data);
        }
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      });

    history.push(`${prevLang ? `/${prevLang}` : "/en"}/wallet/balances`);
    updateWallet();
    resetOnSuccess();
    clearInterval(intervalRef.current);
    onCloseModal();
  };

  const onCopy = (type) => {
    showSuccessMsg(`${type === "address" ? "Address" : "Transaction Id"} copied!`);
  };

  const getStatus = (type) => {
    // if (type === false) {
    //   setisFetchingStatus(true);
    // }

    getTransactionStatus({ txId: txDetails.transactionId })
      .then((res) => {
        const status = res.data?.data?.txStatus;
        setStatusDetails(res && res.data.data);

        if (status && (status === "REJECTED" || status === "CONFIRMED" || status === "FAILED")) {
          // intervalRef.current = null;
          clearInterval(intervalRef.current);
        } else {
          setisFetchingStatus(false);
          // setGetInterval(getInterval2);
        }
        setStatusDetails(res && res.data.data);
      })
      .catch((err) => {})
      .finally(() => {
        setisFetchingStatus(false);
      });
  };

  useEffect(() => {
    setisFetchingStatus(true);
    getStatus(isFetchingStatus);
    intervalRef.current = setInterval(() => {
      getStatus(isFetchingStatus);
    }, 3000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <Fragment>
      {isFetchingStatus || !statusDetails ? (
        <BannerBox display="flex" direction="column" justifyContent="center" width="100%">
          <WithdrawalLoader />
        </BannerBox>
      ) : (
        <Fragment>
          <DivElement displayBtw marginBottom="20px">
            <TextWrapper fontSize="20px" fontWeight="bold">
              Withdrawal Details
            </TextWrapper>
            <CloseIcon onClick={close} />
          </DivElement>

          {txDetails.adminApproval ? (
            <>
              <MainElement mt="15px" display="flex" direction="row" width="100%" gap="12px">
                <BannerBox width="10%" display="flex" direction="column" alignItems="center">
                  <BannerBox
                    Status
                    className="confirm-status"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    1
                  </BannerBox>
                  <hr
                    style={{
                      border: "0.5px dashed #8c9797",
                      letterSpacing: "2px",
                      height: "100%",
                      margin: "3px 0"
                    }}
                  />
                  <BannerBox
                    Status
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={
                      statusDetails.txStatus === "CONFIRMED" ? "confirm-status" : "pending-status"
                    }
                  >
                    2
                  </BannerBox>
                </BannerBox>

                <BannerSection width="90%">
                  <BannerHeading size="16px" weight="bold" BodyColor>
                    Withdrawal Request Submitted
                  </BannerHeading>

                  <HeadingSix size="14px" txtGrey mt="10px">
                    Please note that you will receive an email once it is completed
                  </HeadingSix>

                  <BannerBox margin="15px 0px">
                    <HeadingSix size="14px" txtGrey>
                      The receiver will get
                    </HeadingSix>
                    <BannerHeading size="16px" weight="300" BodyColor>
                      {statusDetails.amount} {selectedCoin.coin} (Fee: {statusDetails.userFee}{" "}
                      {selectedCoin?.withdrawalFeeCoin || ""})
                    </BannerHeading>
                  </BannerBox>

                  <BannerSection
                    TutorialBg
                    style={{ borderRadius: "5px" }}
                    display="flex"
                    direction="column"
                    padd="12px"
                    gap="18px"
                    mb="30px"
                  >
                    <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                      <HeadingSix size="14px" weight="500" txtGrey>
                        Asset
                      </HeadingSix>
                      <HeadingSix size="14px" weight="500" BodyColor>
                        {selectedCoin.coin}
                      </HeadingSix>
                    </BannerBox>

                    <BannerBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      gap="15px"
                    >
                      <HeadingSix size="14px" weight="500" txtGrey>
                        Address
                      </HeadingSix>
                      <HeadingSix size="14px" weight="500" BodyColor style={{ overflow: "scroll" }}>
                        {statusDetails?.awayAddress.length > 20
                          ? statusDetails?.awayAddress?.slice(0, 15) + "..."
                          : statusDetails?.awayAddress}
                      </HeadingSix>
                    </BannerBox>

                    <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                      <HeadingSix size="14px" weight="500" txtGrey>
                        Network
                      </HeadingSix>
                      <HeadingSix size="14px" weight="500" BodyColor>
                        {selectedNetwork?.label}
                      </HeadingSix>
                    </BannerBox>
                  </BannerSection>

                  <BannerBox display="flex" alignItems="center" gap="5px">
                    <BannerHeading size="16px" weight="bold" BodyColor>
                      {statusDetails.txStatus === "APPROVAL_PENDING" && `Awaiting Approval`}
                      {statusDetails.txStatus === "CONFIRMED" && `Withdrawal Confirmed`}
                      {statusDetails.txStatus === "REJECTED" && `Withdrawal Rejected`}
                      {statusDetails.txStatus === "FAILED" && `Withdrawal Failed`}
                    </BannerHeading>

                    {statusDetails.txStatus === "APPROVAL_PENDING" && <HourGlassIllustration />}
                  </BannerBox>
                </BannerSection>
              </MainElement>
              <BannerBox width="87%" ml="auto" mt="10px">
                {statusDetails.txStatus === "APPROVAL_PENDING" && (
                  <ParaTag size="14px" txtGrey>
                    Your withdrawal request is being reviewed and will take approximately
                    {selectedNetwork?.label === "Wire Transfer" ? " 3 - 4 days" : " 12 hours"}. You
                    can view the status of your withdrawal request in the withdrawal history page.
                  </ParaTag>
                )}
              </BannerBox>

              <Button primaryBlue width="100%" height="48px" marginTop="24px" onClick={close}>
                Complete
              </Button>
            </>
          ) : (
            statusDetails &&
            statusDetails.txStatus && (
              <>
                <MainElement mt="15px" display="flex" direction="row" width="100%" gap="12px">
                  <BannerBox width="10%" display="flex" direction="column" alignItems="center">
                    <BannerBox
                      Status
                      className="confirm-status"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      1
                    </BannerBox>
                    <hr
                      style={{
                        border: "0.5px dashed #8c9797",
                        letterSpacing: "2px",
                        height: "100%",
                        margin: "3px 0"
                      }}
                    />
                    <BannerBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      Status
                      className={`d-flex justify-content-center align-items-center ${
                        statusDetails.txStatus === "CONFIRMED" ||
                        statusDetails.txStatus === "PENDING" ||
                        statusDetails.txStatus === "PARTIALLY CONFIRMED"
                          ? "confirm-status"
                          : statusDetails.txStatus === "REJECTED" ||
                            statusDetails.txStatus === "FAILED"
                          ? "reject-status"
                          : "pending-status"
                      }`}
                    >
                      2
                    </BannerBox>
                    <hr
                      style={{
                        border: "0.5px dashed #8c9797",
                        letterSpacing: "2px",
                        height: "100%",
                        margin: "3px 0"
                      }}
                    />
                    <BannerBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      Status
                      className={`
                        ${
                          statusDetails.txStatus === "CONFIRMED" ||
                          statusDetails.txStatus === "PARTIALLY CONFIRMED"
                            ? "confirm-status"
                            : statusDetails.txStatus === "REJECTED" ||
                              statusDetails.txStatus === "FAILED"
                            ? "reject-status"
                            : "pending-status  opacity-4"
                        }
                      `}
                      opacity={statusDetails.txStatus === "PARTIALLY CONFIRMED" && "0.4"}
                    >
                      3
                    </BannerBox>
                  </BannerBox>
                  <BannerBox
                    width="90%"
                    display="flex"
                    justifyContent="space-between"
                    direction="column"
                  >
                    <BannerBox mb="15px">
                      <BannerHeading size="16px" weight="bold" BodyColor>
                        Withdrawal Order Submitted
                      </BannerHeading>
                      <HeadingSix size="14px" txtGrey mt="10px">
                        {moment(statusDetails.createdAt).format("DD/MM/YYYY")},{" "}
                        {moment(statusDetails.createdAt).format("hh:mm")}
                      </HeadingSix>
                    </BannerBox>

                    <BannerBox mb="15px">
                      <BannerHeading size="16px" weight="bold" BodyColor>
                        Withdrawal{" "}
                        {statusDetails.network === "LCX Pay"
                          ? statusDetails.txStatus === "CONFIRMED" && "Processed"
                          : statusDetails.txStatus === "SUBMITTED"
                          ? "Processing"
                          : statusDetails.txStatus === "REJECTED" ||
                            statusDetails.txStatus === "FAILED"
                          ? "Rejected"
                          : "Processed"}
                      </BannerHeading>
                      <HeadingSix size="14px" txtGrey mt="10px">
                        {moment(statusDetails.updatedAt).format("DD/MM/YYYY")},{" "}
                        {moment(statusDetails.updatedAt).format("hh:mm")}
                      </HeadingSix>
                    </BannerBox>

                    <BannerBox>
                      <BannerHeading
                        size="16px"
                        weight="bold"
                        BodyColor
                        opacity={
                          statusDetails.network === "LCX Pay"
                            ? statusDetails.txStatus === "CONFIRMED" && "1"
                            : statusDetails.txStatus === "SUBMITTED"
                            ? "0.4"
                            : "1"
                        }
                      >
                        Withdrawal{" "}
                        {statusDetails.network === "LCX Pay"
                          ? statusDetails.txStatus === "CONFIRMED"
                            ? "Successful"
                            : "Failed"
                          : statusDetails.txStatus === "REJECTED" ||
                            statusDetails.txStatus === "FAILED"
                          ? "Failed"
                          : "Successful"}
                      </BannerHeading>
                    </BannerBox>
                  </BannerBox>
                </MainElement>
                <BannerBox width="87%" ml="auto" mt="7px">
                  <ParaTag
                    size="14px"
                    txtGrey
                    opacity={
                      statusDetails.network === "LCX Pay"
                        ? statusDetails.txStatus === "CONFIRMED" && "1"
                        : statusDetails.txStatus === "SUBMITTED"
                        ? "0.4"
                        : "1"
                    }
                  >
                    You will receive an email once it is completed.
                  </ParaTag>
                </BannerBox>

                <BannerSection
                  className="p-2 font-weight-500 f-14"
                  style={{ borderTop: "1px solid #ccced0" }}
                  display="flex"
                  direction="column"
                  gap="18px"
                  margin="20px 0px"
                  paddTop="10px"
                >
                  {/* <div className="d-flex justify-content-between py-2">
                      <span className="txt-grey">Status</span>
                      <span className="txt-light-black">{capitalize(statusDetails.txStatus)}</span>
                    </div> */}

                  <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                    <HeadingSix size="14px" weight="500" txtGrey>
                      Status
                    </HeadingSix>
                    <HeadingSix size="14px" weight="500" BodyColor>
                      {capitalize(statusDetails.txStatus)}
                    </HeadingSix>
                  </BannerBox>

                  <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                    <HeadingSix size="14px" weight="500" txtGrey>
                      Date
                    </HeadingSix>
                    <HeadingSix size="14px" weight="500" BodyColor>
                      {moment(statusDetails.createdAt).format("DD/MM/YYYY")},{" "}
                      {moment(statusDetails.createdAt).format("hh:mm")}
                    </HeadingSix>
                  </BannerBox>

                  <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                    <HeadingSix size="14px" weight="500" txtGrey>
                      Asset
                    </HeadingSix>
                    <HeadingSix size="14px" weight="500" BodyColor>
                      <ImgElement
                        height="16px"
                        width="16px"
                        alt="coin"
                        src={`${
                          !isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                        }${selectedCoin.coin.toLowerCase()}.svg`}
                      />{" "}
                      {selectedCoin.coin}
                    </HeadingSix>
                  </BannerBox>

                  <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                    <HeadingSix size="14px" weight="500" txtGrey>
                      Withdrawal Amount
                    </HeadingSix>
                    <HeadingSix size="14px" weight="500" BodyColor>
                      {String(statusDetails.amount)} {selectedCoin.coin}
                    </HeadingSix>
                  </BannerBox>
                  <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                    <HeadingSix size="14px" weight="500" txtGrey>
                      Withdrawal Fee
                    </HeadingSix>
                    <HeadingSix size="14px" weight="500" BodyColor>
                      {statusDetails.userFee} {selectedCoin.withdrawalFeeCoin}
                    </HeadingSix>
                  </BannerBox>
                  <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                    <HeadingSix size="14px" weight="500" txtGrey>
                      Network
                    </HeadingSix>
                    <HeadingSix size="14px" weight="500" BodyColor>
                      {selectedNetwork?.label}
                    </HeadingSix>
                  </BannerBox>
                  <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                    <HeadingSix size="14px" weight="500" txtGrey>
                      {selectedNetwork?.label === "LCX Pay" ? "E-mail address" : "Address"}
                    </HeadingSix>
                    <BannerBox
                      BodyColor
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      textAlign="right"
                    >
                      <BannerHeading TextTruncate BodyColor width="60%" size="14px" weight="500">
                        {statusDetails?.awayAddress?.slice(0, 25) + "..."} &nbsp;
                      </BannerHeading>

                      <BannerHeadingSpan BodyColor>
                        <CopyToClipboard
                          onCopy={() => onCopy("address")}
                          text={statusDetails.awayAddress}
                        >
                          <CopyBlueIcon />
                        </CopyToClipboard>
                        {statusDetails?.addressLink && (
                          <Fragment>
                            &nbsp;
                            <a href={statusDetails.addressLink} target="_blank" rel="noreferrer">
                              <LinkBlueIcon />
                            </a>
                          </Fragment>
                        )}
                      </BannerHeadingSpan>
                    </BannerBox>
                  </BannerBox>

                  {statusDetails.transactionId && (
                    <BannerBox display="flex" justifyContent="space-between" alignItems="center">
                      <HeadingSix size="14px" weight="500" txtGrey>
                        TxID
                      </HeadingSix>

                      <BannerBox
                        BodyColor
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        textAlign="right"
                      >
                        <BannerHeading TextTruncate BodyColor width="60%" size="14px" weight="500">
                          {statusDetails?.transactionId?.slice(0, 25) + "..."} &nbsp;
                        </BannerHeading>

                        <BannerHeadingSpan BodyColor>
                          <CopyToClipboard
                            onCopy={() => onCopy("transaction")}
                            text={statusDetails.transactionId}
                          >
                            <CopyBlueIcon />
                          </CopyToClipboard>
                          {statusDetails?.transactionLink && (
                            <Fragment>
                              &nbsp;
                              <AnchorTag
                                href={statusDetails.transactionLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <LinkBlueIcon />
                              </AnchorTag>
                            </Fragment>
                          )}
                        </BannerHeadingSpan>
                      </BannerBox>
                    </BannerBox>
                  )}
                </BannerSection>
              </>
            )
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getMainWallet: () => dispatch(getAllWallets())
});

export default compose(connect(null, mapDispatchToProps))(WithdrawalStatus);
