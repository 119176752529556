import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPromotionList } from "../../../actions/LandingPage/LandingPage";
import PermotionParent from "./PermotionParent";

const PermotionAll = () => {
  const dispatch = useDispatch();

  const promotionTextList = useSelector(state => state.promotion.promotionList);

  useEffect(() => {
    dispatch(getPromotionList());
  }, []);
  if(promotionTextList && promotionTextList.length === 0) return null;
  return (
    <PermotionParent>
      <PermotionParent.PromotionText />

      <PermotionParent.PromotionCarousel />
    </PermotionParent>
  );
};

export default PermotionAll;