/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { deleteUserKey } from "../../lib/api";
import { getApiKeyManagment } from "../../actions/setting.js";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { CloseIcon } from "../../assets/svgs";
import { DeleteBoxIllustration } from "../../assets/svgs/illustrations";
import { DivElement, ModalWrapper, TextWrapper } from "../../components/Reusable";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style.js";
import InputFieldFormik from "../../components/Reusable/Field/index";
import ErrorMessageDiv from "../../components/Reusable/Field/ErrorMessage";

const deleteKeyManagementSchema = Yup.object().shape({
  twoFAToken: Yup.string().required("Google Authentication Code Must!")
});

function DeleteManagmentComponent(props) {
  const [editData, setEditdata] = useState(props.editData);

  const getFormikInitVal = () => {
    return {
      twoFAToken: ""
    };
  };

  const deleteKeyManagment = (values, { resetForm }) => {
    let params = {
      ApiKey: editData.ApiKey
    };

    deleteUserKey(params, values.twoFAToken).then(
      (res) => {
        showSuccessMsg(`${res.data.message}`, { autoClose: 5000 });
        props.getApiKeyManagment({ offset: 1 });
        props.onClose();
        resetForm();
      },
      (err) => {
        resetForm();
        showErrorMsg(err.response.data.message, { autoClose: 5000 });
      }
    );
  };
  let { ApiKey } = editData;

  return (
    <>
      <ModalWrapper>
        <DivElement ModalContainer>
          <DivElement displayBtw>
            <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
              Delete Confirmation
            </TextWrapper>
            <CloseIcon pointer onClick={() => props.onClose()} />
          </DivElement>

          <BannerBox display="flex" direction="column" alignItems="center" width="100%">
            <BannerBox margin="1rem 0px">
              <BannerBox margin="20px 0px" textAlign="center">
                <DeleteBoxIllustration />
              </BannerBox>

              <ParaTag size="16px" txtGrey textAlign="left">
                Delete API Key <BannerHeadingSpan weight="bold"> {ApiKey} </BannerHeadingSpan> will be
                deleted permanently from our system.
              </ParaTag>
            </BannerBox>

            <BannerBox width="100%">
              <Formik
                initialValues={getFormikInitVal()}
                validationSchema={deleteKeyManagementSchema}
                onSubmit={deleteKeyManagment}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form>
                    <BannerBox margin="1rem 0px">
                      <InputFieldFormik
                        type="text"
                        name="twoFAToken"
                        height="44px"
                        width="100%"
                        AddBenificaryInput
                        fontSize="14px"
                        fontWeight="500"
                        BodyColor
                        placeholder="Enter 2FA Code"
                      />
                      <ErrorMessageDiv
                        name="twoFAToken"
                        component="div"
                        mt="6px"
                        size="14px" sizemd="12px" weight="300"
                        txtDanger
                      />
                    </BannerBox>

                    <BannerBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                      <BannerBox width="48%">
                        <ButtonComponent OutlineDanger width={"100%"} onClick={props.onClose}>
                          Go Back
                        </ButtonComponent>
                      </BannerBox>

                      <BannerBox width="48%">
                        <ButtonComponent
                          PrimaryButton
                          type="submit"
                          width={"100%"}
                          disabled={isSubmitting}
                        >
                          Delete
                        </ButtonComponent>
                      </BannerBox>
                    </BannerBox>
                  </Form>
                )}
              </Formik>
            </BannerBox>
            {/* </div> */}
          </BannerBox>
        </DivElement>
      </ModalWrapper>
    </>
  );
}

const maptoStateProps = (state) => ({
  keyManagementData: state.setting.keyManagementData,
  isFetchingKeyManagementData: state.setting.isFetchingKeyManagementData
});

const mapDispatchToProps = (dispatch) => ({
  getApiKeyManagment: (data) => dispatch(getApiKeyManagment(data))
});

export default connect(maptoStateProps, mapDispatchToProps)(DeleteManagmentComponent);
