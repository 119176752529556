import { CloseIcon } from "../../../assets/svgs";
import { LcxLogo } from "../../../assets/svgs/illustrations";
import { LcxAppLogo } from "../../../assets/png";
import DivElement from "../DivElement";
import { Button } from "../Button";
import ImgElement from "../ImgElement";
import TextWrapper from "../TextWrapper";
import styled from "styled-components";

const Container = styled(DivElement)`
  position: fixed;
  bottom: 0;
  z-index: 50000;
  width: 100%;
`;

const AppDownloadBanner = ({ onClickHandler }) => {
  const onClick = () => {
    window.open("https://lcxapp.page.link/dapp", "_blank");
  };
  return (
    <Container>
      <DivElement width="100%" minHeight="64px" bgAppBanner>
        <DivElement displayBtw padding="0 6px">
          <span></span>
          <DivElement bgAppBannerClose borderRadius="0 0 5px 5px">
            <CloseIcon onClick={onClickHandler} width="20px" height="20px" />
          </DivElement>
        </DivElement>
        <DivElement displayBtw padding="0 38px 8px 12px">
          <DivElement flex>
            <ImgElement src={LcxAppLogo} alt="lcx-app" objectFit="cover" borderRadius="8px" marginRight="8px" />
            <DivElement>
              <LcxLogo />
              <TextWrapper fontSize="12px" lineHeight="18px" fontWeight="400">
                One app. Unlimited possibilities.
              </TextWrapper>
            </DivElement>
          </DivElement>
          <DivElement bgPopup borderRadius="5px">
            <Button nowrap secondaryBlue padding="0 12px" height="38px" onClick={onClick}>
              Use App
            </Button>
          </DivElement>
        </DivElement>
      </DivElement>
    </Container>
  );
};

export default AppDownloadBanner;
