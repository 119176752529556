import { Button, DivElement, TextWrapper } from "../../../../../components/Reusable";
import styled from "styled-components";
import { buyBonds } from "../../../../../lib/api";
import { formatAPIErrors, numberWithCommas } from "../../../../../utils/general";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { LoadingButton, Skeleton } from "../../../../../components/Loader";
import { TAndC } from "../../Components";
import { useParams } from "react-router-dom";
import { getBondInvestHistory } from "../../../../../actions/earn";
import { compose } from "redux";
import { connect } from "react-redux";
import BigNumber from "bignumber.js";
import { showErrorMsg, showSuccessMsg } from "../../../../../utils/notification";

const DateSpan = styled.span`
  @media (max-width: 767px) {
    font-size: 10px;
  }
`;

const Step2 = ({ checked, setChecked, amount, setStep, bondDetails, getBondInvestHistory }) => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const params = useParams();

  useEffect(() => {
    setData([
      {
        label: `${bondDetails.bondName} Amount`,
        value: `${amount ? numberWithCommas(amount) : 0} ${bondDetails.bondName}`
      },
      { label: "Fixed interest p.a.", value: "7%" },
      {
        label: (
          <span>
            Investment date <DateSpan>(dd/mm/yy, 24hr)</DateSpan>
          </span>
        ),
        value: moment(bondDetails.investmentDate).format("DD/MM/YYYY, HH:mm")
      },
      {
        label: "Interest accrual date",
        value: moment(bondDetails.interestAccrualDate).format("DD/MM/YYYY, HH:mm")
      },
      { label: "Interest payout interval", value: bondDetails.payoutPeriod },
      {
        label: "First payout date",
        value: moment(bondDetails.firstPayout).format("DD/MM/YYYY, HH:mm")
      },
      {
        label: "First payout amount",
        value: `${numberWithCommas(
          +(bondDetails.firstPayoutAmount * amount * bondDetails.denomination).toFixed(8)
        )} ${bondDetails.bondCoin}`
      },
      {
        label: "Fee (1% in LCX)",
        value: numberWithCommas(
          (bondDetails.feeAmountRate * bondDetails.denomination * (amount || 1)).toFixed(8)
        )
      }
    ]);
  }, [amount, bondDetails]);

  const onConfirmInvetment = () => {
    setLoader(true);
    let data = {
      bondId: params.id,
      amount: BigNumber(amount).multipliedBy(bondDetails.denomination).toNumber()
    };
    buyBonds(data)
      .then((res) => {
        setStep(3);
        showSuccessMsg(res && res.data.message);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setLoader(false);
        getBondInvestHistory({ page: 0 });
      });
  };

  return (
    <DivElement
      padding="20px 24px 0 24px"
      paddingMd="20px 16px 30px 16px"
      paddingSm="20px 8px 32px 8px"
    >
      <DivElement padding="0 15px" paddingMd="0 14px" paddingSm="0 8px">
        <TextWrapper fontSize="14px" lineHeight="1.71" marginBottom="6px">
          Amount
        </TextWrapper>
        <DivElement
          bgCream
          borderGreen
          dAlignCenter
          height="40px"
          padding="0 16px"
          borderRadius="5px"
        >
          <TextWrapper fontSize="16px">
            {numberWithCommas(BigNumber(amount).multipliedBy(bondDetails.denomination || 0))}{" "}
            {bondDetails.bondCoin}
          </TextWrapper>
        </DivElement>
      </DivElement>

      <DivElement
        bgCream
        padding="20px"
        paddingMd="20px 24px"
        paddingSm="16px 12px"
        marginTop="32px"
        marginTopSm="24px"
        borderRadius="5px"
      >
        {data
          ? data.map((item, i) => (
              <DivElement
                key={i}
                displayBtw
                marginBottom={i === data.length - 1 ? "0" : "12px"}
                height="16px"
                heightSm="14px"
              >
                <TextWrapper
                  secondary
                  fontSize="14px"
                  fontSizeSm="12px"
                  lineHeight="1.71"
                  lineHeightSm="2"
                >
                  {item.label}
                </TextWrapper>
                <TextWrapper
                  green={i === 0 ? true : null}
                  fontSize="14px"
                  fontSizeSm="12px"
                  lineHeight="1.71"
                  lineHeightSm="2"
                >
                  {item.value}
                </TextWrapper>
              </DivElement>
            ))
          : [1, 2, 3, 4, 5, 6, 7, 8].map((item, i) => (
              <DivElement key={i} displayBtw marginBottom={i === 7 ? "0" : "12px"}>
                <Skeleton height="16px" width="200px" />
                <Skeleton height="16px" width="200px" />
              </DivElement>
            ))}
      </DivElement>
      <TAndC setChecked={setChecked} checked={checked} />
      <DivElement width="100%" padding="0 14px" paddingSm="0 8px">
        <Button
          primaryGrey={checked ? null : true}
          disabled={loader ? true : checked ? false : true}
          height="48px"
          width="100%"
          primaryBlue
          onClick={onConfirmInvetment}
        >
          {loader ? <LoadingButton /> : "Confirm Investment"}
        </Button>
      </DivElement>
    </DivElement>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBondInvestHistory: (data) => dispatch(getBondInvestHistory(data))
});

export default compose(connect(null, mapDispatchToProps))(Step2);
