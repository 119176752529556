/* eslint-disable no-unused-vars */
import axios from "axios";
import { API } from "../../../constants/api";
import apiURL from "../../../constants/apiConstants";
import globalConfig from "../../../constants/config";
import { formatAPIErrors, getLocalStorageData } from "../../../utils/general";

axios.interceptors.response.use(
  (r) => r,
  function handle401Errors(error) {
    if (error && error.response && error.response.status === 401) {
      window.location = globalConfig.exchangeUrl;
      window.dispatchEvent(new Event("LOGOUT"));
    }
    return Promise.reject(error);
  }
);

export const exchangeApi = axios.create({
  baseURL: apiURL.EXCHANGE_URL,
  headers: {
    "Content-Type": "application/json"
  }
});


// TODO => Fetch Report Template
export const fetchReportTemplate = async () => {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) return false;

  exchangeApi.defaults.headers.common["Authorization"] = accessToken;
  const response = await exchangeApi.get("/wallet/reports/template").then((res) => res.data);
  return response;
};
