import React, { Fragment, useMemo, useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { getAllWallets } from "../../../../actions/wallet";
import { GetKycVerified, RestrictedUser } from "../../../../components/Alert";
import BondDetails from "./BondDetails";
import BondSummary from "./BondSummary";
import { DivElement } from "../../../../components/Reusable";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { getBondDetails } from "../../../../lib/api";
import { formatAPIErrors } from "../../../../utils/general";
import { useHistory, useParams } from "react-router-dom";
import { getBondInfo } from "../../../../constants/bondSummary";
import { showErrorMsg } from "../../../../utils/notification";
import { trackPage } from "../../../../tracking/track";
import InvesmentSteps from "./InvesmentSteps";
import { HalfFooterCommon } from "../../../../components/Footer/common";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { fetchAvailableLanguagesRequest } from "../../../../Modules/Generic/action";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "../../../../Modules/Generic/selectors";
import SetHeaderTitleComponent from "../../../../components/ChangeHeaderTitle";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const EarnInvest = ({ userData, getAccWallets, isLoggedIn }) => {
  const [kycModal, setKycModal] = useState(true);
  const [showRestrictedModal, setShowRestrictedModal] = useState(true);
  const [bondDetails, setBondDetails] = useState("");
  const windowDimension = useWindowSize();
  const param = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { transalationList } = useSelector(GenericReducer);
  const prevLang = localStorage.getItem("ljs-lang");

  const memoizedBondInfo = useMemo(() => {
    return getBondInfo(param?.id);
  }, [param?.id]);
  const bondSummary = memoizedBondInfo;

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchAvailableLanguagesRequest());
      // let checkPath = location.pathname?.split("/")[1];
      // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
      //   history.push(
      //     `${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}${
      //       location.pathname
      //     }`
      //   );
      // }
      getAccWallets();
    }

    trackPage(
      `${location.pathname}`,
      "LCX Earn Invest Page"
    );
  }, []);

  useEffect(() => {
    getDetails();
    const interval = setInterval(() => {
      getDetails();
    }, [30000]);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const getDetails = () => {
    if (param && param.id) {
      let id = Number(param.id);
      if (isNaN(id) || id < 1 || id > 4) {
        return history.push(
          `${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/earn`
        );
      }
    }
    let data = {
      bondId: param.id
    };
    getBondDetails(data)
      .then((res) => {
        setBondDetails(res && res.data.data);
      })
      .catch((err) => {
        history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/earn`);
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const SEOContents = {
    name: `Earn Invest`,
    link: `${location.pathname}`,
    title: "Join LCX Exchange Crypto Trading Competition Now!",
    description:
      "Compete in the LCX Exchange Crypto Trading Competition. Win prizes while honing your trading skills with secure and efficient transactions. Join now!",
    keywords: `trading competition,trade competition, trading competitions, crypto trading competition, trading competition crypto`,
    canonical: `https://exchange.lcx.com${location.pathname}`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${location.pathname}`,
    lang: `${isLoggedIn && prevLang ? `/${prevLang}` : userData ? `${userData?.preferredLanguage}` : ""}`,
    OgTitle: "Join LCX Exchange Crypto Trading Competition Now!",
    OgDescription:
      "Compete in the LCX Exchange Crypto Trading Competition. Win prizes while honing your trading skills with secure and efficient transactions. Join now!",
    OgURL: `https://exchange.lcx.com${location.pathname}`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Join LCX Exchange Crypto Trading Competition Now!",
    TwDescription:
      "Compete in the LCX Exchange Crypto Trading Competition. Win prizes while honing your trading skills with secure and efficient transactions. Join now!"
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || userData.preferredLanguage))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/earn`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <DivElement pageContainer>
        <DivElement width="100%" display="flex" displayMd="initial" justifyContent="space-between">
          <BondSummary bondDetails={bondDetails} bondSummary={bondSummary} />
          <DivElement width="calc(50% - 8px)" widthMd="100%">
            <InvesmentSteps bondDetails={bondDetails} />
          </DivElement>
        </DivElement>
        {windowDimension.width >= 1024 && (
          <BondDetails bondDetails={bondDetails} bondSummary={bondSummary} />
        )}
        {isLoggedIn && (
          <Fragment>
            {userData.status === "RESTRICTED" ? (
              <RestrictedUser
                open={showRestrictedModal}
                onClose={() => setShowRestrictedModal(false)}
              />
            ) : (
              userData &&
              userData.KYC &&
              userData.KYC.STATUS !== "LEVEL2" &&
              kycModal && <GetKycVerified open={kycModal} onClose={() => setKycModal(false)} />
            )}
          </Fragment>
        )}
      </DivElement>
      <HalfFooterCommon />
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.getUserData,
  isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
  getAccWallets: () => dispatch(getAllWallets())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(EarnInvest);
