import { createSelector } from "reselect";

export const MarketAllTicker = () => (state) => state.marketAllTicker;

// Get Market All Ticker ...................
export const getAllTickerData = () => 
  createSelector(MarketAllTicker(), (state) => {
    const listing = state.get("allTickerData").toJS();
    return listing && Object.keys(listing).length > 0  ? listing : {};
  });

// Get Single Pair Ticker Data ...................
export const getSinglePairTickerData = () => 
  createSelector(MarketAllTicker(), (state) => {
    const listing = state.get("tickerData").toJS();
    return listing && Object.keys(listing).length > 0  ? listing : {};
  });

// Get Ticker Change ...................
export const get24Change = () => 
  createSelector(MarketAllTicker(), (state) => {
    return state.get("change");
  });

// Get Ticker high ...................
export const get24high = () => 
  createSelector(MarketAllTicker(), (state) => {
    return state.get("high");
  });

// Get Ticker low ...................
export const get24low = () => 
  createSelector(MarketAllTicker(), (state) => {
    return state.get("low");
  });

// Get Ticker Last Price ...................
export const getTickerLastPrice = () => 
  createSelector(MarketAllTicker(), (state) => {
    return state.get("tickerLastPrice");
  });

// Get Ticker Last 24 Price ...................
export const getLast24Price = () => 
  createSelector(MarketAllTicker(), (state) => {
    return state.get("last24Price");
  });

// Get Ticker volume ...................
export const get24Volume = () => 
  createSelector(MarketAllTicker(), (state) => {
    return state.get("volume");
  });

// Get Ticker volume ...................
export const get24UsdVolume = () => 
  createSelector(MarketAllTicker(), (state) => {
    return state.get("usdVolume");
  });

// Get Ticker 24 equivalent ...................
export const get24Equivalent = () => 
  createSelector(MarketAllTicker(), (state) => {
    return state.get("equivalent");
  });