import styled, { css } from "styled-components";

const ElementBox = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
  height: ${(props) => (props.height ? props.height : "fit-content")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "")};
  border: ${(props) => (props.border ? props.border : "")};
  border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "6px")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "16px")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  padding: ${(props) => (props.padding ? props.padding : "32px")};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : "")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "")};
  border-width: ${(props) => (props.borderWidth ? props.borderWidth : "")};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "0px 2px 4px rgba(126, 142, 177, 0.12)"};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : props.theme.isDarkTheme ? "#151e26" : "#fff"};

  @media (max-width: 1024px) {
    width: ${(props) => (props.widthMd ? props.widthMd : "")};
    height: ${(props) => (props.heightMd ? props.heightMd : "")};
    min-height: ${(props) => (props.minHeightMd ? props.minHeightMd : "")};
    border: ${(props) => (props.borderMd ? props.borderMd : "")};
    margin: ${(props) => (props.marginMd ? props.marginMd : "")};
    margin-top: ${(props) => (props.marginTopMd ? props.marginTopMd : "")};
    margin-right: ${(props) => (props.marginRightMd ? props.marginRightMd : "")};
    margin-bottom: ${(props) => (props.marginBottomMd ? props.marginBottomMd : "")};
    margin-left: ${(props) => (props.marginLeftMd ? props.marginLeftMd : "")};
    padding: ${(props) => (props.paddingMd ? props.paddingMd : "")};
    padding-top: ${(props) => (props.paddingTopMd ? props.paddingTopMd : "")};
    padding-right: ${(props) => (props.paddingRightMd ? props.paddingRightMd : "")};
    padding-bottom: ${(props) => (props.paddingBottomMd ? props.paddingBottomMd : "")};
    padding-left: ${(props) => (props.paddingLeftMd ? props.paddingLeftMd : "")};
    border-radius: ${(props) => (props.borderRadiusMd ? props.borderRadiusMd : "")};
    box-shadow: ${(props) => (props.boxShadowMd ? props.boxShadowMd : "")};
    background-color: ${(props) =>
      props.bgColorMd ? props.bgColorMd : props.theme.isDarkTheme ? "#151e26" : "#fff"};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.widthSm ? props.widthSm : "")};
    height: ${(props) => (props.heightSm ? props.heightSm : "")};
    min-height: ${(props) => (props.minHeightSm ? props.minHeightSm : "")};
    border: ${(props) => (props.borderSm ? props.borderSm : "")};
    margin: ${(props) => (props.marginSm ? props.marginSm : "")};
    margin-top: ${(props) => (props.marginTopSm ? props.marginTopSm : "")};
    margin-right: ${(props) => (props.marginRightSm ? props.marginRightSm : "")};
    margin-bottom: ${(props) => (props.marginBottomSm ? props.marginBottomSm : "")};
    margin-left: ${(props) => (props.marginLeftSm ? props.marginLeftSm : "")};
    padding: ${(props) => (props.paddingSm ? props.paddingSm : "")};
    padding-top: ${(props) => (props.paddingTopSm ? props.paddingTopSm : "")};
    padding-right: ${(props) => (props.paddingRightSm ? props.paddingRightSm : "")};
    padding-bottom: ${(props) => (props.paddingBottomSm ? props.paddingBottomSm : "")};
    padding-left: ${(props) => (props.paddingLeftSm ? props.paddingLeftSm : "")};
    border-radius: ${(props) => (props.borderRadiusSm ? props.borderRadiusSm : "")};
    box-shadow: ${(props) => (props.boxShadowSm ? props.boxShadowSm : "")};
    background-color: ${(props) =>
      props.bgColorSm ? props.bgColorSm : props.theme.isDarkTheme ? "#151e26" : "#fff"};
  }

  /* Black background */
  ${(props) =>
    props.blackBox &&
    props.theme.isDarkTheme === true &&
    css`
      background-color: #151e26;
      color: #dedfe0;
    `}

  ${(props) =>
    props.blackBox &&
    props.theme.isDarkTheme === false &&
    css`
      background-color: #0e141a;
      color: #dedfe0;
    `}

    ${(props) =>
    props.EarnBanner &&
    css`
      .earn-banner-wrapper {
        border-radius: inherit;
        background-image: linear-gradient(
          to right,
          #0e141a 0%,
          rgba(14, 20, 26, 0.75) 61%,
          rgba(14, 20, 26, 0.16) 71%,
          rgba(14, 20, 26, 0) 100%
        );
      }
      .earn-banner {
        border-radius: 6px;
        @media (min-width: 768px) {
          background-image: url("https://lcx-exchange.s3.amazonaws.com/others/LCX-Earn-Tablet.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        @media (min-width: 1024px) {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        @media (max-width: 767px) {
          display: flex;
          background-image: url("https://lcx-exchange.s3.amazonaws.com/others/LCX-Earn-Tablet.jpg");
          flex-direction: column;
          align-items: center;
        }
      }
    `}

    /* Border styles */
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${(props) => (props.theme.isDarkTheme ? "#38393d" : "#ccced0")};
    `}

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${(props) => (props.theme.isDarkTheme ? "#38393d" : "#ccced0")};
    `}

  ${(props) =>
    props.shadowNone &&
    css`
      box-shadow: none !important;
    `}

  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `}

    ${(props) =>
    props.bdGrey &&
    css`
      border-color: ${(props) => props.theme.sepColor};
    `}

    ${(props) =>
    props.displayCenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
    ${(props) =>
    props.EarnPageHeader &&
    css`
      .bondsumm__header {
        padding: 20px 37px 23px 37px;
        font-size: 24px;
        border-radius: 6px 6px 0 0;
        color: ${(props) => props.theme.bodyText};
        background-color: ${(props) => props.theme.EarnThemeHeader};

        span {
          font-size: 36px;
        }
      }
      // Bond Details component styles
      .bonddetail__header {
        width: 100%;
        height: 54px;
        display: flex;
        align-items: center;
        border-radius: 6px 6px 0 0;
        padding: 0 34px;
        background-color: ${(props) => props.theme.EarnThemeHeader};

        @media (max-width: 1024px) {
          height: 52px;
          padding: 0 !important;
        }
        @media (max-width: $mobileBreakingPoint) {
        }
      }
      .bonddetail__header .bonddetail__navlink {
        font-size: 16px;
        margin-right: 28px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 4px;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        line-height: 1.5;
        white-space: nowrap;
        color: ${(props) => props.theme.subText};

        @media (max-width: $mobileBreakingPoint) {
          font-size: 14px;
          line-height: 1.71;
        }

        &.active {
          color: ${(props) => props.theme.primary};
          border-color: ${(props) => props.theme.primary};
        }
      }
    `}
`;

export default ElementBox;
