/* eslint-disable no-unused-vars */
import Wrapper from "./wrapper";
import { ReactComponent as AllOrdersActive } from "./light/all_orders_active.svg";
import { ReactComponent as AllOrdersActiveDark } from "./dark/all_orders_active.svg";
import { ReactComponent as AllOrdersInactive } from "./light/all_orders_inactive.svg";
import { ReactComponent as AllOrdersInactiveDark } from "./dark/all_orders_inactive.svg";
import { ReactComponent as AntiPhising } from "./light/anti_phising.svg";
import { ReactComponent as AntiPhisingDark } from "./dark/anti_phising.svg";
import { ReactComponent as Blockpit } from "./light/blockpit.svg";
import { ReactComponent as BlockpitDark } from "./dark/blockpit.svg";
import { ReactComponent as BoyStanding } from "./light/boy_standing.svg";
import { ReactComponent as BoyWithBag } from "./light/boy_with_bag.svg";
import { ReactComponent as BarcodeReferalLight } from "./light/barcodeReferLight.svg";
import { ReactComponent as BarcodeReferalDark } from "./dark/barcodeButton.svg";
import { ReactComponent as BuyOrdersActive } from "./light/buy_orders_active.svg";
import { ReactComponent as BuyOrdersActiveDark } from "./dark/buy_orders_active.svg";
import { ReactComponent as BuyOrdersInactive } from "./light/buy_orders_inactive.svg";
import { ReactComponent as BuyOrdersInactiveDark } from "./dark/buy_orders_inactive.svg";
import { ReactComponent as CalendarBlue } from "./light/calendar_blue.svg";
import { ReactComponent as ChartMaketLoser } from "./light/ChartLoser.svg";
import { ReactComponent as ChartMaketLoserDark } from "./dark/LoserDark.svg";
import { ReactComponent as ChartMaketUpDark } from "./dark/Gainerdark.svg";
import { ReactComponent as ChartMaketUpLight } from "./light/ChartUp.svg";
import { ReactComponent as ComingSoon } from "./light/coming_soon.svg";
import { ReactComponent as DotIconLight } from "./light/dot.svg";
import { ReactComponent as DotIconDark } from "./dark/dot.svg";
import { ReactComponent as DeleteBox } from "./light/delete_box.svg";
import { ReactComponent as DeleteBoxDark } from "./dark/delete_box.svg";
import { ReactComponent as Deposit } from "./light/deposit.svg";
import { ReactComponent as DepositDark } from "./dark/deposit.svg";
import { ReactComponent as Dollarbag } from "./light/dollarbag.svg";
import { ReactComponent as DollarbagDark } from "./dark/dollarbag.svg";
import { ReactComponent as Earnings } from "./light/earnings.svg";
import { ReactComponent as Error404 } from "./light/error_404.svg";
import { ReactComponent as Error500 } from "./light/error_500.svg";
import { ReactComponent as Error } from "./light/error.svg";
import { ReactComponent as FundsRaised } from "./light/funds_raised.svg";
import { ReactComponent as EarningsDark } from "./dark/earnings.svg";
import { ReactComponent as GetKycVerified } from "./light/get_kyc_verified.svg";
import { ReactComponent as GetKycVerifiedDark } from "./dark/get_kyc_verified.svg";
import { ReactComponent as HourGlass } from "./light/hour_glass.svg";
import { ReactComponent as Invite } from "./light/invite.svg";
import { ReactComponent as InviteDark } from "./dark/invite.svg";
import { ReactComponent as LayeredPeak } from "./light/layered_peaks.svg";
import { ReactComponent as LcxBlue } from "./light/lcx_blue.svg";
import { ReactComponent as Lcx } from "./light/lcx.svg";
import { ReactComponent as LcxDark } from "./dark/lcx.svg";
import { ReactComponent as Linkedin } from "./light/linkedin.svg";
import { ReactComponent as MaintenanceDesktop } from "./light/maintenance_desktop.svg";
import { ReactComponent as MaintenanceMobile } from "./light/maintenance_mobile.svg";
import { ReactComponent as MoneyBag } from "./light/money_bag.svg";
import { ReactComponent as NewTagBlue } from "./light/new_tag_blue.svg";
import { ReactComponent as NewTagBlueDark } from "./dark/new_tag_blue.svg";
import { ReactComponent as NewTagOrange } from "./light/new_tag_orange.svg";
import { ReactComponent as NoOpenOrders } from "./light/no_open_orders.svg";
import { ReactComponent as NoOpenOrdersDark } from "./dark/no_open_orders.svg";
import { ReactComponent as NoRecord } from "./light/no_record.svg";
import { ReactComponent as NoRecordDark } from "./dark/no_record.svg";
import { ReactComponent as Notification } from "./light/notification.svg";
import { ReactComponent as PasswordProtected } from "./light/password_protected.svg";
import { ReactComponent as PasswordProtectedDark } from "./dark/password_protected.svg";
import { ReactComponent as PendingCircle } from "./light/pending_circle.svg";
import { ReactComponent as PendingCircleDark } from "./dark/pending_circle.svg";
import { ReactComponent as People } from "./light/people.svg";
import { ReactComponent as PositiveConeMarkets } from "./light/PositiveCone.svg";
import { ReactComponent as NegitiveConeMarkets } from "./light/NegitiveCone.svg";
import { ReactComponent as NegitiveConeMarketsDark } from "./dark/NegitiveCone.svg";
import { ReactComponent as PositiveConeMarketsDark } from "./dark/PositiveCone.svg";
import { ReactComponent as ReferFriend } from "./light/refer_friend.svg";
import { ReactComponent as Referral } from "./light/referral.svg";
import { ReactComponent as ReleasedToken } from "./light/released_token.svg";
import { ReactComponent as RecentListedTokenLight } from "./light/RecentlyListed.svg";
import { ReactComponent as RecentListedTokenDark } from "./dark/RecentlyListeddark.svg";
import { ReactComponent as SecureLogin } from "./light/secure_login.svg";
import { ReactComponent as SecureLoginDark } from "./dark/secure_login.svg";
import { ReactComponent as SellOrdersActive } from "./light/sell_orders_active.svg";
import { ReactComponent as SellOrdersActiveDark } from "./dark/sell_orders_active.svg";
import { ReactComponent as SellOrdersInactive } from "./light/sell_orders_inactive.svg";
import { ReactComponent as SellOrdersInactiveDark } from "./dark/sell_orders_inactive.svg";
import { ReactComponent as Snowboarding } from "./light/snowboarding.svg";
import { ReactComponent as SuccessAppreciation } from "./light/success_appreciation.svg";
import { ReactComponent as Success } from "./light/success.svg";
import { ReactComponent as SuccessDark } from "./dark/success.svg";
import { ReactComponent as SuccessfullReferrals } from "./light/successfull-referrals.svg";
import { ReactComponent as SuccessfullReferralsDark } from "./dark/successfull-referrals.svg";
import { ReactComponent as TotalReferrals } from "./light/total-referrals.svg";
import { ReactComponent as TotalReferralsDark } from "./dark/total-referrals.svg";
import { ReactComponent as TradeExecution } from "./light/trade_execution.svg";
import { ReactComponent as TradeExecutionDark } from "./dark/trade_execution.svg";
import { ReactComponent as Trade } from "./light/trade.svg";
import { ReactComponent as TradeDark } from "./dark/trade.svg";
import { ReactComponent as Transfer } from "./light/transfer.svg";
import { ReactComponent as TransferDark } from "./dark/transfer.svg";
import { ReactComponent as Trophy } from "./light/trophy.svg";
import { ReactComponent as TwoFaSecured } from "./light/twofa_secured.svg";
import { ReactComponent as TwoFaSecuredDark } from "./dark/twofa_secured.svg";
import { ReactComponent as UkRestrictionOnHold } from "./light/ukRestrictionOnHold.svg";
import { ReactComponent as UkRestrictionOnHoldDark } from "./dark/ukRestrictionOnHold.svg";
import { ReactComponent as UkRestrictionWaitingList } from "./light/ukRestrictionWaitingList.svg";
import { ReactComponent as Website } from "./light/website.svg";
import { ReactComponent as WebsiteDark } from "./dark/website.svg";
import { ReactComponent as Whitepaper } from "./light/whitepaper.svg";
import { ReactComponent as WhitepaperDark } from "./dark/whitepaper.svg";
import { ReactComponent as Withdrawal } from "./light/withdrawal.svg";
import { ReactComponent as WithdrawalDark } from "./dark/withdrawal.svg";
import { ReactComponent as DisableDarkIcon } from "./dark/disableDot.svg";

// Exchanges Logos
import { ReactComponent as Coinbase } from "./light/Exchanges/coinbase.png";
import { ReactComponent as CoinbaseDark } from "./dark/Exchanges/coinbase.svg";

import { ReactComponent as Coindcx } from "./light/Exchanges/coindcx.svg";
import { ReactComponent as CoindcxDark } from "./dark/Exchanges/coindcx.svg";

import { ReactComponent as Kraken } from "./light/Exchanges/kraken.svg";
import { ReactComponent as KrakenDark } from "./dark/Exchanges/kraken.svg";

import { ReactComponent as Liquid } from "./light/Exchanges/liquid.svg";
import { ReactComponent as LiquidDark } from "./dark/Exchanges/liquid.svg";
import { ReactComponent as LeaderBoardLight } from "./light/leaderboard.svg";
import { ReactComponent as LeaderBoardDark } from "./dark/leaderboardDark.svg";

import { ReactComponent as Uniswap } from "./light/Exchanges/uniswap.png";
import { ReactComponent as UniswapDark } from "./dark/Exchanges/uniswap.svg";

// Card Provider Logos
import { ReactComponent as MaestroCard } from "./light/maestro_card_logo.svg";
import { ReactComponent as MaestroCardDark } from "./dark/maestro_card_logo.svg";

import { ReactComponent as MasterCard } from "./light/master_card_logo.svg";
import { ReactComponent as MasterCardDark } from "./dark/master_card_logo.svg";

import { ReactComponent as VisaCard } from "./light/visa_card_logo.svg";
import { ReactComponent as VisaCardDark } from "./dark/visa_card_logo.svg";
import { ReactComponent as GoogleApp } from "./light/googleApp.svg";
import { ReactComponent as GoogleAppDark } from "./dark/googleApp.svg";
import { ReactComponent as AppleAppDark } from "./dark/iphoneApp.svg";
import { ReactComponent as AppleApp } from "./light/iphoneApp.svg";
import { ReactComponent as LCXBannerLight } from "./light/lcx_main_banner.svg";
import { ReactComponent as LCXBannerDark } from "./dark/lcx_dark_banner.svg";
import { ReactComponent as WalletsModaldark } from "./dark/wallet_modal_dark.svg";
import { ReactComponent as WalletsModalLight } from "./dark/wallet_modal_dark.svg";
import { ReactComponent as TwitterTsDark } from "./dark/darkTsTwitter.svg";
import { ReactComponent as TwitterTsLight } from "./light/twitter new 2.svg";
import { ReactComponent as Youtubedark } from "./dark/TSyoutubedark.svg";
import { ReactComponent as YoutubeLight } from "./light/Tsyoutubelight.svg";
import { ReactComponent as TelegramLight } from "./light/telegram.svg";
import { ReactComponent as TelegramDark } from "./dark/Tstelegramdark.svg";
import { ReactComponent as LinkdinLight } from "./light/TSlinkdinlight.svg";
import { ReactComponent as LinkdinDark } from "./dark/linkdinTsdark.svg";

export const AllOrdersIllustration = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={styledProps.active ? <AllOrdersActive /> : <AllOrdersInactive />}
      dark={styledProps.active ? <AllOrdersActiveDark /> : <AllOrdersInactiveDark />}
    />
  );
};

export const AntiPhisingIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<AntiPhising />} dark={<AntiPhisingDark />} />;
};

export const BlockpitIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Blockpit />} dark={<BlockpitDark />} />;
};
export const BarCodeIllustrationReferal = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={<BarcodeReferalLight />}
      dark={<BarcodeReferalDark  />}
    />
  );
};

export const LCXBannerLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<LCXBannerLight />} dark={<LCXBannerDark />} />;
};
export const LinkDinTS = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<LinkdinLight />} dark={<LinkdinDark />} />;
};
export const YoutubeTS = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<YoutubeLight />} dark={<Youtubedark />} />;
};
export const TelegramTS = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<TelegramLight />} dark={<TelegramDark />} />;
};
export const TwitterTS = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<TwitterTsLight />} dark={<TwitterTsDark />} />;
};

export const BoyStandingIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<BoyStanding />} />;
};

export const BoyWithBagIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<BoyWithBag />} />;
};

export const BuyOrdersIllustration = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={styledProps.active ? <BuyOrdersActive /> : <BuyOrdersInactive />}
      dark={styledProps.active ? <BuyOrdersActiveDark /> : <BuyOrdersInactiveDark />}
    />
  );
};

export const CalendarBlueIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<CalendarBlue />} />;
};

export const ComingSoonIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<ComingSoon />} />;
};
export const ChartMarketLoserIllustration = (styledProps) => {
  return (
    <Wrapper styledProps={styledProps} light={<ChartMaketLoser />} dark={<ChartMaketLoserDark />} />
  );
};
export const ChartMarketGainerIllustration = (styledProps) => {
  return (
    <Wrapper styledProps={styledProps} light={<ChartMaketUpLight />} dark={<ChartMaketUpDark />} />
  );
};
export const DotIconIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<DotIconLight />} dark={<DotIconDark />} />;
};
export const GoogleAppIcon = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<GoogleApp />} dark={<GoogleAppDark />} />;
};
export const AppleAppIcon = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<AppleApp />} dark={<AppleAppDark />} />;
};

export const DeleteBoxIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<DeleteBox />} dark={<DeleteBoxDark />} />;
};

export const DepositIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Deposit />} dark={<DepositDark />} />;
};

export const DollarbagIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Dollarbag />} dark={<DollarbagDark />} />;
};

export const EarningsIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Earnings />} dark={<EarningsDark />} />;
};

export const Error404Illustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Error404 />} />;
};

export const Error500Illustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Error500 />} />;
};

export const ErrorIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Error />} />;
};

export const FundsRaisedIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<FundsRaised />} />;
};

export const GetKycVerifiedIllustration = (styledProps) => {
  return (
    <Wrapper styledProps={styledProps} light={<GetKycVerified />} dark={<GetKycVerifiedDark />} />
  );
};

export const GetUkRegistrationOnHold = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={<UkRestrictionOnHold />}
      dark={<UkRestrictionOnHoldDark />}
    />
  );
};

export const GetUkRegistrationWaitingList = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={<UkRestrictionWaitingList />}
      dark={<UkRestrictionWaitingList />}
    />
  );
};

export const HourGlassIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<HourGlass />} />;
};

export const InviteIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Invite />} dark={<InviteDark />} />;
};

export const LayeredPeakIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<LayeredPeak />} />;
};

export const LcxBlueLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<LcxBlue />} />;
};

export const LcxLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Lcx />} dark={<LcxDark />} />;
};
export const LeaderBoardLogo = (styledProps) => {
  return (
    <Wrapper styledProps={styledProps} light={<LeaderBoardLight />} dark={<LeaderBoardDark />} />
  );
};

export const LinkedinIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Linkedin />} />;
};

export const MaintenanceDesktopIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<MaintenanceDesktop />} />;
};

export const MaintenanceMobileIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<MaintenanceMobile />} />;
};

export const MoneyBagIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<MoneyBag />} />;
};

export const NewTagBlueIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<NewTagBlue />} dark={<NewTagBlueDark />} />;
};

export const NewTagOrangeIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<NewTagOrange />} />;
};

export const NegitiveConemarketsIllustration = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={<NegitiveConeMarkets />}
      dark={<NegitiveConeMarketsDark />}
    />
  );
};

export const NoOpenOrdersIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<NoOpenOrders />} dark={<NoOpenOrdersDark />} />;
};

export const NoRecordIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<NoRecord />} dark={<NoRecordDark />} />;
};

export const NotificationIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Notification />} />;
};

export const PasswordProtectedIllustration = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={<PasswordProtected />}
      dark={<PasswordProtectedDark />}
    />
  );
};

export const PendingCircleIllustration = (styledProps) => {
  return (
    <Wrapper styledProps={styledProps} light={<PendingCircle />} dark={<PendingCircleDark />} />
  );
};

export const PeopleIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<People />} />;
};
export const PositiveConeMarketsIllustration = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={<PositiveConeMarkets />}
      dark={<PositiveConeMarketsDark />}
    />
  );
};

export const ReferFriendIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<ReferFriend />} />;
};
export const RecentlyListedIllustration = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={<RecentListedTokenLight />}
      dark={<RecentListedTokenDark />}
    />
  );
};

export const ReferralIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Referral />} />;
};

export const ReleasedTokenIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<ReleasedToken />} />;
};

export const SecureLoginIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<SecureLogin />} dark={<SecureLoginDark />} />;
};

export const SellOrdersIllustration = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={styledProps.active ? <SellOrdersActive /> : <SellOrdersInactive />}
      dark={styledProps.active ? <SellOrdersActiveDark /> : <SellOrdersInactiveDark />}
    />
  );
};

export const SnowboardingIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Snowboarding />} />;
};

export const SuccessAppreciationIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<SuccessAppreciation />} />;
};

export const SuccessIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Success />} dark={<SuccessDark />} />;
};
export const DisableDotIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<DotIconLight />} dark={<DisableDarkIcon />} />;
};

export const SuccessfullReferralsIllustration = (styledProps) => {
  return (
    <Wrapper
      styledProps={styledProps}
      light={<SuccessfullReferrals />}
      dark={<SuccessfullReferralsDark />}
    />
  );
};

export const TotalReferralsIllustration = (styledProps) => {
  return (
    <Wrapper styledProps={styledProps} light={<TotalReferrals />} dark={<TotalReferralsDark />} />
  );
};

export const TradeExecutionIllustration = (styledProps) => {
  return (
    <Wrapper styledProps={styledProps} light={<TradeExecution />} dark={<TradeExecutionDark />} />
  );
};

export const TradeIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Trade />} dark={<TradeDark />} />;
};

export const TransferIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Transfer />} dark={<TransferDark />} />;
};

export const TrophyIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Trophy />} />;
};

export const TwoFaSecuredIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<TwoFaSecured />} dark={<TwoFaSecuredDark />} />;
};

export const WebsiteIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Website />} dark={<WebsiteDark />} />;
};

export const WhitepaperIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Whitepaper />} dark={<WhitepaperDark />} />;
};

export const WithdrawalIllustration = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Withdrawal />} dark={<WithdrawalDark />} />;
};
// ExchangeLogos
// FIXME need to be fixed
export const CoinbaseLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<CoinbaseDark />} dark={<CoinbaseDark />} />;
};

export const CoindcxLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Coindcx />} dark={<CoindcxDark />} />;
};

export const KrakenLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Kraken />} dark={<KrakenDark />} />;
};

export const LiquidLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<Liquid />} dark={<LiquidDark />} />;
};
// FIXME need to be fixed
export const UniswapLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<UniswapDark />} dark={<UniswapDark />} />;
};

// Card Provider Logos
export const MaestroCardLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<MaestroCard />} dark={<MaestroCardDark />} />;
};

export const MasterCardLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<MasterCard />} dark={<MasterCardDark />} />;
};

export const VisaCardLogo = (styledProps) => {
  return <Wrapper styledProps={styledProps} light={<VisaCard />} dark={<VisaCardDark />} />;
};

export const WalletsModalIconsWallet = (styledProps) => {
  return (
    <Wrapper styledProps={styledProps} light={<WalletsModalLight />} dark={<WalletsModaldark />} />
  );
};
