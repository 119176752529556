import { useSelector } from "react-redux";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import OpenOrders from "../../Components/OpenOrders";
import OrderBookFilters from "../../Components/OrderBookFilters";

// Added this in a wrapper so it wont trigger a re render on side change
const Wrapper = ({ children }) => {
  const orderBookActiveSide = useSelector((state) => state.trading.orderBookActiveSide);
  const orderBookConfig = useSelector((state) => state.trading.orderBookConfig);
  const buySellRatio = orderBookConfig && orderBookConfig.buySellRatio;

  return (
    <DivElement
      column
      bgCard
      width="100%"
      height="100%"
      padding="12px"
      paddingBottom={orderBookActiveSide === "sell" && !buySellRatio ? "0" : ""}
    >
      {children}
    </DivElement>
  );
};

const OrderBookSection = () => {
  return (
    <Wrapper>
      <DivElement height="70px">
        <DivElement displayBtw marginBottom="16px">
          <TextWrapper fontSize="12px" lineHeight="18px" secondary>
            Order Book
          </TextWrapper>
        </DivElement>
        <OrderBookFilters />
      </DivElement>
      <OpenOrders containerProps={{ height: "calc(100% - 70px)" }} />
    </Wrapper>
  );
};

export default OrderBookSection;
