import { Fragment, useEffect, useState } from "react";
import { CoinLabel, DivElement, SpanElement, TextWrapper } from "../Reusable";
import { CloseIcon } from "../../assets/svgs";
import SelectDropDownComponent, {
  CustomOptionsForNetwork,
  DropdownIndicator,
  MyOptions
} from "../SelectDropDown/selectDropDown";
import { reactDropdownStyles, selectProviderCustomOptions } from "../../utils";
import { compose } from "redux";
import { connect } from "react-redux";
import { getAllWallets } from "../../actions/wallet";
import { filterCoin, formatAPIErrors, noExponents, numberWithCommas } from "../../utils/general";
import {
  createWallet,
  getBeneficiary,
  getCoinNetworks,
  getFiatDepositDetails
} from "../../lib/api";
import { showErrorMsg } from "../../utils/notification";
import Select from "react-select";
import FiatCoinDetails from "./Components/FiatCoinDetails";
import { Skeleton } from "../Loader";
import NonFiatCoinDetails from "./Components/NonFiatCoinDetails";
import FiatCardDeposit from "../Wallets/Deposits/FiatCardDeposit";
import { AddBeneficiary, SuspensionAlert } from "../Alert";
import WireTransfer from "../../views/walletPageCopy/DepositPage/Component/WireTransfer";

const formattedArrayNetwork = (array) => {
  return array.map((item) => {
    return {
      id: item,
      label: item.label,
      value: item.value
    };
  });
};

const DepositModal = ({
  isDarkTheme,
  mainWallet,
  getMainWallet,
  coin,
  onClose,
  apiLoader,
  userData
}) => {
  const [selectedCoin, setSelectedCoin] = useState(coin);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [coinNetworks, setCoinNetworks] = useState("");
  const [coinProviders, setCoinProviders] = useState("");
  const [loader, setLoader] = useState(false);
  const [isCreatedWallet, setIsCreatedWallet] = useState(false);
  const [fiatAmount, setFiatAmount] = useState("");
  const [beneficiaryList, setBeneficaryList] = useState("");
  const [showBeneficiaryModal, setShowBeneficiaryModal] = useState(false);
  const [isCreatingWallet, setIsCreatingWallet] = useState(false);

  useEffect(() => {
    if (coin.coinType === "fiat_token") {
      getFiatDeposit(coin);
    } else {
      getNetworks(coin);
    }
  }, []);

  useEffect(() => {
    if (mainWallet && !apiLoader) {
      let coinData = mainWallet.data.find((data) => data.coin === selectedCoin.coin);
      setSelectedCoin(coinData);
    }
    // eslint-disable-next-line
  }, [mainWallet, isCreatedWallet, apiLoader]);

  const selectCoinHandler = (coinData) => {
    if (coinData.coinType === "fiat_token") {
      getFiatDeposit(coinData);
    } else {
      getNetworks(coinData);
    }

    setSelectedCoin(coinData);
    setSelectedNetwork("");
    selectProviderHandler("");
  };

  const selectNetworkHandler = (networkData) => {
    setSelectedNetwork(networkData);
    if (
      !selectedCoin.address ||
      (selectedCoin.isMemo && !selectedCoin.memo) ||
      (selectedCoin && selectedCoin.coin === "USDT")
    ) {
      setIsCreatingWallet(true);
      let data = {
        coin: selectedCoin.coin,
        network_id: networkData?.id
      };
      createWallet(data)
        .then((res) => {
          if (selectedCoin.coin === "USDT") {
            if (networkData.label === "Ton") {
              let resData = res?.data?.data;
              let coinData = { ...selectedCoin };
              coinData.address = resData?.address;
              coinData.memo = resData?.memo;
              coinData.isMemo = true;

              setSelectedCoin(coinData);
            } else {
              let coinData = { ...selectedCoin };
              coinData.address = "";
              coinData.memo = "";
              coinData.isMemo = false;
              setSelectedCoin(coinData);
              getMainWallet();
              setIsCreatedWallet(true);
            }
          } else {
            getMainWallet();
            setIsCreatedWallet(true);
          }
        })
        .catch((err) => showErrorMsg(formatAPIErrors(err)))
        .finally(() => setIsCreatingWallet(false));
    }
  };

  const selectProviderHandler = (value) => {
    setSelectedProvider(value);
    setFiatAmount("");
    // setShowBeneficiaryModal(true);
  };

  const getFiatDeposit = (coinData) => {
    setLoader(true);
    let data = {
      coin: coinData.coin
    };
    getFiatDepositDetails(data)
      .then((res) => {
        setCoinProviders(res?.data?.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setLoader(false));

    getBeneficiary()
      .then((res) => {
        let data = res && res.data;
        if (data.data && data.data.length === 0) {
          setShowBeneficiaryModal(true);
          setBeneficaryList("");
        } else {
          setBeneficaryList(res?.data?.data);
        }
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const getNetworks = (coinData) => {
    setLoader(true);
    let data = {
      coin: coinData.coin
    };
    getCoinNetworks(data)
      .then((res) => {
        let networkList = res?.data?.data?.networks.map((val) => {
          let data = { id: val.id, label: val.name, value: val.name, supports: val.supports };
          if (val.min && val.max) {
            data.min = val.min;
            data.max = val.max;
          }
          if (val.supports) data.supports = val.supports;

          return data;
        });
        setCoinNetworks(networkList);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setLoader(false));
  };

  const onCloseBeneficiaryModal = () => {
    onClose();
    setShowBeneficiaryModal(false);
  };

  // const isRestrictedUser = userData && userData.status === "RESTRICTED" ? true : false;

  return (
    <Fragment>
      <DivElement displayBtw>
        <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
          Deposit
        </TextWrapper>
        <CloseIcon pointer onClick={onClose} />
      </DivElement>
      <DivElement bdGrey borderBottom="1px solid" paddingBottom="8px">
        <TextWrapper
          fontSize="14px"
          fontSizeSm="12px"
          fontWeight="400"
          lineHeight="20px"
          marginTop="6px"
        >
          You can manage your deposits here
        </TextWrapper>
      </DivElement>
      <DivElement marginBottom="21px" paddingTop="12px">
        <TextWrapper
          secondary
          fontSize="14px"
          fontSizeSm="12px"
          fontWeight="400"
          lineHeight="20px"
          lineHeightSm="18px"
        >
          Choose Asset
        </TextWrapper>
        <SelectDropDownComponent
          dataComponent={{
            DropdownIndicator,
            Option: MyOptions
          }}
          name="selectCurrency"
          placeholder="Select Asset"
          optionList={filterCoin(mainWallet.data)}
          selected_coin={selectedCoin}
          chooseLabel={(val) => <CoinLabel {...val} insufficientFunds={true} />}
          value={selectedCoin ? { label: selectedCoin.coin, options: selectedCoin.coin } : ""}
          defaultValue={
            selectedCoin ? { label: selectedCoin.coin, options: selectedCoin.coin } : ""
          }
          handlePairName={selectCoinHandler}
          styles={reactDropdownStyles({
            isDarkTheme,
            borderBottom: true,
            height: "48px",
            fontWeight: 300
          })}
          useStyles={true}
        />
        <TextWrapper
          fontWeight="400"
          marginTop="1.5px"
          fontSize="14px"
          fontSizeSm="12px"
          lineHeight="20px"
          lineHeightSm="18px"
        >
          Main balance:
          <SpanElement fontSize="inherit" lineHeight="inherit">
            <var>
              {` ${numberWithCommas(noExponents(selectedCoin?.balance?.freeBalance || 0))} ${
                selectedCoin?.coin
              }`}
            </var>
          </SpanElement>
        </TextWrapper>
      </DivElement>

      {selectedCoin && selectedCoin.depositsEnabled && (
        <DivElement marginBottom="20px">
          <TextWrapper
            secondary
            fontSize="14px"
            fontSizeSm="12px"
            fontWeight="400"
            lineHeight="20px"
            lineHeightSm="18px"
          >
            Choose {selectedCoin.coinType === "fiat_token" ? "Provider" : "Network"}
          </TextWrapper>

          {!loader &&
          ((selectedCoin.coinType === "fiat_token" && coinProviders) ||
            (selectedCoin.coinType !== "fiat_token" && coinNetworks)) ? (
            <Fragment>
              {selectedCoin.coinType === "fiat_token" ? (
                <Fragment>
                  <Select
                    name="selectProvider"
                    placeholder="Select Provider"
                    components={{ DropdownIndicator }}
                    isSearchable={true}
                    formatOptionLabel={(vals) =>
                      selectProviderCustomOptions({ ...vals, isDarkTheme })
                    }
                    onChange={selectProviderHandler}
                    styles={reactDropdownStyles({
                      isDarkTheme,
                      borderBottom: true,
                      height: "48px"
                    })}
                    options={coinProviders?.networks}
                    value={
                      selectedProvider && selectedProvider.value
                        ? { label: selectedProvider.label, value: selectedProvider.value }
                        : ""
                    }
                    minMenuHeight={200}
                    maxMenuHeight={200}
                    menuPlacement="auto"
                  />
                  {selectedProvider && selectedProvider.label === "Monerium" && (
                    <TextWrapper
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="20px"
                      red
                      marginTop="3.5px"
                    >
                      Note: Only Supports SEPA Payment
                    </TextWrapper>
                  )}
                </Fragment>
              ) : (
                <SelectDropDownComponent
                  dataComponent={{
                    DropdownIndicator,
                    Option: (props) => {
                      return <MyOptions {...props} isNetwork={true} />;
                    }
                  }}
                  name="selectNetwork"
                  placeholder="Select Network"
                  optionList={formattedArrayNetwork(coinNetworks)}
                  selected_coin={selectedNetwork}
                  chooseLabel={CustomOptionsForNetwork}
                  styles={reactDropdownStyles({ isDarkTheme, borderBottom: true, height: "48px" })}
                  useStyles={true}
                  value={
                    selectedNetwork
                      ? { label: selectedNetwork.value, options: selectedNetwork.value }
                      : ""
                  }
                  defaultValue={
                    selectedNetwork
                      ? { label: selectedNetwork.value, options: selectedNetwork.value }
                      : ""
                  }
                  handlePairName={selectNetworkHandler}
                />
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Skeleton height="48px" width="100%" marginBottom="20px" />
            </Fragment>
          )}
        </DivElement>
      )}

      {((selectedCoin && !selectedCoin.depositsEnabled) ||
        (selectedProvider &&
          selectedProvider.label === "Credit/Debit Card" &&
          (!selectedProvider.depositsEnabled || !coinProviders.wallet.status))) && (
        <SuspensionAlert type="Deposit" coin={selectedCoin.coin} />
      )}

      {isCreatingWallet ? (
        <Fragment>
          <Skeleton width="25%" height="20px" />
          <Skeleton width="100%" height="48px" />
        </Fragment>
      ) : (
        <Fragment>
          {selectedCoin && selectedNetwork && (
            <NonFiatCoinDetails selectedCoin={selectedCoin} selectedNetwork={selectedNetwork} />
          )}
        </Fragment>
      )}

      {selectedCoin &&
        selectedProvider &&
        // selectedProvider.label === "Monerium" &&
        selectedProvider.label !== "Credit/Debit Card" &&
        selectedProvider.label !== "Wire transfer" &&
        (selectedProvider.label === "BLINC" ? true : beneficiaryList) && (
          <FiatCoinDetails selectedCoin={selectedCoin} selectedProvider={selectedProvider} />
        )}

      {selectedCoin &&
        selectedProvider &&
        selectedProvider.label === "Wire transfer" &&
        beneficiaryList && (
          <WireTransfer
            coin={selectedCoin?.coin}
            selectedProvider={selectedProvider}
            selectedNetwork={selectedNetwork}
          />
        )}

      {selectedCoin &&
        selectedProvider &&
        (selectedProvider.label === "Monerium" ||
          selectedProvider.label === "Wire transfer" ||
          selectedProvider.label === "BCB" ||
          selectedProvider.label === "Clear Junction") &&
        showBeneficiaryModal && <AddBeneficiary onClose={onCloseBeneficiaryModal} />}

      {selectedCoin &&
        selectedProvider &&
        selectedProvider.label === "Credit/Debit Card" &&
        selectedProvider.depositsEnabled &&
        coinProviders.wallet.status && (
          <FiatCardDeposit
            amount={fiatAmount}
            setAmount={setFiatAmount}
            coin={selectedCoin.coin}
            onCardPaymentCancel={() => setSelectedProvider("")}
            cardDetails={selectedProvider && selectedProvider.value}
          />
        )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  mainWallet: state.wallet.wallets,
  apiLoader: state.wallet.apiLoader,
  userData: state.dashboard.userData
});

const mapDispatchToProps = (dispatch) => ({
  getMainWallet: () => dispatch(getAllWallets())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DepositModal);
