/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation } from 'react-router-dom';

import { DivElement, DropdownWrapper, SearchBar } from '../Reusable';
import { BannerBox } from '../../assets/StyleComponents/Exchange.style';
import { LangugaeIcon } from '../../assets/svgs';
import { ParaTag } from '../../assets/StyleComponents/FontSize/para.style';
import { useWindowSize } from '../../Hooks/CheckWidthHeight';
import { getTranslationLanguageList } from '../../Modules/Generic/selectors';
import { setLocalStorage } from '../../utils/helpers';

const LanguageDropDownProps = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});


const LanguageDropDown = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { width } = useWindowSize();
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn } = useSelector(state => state.auth.isLoggedIn);
  const prevLang = localStorage.getItem("ljs-lang");
  let { transalationList } = useSelector(LanguageDropDownProps);
  
  const isBreakingPoint = width >= 1124;
  const handleLanguage = (givenLang) => {
    const newPath = location.pathname.startsWith(`/${prevLang}`) ? location.pathname.replace(`/${prevLang}`, `/${givenLang.code}`) : location.pathname;
    localStorage.setItem("ljs-lang", givenLang.code);
    setLocalStorage(new Date().getTime(), "last-lang-update-time");
    window.Localize.setLanguage(givenLang.code);
    history.push(`${newPath}`);
  };
  return (
    <DropdownWrapper
      minWidth="200px"
      alignCenter={isBreakingPoint ? "calc(80% - 33px)" : "20px"}
      dropdownAlignRight={isBreakingPoint ? "-120px" : "-12px"}
      label={
        <BannerBox display="flex" alignItems="center" ml={width > 373 ? "12px" : '8px'}>
          <LangugaeIcon />
          {width > 373 && <ParaTag ml="4px"> {prevLang.toUpperCase()} </ParaTag>}
        </BannerBox>
      }
      containerProps={{ padding: "0" }}
    >
      <DivElement
        displayCenter
        marginRight={isLoggedIn ? "16px" : ""}
        marginRightMd="0"
        // marginLeft="8px"
        marginLeftSm="0"
        width="100%"
        // onClick={changeTheme}
        cursor
      >
        <BannerBox display="flex" direction="column" width="100%" mt="12px">
          <ParaTag txtGrey size="14px" weight="300" mb="12px" pl="8px"> Language </ParaTag>

          <SearchBar
            fullBorder
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            maxWidth="178px"
            marginBottom="4px"
            marginLeft="4px"
            inputProps={{ placeholder: "Search", pFontSize: "14px", pFontWeight: "400" }}
            imgProps={{ width: "24px", height: "24px", marginBottom: "4px" }}
          />

          <DivElement width="100%" height="154px" scrollY>
            {transalationList && transalationList.length > 0 &&  transalationList.map((item, index) => {
              return (
                ((searchQuery &&
                  (item?.code.includes(searchQuery.toLowerCase()) ||
                    item?.name.includes(searchQuery.toLowerCase()))) ||
                  !searchQuery) && (
                    <ParaTag
                      txtPrimary={item.code === prevLang ? true : null}
                      key={index} backgroundHover py="8px" px="8px" size="16px" onClick={() => handleLanguage(item)}>
                      {`${item.name} (${item.code.toUpperCase()})`}
                    </ParaTag>
                  )
              );
            })
            }
          </DivElement>
        </BannerBox>
      </DivElement>
    </DropdownWrapper>
  );
};

export default LanguageDropDown;