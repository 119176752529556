import styled, { css }  from "styled-components";

const GridBox = styled.div`
  display: grid;
  ${(props) => props.cursor && `cursor:  ${props.cursor}`};
  ${(props) => props.position && `position:  ${props.position}`};
  ${(props) => props.padding && `padding:  ${props.padding}`};
  ${(props) => props.height && `height:  ${props.height || "40px"}`};
  ${(props) => props.minHeight && `min-height:  ${props.minHeight}`};
  ${(props) => props.maxHeight && `max-height:  ${props.maxHeight}`};
  ${(props) => props.gridTempRow && `grid-template-rows:  ${props.gridTempRow}`};
  ${(props) => props.gridTempColumn && `grid-template-columns:  ${props.gridTempColumn}`};
  ${(props) => props.gridAutoFlow && `grid-auto-flow:  ${props.gridAutoFlow}`};
  ${(props) => props.gridAutoRow && `grid-auto-rows:  ${props.gridAutoRow}`};
  ${(props) => props.gridAutoColumn && `grid-auto-columns:  ${props.gridAutoColumn}`};
  ${(props) => props.gap && `gap:  ${props.gap}`};
  ${(props) => props.gridGap && `grid-gap:  ${props.gridGap}`};
  ${(props) => props.columnGap && `column-gap:  ${props.columnGap}`};
  ${(props) => props.rowGap && `row-gap:  ${props.rowGap}`};
  ${(props) => props.alignItems && `align-items:  ${props.alignItems}`};
  ${(props) => props.justifyItems && `justify-items:  ${props.justifyItems}`};
  ${(props) => props.justifyContent && `justify-content:  ${props.justifyContent}`};
  ${(props) => props.justifySelf && `justify-self:  ${props.justifySelf}`};
  ${(props) => props.alignContent && `align-content:  ${props.alignContent}`};
  ${(props) => props.placeItems && `place-items:  ${props.placeItems}`};
  ${(props) => props.placeContent && `place-content:  ${props.placeContent}`};
  ${(props) => props.placeSelf && `place-self:  ${props.placeSelf}`};
  ${(props) => props.border && `boder: 1px solid ${props.theme.borderColor}`};
  ${(props) => props.overflow && `overflow: ${props.overflow}`};
  ${(props) => props.overflowX && `overflow-x: ${props.overflowX}`};
  ${(props) => props.overflowY && `overflow: ${props.overflowY}`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.ml && `margin-left: ${props.ml}`};
  ${(props) => props.mr && `margin-right: ${props.mr}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};

  ${props => props.TableGrid && css`
    background: ${(props) => props.theme.dashboardTableHead} !important;
    padding: ${(props) => props.tablePadding || "12px"};
    ${(props) => props.borderRadius && `border-radius:  ${props.borderRadius}`};
    @media (max-width: 1200px) {
      ${(props) => props.tablePaddingXl && `padding:  ${props.tablePaddingXl}`};
    }
    @media (max-width: 1024px) {
      ${(props) => props.tablePaddingLg && `padding:  ${props.tablePaddingLg}`};
    }
    @media (max-width: 767px) {
      ${(props) => props.tablePaddingMd && `padding:  ${props.tablePaddingMd}`};
    }
    @media (max-width: 568px) {
      ${(props) => props.tablePaddingSm && `padding:  ${props.tablePaddingSm}`};
    }
  `}

  ${props => props.BottomBorder && css`
    border-bottom: 1px solid ${props.theme.borderColor};
    &:last-child {
      border: none;
    }
  `}


  @media (max-width: 1200px) {
    ${(props) => props.gapXl && `gap:  ${props.gapXl}`};
    ${(props) => props.gridGapXl && `grid-gap:  ${props.gridGapXl}`};
    ${(props) => props.gridTempRowXl && `grid-template-rows:  ${props.gridTempRowXl}`};
    ${(props) => props.gridTempColumnXl && `grid-template-columns:  ${props.gridTempColumnXl}`};
    ${(props) => props.marginXl && `margin: ${props.marginXl}`};
    ${(props) => props.mtXl && `margin-top: ${props.mtXl}`};
    ${(props) => props.mlXl && `margin-left: ${props.mlXl}`};
    ${(props) => props.mrXl && `margin-right: ${props.mrXl}`};
    ${(props) => props.mbXl && `margin-bottom: ${props.mbXl}`};
    ${(props) => props.paddingXl && `padding: ${props.paddingXl}`};
  }

  @media (max-width: 1024px) {
    ${(props) => props.gapLg && `gap:  ${props.gapLg}`};
    ${(props) => props.gridGapLg && `grid-gap:  ${props.gridGapLg}`};
    ${(props) => props.gridTempRowLg && `grid-template-rows:  ${props.gridTempRowLg}`};
    ${(props) => props.gridTempColumnLg && `grid-template-columns:  ${props.gridTempColumnLg}`};
    ${(props) => props.marginLg && `margin: ${props.marginLg}`};
    ${(props) => props.mtLg && `margin-top: ${props.mtLg}`};
    ${(props) => props.mlLg && `margin-left: ${props.mlLg}`};
    ${(props) => props.mrLg && `margin-right: ${props.mrLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mbLg}`};
    ${(props) => props.paddingLg && `padding: ${props.paddingLg}`};
  }

  @media (max-width: 767px) {
    ${(props) => props.gapMd && `gap:  ${props.gapMd}`};
    ${(props) => props.gridGapMd && `grid-gap:  ${props.gridGapMd}`};
    ${(props) => props.gridTempRowMd && `grid-template-rows:  ${props.gridTempRowMd}`};
    ${(props) => props.gridTempColumnMd && `grid-template-columns:  ${props.gridTempColumnMd}`};
    ${(props) => props.marginMd && `margin: ${props.marginMd}`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd}`};
    ${(props) => props.mlMd && `margin-left: ${props.mlMd}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mbMd}`};
    ${(props) => props.paddingMd && `padding: ${props.paddingMd}`};
  }
`;

const GridTable = styled.table`
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  ${(props) => props.height && `height:  ${props.height || "40px"}`};
  ${(props) => props.minHeight && `min-height:  ${props.minHeight}`};
  ${(props) => props.maxHeight && `max-height:  ${props.maxHeight}`};
  ${(props) => props.gridTempRow && `grid-template-rows:  ${props.gridTempRow}`};
  ${(props) => props.gridTempColumn && `grid-template-columns:  ${props.gridTempColumn}`};
  ${(props) => props.gap && `gap:  ${props.gap}`};
  ${(props) => props.gridGap && `grid-gap:  ${props.gridGap}`};
  ${(props) => props.columnGap && `column-gap:  ${props.columnGap}`};
  ${(props) => props.rowGap && `row-gap:  ${props.rowGap}`};
  ${(props) => props.alignItems && `align-items:  ${props.alignItems}`};
  ${(props) => props.justifyItems && `justify-items:  ${props.justifyItems}`};
  ${(props) => props.justifyContent && `justify-content:  ${props.justifyContent}`};
  ${(props) => props.alignContent && `align-content:  ${props.alignContent}`};
  ${(props) => props.placeItems && `place-items:  ${props.placeItems}`};
  ${(props) => props.placeContent && `place-content:  ${props.placeContent}`};
  ${(props) => props.placeSelf && `place-self:  ${props.placeSelf}`};
  ${(props) => props.border && `boder:   1px solid ${props.theme.borderColor}`};
`;

export {
  GridBox,
  GridTable
};