import React, { useRef } from "react";
import { numberWithCommas } from "../../../../../utils/general";
import { Skeleton } from "../../../../../components/Loader";
import { useState } from "react";
import {
  ChevronLeftBlueIcon,
  ChevronLeftIcon,
  ChevronRightBlueIcon
} from "../../../../../assets/svgs";
import {
  FundsRaisedIllustration,
  LayeredPeakIllustration
} from "../../../../../assets/svgs/illustrations";
import { TokenSaleAdminContainer } from "../../../../../assets/StyleComponents/TokenSaleAdmin.style";
import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { HeadingSecond } from "../../../../../assets/StyleComponents/FontSize/headsecond.style";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";

const SaleAnalyticsRow1 = (props) => {
  let { saleDetails, loader } = props;
  const [scrollCount, setScrollCount] = useState(0);
  const { width } = useWindowSize();

  const ref = useRef();
  const scroll = (scrollOffset) => {
    if (scrollOffset > 0) {
      setScrollCount(scrollCount + 1);
    } else {
      if (scrollCount < 1) setScrollCount(0);
      else setScrollCount(scrollCount - 1);
    }
    ref.current.scrollLeft += scrollOffset;
  };

  let isLoaded = !loader && saleDetails;

  return (
    <TokenSaleAdminContainer SaleAnalyticsRow1>
      <BannerBox
        width="100%"
        height="145px"
        heightLg="239px"
        heightMd="218px"
        mt="35px"
        display="flex"
        directionLg="column"
        className="saleanalytics__row1 element-box"
      >
        <BannerBox
          CardWhite
          width="50%"
          widthLg="100%"
          heightLg="50%"
          display="flex"
          className="saleanalytics__row1__col1"
        >
          <BannerBox className="row1__col1__bg">
            <LayeredPeakIllustration />
          </BannerBox>
          <BannerBox bottomBorder={width < 1025 ? true : null} className="row1__col1--pd">
            <BannerBox
              rightBorder={width > 1024 ? true : null}
              borderWidth="0.5px"
              className="row1__col1__box"
            >
              <BannerBox className="row1__col1--flexcol">
                <HeadingFourth txtGrey className="row1__col1__title">
                  Total Funds raised
                </HeadingFourth>
                <HeadingSecond
                  size="32px"
                  sizeLg="24px"
                  sizeMd="20px"
                  mt="14px"
                  mtLg="14px"
                  mtMd="14px"
                  className="row1__col1__value"
                  Row1Loader
                >
                  {isLoaded ? (
                    `$ ${
                      saleDetails.totalRaisedUSD ? numberWithCommas(+saleDetails.totalRaisedUSD) : 0
                    }`
                  ) : (
                    <Skeleton className="saleanalytics__loader" />
                  )}
                </HeadingSecond>
              </BannerBox>

              <FundsRaisedIllustration />
            </BannerBox>
          </BannerBox>
        </BannerBox>
        <BannerBox
          CardWhite
          display="flex"
          justifyContent="space-between"
          width="50%"
          widthLg="100%"
          heightLg="50%"
          className="saleanalytics__row1__col2"
        >
          <BannerBox
            CardGrey={scrollCount < 1 ? true : null}
            CardSky={scrollCount > 0 ? true : null}
            radius="6px 0 0 6px"
            className={`row1__col2__scrollbtn leftscroll-btn ${scrollCount > 0 ? "active" : ""}`}
            onClick={() => scroll(-50)}
          >
            {scrollCount > 0 ? <ChevronLeftBlueIcon /> : <ChevronLeftIcon />}
          </BannerBox>
          <BannerBox className="row1__col1__coindetails" ref={ref}>
            {isLoaded
              ? saleDetails.totalRaisedCoinWise.map((data, i) => {
                  return (
                    <BannerBox className="col1__coindetails__box" key={i}>
                      <BannerBox className="col1__coindetails__coin">{data.name}</BannerBox>
                      <BannerBox className="col1__coindetails__coinvalue">{+data.amount}</BannerBox>
                      <BannerBox className="col1__coindetails__coinusd">{`$ ${numberWithCommas(
                        +data.valueUSD
                      )}`}</BannerBox>
                    </BannerBox>
                  );
                })
              : [1, 2, 3, 4].map((i) => {
                  return (
                    <BannerBox className="col1__coindetails__box" key={i}>
                      <BannerBox className="col1__coindetails__coin">
                        <Skeleton className="saleanalytics__loader" />
                      </BannerBox>
                      <BannerBox className="col1__coindetails__coinvalue">
                        <Skeleton className="saleanalytics__loader" />
                      </BannerBox>
                      <BannerBox className="col1__coindetails__coinusd">
                        <Skeleton className="saleanalytics__loader" />
                      </BannerBox>
                    </BannerBox>
                  );
                })}
          </BannerBox>
          <BannerBox
            CardSky
            className="row1__col2__scrollbtn rightscroll-btn active"
            onClick={() => scroll(50)}
          >
            <ChevronRightBlueIcon />
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </TokenSaleAdminContainer>
  );
};

export default SaleAnalyticsRow1;
