import { css } from "styled-components";
import { MarginStyle } from "../StyleComponents/Reusable/Margin.style";
import { PaddingStyle } from "../StyleComponents/Reusable/Padding.style";
import { PositionStyleCommon } from "../StyleComponents/Position/Position";
// import styledConstants from "../../constants/styledConstants";

const commonStyles = (props) => {
  return css`
    height: ${props.height ? props.height : "24px"};
    width: ${props.width ? props.width : "24px"};
    margin: ${props.margin || ""};
    margin-top: ${props.marginTop || ""};
    margin-right: ${props.marginRight || ""};
    margin-bottom: ${props.marginBottom || ""};
    margin-left: ${props.marginLeft || ""};
    cursor: ${props.pointer ? "pointer" : props.cursor};
    position: ${props.absolute ? "absolute" : ""};
    top: ${props.top || ""};
    right: ${props.right || ""};
    bottom: ${props.bottom || ""};
    left: ${props.left || ""};
    z-index: ${props.zIndex || ""};
    overflow: ${props.overflow || ""};

    @media (max-width: 1024px) {
      height: ${props.heightMd || ""};
      margin: ${props.marginMd || ""};
      width: ${props.widthMd || ""} !important;
      margin: ${props.marginMd || ""};
      margin-top: ${props.marginTopMd || ""};
      margin-right: ${props.marginRightMd || ""};
      margin-bottom: ${props.marginBottomMd || ""};
      margin-left: ${props.marginLeftMd || ""};
    }

    @media (max-width: 767px) {
      height: ${props.heightSm ? props.heightSm : ""};
      width: ${props.widthSm ? props.widthSm : ""};
      margin: ${props.marginSm || ""};
      margin-top: ${props.marginTopSm || ""};
      margin-right: ${props.marginRightSm || ""};
      margin-bottom: ${props.marginBottomSm || ""};
      margin-left: ${props.marginLeftSm || ""};
      display: ${props.displaySm || ""};
    }

    &:active,
    &:focus {
      outline: none;
    }
  `;
};

const svgStyles = (props) => {
  return css`
    ${MarginStyle}
    ${PaddingStyle}
    ${PositionStyleCommon}
    
    ${props.background && css `background: ${props.background }`};
    ${props.borderRadius && css `border-radius: ${props.borderRadius }`};

    ${() => commonStyles(props)}
    ${props.fillGrey &&
    css`
      & path {
        fill: ${props.theme.primaryIcon};
      }
    `}

    ${props.fillGreyCircle &&
    css`
      & circle {
        fill: ${props.theme.primaryIcon};
      }
    `}
    ${props.fillRed &&
    css`
      & circle {
        fill: ${props.theme.rejectedRed};
      }
    `}

    ${props.PulseAnimation &&
    css`
      animation: pulse-animation 2s infinite;
      @keyframes pulse-animation {
        0% {
          border-radius: 50%;
          box-shadow: 0 0 0 0px #2ba0ff40;
        }
        100% {
          border-radius: 50%;
          box-shadow: 0 0 0 10px #2ba0ff40;
        }
      }
    `}
    ${props.fillBlue &&
    css`
      & path {
        fill: ${props.theme.primaryBlue};
      }
    `}

    ${props.fillGreen &&
    css`
      & path {
        fill: ${props.theme.primaryGreen};
      }
    `}
    ${props.fillBlack &&
    css`
      & path {
        fill: black;
      }
    `}

    ${props.fillSuccess &&
    css`
      & path {
        fill: ${props.theme.success};
      }
    `}

    ${(props) =>
      props.HoverScreen &&
      css`
        opacity: 0;
        &:hover {
          opacity: 1;
        }
      `};

    ${props.fillRed &&
    css`
      & path {
        fill: ${props.theme.primaryRed};
      }
    `}

    ${props.fillYellow &&
    css`
      & path {
        fill: ${props.theme.primaryYellow};
      }
    `}

    ${props.fillOrange &&
    css`
      & path {
        fill: ${props.theme.primaryOrange};
      }
    `}

    ${props.fillOrangeSecondary &&
    css`
      & path {
        fill: ${props.theme.secondaryOrange};
      }
    `}
    ${props.SVGSmallSize &&
    css`
      width: 24px;
      height: 24px;
    `}

    ${props.SVGExtraSmallSize &&
    css`
      width: 16px;
      height: 16px;
    `}
  

    ${props.fillWhite &&
    css`
      & path {
        fill: #fff;
      }
    `}

    ${props.fillWhiteBlack &&
    css`
      & path {
        fill: ${props.theme.isDarkTheme ? "#012243" : "#fff"};
      }
    `}

    ${props.fillLevel0 &&
    css`
      & path {
        fill: ${props.theme.isDarkTheme ? "#fff" : "#012243"};
      }
    `}

    ${props.pathStrokeGrey &&
    css`
      & path {
        stroke: ${props.theme.primaryIcon};
      }
    `}

    ${props.lineStrokeGrey &&
    css`
      & line {
        stroke: ${props.theme.primaryIcon};
      }
    `}

    ${props.fillGreyDarkTheme &&
    css`
      & path {
        fill: #bbbdbf;
      }
    `}

    ${props.fillDark &&
    css`
      & path {
        fill: ${props.theme.primaryText};
      }
    `}

    ${props.fillBlack &&
    css`
      & path {
        fill: #000;
      }
    `}
  `;
};

export default svgStyles;
