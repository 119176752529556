import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";
import { noExponents, numberWithCommas } from "../../../../utils/general";
import { Button } from "../../../../components/Reusable/Button";
import { DivElement, SpanElement, TextWrapper } from "../../../../components/Reusable";
import BigNumber from "bignumber.js";
import { WalletIcon } from "../../../../assets/svgs";
import styledConstants from "../../../../constants/styledConstants";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import InputContainer from "./InputContainer";

const AmountWrapper = styled(DivElement)`
  display: flex;
  align-items: center;

  @media (max-width: ${styledConstants.BREAKING_POINT_MOBILE}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const AmountInput = (props) => {
  const { amount, setAmount, bondDetails, balance, feeBalance, isLoggedIn } = props;
  const { width } = useWindowSize();

  const onClickMax = () => {
    let amt, coinAmt;
    if (props.name !== "modifyAmount" && props.balance > bondDetails.maxAmount) {
      if (bondDetails.bondCoin === bondDetails.feeCoin) {
        let fee = BigNumber(bondDetails.maxAmount).multipliedBy(+bondDetails.feeAmountRate);
        let total = BigNumber(bondDetails.maxAmount).plus(fee);
        if (total > props.balance) {
          coinAmt = BigNumber(props.balance).div(1 + Number(bondDetails.feeAmountRate));
          amt = coinAmt.div(bondDetails.denomination);
        } else {
          amt = BigNumber(bondDetails.maxAmount).multipliedBy(bondDetails.denomination);
        }

        if (amt >= 1) setAmount(BigNumber(parseInt(amt)));
      } else {
        amt = Number(parseInt(BigNumber(bondDetails.maxAmount).div(bondDetails.denomination)));
        if (amt >= 1) setAmount(BigNumber(amt));
      }
    } else {
      if (bondDetails.bondCoin === bondDetails.feeCoin && props.name !== "modifyAmount") {
        coinAmt = BigNumber(props.balance).div(1 + Number(bondDetails.feeAmountRate));
        amt = coinAmt.div(bondDetails.denomination);
        if (amt >= 1) setAmount(BigNumber(parseInt(amt)));
      } else {
        amt = Number(parseInt(BigNumber(props.balance).div(bondDetails.denomination)));
        if (amt >= 1) setAmount(BigNumber(amt));
      }
    }
  };

  const isFeeAndBalDiff =
    bondDetails.bondCoin !== bondDetails.feeCoin && props.name !== "modifyAmount";

  return (
    <DivElement width="100%">
      <DivElement displayEnd marginBottom="8px">
        <DivElement
          width="calc(100% - 67px)"
          widthSm="100%"
          displayBtw
          bdGrey
          borderRadius="5px"
          border={width <= styledConstants.BREAKING_POINT_MOBILE ? "1px solid" : ""}
          padding={width <= styledConstants.BREAKING_POINT_MOBILE ? "3px 6px" : ""}
        >
          <DivElement dAlignCenter>
            {width > styledConstants.BREAKING_POINT_MOBILE && props.name !== "modifyAmount" && (
              <WalletIcon width="16px" height="16px" marginRight="8px" />
            )}
            <TextWrapper fontSize="12px" lineHeight="18px">
              {props.name === "modifyAmount" ? "Invested amount:" : "Main balance:"}
            </TextWrapper>
          </DivElement>
          <DivElement
            bdGrey
            minWidth={isFeeAndBalDiff ? "206px" : ""}
            height="30px"
            padding="6px 8px"
            border={width > styledConstants.BREAKING_POINT_MOBILE ? "1px solid" : ""}
            borderRadius="5px"
            displayBtw
          >
            <TextWrapper
              minWidth={isFeeAndBalDiff ? "82px" : ""}
              secondary
              fontSize="12px"
              lineHeight="18px"
            >
              {`${bondDetails.bondCoin}- `}
              <SpanElement
                primary
                fontSize="inherit"
                red={balance < amount * bondDetails.denomination || null}
              >
                {numberWithCommas(noExponents(balance > 100 ? +balance.toFixed(2) : balance))}
              </SpanElement>
            </TextWrapper>
            {isFeeAndBalDiff && (
              <Fragment>
                <DivElement
                  bdGrey
                  borderRight="2px solid"
                  height="100%"
                  margin="0 8px"
                ></DivElement>
                <TextWrapper minWidth="82px" secondary fontSize="12px" lineHeight="18px">
                  {`${bondDetails.feeCoin}- `}
                  <SpanElement
                    primary
                    fontSize="inherit"
                    red={
                      feeBalance < +bondDetails.feeAmountRate * amount * bondDetails.denomination ||
                      null
                    }
                  >
                    {numberWithCommas(
                      noExponents(feeBalance > 100 ? +feeBalance.toFixed(2) : feeBalance)
                    )}
                  </SpanElement>
                </TextWrapper>
              </Fragment>
            )}
          </DivElement>
        </DivElement>
      </DivElement>
      <AmountWrapper>
        <TextWrapper fontSize="14px" fontSizeSm="12px" marginRight="16px">
          Amount
        </TextWrapper>
        <InputContainer
          amount={amount}
          setAmount={setAmount}
          bondDetails={bondDetails}
          name={props.name}
          balance={balance}
        >
          {isLoggedIn && (
            <Button
              onClick={onClickMax}
              height="24px !important"
              width="52px"
              secondaryBlue
              padding="0 12px"
              paddingSm="0 8px"
              fontSize="14px"
              fontSizeSm="12px"
            >
              max
            </Button>
          )}
        </InputContainer>
      </AmountWrapper>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  isLoggedIn: state.auth.isLoggedIn
});

export default compose(connect(mapStateToProps))(AmountInput);
