import React, { memo } from "react";
import { Link } from "react-router-dom";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";

const ApiKeysComponent = () => {
  const prevLang = localStorage.getItem("ljs-lang");
  
  return (
    <BannerSection display="block" displayLg="none">
      <BannerBox
        DashboardBordersdark
        height="77px"
        display="flex"
        width="100%"
        justifyContent="space-between"
        borderRadius="10px"
        CardWhite
        alignItems="center"
        padding="16px 24px"
        mt="16px"
        // className="py-3 pd-x-rem-5-1 mt-3 exch-api-key"
      >
        <BannerHeading className="f-rem-1-1 pb-2 pt-1 mb-0" BodyColor weight="500">
          API Keys
        </BannerHeading>

        <Link to={`${prevLang ? `/${prevLang}` : "/en"}/setting/api_management`}>
          <ButtonComponent OutlinePrimary type="Button" width="92px" btnHeight="35px">
            Create
          </ButtonComponent>
        </Link>
      </BannerBox>
    </BannerSection>
  );
};

export default memo(ApiKeysComponent);
