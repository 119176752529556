import styled, { css } from "styled-components";

export const ScrollBarDiv = styled.div`
  ${(props) => props.height && `height: ${props.height}`};

  ${(props) =>
    props.ScrollbarContainer &&
    css`
      .scrollbar {
        display: flex;
        height: 100%;
      }
      .scrollbar__container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow-x: scroll;
        overflow-y: visible;
      }
      .scrollbar__btn {
        display: none;

        @media (max-width: 767px) {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      .scrollbar__btn img {
        width: 24px;
        height: 24px;
      }
      .scrollbar__btn--none {
        display: none !important;
      }
      .scrollbar__btn--left {
        @media (max-width: 767px) {
          justify-content: flex-start;
        }
      }
      .scrollbar__btn--right {
        @media (max-width: 767px) {
          justify-content: flex-end;
        }
      }
    `}
`;
