import moment from "moment";
import { numberWithCommas } from "../../../../utils/general";
import { formatNumbers } from "../../../../utils/helpers";
import { DivElement, TextWrapper } from "../../../Reusable";

const CardDetails = ({ txnDetails }) => {
  const DATA = [
    {
      label: "Deposit amount",
      value: `${numberWithCommas(formatNumbers(txnDetails.amount))} ${txnDetails.coin}`
    },
    {
      label: "Card processing fee",
      value: `${numberWithCommas(formatNumbers(txnDetails.txFee) || 0)} ${txnDetails.coin}`
    },
    { label: "Date & time", value: moment(txnDetails.createdAt).format("DD/MM/YY, hh:mm a") }
  ];
  return (
    <DivElement
      width="100%"
      padding="16px 8px 0 8px"
      paddingMd="16px 0 0 0"
      bdGrey
      borderTop="1px solid"
      marginTop="16px"
    >
      {DATA.map((item, index) => (
        <DivElement
          key={item.label}
          flexJustifyBtwAlignCenter
          marginBottom={index === 2 ? "0" : "8px"}
        >
          <TextWrapper fontSizeSm="12px" lineHeight="20px" lineHeightSm="18px" secondary>
            {item.label}
          </TextWrapper>
          <TextWrapper fontSizeSm="12px" lineHeight="20px" lineHeightSm="18px">
            {item.value}
          </TextWrapper>
        </DivElement>
      ))}
    </DivElement>
  );
};

export default CardDetails;
