import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { BannerBox, BannerSection } from '../../../../assets/StyleComponents/Exchange.style';
import { HeadingSecond } from '../../../../assets/StyleComponents/FontSize/headsecond.style';
import { HeadingFourth } from '../../../../assets/StyleComponents/FontSize/headingFourth.style';
import { ButtonComponent } from '../../../../assets/StyleComponents/Button.style';
import { RewardsBlockicon } from '../../../../assets/svgs';

const MyRewardsPermotionBanner = () => {
  const MainElement = styled.div`
    display: flex;
    align-items: center;
    height: 330px;
    background: url(${(props) => props.bgImg}) no-repeat center;
    background-size: cover;
    padding: ${(props) => props.padding};
  `;

  let isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const prevLang = localStorage.getItem("ljs-lang");

  if(!isLoggedIn) return <Redirect to={`/${prevLang}/rewards`} />;
  return (
    <MainElement 
      // bgImg={ReferalPCImage} 
      padding="56px 40px"
    > 
      <BannerSection display="flex" direction="column" width="100%" gap="90px">
        <BannerBox display="flex" direction="column" width="100%" gap="8px">
          <HeadingSecond weight="500" size="44px" sizeLg="16px" color="white">
            Get Rewarded at Each Step
          </HeadingSecond>
          <HeadingFourth weight="400" sizeLg="16px" sizeMd="12px" color="#CCCED0">
            {`Multiply Your Rewards`}
          </HeadingFourth>
        </BannerBox>

        <BannerBox>
          {isLoggedIn && 
            <ButtonComponent
              Primary
              width="187px"
              btnHeight="56px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="15px"
              size="16px"
            >
              <RewardsBlockicon />
              My Rewards
            </ButtonComponent>
          }

          {!isLoggedIn && 
            <HeadingFourth color={"#fff"} sizeLg="16px" sizeMd="12px">
              Please sign in to view your rewards
            </HeadingFourth>
          }

        </BannerBox>
      </BannerSection>
    </MainElement>
  );
};

export default MyRewardsPermotionBanner;