/* eslint-disable import/namespace */
import * as moment from "moment";
import { new_BN } from "./bigNumber";

const storageKeyName = "accessToken";

export const getToken = () => {
  const storage = getLocalStorage();
  if (storage) {
    const { token } = JSON.parse(storage);
    return token;
  }

  return null;
};

export const logout = () => {
  deleteLocalStorage();
  window.location.replace("/trade/LCX-EUR");
};

export const setLocalStorage = (data, name) => {
  if (!data) return;
  if (name) localStorage.setItem(name, data);
  else localStorage.setItem(storageKeyName, JSON.stringify(data));
};

export const getLocalStorage = (name) => {
  if (name) return localStorage.getItem(name);
  else return localStorage.getItem(storageKeyName);
};

export const deleteLocalStorage = () => {
  return localStorage.removeItem(storageKeyName);
};

export const getUserID = () => {
  const storage = getLocalStorage();
  if (storage) {
    const { user_id } = JSON.parse(storage);
    return user_id;
  }

  return null;
};

export const checkRoute = (pairList, symbol) => {
  if (pairList && pairList.length > 0 && symbol) {
    return pairList?.some((pair) => pair.symbol === symbol);
  }

  return false;
};

export const isRouteAllow = (path, innerPath) => {
  const storage = getLocalStorage("pairData");
  if (storage) {
    const pairList = JSON.parse(storage);
    switch (path) {
      case path:
        return checkRoute(pairList, innerPath);

      default:
        return false;
    }
  }
  return false;
};

export const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, delay);
  };
};

//=> Number convert to  *
export const convertNumberToStar = (x, showStars) => {
  let val = "";
  if (showStars === 7) {
    val = "*******";
  }
  if (showStars === 5) {
    val = "*****";
  }
  return val;
};

export const hoursLeft = (givenDate) => {
  const given = moment(givenDate);
  const current = moment().startOf("day");
  return parseInt(moment.duration(current.diff(given)).hours());
};

export const daysLeft = (givenDate) => {
  // eslint-disable-next-line no-undef
  // var given = moment(givenDate).fromNow();
  var given = moment(givenDate);
  var current = moment().startOf("day");
  return parseInt(moment.duration(current.diff(given)).asDays());

  // var seconds = Math.floor((new Date() - givenDate) / 1000);

  // var interval = seconds / 31536000;

  // interval = seconds / 2592000;
  // if (interval > 1) {
  //   return Math.floor(interval) + " months";
  // }
  // interval = seconds / 86400;
  // if (interval > 1) {
  //   return Math.floor(interval) + " days";
  // }
  // interval = seconds / 3600;
  // if (interval > 1) {
  //   return Math.floor(interval) + " hours";
  // }
  // interval = seconds / 60;
  // if (interval > 1) {
  //   return Math.floor(interval) + " minutes";
  // }
  // return Math.floor(seconds) + " seconds";
};

// => Remove Extra Zero
export const removeZero = (n) => {
  let amount = n.split(".");
  if (amount && amount.length > 1) {
    if (amount && amount[1] === "00000000") return amount[0];
    let count = (amount[1] && amount[1].length - 1) || 0;

    if (amount[1] && amount[1].length > 0) {
      // eslint-disable-next-line no-unused-vars
      for (let i = count; 0 <= count; count--) {
        if (amount[1][count] > 0) {
          break;
        }
      }
    }
    if (count > -1) {
      if (count === 0 && amount[1][count] === "0") amount = amount[0];
      amount[1].slice(count);
      amount = `${amount[0]}.${amount[1].slice(0, count + 1)}`;
    }
    if (count && count < 0) {
      amount = `${amount[0]}`;
    }
  }
  return amount;
};

export const formatNumbers = (getNumber, percision = 8) => {
  if (getNumber === "") return "";
  let amount = new_BN(getNumber, percision) || 0;
  let countValue = removeZero(amount);
  if (isNaN(countValue)) return 0;
  return countValue || 0;
};

// export const getTranslationLanguageList = () => {
//   window.Localize.getAvailableLanguages(function (err, data) {
//     try {
//       return data;
//     } catch(err) {
//       throw new Error(`Error occurred on translation Language! ${err}!`);
//     }
//   });
// };

// export const combineFunctions = (fn, ...props) => {
//   if(fn) {
//     for(let fnc in fn) {

//     }
//   }
// };
export const TimeFormat = (time, multiLang) => {
  if (typeof time !== "object") {
    time = new Date(time);
  }

  var seconds = Math.floor((new Date() - time) / 1000); //=> Find Out Seconds
  var intervalType;

  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    if (interval === 1) intervalType = `${multiLang.Yr_ago}`;
    else intervalType = `${multiLang.Yrs_ago}`; //=> For Years
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      if (interval === 1) intervalType = `${multiLang.Mnth_ago}`;
      else intervalType = `${multiLang.Mnths_ago}`; //=> For Months
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        if (interval === 1) intervalType = `${multiLang.Day_ago}`;
        else intervalType = `${multiLang.Days_ago}`; //=> For days
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          if (interval === 1) intervalType = `${multiLang.Hr_ago}`;
          else intervalType = `${multiLang.Hrs_ago}`; //=> For hours
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            if (interval === 1) intervalType = `${multiLang.Min_ago}`;
            else intervalType = `${multiLang.Mins_ago}`; //=> For Minutes
          } else {
            interval = seconds;
            intervalType = `${multiLang.Secs_ago}`; //=> For Seconds
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += "";
  }

  // if (multiLang.Mins_ago.includes("...")) {
  //   return intervalType.replace("...", interval);
  // }
  else {
    return interval + " " + intervalType;
  }
};

// This function runs when you want to check if 24 hours have passed or Not
export const check24HoursPassedByLocalStorage = (getLocalValue) => {
  const ONE_DAY_MS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  let lastUpdatedTime = 0;
  if(typeof getLocalValue === "string") lastUpdatedTime = getLocalStorage(getLocalValue);
  const currentTime = new Date().getTime();
  if (!lastUpdatedTime || (currentTime - lastUpdatedTime) >= ONE_DAY_MS) {
    return true;
  }
  return false;
};