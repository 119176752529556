import React, { useEffect } from "react";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { HeadingSecond } from "../../../../assets/StyleComponents/FontSize/headsecond.style";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrivateReglarRewardsTasksList,
  resetRewardHubState
} from "../../../../actions/RewardsHub/RewardsHub";
import SingleRewardTask from "../SingleRewardsTask/SingleRewardTask";

const RewardsTasks = () => {
  const dispatch = useDispatch();
  const successMsg = useSelector((state) => state.rewards.success);

  let param = {
    type: "active",
    offset: "0",
    pageSize: 25,
    category: "REGULAR"
  };

  useEffect(() => {
    dispatch(getPrivateReglarRewardsTasksList(param));
  }, []);

  useEffect(() => {
    if (successMsg) {
      dispatch(resetRewardHubState());
    }
  }, [successMsg]);

  return (
    <BannerBox
      CardWhite2
      radius={"10px"}
      padding={"32px 32px 32px"}
      paddingLg={"24px 20px 48px"}
      paddingMd={"16px 12px 8px"}
      margin="1.1rem 0px"
      alignItems="center"
      gap="20px"
    >
      <HeadingSecond size="20px" weight="500" mb="24px">
        Regular Tasks
      </HeadingSecond>

      <SingleRewardTask />
    </BannerBox>
  );
};

RewardsTasks.ListOfCards = SingleRewardTask;

export default RewardsTasks;
