/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import { formatAPIErrors, noExponents, numberWithCommas } from "../../../../utils/general";
import WithdrawalModal from "../Component/Modal/withdrawalModal";
import { withdrawFiat } from "../../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { Button, DivElement, TextWrapper } from "../../../../components/Reusable";
import { numberInputOnWheelPreventChange, setPrecision } from "../../../../utils";
import BigNumber from "bignumber.js";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { FormInput } from "../../../../assets/StyleComponents/Input.style";
import { LoadingButton } from "../../../../components/Loader";
import { divide, multiply, subtract } from "../../../../utils/bigNumber";
import { formatNumbers } from "../../../../utils/helpers";

const FaitAddressComponent = (props) => {
  const { selectedBeneficiary, apiError, clearWalletErrors } = props;
  let { selectedCoin, selectedNetwork } = useContext(DepositContext);
  const [amount, set_amount] = useState("");
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [loading, set_loading] = useState(false);
  const [otpId, setOtpId] = useState("");
  const [errorMsg, setErroMsg] = useState("");
  const [finalAmt, setFinalAmt] = useState(0);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (apiError && apiError.message) {
      showErrorMsg(apiError.message, { autoClose: 7000 });
      clearWalletErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError]);

  useEffect(() => {
    if (!selectedCoin) return;
    if (!amount || errorMsg) {
      setFinalAmt("0");
      return;
    }

    let totalAmount;
    let amt = new BigNumber(amount);
    let fee = new BigNumber(+selectedCoin?.fees);
    totalAmount = amt.minus(selectedNetwork.value === "Wire Transfer" ? 0 : fee);
    if (totalAmount < 0 || !totalAmount) totalAmount = 0;

    setFinalAmt(setPrecision(totalAmount, 8));
  }, [amount, errorMsg]);

  const Condition =
    selectedNetwork?.label === "BCB" ||
    selectedNetwork?.label === "Clear Junction" ||
    selectedNetwork?.label === "BLINC";

  const checkInvalidInput = (value) => {
    let reg = /^(\d{0,8}|\d{0,8}\.\d{0,8})$/;
    return value.match(reg);
  };

  const validateAmount = (value) => {
    let reg = checkInvalidInput(value);
    let valueSplit = value.split(".");

    if (!reg) {
      if (valueSplit && valueSplit.length === 2 && valueSplit[1].length > 8) {
        setErroMsg("Enter correct number");
        set_amount(value);
      }
    } else {
      let amount = isNaN(value) || Number(value) < 0 ? "" : value;
      let minAmt = Number(selectedCoin?.minWithdrawalLimit);
      let minAmtConditional = Number(selectedNetwork?.min);

      let maxAmt = Number(selectedNetwork?.max);
      let balance = Number(selectedCoin?.balance?.freeBalance);

      if (selectedNetwork?.label === "Wire Transfer") {
        if (Number(amount) < 25000) {
          setErroMsg("Minimum withdrawal limit 25000 Euro");
          set_amount(amount);
          setDisabled(true);
          return;
        }
      } else if (Condition) {
        if (Number(amount) < minAmtConditional) {
          setErroMsg(`Amount should be greater than or equal to ${minAmtConditional}`);
          setDisabled(true);
          set_amount(amount);
          return;
        }
        if (Number(amount) > maxAmt) {
          setErroMsg(`Amount should be less than or equal to ${maxAmt}`);
          setDisabled(true);
          set_amount(amount);
          return;
        }
      }
      if (amount === "") {
        setErroMsg("");
        setDisabled(true);
      } else if (Number(amount) > balance && !Condition) {
        setErroMsg("Insufficient balance");
        setDisabled(true);
      } else if (Number(amount) < minAmt && !Condition) {
        setErroMsg(`Amount should be greater than or equal to ${minAmt}`);
        setDisabled(true);
      } else {
        setErroMsg("");
        setDisabled(false);
      }

      set_amount(amount);
      // setDisabled(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setStep(step + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiData]);

  const validateCredentials = () => {
    let total = Number(amount) - Number(YourFees(selectedNetwork.fee));
    if (selectedCoin.fees && total <= 0) {
      showErrorMsg(`Minimum withdrawal amount is ${selectedCoin.fees} ${selectedCoin.coin}`);
      setDisabled(true);
      return false;
    }

    if (!amount || isNaN(amount)) {
      showErrorMsg("Invalid Amount");
      setDisabled(true);
      return false;
    }

    if (selectedCoin.balance && selectedCoin.balance.freeBalance < Number(amount)) {
      showErrorMsg("Insufficient balance");
      setDisabled(true);
      return false;
    }

    set_loading(true);
    setDisabled(false);
    let data = {
      coin: selectedCoin.coin,
      amount: setPrecision(amount, 8),
      beneficiaryId: selectedBeneficiary?.value?.id,
      source: "ACCOUNTS"
    };

    withdrawFiat(data)
      .then((res) => {
        showSuccessMsg(res.data.message, { autoClose: 7000 });
        setOtpId(res.data.data.id);
        setShowModal(true);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      })
      .finally(() => set_loading(false));
  };

  const onClickMaxBtn = () => {
    let balance = setPrecision(selectedCoin?.balance?.freeBalance, 8);
    validateAmount(noExponents(balance));
  };

  function YourFees(data) {
    // debugger;
    if (data.type === "FIXED") {
      const DataFromValue = Number(data.value);
      return DataFromValue;
    } else if (data.type === "PERCENTAGE") {
      const DataFromPercentage = Number(
        multiply(divide(Number(data.value), 100), Number(amount), 2)
      );
      const DataFromPrecentage2 = Number(data.min);
      return DataFromPercentage > DataFromPrecentage2 ? DataFromPercentage : DataFromPrecentage2;
    }
  }

  function YouGet() {
    // debugger;
    const value = subtract(Number(amount), Number(YourFees(selectedNetwork.fee)), 6);
    return value;
  }

  let minAmt = YourFees(selectedNetwork.fee);

  return (
    <BannerBox mt={"16px"}>
      {showModal && (
        <WithdrawalModal
          isTheme={props.isTheme}
          onClose={closeModal}
          show={showModal}
          otpId={otpId}
          coin={selectedCoin}
          goToStep={2}
        />
      )}
      {selectedCoin.coinType === "fiat_token" && (
        <Fragment>
          <DivElement
            className="fInput__form__field"
            width="100%"
            marginTop="24px"
            marginBottom="0"
          >
            <TextWrapper
              secondary
              fontSize="14px"
              fontWeight="300"
              lineHeight="20px"
              marginBottom="8px"
            >
              Withdrawal Amount
            </TextWrapper>
            <DivElement className="fInput__form__input" position="relative">
              <FormInput
                width="calc(100% - 80px)"
                className="form-control"
                type="number"
                required="required"
                sizeMd="12px"
                placeholder="Enter the amount"
                value={amount}
                onChange={(e) => validateAmount(e.target.value)}
                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                onWheel={numberInputOnWheelPreventChange}
              />

              <Button
                secondaryBlue
                padding="0 16px"
                height="32px"
                marginRight="12px"
                onClick={onClickMaxBtn}
              >
                max
              </Button>
            </DivElement>
          </DivElement>

          {errorMsg && (
            <BannerHeadingSpan size="14px" txtDanger mt="10px">
              {errorMsg}
            </BannerHeadingSpan>
          )}

          {selectedCoin?.fees && (
            <DivElement margin="24px 0px">
              <TextWrapper secondary fontSize="14px">
                Transaction Fee:{" "}
                <BannerHeadingSpan headerText siz="14px" weight="500">
                  <var>
                    <b>
                      {amount >= minAmt
                        ? numberWithCommas(
                            selectedNetwork.value === "Wire Transfer"
                              ? 0
                              : noExponents(YourFees(selectedNetwork.fee))
                          )
                        : 0}{" "}
                      {selectedCoin.withdrawalFeeCoinf
                        ? selectedCoin.withdrawalFeeCoin
                        : selectedCoin?.coin}
                    </b>
                  </var>
                </BannerHeadingSpan>
              </TextWrapper>

              <TextWrapper secondary fontSize="14px" marginTop="10px">
                You Will Get:{" "}
                <BannerHeadingSpan headerText size="14px" weight="500">
                  <var>
                    {amount > minAmt
                      ? numberWithCommas(formatNumbers(noExponents(YouGet(selectedNetwork.fee))))
                      : 0}{" "}
                    {selectedCoin?.coin}
                  </var>
                </BannerHeadingSpan>
              </TextWrapper>
            </DivElement>
          )}

          <BannerBox className="text-right">
            <ButtonComponent
              style={{
                borderWidth: 1,
                width: "100%",
                height: "48px",
                cursor: disabled && "no-drop"
              }}
              disabled={errorMsg && disabled ? true : false}
              PrimaryButton
              opacity={disabled ? "0.4" : "1"}
              onClick={() => {
                if (!errorMsg) validateCredentials();
              }}
              className="mr-3 mb-0 btn btn-primary bck-primary"
              type="button"
              // disabled={disabled}
            >
              {loading ? <LoadingButton color="#fff" /> : "Withdraw"}
            </ButtonComponent>
          </BannerBox>
        </Fragment>
      )}
    </BannerBox>
  );
};

// Call To Map To Props
const maptoStateProps = (state) => ({
  isTheme: state.theme.isTheme,
  userData: state.dashboard.getUserData,
  getBeneficiaryAll: state.bank.getBeneficiaryAll,
  getBeneficiaryCount: state.bank.getBeneficiaryCount
});

const mapDispatchToProps = (dispatch) => ({
  // getBeneficiary: (user) => dispatch(getBeneficiary(user))
});

export default connect(maptoStateProps, mapDispatchToProps)(FaitAddressComponent);
