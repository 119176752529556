import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import IndexFooter from "../../components/Footer/footer";
import DepositWithrawal from "./depositWithdrwal";
import TradingFee from "./tradingFee";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { trackPage } from "../../tracking/track";
import { useSelector } from "react-redux";
import { checkPairName } from "../../utils/trade";
import {
  BannerBox,
  BannerHeadingSpan,
  MainElement,
  BannerSection
} from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { RouterLink } from "../../components/Reusable";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import FiatFees from "./FiatFees";
// import { useSelector } from "react-redux";

const FeeStructureComponent = (props) => {
  let location = useLocation();

  useEffect(() => {
    let page = location.pathname + location.search;
    trackPage(page);
  }, []);

  let selectedPairName = useSelector((state) => state.trade.selectedPair);

  const [selectedPairActive, setSelectedPairActive] = useState("");

  useEffect(() => {
    if (location.pathname.includes("/trade") && location.pathname !== "/order/trade-history") {
      let pairActive =
        checkPairName(selectedPairName || { base_pair: "LCX", quote_pair: "EUR" }, location) ||
        "LCX/EUR";
      setSelectedPairActive("/" + pairActive.split("/").join("-"));
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isLoggedIn, userData } = props;
  const isTheme = useSelector((state) => state.theme.isTheme);
  return (
    <BannerBox minHeight="100vh" position="relative">
      <MainElement>
        <BannerSection TutorialBg paddBottom="5.5rem" paddTop="4rem">
          <BannerBox ExchangeContainer>
            <BannerBox display="flex" direction="column">
              <BannerBox textAlign="center" flexLg="1">
                <BannerHeading BodyColor weight="bold" sizeMd="36px" size="2.75rem">
                  {" "}
                  Fee Structure{" "}
                </BannerHeading>
              </BannerBox>

              <BannerBox mt="24px" textAlign="center" widthMd="100%">
                <BannerHeading size="1.5rem" BodyColor sizeMd="18px" weight="300">
                  {" "}
                  Overview of fees on LCX{" "}
                </BannerHeading>
              </BannerBox>
            </BannerBox>

            <BannerBox
              position="absolute"
              mt="45px"
              mtLg="55px"
              width="100%"
              textAlign="center"
              left="0%"
            >
              {!isLoggedIn ? (
                <RouterLink paddingTop="2rem" paddingBottom="2rem" to={"/register"}>
                  <ButtonComponent
                    SuccessButton
                    type="button"
                    width="477px"
                    widthMd="312px"
                    btnHeight="72px"
                    btnHeightMd="48px"
                    btnHeightLg="48px"
                    size="1.25rem"
                    // sizeLg="16"
                    sizeMd="18px"
                    // className="f-rem-2-1"
                  >
                    Start trading in LCX Exchange
                  </ButtonComponent>
                </RouterLink>
              ) : (
                Object.keys(userData).length > 0 && (
                  <RouterLink
                    to={`/trade${selectedPairActive}`}
                    paddingTop="2rem"
                    paddingBottom="2rem"
                  >
                    <ButtonComponent
                      SuccessButton
                      type="button"
                      width="477px"
                      widthMd="312px"
                      btnHeight="72px"
                      btnHeightMd="48px"
                      btnHeightLg="48px"
                      size="1.25rem"
                      // sizeLg="16"
                      sizeMd="18px"
                    >
                      Start trading in LCX Exchange
                    </ButtonComponent>
                  </RouterLink>
                )
              )}
            </BannerBox>
          </BannerBox>
        </BannerSection>

        <BannerSection BeginerBg paddTop="6rem" paddBottom="4rem" paddingLg="48px 0px">
          <BannerBox ExchangeContainer paddBottom="3rem">
            <BannerBox
              widthLg="100%"
              display="flex"
              direction="column"
              justifyContent="center"
              position="relative"
            >
              {!isLoggedIn && (
                <RouterLink textDecoration="none" BodyColor padding="0px auto" to={"/register"}>
                  <ParaTag weight="bold" textAlign="center" mt="16px" size="1.25rem">
                    <BannerHeadingSpan BodyColor>
                      {" "}
                      New to LCX?{" "}
                      <u style={{ color: !isTheme ? "#0470e1" : "#70A6E0" }}>
                        {" "}
                        Create an Account Here{" "}
                      </u>{" "}
                    </BannerHeadingSpan>
                  </ParaTag>
                </RouterLink>
              )}
            </BannerBox>
            <BannerBox display="flex" direction="column" pt="3.3rem">
              <TradingFee {...props} />
            </BannerBox>

            <BannerBox display="flex" direction="column" pt="63px" ptMd="30px">
              <DepositWithrawal {...props} />
            </BannerBox>
            <BannerBox display="flex" direction="column" pt="63px" ptMd="30px">
              <FiatFees {...props} />
            </BannerBox>
          </BannerBox>
        </BannerSection>
        {/* <hr style={{ margin: "0px auto" }} /> */}
      </MainElement>

      {/* Footer Index */}
      <IndexFooter {...props} />
    </BannerBox>
  );
};

export default withRouter(FeeStructureComponent);
