import styled from "styled-components";

export const TokenSaleAdminContainer = styled.main`
width: 100%;
font-weight: 500;

.saleanalytics__title {
  font-size: 24px;
  color: ${({ theme }) => theme.primaryText};
}

${(props) =>
  props.SaleAnalyticsRow1 &&
  `
// Sale Analytics Row1 styles
.saleanalytics__row1 {
  width: 100%;
  height: 145.5px;
  padding: 0!important;
  margin-top: 35px;
  display: flex;

  @media(max-width: 1023.5px) {
    flex-direction: column;
    height: 239px;
  }

  @media(max-width: 767.5px) {
    height: 218px;
  }
}

.saleanalytics__row1__col1 {
  width: 50%;
  display: flex;

  @media(max-width: 1023.5px) {
    width: 100%;
    height: 50%;
  }
}

.row1__col1__bg {
  width: 99.7px;
  overflow: hidden;
  height: 100%;
  border-radius: 6px 0 0 6px;

  svg {
    width: 100%;
  }

  @media(max-width: 1023.5px) {
    display: none;
  }
}

.row1__col1--pd {
  width: 100%;
  height: 100%;
  padding: 24px 0;

  @media(max-width: 1023.5px) {
    padding: 24px 0 0 0;
  }

  @media(max-width: 767.5px) {
    padding: 16px 0 0 0;
  }
}

.row1__col1--flexcol {
  display: flex;
  flex-direction: column;
}

.row1__col1__box {
  width: 100 %;
  height: 100 %;
  padding: 0 32px 0 20px;
  display: flex;
  justify-content: space-between;

  border-right: 0.5px solid ${({ theme }) => theme.ProgesssBarSettings};

  img {
  width: 54px;
  height: 54px;

  @media(max-width: 1023.5px) {
    width: 40px;
    height: 40px;
  }

  @media(max-width: 767.5px) {
    width: 24px;
    height: 24px;
  }
}

@media(max-width: 1023.5px) {
  padding: 0 39px;
  border-right: none!important;
}

@media(max-width: 767.5px) {
  padding: 0 16px;
}
}

.row1__col1__title {
  font-size: 20px;

  color: ${({ theme }) => theme.subText};

  @media(max-width: 1023.5px) {
    font-size: 18px;
  }
}

.row1__col1__value {
  font-size: 32px;
  font-weight: bold;
  margin-top: 14px;
  height: 36px;
  color: ${({ theme }) => theme.primaryText}

  @media(max-width: 1023.5px) {
    font-size: 24px;
    margin-top: 12px;
  }

  @media(max-width: 767.5px) {
    font-size: 20px;
    margin-top: 14px;
  }
}

.saleanalytics__row1__col2 {
  width: 50 %;
  padding-left: 16.5px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 1023.5px) {
    width: 100 %;
    height: 50 %;
    padding: 0 4px;
    align-items: center;
  }

  @media(max-width: 767.5px) {
    padding: 0 8px;
  }
}

.row1__col2__scrollbtn {
  width: 32px;
  height: 100 %;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.TutorialBg};

  img {
    width: 24px;
    height: 24px;
  }

  @media(max-width: 1023.5px) {
    height: 72px;
  }

  @media(max-width: 767.5px) {
    width: 24px;
    height: 24px;
  }

  &.active {
      background-color: ${({ theme }) => theme.buttonActive};
  }

  &.leftscroll-btn {
    @media(max-width: 1023.5px) {
      border-radius: 6px 0 0 6px;
    }
  }

  &.rightscroll-btn {
    border-radius: 0 6px 6px 0;
  }
}

.row1__col1__coindetails {
  width: 100 %;
  height: 100 %;
  display: flex;
  overflow-x: scroll;
  padding-top: 24px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &:: -webkit-scrollbar {
    display: none;
  }

  @media(max-width: 1023.5px) {
    padding: 23.5px 20px 0 20px;
    border-top: 0.5px solid ${({ theme }) => theme.ProgesssBarSettings};
  }

  @media(max-width: 767.5px) {
    padding: 22.5px 24px 0 24px;
  }
}

.col1__coindetails__box {
  padding-left: 62.5px;
  display: flex;
  flex-direction: column;

  @media(max-width: 1023.5px) {
    padding-left: 53px;
  }

  @media(max-width: 767.5px) {
    padding-left: 33px;
  }

  &: last-child {
    margin-right: 20px;
  }
}

.col1__coindetails__box: nth-child(1) {
  @media(max-width: 1023.5px) {
    padding-left: 0;
  }
}

.col1__coindetails__coin {
  font-size: 16px;
  white-space: nowrap;
  color: t($primary-text);

  @media(max-width: 767.5px) {
    font-size: 14px;
  }
}

.col1__coindetails__coinvalue {
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;

  color: ${({ theme }) => theme.primaryText};

  @media(max-width: 767.5px) {
    font-size: 16px;
  }
}

.col1__coindetails__coinusd {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;

    color: ${({ theme }) => theme.subText};
}
`}

${(props) =>
  props.SaleAnalyticsRow2 &&
  `
// Sale Analytics Row2 styles

.saleanalytics__row2 {
  width: 100 %;
  height: 240px;
  padding: 22px 18px!important;
  display: flex;
  flex-wrap: wrap;

  @media(max-width: 1023.5px) {
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    padding: 0.5px 39px 22px 39px!important;
  }

  @media(max-width: 767.5px) {
    padding: 0.5px 16px 0 16px!important;
  }
}

.saleanalytics__row2__box {
  width: 50 %;
  height: 50 %;
  display: flex;
  justify-content: space-between;

  img {
    width: 54px;
    height: 54px;

    @media(max-width: 1023.5px) {
      width: 40px;
      height: 40px;
    }
  }

  @media(max-width: 1023.5px) {
    width: 100 %;
    height: 100px;
    padding: 0!important;
    padding-top: 21.5px!important;
    // border: none!important;
    border-bottom: 0.5px solid t($sep-color)!important;
  }

  @media(max-width: 767.5px) {
    padding-top: 19.5px!important;
  }
}

.saleanalytics__row2__box: nth-child(1),
.saleanalytics__row2__box: nth-child(3) {
  padding-right: 32px;

  border-right: 0.5px solid t($sep-color);
}

.saleanalytics__row2__box: nth-child(1),
.saleanalytics__row2__box: nth-child(2) {
    border-bottom: 0.5px solid ${({ theme }) => theme.ProgesssBarSettings};
}

.saleanalytics__row2__box: nth-child(2),
.saleanalytics__row2__box: nth-child(4) {
  // padding-right: 25px;
  padding-left: 26px;
}

.saleanalytics__row2__box: nth-child(3),
.saleanalytics__row2__box: nth-child(4) {
  padding-top: 30px;
}

.saleanalytics__row2__box: nth-child(4) {
  @media(max-width: 1023.5px) {
    // border-bottom: none;
  }
}

.saleanalytics__row2__box--flexcol {
  display: flex;
  flex-direction: column;
}

.saleanalytics__row2__box.row2__box__title {
  font-size: 18px;

  @include themed() {
    color: t($sub-text);
  }

  @media(max-width: 1023.5px) {
    font-size: 16px;
  }
}

.saleanalytics__row2__box.row2__box__value {
  font-size: 24px;
  font-weight: bold;

  @include themed() {
    color: t($primary-text);
  }

  @media(max-width: 1023.5px) {
    font-size: 20px;
  }
}
`}
${(props) =>
  props.SaleAnalyticsRow3 &&
  `
.saleanalytics__row3 {
  width: 100%;
  min-height: 480px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 1023.5px) {
    flex-direction: column;
    justify-content: initial;
    min-height: auto;
    height: auto;
  }
}

.saleanalytics__row3__col1 {
  width: 60%;
  margin-right: 8px!important;
  padding: 16px 18px!important;
  padding-bottom: 84px!important;

  @media(max-width: 1023.5px) {
    width: 100%;
    padding: 22px 39px!important;
    margin-right: 0!important;
  }

  @media(max-width: 767.5px) {
    padding: 30px 16px 24px 16px!important;
  }
}

.row3__col1__title {
  font-size: 18px;
  font-weight: bold;

  @include themed() {
    color: t($primary-text);
  }
}

.row3__col1__slider {
  margin-top: 36px;
}

.row3__col1__container {
  width: 100%;
  min-height: 232px;
  margin-top: 50.5px;
  display: flex;

  @media(max-width: 1023.5px) {
    flex-direction: column;
    margin-top: 58px;
  }

  @media(max-width: 767.5px) {
    margin-top: 40px;
  }
}

.row3__col1__rounddetails {
  width: 50%;
  padding-top: 42.5px;
  padding-right: 22px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0-8px;
  
  border-right: 1px solid ${({ theme }) => theme.inputBorder};

  @media(max-width: 1023.5px) {
    width: 100%;
    border-right: none!important;
    padding: 0;

    border-bottom: 1px solid ${({ theme }) => theme.inputBorder};
  }
}

.col1__rounddetails__box {
  width: calc(50% - 16px);
  margin: 0 8px;
}

.rounddetails__box__percent {
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.primary};
}

.progress {
  @include themed() {
    background-color: t($input-focus-bg)!important;
  }
}

.progress-bar {
  @include themed() {
    background-color: t($primary-blue)!important;
  }
}

.rounddetails__box__info {
  font-size: 14px;
  text-align: left;
  margin-top: 11px;

  @include themed() {
    color: t($primary-text);
  }
}

.row3__col1__saletimer {
  width: 50 %;
  padding-top: 42.5px;
  padding-left: 27px;

  @media(max-width: 1023.5px) {
    width: 100 %;
    padding-top: 24.5px;
    padding-left: 0;
  }
}

.row3__col1__saletimer.col1__saletimer__title {
  display: flex;
  align-items: center;
  font-size: 18px;

  @include themed() {
    color: t($primary-text);
  }

  img {
    width: 24px;
    margin-right: 12.5px;
  }
}

.row3__col1__saletimer.col1__saletimer__container {
  display: flex;
  margin-top: 43px;

  @media(min-width: 1024px) {
    justify-content: space-between;
  }

  @media(max-width: 1023.5px) {
    margin-top: 32px;
  }

  @media(max-width: 767.5px) {
    margin-top: 24px;
  }
}

.row3__col1__saletimer.col1__saletimer__timebox {
  width: 68px;
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include themed() {
    border: 1px solid t($sep-color);
  }

  @media(max-width: 1023.5px) {
    margin-right: 22px;
  }

  @media(max-width: 767.5px) {
    width: 56px;
    height: 56px;
    margin-right: 24px;
  }
}

.saletimer__timebox__value {
  font-size: 29px;

  @include themed() {
    color: t($primary-text);
  }

  @media(max-width: 767.5px) {
    font-size: 18px;
  }
}

.saletimer__timebox__title {
  font-size: 15px;

  @include themed() {
    color: t($sub-text);
  }

  @media(max-width: 1023.5px) {
    font-size: 16px;
  }

  @media(max-width: 767.5px) {
    font-size: 14px;
  }
}

.saleanalytics__row3__col2 {
  width: 40 %;
  margin-left: 8px!important;
  padding: 16px 18px!important;

  @media(max-width: 1023.5px) {
    width: 100 %;
    margin-left: 0!important;
    // max-height: 351px;
  }
}

.row3__col2__title {
  font-size: 18px;
  font-weight: bold;

  @include themed() {
    color: t($primary-text);
  }
}

.row3__col2__chart {
  width: 100 %;
  height: 100 %;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
`}

${(props) =>
  props.SaleAnalyticsRow4 &&
  `
// Sale Analytics Row4 styles
.saleanalytics__row4 {
  width: 100 %;
  min-height: 480px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 1023.5px) {
    flex-direction: column;
    justify-content: initial;
    min-height: auto;
    height: auto;
  }
}

.saleanalytics__row4__col1 {
  width: 40 %;
  padding: 16px 18px!important;
  margin-left: 8px;

  @media(max-width: 1023.5px) {
    width: 100 %;
    margin-left: 0;
  }
}

.row4__col1__header {
  display: flex;
  justify-content: space-between;
}

.row4__col1__title {
  font-size: 18px;
  font-weight: bold;

  @include themed() {
    color: t($primary-text);
  }
}

.row4__col1__filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include themed() {
    color: t($primary-text);
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.row4__col1__chart {
  margin-top: 49px;
}

.saleanalytics__row4__col2 {
  width: 60 %;
  padding: 16px 18px!important;
  margin-right: 8px;
  font-weight: 500;

  @media(max-width: 1023.5px) {
    width: 100 %;
    margin-right: 0;
    min-height: 438px;
  }
}

.saleanalytics__row4__col2.table {
  th {
    @include themed() {
      color: t($sub-text);
    }
  }

  td {
    @include themed() {
      color: t($primary-text);
    }
  }
}

.row4__col2__header {
  display: flex;
  justify-content: space-between;
}

.row4__col2__title {
  font-size: 18px;
  font-weight: bold;

  @include themed() {
    color: t($primary-text);
  }
}

.row4__col2__pagination {
  display: flex;
  justify-content: flex-end;

  @include themed() {
    color: t($primary-text);
  }
}

.col2__pagination__currPage {
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
  margin-right: 5px;

  @include themed() {
    border: 1px solid t($sep-color);
    color: t($primary-text);
  }
}

.col2__pagination__btns {
  margin-left: 8px;
  cursor: pointer;

  &: last-child {
    margin-left: 16px;
  }
}

.col2__pagination__btns--disable {
  cursor: not-allowed;
}

.row4__col2__chart {
  margin-top: 40px;

  .table thead th {
    @include themed() {
      border-bottom: 1px solid t($sep-color)!important;
    }
  }
}
`}

${(props) =>
  props.Row1Loader &&
  `
  // Row1 React Skeleton Styles
.row1__col1__value.saleanalytics__loader {
  width: 100 %;
  height: 36px;

  @media(max-width: 1023.5px) {
    height: 27px;
  }

  @media(max-width: 767.5px) {
    height: 23px;
  }
}

.col1__coindetails__coin.saleanalytics__loader {
  width: 38px;
  height: 18px;

  @media(max-width: 767.5px) {
    width: 26px;
    height: 16px;
  }
}

.col1__coindetails__coinvalue.saleanalytics__loader {
  width: 84px;
  height: 23px;

  @media(max-width: 767.5px) {
    width: 61px;
    height: 18px;
  }
}

.col1__coindetails__coinusd.saleanalytics__loader {
  width: 96px;
  height: 16px;

  @media(max-width: 767.5px) {
    width: 93px;
  }
}
`}


// Row2 React Skeleton Styles
.row2__box__value.saleanalytics__loader {
  width: 100 %;
  height: 27px;

  @media(max-width: 1023.5px) {
    height: 23px;
  }

  @media(max-width: 767.5px) {
    height: 18px;
  }
}

// Sale Details Tab 1
.saleDetails__tab1 {
  display: flex;
  flex-direction: column;
}

.saleDetails__tab1__yt {
  width: 100 %;
  height: 356px;
  padding-top: 16px;

  @media(max-width: 1023.5px) {
    width: 100 %;
    height: 290px;
  }

  @media(max-width: 767.5px) {
    width: 100 %;
    height: 167px;
  }

  img,
  .react__player {
    width: 100 % !important;
    height: 100 % !important;
  }
}

.saleDetails__tab1__pTitle {
  font-size: 30px;
  font-weight: bold;
  margin-top: 41px;

  @include themed() {
    color: t($primary-text);
  }

  @media(max-width: 1023.5px) {
    font-size: 20px;
    margin-top: 37px;
  }

  @media(max-width: 767.5px) {
    font-size: 18px;
    margin-top: 30px;
  }
}

.saleDetails__tab1__icons {
  display: flex;
  margin-top: 23px;

  @media(max-width: 1023.5px) {
    margin-top: 16px;
  }
}

.saleDetails__icon__box {
  display: flex;
  margin-right: 40px;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  align-items: center;

  @include themed() {
    color: t($primary-blue);
  }

  @media(max-width: 1023.5px) {
    margin-right: 56px;
  }

  @media(max-width: 767.5px) {
    font-size: 14px;
  }

  img {
    width: 36px;
    height: 36px;
    margin-right: 15px;

    @media(max-width: 1023.5px) {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }

    @media(max-width: 767.5px) {
      width: 24px;
      height: 24px;
    }
  }

  a {
    color: inherit;
  }
}

.saleDetails__tab1__content {
  margin-top: 38px;
  font-size: 16px;
  font-weight: 500;

  @include themed() {
    color: t($primary-text);
  }

  @media(max-width: 1023.5px) {
    margin-top: 32px;
  }

  @media(max-width: 767.5px) {
    margin-top: 25px;
    font-size: 14px;
  }
}

// Token Sale Steps Loader Styles
.saleloader {
  width: 100 %;
}

.saleloader__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  .saleloader__header__timer {
    @media(max-width: 767.5px) {
      width: 50px!important;
    }
  }
}

.saleloader__fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.saleloader__input {
  margin-bottom: 28.3px;
}

.saleloader__btn {
  margin-top: 80px;
}

.saleTransactions {
  padding-bottom: 0!important;
  padding-top: 0!important;
  margin-bottom: 0!important;
  box-shadow: none!important;
}

.saleTransactions__title {
  font-size: 24px;
  font-weight: bold;

  @include themed() {
    color: t($primary-text);
  }

  @media(max-width: 767.5px) {
    font-size: 18px;
  }
}

.saleTransactions__subtitle {
  font-size: 16px;
  font-weight: 500;

  @include themed() {
    color: t($primary-text);
  }

  @media(max-width: 1023.5px) {
    font-size: 18px;
  }

  @media(max-width: 767.5px) {
    font-size: 12px;
  }
}

.saleTransactions__table {
  margin-top: 56px;

  @media(max-width: 1023.5px) {
    margin-top: 48px;
  }

  @media(max-width: 767.5px) {
    margin-top: 32px;
  }
}

.saleTransactions__tableContainer {
  @include themed() {
    color: t($primary-text);
  }
}

.saleTransactions__tableContainer.table thead tr th {
  height: 40px!important;
  vertical-align: middle;
  padding: 0 24px!important;
  font-size: 14px;
  font-weight: 500;

  @media(max-width: 1023.5px) {
    padding: 0 16px!important;
  }

  @media(max-width: 767.5px) {
    padding: 0 8px!important;
    font-size: 12px;
  }
}

.saleTransactions__tableContainer.table tbody tr td {
  min-height: 40px!important;
  vertical-align: middle;
  padding: 0 24px!important;
  height: 51.5px;
  font-size: 16px;
  font-weight: 500;

  @media(max-width: 1023.5px) {
    padding: 0 16px!important;
  }

  @media(max-width: 767.5px) {
    padding: 0 8px!important;
    font-size: 12px;
  }
}

.saleTransactions__coinimg {
  width: 28px;
  height: 28px;
  margin-right: 12px;

  @media(max-width: 767.5px) {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

.saleTransactions__tableContainer.table thead tr.saleTransactions__coinTh {
  padding-left: 64px!important;

  @media(max-width: 1023.5px) {
    padding-left: 56px!important;
  }

  @media(max-width: 767.5px) {
    padding-left: 30px!important;
  }
}

// For Get bank details tab
.bankDetails__description {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    max-width: 120px;
  }

  @media(max-width: 767.5px) {
    max-width: 50px;
  }
}

#countdown {
  position: relative;
  margin: auto;
  height: 50px;
  width: 50px;
  text-align: center;
}

#countdown-number {
  color: #999999;
  display: inline-block;
  line-height: 50px;
  position: relative;
  z-index: 4;
  left: -13px;
}

#circleLoader {
  position: absolute;
  top: 0;
  right: 0;
  left: 50px;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
  // z-index:2;
}

#circleLoader circle {
  stroke-dasharray: 47px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  animation: countdown 30s linear infinite;

  @include themed() {
    stroke: t($primary-blue);
    fill: t($cards-bg);
  }
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }

  to {
    stroke-dashoffset: 47px;
  }
}

// Token Sale Step 3----------------------------------->
.buytoken__step3 {
  width: 100 %;
  font-weight: 500;

  @include themed() {
    color: t($primary-text);
  }
}

.buytoken__step3__header {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  @media(max-width: 767.5px) {
    font-size: 18px;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 14px;
    cursor: pointer;
  }
}

.buytoken__step3__container {
  margin-top: 29.5px;
  border-radius: 5px;
  padding: 18px 16px;

  @include themed() {
    border: 1px solid t($sep-color);
  }

  @media(max-width: 1023.5px) {
    margin-top: 32px;
  }
}

.buytoken__step3__details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
}

.step3__details__type,
.step3__details__value {
  font-size: 14px;

  @media(max-width: 767.5px) {
    font-size: 12px;
  }
}

.step3__details__totalamt {
  font-size: 14px;
  font-weight: bold;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.buytoken__step3__btn {
  margin-top: 30px;

  @media(max-width: 1023.5px) {
    margin-top: 40px;
  }
}

// Token Sale Step 4----------------------------------->
.buytoken__step4 {
  width: 100 %;
  font-weight: 500;

  @include themed() {
    color: t($primary-text);
  }
}

.buytoken__step4__header {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  @media(max-width: 767.5px) {
    font-size: 18px;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 14px;
    cursor: pointer;
  }
}

.buytoken__step4__animation {
  width: 100 %;
  display: flex;
  justify-content: center;
}

.buytoken__step4__animation__success {
  width: 248px;
  height: 248px;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    width: 240px;
    height: 240px;
  }

  @media(max-width: 767.5px) {
    width: 152px;
    height: 152px;
  }
}

.buytoken__step4__animation__failure {
  width: 200px;
  height: 200px;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    width: 152px;
    height: 152px;
  }

  @media(max-width: 767.5px) {
    width: 104px;
    height: 104px;
  }
}

.buytoken__step4__msg {
  font-size: 16px;
  font-weight: bold;
  text-align: center;

  @media(max-width: 767.5px) {
    font-size: 14px;
  }
}

.buytoken__step4__link {
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  margin-top: 22px;

  @include themed() {
    color: t($primary-blue);
  }

  a {
    color: inherit;
  }
}

.buytoken {
  display: flex;
  justify-content: space-between;
  margin: 0-8px;
  font-weight: 500;

  @include themed() {
    color: t($primary-text);
  }

  @media(max-width: 1023.5px) and(min-width: 767px) {
    display: block;
    margin: 0;
  }

  @media(max-width: 767.5px) {
    display: block;
    margin: 0;
  }

  .btn {
    padding: 1rem;
  }
}

.buytoken.element-box {
  margin: 0 8px!important;
  margin-bottom: 25px!important;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    margin: 0!important;
    margin-bottom: 0!important;
    border-bottom-right-radius: 0!important;
    border-bottom-left-radius: 0!important;
  }

  @media(max-width: 767.5px) {
    margin: 0!important;
    margin-bottom: 16px!important;
  }
}

.buytoken__right {
  @media(min-width: 1024px) {
    width: 33.3333333333 %;
  }

  @media(max-width: 1023.5px) and(min-width: 767px) {
    max-width: 100 %;
    min-width: 644px;
  }

  @media(max-width: 767.5px) {
    max-width: 100 %;
    min-width: 328px;
  }
}

.buytoken.buytoken__right {
  margin: 0 8px!important;
  margin-bottom: 25px!important;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    margin: 0!important;
    margin-bottom: 16px!important;
  }

  @media(max-width: 767.5px) {
    margin: 0!important;
    margin-bottom: 16px!important;
  }
}

.buytoken__left {
  @media(min-width: 1024px) {
    width: 66.6666666667 %;
    padding: 46px 51px 72px 31px;
  }

  @media(max-width: 1023.5px) and(min-width: 767px) {
    max-width: 100 %;
    min-width: 644px;
    padding: 32px 102px 48px 103px;
  }

  @media(max-width: 767.5px) {
    max-width: 100 %;
    min-width: 328px;
    padding: 32px 8px 16px;
  }
}

.buytoken__left.element-box {
  @media(max-width: 1023.5px) and(min-width: 767px) {
    margin-bottom: 0!important;
    border-bottom-right-radius: 0!important;
    border-bottom-left-radius: 0!important;
  }
}

.buytoken__form {
  margin-left: 0!important;
  margin-right: 0!important;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    display: flex;
    justify-content: center;
  }
}

.buytoken__form.element-box {
  margin-left: 0!important;
  margin-right: 0!important;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    display: flex;
    justify-content: center;
  }
}

.buytoken__form__container {
  @media(max-width: 1023.5px) and(min-width: 767px) {
    width: 480px;
    padding: 13px 24px 32px;
    border-radius: 6px;
    margin-bottom: 32px;

    @include themed() {
      border: 0.5px solid t($sep-color);
    }
  }
}

.buytoken__salebanner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.salebanner__status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 16px;
  border-radius: 16px;
  font-size: 14px;

  @include themed() {
    color: t($primary-white);
  }
}

.salebanner__status--ONGOING {
  @include themed() {
    background-color: t($primary-green);
  }
}

.salebanner__status--UPCOMING {
  @include themed() {
    background-color: t($primary-blue);
  }
}

.salebanner__status--COMPLETED {
  @include themed() {
    background-color: t($primary-orange);
  }
}

.salebanner__coinimg {
  width: 150px;
  height: 150px;
  margin-top: 11px;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    width: 100px;
    height: 100px;
  }

  @media(max-width: 767.5px) {
    width: 80px;
    height: 80px;
    margin-top: 12px;
  }
}

.salebanner__coinimg img {
  width: 100 %;
  height: 100 %;
  border-radius: 50 %;
}

.salebanner__salename {
  font-size: 30px;
  margin-top: 6px;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    font-size: 28px;
  }

  @media(max-width: 767.5px) {
    font-size: 24px;
  }
}

.salebanner__saletype {
  font-size: 20px;
  font-weight: 300;

  @media(max-width: 1023.5px) and(min-width: 767px) {
  }

  @media(max-width: 767.5px) {
    font-size: 16px;
  }
}

.salebanner__saletimer {
  margin-top: 20px;
}

.salebanner__saleinfo {
  width: 500px;
  margin-top: 35px;
  border-radius: 5px;
  padding: 11.8px 17.8px 17px 23px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // margin-bottom: 71px;

  @include themed() {
    border: 1px solid t($sep-color);
  }

  @media(max-width: 1023.5px) and(min-width: 767px) {
    margin-top: 32px;
    // margin-bottom: 16px;
    width: 480px;
  }

  @media(max-width: 767.5px) {
    margin-top: 32px;
    width: 100%;
    height: 184px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // margin-bottom: 0;
  }
}

.saleinfo__info {
  font-size: 16px;
  text-align: left;

  @media(max-width: 767.5px) {
    display: flex;
    justify-content: space-between;
  }
}

.saleinfo__info__value {
  @media(max-width: 767.5px) {
    min-width: 130px;
    text-align: left;
  }
}

.saleinfo__info--width {
  min-width: 230px;

  @media(max-width: 767.5px) {
    min-width: initial;
  }
}

.saleinfo__info--marginTop {
  margin-top: 24px;

  @media(max-width: 767.5px) {
    margin-top: 0;
  }
}

// Token sale dashboard-------------------------------------------------->
.tokensale__card__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0-8px;
  margin-top: 50px;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    margin: 0-12px;
    margin-top: 24px;
  }

  @media(max-width: 767.5px) {
    margin-top: 23px;
  }
}

.tokensale__card {
  width: 269px;
  margin: 8px;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    width: 310px;
    margin: 12px;
  }

  @media(max-width: 767.5px) {
    width: 327.5px;
  }
}

.tokensale__card.element-box {
  margin: 0!important;
}

// Token sale step 2------------------------------------------>
.tokensaleS2__balance {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: 500;
  margin-bottom: 10px;

  @include themed() {
    color: t($sub-text);
  }
}

.tokensaleS2__balance--mt {
  margin-top: 28px;
}

.tokensaleS2__balance__title {
  font-size: 14px;

  @media(max-width: 767px) {
    font-size: 12px;
  }
}

.tokensaleS2__balance__val {
  font-size: 12px;
}

.tokensale__step2__iAgree {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 500;

  @include themed() {
    color: t($sub-text);
  }

  @media(max-width: 767.5px) {
    font-size: 12px;
  }

  a {
    text-decoration: underline;

    @include themed() {
      color: t($primary-blue);
    }
  }
}

.step2__iAgree__txt {
  margin-left: 16px;
  margin-top: -5px;
}

// Salenavbar
.sale-navbar {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  @media(max-width: 1023.5px) {
    flex-direction: column-reverse;
  }

  @media(max-width: 767.5px) {
    flex-direction: column;
  }
}

.sale-navbar__searchbar {
  position: relative;
  display: flex;
  align-items: center;

  @media(max-width: 1023.5px) {
    justify-content: flex-end;
    // margin-top: 40px;
  }

  @media(max-width: 767.5px) {
    width: 100 %;
    margin-bottom: 20px;
  }
}

.sale-navbar__searchbar input {
  width: 268px;
  height: 46px;
  border-radius: 5px;
  padding: 0 16px;

  @include themed() {
    color: t($primary-text);
    background-color: t($cards-bg);
    border: 1px solid t($tapable-input-field);
  }

  &::placeholder {
    font-size: 14px;

    @include themed() {
      color: t($sub-text);
    }
  }

  &: focus,
  &:active {
    outline: none;

    @include themed() {
      border: 1px solid t($tapable-input-field);
    }
  }

  @media(max-width: 1023.5px) {
    width: 224px;
    height: 48px;
  }

  @media(max-width: 767.5px) {
    width: 100 %;
    margin-top: 0;
  }
}

.sale-navbar__searchbar div {
  position: absolute;
  right: 16px;

  @include themed() {
    background-color: t($cards-bg);
  }
}

.sale-navbar__navlinks {
  display: flex;

  @media(max-width: 767.5px) {
    width: 100 %;

    .element-box {
      width: 100 %;
    }
  }
}

.sale-navbar__navlinks.sale-navbar__navlinks__tab {
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @include themed() {
    color: t($primary-text);
  }

  &: hover,
  &.active {
    @include themed() {
      color: t($primary-blue);
    }
  }

  @media(min-width: 767px) {
    width: 161px;
    height: 36px;
    border-radius: 18px;
    margin: 0 9px;

    &: first-child {
      margin-left: 0;
    }

    @include themed() {
      border: 1px solid t($tapable-input-field);
    }

    &: hover,
    &.active {
      @include themed() {
        color: t($primary-white);
        background-color: t($primary-blue);
        border-color: t($primary-blue);
      }
    }
  }

  @media(max-width: 767.5px) {
    height: 100 %;
    padding: 0 8px;
    margin: 0 11px;

    &: first-child {
      margin-left: 0;
    }

    &: last-child {
      margin-right: 0;
    }

    &: hover,
    &.active {
      @include themed() {
        border-bottom: 2px solid t($primary-blue);
      }
    }
  }
}

.wallets-navbar {
  margin: 0!important;
  height: 53px;
  border-radius: 6px 6px 0 0;
  padding: 0 0 0 37px!important;

  @include themed() {
    border-bottom: 1px solid t($sep-color);
  }

  @media(max-width: 767.5px) {
    padding: 0!important;
  }
}

// Sale Dashboard Styles
.active-tab {
  cursor: pointer;

  @include themed() {
    background-color: t($primary-blue);
    color: t($primary-white);
  }
}

.inactive-tab {
  cursor: pointer;

  @include themed() {
    border: solid 1px t($sep-color);
    color: t($primary-text);
  }
}

.active-tab,
.inactive-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 150px;
  font-size: 14px;
  border-radius: 20px;
  font-weight: 500;
  margin: 0 0.5rem;
}

.sale-countdown {
  text-align: right;
  width: fit-content;
  height: 30px;
  font-weight: bold;
  border: double 2px transparent;
  border-radius: 80px;
  background-origin: border-box;
  background-clip: content-box, border-box;

  @include themed() {
    background-color: t($cards-bg)!important;
    color: t($sub-text);
  }
}

.ongoing {
  @include themed() {
    background-image: linear-gradient(t($cards-bg), t($cards-bg)),
      radial-gradient(circle at top left, #24b314, #82b37d);
  }
}

.upcoming {
  @include themed() {
    background-image: linear-gradient(t($cards-bg), t($cards-bg)),
      radial-gradient(circle at top left, #ffb83e, #ffe3b3);
  }
}

.token-sale-link: link,
.token-sale-link: active,
.token-sale-link: visited,
.token-sale-link:hover {
  text-decoration: none;
}

.status-box {
  width: 95px;
  text-align: center;
  padding: 6px;
  background-color: #24b314;
  border-radius: 18px;
  color: #fff;
}

.custom-amount-input input,
.custom-amount-input input: focus,
.custom-amount-input input:active {
  border: none;
  outline: none;
}

.sale-amount-input {
  font-weight: 500;
  border-radius: 6px;
  width: 100 %;
  padding: 10px 10px;

  @include themed() {
    background-color: t($input-field);
    border: 1px solid t($sep-color);
  }

  input {
    outline: none!important;
    font-size: 16px;
    line-height: 20px;
    border: none!important;
    padding: 0px;

    @include themed() {
      background-color: t($input-field);
      color: t($primary-text);
    }
  }

  input:focus {
    @include themed() {
      background-color: t($input-field);
    }
  }
}

.right-box-2 {
  float: right;
  margin-top: 10px;
  width: 40 %;
  justify-content: center;
  align-items: flex-end;
  font-weight: 500;

  @media(max-width: 767px) {
    width: 20 %;
  }
}

.roundDropdown.Dropdown-control {
  background-color: transparent;

  @include themed() {
    border: 1px solid t($sep-color);
    color: t($primary-text);
  }
}

.tablet {
  .roundDropdown.Dropdown-control {
    padding: 8px 100px 8px 10px;
  }
}

.mobile {
  .roundDropdown.Dropdown-control {
    padding: 8px 100px 8px 10px;
  }
}

.right-box-3 {
  max-width: 122px!important;
  width: 100 %;
  max-height: 35px;
}

// Token Sale Transactions--------------------------------->
.saleTransactions {
  padding-bottom: 0!important;
  padding-top: 0!important;
  margin-bottom: 0!important;
  box-shadow: none!important;
}

.saleTransactions__title {
  font-size: 24px;
  font-weight: bold;

  @include themed() {
    color: t($primary-text);
  }

  @media(max-width: 767.5px) {
    font-size: 18px;
  }
}

.saleTransactions__subtitle {
  font-size: 16px;
  font-weight: 500;

  @include themed() {
    color: t($primary-text);
  }

  @media(max-width: 1023.5px) {
    font-size: 18px;
  }

  @media(max-width: 767.5px) {
    font-size: 12px;
  }
}

.saleTransactions__table {
  margin-top: 56px;

  @media(max-width: 1023.5px) {
    margin-top: 48px;
  }

  @media(max-width: 767.5px) {
    margin-top: 32px;
  }
}

.saleTransactions__tableContainer {
  @include themed() {
    color: t($primary-text);
  }
}

.saleTransactions__tableContainer.table thead tr th {
  height: 40px!important;
  vertical-align: middle;
  padding: 0 24px!important;
  font-size: 14px;
  font-weight: 500;

  @media(max-width: 1023.5px) {
    padding: 0 16px!important;
  }

  @media(max-width: 767.5px) {
    padding: 0 8px!important;
    font-size: 12px;
  }
}

.saleTransactions__tableContainer.table tbody tr td {
  min-height: 40px!important;
  vertical-align: middle;
  padding: 0 24px!important;
  height: 51.5px;
  font-size: 16px;
  font-weight: 500;

  @media(max-width: 1023.5px) {
    padding: 0 16px!important;
  }

  @media(max-width: 767.5px) {
    padding: 0 8px!important;
    font-size: 12px;
  }
}

.saleTransactions__coinimg {
  width: 28px;
  height: 28px;
  margin-right: 12px;

  @media(max-width: 767.5px) {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

.saleTransactions__tableContainer.table thead tr.saleTransactions__coinTh {
  padding-left: 64px!important;

  @media(max-width: 1023.5px) {
    padding-left: 56px!important;
  }

  @media(max-width: 767.5px) {
    padding-left: 30px!important;
  }
}

// For Get bank details tab
.bankDetails__description {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media(max-width: 1023.5px) and(min-width: 767px) {
    max-width: 120px;
  }

  @media(max-width: 767.5px) {
    max-width: 50px;
  }
}

`;
