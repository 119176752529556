/* eslint-disable no-unused-vars */
import React from "react";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
} from "../../../../assets/StyleComponents/Exchange.style";
import { HeadingSecond } from "../../../../assets/StyleComponents/FontSize/headsecond.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { AnchorTag } from "../../../../assets/StyleComponents/FontSize/para.style";

function ProfileFaqSection() {
  const { width } = useWindowSize();

  const Faqs = [
    // {
    //   title: "How to Make an Account on LCX Exchange?",
    //   link: "https://www.lcx.com/info/faq/sign-up/how-to-make-an-account-on-lcx-exchange/"
    // },
    {
      title: "What to Do if You Haven’t Received the Verification Email?",
      link: "https://www.lcx.com/info/faq/sign-up/what-to-do-if-you-havent-received-the-verification-email/"
    }
  ];

  return (
    <BannerSection SectionWhite padding="24px" radius="10px">
      <BannerBox>
        <HeadingSecond size="16px" weight="500">
          KYC Tutorials
        </HeadingSecond>
        <BannerBox margin="16px 0px" radius="10px" bg="transparent">
          <iframe
            width="100%"
            height={width < 1024 && width > 767 ? "300" : "200"}
            title="lcx Info Profile video"
            style={{ borderRadius: "5px", border: "0px" }}
            src="https://www.youtube.com/embed/2XqKE6ejRxs?controls=0&modestbranding=1&rel=0&iv_load_policy=3&cc_load_policy=0&disablekb=1&fs=0"
            allow="autoplay; encrypted-media"
          ></iframe>
        </BannerBox>
      </BannerBox>
      {/* {Faqs?.length > 0 && (
        <BannerSection
          SectionWhite
          radius="5px"
          // padd={"24px 36px 32px 40px"}
          // paddMd={"16px 16px 21px 16px"}
          mt="16px"
          mtMd="12px"
        >
          <BannerHeading size="16px" margin="20px 0px">
            FAQ
          </BannerHeading>
          <BannerBox display="flex" direction="column" gap="8px">
            {Faqs?.length > 0 &&
              Faqs.map((value, index) => {
                return (
                  <>
                    <AnchorTag
                      BodyColorHover
                      BodyColor
                      href={value?.link && value?.link}
                      target="_blank"
                      rel="noreferrer"
                      BorderBottomTradingComp={Faqs.length - 1 !== index}
                      padding="8px 0px"
                    >
                      <BannerHeadingSpan weight="500" size="14px" sizeLg="14px" sizeMd="12px">
                        {" "}
                        {value?.title}
                      </BannerHeadingSpan>
                    </AnchorTag>
                  </>
                );
              })}
          </BannerBox>
        </BannerSection>
      )} */}
    </BannerSection>
  );
}

export default ProfileFaqSection;
