import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../constants/Images/imageIndex";
import HalfFooter from "./halfFooter";
import { FooterPart } from "../../assets/StyleComponents/Fotter.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { AccordianCard } from "../../assets/StyleComponents/AccordionCard.style";
import { AnchorTag, ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { PlusIcon } from "../../assets/svgs";
import { ImgElement, RouterLink } from "../Reusable";
import { useSelector } from "react-redux";
// import { FigureComponent } from "../../assets/StyleComponents/Figure.style";

function MobilePartFooter(props) {
  let isTheme = useSelector((state) => state.theme.isTheme);
  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);

  return (
    <FooterPart FooterWhite>
      <BannerBox
        ExchangeFooterGrey={isTheme && false}
        bg={!isTheme && "#e6f1fc"}
        paddingMd="1rem 0.8rem"
        paddingLg="1.5rem 2rem"
        mb="0px"
      >
        <BannerBox ExchangeContainer>
          <BannerBox>
            <BannerBox>
              <BannerBox display="flex" mb="20px" direction="column">
                <BannerBox display="flex" direction="column" width="100%">
                  <ImgElement
                    src={!isTheme ? IMAGE_PREFIX.logo_grey : IMAGE_PREFIX.logo_white}
                    alt="LCX"
                    width="125px"
                    marginLeft="-6px"
                  />
                  <BannerHeading BodyColor weight="300" size="14px" mt="20px">
                    <BannerHeadingSpan weight="500">
                      {" "}
                      The Liechtenstein Cryptoassets Exchange -{" "}
                    </BannerHeadingSpan>{" "}
                    <br /> A Blockchain Ecosystem For The New <br /> Financial World.
                  </BannerHeading>
                </BannerBox>
                <BannerBox width="100%">
                  <BannerBox display="flex" direction="column" width="100%" mt="24px">
                    <BannerHeading size="16px" BodyColor weight="500">
                      <b> Contact </b>
                    </BannerHeading>

                    <BannerBox
                      display="flex"
                      flexWrap="flex-fill"
                      mb="20px"
                      mt="12px"
                      alignItems="center"
                    >
                      <BannerHeadingSpan size="16px">
                        <ImgElement
                          src={!isTheme ? IMAGE_PREFIX.MailIcon : IMAGE_PREFIX_DARK.MailIcon}
                          alt=""
                          width="14px"
                          height="auto"
                        />
                      </BannerHeadingSpan>
                      <ParaTag ml="12px" mb="0px" textGrey size="14px">
                        <AnchorTag href="mailto:hello@lcx.com" size="14px" textGrey>
                          hello@lcx.com
                        </AnchorTag>
                      </ParaTag>
                    </BannerBox>

                    <BannerBox display="flex" flexWrap="flex-fill" mb="20px" mt="12px">
                      <BannerHeadingSpan size="18px">
                        <ImgElement
                          src={!isTheme ? IMAGE_PREFIX.MapPinIcon : IMAGE_PREFIX_DARK.MapPinIcon}
                          alt=""
                          width="14px"
                          height="auto"
                        />
                      </BannerHeadingSpan>
                      <ParaTag ml="12px" size="14px" textGrey>
                        LCX AG Herrengasse 6 9490 Vaduz <br />
                        Liechtenstein
                      </ParaTag>
                    </BannerBox>
                  </BannerBox>
                </BannerBox>

                <BannerBox width="100%" margin="25px 0px">
                  <Accordion defaultActiveKey="" className="accordionFooter">
                    <AccordianCard NoBackground>
                      <BannerBox
                        display="flex"
                        justifyContent="space-between"
                        onClick={() => setCollapse1(!collapse1)}
                        height="50px"
                        alignItems="center"
                      >
                        <BannerHeading BodyColor size="20px" weight="bold" sizeMd="14px">
                          {" "}
                          Content{" "}
                        </BannerHeading>
                        <PlusIcon />
                      </BannerBox>
                      {collapse1 && (
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <ParaTag size="14px" txtGrey mb="10px" mt="15px">
                              <AnchorTag
                                href="https://www.lcx.com/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Home
                              </AnchorTag>
                            </ParaTag>

                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/about/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                About
                              </AnchorTag>
                            </ParaTag>

                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/partners/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Partner
                              </AnchorTag>
                            </ParaTag>

                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/smart-order/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Smart Order
                              </AnchorTag>
                            </ParaTag>

                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/insights/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Insights
                              </AnchorTag>
                            </ParaTag>

                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/careers/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Careers
                              </AnchorTag>
                            </ParaTag>

                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/cryptocurrency-reference-price-services/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Cryptocurrency Reference Price
                              </AnchorTag>
                            </ParaTag>
                          </Card.Body>
                        </Accordion.Collapse>
                      )}
                    </AccordianCard>

                    <AccordianCard NoBackground>
                      <BannerBox
                        display="flex"
                        justifyContent="space-between"
                        onClick={() => setCollapse2(!collapse2)}
                        height="50px"
                        alignItems="center"
                      >
                        <BannerHeading BodyColor size="20px" weight="bold" sizeMd="14px">
                          {" "}
                          Resources{" "}
                        </BannerHeading>
                        <PlusIcon />
                      </BannerBox>
                      {collapse2 && (
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <RouterLink
                                primary
                                to="/lcx-token"
                                cursor="pointer"
                                textDecoration="none"
                              >
                                LCX Token
                              </RouterLink>
                            </ParaTag>
                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/documents/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Documents
                              </AnchorTag>
                            </ParaTag>
                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/brand-and-trademarks/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Brand and Trademarks
                              </AnchorTag>
                            </ParaTag>
                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/info/faq/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                FAQ & Support
                              </AnchorTag>
                            </ParaTag>
                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href={"https://docs.lcx.com/"}
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                API Docs
                              </AnchorTag>
                            </ParaTag>
                          </Card.Body>
                        </Accordion.Collapse>
                      )}
                    </AccordianCard>

                    <AccordianCard NoBackground>
                      <BannerBox
                        display="flex"
                        justifyContent="space-between"
                        onClick={() => setCollapse3(!collapse3)}
                        height="50px"
                        alignItems="center"
                      >
                        <BannerHeading BodyColor size="20px" weight="bold" sizeMd="14px">
                          {" "}
                          Legal{" "}
                        </BannerHeading>
                        <PlusIcon />
                      </BannerBox>
                      {collapse3 && (
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/privacy-policy/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Privacy Policy
                              </AnchorTag>
                            </ParaTag>

                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/terms-of-service/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Terms of Service
                              </AnchorTag>
                            </ParaTag>

                            <ParaTag size="14px" txtGrey mb="10px" mt="10px">
                              <AnchorTag
                                href="https://www.lcx.com/imprint/"
                                target="blank"
                                cursor="pointer"
                                textDecoration="none"
                                textGrey
                              >
                                Imprint
                              </AnchorTag>
                            </ParaTag>
                          </Card.Body>
                        </Accordion.Collapse>
                      )}
                    </AccordianCard>
                  </Accordion>
                </BannerBox>
                <HalfFooter {...props} />
              </BannerBox>
            </BannerBox>
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </FooterPart>
  );
}

export default MobilePartFooter;
