/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
} from "../../../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../../../assets/StyleComponents/FontSize/headingFourth.style";
import {
  ExpandLessIcon,
  ExpandMoreIcon,
  ProgressSuccessIcon,
  ProgressSuccessGreyIcon,
  BarChartKycIcon,
  KYCRejectedIcon,
  IdVerificatiionIcon,
  SelfieVerificationIcon,
  ResidenceProofIcon,
  LockIcon,
  NotCompleteIcon,
  PendingScreenIcon,
  ResidenceProofWhiteIcon
} from "../../../../assets/svgs";
import { LiTag, ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { useEffect } from "react";
import { HeadingSix } from "../../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  currentSubmissionList,
  getAcceptedDocument,
  startVerificationPlus
} from "../../../../lib/api";
// import { showSuccessMsg } from "../../../../utils/notification";

function Verificationtab({ userData }) {
  const history = useHistory();
  const { width } = useWindowSize();
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [verifyDivheight, setVerifydivHeight] = useState(0);
  const [verifyDivheight2, setVerifydivHeight2] = useState(0);
  const [rejectionsToggle, setRejectionsToggle] = useState(true);
  const [rejectionsTogglePlus, setRejectionsTogglePlus] = useState(true);
  // const [loader, setLoader] = useState(true);
  const [allOptionsId, setAllOptionsId] = useState([]);
  const verificationRef = useRef(null);
  const verificationRef2 = useRef(null);
  const VerticalLine1 = parseInt(Number(verifyDivheight) - 32);
  // eslint-disable-next-line no-unused-vars
  const VerticalLine2 = parseInt(Number(verifyDivheight2) - 25);
  const isTheme = useSelector((state) => state.theme.isTheme);
  const [verfication, setVerification] = useState("uncomplete");
  const [verficationPlus, setVerificationPlus] = useState("uncomplete");
  const [verficationPlusStatus, setVerificationPlusStatus] = useState([]);
  // All Status Plays
  const Status = userData?.KYC?.STATUS;
  const StatusLVL2 = userData?.KYC?.LEVEL2?.status;
  const StepsLVL2Helper = userData?.KYC?.LEVEL2;
  const LVL3Status = userData?.KYC?.transactionRiskInfo?.status;
  // const StatusOnHold = userData?.KYC?.onHold;
  const AddressProof = userData?.addressProofType;
  const isFromHightRiskCountry = userData?.KYC?.isHighRiskCountry;
  // const StatusIsOld = userData?.KYC?.isOLD;
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (LVL3Status === "REJECTED" || LVL3Status === "PARTIALLY_REJECTED") {
      currentSubmissionList()
        .then((res) => {
          setVerificationPlusStatus(
            res?.data?.data?.transactionRisk?.submittedInfo?.action?.partialRejectionReason
          );
        })
        .catch(() => {
          throw new Error();
        });
    }
  }, []);

  function FinalNaming(name) {
    switch (name) {
      case "PASSPORT":
        return "Passport";
      case "AADHAAR_CARD":
        return "Aadhaar Card";
      case "VOTER_ID_CARD":
        return "Voter ID Card";
      case "DRIVING_LICENSE":
        return "Driving Licence";
      case "NATIONALID":
        return "National ID";
      case "NATIONAL ID":
        return "National ID";
      case "RESIDENCE_PERMIT":
        return "Residence Permit";
      case "RESIDENCE PERMIT":
        return "Residence Permit";
      default:
        return name;
    }
  }
  function FinalAddress(name) {
    switch (name) {
      case "bankStatement":
        return "Bank Statement";
      case "electricityBill":
        return "Electricity Bill";
      case "cableBill":
        return "Cable Bill";
      case "otherUtilityBill":
        return "Other Utility Bill";
      default:
        return name;
    }
  }

  const CommentsObject = [
    {
      title: FinalNaming(userData?.idType),
      comments: StepsLVL2Helper?.step2?.comment,
      status: StepsLVL2Helper?.step2?.status === "REJECTED"
    },
    {
      title: "Liveliness Check",
      comments: StepsLVL2Helper?.step3?.comment,
      status: StepsLVL2Helper?.step3?.status === "REJECTED"
    },
    {
      title: FinalAddress(AddressProof),
      comments: StepsLVL2Helper?.step4?.comment,
      status: StepsLVL2Helper?.step4?.status === "REJECTED"
    }
  ];

  const CommentsObjectPlus = [
    {
      title: verficationPlusStatus[0]?.type === "sof" ? "Source of Funds" : "Source of Wealth",
      comments: verficationPlusStatus[0]?.key[0]?.comment,
      status: verficationPlusStatus[0] ? true : false
    }
  ];

  function PredictColorBasedOnStatus(status) {
    switch (status) {
      case "success":
        return isTheme ? "#68BF60" : "#008000";
      case "rejected":
        return isTheme ? "#E66767" : "#E65252";
      case "inprocess":
        return isTheme ? "#FCBE37" : "#FFB518";
      case "uncomplete":
        return isTheme ? "#969799" : "#8C9797";
      default:
        return isTheme ? "#969799" : "#8C9797";
    }
  }

  useEffect(() => {
    getAcceptedDocument()
      .then((res) => {
        // setLoader(true);
        setAllOptionsId(res.data.data);
        // setAllOptionsId([]);
        // setLoader(false);
      })
      .catch(() => {
        // setLoader(false);
        return;
      });
  }, []);

  useEffect(() => {
    if (
      verfication === "success" &&
      (verficationPlus === "inprocess" || verficationPlus === "rejected")
    ) {
      setToggle2(true);
    }
  }, []);

  useEffect(() => {
    if (Status === "LEVEL0" && !StatusLVL2) {
      setVerification("uncomplete");
      setVerificationPlus("locked");
      setToggle1(true);
      setToggle2(false);
    } else if (Status === "LEVEL1" && !StatusLVL2) {
      setVerification("uncomplete");
      setVerificationPlus("locked");
      setToggle1(true);
      setToggle2(false);
    } else if (Status === "LEVEL1" && StatusLVL2 === "SUBMITTED" && !isFromHightRiskCountry) {
      setVerification("inprocess");
      setVerificationPlus("locked");
      setToggle1(true);
      setToggle2(false);
    } else if (Status === "LEVEL1" && StatusLVL2 === "SUBMITTED" && isFromHightRiskCountry) {
      setVerification("inprocess");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (Status === "LEVEL0" && StatusLVL2 === "SUBMITTED" && !isFromHightRiskCountry) {
      setVerification("inprocess");
      setVerificationPlus("locked");
      setToggle1(true);
      setToggle2(false);
    } else if (Status === "LEVEL0" && StatusLVL2 === "SUBMITTED" && isFromHightRiskCountry) {
      setVerification("inprocess");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "PARTIALLY_SUBMITTED" &&
      isFromHightRiskCountry &&
      (LVL3Status === "REQUESTED" || LVL3Status === "NOT_REQUIRED")
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("uncomplete");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "PARTIALLY_SUBMITTED" &&
      isFromHightRiskCountry &&
      (LVL3Status === "REQUESTED" || LVL3Status === "NOT_REQUIRED")
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("uncomplete");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "PARTIALLY_SUBMITTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "SUBMITTED"
    ) {
      setVerification("inprocess");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "REJECTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "RESUBMITTED"
    ) {
      setVerification("rejected");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "REJECTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "RESUBMITTED"
    ) {
      setVerification("rejected");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "PARTIALLY_SUBMITTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "SUBMITTED"
    ) {
      setVerification("inprocess");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (Status === "LEVEL1" && StatusLVL2 === "REJECTED" && !isFromHightRiskCountry) {
      setVerification("rejected");
      setVerificationPlus("locked");
      setToggle1(true);
      setToggle2(false);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "REJECTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "SUBMITTED"
    ) {
      setVerification("rejected");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "APPROVED"
    ) {
      setVerification("success");
      setVerificationPlus("success");
      setToggle1(false);
      setToggle2(false);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "APPROVED"
    ) {
      setVerification("success");
      setVerificationPlus("success");
      setToggle1(false);
      setToggle2(false);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "REJECTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "REJECTED"
    ) {
      setVerification("rejected");
      setVerificationPlus("rejected");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "REJECTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "REJECTED"
    ) {
      setVerification("rejected");
      setVerificationPlus("rejected");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "SUBMITTED"
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "SUBMITTED"
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "REJECTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "APPROVED"
    ) {
      setVerification("rejected");
      setVerificationPlus("success");
      setToggle1(true);
      setToggle2(false);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "REJECTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "APPROVED"
    ) {
      setVerification("rejected");
      setVerificationPlus("success");
      setToggle1(true);
      setToggle2(false);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "REQUESTED"
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("uncomplete");
      setToggle1(true);
      setToggle2(false);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "REQUESTED"
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("uncomplete");
      setToggle1(true);
      setToggle2(false);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "RESUBMITTED"
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "RESUBMITTED"
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "REJECTED"
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("rejected");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "REJECTED"
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("rejected");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "RESUBMITTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "RESUBMITTED"
    ) {
      setVerification("inprocess");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "RESUBMITTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "RESUBMITTED"
    ) {
      setVerification("inprocess");
      setVerificationPlus("inprocess");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "PARTIALLY_REJECTED"
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("rejected");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "APPROVED" &&
      isFromHightRiskCountry &&
      LVL3Status === "PARTIALLY_REJECTED"
    ) {
      setVerification("postHightcomplete");
      setVerificationPlus("rejected");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL0" &&
      StatusLVL2 === "REJECTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "PARTIALLY_REJECTED"
    ) {
      setVerification("rejected");
      setVerificationPlus("rejected");
      setToggle1(true);
      setToggle2(true);
    } else if (
      Status === "LEVEL1" &&
      StatusLVL2 === "REJECTED" &&
      isFromHightRiskCountry &&
      LVL3Status === "PARTIALLY_REJECTED"
    ) {
      setVerification("rejected");
      setVerificationPlus("rejected");
      setToggle1(true);
      setToggle2(true);
    } else if (Status === "LEVEL0" && StatusLVL2 === "REJECTED") {
      setVerification("rejected");
      setVerificationPlus("locked");
      setToggle1(true);
      setToggle2(false);
    } else if (Status === "LEVEL2" && LVL3Status === "NOT_REQUIRED") {
      setVerification("success");
      setVerificationPlus("uncomplete");
      setToggle1(false);
      setToggle2(true);
    } else if (Status === "LEVEL2" && LVL3Status === "REQUESTED") {
      setVerification("success");
      setVerificationPlus("uncomplete");
      setToggle1(false);
      setToggle2(true);
    } else if (Status === "LEVEL2" && LVL3Status === "SUBMITTED") {
      setVerification("success");
      setVerificationPlus("inprocess");
      setToggle1(false);
      setToggle2(true);
    } else if (Status === "LEVEL2" && LVL3Status === "REJECTED") {
      setVerification("success");
      setVerificationPlus("rejected");
      setToggle1(false);
      setToggle2(true);
    } else if (Status === "LEVEL2" && LVL3Status === "APPROVED") {
      setVerification("success");
      setVerificationPlus("success");
      setToggle1(false);
      setToggle2(false);
    } else if (Status === "LEVEL2" && LVL3Status === "PARTIALLY_REJECTED") {
      setVerification("success");
      setVerificationPlus("rejected");
      setToggle1(false);
      setToggle2(true);
    } else if (Status === "LEVEL2" && LVL3Status === "RESUBMITTED") {
      setVerification("success");
      setVerificationPlus("inprocess");
      setToggle1(false);
      setToggle2(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Status]);

  useEffect(() => {
    setVerifydivHeight(verificationRef.current?.offsetHeight);
  }, [toggle1, rejectionsToggle]);

  useEffect(() => {
    setVerifydivHeight2(verificationRef2?.current?.offsetHeight);
  }, [toggle2, rejectionsTogglePlus]);

  const VerificationBenifits = [
    "Full access to all the features of Trade, Invest & Earn",
    "Unlimited Withdrawal & Trading",
    "Deposits upto 100K CHF"
  ];

  const VerificationSteps = [
    {
      titleHeader: "ID Verification",
      type: [
        {
          failedStatus:
            StepsLVL2Helper?.step2?.status === "REJECTED" &&
            (userData.idType === "NATIONALID" || userData.idType === "NATIONAL ID"),
          title: "National ID",
          show: allOptionsId.includes("NATIONALID")
        },
        {
          failedStatus:
            StepsLVL2Helper?.step2?.status === "REJECTED" && userData.idType === "PASSPORT",
          title: "Passport",
          show: allOptionsId.includes("PASSPORT")
        },
        {
          failedStatus:
            StepsLVL2Helper?.step2?.status === "REJECTED" &&
            (userData.idType === "DRIVING_LICENSE" || userData.idType === "DRIVING LICENCE"),
          title: "Driving Licence",
          show: allOptionsId.includes("DRIVING_LICENSE")
        },
        {
          failedStatus:
            StepsLVL2Helper?.step2?.status === "REJECTED" &&
            (userData.idType === "RESIDENCE_PERMIT" || userData.idType === "RESIDENCE PERMIT"),
          title: "Residence Permit",
          show: allOptionsId.includes("RESIDENCE_PERMIT")
        }
      ],
      icon: <IdVerificatiionIcon />
    },
    {
      titleHeader: "Selfie Verification",
      type: [
        {
          failedStatus: StepsLVL2Helper?.step3?.status === "REJECTED",
          title: "Liveliness Check"
        }
      ],
      icon: <SelfieVerificationIcon />
    },
    {
      titleHeader: "Residence Proof",
      type: [
        {
          failedStatus:
            StepsLVL2Helper?.step4?.status === "REJECTED" &&
            (AddressProof === "Bank Statement" || AddressProof === "bankStatement"),
          title: "Bank Statement"
        },
        {
          title: "Electricity Bill",
          failedStatus:
            StepsLVL2Helper?.step4?.status === "REJECTED" &&
            (AddressProof === "Electricity Bill" || AddressProof === "electricityBill")
        },
        {
          failedStatus:
            StepsLVL2Helper?.step4?.status === "REJECTED" &&
            (AddressProof === "Cable Bill" || AddressProof === "cableBill"),
          title: "Cable Bill"
        },
        {
          failedStatus:
            StepsLVL2Helper?.step4?.status === "REJECTED" &&
            (AddressProof === "Other Utility Bill" || AddressProof === "otherUtilityBill"),
          title: "Other Utility Bill"
        }
      ],
      icon: isTheme ? <ResidenceProofWhiteIcon /> : <ResidenceProofIcon />
    }
  ];

  const handleVerification = () => {
    const data = {
      start: true
    };
    startVerificationPlus(data)
      .then((res) => {})
      .catch(() => {
        throw new Error();
      });
    history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/verify/verification-plus`);
  };

  return (
    <BannerSection SectionWhite radius="5px" padding="24px">
      {/* Verfication screen */}
      <BannerBox
        display="flex"
        alignItems="start"
        gap="6px"
        cursorPointer={verfication === "success" && "pointer"}
        onClick={() => verfication === "success" && setToggle1(!toggle1)}
      >
        {width > 767 && (
          <BannerBox width="6%" display="flex" direction="column" alignItems="center">
            {verfication === "success" && <ProgressSuccessIcon />}
            {verfication === "inprocess" && <PendingScreenIcon />}
            {verfication === "rejected" && <KYCRejectedIcon />}
            {verfication === "uncomplete" && <NotCompleteIcon width="20px" height="20px" />}
            {verfication === "postHightcomplete" && <NotCompleteIcon width="20px" height="20px" />}
            <hr
              style={{
                height: VerticalLine1,
                width: "0",
                borderLeft: `0.5px solid ${PredictColorBasedOnStatus(verfication)}`,
                borderTop: "0px",
                borderBottom: "0px",
                borderRight: "0px",
                marginTop: "10"
              }}
            />
          </BannerBox>
        )}
        <BannerBox
          display="flex"
          direction="column"
          gap="8px"
          width="94%"
          bottomBorder="0.5px solid"
          pb="10px"
          borderColor
          widthMd="100%"
          ref={verificationRef}
        >
          <BannerBox display="flex" justifyContent="space-between" alignItems="center">
            <HeadingFourth
              size="18px"
              weight="500"
              BodyColor={verfication === "uncomplete" || verfication === "postHightcomplete"}
              color={verfication === "inprocess" && "#FFAE00"}
              txtDanger={verfication === "rejected"}
              txtSuccess={verfication === "success"}
              display="flex"
              alignItems="center"
              gap="6px"
              sizeMd="16px"
            >
              {width < 767 && verfication === "success" && (
                <ProgressSuccessIcon width="20px" height="20px" />
              )}
              {width < 767 && verfication === "inprocess" && (
                <PendingScreenIcon width="20px" height="20px" />
              )}
              {width < 767 && verfication === "rejected" && (
                <KYCRejectedIcon width="20px" height="20px" />
              )}
              {width < 767 && verfication === "uncomplete" && (
                <NotCompleteIcon width="20px" height="20px" />
              )}
              {width < 767 && verfication === "postHightcomplete" && (
                <NotCompleteIcon width="20px" height="20px" />
              )}
              Verification
            </HeadingFourth>
            {verfication === "success" && (
              <>
                <BannerHeadingSpan>
                  {!toggle1 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </BannerHeadingSpan>
              </>
            )}
          </BannerBox>
          <ParaTag
            weight="400"
            size="14px"
            txtGrey={verfication === "success" || verfication === "uncomplete"}
            color={verfication === "inprocess" && "#FFAE00"}
            txtDanger={verfication === "rejected"}
          >
            {verfication === "uncomplete" &&
              "Unlock all the benefits of LCX with lightning-fast verification. Start your trading journey with a regulated crypto exchange."}
            {verfication === "postHightcomplete" &&
              "Unlock all the benefits of LCX with lightning-fast verification. Start your trading journey with a regulated crypto exchange."}
            {verfication === "success" &&
              "Upon successful verification, you are now eligible to buy, sell and trade crypto on a regulated exchange."}
            {verfication === "inprocess" &&
              "(Your account is currently under review. We anticipate this will take approximately 24-48 working hours)"}
            {verfication === "rejected" && `Your verification wasn't successful, Let's try again!`}
          </ParaTag>

          {/* Hidden Toggle Content */}
          {toggle1 && (
            <>
              <BannerBox>
                {(!isFromHightRiskCountry ||
                  (verfication === "success" && isFromHightRiskCountry)) && (
                  <BannerBox bottomBorder={verfication !== "success"} pb="20px" mt="20px">
                    <BannerHeading size="16px" weight="500" mb="12px" BodyColor>
                      Benefits
                    </BannerHeading>
                    {VerificationBenifits.map((values) => {
                      return (
                        <>
                          <ParaTag
                            size="14px"
                            weight="500"
                            display="flex"
                            alignItems="center"
                            alignItemsMd="start"
                            txtGrey
                            mt="8px"
                            mb="8px"
                            subText
                          >
                            <ProgressSuccessGreyIcon width="15px" height="15px" marginRight="7px" />
                            {values}
                          </ParaTag>
                        </>
                      );
                    })}
                  </BannerBox>
                )}
                {verfication !== "success" && (
                  <>
                    <BannerBox pb="20px" mt="20px">
                      <BannerHeading size="16px" weight="500" BodyColor mb="12px">
                        Requirements
                      </BannerHeading>
                      <ParaTag size="14px" weight="400" display="flex" alignItems="center" txtGrey>
                        (When making selections, kindly ensure to choose one option from each
                        category provided)
                      </ParaTag>
                    </BannerBox>
                    <BannerBox display="grid" gridCols="repeat(3,1fr)" gridColsMd="1fr">
                      {VerificationSteps.map((data, index) => {
                        return (
                          <>
                            <BannerBox pbMd="8px" ptMd="8px">
                              <HeadingSix
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                txtGrey
                                size="14px"
                                weight="500"
                              >
                                {data.icon}
                                {data.titleHeader}
                              </HeadingSix>
                              <ul
                                style={{
                                  paddingLeft: "0px",
                                  listStyle: "none",
                                  display: width < 767 && "flex",
                                  flexWrap: width < 767 && "wrap",
                                  gap: width < 767 && "10px"
                                }}
                              >
                                {data.type.map((values, index) => {
                                  return (
                                    <LiTag
                                      key={values.title}
                                      mt="6px"
                                      mb="6px"
                                      txtGrey
                                      size="14px"
                                      weight="400"
                                      rightBorder={
                                        width < 767 && data.type.length - 1 !== index ? true : false
                                      }
                                      paddRightMd="10px"
                                      txtDanger={values.failedStatus}
                                      display={
                                        !values.show &&
                                        data.titleHeader === "ID Verification" &&
                                        "none"
                                      }
                                    >
                                      {values.title}
                                    </LiTag>
                                  );
                                })}
                              </ul>
                            </BannerBox>
                          </>
                        );
                      })}
                    </BannerBox>
                    {/* Rejection Toggle */}
                    {verfication === "rejected" && (
                      <BannerBox mt="10px" onClick={() => setRejectionsToggle(!rejectionsToggle)}>
                        <BannerBox
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          gap="16px"
                        >
                          <HeadingSix txtDanger size="14px" weight="500">
                            Rejection reasons:
                          </HeadingSix>
                          <BannerBox topBorder width="67%" widthMd="35%"></BannerBox>
                          <BannerHeadingSpan>
                            {rejectionsToggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </BannerHeadingSpan>
                        </BannerBox>

                        {rejectionsToggle && (
                          <BannerBox
                            mt="24px"
                            BgSecondaydanger
                            padding="8px 12px"
                            BorderRadius="8px"
                            mb="24px"
                          >
                            {CommentsObject.filter((vals) => vals.status).map((data) => {
                              return (
                                <>
                                  <HeadingSix txtDanger size="14px" weight="500">
                                    {FinalNaming(data.title)}
                                  </HeadingSix>
                                  <ul style={{ paddingLeft: "20px" }}>
                                    {data.comments.map((values) => {
                                      return (
                                        <>
                                          <LiTag
                                            size="14px"
                                            weight="400"
                                            BodyColor
                                            margin="10px 0px"
                                          >
                                            {values}
                                          </LiTag>
                                        </>
                                      );
                                    })}
                                  </ul>
                                </>
                              );
                            })}
                          </BannerBox>
                        )}
                      </BannerBox>
                    )}
                    {StatusLVL2 !== "PARTIALLY_SUBMITTED" && (
                      <ButtonComponent
                        width={verfication === "inprocess" ? "200px" : "185px"}
                        widthMd="100%"
                        mt="12px"
                        mb="24px"
                        display={
                          isFromHightRiskCountry &&
                          (StatusLVL2 === "SUBMITTED" || verfication === "postHightcomplete") &&
                          "none"
                        }
                        disabled={verfication === "inprocess"}
                        DisabledBTN={verfication === "inprocess"}
                        PrimaryButton={verfication === "success" || verfication === "rejected"}
                        onClick={() => history.push(`${prevLang ? `/${prevLang}` :userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/verify/kyc`)}
                      >
                        {verfication === "uncomplete" &&
                          Status === "LEVEL0" &&
                          "Start Verification"}
                        {verfication === "uncomplete" &&
                          Status === "LEVEL1" &&
                          "Resume Verification"}
                        {verfication === "rejected" && "Re-Submit Documents"}
                        {verfication === "inprocess" && "Verification In - Process"}
                      </ButtonComponent>
                    )}
                  </>
                )}
              </BannerBox>
            </>
          )}
        </BannerBox>
      </BannerBox>

      {/* VerIfiCation Plus++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++= */}
      <BannerBox
        display="flex"
        pb="10px"
        mt="40px"
        alignItems="start"
        gap="6px"
        cursorPointer={(verficationPlus === "success" || verficationPlus === "locked") && "pointer"}
        onClick={() => {
          if (verficationPlus === "success" || verficationPlus === "locked") {
            setToggle2(!toggle2);
          }
        }}
      >
        {width > 767 && (
          <BannerBox width="6%" display="flex" direction="column" alignItems="center">
            {verficationPlus === "success" && <ProgressSuccessIcon />}
            {verficationPlus === "inprocess" && <PendingScreenIcon />}
            {verficationPlus === "rejected" && <KYCRejectedIcon />}
            {verficationPlus === "locked" && <LockIcon />}
            {verficationPlus === "uncomplete" && <NotCompleteIcon width="20px" height="20px" />}
            <hr
              style={{
                height: VerticalLine2,
                width: "0",
                borderLeft: `0.5px solid ${PredictColorBasedOnStatus(verficationPlus)}`,
                borderTop: "0px",
                borderBottom: "0px",
                borderRight: "0px",
                marginTop: "10"
              }}
            />
          </BannerBox>
        )}
        <BannerBox
          display="flex"
          direction="column"
          gap="8px"
          width="94%"
          widthMd="100%"
          ref={verificationRef2}
        >
          <BannerBox display="flex" justifyContent="space-between" alignItems="center">
            <HeadingFourth
              size="18px"
              weight="500"
              BodyColor={verficationPlus === "uncomplete"}
              color={verficationPlus === "inprocess" && "#FFAE00"}
              txtDanger={verficationPlus === "rejected"}
              txtSuccess={verficationPlus === "success"}
              display="flex"
              alignItems="center"
              gap="6px"
              sizeMd="16px"
            >
              {width < 767 && verficationPlus === "success" && (
                <ProgressSuccessIcon width="20px" height="20px" />
              )}
              {width < 767 && verficationPlus === "inprocess" && (
                <PendingScreenIcon width="20px" height="20px" />
              )}
              {width < 767 && verficationPlus === "rejected" && (
                <KYCRejectedIcon width="20px" height="20px" />
              )}
              {width < 767 && verficationPlus === "locked" && (
                <LockIcon width="20px" height="20px" />
              )}
              {width < 767 && verficationPlus === "uncomplete" && (
                <NotCompleteIcon width="20px" height="20px" />
              )}
              Verification Plus
            </HeadingFourth>
            {verficationPlus === "success" && (
              <BannerHeadingSpan>
                {toggle2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </BannerHeadingSpan>
            )}
            {verficationPlus === "locked" && (
              <BannerHeadingSpan>
                {toggle2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </BannerHeadingSpan>
            )}
          </BannerBox>
          <ParaTag
            weight="400"
            size="14px"
            txtGrey={
              verficationPlus === "success" ||
              verficationPlus === "uncomplete" ||
              verficationPlus === "locked"
            }
            color={verficationPlus === "inprocess" && "#FFAE00"}
            txtDanger={verficationPlus === "rejected"}
          >
            {verficationPlus === "success" &&
              "Upon successful verification, you are now eligible to buy, sell and trade crypto on a regulated exchange with exclusive benefits."}
            {verficationPlus === "uncomplete" &&
              "Upgrade your verification status to access additional and exclusive benefits on the LCX platform."}
            {verficationPlus === "rejected" &&
              `Your verification wasn't successful, Let's try again!`}
            {verficationPlus === "locked" &&
              `Upgrade your verification status to access additional and exclusive benefits on the
              LCX platform.`}
            {verficationPlus === "inprocess" &&
              `(Your account is currently under review. We anticipate this will take approximately
                24-48 working hours.)`}
          </ParaTag>

          {/* Hidden Toggle Content */}
          {toggle2 && (
            <>
              <BannerBox>
                <BannerBox bottomBorder={verficationPlus !== "success"} pb="20px" mt="20px">
                  <BannerHeading size="16px" weight="500" mb="12px" BodyColor>
                    Benefits
                  </BannerHeading>
                  <ParaTag
                    size="14px"
                    weight="500"
                    display="flex"
                    alignItems="center"
                    txtGrey
                    alignItemsMd="start"
                    mb="7px"
                  >
                    <ProgressSuccessGreyIcon width="15px" height="15px" marginRight="7px" />
                    Full access to all the features of Trade, Invest & Earn
                  </ParaTag>
                  <ParaTag
                    size="14px"
                    weight="500"
                    display="flex"
                    alignItems="center"
                    txtGrey
                    alignItemsMd="start"
                    mb="7px"
                  >
                    <ProgressSuccessGreyIcon width="15px" height="15px" marginRight="7px" />
                    Unlimited Withdrawal & Trading
                  </ParaTag>
                  <ParaTag
                    size="14px"
                    weight="500"
                    display="flex"
                    alignItems="center"
                    txtGrey
                    alignItemsMd="start"
                    mb="7px"
                  >
                    <ProgressSuccessGreyIcon width="15px" height="15px" marginRight="7px" />
                    Unlimited Deposits
                  </ParaTag>
                </BannerBox>
                {verficationPlus !== "success" && (
                  <>
                    <BannerBox pb="20px" mt="20px">
                      <BannerHeading size="16px" weight="500" BodyColor mb="12px">
                        Requirements
                      </BannerHeading>
                      <ParaTag size="14px" weight="500" display="flex" alignItems="center" txtGrey>
                        <BarChartKycIcon width="15px" height="15px" marginRight="7px" />
                        Source of Funds and Wealth Proof
                      </ParaTag>
                    </BannerBox>
                    <ParaTag size="14px" weight="400" txtGrey>
                      Please ensure you upload your{" "}
                      <BannerHeadingSpan weight="500">Source of Funds</BannerHeadingSpan> and{" "}
                      <BannerHeadingSpan weight="500">Wealth Proof</BannerHeadingSpan> document
                      accordingly
                    </ParaTag>

                    {/* Rejection Toggle */}
                    {verficationPlus === "rejected" && LVL3Status === "PARTIALLY_REJECTED" && (
                      <BannerBox
                        mt="10px"
                        onClick={() => setRejectionsTogglePlus(!rejectionsTogglePlus)}
                      >
                        <BannerBox
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          gap="16px"
                        >
                          <HeadingSix txtDanger size="14px" weight="500">
                            Rejection reasons:
                          </HeadingSix>
                          <BannerBox topBorder width="67%" widthMd="35%"></BannerBox>
                          <BannerHeadingSpan>
                            {rejectionsTogglePlus ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </BannerHeadingSpan>
                        </BannerBox>

                        {rejectionsTogglePlus && (
                          <BannerBox
                            mt="24px"
                            BgSecondaydanger
                            padding="8px 12px"
                            BorderRadius="8px"
                            mb="24px"
                          >
                            {CommentsObjectPlus?.map((data) => {
                              return (
                                <>
                                  <BannerBox key={data.title}>
                                    <HeadingSix txtDanger size="14px" weight="500">
                                      {data.title}
                                    </HeadingSix>
                                    <ul style={{ paddingLeft: "20px" }}>
                                      {data?.comments?.map((values, index) => {
                                        return (
                                          <>
                                            <LiTag
                                              size="14px"
                                              weight="400"
                                              BodyColor
                                              key={index}
                                              margin="10px 0px"
                                            >
                                              {values}
                                            </LiTag>
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </BannerBox>
                                </>
                              );
                            })}
                          </BannerBox>
                        )}
                      </BannerBox>
                    )}
                    {StatusLVL2 !== "PARTIALLY_SUBMITTED" && (
                      <ButtonComponent
                        width="230px"
                        widthMd="100%"
                        mt="24px"
                        sizeMd="16px"
                        weight="500"
                        onClick={() => {
                          handleVerification();
                        }}
                        disabled={verficationPlus === "inprocess" || verficationPlus === "locked"}
                        DisabledBTN={
                          verficationPlus === "inprocess" || verficationPlus === "locked"
                        }
                        PrimaryButton={
                          verficationPlus === "success" || verficationPlus === "rejected"
                        }
                      >
                        {verficationPlus === "uncomplete" && "Start Verification Plus"}
                        {verficationPlus === "locked" && "Verify Now"}
                        {verficationPlus === "rejected" && "Re-Submit Documents"}
                        {verficationPlus === "inprocess" && "Verification Plus In - Process"}
                      </ButtonComponent>
                    )}
                    {StatusLVL2 === "PARTIALLY_SUBMITTED" && (
                      <ButtonComponent
                        width="230px"
                        widthMd="100%"
                        mt="24px"
                        sizeMd="16px"
                        weight="500"
                        onClick={() => {
                          handleVerification();
                        }}
                        PrimaryButton
                      >
                        Resume Verification Plus
                      </ButtonComponent>
                    )}
                  </>
                )}
              </BannerBox>
            </>
          )}
        </BannerBox>
      </BannerBox>
    </BannerSection>
  );
}

export default Verificationtab;
