import { Fragment, useEffect, useState } from "react";
import { DivElement, TextWrapper, Button, LinkButton } from "../../../../../components/Reusable";
import AmountInput from "../../Components/AmountInput";
import ValidationMessage from "./ValidationMessage";
import { getBondAccess } from "../../../../../lib/api";
import { formatAPIErrors, numberWithCommas } from "../../../../../utils/general";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import { TAndC } from "../../Components";
import { EarnInvestLoader, Skeleton } from "../../../../../components/Loader";
import { showErrorMsg } from "../../../../../utils/notification";
import BigNumber from "bignumber.js";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../../../constants/styledConstants";
import InsufficientBalance from "../../../../../components/InsufficientBalance";
import { customDecode } from "../../../../../utils/envScript/envCrypto";

const Step1 = ({
  amount,
  setAmount,
  setStep,
  checked,
  setChecked,
  wallets,
  bondDetails,
  userData,
  isLoggedIn
}) => {
  const [isCountryAccess, setIsCountryAccess] = useState(true);
  const [isKycApproved, setIsKycApproved] = useState(true);
  const [data, setData] = useState("");
  const [bondCoinBalance, setBondCoinBalance] = useState("");
  const [feeBalance, setFeeBalance] = useState("");
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);
  const [requiredBalance, setRequiredBalance] = useState(0);
  const [requiredFee, setRequiredFee] = useState(0);
  const [showAddFunds, setShowAddFunds] = useState(false);
  const [coinWithLowBalance, setCoinWithLowBalance] = useState("");
  const [requiredFunds, setRequiredFunds] = useState("");
  const { width } = useWindowSize();
  const isDisabled = customDecode(process.env.REACT_APP_NODE_ENV) === "development" ? false : true;
  let prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (isLoggedIn) checkBondAccess();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (wallets) {
        wallets.data.forEach((item) => {
          if (item.coin === bondDetails.bondCoin) {
            setBondCoinBalance(item.balance.freeBalance);
          }
          if (item.coin === bondDetails.feeCoin) {
            setFeeBalance(item.balance.freeBalance);
          }
        });
      }
    } else {
      setBondCoinBalance(0);
      setFeeBalance(0);
    }

    // eslint-disable-next-line
  }, [wallets, bondDetails]);

  useEffect(() => {
    const reqBal = amount * bondDetails.denomination;
    const reqFee = +bondDetails.feeAmountRate * amount * bondDetails.denomination;

    setRequiredFee(reqFee);
    setRequiredBalance(reqBal);

    setData([
      {
        label: "INVESTMENT",
        value: `${numberWithCommas(
          amount
            ? BigNumber(amount).multipliedBy(bondDetails.denomination)
            : bondDetails
            ? bondDetails.denomination
            : 0
        )} ${bondDetails.bondCoin}`
      },
      {
        label: `Fee (1% in ${bondDetails.feeCoin})`,
        value:
          amount && bondDetails
            ? `${numberWithCommas(
                +(+bondDetails.feeAmountRate * amount * bondDetails.denomination).toFixed(8)
              )} ${bondDetails.feeCoin}`
            : `0 ${bondDetails.feeCoin}`
      },
      {
        label: `${bondDetails.bondName} Amount`,
        value: `${amount ? numberWithCommas(amount) : 0} ${bondDetails.bondName}`
      },
      { label: "Interest payout interval", value: bondDetails.payoutPeriod },
      {
        label: "First payout date",
        value: moment(bondDetails.firstPayout).format("DD/MM/YYYY, HH:mm")
      },
      {
        label: "First payout amount",
        value: `${numberWithCommas(
          +(bondDetails.firstPayoutAmount * amount * bondDetails.denomination).toFixed(8)
        )} ${bondDetails.bondCoin}`
      }
    ]);
  }, [bondDetails, amount]);

  const checkBondAccess = () => {
    setIsFetchingDetails(true);
    getBondAccess()
      .then((res) => {
        setIsCountryAccess(res && res.data?.isCountryAccess);
        setIsKycApproved(res && res.data?.isKycApproved);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setIsFetchingDetails(false);
      });
  };

  const onClickAddFunds = (type) => {
    if (type === "BALANCE" || (!type && bondCoinBalance < requiredBalance)) {
      setCoinWithLowBalance(bondDetails.bondCoin);
      setRequiredFunds(requiredBalance);
    } else {
      setCoinWithLowBalance(bondDetails.feeCoin);
      setRequiredFunds(requiredFee);
    }
    setShowAddFunds(true);
  };

  const onSubmitHandler = (type) => {
    if (isDisabled) return;

    if (type === "balance") {
      onClickAddFunds("");
    } else {
      setStep(2);
    }
  };

  const isLoading =
    (!bondCoinBalance && bondCoinBalance !== 0) ||
    (!feeBalance && feeBalance !== 0) ||
    !data ||
    isFetchingDetails ||
    !bondDetails;

  const sumTotal = requiredBalance + requiredFee;
  const disableBtnWhenFeeAndBondSame =
    bondDetails && amount
      ? bondDetails.bondCoin === bondDetails.feeCoin && sumTotal > bondCoinBalance
      : false;

  return (
    <Fragment>
      <DivElement
        padding="20px 24px 30px 24px"
        paddingMd="20px 16px 30px 16px"
        paddingSm="20px 8px 32px 8px"
      >
        {!isLoading && bondDetails ? (
          <Fragment>
            <DivElement padding="0 20px" paddingMd="0 14px" paddingSm="0 8px">
              <AmountInput
                amount={amount}
                setAmount={setAmount}
                name="investAmount"
                balance={+bondCoinBalance}
                feeBalance={+feeBalance}
                bondDetails={bondDetails}
              />
              {isLoggedIn && (
                <DivElement displayEnd paddingTop="10px">
                  <DivElement width="calc(100% - 67px)" widthSm="100%">
                    <ValidationMessage
                      amount={amount}
                      bondDetails={bondDetails}
                      isEnoughFee={feeBalance >= requiredFee}
                      isEnoughBalance={
                        bondDetails.bondCoin === bondDetails.feeCoin
                          ? bondCoinBalance >= requiredBalance + requiredFee
                          : bondCoinBalance >= requiredBalance
                      }
                      isKycApproved={isKycApproved}
                      isCountryAccess={isCountryAccess}
                      onClickAddFunds={onClickAddFunds}
                    />
                  </DivElement>
                </DivElement>
              )}
            </DivElement>

            <DivElement bgCream padding="20px" paddingSm="28px 12px" paddingMd="20px 0px">
              {data
                ? data.map((item, i) => (
                    <DivElement
                      bdGrey
                      key={i}
                      displayBtw
                      marginBottom={i === data.length - 1 ? "0" : "8px"}
                      borderBottom={i === 1 ? "1px dashed" : ""}
                      paddingBottom={i === 1 ? "8px" : ""}
                    >
                      <TextWrapper
                        secondary
                        fontSize="14px"
                        fontSizeSm="12px"
                        lineHeight="1.71"
                        lineHeightSm="2"
                      >
                        {item.label}
                      </TextWrapper>
                      <TextWrapper
                        fontSize="14px"
                        fontSizeSm="12px"
                        lineHeight="1.71"
                        lineHeightSm="2"
                      >
                        <var>{item.value}</var>
                      </TextWrapper>
                    </DivElement>
                  ))
                : [1, 2, 3, 4, 5, 6].map((i) => (
                    <DivElement key={i} displayBtw marginBottom={i === 6 ? "0" : "12px"}>
                      <Skeleton height="16px" width="200px" />
                      <Skeleton height="16px" width="200px" />
                    </DivElement>
                  ))}
            </DivElement>
            {isLoggedIn ? (
              <Fragment>
                <TAndC setChecked={setChecked} checked={checked} />
                <DivElement width="100%" padding="0 14px" paddingSm="0 8px">
                  {bondCoinBalance < requiredBalance ||
                  feeBalance < requiredFee ||
                  disableBtnWhenFeeAndBondSame ? (
                    <Button
                      height="48px"
                      width="100%"
                      secondaryBlue
                      disabled={isDisabled}
                      onClick={() => onSubmitHandler("balance")}
                    >{`Add ${
                      bondCoinBalance < requiredBalance ? bondDetails.bondCoin : bondDetails.feeCoin
                    }`}</Button>
                  ) : !isCountryAccess ||
                    !isKycApproved ||
                    userData.status === "RESTRICTED" ||
                    !amount ||
                    !checked ? (
                    <Button disabled height="48px" width="100%" primaryGrey>
                      Invest in {bondDetails.bondCoin}
                    </Button>
                  ) : (
                    <Button
                      height="48px"
                      width="100%"
                      primaryBlue
                      disabled={isDisabled}
                      onClick={onSubmitHandler}
                    >
                      Invest in {bondDetails.bondCoin}
                    </Button>
                  )}
                </DivElement>
              </Fragment>
            ) : (
              <DivElement
                absolute={width > styledConstants.BREAKING_POINT_TABLET ? true : null}
                width={width > styledConstants.BREAKING_POINT_TABLET ? "calc(100% - 48px)" : "100%"}
                bottom="49px"
                paddingMd="40px 8px 0 8px"
              >
                <LinkButton
                  to={`${prevLang ? `/${prevLang}` : ""}/login`}
                  width="100%"
                  height="48px"
                  secondaryBlue
                >
                  Log in or Register
                </LinkButton>
              </DivElement>
            )}
          </Fragment>
        ) : (
          <EarnInvestLoader />
        )}
      </DivElement>

      {showAddFunds && (
        <InsufficientBalance
          onClose={() => setShowAddFunds(false)}
          coin={coinWithLowBalance}
          requiredFunds={requiredFunds}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  wallets: state.wallet.wallets,
  userData: state.dashboard.getUserData,
  isLoggedIn: state.auth.isLoggedIn
});

export default compose(connect(mapStateToProps))(Step1);
