import { DivElement, TextWrapper } from "../../../Reusable";
import { SuccessIcon } from "../../../../assets/svgs";

const CheckList = ({ label, margin }) => {
  return (
    <DivElement flex margin={margin || null}>
      <SuccessIcon marginRight="12px" />

      <TextWrapper fontSize="14px">{label}</TextWrapper>
    </DivElement>
  );
};

export default CheckList;
