/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { compose } from "redux";
import { getImportantPoints } from "../../../../lib/api";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import SelectDropDownComponent, {
  CustomOptionsForCurrency,
  CustomOptionsForNetwork,
  DropdownIndicator,
  MyOptions
} from "../../../../components/SelectDropDown/selectDropDown";
import { WalletContext } from "../../../../contexts/WalletPage/BalancePage/wallet";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import {
  getCoinNetworks,
  getFiatDepositDetails,
  getBeneficiary,
  getFqa
} from "../../../../lib/api";
import { formatAPIErrors, numberWithCommas } from "../../../../utils/general";
import NonFiatAddressComponent from "../Web/nonFiatAddress";
import FiatAddressComponent from "../Web/faitAddress";
import FiatCardDeposit from "../../../../components/Wallets/Deposits/FiatCardDeposit";
import { reactDropdownStyles, selectProviderCustomOptions } from "../../../../utils";
import Select from "react-select";
import { showErrorMsg } from "../../../../utils/notification";
import RestrictedComponent from "./Modal/restrictedModal";
import {
  AddBeneficiary,
  // Enable2FA,
  GetKycVerified,
  SuspensionAlert
} from "../../../../components/Alert";
import { formatNumbers } from "../../../../utils/helpers";
import { Label } from "../../../../assets/StyleComponents/FontSize/para.style";
import WireTransfer from "./WireTransfer";

const formattedArrayNetwork = (array) => {
  return array.map((item) => {
    return {
      id: item,
      label: item.label,
      value: item.value
    };
  });
};

const formattedArrayProvider = (array) => {
  return array.map((item) => {
    return {
      ...item,
      id: item,
      label: item.label,
      value: item.value
    };
  });
};

const DepositFormComponent = ({ userData, isDarkTheme }) => {
  const [showModal, setShowModal] = useState(false);
  const [showRestrictedMode, setRestrictedMode] = useState(true);
  const [isCardPayment, setIsCardPayment] = useState(false);
  const [fiatAmount, setFiatAmount] = useState("");
  const [fiatDetails, setFiatDetails] = useState("");
  const history = useHistory();

  let kycStatus = useMemo(
    () => (userData && userData.KYC && userData.KYC.STATUS ? userData.KYC.STATUS : "LEVEL0"),
    [userData.KYC.STATUS]
  );

  let { exchWallet } = useContext(WalletContext);
  let {
    currencyList,
    setCurrencyList,
    setSelectedNetwork,
    setSelectedProvider,
    page,
    selectedNetwork,
    selectedCoin,
    selectedProvider,
    setProviderList,
    providerList,
    setNetworkList,
    networkList,
    callGetDeposits,
    coinChanged,
    beneficaryList,
    setBeneficaryList,
    setSelectedCoin,
    setMtc,
    setFqa,
    setIsFqaLoading,
    setImportantPoint,
    setLoadingImportantPoint,
    setPage,
    isCardSelected,
    setIsCardSelected,
    isTopModalActive
  } = useContext(DepositContext);

  // eslint-disable-next-line no-unused-vars
  let optionList = useMemo(() => setCurrencyList(exchWallet.data), [exchWallet]);

  useEffect(() => {
    return () => {
      setSelectedCoin("");
      setSelectedNetwork("");
      setSelectedProvider("");
      setIsCardSelected(false);
    };
  }, []);

  useEffect(() => {
    if (selectedCoin !== "" && selectedCoin.coinType === "fiat_token") {
      if (beneficaryList && beneficaryList.length === 0) {
        setShowModal(true);
      }
    }
  }, [beneficaryList]);

  useEffect(() => {
    if (selectedCoin !== "") {
      if (kycStatus === "LEVEL0" || kycStatus === "LEVEL1") {
        setShowModal(true);
      }
      if (history.location.state?.state) {
        let coinValue = history.location.state?.state?.getValue;
        if (coinValue.coinType === "fiat_token") {
          getFiatDeposit(coinValue);
        } else {
          getNetworks(coinValue);
        }
      }

      let param = {
        coin: selectedCoin?.coin || "",
        page: page
      };
      callGetDeposits(param);
    } else {
      if (showModal) {
        setShowModal(false);
      }
    }

    setPage(0);
    setFiatAmount("");
    setRestrictedMode(true);
  }, [selectedCoin]);

  useEffect(() => {
    if (selectedNetwork) {
      getImpPointsAndFaq();
    }
  }, [selectedNetwork]);

  useEffect(() => {
    if (selectedProvider) {
      if (selectedProvider.label === "Credit/Debit Card") {
        getImpPointsAndFaq(true);
        setIsCardPayment(true);
      } else {
        getImpPointsAndFaq();
        setIsCardPayment(false);
      }
    }

    setShowModal(true);
    setFiatAmount("");
  }, [selectedProvider]);

  // Condition to handle the seletion of CARD
  useEffect(() => {
    if (isCardSelected && !exchWallet) {
      setIsCardSelected(false);
      return;
    }
    if (isCardSelected) {
      const coinData = exchWallet.data.find((data) => data.coin === "BTC");
      handleDepositCurrency(coinData);
    }
  }, [isCardSelected]);

  useEffect(() => {
    if (
      networkList &&
      networkList.length > 0 &&
      isCardSelected &&
      selectedCoin &&
      selectedCoin.coin === "BTC" &&
      userData.status !== "RESTRICTED" &&
      !(kycStatus === "LEVEL0" || kycStatus === "LEVEL1")
    ) {
      const networkData = networkList.find((data) => data.label === "Lightning");
      handleNetworkCurrency(networkData);
      setIsCardSelected(false);
    }
  }, [networkList]);

  const getImpPointsAndFaq = (fetchingForCard = false) => {
    let data = {
      type: "DEPOSIT",
      key: selectedCoin?.coin || "",
      network: selectedProvider?.label ? selectedProvider?.label : selectedCoin?.coin,
      provider: selectedProvider?.label ? selectedProvider?.label : selectedCoin?.coin
    };
    if (fetchingForCard) {
      data.provider = "CARD";
    }
    setLoadingImportantPoint(true);
    setIsFqaLoading(true);
    getFqa(data)
      .then((res) => {
        if (res.status === 200) {
          setFqa(res.data.data);
          setIsFqaLoading(false);
        }
      })
      .catch((err) => {
        setIsFqaLoading(false);
        showErrorMsg(formatAPIErrors(err));
      });

    getImportantPoints(data)
      .then((res) => {
        if (res.status === 200) {
          setImportantPoint(res.data.data);
          setLoadingImportantPoint(false);
        }
      })
      .catch((err) => {
        setLoadingImportantPoint(false);
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const handleDepositCurrency = (coinData) => {
    if (coinData.coinType === "fiat_token") {
      getFiatDeposit(coinData);
    } else {
      getNetworks(coinData);
    }
    coinChanged(coinData);
    const state = { ...history?.location?.state };
    delete state.deleted;
    history.replace({ ...history?.state, state });
  };

  const handleProviderCurrency = (coinData) => {
    setSelectedProvider(coinData);
    setSelectedNetwork("");
  };

  const handleNetworkCurrency = (coinData) => {
    setSelectedNetwork(coinData);
    setSelectedProvider("");
  };

  const getFiatDeposit = (coin) => {
    getFiatDepositDetails(coin)
      .then((res) => {
        setProviderList(res && res?.data?.data?.networks);
        setFiatDetails(res && res?.data?.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });

    getBeneficiary()
      .then((res) => {
        let data = res && res.data;
        if (data.data && data.data.length === 0) {
          setShowModal(true);
          setBeneficaryList([]);
        }
        setBeneficaryList(data.data || []);
        setMtc(data.mtc);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const getNetworks = (coin) => {
    getCoinNetworks({ coin: coin.coin })
      .then((res) => {
        let data = res && res.data.data;
        if (Array.isArray(data.networks)) {
          // setNetworkList(data.networks);
          setNetworkList(
            data.networks.map((val, i) => {
              let arr = { id: val.id, label: val.name, value: val.name };
              if (val?.min) {
                arr.min = val.min;
              }
              if (val?.max) {
                arr.max = val.max;
              }

              return arr;
            })
          );
        } else {
          setNetworkList([]);
        }
      })
      .catch((err) => showErrorMsg(formatAPIErrors(err)));
  };

  const onCloseModal = () => {
    setShowModal(false);
    setSelectedProvider("");
  };
  // const onCloseModal2fa = () => {
  //   setShowModal(false);
  //   // setSelectedProvider("");
  //   setSelectedCoin("");
  // };

  const onCardPaymentCancel = () => {
    setSelectedProvider("");
  };

  let status2fa = useMemo(
    () =>
      userData && userData.twoFactorAuth && userData.twoFactorAuth.status
        ? userData.twoFactorAuth.status
        : false,
    [userData.twoFactorAuth.status]
  );

  useEffect(() => {
    if (!status2fa) {
      setShowModal(true);
    }
  }, [status2fa, selectedCoin]);

  const isRestrictedUser = userData && userData.status === "RESTRICTED" ? true : false;
  const checkKycStatus = kycStatus === "LEVEL0" || kycStatus === "LEVEL1" ? true : false;

  return (
    <BannerBox mt="34px" mtMd="24px" width={"85%"} widthLg={"100%"} weight={300}>
      <Label htmlFor="Network" txtGrey mb="0px" size="14px" width="100%">
        Choose Asset
      </Label>

      <SelectDropDownComponent
        dataComponent={{
          DropdownIndicator,
          Option: MyOptions
        }}
        name="selectCurrency"
        placeholder="Select Asset"
        optionList={currencyList}
        selected_coin={selectedCoin}
        chooseLabel={CustomOptionsForCurrency}
        value={selectedCoin.coin ? { label: selectedCoin.coin, options: selectedCoin.coin } : ""}
        defaultValue={
          selectedCoin.coin ? { label: selectedCoin.coin, options: selectedCoin.coin } : ""
        }
        handlePairName={handleDepositCurrency}
        styles={reactDropdownStyles({
          isDarkTheme,
          borderBottom: true,
          height: "48px",
          fontWeight: 300
        })}
      />

      {selectedCoin !== "" && !selectedCoin.depositsEnabled && (
        <SuspensionAlert type="Deposit" coin={selectedCoin.coin} />
      )}

      {selectedCoin && selectedCoin.depositsEnabled && (
        <Fragment>
          {selectedCoin && selectedCoin.coin && (
            <BannerHeading txtGrey size="14px" mt={"6px"}>
              Main balance:{" "}
              <b>
                <var>
                  {numberWithCommas(
                    formatNumbers(
                      (selectedCoin &&
                        selectedCoin.coin &&
                        exchWallet &&
                        exchWallet.data &&
                        exchWallet.data.filter((data) => data.coin === selectedCoin.coin)[0][
                          "balance"
                        ]["freeBalance"]) ||
                        0
                    )
                  )}{" "}
                  {selectedCoin.coin}
                </var>
              </b>
            </BannerHeading>
          )}

          {selectedCoin !== "" &&
            selectedCoin.coin &&
            selectedCoin.coinType === "fiat_token" &&
            !checkKycStatus &&
            !isRestrictedUser && (
              <BannerBox mt="24px">
                <Label htmlFor="selectProvider" txtGrey size="14px" width="100%" mb="8px">
                  Choose Provider
                </Label>
                <Select
                  name="selectProvider"
                  placeholder="Select Provider"
                  components={{ DropdownIndicator }}
                  isSearchable={true}
                  formatOptionLabel={(vals) =>
                    selectProviderCustomOptions({ ...vals, isDarkTheme })
                  }
                  onChange={handleProviderCurrency}
                  styles={reactDropdownStyles({ isDarkTheme, borderBottom: true, height: "48px" })}
                  options={formattedArrayProvider(providerList)}
                  value={
                    selectedProvider && selectedProvider.value
                      ? { label: selectedProvider.label, value: selectedProvider.value }
                      : ""
                  }
                  minMenuHeight={200}
                  maxMenuHeight={200}
                  menuPlacement="auto"
                />
              </BannerBox>
            )}

          {selectedCoin &&
            selectedCoin.coinType !== "fiat_token" &&
            !checkKycStatus &&
            !isRestrictedUser && (
              <>
                <Label htmlFor="Network" txtGrey mb="0px" size="14px" width="100%" mt="24px">
                  Choose Network
                </Label>

                <SelectDropDownComponent
                  dataComponent={{
                    DropdownIndicator,
                    Option: (props) => {
                      return <MyOptions {...props} isNetwork={true} />;
                    }
                  }}
                  name="selectNetwork"
                  placeholder="Select Network"
                  optionList={formattedArrayNetwork(networkList)}
                  selected_coin={selectedNetwork}
                  chooseLabel={CustomOptionsForNetwork}
                  styles={reactDropdownStyles({
                    isDarkTheme,
                    borderBottom: true,
                    height: "48px",
                    fontWeight: "300"
                  })}
                  value={
                    selectedNetwork && selectedNetwork !== "" && selectedNetwork?.value
                      ? { label: selectedNetwork.value, options: selectedNetwork.value }
                      : ""
                  }
                  defaultValue={
                    selectedNetwork && selectedNetwork?.value
                      ? { label: selectedNetwork.value, options: selectedNetwork.value }
                      : ""
                  }
                  handlePairName={handleNetworkCurrency}
                />
              </>
            )}

          {selectedCoin.coin &&
            selectedCoin.coinType === "fiat_token" &&
            selectedProvider &&
            isCardPayment &&
            (!selectedProvider.depositsEnabled || !fiatDetails?.wallet?.status) && (
              <SuspensionAlert type="Deposit" coin={selectedCoin.coin} />
            )}

          {selectedCoin.coin &&
            selectedCoin.coinType === "fiat_token" &&
            selectedProvider &&
            isCardPayment &&
            selectedProvider.depositsEnabled &&
            fiatDetails?.wallet?.status && (
              <FiatCardDeposit
                amount={fiatAmount}
                setAmount={setFiatAmount}
                coin={selectedCoin.coin}
                onCardPaymentCancel={onCardPaymentCancel}
                cardDetails={selectedProvider && selectedProvider.value}
              />
            )}

          {selectedCoin &&
            selectedProvider &&
            (selectedProvider.label === "Monerium" ||
              selectedProvider.label === "Wire transfer" ||
              selectedProvider.label === "BCB" ||
              selectedProvider.label === "Clear Junction") &&
            beneficaryList &&
            beneficaryList.length === 0 &&
            !checkKycStatus &&
            showModal &&
            !isRestrictedUser && <AddBeneficiary onClose={onCloseModal} />}

          {selectedCoin.coin &&
            selectedCoin.coinType !== "fiat_token" &&
            selectedNetwork &&
            selectedNetwork.value && <NonFiatAddressComponent />}

          {selectedCoin &&
            selectedCoin.coinType === "fiat_token" &&
            selectedProvider &&
            (selectedProvider.label === "BLINC"
              ? true
              : beneficaryList && beneficaryList.length > 0) &&
            !isCardPayment && (
              <Fragment>
                {selectedProvider.label === "Wire transfer" ? (
                  <WireTransfer coin={selectedCoin?.coin} />
                ) : (
                  <FiatAddressComponent />
                )}
              </Fragment>
            )}

          {selectedCoin && checkKycStatus && !isRestrictedUser && (
            <GetKycVerified open={showModal} onClose={onCloseModal} />
          )}
          {/* {!status2fa && showModal && <Enable2FA onClose={onCloseModal2fa} />} */}

          {selectedCoin && isRestrictedUser && !isTopModalActive && (
            <RestrictedComponent
              open={showRestrictedMode}
              onClose={() => setRestrictedMode(false)}
            />
          )}
        </Fragment>
      )}
    </BannerBox>
  );
};

const maptoStateProps = (state) => ({
  isDarkTheme: state.theme.isTheme,
  userData: state.dashboard.getUserData
});

export default compose(connect(maptoStateProps), withRouter)(DepositFormComponent);
