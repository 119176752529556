/* eslint-disable no-case-declarations */
import apiConstants from "../constants/apiConstants";
import { getPairDetail, updateOrders, updateTickers } from "../utils/trading";

const orderBookDefaultConfig = {
  displayAvgSum: false,
  buySellRatio: true,
  cumulative: false,
  spread: false,
  animation: true
};

const defaultGraphData = {
  priceSumArray: [],
  amountSumArray: [],
  totalSumArray: [],
  sumOfTotals: 0,
  maxOfTotals: 0,
  sumOfAmounts: 0,
  count: 0
};

const getStorageValue = (key, defaultValue) => {
  let data = localStorage.getItem(key);
  if (data && data !== "undefined" && data !== undefined) {
    return JSON.parse(data);
  }

  return defaultValue;
};

const initialStateSchema = {
  bestAsk: "",
  bestBid: "",
  selectedPair: getStorageValue("selectedPair", { symbol: "LCX/EUR", base: "LCX", quote: "EUR" }),
  isFetchingSelectedPairConfig: false,
  selectedPairConfig: "",
  selectedPairConfigErr: "",
  selectedPairTicker: "",
  orderFormSide: "buy", // Can be "buy" or "sell"
  orderFormType: "limit", // Can be "limit" or "market"
  orderBookActiveSide: "all", // Can be "all", "buy" or "sell"
  allBuyOrders: [],
  allSellOrders: [],
  orderBookBids: [],
  orderBookAsks: [],
  lastPrice: "",
  tradeHistory: [],
  tickersData: "",
  selectedPricePrecision: 8,
  selectedChartType: "trading", // Can be "trading" or "depth"
  userOrderUpdate: "",
  orderDataFromOrderBook: "", // {price: 'price', amount: 'amount'}
  pairCategoryList: "",
  isFetchingPairsCategory: false,
  actionOnLayout: "", // CHANGE, SAVE, RESET
  openOrdersData: "",
  isFetchingOpenOrders: "",
  orderHistoryData: "",
  isFetchingOrderHistory: "",
  orderExecutionsData: "",
  isFetchingOrderExecutions: "",
  obBuyGraphData: defaultGraphData,
  obSellGraphData: defaultGraphData,
  favChartPairs: getStorageValue("favChartPairs", []),
  userFavPairs: getStorageValue("userFavPairs", {}),
  showAllPairsOrder: getStorageValue("showAllPairsOrder", false),
  orderBookConfig: getStorageValue("orderBookConfig", orderBookDefaultConfig),
  activeWallet: getStorageValue("activeWallet", "exchange"),
  lastOrderUpdate: ""
};

const tradingReducer = (state = initialStateSchema, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.UPDATE_BEST_BID:
      newState = {
        ...state,
        bestBid: action.payload
      };

      return newState;

    case apiConstants.UPDATE_BEST_ASK:
      newState = {
        ...state,
        bestAsk: action.payload
      };

      return newState;

    case apiConstants.UPDATE_SELECTED_PAIR:
      let pData = getPairDetail(action.payload);
      localStorage.setItem("selectedPair", JSON.stringify(pData));
      newState = {
        ...state,
        selectedPair: pData
      };

      return newState;

    case apiConstants.UPDATE_ORDER_BOOK:
      newState = {
        ...state,
        allBuyOrders: updateOrders(action.payload.buy, "buy"),
        allSellOrders: updateOrders(action.payload.sell, "sell"),
        lastPrice: action.payload.lastPrice
      };

      return newState;

    case apiConstants.UPDATE_ALL_BUY_ORDERS:
      newState = {
        ...state,
        allBuyOrders: updateOrders(state["allBuyOrders"], "buy", action.payload),
        lastOrderUpdate: action.payload
      };

      return newState;

    case apiConstants.UPDATE_ALL_SELL_ORDERS:
      newState = {
        ...state,
        allSellOrders: updateOrders(state["allSellOrders"], "sell", action.payload),
        lastOrderUpdate: action.payload
      };

      return newState;

    case apiConstants.UPDATE_ALL_ASKS_AND_BIDS:
      let orderType = action.orderType;
      newState = {
        ...state,
        [orderType]: action.payload
      };

      return newState;

    case apiConstants.SET_TRADE_HISTORY_SNAPSHOT:
      newState = {
        ...state,
        tradeHistory: action.payload
      };

      return newState;

    case apiConstants.SET_TRADE_HISTORY_UPDATE:
      let trades = [...state["tradeHistory"]];
      trades.unshift(action.payload);
      newState = {
        ...state,
        tradeHistory: trades
      };

      return newState;

    case apiConstants.SET_TICKER_SNAPSHOT:
      let tickersData = action.payload;
      let pair = state.selectedPair.symbol;
      newState = {
        ...state,
        tickersData: tickersData,
        selectedPairTicker: tickersData[pair]
      };

      return newState;

    case apiConstants.SET_TICKER_UPDATE:
      let tickersData1 = updateTickers(state["tickersData"], action.payload);
      let pair1 = state.selectedPair.symbol;
      newState = {
        ...state,
        tickersData: tickersData1,
        selectedPairTicker: tickersData1[pair1]
      };

      return newState;

    case apiConstants.UPDATE_SELECTED_PAIR_TICKER:
      newState = {
        ...state,
        selectedPairTicker: action.payload
      };

      return newState;

    case apiConstants.UPDATE_ORDER_FORM_SIDE:
      newState = {
        ...state,
        orderFormSide: action.payload
      };

      return newState;

    case apiConstants.UPDATE_ORDER_FORM_TYPE:
      newState = {
        ...state,
        orderFormType: action.payload
      };

      return newState;

    case apiConstants.UPDATE_SELECTED_CHART_TYPE:
      newState = {
        ...state,
        selectedChartType: action.payload
      };

      return newState;

    case apiConstants.UPDATE_ORDER_BOOK_PRICE_PRECISION:
      newState = {
        ...state,
        selectedPricePrecision: action.payload
      };

      return newState;

    case apiConstants.GET_SELECTED_PAIR_CONFIG:
      newState = {
        ...state,
        isFetchingSelectedPairConfig: true,
        selectedPairConfigErr: ""
      };

      return newState;

    case apiConstants.GET_SELECTED_PAIR_CONFIG_SUCCESS:
      newState = {
        ...state,
        isFetchingSelectedPairConfig: false,
        selectedPairConfig: action?.response?.data?.data,
        selectedPricePrecision: action?.response?.data?.data?.orderPrecision?.Price || 8
      };

      return newState;

    case apiConstants.GET_SELECTED_PAIR_CONFIG_FAIL:
      newState = {
        ...state,
        isFetchingSelectedPairConfig: false,
        selectedPairConfig: "",
        selectedPairConfigErr: action.response
      };

      return newState;

    case apiConstants.UPDATE_FAV_CHART_PAIRS:
      localStorage.setItem("favChartPairs", JSON.stringify(action.payload));
      newState = {
        ...state,
        favChartPairs: action.payload
      };

      return newState;

    case apiConstants.UPDATE_USER_ORDERS:
      newState = {
        ...state,
        userOrderUpdate: action.payload
      };

      return newState;

    case apiConstants.UPDATE_ORDER_FROM_ORDER_BOOK:
      newState = {
        ...state,
        orderDataFromOrderBook: action.payload
      };

      return newState;

    case apiConstants.UPDATE_USER_FAV_PAIRS:
      localStorage.setItem("userFavPairs", JSON.stringify(action.payload));
      newState = {
        ...state,
        userFavPairs: action.payload
      };

      return newState;

    case apiConstants.GET_ALL_PAIRS_CATEGORIES:
      newState = {
        ...state,
        isFetchingPairsCategory: true
      };

      return newState;

    case apiConstants.GET_ALL_PAIRS_CATEGORIES_SUCCESS:
      newState = {
        ...state,
        isFetchingPairsCategory: false,
        pairCategoryList: action.response?.data?.categories
      };

      return newState;

    case apiConstants.GET_ALL_PAIRS_CATEGORIES_FAIL:
      newState = {
        ...state,
        isFetchingPairsCategory: false,
        pairCategoryList: ""
      };

      return newState;

    case apiConstants.PERFORM_ACTION_ON_LAYOUT:
      newState = {
        ...state,
        actionOnLayout: action.payload
      };

      return newState;

    case apiConstants.UPDATE_SHOW_ALL_PAIRS_ORDER:
      localStorage.setItem("showAllPairsOrder", JSON.stringify(action.payload));
      newState = {
        ...state,
        showAllPairsOrder: action.payload
      };

      return newState;

    case apiConstants.UPDATE_ACTIVE_WALLET:
      localStorage.setItem("activeWallet", JSON.stringify(action.payload));
      newState = {
        ...state,
        activeWallet: action.payload
      };

      return newState;

    case apiConstants.GET_USER_ORDERS:
      let changes;
      if (action?.payload?.type === "orderExecutions") {
        changes = {
          isFetchingOrderExecutions: true
        };
      } else if (action?.payload?.type === "orderHistory") {
        changes = {
          isFetchingOrderHistory: true
        };
      } else {
        changes = {
          isFetchingOpenOrders: true
        };
      }

      newState = {
        ...state,
        ...changes
      };

      return newState;

    case apiConstants.GET_USER_ORDERS_SUCCESS:
      let successChanges;
      if (action?.response?.type === "orderExecutions") {
        successChanges = {
          isFetchingOrderExecutions: false,
          orderExecutionsData: action.response
        };
      } else if (action?.response?.type === "orderHistory") {
        successChanges = {
          isFetchingOrderHistory: false,
          orderHistoryData: action.response
        };
      } else {
        successChanges = {
          isFetchingOpenOrders: false,
          openOrdersData: action.response
        };
      }

      newState = {
        ...state,
        ...successChanges
      };

      return newState;

    case apiConstants.GET_USER_ORDERS_FAIL:
      let errChanges;
      if (action?.payload?.type === "orderExecutions") {
        errChanges = {
          isFetchingOrderExecutions: false,
          orderExecutionsData: ""
        };
      } else if (action?.payload?.type === "orderHistory") {
        errChanges = {
          isFetchingOrderHistory: false,
          orderHistoryData: ""
        };
      } else {
        errChanges = {
          isFetchingOpenOrders: false,
          openOrdersData: ""
        };
      }

      newState = {
        ...state,
        ...errChanges
      };

      return newState;

    case apiConstants.UPDATE_ORDER_BOOK_CONFIG:
      localStorage.setItem("orderBookConfig", JSON.stringify(action.payload));

      newState = {
        ...state,
        orderBookConfig: action.payload
      };

      return newState;

    case apiConstants.UPDATE_ORDER_BOOK_GRAPH_DATA:
      let key = action?.payload?.type === "buy" ? "obBuyGraphData" : "obSellGraphData";

      newState = {
        ...state,
        [key]: action?.payload?.data
      };

      return newState;

    case apiConstants.UPDATE_ORDER_BOOK_ACTIVE_SIDE:
      newState = {
        ...state,
        orderBookActiveSide: action.payload
      };

      return newState;

    default:
      return state;
  }
};

export default tradingReducer;
