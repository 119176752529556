import React from "react";
import ReactTooltip from "react-tooltip";
import { InfoIcon } from "../../../assets/svgs";
import DivElement from "../DivElement";
import TextWrapper from "../TextWrapper";
import SpanElement from "../SpanElement";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";

const Container = styled(DivElement)`
  position: relative;
  display: inline-flex;
  align-items: center;

  ${(props) =>
    props.contentMaxWidth &&
    css`
      .r-tool-tip {
        max-width: ${(props) => props.contentMaxWidth} !important;
        text-align: left;
      }
    `}
`;

const ToolTip = ({
  containerProps,
  titleProps,
  imgProps,
  tootlTipProps,
  contentProps,
  title,
  id,
  tipContent
}) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <Container {...containerProps} contentMaxWidth="296px">
      {title && <TextWrapper {...titleProps}>{title}</TextWrapper>}
      <InfoIcon
        data-tip
        data-for={id}
        data-background-color={isDarkTheme ? "#263645" : "#ecf5fe"}
        absolute
        top={imgProps?.top || "-2px"}
        right={imgProps?.right || "-26px"}
        {...imgProps}
      />
      <ReactTooltip id={id} type="light" place="bottom" className="r-tool-tip" {...tootlTipProps}>
        <SpanElement {...contentProps}>{tipContent}</SpanElement>
      </ReactTooltip>
    </Container>
  );
};

export default ToolTip;
