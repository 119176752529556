/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, createRef } from "react";
import { FavouriteFilledIcon, ThreeDotsIcon } from "../../assets/svgs";
import configs from "../../constants/config";
import { Fragment } from "react";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import {
  DivElement,
  DropdownWrapper,
  ImgElement,
  NoRecord,
  SearchBar,
  SpanElement,
  Table,
  Tbody,
  Td,
  TextWrapper,
  Tr
} from "../Reusable";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { updateUserFavPairs } from "../../actions/trading";

const MoreOption = ({ onAddRemoveFavPair }) => {
  const [show, setShow] = useState(false);
  let dropdown = createRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleClickOutside = (event) => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setShow(false);
    }
  };

  return (
    <div ref={dropdown}>
      <DivElement relative height="24px" width="24px" onClick={() => setShow(!show)}>
        <ThreeDotsIcon />
        <DivElement
          absolute
          bottom="-51px"
          right="0"
          zIndex="5"
          display={show ? "initial" : "none"}
        >
          <ButtonComponent
            DangerButton
            btnHeight="36px"
            padding="0 16px"
            sizeLg="14"
            sizeMd="12px"
            zIndex="10"
            onClick={onAddRemoveFavPair}
          >
            Delete
          </ButtonComponent>
        </DivElement>
      </DivElement>
    </div>
  );
};

const WatchList = ({ isDarkTheme }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [favPairList, setFavPairList] = useState("");
  const tickersData = useSelector((state) => state.trading.tickersData);
  const userFavPairs = useSelector((state) => state.trading.userFavPairs);
  const userData = useSelector((state) => state.dashboard.userData);
  const history = useHistory();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    formatFavPairsData();
  }, [tickersData, userFavPairs]);

  const formatFavPairsData = () => {
    let filteredData = [];
    if (tickersData && userFavPairs) {
      Object.keys(userFavPairs).forEach((key) => {
        if (userFavPairs[key] === true) {
          let data = tickersData[key];
          let symbol = data.symbol;
          let [base, quote] = symbol.split("/");
          filteredData.push({ ...data, base: base, quote: quote });
        }
      });
    }

    setFavPairList(filteredData);
  };

  const onAddRemoveFavPair = (symbol) => {
    let favs = { ...userFavPairs };
    if (favs[symbol]) {
      delete favs[symbol];
    } else {
      favs[symbol] = true;
    }
    dispatch(updateUserFavPairs(favs));
  };

  const onClickHandler = (data) => {
    history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/trade/${data.base}-${data.quote}`);
  };

  const isBreakingPoint = width >= 1124;

  return (
    <DropdownWrapper
      minWidth="320px"
      alignCenter={isBreakingPoint ? "calc(50% - 33px)" : "20px"}
      dropdownAlignRight={isBreakingPoint ? "-120px" : "-12px"}
      label={<FavouriteFilledIcon pointer margin="0 8px" />}
      containerProps={{ padding: "0" }}
    >
      <DivElement padding="16px">
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          maxWidth="178px"
          marginBottom="24px"
          inputProps={{ placeholder: "Search Assets", pFontSize: "14px", pFontWeight: "400" }}
          imgProps={{ width: "24px", height: "24px", marginBottom: "4px" }}
        />
        <DivElement width="100%" height="154px" scrollY>
          {favPairList && favPairList.length > 0 && (
            <Table>
              <Tbody verticalAlign="top" height="52px" paddingTop="8px" fontSize="14px">
                {favPairList.map((data, i) => (
                  <Fragment key={i}>
                    {((searchQuery &&
                      (data?.symbol.includes(searchQuery.toUpperCase()) ||
                        data?.symbol.includes(searchQuery.toUpperCase()))) ||
                      !searchQuery) && (
                      <Tr border={i === favPairList.length - 1 ? null : true}>
                        <Td textAlign="left" onClick={() => onClickHandler(data)}>
                          <DivElement flexAlignCenter>
                            <ImgElement
                              width="20px"
                              height="20px"
                              alt={data?.base}
                              src={`${
                                isDarkTheme ? configs.darkCoinUrl : configs.lightCoinUrl
                              }${data?.base.toLowerCase()}.svg`}
                              marginRight="8px"
                            />
                            {data.base}
                            <SpanElement fontSize="inherit" fontWeight="400" secondary>
                              {`/${data?.quote}`}
                            </SpanElement>
                          </DivElement>
                        </Td>
                        <Td textAlign="right" paddingRight="0">
                          <DivElement flex justifyContent="flex-end" alignItems="flex-start">
                            <DivElement marginRight="8px">
                              <TextWrapper fontSize="inherit" lineHeight="20px">
                                {data.volume}
                              </TextWrapper>
                              <TextWrapper
                                fontSize="inherit"
                                lineHeight="20px"
                                fontWeight="400"
                                green={data.change >= 0 ? true : null}
                                red={data.change < 0 ? true : null}
                              >
                                {`${data.change > 0 ? "+" : ""}${data.change}%`}
                              </TextWrapper>
                            </DivElement>
                            <MoreOption
                              onAddRemoveFavPair={() => onAddRemoveFavPair(data.symbol)}
                            />
                          </DivElement>
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                ))}
              </Tbody>
            </Table>
          )}

          {(!favPairList || favPairList.length === 0) && (
            <NoRecord message="Add Your Favorite Asset" />
          )}
        </DivElement>
      </DivElement>
    </DropdownWrapper>
  );
};

export default WatchList;
