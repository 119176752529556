/* eslint-disable no-console */

/* eslint-disable no-unused-vars */
import { fromJS } from "immutable";
import { MARKET_ALL_TICKER, UPDATE_MARKET_ALL_TICKER, UPDATE_SINGLE_TICKER_DATA } from "./const.js";

import { failure, success } from "../../../utils/redux.js";
import { filterUSDVolume } from "../../../utils/general.js";

export function createInitialState() {
  return fromJS({
    allTickerData: {},
    tickerData: {},
    change: null,
    high: null,
    low: null,
    tickerLastPrice: null,
    last24Price: null,
    volume: null,
    usdVolume: null,
    equivalent: null,
    isFetchingMarketTicker: false
  });
}

export function TradeMarketTickerReducer(state = createInitialState(), action) {
  switch (action.type) {
    case MARKET_ALL_TICKER:
      return fromJS({
        ...state.toJS(),
        isFetchingMarketTicker: true
      });

    case success(MARKET_ALL_TICKER):
      return fromJS({
        ...state.toJS(),
        allTickerData: action.payload,
        isFetchingMarketTicker: false
      });

    case failure(MARKET_ALL_TICKER):
      return fromJS({
        ...state.toJS(),
        isFetchingMarketTicker: false
      });

    case UPDATE_MARKET_ALL_TICKER:
      return fromJS({
        ...state.toJS(),
        allTickerData: action.payload
      });

    case UPDATE_SINGLE_TICKER_DATA:
      var { change, high, low, lastPrice, last24Price, volume, usdVolume, equivalent } =
        action.payload || {};

      return fromJS({
        ...state.toJS(),
        tickerData: action.payload || {},
        change: change || null,
        high: high || null,
        low: low || null,
        tickerLastPrice: lastPrice || null,
        last24Price: last24Price || null,
        volume: volume || null,
        equivalent: equivalent || null,
        usdVolume: usdVolume || null
      });

    default:
      return state;
  }
}

//=> Sort Data All Ticker
const sortData = (array) => {
  let sortCopy = array;
  if (sortCopy) {
    array = array.sort((a, b) => {
      if (sortCopy.type === "asc") {
        if (sortCopy.value === "symbol") {
          if (a[sortCopy.value] < b[sortCopy.value]) return -1;
          if (a[sortCopy.value] > b[sortCopy.value]) return 1;
          return 0;
        } else {
          return a[sortCopy.value] - b[sortCopy.value];
        }
      } else {
        if (sortCopy.value === "symbol") {
          if (a[sortCopy.value] < b[sortCopy.value]) return 1;
          if (a[sortCopy.value] > b[sortCopy.value]) return -1;
          return 0;
        } else {
          return b[sortCopy.value] - a[sortCopy.value];
        }
      }
    });
  }
  return array;
};

// => Filter All Quote Data
const filterQuoteData = (pairList) => {
  let response = pairList,
    array = [];

  let active = JSON.parse(localStorage.getItem("fav")) || {};
  if (response.length > 0) {
    for (let i = 0; i < response.length; i++) {
      response[i]["fav"] = active[response[i]["symbol"]] ? true : false;
      array.push(response[i]);
    }
  }
  return array;
};

//=> Handle Market Data
const handleMarketData = (obj, allData) => {
  let array = Object.values(obj);
  let length = array.length;
  for (let i = 0; i < length; i++) {
    array[i]["quote_pair"] = array[i].symbol.split("/")[1];
    array[i]["base_pair"] = array[i].symbol.split("/")[0];
  }
  if (!array.length) return filterUSDVolume(Object.values(obj) || []);
  else if (allData) return array;
  else {
    array = filterQuoteData(array);
    array = filterUSDVolume(array);
    return sortData(array);
  }
};
