import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { HeadingThird } from "../../../../assets/StyleComponents/FontSize/headingThird.style";
import { RewardsGiftIllustration } from "../../../../assets/svgs";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import ListTimeOffer from "./ListTimeOffer";
import { getPrivateRewardsTasksList } from "../../../../actions/RewardsHub/RewardsHub";
import DynamicAnimatedBorders from "./TextComponent";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";

const RewardsTimeOffer = ({ children }) => {
  // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    let param = {
      type: "active",
      offset: "0",
      pageSize: 25,
      category: "ONBOARDING"
    };

    dispatch(getPrivateRewardsTasksList(param));
  }, []);

  return (
    <BannerBox
      CardWhite2
      radius={"10px"}
      padding={"32px 32px 32px"}
      paddingLg={"24px 20px 48px"}
      paddingMd={"16px 12px 8px"}
      margin="1.1rem 0px"
      display="flex"
      alignItems="flex-start"
      gap="20px"
      directionLg="column"
    >
      {width > 767 && 
        <BannerBox width="40%" widthLg="100%" displayLg="flex" justifyContentLg="space-between">
          <BannerBox widthLg="40%">
            <HeadingFourth txtGrey weight="500" size="20px" sizeMd="14px" mb="24px" mbMd="12px">
              Limited Time Offer
            </HeadingFourth>
            <HeadingThird weight="500" size="40px" sizeMd="25px">
              Prizes over
            </HeadingThird>
            
            <DynamicAnimatedBorders />
          </BannerBox>

          <RewardsGiftIllustration height="275px" width="372px" />
        </BannerBox>
      }
      {width < 768 && 
        <BannerBox widthMd="100%" displayLg="flex" direction="column" justifyContentLg="space-between">
          <BannerBox display="flex" width="100%" position="relative" heightMd="110px">
            <BannerBox widthLg="40%" widthSm="80%">
              <HeadingFourth txtGrey weight="500" size="20px" sizeMd="14px" mb="24px" mbMd="12px">
                Limited Time Offer
              </HeadingFourth>
              <HeadingThird weight="500" size="40px" sizeMd="25px">
                Prizes over
              </HeadingThird>
            </BannerBox>

            <RewardsGiftIllustration 
              height="275px" width="372px" widthMd="272px" heightMd="150px"
              position="relative" top="-32px" right="0%" 
            />
          </BannerBox>
          <DynamicAnimatedBorders />
        </BannerBox>
      }
      <BannerBox width="60%" widthLg="100%">
        <BannerHeading size="16px" weight="500" mb="24px">
          For New Users
        </BannerHeading>

        {children}

      </BannerBox>
    </BannerBox>
  );
};

RewardsTimeOffer.ListTimeOfferList = ListTimeOffer;

export default RewardsTimeOffer;
