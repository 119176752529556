import { Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";

import { DivElement, RouterLink, TextWrapper, UserAvatar } from "../../Reusable";
import NavLink from "./NavLink";
import MoreNav from "./MoreNav";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../constants/styledConstants";
import { FlexCard } from "../../../assets/StyleComponents/FlexDiv.style";
import { BlueNewMoreNavbarTag, PromotionIcon } from "../../../assets/svgs";

const NavWrapper = styled(DivElement)`
  position: fixed;
  top: 57px;
  left: 0;
  height: calc(100vh - 57px);
  width: 100vw;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: scroll;
`;

const NonDesktop = ({
  userData,
  openHamburger,
  setOpenHamburger,
  isLoggedIn,
  toggleChatSupport,
  allClientSales,
  show,
  pathNameCheck
}) => {
  const { width } = useWindowSize();
  const isMobile = width <= styledConstants.BREAKING_POINT_MOBILE;
  let prevLang = localStorage.getItem("ljs-lang");
  // let currentLang = userData?.preferredLanguage ? userData?.preferredLanguage :

  const TABS = [
    {
      label: "Markets",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }/markets`,
      showWhenLoggedOut: true,
      showWhenLoggedIn: true
    },
    {
      label: "Trade",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }/trade`,
      showWhenLoggedOut: true,
      showWhenLoggedIn: true
    },
    {
      label: "Orders",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }/order/open`,
      showWhenLoggedOut: false,
      showWhenLoggedIn: true
    },
    {
      label: "Wallet",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }/wallet/balance`,
      showWhenLoggedOut: false,
      showWhenLoggedIn: true
    },
    {
      label: "Earn",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }/earn`,
      showWhenLoggedOut: true,
      showWhenLoggedIn: true
    },
    {
      label: "Token Sale",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }/token-sale`,
      showWhenLoggedOut: true,
      showWhenLoggedIn: true
    },
    {
      label: "Rewards Hub",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }/rewards`,
      newStatus: true,
      showWhenLoggedOut: true,
      showWhenLoggedIn: true
    },
    { label: "more", path: "", showWhenLoggedOut: true, showWhenLoggedIn: true },
    {
      label: "Reports",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }/setting/reports`,
      showWhenLoggedOut: false,
      showWhenLoggedIn: true
    },
    {
      label: "Settings",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }/setting/profile`,
      showWhenLoggedOut: false,
      showWhenLoggedIn: true
    },
    {
      label: "Support",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }`,
      showWhenLoggedOut: true,
      showWhenLoggedIn: true
    },
    {
      label: "Sale Dashboard",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }/token-sale/admin`,
      showWhenLoggedOut: false,
      showWhenLoggedIn: true
    },
    {
      label: "Log Out",
      path: `${
        prevLang
          ? `/${prevLang}`
          : userData?.preferredLanguage
          ? `/${userData?.preferredLanguage}`
          : "/en"
      }`,
      showWhenLoggedOut: false,
      showWhenLoggedIn: true
    },
    {
      label: "Log in",
      path: `${prevLang ? `/${prevLang}` : "/en"}/login`,
      showWhenLoggedOut: true,
      showWhenLoggedIn: false
    }
  ];

  return (
    <NavWrapper>
      <DivElement
        padding="24px 62px 48px 62px"
        paddingMd={show && pathNameCheck ? "49px 62px 48px 62px" : "24px 62px 48px 62px"}
        paddingSm={show && pathNameCheck ? "47px 16px 48px 16px" : "16px 16px 48px 16px"}
        bgCard
      >
        {isLoggedIn && (
          <DivElement dAlignCenter marginBottom="24px">
            <UserAvatar />
            <TextWrapper fontSize="14px" fontWeight="400" lineHeight="20px" marginLeft="16px">
              {userData?.firstName && userData.lastName
                ? `${userData?.firstName} ${userData.lastName}`
                : userData.email}
            </TextWrapper>
          </DivElement>
        )}
        
        <FlexCard LightPrimaryBackground padding="8px 12px" borderRadius="5px" border>
          <RouterLink to={`/${prevLang}/promotions`}>
            <DivElement displayBtw height="44px" width="100%" bdGrey>
              <FlexCard gap="8px" flexAlignCenter>
                <DivElement display="inline-block">
                  <PromotionIcon />
                </DivElement>

                <FlexCard direction="column">
                  <TextWrapper
                    fontSize="14px"
                    fontSizeSm="12px"
                    lineHeight="20px"
                    lineHeightSm="18px"
                  >
                    Announcement Centre {" "}
                    <BlueNewMoreNavbarTag
                      color="white"
                      width="33px"
                      height="20px"
                      marginLeft="8px"
                    />
                  </TextWrapper>
                  <TextWrapper
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="18px"
                  >
                    LCX is the top exchange for trading for more check the link below
                  </TextWrapper>
                </FlexCard>
              </FlexCard>
              {/* <ChevronRightIcon pointer /> */}
            </DivElement>
          </RouterLink>
        </FlexCard>

        {TABS.map((tab, i) => (
          <Fragment key={i}>
            {((!isLoggedIn && tab.showWhenLoggedOut) || (isLoggedIn && tab.showWhenLoggedIn)) && (
              <Fragment>
                {tab.label === "more" ? (
                  <MoreNav openHamburger={openHamburger} setOpenHamburger={setOpenHamburger} />
                ) : (tab.label === "Sale Dashboard" &&
                    !(allClientSales && allClientSales.showLink)) ||
                  (tab.label === "Support" && !isMobile) ? (
                  ""
                ) : (
                  <NavLink
                    label={tab.label}
                    path={tab.path}
                    border={i === TABS.length - 1 ? false : true}
                    openHamburger={openHamburger}
                    setOpenHamburger={setOpenHamburger}
                    toggleChatSupport={toggleChatSupport}
                    newStatus= {tab.newStatus || false}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        ))}
      </DivElement>
    </NavWrapper>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData,
  isLoggedIn: state.auth.isLoggedIn,
  allClientSales: state.sale.allClientSales
});

export default compose(connect(mapStateToProps))(NonDesktop);
