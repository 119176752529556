/* eslint-disable no-console */
import { useSelector } from "react-redux";
import { Skeleton } from "../../../../../components/Loader";
import { numberWithCommas } from "../../../../../utils/general";
import { formatNumbers } from "../../../../../utils/helpers";
import { PlusIcon, ExchangeBalanceIcon } from "../../../../../assets/svgs";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import { Fragment, memo, useState } from "react";
import InsufficientBalance from "../../../../../components/InsufficientBalance";
import { useEffect } from "react";

const ShowFunds = ({ coin, requiredFunds, isError, showModal, setShowModal }) => {
  const [showAddFunds, setShowAddFunds] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const exchangeWallet = useSelector((state) => state.dashboard.exchangeWallet);
  const coinData = exchangeWallet && exchangeWallet.data.find((data) => data.coin === coin);

  useEffect(() => {
    if (showModal) {
      setShowAddFunds(true);
    }
  }, [showModal]);

  const onClose = () => {
    setShowAddFunds(false);
    if (setShowModal) setShowModal(false);
  };

  return (
    <Fragment>
      <DivElement displayBtw>
        <DivElement flexAlignCenter>
          <ExchangeBalanceIcon width="18px" height="18px" marginRight="4px" />
          <TextWrapper secondary fontSize="12px" fontWeight="400" lineHeight="18px">
            Exchange balance
          </TextWrapper>
        </DivElement>
        <DivElement flexAlignCenter>
          {isLoggedIn && coinData !== null && coinData && (
            <DivElement bgAppBanner borderRadius="50%" displayCenter marginRight="4px">
              <PlusIcon pointer width="18px" height="18px" onClick={() => setShowAddFunds(true)} />
            </DivElement>
          )}
          <TextWrapper secondary fontSize="12px" lineHeight="18px" red={isError ? true : null}>
            {!isLoggedIn ? (
              `0 ${coin}`
            ) : coinData !== null && coinData ? (
              `${numberWithCommas(
                formatNumbers(coinData?.balance?.freeBalance || 0, 8) || 0
              )} ${coin}`
            ) : (
              <Skeleton width={74} height={18} />
            )}
          </TextWrapper>
        </DivElement>
      </DivElement>

      {showAddFunds && (
        <InsufficientBalance
          coin={coin}
          requiredFunds={requiredFunds}
          onClose={onClose}
          defaultActiveWallet="MAIN"
        />
      )}
    </Fragment>
  );
};

export default memo(ShowFunds);
