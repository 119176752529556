import deepExtend from "deep-extend";
import { customDecode } from "../utils/envScript/envCrypto";

let url = {
  publicApiUrl: customDecode(process.env.REACT_APP_PUBLIC_API_URL),
  publicApiUrlV2: customDecode(process.env.REACT_APP_PUBLIC_API_URL_V2),
  klineApiUrl: customDecode(process.env.REACT_APP_KLINKE_API_URL),
  apiUrl: customDecode(process.env.REACT_APP_API_URL),
  walletUrl: customDecode(process.env.REACT_APP_WALLET_URL),
  walletUrl2: customDecode(process.env.REACT_APP_WALLET_URL_2),
  walletUrl3: customDecode(process.env.REACT_APP_WALLET_URL_3),
  notificationUrl: customDecode(process.env.REACT_APP_NOTIFICATION_URL),
  accountsUrl: customDecode(process.env.REACT_APP_ACCOUNTS_URL),
  terminalUrl: customDecode(process.env.REACT_APP_TERMINAL_URL),
  exchangeUrl: customDecode(process.env.REACT_APP_EXCHANGE_URL),
  tiamondsUrl: customDecode(process.env.REACT_APP_TIAMONDS_URL),
  socketUrl: customDecode(process.env.REACT_APP_SOCKET_URL),
  privateSocketUrl: customDecode(process.env.REACT_APP_PRIVATE_SOCKET_URL),
  newAccountsUrl: customDecode(process.env.REACT_APP_NEW_ACCOUNT_URL),
  newAccountsUrlV2: `${customDecode(process.env.REACT_APP_NEW_ACCOUNT_URL_V2)}/v2`,
  newAccountsUrlV3: `${customDecode(process.env.REACT_APP_NEW_ACCOUNT_URL_V2)}/v3`,
  reCaptchaKey: customDecode(process.env.REACT_APP_RE_CAPTCHA_KEY),
  masterdexUrl: customDecode(process.env.REACT_APP_MASTERDEX_URL)
};

let config = {
  blur: false,
  pageTitle: {
    title: "Buy, Sell & Trade Bitcoin and Altcoins | Cryptocurrency Exchange",
    description:
      "LCX Exchnage: Your secure, fast, and reliable crypto exchange platform for trading Bitcoin, Ethereum, and 500+ coins with 24/7 live support.",
    keywords: `uk regulated crypto exchanges, crypto exchange regulation, crypto exchanges regulated in uk, regulated exchanges crypto, regulated crypto exchanges uk, crypto asset exchange, cryptoassets exchange, european crypto exchange, crypto exchange with lowest fees`
  },
  platform: {
    name: "LCX Exchange",
    url: url.exchangeUrl,
    favicon: "",
    supportEmail: "support@lcx.com"
  },
  reCaptchaKey: url.reCaptchaKey,
  api: {
    user: {
      exchangeToken: "/auth/refresh-token",
      find: "/terminals/get-user"
    },
    currency: {
      findAll: "currency",
      findById: (id) => `currency/${id}`,
      forexPrice: "currency/forex"
    },
    public: {
      allTickers: "market/tickers",
      getPairConfig: "market/pair",
      getPairsConfig: "market/pairs",
      tradeHistory: "trade/recent",
      orderBook: "order/book"
    },
    private: {
      placeOrder: "order/create",
      openOrders: "order/open",
      tradeHistory: "trade/uHistory",
      orderModify: "order/modify"
    }
  },
  defaultCurrency: {
    fiat: "USD",
    crypto: "ETH",
    defaultActive: "crypto"
  },
  debug: customDecode(process.env.REACT_APP_NODE_ENV) === "production" ? false : true,

  currencyUrl: "https://terminal-files.lcx.com/static/img/coins/64x64/",
  lightCoinUrl: "https://lcx-exchange.s3.amazonaws.com/coins/light/svg/",
  darkCoinUrl: "https://lcx-exchange.s3.amazonaws.com/coins/dark/svg/",
  lightNetworkUrl: "https://lcx-exchange.s3.amazonaws.com/networks/png/light/",
  darkNetworkUrl: "https://lcx-exchange.s3.amazonaws.com/networks/png/dark/",
  publicApiUrl: "",
  klineApiUrl: "",
  basePairs: ["BTC", "ETH", "USDT", "BNB"],
  socketUrl: "",
  logo: {
    currency: (currency_id) => `//terminal-files.lcx.com/static/img/coins/32x32/${currency_id}.png`
  },
  loaderColor: "#047bf8",

  defaultPair: "LCX/EUR",

  hubspotPortalId: 5983274
};
deepExtend(config, url);
export default config;
