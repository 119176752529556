import React from "react";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";

const LcxTokenText = () => {
  const { width } = useWindowSize();
  return (
    <>
      <BannerBox display="flex" direction="column" width="100%" mb="40px" mbLg="10px">
        {width > 1024 && (
          <BannerHeading size="24px" weight="bold" lineHeight="1.7" BodyColor mt="20px">
            Why should I buy LCX Token?
          </BannerHeading>
        )}

        <BannerHeading size="16px" weight="300" lineHeight="1.7" mt="15px" mtLg="10px">
          LCX Token works as a long-term sustainable incentive mechanism to motivate various
          stakeholders to participate in the ecosystem.
        </BannerHeading>

        <ul style={{ marginBottom: "20px", paddingLeft: "1rem" }}>
          <li>
            <BannerHeading weight="300" mt="20px" BodyColor size="16px">
              <b> LCX Exchange: </b> Reduction of up to 50% trading fees at LCX’s compliant digital
              asset exchange.
            </BannerHeading>
          </li>

          <li>
            <BannerHeading weight="300" mt="20px" BodyColor size="16px">
              <b> LCX DeFi Terminal: </b> get full access including usage of our second layer DeFi
              protocol to enable limit orders on Uniswap.
            </BannerHeading>
          </li>

          <li>
            <BannerHeading weight="300" mt="20px" BodyColor size="16px">
              <b> LCX Vault: </b> Pay fees for LCX Vault and our crypto custody solutions.
            </BannerHeading>
          </li>

          <li>
            <BannerHeading weight="300" mt="20px" BodyColor size="16px">
              <b> LCX Price Oracle: </b> pay fees in relation to LCX’s regulated reference price
              services which we provide as Price Service Provider.
            </BannerHeading>
          </li>

          <li>
            <BannerHeading weight="300" mt="20px" BodyColor size="16px">
              <b> Token Sales: </b> pay fees in relation to token sales hosted or powered by LCX
              technology.
            </BannerHeading>
          </li>

          <li>
            <BannerHeading weight="300" mt="20px" BodyColor size="16px">
              Furthermore companies and clients of LCX will have the ability to pay fees for
              listings, exchange membership fees or token sale fees with LCX Token as well.
            </BannerHeading>
          </li>
        </ul>

        <BannerHeading weight="300" mt="20px" BodyColor size="16px">
          The LCX Token is your chance to be a part of LCX’s vision to bridge the gap between
          traditional finance and the new monetary world powered by blockchain and cryptocurrencies.
        </BannerHeading>

        <BannerHeading weight="300" mt="20px" BodyColor size="16px">
          International Token Identification Number (ITIN) for the LCX Token: 3YJF06QR7
        </BannerHeading>

        <BannerHeading weight="300" mt="20px" BodyColor size="16px">
          Token name: LCX Token | Token symbol: LCX
        </BannerHeading>
      </BannerBox>
    </>
  );
};

export default LcxTokenText;
