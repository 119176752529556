import { Fragment } from "react";
import { DivElement, ElementBox, TextWrapper } from "../../../../../components/Reusable";
import TradeTypeNavbar from "./TradeTypeNavbar";
import MobileOrderForm from "./MobileOrderForm";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
// import PayFeeInLCX from "../../Components/PayFeeInLCX";
import BuyForm from "../../Components/BuyForm";
import PayFeeInLCX from "../../Components/PayFeeInLCX";

const OrderFormSection = () => {
  const { width } = useWindowSize();
  return (
    <Fragment>
      {width <= 767 ? (
        <MobileOrderForm />
      ) : (
        <ElementBox padding="20px 40px 32px 40px" boxShadow="none">
          <DivElement displayBtw>
            <TextWrapper fontSize="18px" lineHeight="21.6px">
              Order Form
            </TextWrapper>
            {width < 1024 && width > 767 && <PayFeeInLCX />}
          </DivElement>
          <TradeTypeNavbar />
          <DivElement flexJustifyBtw width="100%">
            <DivElement width="calc(50% - 26px)">
              <BuyForm orderFormSide="buy" />
            </DivElement>
            <DivElement width="calc(50% - 26px)">
              <BuyForm orderFormSide="sell" />
            </DivElement>
          </DivElement>
        </ElementBox>
      )}
    </Fragment>
  );
};

export default OrderFormSection;
