import React, { Fragment } from "react";
import { Range, getTrackBackground } from "react-range";
import { connect } from "react-redux";
import { compose } from "redux";

const RangeSlider = ({ total_tokens_sold, totalToken, tokenSaleCoin, isDarkTheme, percentage }) => {
  return (
    <Fragment>
      <div className="d-flex justify-content-between black-font" style={{ fontSize: "16px" }}>
        <output id="output">
          Sold: {total_tokens_sold} {tokenSaleCoin}
        </output>
        <span>
          Total: {totalToken ? totalToken : "NA"} {tokenSaleCoin}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap"
        }}
      >
        <Range
          values={[+percentage > 100 ? 100 : 0]}
          min={0}
          max={100}
          renderTrack={({ props, children }) => (
            <div
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%"
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "8px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: [+percentage],
                    colors: ["#548BF4", !isDarkTheme ? "#cde2f9" : "#112d51"],
                    min: 0,
                    max: 100
                  }),
                  alignSelf: "center"
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "22px",
                width: "22px",
                borderRadius: "50%",
                backgroundColor: !isDarkTheme ? "#047bf8" : "#70a6e0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA"
              }}
            >
              <div
                style={{
                  height: "16px",
                  width: "16px",
                  borderRadius: "50%",
                  backgroundColor: "#fff"
                }}
              />
            </div>
          )}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(RangeSlider);
