import React, { useEffect, useState } from "react";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import configs from "../../constants/config";
import { getDigitalAssets } from "../../lib/api";
import { formatAPIErrors } from "../../utils/general";
import { showErrorMsg } from "../../utils/notification";
import { NoRecordIllustration } from "../../assets/svgs/illustrations";
import { SearchIcon } from "../../assets/svgs";
import { AnchorTag } from "../../assets/StyleComponents/FontSize/para.style";
import { ImgElement } from "../../components/Reusable";

const AssetsList = (props) => {
  let { isTheme } = props;

  const [assetsDetails, setAssetsDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getDigitalAssets()
      .then((res) => {
        if (res.data.status === "success") {
          setAssetsDetails(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      });
  }, []);

  const renderList = () => {
    let filterArray = assetsDetails.data.filter((filterAsset) =>
      filterAsset.symbol.toLowerCase().includes(filter.toLowerCase())
    );
    if (filterArray && filterArray.length === 0)
      return (
        <BannerBox
          width="100%"
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <NoRecordIllustration height="100%" />
          <BannerHeading size="20px" sizeLg="16px">
            No Records Found
          </BannerHeading>
        </BannerBox>
      );
    else
      return filterArray
        .filter((data) => data.symbol.toLowerCase().includes(filter.toLowerCase()))
        .map((asset, i) =>
          asset.url !== "" ? (
            <AnchorTag
              rel="noreferrer"
              href={asset.url}
              target="_blank"
              display="flex"
              flex="0 0 33.33%"
              margin="1rem 0px"
              alignItems="center"
              justifyContent="flex-start"
              key={i}
            >
              <ImgElement
                alt="LCX Logo"
                marginRight="1rem"
                width="20"
                height="20"
                src={`${
                  !isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                }${asset.symbol.toLowerCase()}.svg`}
              />
              <BannerHeading size="18px" sizeMd="16px" BodyColor>
                {" "}
                {`${asset.coin} (${asset.symbol})`}{" "}
              </BannerHeading>
            </AnchorTag>
          ) : (
            <BannerBox
              key={i}
              display="flex"
              flex="0 0 33.33%"
              margin="1rem 0px"
              alignItems="center"
              justifyContent="flex-start"
            >
              <ImgElement
                alt="LCX Logo"
                marginRight="1rem"
                width="20"
                height="20"
                src={`${
                  !isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                }${asset.symbol.toLowerCase()}.svg`}
              />
              <BannerHeading size="18px" sizeMd="16px" BodyColor>
                {" "}
                {`${asset.coin} (${asset.symbol})`}{" "}
              </BannerHeading>
            </BannerBox>
          )
        );
  };

  return (
    <BannerBox CardWhite width="100%" padding="1rem 1.5rem" borderRadius="10px">
      <BannerBox
        display="flex"
        alignItems="center"
        alignItemsLg="start"
        justifyContent="space-between"
        padding="1.2rem 0px"
        direction="row"
        directionMd="column"
        gapLg="15px"
      >
        <BannerHeading size="22px" weight="bold" BodyColor mb="0px" sizeMd="16px">
          {" "}
          Name of Asset (Ticker Symbol){" "}
        </BannerHeading>

        <BannerBox ExcAssetsSearchBarParent display="flex" position="relative">
          <input
            onChange={(e) => setFilter(e.target.value)}
            style={{
              borderRadius: "0px",
              padding: "8px 40px 8px 5px",
              fontSize: "1rem",
              borderBottom: "1px solid grey",
              background: "transparent"
            }}
            placeholder="Search Assets"
            type="text"
          />
          <BannerHeadingSpan position="absolute" right="0px" top="11px">
            <SearchIcon width="18px" height="18px" />
          </BannerHeadingSpan>
        </BannerBox>
      </BannerBox>

      <BannerBox
        display="grid"
        mt="1rem"
        gridCols="repeat(3,1fr)"
        gridColsLg="repeat(2,1fr)"
        gridColsMd="1fr"
      >
        {!isLoading && assetsDetails.data && assetsDetails.data.length > 0 && renderList()}
      </BannerBox>
    </BannerBox>
  );
};

export default AssetsList;
