/* eslint-disable no-console */

/* eslint-disable no-unused-vars */
import { fromJS } from "immutable";
import { RECENT_ALL_TRADE } from "./const.js";

export function createInitialState() {
  return fromJS({
    apiRecentTrades: []
  });
}

export function TradeRecentTradeReducer(state = createInitialState(), action) {
  switch (action.type) {
    case RECENT_ALL_TRADE:
      return fromJS({
        ...state.toJS(),
        apiRecentTrades: action.payload
      });

    default:
      return state;
  }
}
