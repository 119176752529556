
/* eslint-disable semi */
/* eslint-disable no-console */
/* eslint-disable no-empty */
import React, { Component } from "react";
import config from "../../constants/config";
import { connect } from "react-redux";
import { updatePrivateSocketBalance, updatePrivateSocketOrder } from "../../actions/trade";
import { showSuccessMsg } from "../../utils/notification";
// import { debounce } from "../../utils/helpers";
export const PrivateSocketConnection = React.createContext();

class PrivateSocketContexts extends Component {
  constructor(props) {
    super(props);
    this.socket = {};
    this.state = {};
    this.notification = [];
    this.ping_interval = null;
    this.reconnect_interval = null;
    this.retry_counter = 0;
    this.alertId = null;
  }

  showNotification = (parseData) => {
    //FIXME: remove unused code , comment out code, no extra next lines, no un used variable declearation
    let notification = "";
    if (parseData.data.Status == "CLOSED") {
      notification = `${parseData.data.OrderType == "LIMIT" ? "Limit" : "Market"} ${parseData.data.Side == "BUY" ? "Buy" : "Sell"
        } Order of ${parseData.data.Amount} ${parseData.data.Pair.split("/")[0]} is Executed`;
    } else if (parseData.data.Status == "PARTIAL") {
      notification = `${parseData.data.OrderType == "LIMIT" ? "Limit" : "Market"} ${parseData.data.Side == "BUY" ? "Buy" : "Sell"
        } Order of ${parseData.data.Amount} ${parseData.data.Pair.split("/")[0]
        } is Partially Executed`;
    } else if (parseData.data.Status == "OPEN") {
      notification = `${parseData.data.OrderType == "LIMIT" ? "Limit" : "Market"} ${parseData.data.Side == "BUY" ? "Buy" : "Sell"
        } Order ${parseData?.method === "modify" ? "Updated" : "Created"} `;
      // notification = 'Order Placed Successfully.';
    } else if (parseData.data.Status == "CANCEL") {
      // notification = 'Order Cancelled.';
    }
    if (notification) {
      // if(this.notification && this.notification.length > 2) {
      //   alert.remove(this.notification[0]);
      //   this.notification.shift();
      // }
      // else {
      //   const id = alert.show(notification, {
      //     timeout: 4000,
      //     type: "success"
      //   });
      //   this.notification = [...this.notification, id];
      // }

      showSuccessMsg(notification, {
        limit: "3"
      });
    }
  };

  // Init Calling of socket
  SocketConnection = (token, UserId) => {
    this.socket = new WebSocket(config.privateSocketUrl + `?authorization=${token}`);
    let constSocket = this.socket;
    if(this.socket.readyState === 3) return this.SocketConnection(token, UserId);
    this.socket.onmessage = (msg) => {
      let parseData = {};
      try {
        parseData = JSON.parse(msg.data);
      } catch (e) { }
      if (!Object.keys(parseData).length) return;
      if (parseData.type === "user_orders") {
        this.showNotification(parseData);
        this.props.updatePrivateSocketOrder(parseData.data);
      } else if (parseData.type === "user_wallets") {
        this.props.updatePrivateSocketBalance(parseData.data);
      }
    };

    this.socket.onclose = (data) => {
      this.setState({ tickerSocketStatus: false });
      if (this.ping_interval) clearInterval(this.ping_interval);
      if (this.reconnect_interval) clearInterval(this.reconnect_interval);
      let set_timeout_time = 1000;
      // if (this.retry_counter < 5) {
      setTimeout(() => {
        if (!this.state.tickerSocketStatus) this.SocketConnection(token, UserId);
      }, set_timeout_time);
        // this.retry_counter++;
      // }
    };

    this.socket.onopen = (data) => {
      this.setState({ tickerSocketStatus: true });
      this.startAllSockets(UserId);
      constSocket = this.socket;
      this.ping_interval = setInterval(function () {
        if (constSocket) constSocket.send(JSON.stringify({ Topic: "ping" }));
      }, 30000);
    };

    if (this.socket.readyState) this.startAllSockets(UserId);
  };

  startAllSockets = (UserId) => {
    if (this.socket.readyState != this.socket.OPEN) return;
    this.socket.send(
      JSON.stringify({
        // UserId,
        Topic: "subscribe",
        Type: "user_wallets"
      })
    );
    this.socket.send(
      JSON.stringify({
        // UserId,
        Topic: "subscribe",
        Type: "user_orders"
      })
    );
  };

  // Close Socket Connection
  CloseSocketConnection = (UserId) => {
    if (this.socket && this.socket.readyState) {
      this.socket.send(
        JSON.stringify({
          // UserId,
          Topic: "unsubscribe",
          Type: "user_wallets"
        })
      );
      this.socket.send(
        JSON.stringify({
          // UserId,
          Topic: "unsubscribe",
          Type: "user_orders"
        })
      );
    }
  };

  render() {
    return (
      <PrivateSocketConnection.Provider
        value={{
          socketStatus: this.socket,
          startSocketConection: this.SocketConnection,
          socketDisconnect: this.CloseSocketConnection
        }}
      >
        {this.props.children}
      </PrivateSocketConnection.Provider>
    );
  }
}

// Call To Map To Props
const maptoStateProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updatePrivateSocketOrder: (user) => dispatch(updatePrivateSocketOrder(user)),
  updatePrivateSocketBalance: (user) => dispatch(updatePrivateSocketBalance(user))
});

export const PrivateSocketContext = connect(maptoStateProps, mapDispatchToProps)(React.memo(PrivateSocketContexts));

export const PrivateSocketConnetionConsumer = PrivateSocketConnection.Consumer;
