import React, { useEffect, useState } from "react";
import {
  BannerBox,
  BannerSection,
  BannerHeadingSpan
} from "../../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../../assets/StyleComponents/FontSize/headingFourth.style";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { debounce } from "../../../utils/helpers";
import { getParticipatedList } from "../../../lib/api";
import { showErrorMsg } from "../../../utils/notification";
import { useHistory } from "react-router-dom";
import { formatAPIErrors } from "../../../utils/general";
// import { TableComponent } from "../../../assets/StyleComponents/Table/Table.style";
// import {
//   TableBodyComponent,
//   TableHeadComponent
// } from "../../../assets/StyleComponents/Table/TableHead.style";
import { TradingCompRow } from "../../../assets/StyleComponents/Table/Comptition/tradecomp.style";
import CountTimerInWords from "../../../components/CountDownTimer/countDownTimer";
import DollarImageComponent from "../../../components/DollarImage/dollarImage";
import moment from "moment";
import { checkThousand, numberWithCommas } from "../../../utils/general";
import { TrophyIllustration } from "../../../assets/svgs/illustrations";
import NoRecordsFound from "../../../components/NoRecordsFound/noRecordsFound";
import { Table, Thead, Th, Tbody, Td, ThDropdown } from "../../../components/Reusable";
import { TableLoader } from "../../../components/Loader";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";

const ParticipatedComponent = () => {
  const [activeState, setActiveState] = useState("ongoing");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { width } = useWindowSize();
  // eslint-disable-next-line no-unused-vars
  const [dropdownOptions, setDropdownOptions] = useState(["Rank", "Ends In"]);
  const [activeCol, setActiveCol] = useState("Rank");
  // const userData = useSelector(state => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");


  const callApi = () => {
    setLoading(true);
    getParticipatedList(activeState)
      .then((res) => {
        setList(res.data.data || []);
        setLoading(false);
      })
      .catch((e) => {
        showErrorMsg(formatAPIErrors(e));
      });
  };

  useEffect(() => {
    callApi();
    // eslint-disable-next-lin
  }, [activeState]);

  const handleActiveState = debounce((givenName) => {
    setActiveState(givenName);
  }, 700);

  const handleCompetitionLink = (id) => {
    history.push(`${prevLang ? `/${prevLang}` : "/en"}/trading-competition/${id}`);
  };

  return (
    <BannerSection
      SectionWhite
      radius="5px"
      margin="16px 0 10px"
      padd={"24px 36px 45px 40px"}
      paddMd={"16px 16px 21px 16px"}
      mt="16px"
      mtMd="12px"
    >
      <BannerBox>
        <HeadingFourth size="20px" sizeLg="18px" sizeMd="16px">
          Participated Competitions
        </HeadingFourth>

        <BannerBox display="inline-flex" gap="8px" mt="24px">
          <ButtonComponent
            OutlineLightPrimary2={activeState === "ongoing" ? true : null}
            OutlineLightDefaultPrimary={activeState !== "ongoing" ? true : null}
            borderRadius="20px"
            btnHeight="auto"
            padd={"4px 16px"}
            paddingMd={"5px 12px"}
            sizeMd="12px"
            sizeLg="16px"
            onClick={() => handleActiveState("ongoing")}
          >
            Ongoing
          </ButtonComponent>

          <ButtonComponent
            OutlineLightPrimary2={activeState === "completed" ? true : null}
            OutlineLightDefaultPrimary={activeState !== "completed" ? true : null}
            borderRadius="20px"
            btnHeight="auto"
            padd={"4px 16px"}
            paddingMd={"5px 12px"}
            sizeMd="12px"
            sizeLg="16px"
            onClick={() => handleActiveState("completed")}
          >
            Completed
          </ButtonComponent>
        </BannerBox>

        <BannerBox mt="40px" display="flex" width="100%">
          <Table bRadius width="100%" marginBottom="16px" TableBodyRowHeight>
            <Thead height="40px" fontSize="14px" fontSizeMd="12px">
              <TradingCompRow displayFlex OrdersPagesHeadRow>
                <Th scope="col" padding="0px 8px" nowrap>
                  Token name
                </Th>
                <Th scope="col" textAlignSm="right">
                  Trading volume
                </Th>
                {width > 767 && <Th scope="col">Rank</Th>}
                {width > 767 && <Th scope="col">Ends in</Th>}
                {width < 768 && (
                  <ThDropdown
                    textAlign={"right"}
                    OPTIONS={dropdownOptions}
                    activeCol={activeCol}
                    selectOption={setActiveCol}
                  />
                )}
              </TradingCompRow>
            </Thead>

            <Tbody>
              {loading && <TableLoader rows={5} webCol={4} tabletCol={4} mobileCol={4} />}
              {!loading && list && list.length < 1 && (
                <BannerBox margin="15px 0px">
                  <NoRecordsFound text="Not participated yet. Participate now!" />
                </BannerBox>
              )}

              {!loading &&
                list &&
                list.length > 0 &&
                list.map((item) => {
                  return (
                    <TradingCompRow
                      displayFlex
                      key={item.competitionId}
                      onClick={() => handleCompetitionLink(item?.competitionId)}
                      style={{ cursor: "pointer" }}
                    >
                      <Td padding="0px 8px">{item.token}</Td>
                      <Td textAlignSm="right" paddingSm="0px 8px">
                        {/* {width < 767 && */}
                        <>
                          <DollarImageComponent
                            volumeStatus={true}
                            givenClass={"hght-14 ml-1"}
                            DollarAmount={numberWithCommas(checkThousand(item.volumeTradedUSD))}
                          />
                        </>
                        {/* } */}
                      </Td>
                      {width > 767 && (
                        <Td
                          fontWeight={
                            (item?.rank === 1 || item?.rank === 2 || item?.rank === 3) && "bold"
                          }
                        >
                          {item.rank}
                          {(item?.rank === 1 || item?.rank === 2 || item?.rank === 3) && (
                            <BannerHeadingSpan ml="8px">
                              <TrophyIllustration width="20px" height="20px" />
                            </BannerHeadingSpan>
                          )}
                        </Td>
                      )}
                      {width < 768 && activeCol === "Rank" && (
                        <Td
                          paddingSm="0px 8px"
                          fontWeight={
                            (item?.rank === 1 || item?.rank === 2 || item?.rank === 3) && "bold"
                          }
                        >
                          {item.rank}
                          {(item?.rank === 1 || item?.rank === 2 || item?.rank === 3) && (
                            <BannerHeadingSpan ml="8px">
                              <TrophyIllustration width="20px" height="20px" />
                            </BannerHeadingSpan>
                          )}
                        </Td>
                      )}
                      {width < 768 && activeCol === "Ends In" && (
                        <Td paddingSm="0px 8px" textAlignSm="right">
                          {activeState === "ongoing" && (
                            <CountTimerInWords
                              eventTime={activeState === "ongoing" && item.to}
                              type="Cardcounter"
                              gap="1px"
                            />
                          )}
                          {activeState === "completed" && moment(item?.To).format("DD/MM/YYYY")}
                        </Td>
                      )}
                      {width > 767 && (
                        <Td>
                          {activeState === "ongoing" && (
                            <CountTimerInWords
                              eventTime={activeState === "ongoing" && item.to}
                              type="Cardcounter"
                              gap="1px"
                            />
                          )}
                          {activeState === "completed" && moment(item?.To).format("DD/MM/YYYY")}
                        </Td>
                      )}
                    </TradingCompRow>
                  );
                })}
            </Tbody>
          </Table>
        </BannerBox>
      </BannerBox>
    </BannerSection>
  );
};

export default ParticipatedComponent;
