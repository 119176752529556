/**
 * Import Other Dependicies
 */
import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  isLogoutFetching: false,
  getUserData: {},
  userData: "",
  isFetchingUserData: true,
  walletBalance: [],
  walletTotalBalance: {},
  isFetchingGetWalletBalance: false,
  recentActivity: [],
  recentActivityCount: null,
  isFetchingRecentActivity: false,
  loading: false,
  isRedirect: false,
  isVerified: false,
  isWholeLoading: false,
  errorGet: false,
  exchangeWallet: ""
};

let initialState = initialStateSchema;
const localData = localStorage.getItem("accessToken");

if (localData && localData !== undefined) {
  try {
    initialState.authToken = localData;
  } catch (e) {
    initialState = initialStateSchema;
  }
}

const dashboardReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.ALL_LOADING:
      newState = {
        ...state,
        isWholeLoading: true,
        errorGet: false
      };
      return newState;

    case apiConstants.ALL_LOADING_SUCCESS:
      newState = {
        ...state,
        isWholeLoading: false,
        errorGet: false
      };
      return newState;

    case apiConstants.ALL_LOADING_FAIL:
      newState = {
        ...state,
        isWholeLoading: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_USER_DETAILS_DATA:
      newState = {
        ...state,
        isFetchingUserData: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_USER_DETAILS_DATA_SUCCESS:
      newState = {
        ...state,
        getUserData: action.response.data,
        userData: action.response.data,
        isFetchingUserData: false,
        errorGet: false,
        invalidFields: action.response.invalidFields
      };
      return newState;

    case apiConstants.GET_USER_DETAILS_DATA_FAIL:
      newState = {
        ...state,
        isFetchingUserData: false,
        errorGet: false
      };
      return newState;

    case apiConstants.UPDATE_USER_DATA:
      newState = {
        ...state,
        isFetchingUserData: true,
        errorGet: false
      };
      return newState;

    case apiConstants.UPDATE_USER_DATA_SUCCESS:
      newState = {
        ...state,
        getUserData: action.response,
        userData: action.response,
        isFetchingUserData: false,
        errorGet: false,
        invalidFields: action.response.invalidFields
      };
      return newState;

    case apiConstants.UPDATE_USER_DATA_FAIL:
      newState = {
        ...state,
        isFetchingUserData: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_WALLET_BALANCE_DATA:
      newState = {
        ...state,
        isFetchingGetWalletBalance: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_WALLET_BALANCE_DATA_SUCCESS:
      newState = {
        ...state,
        exchangeWallet: action.response,
        walletBalance: action.response.data,
        walletTotalBalance: action.response.totalBalance,
        isFetchingGetWalletBalance: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_WALLET_BALANCE_DATA_FAIL:
      newState = {
        ...state,
        isFetchingGetWalletBalance: false,
        errorGet: false
      };

      return newState;

    case apiConstants.UPDATE_USER_EXCHANGE_WALLET:
      newState = {
        ...state,
        exchangeWallet: action.payload,
        walletBalance: action.payload.data,
        walletTotalBalance: action.payload.totalBalance
      };

      return newState;

    case apiConstants.UPDATE_SOCKET_BALANCE_SUCCESS:
      newState = {
        ...state,
        walletBalance: action.response.data,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_RECENT_ACTIVITY_DATA:
      newState = {
        ...state,
        isFetchingRecentActivity: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_RECENT_ACTIVITY_DATA_SUCCESS:
      newState = {
        ...state,
        recentActivity: action.response.data,
        recentActivityCount: action.response.count,
        isFetchingRecentActivity: false,
        errorGet: false,
        recentLogins: action.response
      };
      return newState;

    case apiConstants.GET_RECENT_ACTIVITY_DATA_FAIL:
      newState = {
        ...state,
        isFetchingRecentActivity: false,
        errorGet: false
      };
      return newState;

    case apiConstants.ERROR_500:
      newState = {
        ...state,
        errorGet: true
      };
      return newState;

    default:
      return state;
  }
};

export default dashboardReducer;
