import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { AccordianCard } from "../../../assets/StyleComponents/AccordionCard.style";
import { BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import LcxTokenText from "./lcxToken";
import { ExpandMoreIcon } from "../../../assets/svgs";

const MobileLcxTokenText = (props) => (
  <div className="d-flex flex-column w-100">
    <Accordion defaultActiveKey="0">
      <AccordianCard>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <BannerHeadingSpan className="f-lg-14 f-sm-12">
            Why should I buy LCX Token?
          </BannerHeadingSpan>

          <BannerHeadingSpan widthLg="24px" widthSm="15px" className="pull-right">
            <ExpandMoreIcon />
          </BannerHeadingSpan>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <LcxTokenText {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </AccordianCard>
    </Accordion>
  </div>
);

export default MobileLcxTokenText;
