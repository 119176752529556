import { css } from "styled-components";
import { createResponsiveStyles } from "../GlobalStyle/DynamicResponsiveDesign/DynamicResponsiveDesign.style";

const PaddingStyle = css`
  ${({ pt, ptXl, ptLg, ptMd, ptSm }) => (pt || ptXl || ptLg || ptMd || ptSm) && 
    createResponsiveStyles("pt", "padding-top", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : pt 
    )
  }

  ${({ pb, pbXl, pbLg, pbMd, pbSm }) => (pb || pbXl || pbLg || pbMd || pbSm) && 
    createResponsiveStyles("pb", "padding-bottom", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : pb 
    )
  }

  ${({ pl, plXl, plLg, plMd, plSm }) => (pl || plXl || plLg || plMd || plSm) && 
    createResponsiveStyles("pl", "padding-left", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : pl 
    )
  }

  ${({ pr, prXl, prLg, prMd, prSm }) => (pr || prXl || prLg || prMd || prSm) && 
    createResponsiveStyles("pr", "padding-right", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : pr 
    )
  }
  
  ${({ px, pxXl, pxLg, pxMd, pxSm }) => (px || pxXl || pxLg || pxMd || pxSm) && css `
    ${createResponsiveStyles("px", "padding-left", (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : `${px} !important` )}
    ${createResponsiveStyles("px", "padding-right", (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : `${px} !important` )}
  `}

  ${({ py, pyXl, pyLg, pyMd, pySm }) => (py || pyXl || pyLg || pyMd || pySm) && css `
    ${createResponsiveStyles("py", "padding-top", (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : `${py} !important` )}
    ${createResponsiveStyles("py", "padding-bottom", (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : `${py} !important` )}
  `}

  ${({ padding, paddingXl, paddingLg, paddingMd, paddingSm }) => (padding || paddingXl || paddingLg || paddingMd || paddingSm) && 
    createResponsiveStyles("padding", "padding", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : `${padding} !important` 
    )
  }
`;

export { PaddingStyle };
