import { useSelector } from "react-redux";
import { TextWrapper } from "../../../../../components/Reusable";
import { useEffect, useState } from "react";
import { divideNumbers, multiplyNumbers, subtractNumbers } from "../../../../../utils/math";

const OrderSpread = () => {
  const [spreadData, setSpreadData] = useState("");
  const bestAsk = useSelector((state) => state.trading.bestAsk);
  const bestBid = useSelector((state) => state.trading.bestBid);

  useEffect(() => {
    if (bestAsk && bestBid) {
      let spread = subtractNumbers(bestAsk, bestBid);
      let num = divideNumbers(spread, bestAsk);
      let percentage = multiplyNumbers(num, 100);

      setSpreadData({
        spread: spread,
        percentage: percentage
      });
    } else {
      setSpreadData("");
    }
  }, [bestAsk, bestBid]);

  return (
    <TextWrapper fontWeight="400" lineHeight="20px">
      {spreadData
        ? `${spreadData.spread < 0 ? 0 : spreadData.spread} (${
            spreadData.percentage < 0 ? 0 : spreadData.percentage
          }%)`
        : "0 (0%)"}
    </TextWrapper>
  );
};

export default OrderSpread;
