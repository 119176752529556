import { fromJS } from "immutable";
import {
  ACCOUNT_WALLET_LIST,
  EXCHANGE_WALLET_UPDATE,
  ACCOUNT_IS_FECTHING,
  EXCHANGE_IS_FECTHING
} from "./const.js";

export function createInitialState() {
  return fromJS({
    accountBalanceList: [],
    exchangeBalanceList: [],
    accountTotalUsd: {},
    exchangeTotalUsd: {},
    isFetchingAccountWallet: true,
    isFetchingExchangeWallet: true
  });
}

export function WalletBalanceReducer(state = createInitialState(), action) {
  switch (action.type) {
    case ACCOUNT_IS_FECTHING:
      return fromJS({
        ...state.toJS(),
        isFetchingAccountWallet: action.payload
      });

    case EXCHANGE_IS_FECTHING:
      return fromJS({
        ...state.toJS(),
        isFetchingExchangeWallet: action.payload
      });

    case ACCOUNT_WALLET_LIST:
      return fromJS({
        ...state.toJS(),
        accountBalanceList: action.payload?.data || [],
        accountTotalUsd: action.payload?.totalBalance || {}
      });

    case EXCHANGE_WALLET_UPDATE:
      return fromJS({
        ...state.toJS(),
        exchangeBalanceList: action.payload?.data || [],
        exchangeTotalUsd: action.payload?.totalBalance || {}
      });

    default:
      return state;
  }
}
