import styled from "styled-components";
import Datetime from "react-datetime";

export const DatePickerStyles2 = styled(Datetime)`
  background: ${(props) => props.theme.inputBackground} !important;
  border-bottom: 1px solid ${(props) => props.theme.inputBorder};
  padding-left: ${(props) => props.theme.paddingLeft};
  position: relative;

  &: .font-bold {
    font-weight: bold;
  }

  .inputMain {
    width: 100% !important;
    text-decoration: none;
    border: none;
    height: 44px;
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
    color: ${(props) => props.theme.bodyText};

    &:focus {
      outline: 0px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }

  .rdtPicker {
    background: ${(props) => props.theme.level1Card};
    color: ${(props) => props.theme.bodyText};
    @media (max-width: 767px) {
      right: 0;
    }

    td.rdtYear:hover,
    td.rdtMonth:hover,
    .rdtPrev:hover,
    .rdtNext:hover,
    .rdtSwitch:hover,
    .rdtPicker td.rdtDay:hover {
      background: ${(props) => props.theme.primary} !important;
      color: white !important;
    }
  }
  .rdtPicker td.rdtOld {
    color: ${(props) => props.theme.bodyText};
  }
  .rdtPicker td.rdtActive {
    background: ${(props) => props.theme.primary} !important;
    color: white !important;
  }
  .rdtDisabled {
    color: ${(props) => props.theme.subText} !important;
  }

  // Light and Dark theme for react-phone-input-2 Country picker
`;
