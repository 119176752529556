import React, { useContext, useState } from "react";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import SkeletonLoader from "../../../../components/SkeletonLoader";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { useSelector } from "react-redux";
import { LiTag, AnchorTag } from "../../../../assets/StyleComponents/FontSize/para.style";

const ImportantPointFaq = () => {
  const [faqStatus, setFaqStatus] = useState(false);
  const { width } = useWindowSize();
  const isTheme = useSelector((state) => state.theme.isTheme);
  const { fqa, isFqaLoading, loadingImportantPoint, importantPoint } = useContext(DepositContext);
  return (
    <BannerBox
      widthLg="100%"
      widthMd="95%"
      display="flex"
      direction="column"
      CardWhite
      BorderRadius="10px"
      border
      mtMd="34px"
    >
      <BannerBox
        display="flex"
        justifyContent="space-between"
        width="100%"
        bck={isTheme ? "#393B40" : "#f5f6fa"}
      >
        <BannerBox
          width="50%"
          textAlign="center"
          cursor="pointer"
          padding="1rem 0px"
          BCKActive={faqStatus === false ? true : false}
          onClick={() => setFaqStatus(false)}
        >
          <ParaTag
            weight="500"
            size="14px"
            BodyColor={faqStatus !== false}
            txtPrimary={faqStatus === false}
          >
            {" "}
            Important Points{" "}
          </ParaTag>
        </BannerBox>

        <BannerBox
          width="50%"
          textAlign="center"
          cursor="pointer"
          padding="1rem 0px"
          BCKActive={faqStatus === true}
          onClick={() => setFaqStatus(true)}
        >
          <ParaTag
            weight="500"
            size="14px"
            BodyColor={faqStatus !== true}
            txtPrimary={faqStatus === true}
          >
            {" "}
            FAQs{" "}
          </ParaTag>
        </BannerBox>
      </BannerBox>

      <BannerBox CardWhite padding="20px" overflow="scroll" paddingMd="20px 10px">
        <ul style={{ paddingLeft: width < 767 && "15px" }}>
          {faqStatus ? (
            <>
              {isFqaLoading && (
                <BannerBox mt="1rem">
                  <SkeletonLoader
                    count={4}
                    width={width > 1024 ? 350 : width > 767 ? 540 : 200}
                    height={20}
                  />
                </BannerBox>
              )}
              {!isFqaLoading &&
                faqStatus &&
                fqa &&
                fqa.length > 0 &&
                fqa.map((item, index) =>
                  item.link ? (
                    <LiTag
                      key={index}
                      size="14px"
                      weight="300"
                      margin={index > 0 && "1rem 0px"}
                      BodyColor
                    >
                      <AnchorTag rel="noreferrer" href={item.link} target="_blank" BodyColor>
                        {item.question}
                      </AnchorTag>
                    </LiTag>
                  ) : (
                    <LiTag
                      key={index}
                      size="14px"
                      weight="300"
                      margin={index > 0 && "1rem 0px"}
                      BodyColor
                    >
                      {item.question}
                    </LiTag>
                  )
                )}
            </>
          ) : (
            <>
              {loadingImportantPoint && (
                <BannerBox mt="1rem">
                  <SkeletonLoader
                    count={4}
                    width={width > 1024 ? 350 : width > 767 ? 540 : 200}
                    height={20}
                  />
                </BannerBox>
              )}

              {!loadingImportantPoint &&
                !faqStatus &&
                importantPoint &&
                importantPoint.length > 0 &&
                importantPoint.map((item, index) => (
                  <LiTag
                    key={index}
                    size="14px"
                    weight="300"
                    txtDanger={item.markRed}
                    BodyColor={!item.markRed}
                    margin={index > 0 && "1rem 0px"}
                  >
                    {item.point}
                  </LiTag>
                ))}
            </>
          )}
        </ul>
      </BannerBox>
    </BannerBox>
  );
};

export default ImportantPointFaq;
