/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import {
  CampaignBannerDesktop,
  CampaignBannerMobile,
  CampaignBannerTablet
} from "../../../assets/png";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { LiTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { CheckCircleIcon } from "../../../assets/svgs";
import { H1Tag } from "../../../assets/StyleComponents/FontSize/fonts.style";

function TopBanner() {
  const { width } = useWindowSize();

  const Options = ["Sign up", "Complete KYC", "Deposit", "Trade"];
  return (
    <BannerSection>
      <BannerBox
        radius={"5px"}
        display="flex"
        background={`url(${CampaignBannerDesktop})`}
        bgLg={`url(${CampaignBannerTablet})`}
        bgMd={`url(${CampaignBannerMobile})`}
        height="330px"
        heightLg="303px"
        heightMd="300px"
        bgPosition="center"
        bgPositionLg="center"
        bgPositionMd="center"
        bgSize="cover"
        bgSizeLg="cover"
        bgSizeMd="cover"
        position="relative"
      >
        <BannerBox
          width={width > 1024 ? "65%" : "100%"}
          display="flex"
          direction="column"
          // alignItems="center"
          ml="3%"
          justifyContent="center"
        >
          {/* ++++++++++++++++++++ */}
          <H1Tag
            size="44px"
            weight="500"
            sizeLg="36px"
            sizeMd="24px"
            lineHeight="52px"
            lineHeightLg="43px"
            lineHeightMd="30px"
            textAlign={width < 767 && "center"}
            color="#FFFFFF"
          >
            Get Rewarded at <br /> Each Step
          </H1Tag>
          {/* +++++++++++++++++++++++++ */}
          {width < 768 && (
            <BannerBox
              topBorder="0.4px solid white"
              margin="13px auto"
              width="75%"
              mt="15px"
            ></BannerBox>
          )}
          {/* +++++++++++++++++++++++++++ */}
          <BannerBox mt="20px" mtLg="20px" mtMd="0px">
            <ul
              style={{
                // display: width > 767 ? "flex" : "grid",
                display: width < 767 ? "grid" : "flex",
                flexWrap: width < 767 && "wrap",
                justifyContent: width < 767 && "center",
                gap: width > 767 && "25px",
                gridTemplateColumns: "1fr 1fr",
                paddingLeft: "5px",
                textDecoration: "none",
                columnGap: width < 767 && "10px",
                rowGap: width < 767 && "20px"
              }}
            >
              {Options.map((values) => (
                <LiTag
                  key={values}
                  size="24px"
                  sizeLg="14px"
                  weight="500"
                  color="#FFFFFF"
                  display="flex"
                  gap="10px"
                  alignItems="center"
                >
                  <CheckCircleIcon /> {values}
                </LiTag>
              ))}
            </ul>
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </BannerSection>
  );
}

export default TopBanner;
