import { useContext, useMemo, useState } from "react";
import { DivElement } from "../../../../../components/Reusable";
import { numberInputOnWheelPreventChange, reactDropdownStyles } from "../../../../../utils";
import ShowErrorMessage from "../../../../../components/Reusable/ShowErrorMessage";
import {
  CustomOptionsForBeneficiary,
  DropdownIndicator
} from "../../../../../components/ReusedComponent/SelectReused";
import Select from "react-select";
import { useSelector } from "react-redux";
import { DepositContext } from "../../../../../contexts/WalletPage/DepositPage/depositContext";
import BankDetails from "./BankDetails";
import { useEffect } from "react";
import { checkThousand } from "../../../../../utils/general";
import { FormInput } from "../../../../../assets/StyleComponents/Input.style";
import { BannerBox, BannerHeadingSpan } from "../../../../../assets/StyleComponents/Exchange.style";
import { Label } from "../../../../../assets/StyleComponents/FontSize/para.style";

const formatBeneficiaryList = (array) => {
  return array.map((item, index) => {
    return {
      id: index,
      label: item.beneficiaryName,
      value: item
    };
  });
};

const WireTransfer = ({ coin, selectedProvider: PropProvider }) => {
  const [amount, setAmount] = useState("");
  const [amountErr, setAmountErr] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const { beneficaryList, selectedProvider } = useContext(DepositContext);
  const Provider = PropProvider ? PropProvider : selectedProvider;
  let minm = useMemo(() => Number(Provider?.value?.minAmount), [Provider]);
  let maxm = useMemo(() => Number(Provider?.value?.maxAmount), [Provider]);

  useEffect(() => {
    if (amount === "" && amountErr === "") {
      return;
    } else if (amount < minm) {
      setAmountErr(`Minimum deposit amount is ${checkThousand(+minm)} ${coin}.`);
    } else if (amount > maxm) {
      setAmountErr(`Maximum deposit amount is ${checkThousand(+maxm)} ${coin}.`);
    } else {
      setAmountErr("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const amountChangeHandler = (e) => {
    let amt = e.target.value;
    if (amt) {
      if (amt >= maxm * 100) {
        return;
      } else {
        let nums = amt.split(".");
        if (nums.length > 1 && nums[1].length > 2) return;
        setAmount(amt);
      }
    } else {
      setAmount("");
    }
  };

  return (
    <DivElement marginTop="32px">
      <DivElement width="100%" marginBottom="30px" className={`fInput__form__field `} relative>
        <Label>Amount to deposit</Label>
        <BannerBox
          height="48px"
          paddingRight="16px"
          fontSize="16px"
          fontSizeSm="14px"
          position="relative"
        >
          <FormInput
            type="number"
            placeholder="Enter Amount"
            value={amount}
            onChange={amountChangeHandler}
            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            onWheel={numberInputOnWheelPreventChange}
          />
          <BannerHeadingSpan BodyColor fontSize="16px" position="absolute" top="10px" right="10px">
            {coin}
          </BannerHeadingSpan>
        </BannerBox>
        <ShowErrorMessage
          type={amountErr ? "" : "warning"}
          message={
            amountErr
              ? amountErr
              : `The minimum amount is ${checkThousand(
                  Number(minm)
                )}, Remember the amount below ${checkThousand(Number(minm))} won't be refunded`
          }
          containerProps={{ marginTop: "4px", absolute: true }}
        />
      </DivElement>

      <DivElement width="100%" className="fInput__form__field" paddingTop="32px">
        <label>Select Beneficiary</label>
        <Select
          name="selectBeneficiary"
          placeholder="Select Bank"
          components={{ DropdownIndicator }}
          isSearchable={false}
          formatOptionLabel={CustomOptionsForBeneficiary}
          onChange={(val) => setSelectedBeneficiary(val)}
          styles={reactDropdownStyles({
            isDarkTheme,
            borderBottom: true,
            height: "48px"
          })}
          options={formatBeneficiaryList(beneficaryList)}
          value={selectedBeneficiary}
          minMenuHeight={200}
          maxMenuHeight={200}
          menuPlacement="auto"
        />
      </DivElement>

      {selectedBeneficiary && (
        <BankDetails
          details={selectedProvider?.details}
          amount={amount}
          coin={coin}
          beneficiary={selectedBeneficiary?.value}
          disabled={amountErr || !amount ? true : false}
        />
      )}
    </DivElement>
  );
};

export default WireTransfer;
