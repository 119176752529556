import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { Button, DivElement, ModalWrapper } from "../../../Reusable";
import {
  changeAntiPhisingCode,
  clearAntiphisingApidata,
  getAntiPhisingCode
} from "../../../../actions/Setting/setting";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { CloseIcon } from "../../../../assets/svgs";
import { PasswordInput, OtpInput } from "../../../Reusable/FormInput";
import { useRef } from "react";
import {
  SnowboardingIllustration,
  SuccessAppreciationIllustration
} from "../../../../assets/svgs/illustrations";
import { showSuccessMsg } from "../../../../utils/notification";
import { LoadingButton } from "../../../Loader";
import { TextWrapper } from "../../../Reusable";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { HeadingFourth } from "../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { HeadingSix } from "../../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";

const antiphisingValidation = Yup.object().shape({
  antiPhishingCode: Yup.string()
    .length(8, "Anti-phising code must be exactly 8 characters")
    .required("Anti-phishing code is required"),
  twoFA: Yup.string()
    .length(6, "2FA Code must be exactly 6 digits")
    .required("2FA Code is required")
});

const getInitialValues = () => {
  return {
    antiPhishingCode: "",
    twoFA: ""
  };
};

const GenerateCode = (props) => {
  const { onClose, apiLoader } = props;
  const [codeGenerated, setCodeGenerated] = useState(false);
  const formikRef = useRef();
  const { width } = useWindowSize();
  const isTheme = useSelector((state) => state.theme.isTheme);

  useEffect(() => {
    if (props.apiData) {
      showSuccessMsg(props.apiData.message);

      props.clearAntiphisingApidata();
      formikRef.current?.resetForm();
      props.getAntiPhisingCode();
      setCodeGenerated(true);
    }
    // eslint-disable-next-line
  }, [props.apiData]);

  const onSubmit = (values) => {
    let data = {
      antiPhishingCode: values.antiPhishingCode,
      twoFA: values.twoFA
    };
    props.updateAntiPhisingCode(data);
  };

  const inputStyle = {
    width: width > 767 ? "50px" : "40px",
    height: width > 767 ? "48px" : "44px",
    background: isTheme ? "#1f2c38" : "#f5f6fa",
    color: isTheme ? "#f5f6fa" : "#000",
    border: "none",
    borderRadius: "5px 5px 0 0",
    fontSize: width > 767 ? "24px" : "16px",
    borderBottom: isTheme ? "1px solid #969799" : "1px solid #8c9797",
    outline: "none"
  };

  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        {!codeGenerated ? (
          <Fragment>
            <DivElement displayBtw>
              <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
                Generate Anti-phishing Code
              </TextWrapper>
              <CloseIcon pointer onClick={onClose} />
            </DivElement>

            <BannerBox>
              <Formik
                innerRef={formikRef}
                initialValues={getInitialValues()}
                onSubmit={onSubmit}
                validateOnMount={true}
                validateOnChange={true}
                validationSchema={antiphisingValidation}
              >
                {({ values, setFieldValue, touched }) => (
                  <Form>
                    <PasswordInput
                      title="Enter Code"
                      name="antiPhishingCode"
                      value={values.antiPhishingCode}
                      placeholder="********"
                      subText={
                        touched.antiPhishingCode ? "" : "Please enter 8 alphanumeric characters"
                      }
                    />

                    <OtpInput
                      title="Enter 2FA Code"
                      name="twoFA"
                      value={values.twoFA}
                      setFieldValue={setFieldValue}
                      separator={
                        <BannerHeadingSpan mr="8px" mrLg="10px">
                          {" "}
                        </BannerHeadingSpan>
                      }
                      inputStyle={inputStyle}
                    />

                    <Button
                      marginTop="24px"
                      primaryBlue
                      width="100%"
                      height="48px"
                      type="submit"
                      disabled={apiLoader}
                    >
                      {apiLoader ? <LoadingButton /> : "Submit"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </BannerBox>
          </Fragment>
        ) : (
          <Fragment>
            <BannerBox display="flex" justifyContent="flex-end" cursor="pointer">
              <CloseIcon onClick={onClose} />
            </BannerBox>

            <BannerBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="8px"
              mb="20px"
            >
              <SuccessAppreciationIllustration width="36px" height="36px" />
              <HeadingFourth size="18px" weight="500">
                Code Generated
              </HeadingFourth>
            </BannerBox>

            <HeadingSix weight="300" size="14px" textAlign="center" margin="12px 0px">
              Hurray! Account is safe from phishing
            </HeadingSix>

            <BannerBox display="flex" justifyContent="center" alignItems="center">
              <SnowboardingIllustration width="100%" />
            </BannerBox>
          </Fragment>
        )}
      </DivElement>
    </ModalWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateAntiPhisingCode: (data) => dispatch(changeAntiPhisingCode(data)),
  clearAntiphisingApidata: () => dispatch(clearAntiphisingApidata()),
  getAntiPhisingCode: () => dispatch(getAntiPhisingCode())
});

const mapStateToProps = (state) => ({
  apiLoader: state.setting.apiLoader,
  error: state.setting.error,
  apiData: state.setting.updateApiData
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(GenerateCode);
