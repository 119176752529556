import React, { useEffect, useMemo } from "react";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";

import EarnBanner from "./EarnBanner";
import TokenizedBonds from "./TokenizedBonds";
import TokenizedBondsPublic from "./TokenizedBondsPublic";
import InvestmentHistory from "./InvesmentHistory";
import { trackPage } from "../../../../tracking/track";
import { DivElement } from "../../../../components/Reusable";
import { HalfFooterCommon } from "../../../../components/Footer/common";
import SetHeaderTitleComponent from "../../../../components/ChangeHeaderTitle";
import { getTranslationLanguageList } from "../../../../Modules/Generic/selectors";
import { fetchAvailableLanguagesRequest } from "../../../../Modules/Generic/action";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const EarnDashboard = ({ isLoggedIn }) => {
  const location = useLocation();
  // const history = useHistory();
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchAvailableLanguagesRequest());
      // let checkPath = location.pathname?.split("/")[1];
      // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
      //   history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/earn`);
      // }
    }
    trackPage(
      `${isLoggedIn && prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/earn${
        isLoggedIn ? "" : "/public"
      }`,
      "LCX Earn Page"
    );
  }, []);

  const userData = useSelector((state) => state.dashboard.userData);
  const { transalationList } = useSelector(GenericReducer);

  let SEOContents = {
    name: `EARN`,
    link: `earn`,
    title: "Earn Crypto with LCX Exchange: Your Premier Rewards Destination",
    description:
      "Earn crypto rewards efficiently with LCX Exchange, your trusted platform for secure transactions and reliable rewards. Explore our comprehensive services and start earning today!",
    keywords: `crypto earn, lcx earn, earn crypto, best crypto earn, crypto earn platforms, crypto earn platform`,
    canonical: `https://exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/earn`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${location.pathname}`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: "Earn Crypto with LCX Exchange: Your Premier Rewards Destination",
    OgDescription:
      "Earn crypto rewards efficiently with LCX Exchange, your trusted platform for secure transactions and reliable rewards. Explore our comprehensive services and start earning today!",
    OgURL: `https://www.exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/earn`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Earn Crypto with LCX Exchange: Your Premier Rewards Destination",
    TwDescription:
      "Earn crypto rewards efficiently with LCX Exchange, your trusted platform for secure transactions and reliable rewards. Explore our comprehensive services and start earning today!"
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang ? prevLang: userData.preferredLanguage))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/earn`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <DivElement pageContainer minHeight="84vh">
        <EarnBanner />
        {isLoggedIn ? <TokenizedBonds /> : <TokenizedBondsPublic />}
        {isLoggedIn && <InvestmentHistory />}
      </DivElement>
      <HalfFooterCommon />
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default compose(connect(mapStateToProps))(EarnDashboard);
