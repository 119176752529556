import config from "../config";

var errors = {
  REQUIRED_FIELD_IS_MISSNG: "Required field is missing",
  ENTER_EMAIL: "Please enter email address",
  ENTER_INVITATION_CODE: "Please enter Invitation Code",
  ENTER_VALID_EMAIL: "Please enter a valid email address",
  ENTER_PASSWORD: "Please enter password",
  ENTER_FIRST_NAME: "Please enter your first name",
  ENTER_LAST_NAME: "Please enter your last name",
  PASSWORD_MUST_CONTAIN:
    "Password must contain at least 8 characters and must contain at least one capital letter and one numeric character",
  ENTER_CONFIRM_PASSWORD: "Please enter confirm password",
  PASSWORD_DOES_NOT_MATCH: "Password does not match",
  LOGIN_FAILED:
    "Log in failed as the email address or the password that you have entered is incorrect",
  LOGIN_FAILED_EMAIL_NOT_VERIFIED: "Login failed as the email has not been verified",
  SOMETHING_WRONG: `Oops something went wrong please try again. Contact us ${config.platform.supportEmail}`,
  PASSWORD_LINK_EXPIRED:
    "Password reset link is expired. Link gets expire after 15 min of generation. Please generate a new one."
};

export default errors;
