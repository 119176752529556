const bondsProspectusData = {
  EURt7: [
    {
      type: "EURt7 Prospectus - Main Documents in German",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-01_Wertpapierbeschreibung_LCX_signed.pdf" target="_blank" rel="noopener">Wertpapierbeschreibung Deutsch – Liechtenstein</a>',
          subtitle: "Main Security Prospectus in German Language"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCX_EURt7_Registrierungsformular.pdf" target="_blank" rel="noopener">Registrierungsformular Deutsch – Liechtenstein</a>',
          subtitle: "Main Registration Form in German Language"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCX_EURt7_Endgultige_Bedingungen.pdf" target="_blank" rel="noopener">Endgültige Bedingungen Deutsch – Liechtenstein</a>',
          subtitle: "Main Final Terms in German Language"
        }
      ]
    },
    {
      type: "EURt7 Prospectus - English Translation",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Wertpapierbeschreibung_LCX-EURt7_signed.pdf" target="_blank" rel="noopener">Wertpapierbeschreibung English – General</a>',
          subtitle: "English Translation of Security Prospectus"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Registrierungsformular_LCX-EURt7.pdf" target="_blank" rel="noopener">Registrierungsformular English – General</a>',
          subtitle:
            "English Translation of Registration Document for Non-Dividend Securities for Retail Investors"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/EN_2023-02-01_Endgultige_Bedingungen_Nr_01_LCX-EURt7.pdf" target="_blank" rel="noopener">Endgültige Bedingungen English – Liechtenstein</a>',
          subtitle: "English Translation of Final Terms"
        },
        // Document
        {
          isDoc: true,
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/20230201-LCX-Earn-for-EURt7-Allowed-Countries.pdf" target="_blank" rel="noopener">DOCUMENT: Allowed and Prohibited Countries</a>',
          subtitle:
            "The document “LCX Tokenized Bond EURt7 – Allowed and Prohibited Countries” provides an overview of the countries where it is strictly prohibited and allowed to be sold. The document also includes example customer cases to explain who is allowed or prohibited of investing. "
        }
      ]
    },
    {
      type: "EURt7 Prospectus - Translations",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/DE-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">AUSTRIA: Final Conditions of EURt7 Security Prospectus in German</a>'
        },
        {
          link: 'BELGIUM: Final Conditions of EURt7 Security Prospectus in <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/French-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">French</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Dutch-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">Dutch</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Bulgarian-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">BULGARIA: Final Conditions of EURt7 Security Prospectus in Bulgarian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Croatian-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.docx.pdf" target="_blank" rel="noopener">CROATIA: Final Conditions of EURt7 Security Prospectus in Croatian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Greek-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">CYPRUS: Final Conditions of EURt7 Security Prospectus in Greek</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Danish-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">DENMARK: Final Conditions of EURt7 Security Prospectus in Danish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Estonian-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">ESTONIA: Final Conditions of EURt7 Security Prospectus in Estonian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Finnish-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">FINLAND: Final Conditions of EURt7 Security Prospectus in Finnish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/French-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">FRANCE: Final Conditions of EURt7 Security Prospectus in French</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/DE-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">GERMANY: Final Conditions of EURt7 Security Prospectus in German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Greek-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">GREECE: Final Conditions of EURt7 Security Prospectus in Greek</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Hungarian-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">HUNGARY: Final Conditions of EURt7 Security Prospectus in Hungarian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Icelandic-EN-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.docx.pdf" target="_blank" rel="noopener">ICELAND: Final Conditions of EURt7 Security Prospectus in Icelandic</a>'
        },
        {
          link: 'IRELAND: Final Conditions of EURt7 Security Prospectus in <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">English</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Irish-gaelic-EN-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.docx.pdf" target="_blank" rel="noopener">Irish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Italian-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">ITALY: Final Conditions of EURt7 Security Prospectus in Italian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Latvian-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.docx.pdf" target="_blank" rel="noopener">LATVIA: Final Conditions of EURt7 Security Prospectus in Latvian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/DE-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">LIECHTENSTEIN: Final Conditions of EURt7 Security Prospectus in German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Lithuanian-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">LITHUANIA: Final Conditions of EURt7 Security Prospectus in Lithuanian</a>'
        },
        {
          link: 'LUXEMBOURG: Final Conditions of EURt7 Security Prospectus in <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/French-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">French</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/DE-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Maltese-EN-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.docx.pdf" target="_blank" rel="noopener">MALTA: Final Conditions of EURt7 Security Prospectus in Maltese</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Dutch-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">NETHERLANDS: Final Conditions of EURt7 Security Prospectus in Dutch</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Norwegian-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">NORWAY: Final Conditions of EURt7 Security Prospectus in Norwegian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Polish-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">POLAND: Final Conditions of EURt7 Security Prospectus in Polish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Portuguese-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">PORTUGAL: Final Conditions of EURt7 Security Prospectus in Portuguese</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Romanian-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">ROMANIA: Final Conditions of EURt7 Security Prospectus in Romanian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Slovak-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">SLOVAKIA: Final Conditions of EURt7 Security Prospectus in Slovak</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Slovenian-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">SLOVENIA: Final Conditions of EURt7 Security Prospectus in Slovenian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Spanish-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">SPAIN: Final Conditions of EURt7 Security Prospectus in Spanish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Swedish-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">SWEDEN: Final Conditions of EURt7 Security Prospectus in Swedish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/Czech-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">CZECH REPUBLIC: Final Conditions of EURt7 Security Prospectus in Czech</a>'
        }
      ]
    }
  ],
  BTCt7: [
    {
      type: "BTCt7 Prospectus - Main Documents in German",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-01_Wertpapierbeschreibung_LCX_signed.pdf" target="_blank" rel="noopener">Wertpapierbeschreibung Deutsch – Liechtenstein</a>',
          subtitle: "Main Security Prospectus in German Language"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCX_EURt7_Registrierungsformular.pdf" target="_blank" rel="noopener">Registrierungsformular Deutsch – Liechtenstein</a>',
          subtitle: "Main Registration Form in German Language"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-20_Endgultige_Bedingungen-Nr-2_LCX-BTCt7-Clean-signed.pdf">Endgultige Bedingungen Deutsch Liechtenstein</a>',
          subtitle: "Main Final Terms in German Language"
        }
      ]
    },
    {
      type: "BTCt7 Prospectus - English Translation",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Wertpapierbeschreibung_LCX-EURt7_signed.pdf" target="_blank" rel="noopener">Wertpapierbeschreibung English – General</a>',
          subtitle: "English Translation of Security Prospectus"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Registrierungsformular_LCX-EURt7.pdf" target="_blank" rel="noopener">Registrierungsformular English – General</a>',
          subtitle:
            "English Translation of Registration Document for Non-Dividend Securities for Retail Investors"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-20_Endgultige_Bedingungen-Nr-2_LCX-BTCt7-Clean-signed-en-GB.pdf">Endgultige Bedingungen English Translation</a>',
          subtitle: "English Translation of Final Terms"
        },
        //   Document
        {
          isDoc: true,
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/20230201-LCX-Earn-for-EURt7-Allowed-Countries.pdf" target="_blank" rel="noopener">DOCUMENT: Allowed and Prohibited Countries</a>',
          subtitle:
            "The document “LCX Tokenized Bond BTCt7 – Allowed and Prohibited Countries” provides an overview of the countries where it is strictly prohibited and allowed to be sold. The document also includes example customer cases to explain who is allowed or prohibited of investing. "
        }
      ]
    },
    {
      type: "BTCt7 Prospectus - Translations",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Deutch.pdf">AUSTRIA: Final Conditions of BTCt7 Security Prospectus in German</a>'
        },
        {
          link: 'BELGIUM: Final Conditions of BTCt7 Security Prospectus in <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_French.pdf">French</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Dutch.pdf" target="_blank" rel="noopener">Dutch</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Bulgarian.pdf">BULGARIA: Final Conditions of BTCt7 Security Prospectus in Bulgarian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-20_Endgultige_Bedingungen-Nr-2_LCX-BTCt7-Clean-signed-en-GB.pdf">CROATIA: Final Conditions of BTCt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Greek.pdf">CYPRUS: Final Conditions of BTCt7 Security Prospectus in Greek</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Danish.pdf">DENMARK: Final Conditions of BTCt7 Security Prospectus in Danish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Estonian.pdf">ESTONIA: Final Conditions of BTCt7 Security Prospectus in Estonian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Finnish.pdf">FINLAND: Final Conditions of BTCt7 Security Prospectus in Finnish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_French.pdf">FRANCE: Final Conditions of BTCt7 Security Prospectus in French</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Deutch.pdf">GERMANY: Final Conditions of BTCt7 Security Prospectus in German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Greek.pdf">GREECE: Final Conditions of BTCt7 Security Prospectus in Greek</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Hungarian.pdf">HUNGARY: Final Conditions of BTCt7 Security Prospectus in Hungarian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-20_Endgultige_Bedingungen-Nr-2_LCX-BTCt7-Clean-signed-en-GB.pdf">ICELAND: Final Conditions of BTCt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-20_Endgultige_Bedingungen-Nr-2_LCX-BTCt7-Clean-signed-en-GB.pdf">IRELAND: Final Conditions of BTCt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Italian.pdf">ITALY: Final Conditions of BTCt7 Security Prospectus in Italian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Latvian.pdf">LATVIA: Final Conditions of BTCt7 Security Prospectus in Latvian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Deutch.pdf" target="_blank" rel="noopener">LIECHTENSTEIN: Final Conditions of BTCt7 Security Prospectus in German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Lithuanian.pdf">LITHUANIA: Final Conditions of BTCt7 Security Prospectus in Lithuanian</a>'
        },
        // Diff
        {
          link: 'LUXEMBOURG: Final Conditions of BTCt7 Security Prospectus in <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_French.pdf" target="_blank" rel="noopener">French</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Deutch.pdf" target="_blank" rel="noopener">German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-20_Endgultige_Bedingungen-Nr-2_LCX-BTCt7-Clean-signed-en-GB.pdf">MALTA: Final Conditions of BTCt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Dutch.pdf">NETHERLANDS: Final Conditions of BTCt7 Security Prospectus in Dutch</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Norwegian.pdf">NORWAY: Final Conditions of BTCt7 Security Prospectus in Norwegian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Polish.pdf">POLAND: Final Conditions of BTCt7 Security Prospectus in Polish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Portuguese.pdf">PORTUGAL: Final Conditions of BTCt7 Security Prospectus in Portuguese</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Romanian.pdf">ROMANIA: Final Conditions of BTCt7 Security Prospectus in Romanian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Slovak.pdf">SLOVAKIA: Final Conditions of BTCt7 Security Prospectus in Slovak</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Slovenian.pdf">SLOVENIA: Final Conditions of BTCt7 Security Prospectus in Slovenian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Spanish.pdf">SPAIN: Final Conditions of BTCt7 Security Prospectus in Spanish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Swedish.pdf">SWEDEN: Final Conditions of BTCt7 Security Prospectus in Swedish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/BTCt7_Prosectus_Czech.pdf">CZECH REPUBLIC: Final Conditions of BTCt7 Security Prospectus in Czech</a>'
        }
      ]
    }
  ],
  ETHt7: [
    {
      type: "ETHt7 Prospectus - Main Documents in German",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-01_Wertpapierbeschreibung_LCX_signed.pdf" target="_blank" rel="noopener">Wertpapierbeschreibung Deutsch – Liechtenstein</a>',
          subtitle: "Main Security Prospectus in German Language"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCX_EURt7_Registrierungsformular.pdf" target="_blank" rel="noopener">Registrierungsformular Deutsch – Liechtenstein</a>',
          subtitle: "Main Registration Form in German Language"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Deutch.pdf" target="_blank" rel="noopener">Endgültige Bedingungen Deutsch – Liechtenstein</a>',
          subtitle: "Main Final Terms in German Language"
        }
      ]
    },
    {
      type: "ETHt7 Prospectus - English Translation",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Wertpapierbeschreibung_LCX-EURt7_signed.pdf" target="_blank" rel="noopener">Wertpapierbeschreibung English – General</a>',
          subtitle: "English Translation of Security Prospectus"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Registrierungsformular_LCX-EURt7.pdf" target="_blank" rel="noopener">Registrierungsformular English – General</a>',
          subtitle:
            "English Translation of Registration Document for Non-Dividend Securities for Retail Investors"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_English.pdf" target="_blank" rel="noopener">Endgültige Bedingungen English – Liechtenstein</a>',
          subtitle: "English Translation of Final Terms"
        },
        // Document
        {
          isDoc: true,
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/20230201-LCX-Earn-for-EURt7-Allowed-Countries.pdf" target="_blank" rel="noopener">DOCUMENT: Allowed and Prohibited Countries</a>',
          subtitle:
            "The document “LCX Tokenized Bond ETHt7 – Allowed and Prohibited Countries” provides an overview of the countries where it is strictly prohibited and allowed to be sold. The document also includes example customer cases to explain who is allowed or prohibited of investing. "
        }
      ]
    },
    {
      type: "ETHt7 Prospectus - Translations",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Deutch.pdf">AUSTRIA: Final Conditions of ETHt7 Security Prospectus in German</a>'
        },
        {
          link: 'BELGIUM: Final Conditions of ETHt7 Security Prospectus in <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_French.pdf">French</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Dutch.pdf">Dutch</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prosectus_Bulgarian.pdf">BULGARIA: Final Conditions of ETHt7 Security Prospectus in Bulgarian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_English.pdf" target="_blank" rel="noopener">CROATIA: Final Conditions of ETHt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Greek.pdf">CYPRUS: Final Conditions of ETHt7 Security Prospectus in Greek</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Danish.pdf">DENMARK: Final Conditions of ETHt7 Security Prospectus in Danish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Estonian.pdf">ESTONIA: Final Conditions of ETHt7 Security Prospectus in Estonian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Finnish.pdf">FINLAND: Final Conditions of ETHt7 Security Prospectus in Finnish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_French.pdf">FRANCE: Final Conditions of ETHt7 Security Prospectus in French</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Deutch.pdf" target="_blank" rel="noopener">GERMANY: Final Conditions of ETHt7 Security Prospectus in German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Greek.pdf">GREECE: Final Conditions of ETHt7 Security Prospectus in Greek</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Hungarian.pdf">HUNGARY: Final Conditions of ETHt7 Security Prospectus in Hungarian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_English.pdf" target="_blank" rel="noopener">ICELAND: Final Conditions of ETHt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_English.pdf">IRELAND: Final Conditions of ETHt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Italian.pdf">ITALY: Final Conditions of ETHt7 Security Prospectus in Italian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Latvian.pdf">LATVIA: Final Conditions of ETHRt7 Security Prospectus in Latvian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Deutch.pdf" target="_blank" rel="noopener">LIECHTENSTEIN: Final Conditions of ETHt7 Security Prospectus in German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Lithuanian.pdf">LITHUANIA: Final Conditions of ETHt7 Security Prospectus in Lithuanian</a>'
        },
        {
          link: 'LUXEMBOURG: Final Conditions of ETHt7 Security Prospectus in <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/French-2023-02-01_Endgultige_Bedingungen-Nr-01_LCX-EURt7.pdf" target="_blank" rel="noopener">French</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Deutch.pdf" target="_blank" rel="noopener">German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_English.pdf" target="_blank" rel="noopener">MALTA: Final Conditions of ETHt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Dutch.pdf">NETHERLANDS: Final Conditions of ETHt7 Security Prospectus in Dutch</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Norwegian.pdf">NORWAY: Final Conditions of ETHt7 Security Prospectus in Norwegian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Polish.pdf">POLAND: Final Conditions of ETHt7 Security Prospectus in Polish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Portuguese.pdf">PORTUGAL: Final Conditions of ETHt7 Security Prospectus in Portuguese</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Romanian.pdf">ROMANIA: Final Conditions of ETHt7 Security Prospectus in Romanian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Slovak.pdf">SLOVAKIA: Final Conditions of ETHt7 Security Prospectus in Slovak</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Slovenian.pdf">SLOVENIA: Final Conditions of ETHt7 Security Prospectus in Slovenian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Spanish.pdf">SPAIN: Final Conditions of ETHt7 Security Prospectus in Spanish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Swedish.pdf">SWEDEN: Final Conditions of ETHt7 Security Prospectus in Swedish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/ETHt7_Prospectus_Czech.pdf" target="_blank" rel="noopener">CZECH REPUBLIC: Final Conditions of ETHt7 Security Prospectus in Czech</a>'
        }
      ]
    }
  ],
  LCXt7: [
    {
      type: "LCXt7 Prospectus - Main Documents in German",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-01_Wertpapierbeschreibung_LCX_signed.pdf" target="_blank" rel="noopener">Wertpapierbeschreibung Deutsch – Liechtenstein</a>',
          subtitle: "Main Security Prospectus in German Language"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCX_EURt7_Registrierungsformular.pdf" target="_blank" rel="noopener">Registrierungsformular Deutsch – Liechtenstein</a>',
          subtitle: "Main Registration Form in German Language"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-20_Endgultige_Bedingungen-Nr-4_LCX-LCXt7-clean-signed.pdf">Endgultige Bedingungen Deutsch Liechtenstien</a>',
          subtitle: "Main Final Terms in German Language"
        }
      ]
    },
    {
      type: "LCXt7 Prospectus - English Translation",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Wertpapierbeschreibung_LCX-EURt7_signed.pdf" target="_blank" rel="noopener">Wertpapierbeschreibung English – General</a>',
          subtitle: "English Translation of Security Prospectus"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/EN-2023-02-01_Registrierungsformular_LCX-EURt7.pdf" target="_blank" rel="noopener">Registrierungsformular English – General</a>',
          subtitle:
            "English Translation of Registration Document for Non-Dividend Securities for Retail Investors"
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/2023-02-20_Endgultige_Bedingungen-Nr-4_LCX-LCXt7-clean-signed-1.pdf">Endgultige Bedingungen English Liechtenstien</a>',
          subtitle: "English Translation of Final Terms"
        },
        // Document
        {
          isDoc: true,
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/20230201-LCX-Earn-for-EURt7-Allowed-Countries.pdf" target="_blank" rel="noopener">DOCUMENT: Allowed and Prohibited Countries</a>',
          subtitle:
            "The document “LCX Tokenized Bond LCXt7 – Allowed and Prohibited Countries” provides an overview of the countries where it is strictly prohibited and allowed to be sold. The document also includes example customer cases to explain who is allowed or prohibited of investing. "
        }
      ]
    },
    {
      type: "LCXt7 Prospectus - Translations",
      data: [
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Deutsch.pdf">AUSTRIA: Final Conditions of LCXt7 Security Prospectus in German</a>'
        },
        {
          link: 'BELGIUM: Final Conditions of LCXt7 Security Prospectus in <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_French.pdf">French</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Dutch.pdf">Dutch</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Bulgarian.pdf">BULGARIA: Final Conditions of LCXt7 Security Prospectus in Bulgarian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_English.pdf" target="_blank" rel="noopener">CROATIA: Final Conditions of LCXt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Greek.pdf">CYPRUS: Final Conditions of LCXt7 Security Prospectus in Greek</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Danish.pdf">DENMARK: Final Conditions of LCXt7 Security Prospectus in Danish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Estonian.pdf">ESTONIA: Final Conditions of LCXt7 Security Prospectus in Estonian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Finnish.pdf">FINLAND: Final Conditions of LCXt7 Security Prospectus in Finnish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_French.pdf">FRANCE: Final Conditions of LCXt7 Security Prospectus in French</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Deutsch.pdf">GERMANY: Final Conditions of LCXt7 Security Prospectus in German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Greek.pdf">GREECE: Final Conditions of LCXt7 Security Prospectus in Greek</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Hungarian.pdf">HUNGARY: Final Conditions of LCXt7 Security Prospectus in Hungarian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_English.pdf" target="_blank" rel="noopener">ICELAND: Final Conditions of LCXt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_English.pdf">IRELAND: Final Conditions of LCXt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Italian.pdf">ITALY: Final Conditions of LCXt7 Security Prospectus in Italian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Latvian.pdf">LATVIA: Final Conditions of LCXt7 Security Prospectus in Latvian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Deutsch.pdf">LIECHTENSTEIN: Final Conditions of LCXt7 Security Prospectus in German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Lithuanian.pdf">LITHUANIA: Final Conditions of LCXt7 Security Prospectus in Lithuanian</a>'
        },
        {
          link: 'LUXEMBOURG: Final Conditions of LCXt7 Security Prospectus in <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_French.pdf">French</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Deutsch.pdf">German</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_English.pdf" target="_blank" rel="noopener">MALTA: Final Conditions of LCXt7 Security Prospectus in English</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Dutch.pdf">NETHERLANDS: Final Conditions of LCXt7 Security Prospectus in Dutch</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Norwegian.pdf">NORWAY: Final Conditions of LCXt7 Security Prospectus in Norwegian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Polish.pdf">POLAND: Final Conditions of LCXt7 Security Prospectus in Polish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Portuguese.pdf">PORTUGAL: Final Conditions of LCXt7 Security Prospectus in Portuguese</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Romanian.pdf">ROMANIA: Final Conditions of LCXt7 Security Prospectus in Romanian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Slovak.pdf">SLOVAKIA: Final Conditions of LCXt7 Security Prospectus in Slovak</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Slovenian.pdf">SLOVENIA: Final Conditions of LCXt7 Security Prospectus in Slovenian</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Spanish.pdf">SPAIN: Final Conditions of LCXt7 Security Prospectus in Spanish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Swedish.pdf">SWEDEN: Final Conditions of LCXt7 Security Prospectus in Swedish</a>'
        },
        {
          link: '<a target="_blank" rel="noopener noreferrer" href="https://www.lcx.com/wp-content/uploads/LCXt7_Prospectus_Czech.pdf">CZECH REPUBLIC: Final Conditions of LCXt7 Security Prospectus in Czech</a>'
        }
      ]
    }
  ]
};

export default bondsProspectusData;
