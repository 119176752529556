import { connect } from "react-redux";
import { compose } from "redux";
import styled, { css } from "styled-components";

const Tbody = styled.tbody`
  tr td {
    vertical-align: ${(props) => (props.verticalAlign ? props.verticalAlign : "")};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "")};
    flex: ${(props) => (props.flex ? props.flex : "")};
    height: ${(props) => props.height && props.height} !important;
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};

    @media (max-width: 1024px) {
      vertical-align: ${(props) => (props.verticalAlignMd ? props.verticalAlignMd : "")};
      font-weight: ${(props) => (props.fontWeightMd ? props.fontWeightMd : "")};
      font-size: ${(props) => (props.fontSizeMd ? props.fontSizeMd : "")};
      padding-top: ${(props) => (props.paddingTopMd ? props.paddingTopMd : "")};
      height: ${(props) => props.heightMd && props.heightMd};
    }

    @media (max-width: 767px) {
      vertical-align: ${(props) => (props.verticalAlignSm ? props.verticalAlignSm : "")};
      font-weight: ${(props) => (props.fontWeightSm ? props.fontWeightSm : "")};
      font-size: ${(props) => (props.fontSizeSm ? props.fontSizeSm : "")};
      padding-top: ${(props) => (props.paddingTopSm ? props.paddingTopSm : "")};
      height: ${(props) => props.heightSm && props.heightSm};
    }

    ${(props) =>
      props.nowrap &&
      css`
        white-space: nowrap;
      `}
    ${(props) =>
      props.OverFlow &&
      css`
        overflow-x: scroll;
        height: 50%;
      `}
  }
`;

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isTheme
});

export default compose(connect(mapStateToProps))(Tbody);
