import { Fragment } from "react";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import ProspectusDoc from "./ProspectusDoc";
import bondsProspectusData from "../../../../../constants/bondsProspectusData";

const Prospectus = ({ bondName }) => {
  return (
    <DivElement marginTopMd="40px" marginTopSm="32px">
      <TextWrapper
        fontSize="28px"
        fontSizeMd="24px"
        fontSizeSm="20px"
        lineHeight="0.71"
        lineHeightMd="0.83"
        lineHeightSm="1"
        marginBottom="30px"
        marginBottomMd="23px"
        marginBottomSm="21px"
      >
        Prospectus
      </TextWrapper>
      {bondsProspectusData &&
        bondsProspectusData[bondName] &&
        bondsProspectusData[bondName].map((data, idx) => (
          <Fragment key={idx}>
            <TextWrapper
              fontSize="16px"
              fontSizeSm="14px"
              lineHeight="1.25"
              lineHeightSm="1.43"
              marginBottom="17px"
              marginBottomSm="14px"
              marginTopSm={idx === 1 ? "8px" : "0"}
            >
              {data.type}
            </TextWrapper>
            <DivElement display="flex" flexWrap="wrap">
              {data.data.map((item, i) => (
                <ProspectusDoc data={item} key={i} />
              ))}
            </DivElement>
          </Fragment>
        ))}
    </DivElement>
  );
};

export default Prospectus;
