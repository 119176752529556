import { useRef, useState, useEffect } from "react";
import Th from "./Th";
import DivElement from "../DivElement";
import { ExpandMoreIcon } from "../../../assets/svgs";
import { BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
// import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
// import { HeadingSix } from "../../../assets/StyleComponents/FontSize/headingSix.style";

const ThDropdown = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdown = useRef();
  // const width = useWindowSize();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line
  }, []);

  const handleClickOutside = (event) => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  return (
    <Th
      whiteSpace="nowrap"
      relative
      paddingBottom={props?.paddBottom ? props?.paddBottom : "0px"}
      {...props}
    >
      <BannerHeadingSpan
        cursor="pointer"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        onClick={() => setShowDropdown(!showDropdown)}
        ref={dropdown}
      >
        {props.activeCol}{" "}
        <DivElement relative display="inline">
          <ExpandMoreIcon
            width="24px"
            height="24px"
            widthSm="20px"
            heightSm="20px"
            marginTop="2px"
            pointer
          />
          {showDropdown && (
            <DivElement
              TableOptions
              right={props.textAlign !== "center" && props.right ? props.right : "-15px"}
              TableOptionsCenterAlign={props.textAlign === "center"}
            >
              <DivElement
                TableOptionsIndicator
                className={`${props.textAlign ? props.textAlign : "right"}-align`}
              ></DivElement>
              <ul>
                {props.OPTIONS.map(
                  (option, i) =>
                    props.activeCol !== option && (
                      <li
                        key={i}
                        onClick={() => props.selectOption(option)}
                        style={{ height: props.height ? props.height : "35px" }}
                      >
                        {option}
                      </li>
                    )
                )}
              </ul>
            </DivElement>
          )}
        </DivElement>
      </BannerHeadingSpan>
    </Th>
  );
};

export default ThDropdown;
