import React from 'react';
import { FlexCard } from '../../assets/StyleComponents/FlexDiv.style';
import { ParaTag } from '../../assets/StyleComponents/FontSize/para.style';
import { EmptyBoxIcon, FavouriteFilledIcon } from '../../assets/svgs';

const SinglePromitionsList = ({ list }) => {
  if(list && list.length < 1){ 
    return (
      <FlexCard gap="20px" py="40px" direction="column" flexCenter>
        <EmptyBoxIcon height="204px" width="220px" />
        <ParaTag> No announcements at the moment. Check back soon! </ParaTag>
      </FlexCard>
    );
  }
  else {
    return (
      <FlexCard direction="column" gap="34px" mt="40px" mtMd="16px" justifyContent="center" pb="24px">
        {list.map((item, index) => {
          return (
            <FlexCard alignItems="center" key={index} gap="8px">
              <FlexCard width="24px">
                <FavouriteFilledIcon width="24px" height="24px" fillLevel0 />
              </FlexCard>
              <ParaTag as="a" onHoverTxtPrimary href={item.topbar_link} target="_blank"> {item.topbar_text} </ParaTag>
            </FlexCard>
            );
          })
        }
      </FlexCard>
    );
  }
};

export default SinglePromitionsList;