/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  addDays,
  addYears,
  endOfMonth,
  endOfYear,
  formatISO,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  setMonth,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears
} from "date-fns";
import { CalendarContainer } from "react-datepicker";
import styled, { css } from "styled-components";

import "react-datepicker/dist/react-datepicker.css";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import SingleDropDownValue from "../Reusable/SingleDropDown/SingleDropDown";
import { yearRange } from "../../utils/general";
import { DatePickerStyle } from "./DatePickerStyle";

let CurrentDate = new Date();

const formattedArray = (array) => {
  return array.map((item) => {
    return {
      id: item,
      label: item,
      value: item
    };
  });
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const DateFilterWrapper = styled(BannerBox)`
  position: relative;
  right: 0%;
  border: none;
  outline: none;
  font-weight: 500;
  background: ${({ theme }) => theme.inputBackground};
  border-bottom: 1px solid ${(props) => props.theme.inputBorder};
  .react-datepicker,
  .react-datepicker__header {
    background: ${({ theme }) => theme.level2Card};
  }
  .react-datepicker__day {
    border: 1px solid ${({ theme }) => theme.level3CardTC}!important;
    border-radius: 5px;
    &:hover {
      color: ${({ theme }) => theme.bodyText3} !important;
    }
  }

  .react-datepicker-popper {
    z-index: 1000;
    @media (max-width: 560px) {
      // left: -24% !important;
      right: 100%;
      left: ${(props) => props.leftMains || "0%"} !important;
    }
  }

  ${(props) =>
    props.QuickFilterFrom &&
    css`
      .react-datepicker-popper {
        @media (max-width: 767px) {
          transform: translate(76px, 53px) !important;
        }
      }
    `}

  ${(props) =>
    props.QuickFilterTo &&
    css`
      .react-datepicker-popper {
        @media (max-width: 767px) {
          transform: translate(-72px, 53px) !important;
        }
      }
    `}

  .react-datepicker__triangle {
    @media (max-width: 560px) {
      display: none !important;
    }
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${(props) => props.theme.bodyText};
    &:hover {
      background-color: ${(props) => props.theme.primary} !important;
    }
  }

  .react-datepicker__day--disabled {
    cursor: no-drop !important;
    color: ${({ theme }) => theme.subText} !important;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${(props) => props.theme.primary} !important;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.primary} !important;
    color: ${({ theme }) => theme.bodyText3} !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    right: 0;
  }
  .react-datepicker__input-container {
    height: 100%;
  }
  input {
    width: 100%;
    height: 100%;
    text-decoration: none;
    border: none;
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    font-weight: 500;
    padding: 0px 10px;
    color: ${(props) => props.theme.bodyText};
    &:focus {
      outline: 0px;
    }
    &::placeholder {
      font-weight: 300;
    }
  }
  @media (max-width: 767.5px) {
    width: 100%;
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
`;

const QuickFilterContainer = styled(BannerBox)`
  position: absolute;
  right: 99%;
  top: -1px;
  bottom: 0;
  width: 130px;
  height: ${({ height }) => height}px;
  display: flex;
  flex-direction: column;
  border-radius: 5px 0 0 5px;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  background: ${({ theme }) => theme.level2Card};
  padding: 16px 16px 30px;
  z-index: 0;
  @media (max-width: 767px) {
    width: 117px;
    padding: 16px 8px 30px;
  }
`;

const ParentQuickFilterContainer = styled(CalendarContainer)`
  .react-datepicker {
    #quick-Filter-Container {
      height: 100% !important;
    }
  }
`;

const QuickFilterThreeMonths = [
  "Last 7 days",
  "Last 30 days",
  "This month",
  "Last month",
  "Last 3 Month"
];
const QuickFilterYear = [
  "Last 7 days",
  "Last 30 days",
  "This month",
  "Last month",
  "This year",
  "Last year"
];

const QuickFilterComponent = ({ DatePickerProps, setQuickFilterValue }) => {
  let { changeYear, changeMonth, threeMonthCheck, datePickerHeight } = DatePickerProps;

  const handleOnClickFilters = (recievedName) => {
    let previousMonth = subMonths(CurrentDate, 1);
    let previousYear = subYears(CurrentDate, 1);

    // Set the month to December (11 because months are zero-indexed)
    const lastMonthPreviousYear = setMonth(previousYear, 11);

    changeYear(CurrentDate.getFullYear());
    if (recievedName === "Last month") changeMonth(CurrentDate.getMonth() - 1);
    else if (recievedName !== "Last month") changeMonth(CurrentDate.getMonth());

    if (recievedName === "Last 7 days")
      setQuickFilterValue({ fromDate: subDays(CurrentDate, 7), toDate: CurrentDate });
    if (recievedName === "Last 30 days")
      setQuickFilterValue({ fromDate: subDays(CurrentDate, 30), toDate: CurrentDate });
    if (recievedName === "This month")
      setQuickFilterValue({
        fromDate: subDays(CurrentDate, CurrentDate.getDate() - 1),
        toDate: CurrentDate
      });
    if (recievedName === "Last month")
      setQuickFilterValue({
        fromDate: startOfMonth(previousMonth),
        toDate: endOfMonth(previousMonth)
      });
    if (recievedName === "Last 3 Month")
      setQuickFilterValue({
        fromDate: subMonths(CurrentDate, 3),
        toDate: CurrentDate
      });
    if (recievedName === "This year") {
      let previousMonth = subMonths(CurrentDate, CurrentDate.getMonth() - 0);
      setQuickFilterValue({
        fromDate: startOfMonth(previousMonth),
        toDate: CurrentDate
      });
    }
    if (recievedName === "Last year") {
      changeYear(previousYear.getFullYear());
      changeMonth(lastMonthPreviousYear.getMonth());
      setQuickFilterValue({
        fromDate: startOfYear(previousYear),
        toDate: endOfYear(previousYear)
      });
    }
  };

  return (
    <QuickFilterContainer height={parseInt(datePickerHeight + 1)} id="quick-Filter-Container">
      <ParaTag weight="500"> Quick Filters </ParaTag>
      <BannerBox
        mt="24px"
        display="flex"
        width="100%"
        height="calc(100% - 40px)"
        alignSelf="center"
        direction="column"
        justifyContent="flex-start"
        gap="10px"
        className="innerValue"
      >
        {threeMonthCheck &&
          QuickFilterThreeMonths.map((item) => (
            <ButtonComponent
              key={item}
              size="12px"
              width="100%"
              btnHeight="24px"
              type="button"
              PrimaryButton
              onClick={() => {
                handleOnClickFilters(item);
              }}
            >
              {item}
            </ButtonComponent>
          ))}
        {!threeMonthCheck &&
          QuickFilterYear.map((item) => (
            <ButtonComponent
              key={item}
              size="12px"
              width="100%"
              btnHeight="24px"
              type="button"
              PrimaryButton
              onClick={() => {
                handleOnClickFilters(item);
              }}
            >
              {item}
            </ButtonComponent>
          ))}
      </BannerBox>
    </QuickFilterContainer>
  );
};

const DatePickerComponent = ({
  getYearRange = 1900,
  DobStatus = false,
  quickFilterStatus = false,
  threeMonthCheck = true,
  onDayBeforeCheck = false,
  givenDate,
  selectedDate,
  setQuickFilterValue,
  left,
  name
}) => {
  const [datePickerHeight, setDatePickerHeight] = useState(0);
  const [minDateValue, setMinDateValue] = useState(null);
  const [maxDateValue, setMaxDateValue] = useState(CurrentDate);

  let selectionDate = useMemo(() => selectedDate, [selectedDate]);
  let yearDetails = DobStatus ? getYear(subYears(CurrentDate, 18)) + 1 : getYear(CurrentDate) + 1;
  const years = yearRange(getYearRange, yearDetails, 1);
  const datePickerRef = useRef(null);

  useEffect(() => {
    // if (threeMonthCheck) {
    if (name.toLowerCase() === "from") {
      if (givenDate?.toDate) {
        let max = subYears(givenDate.toDate.getTime(), 1);
        if (threeMonthCheck) max = subDays(givenDate.toDate.getTime(), 92);
        setMinDateValue(max);
        setMaxDateValue(givenDate.toDate);
      } else {
        setMaxDateValue(CurrentDate);
      }
    } else {
      if (givenDate?.fromDate) {
        let max = addYears(givenDate.fromDate.getTime(), 1);
        if (threeMonthCheck) max = addDays(givenDate.fromDate.getTime(), 92);
        if (
          CurrentDate &&
          (isAfter(givenDate.fromDate, givenDate.fromDate.getTime()) ||
            (isEqual(givenDate.fromDate, givenDate.fromDate.getTime()) &&
              isBefore(givenDate.fromDate, max) &&
              isBefore(max, CurrentDate)))
        ) {
          setMinDateValue(givenDate.fromDate);
          setMaxDateValue(max);
        } else {
          setMinDateValue(givenDate.fromDate);
          setMaxDateValue(CurrentDate);
        }
      } else {
        setMaxDateValue(CurrentDate);
      }
    }
    // }
  }, [givenDate]);

  useEffect(() => {
    if (DobStatus) {
      setMaxDateValue(subYears(CurrentDate, 18));
    }
  }, [DobStatus]);

  const ParentContainer = (props) => {
    let { className, children } = props;
    useEffect(() => {
      updateHeight();
    }, [datePickerRef]);
    return (
      <BannerBox position="realtive" ref={datePickerRef}>
        <ParentQuickFilterContainer className={className}>
          <BannerBox style={{ position: "relative" }}>{children}</BannerBox>
        </ParentQuickFilterContainer>
      </BannerBox>
    );
  };

  const handleQuickFilterState = ({ fromDate, toDate }) => {
    if (name.toLowerCase() === "from") {
      setQuickFilterValue({ fromDate: fromDate, toDate: toDate });
    } else {
      setQuickFilterValue({ fromDate: fromDate, toDate: toDate });
    }
  };

  const updateHeight = () => {
    if (datePickerRef.current) {
      const height = datePickerRef.current.getBoundingClientRect().height;
      setDatePickerHeight(height);
    }
  };

  const CustomInput = ({ value, placeholder, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      placeholder={placeholder}
      onKeyDown={(e) => e.preventDefault()}
      readOnly
    />
  );

  return useMemo(() => {
    return (
      <DateFilterWrapper
        QuickFilterTo={name.toLowerCase() === "to" ? true : null}
        QuickFilterFrom={name.toLowerCase() === "from" ? true : null}
        width="100%"
        height="100%"
        leftMains={left}
      >
        <DatePickerStyle
          dateFormat="dd/MM/yyyy"
          ref={datePickerRef}
          calendarContainer={ParentContainer}
          placeholderText={name || ""}
          className="inputMain"
          minDate={minDateValue}
          maxDate={maxDateValue}
          customInput={<CustomInput />}
          openToDate={
            DobStatus
              ? selectionDate
                ? selectionDate
                : subYears(CurrentDate, 18)
              : givenDate && givenDate.fromDate && name.toLowerCase() === "from"
              ? givenDate.fromDate
              : givenDate.toDate && name.toLowerCase() === "to"
              ? givenDate.toDate
              : CurrentDate
          }
          // disabled={threeMonthCheck && name.toLowerCase() === "to"}
          renderCustomHeader={(props) => {
            let { date, changeYear, changeMonth } = props;
            return (
              <BannerBox
                margin="0 10px 10px"
                display="flex"
                justifyContent="center"
                id="quickDobFilter"
              >
                {quickFilterStatus && (
                  <QuickFilterComponent
                    DatePickerProps={{
                      date,
                      changeYear,
                      changeMonth,
                      threeMonthCheck,
                      datePickerHeight
                    }}
                    setQuickFilterValue={handleQuickFilterState}
                  />
                )}
                <BannerBox position="relative" display="flex" justifyContent="center" gap="10px">
                  <BannerBox width="48%" minWidth="90px">
                    <SingleDropDownValue
                      DobHeaderStatus="true"
                      menuCloseOnSelect="true"
                      searchStatus={false}
                      setSelectedOptions={({ value }) => {
                        changeYear(value);
                        setTimeout(() => {
                          updateHeight();
                        }, 100);
                      }}
                      options={formattedArray(years)}
                      placeholder={"Year"}
                      value={{
                        id: getYear(date),
                        label: getYear(date),
                        value: getYear(date)
                      }}
                    />
                  </BannerBox>

                  <BannerBox width="48%" minWidth="100px">
                    <SingleDropDownValue
                      DobHeaderStatus="true"
                      searchStatus={false}
                      menuCloseOnSelect="true"
                      setSelectedOptions={({ value }) => {
                        changeMonth(months.indexOf(value));
                        setTimeout(() => {
                          updateHeight();
                        }, 100);
                      }}
                      options={formattedArray(months)}
                      placeholder={"Month"}
                      value={{
                        id: months[getMonth(date)],
                        label: months[getMonth(date)],
                        value: months[getMonth(date)]
                      }}
                    />
                  </BannerBox>
                </BannerBox>
              </BannerBox>
            );
          }}
          selected={selectionDate}
          onChange={(date) => {
            if (quickFilterStatus) {
              if (name.toLowerCase() === "from") {
                handleQuickFilterState({
                  fromDate: date,
                  toDate: givenDate.toDate ? givenDate.toDate : null
                });
              } else
                handleQuickFilterState({
                  fromDate: givenDate.fromDate ? givenDate.fromDate : null,
                  toDate: date
                });
            } else {
              setQuickFilterValue(date);
            }
          }}
        />
      </DateFilterWrapper>
    );
  }, [selectedDate, givenDate, threeMonthCheck, minDateValue, maxDateValue, updateHeight]);
};

DatePickerComponent.propTypes = {};

export default memo(DatePickerComponent);
