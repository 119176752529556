/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { buySalesToken, getCoinRate } from "../../../../../lib/api";
import { showErrorMsg } from "../../../../../utils/notification";
import { formatAPIErrors } from "../../../../../utils/general";
import { divide, multiply } from "../../../../../utils/bigNumber";
import { useDispatch, useSelector } from "react-redux";
import { getAllWallets } from "../../../../../actions/wallet";
import { TokenSaleStepsLoader } from "../../../../../components/Loader";
import { DivElement, ElementBox } from "../../../../../components/Reusable";

const Steps = ({ saleDetails, setSaleDetails, loader, isPrivate = false, setIsPrivate }) => {
  const [selectedPaymentCoin, setSelectedPaymentCoin] = useState("");
  const [validatedAccessCode, setValidatedAccessCode] = useState("");
  const [selectedCoinRate, setSelectedCoinRate] = useState("");
  const [purchaseFee, setPurchaseFee] = useState(0);
  const [purchaseAmt, setPurchaseAmt] = useState("");
  const [paymentAmt, setPaymentAmt] = useState("");
  const [paymentCoinBalance, setPaymentCoinBalance] = useState(0);
  const [feeCoinBalance, setFeeCoinBalance] = useState(0);
  const [coinRates, setCoinRates] = useState("");
  const [saleRoundData, setSaleRoundData] = useState("");
  const [confirmPayLoader, setConfirmPayLoader] = useState(false);
  const [successfulPurchaseAmt, setSuccessfulPurchaseAmt] = useState(0);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const mainWallet = useSelector((state) => state.wallet?.wallets?.data);

  useEffect(() => {
    if (isPrivate && !saleDetails) {
      setStep(1);
    }

    if (saleDetails?.isAccessCode) {
      setStep(1);
    } else {
      setSaleRoundData(saleDetails?.round);
      setStep(2);
    }
  }, [saleDetails]);

  useEffect(() => {
    if (!saleRoundData) return;
    setSelectedPaymentCoin(saleRoundData.coins[0].coin);
    dispatch(getAllWallets());
    getRates();

    const interval = setInterval(() => {
      getRates();
    }, [30000]);

    return () => {
      clearInterval(interval);
    };
  }, [saleRoundData]);

  useEffect(() => {
    if (!coinRates || !selectedPaymentCoin) return;
    let rate = coinRates.find((data) => data.coin === selectedPaymentCoin);
    setSelectedCoinRate(rate);
  }, [coinRates, selectedPaymentCoin]);

  useEffect(() => {
    if (!mainWallet || !saleRoundData) return;
    let paymentCoin, feeCoin;

    for (let i = 0; i < mainWallet.length; i++) {
      if (mainWallet[i].coin === selectedPaymentCoin) {
        paymentCoin = mainWallet[i];
      }

      if (mainWallet[i].coin === saleRoundData?.feeCoin) {
        feeCoin = mainWallet[i];
      }

      if (paymentCoin && feeCoin) break;
    }
    setPaymentCoinBalance(paymentCoin?.balance?.freeBalance || 0);
    setFeeCoinBalance(feeCoin?.balance?.freeBalance || 0);
  }, [mainWallet, selectedPaymentCoin, saleRoundData]);

  useEffect(() => {
    if (!saleRoundData || !selectedCoinRate) return;
    let feePercent = multiply(+saleRoundData?.fee, +selectedCoinRate?.feeCoinRate, 8);
    let oneCoinFee = divide(+feePercent, 100, 8);
    let fee = multiply(+purchaseAmt, +oneCoinFee, 8);
    setPurchaseFee(fee);
  }, [purchaseAmt, selectedCoinRate, saleRoundData]);

  const getRates = () => {
    let data = {
      tokenSaleId: saleDetails?.tokenSaleId,
      roundId: saleRoundData?.roundId
    };
    getCoinRate(data)
      .then((res) => {
        setCoinRates(res?.data?.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const onVerifyAccessCode = (details, accessCode) => {
    setSaleDetails(details);
    setValidatedAccessCode(accessCode);
    if (isPrivate) setIsPrivate(false);
    setStep(2);
  };

  const onBuyHandler = (values) => {
    if (saleDetails.tokenSoldOut) return;
    setPurchaseAmt(values.lcxAmount);

    setPaymentAmt(
      values.lcxAmount && selectedCoinRate.rate
        ? multiply(values.lcxAmount, selectedCoinRate.rate, 8)
        : values.lcxPayment
    );
    setStep(3);
  };

  const onAmountChangeHandler = (amt, setFieldValue, name) => {
    let purchaseInputAmt, paymentInputAmt;
    if (amt && selectedCoinRate) {
      let nums = amt.split(".");
      if (nums.length > 1 && nums[1].length > 8) return;
      if (name === "lcxAmount") {
        purchaseInputAmt = amt;
        paymentInputAmt = multiply(amt, selectedCoinRate.rate, 8);
      } else {
        paymentInputAmt = amt;
        purchaseInputAmt = divide(amt, selectedCoinRate.rate, 8);
      }
    } else {
      purchaseInputAmt = "";
      paymentInputAmt = "";
    }
    setPurchaseAmt(purchaseInputAmt);
    setPaymentAmt(paymentInputAmt);
    setFieldValue("lcxAmount", purchaseInputAmt);
    setFieldValue("lcxPayment", paymentInputAmt);
  };

  const onCoinChangeHandler = (coin, setFieldValue) => {
    setSelectedPaymentCoin(coin);
    let coinRate = coinRates.find((data) => data.coin === coin);
    if (purchaseAmt) {
      let payment = multiply(purchaseAmt, coinRate.rate, 8);
      setFieldValue("lcxPayment", payment);
    }
  };

  const onConfirmAndPayHandler = () => {
    if (confirmPayLoader) return;
    setConfirmPayLoader(true);
    let data = {
      coin: selectedPaymentCoin,
      amount: purchaseAmt
    };

    if (validatedAccessCode) {
      data.accessCode = validatedAccessCode;
    } else {
      data.tokenSaleId = saleDetails.tokenSaleId;
      data.roundId = saleRoundData.roundId;
    }
    buySalesToken(data)
      .then((res) => {
        setSuccessfulPurchaseAmt(res?.data?.amount);
        setStep(4);
      })
      .catch((err) => {
        setStep(5);
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setConfirmPayLoader(false);
        dispatch(getAllWallets());
      });
  };

  const onBackHandler = () => {
    setStep(3);
    if (step !== 3) {
      setPurchaseAmt("");
      setPaymentAmt("");
    }
  };

  return (
    <ElementBox displayCenter padding="0" borderRadiusMd="0 0 5px 5px" borderRadiusSm="5px">
      <DivElement
        bdGrey
        width="100%"
        widthMd="100%"
        widthSm="100%"
        minHeightSm="555px"
        borderMd="0px"
        borderSm="none"
        borderRadiusMd="5px"
        padding="24px 16px 32px 16px"
        paddingMd="24px"
      >
        {!loader ? (
          <Fragment>
            {step === 1 && (
              <Step1
                saleDetails={saleDetails}
                onVerifyAccessCode={onVerifyAccessCode}
                isPrivate={isPrivate}
              />
            )}

            {step >= 2 && (
              <Step2
                saleDetails={saleDetails}
                paymentCoinBalance={paymentCoinBalance}
                selectedPaymentCoin={selectedPaymentCoin}
                selectedCoinRate={selectedCoinRate}
                feeCoinBalance={feeCoinBalance}
                purchaseFee={purchaseFee}
                purchaseAmt={purchaseAmt}
                paymentAmt={paymentAmt}
                onBuyHandler={onBuyHandler}
                onAmountChangeHandler={onAmountChangeHandler}
                onCoinChangeHandler={onCoinChangeHandler}
              />
            )}

            {step === 3 && (
              <Step3
                saleDetails={saleDetails}
                selectedCoinRate={selectedCoinRate}
                onBackHandler={onBackHandler}
                purchaseAmt={purchaseAmt}
                paymentAmt={paymentAmt}
                purchaseFee={purchaseFee}
                saleRoundData={saleRoundData}
                onConfirmAndPayHandler={onConfirmAndPayHandler}
                confirmPayLoader={confirmPayLoader}
                setStep={setStep}
              />
            )}

            {(step === 4 || step === 5) && (
              <Step4
                step={step}
                saleDetails={saleDetails}
                successfulPurchaseAmt={successfulPurchaseAmt || purchaseAmt}
                onBackHandler={onBackHandler}
                purchaseAmt={purchaseAmt}
                paymentAmt={paymentAmt}
                purchaseFee={purchaseFee}
                selectedCoinRate={selectedCoinRate}
              />
            )}
          </Fragment>
        ) : (
          <TokenSaleStepsLoader />
        )}
      </DivElement>
    </ElementBox>
  );
};

export default Steps;
