import styled, { css } from "styled-components";

const OrderRowAnimation = styled.div`
  position: absolute;
  left: 0;
  z-index: 0;
  width: 100%;
  height: calc(100% - 2px);
  transform: ${(props) => `translateX(${props.percent}%);`};
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;

  ${(props) =>
    props.side === "buy" &&
    css`
      background-color: ${(props) => props.theme.buyAnimationBg2};
    `};

  ${(props) =>
    props.side === "sell" &&
    css`
      background-color: ${(props) => props.theme.sellAnimationBg2};
    `}
`;

export default OrderRowAnimation;
