/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { DivElement, ModalWrapper, TextWrapper } from "../../../components/Reusable";
import { CountriesDropdown } from "../../../components/Reusable/FormInput";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { FormInput } from "../../../assets/StyleComponents/Input.style";
import DragAndDropComponent from "../../../components/Reusable/DragAndDrop";
import { useForm } from "react-hook-form";
import { formatCountriesArray } from "../../../utils";
import {
  BankIcon,
  BankWhiteIcon,
  CableIcon,
  CableWhiteIcon,
  ElectricityBIllIcon,
  ElectricityBIllWhiteIcon,
  OtherUtilityIcon,
  OtherUtilityWhiteIcon,
  CloseIcon
} from "../../../assets/svgs";
import { useSelector } from "react-redux";
import useOnClickOutside from "../../../Hooks/CheckOutSideClick/checkOutSideClick";
import { useRef } from "react";

const Options = (isDarkTheme = true) => [
  {
    label: (
      <DivElement flex alignItems="center">
        <DivElement bgBlue padding="5px 8px" radius="50%">
          {isDarkTheme ? (
            <BankWhiteIcon width="15px" height="15px" />
          ) : (
            <BankIcon width="15px" height="15px" />
          )}
        </DivElement>

        <TextWrapper marginLeft="10px"> Bank Statement</TextWrapper>
      </DivElement>
    ),
    id: "bankStatement",
    value: "bankStatement"
  },
  {
    label: (
      <DivElement flex alignItems="center">
        <DivElement bgBlue padding="5px 8px" radius="50%">
          {isDarkTheme ? (
            <ElectricityBIllWhiteIcon width="15px" height="15px" />
          ) : (
            <ElectricityBIllIcon width="15px" height="15px" />
          )}
        </DivElement>
        <TextWrapper marginLeft="10px">Electricity Bill</TextWrapper>
      </DivElement>
    ),
    value: "electricityBill",
    id: "electricityBill"
  },
  {
    label: (
      <DivElement flex alignItems="center">
        <DivElement bgBlue padding="5px 8px" radius="50%">
          {isDarkTheme ? (
            <CableWhiteIcon width="15px" height="15px" />
          ) : (
            <CableIcon width="15px" height="15px" />
          )}
        </DivElement>
        <TextWrapper marginLeft="10px">Cable Bill</TextWrapper>
      </DivElement>
    ),
    value: "cableBill",
    id: "cableBill"
  },
  {
    label: (
      <DivElement flex alignItems="center">
        <DivElement bgBlue padding="5px 8px" radius="50%">
          {isDarkTheme ? (
            <OtherUtilityWhiteIcon width="15px" height="15px" />
          ) : (
            <OtherUtilityIcon width="15px" height="15px" />
          )}
        </DivElement>
        <TextWrapper marginLeft="10px">Other Utility Bill</TextWrapper>
      </DivElement>
    ),
    id: "otherUtilityBill",
    value: "otherUtilityBill"
  }
];

const AddressUpdateModal = ({ countriesList, onShowUpdateAddressModal, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [country, setCountry] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isAddressUpdateLoading } = useSelector((state) => state.setting);
  const popUpRef = useRef();

  useOnClickOutside(popUpRef, () => {
    onShowUpdateAddressModal();
  });

  useEffect(() => {
    if (country) {
      setError((prev) => ({
        ...prev,
        country: ""
      }));
    }

    if (files?.length) {
      setError((prev) => ({
        ...prev,
        poa: ""
      }));
    }
    if (documentType) {
      setError((prev) => ({
        ...prev,
        documentType: ""
      }));
    }
  }, [documentType, files, country]);

  const [error, setError] = useState({
    poa: "",
    country: ""
  });

  const onSelectCountry = (name, value) => {
    setCountry(value);
  };

  const onSelectDocType = (name, value) => {
    setDocumentType(value);
  };

  const checkValidation = () => {
    let isError = false;

    if (!country) {
      setError((prev) => ({
        ...prev,
        country: "Country is required"
      }));
      isError = true;
    }

    if (!files?.length) {
      setError((prev) => ({
        ...prev,
        poa: "Proof of Address is required"
      }));
      isError = true;
    }
    if (!documentType) {
      setError((prev) => ({
        ...prev,
        documentType: "Document type is required"
      }));
      isError = true;
    }

    return isError;
  };

  const submit = (value) => {
    const isError = checkValidation();
    if (!isError) {
      onSubmit(value, country, files, documentType);
    }
  };

  const getFormContainer = () => (
    <form onSubmit={handleSubmit((value) => submit(value))}>
      <DivElement marginTop="32px">
        <TextWrapper fontSize="12px" marginBottom="5px">
          Street Address
        </TextWrapper>
        <FormInput
          size="14px"
          sizeMd="12px"
          height="44px"
          placeholder="Enter street / locality / area"
          {...register("street", { required: "Street / locality / area is required" })}
        />
        {errors.street?.type === "required" && (
          <TextWrapper red fontSize="12px" marginTop="5px">
            {errors.street?.message}
          </TextWrapper>
        )}
      </DivElement>

      <DivElement marginTop="32px">
        <TextWrapper fontSize="12px" marginBottom="5px">
          Unit
        </TextWrapper>
        <FormInput
          size="14px"
          sizeMd="12px"
          height="44px"
          placeholder="House number / flat number"
          {...register("flatNo", { required: "House number / flat number is required" })}
        />
        {errors.flatNo?.type === "required" && (
          <TextWrapper red fontSize="12px" marginTop="5px">
            {errors.flatNo?.message}
          </TextWrapper>
        )}
      </DivElement>

      <DivElement displaySm="block" display="flex" justifyContent="space-between">
        <DivElement marginTop="32px" marginRight="8px" marginRightSm="4px">
          <TextWrapper fontSize="12px" marginBottom="5px">
            City
          </TextWrapper>
          <FormInput
            size="14px"
            sizeMd="12px"
            height="44px"
            placeholder="Enter city"
            {...register("city", { required: "City is required" })}
          />
          {errors.city?.type === "required" && (
            <TextWrapper red fontSize="12px" marginTop="5px">
              {errors.city?.message}
            </TextWrapper>
          )}
        </DivElement>
        <DivElement marginTop="32px" marginLeft="10px" marginLeftSm="0px">
          <TextWrapper fontSize="12px" marginBottom="5px">
            State/Region
          </TextWrapper>
          <FormInput
            size="14px"
            sizeMd="12px"
            height="44px"
            placeholder="Enter state"
            {...register("state", { required: "State is required" })}
          />
          {errors.state?.type === "required" && (
            <TextWrapper red fontSize="12px" marginTop="5px">
              {errors.state?.message}
            </TextWrapper>
          )}
        </DivElement>
      </DivElement>
      <DivElement displaySm="block" display="flex" justifyContent="space-between">
        <DivElement
          maxWidth="185px"
          maxWidthSm="none"
          marginTop="32px"
          marginRight="16px"
          marginRightSm="0px"
        >
          <TextWrapper fontSize="12px" marginBottom="5px">
            Zip code
          </TextWrapper>
          <FormInput
            size="14px"
            sizeMd="12px"
            height="44px"
            maxLength={6}
            placeholder="Enter zip code"
            {...register("zipcode", { required: "Zip code is required" })}
          />
          {errors.zipcode?.type === "required" && (
            <TextWrapper red fontSize="12px" marginTop="5px">
              {errors.zipcode?.message}
            </TextWrapper>
          )}
        </DivElement>

        <DivElement
          marginTop="32px"
          marginLeft="0px"
          marginLeftSm="0px"
          width="185px"
          widthSm="100%"
          position="relative"
        >
          <TextWrapper fontSize="12px" marginBottom="5px">
            Country of Residence
          </TextWrapper>
          <CountriesDropdown
            name="country"
            value={country}
            setFieldValue={onSelectCountry}
            disabled={!countriesList}
            countryList={formatCountriesArray(countriesList.Country) || []}
            placeholder="Select Country"
            error={errors.Country}
            containerClass={"fInput__form__field--w100"}
            showError={false}
          />

          {error.country && (
            <TextWrapper position="absolute" red fontSize="12px" marginTop="-30px">
              {error.country}
            </TextWrapper>
          )}
        </DivElement>
      </DivElement>

      <DivElement width="100%" widthSm="100%" position="relative">
        <TextWrapper fontSize="12px" marginBottom="5px">
          Document for Residence Verification
        </TextWrapper>
        <CountriesDropdown
          name="documentType"
          value={documentType}
          setFieldValue={onSelectDocType}
          disabled={!countriesList}
          countryList={Options(true)}
          placeholder="Select an option"
          error={errors.Country}
          showError={false}
        />

        {error.documentType && (
          <TextWrapper position="absolute" red fontSize="12px" marginTop="-30px">
            {error.documentType}
          </TextWrapper>
        )}
      </DivElement>
      <DivElement>
        <DragAndDropComponent
          setFiles={setFiles}
          files={files}
          maxFileSize={20000000}
          accept={{
            image: [".jpg", ".jpeg", ".pdf"]
          }}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
        {error.poa && (
          <TextWrapper red fontSize="12px" marginTop="5px">
            {error.poa}
          </TextWrapper>
        )}
      </DivElement>

      {isAddressUpdateLoading || isLoading ? (
        <ButtonComponent width="100%" height="48px" mt="24px" DisabledBTNDark disabled>
          Submit
        </ButtonComponent>
      ) : (
        <ButtonComponent
          primaryBlue
          width="100%"
          height="48px"
          mt="24px"
          type="submit"
          onClick={checkValidation}
        >
          Submit
        </ButtonComponent>
      )}
    </form>
  );
  return (
    <ModalWrapper>
      <DivElement ModalContainer AddressUpdateModal ref={popUpRef}>
        <DivElement display="flex" justifyContent="space-between" alignItems="center">
          <TextWrapper fontSize="18px">Update Address</TextWrapper>
          <span role="presentation" onClick={onShowUpdateAddressModal}>
            <CloseIcon />
          </span>
        </DivElement>
        {getFormContainer()}
      </DivElement>
    </ModalWrapper>
  );
};

export default AddressUpdateModal;
