/* eslint-disable no-console */

/* eslint-disable no-unused-vars */
import { fromJS } from "immutable";
import { success, failure } from "../../utils/redux";
import { getLocalStorage, setLocalStorage } from "../../utils/helpers";
import {
  UPDATE_ACTIVE_KEY,
  UPDATE_SILDER_PERCENTAGE_SELL,
  TABLET_UPDATE_ACTIVE_TAB,
  UPDATE_ACTIVE_TAB,
  UPDATE_CURRENT_PAIR_DETAILS,
  UPDATE_LAST_PRICE,
  UPDATE_SELECTED_PAIR_NAME,
  UPDATE_ORDER_AMOUNT_BUY,
  UPDATE_TOTAL_AMOUNT_BUY,
  UPDATE_SILDER_PERCENTAGE,
  KYC_STATUS,
  RESTRICTED_STATUS,
  ORDER_LOADING_STATUS,
  GET_ERROR_MSG_STATUS,
  FAV_TAB_LIST,
  MARKET_ALERT_CONFIRMATION,
  UPDATE_PRICE_AMOUNT_ORDERBOOK,
  UPDATE_CHART_VALUE,
  UPDATE_LAST_PRICE_SELL,
  UPDATE_ORDER_AMOUNT_SELL,
  UPDATE_TOTAL_AMOUNT_SELL,
  GET_ERROR_MSG_STATUS_SELL,
  UPDATE_FEE_STATUS,
  MARKET_ALERT_CONFIRMATION_SELL
} from "./const.js";

export function createInitialState() {
  let initialActiveTab = getLocalStorage("default");
  let favActiveTab = JSON.parse(getLocalStorage("Activetabs"));

  if (initialActiveTab) {
    if (initialActiveTab.includes("_")) initialActiveTab = initialActiveTab.split("_");
    else initialActiveTab = initialActiveTab.split("-");
  }

  return fromJS({
    buyBid: "0",
    sellBid: "0",
    orderAmountBuy: "",
    orderAmountSell: "",
    totalAmountBuy: "",
    totalAmountSell: "",
    percentage: 0,
    percentageSell: 0,
    selectedPair: {
      base: "",
      qoute: "",
      symbol: ""
    },
    kycStatus: false,
    ristrictedStatus: false,
    orderLoading: false,
    activeTab: (initialActiveTab && initialActiveTab[0]) || "buy",
    tabletActiveTab: (initialActiveTab && initialActiveTab[0]) || "buy",
    defaultActiveKey: (initialActiveTab && initialActiveTab[1]) || "limit",
    pairDetails: {},
    tradeError: {
      errorMsg: "",
      errorMsgInsuficient: "",
      errorAmount: false,
      errorTotal: false
    },
    tradeErrorSell: {
      errorMsgSell: "",
      errorMsgInsuficientSell: "",
      errorAmountSell: false,
      errorTotalSell: false
    },
    selectedOrderBookPrice: {
      price: "",
      amount: "",
      type: ""
    },
    favActiveTabsList: !favActiveTab ? [] : favActiveTab,
    marketAlert: false,
    marketAlertSell: false,
    isFeeStatus: false,
    chartView: "trading"
  });
}

export function TradePageReducer(state = createInitialState(), action) {
  switch (action.type) {
    case FAV_TAB_LIST:
      return fromJS({
        ...state.toJS(),
        favActiveTabsList: action.payload
      });

    case KYC_STATUS:
      return fromJS({
        ...state.toJS(),
        kycStatus: action.payload
      });

    case MARKET_ALERT_CONFIRMATION:
      return fromJS({
        ...state.toJS(),
        marketAlert: action.payload
        // marketAlert: {
        //   marketAlertConfirm: action.payload.confirmStatus,
        //   openAlertStatus: action.payload.status,
        //   marketData: action.payload.data
        // }
      });

    case MARKET_ALERT_CONFIRMATION_SELL:
      return fromJS({
        ...state.toJS(),
        marketAlertSell: action.payload
      });

    case RESTRICTED_STATUS:
      return fromJS({
        ...state.toJS(),
        ristrictedStatus: action.payload
      });

    case ORDER_LOADING_STATUS:
      return fromJS({
        ...state.toJS(),
        orderLoading: action.payload
      });

    case UPDATE_LAST_PRICE:
      return fromJS({
        ...state.toJS(),
        buyBid: action.payload
      });

    case UPDATE_LAST_PRICE_SELL:
      return fromJS({
        ...state.toJS(),
        sellBid: action.payload
      });

    case UPDATE_ORDER_AMOUNT_BUY:
      return fromJS({
        ...state.toJS(),
        orderAmountBuy: action.payload
      });

    case UPDATE_ORDER_AMOUNT_SELL:
      return fromJS({
        ...state.toJS(),
        orderAmountSell: action.payload
      });

    case UPDATE_TOTAL_AMOUNT_BUY:
      return fromJS({
        ...state.toJS(),
        totalAmountBuy: action.payload
      });

    case UPDATE_TOTAL_AMOUNT_SELL:
      return fromJS({
        ...state.toJS(),
        totalAmountSell: action.payload
      });

    case UPDATE_SILDER_PERCENTAGE:
      return fromJS({
        ...state.toJS(),
        percentage: action.payload
      });

    case UPDATE_SILDER_PERCENTAGE_SELL:
      return fromJS({
        ...state.toJS(),
        percentageSell: action.payload
      });

    case UPDATE_CURRENT_PAIR_DETAILS:
      return fromJS({
        ...state.toJS(),
        pairDetails: action.payload
      });

    case UPDATE_FEE_STATUS:
      return fromJS({
        ...state.toJS(),
        isFeeStatus: action.payload
      });

    case UPDATE_CHART_VALUE:
      return fromJS({
        ...state.toJS(),
        chartView: action.payload
      });

    case TABLET_UPDATE_ACTIVE_TAB:
      setLocalStorage(`${action.payload}_${state.get("defaultActiveKey")}`, "default");
      return fromJS({
        ...state.toJS(),
        tabletActiveTab: action.payload
      });

    case UPDATE_ACTIVE_TAB:
      setLocalStorage(`${action.payload}_${state.get("defaultActiveKey")}`, "default");
      return fromJS({
        ...state.toJS(),
        activeTab: action.payload,
        tabletActiveTab: action.payload
      });

    case UPDATE_ACTIVE_KEY:
      setLocalStorage(`${state.get("activeTab")}_${action.payload}`, "default");
      return fromJS({
        ...state.toJS(),
        defaultActiveKey: action.payload
      });

    case UPDATE_SELECTED_PAIR_NAME:
      return fromJS({
        ...state.toJS(),
        selectedPair: {
          base: action.payload.base,
          qoute: action.payload.qoute,
          symbol: `${action.payload.base}/${action.payload.qoute}`
        }
      });

    case UPDATE_PRICE_AMOUNT_ORDERBOOK:
      return fromJS({
        ...state.toJS(),
        selectedOrderBookPrice: {
          price: action.payload.price,
          amount: action.payload.sum,
          type: action.payload.type
        }
      });

    case GET_ERROR_MSG_STATUS:
      return fromJS({
        ...state.toJS(),
        tradeError: {
          errorMsg: action.payload.errorMsg,
          errorMsgInsuficient: action.payload.errorMsgInsuficient,
          errorAmount: action.payload.errorAmount,
          errorTotal: action.payload.errorTotal
        }
      });

    case GET_ERROR_MSG_STATUS_SELL:
      return fromJS({
        ...state.toJS(),
        tradeErrorSell: {
          errorMsgSell: action.payload.errorMsg,
          errorMsgInsuficientSell: action.payload.errorMsgInsuficient,
          errorAmountSell: action.payload.errorAmount,
          errorTotalSell: action.payload.errorTotal
        }
      });

    default:
      return state;
  }
}
