/* eslint-disable no-console */
import { Fragment, useEffect, useState } from "react";
import Select, { components } from "react-select";
import { reactDropdownStyles } from "../../../utils";
import { DropdownIndicator } from "../../ReusedComponent/SelectReused";
import { compose } from "redux";
import { connect } from "react-redux";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";
import Checkbox from "../Checkbox";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";

const DropdownInput = ({
  title,
  name,
  value,
  errorProps,
  setFieldValue,
  placeholder,
  error,
  containerClass,
  disabled,
  options,
  defaultValue,
  isMultiStatus = false,
  isDarkTheme
}) => {
  const [selected, setSelected] = useState(value ? { label: value, value: value } : "");
  const { width: ScreenWidth } = useWindowSize();

  useEffect(() => {
    if (selected) {
      if (selected.value !== value && options) {
        setSelected(options.find((option) => option.value === value));
      }
    } else {
      if (options) {
        // Different condition because of occupation options structure
        if (name === "Occupation") {
          let occupationData = "";
          options.forEach((element) => {
            element.options.forEach((item) => {
              if (item.value === value) {
                occupationData = item;
              }
            });
          });

          setSelected(occupationData);
        } else {
          setSelected(options.find((option) => option.value === value));
        }
      }
    }
    // eslint-disable-next-line
  }, [value, options]);

  const onChangeHandler = (value) => {
    if (isMultiStatus) {
      if (Array.isArray(value)) {
        let resultData = [];
        value.map((item, index) => {
          let quotient = Math.floor(100 / value.length);
          let remainder = 100 % value.length;
          let resultArray = Array(value.length).fill(quotient);

          for (let i = 0; i < remainder; i++) {
            value.sourceFund = item.value;
            value.percentage = resultArray[i];
            value.imgUrl = "";
            resultArray[i]++;
          }

          let obj = {
            label: item.label,
            value: item.value,
            sourceFund: item.value,
            percentage: resultArray[index],
            imgUrl: item.imgUrl ? item.imgUrl : ""
          };

          resultData.push(obj);
        });

        setSelected(resultData || value);
        setFieldValue(name, resultData || value);
      }
    } else {
      setSelected(value);
      setFieldValue(name, isMultiStatus ? value : value.value);
    }
  };

  const MoreSelectedBadge = ({ items }) => {
    const title = items.join(", ");
    const length = items.length;
    const label = `+${length}`;

    return (
      <BannerBox BadgeForDropDown size={`${length > 9 ? "10px" : "12px"}`} title={title}>
        {label}
      </BannerBox>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = props.maxToShow ? props.maxToShow : 2;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);

    return index < maxToShow ? (
      <components.MultiValue {...props} />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    // eslint-disable-next-line no-unused-vars
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      display: "flex "
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <Checkbox checked={isSelected} setChecked={() => {}} />
        <BannerHeadingSpan ml="8px">
          <label htmlFor="lcx-checkbox"> {children} </label>
        </BannerHeadingSpan>
      </components.Option>
    );
  };

  return (
    <Fragment>
      <BannerBox width="100%" mb="32px" mbMd="25px">
        <Label txtGrey size="14px" width="100%">
          {title}
        </Label>
        <BannerBox mt="8px">
          <Select
            className={`${error ? "fInput__form__input--bRed" : "fInput__form__input--bGrey"} ${
              disabled ? "fInput__form__input--Disabled" : ""
            } `}
            components={
              isMultiStatus
                ? {
                    DropdownIndicator,
                    MultiValue,
                    Option: InputOption
                  }
                : { DropdownIndicator }
            }
            name={name}
            defaultValue={defaultValue}
            closeMenuOnSelect={isMultiStatus ? !isMultiStatus : true}
            hideSelectedOptions={isMultiStatus ? !isMultiStatus : true}
            isMulti={isMultiStatus ? isMultiStatus : false}
            isClearable={isMultiStatus ? !isMultiStatus : false}
            isSearchable={true}
            placeholder={placeholder}
            onChange={onChangeHandler}
            styles={reactDropdownStyles({ isDarkTheme, disabled, ScreenWidth })}
            options={options}
            value={selected || value || ""}
            isDisabled={disabled}
            minMenuHeight={200}
            maxMenuHeight={200}
            menuPlacement="auto"
          />
        </BannerBox>
        <ErrorMessageDiv
          name={name}
          component="div"
          mt="6px"
          size="14px"
          sizemd="12px"
          weight="300"
          txtDanger
          {...errorProps}
        />
      </BannerBox>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(DropdownInput);
