import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  levelStatus: "",
  isLevelStatusFetching: false,
  isLevelStatusError: "",
  levelOneStatus: "",
  isLevelOneStatusFetching: false,
  isLevelOneStatusError: "",
  countriesList: "",
  isFetchingCountries: false,
  countriesError: "",
  kycContent: "",
  error: "",
  loader: false
};

let initialState = initialStateSchema;

const kycReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GET_LEVEL_STATUS:
      newState = {
        ...state,
        isLevelStatusFetching: true
      };

      return newState;

    case apiConstants.GET_LEVEL_STATUS_SUCCESS:
      newState = {
        ...state,
        levelStatus: action.response,
        isLevelStatusFetching: false
      };
      return newState;

    case apiConstants.GET_LEVEL_STATUS_FAIL:
      newState = {
        ...state,
        isLevelStatusError: action.response,
        isLevelStatusFetching: false
      };
      return newState;

    case apiConstants.GET_COUNTRIES_LIST:
      newState = {
        ...state,
        isFetchingCountries: true,
        countriesError: ""
      };

      return newState;

    case apiConstants.GET_COUNTRIES_LIST_SUCCESS:
      newState = {
        ...state,
        countriesList: (action.response && action.response?.data?.data) || "",
        isFetchingCountries: false
      };
      return newState;

    case apiConstants.GET_COUNTRIES_LIST_FAIL:
      newState = {
        ...state,
        countriesError: action.response,
        isFetchingCountries: false
      };
      return newState;

    case apiConstants.GET_KYC_FAQ_CONTENT:
      newState = {
        ...state,
        loader: true
      };

      return newState;

    case apiConstants.GET_KYC_FAQ_CONTENT_SUCCESS:
      newState = {
        ...state,
        loader: false,
        kycContent: action.response.data
      };
      return newState;

    case apiConstants.GET_KYC_FAQ_CONTENT_FAIL:
      newState = {
        ...state,
        loader: false,
        error: action.response.data
      };
      return newState;

    default:
      return state;
  }
};

export default kycReducer;
