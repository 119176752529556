import styled, { css } from "styled-components";

const TableHead = styled.thead`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "40px"} !important;
  background: ${(props) => props.theme.tableHead};
  border: none;
  tr,
  th {
    border: none;
  }
  th {
    color: ${(props) => props.theme.bodyText};
    font-size: ${(props) => props.size || "14px"};
    border: none !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  th.symb-padd {
    padding-left: 5.4% !important;
  }
  tr {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    ${(props) => props.width && `width: ${props.width}`};
    ${(props) => props.minWidth && `min-width: ${props.minWidth}`};
    color: ${(props) => props.theme.bodyText};
  }
  ${(props) =>
    props.MarketTable &&
    css`
      th {
        &:nth-child(1) {
          min-width: 50px !important;
          max-width: 50px !important;
          flex: 50 1 0% !important;
          padding: 0 0 0 19px !important;
        }
        &:nth-child(2) {
          min-width: 170px !important;
          max-width: 170px !important;
          flex: 170 1 0% !important;
        }
        &:nth-child(3),
        &:nth-child(9) {
          min-width: 117px !important;
          max-width: 117px !important;
          flex: 117 1 0% !important;
        }
        &:nth-child(4),
        &:nth-child(5) {
          min-width: 123px !important;
          max-width: 123px !important;
          flex: 123 1 0% !important;
        }

        &:nth-child(7),
        &:nth-child(8) {
          min-width: 110px !important;
          max-width: 110px !important;
          flex: 110 1 0% !important;
        }

        &:nth-child(6) {
          min-width: 115px !important;
          max-width: 115px !important;
          flex: 115 1 0% !important;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          justify-content: flex-end !important;
        }
        .exch-dashboard-btn-link {
          margin: 0 !important;
          margin-left: 8px !important;
          padding: 0 !important;
          span.ml-2 {
            margin-left: 0 !important;
          }
        }
      }
    `}
`;

const TableBody = styled.tbody`
  width: ${(props) => props.width || "100%"};
  ${(props) => props.height && `height: ${props.height};`};
  border: none;
  tr,
  td {
    border: none;
  }
  td {
    padding-top: 0;
    padding-bottom: 0;
    color: ${(props) => props.theme.bodyText};
    font-size: ${(props) => props.size || "16px"} !important;
    font-weight: ${(props) => props.weight || "500"} !important;
    ${(props) => props.padding && `padding: ${props.padding};`};
    ${(props) => props.paddLeft && `padding-left: ${props.paddLeft};`};
    ${(props) => props.paddRight && `padding-right: ${props.paddRight};`};
  }
  tr {
    ${(props) => props.width && `width: ${props.width};`};
    ${(props) => props.minWidth && `min-width: ${props.minWidth};`};
    height: 56px !important;
    color: ${(props) => props.theme.bodyText};
    border-bottom: 1px solid ${(props) => props.borderColor};
    &:first-child() {
      border-top: none;
    }
    &:last-child() {
      border: none;
    }
  }
  ${(props) =>
    props.MarketTable &&
    css`
      max-height: 600px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 0px;
        background-color: #fcfcfd;
      }

      td {
        &:nth-child(1) {
          min-width: 50px !important;
          max-width: 50px !important;
          flex: 50 1 0% !important;
          padding: 0 0 0 19px !important;
        }
        &:nth-child(2) {
          min-width: 170px !important;
          max-width: 170px !important;
          flex: 170 1 0% !important;
        }
        &:nth-child(3),
        &:nth-child(9) {
          min-width: 117px !important;
          max-width: 117px !important;
          flex: 117 1 0% !important;
        }
        &:nth-child(4) {
          min-width: 117px !important;
          max-width: 117px !important;
          flex: 117 1 0% !important;
        }
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          min-width: 110px !important;
          max-width: 110px !important;
          flex: 110 1 0% !important;
        }
        .exch-dashboard-btn-link {
          margin: 0 !important;
          margin-left: 8px !important;
          padding: 0 !important;
          span.ml-2 {
            margin-left: 0 !important;
          }
        }
        @media (max-width: 767px) {
          &:nth-child(1) {
            min-width: 50px !important;
            max-width: 50px !important;
            flex: 50 1 0% !important;
            padding: 0 0 0 5px !important;
          }
        }
      }
    `}
`;

export { TableHead, TableBody };
