/* eslint-disable no-unused-vars */
import axios from "axios";
import moment from "moment";
import { API } from "../../constants/api";
import apiURL from "../../constants/apiConstants";
import globalConfig from "../../constants/config";
import { formatAPIErrors, getLocalStorageData } from "../../utils/general";
import { getCookie } from "../../utils/cookies";

axios.interceptors.response.use(
  (r) => r,
  function handle401Errors(error) {
    if (error && error.response && error.response.status === 401) {
      window.location = globalConfig.exchangeUrl;
      window.dispatchEvent(new Event("LOGOUT"));
    }
    return Promise.reject(error);
  }
);

export const walletAPI = axios.create({
  baseURL: apiURL.WALLET_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

export const walletV2API = axios.create({
  baseURL: apiURL.WALLET_URL_V2,
  headers: {
    "Content-Type": "application/json"
  }
});
export const walletV3API = axios.create({
  baseURL: apiURL.WALLET_URL_V3,
  headers: {
    "Content-Type": "application/json"
  }
});

/**
 * @param {Get Email Verification for LCX User } param
 */

export async function getEmailVerify(param) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletV2API.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletV2API.post("/user/verifyEmail", param).then((res) => res);
    return response;
  }
}
export async function getVerifyBlincId(param) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletAPI.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletAPI.post("/verifyBlinc", param).then((res) => res);
    return response;
  }
}

/**
 * @param {Invite the User API } param
 */

export async function inviteUserApi(param) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    return false;
  } else {
    walletV2API.defaults.headers.common["Authorization"] = accessToken;
    const response = await walletV2API.post("/user/inviteEmail", param).then((res) => res);
    return response;
  }
}

/**
 *
 * @param {LCX Pay withdrawal API} data
 * @returns
 */

export async function lcxWithdrawalApi(params) {
  const accessToken = localStorage.getItem("accessToken");
  walletV2API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletV2API.post("/user/withdrawalByEmail", params).then((res) => res);
    return response;
  }
}
/**
 *
 * @param {Blinc Confirm Widthdrawl} data
 * @returns
 */

export async function BlincConfirmWidthdrawl(params) {
  const accessToken = localStorage.getItem("accessToken");
  walletV3API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletV3API.post("/confirmWithdrawal", params).then((res) => res);
    return response;
  }
}

/**
 *
 * @param {Confirm Withdrawal by LCX Pay API} data
 * @returns
 */

export async function getConfirmWithdrawalEmailApi(params) {
  const accessToken = localStorage.getItem("accessToken");
  walletV3API.defaults.headers.common["Authorization"] = accessToken;
  if (typeof accessToken === "undefined" || accessToken == null) return false;
  else {
    const response = await walletV3API
      .post("/user/confirmWithdrawalByEmail", params.data)
      .then((res) => res);
    return response;
  }
}
