/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { getAntiPhisingCode } from "../../../actions/Setting/setting";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { MainElement, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import Step2Modal from "../ApiKey/component/Step2Modal";
import DisableStepModal from "./DisableStep";
import { getUserDetails } from "../../../actions/dashboard";

const SecurityConfirm = (props) => {
  const { userData, antiPhising } = props;
  const [TwoFaModal, setTwofamodal] = useState(false);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    props.getAntiPhisingCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setTwofamodal(false);
  };

  return (
    <>
      {TwoFaModal && <DisableStepModal onClose={closeModal} {...props} />}
      <MainElement width="100%" padding={"0px 16px"} paddingMd={"20px 16px"}>
        <BannerBox>
          <BannerHeading size={"24px"} sizeMd={"18px"} mb={"8px"} weight="bold">
            Security
          </BannerHeading>
          <ParaTag size={"16px"} sizeLg={"16px"} sizeMd={"12px"} weight="500">
            Adhering to the security protocol ensures the safeguarding of your account from various
            threats. Please complete your profile to upgrade your account.
          </ParaTag>
        </BannerBox>

        <BannerSection width="100%" padd="24px 0px" SettingBorderBottom>
          <BannerBox
            display="flex"
            directionMd="column"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            alignItemsLg="start"
            gapLg="16px"
          >
            <BannerBox
              display="flex"
              direction="column"
              justifyContent="space-evenly"
              width="60%"
              widthMd="100%"
            >
              <BannerHeading size="20px" weight="bold" sizeMd="14px">
                2 Factor Authentication
              </BannerHeading>
              <ParaTag weight="300" size="16px" mt="12px" sizeMd="14px">
                With 2-Step Verification you have an increased security to your account.
              </ParaTag>
            </BannerBox>

            <BannerBox width="40%" textAlign="right" widthMd="100%" textAlignMd="start">
              {userData.twoFactorAuth && userData.twoFactorAuth.status ? (
                <ButtonComponent
                  OutlineDanger
                  type="submit"
                  width="120px"
                  btnHeight="48px"
                  widthMd="109px"
                  btnHeightMd="44px"
                  onClick={() => setTwofamodal(true)}
                >
                  Disable
                </ButtonComponent>
              ) : (
                <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/security/auth`}>
                  <ButtonComponent
                    PrimaryButton
                    type="submit"
                    width="120px"
                    btnHeight="48px"
                    widthMd="109px"
                    btnHeightMd="44px"
                  >
                    Enable
                  </ButtonComponent>
                </Link>
              )}
            </BannerBox>
          </BannerBox>
        </BannerSection>

        <BannerSection width="100%" padd="24px 0px" SettingBorderBottom>
          <BannerBox
            display="flex"
            directionMd="column"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            alignItemsLg="start"
            gapLg="16px"
          >
            <BannerBox
              display="flex"
              direction="column"
              justifyContent="space-evenly"
              width="60%"
              widthMd="100%"
            >
              <BannerHeading size="20px" weight="bold" sizeMd="14px">
                Anti-Phishing
              </BannerHeading>
              <ParaTag weight="300" size="16px" mt="12px" sizeMd="14px">
                Secures your account from web-based threats.
              </ParaTag>
            </BannerBox>

            <BannerBox width="40%" textAlign="right" widthMd="100%" textAlignMd="start">
              <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/security/phishing`}>
                {antiPhising.exists ? (
                  <ButtonComponent
                    OutlineDanger
                    type="submit"
                    width="120px"
                    btnHeight="48px"
                    widthMd="109px"
                    btnHeightMd="44px"
                  >
                    Disable
                  </ButtonComponent>
                ) : (
                  <ButtonComponent
                    PrimaryButton
                    type="submit"
                    width="120px"
                    btnHeight="48px"
                    widthMd="109px"
                    btnHeightMd="44px"
                  >
                    Enable
                  </ButtonComponent>
                )}

                {/* {isAntiPhisingFetching && (
                <BannerBox display="flex" direction="column" mt="1rem">
                  <SkeletonLoader width={80} />
                </BannerBox>
              )} */}
              </Link>
            </BannerBox>
          </BannerBox>
        </BannerSection>

        <BannerSection width="100%" padd="24px 0px">
          <BannerBox
            display="flex"
            directionMd="column"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            alignItemsLg="start"
            gapLg="16px"
          >
            <BannerBox
              display="flex"
              direction="column"
              justifyContent="space-evenly"
              width="60%"
              widthLg="100%"
            >
              <BannerHeading size="20px" weight="bold" sizeMd="14px">
                Password
              </BannerHeading>
              <ParaTag weight="300" size="16px" mt="12px" sizeMd="14px">
                Change your password periodically to protect your account.
              </ParaTag>
            </BannerBox>

            <BannerBox width="40%" textAlign="right" textAlignMd="start">
              <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/password`}>
                <ButtonComponent
                  PrimaryButton
                  type="submit"
                  width="120px"
                  btnHeight="48px"
                  widthMd="109px"
                  btnHeightMd="44px"
                >
                  Change
                </ButtonComponent>
              </Link>
            </BannerBox>
          </BannerBox>
        </BannerSection>
      </MainElement>
    </>
  );
};

const maptoStateProps = (state) => ({
  isTheme: state.theme.isTheme,
  isLoggedIn: state.auth.isLoggedIn,
  userData: state.dashboard.getUserData,
  isWholeLoading: state.dashboard.isWholeLoading,
  antiPhising: state.setting.antiPhising,
  isAntiPhisingFetching: state.setting.isAntiPhisingFetching
});

const mapDispatchToProps = (dispatch) => ({
  getAntiPhisingCode: (data) => dispatch(getAntiPhisingCode(data)),
  getUserData: (data) => dispatch(getUserDetails())
});

export default connect(maptoStateProps, mapDispatchToProps)(withRouter(SecurityConfirm));
