import { DivElement, RouterLink, TextWrapper } from "../../../../components/Reusable";

const LoginMessage = () => {
  const prevLang = localStorage.getItem("ljs-lang");
  
  return (
    <DivElement width="100%" height="100%" displayCenter>
      <TextWrapper fontSize="16px" fontSizeSm="14px" lineHeight="24px" lineHeightSm="18px">
        <RouterLink fontSize="inherit" lineHeight="inherit" to={`${prevLang ? `/${prevLang}` : ""}/login`}>
          Log In
        </RouterLink>{" "}
        or{" "}
        <RouterLink fontSize="inherit" lineHeight="inherit" to="/register">
          Register Now
        </RouterLink>{" "}
        to trade
      </TextWrapper>
    </DivElement>
  );
};

export default LoginMessage;
