/* eslint-disable no-debugger */
/* eslint-disable no-console */
import React, { Fragment, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { getMarketTopData } from "../../actions/marketLists";
import { watchList } from "../../actions/WatchList/watchlist";
import { selectedPair } from "../../actions/trade";
import ErrorBoundary from "../ErrorBoundary/errorBoundary";
import { Skeleton } from "../Loader";
import { DivElement, ImgElement, Table, Tbody, Td } from "../Reusable";
import styledConstants from "../../constants/styledConstants";
import {
  ChartMarketGainerIllustration,
  ChartMarketLoserIllustration,
  NegitiveConemarketsIllustration,
  PositiveConeMarketsIllustration,
  RecentlyListedIllustration
} from "../../assets/svgs/illustrations";
import { MarketRow } from "../../views/marketPage/MarketRow";
import configs from "../../constants/config";
import { numberWithCommas, noExponents } from "../../utils/general";

function GetSVGForMarketPage(type) {
  switch (type) {
    case "Gainer":
      return <ChartMarketGainerIllustration />;
    case "Looser":
      return <ChartMarketLoserIllustration />;
    case "Recently Listed":
      return <RecentlyListedIllustration />;
    default:
      return <ChartMarketGainerIllustration />;
  }
}

const MarketCap = (props) => {
  let { title, type } = props;
  const { width } = useWindowSize();
  const tickersData = useSelector((state) => state.trading.tickersData);
  const userData = useSelector((state) => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");

  const tickers = useMemo(() => {
    let keys = Object.keys(tickersData);
    if (keys.length === 0) {
      return [];
    } else {
      let data = keys.map((item) => {
        return { ...tickersData[item] };
      });
      return data;
    }
  }, [tickersData]);

  const history = useHistory();
  const isTheme = useSelector((state) => state.theme.isDarkTheme);

  const TopGainers = useMemo(() => {
    return tickers.length > 0 && tickers?.sort((a, b) => b?.change - a?.change).slice(0, 3);
  }, [tickers]);
  const TopLosers = useMemo(() => {
    return tickers.length > 0 && tickers?.sort((a, b) => a?.change - b?.change).slice(0, 3);
  }, [tickers]);
  const RecentListed = useMemo(() => {
    return tickers.length > 0 && tickers?.sort((a, b) => b?.createdAt - a?.createdAt).slice(0, 3);
  }, [tickers]);

  const handleLink = (givenLink) => {
    history.push(
      `${
        prevLang
          ? `/${prevLang}`
          : userData.preferredLanguage
          ? `/${userData.preferredLanguage}`
          : ""
      }/trade/${givenLink.split("/").join("-")}`
    );
  };

  return (
    <BannerBox
      cursorPointer="pointer"
      // HoverScaleBox
      MarketCardBox
      height="196px"
      width="100%"
      heightLg="196px"
      padding="16px 0px"
    >
      <ErrorBoundary>
        {/* Header Section */}
        <BannerBox padding="0px 16px" width="100%" height="40px" mb="10px" bottomBorder>
          <BannerBox display="flex" alignItems="start" gap="12px">
            {GetSVGForMarketPage(type)}
            <BannerHeading weight="500" size="16px">
              {title}
            </BannerHeading>
          </BannerBox>
        </BannerBox>
        {/* Table Section */}
        <BannerBox>
          <Table>
            <Tbody fontSizeMd="14px" fontSizeSm="12px" fontSize="14px" fontWeight="400">
              {title === "Top Gainers" &&
                TopGainers.length > 0 &&
                TopGainers?.map((data, index) => {
                  const [split1, split2] = data.symbol && data.symbol.split("/");
                  return (
                    <MarketRow displayFlex OrdersPagesHeadRow key={index}>
                      {width > 1024 && <Td>{index + 1}</Td>}
                      <Td
                        textAlign="left"
                        display="flex"
                        alignItems="center"
                        gap="6px"
                        onClick={() => handleLink(data?.symbol)}
                      >
                        <ImgElement
                          width="18px"
                          height="18px"
                          src={`${
                            !isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                          }${split1.toLowerCase()}.svg`}
                        />
                        <BannerBox>
                          <BannerHeadingSpan weight="700">{split1}</BannerHeadingSpan>/{split2}
                        </BannerBox>
                      </Td>
                      {width > 1024 && (
                        <Td fontWeight="500" textAlign="right">
                          <var>{noExponents(numberWithCommas(data?.lastPrice))}</var>
                        </Td>
                      )}
                      <Td
                        textAlign="right"
                        fontWeight="500"
                        display="flex"
                        width="100%"
                        gap="3px"
                        alignItems="center"
                        alignItemsLg="flex-end"
                        justifyContent="flex-end"
                      >
                        <BannerHeading
                          txtSuccess={data.change >= 0 && true}
                          txtDanger={data.change < 0 && true}
                          size="14px"
                        >
                          {data.change}%
                        </BannerHeading>
                        <BannerHeadingSpan>
                          {data.change >= 0 ? (
                            <PositiveConeMarketsIllustration />
                          ) : (
                            <NegitiveConemarketsIllustration />
                          )}
                        </BannerHeadingSpan>
                      </Td>
                    </MarketRow>
                  );
                })}
              {title === "Top Losers" &&
                TopLosers.length > 0 &&
                TopLosers?.map((data, index) => {
                  const [split1, split2] = data.symbol && data.symbol.split("/");
                  return (
                    <MarketRow
                      displayFlex
                      OrdersPagesHeadRow
                      key={index}
                      className="row_marketing_localise_js"
                    >
                      {width > 1024 && <Td>{index + 1}</Td>}
                      <Td
                        textAlign="left"
                        display="flex"
                        alignItems="center"
                        gap="6px"
                        onClick={() => handleLink(data?.symbol)}
                      >
                        <ImgElement
                          width="18px"
                          height="18px"
                          src={`${
                            !isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                          }${split1.toLowerCase()}.svg`}
                        />
                        <BannerBox>
                          <BannerHeadingSpan weight="700">{split1}</BannerHeadingSpan>/{split2}
                        </BannerBox>
                      </Td>
                      {width > 1024 && (
                        <Td fontWeight="500" textAlign="right">
                          <var>{noExponents(numberWithCommas(data?.lastPrice))}</var>
                        </Td>
                      )}
                      <Td fontWeight="500" display="flex" gap="3px" alignItems="center">
                        <BannerHeading
                          txtSuccess={data.change >= 0 && true}
                          txtDanger={data.change < 0 && true}
                          size="14px"
                        >
                          {data?.change}%
                        </BannerHeading>
                        <BannerHeadingSpan>
                          {data?.change >= 0 ? (
                            <PositiveConeMarketsIllustration />
                          ) : (
                            <NegitiveConemarketsIllustration />
                          )}
                        </BannerHeadingSpan>
                      </Td>
                    </MarketRow>
                  );
                })}
              {title === "Recently Listed" &&
                RecentListed.length > 0 &&
                RecentListed?.map((data, index) => {
                  const [split1, split2] = data.symbol && data.symbol.split("/");
                  return (
                    <MarketRow displayFlex OrdersPagesHeadRow key={index}>
                      {width > 1024 && <Td>{index + 1}</Td>}
                      <Td
                        textAlign="left"
                        display="flex"
                        alignItems="center"
                        gap="6px"
                        onClick={() => handleLink(data?.symbol)}
                      >
                        <ImgElement
                          width="18px"
                          height="18px"
                          src={`${
                            !isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                          }${split1.toLowerCase()}.svg`}
                        />
                        <BannerBox>
                          <BannerHeadingSpan weight="700">{split1}</BannerHeadingSpan>/{split2}
                        </BannerBox>
                      </Td>
                      {width > 1024 && (
                        <Td fontWeight="500" textAlign="right">
                          <var>{noExponents(numberWithCommas(data?.lastPrice))}</var>
                        </Td>
                      )}
                      <Td
                        fontWeight="500"
                        display="flex"
                        gap="3px"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <BannerHeading
                          txtSuccess={data.change >= 0 && true}
                          txtDanger={data.change < 0 && true}
                          size="14px"
                        >
                          {data.change}%
                        </BannerHeading>
                        <BannerHeadingSpan>
                          {data.change >= 0 ? (
                            <PositiveConeMarketsIllustration />
                          ) : (
                            <NegitiveConemarketsIllustration />
                          )}
                        </BannerHeadingSpan>
                      </Td>
                    </MarketRow>
                  );
                })}
            </Tbody>
          </Table>
        </BannerBox>
      </ErrorBoundary>
    </BannerBox>
  );
};

const MarketCapComponent = () => {
  const tickersData = useSelector((state) => state.trading.tickersData);
  const { width } = useWindowSize();
  const isDesktop = width > styledConstants.BREAKING_POINT_TABLET;

  return (
    <Fragment>
      {width > 767 && !tickersData ? (
        <BannerBox className="exch-view-tablet-upto" width="100%">
          <BannerBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gapLg="14px"
            gap="16px"
          >
            {[0, 1, 2].map((item) => (
              <BannerBox
                MarketCardBox
                heightLg="224px"
                key={item}
                width="100%"
                height="196px"
                widthLg="100%"
              >
                <BannerBox padding="10px">
                  <BannerBox mtLg="13px" mt="10px">
                    <Skeleton height="25px" width={isDesktop ? "150px" : "135px"} />
                    <DivElement
                      flex
                      justifyContent="space-between"
                      flexDirection="column"
                      marginTop="20px"
                      gap="15px"
                    >
                      <Skeleton height="20px" width="100%" />
                      <Skeleton height="20px" width="100%" />
                      <Skeleton height="20px" width="100%" />
                    </DivElement>
                  </BannerBox>
                </BannerBox>
              </BannerBox>
            ))}
          </BannerBox>
        </BannerBox>
      ) : (
        <BannerBox width="100%">
          {width > 767 && (
            <BannerBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              gap="16px"
              gapLg="24px"
            >
              <MarketCap type="Gainer" title="Top Gainers" />
              <MarketCap type="Looser" title="Top Losers" />
              <MarketCap title="Recently Listed" type="Recently Listed" />
            </BannerBox>
          )}
        </BannerBox>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  isMarketLists: state.market.isMarketLists,
  selectedPairName: state.trade.selectedPair,
  isSelectedPairFecthing: state.trade.isSelectedPairFecthing,
  isMarketListFetching: state.market.isMarketListFetching,
  isThemeFecthing: state.theme.isThemeFetching,
  topMarketData: state.market.topMarketData,
  isTopMarketFetching: state.market.isTopMarketFetching
});

const mapDispatchToProps = (dispatch) => ({
  getMarketTopData: () => dispatch(getMarketTopData()),
  selectedPair: (user) => dispatch(selectedPair(user)),
  watchList: (user) => dispatch(watchList(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MarketCapComponent));
