import { connect } from "react-redux";
import { ErrorMessage } from "formik";
import { compose } from "redux";
import styled, { css } from "styled-components";

const ErrorMessageDiv = styled(ErrorMessage)`
  ${(props) => props.cursorPointer && "cursor: pointer"};
  ${(props) => props.color && `color: ${props.color || props.theme.headerText}`};
  ${(props) => props.bck && `background-color: ${props.bck}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.size && `font-size: ${props.size || "16px"}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.minWidth && `min-width: ${props.minWidth}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.weight && `font-weight: ${props.weight || 300}`};
  ${(props) => props.margin && `margin: ${props.margin || "0"}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.padding && `padding: ${props.padding}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.float && `float: ${props.float}`};
  ${(props) => props.shadow && `box-shadow: ${props.shadow}`};
  ${(props) => props.position && `position: ${props.position}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.spacing && `letter-spacing: ${props.spacing}`};
  ${(props) => props.BorderRadius && `border-radius: ${props.BorderRadius}`};
  ${(props) => props.ml && `margin-left: ${props.ml}`};
  ${(props) => props.mr && `margin-right: ${props.mr}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.fontStyle && `font-style: ${props.fontStyle}`};
  ${(props) => props.textTransform && `text-transform: ${props.textTransform}`};
  ${(props) => props.paddingLeft && `padding-left: ${props.paddingLeft}`};
  ${(props) => props.display && `display: ${props.display}`};

  & img {
    ${(props) => props.width && `width: ${props.width}`};
    ${(props) => props.imgMargin && `margin: ${props.imgMargin}`};
  }

  @media (max-width: 1024px) {
    ${(props) => props.widthLg && `width: ${props.widthLg}`};
    ${(props) => props.minWidthLg && `min-width: ${props.minWidthLg}`};
    ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`};
    ${(props) => props.marginLg && `margin: ${props.marginLg || "0"}`};
    ${(props) => props.paddingLg && `padding: ${props.paddingLg}`};
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg || "16px"}`};
    ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg}`};
    ${(props) => props.topLg && `top: ${props.topLg}`};
    ${(props) => props.leftLg && `left: ${props.leftLg}`};
    ${(props) => props.rightLg && `right: ${props.rightLg}`};
    ${(props) => props.paddingTopLg && `padding: ${props.paddingTopLg} !important`};
      ${(props) => props.mtLg && `margin-top: ${props.mtLg}`};
  }

  @media (max-width: 767px) {
    ${(props) => props.widthMd && `width: ${props.widthMd}`};
    ${(props) => props.minWidthMd && `min-width: ${props.minWidthMd}`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.marginMd && `margin: ${props.marginMd || "0"}`};
    ${(props) => props.paddingMd && `padding: ${props.paddingMd}`};
    ${(props) => props.paddingTopMd && `padding: ${props.paddingTopMd} !important`};
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd || "16px"}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`};
    ${(props) => props.topMd && `top: ${props.topMd}`};
    ${(props) => props.leftMd && `left: ${props.leftMd}`};
    ${(props) => props.rightMd && `right: ${props.rightMd}`};
      ${(props) => props.mtMd && `margin-top: ${props.mtMd}`};Md
  }

  @media (max-width: 568px) {
    ${(props) => props.widthSm && `width: ${props.widthSm}`};
    ${(props) => props.minWidthSm && `min-width: ${props.minWidthSm}`};
    ${(props) => props.maxWidthSm && `max-width: ${props.maxWidthSm}`};
    ${(props) => props.marginSm && `margin: ${props.marginSm || "0"}`};
    ${(props) => props.paddingSm && `padding: ${props.paddingSm} !important`};
    ${(props) => props.sizeSm && `font-size: ${props.sizeSm || "16px"}`};
    ${(props) => props.textAlignSm && `text-align: ${props.textAlignSm}`};
  }
  @media (max-width: 380px) {
    ${(props) => props.sizeSm && `padding: ${props.sizeSm} !important`};
  }

  ${props => props.headerText && css`
    color: ${({ theme }) => theme.headerText} !important;
  `}

  ${props => props.CardLevel1 && css`
    background: ${({ theme }) => theme.level1Card} !important;
  `}

  ${props => props.CardLevel2 && css`
    background: ${({ theme }) => theme.level2Card} !important;
  `}
  ${props => props.WalletActiveTab && css`
    color: ${({ theme }) => theme.primary} !important;
    font-weight:500 !important
  `}
  
  ${(props) => props.txtGrey && css`
    color: ${(props) => props.color || props.theme.subText};
  `};

  ${(props) => props.txtDanger && css`
    color: ${(props) => props.color || props.theme.danger};
  `};

  ${(props) => props.txtSuccess && css`
    color: ${(props) => props.color || props.theme.success};
  `};

  ${(props) => props.txtPrimary && css`
    color: ${(props) => props.color || props.theme.primary};
  `};
`;

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(ErrorMessageDiv);