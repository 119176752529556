import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { KycSection } from "../../../assets/StyleComponents/kyc.style";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
} from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { AnchorTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { AllFaqs1, AllFaqs3 } from "../../../views/settingPages/ProfileNew/components/FaqArray";

const KycFAQ = ({ step }) => {
  const AllFaqs = step === 3 ? AllFaqs3 : AllFaqs1;
  return (
    <KycSection HavingIssueSectionStyles>
      {AllFaqs?.length > 0 && (
        <BannerSection radius="5px" mt="16px" mtMd="12px" FAQBorderKYC padding="0px 12px">
          <BannerHeading size="18px" margin="20px 0px" weight="500">
            FAQ
          </BannerHeading>
          <BannerBox display="flex" direction="column" gap="8px">
            {AllFaqs?.length > 0 &&
              AllFaqs.map((value, index) => {
                return (
                  <>
                    <AnchorTag
                      BodyColorHover
                      BodyColor
                      href={value?.link && value?.link}
                      target="_blank"
                      rel="noreferrer"
                      BorderBottomTradingComp={AllFaqs.length - 1 !== index}
                      padding={AllFaqs.length - 1 !== index ? "6px 0px" : "6px 0px 15px 0px"}
                    >
                      <BannerHeadingSpan weight="500" size="14px" sizeLg="14px" sizeMd="12px">
                        {" "}
                        {value?.title}
                      </BannerHeadingSpan>
                    </AnchorTag>
                  </>
                );
              })}
          </BannerBox>
        </BannerSection>
      )}
    </KycSection>
  );
};

const mapStateToProps = (state) => ({
  kycContent: state.kyc.kycContent,
  kycContentLoader: state.kyc.kycContentLoader
});

export default compose(connect(mapStateToProps))(KycFAQ);
