const styledConstants = {
  // Breaking Points
  // Width > 1024 is Desktop, Width > 767 is tablet, Width <=767 is Mobile
  BREAKING_POINT_TABLET: 1024,
  BREAKING_POINT_MOBILE: 767,

  // Light theme variables
  BLUE_COLOR_LIGHT: "#0470e1",
  RED_COLOR_LIGHT: "#e65252",
  GREEN_COLOR_LIGHT: "#008000",
  YELLOW_COLOR_LIGHT: "#ffc955",
  ORANGE_COLOR_LIGHT: "#ffb83e",
  WHITE_COLOR_LIGHT: "#fff",

  SUBTEXT_COLOR_LIGHT: "#606f80",
  TEXT_COLOR_LIGHT: "#012243",
  ICON_COLOR_LIGHT: "#707070",
  SEPERATOR_COLOR_LIGHT: "#ccced0",
  TAPABLE_INPUT_COLOR_LIGHT: "#8c9797",
  INPUT_COLOR_LIGHT: "#f5f6fa",
  PRIMARY_BG_COLOR_LIGHT: "#f5f6fa",
  CARD_BG_COLOR_LIGHT: "#ffffff",
  POPUP_BG_COLOR_LIGHT: "#ffffff",

  // Dark theme variables
  ICON_COLOR_DARK: "#BBBDBF",
  GREEN_COLOR_DARK: "#68bf60",
  SEPERATOR_COLOR_DARK: "#5c5f66"
};

export default styledConstants;
