import React, { Fragment } from "react";
import ModalWrapper from "../Reusable/ModalWrapper";
import { DivElement, TextWrapper } from "../Reusable";
import { CloseIcon } from "../../assets/svgs";

const RestrictedUser = ({ open, onClose }) => {
  return (
    <Fragment>
      {open && (
        <ModalWrapper>
          <DivElement ModalContainer padding="0 !important">
            <DivElement
              bgCream
              padding="0 24px"
              height="48px"
              borderRadius="10px 10px 0 0"
              displayBtw
            >
              <TextWrapper fontSize="20px" fontSizeSm="12px">
                Account Restricted
              </TextWrapper>
              <CloseIcon onClick={onClose} />
            </DivElement>
            <DivElement padding="50px 24px">
              <TextWrapper fontSize="14px" secondary textAlign="center">
                We apologize for the inconvenience. Your account is in restricted mode, allowing
                only withdrawals. Please contact support for assistance.
              </TextWrapper>
            </DivElement>
          </DivElement>
        </ModalWrapper>
      )}
    </Fragment>
  );
};

export default RestrictedUser;
