/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { useEffect } from "react";
import { Fragment } from "react";
import CloseSessionModal from "./CloseSessionModal";
import { compose } from "redux";
import { connect } from "react-redux";
import { getActiveSessions } from "../../../../lib/api";
import { formatAPIErrors } from "../../../../utils/general";
import { TableLoader } from "../../../Loader";
import { capitalize } from "lodash";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import {
  DivElement,
  SpanElement,
  Table,
  Tbody,
  Td,
  TextWrapper,
  Th,
  ThDropdown,
  Thead,
  Tr
} from "../../../Reusable";
import {
  AndroidIcon,
  AppleIcon,
  ComputerIcon,
  MobileIcon,
  WindowsIcon
} from "../../../../assets/svgs";
import OptionsDropdown from "../../../Reusable/Dropdown/OptionsDropdown";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";

const IPWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;

  @media (max-width: 1024px) {
    max-width: 162px;
  }
  @media (max-width: 767px) {
    max-width: 96px;
  }
`;

const ActiveSessions = () => {
  const [loader, setLoader] = useState(false);
  const [sessionsData, setSessionsData] = useState("");
  const [activeSessionData, setActiveSessionData] = useState("");
  const [activeCol, setActiveCol] = useState("");
  const { width } = useWindowSize();

  useEffect(() => {
    if (activeCol === "" && width < 768) {
      setActiveCol("IP");
    }

    if (width >= 768) {
      setActiveCol("");
    }
  }, [width]);

  useEffect(() => {
    getSessions();
  }, []);

  const getSessions = () => {
    setLoader(true);
    getActiveSessions()
      .then((res) => {
        setSessionsData(res && res.data.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setLoader(false));
  };

  const onCloseSessionHandler = (data) => {
    if (!data.current || data.allOther) {
      setActiveSessionData(data);
    }

    return;
  };

  const onAddressCopy = () => {
    showSuccessMsg("Successfully copied IP address!");
  };

  return (
    <Fragment>
      {activeSessionData && (
        <CloseSessionModal
          activeSessionData={activeSessionData}
          onClose={() => setActiveSessionData("")}
          getSessions={getSessions}
        />
      )}
      <DivElement paddingBottom="32px" paddingBottomSm="24px" bdGrey borderBottom="1px solid">
        <TextWrapper fontSize="24px" fontSizeSm="18px" fontWeight="bold" lineHeight="28px">
          Active Sessions
        </TextWrapper>
        <TextWrapper
          fontSize="16px"
          fontSizeSm="12px"
          fontWeight="300"
          lineHeight="18px"
          marginTop="10px"
        >
          Check the existing active session record.
          {sessionsData && sessionsData.length > 2 && (
            <Fragment>
              &nbsp;Wish to logout from all other devices, click here&nbsp;
              <TextWrapper
                fontSize="inherit"
                fontWeight="inherit"
                display="inline"
                red
                textDecoration="underline"
                cursor
                onClick={() => onCloseSessionHandler({ allOther: true })}
              >
                Close all sessions
              </TextWrapper>
            </Fragment>
          )}
        </TextWrapper>
        <DivElement marginTop="34px" marginTopMd="32px">
          {sessionsData && !loader ? (
            <Fragment>
              <Table bRadiusTop="5px" bRadiusBottom="5px" borderCollapse="collapse">
                <Thead fontSize="14px" fontSizeSm="12px">
                  <Tr>
                    <Th textAlign="left" paddingLeft="16px">
                      Log in Device
                    </Th>
                    {width >= 768 && <Th textAlign="left">IP</Th>}
                    {width >= 768 && <Th textAlign="left">Device OS</Th>}
                    {width < 768 && (
                      <ThDropdown
                        textAlign="left"
                        activeCol={activeCol}
                        OPTIONS={["IP", "Device OS"]}
                        selectOption={setActiveCol}
                      />
                    )}
                    <Th width={width >= 1024 ? "" : "48px"} paddingLeft="8px" paddingRight="16px">
                      {width >= 1024 ? "Action" : ""}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody fontSizeSm="12px">
                  {sessionsData.map((data, index) => (
                    <Tr
                      key={index}
                      height="56px"
                      heightSm="48px"
                      border={sessionsData.length === index + 1 ? null : true}
                    >
                      <Td textAlign="left" padding="0 8px" width="27%" widthMd="32%" widthSm="48%">
                        {data?.recentLogin?.userAgent?.device ? (
                          <DivElement display="inline-flex" alignItems="center">
                            {data.recentLogin.userAgent.device === "mobile" ? (
                              <MobileIcon marginRight="8px" />
                            ) : (
                              <ComputerIcon marginRight="8px" />
                            )}

                            {capitalize(data.recentLogin.userAgent.device || "")}
                          </DivElement>
                        ) : (
                          "Unknown"
                        )}
                      </Td>
                      {(width >= 768 || (width < 768 && activeCol === "IP")) && (
                        <Td
                          textAlign="left"
                          textAlignSm="right"
                          paddingSm="0 8px"
                          width="27%"
                          widthMd="32%"
                          widthSm="48%"
                          overflow="hidden"
                        >
                          <CopyToClipboard
                            text={data?.recentLogin?.ipAddress || ""}
                            onCopy={onAddressCopy}
                          >
                            <IPWrapper>{data?.recentLogin?.ipAddress || "Unknown"}</IPWrapper>
                          </CopyToClipboard>
                        </Td>
                      )}
                      {(width >= 768 || (width < 768 && activeCol === "Device OS")) && (
                        <Td
                          textAlign="left"
                          textAlignSm="right"
                          paddingSm="0 8px"
                          width="27%"
                          widthMd="32%"
                          widthSm="48%"
                        >
                          {data?.recentLogin?.userAgent?.os ? (
                            <DivElement display="inline-flex" alignItems="center">
                              {(data?.recentLogin?.userAgent?.os?.name || "")
                                .toLowerCase()
                                .includes("ios") ||
                              (data?.recentLogin?.userAgent?.os?.name || "")
                                .toLowerCase()
                                .includes("mac") ? (
                                <AppleIcon />
                              ) : (data?.recentLogin?.userAgent?.os?.name || "")
                                  .toLowerCase()
                                  .includes("windows") ? (
                                <WindowsIcon />
                              ) : (data?.recentLogin?.userAgent?.os?.name || "")
                                  .toLowerCase()
                                  .includes("android") ? (
                                <AndroidIcon />
                              ) : data.recentLogin.userAgent.device === "mobile" ? (
                                <MobileIcon />
                              ) : (
                                <ComputerIcon />
                              )}
                              <SpanElement marginLeft="6px">
                                {data?.recentLogin?.userAgent?.os?.name || ""}
                              </SpanElement>
                            </DivElement>
                          ) : (
                            "Unknown"
                          )}
                        </Td>
                      )}
                      <Td
                        width={width >= 1024 ? "19%" : "48px"}
                        paddingLeft="8px"
                        paddingRight="16px"
                        paddingRightSm="12px"
                      >
                        {width >= 1024 ? (
                          <Fragment>
                            {data.current ? (
                              <DivElement
                                display="inline-flex"
                                width="fit-content"
                                borderRadius="20px"
                                padding="4px 8px"
                                cursorDisabled
                                opacity="0.6"
                              >
                                <TextWrapper green>Current Session</TextWrapper>
                              </DivElement>
                            ) : (
                              <TextWrapper
                                red
                                cursor
                                textDecoration="underline"
                                fontSize="inherit"
                                onClick={() => onCloseSessionHandler(data)}
                              >
                                Close Session
                              </TextWrapper>
                            )}
                          </Fragment>
                        ) : (
                          <OptionsDropdown
                            containerClass="tablet-mobile"
                            borderColor={data.current ? "green" : "red"}
                          >
                            <ParaTag
                              cursorPointer={data.current ? "pointer" : "no-drop"}
                              txtSuccess={data.current ? true : false}
                              txtDanger={!data.current ? true : false}
                              onClick={() => onCloseSessionHandler(data)}
                              disabled={data.current ? true : null}
                              width="150px"
                              py="8px"
                            >
                              {data.current ? "Active Session" : "Close Session"}
                            </ParaTag>
                          </OptionsDropdown>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Fragment>
          ) : (
            <TableLoader webCol={4} tabletCol={3} mobileCol={2} rows={5} />
          )}
        </DivElement>
      </DivElement>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(ActiveSessions);
