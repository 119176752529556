import { connect } from "react-redux";
import DigitalAssetsComponent from "../views/digitalAssets/index.jsx";
import { logout } from "../actions/auth";

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  isThemeFecthing: state.theme.isThemeFetching,
  selectedPairName: state.trade.selectedPair,
  isSelectedPairFecthing: state.trade.isSelectedPairFecthing,
  isLoggedIn: state.auth.isLoggedIn,
  userData: state.dashboard.getUserData,
  isLogoutFetching: state.dashboard.isLogoutFetching
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(DigitalAssetsComponent);
