/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";
import { ButtonComponent } from "../../../../../assets/StyleComponents/Button.style";
import { ChevronLeftIcon } from "../../../../../assets/svgs";
import { HeadingFourth } from "../../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { ParaTag } from "../../../../../assets/StyleComponents/FontSize/para.style";
import { ImgElement } from "../../../../Reusable";
import { HeadingSix } from "../../../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { genrateBarCodeKYC, getUserDetails } from "../../../../../lib/api";
import { Skeleton } from "../../../../Loader";
import SubmittedModal from "../../SubmittedModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../../../../actions/Setting/setting";

function BarcodeSection({ setState, backbtn }) {
  const [qrCode, setQrCode] = useState("");
  const [modal, setModal] = useState(false);
  const [userData, setUserData] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem("ljs-lang");

  const CheckStatus = () => {
    getUserDetails()
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch(() => {
        throw new Error();
      });
  };

  useEffect(() => {
    genrateBarCodeKYC()
      .then((res) => {
        setQrCode(res?.data?.data || "");
      })
      .catch(() => {
        throw new Error();
      });

    CheckStatus();
    const intervalId = setInterval(CheckStatus, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (userData) {
      const LVL = userData?.KYC?.STATUS;
      const LVL2Status = userData?.KYC?.LEVEL2?.status;
      const HighRisk = userData?.KYC?.isHighRiskCountry;
      if (
        (LVL === "LEVEL1" || LVL === "LEVEL0") &&
        (LVL2Status === "SUBMITTED" ||
          LVL2Status === "PARTIALLY_SUBMITTED" ||
          LVL2Status === "APPROVED")
      ) {
        if (HighRisk) {
          dispatch(updateUserData(userData));
          history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/verify/verification-plus`);
        } else {
          setModal(true);
        }
      }
    }
  }, [userData]);

  const onClose = () => {
    setModal(false);
    history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/dashboard`);
  };

  return (
    <>
      {modal && <SubmittedModal onClose={onClose} />}
      <BannerBox
        display="flex"
        direction="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        {backbtn === "no" ? (
          ""
        ) : (
          <ButtonComponent
            position="absolute"
            left="0px"
            top="0px"
            DisabledCancelBtn2
            width="86px"
            btnHeight="28px"
            display="flex"
            alignItems="center"
            onClick={() => setState("main")}
          >
            <ChevronLeftIcon />
            back
          </ButtonComponent>
        )}

        <BannerBox mt="35px">
          <HeadingFourth size="18px" weight="500" textAlign="center" mb="8px">
            Continue with mobile app
          </HeadingFourth>
          <ParaTag size="14px" weight="500" textAlign="center">
            Scan the QR code and continue KYC from mobile application.
          </ParaTag>
        </BannerBox>
        <BannerBox width="192px" height="192px" mt="32px" mb="16px">
          {!qrCode && <Skeleton width="192px" height="192px" />}
          {qrCode && <ImgElement src={qrCode} width="192" height="192" />}
        </BannerBox>
        <HeadingSix size="14px" weight="500" txtDanger>
          Note - Please keep this page open while you continue with mobile.
        </HeadingSix>
      </BannerBox>
    </>
  );
}

export default BarcodeSection;
