/* eslint-disable no-console */
import { Fragment } from "react";
import DivElement from "../DivElement";
// import DateTime from "react-datetime";
import { CalendarIcon } from "../../../assets/svgs";
// import moment from "moment";
import styled, { css } from "styled-components";
import DatePickerComponent from "../../../components/DatePicker/DatePicker";

const DatePickerContainer = styled(DivElement)`
  position: relative;
  input {
    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${(props) => props.theme.subText} !important;
    }
  }

  ${(props) =>
    props.DatePickerContainerStyle &&
    css`
      display: block;
      height: 48px;
      width: 100%;
      position: relative;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
      border: none;
      border-radius: 5px 5px 0 0;
      background-color: ${({ theme }) => theme.inputBackground};
      border-bottom: 1px solid ${({ theme }) => theme.inputBorder};
    `}

  ${(props) =>
    props.right &&
    css`
      .rdtPicker {
        // right: -36px;
      }
    `}
`;

const DateRangePicker = ({ date, setDate, containerProps }) => {
  return (
    <Fragment>
      <DatePickerContainer
        width="inherit"
        className={`fInput__form__input ${date.fromDate ? "showActive" : ""}`}
        {...containerProps}
      >
        {/* <DatePickerStyles
          timeFormat={false}
          inputProps={{
            placeholder: "From",
            readOnly: true,
            className: "inputMain"
          }}
          dateFormat="DD/MM/YYYY"
          closeOnSelect={true}
          value={date?.fromDate}
          isValidDate={(selectedDate) => selectedDate.isBefore(date?.toDate || moment())}
          onChange={(val) => onChangeHandler(val, "fromDate")}
          renderInput={(props) => {
            return <input {...props} value={date?.fromDate ? props.value : ""} />;
          }}
        /> */}
        <DatePickerComponent
          quickFilterStatus="true"
          givenDate={date}
          selectedDate={date.fromDate}
          setQuickFilterValue={({ fromDate, toDate }) => {
            setDate({ fromDate, toDate });
          }}
          name="From"
        />
        <CalendarIcon absolute right="12px" top="13px" topSm="23px" />
      </DatePickerContainer>
      <DatePickerContainer
        // flexJustifyBtwAlignCenter
        width="inherit"
        className={`fInput__form__input ${date.toDate ? "showActive" : ""}`}
        // paddingRight="16px"
        right={true}
        // DatePickerContainerStyle
        {...containerProps}
      >
        {/* <DatePickerStyles2
          timeFormat={false}
          inputProps={{
            placeholder: "To",
            readOnly: true,
            className: "inputMain"
          }}
          dateFormat="DD/MM/YYYY"
          closeOnSelect={true}
          value={date?.toDate}
          isValidDate={
            (selectedDate) =>
              selectedDate.isSameOrAfter(date.fromDate) && // Allow same date
              selectedDate.isBefore(maxDate) && // Before max allowed date
              selectedDate.isBefore(today) // Not beyond today's date
          }
          onChange={(val) => onChangeHandler(val, "toDate")}
          renderInput={(props) => {
            return <input {...props} value={date?.toDate ? props.value : ""} />;
          }}
        /> */}
        <DatePickerComponent
          quickFilterStatus="true"
          givenDate={date}
          selectedDate={date.toDate}
          setQuickFilterValue={({ fromDate, toDate }) => setDate({ fromDate, toDate })}
          name="To"
        />
        <CalendarIcon absolute right="12px" top="13px" topSm="23px" />
      </DatePickerContainer>
    </Fragment>
  );
};

export default DateRangePicker;
