import React from "react";
import { DivElement, LinkButton, ModalWrapper, TextWrapper } from "../Reusable";
import { CloseIcon } from "../../assets/svgs";
import { TwoFaSecuredIllustration } from "../../assets/svgs/illustrations";

const Enable2FA = ({ onClose }) => {
  const prevLang = localStorage.getItem("ljs-lang");
  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <DivElement displayBtw>
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
            Improve Security
          </TextWrapper>
          <CloseIcon pointer onClick={onClose} />
        </DivElement>

        <DivElement displayCenter padding="32px 0">
          <TwoFaSecuredIllustration />
        </DivElement>

        <TextWrapper secondary fontSize="14px" fontWeight="400" lineHeight="20px">
          Enable 2FA, including Google Authenticator, to generate anti-phishing code
        </TextWrapper>

        <LinkButton
          primaryBlue
          width="100%"
          height="48px"
          to={`${prevLang ? `/${prevLang}`: "/en"}/setting/security/auth`}
          marginTop="24px"
          onClick={onClose}
        >
          Enable 2FA
        </LinkButton>
      </DivElement>
    </ModalWrapper>
  );
};

export default Enable2FA;
