import DivElement from "../DivElement";
import { DownBarWrapper } from "./BottomBar";
import { HeadingThird } from "../../../assets/StyleComponents/FontSize/headingThird.style";
import { CloseIcon } from "../../../assets/svgs";

function BottomBarComponent({ heading, children, onClose }) {
  return (
    <DownBarWrapper>
      <DivElement paddingSm="19px 16px 17px" DownBarContainer>
        <DivElement displayBtw>
          <HeadingThird size="20px" fontSizeSm="16px" lineHeight="24px">
            {heading}
          </HeadingThird>
          <CloseIcon pointer onClick={onClose} />
        </DivElement>
        {children}
      </DivElement>
    </DownBarWrapper>
  );
}

export default BottomBarComponent;
