import React from "react";
import { BadgeStyle } from "../../../assets/StyleComponents/Badge/badge.style";
import { BlueDotIcon, OneDotIcon } from "../../../assets/svgs";
// import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";

const BadgeComponent = ({ title, borderColor, size, radius, width, maxWidth }) => {
  return (
    <BadgeStyle
      size="12px"
      display="flex"
      alignItems="center"
      PrimaryBorder={borderColor === "primary" ? true : null}
      SuccessBorder={borderColor === "success" ? true : null}
      DangerBorder={borderColor === "danger" ? true : null}
      WarningBorder={borderColor === "warning" ? true : null}
      borderRadius={radius || "55px"}
      width={width ? width : "fit-content"}
    >
      <BannerHeadingSpan width="6px" height="17px" borderRadius="50%" mr="4px">
        {borderColor === "primary" ? (
          <BlueDotIcon width="6px" height="6px" />
        ) : (
          <OneDotIcon width="6px" height="6px" />
        )}
      </BannerHeadingSpan>
      {title}
    </BadgeStyle>
  );
};

export default BadgeComponent;
