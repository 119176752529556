import apiConstants from "../constants/apiConstants";

export const updateBestBid = (data) => ({
  type: apiConstants.UPDATE_BEST_BID,
  payload: data
});

export const updateBestAsk = (data) => ({
  type: apiConstants.UPDATE_BEST_ASK,
  payload: data
});

export const updateSelectedPair = (data) => ({
  type: apiConstants.UPDATE_SELECTED_PAIR,
  payload: data
});

export const getSelectedPairConfig = (data) => ({
  type: apiConstants.GET_SELECTED_PAIR_CONFIG,
  payload: data
});

export const updateOrderFormSide = (data) => ({
  type: apiConstants.UPDATE_ORDER_FORM_SIDE,
  payload: data
});

export const updateOrderFormType = (data) => ({
  type: apiConstants.UPDATE_ORDER_FORM_TYPE,
  payload: data
});

export const updateOrderBook = (data) => ({
  type: apiConstants.UPDATE_ORDER_BOOK,
  payload: data
});

export const updateAllBuyOrders = (data) => ({
  type: apiConstants.UPDATE_ALL_BUY_ORDERS,
  payload: data
});

export const updateAllSellOrders = (data) => ({
  type: apiConstants.UPDATE_ALL_SELL_ORDERS,
  payload: data
});

export const updateAllAsksAndBids = (data, orderType) => ({
  type: apiConstants.UPDATE_ALL_ASKS_AND_BIDS,
  payload: data,
  orderType: orderType
});

export const setTradeHistorySnapshot = (data) => ({
  type: apiConstants.SET_TRADE_HISTORY_SNAPSHOT,
  payload: data
});

export const setTradeHistoryUpdate = (data) => ({
  type: apiConstants.SET_TRADE_HISTORY_UPDATE,
  payload: data
});

export const setTickerSnapshot = (data) => ({
  type: apiConstants.SET_TICKER_SNAPSHOT,
  payload: data
});

export const setTickerUpdate = (data) => ({
  type: apiConstants.SET_TICKER_UPDATE,
  payload: data
});

export const updateSelectedChartType = (data) => ({
  type: apiConstants.UPDATE_SELECTED_CHART_TYPE,
  payload: data
});

export const updateOrderBookPricePrecision = (data) => ({
  type: apiConstants.UPDATE_ORDER_BOOK_PRICE_PRECISION,
  payload: data
});

export const updateFavChartPairs = (data) => ({
  type: apiConstants.UPDATE_FAV_CHART_PAIRS,
  payload: data
});

export const updateUserExchangeWallet = (data) => ({
  type: apiConstants.UPDATE_USER_EXCHANGE_WALLET,
  payload: data
});

export const updateUserOrders = (data) => ({
  type: apiConstants.UPDATE_USER_ORDERS,
  payload: data
});

export const updateOrderFromOrderBook = (data) => ({
  type: apiConstants.UPDATE_ORDER_FROM_ORDER_BOOK,
  payload: data
});

export const updateUserFavPairs = (data) => ({
  type: apiConstants.UPDATE_USER_FAV_PAIRS,
  payload: data
});

export const getAllPairsCategories = () => ({
  type: apiConstants.GET_ALL_PAIRS_CATEGORIES
});

export const performActionOnLayout = (data) => ({
  type: apiConstants.PERFORM_ACTION_ON_LAYOUT,
  payload: data
});

export const updateShowAllPairsOrder = (data) => ({
  type: apiConstants.UPDATE_SHOW_ALL_PAIRS_ORDER,
  payload: data
});

export const updateSelectedPairTicker = (data) => ({
  type: apiConstants.UPDATE_SELECTED_PAIR_TICKER,
  payload: data
});

export const getUserOrders = (data) => ({
  type: apiConstants.GET_USER_ORDERS,
  payload: data
});

export const updateActiveWallet = (data) => ({
  type: apiConstants.UPDATE_ACTIVE_WALLET,
  payload: data
});

export const updateOpenOrdersFromSocket = (data) => ({
  type: apiConstants.GET_USER_ORDERS_SUCCESS,
  response: data
});

export const updateOrderBookConfig = (data) => ({
  type: apiConstants.UPDATE_ORDER_BOOK_CONFIG,
  payload: data
});

export const updateOrderBookGraphData = (data) => ({
  type: apiConstants.UPDATE_ORDER_BOOK_GRAPH_DATA,
  payload: data
});

export const updateOrderBookActiveSide = (data) => ({
  type: apiConstants.UPDATE_ORDER_BOOK_ACTIVE_SIDE,
  payload: data
});
