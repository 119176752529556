import React from "react";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { NoRecordIllustration } from "../../assets/svgs/illustrations";

const NoRecordsFound = ({ text }) => {
  return (
    <BannerBox
      display="flex"
      direction="column"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <NoRecordIllustration />
      <br />
      <ParaTag size="16px" sizeMd="12px" sizeLg="14px" textAlign="center">
        {text ? text : "No Record Found"}{" "}
      </ParaTag>
    </BannerBox>
  );
};

export default NoRecordsFound;
