/**
 * Import Node Modules
 */
import { combineReducers } from "redux";

/**
 * Import Other Depedencies
 */
import ThemeReducer from "./themeReducer";
import MarketDataReducer from "./marketDataReducer";
import TradeReducer from "./tradeReducer";
import dashboardReducer from "./dashboardReducer";
import settingReducer from "./settingReducer";
import walletReducer from "./walletReducer";
import competitionReducer from "./competitionReducer";
import bankReducer from "./bankReducer";
import WatchListReducer from "./watchListReducer";
import earnReducer from "./earnReducer";
import kycReducer from "./kycReducer";
import authReducer from "./authReducer";
import maintenanceReducer from "./maintenanceReducer";
import tokenSaleReducer from "./tokenSaleReducer";
import tradingReducer from "./tradingReducer";
import RewardsHubReducer from "./RewardsHub";
import { TradePageReducer } from "../Modules/Trades/tradeReducer";
import { TradeMarketTickerReducer } from "../Modules/Trades/MarketData/MarketTickerReducer";
import { TradeOrderBookReducer } from "../Modules/Trades/OrderBook/orderBookReducer";
import { TradeRecentTradeReducer } from "../Modules/Trades/RecentTrade/recentTradeReducer";
import { TradeOrderRecordsReducer } from "../Modules/Trades/OrderRecords/OrderRecordsReducer";
import { TradeResponsiveLayoutReducer } from "../Modules/Trades/ResponsiveLayout/ResponsiveLayoutReducer";
import { WalletBalanceReducer } from "../Modules/Wallet/WalletBalance/WalletBalanceReducer";
import { GenericPageReducer } from "../Modules/Generic/GenericReducer";
import PromotionReducer from "./PromotionReducer";

export default combineReducers({
  theme: ThemeReducer,
  market: MarketDataReducer,
  trade: TradeReducer,
  dashboard: dashboardReducer,
  setting: settingReducer,
  wallet: walletReducer,
  competition: competitionReducer,
  bank: bankReducer,
  tradePage: TradePageReducer,
  watchList: WatchListReducer,
  earn: earnReducer,
  kyc: kycReducer,
  auth: authReducer,
  maintenance: maintenanceReducer,
  sale: tokenSaleReducer,
  trading: tradingReducer,
  rewards: RewardsHubReducer,
  promotion: PromotionReducer,

  generic: GenericPageReducer,
  walletBalance: WalletBalanceReducer,
  marketAllTicker: TradeMarketTickerReducer,
  tradeOrderBook: TradeOrderBookReducer,
  orderRecords: TradeOrderRecordsReducer,
  recentTrade: TradeRecentTradeReducer,
  responsiveLayout: TradeResponsiveLayoutReducer
});
