import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import PostOrHaltMode from "../PostOrHaltMode";
import { ArrowDownRedIcon, ArrowUpGreenIcon } from "../../../../../assets/svgs";
import { Skeleton } from "../../../../../components/Loader";
import { numberFormatter } from "../../../../../utils";
import OrderSpread from "./OrderSpread";

const LastPriceContainer = styled(DivElement)`
  position: absolute;
  width: calc(100% + 24px);
  left: -12px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid ${(props) => props.theme.mainBackground};
  border-width: 1px 0 1px 0;

  @media (min-width: 767px) and (max-width: 1024px) {
    height: 36px;
  }

  ${(props) =>
    props.activeSide === "sell" &&
    props.buySellRatio === false &&
    css`
      @media (min-width: 767px) {
        border-width: 1px 0 0 0;
      }
    `}

  @media (max-width: 767px) {
    width: 100%;
    left: 0;
  }
`;

const LastPrice = () => {
  const selectedPairTicker = useSelector((state) => state.trading.selectedPairTicker);
  const selectedPairConfig = useSelector((state) => state.trading.selectedPairConfig);
  const orderBookConfig = useSelector((state) => state.trading.orderBookConfig);
  const tradeHistory = useSelector((state) => state.trading.tradeHistory);
  const activeSide = useSelector((state) => state.trading.orderBookActiveSide);
  const pairData = useMemo(() => selectedPairTicker, [selectedPairTicker]);
  const [isUp, setIsUp] = useState(true);

  useEffect(() => {
    if (!tradeHistory || (tradeHistory && tradeHistory.length <= 1)) return;
    if (tradeHistory[0][0] < tradeHistory[1][0]) {
      setIsUp(false);
    } else {
      setIsUp(true);
    }
  }, [tradeHistory]);

  return (
    <DivElement relative height="31px">
      <LastPriceContainer activeSide={activeSide} buySellRatio={orderBookConfig.buySellRatio}>
        {pairData && selectedPairConfig ? (
          <Fragment>
            {selectedPairConfig.mode === "post_only" || selectedPairConfig.mode === "halt" ? (
              <PostOrHaltMode side={activeSide} />
            ) : (
              <Fragment>
                {orderBookConfig && orderBookConfig.spread ? (
                  <OrderSpread />
                ) : (
                  <Fragment>
                    <TextWrapper
                      green={isUp ? true : null}
                      red={isUp ? null : true}
                      lineHeight="20px"
                    >
                      {numberFormatter(pairData?.lastPrice)}
                    </TextWrapper>
                    {isUp ? <ArrowUpGreenIcon /> : <ArrowDownRedIcon />}
                    <TextWrapper fontWeight="300" lineHeight="20px" marginLeft="19px">
                      <var>{`≈ $${numberFormatter(pairData?.equivalent)}`}</var>
                    </TextWrapper>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        ) : (
          <Skeleton width="100px" height="18px" />
        )}
      </LastPriceContainer>
    </DivElement>
  );
};

export default LastPrice;
