import React, { Fragment, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { getClientSaleStatistic } from "../../../../../lib/api";
import { compose } from "redux";
import { connect } from "react-redux";
import { formatAPIErrors } from "../../../../../utils/general";
import moment from "moment";
import { showErrorMsg } from "../../../../../utils/notification";

const DashboardChart = ({ isDarkTheme, id }) => {
  const [statisticData, setStatisticData] = useState([]);
  const [statisticLabels, setStatisticLabels] = useState([]);
  const [isLoading, setIsLoading] = useState("");

  useEffect(() => {
    getStatistic(true);
    // eslint-disable-next-line
  }, []);

  const getStatistic = (showLoading) => {
    setIsLoading(showLoading);
    let tokenSaleId = id;
    let data = {
      tokenSaleId,
      category: "day"
    };
    getClientSaleStatistic(data)
      .then((res) => {
        let amounts = [];
        let labels = [];

        res.data.data.forEach((item) => {
          labels.push(moment(item.date).format("DD-MM-YYYY"));
          amounts.push(+item.totalAmt);
        });

        setStatisticData(amounts);
        setStatisticLabels(labels);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      {!isLoading && (
        <Line
          data={{
            labels: statisticLabels,
            datasets: [
              {
                data: statisticData,
                backgroundColor: "transparent",
                borderColor: !isDarkTheme ? "#0470e1" : "#70a6e0"
              }
            ]
          }}
          options={{
            title: {
              display: false
            },
            legend: {
              display: false
            },

            scales: {
              yAxes: [
                {
                  ticks: {
                    fontColor: !isDarkTheme ? "#012243" : "#dedfe0"
                  },
                  gridLines: {
                    color: !isDarkTheme ? "#706f6f" : "#bbbdbf"
                  }
                }
              ],
              xAxes: [
                {
                  ticks: {
                    fontColor: !isDarkTheme ? "#012243" : "#dedfe0"
                  },
                  gridLines: {
                    color: !isDarkTheme ? "#706f6f" : "#bbbdbf"
                  }
                }
              ]
            },

            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData,
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(DashboardChart);
