/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Button, DivElement, SpanElement, TextWrapper } from "../../../../../components/Reusable";
import { CloseIcon, ErrorIcon } from "../../../../../assets/svgs";
import InputWrapper from "../BuyForm/InputWrapper";
import ShowFunds from "../BuyForm/ShowFunds";
import { capitalize, numberFormatter } from "../../../../../utils";
import { addNumbers, multiplyNumbers } from "../../../../../utils/math";
import { getTradePairConfig, modifyUserOpenOrders } from "../../../../../lib/api";
import { showErrorMsg } from "../../../../../utils/notification";
import { formatAPIErrors } from "../../../../../utils/general";
import { LoadingButton } from "../../../../../components/Loader";
import { useSelector } from "react-redux";
import { checkDecimalPrecision } from "../../../../../utils/trading";

const ERROR_MSGS = {
  LOW_FUNDS: "Insufficient funds"
};

const Wrapper = styled(DivElement)`
  position: fixed;
  left: ${(props) => props.left};
  width: 320px;
  border-radius: 5px;
  z-index: 10001;
  top: ${(props) => `${props.yCoordinate - 380}px`};

  @media (max-width: 1024px) {
    top: ${(props) => `${props.yCoordinate + 14}px`};
  }
  @media (max-width: 767px) {
    left: 10px;
    top: ${(props) => `${props.yCoordinate - 380}px`};
  }

  ::before {
    content: "";
    height: 16px;
    width: 16px;
    position: absolute;
    left: 12px;
    bottom: -8px;
    transform: rotate(45deg);
    background-color: ${(props) => props.theme.cardBg};
    border: solid ${(props) => props.theme.sepColor};
    border-width: 0 1px 1px 0 !important;

    @media (max-width: 1024px) and (min-width: 767px) {
      top: -8px;
      bottom: initial;
      transform: rotate(225deg);
    }

    @media (max-width: 767px) {
      left: 32px;
    }
  }
`;

const ModifyOrder = ({ orderData, position, onClose }) => {
  const [amount, setAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState("");
  const [loader, setLoader] = useState(false);
  const [base, quote] = orderData.Pair.split("/");
  const [coinBalance, setCoinBalance] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAddFunds, setShowAddFunds] = useState(false);
  const [pairConfig, setPairConfig] = useState("");
  const exchangeWallet = useSelector((state) => state.dashboard.exchangeWallet);

  useEffect(() => {
    getTradePairConfig({ pair: orderData.Pair })
      .then((res) => {
        setPairConfig(res);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  }, []);

  useEffect(() => {
    if (!exchangeWallet) return;
    let coin = orderData.Side === "BUY" ? quote : base;
    let coinData = exchangeWallet.data.find((data) => data.coin === coin);
    setCoinBalance(coinData?.balance?.freeBalance);
  }, [exchangeWallet]);

  useEffect(() => {
    setAmount(numberFormatter(orderData?.Amount));
    setPrice(numberFormatter(orderData?.Price));
  }, [orderData]);

  useEffect(() => {
    if (price && amount) {
      let total = multiplyNumbers(+price, +amount);
      setTotalAmount(total);
    } else {
      setTotalAmount("");
    }
  }, [price, amount]);

  useEffect(() => {
    let orderBalance =
      orderData.Side === "BUY"
        ? multiplyNumbers(+orderData?.Price, +orderData?.Amount)
        : orderData?.Amount;
    let totalBalance = addNumbers(coinBalance, orderBalance);
    let errMsg = "";
    let amt = orderData.Side === "BUY" ? totalAmount : amount;

    if (amt && totalBalance < Number(amt)) {
      errMsg = ERROR_MSGS.LOW_FUNDS;
    } else if (pairConfig && amount && Number(amount) < pairConfig.minBaseOrder) {
      errMsg = `Amount should be greater than ${pairConfig.minBaseOrder}`;
    } else if (pairConfig && amount && Number(amount) > pairConfig.maxBaseOrder) {
      errMsg = `Amount should be less than ${pairConfig.maxBaseOrder}`;
    } else if (pairConfig && totalAmount && Number(totalAmount) < pairConfig.minQuoteOrder) {
      errMsg = `Total value should be greater than ${pairConfig.minQuoteOrder}`;
    } else if (pairConfig && totalAmount && Number(totalAmount) > pairConfig.maxQuoteOrder) {
      errMsg = `Total value should be less than ${pairConfig.maxQuoteOrder}`;
    }

    setErrorMessage(errMsg);
  }, [totalAmount, pairConfig, amount]);

  const onConfirm = () => {
    setLoader(true);
    let data = {
      OrderId: orderData?.Id,
      Amount: Number(amount),
      Price: Number(price)
    };

    modifyUserOpenOrders(data)
      .then((res) => {
        onClose();
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setLoader(false));
  };

  const onPriceChangeHandler = (e) => {
    let value = e.target.value;
    if (checkDecimalPrecision(value, pairConfig?.orderPrecision?.Price)) {
      setPrice(value);
    }
  };

  const onAmountChangeHandler = (e) => {
    let value = e.target.value;
    if (checkDecimalPrecision(value, pairConfig?.orderPrecision?.Amount)) {
      setAmount(e.target.value);
    }
  };

  return (
    <Wrapper
      left={`${parseInt(position.x)}px`}
      yCoordinate={parseInt(position.y)}
      bgCard
      bdGrey
      border="1px solid"
    >
      <DivElement
        width="100%"
        height="44px"
        displayBtw
        padding="0 20px 0 24px"
        bdGrey
        borderBottom="1px solid"
      >
        <TextWrapper fontSize="13px" fontWeight="300">
          Modify Order ({base}/
          <SpanElement fontSize="inherit" fontWeight="inherit" secondary>
            {quote}
          </SpanElement>
          ){" "}
          <SpanElement
            fontSize="inherit"
            fontWeight="500"
            lineHeight="19px"
            green={orderData.Side === "BUY" ? true : null}
            red={orderData.Side === "SELL" ? true : null}
            marginLeft="8px"
          >
            {capitalize(orderData?.Side)}
          </SpanElement>
        </TextWrapper>
        <CloseIcon onClick={() => onClose()} />
      </DivElement>
      <DivElement width="100%" padding="16px 24px 20px 24px">
        <ShowFunds
          coin={orderData.Side === "BUY" ? quote : base}
          requiredFunds={orderData.Side === "BUY" ? totalAmount : amount}
          isError={errorMessage === ERROR_MSGS.LOW_FUNDS ? true : null}
          showModal={showAddFunds}
          setShowModal={setShowAddFunds}
        />
        <InputWrapper
          name="price"
          label={orderData.Side === "BUY" ? "Bid" : "Ask"}
          coin={quote}
          value={price}
          onChange={onPriceChangeHandler}
        />
        <InputWrapper
          name="amount"
          label="Amount"
          coin={base}
          value={amount}
          onChange={onAmountChangeHandler}
        />

        <DivElement relative>
          <InputWrapper
            name="total"
            label="Total"
            coin={quote}
            value={totalAmount}
            onChange={() => {}}
            isError={
              orderData.Side === "BUY" && errorMessage === ERROR_MSGS.LOW_FUNDS ? true : null
            }
          />

          {errorMessage && (
            <DivElement absolute top="calc(100% + 2px)" flexAlignCenter>
              <ErrorIcon width="16px" height="16px" marginRight="8px" />
              <TextWrapper red fontSize="12px" fontWeight="400" lineHeight="18px">
                {errorMessage}
              </TextWrapper>
              {errorMessage === ERROR_MSGS.LOW_FUNDS && (
                <Button
                  blueTextBtn
                  fontSize="12px"
                  lineHeight="18px"
                  marginLeft="8px"
                  onClick={() => setShowAddFunds(true)}
                >
                  Add {orderData.Side === "BUY" ? quote : base}
                </Button>
              )}
            </DivElement>
          )}
        </DivElement>

        <Button
          marginTop="32px"
          primaryBlue
          width="100%"
          height="40px"
          onClick={onConfirm}
          disabled={errorMessage || !amount || !price}
        >
          {loader ? <LoadingButton color="#fff" /> : "Confirm"}
        </Button>
      </DivElement>
    </Wrapper>
  );
};

export default ModifyOrder;
