import { compose } from "redux";
import { connect } from "react-redux";
import ToastWrapper from "../../components/Notification/toastWrapper";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { useEffect } from "react";
import FontStyles from "../../assets/StyleComponents/GlobalStyle/Fonts/GlobalFonts.style";
import { GlobalStyle } from "../../assets/StyleComponents/GlobalStyle/GlobalStyles/GlobalStyle.style";

const theme = (darkMode) => {
  if (typeof darkMode === "string") {
    darkMode = darkMode === "true" ? true : false;
  }

  return {
    isDarkTheme: darkMode,
    CardPrimary: "#0470E1",
    primary: darkMode ? "#70A6E0" : "#0470E1",
    PrimaryGradientColor: darkMode ? "linear-gradient(90deg, #ADC0FF 0%, #A8AEFF 100%)" : "linear-gradient(90deg, #4E17CF 0%, #3064F3 100%)",
    success: darkMode ? "#68BF60" : "#008000",
    successLight: darkMode ? "#F0FFED" : "#F0FFED",
    danger: darkMode ? "#E66767" : "#E65252",
    dangerToolTip: darkMode ? "#E66767" : "#fff",
    warning: darkMode ? "#383631" : "#e8d9bb",
    warningTxt: darkMode ? "#FFC955" : "#FFC955",
    hoverBorder: darkMode ? "#7ca5db" : "#4595e7",
    bgActiveTable: darkMode ? "#263645" : "#ECF5FE",
    borderColor: darkMode ? "#38393D" : "#CCCED0",
    bodyText: darkMode ? "#DEDFE0" : "#012243",
    bodyText2: darkMode ? "#FFFFFF" : "#012243",
    bodyText3: darkMode ? "#012243" : "#FFFFFF",
    settingsInputbg: darkMode ? "#101726" : "#FFFFFF",
    tableHeaderColor: darkMode ? "#A7A9AB" : "#606F80",
    tableHead: darkMode ? "#1b2631" : "#f5f6fa",
    subText: darkMode ? "#A7A9AB" : "#606F80",
    navTabText: darkMode ? "#A7A9AB" : "#012243",
    inputBorder: darkMode ? "#969799" : "#8c9797",
    inputBackground: darkMode ? "#1f2c38" : "#F5F6FA",
    mainBackground: darkMode ? "#0a0d12" : "#F5F6FA",
    mainCardBackground: darkMode ? "#0a0d12" : "#FFFFFF",
    level1CardSky: darkMode ? "#151e26" : "#e6f1fc",
    level2CardSky: darkMode ? "#151e26" : "#f5faff",
    level3CardGrey: darkMode ? "#263645" : "white",
    level3CardGrey2: darkMode ? "white" : "#263645",
    level3CardTC: darkMode ? "#263645" : "#ECF5FE",
    level1Card: darkMode ? "#151e26" : "#FFFFFF",
    TooltipColors: darkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
    ProgesssBarSettings: darkMode ? "#5c5f66" : "#ccced0",
    TutorialBg: darkMode ? "#0a0d12" : "#f5f6fa",
    DropdownBorder: darkMode ? "#38393D" : "#CCCED0",
    level2Card: darkMode ? "#263645" : "#FFFFFF",
    level3NotClicked: darkMode ? "#393B40" : "#F5F6FA",
    cc: darkMode ? "#1B2631" : "#f5f6fa",
    dashboardTableHead: darkMode ? "#1f2c38" : "#e1e9f5",
    toatSuccess: darkMode ? "#68bf60" : "#47915c",
    buttonActive: darkMode ? "#cde2f9" : "#70a6e0",
    toatError: darkMode ? "#e66767" : "#b83539",
    headerText: darkMode ? "#f5f6fa" : "#3b4848",
    backgroundColor: darkMode ? "#152238" : "#ffffff",
    commonBorder: darkMode ? "1px solid #707070" : "1px solid #ccced0",
    TutorialColor: darkMode ? "#090e1b" : "white",
    bottomBorder: darkMode ? "1px solid #151B22" : "1px solid #ccced0",
    OptionsBg: darkMode ? "rgba(124, 165, 219, 0.2)" : "#f5f6fa",
    buttonGradient: darkMode
      ? "linear-gradient(#090e1b, #090e1b), radial-gradient(circle at top left, #0470e1 ,#b3d4f6 )"
      : "linear-gradient(white, white), radial-gradient(circle at top left, #0470e1 ,#b3d4f6 )",
    inputBoxBackground: darkMode ? "#152238" : "#ffffff",
    SecurityBoxShadow: darkMode ? "none" : "rgba(4, 112, 225, 0.21)",
    inputBoxBorder: darkMode ? "#222e47" : "#ccced0",
    rejectedRed: darkMode ? "#E66767 " : "#e65252",
    DashbordCardsBorder: darkMode ? "#222e47" : "transparent",
    inputBoxText: darkMode ? "#f5f6fa" : "#707070",
    primaryButton: darkMode ? "#2ba0ff" : "#0470e1",
    filterBackground: darkMode ? "#152238" : "#ffffff",
    headerButtonGradient: darkMode
      ? "linear-gradient(#090e1b, #090e1b), radial-gradient(circle at top left, #0470e1 ,#b3d4f6 )"
      : "linear-gradient(white, white), radial-gradient(circle at top left, #0470e1 ,#b3d4f6 )",
    FeeHeaderBg: darkMode
      ? "linear-gradient(90deg, #233240 0%, #1F2C38 52.96%, #151E26 100%)"
      : "linear-gradient(90deg, #E6E7EC 0%, #FFFFFF 100%)",

    filterBorder: darkMode ? "1px solid #707070" : "1px solid #ccced0",
    EarnThemeHeader: !darkMode ? "#fafafa" : "#162029",
    BlackBgReferal: darkMode ? "#151e26" : "#0e141a",
    AccorianBg: darkMode ? "#1F2C38" : "#F5F6FA",
    TimeLineBg: darkMode
      ? "linear-gradient(294.72deg, #FF4581 9.05%, #4388DD 79.28%)"
      : "linear-gradient(294.72deg, #FF4581 9.05%, #4388DD 79.28%)",
    RewardsHubPriceTextColor: darkMode
      ? "linear-gradient(90deg, #4E17CF 0%, #3064F3 100%)"
      : "linear-gradient(90deg, #99A5FF 0%, #BE99FF 100%)",

    // New Styles
    primaryBlue: darkMode ? "#70A6E0" : "#0470E1",
    primaryIcon: darkMode ? "#BBBDBF" : "#707070",
    primaryGreen: darkMode ? "#68bf60" : "#008000",
    primarySuccess: darkMode ? "#334530" : "#F0FFED",
    dangerNotification: darkMode ? "#453030" : "#FFEDED",
    warningNotification: darkMode ? "#FFFAE9" : "#FFFAE9",
    successNotificationText: darkMode ? "#51B348" : "#006B00",
    primaryRed: darkMode ? "#e66767" : "#e65252",
    secondaryRed: darkMode ? "#453030" : "#FFEDED",
    inputActive: darkMode ? "#263645" : "#ECF5FE",
    tradeHoverValue: darkMode ? "#012243" : "rgba(17, 17, 17, 0.21)",
    orderBookHoverValue: darkMode ? "#222" : "222",
    tabBox: darkMode ? "#38393d" : "#E1E3E5",
    NewSpans: darkMode ? "#393B40" : "#E1E3E5",
    activeTabBox: darkMode ? "#263645" : "#fff",
    inputFieldBg: darkMode ? "#1f2c38" : "#f5f6fa",
    primaryText: darkMode ? "#dedfe0" : "#012243",
    popupBg: darkMode ? "#263645" : "#ffffff",
    cardBg: darkMode ? "#151e26" : "#ffffff",
    primaryWhite: darkMode ? "#012243" : "#ffffff",
    primaryYellow: "#ffc955",
    primaryOrange: "#ffb83e",
    secondaryOrange: "#F7931A",
    warningFill: "#F7EDD9",
    sepColor: darkMode ? "#38393D" : "#ccced0",
    hoverBg: "linear-gradient(76deg, rgb(4, 112, 225, 0.08) 2%, rgb(64, 163, 199, 0.08))",
    disabledBorder: darkMode ? "#1C2833" : "#F2F2F2",
    tableHeader: darkMode ? "#1B2631" : "#f5f6fa",
    buyAnimationBg: "rgba(0, 128, 0, 0.09)",
    buyAnimationBg2: "rgba(0, 128, 0, 0.09)",
    sellAnimationBg: "rgba(223, 37, 37, 0.12)",
    sellAnimationBg2: "rgba(223, 37, 37, 0.12)"
  };
};

const ThemeProvider = ({ children, isDarkTheme }) => {
  useEffect(() => {
    if (isDarkTheme) {
      window.document.body.classList.add("dark");
    } else {
      window.document.body.classList.remove("dark");
    }
  }, [isDarkTheme]);

  return (
    <StyledComponentsThemeProvider theme={theme(isDarkTheme)}>
      <FontStyles />
      <GlobalStyle />
      <ToastWrapper />
      {children}
      {/* </GlobalStyle> */}
      {/* </FontStyles> */}
    </StyledComponentsThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(ThemeProvider);
