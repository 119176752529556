import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import { Saga } from "./sagas";
import ThemeProvider from "./contexts/Theme";
import { initializeGA4 } from "./tracking/track";
import WalletProvider from "./contexts/WalletPage/BalancePage/wallet";
import DepositProvider from "./contexts/WalletPage/DepositPage/depositContext";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown/style.css";
import "pennant/dist/style.css";
import store, { sagaMiddleware } from "./store";
import { ScrollToTop } from "./components/Reusable";
import { WebSocketProvider } from "./contexts/WebSocket";
import "./assets/StyleComponents/GlobalStyle/Fonts/GlobalFonts.scss";
import { customDecode } from "./utils/envScript/envCrypto";

sagaMiddleware.run(Saga);

if (customDecode(process.env.REACT_APP_NODE_ENV) === "production") {
  // Initialize React Google Analytics
  initializeGA4();
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <ThemeProvider>
          <WebSocketProvider>
            <WalletProvider>
              <DepositProvider>
                <ScrollToTop />
                <App />
              </DepositProvider>
            </WalletProvider>
          </WebSocketProvider>
        </ThemeProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
