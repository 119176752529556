import React from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";

const DatePickerWrapper = styled(DatePicker)`
  font-weight: 500;
  @media (max-width: 767.5px) {
    width: 100%;
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const DatePickerStyle = (props) => {
  return <DatePickerWrapper {...props} />;
};
