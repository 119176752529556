import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { DivElement } from "../../../../../components/Reusable";
import { updateOrderFormSide } from "../../../../../actions/trading";

const SingleTab = styled(DivElement)`
  position: absolute;
  border-radius: 2px;
  top: 0px;
  height: 35px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 1px solid transparent;
  left: ${(props) => (props.type === "buy" ? "0" : "")};
  right: ${(props) => (props.type === "buy" ? "" : "0")};
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  cursor: pointer;
  color: ${(props) => props.theme.subText};

  ${(props) =>
    props.active &&
    css`
      outline: 1px solid ${props.type === "buy" ? props.theme.primaryGreen : props.theme.primaryRed};
      color: ${props.type === "buy" ? props.theme.primaryGreen : props.theme.primaryRed};
      background-color: ${(props) =>
        props.type === "buy"
          ? props.theme.isDarkTheme
            ? "#263645"
            : "#ecf5fe"
          : props.theme.isDarkTheme
          ? "#453030"
          : "#FFEDED"};
    `}
`;

const OrderTypes = () => {
  const orderFormSide = useSelector((state) => state.trading.orderFormSide);
  const dispatch = useDispatch();

  return (
    <DivElement
      width="200px"
      height="36px"
      bdGrey
      border="1px solid"
      borderRadius="2px"
      flexAlignCenter
      relative
    >
      <SingleTab
        active={orderFormSide === "buy" ? true : null}
        type="buy"
        onClick={() => dispatch(updateOrderFormSide("buy"))}
      >
        Buy
      </SingleTab>
      <SingleTab
        active={orderFormSide === "buy" ? null : true}
        type="sell"
        onClick={() => dispatch(updateOrderFormSide("sell"))}
      >
        Sell
      </SingleTab>
    </DivElement>
  );
};

export default OrderTypes;
