/* eslint-disable no-unused-vars */
import React, { useMemo, useEffect, useState } from "react";
import { MarketTableRow } from "../../../assets/StyleComponents/Table/Market/MarketTable.style";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import useMarketData from "../../../Hooks/MarketData/useMarketData";
import { UpDownIcon } from "../../../assets/svgs";
import { Th, ThDropdown } from "../../Reusable";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { SDKTableRow } from "../../../assets/StyleComponents/Table/Market/Sdk.style";

const SortingHeading = ({
  title,
  sortName,
  upadteSort,
  right = false,
  left = false,
  center = false,
  Dropdown = false,
  paddingRight,
  padding
}) => {
  return useMemo(() => {
    let textAligned = null;
    let sizeFont = "14px";
    if (right) textAligned = "right";
    if (left) textAligned = "left";
    if (center) textAligned = "center";
    if (Dropdown) sizeFont = "12px";
    return (
      <Th
        scope="col"
        className=""
        textAlign={textAligned}
        onClick={() => upadteSort(`${sortName}`)}
        padding={padding}
      >
        <ParaTag
          size={sizeFont}
          alignItems="center"
          className="no-wrap"
          display="flex"
          paddingRight={paddingRight && "0px"}
          pl={title === "Symbol" && "10px"}
        >
          {title === "Symbol" ? "Pairs" : title}
          <BannerHeadingSpan>
            <UpDownIcon marginLeft="5px" width="12px" />
          </BannerHeadingSpan>
        </ParaTag>
      </Th>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upadteSort]);
};

function MarketTableHeader(props) {
  const {
    filterSearch,
    filter_top_quote_pair,
    setFilterTopQuotePair,
    setFilterSearchData,
    setActiveCol,
    dropdownOptions,
    activeCol,
    onSorting
  } = props;
  const { width } = useWindowSize();

  let { setFilterQuotePair, setFilterSearch } = useMarketData(filterSearch, props.watchList);

  useEffect(() => {
    setFilterTopQuotePair(filter_top_quote_pair);
    setFilterQuotePair(filter_top_quote_pair);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_top_quote_pair]);

  useEffect(() => {
    setFilterSearchData(filterSearch);
    setFilterSearch(filterSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch]);

  const location = useLocation();

  const renderMarketPageHeader = () => (
    <>
      <SortingHeading title={"Symbol"} upadteSort={onSorting} sortName="symbol" />
      {width > 1025 && (
        <SortingHeading right title={"Last Price"} upadteSort={onSorting} sortName="lastPrice" />
      )}
      {width > 1025 && (
        <SortingHeading right title={"24h Change"} upadteSort={onSorting} sortName="change" />
      )}
      {width > 767 && (
        <SortingHeading right title={"24h Volume"} upadteSort={onSorting} sortName="usdVolume" />
      )}
      {width > 1025 && (
        <SortingHeading right title={"24h High"} upadteSort={onSorting} sortName="high" />
      )}
      {width > 1025 && (
        <SortingHeading right title={"24h Low"} upadteSort={onSorting} sortName="low" />
      )}
      {width < 1025 && (
        <ThDropdown
          textAlign={"right"}
          OPTIONS={dropdownOptions}
          activeCol={activeCol}
          selectOption={setActiveCol}
        />
      )}
    </>
  );

  return (
    <>
      {location.pathname.includes("/en/sdk") ? (
        <SDKTableRow displayFlex>
          <Th scope="col" Row1Market></Th>
          {renderMarketPageHeader()}

          <Th scope="col" textAlign="center">
            {width > 767 ? "Trade" : ""}
          </Th>
        </SDKTableRow>
      ) : (
        <MarketTableRow displayFlex>
          <Th scope="col" Row1Market></Th>

          {renderMarketPageHeader()}

          <Th scope="col" textAlign="center">
            {width > 767 ? "Trade" : ""}
          </Th>
        </MarketTableRow>
      )}
    </>
  );
}

export default MarketTableHeader;
