/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { AuthRouter, NonAuthRouter } from '../router';
import MainAuth from '../pages/Auth/MainAuth';
import { createStructuredSelector } from 'reselect';
import { getTranslationLanguageList, getUserSelectedLang } from '../Modules/Generic/selectors';
import { setLocalStorage } from '../utils/helpers';

const LangLayoutProps = createStructuredSelector({
  transalationList: getTranslationLanguageList(),
  userSelectedLang: getUserSelectedLang()
});

const LangCheckComponent = () => {
  const [selectedLang, setSelectedLang] = useState("en");
  let listData = useRef([]);
  let routeStatus = useRef(false);
  const param = useParams();
  const history = useHistory();
  const location = useLocation();
  const userData = useSelector(state => state.dashboard.userData);
  let isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  let { transalationList, userSelectedLang } = useSelector(LangLayoutProps);
  const prevLang = localStorage.getItem("ljs-lang");  

  let memoPreferredLanguage = useMemo(() => userData?.preferredLanguage || "", [userData?.preferredLanguage]);
  let memoHasMultilingualSupport = useMemo(() => userData?.hasMultilingualSupport || false, [userData?.hasMultilingualSupport]);

  useEffect(() => {
    window.Localize.getAvailableLanguages(function (err, data) {
      if (err) return console.log(err);
      // setAllLanguages(data);
      // console.log(data, "aa gya data");
      listData.current = data;
    });
  }, []);

  useEffect(() => {
    if(memoHasMultilingualSupport && memoPreferredLanguage){
      setSelectedLang(memoPreferredLanguage);
    } else setSelectedLang("en");
  }, [memoPreferredLanguage, memoHasMultilingualSupport]);

  useEffect(() => {
    let path = location.pathname;
    let getValue = path.split("/");
    if(listData.current && listData.current.length > 0 ){
      if(getValue[1] === "") {
        return history.push(`/${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}`);
      }
      
      let getStatus = listData.current.find(d => d.code === getValue[1]);
      if(getStatus) {
        routeStatus.current = true;
        setSelectedLang(getStatus.code);
      } else{
        const regex = new RegExp(`^/${getValue[1]}/`);
        const newPath = path.replace(regex, '/en/');
         setSelectedLang("en");
         history.push(newPath);
      }
      window.Localize.getAvailableLanguages(function (err, data) {
        if (err) return console.log(err);
        localStorage.setItem("ljs-lang", selectedLang || "en");
        setLocalStorage(new Date().getTime(), "last-lang-update-time");
      });
    }
  }, [location, listData.current]);

  return (
    <>
      {isLoggedIn ? <MainAuth /> : <NonAuthRouter />}
    </>
  );
};

export default LangCheckComponent;