import React, { useEffect, useMemo, useState } from "react";

import { BannerBox, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../assets/StyleComponents/FontSize/headingFourth.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
// import { debounce } from "../../utils/helpers";
import SingleCompetitionList from "./CompetitionList/competitionList";
import { getAllCompetitionsList, getAllCampaigins } from "../../lib/api";
import { showErrorMsg } from "../../utils/notification";
import NoRecordsFound from "../../components/NoRecordsFound/noRecordsFound";
import { formatAPIErrors } from "../../utils/general";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { TrophyIllustration } from "../../assets/svgs/illustrations";
import SingleCampaigncard from "../campaigns/Components/SingleCampign";

const CompetitionList = (props) => {
  const { title, type } = props;
  const [activeState, setActiveState] = useState("");
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(false);
  // const [page, setPage] = useState(1);
  const [list, setList] = useState({
    onGoing: [],
    completed: [],
    upcoming: []
  });

  const callApi = () => {
    setLoading(true);
    // basic api import based on diffrent apis
    type === "campaigins"
      ? getAllCampaigins()
          .then((res) => {
            setList({
              onGoing: res.data.data.ONGOING || [],
              completed: res.data.data.COMPLETED || [],
              upcoming: res.data.data.UPCOMING || []
            });
            // if (res.data?.data?.ONGOING?.length === 0) {
            //   setActiveState("upcoming");
            // }
            setLoading(false);
          })
          .catch((e) => {
            showErrorMsg(formatAPIErrors(e));
            setLoading(false);
          })
      : getAllCompetitionsList()
          .then((res) => {
            setList({
              onGoing: res.data.data.live?.reverse() || [],
              completed: res.data.data.old?.reverse() || [],
              upcoming: res.data.data.upcoming?.reverse() || []
            });
            // if (res.data.data.live.length === 0) {
            //   setActiveState("upcoming");
            // }
            setLoading(false);
          })
          .catch((e) => {
            showErrorMsg(formatAPIErrors(e));
            setLoading(false);
          });
  };

  useEffect(() => {
    type === "campaigins"
      ? getAllCampaigins()
          .then((res) => {
            setList({
              onGoing: res.data.data.ONGOING || [],
              completed: res.data.data.COMPLETED || [],
              upcoming: res.data.data.UPCOMING || []
            });
            if (res.data.data.ONGOING.length === 0) {
              setActiveState("upcoming");
            }
            if (res.data.data.ONGOING.length > 0) setActiveState("ongoing");
            setLoading(false);
          })
          .catch((e) => {
            showErrorMsg(formatAPIErrors(e));
            setLoading(false);
          })
      : getAllCompetitionsList()
          .then((res) => {
            setLoading(true);
            setList({
              onGoing: res.data.data.live?.reverse() || [],
              completed: res.data.data.old?.reverse() || [],
              upcoming: res.data.data.upcoming?.reverse() || []
            });
            if (res.data.data.live.length === 0) {
              setActiveState("upcoming");
            }
            if (res.data.data.live.length > 0) setActiveState("ongoing");
            // else setActiveState("ongoing");
            setLoading(false);
          })
          .catch((e) => {
            showErrorMsg(formatAPIErrors(e));
          });
  }, []);

  const handleActiveState = (givenName) => {
    setActiveState(givenName);
    callApi();
  };

  let competition = useMemo(() => {
    let result = list.onGoing;
    if (activeState === "upcoming") {
      result = list.upcoming;
    } else if (activeState === "completed") {
      result = list.completed.reduce((acc, item) => [item, ...acc], []); // Create a reversed copy using reduce
    }
    return result;
  }, [activeState, list]);

  return (
    <BannerSection
      SectionWhite
      radius="5px"
      padd={"24px 36px 32px 40px"}
      paddMd={"16px 16px 21px 16px"}
      mt="16px"
      mtMd="12px"
    >
      <BannerBox>
        <HeadingFourth size="20px" sizeLg="18px" sizeMd="16px">
          {title} {type === "campaigins" && <TrophyIllustration width="20px" height="20px" />}
        </HeadingFourth>

        {/* {loading ? */}
        <BannerBox display="inline-flex" gap="8px" mt="24px">
          {list?.onGoing?.length > 0 && (
            <ButtonComponent
              OutlineLightPrimary={activeState === "ongoing" ? true : null}
              OutlineLightDefaultPrimary={activeState !== "ongoing" ? true : null}
              borderRadius="20px"
              btnHeight="auto"
              padd={"4px 16px"}
              // widthMd="70px"
              paddingMd={"5px 12px"}
              sizeMd="12px"
              sizeLg="16px"
              // btnHeightLg="32px"
              // btnHeightMd="32px"
              onClick={() => handleActiveState("ongoing")}
            >
              Ongoing
            </ButtonComponent>
          )}

          <ButtonComponent
            OutlineLightPrimary={activeState === "upcoming" ? true : null}
            OutlineLightDefaultPrimary={activeState !== "upcoming" ? true : null}
            borderRadius="20px"
            btnHeight="auto"
            padd={"4px 16px"}
            paddingMd={"5px 12px"}
            sizeMd="12px"
            sizeLg="16px"
            // widthLg="93px"
            // btnHeightLg="32px"
            // btnHeightMd="32px"
            onClick={() => handleActiveState("upcoming")}
          >
            Upcoming
          </ButtonComponent>

          <ButtonComponent
            OutlineLightPrimary={activeState === "completed" ? true : null}
            OutlineLightDefaultPrimary={activeState !== "completed" ? true : null}
            borderRadius="20px"
            btnHeight="auto"
            padd={"4px 16px"}
            paddingMd={"5px 12px"}
            sizeMd="12px"
            sizeLg="16px"
            onClick={() => handleActiveState("completed")}
          >
            Completed
          </ButtonComponent>
        </BannerBox>
        {/* : (
            <BannerBox display="flex" gap="20px" justifyContent="flex-start" mt="20px" mlMd="5px">
              <SkeletonLoader width={width > 767 ? 500 : 300} height="50px" />
            </BannerBox>
          ) */}
        {/* } */}

        <BannerBox mt="40px" justifyContent="center" display="flex" width="100%">
          {loading ? (
            <>
              {width > 1025 && (
                <>
                  <BannerBox display="flex" gap="20px" justifyContent="flex-start">
                    <SkeletonLoader width={325} height="200px" />
                    <SkeletonLoader width={325} height="200px" />
                    <SkeletonLoader width={325} height="200px" />
                  </BannerBox>
                </>
              )}
              {width < 1024 && width > 767 && (
                <>
                  <BannerBox display="flex" gap="20px" justifyContent="flex-start">
                    <SkeletonLoader width={320} height="200px" />
                    <SkeletonLoader width={320} height="200px" />
                  </BannerBox>
                </>
              )}
              {width < 767 && (
                <>
                  <BannerBox display="flex" gap="20px" justifyContent="flex-start">
                    <SkeletonLoader width={325} height="200px" />
                  </BannerBox>
                </>
              )}
            </>
          ) : (
            <>
              {competition && competition?.length > 0 ? (
                <BannerBox
                  gap="32px 20px"
                  display="grid"
                  width="100%"
                  gridCols="repeat(3,1fr)"
                  gridColsMd="repeat(1,1fr)"
                  gridColsLg="repeat(2,1fr)"
                >
                  {competition?.map((item) => {
                    return (
                      <>
                        {type === "campaigins" ? (
                          <SingleCampaigncard key={item} data={item} activeState={activeState} />
                        ) : (
                          <SingleCompetitionList
                            key={item}
                            data={item}
                            activeState={activeState}
                            Participated={item?.Participated}
                          />
                        )}
                      </>
                    );
                  })}
                </BannerBox>
              ) : (
                <BannerBox display="flex" justifyContent="center" alignItems="center">
                  <NoRecordsFound
                    text={type === "campaigins" ? "No Campaign Found" : "No Competition Found"}
                  />
                </BannerBox>
              )}
            </>
          )}
        </BannerBox>
      </BannerBox>
    </BannerSection>
  );
};

export default CompetitionList;
