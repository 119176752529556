/* eslint-disable no-console */
import styled, { css } from "styled-components";
import DropdownButton from "react-bootstrap/DropdownButton";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../constants/Images/imageIndex";

const DropDownButtonComponent = styled(DropdownButton)`
  ${(props) => props.btnDisplay && `display: ${props.btnDisplay}!important;`};

  .dropdown-toggle.btn,
  .show > .dropdown-toggle.btn {
    position: relative !important;
    top: ${(props) => props.TopMarginMain || ""} !important;
    padding: 0 8px !important;
    height: auto;
    font-size: 12px;
    color: ${(props) => props.theme.bodyText} !important;
    transition: all 0.2ms linear;

    @media (max-width: 1024px and min-width:767px) {
      font-size: 14px !important;
    }
    @media (max-width: 767px) {
      font-size: 12px !important;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:focus-visible,
    &:focus-within {
      background: none !important;
      background-color: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
    &:after {
      content: "";
      position: relative;
      top: ${(props) => props.RemoveArrowButton || "5px"};
      border: none !important;
      display: inline-block;
      width: ${(props) => props.caretWidth || "14px"};
      height: ${(props) => props.caretWidth || "14px"};
      background: url(${(props) =>
        props.theme.primary !== "#70A6E0"
          ? IMAGE_PREFIX.ChevronIcon
          : IMAGE_PREFIX_DARK.ChevronDownIconDark});
      background-size: cover;
    }
  }

  .dropdown-menu {
    font-size: 20px !important;
    font-weight: bold !important;
    color: ${(props) => props.theme.bodyText} !important;
    border-bottom: 1px solid ${(props) => props.theme.borderColor} !important;
    @media (max-width: 767px) {
      font-size: 16px !important;
    }
  }

  .dropdown-menu.show {
    min-width: ${(props) => props.minWidth || "max-content"};
    padding-left: 8px;
    padding-right: 8px;
    z-index: 3;
    background-color: ${(props) => props.theme.level2Card} !important;
    ${(props) => props.DropDownMargin && `margin: ${props.DropDownMargin}!important;`}
  }

  .dropdown-item {
    cursor: pointer;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 500;
    color: ${(props) => props.theme.bodyText} !important;
    &:hover {
      background-color: transparent;
    }
  }

  ${(props) =>
    props.TradeOrder &&
    css`
      .dropdown-toggle.btn.btn-outline-secondary,
      .show > .btn-outline-secondary.dropdown-toggle {
        cursor: pointer;
        background-color: none !important;
        background: none !important;
        border: none !important;
        box-shadow: none !important;
      }

      .show > .dropdown-item {
        padding: 4px 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }

      .dropdown-menu.show {
        cursor: pointer;
        border-radius: 5px !important;
        padding: 8px 16px;
        z-index: 10;
        background-color: ${(props) => props.theme.level2Card} !important;
      }
    `};

  ${(props) =>
    props.HoverListActive &&
    css`
      .dropdown-menu.show {
        padding-left: 0;
        padding-right: 0;
        min-width: 150px;
        .dropdown-item {
          padding: 8px 16px;
          // display: block !important;
          display: flex;
          gap: 7px;
          position: relative;
          width: 100%;
          box-sizing: border-box;
          &:hover {
            &:after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: linear-gradient(
                76deg,
                rgba(4, 112, 225, 0.08) 2%,
                rgba(64, 163, 199, 0.08)
              ) !important;
              border-right: 4px solid ${(props) => props.theme.hoverBorder};
            }
          }
        }
      }
      .dropdown-toggle.btn.btn-outline-secondary,
      .show > .btn-outline-secondary.dropdown-toggle {
        background-color: none !important;
        background: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    `};

  ${(props) =>
    props.RemoveBtnPadding &&
    css`
      .dropdown-toggle.btn.btn-outline-secondary,
      .show > .btn-outline-secondary.dropdown-toggle {
        padding: 0 !important;
      }
    `};

  ${(props) =>
    props.FullBorder &&
    css`
      .dropdown-menu.show {
        border: 1px solid ${(props) => props.theme.borderColor} !important;
      }
    `};

  ${(props) =>
    props.InnerLevel &&
    css`
      .dropdown-menu.show {
        min-width: ${(props) => props.minWidth || "max-content"};
        padding: 2px 8px !important;
        background-color: ${(props) => props.theme.level1Card} !important;
        border: 1px solid ${(props) => props.theme.borderColor} !important;
      }
    `};

  ${(props) =>
    props.NoCaret &&
    css`
    .dropdown-toggle.btn, .show > .dropdown-toggle.btn {
      &:after {
        display: none !important;
      }
    `};

  ${(props) =>
    props.RemoveBtnPadding &&
    css`
      .dropdown-toggle.btn.btn-outline-secondary,
      .show > .btn-outline-secondary.dropdown-toggle {
        padding: 0 !important;
      }
    `};

  ${(props) =>
    props.InnerLevel &&
    css`
      .dropdown-menu.show {
        min-width: ${(props) => props.minWidth || "max-content"};
        padding: 2px 8px !important;
        background-color: ${(props) => props.theme.level1Card} !important;
        border: 1px solid ${(props) => props.theme.borderColor} !important;
      }
    `};

  ${(props) =>
    props.NoCaret &&
    css`
      .dropdown-toggle.btn,
      .show > .dropdown-toggle.btn {
        &:after {
          display: none !important;
        }
      }
    `};

  ${(props) =>
    props.TopMarginMain &&
    css`
      .dropdown-toggle.btn,
      .show > .dropdown-toggle.btn {
        top: ${(props) => props.TopMarginMain || "-2px"} !important;
      }
    `};
`;

export { DropDownButtonComponent };
