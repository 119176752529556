/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, useMemo } from "react";
import moment from "moment";
import Datetime from "react-datetime";
import { connect, useSelector } from "react-redux";
import { subMonths } from "date-fns";

import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
} from "../../../../assets/StyleComponents/Exchange.style";
import SelectDropDownComponent, {
  CustomOptionsForCurrency,
  DropdownIndicator,
  CustomOptionsForWallets,
  customStylesContinentsForWallets,
  CustomOptionsForBlockPit
} from "../../../../components/SelectDropDown/selectDropDown";
import { WalletContext } from "../../../../contexts/WalletPage/BalancePage/wallet";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { setupUserReports } from "../../../../lib/api";
import { getMarketLists } from "../../../../actions/marketLists";
import { getTransactions } from "../../../../lib/api";
import { getUserReports } from "../../../../actions/wallet";
import { formatAPIErrors, formattedSymbol } from "../../../../utils/general";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { debounce } from "../../../../utils/helpers";
import { GetKycVerified } from "../../../../components/Alert";
import { EditCalendarIcon } from "../../../../assets/svgs";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { DatePickerStyles } from "../../../../assets/StyleComponents/DatePicker.style";
import { DatePickerStyles2 } from "../../../../assets/StyleComponents/DatePicker2.style";
import DatePickerComponent from "../../../../components/DatePicker/DatePicker";
import { fetchReportTemplate } from "../../../../lib/Setting/GenerateReport/GenerateReport";

const formattedArray = (array) => {
  return array.map((item) => {
    return {
      id: {
        id: item,
        label: item,
        value: item
      },
      label: item,
      value: item
    };
  });
};

const inputStyle = {
  position: "relative",
  // width: 159px;
  // height: 44px !important;
  zIndex: "1",
  backgroundColor: "green",
  border: "0",
  borderBottom: "1px solid #8c9797",
  borderRadius: "5px 5px 0 0",
  paddingRight: "40px"
};

function LedgerTradeCheck(value) {
  switch (value) {
    case "Ledger (Exchange Wallet)":
      return "LedgerExchange";
    case "Ledger (Main Wallet)":
      return "LedgerMain";
    case "Trade":
      return "Trade";
    default:
      return "Trade";
  }
}

let currentDate = Date.now();
let CurrentDate = new Date();
let previousMonthDate = subMonths(CurrentDate, 1);
let beforeCurrentDatte = moment(currentDate).subtract(1, "months");
var yesterday = Datetime.moment();
var valid = function (current) {
  return current.isBefore(yesterday);
};

const TradesOptions = ["Trade", "Ledger (Main Wallet)"];

function FilterComponent(props) {
  let page = 1;
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [date, setDate] = useState({ fromDate: "", toDate: "" });
  const [submitLoader, setSubmitloader] = useState(false);
  const [blockpitList, setBlockpitList] = useState({});
  const [iconList, setIconList] = useState([]);
  const [error, setError] = useState({
    from: false,
    to: false
  });

  const [trades, setTrades] = useState({
    id: { id: "Trade", label: "Trade", value: "Trade" },
    label: "Trade",
    value: "Trade"
  });

  const [selectedCoin, setSelectedCoin] = useState({
    id: "0",
    label: "All Assets",
    value: "All Assets",
    coin: "All Assets"
  });

  const [pair, setPair] = useState({
    id: { id: "All Pairs", symbol: "All Pairs" },
    symbol: "All Pairs"
  });

  const [selectedTaxReport, setSelectedTaxReport] = useState("");

  const { width } = useWindowSize();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  let userData = useSelector((state) => state.dashboard.getUserData);
  let { KYC } = userData || {};
  const [show2fa, setShow2fa] = useState(false);

  let { currencyList, setCurrencyList } = useContext(DepositContext);

  let { exchWallet, callAllWallet } = useContext(WalletContext);
  let optionList = useMemo(() => setCurrencyList(exchWallet.data, false, true), [exchWallet]);


  const formattedArrayBlockpit = (array) => {
    return array?.map((item) => {
      return {
        id: {
          id: iconList?.find(d => d.Tepmlate === item),
          label: item,
          value: item
        },
        label: item,
        value: item
      };
    });
  };

  useEffect(() => {
    callAllWallet();
    props.getMarketLists();

    fetchReportTemplate().then(res => {
      setBlockpitList(res || {});
      setIconList(res.Icons || []);
    }).catch(err => {
      showErrorMsg(formatAPIErrors(err));
      setBlockpitList({});
      setIconList([]);
    });
  }, []);

  const validToDate = (current) => {
    if (from) {
      const max = moment(from).add(365, "days");
      return (
        valid(current) &&
        (moment(current).isAfter(moment(from)) || moment(current).isSame(moment(from))) &&
        moment(current).isBefore(max)
      );
    }
  };

  const FetchReports = (page) => {
    const data = {
      offset: page || 1
    };
    props.getUserReports(data);
  };

  const validFromDate = (current) => {
    if (to) {
      return (
        valid(current) &&
        (moment(current).isBefore(moment(to)) || moment(current).isSame(moment(to)))
      );
    }
    return valid(current);
  };

  const comparission = trades.value === TradesOptions[1] || trades.value === TradesOptions[2];

  const createRequest = debounce(() => {
    setSubmitloader(true);
    if (KYC?.STATUS !== "LEVEL2") return setShow2fa(true);
    else {
      if (date.fromDate && date.toDate && selectedTaxReport) {
        const data = {
          Type: LedgerTradeCheck(trades?.value),
          From: moment(date.fromDate).format("DD-MM-YYYY"),
          To: moment(date.toDate).format("DD-MM-YYYY"),
          Template: selectedTaxReport.label,
          Currency: comparission
            ? selectedCoin.coin === "All Assets"
              ? ""
              : selectedCoin.coin
            : pair.symbol === "All Pairs"
            ? ""
            : pair.symbol
        };

        return setupUserReports(data)
          .then((res) => {
            showSuccessMsg(res.data.message);
            FetchReports(page || 1);
            // setTo("");
            // setFrom("");
            setTrades({
              id: { id: "Trades", label: "Trades", value: "Trades" },
              label: "Trades",
              value: "Trades"
            });
            setPair({
              id: { id: "All Pairs", symbol: "All Pairs" },
              symbol: "All Pairs"
            });
            setSelectedTaxReport("");
            setSelectedCoin({
              id: "0",
              label: "All Assets",
              value: "All Assets",
              coin: "All Assets"
            });
          })
          .catch((e) => showErrorMsg(e.response?.data?.message));
      } else {
        showErrorMsg("Fill All Fields");
      }
    }
  }, 700);

  useEffect(() => {
    setTimeout(() => {
      setSubmitloader(false);
    }, 700);
  }, [submitLoader]);

  const handleTradeDropeDown = (coinData) => {
    setTrades(coinData);
  };
  const handleDepositPair = (coinData) => {
    setPair(coinData);
  };

  const handleTaxReport = (coinData) => {
    setSelectedTaxReport(coinData);
  };

  const handleDepositCurrency = (coin) => {
    if (coin === "0")
      setSelectedCoin({
        id: "0",
        coin: "All Assets"
      });
    else setSelectedCoin(coin);
  };

  const selectedPair = (coinData) => {
    setPair(coinData);
  };

  let optionList2 =
    (props.isMarketLists &&
      props.isMarketLists.length > 0 &&
      formattedSymbol([{ id: "All Pairs", symbol: "All Pairs" }, ...props.isMarketLists])) ||
    [];

  useEffect(() => {
    if (from.length === 0) {
      setTo("");
      // setFrom(moment(beforeCurrentDatte));
    }
  }, [to, from]);

  useEffect(() => {
    setTo(moment(currentDate));
    setFrom(moment(beforeCurrentDatte));
  }, []);

  const SubmitCheck = () => {
    if (KYC?.STATUS !== "LEVEL2") return setShow2fa(true);
    else {
      if (selectedTaxReport === "") {
        setError({
          ...error,
          taxReport: true
        });
      }
      if (date.fromDate === "") {
        setError({
          from: true,
          to: false,
          taxReport: false
        });
      }
      if (date.toDate === "" && date.fromDate === "") {
        setError({
          to: true,
          from: true,
          taxReport: false
        });
      } else if (date.toDate === "") {
        setError({
          to: true,
          from: false,
          taxReport: false
        });
      } else {
        setError({
          to: false,
          from: false,
          taxReport: false
        });
      }
    }
  };

  useEffect(() => {
    if (from || to) {
      setError({
        from: false,
        to: false
      });
    }
  }, [from, to]);

  return (
    <BannerSection>
      {show2fa && <GetKycVerified open={show2fa} onClose={() => setShow2fa(false)} />}
      <BannerBox
        display="flex"
        justifyContent="space-between"
        width="88%"
        widthLg="82%"
        widthMd="100%"
      >
        <BannerHeading size="14px" txtGrey>
          {" "}
          Filters{" "}
        </BannerHeading>

        {date.fromDate !== "" && date.toDate !== "" && (
          <BannerHeadingSpan
            txtDanger
            size="14px"
            cursorPointer
            onClick={() => setDate({ fromDate: "", toDate: "" })}
          >
            Clear all
          </BannerHeadingSpan>
        )}
      </BannerBox>

      <BannerBox display="flex" width="100%" direction="row" directionLg="column" gap="16px">
        <BannerBox display="flex" width="48%" direction="row" gap="16px" widthLg="100%" justifyContentLg="space-between" flexWrapLg="wrap">
          <BannerBox width="100%" widthLg="31%" widthMd="100%">
            <SelectDropDownComponent
              dataComponent={{
                DropdownIndicator
              }}
              name="Trades"
              placeholder={"Select Trade"}
              // givenClass={"f-sm-10"}
              menuIsOpen={true}
              optionList={formattedArray(TradesOptions)}
              chooseLabel={CustomOptionsForWallets}
              value={
                trades && trades.value
                  ? { id: trades.id, label: trades.value, value: trades.value }
                  : ""
              }
              defaultValue={
                trades && trades.value
                  ? { id: trades.id, label: trades.value, value: trades.value }
                  : ""
              }
              handlePairName={handleTradeDropeDown}
              getStyle={customStylesContinentsForWallets}
            />
          </BannerBox>

          {!comparission ? (
            <BannerBox width="100%" widthLg="31%" widthMd="47%">
              <SelectDropDownComponent
                dataComponent={{
                  DropdownIndicator
                }}
                name="pair"
                // givenClass={"f-md-10"}
                placeholder="Select Pair"
                optionList={optionList2}
                selected_coin={selectedPair.symbol}
                // chooseLabel={CustomOptionsForCurrency}
                value={pair.symbol ? { id: pair, label: pair.symbol, value: pair.symbol } : ""}
                defaultValue={
                  pair.symbol ? { id: pair, label: pair.symbol, value: pair.symbol } : ""
                }
                handlePairName={handleDepositPair}
                getStyle={customStylesContinentsForWallets}
              />
            </BannerBox>
          ) : (
            <BannerBox width="100%" widthLg="31%" widthMd="47%">
              <SelectDropDownComponent
                dataComponent={{
                  DropdownIndicator
                }}
                name="selectCurrency"
                // givenClass={"f-md-10"}
                placeholder="Select Asset"
                optionList={currencyList}
                selected_coin={selectedCoin}
                chooseLabel={CustomOptionsForCurrency}
                value={
                  selectedCoin.coin ? { label: selectedCoin.coin, options: selectedCoin.coin } : ""
                }
                defaultValue={
                  selectedCoin.coin ? { label: selectedCoin.coin, options: selectedCoin.coin } : ""
                }
                handlePairName={handleDepositCurrency}
                getStyle={customStylesContinentsForWallets}
              />
            </BannerBox>
          )}

          <BannerBox width="100%" widthLg="31%" widthMd="47%">
            <SelectDropDownComponent
              dataComponent={{
                DropdownIndicator
              }}
              name="blockpit"
              placeholder="Tax report via"
              optionList={trades && trades.value && trades.value === "Trade" ? formattedArrayBlockpit(blockpitList?.SupportedTemplate?.trade || []) : formattedArrayBlockpit(blockpitList?.SupportedTemplate?.wallet || [])}
              selected_coin={selectedPair.symbol}
              chooseLabel={CustomOptionsForBlockPit}
              value={selectedTaxReport.label ? { id: selectedTaxReport, label: selectedTaxReport.label, value: selectedTaxReport.label } : ""}
              defaultValue={
                selectedTaxReport.label ? { id: selectedTaxReport, label: selectedTaxReport.label, value: selectedTaxReport.label } : ""
              }
              handlePairName={handleTaxReport}
              getStyle={customStylesContinentsForWallets}
            />
          </BannerBox>
          
          {width > 767 && width <= 1024 && (
            <BannerBox paddRight="0px">
              <div style={{ width: "85px" }}></div>
            </BannerBox>
          )}
        </BannerBox>

        <BannerBox display="flex" width="48%" direction="row" gap="16px" widthLg="100%">
          <BannerBox
            mt={"6px"}
            mtLg={"8px"}
            mtMd={"10px"}
            position="relative"
            width="196px"
            widthLg="48%"
            widthMd="50%"
            height="44px"
            // DatePickerContainer
          >
            <DatePickerComponent
              threeMonthCheck={false}
              onDayBeforeCheck={true}
              quickFilterStatus="true"
              givenDate={date}
              selectedDate={date.fromDate}
              setQuickFilterValue={({ fromDate, toDate }) => {
                setDate({ fromDate, toDate });
              }}
              name="From"
            />
            <BannerBox
              position="absolute"
              zIndex="2"
              top="10px"
              right="16px"
              topLg="10px"
              topMd="10px"
              rightLg="16px"
              rightMd="8px"
            >
              <EditCalendarIcon widthSm="16px" />
            </BannerBox>
          </BannerBox>

          <BannerBox
            mtLg={"8px"}
            mtMd={"10px"}
            mt={"6px"}
            width="196px"
            widthLg="48%"
            position="relative"
            widthMd="50%"
            height="44px"
          >
            <DatePickerComponent
              threeMonthCheck={false}
              onDayBeforeCheck={true}
              quickFilterStatus="true"
              givenDate={date}
              selectedDate={date.toDate}
              setQuickFilterValue={({ fromDate, toDate }) => {
                setDate({ fromDate, toDate });
              }}
              name="To"
            />

            <BannerBox
              position="absolute"
              zIndex="2"
              top="10px"
              right="16px"
              topMd="10px"
              topLg="10px"
              rightLg="16px"
              rightMd="8px"
            >
              <EditCalendarIcon widthSm="16px" />
            </BannerBox>
          </BannerBox>
          {width > 560 && (
            <BannerBox>
              {to && from && (
                <ButtonComponent
                  type="button"
                  onClick={() => createRequest()}
                  width="85px"
                  btnHeight="42px"
                  mt="8px"
                  bck="transparent"
                  OutlineOnlyPrimary2={(to && from && selectedTaxReport && !submitLoader) ? true : false }
                  OutlineOnlyDisabled={!(to && from && selectedTaxReport && !submitLoader) ? true : false}
                  size="14px"
                >
                  Submit
                </ButtonComponent>
              )}
            </BannerBox>
          )}
        </BannerBox>
      </BannerBox>
      {width <= 560 && (
        <BannerBox mt="16px">
          {to && from && selectedTaxReport && !submitLoader ? (
            <ButtonComponent
              type="button"
              onClick={() => createRequest()}
              width="100px"
              btnHeight="36px"
              mt="8px"
              bck="transparent"
              OutlineOnlyPrimary2
              size="14px"
            >
              Submit
            </ButtonComponent>
          ) : (
            <ButtonComponent
              type="button"
              width="100px"
              btnHeight="36px"
              mt="8px"
              bck="transparent"
              size="14px"
              onClick={() => {
                SubmitCheck();
              }}
              style={{
                border: props.isTheme ? "1px solid #38393D" : "1px solid #CCCED0",
                color: props.isTheme ? "#969799" : "#8C9797"
              }}
            >
              Submit
            </ButtonComponent>
          )}
        </BannerBox>
      )}
    </BannerSection>
  );
}
const maptoStateProps = (state) => ({
  isMarketLists: state.market.isMarketLists,
  isTheme: state.theme.isTheme
});

const mapDispatchToProps = (dispatch) => ({
  setupUserReports: (data) => dispatch(setupUserReports(data)),
  getMarketLists: (user) => dispatch(getMarketLists(user)),
  getTransactions: (user) => dispatch(getTransactions(user)),
  getUserReports: (data) => dispatch(getUserReports(data))
});

export default connect(maptoStateProps, mapDispatchToProps)(FilterComponent);
