/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";

import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import CustimizeMultiSelectDropDown from "../../Reusable/ReactMultiSelectDropdown/CustimizeMultiSelectDropDown";
import { EditCalendarIcon } from "../../../assets/svgs";
import InputWrapper from "../../Reusable/InputWrapper/InputWrapper";
import { getAllWallets } from "../../../lib/api";
import { useDispatch } from "react-redux";
import { getMarketLists } from "../../../actions/marketLists";
import { formattedArray, sortNumberData } from "../../../utils/general";
import DatePickerComponent from "../../../components/DatePicker/DatePicker";

var yesterday = Datetime.moment();
var valid = function (current) {
  return current.isBefore(yesterday);
};

const FilterDetailsEarning = ({ onApplyFilterHandler }) => {
  const [date, setDate] = useState({ fromDate: null, toDate: null });
  const [selectedCoins, setSelectedCoins] = useState("");
  const [optionList, setOptionList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllWallets()
      .then((res) => {
        dispatch(getMarketLists());
        setOptionList(sortNumberData(res.data, true));
      })
      .catch((err) => {});
  }, []);

  const validFromDate = (current) => {
    if (date.toDate) {
      return (
        valid(current) &&
        (moment(current).isBefore(moment(date.toDate)) ||
          moment(current).isSame(moment(date.toDate)))
      );
    }
    return valid(current);
  };

  const validToDate = (current) => {
    if (date.fromDate) {
      return (
        valid(current) &&
        (moment(current).isAfter(moment(date.fromDate)) ||
          moment(current).isSame(moment(date.fromDate)))
      );
    }
    return valid(current);
  };

  const clearAll = () => {
    setDate({ fromDate: "", toDate: "" });
    setSelectedCoins("");
    onApplyFilterHandler({
      Detailed: true,
      Offset: 1
    });
  };

  useEffect(() => {
    if ((date.fromDate && date.toDate) || selectedCoins) {
      applyFilter();
    }
  }, [selectedCoins, date]);

  const applyFilter = () => {
    let filter = {
      Detailed: true,
      Offset: 1
    };

    if (selectedCoins && selectedCoins.length > 0) {
      let coins = [];
      selectedCoins.forEach((item) => coins.push(item.value));
      filter.Coins = coins;
    }

    if (date.fromDate && date.toDate) {
      filter.From = moment(date.fromDate).format("DD-MM-YYYY");
      filter.To = moment(date.toDate).format("DD-MM-YYYY");
    }
    if (Object.keys(filter).length >= 2) {
      onApplyFilterHandler(filter);
    }
  };

  return (
    <BannerBox width="75%" widthMd="100%" display="flex" direction="column">
      <BannerBox display="flex" width="100%" justifyContent="flex-end">
        {((selectedCoins && selectedCoins.length > 0) || date.fromDate || date.toDate) && (
          <ParaTag size="14px" txtDanger cursor="pointer" onClick={clearAll}>
            Clear All
          </ParaTag>
        )}
      </BannerBox>

      <BannerBox
        display="flex"
        justifyContent="flex-end"
        gap="24px"
        alignItems="center"
        flexWrapMd="nowrap"
        directionMd="column"
      >
        <BannerBox width="30%" widthLg="33%" mt="6px" widthMd="100%">
          <CustimizeMultiSelectDropDown
            WalletStatus={true}
            setSelectedOptions={setSelectedCoins}
            options={formattedArray((optionList && optionList?.data) || [])}
            placeholder={"Asset Earned"}
            value={selectedCoins}
          />
        </BannerBox>

        <BannerBox display="flex" gapMd="12px" gap="24px">
          <BannerBox
            mtMd={"0px"}
            position="relative"
            CardLevel3OnlyBackground
            InputBottomBorder
            borderWidth="2px"
            radius="5px 5px 0 0"
            mt={"6px"}
            mx="0"
            height="44px"
            top="6px"
            topSm="0px"
            widthMd="48%"
          >
            <DatePickerComponent
              quickFilterStatus="true"
              givenDate={date}
              selectedDate={date.fromDate}
              setQuickFilterValue={({ fromDate, toDate }) => {
                setDate({ fromDate, toDate });
              }}
              name="From"
            />

            <BannerBox
              position="absolute"
              top="0"
              right="16px"
              zIndex="1"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <EditCalendarIcon />
            </BannerBox>
          </BannerBox>

          <BannerBox
            position="relative"
            CardLevel3OnlyBackground
            radius="5px 5px 0 0"
            mt={"6px"}
            mtMd={"6px"}
            height="44px"
            widthMd="48%"
          >
            <DatePickerComponent
              quickFilterStatus="true"
              givenDate={date}
              selectedDate={date.toDate}
              setQuickFilterValue={({ fromDate, toDate }) => setDate({ fromDate, toDate })}
              name="To"
            />
            <BannerBox
              position="absolute"
              top="0"
              right="16px"
              zIndex="1"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <EditCalendarIcon />
            </BannerBox>
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
};

export default FilterDetailsEarning;
