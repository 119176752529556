import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Datetime from "react-datetime";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { WalletContext } from "../../../../contexts/WalletPage/BalancePage/wallet";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { EditCalendarIcon } from "../../../../assets/svgs";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import InputWrapper from "../../../../components/Reusable/InputWrapper/InputWrapper";
import { AllSaleCoins } from "../../../../lib/api";
import { ReactMultiSelectDropdown } from "../../../../components/Reusable";
import { DatePickerStyles } from "../../../../assets/StyleComponents/DatePicker.style";
import { DatePickerStyles2 } from "../../../../assets/StyleComponents/DatePicker2.style";

var yesterday = Datetime.moment();
var valid = function (current) {
  return current.isBefore(yesterday);
};

function ParticipatedFilterComponent(props) {
  const { selectedAssets, setSelectedAssets, date, setDate, clearAll } = props;
  let { callAllWallet } = useContext(WalletContext);
  const [allcoinList, setAllcoinsList] = useState([]);
  const { width } = useWindowSize();

  let {
    selectedCoin,
    setToDate,
    setFrom,
    setPage,
    from,
    to,
    coinChanged,
    setSelectedCoin,
    FilterCallApi
  } = useContext(DepositContext);

  useEffect(() => {
    callAllWallet();
    AllSaleCoins()
      .then((res) => {
        setAllcoinsList(res.data.data);
      })
      .catch((err) => {
        throw new Error();
      });
  }, []);

  const Coins = allcoinList.map((values) => {
    return { label: values.coin, value: values.coin, quoteCoin: values.coin };
  });

  useEffect(() => {
    if (selectedCoin !== "") {
      // callApi(selectedCoin, from, to);
      onApplyFilter();
    }
  }, [selectedCoin]);

  useEffect(() => {
    if (date.fromDate && date.toDate) onApplyFilter();
  }, [date]);

  const onApplyFilter = () => {
    let filterData = {};
    if (selectedAssets && selectedAssets.length > 0) {
      let filterAssets = [];
      selectedAssets.forEach((item) => filterAssets.push(item.value));
      filterData.coins = filterAssets;
    }

    if (date.fromDate && date.toDate) {
      filterData.fromDate = moment(date.fromDate).format();
      filterData.toDate = moment(date.toDate).endOf("day").format();
    }

    if (Object.keys(filterData).length >= 1) {
      onApplyFilterHandler(filterData);
    }
  };

  const onApplyFilterHandler = (givenFilterData) => {
    setPage(0);
    FilterCallApi(givenFilterData);
  };

  const validToDate = (current) => {
    if (from) {
      return (
        valid(current) &&
        (moment(current).isAfter(moment(from)) || moment(current).isSame(moment(from)))
      );
    }
    return valid(current);
  };

  const validFromDate = (current) => {
    if (to) {
      return (
        valid(current) &&
        (moment(current).isBefore(moment(to)) || moment(current).isSame(moment(to)))
      );
    }
    return valid(current);
  };

  const selectFromDate = (fromDate) => {
    setFrom(moment(fromDate).format());
    setDate({
      ...date,
      fromDate: moment(fromDate).format()
    });
  };

  const selectToDate = (toDate) => {
    setToDate(moment(toDate).endOf("day").format());
    setDate({
      ...date,
      toDate: moment(toDate).endOf("day").format()
    });
  };

  useEffect(() => {
    let param = {
      coin: selectedAssets
    };
    coinChanged(selectedAssets);
    setSelectedAssets(selectedAssets);
    setSelectedCoin(param);
  }, [selectedAssets]);

  return (
    <BannerBox width="100%" mb="32px">
      <BannerBox
        display="flex"
        width="55%"
        widthLg="100%"
        widthMd="97%"
        justifyContent="space-between"
      >
        <ParaTag size="14px" txtGrey>
          {" "}
          Filters{" "}
        </ParaTag>
        {(selectedAssets?.length > 0 || from || to) && (
          <ParaTag size="14px" txtDanger cursor="pointer" onClick={clearAll}>
            Clear All
          </ParaTag>
        )}
      </BannerBox>

      <BannerBox
        width="75%"
        widthLg="100%"
        display="flex"
        flexWrapMd="wrap"
        // justifyContent="space-between"
        alignItems="center"
        flex="1"
        gap="24px"
        // className="col-md-12 d-flex flex-fill px-0 col-lg-9"
      >
        <BannerBox width="180px" widthLg="180px" mt="6px" mb="0px" widthMd="100%">
          <ReactMultiSelectDropdown
            setSelectedOptions={setSelectedAssets}
            options={Coins}
            containerProps={{ width: "100%" }}
            placeholder={width > 767 ? "Select Asset" : "Asset"}
            value={selectedAssets}
            marginBottom={{ marginBottom: "0px" }}
          />
        </BannerBox>

        <BannerBox display="flex" gapMd="10px" gapLg="20px" gap="25px">
          <BannerBox
            mt={"6px"}
            mtMd={"6px"}
            width="180px"
            widthLg="50%"
            widtdMd="47%"
            position="relative"
            height="100%"
            BodyColor
          >
            <DatePickerStyles
              inputProps={{
                placeholder: "From"
              }}
              renderInput={(props) => {
                return (
                  <InputWrapper
                    {...props}
                    // InputBottomBorder
                    value={from && moment(from).format("DD-MM-YYYY")}
                    style={{ width: "100%", paddingLeft: "10px" }}
                  />
                );
              }}
              closeOnSelect={true}
              dateFormat="DD-MM-YYYY"
              isValidDate={validFromDate}
              timeFormat={false}
              onChange={(fromDate) => {
                if (typeof fromDate !== "object") return;
                selectFromDate(fromDate);
              }}
              selected={from !== "" ? from : ""}
              // value={fromDate !== "" ? fromDate : ""}
              isClearable={from !== "" ? false : true}
            />

            <BannerBox position="absolute" top="7px" right="16px" zIndex="1">
              <EditCalendarIcon />
            </BannerBox>
          </BannerBox>

          <BannerBox
            mt={"6px"}
            mtMd={"6px"}
            width="180px"
            widthLg="50%"
            widtdMd="47%"
            position="relative"
            height="100%"
            // className="form-group pd-rem-l-5-3 pd-md-l-4 wth-md-per-36 position-relative mb-0"
          >
            <DatePickerStyles2
              inputProps={{
                placeholder: "To"
              }}
              renderInput={(props) => {
                return (
                  <InputWrapper
                    {...props}
                    // InputBottomBorder
                    value={to && moment(to).format("DD-MM-YYYY")}
                    style={{ width: "100%", paddingLeft: "10px" }}
                  />
                );
              }}
              closeOnSelect={true}
              dateFormat="DD-MM-YYYY"
              timeFormat={false}
              isValidDate={validToDate}
              onChange={(toDate) => {
                if (typeof toDate !== "object") return;
                selectToDate(toDate);
                // this.setState({ toDate: moment(toDate).format() });
              }}
              value={to}
            />
            <BannerBox
              position="absolute"
              top="7px"
              right="16px"
              zIndex="1"
              className="exch-wallet-date-icon3"
            >
              <EditCalendarIcon className="img-fluid wth-md-16" />
            </BannerBox>
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
}

export default ParticipatedFilterComponent;
