import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  tokenSales: "",
  error: "",
  apiData: "",
  apiLoader: false,
  errorGet: "",
  allClientSales: "",
  isAllClientSalesFetching: false
};

let initialState = initialStateSchema;
const localData = localStorage.getItem("accessToken");

if (localData && localData !== undefined) {
  try {
    initialState.authToken = localData;
  } catch (e) {
    initialState = initialStateSchema;
  }
}

const tokenSaleReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GET_TOKEN_SALES:
      newState = {
        ...state,
        apiLoader: true
      };

      return newState;

    case apiConstants.GET_TOKEN_SALES_SUCCESS:
      newState = {
        ...state,
        apiLoader: false,
        tokenSales: action.response.data,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_TOKEN_SALES_FAIL:
      newState = {
        ...state,
        apiLoader: false,
        errorGet: true,
        error: action.response
      };
      return newState;

    case apiConstants.GET_ALL_CLIENT_SALES:
      newState = {
        ...state,
        isAllClientSalesFetching: true,
        error: ""
      };
      return newState;

    case apiConstants.GET_ALL_CLIENT_SALES_SUCCESS:
      newState = {
        ...state,
        allClientSales: action.response.data,
        isAllClientSalesFetching: false
      };
      return newState;

    case apiConstants.GET_ALL_CLIENT_SALES_FAIL:
      newState = {
        ...state,
        error: action?.response,
        isAllClientSalesFetching: false
      };
      return newState;

    default:
      return state;
  }
};

export default tokenSaleReducer;
