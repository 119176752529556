import { Doughnut } from "react-chartjs-2";
import React, { useState, useEffect } from "react";

import { new_BN } from "../../utils/bigNumber";
import { formatNumbers } from "../../utils/helpers";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { checkThousand, numberWithCommas } from "../../utils/general";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import DollarImageComponent from "../../components/DollarImage/dollarImage";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";

export default function ChartDashboard(props) {
  const { width } = useWindowSize();
  const [data, setData] = useState("");
  const [amount, setAmount] = useState("0");
  const [coinName, setCoinName] = useState("LCX");
  const [usdAmount, setUsdAmount] = useState("0");

  const prepareFormat = (walletBalances) => {
    if (!walletBalances || !Object.keys(walletBalances).length) return { array: [], color: [] };
    let array = [],
      color = ["#C1D0E0", "#5599E0", "#438FE0", "#f5f6fa", "#cde2f9", "#9bc6f3", "#68a9ed"];
    color = color.reverse();
    color = [...color, ...color];
    return { array, color };
  };

  const refineData = () => {
    props.data.sort((a, b) => a.equivalentUSDBalance.totalBalance > b.equivalentUSDBalance.balance);

    // eslint-disable-next-line no-console
    if (props.data && props.data.length > 0) {
      let { color } = prepareFormat(props.data);
      let labels = [];
      let totalPieData = [];
      let othersData = [];
      let pieData = [];
      let count = 0;
      let usdSum = 0;

      let resultData = props.data.sort((a, b) =>
        a.equivalentUSDBalance.totalBalance < b.equivalentUSDBalance.totalBalance
          ? 1
          : b.equivalentUSDBalance.totalBalance < a.equivalentUSDBalance.totalBalance
          ? -1
          : 0
      );

      resultData.map((item, index) => {
        if (count > 5) {
          usdSum += item.equivalentUSDBalance.totalBalance;
        } else {
          if (item.balance.totalBalance > 0) {
            labels.push(item.coin);
            totalPieData.push(item.balance.totalBalance || 0);
            pieData.push(item.equivalentUSDBalance.totalBalance || 0);
          }
        }
        count++;

        return true;
      });

      if (usdSum > 0) {
        labels.push("Others");
        totalPieData.push(0);
        pieData.push(usdSum);
      }

      let checkArrZero = pieData.every((item) => Number(item) === 0);

      color.splice(labels.length);

      if (checkArrZero === true) setDefaultChartValue(labels, pieData, props);
      else {
        setCoinName(labels[0]);
        setAmount(totalPieData[0]);
        setUsdAmount(pieData[0]);

        let usersData = {
          responsive: true,
          labels: labels,
          datasets: [
            {
              labels: labels,
              data: othersData.concat(pieData),
              dataValue: totalPieData,
              backgroundColor: color,
              hoverBackgroundColor: color,
              borderColor: color
            }
          ],
          options: {
            elements: {
              arc: {
                borderWidth: 0
              }
            },
            cutoutPercentage: 70,
            legend: {
              display: false
            },
            layout: {
              padding: 5,
              line: {
                borderColor: "#F85F73"
              }
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  var label = data.datasets[tooltipItem.datasetIndex].labels[tooltipItem.index];
                  var totalValue =
                    data.datasets[tooltipItem.datasetIndex].dataValue[tooltipItem.index];
                  var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  setCoinName(label);
                  setUsdAmount(
                    Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) || 0
                  );
                  setAmount(Number(totalValue) || 0);

                  return props.hideValue
                    ? `${label}: ${convertNumberToStar(value?.toString(), 7)}`
                    : `${label}: $${numberWithCommas(
                        checkThousand(Number(new_BN(value || 0, 8)))
                      )}`;
                }
              }
            }
          }
        };

        setData(usersData);
      }
    }
  };

  const setDefaultChartValue = (getLabel, getPieData, getProps) => {
    // let findLabel = props.data.find((data) => data.coin === "LCX");
    setCoinName("LCX");
    setAmount(0);
    setUsdAmount(0);
    let usersData = {
      responsive: true,
      labels: ["LCX"],
      datasets: [
        {
          labels: ["LCX"],
          data: [9999],
          backgroundColor: ["#438FE0"],
          borderColor: ["#438FE0"]
        }
      ],
      options: {
        cutoutPercentage: 70,
        legend: {
          display: false
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        layout: {
          padding: 5
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return "LCX: 0";
            }
          }
        }
      }
    };
    setData(usersData);
  };

  //=> Number convert to  *
  const convertNumberToStar = (x, showStars) => {
    let val = "";
    if (showStars === 7) {
      val = "*******";
    }
    if (showStars === 5) {
      val = "*****";
    }
    return val;
  };

  useEffect(() => {
    if (props.data) refineData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.hideValue]);

  return (
    <BannerBox
      display="flex"
      width="100%"
      height="100%"
      alignItems="flex-start"
      justifyContent="between"
      alignItemsLg="center"
    >
      <BannerBox position="relative" width="50%" widthLg="65%" style={{ zIndex: "1" }}>
        <BannerBox
          position="relative"
          padding="16px"
          paddingLg="16px 0 0 0"
          style={{ zIndex: "1" }}
        >
          {data && (
            <Doughnut
              data={data}
              options={data.options}
              width={width >= 1024 && width <= 1130 ? 250 : 200}
              height={width >= 1024 && width <= 1130 ? 250 : 200}
            />
          )}
        </BannerBox>

        <BannerBox
          position="absolute"
          top="0"
          width="100%"
          height="100%"
          display="flex"
          direction="column"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
        >
          <BannerBox width="250px">
            <ParaTag size="14px" fontWeight="bold" mb="4px">
              {" "}
              {coinName}{" "}
            </ParaTag>

            <ParaTag size="14px" mb="4px">
              {props.hideValue
                ? `${convertNumberToStar(amount?.toString(), 7)}`
                : `${numberWithCommas(checkThousand(Number(new_BN(amount, 8))))}`}
            </ParaTag>

            <ParaTag size="12px" txtGrey mb="4px">
              {props.hideValue ? (
                `${convertNumberToStar(usdAmount?.toString(), 5)}`
              ) : (
                <DollarImageComponent
                  DollarAmount={checkThousand(formatNumbers(usdAmount, 2))}
                  {...props}
                />
              )}
            </ParaTag>
          </BannerBox>
        </BannerBox>
      </BannerBox>

      <BannerBox
        display="flex"
        width="35%"
        flexWrap="wrap"
        justifyContent="flex-end"
        pt="16px"
        // className="d-flex wth-per-35 flex-wrap justify-content-end pt-3"
      >
        {data.labels &&
          data.labels.map((item, index) => (
            <BannerBox
              key={index}
              display="flex"
              width="70px"
              alignItems="center"
              mb="8px"
              // className="d-flex wth-70 align-items-center mb-2"
            >
              <BannerBox
                radius="2px"
                width="20px"
                height="20px"
                mr="8px"
                bck={data?.datasets[0]?.backgroundColor[index]}
                shadow={"inset -2px -2px 6px 0 rgba(82, 132, 186, 0.4)"}
              />
              <BannerHeading weight="300" size="12px">
                {" "}
                {item}{" "}
              </BannerHeading>
            </BannerBox>
          ))}
      </BannerBox>
    </BannerBox>
  );
}
