/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { detectIncognito } from "detectincognitojs";
import Stepfirst from "./Stepfirst";
import { formatAPIErrors } from "../../../../../utils/general";
import { getTransactions, getWithdrawalOtp, confirmCoinWithdrawal } from "../../../../../lib/api";
import WithdrawalStatus from "../../../../../views/walletPageCopy/WithdrawalPage/Component/Modal/WithdrawalStatus";
import { DepositContext } from "../../../../../contexts/WalletPage/DepositPage/depositContext";
import BigNumber from "bignumber.js";
import { LoadingButton } from "../../../../../components/Loader";
import { showErrorMsg, showSuccessMsg } from "../../../../../utils/notification";
import { CloseIcon } from "../../../../../assets/svgs";
import {
  Button,
  DivElement,
  ModalWrapper,
  TextWrapper,
  ToolTip
} from "../../../../../components/Reusable";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
// import { BannerHeadingSpan } from "../../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox, BannerHeadingSpan } from "../../../../../assets/StyleComponents/Exchange.style";
import { getConfirmWithdrawalEmailApi } from "../../../../../lib/Wallet/walletApi";

const WithdrawalModal = (props) => {
  let { isDarkTheme } = props;
  const [enteredOtp, setEnteredOtp] = useState("");
  const [entered2Fa, setEntered2Fa] = useState("");
  const [txDetails, setTxDetails] = useState("");
  const [notabeneLoading, setNotabeneLoading] = useState(false);
  const [notabeneTx, setNotabeneTx] = useState({});
  const [steps, setSteps] = useState(() =>
    props.goToStep && typeof props.goToStep === "number" ? props.goToStep : 1
  );
  const [btnLoading, setBtnLoading] = useState(false);
  const { withdrawalRes, coin, amount, address } = props;
  let notaBeneInstance = useRef(null);
  const { selectedCoin, setWithdrawalCount, setWithdrawalList, selectedNetwork, selectedProvider } =
    useContext(DepositContext);

  const [timerRunning, setTimerRunning] = useState(false);
  const [dateTimer, setDateTimer] = useState("");
  const [otpType, setOtpType] = useState("Get Code");
  const [getOtpLoader, setGetOtpLoader] = useState(false);

  const getOtp = () => {
    if (timerRunning || getOtpLoader) return;
    setGetOtpLoader(true);
    let { otpId } = props;

    getWithdrawalOtp({ otpId })
      .then((res) => {
        showSuccessMsg(res.data.message);
        setOtpType(otpType === "Get Code" ? "Resend" : otpType);
        setDateTimer(Date.now() + 30000);
        setTimerRunning(true);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setGetOtpLoader(false));
  };

  useEffect(() => {
    getOtp();
  }, []);

  const onConfirmWithdrawal = () => {
    if (!entered2Fa) {
      showErrorMsg("Please enter 2FA code");
      return;
    }
    if (!enteredOtp) {
      showErrorMsg("Please enter the OTP");
      return;
    }
    setBtnLoading(true);
    let { otpId } = props;
    let data = {
      otp: enteredOtp,
      token: entered2Fa,
      notabene: notabeneTx,
      otpId,
      provider:
        selectedNetwork.label === "Clear Junction" ? "CLEAR_JUNCTION" : selectedNetwork.label,
      network_id:
        typeof selectedNetwork.id === "number"
          ? Number(selectedNetwork.id)
          : Number(selectedNetwork.id.id)
    };

    const params = {
      data: data,
      isBtcLightning: selectedCoin.coin === "BTC" && selectedNetwork.label === "Lightning"
    };
    if (selectedNetwork.label === "LCX Pay") {
      getConfirmWithdrawalEmailApi(params)
        .then((res) => {
          setTxDetails(res.data.data);
          setNotabeneTx({});
          getTransactions({
            order: "DESC",
            type: "WITHDRAWAL",
            page: 0,
            coin: props.coin.coin
          })
            .then((res) => {
              if (res.status === 200) {
                setWithdrawalCount(res.data.count);
                setWithdrawalList(res.data.data);
              }
            })
            .catch((err) => {
              showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
            });
          showSuccessMsg(res.data.message, { autoClose: 7000 });
        })
        .catch((err) => {
          showErrorMsg(formatAPIErrors(err));
        })
        .finally(() => {
          setBtnLoading(false);
        });
    } else {
      confirmCoinWithdrawal(params)
        .then((res) => {
          setTxDetails(res.data.data);
          setNotabeneTx({});
          getTransactions({
            order: "DESC",
            type: "WITHDRAWAL",
            page: 0,
            coin: props.coin.coin
          })
            .then((res) => {
              if (res.status === 200) {
                setWithdrawalCount(res.data.count);
                setWithdrawalList(res.data.data);
              }
            })
            .catch((err) => {
              showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
            });
          showSuccessMsg(res.data.message, { autoClose: 7000 });
        })
        .catch((err) => {
          showErrorMsg(formatAPIErrors(err));
        })
        .finally(() => {
          setBtnLoading(false);
        });
    }
  };

  const loadScript = (src) => {
    setNotabeneLoading(true);
    if (window.Notabene) {
      return true;
    }
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const callNotabean = async () => {
    try {
      if (notaBeneInstance.current) {
        notaBeneInstance.current.destroyWidget();
        notaBeneInstance.current = null;
      }
      const res = await loadScript(
        "https://unpkg.com/@notabene/javascript-sdk@latest/dist/es/index.js"
      );
      let checkBrowser = await detectIncognito();
      if (!res || checkBrowser?.isPrivate) {
        setNotabeneLoading(false);
        setNotabeneTx({});
        setSteps(2);
        return;
      }
      // eslint-disable-next-line no-undef
      let notaBene = new Notabene({
        vaspDID: withdrawalRes.vaspId,
        widget: withdrawalRes.widgetUrl,
        container: "#widget",
        authToken: withdrawalRes.accessToken,
        theme: {
          primaryColor: isDarkTheme ? "#70A6E0" : "#0470E1",
          secondaryColor: isDarkTheme ? "#A7A9AB" : "#606F80",
          primaryFontColor: isDarkTheme ? "#fff" : "#012243",
          secondaryFontColor: isDarkTheme ? "#A7A9AB" : "#606F80",
          backgroundColor: isDarkTheme ? "#263645" : "#FFFFFF",
          fontFamily: "Roboto",
          mode: isDarkTheme ? "dark" : "light" // default: 'light'
        },
        transactionTypeAllowed: "ALL", // 'ALL', 'SELF_TRANSACTION_ONLY', 'VASP_2_VASP_ONLY'
        nonCustodialDeclarationType: "SIGNATURE", // 'SIGNATURE', 'DECLARATION'
        onValidStateChange: (isValid) => {
          if (isValid) {
            setNotabeneTx(notaBene.tx);
            notaBeneInstance.current.destroyWidget();
            setSteps(2);
          }
        },
        onError: (err) => {
          // If any errors are encountered, they will be passed to this function
          setNotabeneTx({});
          setSteps(2);
          if (notaBeneInstance.current) {
            notaBeneInstance.current.destroyWidget();
            notaBeneInstance.current = null;
          }
          window.Notabene = null;
        }
      });
      notaBeneInstance.current = notaBene;
      notaBene.setTransaction({
        transactionAsset: coin.coin,
        beneficiaryAccountNumber: address,
        transactionAmount: BigNumber(amount)
          .multipliedBy(BigNumber(10).exponentiatedBy(selectedCoin.decimals))
          .toFixed()
      });
      setNotabeneLoading(false);
      notaBene.renderWidget();
    } catch (error) {
      setNotabeneLoading(false);
      setNotabeneTx({});
      setSteps(2);
      if (notaBeneInstance.current) {
        notaBeneInstance.current.destroyWidget();
        notaBeneInstance.current = null;
      }
      return;
    }
  };

  const onCloseHandler = () => {
    setNotabeneTx({});
    setSteps(1);
    if (notaBeneInstance.current) {
      notaBeneInstance.current.destroyWidget();
      notaBeneInstance.current = null;
    }
    window.Notabene = null;
    props.onClose();
  };

  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        {txDetails && txDetails.transactionId ? (
          <WithdrawalStatus
            selectedCoin={props.coin}
            selectedNetwork={selectedNetwork}
            txDetails={txDetails}
            onCloseModal={props.onClose}
            isTheme={props.isTheme}
            getTransactions={getTransactions}
          />
        ) : steps === 1 && coin.coinType !== "fiat_token" ? (
          <Stepfirst
            openNotabean={callNotabean}
            notabeneInstance={notaBeneInstance}
            notabeneLoading={notabeneLoading}
            {...props}
          />
        ) : (
          <Fragment>
            <DivElement displayBtw>
              <TextWrapper fontSize="20px" fontWeight="bold">
                {steps === 1 ? "Additional Information Required" : "Security Verification"}
              </TextWrapper>
              <CloseIcon onClick={onCloseHandler} />
            </DivElement>

            <DivElement marginBottom="32px" marginTop="24px">
              <ToolTip
                id="emailOtp"
                title="Enter OTP Code"
                tipContent="Please use the latest OTP received on your email"
                imgProps={{
                  height: "16px",
                  width: "16px",
                  absolute: true,
                  top: "-2px",
                  right: "-18px"
                }}
                titleProps={{ secondary: true, marginBottom: "4px" }}
              />

              <BannerBox FormNumberContainer>
                <NumberFormat
                  format="### ###"
                  autoComplete="totp"
                  mask="_"
                  name="totp"
                  placeholder="Enter OTP"
                  value={enteredOtp}
                  onValueChange={(e) => setEnteredOtp(e.value)}
                  className="form-control grey-placeholder"
                  id="otp"
                />
                <BannerHeadingSpan
                  OTPSpan
                  className={timerRunning || getOtpLoader ? "disabled" : null}
                  onClick={getOtp}
                >
                  {otpType}
                </BannerHeadingSpan>
              </BannerBox>
              <BannerHeading mt="6px" weight="300" size="14px" BodyColor>
                {timerRunning ? (
                  <Countdown
                    date={dateTimer}
                    renderer={({ seconds }) => (
                      <span>Didn't receive email? resend code in {seconds} seconds</span>
                    )}
                    onComplete={() => setTimerRunning(false)}
                  />
                ) : otpType === "Get Code" ? (
                  "Press 'Get Code' to receive 6 digit code"
                ) : (
                  "Didn't receive email? resend code now"
                )}
              </BannerHeading>
            </DivElement>

            <BannerBox className="form__field" mb="32px">
              <ToolTip
                id="twoFactorAuth"
                title="Enter 2FA Code"
                tipContent="Google Authenticator generates 2-Step Verification codes on your phone."
                imgProps={{
                  height: "16px",
                  width: "16px",
                  absolute: true,
                  top: "2px",
                  right: "-18px"
                }}
                titleProps={{ secondary: true, marginBottom: "4px" }}
              />
              <BannerBox FormNumberContainer>
                <NumberFormat
                  format="### ###"
                  autoComplete="totp"
                  mask="_"
                  name="totp"
                  placeholder="Enter 2FA"
                  value={entered2Fa}
                  onValueChange={(e) => setEntered2Fa(e.value)}
                  className="form-control grey-placeholder black-font"
                />
              </BannerBox>
            </BannerBox>

            <Button
              primaryBlue
              width="100%"
              height="48px"
              onClick={onConfirmWithdrawal}
              disabled={!entered2Fa || !enteredOtp || btnLoading}
            >
              {btnLoading ? <LoadingButton /> : "Confirm Withdrawal"}
            </Button>
          </Fragment>
        )}
      </DivElement>
    </ModalWrapper>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default connect(mapStateToProps)(WithdrawalModal);
