import { ACCOUNT_WALLET_LIST, EXCHANGE_WALLET_UPDATE, ACCOUNT_IS_FECTHING, EXCHANGE_IS_FECTHING } from "./const";

// Update Account FETCHING Wallet ...................
export const isFetchingAccountWallet = (getValue) => {
  return {
    type: ACCOUNT_IS_FECTHING,
    payload: getValue
  };
};

// Update ECHANGE FETCHING Wallet ...................
export const isFetchingExchangeWallet = (getValue) => {
  return {
    type: EXCHANGE_IS_FECTHING,
    payload: getValue
  };
};

// Update Account Wallet ...................
export const updateAccountWallet = (getValue) => {
  return {
    type: ACCOUNT_WALLET_LIST,
    payload: getValue
  };
};

// Update Exchange Wallet ...................
export const updateExchangeWallet = (getValue) => {
  return {
    type: EXCHANGE_WALLET_UPDATE,
    payload: getValue
  };
};
