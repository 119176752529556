import styled, { css } from "styled-components";

const FooterPart = styled.footer`
  margin: ${(props) => props.margin || 0};
  ${(props) => props.position && `position: ${props.position}`};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.minHeight && `min-height: ${props.minHeight}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.background && `background: ${props.background}`};
  ${(props) => props.bgImg && `background-image: ${props.bgImg}`};
  ${(props) => props.bckOrigin && `background-origin: ${props.bckOrigin}`};
  ${(props) => props.bckClip && `background-clip: ${props.bckClip}`};
  ${(props) => props.paddTop && `padding-top: ${props.paddTop}`};
  ${(props) => props.paddBottom && `padding-bottom: ${props.paddBottom}`};
  ${(props) => props.paddLeft && `padding-left: ${props.paddLeft}`};
  ${(props) => props.paddRight && `padding-right: ${props.paddRight}`};
  ${(props) => props.padd && `padding: ${props.padd}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  

  ${(props) =>
    props.FooterWhite &&
    css`
      background: ${({ theme }) => theme.level1Card} !important;
      // background-image: url('../img/bg-pattern.png');
      background-repeat: no-repeat;
      background-position: 20px 50px;
      vertical-align: top;
    `}

  ${(props) =>
    props.FooterSky &&
    css`
      background: ${({ theme }) => theme.level1CardSky} !important;
    `}
  
  ${(props) =>
    props.FooterGrey &&
    css`
      background: ${({ theme }) => theme.mainBackground} !important;
    `}
  ${(props) =>
    props.DashboardFotter &&
    css`
      background-color: #f2f4f8;
      background-image: url("../img/bg-pattern.png");
      background-repeat: no-repeat;
      background-position: 20px 50px;
      vertical-align: bottom;
    `}
`;

export { FooterPart };
