/* eslint-disable no-unused-vars */
import axios from "axios";
import { API } from "../../constants/api";
import apiURL from "../../constants/apiConstants";
import globalConfig from "../../constants/config";
import { formatAPIErrors, getLocalStorageData } from "../../utils/general";

axios.interceptors.response.use(
  (r) => r,
  function handle401Errors(error) {
    if (error && error.response && error.response.status === 401) {
      window.location = globalConfig.exchangeUrl;
      window.dispatchEvent(new Event("LOGOUT"));
    }
    return Promise.reject(error);
  }
);

export const accountApiV3 = axios.create({
  baseURL: apiURL.API_URL_V3,
  headers: {
    "Content-Type": "application/json"
  }
});

/**
 * @param {Get Email Verification for LCX User } param
 */

export async function getPrivateRewardsTasksList(param) {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) {
    accountApiV3.defaults.headers.common["Authorization"] = accessToken;
    let url = `/public/rewardhub-task-list?`;
    if (!param.type && !param.category) return false;
    if (param.type) url = `${url}type=${param.type}`;
    if (param.offset) url = `${url}&offset=${param.offset}`;
    if (param.pageSize) url = `${url}&pageSize=${param.pageSize}`;
    if (param.category) url = `${url}&category=${param.category}`;
    const response = await accountApiV3.get(url).then((res) => res);
    return response;
  } else {
    accountApiV3.defaults.headers.common["Authorization"] = accessToken;
    let url = `/user/list-user-tasks?`;
    if (!param.type && !param.category) return false;
    if (param.type) url = `${url}type=${param.type}`;
    if (param.offset) url = `${url}&offset=${param.offset}`;
    if (param.pageSize) url = `${url}&pageSize=${param.pageSize}`;
    if (param.category) url = `${url}&category=${param.category}`;
    const response = await accountApiV3.get(url).then((res) => res);
    return response;
  }
}

export const postUserParticipated = async (data) => {
  accountApiV3.defaults.headers.common["Authorization"] = localStorage.getItem("accessToken");

  const response = await accountApiV3
    .post("/user/participate-reward", data)
    .then((res) => res.data);
  return response;
};

export const postClaimReward = async (data) => {
  accountApiV3.defaults.headers.common["Authorization"] = localStorage.getItem("accessToken");

  const response = await accountApiV3.post("/user/claim-reward", data).then((res) => res.data);
  return response;
};

// TODO => Fetch Total Rewards Data
export const fetchTotalRewardsData = async () => {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken === "undefined" || accessToken == null) return false;

  accountApiV3.defaults.headers.common["Authorization"] = accessToken;
  const response = await accountApiV3.get("/user/fetch-total-rewards").then((res) => res.data);
  return response;
};
