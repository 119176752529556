import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { changeTheme } from "../../actions/theme";
import { updateSelectedPair } from "../../actions/trading";
import DepthChart from "../../pages/Auth/TradePage/Components/DepthChart";

const MobileDepthChart = () => {
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  let isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const { pair } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let theme = new URLSearchParams(location.search).get("theme");

    if ((theme === "dark" && !isDarkTheme) || (theme === "light" && isDarkTheme)) {
      dispatch(changeTheme());
    }
  }, []);

  useEffect(() => {
    let symbol = selectedPair.symbol;
    if (pair) {
      if (pair.includes("_")) {
        symbol = pair.replace("_", "/");
      } else if (pair.includes("-")) {
        symbol = pair.replace("-", "/");
      }
      dispatch(updateSelectedPair(symbol));
    }
  }, [pair]);

  return (
    <BannerBox width="100%" height="calc(100vh - 0px)">
      <DepthChart isMobileStatus={true} />
    </BannerBox>
  );
};

export default MobileDepthChart;
