import { Fragment } from "react";
import Select, { components } from "react-select";
import DivElement from "../DivElement";
import { reactDropdownStyles } from "../../../utils";
import { useSelector } from "react-redux";
import { DropdownIndicator } from "../../SelectDropDown/selectDropDown";
import Checkbox from "../Checkbox";
import ImgElement from "../ImgElement";
import config from "../../../constants/config";
import TextWrapper from "../TextWrapper";

const ValueContainer = (props) => {
  const { hasValue, children, selectProps } = props;
  return (
    <components.ValueContainer {...props}>
      {hasValue ? (
        <DivElement flexJustifyBtwAlignCenter width="100%">
          <TextWrapper
            width="calc(100% - 24px)"
            lineHeight="20px"
            textOverflow="ellipsis"
            overflow="hidden"
            nowrap
          >
            {selectProps?.value.map((option, i) => {
              if (i === 0) {
                return option.label;
              } else {
                return `, ${option.label}`;
              }
            })}
          </TextWrapper>
          {children[1]}
          <DivElement
            width="24px"
            height="24px"
            displayCenter
            borderRadius="50%"
            bgBlue
            color="#fff"
            marginLeft="4px"
          >
            {selectProps?.value.length}
          </DivElement>
        </DivElement>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

const customOptionLabel = ({ isDarkTheme, label, quoteCoin }) => {
  return (
    <Fragment>
      <ImgElement
        width="20px"
        height="20px"
        src={`${
          isDarkTheme ? config.darkCoinUrl : config.lightCoinUrl
        }${quoteCoin?.toLowerCase()}.svg`}
        marginLeft="16px"
        marginRight="8px"
      />
      <TextWrapper fontSize="16px" lineHeight="24px">
        {label}
      </TextWrapper>
    </Fragment>
  );
};

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  data,
  ...rest
}) => {
  const style = {
    alignItems: "center",
    color: "inherit",
    display: "flex"
  };

  const props = {
    ...innerProps,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <DivElement flexAlignCenter>
        <Checkbox checked={isSelected} setChecked={() => {}} />
        {children}
      </DivElement>
    </components.Option>
  );
};

const ReactMultiSelectDropdown = ({
  setSelectedOptions,
  options,
  defaultValue = [],
  containerProps,
  placeholder,
  value,
  marginBottom
}) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  return (
    <DivElement {...containerProps} style={marginBottom}>
      <Select
        isMulti
        // menuIsOpen={true}
        value={value || []}
        placeholder={placeholder}
        defaultValue={defaultValue}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={setSelectedOptions}
        options={options}
        formatOptionLabel={(val) => customOptionLabel({ ...val, isDarkTheme })}
        components={{
          DropdownIndicator,
          ValueContainer,
          Option: InputOption
        }}
        styles={reactDropdownStyles({
          isDarkTheme,
          borderBottom: true,
          placeholderProps: { fontSize: "14px" },
          menuProps: { zIndex: "2" },
          showActiveState: value ? true : false
        })}
      />
    </DivElement>
  );
};

export default ReactMultiSelectDropdown;
