import apiConstants from "../../constants/apiConstants";

export const getBeneficiary = () => ({
  type: apiConstants.GET_BENEFICIARY_ALL
});

export const getBeneficiarySingle = (data) => ({
  type: apiConstants.GET_BENEFICIARY_SINGLE,
  payload: data
});

export const addBeneficiary = (data) => ({
  type: apiConstants.ADD_BENEFICIARY_DATA,
  payload: data
});
