import LcxLogo from "../../assets/darkSvg/lcx_logo_dark.svg";
import SunIcon from "../../assets/darkSvg/sun.svg";
import DashboardOpenOrderIconDark from "../../assets/darkSvg/Dashboard-Openorders.svg";
// import CrossIconDark from "../../assets/darkSvg/crossIcon.svg";
import WaveIconDark from "../../assets/darkSvg/dark-layered-peaks-haikei.svg";
import NoRecordIconDark from "../../assets/darkSvg/no_record.svg";
import NoRecordFirstIconDark from "../../assets/darkSvg/no-record-found.svg";
import AvatarIconDark from "../../assets/darkSvg/avatar.svg";
import CameraIconDark from "../../assets/darkSvg/camera.svg";
import CoinIconDark from "../../assets/darkSvg/coins.svg";
import TransferIconDark from "../../assets/darkSvg/transferIcon.svg";
import ArrowIconDark from "../../assets/darkSvg/arrow.svg";
import TradeIconDark from "../../assets/darkSvg/tradeIcon.svg";
import AlertIconDark from "../../assets/darkSvg/alert-triangle.svg";
import WithdrawIconDark from "../../assets/darkSvg/withdrawIcon.svg";
import ArrowDownIconDark from "../../assets/darkSvg/arrow-down.svg";
import ArrowUpIconDark from "../../assets/darkSvg/arrow-up.svg";
import ArrowLeftRightIconDark from "../../assets/darkSvg/arrow-left-right.svg";
import BackIconDark from "../../assets/darkSvg/back.svg";
import CalendarIconDark from "../../assets/darkSvg/calendar.svg";
import UncheckBoxIconDark from "../../assets/darkSvg/uncheckBox.svg";
import CheckCircleIconDark from "../../assets/darkSvg/check-circle.svg";
import SuccessCheckCircleIcon from "../../assets/darkSvg/checkSuccessCircle.svg";
import FillCircleIconDark from "../../assets/darkSvg/check-circle1.svg";
import CheckSquareIconDark from "../../assets/darkSvg/check-square.svg";
import ChevronDownIconDark from "../../assets/darkSvg/chevron-down.svg";
import CrossIconDark from "../../assets/darkSvg/close-icon.svg";
import BlueCopyIconDark from "../../assets/darkSvg/blueCopy.svg";
import GreyCopyIconDark from "../../assets/darkSvg/copyGrey.svg";
import DepositIconDark from "../../assets/darkSvg/depositIcon.svg";
import DeleteIconDark from "../../assets/darkSvg/Delete.svg";
import DeleteBoxIcon from "../../assets/darkSvg/delete-box.svg";
import EditIconDark from "../../assets/darkSvg/Edit.svg";
import EditDisabledIconDark from "../../assets/darkSvg/editDisbaled.svg";
import EyeOnIconDark from "../../assets/darkSvg/eye-on.svg";
import EyeCloseIconDark from "../../assets/darkSvg/eye-close.svg";
import FacebookIcon from "../../assets/darkSvg/facebook.svg";
import FavoriteIconDark from "../../assets/darkSvg/favorite.svg";
import FileTextIconDark from "../../assets/darkSvg/file-text.svg";
import FingerPrintFilledIconDark from "../../assets/darkSvg/fingerPrintFilled.svg";
import FingerPrintIconDark from "../../assets/darkSvg/fingerprint.svg";
import DateIconDark from "../../assets/darkSvg/Date.svg";
import SortingIconDark from "../../assets/darkSvg/sorting.svg";
import CheckFillIconDark from "../../assets/darkSvg/CheckFill.svg";
import HourGlassIconDark from "../../assets/darkSvg/HourGlass.svg";
import InfoIconDark from "../../assets/darkSvg/info.svg";
import LanguageIcon from "../../assets/darkSvg/language.svg";
import LayoutIconDark from "../../assets/darkSvg/layout.svg";
import LcxLogoIconDark from "../../assets/darkSvg/lcx_logo.svg";
import LinkIconDark from "../../assets/darkSvg/link.svg";
import BlueLinkIconDark from "../../assets/darkSvg/blueLink.svg";
import LinkdinIcon from "../../assets/darkSvg/linkdin.svg";
import PasswordIconDark from "../../assets/darkSvg/password.svg";
import MoonIconDark from "../../assets/darkSvg/moon.svg";
import NotificationsIconDark from "../../assets/darkSvg/notifications.svg";
import ThreeDotIconDark from "../../assets/darkSvg/threeDot.svg";
import SearchIconDark from "../../assets/darkSvg/search.svg";
import SignalIconDark from "../../assets/darkSvg/signal.svg";
import TelegramIcon from "../../assets/darkSvg/telegram.svg";
import TwitterIcon from "../../assets/darkSvg/twitter.svg";
import WalletIcon from "../../assets/darkSvg/wallet.svg";
import YoutubeIcon from "../../assets/darkSvg/youtube.svg";
import NotificationIcon from "../../assets/darkSvg/notification.svg";
import HelpIcon from "../../assets/darkSvg/help.svg";
import HamburgerIcon from "../../assets/darkSvg/hamburger.svg";
import SwapIcon from "../../assets/darkSvg/swap.svg";
import NoRecordDarkIcon from "../../assets/darkSvg/no-record-found.svg";
import LockIcon from "../../assets/darkSvg/lock.svg";
import CoinBaseProIcon from "../../assets/darkSvg/Coinbase.png";
import UniswapIcon from "../../assets/darkSvg/uniswap.png";
import LiquidIcon from "../../assets/darkSvg/liquid.svg";
import LCXIcon from "../../assets/darkSvg/lcx.png";
import PlusIcon from "../../assets/darkSvg/plus.svg";
import KycIcon from "../../assets/darkSvg/kyc.svg";
import MailIcon from "../../assets/darkSvg/mail.svg";
import MapPinIcon from "../../assets/darkSvg/map-pin.svg";
import InstagramIcon from "../../assets/darkSvg/instagram.svg";
import ArrowIconGif from "../../assets/gif/dark-arrow.gif";
import AvatarIconGif from "../../assets/gif/dark-avatar.gif";
import CameraIconGif from "../../assets/gif/dark-camera.gif";
import CoinIconGif from "../../assets/gif/dark-coins.gif";
import SettingIcon from "../../assets/darkSvg/setting.svg";
import LogoutIcon from "../../assets/darkSvg/logout.svg";
import ProfileDarkIcon from "../../assets/darkSvg/profile_dark.svg";
import Group343Icon from "../../assets/img/darkGroup343.png";
import TwoFaIcon from "../../assets/darkSvg/twofa.svg";
import FeeDiscountIcon from "../../assets/darkSvg/feeDiscount.svg";
import SnowBoardingIcon from "../../assets/darkSvg/Snowboarding.svg";
import DepositDropDownIcon from "../../assets/darkSvg/depositDrop.svg";
import GraphDropDownIcon from "../../assets/darkSvg/graphDrop.svg";
import TransferDropDownIcon from "../../assets/darkSvg/transferDrop.svg";
import WithdrawDropDownIcon from "../../assets/darkSvg/withdrawDrop.svg";
import HackingIcon from "../../assets/darkSvg/hacking.svg";
import SuccessCheckIcon from "../../assets/darkSvg/successCheck.svg";
import DotIcon from "../../assets/darkSvg/dot.svg";
import DisableDotIcon from "../../assets/darkSvg/disableDot.svg";
import PasswordPageIcon from "../../assets/darkSvg/passwordIcon.svg";
import NotificationBannerIcon from "../../assets/darkSvg/notificationBanner.svg";
import KrakenIcon from "../../assets/darkSvg/kraken.svg";
import DollarSignIcon from "../../assets/darkSvg/dollarSign.svg";
import DollarSignVolumeIcon from "../../assets/darkSvg/dollarSignVolume.svg";
import DollarSignMobileIcon from "../../assets/darkSvg/dollarSignMobile.svg";
import DollarSignBoldIcon from "../../assets/darkSvg/dollarSignDarkBold.svg";
import BagIcon from "../../assets/darkSvg/bag.svg";
import DoubleDownArrowIcon from "../../assets/darkSvg/doubleDownArrow.svg";
import NetworkIcon from "../../assets/darkSvg/networkDark.svg";
import ChatSocketIcon from "../../assets/darkSvg/chatSocketDark.svg";
import NoNetworkIcon from "../../assets/darkSvg/networkErrorDark.svg";
import ChangeLayoutIcon from "../../assets/darkSvg/changeLayoutIcon.svg";
import StarIcon from "../../assets/darkSvg/star_dark.svg";
import MobileAppIcon from "../../assets/darkSvg/mobileImage.svg";
import GoogleAppIcon from "../../assets/darkSvg/googleApp.svg";
import IphoneAppIcon from "../../assets/darkSvg/iphoneApp.svg";
import FiatCardIconDark from "../../assets/darkSvg/fiat_card.svg";
import CoinDCXDark from "../../assets/darkSvg/CoinDCX-Dark.svg";
import MaestroWrappedIconDark from "../../assets/darkSvg/maestro_wrapped.svg";
import MastercardWrappedIconDark from "../../assets/darkSvg/mastercard_wrapped.svg";
import VisacardWrappedIconDark from "../../assets/darkSvg/visa_wrapped.svg";
import NewTagIconDark from "../../assets/darkSvg/new_tag.svg";
import AlertCircleRedIconDark from "../../assets/darkSvg/alert_circle.svg";
import GreenCheckIconDark from "../../assets/darkSvg/green-check.svg";
import LinkBlueIconDark from "../../assets/darkSvg/link_blue.svg";
import Apple from "../../assets/images/apple.svg";
import Windows from "../../assets/images/windows.svg";
import Android from "../../assets/images/android.svg";
import Web_dark from "../../assets/images/pc_dark.svg";
import Phone_dark from "../../assets/images/phone_dark.svg";
import Tablet_dark from "../../assets/images/tablet_dark.svg";
import Apple_dark from "../../assets/images/apple_dark.svg";
import InfoDarkFee from "../../assets/img/fee-dar-info.svg";
import PlusIcondark from "../../assets/darkSvg/greyPlus.svg";
import StarMainIcon from "../../assets/darkSvg/star_main.svg";
import DepositeTransferNew from "../../assets/svg/depositeTransferNew.svg";
import WithdrawTransferNew from "../../assets/svg/WithdrawNewdark.svg";
import TransferNew from "../../assets/svg/TransferNewDark.svg";
import TabTransfer from "../../assets/darkSvg/tabtransfer.svg";
import TabDeposit from "../../assets/darkSvg/Tabdeposit.svg";
import TabWithdraw from "../../assets/darkSvg/Tabwithdraw.svg";
import BlueFacebook from "../../assets/darkSvg/bluefacebook.svg";
import Bluemail from "../../assets/darkSvg/BlueMail.svg";
import Bluetelegram from "../../assets/darkSvg/Bluetelegram.svg";
import BlueLinkNew from "../../assets/darkSvg/blueLink.svg";
import BlueLinkdin from "../../assets/darkSvg/blueLinkdin.svg";
import DarkShareBtn from "../../assets/darkSvg/darksharebtn.svg";
import TimerLink from "../../assets/darkSvg/Timer.svg";
import LeaderBoardDark from "../../assets/images/leaderBoardBlack.jpg";
import TradingmainAni from "../../assets/images/blackanimi.jpg";
import FileIcon from "../../assets/darkSvg/fileIcon.svg";
import BackIcondark from "../../assets/darkSvg/back_dark.svg";
import HammerDarkHome from "../../assets/darkSvg/HammerDark.svg";
import SecurityDark from "../../assets/darkSvg/Securitydark.svg";
import AdvancedTrading from "../../assets/darkSvg/AdvancedTrading.svg";
import DarkWaves from "../../assets/darkSvg/homeBackgroundShapes.svg";
import HammerdarkGif from "../../assets/gif/hammerDark.gif";
import SecurityDarkGif from "../../assets/gif/securitydark.gif";
import AdvancedTradingToolGif from "../../assets/gif/advancedDark.gif";
import IphoneIcondark from "../../assets/darkSvg/Iconicondark.svg";
import OuterBorderDark from "../../assets/darkSvg/outer_border.svg";
import ExclamationCircleRedIconDark from "../../assets/darkSvg/exclamation_circle_red.svg";

const IMAGE_PREFIX_DARK = {
  CoinDCXDark,
  IphoneIcondark,
  HammerdarkGif,
  SecurityDarkGif,
  AdvancedTradingToolGif,
  DarkWaves,
  HammerDarkHome,
  SecurityDark,
  AdvancedTrading,
  DarkShareBtn,
  BackIcondark,
  TradingmainAni,
  LeaderBoardDark,
  TimerLink,
  BlueFacebook,
  Bluemail,
  TabTransfer,
  Bluetelegram,
  BlueLinkdin,
  BlueLinkNew,
  TabDeposit,
  TabWithdraw,
  DepositeTransferNew,
  WithdrawTransferNew,
  TransferNew,
  DashboardOpenOrderIconDark,
  NoRecordIconDark,
  WaveIconDark,
  AvatarIconDark,
  CalendarIconDark,
  CameraIconDark,
  CoinIconDark,
  ArrowIconDark,
  CrossIconDark,
  TradeIconDark,
  UncheckBoxIconDark,
  TransferIconDark,
  DepositIconDark,
  WithdrawIconDark,
  AlertIconDark,
  CheckCircleIconDark,
  ArrowDownIconDark,
  ArrowLeftRightIconDark,
  ArrowUpIconDark,
  BackIconDark,
  CheckSquareIconDark,
  ChevronDownIconDark,
  BlueCopyIconDark,
  GreyCopyIconDark,
  DeleteIconDark,
  EditIconDark,
  EyeOnIconDark,
  EyeCloseIconDark,
  FacebookIcon,
  FavoriteIconDark,
  FileTextIconDark,
  FingerPrintFilledIconDark,
  FingerPrintIconDark,
  DateIconDark,
  SortingIconDark,
  CheckFillIconDark,
  HourGlassIconDark,
  InfoIconDark,
  LanguageIcon,
  LayoutIconDark,
  LcxLogoIconDark,
  LinkIconDark,
  LinkdinIcon,
  PasswordIconDark,
  MoonIconDark,
  NotificationsIconDark,
  ThreeDotIconDark,
  SearchIconDark,
  SignalIconDark,
  TelegramIcon,
  TwitterIcon,
  WalletIcon,
  YoutubeIcon,
  LcxLogo,
  SunIcon,
  NotificationIcon,
  HelpIcon,
  HamburgerIcon,
  SwapIcon,
  NoRecordDarkIcon,
  LockIcon,
  CoinBaseProIcon,
  UniswapIcon,
  LiquidIcon,
  LCXIcon,
  PlusIcon,
  KycIcon,
  BlueLinkIconDark,
  MailIcon,
  MapPinIcon,
  InstagramIcon,
  ArrowIconGif,
  AvatarIconGif,
  CameraIconGif,
  CoinIconGif,
  FillCircleIconDark,
  NoRecordFirstIconDark,
  SettingIcon,
  LogoutIcon,
  ProfileDarkIcon,
  FileIcon,
  Group343Icon,
  TwoFaIcon,
  FeeDiscountIcon,
  SnowBoardingIcon,
  DeleteBoxIcon,
  DepositDropDownIcon,
  GraphDropDownIcon,
  TransferDropDownIcon,
  WithdrawDropDownIcon,
  HackingIcon,
  SuccessCheckIcon,
  DotIcon,
  DisableDotIcon,
  PasswordPageIcon,
  NotificationBannerIcon,
  KrakenIcon,
  DollarSignIcon,
  DollarSignMobileIcon,
  DollarSignVolumeIcon,
  DollarSignBoldIcon,
  BagIcon,
  EditDisabledIconDark,
  DoubleDownArrowIcon,
  NetworkIcon,
  ChatSocketIcon,
  NoNetworkIcon,
  ChangeLayoutIcon,
  StarIcon,
  MobileAppIcon,
  GoogleAppIcon,
  IphoneAppIcon,
  FiatCardIconDark,
  SuccessCheckCircleIcon,
  MaestroWrappedIconDark,
  MastercardWrappedIconDark,
  VisacardWrappedIconDark,
  NewTagIconDark,
  AlertCircleRedIconDark,
  GreenCheckIconDark,
  LinkBlueIconDark,
  Apple,
  Windows,
  Android,
  Web_dark,
  Phone_dark,
  Tablet_dark,
  Apple_dark,
  InfoDarkFee,
  PlusIcondark,
  StarMainIcon,
  OuterBorderDark,
  ExclamationCircleRedIconDark
};

export { IMAGE_PREFIX_DARK };
