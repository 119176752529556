import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import TextWrapper from "../TextWrapper";
import DivElement from "../DivElement";
import { UploadBlueIcon, CheckCircleIcon } from "../../../assets/svgs";
import { LoadingButton } from "../../../components/Loader";

const UploadBox = ({ files, setFiles, accept = {}, maxFileSize, isLoading, setIsLoading }) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      setIsLoading(true);
      const newFiles = acceptedFiles.map((file, index) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
      });
      let fakeProgress = 0;

      const interval = setInterval(() => {
        fakeProgress += 15;
        setUploadProgress(fakeProgress);
        if (fakeProgress >= 100) {
          clearInterval(interval);
          setFiles(newFiles);
          setIsLoading(false);
        }
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (uploadProgress >= 100) {
      setUploadProgress(0);
    }
  }, [uploadProgress]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: accept,
    maxSize: maxFileSize
  });

  const showErrorMessages = () => {
    const { message, code } = fileRejections[0]?.errors[0] || {};
    if (code && code === "file-invalid-type") {
      const allowedExtensions = [];
      Object.values(accept).forEach((val) => {
        allowedExtensions.push(...val);
      });

      const extensionsList = allowedExtensions.join(", ").replace(/, ([^,]*)$/, ", or $1");
      return (
        <TextWrapper fontSize="12px" red marginTop="5px">
          Invalid file type. Please upload {extensionsList} file only.
        </TextWrapper>
      );
    } else if (code && code === "file-too-large") {
      return (
        <TextWrapper fontSize="12px" red marginTop="5px">
          {message.replace(`${maxFileSize} bytes`, `${Math.ceil(maxFileSize / (1024 * 1024))} MB`)}
        </TextWrapper>
      );
    }

    return <></>;
  };

  return (
    <div>
      <DivElement
        borderBlue
        flex
        alignItems="center"
        flexDirection="column"
        isDarkTheme
        padding="12px 16px"
        borderRadius="8px"
        cursorDragAndDrop
        isDisabled={isLoading}
        {...getRootProps()}
      >
        <input {...getInputProps()} disabled={isLoading} />

        {isLoading ? (
          <DivElement height="40px" flex justifyContent="center" alignItems="center">
            <TextWrapper blue>
              <strong>
                Uploading..... {uploadProgress}% <LoadingButton blue width="50" height="10" />
              </strong>
            </TextWrapper>
          </DivElement>
        ) : (
          <>
            <DivElement flex fontSize="14px">
              <UploadBlueIcon height={18} />
              <TextWrapper display="inline" blue fontWeight="500">
                Click to upload
              </TextWrapper>
              <TextWrapper display="inline" marginLeft="3px" fontWeight="500">
                or drag and drop
              </TextWrapper>
            </DivElement>
            <TextWrapper fontWeight="500" display="inline" fontSize="12px" marginTop="10px">
              Note - JPG, JPEG, PDF less than {Math.ceil(maxFileSize / (1024 * 1024))} MB.
            </TextWrapper>
          </>
        )}
      </DivElement>
      {!!files.length && (
        <TextWrapper fontSize="12px" green marginTop="5px">
          <CheckCircleIcon fillGreen height="10px" />
          {files[0]?.name} Uploaded Successfully
        </TextWrapper>
      )}
      {showErrorMessages()}
    </div>
  );
};

export default UploadBox;
