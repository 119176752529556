/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
// import Datetime from "react-datetime";
import moment from "moment";

import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { EditCalendarIcon } from "../../../assets/svgs";
import DatePickerComponent from "../../../components/DatePicker/DatePicker";

const FilterEarningOverview = ({ onApplyFilterHandler }) => {
  const [date, setDate] = useState({ fromDate: null, toDate: null });
  const [selectedCoins, setSelectedCoins] = useState("");

  const clearAll = () => {
    setDate({ fromDate: "", toDate: "" });
    setSelectedCoins("");
    onApplyFilterHandler({
      Detailed: false,
      Offset: 1
    });
  };

  useEffect(() => {
    if (date.fromDate && date.toDate) applyFilter();
  }, [date]);

  const applyFilter = () => {
    let filter = {
      Detailed: false,
      Offset: 1
    };

    if (date.fromDate && date.toDate) {
      filter.From = moment(date.fromDate).format("DD-MM-YYYY");
      filter.To = moment(date.toDate).format("DD-MM-YYYY");
    }
    if (Object.keys(filter).length >= 2) {
      onApplyFilterHandler(filter);
    }
  };

  return (
    <BannerBox width="100%" flexWrap="wrap">
      <BannerBox display="flex" width="100%" justifyContent="flex-end">
        {((selectedCoins && selectedCoins.length > 0) || date.fromDate || date.toDate) && (
          <ParaTag size="14px" txtDanger cursor="pointer" onClick={clearAll}>
            Clear All
          </ParaTag>
        )}
      </BannerBox>

      <BannerBox
        display="flex"
        justifyContent="flex-end"
        gap="24px"
        alignItems="center"
        flexWrap="wrap"
        flexWrapMd="nowrap"
        flexWrapLg="nowrap"
        style={{ flexWrap: "nowrap" }}
      >
        <BannerBox
          mtMd={"0px"}
          position="relative"
          CardLevel3OnlyBackground
          InputBottomBorder
          borderWidth="2px"
          radius="5px 5px 0 0"
          mt={"6px"}
          mx="0"
          height="44px"
          top="6px"
          topMd="0px"
          widthLg="200px"
          widthMd="48%"
        >
          <DatePickerComponent
            quickFilterStatus="true"
            givenDate={date}
            selectedDate={date.fromDate}
            setQuickFilterValue={({ fromDate, toDate }) => {
              setDate({ fromDate, toDate });
            }}
            name="From"
          />

          <BannerBox
            position="absolute"
            top="0"
            right="16px"
            zIndex="1"
            height="100%"
            display="flex"
            alignItems="center"
          >
            <EditCalendarIcon />
          </BannerBox>
        </BannerBox>

        <BannerBox
          position="relative"
          CardLevel3OnlyBackground
          radius="5px 5px 0 0"
          mt={"8px"}
          mtMd={"0px"}
          height="44px"
          widthLg="200px"
          widthMd="48%"
        >
          <DatePickerComponent
            quickFilterStatus="true"
            givenDate={date}
            selectedDate={date.toDate}
            setQuickFilterValue={({ fromDate, toDate }) => setDate({ fromDate, toDate })}
            name="To"
          />
          <BannerBox
            position="absolute"
            top="0"
            right="16px"
            zIndex="1"
            height="100%"
            display="flex"
            alignItems="center"
          >
            <EditCalendarIcon />
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
};

export default FilterEarningOverview;
