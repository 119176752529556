import React from "react";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../../assets/StyleComponents/FontSize/headingFourth.style";
import { AnchorTag } from "../../../assets/StyleComponents/FontSize/para.style";
import {
  WorksReferal1Illustration,
  WorksReferalIllustration2,
  WorksReferalIllustration3
} from "../../../assets/svgs";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import styled from "styled-components";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";

const Verticalline = styled.div`
  border-left: 1px solid ${(props) => props.theme.inputBorder};
  height: 50px;
  margin: 0 auto;
  margin-left: 23px;
`;

function HowItWorks() {
  const { width } = useWindowSize();
  const Data = [
    {
      text: "Share your referral code, poster, link, or QR code with your friends",
      image: (
        <WorksReferal1Illustration
          width="120px"
          height="120px"
          widthMd="56px"
          heightMd="56px"
          widthSm="40px"
          heightSm="40px"
        />
      )
    },
    {
      text: "Your friends sign up with your referral code and make trades",
      image: (
        <WorksReferalIllustration2
          width="120px"
          height="120px"
          widthMd="56px"
          heightMd="56px"
          widthSm="40px"
          heightSm="40px"
        />
      )
    },
    {
      text: "You will receive referral commissions the next day.",
      image: (
        <WorksReferalIllustration3
          width="120px"
          height="120px"
          widthMd="56px"
          heightMd="56px"
          widthSm="40px"
          heightSm="40px"
        />
      )
    }
  ];
  return (
    <>
      <BannerBox mt="32px" pb="32px" bottomBorder>
        <BannerBox display="flex" justifyContent="space-between" mb="40px">
          <HeadingFourth size="24px" weight="500" sizeLg="20px" sizeMd="16px">
            How it Works
          </HeadingFourth>
          <AnchorTag
            size="16px"
            weight="500"
            sizeMd="12px"
            txtPrimary
            cursor="pointer"
            href="https://www.lcx.com/info/lcx-referral-program/"
            textDecoration="underline"
            target="_blank"
          >
            Refer FAQ's
          </AnchorTag>
        </BannerBox>
        <BannerBox
          display="grid"
          gridColumn="repeat(3,1fr)"
          gridColumnMd="1fr"
          gridTemplateColums
          gap="35px"
          gapMd="0px"
          justifyContent="center"
          alignItems="center"
        >
          {Data.map((values, index) => {
            return (
              <>
                <BannerBox
                  display="flex"
                  direction="column"
                  directionMd="row"
                  gap="24px"
                  gapMd="5px"
                  justifyContent="center"
                  alignItems="center"
                  alignItemsMd="start"
                >
                  <BannerBox>
                    {values.image}
                    {width < 767 && index !== Data.length - 1 && <Verticalline />}
                  </BannerBox>
                  <BannerHeading size="18px" weight="400" textAlign="center" txtGrey sizeLg="14px">
                    {values.text}
                  </BannerHeading>
                </BannerBox>
              </>
            );
          })}
        </BannerBox>
      </BannerBox>
    </>
  );
}

export default HowItWorks;
