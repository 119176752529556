/* eslint-disable no-unused-vars */
import { useState, useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { object } from "prop-types";

import { filterPairListByQuote, filterUSDVolume } from "../../utils/general";
import { TradeSocketContext } from "../../components/Socket/Public/tradeSocket";
import { createStructuredSelector } from "reselect";
import { getAllTickerData } from "../../Modules/Trades/MarketData/selectors";
import { getMarketLists } from "../../lib/api";
import { callAllTickerMarket } from "../../Modules/Trades/MarketData/action";
import { updateFavPiarsWatchlist } from "../../actions/marketLists";
import { filterPairListByCategory } from "../../utils/general";

const useMarketData = (search, watchList) => {
  const [marketData, setMarketDataRes] = useState([]);
  const [filterSearch, setFilterSearchRes] = useState("");
  const [filter_top_quote_pair, setFilterTopQuotePairRes] = useState("ALL");
  const [favList, setFavListRes] = useState(JSON.parse(localStorage.getItem("fav")) || {});
  const [sort, setSortRes] = useState(null);
  const [filter_Category_data, setFilterCategoryData] = useState("All");

  const location = useLocation();
  const dispatch = useDispatch();

  let { apiAllTickerData } = useSelector(mapStateToProps);

  const isFavWatchList = useSelector((state) => state.watchList.isFavWatchList);

  const setFilterQuotePair = useCallback((props) => setFilterTopQuotePairRes(props), []);
  const setFilterSearch = useCallback((props) => setFilterSearchRes(props), []);
  const setFavList = useCallback((props) => setFavListRes(props), []);
  const setSort = useCallback((props) => setSortRes(props), []);

  // let contextValue = useContext(TradeSocketConnection);
  let contextValue = useContext(TradeSocketContext);
  // let { all_ticker_data } = contextValue || {};
  let { allTickerData } = contextValue || {};

  const upadteSortData = useMemo(() => {
    const SortData = (type) => {
      let sortCopy = sort;
      let sortType = "";
      if (
        !sortCopy ||
        sortCopy.type === "asc" ||
        (sortCopy.type === "asc" && sortCopy.value === type)
      )
        sortType = "desc";
      else sortType = "asc";
      setSortRes({
        value: type,
        type: sortType
      });
      setMarketDataRes(
        handleSocketData(allTickerData || apiAllTickerData || [], apiAllTickerData, false)
      );
    };
    return SortData;
  }, [sort]);

  const sortData = useMemo(() => {
    const SortDataRes = (array) => {
      let sortCopy = sort;
      if (sortCopy) {
        array = array.sort((a, b) => {
          if (sortCopy.type === "asc") {
            if (sortCopy.value === "symbol") {
              if (a[sortCopy.value] < b[sortCopy.value]) return -1;
              if (a[sortCopy.value] > b[sortCopy.value]) return 1;
              return 0;
            } else {
              return a[sortCopy.value] - b[sortCopy.value];
            }
          } else {
            if (sortCopy.value === "symbol") {
              if (a[sortCopy.value] < b[sortCopy.value]) return 1;
              if (a[sortCopy.value] > b[sortCopy.value]) return -1;
              return 0;
            } else {
              return b[sortCopy.value] - a[sortCopy.value];
            }
          }
        });
      }
      return array;
    };
    return SortDataRes;
  }, [marketData, sort]);

  const filterQuotePairData = useMemo(() => {
    const filterQuoteData = (pairList) => {
      let response;
      let array = [];
      if (filter_top_quote_pair) {
        let favPairs = JSON.parse(localStorage.getItem("fav")) || {};

        if (filter_top_quote_pair === "favorite") {
          response = pairList.filter((coin_pair) => {
            if (favPairs[coin_pair.symbol]) {
              return coin_pair;
            }
          });
        } else if (filter_top_quote_pair === "ALL") {
          response = filterPairListByCategory(filter_Category_data, pairList);
        } else {
          response = filterPairListByQuote(filter_top_quote_pair, pairList, filter_Category_data);
        }

        let filterSearch = search?.toUpperCase();

        for (let i = 0; i < response.length; i++) {
          let symbol = response[i].symbol?.toUpperCase();
          let baseFullName = response[i].baseFullName?.toUpperCase();
          let quoteFullName = response[i].quoteFullName?.toUpperCase();
          let concatenatedSymbol = symbol.split("/").join("");
          if (
            !filterSearch ||
            (filterSearch &&
              (concatenatedSymbol?.includes(filterSearch) ||
                symbol?.includes(filterSearch) ||
                baseFullName?.includes(filterSearch) ||
                quoteFullName?.includes(filterSearch)))
          ) {
            response[i]["fav"] = favPairs[symbol] ? true : false;
            array.push(response[i]);
          }
        }
      }
      return array;
    };

    return filterQuoteData;
  }, [search, filter_top_quote_pair, sort, filter_Category_data]);

  const handleSocketData = useMemo(() => {
    const processSocketData = (obj, apiAllTickerData, allData) => {
      let array = Object.values(obj);
      let length = array.length;
      for (let i = 0; i < length; i++) {
        array[i]["quote_pair"] = array[i].symbol.split("/")[1];
        array[i]["base_pair"] = array[i].symbol.split("/")[0];
      }
      if (!array.length) return filterUSDVolume(apiAllTickerData);
      else if (allData) return array;
      else {
        array = filterQuotePairData(array);
        array = filterUSDVolume(array);
        return sortData(array);
      }
    };

    return processSocketData;
  }, [apiAllTickerData, filterQuotePairData, sortData, sort]);

  const changefav = (data) => {
    let active = JSON.parse(localStorage.getItem("fav")) || {};
    if (active[data.symbol]) delete active[data.symbol];
    else active[data.symbol] = true;
    localStorage.setItem("fav", JSON.stringify(active));

    watchList(active);
    setFavListRes(active);
    dispatch(updateFavPiarsWatchlist(active));
    setMarketDataRes(
      handleSocketData(allTickerData || apiAllTickerData || [], apiAllTickerData, false)
    );
  };

  useEffect(() => {
    const handleMarketSocketData = () => {
      setMarketDataRes(
        handleSocketData(allTickerData || apiAllTickerData || [], apiAllTickerData, false)
      );
    };

    handleMarketSocketData();
    return () => handleMarketSocketData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue]);

  useEffect(() => {
    setMarketDataRes(
      handleSocketData(allTickerData || apiAllTickerData || [], apiAllTickerData, false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_top_quote_pair]);

  useEffect(() => {
    setMarketDataRes(
      handleSocketData(allTickerData || apiAllTickerData || [], apiAllTickerData, false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_Category_data]);

  useEffect(() => {
    setMarketDataRes(
      handleSocketData(allTickerData || apiAllTickerData || [], apiAllTickerData, false)
    );
  }, [filterSearch]);

  useEffect(() => {
    setMarketDataRes(
      handleSocketData(allTickerData || apiAllTickerData || [], apiAllTickerData, false)
    );
  }, [isFavWatchList]);

  useEffect(() => {
    setMarketDataRes(
      handleSocketData(allTickerData || apiAllTickerData || [], apiAllTickerData, false)
    );
  }, [sort]);

  useEffect(() => {
    if (!location.pathname.includes("/trade")) {
      setMarketDataRes(handleSocketData(apiAllTickerData, allTickerData, false));
    }
  }, [apiAllTickerData]);

  return useMemo(() => {
    return {
      marketData,
      setFilterQuotePair,
      setFilterSearch,
      setFilterCategoryData,
      setFavList,
      favList,
      changefav,
      upadteSortData
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketData, setFilterQuotePair, setFilterSearch, changefav]);
};

useMarketData.propTypes = {
  defaultActiveKey: object.isRequired
};

const mapStateToProps = createStructuredSelector({
  apiAllTickerData: getAllTickerData()
});

export default useMarketData;
