export const UPDATE_TRADE_SELECTED_TAB = 
  "src/trade/orderRecords/UPDATE_TRADE_SELECTED_TAB";

export const UPDATE_CHECK_TRADING_PAIR = 
  "src/trade/orderRecords/UPDATE_CHECK_TRADING_PAIR";

export const UPDATE_CHECK_OPEN_ORDERS = 
  "src/trade/orderRecords/UPDATE_CHECK_OPEN_ORDERS";

export const UPDATE_HISTORY_FILTER_SLAB = 
  "src/trade/orderRecords/UPDATE_HISTORY_FILTER_SLAB";

export const UPDATE_ORDERS_OFFSET = 
  "src/trade/orderRecords/UPDATE_ORDERS_OFFSET";

export const UPDATE_TAB_HISTORY_TAB = 
  "src/trade/orderRecords/UPDATE_TAB_HISTORY_TAB";

export const UPDATE_MOBILE_HISTORY_TAB = 
  "src/trade/orderRecords/UPDATE_MOBILE_HISTORY_TAB";

export const UPDATE_ORDER_BALANCE_SLAB = 
  "src/trade/orderRecords/UPDATE_ORDER_BALANCE_SLAB";