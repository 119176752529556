/* eslint-disable no-console */
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from 'react-router-dom';
import { SpanElement } from "../../Reusable";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { CircleCheckIcon, CloseIcon } from "../../../assets/svgs";
import { removeApproveText } from "../../../lib/api";
import { debounce } from "../../../utils/helpers";
import { getUserDetails } from "../../../actions/dashboard";

const LevelThreeNotice = () => {
  const [showPage, setShowPage] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [notice, setNotice] = useState("");
  let userData = useSelector((state) => state.dashboard.getUserData);
  let dispatch = useDispatch();
  let { KYC } = userData;
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (KYC && KYC.transactionRiskInfo && KYC.transactionRiskInfo.status) {
      // let lastDateDeadline = new Date(KYC.transactionRiskInfo.deadLine) || "";
      // let todayDate = new Date();
      if (KYC.transactionRiskInfo.content) {
        setNotice(KYC.transactionRiskInfo.content);
      }
      if (KYC.transactionRiskInfo.status) {
        if (KYC.transactionRiskInfo.status === "NOT_REQUIRED") {
          setCurrentStatus(KYC.transactionRiskInfo.status);
          setShowPage(false);
        } else if (
          KYC.transactionRiskInfo.status === "APPROVED" &&
          !KYC.transactionRiskInfo.content
        ) {
          setCurrentStatus(KYC.transactionRiskInfo.status);
          setShowPage(false);
        } else {
          setCurrentStatus(KYC.transactionRiskInfo.status);
          setShowPage(true);
        }
      }
    }
  }, []);

  const closeApprovedStatus = debounce(() => {
    removeApproveText({ display: false }).then((res) => {
      dispatch(getUserDetails());
      setShowPage(false);
    });
  }, 800);

  if (!showPage) return null;
  return (
    <Fragment>
      
      <BannerBox
        PrimaryBorderCard={currentStatus === "REQUESTED" ? true : null}
        DangerBorderCard={
          currentStatus === "REJECTED" || currentStatus === "PARTIALLY_REJECTED" ? true : null
        }
        WarningBorderCard={
          currentStatus === "RESUBMITTED" || currentStatus === "SUBMITTED" ? true : null
        }
        SuccessBorderCard={currentStatus === "APPROVED" ? true : null}
        radius="10px"
        display="flex"
        flexWrapMd={currentStatus === "APPROVED" ? "nowrap" : "wrap"}
        flexFill="1 1 100% "
        alignItems="center"
        mb="24px"
        padding="10px 16px"
        mtMd="10px"
      >
        <BannerBox mtMd="10px">
          {/* <BannerHeadingSpan
            display="flex"
            alignItems="center"
            width="10%"
            gap="5px"
            heightMd="10px"
          > */}
          {currentStatus === "APPROVED" && (
            <BannerHeadingSpan mt="5px">
              <CircleCheckIcon width="20px" fillGreen height="16px" />
            </BannerHeadingSpan>
          )}
          <SpanElement
            fontSize="18px"
            red={currentStatus !== "APPROVED" ? true : null}
            green={currentStatus === "APPROVED" ? true : null}
          >
            {notice.alertType}: &nbsp;
          </SpanElement>
          {/* </BannerHeadingSpan> */}

          {currentStatus && (
            <BannerHeadingSpan
              dangerouslySetInnerHTML={{ __html: notice.alertText || "" }}
              BodyText={
                currentStatus !== "RESUBMITTED" && currentStatus !== "SUBMITTED" ? true : null
              }
              WarningBodyText={
                currentStatus === "RESUBMITTED" || currentStatus === "SUBMITTED" ? true : null
              }
              size="16px"
            />
          )}
        </BannerBox>

        {notice.buttonText && (
          <BannerBox width="204px" mtMd="10px" padding="0 0 0 16px">
            <Link to={`${prevLang ? `/${prevLang}`: "/en"}/verify/verification-plus`}>
              <ButtonComponent PrimaryButton btnHeight="48px" padd="12px 16px">
                {notice.buttonText}
              </ButtonComponent>
            </Link>
          </BannerBox>
        )}

        {currentStatus === "APPROVED" && (
          <BannerBox onClick={closeApprovedStatus}>
            <CloseIcon width="24px" />
          </BannerBox>
        )}
      </BannerBox>
    </Fragment>
  );
};

export default LevelThreeNotice;
