/**
 * Import Other Modules
 */
import apiConstants from "../constants/apiConstants";

/**
 *
 *  @param { Setup 2Fa Api Calling } action
 */
export const setup2FA = (data) => ({
  type: apiConstants.SETUP_2FA,
  payload: data
});

/**
 *
 *  @param { Generate @Auth token Api Calling } action
 */
export const generate2AuthToken = () => ({
  type: apiConstants.GENERATE_TWO_AUTH_TOKEN
});

/**
 *
 *  @param { Generate @Auth token Api Calling } action
 */
export const changePassword = (user) => ({
  type: apiConstants.CHANGE_PASSWORD,
  payload: user
});

/**
 *
 *  @param { Generate @Auth token Api Calling } action
 */
export const clearUserErrors = () => ({
  type: apiConstants.CLEAR_USER_ERRORS
});

/**
 *
 *  @param { Generate @Auth token Api Calling } action
 */
export const getApiKeyManagment = (params) => ({
  type: apiConstants.GET_API_LIST,
  payload: params
});

/**
 *
 *  @param { Generate FEE LIST } action
 */
export const getFeesList = () => ({
  type: apiConstants.GET_FEES_LIST
});

/**
 *
 *  @param { Generate @Auth token Api Calling } action
 */
export const updateFeeList = (params) => ({
  type: apiConstants.UPDATE_FEES_LIST,
  payload: params
});

// /**
//  *
//  *  @param { Generate @Auth token Api Calling } action
//  */
// export const getTutorialsData = () => ({
//   type: apiConstants.GET_ACTIVESESSIONS_CODE
//   // payload: params
// });

/**
 *
 *  @param { Get Active Sessions } action
 */
export const getActiveSessions = () => ({
  type: apiConstants.GET_ACTIVESESSIONS_CODE
  // payload: params
});
/**
 *
 *  @param { Logout from Particular Device } action
 */
export const logoutfromdevice = (params) => ({
  type: apiConstants.UPDATE_ACTIVESESSIONS_CODE,
  payload: params
});
/**
 *
 *  @param { Get Order Fees } action
 */
export const getOrderFees = () => ({
  type: apiConstants.GET_ORDER_FEES
});

/**
 *
 *  @param { Post Address Update data } action
 */
export const postAddressUpdate = (payload) => ({
  type: apiConstants.POST_USER_ADDRESS_UPDATE_REQUEST,
  payload
});

/**
 *
 *  @param { Reset Address Update state } action
 */
export const resetAddressUpdate = () => ({
  type: apiConstants.RESET_USER_ADDRESS_UPDATE_STATE
});
