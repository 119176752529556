import React, { useEffect, useState } from "react";
import { DepthChart as Chart } from "pennant";
import { useSelector } from "react-redux";

const defaultData = {
  buy: [{ price: 0, volume: 0 }],
  sell: [{ price: 0, volume: 0 }]
};

const formatOrdersData = (orders) => {
  orders = orders.filter((order) => order[1] !== 0);
  orders.sort((a, b) => a[0] - b[0]);
  let data = orders.map((item) => {
    return { price: item[0], volume: item[1] };
  });

  return data;
};

const DepthChart = ({ isMobileStatus = false }) => {
  const [buyOrderData, setBuyOrderData] = useState([]);
  const [sellOrderData, setSellOrderData] = useState([]);
  const [chartData, setChartData] = useState(defaultData);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const allBuyOrders = useSelector((state) => state.trading.allBuyOrders);
  const allSellOrders = useSelector((state) => state.trading.allSellOrders);

  useEffect(() => {
    if (buyOrderData && sellOrderData && buyOrderData.length === 0 && sellOrderData.length === 0) {
      setChartData(defaultData);
    } else {
      let data = {
        buy: buyOrderData,
        sell: sellOrderData
      };
      setChartData(data);
    }
  }, [buyOrderData, sellOrderData]);

  useEffect(() => {
    let list = formatOrdersData(allBuyOrders);
    setBuyOrderData(list);
  }, [allBuyOrders]);

  useEffect(() => {
    let list = formatOrdersData(allSellOrders);
    setSellOrderData(list);
  }, [allSellOrders]);

  return <Chart id={isMobileStatus ? "activeDepthChart" : ""} data={chartData} theme={isDarkTheme ? "dark" : "light"} />;
};

export default DepthChart;
