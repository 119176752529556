import React from "react";
import DivElement from "../DivElement";
import styled, { css } from "styled-components";
import TextWrapper from "../TextWrapper";
import { NoRecordIllustration } from "../../../assets/svgs/illustrations";

const NoRecordContainer = styled(DivElement)`
  svg {
    width: 48.72px;
    height: 48px;
    @media (max-width: 1024px) {
      width: 32.48px;
      height: 32px;
    }
    @media (max-width: 767px) {
      width: 24.36px;
      height: 24px;
    }

    ${(props) =>
      props.tokenSale &&
      css`
        width: 150.21px;
        height: 148px;
        @media (max-width: 1024px) {
          width: 121.79px;
          height: 120px;
        }
        @media (max-width: 767px) {
          width: 56.84px;
          height: 56px;
        }
      `}
  }
`;

const TableNoRecord = ({ message, tokenSale, containerProps }) => {
  return (
    <DivElement
      width="100%"
      displayCenter
      margin="20px 0"
      paddingTop={tokenSale ? "100px" : ""}
      {...containerProps}
    >
      <NoRecordContainer column alignItems="center" tokenSale={tokenSale}>
        <NoRecordIllustration />
        <TextWrapper fontSize="14px" fontSizeSm="12px" secondary>
          {message || "No Records found."}
        </TextWrapper>
      </NoRecordContainer>
    </DivElement>
  );
};

export default TableNoRecord;
