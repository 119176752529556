import { css } from "styled-components";
import { createResponsiveStyles } from "../GlobalStyle/DynamicResponsiveDesign/DynamicResponsiveDesign.style";

const CommanHeightStyle = css`
  ${({ height, heightXl, heightLg, heightMd, heightSm }) => (height || heightXl || heightLg || heightMd || heightSm) && 
    createResponsiveStyles("height", "height", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? `${height} !important` : `${value} !important` : `${height} !important`
    )
  }
  
  ${({ minHeight, minHeightXl, minHeightLg, minHeightMd, minHeightSm }) => (minHeight || minHeightXl || minHeightLg || minHeightMd || minHeightSm) && 
    createResponsiveStyles("minHeight", "min-height", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : minHeight 
    )
  }

  ${({ maxHeight, maxHeightXl, maxHeightLg, maxHeightMd, maxHeightSm }) => (maxHeight || maxHeightXl || maxHeightLg || maxHeightMd || maxHeightSm) && 
    createResponsiveStyles("maxHeight", "max-height", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : maxHeight 
    )
  }
`;

export { CommanHeightStyle };