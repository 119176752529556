import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { getNotificationAlert, updateNotificationAlert } from "../../../lib/api";
import { formatAPIErrors } from "../../../utils/general";
import { debounce, setLocalStorage } from "../../../utils/helpers";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { ChevronRightIcon } from "../../../assets/svgs";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import InputSwitch from "../../../components/Reusable/InputSwitch";

const NotificationComponent = () => {
  const { width } = useWindowSize();
  const [alerts, setAlerts] = useState();
  const [alertsLoading, setIsAlertLoading] = useState();

  useEffect(() => {
    getNotification(true);
  }, []);

  const getNotification = (showLoading) => {
    if (showLoading) setIsAlertLoading(true);
    getNotificationAlert()
      .then((res) => {
        if (res.data.data.hiddenToast) {
          setLocalStorage(true, "hiddenToast");
        } else {
          localStorage.removeItem("hiddenToast");
        }
        setAlerts(res.data.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      })
      .finally(() => {
        setIsAlertLoading(false);
      });
  };

  const updateNotification = debounce((type, value) => {
    let params = {};
    if (type === "email") params.email = value;
    if (type === "push") params.push = value;
    if (type === "newsLetter") params.newsLetter = value;
    if (type === "hiddenToast") {
      params.hiddenToast = value;
      if (params.hiddenToast) {
        setLocalStorage(true, "hiddenToast");
      } else {
        localStorage.removeItem("hiddenToast");
      }
    }
    setAlerts({ ...alerts, ...params });
    updateNotificationAlert(params)
      .then((res) => {
        showSuccessMsg(res.data.message, { autoClose: 7000 });
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      });
  }, 1000);

  const prevLang = localStorage.getItem("ljs-lang");
  
  // const { isLoader, maker, taker, feeCheck, handleCheckBox, getUserData } = props;
  return (
    <>
      <BannerBox
        flex="0 0 33.33%"
        flexLg="1"
        height="264px"
        order="3"
        mt="1rem"
        paddRight="8px"
        display={width > 1024 ? "block" : "none"}
      >
        {alertsLoading && (
          <SkeletonLoader
            width={"100%"}
            height={"264px"}
            // givenClass={"d-flex flex-column"}
            style={{ minHeight: "264px" }}
          />
        )}

        {!alertsLoading && (
          <BannerBox
            height="264px"
            width="100%"
            borderRadius="10px"
            DashboardBordersdark
            CardWhite
            padding="0.8rem 16px"
          >
            <BannerBox display="flex" justifyContent="space-between" mb="15px" mt="5px">
              <BannerHeading BodyColor weight="500" mb="0px" size="1.125rem">
                Notification Alert
              </BannerHeading>

              <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/notification`}>
                <ChevronRightIcon />
              </Link>
            </BannerBox>

            <BannerBox display="Flex" direction="column" gap="15px">
              <BannerBox display="flex" margin="0px" width="100%" justifyContent="space-between">
                <BannerBox width="80%" display="flex" direction="column">
                  <BannerHeading BodyColor weight="500" mb="0px" size="16px">
                    Push Notifications
                  </BannerHeading>
                  <ParaTag size="14px" weight="300" txtGrey>
                    Enable to receive notifications
                  </ParaTag>
                </BannerBox>

                <InputSwitch
                  checked={alerts?.push}
                  setChecked={() => {
                    setAlerts(alerts?.push === true ? true : false);
                    updateNotification("push", !alerts?.push);
                  }}
                  disabled={alertsLoading ? true : false}
                />
              </BannerBox>

              <BannerBox display="flex" margin="0px" width="100%" justifyContent="space-between">
                <BannerBox width="80%" display="flex" direction="column">
                  <BannerHeading BodyColor weight="500" mb="0px" size="16px">
                    Email Alerts
                  </BannerHeading>
                  <ParaTag size="14px" weight="300" txtGrey>
                    Activate prompt trade notifications via email.
                  </ParaTag>
                </BannerBox>

                <InputSwitch
                  checked={alerts?.email}
                  setChecked={() => {
                    setAlerts(alerts?.email === true ? true : false);
                    updateNotification("email", !alerts?.email);
                  }}
                  disabled={alertsLoading ? true : false}
                />
              </BannerBox>

              <BannerBox display="flex" margin="0px" width="100%" justifyContent="space-between">
                <BannerBox width="80%" display="flex" direction="column">
                  <BannerHeading BodyColor weight="500" mb="0px" size="16px">
                    Newsletter
                  </BannerHeading>
                  <ParaTag size="14px" weight="300" txtGrey>
                    Enable to get LCX and Crypto insights.
                  </ParaTag>
                </BannerBox>

                <InputSwitch
                  checked={alerts?.newsLetter}
                  setChecked={() => {
                    setAlerts(alerts?.newsLetter === true ? true : false);
                    updateNotification("newsLetter", !alerts?.newsLetter);
                  }}
                  disabled={alertsLoading ? true : false}
                />
              </BannerBox>
            </BannerBox>
          </BannerBox>
        )}
      </BannerBox>
    </>
  );
};

export default memo(NotificationComponent);
