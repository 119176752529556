/**
 * Import Other Modules
 */
import apiConstants from "../constants/apiConstants";

/**
 *
 *  @param {Get User Details} action
 */
export function getUserDetails() {
  return {
    type: apiConstants.GET_USER_DETAILS_DATA
  };
}

/**
 *
 *  @param { Get Wallet Balance Api Calling } action
 */
export function getWalletBalance() {
  return {
    type: apiConstants.GET_WALLET_BALANCE_DATA
  };
}

/**
 *
 *  @param { Get Recent Activity Api Calling } action
 */
export function getRecentActivity(data) {
  return {
    type: apiConstants.GET_RECENT_ACTIVITY_DATA,
    payload: data
  };
}
