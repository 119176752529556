/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
/**
 * Import Node Modules
 */

import React, { useState, useEffect, useMemo } from "react";
import { withRouter, Redirect, useLocation, Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
/**
 * Import Other Dependices
 */

// import { NonAuthHeader, AuthHeader } from "../../components/Header";
import { getLocalStorageData } from "../../utils/general";
import { PageLoader } from "../../components/Loader";
import OpenOrder from "./OpenOrder";
import OpenHistory from "./OpenHistory";
import TradeHistory from "./TradeHistory";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import {
  BannerHeadingSpan,
  MainElement,
  BannerSection
} from "../../assets/StyleComponents/Exchange.style";
import OrderNavbar from "./OrderNavbar/orderNavbar";
import { trackPage } from "../../tracking/track";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { RouterLink } from "../../components/Reusable";
import { HalfFooterCommon } from "../../components/Footer/common";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList, getUserSelectedLang } from "../../Modules/Generic/selectors";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList(),
  userSelectedLang: getUserSelectedLang()
});

function OrderPage(props) {
  let {
    isLogoutFetching,
    walletErrorGet,
    marketErrorGet,
    tradeErrorGet,
    dashErrorGet,
    settingErrorGet
  } = props;
  let location = useLocation();
  const history = useHistory();
  const param = useParams();
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem("ljs-lang");


  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { transalationList, userSelectedLang } = useSelector(GenericReducer);

  const [index, setindex] = useState(0);
  const [display, setDisplay] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(getLocalStorageData("user")));
  const [accessToken, setAccessToken] = useState(getLocalStorageData("accessToken"));

  useEffect(() => {
    let page = location.pathname + location.search;
    trackPage(page);
  }, [location]);
  // logout User
  // const logoutUser = () => {
  //   props.logoutUser();
  // };

  // const handleSelect = (selectedIndex) => {
  //   setindex(selectedIndex);
  // };

  // const openNavLink = () => {
  //   setDisplay(display);
  // };

  if (isLogoutFetching) {
    return <PageLoader />;
  }
  // if (walletErrorGet || marketErrorGet || tradeErrorGet || dashErrorGet || settingErrorGet) {
  //   return <Redirect to="/error500" />;
  // }

  const path = props.location.pathname;
  const SEOContents = {
    name: `ORDERS`,
    link: `order`,
    title: "Crypto Orders: Open, History & Execution | LCX Exchange",
    description:
      "View and manage your crypto orders with ease on LCX Exchange. Access open orders, track order history, and monitor order execution—all in one place.",
    keywords: `orders page`,
    canonical: `https://exchange.lcx.com/order`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}/order/${param.tab}`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: "Crypto Orders: Open, History & Execution | LCX Exchange",
    OgDescription:
      "View and manage your crypto orders with ease on LCX Exchange. Access open orders, track order history, and monitor order execution—all in one place.",
    OgURL: `https://www.exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/order/${param.tab}`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Crypto Orders: Open, History & Execution | LCX Exchange",
    TwDescription:
      "View and manage your crypto orders with ease on LCX Exchange. Access open orders, track order history, and monitor order execution—all in one place."
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/order/${param.tab}`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <MainElement>
        <BannerSection
          SectionGrey
          width="100%"
          padd="56px 0px"
          paddMd="32px 8px"
          paddLg="32px 64px"
        >
          {/* Main code Start Here */}
          <BannerBox ExchangeContainer widthMd="97%">
            <BannerSection minHeight="100vh" borderRadius="10px">
              <OrderNavbar />
              <BannerBox
                minHeight="600px"
                boxShadow="none"
                padding="10px 1rem"
                paddingMd="0px 10px"
                CardWhite
                DashboardBordersdark
                radius={"0 0 10px 10px"}
              >
                {path.includes("/order/open") && (
                  <BannerBox width="100%" display="flex" direction="column">
                    {userData === null ||
                    userData === undefined ||
                    accessToken === null ||
                    accessToken === undefined ? (
                      <BannerHeading
                        display="flex"
                        justifyContent="center"
                        padding="1rem 0px"
                        weight="300"
                      >
                        <BannerHeadingSpan txtSucces>
                          <RouterLink to={"/login"} textDecoration="none">
                            Log in &nbsp;
                          </RouterLink>
                        </BannerHeadingSpan>
                        or &nbsp;
                        <BannerHeadingSpan txtSucces>
                          <RouterLink to={"/register"} textDecoration="none">
                            Register Now &nbsp;
                          </RouterLink>
                        </BannerHeadingSpan>
                        to trade
                      </BannerHeading>
                    ) : (
                      <OpenOrder />
                    )}
                  </BannerBox>
                )}

                {path.includes("/order/history") && (
                  <BannerBox width="100%" display="flex" direction="column">
                    {userData === null ||
                    userData === undefined ||
                    accessToken === null ||
                    accessToken === undefined ? (
                      <BannerHeading
                        display="flex"
                        justifyContent="center"
                        padding="1rem 0px"
                        weight="300"
                      >
                        <BannerHeadingSpan txtSucces>
                          <RouterLink to={"/login"} textDecoration="none">
                            Log in &nbsp;
                          </RouterLink>
                        </BannerHeadingSpan>
                        or &nbsp;
                        <BannerHeadingSpan txtSucces>
                          <RouterLink to={"/register"} textDecoration="none">
                            Register Now &nbsp;
                          </RouterLink>
                        </BannerHeadingSpan>
                        to trade
                      </BannerHeading>
                    ) : (
                      <OpenHistory />
                    )}
                  </BannerBox>
                )}

                {path.includes("/order/trade-history") && (
                  <BannerBox width="100%" display="flex" direction="column">
                    {userData === null ||
                    userData === undefined ||
                    accessToken === null ||
                    accessToken === undefined ? (
                      <BannerHeading
                        display="flex"
                        justifyContent="center"
                        padding="1rem 0px"
                        weight="300"
                      >
                        <BannerHeadingSpan txtSucces>
                          <RouterLink to={"/login"} textDecoration="none">
                            Log in &nbsp;
                          </RouterLink>
                        </BannerHeadingSpan>
                        or &nbsp;
                        <BannerHeadingSpan txtSucces>
                          <RouterLink to={"/register"} textDecoration="none">
                            Register Now &nbsp;
                          </RouterLink>
                        </BannerHeadingSpan>
                        to trade
                      </BannerHeading>
                    ) : (
                      <TradeHistory />
                    )}
                  </BannerBox>
                )}
              </BannerBox>
            </BannerSection>
          </BannerBox>
        </BannerSection>

        <HalfFooterCommon />
      </MainElement>
    </>
  );
}

export default OrderPage;
