import React from "react";
import { ImgElement, ModalWrapper } from "../../../components/Reusable";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
// import { IMAGE_PREFIX } from "../../../constants/Images/image";
// import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { CloseIcon } from "../../../assets/svgs";
import { AnchorTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
// import { FlokiPromotionTemp } from "../../../assets/png";

const DashboardPermotionComponent = (props) => {
  const { srcImage, link } = props;
  const { width } = useWindowSize();
  return (
    <ModalWrapper>
      <BannerBox CardGrey position="relative" width="520px" widthMd="320px" height="520px" heightMd="320px">
        <BannerBox position="relative" height="100%" zIndex="1">
          <BannerHeadingSpan
            position="absolute"
            zIndex="20"
            right="25px"
            top="37px"
            topMd="20px"
            BorderRadius="50%"
            width="28px"
            height="28px"
            // bck="rgba(0, 0, 0, 0.6)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CloseIcon pointer onClick={props?.onClose} width="20px" height="20px" />
          </BannerHeadingSpan>
          
          <AnchorTag
            color="white"
            PromotionBanner
            href={link}
            target="_blank"
            // onClick={() => props?.Reset24hrs()}
            size="16px"
            sizeMd="14px"
          >
            <ImgElement
              src={srcImage}
              aspectRatio="1"
              objectFit="contain"
              ObjectFitSm="contain"
              alt="Permotion Banner"
              width={width > 767 ? "520" : "320"}
              height={width > 767 ? "520" : "320"}
            />
          </AnchorTag>
          {/* <BannerBox
            position="absolute"
            // positionMd="relative"
            bottom="-8px"
            bottomMd="13%"
            bottomLg="4%"
            zIndex="2"
            width="100%"
            padding="0 16px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ButtonComponent
              width="80%"
              bck="#263645"
              color="white"
              btnHeight="42px"
              btnHeightMd="32px"
            >
              <AnchorTag
                color="white"
                PromotionBanner
                href={link}
                target="_blank"
                // onClick={() => props?.Reset24hrs()}
                size="16px"
                sizeMd="14px"
              >
                {btnText || "Register & Claim"} 
              </AnchorTag>
            </ButtonComponent>
          </BannerBox> */}
        </BannerBox>
      </BannerBox>
      {/* </DivElement> */}
    </ModalWrapper>
  );
};

export default DashboardPermotionComponent;
