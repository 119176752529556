export const CampaginsFaqs = [
  {
    question: "What is LCX Thrive",
    answer: `The LCX Thrive campaign is a fresh effort designed to greet new users on our network and give them incentives for participating. This campaign showcases our dedication to the development of our community and provides a compelling entry point for those who are new to trading cryptocurrencies with LCX. \n \n
      \nYour one-stop shop for unique cryptocurrency prizes, freebies, sign-up bonuses, airdrops, and trading contests is LCX Thrive.
      `
  },
  {
    question: "How to participate in LCX thrive ?",
    answer: `LCX thrive is a reward hub with new campaigns every week for signup bonuses, trading competition and giveaways. Each campaign has a specific task that must be finished while the campaign is active. You can visit our LCX thrive page and select "ongoing" campaigns to view the details of the ongoing campaign`
  },
  {
    question: "Who is eligible for rewards?",
    answer: `Each campaign includes specific duties that each user must perform; once you have met all the campaign's requirements, you are qualified to get rewards. You can view the current campaigns on our LCX thrive page for further information. `
  },
  {
    question: "When will I receive my rewards?",
    answer: `Within 48 hours of the campaign's end, rewards are transferred to the main wallet.Please check out the respective campaign on our LCX thrive page for more information on rewards.`
  },
  {
    question: "Who is ineligible for the rewards?",
    answer: `The campaigns are not applicable to traders who have preferential trading fee discounts, such as Market Makers (MMs), High-Frequency Traders (HFTs), as well as employees or partners etc.Any user from the prohibited countries mentioned in the following link cannot be a participant for LCX thrive. \n
    `,
    url: `https://www.lcx.com/info/faq/kyc-verification/which-countries-and-jurisdictions-are-prohibited-by-lcx-com/`
  }
];
