import { setPrecision } from ".";
import BigNumber from "../../node_modules/bignumber.js/bignumber.mjs";

export function new_BN(n, precision) {
  if (precision) {
    let num = setPrecision(n, precision);
    return new BigNumber(num).toFixed(precision);
  } else {
    return new BigNumber(n);
  }
}
export function add(n1, n2, precision) {
  n1 = new BigNumber(n1);
  n2 = new BigNumber(n2);
  if (precision) return n1.plus(n2).toFixed(precision);
  return n1.plus(n2).toFixed();
}
export function subtract(n1, n2, precision) {
  if (Number(n1) < Number(n2)) throw new Error("Check number");
  let num1 = new BigNumber(n1);
  let num2 = new BigNumber(n2);
  const result = num1.minus(num2);
  if (precision) return result.toFixed(precision);
  return result.toFixed();
}
export function multiply(n1, n2, precision) {
  n1 = new BigNumber(n1);
  n2 = new BigNumber(n2);
  if (precision) return n1.multipliedBy(n2).toFixed(precision);
  return n1.multipliedBy(n2).toFixed();
}
export function divide(n1, n2, precision) {
  // TODO check of n2 == 0
  if (n2 === 0) throw new Error("Check number");
  n1 = new BigNumber(n1);
  n2 = new BigNumber(n2);
  if (precision) return n1.dividedBy(n2).toFixed(precision);
  return n1.dividedBy(n2).toFixed();
}
