import DivElement from "../DivElement";
import TextWrapper from "../TextWrapper";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "../Button";
import { addThreeDotInString } from "../../../utils";
import { CopyWhiteIcon } from "../../../assets/svgs";

const AddressContainer = ({
  text = "",
  onCopy,
  containerProps,
  textProps,
  showThreeDot = false,
  maxLength = 256
}) => {
  return (
    <DivElement
      bdGrey
      border="1px solid"
      minHeight="56px"
      borderRadius="5px"
      displayBtw
      {...containerProps}
    >
      <TextWrapper
        wordWrap
        fontSize="14px"
        fontWeight="400"
        lineHeight="20px"
        padding="8px 16px"
        paddingSm="8px"
        width="calc(100% - 90px)"
        widthSm="calc(100% - 74px)"
        {...textProps}
      >
        {showThreeDot ? addThreeDotInString(text, maxLength) : text}
      </TextWrapper>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <Button
          width="90px"
          widthSm="74px"
          padding="0 12px"
          primaryBlue
          borderRadius="5px 0 0 5px"
          fontSizeSm="14px"
        >
          <CopyWhiteIcon width="16px" height="16px" marginRight="8px" />
          Copy
        </Button>
      </CopyToClipboard>
    </DivElement>
  );
};

export default AddressContainer;
