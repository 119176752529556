import { Fragment } from "react";
import styled, { css } from "styled-components";
import { DivElement, TextWrapper, ThreeDotDropdown } from "../../../../../components/Reusable";
import AllPairFilter from "./AllPairFilter";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import { useSelector } from "react-redux";

const SingleTab = styled(TextWrapper)`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: ${(props) => props.theme.subText};
  margin: 0 12px;
  cursor: pointer;
  white-space: nowrap;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 18px;
    margin: 0 11px;
  }

  &:hover {
    color: ${(props) => props.theme.primaryBlue};
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: 500;
      color: ${(props) => props.theme.primaryBlue};
    `}
`;

const OrdersNavbar = ({ TABS, activeTab, setActiveTab }) => {
  const { width } = useWindowSize();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isDesktop = width > 1024 ? true : false;
  const isMobile = width <= 767 ? true : false;
  return (
    <DivElement maxHeight="40px" paddingBottom="16px" displayBtw>
      <DivElement
        flexAlignCenter
        margin="0 -12px"
        marginSm="0 -11px"
        scrollX
        maxWidth="calc(100% - 148px)"
        maxWidthSm="100%"
      >
        {TABS.map((tab) => (
          <Fragment key={tab.value}>
            {((isLoggedIn && tab.value === "balances" && isDesktop) || tab.value !== "balances") && (
              <SingleTab
                active={activeTab === tab.value ? true : null}
                onClick={() => setActiveTab(tab.value)}
              >
                {tab.label}
              </SingleTab>
            )}
          </Fragment>
        ))}
      </DivElement>
      {!isMobile && activeTab !== "balances" && <AllPairFilter />}
      {isMobile && (
        <ThreeDotDropdown
          activeContainerProps={{ top: "32px", right: "0" }}
          imgProps={{ width: "20px", height: "20px", marginLeft: "8px" }}
        >
          <ThreeDotDropdown.ThreeDotOption>
            <AllPairFilter />
          </ThreeDotDropdown.ThreeDotOption>
        </ThreeDotDropdown>
      )}
    </DivElement>
  );
};

export default OrdersNavbar;
