import React, { useEffect, useState } from "react";
import { getFeesListPublic } from "../../lib/api";
import { formatAPIErrors } from "../../utils/general";
import { showErrorMsg } from "../../utils/notification";
import { new_BN } from "../../utils/bigNumber";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { Table, Thead, Tr, Th, Tbody, Td } from "../../components/Reusable";

const TradingFee = (props) => {
  const [feeDetails, setFeeDetails] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    getFeesListPublic()
      .then((res) => {
        if (res.data.status === "success") {
          setFeeDetails(res.data.data);
        }
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      });
  }, []);

  return (
    <BannerBox
      CardWhite
      width="100%"
      padding="1.5rem 35px"
      paddingMd="1rem 20px"
      BorderRadius="10px"
      margin="0 auto"
      position="relative"
      maxWidth="839px"
    >
      <BannerBox pt="3px" ptMd="11px">
        <BannerHeading size="22px" weight="bold" BodyColor mb="0px">
          {" "}
          Trading Fee{" "}
        </BannerHeading>
        <BannerBox LeftBoxBadge position="absolute"></BannerBox>
      </BannerBox>

      <BannerBox pt="29px">
        <ParaTag size="18px" weight="300" BodyColor mb="0px">
          LCX Exchange offers individuals a secure and compliant trading platform for a variety of
          digital assets.
        </ParaTag>
      </BannerBox>

      <BannerBox pt="23px">
        <ParaTag size="18px" weight="300" BodyColor mb="0px">
          Pay fees in LCX to get a 50% discount: Our fee structure rewards users by lowering their
          trading fees when they pay with LCX Token.
        </ParaTag>
      </BannerBox>

      <BannerBox pt="22px">
        <BannerBox overflow="visible">
          <Table>
            <Thead borderRadius="5px" BodyColor height="58px">
              <Tr>
                <Th txtGrey textAlign="start" fontSize="14px" paddingLeft="8px">
                  30 Day Volume (USD)
                </Th>
                <Th txtGrey textAlign="start" fontSize="14px">
                  {" "}
                  Maker/Taker{" "}
                </Th>
                <Th txtGrey textAlign="start" fontSize="14px">
                  {" "}
                  Maker/Taker <br />
                  <b>(LCX 50% OFF)</b>
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {feeDetails &&
                feeDetails?.feesData?.map((values, index) => {
                  return (
                    <>
                      <Tr
                        height="56px"
                        border={feeDetails?.feesData.length - 1 !== index ? true : false}
                      >
                        <Td textAlign="start" txtGrey fontSizeSm="12px" paddingLeft="8px">
                          {values?.Range}
                        </Td>
                        <Td textAlign="start" txtGrey fontSizeSm="12px">
                          {`${values?.MakerFees && new_BN(values?.MakerFees || 0)}% / ${new_BN(
                            values?.TakerFees || 0
                          )}%`}
                        </Td>
                        <Td txtGrey textAlign="start" fontSizeSm="12px">
                          {`${new_BN(values?.MakerFeesInLCX)}%  / ${new_BN(
                            values?.TakerFeesInLCX
                          )}%`}
                        </Td>
                      </Tr>
                    </>
                  );
                })}
            </Tbody>
          </Table>
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
};

export default TradingFee;
