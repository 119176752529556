import React, { memo } from "react";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";

import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import ImgElement from "../ImgElement";
import { DobDropdownIndicator, DropdownIndicator } from "../../SelectDropDown/selectDropDown";
import { reactDropdownStyles } from "../../../utils";
import config from "../../../constants/config";
import { HeadingSix } from "../../../assets/StyleComponents/FontSize/HeadingSix.style";
// import {} from 'prop-types';

const customOptionLabel = ({ isDarkTheme, label, quoteCoin, imgShown, DobHeaderStatus }) => {
  return (
    <>
      {imgShown && (
        <ImgElement
          width="20px"
          height="20px"
          src={`${
            isDarkTheme ? config.darkCoinUrl : config.lightCoinUrl
          }${quoteCoin?.toLowerCase()}.svg`}
          marginLeft="16px"
          marginRight="8px"
        />
      )}
      <HeadingSix size={DobHeaderStatus ? "11px" : "16px"} sizeMd="14px" lineHeight="24px">
        {label}
      </HeadingSix>
    </>
  );
};

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  data,
  ...rest
}) => {
  const style = {
    alignItems: "center",
    color: "inherit",
    display: "flex"
  };

  const props = {
    ...innerProps,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <BannerBox display="flex" alignItems="center" width="100%" cursorPointer="pointer">
        {children}
      </BannerBox>
    </components.Option>
  );
};

const SingleDropDownValue = ({
  setSelectedOptions,
  options,
  defaultValue = [],
  containerProps,
  imgShown = false,
  menuCloseOnSelect = false,
  DobHeaderStatus = false,
  searchStatus=true,
  placeholder,
  value
}) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <Select
      value={value || ""}
      isSearchable={searchStatus}
      placeholder={placeholder}
      defaultValue={defaultValue}
      closeMenuOnSelect={menuCloseOnSelect}
      hideSelectedOptions={false}
      onChange={setSelectedOptions}
      options={options}
      formatOptionLabel={(val) =>
        customOptionLabel({ ...val, isDarkTheme, imgShown, DobHeaderStatus })
      }
      components={{
        DropdownIndicator: DobHeaderStatus ? DobDropdownIndicator : DropdownIndicator,
        // ValueContainer,
        Option: InputOption
      }}
      styles={reactDropdownStyles({
        isDarkTheme,
        borderBottom: true,
        placeholderProps: { fontSize: "14px" },
        menuProps: { zIndex: "2" },
        showActiveState: value ? true : false,
        DobHeaderStatus: DobHeaderStatus
      })}
    />
  );
};

SingleDropDownValue.propTypes = {};

export default memo(SingleDropDownValue);
