/* eslint-disable no-console */

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getNotificationAlert, updateNotificationAlert } from "../../../lib/api";
import { formatAPIErrors } from "../../../utils/general";
import { debounce, setLocalStorage } from "../../../utils/helpers";
// import SkeletonLoader from "../../../components/SkeletonLoader";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { NotificationIllustration } from "../../../assets/svgs/illustrations";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import InputSwitch from "../../../components/Reusable/InputSwitch";

const NotificationComponent = () => {
  const [alerts, setAlerts] = useState({});
  const [alertsLoading, setIsAlertLoading] = useState();

  useEffect(() => {
    getNotification(true);
  }, []);

  const getNotification = (showLoading) => {
    if (showLoading) setIsAlertLoading(true);
    getNotificationAlert()
      .then((res) => {
        if (res.data.data.hiddenToast) {
          setLocalStorage(true, "hiddenToast");
        } else {
          localStorage.removeItem("hiddenToast");
        }
        setAlerts(res.data.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      })
      .finally(() => {
        setIsAlertLoading(false);
      });
  };

  const updateNotification = debounce((type, value) => {
    let params = {};
    if (type === "email") params.email = value;
    if (type === "push") params.push = value;
    if (type === "newsLetter") params.newsLetter = value;
    if (type === "hiddenToast") {
      params.hiddenToast = value;
      if (params.hiddenToast) {
        setLocalStorage(true, "hiddenToast");
      } else {
        localStorage.removeItem("hiddenToast");
      }
    }

    setAlerts({ ...alerts, ...params });
    updateNotificationAlert(params)
      .then((res) => {
        showSuccessMsg(res.data.message, { autoClose: 7000 });
        getNotification(true);
      })
      .catch((err) => {
        // setLocalStorage(JSON.stringify(value), "push-notification");
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      });
  }, 1000);

  return (
    <BannerBox display="flex" direction="column" paddingMd="20px 16px" padding="0px 16px">
      <div className="element-wrapper mb-0 pb-0">
        {/* {!alertsLoading && ( */}
        <BannerBox display="flex" direction="column" paddingMd="20px 0px">
          <BannerBox position="relative" widthLg="100%">
            <BannerHeading size={"24px"} sizeLg={"20px"} sizeMd={"18px"} mb={"8px"} weight="bold">
              Notification Alert
            </BannerHeading>
            <BannerHeading txtGrey size={"16px"} sizeLg={"18px"} sizeMd={"12px"}>
              Alerts notify you of account-related activity, ensuring you stay well-informed
            </BannerHeading>
          </BannerBox>

          <BannerSection width="100%" display="flex" directionLg="column" direction="row">
            <BannerBox
              display="flex"
              width="40%"
              widthLg="100%"
              direction="column"
              widthMd="100%"
              mt="36px"
              gap="35px"
            >
              <BannerBox display="flex" direction="row" justifyContent="space-between" width="100%">
                <BannerBox display="flex" direction="column" gap="6px">
                  <BannerHeading size="16px" weight="500">
                    Push Notifications
                  </BannerHeading>
                  <ParaTag weight="300" size="14px" BodyColor>
                    Enable to receive notifications
                  </ParaTag>
                </BannerBox>

                <InputSwitch
                  checked={alerts?.push}
                  setChecked={() => {
                    setAlerts(alerts?.push === true ? true : false);
                    updateNotification("push", !alerts?.push);
                  }}
                  disabled={alertsLoading ? true : false}
                />
              </BannerBox>

              <BannerBox display="flex" direction="row" justifyContent="space-between" width="100%">
                <BannerBox display="flex" direction="column" gap="6px">
                  <BannerHeading size="16px" weight="500">
                    Email Alerts
                  </BannerHeading>
                  <ParaTag weight="300" size="14px" BodyColor>
                    Activate prompt trade notifications via email.
                  </ParaTag>
                </BannerBox>

                <InputSwitch
                  checked={alerts?.email}
                  setChecked={() => {
                    setAlerts(alerts?.email === true ? true : false);
                    updateNotification("email", !alerts?.email);
                  }}
                  disabled={alertsLoading ? true : false}
                />
              </BannerBox>

              <BannerBox display="flex" direction="row" justifyContent="space-between" width="100%">
                <BannerBox display="flex" direction="column" gap="6px">
                  <BannerHeading size="16px" weight="500">
                    Newsletter
                  </BannerHeading>
                  <ParaTag weight="300" size="14px" BodyColor>
                    Enable to get LCX and Crypto insights
                  </ParaTag>
                </BannerBox>

                <InputSwitch
                  checked={alerts?.newsLetter}
                  setChecked={() => {
                    setAlerts(alerts?.newsLetter === true ? true : false);
                    updateNotification("newsLetter", !alerts?.newsLetter);
                  }}
                  disabled={alertsLoading ? true : false}
                />
              </BannerBox>

              <BannerBox display="flex" direction="row" justifyContent="space-between" width="100%">
                <BannerBox display="flex" direction="column" gap="6px">
                  <BannerHeading size="16px" weight="500">
                    Hide Toast
                  </BannerHeading>
                  <ParaTag weight="300" size="14px" BodyColor>
                    Enable to hide the Toast
                  </ParaTag>
                </BannerBox>

                <InputSwitch
                  checked={alerts?.hiddenToast}
                  setChecked={() => {
                    setAlerts(alerts?.hiddenToast === true ? true : false);
                    updateNotification("hiddenToast", !alerts?.hiddenToast);
                  }}
                  disabled={alertsLoading ? true : false}
                />
              </BannerBox>
            </BannerBox>

            <BannerBox width="60%" justifyContent="center" display="flex" widthLg="100%">
              <NotificationIllustration width="316.5px" height="263px" />
            </BannerBox>
          </BannerSection>
        </BannerBox>
        {/* )} */}
      </div>
    </BannerBox>
  );
};

export default withRouter(NotificationComponent);
