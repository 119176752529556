import React, { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import TokenInfo from "../Components/TokenInfo";
import SaleDetails from "../Components/SaleDetails";
import { LoadingButton, Skeleton } from "../../../../components/Loader";
import { getParticipatedSaleDetails, claimVestedToken } from "../../../../lib/api";
import { formatAPIErrors } from "../../../../utils/general";
// import { capitalize } from "../../../../utils";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import {
  Button,
  DivElement,
  ElementBox,
  // ImgElement,
  TextWrapper,
  ToolTip
} from "../../../../components/Reusable";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../../constants/styledConstants";
// import BuyTokenBanner from "../Components/BuyTokenBanner";
import { getSaleDetails } from "../../../../lib/api";
import ParticipatedBuyTokenBanner from "../../../../views/NewTokenSale/Components/ParticipatedTokenBanner";
import { useSelector } from "react-redux";

const DetailWithTip = (props) => {
  let { idName, title, value, isLoaded, tip } = props;
  return (
    <DivElement flexJustifyBtwAlignCenter marginTop="19px">
      {isLoaded ? (
        <ToolTip
          title={title}
          id={idName}
          tipContent={tip}
          imgProps={{ width: "18px", height: "18px", right: "-18px", top: "2px" }}
          titleProps={{ fontWeight: "300", fontSizeSm: "12px", secondary: true }}
        />
      ) : (
        <Skeleton width="100px" height="20px" />
      )}

      <TextWrapper fontWeight="700" fontSizeSm="14px">
        {isLoaded ? value : <Skeleton width="100px" height="20px" />}
      </TextWrapper>
    </DivElement>
  );
};

const TotalValueCard = ({ title, isLoaded, totalValue, totalUsdValue }) => {
  return (
    <ElementBox
      bdGrey
      borderMd="1px solid"
      padding="27px 16px 26px 16px"
      paddingMd="19px 24px 20px 24px"
      paddingSm="15px 14px 16px 14px"
      width="100%"
      widthMd="calc(50% - 8px)"
      widthSm="100%"
    >
      <TextWrapper secondary fontSize="14px" fontWeight="300" fontSizeMd="14px">
        {isLoaded ? title : <Skeleton width="60%" height="27px" />}
      </TextWrapper>
      <TextWrapper
        fontSize="18px"
        fontSizeMd="16px"
        fontSizeSm="16px"
        fontWeight="500"
        marginTop="10px"
      >
        {isLoaded ? totalValue : <Skeleton width="50%" height="40px" />}
      </TextWrapper>
      <TextWrapper secondary fontSize="16px" fontSizeMd="14px" fontSizeSm="14px" fontWeight="500">
        {isLoaded ? totalUsdValue : <Skeleton width="30%" height="27px" />}
      </TextWrapper>
    </ElementBox>
  );
};

const ParticipatedSale = () => {
  const [saleDetails, setSaleDetails] = useState("");
  const [loader, setLoader] = useState(false);
  const [rounds, setRounds] = useState("");
  const [selectedRound, setSelectedRound] = useState("");
  const [isClamingToken, setIsClamingToken] = useState(false);
  const { width } = useWindowSize();
  const params = useParams();
  const history = useHistory();
  const userData = useSelector(state => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    getSaleDetailsAPI(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rounds && !selectedRound) {
      setSelectedRound(rounds[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rounds]);

  const getSaleDetailsAPI = (showLoading) => {
    if (showLoading) {
      setLoader(true);
    }

    let data = {
      tokenSaleId: params?.id
    };

    getParticipatedSaleDetails(data)
      .then((res) => {
        setSaleDetails(res?.data?.data);
        setRounds(
          res?.data?.data?.rounds?.map((data) => {
            return { value: data.id, label: data.name, ...data };
          })
        );
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
        history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/token-sale`);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const claimToken = (id) => {
    if (isClamingToken) return;
    setIsClamingToken(true);

    let data = { tokenSalesRoundId: id };
    let data2 = {
      tokenSaleId: id
    };
    claimVestedToken(data)
      .then((res) => {
        showSuccessMsg(res.data.message);
        getSaleDetails(data2);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setIsClamingToken(false));
  };

  const dropdownChangeHandler = (e) => {
    setSelectedRound({ ...saleDetails.rounds.filter((data) => data.id === e.value)[0], ...e });
  };

  let isLoaded = saleDetails && !loader ? true : false;
  const isDesktop = width > styledConstants.BREAKING_POINT_TABLET;

  return (
    <DivElement pageContainer>
      <DivElement
        display={width == 1024 ? "" : "flex"}
        flexDirectionMd="column"
        justifyContent="space-between"
        minWidth={width > 1024 && "1124px"}
        minWidthMd="644px"
        minWidthSm="328px"
      >
        <DivElement width="66.66%" widthMd="100%" marginRight="8px" marginRightMd="0" padding="0">
          {width > 1024 && (
            <>
              <ParticipatedBuyTokenBanner
                saleDetails={saleDetails}
                loader={loader}
                selectedRound={selectedRound}
              />
              <SaleDetails saleDetails={saleDetails} />
            </>
          )}
        </DivElement>
        {width <= 1024 && (
          <ElementBox paddingMd="24px" paddingSm="8px">
            <SaleDetails saleDetails={saleDetails} />
          </ElementBox>
        )}
        <ElementBox
          width={width == 1024 ? "100%" : "33.33%"}
          marginLeft="8px"
          widthMd="100%"
          marginLeftMd="0"
          boxShadow="none"
          padding="0"
          paddingMd="16px"
          bgColor="transparent"
        >
          <DivElement flexJustifyBtw flexAlignCenter justifyContentMd="flex-end">
            <TextWrapper fontSize="16px" fontSizeSm="14px" marginRightMd="8px" fontWeight="300">
              {isLoaded ? "Select Sale Round:" : <Skeleton />}
            </TextWrapper>
            {isLoaded ? (
              <Dropdown
                className="roundDropdown"
                options={rounds}
                onChange={dropdownChangeHandler}
                value={selectedRound}
                placeholder={selectedRound ? selectedRound.label : "Select..."}
              />
            ) : (
              <Skeleton style={{ width: "149px", height: "36px" }} />
            )}
          </DivElement>

          <DivElement
            flex
            flexWrap="wrap"
            justifyContentMd="space-between"
            marginTop="16px"
            marginTopSm="24px"
          >
            <TotalValueCard
              isLoaded={isLoaded}
              title="You have bought"
              totalValue={`${+selectedRound.totalBought || 0} ${saleDetails.coin || ""}`}
              totalUsdValue={`$${+selectedRound.totalBoughtUSD || 0}`}
            />
            <TotalValueCard
              isLoaded={isLoaded}
              title="Total Vesting Amount"
              totalValue={`${+selectedRound.totalVested || 0} ${saleDetails.coin || ""}`}
              totalUsdValue={`$${+selectedRound.totalVestedUSD || 0}`}
            />

            <ElementBox
              bdGrey
              borderMd="1px solid"
              padding="27px 16px 26px 16px"
              paddingMd="19px 24px 20px 24px"
              paddingSm="15px 14px 16px 14px"
            >
              {isLoaded ? (
                <DivElement
                  flexJustifyBtwAlignCenter
                  bdGrey
                  borderBottom="1px solid"
                  paddingBottom="15.5px"
                >
                  <DivElement>
                    <TextWrapper secondary fontWeight="500" txtGrey fontSize="14px">
                      You can claim
                    </TextWrapper>
                    <TextWrapper fontSize="14px" fontWeight="700">
                      = {+selectedRound.releasableAmount || 0} {saleDetails.coin || ""}
                    </TextWrapper>
                  </DivElement>
                  <Button
                    primaryBlue
                    height="40px"
                    padding="0 24px"
                    onClick={() => claimToken(selectedRound.id)}
                    disabled={selectedRound && selectedRound.releasableAmount == 0}
                  >
                    {isClamingToken ? <LoadingButton color="#fff" /> : "Claim"}
                  </Button>
                </DivElement>
              ) : (
                <Skeleton style={{ height: "40px", width: "100%" }} />
              )}

              <DetailWithTip
                title="Total Vested"
                idName="vestedAmout"
                value={`${+selectedRound.totalVested || "0"} ${saleDetails.coin}`}
                isLoaded={isLoaded}
                tip="Your total vested amount for this round."
              />
              <DetailWithTip
                title="Total Claimed"
                idName="claimedAmount"
                value={`${+selectedRound.claimedAmount || "0"} ${saleDetails.coin}`}
                isLoaded={isLoaded}
                tip="You have claimed this much amount till now."
              />
              <DetailWithTip
                title="Total Releasable"
                idName="relesableAmount"
                value={`${+selectedRound.releasableAmount || "0"} ${saleDetails.coin}`}
                isLoaded={isLoaded}
                tip="Your total releasable amount now."
              />
            </ElementBox>
          </DivElement>

          {isDesktop && <TokenInfo saleDetails={saleDetails} loader={loader} />}
        </ElementBox>
      </DivElement>
    </DivElement>
  );
};

export default ParticipatedSale;
