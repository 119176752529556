/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { useSelector } from "react-redux";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { ErrorIcon, ExpandLessIcon, ExpandMoreIcon } from "../../../../assets/svgs";
import { AccordianCard } from "../../../../assets/StyleComponents/AccordionCard.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import styled from "styled-components";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";

const ShowErrorListing = ({ list }) => {
  return (
    <ul className="guidline__modal__points" style={{ marginLeft: "8px" }}>
      {list.map((data, i) => {
        return (
          <li key={i} style={{ marginBottom: "12px" }}>
            <ParaTag size="14px" sizeMd="12px">
              {" "}
              {data}{" "}
            </ParaTag>
          </li>
        );
      })}
    </ul>
  );
};

const ItemWithChevron = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <ExpandMoreIcon className="chevron-down" />
      </>
    }
  />
);

const AccordionItemDiv = styled(ItemWithChevron)`
  border-bottom: 1px solid #ccc;
  width: 90%;
  color: ${({ theme }) => theme.primaryText};
  background: ${({ theme }) => theme.dangerNotification};
  // margin-bottom: 32px;
  margin: 16px auto;
  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
  .szh-accordion__item {
    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 1rem;
      font-size: 1rem;
      font-weight: 500;
      text-align: left;

      color: ${({ theme }) => theme.primaryText};
      background-color: transparent;
      border: none;
    }

    &-content {
      transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    }

    &-panel {
      background: ${({ theme }) => theme.level2Card};
      padding: 1rem;
    }
  }

  .chevron-down {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  }

  &.szh-accordion__item--expanded {
    .szh-accordion__item-btn {
      background-color: ${(props) => props.theme.AccorianBg};
    }
    .chevron-down {
      transform: rotate(180deg);
    }
  }
`;

const AccordianErrorList = ({ submissionList, errorType }) => {
  let userData = useSelector((state) => state.dashboard.userData);
  let isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const { width } = useWindowSize();
  const [missingProofList, setMissingProofList] = useState([]);
  const [collapseStatus, setCollapseStatus] = useState(false);

  let transactionRiskStatus = userData["KYC"]["transactionRiskInfo"] || "";

  useEffect(() => {
    if (submissionList.partialRejectionReason && submissionList.partialRejectionReason.length > 0) {
      submissionList.partialRejectionReason.map((item) => {
        if (item.type === errorType) {
          setMissingProofList(item.key);
        }
      });
    }
  }, [submissionList]);

  return (
    // <BannerBox className="stepWrapper__guidlines">
    <Accordion transition transitionTimeout={350} defaultActiveKey="" className="cursor-pointer">
      <AccordionItemDiv
        header={
          <ParaTag txtDanger size="16px" sizeMd="12px">
            Heads up! Refill Step 2 with these corrections:
          </ParaTag>
        }
      >
        <BannerBox mt="16px">
          {missingProofList &&
            missingProofList.length > 0 &&
            missingProofList.map((item, index) => {
              return (
                <BannerBox key={index}>
                  <ParaTag txtDanger size="14px" sizeMd="12px">
                    <BannerHeadingSpan>
                      {" "}
                      <ErrorIcon
                        width={width < 768 ? "16px" : "20px"}
                        height={width < 768 ? "16px" : "20px"}
                      />{" "}
                    </BannerHeadingSpan>
                    {item.value}
                  </ParaTag>

                  {item.comment && item.comment.length > 0 && (
                    <ShowErrorListing list={item.comment} />
                  )}
                </BannerBox>
              );
            })}
        </BannerBox>
      </AccordionItemDiv>
      {/* <AccordianCard
        ErrorBackground
        mb="16px"
        className="mt-3"
        onClick={() => {
          setCollapseStatus(!collapseStatus);
          // setCollapse2(false);
        }}
      >
        <Accordion.Toggle
          as={Card.Header}
          eventKey="1"
          // style={{ background: isDarkTheme ? "#1F2C38" : "#F5F6FA" }}
        >
          <ParaTag txtDanger size="16px" sizeMd="12px">
            Please resubmit this step. Identify errors in the last submission.
          </ParaTag>

          <BannerHeadingSpan>
            {!collapseStatus ? <ExpandMoreIcon className="" /> : <ExpandLessIcon className="" />}
          </BannerHeadingSpan>
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="1" style={{ padding: "0px" }}>
          <Card.Body>
            <BannerBox mt="16px">
              {missingProofList &&
                missingProofList.length > 0 &&
                missingProofList.map((item, index) => {
                  return (
                    <BannerBox key={index}>
                      <ParaTag txtDanger size="14px" sizeMd="12px">
                        <BannerHeadingSpan>
                          {" "}
                          <ErrorIcon
                            width={width < 768 ? "16px" : "20px"}
                            height={width < 768 ? "16px" : "20px"}
                          />{" "}
                        </BannerHeadingSpan>
                        {item.value}
                      </ParaTag>

                      {item.comment && item.comment.length > 0 && (
                        <ShowErrorListing list={item.comment} />
                      )}
                    </BannerBox>
                  );
                })}
            </BannerBox>
          </Card.Body>
        </Accordion.Collapse>
      </AccordianCard> */}
    </Accordion>
  );
};

export default AccordianErrorList;
