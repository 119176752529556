import React from "react";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import BadgeComponent from "../../NewtradingCompetition/components/badge";
import { BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { ImgElement } from "../../../components/Reusable";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { AnchorTag } from "../../../assets/StyleComponents/FontSize/para.style";
import CountTimerInWords from "../../../components/CountDownTimer/countDownTimer";
import { TelegramTS, TwitterTS, LinkDinTS, YoutubeTS } from "../../../assets/svgs/illustrations";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { WhitePaperTcIll, WebsiteTcIll } from "../../../assets/svgs";
import { HeadingSecond } from "../../../assets/StyleComponents/FontSize/headsecond.style";
import moment from "moment";
import { useSelector } from "react-redux";
import { TimerGreyIcon } from "../../../assets/svgs";

function ParticipatedMobileBuyTokenBanner({ saleDetails, saleDetailsData }) {
  const isTheme = useSelector((state) => state.theme.isTheme);
  return (
    <BannerSection padding="0px 8px">
      <BannerBox margin="15px 0px">
        <BadgeComponent
          title={saleDetailsData?.status}
          borderColor={saleDetailsData?.status === "ONGOING" ? "success" : "primary"}
          radius="5px"
          width="100px"
        />
        {saleDetailsData.endingSoon && (
          <BannerHeadingSpan
            size="12px"
            weight="400"
            bg="#0470E1"
            color="white"
            width="84px"
            height="32px"
            position="absolute"
            top="0"
            right="10px"
            display="flex"
            alignItems="start"
            justifyContent="center"
            FlagShapeTc
          >
            Ending Soon
          </BannerHeadingSpan>
        )}
      </BannerBox>
      <BannerBox display="flex" gap="8px" margin="10px 0px">
        <BannerBox
          width="100px"
          height="80px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          CardGrey
        >
          <ImgElement src={saleDetails?.logo} width="64px" height="64px" />
        </BannerBox>
        <div>
          <HeadingSecond size="24px" weight="500" mb="6px">
            {saleDetails?.coin}
          </HeadingSecond>
          <BannerHeading size="14px" weight="400">
            {saleDetails?.name.length > 20 ? saleDetails?.name.slice(0, 15) : saleDetails?.name}{" "}
            Token ({saleDetails?.type})
          </BannerHeading>
        </div>
      </BannerBox>
      <BannerBox display="flex" justifyContent="space-between" margin="10px 0px">
        <BannerBox display="inline-flex" alignItems="center" mt="5px">
          <TimerGreyIcon width="20px" height="20px" fillGrey={true} />
          <ParaTag
            size="12px"
            ml="6px"
            display="flex"
            alignItems="center"
            color={isTheme ? "#A7A9AB" : "#606F80"}
          >
            {saleDetailsData?.status === "ONGOING"
              ? "Ends in "
              : saleDetailsData?.status === "UPCOMING"
              ? "Starts in "
              : "Ended on "}

            <BannerHeading ml="4px" display="flex" alignItems="center">
              {saleDetails.status === "COMPLETED" ? (
                <BannerHeadingSpan size="12px" weight="400">
                  {moment(saleDetailsData?.endAt * 1000).format("DD/MM/YYYY")}
                </BannerHeadingSpan>
              ) : (
                <CountTimerInWords
                  eventTime={
                    saleDetailsData.status === "ONGOING"
                      ? saleDetailsData?.endAt
                      : saleDetailsData?.startAt
                  }
                  type="Cardcounter"
                />
              )}
            </BannerHeading>
          </ParaTag>
        </BannerBox>
        <BannerBox display="flex" alignItems="center" cursorPointer="pointer">
          <BannerHeadingSpan margin="0px 5px">
            <AnchorTag href={saleDetails?.publicData?.socials?.telegram} target="blank">
              <TelegramTS />
            </AnchorTag>
          </BannerHeadingSpan>
          <BannerHeadingSpan margin="0px 5px">
            <AnchorTag href={saleDetails?.publicData?.socials?.twitter} target="_blank">
              <TwitterTS />
            </AnchorTag>
          </BannerHeadingSpan>
          <BannerHeadingSpan margin="0px 5px">
            <AnchorTag href={saleDetails?.publicData?.socials?.youtube} target="blank">
              <YoutubeTS />
            </AnchorTag>
          </BannerHeadingSpan>
          <BannerHeadingSpan margin="0px 5px">
            <AnchorTag href={saleDetails?.publicData?.socials?.linkedin} target="blank">
              <LinkDinTS />
            </AnchorTag>
          </BannerHeadingSpan>
        </BannerBox>
      </BannerBox>
      <BannerBox display="flex" gap="20px" margin="15px 0px">
        <AnchorTag href={saleDetails?.publicData?.about?.websiteLink} target="_blank">
          <ButtonComponent
            size="14px"
            weight="500"
            TokenSaleBannerbuttons
            width="113px"
            btnHeight="32px"
            display="flex"
            gap="5px"
            justify="center"
            alignItems="center"
          >
            <WebsiteTcIll width="20px" height="10px" />
            Website
          </ButtonComponent>
        </AnchorTag>

        <AnchorTag href={saleDetails?.publicData?.about?.whitepaperLink} target="_blank">
          <ButtonComponent
            size="14px"
            weight="500"
            TokenSaleBannerbuttons
            width="113px"
            btnHeight="32px"
            display="flex"
            justify="center"
            gap="5px"
            alignItems="center"
          >
            <WhitePaperTcIll width="16px" height="20px" />
            Whitepaper
          </ButtonComponent>
        </AnchorTag>
      </BannerBox>
    </BannerSection>
  );
}

export default ParticipatedMobileBuyTokenBanner;
