/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from "react";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
} from "../../../../../assets/StyleComponents/Exchange.style";
import { ButtonComponent } from "../../../../../assets/StyleComponents/Button.style";
import {
  CameraBlueIcon,
  ChevronLeftIcon,
  MobileWhiteIcon,
  RightWayIcon,
  WrongWayIcon
} from "../../../../../assets/svgs";
import { BannerHeading } from "../../../../../assets/StyleComponents/FontSize/Heading.style";
import { Label, LiTag, ParaTag } from "../../../../../assets/StyleComponents/FontSize/para.style";
import { ImgElement } from "../../../../Reusable";
import { BlurNationalId, NationalId } from "../../../../../assets/png";
import WebCamCapture from "../../WebCamCapture";
import { FormInput } from "../../../../../assets/StyleComponents/Input.style";
import { HeadingFourth } from "../../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { LoadingButton } from "../../../../Loader";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import { showErrorMsg, showSuccessMsg } from "../../../../../utils/notification";
import { submitIdProof, validateDocument } from "../../../../../lib/api";
import { getUserDetails } from "../../../../../actions/dashboard";
import { formatAPIErrors } from "../../../../../utils/general";

const ImageScanningCss = styled(BannerBox)`
  position: relative;
  &::after {
    content: "";
    background: linear-gradient(90deg, #f37335 0%, #fdc830 50%, #f37335 100%);
    width: 250px;
    height: 4px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0%;
    bottom: 0;
    margin: auto;
    opacity: 7;
    z-index: 2;
    animation: 2s infinite ease-in-out roll;
    box-shadow: 0px 0px 8px 0px #fdc83080;
    box-shadow: 0px 0px 3px 0px #fdc830 inset;

    @keyframes roll {
      0% {
        transform: translateY(-70px);
      }
      10% {
        transform: translateY(-40px);
      }
      20% {
        transform: translateY(10px);
      }
      30% {
        transform: translateY(40px);
      }
      40% {
        transform: translateY(50px);
      }
      50% {
        transform: translateY(60px);
      }
      60% {
        transform: translateY(50px);
      }
      70% {
        transform: translateY(40px);
      }
      80% {
        transform: translateY(10px);
      }
      90% {
        transform: translateY(-40px);
      }
      100% {
        transform: translateY(-70px);
      }
    }

    @media (max-width: 1024px) {
      left: -2%;
    }
    @media (max-width: 767px) {
      // width: 250pxpx;

      @keyframes roll {
        0% {
          transform: translateY(-50px);
        }
        25% {
          transform: translateY(20px);
        }
        50% {
          transform: translateY(60px);
        }
        60% {
          transform: translateY(60px);
        }
        75% {
          transform: translateY(20px);
        }
        100% {
          transform: translateY(-50px);
        }
      }
    }
  }
`;

function UploadIds({
  selectType,
  firstImage,
  secondImage,
  setFirstImage,
  setSecondImage,
  setOpenWebcam2,
  setOpenWebcam,
  setCapturedImg,
  setCapturedImg2,
  openWebcam,
  openWebcam2,
  onCapture,
  onCapture2,
  img,
  img2,
  setState,
  mobileSetup,
  verifyKYCDocument
}) {
  const isTheme = useSelector((state) => state.theme.isTheme);
  const { width } = useWindowSize();
  const ref = useRef(null);
  const [step, setStep] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [documentNumber, setDocumentNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const RightWaysPointers = [
    "Ensure all corners inside the box.",
    "Confirm details are clearly readable.",
    "Verify the ID is valid."
  ];
  const WrongWaysPointers = [
    "Glare on the image is wrong.",
    "Blurry images are unacceptable.",
    "Expired IDs are unacceptable."
  ];

  // eslint-disable-next-line no-unused-vars
  const [statusofKYC, setStatusOfKyc] = useState("processing");

  useEffect(() => {
    if (step === 3) {
      setOpenWebcam2(true);
    } else if (step === 4) {
      handleValidateDocument();
    }
  }, [step]);

  useEffect(() => {
    if (step === 5) {
      ref.current.focus();
    }
  }, [step]);

  const handleValidateDocument = () => {
    if (!selectType || !firstImage) {
      showErrorMsg("Please fill the required fields!");
      setFirstImage("");
      setSecondImage("");
      return;
    }
    setStatusOfKyc("processing");
    let d = firstImage?.split(";base64,");
    let data = {
      // userid: match.params.id,
      recognizers: [selectType.toUpperCase()],
      images: [d[1]]
    };

    if (secondImage) {
      let d2 = secondImage?.split(";base64,");
      data["images"].push(d2[1]);
    }

    validateDocument(data)
      .then((res) => {
        setStep(5);
        showSuccessMsg("Your document validated successfully, Please continue!");
        setDocumentNumber(res.data.data.idNumber);
      })
      .catch((e) => {
        // dispatch(getUserDetails());
        setStatusOfKyc("failed");
        showErrorMsg(formatAPIErrors(e));
      })
      .finally((f) => {
        // setSLevel(0);
      });
  };

  const submitData = () => {
    setLoading(true);

    let data = {
      // userid: match.params.id,
      documentType: selectType?.toUpperCase(),
      documentNumber: documentNumber?.trim()
    };
    submitIdProof(data)
      .then((res) => {
        showSuccessMsg("ID Document uploaded successfully");
        dispatch(getUserDetails());
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if ((step === 5 || step === 4) && firstImage === "") {
      setStep(1);
      showErrorMsg("Sorry, Images not Detected please capture again.");
    }
  }, [step]);

  return (
    <>
      {step === 1 && (
        <BannerSection>
          <BannerBox display="flex" gap="4px">
            <ButtonComponent bck="transparent" onClick={() => mobileSetup()}>
              <ChevronLeftIcon />
            </ButtonComponent>
            <BannerBox>
              <BannerHeading size="18px" weight="500" style={{ textTransform: "capitalize" }}>
                {selectType === "passport"
                  ? "Passport"
                  : selectType === "national id"
                  ? "National ID"
                  : selectType}{" "}
                {(selectType !== "national Id") !== "ID"}
              </BannerHeading>
              <ParaTag size="14px" weight="500" mt="6px">
                Follow the instructions carefully to click photos correctly for verification.
              </ParaTag>
            </BannerBox>
          </BannerBox>

          <BannerBox
            mt="32px"
            display="flex"
            gap="44px"
            alignItems="center"
            justifyContent="center"
            directionMd="column"
          >
            <BannerBox display="flex" direction="column" alignItems="center">
              <BannerHeading
                txtSuccess
                size="12px"
                weight="500"
                mb="11px"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="9px"
              >
                <BannerHeadingSpan width="20px" height="20px">
                  <RightWayIcon />
                </BannerHeadingSpan>
                The right way
              </BannerHeading>
              <ImgElement src={NationalId} height="160px" width="240px" />
              <ul style={{ paddingLeft: width > 1024 && "20px" }}>
                {RightWaysPointers.map((values) => {
                  return (
                    <>
                      <LiTag size="14px" weight="500">
                        {values}
                      </LiTag>
                    </>
                  );
                })}
              </ul>
            </BannerBox>
            <BannerBox display="flex" direction="column" alignItems="center">
              <BannerHeading
                txtDanger
                size="12px"
                weight="500"
                mb="8px"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="6px"
              >
                <BannerHeadingSpan>
                  <WrongWayIcon />
                </BannerHeadingSpan>
                The wrong way
              </BannerHeading>
              <ImgElement src={BlurNationalId} height="160px" width="240px" />
              <ul>
                {WrongWaysPointers.map((values) => {
                  return (
                    <>
                      <LiTag size="14px" weight="500">
                        {values}
                      </LiTag>
                    </>
                  );
                })}
              </ul>
            </BannerBox>
          </BannerBox>
          <ButtonComponent
            width="100%"
            mt="32px"
            onClick={() => {
              setStep(2);
              setOpenWebcam(true);
            }}
          >
            Start
          </ButtonComponent>
        </BannerSection>
      )}
      {/* STep2+++++++++++++++++++++++++++++++++++++++++++++++++++++content */}
      {step === 2 && (
        <BannerBox>
          {width > 767 && (
            <BannerBox display="flex" gap="4px">
              <ButtonComponent bck="transparent" onClick={() => setStep(1)}>
                <ChevronLeftIcon />
              </ButtonComponent>
              <BannerBox>
                <BannerHeading size="18px" weight="500" style={{ textTransform: "capitalize" }}>
                  Front of{" "}
                  {selectType === "passport"
                    ? "Passport"
                    : selectType === "national id"
                    ? "National ID"
                    : selectType}{" "}
                  {(selectType !== "national Id") !== "ID"}
                </BannerHeading>
                <ParaTag size="14px" weight="500" mt="6px">
                  Click a clear photo of your ID's front side.
                </ParaTag>
              </BannerBox>
            </BannerBox>
          )}
          {width < 767 && (
            <BannerBox display="flex" gap="4px" alignItems="center">
              <BannerHeadingSpan bck="transparent" onClick={() => setStep(1)} height="22px">
                <ChevronLeftIcon />
              </BannerHeadingSpan>
              <BannerHeading size="16px" weight="500">
                Front ID
              </BannerHeading>
            </BannerBox>
          )}

          {openWebcam && (
            <WebCamCapture
              // documentName={"capturedImg"}
              documentName={
                selectType === "passport" ? "firstImage" : firstImage ? "secondImage" : "firstImage"
              }
              selectType={selectType}
              setFirstImage={setFirstImage}
              setSecondImage={setSecondImage}
              setCapturedImg={setCapturedImg}
              captureImages={onCapture}
              setOpenWebcam={setOpenWebcam}
              kycStep="STEP2"
              setStepDocUpload={setStep}
            />
          )}
        </BannerBox>
      )}
      {/* Content Update++++++++++++++++++++++++++++++++++ */}
      {step === 3 && (
        <BannerBox>
          <BannerBox display="flex" gap="4px">
            <ButtonComponent bck="transparent" onClick={() => setStep(1)}>
              <ChevronLeftIcon />
            </ButtonComponent>
            <BannerBox>
              <BannerHeading size="18px" weight="500" style={{ textTransform: "capitalize" }}>
                Back of {selectType === "national id" ? "National ID" : selectType}
              </BannerHeading>
              <ParaTag size="14px" weight="500" mt="6px">
                Click a clear photo of your ID's back side.
              </ParaTag>
            </BannerBox>
          </BannerBox>
          {openWebcam2 && (
            <WebCamCapture
              // documentName={"capturedImg"}
              documentName={
                selectType === "passport" ? "firstImage" : firstImage ? "secondImage" : "firstImage"
              }
              selectType={selectType}
              setFirstImage={setFirstImage}
              setSecondImage={setSecondImage}
              setCapturedImg={setCapturedImg}
              captureImages={onCapture}
              setOpenWebcam={setOpenWebcam}
              kycStep="STEP2"
              setStepDocUpload={setStep}
            />
            // <WebCamCapture
            //   // documentName={"capturedImg"}
            //   documentName={
            //     selectType === "passport" ? "firstImage" : firstImage ? "secondImage" : "firstImage"
            //   }
            //   setSecondImage={setSecondImage}
            //   secondImage={secondImage}
            //   setOpenWebcam={setOpenWebcam2}
            //   setStepDocUpload={setStep}
            // />
          )}
        </BannerBox>
      )}
      {step === 4 && (
        <BannerBox>
          {statusofKYC === "failed" && (
            <BannerBox display="flex" gap="5px" direction="column">
              <HeadingFourth size="18px" weight="500" txtDanger>
                Issues detected{" "}
              </HeadingFourth>
              <ParaTag size="14px" weight="500" mt="6px" txtDanger>
                Try again! Blurry photos and details are unreadable. Provide clearer documentation.
              </ParaTag>
            </BannerBox>
          )}
          {statusofKYC === "processing" && (
            <BannerBox display="flex" gap="5px" direction="column">
              <HeadingFourth size="18px" weight="500">
                Processing Photos <LoadingButton color={!isTheme && "#012243"} />
              </HeadingFourth>
              <ParaTag size="14px" weight="500" mt="6px" style={{ textTransform: "capitalize" }}>
                Wait a moment while we review your{" "}
                {selectType === "passport"
                  ? "Passport"
                  : selectType === "national id"
                  ? "National ID"
                  : selectType}{" "}
                {(selectType !== "national id") !== "ID"}.
              </ParaTag>
            </BannerBox>
          )}
          {statusofKYC === "processing" && (
            <BannerBox
              display="flex"
              alignItems="center"
              gap="24px"
              mt="32px"
              justifyContent="center"
              directionMd="column"
            >
              <ImageScanningCss
                BorderFrameForPhotoKYC
                width="250px"
                height="162px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <ImgElement
                  src={firstImage}
                  width="210px"
                  height="130px"
                  borderRadius="10px"
                  ObjectFitSm="contain"
                  position="relative"
                />
              </ImageScanningCss>
              {selectType !== "passport" && (
                <ImageScanningCss
                  BorderFrameForPhotoKYC
                  width="250px"
                  height="162px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ImgElement
                    src={secondImage}
                    width="210px"
                    height="130px"
                    borderRadius="10px"
                    ObjectFitSm="contain"
                    position="relative"
                  />
                </ImageScanningCss>
              )}
            </BannerBox>
          )}

          {statusofKYC === "failed" && (
            <BannerBox
              display="flex"
              alignItems="center"
              gap="24px"
              mt="32px"
              justifyContent="center"
              directionMd="column"
            >
              <BannerBox
                width="250px"
                height="162px"
                DangerKyc
                display="flex"
                alignItems="center"
                justifyContent="center"
                BorderFrameForPhotoKYCDanger
              >
                <ImgElement
                  src={firstImage}
                  width="210px"
                  height="130px"
                  borderRadius="10px"
                  ObjectFitSm="contain"
                  position="relative"
                />
              </BannerBox>
              {selectType !== "passport" && (
                <BannerBox
                  width="250px"
                  height="162px"
                  DangerKyc
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  BorderFrameForPhotoKYCDanger
                >
                  <ImgElement
                    src={secondImage}
                    width="210px"
                    height="130px"
                    borderRadius="10px"
                    ObjectFitSm="contain"
                    position="relative"
                  />
                </BannerBox>
              )}
            </BannerBox>
          )}

          {statusofKYC === "failed" && (
            <BannerBox>
              {width > 767 && (
                <ButtonComponent
                  height="48px"
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="8px"
                  position="relative"
                  mt="32px"
                  onClick={() => setState("barcode")}
                >
                  <BannerHeadingSpan
                    RadioBtnIconBg
                    ml="6px"
                    height="20px"
                    txtPrimary
                    padding="0px 6px 2px 6px"
                    BorderRadius="0px 5px 0px 5px"
                    position="absolute"
                    top="0px"
                    right="0px"
                    size="12px"
                    weight="500"
                  >
                    recommended
                  </BannerHeadingSpan>
                  <MobileWhiteIcon />
                  Continue with mobile app
                </ButtonComponent>
              )}

              <ButtonComponent
                height="48px"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="8px"
                OutlinePrimaryWithoutHover
                mt="24px"
                onClick={() => {
                  setStep(1);
                  setCapturedImg("");
                  setFirstImage("");
                  setSecondImage("");
                }}
              >
                <CameraBlueIcon />
                {statusofKYC === "failed" ? "Re-take photo" : "Take photo using webcam"}
              </ButtonComponent>
            </BannerBox>
          )}
        </BannerBox>
      )}
      {step === 5 && (
        <BannerBox>
          <BannerBox display="flex" gap="4px">
            <BannerBox>
              <BannerHeading size="18px" weight="500">
                Submit your Valid ID
              </BannerHeading>
              <ParaTag size="14px" weight="500" mt="6px">
                Ensure your ID number is correct before submitting
              </ParaTag>
            </BannerBox>
          </BannerBox>
          <BannerBox
            display="flex"
            alignItems="center"
            gap="24px"
            mt="32px"
            justifyContent="center"
            mb="32px"
            directionMd="column"
          >
            <BannerBox
              width={selectType !== "passport" ? "250px" : "100%"}
              height={selectType !== "passport" ? "162px" : "350px"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              BorderFrameForPhotoKYC
            >
              <ImgElement
                src={firstImage}
                width={selectType !== "passport" ? "210px" : "100%"}
                height={selectType !== "passport" ? "130px" : "100%"}
                borderRadius="10px"
                ObjectFitSm="contain"
              />
            </BannerBox>
            {selectType !== "passport" && (
              <BannerBox
                width="250px"
                height="162px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                BorderFrameForPhotoKYC
              >
                <ImgElement
                  src={secondImage}
                  width="210px"
                  height="130px"
                  borderRadius="10px"
                  ObjectFitSm="contain"
                />
              </BannerBox>
            )}
          </BannerBox>

          <BannerBox mb="30px">
            <Label size="14px" weight="500">
              ID Document Number
            </Label>
            <FormInput
              title="ID Document Number"
              name="idNumber"
              value={documentNumber}
              onChange={(e) => setDocumentNumber(e.target.value)}
              placeholder="Type here"
              ref={ref}
            />
          </BannerBox>

          <ButtonComponent type="submit" disabled={loading} width="100%" onClick={submitData}>
            Submit
          </ButtonComponent>
        </BannerBox>
      )}
    </>
  );
}

export default UploadIds;
