/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React, { useEffect, useRef, useState } from "react";
import { BannerBox, BannerSection } from "../../assets/StyleComponents/Exchange.style";
// import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ExchangeLightTheme from "../../assets/videos/ExchangeLightTheme.mp4";
import ExchangeDarkTheme from "../../assets/videos/ExchangeDarkTheme.mp4";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { H1Tag } from "../../assets/StyleComponents/FontSize/fonts.style";

const BannerSectionMain = (props) => {
  const { promotion } = props;
  const location = useHistory();
  const videoRef = useRef(null);
  const { width } = useWindowSize();
  const [videoSource, setVideoSource] = useState('');
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const isTheme = useSelector((state) => state.theme.isTheme);

  // Function to preload the video
  const preloadVideo = () => {
    if (width > 1024 && videoRef.current) {
      videoRef.current.load();
    }
  };

  useEffect(() => {
    // Call the preload function when the component mounts
    if(width > 1024) preloadVideo();
    // Set video source based on theme
    const source = !isTheme ? ExchangeLightTheme : ExchangeDarkTheme;
    setVideoSource(source);
  }, [isTheme, ExchangeLightTheme, ExchangeDarkTheme]);

  useEffect(() => {
    
    // Call the preload function when the component mounts
    if(width > 1024) preloadVideo();

    // Cleanup function to remove event listeners or perform other cleanup
    return () => {
      // Add cleanup code if needed
    };
  }, []);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const Redirect = () => {
    return location?.push("/register");
  };

  if(!width ) return null;
  return (
    <BannerSection
      display="flex"
      direction="column"
      alignItem="center"
      justify="space-between"
      gap="50px"
      paddTop={promotion ? "100px" : "70px"}
      paddTopMd={promotion ? "100px" : "48px"}
      pbMd={"48px"}
      gapLg="0px"
      background={
        isTheme
          ? "linear-gradient(180deg, rgba(151, 201, 253, 0.00) 0%, rgba(151, 201, 253, 0.32) 100%)"
          : "linear-gradient(180deg, rgba(151, 201, 253, 0) 0%, rgba(151, 201, 253, 0.32) 100%)"
      }
      backgroundMd="none"
      backgroundLg="none"
    >
      <BannerBox>
        <H1Tag size="52px" weight="500" textAlign="center" sizeLg="44px" sizeMd="24px">
          Crypto Exchange {width < 767 && <br />} with Integrity
        </H1Tag>
        <ParaTag
          weight="500"
          size="20px"
          textAlign="center"
          mt="16px"
          sizeMd="12px"
          mtMd="16px"
          txtGrey
        >
          Buy, sell and store digital assets at LCX. {width < 767 && <br />} Europe's leading
          regulated crypto exchange.
        </ParaTag>
        <BannerBox display="flex" justifyContent="center" mt="32px" mtMd="24px">
          <ButtonComponent
            SuccessButton
            minWidth="159px"
            height="44px"
            color="#012243"
            widthMd="144px"
            onClick={() => Redirect()}
            sizeMd="14px"
          >
            Start Trading
          </ButtonComponent>
        </BannerBox>
      </BannerBox>
      
      {width > 1024 && 
        <BannerBox
          width="100%"
          display="flex"
          justifyContent="center"
          height={width > 1024 && "460px"}
        >
          <BannerBox
            displayLg="none"
            displayMd="none"
            maxWidth="1124px"
            width="100%"
            boxSizing="border-box"
          >
            {videoSource && (
              <video
                ref={videoRef}
                id="background-video"
                preload="metadata"
                autoPlay
                loop
                muted
                style={{
                  width: "100%",
                  // height: "100%",
                  borderRadius: "16px 16px 0 0",
                  verticalAlign: "bottom"
                }}
                onLoadedData={handleVideoLoad}
                poster={isVideoLoaded ? undefined : null}
                // src={videoRef.current && !isTheme ? ExchangeLightTheme : ExchangeDarkTheme}
                type="video/mp4"
                playsInline
              >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </BannerBox>
        </BannerBox>
      }
    </BannerSection>
  );
};

export default BannerSectionMain;
