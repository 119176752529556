/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { fromJS } from "immutable";
import { createSelector } from "reselect";

export const WalletBalance = () => (state) => state.walletBalance;

// Get Account Wallet ...................
export const getAccountWallet = () =>
  createSelector(WalletBalance(), (state) => {
    const listing = state.get("accountBalanceList").toJS();
    return listing.length > 0 ? listing : [];
  });

// Get Exchange Wallet ...................
export const getExchangeWallet = () =>
  createSelector(WalletBalance(), (state) => {
    const listing = state.get("exchangeBalanceList").toJS();
    return listing.length > 0 ? listing : [];
  });

// Get Account Wallet Total USD ...................
export const getAccountTotalUSD = () =>
  createSelector(WalletBalance(), (state) => {
    const listing = state.get("accountTotalUsd").toJS();
    return listing && Object.keys(listing).length > 0 ? listing : {};
  });

// Get Exchange Wallet Total USD ...................
export const getExchangeTotalUSD = () =>
  createSelector(WalletBalance(), (state) => {
    const listing = state.get("exchangeTotalUsd").toJS();
    return listing && Object.keys(listing).length > 0 ? listing : {};
  });

// Get is Fetching Account Wallet ...................
export const getisFetchingAccountWallet = () =>
  createSelector(WalletBalance(), (state) => {
    return state.get("isFetchingAccountWallet");
  });

// Get is Fetching Exchange Wallet ...................
export const getisFetchingExchangeWallet = () =>
  createSelector(WalletBalance(), (state) => {
    return state.get("isFetchingExchangeWallet");
  });
