import React from "react";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import moment from "moment";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { AnchorTag, ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import CountTimerInWords from "../../../components/CountDownTimer/countDownTimer";
import { TimerGreenIcon } from "../../../assets/svgs";
import { ImgElement } from "../../../components/Reusable";

const SingleCampaigncard = ({ data, activeState }) => {
  const isTheme = useSelector((state) => state.theme.isTheme);
  // const history = useHistory();

  return (
    <AnchorTag href={data?.externalLink} target="_blank">
      <BannerBox
        CardWhite2
        CardWhiteShadow
        CardLevel2BorderColor
        width="336px"
        height="325px"
        radius="10px"
        widthLg="100%"
        heightLg="auto"
        cursorPointer={"pointer"}
      >
        <BannerBox
          overflow="hidden"
          BorderRadiusLR="5px"
          BorderRadiusTR="5px"
          heightLg="auto"
          heightMd="auto"
          height="200px"
        >
          <ImgElement
            src={
              data?.campaign_banner_image
                ? data?.campaign_banner_image
                : require("../../../assets/img/Card_5-03.jpg")
            }
            alt="Lcx Competition"
            width="100%"
            height="100%"
            ObjectFit="cover"
            // className="img-fluid"
          />
        </BannerBox>

        <BannerBox padding="0 12px 19px">
          <ParaTag size="18px" mt="16px">
            {data?.campaign_name
              ? data?.campaign_name.length > 60
                ? data?.campaign_name.slice(0, 60) + "..."
                : data?.campaign_name
              : "No Name"}
            {/* {Participated && <BadgeComponent title="Participated" borderColor="success" />} */}
          </ParaTag>

          <BannerBox display="inline-flex" alignItems="center" mt="13px">
            <TimerGreenIcon width="20px" height="20px" />
            <ParaTag
              size="12px"
              ml="6px"
              display="flex"
              alignItems="center"
              color={isTheme ? "#A7A9AB" : "#606F80"}
            >
              {activeState === "ongoing"
                ? "Ends in "
                : activeState === "upcoming"
                ? "Starts in "
                : "Ended on "}

              <BannerHeading ml="4px" display="flex" alignItems="center">
                {activeState === "completed" ? (
                  <BannerHeadingSpan size="12px" weight="400">
                    {moment(data.end_date * 1000).format("DD/MM/YYYY")}
                  </BannerHeadingSpan>
                ) : (
                  <CountTimerInWords
                    eventTime={
                      activeState === "ongoing"
                        ? data?.end_date
                        : activeState === "upcoming"
                        ? data.start_date
                        : data?.announcement_date
                    }
                    type="Cardcounter"
                  />
                )}
              </BannerHeading>
            </ParaTag>
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </AnchorTag>
  );
};

export default SingleCampaigncard;
