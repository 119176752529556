import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { submitIdProof, validateDocument } from "../../../../lib/api";
import { getUserDetails } from "../../../../actions/dashboard";
import { formatAPIErrors } from "../../../../utils/general";
import WebCamCapture from "../WebCamCapture";
import Uploader from "../Uploader";
import KycFAQ from "../KycFAQ";
import "react-day-picker/dist/style.css";
import "react-phone-input-2/lib/style.css";
import KycStepButton from "./KycStepBtn";
import { ImgElement, TextWrapper } from "../../../Reusable";
import { getIdValueFromLabel } from "../../../../utils";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { ErrorIcon } from "../../../../assets/svgs";
import { BannerBox, BannerSection } from "../../../../assets/StyleComponents/Exchange.style";
import { FormInput } from "../../../../assets/StyleComponents/Input.style";
import { Label } from "../../../../assets/StyleComponents/FontSize/para.style";
import { KycDivBox, KycSection } from "../../../../assets/StyleComponents/kyc.style";
// import SelectDropDownComponent from "../../../SelectDropDown/selectDropDown";
// import { DropdownIndicator } from "../../../../components/SelectDropDown/selectDropDown";
// import { reactDropdownStyles } from "../../../../utils";

const STATEMENTS = [
  "Uploading Document",
  "Scanning Document Details",
  "Recognizing Document Details",
  "Analysing Documents Details",
  "Matching Document Details"
];

const KycStep2 = ({ match, getUserData, isDarkTheme, guidlinesModal, userData }) => {
  const [loader, setLoader] = useState(false);
  const [selectType, setSelectType] = useState(
    userData?.idType ? getIdValueFromLabel(userData.idType) : "PASSPORT"
  );
  const [documentNumber, setDocumentNumber] = useState(userData.idNumber || "");
  const [disabled, setDisabled] = useState(false);
  const [openWebcam, setOpenWebcam] = useState(false);
  const [validateStatus, setValidateStatus] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [sLevel, setSLevel] = useState(0);
  const [failed, setFailed] = useState(false);
  const [firstImage, setFirstImage] = useState("");
  const [secondImage, setSecondImage] = useState("");

  const attempts = userData && userData.KYC.LEVEL2.step2.attempts;
  const showUpload = attempts >= 3;

  // eslint-disable-next-line no-unused-vars
  const chooseId = (value) => {
    setSelectType(value);
    setFailed(false);
    setValidateStatus(false);
    setFirstImage("");
    setSecondImage("");
  };

  const submitData = () => {
    setDisabled(true);
    setLoader(true);

    let data = {
      userid: match.params.id,
      documentType: selectType,
      documentNumber: documentNumber.trim()
    };
    submitIdProof(data)
      .then((res) => {
        showSuccessMsg("ID Document uploaded successfully");
        getUserData();
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setDisabled(false);
        setLoader(false);
      });
  };

  const handleValidateDocument = () => {
    if (!selectType || !documentNumber) {
      showErrorMsg("Please fill the required fields!");
      setFirstImage("");
      setSecondImage("");
      return;
    }

    let d = firstImage.split(";base64,");
    let data = {
      userid: match.params.id,
      recognizers: [selectType],
      images: [d[1]]
    };

    if (secondImage) {
      let d2 = secondImage.split(";base64,");
      data["images"].push(d2[1]);
    }

    setFailed(false);
    setValidateStatus(false);
    setDisabled(true);

    let interval = setInterval(() => {
      if (sLevel < 4) setSLevel(sLevel + 1);
    }, 1700 + Math.random() * 2000);

    setIsValidating(true);

    validateDocument(data)
      .then((res) => {
        setValidateStatus(true);
        showSuccessMsg("Your document validated successfully, Please continue!");
      })
      .catch((e) => {
        getUserData();
        setFirstImage("");
        setSecondImage("");
        setFailed(true);
        showErrorMsg(formatAPIErrors(e));
      })
      .finally((f) => {
        clearInterval(interval);
        setSLevel(0);
        setIsValidating(false);
        setDisabled(false);
      });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onUpload = async (img) => {
    if (img.target.files[0]) {
      if (
        img.target.files[0].name.includes("jpg") ||
        img.target.files[0].name.includes("png") ||
        img.target.files[0].name.includes("jpeg") ||
        img.target.files[0].name.includes("Jpg") ||
        img.target.files[0].name.includes("Png") ||
        img.target.files[0].name.includes("Jpeg") ||
        img.target.files[0].name.includes("JPG") ||
        img.target.files[0].name.includes("PNG") ||
        img.target.files[0].name.includes("JPEG")
      ) {
        let data = await toBase64(img.target.files[0]);
        if (firstImage) {
          setSecondImage(data);
        } else {
          setFirstImage(data);
        }
      } else {
        showErrorMsg("Documents should be in jpeg, jpg or png format.");
        return false;
      }
    }
  };

  const onSubmitHandler = () => {
    if (failed) {
      setFailed(false);
    } else if (validateStatus) {
      submitData();
    } else {
      handleValidateDocument();
    }
  };

  const onCaptureAgain = (fieldName) => {
    if (loader || isValidating) {
      return;
    }
    const inputs = document.querySelectorAll(".kycsteps__uploader__circle input");
    inputs.forEach((input) => {
      input.value = "";
    });
    if (fieldName === "firstImage") {
      setFirstImage("");
    } else if (fieldName === "secondImage") {
      setSecondImage("");
    }

    setValidateStatus(false);
  };

  let isBtnDisabled =
    (!selectType ||
      !documentNumber ||
      disabled ||
      (selectType === "PASSPORT" && !firstImage) ||
      (selectType === "NATIONALID" && (!firstImage || !secondImage))) &&
    !failed;

  // const OptionList = [
  //   // { label: "Select your ID Type", value: "" },
  //   {
  //     label: "Passport",
  //     value: "PASSPORT",
  //     id: 0
  //     // options: [{ label: "Passport", value: "PASSPORT", id: 0 }]
  //   },
  //   {
  //     label: "National ID",
  //     value: "NATIONALID",
  //     id: 1
  //     // options: [{ label: "Passport", value: "PASSPORT", id: 0 }]
  //   }
  // ];

  // const handleKycDropdown = (coin) => {
  //   chooseId(OptionList[coin].value);
  // };

  return (
    <Fragment>
      {!openWebcam && (
        <KycSection StepContainer>
          <div className="stepWrapper__form">
            <div className="stepWrapper__form__guidline">
              <u onClick={() => guidlinesModal(true)}>Having Issues?</u>
            </div>
            <BannerSection width="100%">
              <BannerBox>
                <div>
                  <Label txtGrey size="14px">
                    Select ID type
                  </Label>
                  <BannerBox InputSelectKyc>
                    <select onChange={(e) => chooseId(e.target.value)} value={selectType}>
                      <option value="" disabled selected hidden>
                        Select your ID Type
                      </option>
                      <option value="PASSPORT">Passport</option>
                      <option value="NATIONALID"> National ID </option>
                    </select>
                    {/* <SelectDropDownComponent
                      dataComponent={{
                        DropdownIndicator
                      }}
                      name="selectKycType"
                      placeholder="Select your ID Type"
                      optionList={OptionList}
                      selected_coin={selectType.value}
                      value={selectType.value}
                      handlePairName={handleKycDropdown}
                      styles={reactDropdownStyles({
                        isDarkTheme,
                        borderBottom: true,
                        height: "48px"
                        // marginTopforKyc: true
                      })}
                      useStyles={true}
                    /> */}
                  </BannerBox>
                </div>

                <BannerBox mt="25px">
                  <Label txtGrey size="14px">
                    Enter ID Number
                  </Label>
                  <FormInput
                    type="text"
                    id="idNumber"
                    height="48px"
                    mt="8px"
                    onChange={(e) => setDocumentNumber(e.target.value)}
                    value={documentNumber}
                    placeholder="Enter your ID Number"
                  />
                  {documentNumber && documentNumber.trim().length <= 0 && (
                    <TextWrapper red>Please enter valid ID Number</TextWrapper>
                  )}
                </BannerBox>
              </BannerBox>

              <KycDivBox KycStepsContainerCommon>
                {(validateStatus || firstImage || secondImage) && (
                  <Fragment>
                    <div className="kycS2__msg">
                      {validateStatus
                        ? "Your document validated successfully."
                        : selectType === "NATIONALID" && !secondImage
                        ? "Front Document Uploaded"
                        : isValidating
                        ? STATEMENTS[sLevel]
                        : ""}
                    </div>
                    <KycDivBox KYC2Captured>
                      {firstImage && (
                        <div className="kycS2__captured__img">
                          <ImgElement src={firstImage} alt="captured_img" />
                          {!validateStatus && (
                            <div className="kycS2__captureAgain">
                              <u onClick={() => onCaptureAgain("firstImage")}>Capture it again</u>
                            </div>
                          )}
                        </div>
                      )}
                      {secondImage && (
                        <div className="kycS2__captured__img">
                          <img src={secondImage} alt="captured_img" />
                          {!validateStatus && (
                            <div className="kycS2__captureAgain">
                              <u onClick={() => onCaptureAgain("secondImage")}>Capture it again</u>
                            </div>
                          )}
                        </div>
                      )}
                    </KycDivBox>
                  </Fragment>
                )}

                {(!firstImage || (selectType === "NATIONALID" && !secondImage)) && !failed && (
                  <Fragment>
                    {selectType === "NATIONALID" && (
                      <div
                        className={`kycS2__upload__msg ${
                          !firstImage ? "kycS2__upload__msg--m0" : ""
                        }`}
                      >
                        Please Select <strong>{!firstImage ? "Front" : "Back"}</strong> of your ID
                        document.
                      </div>
                    )}

                    <Uploader
                      onUpload={onUpload}
                      handleCaptureId={() => setOpenWebcam(true)}
                      step={2}
                    />
                  </Fragment>
                )}

                {failed && (
                  <KycDivBox KYC2FFailed>
                    <ErrorIcon />
                    <div className="kycS2__uploadfailed__msg">
                      <div className="uploadfailed__msg__title">Documents Not Recognised</div>
                      <div className="uploadfailed__msg__subtitle">
                        Please try again with a supported document or select a different document
                        type {showUpload ? "or try uploading the document" : ""}
                      </div>
                    </div>
                  </KycDivBox>
                )}
              </KycDivBox>

              <KycStepButton
                onClick={onSubmitHandler}
                disabled={isBtnDisabled || (documentNumber && documentNumber.trim().length <= 0)}
                loader={loader || isValidating}
                label={failed ? "Try Again" : validateStatus ? "Continue" : "Validate"}
              />
            </BannerSection>
          </div>

          <KycFAQ userData={userData} />
        </KycSection>
      )}

      {openWebcam && (
        <WebCamCapture
          setOpenWebcam={setOpenWebcam}
          documentName={
            selectType === "PASSPORT" ? "firstImage" : firstImage ? "secondImage" : "firstImage"
          }
          setFirstImage={setFirstImage}
          setSecondImage={setSecondImage}
          kycStep="STEP2"
        />
      )}
    </Fragment>
  );
};

const maptoStateProps = (state) => ({
  userData: state.dashboard.userData,
  isDarkTheme: state.theme.isDarkTheme
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: () => dispatch(getUserDetails())
});

export default compose(connect(maptoStateProps, mapDispatchToProps))(withRouter(KycStep2));
