export const lightThemeOverrides = {
  "paneProperties.backgroundGradientStartColor": "#1f9357",
  "paneProperties.backgroundGradientEndColor": "#fff",
  "mainSeriesProperties.candleStyle.borderDownColor": "#e04d5c",
  "mainSeriesProperties.candleStyle.borderUpColor": "#1f9357",
  "mainSeriesProperties.candleStyle.downColor": "#e04d5c",
  "mainSeriesProperties.candleStyle.upColor": "#1f9357",
  "mainSeriesProperties.candleStyle.wickUpColor": "#1f9357",
  "mainSeriesProperties.candleStyle.wickDownColor": "#e04d5c"
};

export const darkThemeOverrides = {
  "mainSeriesProperties.candleStyle.borderColor": "#C400CB",
  "mainSeriesProperties.candleStyle.borderDownColor": "#e04d5c",
  "mainSeriesProperties.candleStyle.borderUpColor": "#1f9357",
  "paneProperties.backgroundGradientStartColor": "#1f9357",
  "paneProperties.backgroundGradientEndColor": "#fff",
  "mainSeriesProperties.candleStyle.downColor": "#e04d5c",
  "mainSeriesProperties.candleStyle.drawBorder": true,
  "mainSeriesProperties.candleStyle.drawWick": true,
  "mainSeriesProperties.candleStyle.upColor": "#1f9357",
  "mainSeriesProperties.candleStyle.wickUpColor": "#1f9357",
  "mainSeriesProperties.candleStyle.wickDownColor": "#e04d5c",
  "mainSeriesProperties.style": 1,
  "paneProperties.background": "#151e26",
  "paneProperties.backgroundType": "solid",
  "paneProperties.backgroundColor": "#151e26",
  "paneProperties.crossHairProperties.color": "#626c73",
  "paneProperties.horzGridProperties.color": "#222326",
  "paneProperties.vertGridProperties.color": "#222326",
  "--tv-color-pane-background": "#151e26",
  "--tv-color-pane-backgroundColor": "#151e26",
  "--tv-color-pane-bgColor": "#151e26",
  "--tv-color-platform-background": "#151e26",
  "--tv-color-pane-background-secondary": "#151e26",
  "scalesProperties.backgroundColor": "#151e26",
  "scalesProperties.bgColor": "#151e26",
  "scalesProperties.background": "#151e26",
  "scalesProperties.lineColor": "#555555",
  "scalesProperties.textColor": "#999999",
  "symbolWatermarkProperties.transparency": 85
};

export const disabled_features = [
  "legend_context_menu",
  "compare_symbol",
  "delete_button_in_legend",
  "adaptive_logo",
  "symbol_info",
  "header_symbol_search",
  "timeframes_toolbar",
  "header_compare",
  "header_saveload"
];

export const enabled_features = [
  "study_templates",
  "charting_library_single_symbol_request",
  "use_localstorage_for_settings",
  "save_chart_properties_to_local_storage",
  "snapshot_trading_drawings",
  "chart_template_storage",
  "header_in_fullscreen_mode",
  "side_toolbar_in_fullscreen_mode"
];

export const studies_overrides = {
  "volume.volume.color.0": "#e04d5c",
  "volume.volume.color.1": "#1f9357",
  "volume.volume.transparency": 70,
  "moving average.precision": 4
};
