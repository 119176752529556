/**
 * Import Node Modules
 */

import axios from "axios";

/**
 * Import Other Dependicies
 */
import apiConstants from "./apiConstants";

const initialStateSchema = {
  accessToken: ""
};

let initialState = initialStateSchema;

const stateLocal = localStorage.getItem("accessToken");
if (stateLocal && stateLocal !== undefined) {
  try {
    initialState.accessToken = stateLocal;
  } catch (e) {
    initialState = initialStateSchema;
  }
}

axios.defaults.headers.common.Authorization = initialState.accessToken;

const instance = axios.create({
  baseURL: apiConstants.API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

const API = axios.create({
  baseURL: apiConstants.API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

const APIV2 = axios.create({
  baseURL: apiConstants.API_URL_V2,
  headers: {
    "Content-Type": "application/json"
  }
});

export {
  instance,
  API,
  APIV2
};
