import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { capitalize } from "../../../utils";

function TokenomicsChart(props) {
  const { values } = props;
  const { width } = useWindowSize();

  let labelArray = [];
  let dataArray = [];

  // eslint-disable-next-line array-callback-return
  values?.map((data) => {
    labelArray.push(data.type);
    dataArray.push(data.percentage);
  });

  const options = {
    responsive: true,
    legend: {
      display: false,
      position: width > 767 ? "right" : "bottom"
    }
    // maintainAspectRatio: false
  };

  const bgColor = [
    "#68A9ED",
    "#9BC6F3",
    "#C3E1FF",
    "#DAEAFC",
    "#F2F8FF",
    "#6497CD",
    "#ADD8E6",
    "#7DF9FF",
    "#5D8AA8",
    "#6495ED"
  ];

  const data = {
    labels: labelArray,
    datasets: [
      {
        label: "Labels",
        data: dataArray,
        backgroundColor: [
          "#68A9ED",
          "#9BC6F3",
          "#C3E1FF",
          "#DAEAFC",
          "#F2F8FF",
          "#6497CD",
          "#ADD8E6",
          "#7DF9FF",
          "#5D8AA8",
          "#6495ED"
        ],
        borderColor: [
          "#68A9ED",
          "#9BC6F3",
          "#C3E1FF",
          "#DAEAFC",
          "#F2F8FF",
          "#6497CD",
          "#ADD8E6",
          "#7DF9FF",
          "#5D8AA8",
          "#6495ED"
        ],
        borderWidth: 1
      }
    ]
  };

  return (
    <BannerBox
      display="flex"
      direction="column"
      justifyContent="center"
      position="relative"
      gap="20px"
      alignItems={width > 767 ? "start" : "center"}
    >
      {/* <div className="position-relative wth-per-50 wth-lg-per-65" style={{ zIndex: "1" }}> */}
      <BannerBox position="relative">
        <Doughnut data={data} options={options} width={350} height={350} />
      </BannerBox>
      {/* <div className="position-absolute Chart-Value">
          <div className="chartInner">
            <p className="mb-1 txt-light-black f-14 font-weight-bold"></p>
          </div>
        </div> */}
      {/* </div> */}
      <BannerBox
        width={width > 767 ? "32%" : "70%"}
        right="0px"
        position={width > 767 && "absolute"}
      >
        <BannerBox mb={width > 767 && "10px"}>
          {labelArray.map((values, i) => {
            return (
              <BannerBox
                display="flex"
                justifyContent="start"
                key={i}
                alignItems="center"
                BorderRadius="50%"
                gap="10px"
                style={{ borderRadius: "50%" }}
              >
                <BannerHeadingSpan
                  width="16px"
                  height="16px"
                  bg={bgColor[i]}
                  BorderRadius="50%"
                ></BannerHeadingSpan>
                <BannerBox display="flex" justifyContent="space-between" width="90%">
                  <BannerHeading size="12px" weight="400">
                    {values.length > 25
                      ? capitalize(values ? values.slice(0, 20) : "") + "..."
                      : capitalize(values ? values : "")}
                  </BannerHeading>
                  <BannerHeading size="12px" weight="400">
                    {dataArray[i]}%
                  </BannerHeading>
                </BannerBox>
              </BannerBox>
            );
          })}
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
}

export default TokenomicsChart;
