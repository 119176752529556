import {
  RECENT_ALL_TRADE
} from "./const.js";

// Call All Ticker API...................
export const callAllRecentTrades = (getValue) => {
  return {
    type: RECENT_ALL_TRADE,
    payload: getValue
  };
};