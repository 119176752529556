/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import ReactDOM from "react-dom";
import DivElement from "../DivElement";

export const DownBarWrapper = ({ children }) => {
  return ReactDOM.createPortal(
    <DivElement LCXDownBar>{children}</DivElement>,
    document.getElementById("down_bar")
  );
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme
});

export default compose(connect(mapStateToProps))(DownBarWrapper);
