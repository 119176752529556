import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { BlueNewMoreNavbarTag } from "../../assets/svgs";

const NavLinkComponent = styled(Link)`
  color: ${(props) => props.theme.subText};
  padding: 0 12px;
  font-weight: 500;
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;

  :hover {
    color: ${(props) => props.theme.primaryBlue};
    border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.primaryBlue};
      border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
    `}
`;

const NavLink = ({ to, label, activeLink, newStatus }) => {
  const location = useLocation();

  return (
    <NavLinkComponent
      to={to}
      active={
        label === "Trade"
          ? location.pathname.includes(to) && !location.pathname.includes("/trade-history")
            ? true
            : null
          : location.pathname.includes(`/${activeLink?.toLowerCase()}`)
          ? true
          : null
      }
    >
      {label}
      {newStatus && 
        <BannerBox position="relative" display="flex" top="-9px" left="4px" bck="linear-gradient(90deg, #0470E1 0%, #41A4C7 100%);" borderRadius="5px">
          <BlueNewMoreNavbarTag color="white" width="33px" height="20px" />
        </BannerBox>
      }
    </NavLinkComponent>
  );
};

export default NavLink;
