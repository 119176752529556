/* eslint-disable no-console */
/**
 * Custom base64 decoding function for the specific encoding pattern
 * @param {string} str - Encoded string to decode
 * @returns {string} Decoded string
 */
const customDecode = str => {
  try {
    // Step 1: Reverse the string
    const reversed = str.split('').reverse().join('');

    // Step 2: Add padding if needed
    let paddedStr = reversed;
    while (paddedStr.length % 4) {
      paddedStr += '=';
    }

    // Step 3: Convert to standard base64 characters
    const base64Str = paddedStr.replace(/-/g, '+').replace(/_/g, '/');

    // Step 4: Decode base64
    const decoded = atob(base64Str);

    // Step 5: Convert to UTF-8 string
    const utf8String = decodeURIComponent(
      Array.from(decoded)
        .map(char => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return utf8String;
  } catch (e) {
    console.error('Decoding failed:', e);
    return str;
  }
};

/**
 * Decodes an environment variable value
 * @param {string} envString - The environment variable string (KEY=value)
 * @returns {string} The decoded value
 */
const decodeEnvValue = envString => {
  try {
    // Split on first '=' only
    const splitIndex = envString.indexOf('=');
    if (splitIndex === -1) return envString;

    // Get everything after the first '='
    const encodedValue = envString.slice(splitIndex + 1).trim();

    if (!encodedValue) return '';

    return customDecode(encodedValue);
  } catch (error) {
    console.error(`Error decoding env value: ${error.message}`);
    return envString.slice(envString.indexOf('=') + 1);
  }
};

// Export for both browser and Node.js environments
if (typeof module !== 'undefined' && module.exports) {
  module.exports = { customDecode, decodeEnvValue };
} else {
  window.cryptoUtils = { customDecode, decodeEnvValue };
}

// module.exports = {
//   decode,
//   decodeEnv
// };