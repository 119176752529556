/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
// import { completeProfileData } from "../../../../lib/api";
import { getUserDetails } from "../../../../actions/dashboard";
import {
  DropdownInput,
  TextInput,
  DropdownWithSpecifyOtherInput
} from "../../../Reusable/FormInput";
import * as Yup from "yup";
import { ANNUAL_NET_INCOME } from "../../../../constants/kycOptions";
import { Persist } from "formik-persist";
// import { formatAPIErrors } from "../../../../utils/general";
import KycStepButton from "./KycStepBtn";
import { formatDropdownOptions } from "../../../../utils";
// import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import OccupationInput from "../../../Reusable/FormInput/OccupationInput";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { HEAR_ABOUT_US, USE_OF_LCX } from "../../../../constants/kycOptions";
import { KycSection, KycDivBox } from "../../../../assets/StyleComponents/kyc.style";
import KycFAQ from "../KycFAQ";
import { AnchorTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { HeadingFourth } from "../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { ChevronLeftIcon, ChevronRightIcon, ProgressSuccessIcon } from "../../../../assets/svgs";
import { completeProfileData } from "../../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { formatAPIErrors } from "../../../../utils/general";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import moment from "moment";
import { debounce } from "../../../../utils/helpers";

const kycStep1cValidationSchema = Yup.object().shape({
  annualIncome: Yup.string().trim().required("Annual net income is required"),
  networth: Yup.string().trim().required("Total net worth is required"),
  SourceOfFunds: Yup.string().trim().required("Source of fund is required"),
  SourceOfWealth: Yup.string()
    .trim()
    .required("Source of wealth is required")
    .test("is-other", "Source of wealth is required", (value) => {
      return !(value === "Other -");
    }),
  SourceOfFundsComment: Yup.string()
    .trim()
    .min(30, "Minimum 30 characters required")
    .required("Description on source of funds is required"),
  SourceOfWealthComment: Yup.string()
    .trim()
    .min(30, "Minimum 30 characters required")
    .required("Description on source of wealth is required"),
  usesFor: Yup.string().trim().required("This field is required"),
  Occupation: Yup.string().trim().required("Occupation is required"),
  howDidYouHearAboutUs: Yup.string().trim().required("This field is required")
});

const Step1c = ({ getUserData, increaseStep, alert, userData, sourceList, ParentsetStep }) => {
  const formikRef = useRef();
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disableStatus, setDisableStatus] = useState(true);
  const { width } = useWindowSize();
  const [mobileSteps, setMobileSteps] = useState(1);

  useEffect(() => {
    formikRef.current?.setTouched({}, false);
    // eslint-disable-next-line
  }, []);

  const getInitialValues = () => {
    let s2Data = JSON.parse(localStorage.getItem("profileData2"))?.values;
    return {
      annualIncome: userData.annualIncome || "",
      networth: userData.networth || "",
      SourceOfFunds: userData.SourceOfFunds || "",
      SourceOfWealth: userData.SourceOfWealth || "",
      SourceOfFundsComment: userData.SourceOfFundsComment || "",
      SourceOfWealthComment: userData.SourceOfWealthComment || "",
      Occupation: userData.Occupation || "",
      howDidYouHearAboutUs: userData.howDidYouHearAboutUs || "",
      usesFor: userData.usesFor || ""
    };
  };

  const MobileSteps = [
    {
      StepName: "Earnings",
      alpha: "a",
      active: mobileSteps >= 1
    },
    {
      StepName: "Origins",
      alpha: "b",
      active: mobileSteps >= 2
    },
    {
      StepName: "Objectives",
      alpha: "c",
      active: mobileSteps === 3
    }
  ];

  const submitDataKyc = debounce((values, { setSubmitting }) => {
    let s1Data = JSON.parse(localStorage.getItem("profileData1")).values;
    setLoader(true);
    setDisabled(true);
    setDisableStatus(true);
    setSubmitting(true);
    let data = {
      ...s1Data,
      DOB: moment(s1Data.DOB).format("YYYY/MM/DD"),
      annualIncome: values.annualIncome,
      networth: values.networth,
      SourceOfFunds: values.SourceOfFunds,
      SourceOfWealth: values.SourceOfWealth,
      SourceOfFundsComment: values.SourceOfFundsComment,
      SourceOfWealthComment: values.SourceOfWealthComment,
      Occupation: values.Occupation,
      howDidYouHearAboutUs: values.howDidYouHearAboutUs,
      usesFor: values.usesFor,
      Phone: s1Data.Phone[0] === "+" ? s1Data.Phone : `+${s1Data.Phone}`,
      level: "LEVEL2"
    };
    completeProfileData(data)
      .then((res) => {
        ParentsetStep(2);
        getUserData();
        showSuccessMsg(res.data.message);
        setSubmitting(false);
        localStorage.removeItem("kycStep");
        localStorage.removeItem("profileData1");
        localStorage.removeItem("profileData2");
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
        setSubmitting(false);
      })
      .finally(() => {
        setLoader(false);
        setDisabled(false);
        setSubmitting(false);
        setDisableStatus(false);
      });
  }, 800);

  const handleMobileSteps = async (values) => {
    if (values) {
      if (width < 768) {
        if (mobileSteps === 1) {
          if (!values.Occupation || !values.annualIncome || !values.networth) {
            setDisableStatus(true);
          } else {
            setDisableStatus(false);
          }
        } else if (
          mobileSteps === 2 &&
          (!values.SourceOfFunds ||
            !values.SourceOfWealth ||
            !values.SourceOfWealthComment ||
            !values.SourceOfFundsComment ||
            values.SourceOfFundsComment.length < 30 ||
            values.SourceOfWealthComment.length < 30)
        ) {
          setDisableStatus(true);
        } else if (mobileSteps === 3) {
          if (!values.howDidYouHearAboutUs || !values.usesFor) {
            setDisableStatus(true);
          } else {
            setDisableStatus(false);
          }
        } else {
          setDisableStatus(false);
        }
      } else {
        if (
          !values.annualIncome ||
          !values.networth ||
          !values.SourceOfFunds ||
          !values.SourceOfWealth ||
          !values.SourceOfFundsComment ||
          !values.SourceOfWealthComment ||
          !values.Occupation ||
          !values.howDidYouHearAboutUs ||
          !values.usesFor
        ) {
          setDisableStatus(true);
        } else {
          setDisableStatus(false);
        }
      }
    } else {
      setDisableStatus(false);
    }
  };

  return (
    <KycSection StepContainer>
      <div className="stepWrapper__form">
        <KycDivBox KYCS1>
          <div className="kycS1__header">
            {width > 767 && (
              <BannerBox
                cursorPointer="pointer"
                display="flex"
                direction="column"
                gap="17px"
                alignItems="start"
                justifyContent="flex-start"
                // gap="10px"
              >
                <ButtonComponent
                  btnHeight="24px"
                  bck="transparent"
                  display="flex"
                  alignItems="center"
                  margin="0px 15px 0px 0px"
                  txtGrey
                  pl="0"
                  onClick={() => ParentsetStep(1)}
                >
                  <ChevronLeftIcon /> BACK
                </ButtonComponent>
                <HeadingFourth size="18px" weight="500">
                  Earnings & Origins
                </HeadingFourth>
              </BannerBox>
            )}

            {width > 767 && width < 1024 && (
              <AnchorTag
                txtPrimary
                href="https://www.lcx.com/info/lcx-accounts/kyc-verification/"
                target="_blank"
                textDecoration="underline"
              >
                Important Points
              </AnchorTag>
            )}
          </div>

          {/* For Mobile Screens */}
          {width < 767 && (
            <>
              <BannerBox display="flex" alignItems="center" gap="16px" mb="24px" gapMd="5px">
                <ButtonComponent
                  btnHeight="auto"
                  bck="transparent"
                  pl="0"
                  onClick={() => ParentsetStep(1)}
                >
                  <ChevronLeftIcon />
                </ButtonComponent>
                {MobileSteps.map((data, index) => {
                  return (
                    <>
                      <BannerBox display="flex" gap="6px">
                        {index + 1 < mobileSteps ? (
                          <ProgressSuccessIcon width="22px" height="22px" />
                        ) : (
                          <>
                            <BannerHeadingSpan
                              width="22px"
                              height="22px"
                              size="14px"
                              weight="500"
                              BorderRadius="50%"
                              BodyColorBg
                              opacity={data.active ? 1 : 0.4}
                            >
                              {data.alpha}
                            </BannerHeadingSpan>
                          </>
                        )}
                        <BannerHeading
                          size="14px"
                          weight="500"
                          opacity={data.active ? 1 : 0.4}
                          txtSuccess={index + 1 < mobileSteps}
                        >
                          {data.StepName}
                        </BannerHeading>
                      </BannerBox>
                      {data.alpha !== "c" && <ChevronRightIcon />}
                    </>
                  );
                })}
              </BannerBox>
            </>
          )}

          <Formik
            innerRef={formikRef}
            initialValues={getInitialValues()}
            onSubmit={submitDataKyc}
            validateOnMount={true}
            validationSchema={kycStep1cValidationSchema}
          >
            {(data) => {
              let { setFieldValue, values } = data;
              handleMobileSteps(values, data);
              return (
                <Form>
                  <BannerBox>
                    {(width >= 768 || (width < 767 && mobileSteps === 1)) && (
                      <>
                        <BannerBox minHeightMd="60vh">
                          <OccupationInput
                            title="Current occupation"
                            name="Occupation"
                            placeholder="Select an option"
                            value={values.Occupation}
                            setFieldValue={setFieldValue}
                          />

                          <BannerBox display="flex" gap="32px" directionMd="column" gapMd="0px">
                            <DropdownInput
                              title="Annual net income"
                              name="annualIncome"
                              placeholder="Select income range"
                              value={values.annualIncome}
                              setFieldValue={setFieldValue}
                              options={ANNUAL_NET_INCOME}
                            />

                            <DropdownInput
                              title="Total net worth"
                              name="networth"
                              placeholder="Select range"
                              value={values.networth}
                              setFieldValue={setFieldValue}
                              options={ANNUAL_NET_INCOME}
                            />
                          </BannerBox>
                        </BannerBox>
                      </>
                    )}

                    {(width >= 768 || (width < 767 && mobileSteps === 2)) && (
                      <>
                        <BannerBox minHeightMd="60vh">
                          <BannerBox display="flex" gap="32px" directionMd="column" gapMd="0px">
                            <DropdownInput
                              title="Source of funds"
                              name="SourceOfFunds"
                              placeholder="Select an option"
                              value={values.SourceOfFunds}
                              setFieldValue={setFieldValue}
                              disabled={!sourceList}
                              options={sourceList && formatDropdownOptions(sourceList.funds)}
                            />

                            {width < 768 && (
                              <TextInput
                                type="textarea"
                                title="Comment on source of fund"
                                containerClass="small-full-width"
                                name="SourceOfFundsComment"
                                value={values.SourceOfFundsComment}
                                placeholder="Describe on source of fund"
                              />
                            )}

                            {sourceList && sourceList.wealth && (
                              <DropdownWithSpecifyOtherInput
                                title="Source of wealth"
                                isOtherTitle="Name source of wealth"
                                isPlaceHolderTitle="Enter the source"
                                name="SourceOfWealth"
                                placeholder="Select an option"
                                value={values.SourceOfWealth}
                                setFieldValue={setFieldValue}
                                options={sourceList && formatDropdownOptions(sourceList.wealth)}
                              />
                            )}
                          </BannerBox>

                          {width < 768 && (
                            <TextInput
                              type="textarea"
                              title="Comment on source of wealth"
                              containerClass="small-full-width"
                              name="SourceOfWealthComment"
                              value={values.SourceOfWealthComment}
                              placeholder="Describe on source of wealth"
                            />
                          )}
                          <BannerBox display="flex" gap="32px">
                            {width > 767 && (
                              <>
                                <TextInput
                                  type="textarea"
                                  title="Comment on source of fund"
                                  containerClass="small-full-width"
                                  name="SourceOfFundsComment"
                                  value={values.SourceOfFundsComment}
                                  placeholder="Describe on source of fund"
                                />

                                <TextInput
                                  type="textarea"
                                  title="Comment on source of wealth"
                                  containerClass="small-full-width"
                                  name="SourceOfWealthComment"
                                  value={values.SourceOfWealthComment}
                                  placeholder="Describe on source of wealth"
                                />
                              </>
                            )}
                          </BannerBox>
                        </BannerBox>
                      </>
                    )}

                    {width > 767 && (
                      <BannerHeading
                        size="18px"
                        weight="500"
                        mb="20px"
                        directionMd="column"
                        gapMd="0px"
                      >
                        Objectives
                      </BannerHeading>
                    )}
                    {(width >= 768 || (width < 767 && mobileSteps === 3)) && (
                      <>
                        <BannerBox
                          display="flex"
                          gap="32px"
                          directionMd="column"
                          gapMd="0px"
                          minHeightMd="60vh"
                        >
                          <DropdownInput
                            title="How did you hear about us?"
                            name="howDidYouHearAboutUs"
                            placeholder="Select an option"
                            containerClass="w-100"
                            value={values.howDidYouHearAboutUs}
                            setFieldValue={setFieldValue}
                            options={HEAR_ABOUT_US}
                          />

                          <DropdownInput
                            title="What will you use LCX for?"
                            name="usesFor"
                            placeholder="Select an option"
                            containerClass="w-100"
                            value={values.usesFor}
                            setFieldValue={setFieldValue}
                            options={USE_OF_LCX}
                          />
                        </BannerBox>

                        <Persist name="profileData2" />
                      </>
                    )}
                  </BannerBox>

                  {width < 767 && mobileSteps <= 2 && (
                    <KycStepButton
                      loader={loader}
                      label="Continue"
                      disabled={disableStatus}
                      onClick={() => setMobileSteps(mobileSteps + 1)}
                    />
                  )}
                  {(width > 767 || (width < 767 && mobileSteps === 3)) && (
                    <KycStepButton
                      loader={loader}
                      label="Submit"
                      disabled={disabled || disableStatus}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </KycDivBox>
      </div>

      <KycFAQ userData={userData} />
    </KycSection>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.getUserData
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: () => dispatch(getUserDetails())
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Step1c);
