import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { DivElement } from "../../../../../components/Reusable";
import OrdersNavbar from "../OrdersNavbar";
import LoginMessage from "../LoginMessage";
import OrdersTab from "./OrdersTab";
import BalancesTab from "../BalancesTab";

const TABS = [
  { label: "Open Orders", value: "openOrders" },
  { label: "Order History", value: "orderHistory" },
  { label: "Order Executions", value: "orderExecutions" },
  { label: "Balances", value: "balances" }
];

const UserAllOrders = ({ containerProps }) => {
  const [activeTab, setActiveTab] = useState("openOrders");
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <DivElement width="100%" height="100%" {...containerProps}>
      <OrdersNavbar TABS={TABS} activeTab={activeTab} setActiveTab={setActiveTab} />
      <DivElement width="100%" height="calc(100% - 40px)">
        {isLoggedIn ? (
          <Fragment>
            {activeTab === TABS[0].value && <OrdersTab type={TABS[0].value} />}
            {activeTab === TABS[1].value && <OrdersTab type={TABS[1].value} />}
            {activeTab === TABS[2].value && <OrdersTab type={TABS[2].value} />}
            {activeTab === TABS[3].value && <BalancesTab />}
          </Fragment>
        ) : (
          <LoginMessage />
        )}
      </DivElement>
    </DivElement>
  );
};

export default UserAllOrders;
