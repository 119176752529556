import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  error: {},
  wallets: "",
  apiData: "",
  balances: "",
  deposits: "",
  withdrawals: "",
  allList: "",
  conversionRate: {},
  isConversionLoader: "",
  totalDepositCount: 0,
  totalWithrawalCount: 0,
  totalAllCount: 0,
  errorGet: false,
  isFetchingWallet: false,
  isTokenInfoLoading: false,
  tokenInfoData: null
};

let initialState = initialStateSchema;
const localData = localStorage.getItem("accessToken");

if (localData && localData !== undefined) {
  try {
    initialState.authToken = localData;
  } catch (e) {
    initialState = initialStateSchema;
  }
}

const walletReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GET_CONVERSION_RATE:
      newState = {
        ...state,
        isConversionLoader: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_CONVERSION_RATE_SUCCESS:
      newState = {
        ...state,
        conversionRate: action.response.data,
        isConversionLoader: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_CONVERSION_RATE_FAIL:
      newState = {
        ...state,
        isConversionLoader: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_USER_REPORTS_SUCCESS:
      newState = {
        ...state,
        reports: action.response,
        isReportsLoader: false
      };
      return newState;

    case apiConstants.GET_USER_REPORTS_FAIL:
      newState = {
        ...state,
        isReportsLoader: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_USER_REPORTS:
      newState = {
        ...state,
        isReportsLoader: true,
        errorGet: false
      };
      return newState;

    case apiConstants.DOWNLOAD_REPORT_REQUEST_SUCCESS:
      newState = {
        ...state,
        isConversionLoader: false,
        errorGet: false,
        csv: action.response
      };
      return newState;

    case apiConstants.DOWNLOAD_REPORT_REQUEST_FAIL:
      newState = {
        ...state,
        isConversionLoader: false,
        errorGet: false
      };
      return newState;

    case apiConstants.DOWNLOAD_REPORT_REQUEST:
      newState = {
        ...state,
        apiLoader: true,
        errorGet: false
      };
      return newState;

    case apiConstants.SETUP_REPORT_REQUEST_SUCCESS:
      newState = {
        ...state,
        isConversionLoader: false,
        errorGet: false
        // reports: action.response
      };
      return newState;

    case apiConstants.SETUP_REPORT_REQUEST_FAIL:
      newState = {
        ...state,
        isConversionLoader: false,
        errorGet: false
      };
      return newState;

    case apiConstants.SETUP_REPORT_REQUEST:
      newState = {
        ...state,
        apiLoader: true,
        errorGet: false
      };
      return newState;

    case apiConstants.CANCEL_REPORT_REQUEST_SUCCESS:
      newState = {
        ...state,
        isConversionLoader: false,
        errorGet: false
        // reports: action.response
      };
      return newState;

    case apiConstants.CANCEL_REPORT_REQUEST_FAIL:
      newState = {
        ...state,
        isConversionLoader: false,
        errorGet: false
      };
      return newState;

    case apiConstants.CANCEL_REPORT_REQUEST:
      newState = {
        ...state,
        apiLoader: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_ALL_WALLETS:
      newState = {
        ...state,
        apiLoader: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_ALL_WALLETS_SUCCESS:
      newState = {
        ...state,
        apiLoader: false,
        wallets: action.response,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_ALL_WALLETS_FAIL:
      newState = {
        ...state,
        apiLoader: false,
        errorGet: false
      };
      return newState;

    case apiConstants.CREATE_WALLET:
      newState = {
        ...state,
        apiLoader: true,
        errorGet: false
      };
      return newState;

    case apiConstants.CREATE_WALLET_SUCCESS:
      newState = {
        ...state,
        apiLoader: false,
        apiData: action.response,
        errorGet: false
      };
      return newState;

    case apiConstants.CREATE_WALLET_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        apiLoader: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_TRANSACTIONS:
      newState = {
        ...state,
        apiLoader: true,
        errorGet: false,
        deposits: [],
        withdrawals: [],
        allList: [],
        totalDepositCount: 0,
        totalAllCount: 0,
        totalWithrawalCount: 0,
        isFetchingWallet: true
      };
      return newState;

    case apiConstants.GET_TRANSACTIONS_SUCCESS:
      if (action.mode === "DEPOSIT") {
        var depsotData = action.response.data || [];
        newState = {
          ...state,
          apiLoader: false,
          apiData: action.response,
          deposits: [...depsotData],
          totalDepositCount: action.response.count,
          totalAllCount: 0,
          totalWithrawalCount: 0,
          errorGet: false,
          isFetchingWallet: false
        };
      } else if (action.mode === "WITHDRAWAL") {
        var withdrawalData = action.response.data || [];
        newState = {
          ...state,
          apiLoader: false,
          apiData: action.response,
          withdrawals: [...withdrawalData],
          totalWithrawalCount: action.response.count,
          totalDepositCount: 0,
          totalAllCount: 0,
          errorGet: false,
          isFetchingWallet: false
        };
      } else {
        var data = action.response.data || [];
        newState = {
          ...state,
          apiLoader: false,
          apiData: action.response,
          allList: [...data],
          totalAllCount: action.response.count,
          totalWithrawalCount: 0,
          totalDepositCount: 0,
          errorGet: false,
          isFetchingWallet: false
        };
      }

      return newState;

    case apiConstants.GET_TRANSACTIONS_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        apiLoader: false,
        errorGet: false,
        isFetchingWallet: false
      };
      return newState;

    case apiConstants.WITHDRAW_TOKEN:
      newState = {
        ...state,
        apiLoader: true,
        errorGet: false
      };
      return newState;

    case apiConstants.WITHDRAW_TOKEN_SUCCESS:
      newState = {
        ...state,
        apiLoader: false,
        apiData: action.response,
        errorGet: false
      };

      return newState;

    case apiConstants.WITHDRAW_TOKEN_FAIL:
      newState = {
        ...state,
        error: action.response,
        apiLoader: false,
        errorGet: false
      };
      return newState;

    case apiConstants.CLEAR_WALLET_ERRORS:
      newState = {
        ...state,
        error: {},
        errorGet: false
      };
      return newState;

    case apiConstants.ERROR_500:
      newState = {
        ...state,
        errorGet: true
      };
      return newState;
    case apiConstants.GET_TOKEN_INFO_DATA:
      newState = {
        ...state,
        isTokenInfoLoading: true
      };
      return newState;
    case apiConstants.GET_TOKEN_INFO_DATA_SUCCESS: {
      const newState = {
        ...state,
        isTokenInfoLoading: false,
        tokenInfoData: action?.response?.data || null
      };
      return newState;
    }

    case apiConstants.GET_TOKEN_INFO_DATA_FAIL:
      newState = {
        ...state,
        isTokenInfoLoading: false,
        errorGet: false
      };
      return newState;

    default:
      return state;
  }
};

export default walletReducer;
