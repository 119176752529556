import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  isTheme: true,
  isDarkTheme: true,
  isThemeFetching: false
};

let initialState = initialStateSchema;
const getThemeFromLocalStorage = localStorage.getItem("darkTheme");

if (getThemeFromLocalStorage) {
  let theme = getThemeFromLocalStorage === "true" ? true : false;
  initialState.isDarkTheme = theme;
  initialState.isTheme = theme;
}

const themeReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.CHANGE_THEME:
      localStorage.setItem("darkTheme", (!state.isDarkTheme).toString());
      newState = {
        ...state,
        isTheme: !state.isDarkTheme,
        isDarkTheme: !state.isDarkTheme
      };
      return newState;

    default:
      return state;
  }
};

export default themeReducer;
