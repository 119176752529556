import apiConstants from "../constants/apiConstants";

export const withdrawToken = (data) => ({
  type: apiConstants.WITHDRAW_TOKEN,
  payload: data
});

export const getAllWallets = () => ({
  type: apiConstants.GET_ALL_WALLETS
});

export const createWallet = (data) => ({
  type: apiConstants.CREATE_WALLET,
  payload: data
});

export const getTransactions = (data) => ({
  type: apiConstants.GET_TRANSACTIONS,
  payload: data
});

export const clearWalletErrors = () => ({
  type: apiConstants.CLEAR_WALLET_ERRORS
});

export const withdrawToken2FA = (data) => ({
  type: apiConstants.WITHDRAW_TOKEN_2FA,
  payload: data
});

export const getConversionRate = () => ({
  type: apiConstants.GET_CONVERSION_RATE
});

export const getUserReports = (data) => ({
  type: apiConstants.GET_USER_REPORTS,
  payload: data
});
// export const setupUserReports = (data) => ({
//   type: apiConstants.SETUP_REPORT_REQUEST,
//   payload: data
// });
// export const cancelUserReports = (data) => ({
//   type: apiConstants.CANCEL_REPORT_REQUEST,
//   payload: data
// });
export const downloadReports = (id) => ({
  type: apiConstants.DOWNLOAD_REPORT_REQUEST,
  payload: id
});

/**
 *  @param {Get Token Info} action
 */
export const getTokenInfoData = (data) => ({
  type: apiConstants.GET_TOKEN_INFO_DATA,
  payload: data
});
