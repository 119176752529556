import BigNumber from "bignumber.js";

export const setPrecision = (num, precision = 8) => {
  let multiplier = Math.pow(10, precision);
  return BigNumber(num)
    .multipliedBy(multiplier)
    .integerValue(BigNumber.ROUND_DOWN)
    .dividedBy(multiplier)
    .toFixed();
};

export const addNumbers = (num1, num2, precision = 8) => {
  num1 = BigNumber(setPrecision(num1, precision));
  num2 = BigNumber(setPrecision(num2, precision));
  let add = num1.plus(num2);

  return setPrecision(add, precision);
};

export const subtractNumbers = (num1, num2, precision = 8) => {
  num1 = BigNumber(setPrecision(num1, precision));
  num2 = BigNumber(setPrecision(num2, precision));
  let subtract = num1.minus(num2);

  return setPrecision(subtract, precision);
};

export const multiplyNumbers = (num1, num2, precision = 8) => {
  num1 = BigNumber(setPrecision(num1, precision));
  num2 = BigNumber(setPrecision(num2, precision));
  let product = num1.multipliedBy(num2);

  return setPrecision(product, precision);
};

export const divideNumbers = (num1, num2, precision = 8) => {
  num1 = BigNumber(setPrecision(num1, precision));
  num2 = BigNumber(setPrecision(num2, precision));
  let division = num1.dividedBy(num2);

  return setPrecision(division, precision);
};
