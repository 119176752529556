/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { TableBody } from "../../assets/StyleComponents/Table.style";
import { getDepositWithdrawalFee } from "../../lib/api";
import { formatAPIErrors } from "../../utils/general";
import configs from "../../constants/config";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { showErrorMsg } from "../../utils/notification";
import { NoRecordIllustration } from "../../assets/svgs/illustrations";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { Table, Thead, Tr, Th, Tbody, Td, ImgElement } from "../../components/Reusable";

const FiatFees = (props) => {
  const [feeDetails, setFeeDetails] = useState([]);
  const [feeDetails2, setFeeDetails2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setIsLoading(true);
  //   getDepositWithdrawalFee()
  //     .then((res) => {
  //       if (res.data.status === "SUCCESS") {
  //         setFeeDetails(res.data.data);
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
  //     });
  // }, []);

  const DepositData = [
    {
      PaymentProvider: "Credit/ Debit",
      DepositFee: "3%",
      minDeposit: "10 EUR",
      maxDeposit: "14.500 EUR"
    },
    {
      PaymentProvider: "BCB",
      DepositFee: "No Fee",
      minDeposit: "No Limit",
      maxDeposit: "No Limit"
    },
    {
      PaymentProvider: "Clear Junction",
      DepositFee: "0.15 % ( with a minimum charge of 2 EUR )",
      minDeposit: "No Limit",
      maxDeposit: "No Limit"
    }
  ];
  const WithdrawalData = [
    {
      PaymentProvider: "BCB",
      WithdrawalFee: "3%",
      minWith: "20 EUR"
    },
    {
      PaymentProvider: "Clear Junction",
      WithdrawalFee: "0.3% ( with a minimum charge of 10 EUR )",
      minWith: "20 EUR"
    }
  ];

  useEffect(() => {
    setFeeDetails(DepositData);
    setFeeDetails2(WithdrawalData);
  }, []);

  return (
    <BannerBox
      CardWhite
      width="100%"
      padding="1.5rem 35px"
      paddingMd="1rem 20px"
      BorderRadius="10px"
      margin="0 auto"
      position="relative"
      maxWidth="839px"
    >
      <BannerBox pt="3px" ptMd="11px">
        <BannerHeading size="22px" weight="bold" BodyColor mb="0px">
          {" "}
          FIAT Deposit and Withdrawal Fees{" "}
        </BannerHeading>
        <BannerBox LeftBoxBadge position="absolute"></BannerBox>
      </BannerBox>

      <BannerBox pt="29px">
        <ParaTag size="18px" weight="300" BodyColor mb="0px">
          FIAT deposits and withdrawals have a minimum requirement and associated fees for specific
          payment providers. If a withdrawal amount is below the minimum requirement, the funds will
          not be debited from your LCX account.
        </ParaTag>
        <ParaTag size="18px" weight="300" BodyColor mb="0px" mt="10px">
          Please refer to the list below for exceptions and a summary of the minimum requirements
          and fees.
        </ParaTag>
      </BannerBox>

      <BannerBox pt="22px">
        <BannerBox overflow="visible">
          <Table>
            <Thead borderRadius="5px" BodyColor height="58px">
              <Tr>
                <Th txtGrey textAlign="center" fontSize="14px" paddingLeft="8px">
                  {" "}
                  Payment Provider{" "}
                </Th>
                <Th txtGrey textAlign="center" fontSize="14px">
                  {" "}
                  Deposit Fee{" "}
                </Th>
                <Th txtGrey textAlign="center" fontSize="14px">
                  {" "}
                  Min Deposit Amount{" "}
                </Th>
                <Th txtGrey textAlign="center" fontSize="14px" paddingRight="8px">
                  {" "}
                  Max Deposit Amount{" "}
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {!isLoading && feeDetails && feeDetails.length === 0 && (
                <Tr>
                  <Td colSpan="4" textAlign="center" padding="20px" fontSizeSm="12px">
                    <NoRecordIllustration paddingTop="32px" />
                    <br />
                    No Records Found
                  </Td>
                </Tr>
              )}
              {!isLoading &&
                feeDetails &&
                feeDetails.length > 0 &&
                feeDetails.map((data, index) => (
                  <Tr
                    key={data.coin}
                    height="56px"
                    border={feeDetails.length - 1 !== index ? true : false}
                  >
                    <Td
                      fontSize="16px"
                      fontSizeSm="12px"
                      txtGrey
                      padding="10px 8px"
                      textAlign="center"
                      paddingLeft="10px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <BannerHeadingSpan textAlign="center">
                        {" "}
                        {data.PaymentProvider}{" "}
                      </BannerHeadingSpan>
                    </Td>
                    <Td textAlign="center" txtGrey fontSizeSm="12px">
                      {data?.DepositFee === "0.15 % ( with a minimum charge of 2 EUR )" ? (
                        <BannerBox>
                          <ParaTag>
                            0.15% <br />
                            <BannerHeadingSpan size="11px">
                              ( with a minimum charge of 2 EUR )
                            </BannerHeadingSpan>
                          </ParaTag>
                        </BannerBox>
                      ) : (
                        data?.DepositFee
                      )}
                    </Td>
                    <Td textAlign="center" txtGrey fontSizeSm="12px">
                      {" "}
                      {`${data.minDeposit}`}{" "}
                    </Td>
                    <Td textAlign="center" txtGrey fontSizeSm="12px" paddingRight="8px">
                      {" "}
                      {`${data.maxDeposit}`}{" "}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>

          <BannerBox mt="30px">
            <Table>
              <Thead borderRadius="5px" BodyColor height="58px">
                <Tr>
                  <Th txtGrey textAlign="center" fontSize="14px" paddingLeft="8px">
                    {" "}
                    Payment Provider{" "}
                  </Th>
                  <Th txtGrey textAlign="center" fontSize="14px">
                    Withdrawal Fee{" "}
                  </Th>
                  <Th txtGrey textAlign="center" fontSize="14px">
                    {" "}
                    Min Withdrawal Amount{" "}
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {!isLoading && feeDetails2 && feeDetails2.length === 0 && (
                  <Tr>
                    <Td colSpan="4" textAlign="center" padding="20px" fontSizeSm="12px">
                      <NoRecordIllustration paddingTop="32px" />
                      <br />
                      No Records Found
                    </Td>
                  </Tr>
                )}
                {!isLoading &&
                  feeDetails2 &&
                  feeDetails2.length > 0 &&
                  feeDetails2.map((data, index) => (
                    <Tr
                      key={data.coin}
                      height="56px"
                      border={feeDetails.length - 1 !== index ? true : false}
                    >
                      <Td
                        fontSize="16px"
                        fontSizeSm="12px"
                        txtGrey
                        padding="10px 8px"
                        textAlign="center"
                        paddingLeft="10px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {data.PaymentProvider}
                      </Td>
                      <Td textAlign="center" txtGrey fontSizeSm="12px">
                        {data?.WithdrawalFee === "0.3% ( with a minimum charge of 10 EUR )" ? (
                          <BannerBox>
                            <ParaTag>
                              0.3% <br />
                              <BannerHeadingSpan size="11px">
                                ( with a minimum charge of 10 EUR )
                              </BannerHeadingSpan>
                            </ParaTag>
                          </BannerBox>
                        ) : (
                          data?.WithdrawalFee
                        )}
                      </Td>
                      <Td textAlign="center" txtGrey fontSizeSm="12px">
                        {" "}
                        {`${data.minWith}`}{" "}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
};

export default FiatFees;
