import { useDispatch } from "react-redux";
import { ThreeDotDropdown } from "../../../../../../components/Reusable";
import { updateSelectedChartType } from "../../../../../../actions/trading";

const ChartTypeDropdown = () => {
  const dispatch = useDispatch();
  return (
    <ThreeDotDropdown
      activeContainerProps={{ top: "32px", right: "12px", padding: "2px 0" }}
      imgProps={{ width: "28px", height: "28px" }}
    >
      <ThreeDotDropdown.ThreeDotOption
        fontSize="12px"
        padding="0 12px"
        sideBorder
        onClick={() => dispatch(updateSelectedChartType("trading"))}
      >
        Trading View
      </ThreeDotDropdown.ThreeDotOption>
      <ThreeDotDropdown.ThreeDotOption
        fontSize="12px"
        padding="0 12px"
        sideBorder
        onClick={() => dispatch(updateSelectedChartType("depth"))}
      >
        Depth
      </ThreeDotDropdown.ThreeDotOption>
    </ThreeDotDropdown>
  );
};
export default ChartTypeDropdown;
