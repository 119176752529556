/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, memo, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import configs from "../../constants/config";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { TableLoader } from "../Loader";
import { TableBodyComponent } from "../../assets/StyleComponents/Table/TableHead.style";
import { MarketTableRow } from "../../assets/StyleComponents/Table/Market/MarketTable.style";
import { SDKTableRow } from "../../assets/StyleComponents/Table/Market/Sdk.style";
import { checkThousand, numberWithCommas } from "../../utils/general";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { watchList } from "../../actions/WatchList/watchlist";
import DollarImageComponent from "../DollarImage/dollarImage";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import FilterPairsComponent from "./filterPairs";
import { getMarketLists, getMarketTickerData } from "../../actions/marketLists";
import { favTradePairsList, selectedPair } from "../../actions/trade";
import MarketTableHeader from "./components/Header";
import { FavouriteFilledIcon, FavouriteIcon, ThreeDotsIcon } from "../../assets/svgs";
import { Table, Td, ImgElement, Thead, RouterLink } from "../Reusable";
import { AnchorTag, ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import NoRecordsFound from "../NoRecordsFound/noRecordsFound";
import { noExponents } from "../../utils/general";
import { DropDownButtonComponent } from "../../assets/StyleComponents/DropDown.style";
import { updateUserFavPairs } from "../../actions/trading";

const MarketTableComponent = (props) => {
  const { isTheme } = props;
  const { width } = useWindowSize();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [dropdownOptions, setDropdownOptions] = useState([
    "Last Price",
    "24h Change",
    "24h High",
    "24h Low"
  ]);
  const [activeCol, setActiveCol] = useState("Last Price");
  const [filterSearch, setFilterSearchData] = useState("");
  const [filter_top_quote_pair, setFilterTopQuotePair] = useState("ALL");
  const [sorting, setSorting] = useState("");
  const [coinFilter, setCoinFilter] = useState("ALL");
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [showNoRecords, setShowNoRecords] = useState(true);

  const tickersData = useSelector((state) => state.trading.tickersData);
  const userFavPairs = useSelector((state) => state.trading.userFavPairs);
  // const userData = useSelector((state) => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (width < 768) {
      setDropdownOptions([...dropdownOptions, "24h Volume"]);
    }
  }, [width]);

  useEffect(() => {
    setCategoryFilter("All");
  }, [coinFilter]);

  const tickers = useMemo(() => {
    let keys = Object.keys(tickersData);
    if (keys.length === 0) {
      return [];
    } else {
      let data = keys.map((item) => {
        return { ...tickersData[item] };
      });
      return data;
    }
  }, [tickersData]);

  const isLoading = !tickers;
  const tableRef = useRef(null);

  const customSort = (a, b) => {
    if (sorting) {
      let key = Object.keys(sorting)[0];
      const sortOrder = sorting[key] ? -1 : 1;
      if (a[key] >= b[key]) {
        return 1 * sortOrder;
      } else {
        return -1 * sortOrder;
      }
    } else {
      let key = "usdVolume";
      const sortOrder = -1;
      if (a[key] >= b[key]) {
        return 1 * sortOrder;
      } else {
        return -1 * sortOrder;
      }
      // return b.change - a.change;
    }
  };

  const onSorting = (field) => {
    let sort;
    if (sorting) {
      // eslint-disable-next-line no-prototype-builtins
      if (sorting.hasOwnProperty(field)) {
        sort = {
          [field]: !sorting[field]
        };
      } else {
        sort = {
          [field]: true
        };
      }
    } else {
      sort = {
        [field]: true
      };
    }
    setSorting(sort);
  };

  const pairFilter = (data) => {
    let pair = data.symbol.replace("/", "");
    let baseName = data.baseFullName.toUpperCase();
    let quoteName = data.quoteFullName.toUpperCase();
    let searchQuery = searchText.toUpperCase();

    let coinFlag = false,
      categoryFlag = false,
      searchFlag = false;
    if (
      coinFilter === "ALL" ||
      data?.symbol?.includes(coinFilter) ||
      (coinFilter === "FAVOURITES" && userFavPairs[data.symbol])
    ) {
      coinFlag = true;
    }
    if (categoryFilter === "All" || data?.categories?.includes(categoryFilter)) {
      categoryFlag = true;
    }
    if (
      !searchText ||
      (searchText && data.symbol.includes(searchQuery)) ||
      pair.includes(searchQuery) ||
      baseName.includes(searchQuery) ||
      quoteName.includes(searchQuery)
    ) {
      searchFlag = true;
    }
    let flag = coinFlag && categoryFlag && searchFlag;

    if (flag && showNoRecords) {
      setShowNoRecords(false);
    }

    return flag;
  };

  const onAddRemoveFavPair = (symbol) => {
    let favs = { ...userFavPairs };
    if (favs[symbol]) {
      delete favs[symbol];
    } else {
      favs[symbol] = true;
    }
    dispatch(updateUserFavPairs(favs));
  };

  const onClickFav = (e, symbol) => {
    e.stopPropagation();
    onAddRemoveFavPair(symbol);
  };

  return (
    <BannerBox
      CardWhite
      padding={"32px 36px"}
      paddingMd={"24px 16px"}
      height={"830px"}
      minHeight={"820px"}
      radius={"20px"}
      radiusMd={"10px"}
      position="relative"
      overflow="hidden"
    >
      <FilterPairsComponent
        filter_top_quote_pair={coinFilter}
        setFilterTopQuotePair={(data) => setCoinFilter(data)}
        filterSearch={searchText}
        filterData={(data) => setSearchText(data)}
        width={width}
        filter_Category={categoryFilter}
        setFilterCategory={setCategoryFilter}
        onSorting={onSorting}
        {...props}
      />

      <BannerBox height={"calc(100vh - 170px)"} width="100%" mt={"26px"} mtMd="10px">
        {!isLoading ? (
          <BannerBox>
            <Table>
              <Thead
                display="flex"
                fontSize="14px"
                fontSizeMd="12px"
                // height={tradeStatusDropdown && width < 768 && "34px"}
                borderBottom="0px"
              >
                <MarketTableHeader
                  activeCol={activeCol}
                  setActiveCol={setActiveCol}
                  dropdownOptions={dropdownOptions}
                  filterSearch={filterSearch}
                  filter_top_quote_pair={filter_top_quote_pair}
                  setFilterTopQuotePair={setFilterTopQuotePair}
                  setFilterSearchData={setFilterSearchData}
                  onSorting={onSorting}
                  {...props}
                />
              </Thead>
              <TableBodyComponent
                MarketHeight={location.pathname.includes("/market") ? 600 : 550 }
                overflow={"scroll"}
                ref={tableRef}
                MarketsTableMobileScreen
                TableAlternateBGDesign={width < 767}
                style={{ paddingBottom: width < 767 && "25px" }}
              >
                {tickers &&
                  tickers.length > 0 &&
                  tickers.sort(customSort).map((item, index) => {
                    let { symbol, change, low, high, usdVolume, lastPrice } = item;

                    const OnclickforRow = (givenLink) => {
                      history.push(
                        `${prevLang ? `/${prevLang}` : "/en"}/trade/${givenLink
                          .split("/")
                          .join("-")}`
                      );
                    };

                    return (
                      <>
                        {pairFilter(item) && (
                          <>
                            {location.pathname.includes("/en/sdk") ? (
                              <SDKTableRow displayFlex MarketsTableMobileScreen key={index}>
                                <>
                                  <td></td>

                                  <Td className="row_element_marketing_localise_js">
                                    <ButtonComponent
                                      OutlineOnlySuccess
                                      NoUnderline
                                      display="flex"
                                      alignItems="center"
                                      cursor={
                                        location.pathname.includes("/en/sdk") && "default!important"
                                      }
                                      textAlign="left"
                                      onClick={() =>
                                        location.pathname.includes("/en/sdk")
                                          ? ""
                                          : OnclickforRow(symbol)
                                      }
                                      gap="8px"
                                    >
                                      <ImgElement
                                        alt={"Coin Name"}
                                        className={"coinlogo"}
                                        border="0"
                                        width="20"
                                        height="20"
                                        src={`${
                                          !isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                                        }${item.symbol.split("/")[0].toLowerCase()}.svg`}
                                        loading="lazy"
                                      />
                                      <ParaTag size={"16px"} sizeLg="14px" sizeMd="12px">
                                        <BannerHeadingSpan weight={"700"}>
                                          {item.symbol.split("/")[0]}/
                                        </BannerHeadingSpan>
                                        <BannerHeadingSpan txtGrey>
                                          {item.symbol.split("/")[1]}{" "}
                                        </BannerHeadingSpan>
                                      </ParaTag>
                                    </ButtonComponent>
                                  </Td>

                                  {width > 1025 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading size={"16px"}>
                                        <var> {noExponents(numberWithCommas(lastPrice))} </var>
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width < 1025 && width > 767 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading sizeLg="14px">
                                        <DollarImageComponent
                                          volumeStatus={true}
                                          DollarAmount={noExponents(
                                            numberWithCommas(checkThousand(usdVolume))
                                          )}
                                          {...props}
                                        />
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {/* All dropwdons Tds */}
                                  {width < 1025 && activeCol === "Last Price" && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerBox display="flex" direction="column">
                                        <BannerHeading sizeLg="14px" sizeMd="12px">
                                          <var> {numberWithCommas(lastPrice)} </var>
                                        </BannerHeading>
                                        <BannerHeading
                                          txtSuccess={change >= 0}
                                          txtDanger={change < 0}
                                          sizeLg="14px"
                                          sizeMd="12px"
                                        >
                                          <var> {change} % </var>
                                        </BannerHeading>
                                      </BannerBox>
                                    </Td>
                                  )}
                                  {width < 1025 && activeCol === "24h Change" && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading
                                        txtSuccess={change >= 0}
                                        txtDanger={change < 0}
                                        sizeLg="14px"
                                        sizeMd="12px"
                                      >
                                        <var> {change} % </var>
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width < 1025 && activeCol === "24h Volume" && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading sizeLg="14px" sizeMd="12px">
                                        <DollarImageComponent
                                          volumeStatus={true}
                                          DollarAmount={numberWithCommas(checkThousand(usdVolume))}
                                          {...props}
                                        />
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width < 1025 && activeCol === "24h High" && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerBox display="flex" direction="column">
                                        <BannerHeading sizeLg="14px" sizeMd="12px">
                                          <var> {noExponents(numberWithCommas(high))} </var>
                                        </BannerHeading>
                                        <BannerHeading
                                          txtSuccess={change >= 0}
                                          txtDanger={change < 0}
                                          sizeLg="14px"
                                          sizeMd="12px"
                                        >
                                          <var> {change} % </var>
                                        </BannerHeading>
                                      </BannerBox>
                                    </Td>
                                  )}
                                  {width < 1025 && activeCol === "24h Low" && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerBox display="flex" direction="column">
                                        <BannerHeading sizeLg="14px" sizeMd="12px">
                                          <var> {noExponents(numberWithCommas(low))} </var>
                                        </BannerHeading>
                                        <BannerHeading
                                          txtSuccess={change >= 0}
                                          txtDanger={change < 0}
                                          sizeLg="14px"
                                          sizeMd="12px"
                                        >
                                          <var> {change} % </var>
                                        </BannerHeading>
                                      </BannerBox>
                                    </Td>
                                  )}
                                  {/* End of dropdowns tds */}

                                  {width > 1025 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading
                                        txtSuccess={change >= 0}
                                        txtDanger={change < 0}
                                        size={"16px"}
                                      >
                                        <var> {change} % </var> 
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width > 1025 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading size={"16px"}>
                                        <DollarImageComponent
                                          volumeStatus={true}
                                          DollarAmount={numberWithCommas(checkThousand(usdVolume))}
                                          {...props}
                                        />
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width > 1025 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading>
                                        <var> {noExponents(numberWithCommas(high))} </var>
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width > 1025 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading>
                                        <var> {noExponents(numberWithCommas(low))} </var>
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width > 767 ? (
                                    <Td>
                                      <BannerBox width="100%">
                                        <AnchorTag
                                          href="https://exchange.lcx.com/join/5tClORF5BE"
                                          target="_blank"
                                          txtPrimary
                                          fontSize="14px"
                                          underline
                                        >
                                          <ButtonComponent
                                            OutlineSuccess
                                            minWidth="64px"
                                            btnHeight="32px"
                                            // onClick={() => OnclickforRow(symbol)}
                                          >
                                            Trade
                                          </ButtonComponent>
                                        </AnchorTag>
                                      </BannerBox>
                                    </Td>
                                  ) : (
                                    <Td>
                                      <DropDownButtonComponent
                                        TradeOrder
                                        InnerLevel
                                        RemoveBtnPadding
                                        NoCaret
                                        variant="outline-secondary"
                                        title={
                                          <ThreeDotsIcon
                                            width="20px"
                                            height="20px"
                                            marginTop="5px"
                                          />
                                        }
                                        id="input-group-dropdown-1"
                                      >
                                        <Dropdown.Item
                                          as={"div"}
                                          style={{ fontSize: "12px", textAlign: "left" }}
                                          onClick={() => {
                                            window.open(
                                              "https://exchange.lcx.com/join/5tClORF5BE",
                                              "_blank"
                                            );
                                          }}
                                        >
                                          Trade
                                        </Dropdown.Item>
                                      </DropDownButtonComponent>
                                    </Td>
                                  )}
                                </>
                              </SDKTableRow>
                            ) : (
                              <MarketTableRow displayFlex MarketsTableMobileScreen key={index}>
                                <>
                                  <td>
                                    <ButtonComponent
                                      OutlineOnlySuccess
                                      NoUnderline
                                      width={"24px"}
                                      widthMd="24px"
                                      onClick={(e) =>
                                        location.pathname.includes("/en/sdk")
                                          ? ""
                                          : onClickFav(e, item.symbol)
                                      }
                                    >
                                      {userFavPairs[item.symbol] ? (
                                        <FavouriteFilledIcon />
                                      ) : (
                                        <FavouriteIcon />
                                      )}
                                    </ButtonComponent>
                                  </td>

                                  <Td className="row_element_marketing_localise_js">
                                    <ButtonComponent
                                      OutlineOnlySuccess
                                      NoUnderline
                                      display="flex"
                                      alignItems="center"
                                      cursor={
                                        location.pathname.includes("/en/sdk") && "default!important"
                                      }
                                      textAlign="left"
                                      onClick={() =>
                                        location.pathname.includes("/en/sdk")
                                          ? ""
                                          : OnclickforRow(symbol)
                                      }
                                      gap="8px"
                                    >
                                      <ImgElement
                                        alt={"Coin Name"}
                                        className={"coinlogo"}
                                        border="0"
                                        width="20"
                                        height="20"
                                        src={`${
                                          !isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                                        }${item.symbol.split("/")[0].toLowerCase()}.svg`}
                                        loading="lazy"
                                      />
                                      <ParaTag size={"16px"} sizeLg="14px" sizeMd="12px">
                                        <BannerHeadingSpan weight={"700"}>
                                          {item.symbol.split("/")[0]}/
                                        </BannerHeadingSpan>
                                        <BannerHeadingSpan txtGrey>
                                          {item.symbol.split("/")[1]}{" "}
                                        </BannerHeadingSpan>
                                      </ParaTag>
                                    </ButtonComponent>
                                  </Td>

                                  {width > 1025 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading size={"16px"}>
                                        <var> {noExponents(numberWithCommas(lastPrice))} </var>
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width < 1025 && width > 767 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading sizeLg="14px">
                                        <DollarImageComponent
                                          volumeStatus={true}
                                          DollarAmount={noExponents(
                                            numberWithCommas(checkThousand(usdVolume))
                                          )}
                                          {...props}
                                        />
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {/* All dropwdons Tds */}
                                  {width < 1025 && activeCol === "Last Price" && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerBox display="flex" direction="column">
                                        <BannerHeading sizeLg="14px" sizeMd="12px">
                                          <var> {numberWithCommas(lastPrice)} </var>
                                        </BannerHeading>
                                        <BannerHeading
                                          txtSuccess={change >= 0}
                                          txtDanger={change < 0}
                                          sizeLg="14px"
                                          sizeMd="12px"
                                        >
                                          <var> {change} % </var>
                                        </BannerHeading>
                                      </BannerBox>
                                    </Td>
                                  )}
                                  {width < 1025 && activeCol === "24h Change" && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading
                                        txtSuccess={change >= 0}
                                        txtDanger={change < 0}
                                        sizeLg="14px"
                                        sizeMd="12px"
                                      >
                                        <var> {change} % </var>
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width < 1025 && activeCol === "24h Volume" && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading sizeLg="14px" sizeMd="12px">
                                        <DollarImageComponent
                                          volumeStatus={true}
                                          DollarAmount={numberWithCommas(checkThousand(usdVolume))}
                                          {...props}
                                        />
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width < 1025 && activeCol === "24h High" && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerBox display="flex" direction="column">
                                        <BannerHeading sizeLg="14px" sizeMd="12px">
                                          <var> {noExponents(numberWithCommas(high))} </var>
                                        </BannerHeading>
                                        <BannerHeading
                                          txtSuccess={change >= 0}
                                          txtDanger={change < 0}
                                          sizeLg="14px"
                                          sizeMd="12px"
                                        >
                                          <var> {change} % </var>
                                        </BannerHeading>
                                      </BannerBox>
                                    </Td>
                                  )}
                                  {width < 1025 && activeCol === "24h Low" && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerBox display="flex" direction="column">
                                        <BannerHeading sizeLg="14px" sizeMd="12px">
                                          <var> {noExponents(numberWithCommas(low))} </var>
                                        </BannerHeading>
                                        <BannerHeading
                                          txtSuccess={change >= 0}
                                          txtDanger={change < 0}
                                          sizeLg="14px"
                                          sizeMd="12px"
                                        >
                                          <var> {change} % </var>
                                        </BannerHeading>
                                      </BannerBox>
                                    </Td>
                                  )}
                                  {/* End of dropdowns tds */}

                                  {width > 1025 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading
                                        txtSuccess={change >= 0}
                                        txtDanger={change < 0}
                                        size={"16px"}
                                      >
                                        <var> {change} % </var>
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width > 1025 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading size={"16px"}>
                                        <DollarImageComponent
                                          volumeStatus={true}
                                          DollarAmount={numberWithCommas(checkThousand(usdVolume))}
                                          {...props}
                                        />
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width > 1025 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading>
                                        <var> {noExponents(numberWithCommas(high))} </var>
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width > 1025 && (
                                    <Td
                                      textAlign="right"
                                      className="row_element_marketing_localise_js"
                                    >
                                      <BannerHeading>
                                        <var> {noExponents(numberWithCommas(low))} </var>
                                      </BannerHeading>
                                    </Td>
                                  )}
                                  {width > 767 ? (
                                    <Td>
                                      <BannerBox width="100%">
                                        <RouterLink
                                          to={`${prevLang ? `/${prevLang}` : "/en"}/trade/${symbol
                                            .split("/")
                                            .join("-")}`}
                                          txtPrimary
                                          fontSize="14px"
                                          underline
                                        >
                                          <ButtonComponent
                                            OutlineSuccess
                                            minWidth="64px"
                                            btnHeight="32px"
                                            // onClick={() => OnclickforRow(symbol)}
                                          >
                                            Trade
                                          </ButtonComponent>
                                        </RouterLink>
                                      </BannerBox>
                                    </Td>
                                  ) : (
                                    <Td>
                                      <DropDownButtonComponent
                                        TradeOrder
                                        InnerLevel
                                        RemoveBtnPadding
                                        NoCaret
                                        variant="outline-secondary"
                                        title={
                                          <ThreeDotsIcon
                                            width="20px"
                                            height="20px"
                                            marginTop="5px"
                                          />
                                        }
                                        id="input-group-dropdown-1"
                                      >
                                        <Dropdown.Item
                                          as={"div"}
                                          style={{ fontSize: "12px", textAlign: "left" }}
                                          onClick={() =>
                                            location.pathname.includes("/en/sdk")
                                              ? ""
                                              : OnclickforRow(symbol)
                                          }
                                        >
                                          Trade
                                        </Dropdown.Item>
                                      </DropDownButtonComponent>
                                    </Td>
                                  )}
                                </>
                              </MarketTableRow>
                            )}
                          </>
                        )}
                      </>
                    );
                  })}

                {tickers && tickers.length === 0 && coinFilter !== "FAVOURITES" && (
                  <BannerBox margin="15px 0px">
                    <NoRecordsFound text="No Record Found" />
                  </BannerBox>
                )}
                {userFavPairs &&
                  coinFilter === "FAVOURITES" &&
                  Object.keys(userFavPairs).length === 0 && (
                    <BannerBox margin="15px 0px">
                      <NoRecordsFound text="Add Your Favorite Asset" />
                    </BannerBox>
                  )}
              </TableBodyComponent>
            </Table>
          </BannerBox>
        ) : (
          <TableLoader rows={10} webCol={7} tabletCol={4} mobileCol={2} />
        )}
      </BannerBox>
    </BannerBox>
  );
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  favPairList: state.trade.favPairList,
  selectedPairName: state.trade.selectedPair
});

const mapDispatchToProps = (dispatch) => ({
  getMarketLists: (user) => dispatch(getMarketLists(user)),
  selectedPair: (user) => dispatch(selectedPair(user)), // Call for Selected Pair From redux
  getMarketTickerData: () => dispatch(getMarketTickerData()), // Call for Market Ticker From  redux
  watchList: (user) => dispatch(watchList(user)),
  favTradePairsList: (user) => dispatch(favTradePairsList(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MarketTableComponent));
