import React from 'react';

import { MainTag } from '../../../assets/StyleComponents/Main.style';


const MainTagContainer = ({ children, extraStyle, cardStyle }) => {
  return (
    <MainTag
      position={extraStyle?.position ? extraStyle?.position : "relative"}
      zIndex={extraStyle?.zIndex ? extraStyle?.zIndex : "1"}
      mx="auto"
      {...extraStyle}
      ParentCard={(cardStyle && cardStyle === "ParentCard") ? true : false}
      MainCardLevel1={(cardStyle && cardStyle === "MainCardLevel1") ? true : false}
    >
      {children}
    </MainTag>
  );
};

export default MainTagContainer;