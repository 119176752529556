import React from "react";
import { Doughnut } from "react-chartjs-2";
import { connect } from "react-redux";
import { compose } from "redux";

const DoughnutChart = ({ chartData, isDarkTheme }) => {
  return (
    <Doughnut
      data={chartData}
      options={{
        title: {
          display: false
        },
        legend: {
          display: true,
          position: "bottom",
          maxWidth: 50,
          align: "center",
          usePointStyle: true,
          pointStyle: "circle",
          backgroundColor: "red",

          labels: {
            fontColor: !isDarkTheme ? "#012243" : "#dedfe0"
          }
        },
        cutoutPercentage: 70,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              var label = data.labels[tooltipItem.index];
              var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return `${label}: $${value}`;
            }
          }
        },
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1
      }}
      height={250}
    />
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(DoughnutChart);
