import { DivElement, TextWrapper, ThreeDotDropdown } from "../../../../components/Reusable";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateOrderBookActiveSide,
  updateOrderBookPricePrecision
} from "../../../../actions/trading";
import { ExpandMoreIcon } from "../../../../assets/svgs";
import {
  AllOrdersIllustration,
  BuyOrdersIllustration,
  SellOrdersIllustration
} from "../../../../assets/svgs/illustrations";
import MoreMenu from "./MoreMenu";

const getDecimalArray = (decimal) => {
  let res = [];
  let i = 0;
  while (i < 4) {
    let val = decimal - i;
    if (val <= 0) return res;
    res.push(val);
    i++;
  }

  return res;
};

const numToDecimalLabel = (num) => {
  let digit = "1";
  let str = "0." + digit.padStart(num, "0");
  return str;
};

const SelectDecimalDropdown = () => {
  const [decimalArr, setDecimalArr] = useState("");
  const selectedPairConfig = useSelector((state) => state.trading.selectedPairConfig);
  const selectedPricePrecision = useSelector((state) => state.trading.selectedPricePrecision);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedPairConfig) {
      setDecimalArr(getDecimalArray(selectedPairConfig?.orderPrecision?.Price));
    } else {
      setDecimalArr(getDecimalArray(8));
    }
  }, [selectedPairConfig]);

  return (
    <DivElement flexAlignCenter>
      <ThreeDotDropdown
        activeContainerProps={{ top: "32px", right: "12px", padding: "2px 0" }}
        imgProps={{ width: "20px", height: "20px" }}
        label={
          <Fragment>
            <TextWrapper secondary lineHeight="20px">
              {numToDecimalLabel(selectedPricePrecision)}
            </TextWrapper>
            <ExpandMoreIcon width="20px" height="20px" />
          </Fragment>
        }
      >
        {decimalArr &&
          decimalArr.map((decimal) => (
            <ThreeDotDropdown.ThreeDotOption
              fontSize="12px"
              padding="0 12px"
              key={decimal}
              sideBorder
              onClick={() => dispatch(updateOrderBookPricePrecision(decimal))}
            >
              {numToDecimalLabel(decimal)}
            </ThreeDotDropdown.ThreeDotOption>
          ))}
      </ThreeDotDropdown>

      <MoreMenu />
    </DivElement>
  );
};

const OrderBookFilters = () => {
  const activeSide = useSelector((state) => state.trading.orderBookActiveSide);
  const dispatch = useDispatch();

  const setActiveSide = (side) => {
    dispatch(updateOrderBookActiveSide(side));
  };

  return (
    <DivElement displayBtw width="100%" marginBottom="16px">
      <DivElement flexAlignCenter height="100%" margin="0 -8px">
        <AllOrdersIllustration
          margin="0 8px"
          pointer
          active={activeSide === "all" ? true : null}
          onClick={() => setActiveSide("all")}
        />
        <BuyOrdersIllustration
          margin="0 8px"
          pointer
          active={activeSide === "buy" ? true : null}
          onClick={() => setActiveSide("buy")}
        />

        <SellOrdersIllustration
          margin="0 8px"
          pointer
          active={activeSide === "sell" ? true : null}
          onClick={() => setActiveSide("sell")}
        />
      </DivElement>
      <SelectDecimalDropdown />
    </DivElement>
  );
};

export default OrderBookFilters;
