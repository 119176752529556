import React from "react";

// import PriceSection from "./components/PriceSection";
// import Tasks from "./components/Tasks";
import SocialMedia from "./components/SocialMedia";
import Faq from "../NewtradingCompetition/components/Faq";
import RewardsHubParent from "./components/RewardsHubParent";
import RewardsTimeOffer from "./components/RewardsTimeOffer/RewardsTimeOffer";

function RewardsHubHomePage() {
  return (
    <RewardsHubParent>
      <RewardsHubParent.PermotionBanner />

      <RewardsHubParent.LimitedTimeOffer>
        <RewardsTimeOffer.ListTimeOfferList />
      </RewardsHubParent.LimitedTimeOffer>

      <RewardsHubParent.RewardsTasks />

      <SocialMedia />
      <Faq type="rewardsHub" />
    </RewardsHubParent>
  );
}

export default RewardsHubHomePage;
