/* eslint-disable react/no-unknown-property */
/**
 * Import Node Modules
 */

import React from "react";
import { withRouter, Link } from "react-router-dom";
// import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../constants/Images/imageIndex";

/**
 * Import Other Dependices
 */
import { BannerBox, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
// import { HeadingSecond } from "../../assets/StyleComponents/FontSize/headsecond.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { ImgElement } from "../../components/Reusable";
import {
  BegieerWebLight,
  BegineerMobileDark,
  BegineerMobileLight,
  BegineerWebDark
} from "../../assets/png";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { FigureComponent } from "../../assets/StyleComponents/Figure.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";

function ActiveTraderPart(props) {
  const { isTheme } = props;
  const { width } = useWindowSize();
  const prevLang = localStorage.getItem("ljs-lang");

  return (
    <BannerSection background={!isTheme && "white"} position={"relative"} zIndex={0}>
      <BannerBox width={"100%"} position={"absolute"} bottom="0">
        {!isTheme ? (
          <video
            id="background-video"
            autoPlay
            loop
            muted
            style={{ width: "100%", height: "601px", objectFit: "cover", opacity: 0.5 }}
            src="https://lcx-exchange.s3.amazonaws.com/landing_banner/TradeLightTheme.mp4"
            playsInline
            webkit-playsinline
          ></video>
        ) : (
          <video
            id="background-video"
            autoPlay
            loop
            muted
            style={{ width: "100%", height: "601px", objectFit: "cover", opacity: 0.5 }}
            src="https://lcx-exchange.s3.amazonaws.com/landing_banner/TradeDarkTheme.mp4"
            playsInline
            webkit-playsinline
          ></video>
        )}
      </BannerBox>
      <BannerBox ExchangeContainer position="relative">
        <BannerBox
          display="flex"
          alignItems="center"
          direction="row-reverse"
          directionLg="column"
          gap="30px"
          gapLg="64px"
          gapMd="48px"
          justifyContentLg="center"
        >
          <BannerBox paddLeft="88px" paddLeftLg="0px">
            <BannerHeading
              size="44px"
              sizeLg="36px"
              sizeMd="24px"
              textAlign="left"
              textAlignLg="center"
              mbLg={"16px"}
              mbMd="8px"
              BodyColor
            >
              Are you a beginner?
            </BannerHeading>

            <ParaTag
              txtGrey
              size="20px"
              sizeMd="12px"
              weight="300"
              mt={"16px"}
              mb={"44px"}
              mbLg="32px"
              mbMd="24px"
              textAlign="left"
              textAlignLg="center"
              pr="2rem"
              pb="1rem"
              pxMd="1px"
            >
              {
                "Don't worry, we've got you covered, check out our beginners guide and tutorials to start your crypto journey with LCX!"
              }
            </ParaTag>

            <BannerBox display="flex" justifyContentLg="center">
              <Link to={`${prevLang ? `/${prevLang}` : "/en"}/tutorials`}>
                <ButtonComponent
                  // className="pd-x-rem-6 pd-md-x-16 f-md-14"
                  btnHeight="44px"
                  // btnHeightMd="48px"
                  size="16px"
                  sizeMd="14px"
                  minWidth="168px"
                  widthLg="207px"
                  widthMd="155px"
                  mt="16px"
                  mtMd="8px"
                  PrimaryButton
                >
                  Start Learning
                </ButtonComponent>
              </Link>
            </BannerBox>

            {/* <br className={AllStyle.exch_desktop_trade} /> */}
          </BannerBox>

          <FigureComponent mtLg="0px">
            {width > 767 ? (
              <ImgElement
                width={width < 1024 && width > 767 ? "644px" : "600px"}
                height={width < 1024 && width > 767 ? "542px" : "505px"}
                src={!isTheme ? BegieerWebLight : BegineerMobileDark}
                alt="lcx"
              />
            ) : (
              <ImgElement
                width="328px"
                height="340px"
                src={!isTheme ? BegineerMobileLight : BegineerWebDark}
                alt="lcx"
              />
            )}
          </FigureComponent>
        </BannerBox>
      </BannerBox>
    </BannerSection>
  );
}

export default withRouter(ActiveTraderPart);
