import {
  MARKET_ALL_TICKER, 
  UPDATE_MARKET_ALL_TICKER, 
  UPDATE_SINGLE_TICKER_DATA
} from "./const.js";

// Call All Ticker API...................
export const callAllTickerMarket = (getValue) => {
  return {
    type: MARKET_ALL_TICKER,
    payload: getValue
  };
};

//=> Update All Ticker through socket ...................
export const updateAllTickerMarket = (getValue) => {
  return {
    type: UPDATE_MARKET_ALL_TICKER,
    payload: getValue
  };
};

//=> Update All Ticker through socket ...................
export const updateSingleTickerData = (getValue) => {
  return {
    type: UPDATE_SINGLE_TICKER_DATA,
    payload: getValue
  };
};