import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { DivElement } from "../../../../../components/Reusable";

const Tab = styled(DivElement)`
  padding: 8px;
  margin: 0 8px;
  color: ${(props) => props.theme.primaryText};
  cursor: pointer;
  height: 34px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;

  &:hover {
    color: ${(props) => props.theme.primaryBlue};
    background-color: ${(props) => props.theme.inputActive};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.primaryBlue};
      background-color: ${(props) => props.theme.inputActive};
    `}
`;

const CategoryFilter = ({ filter, setFilter, coinFilter }) => {
  const pairCategoryList = useSelector((state) => state.trading.pairCategoryList);
  return (
    <Fragment>
      {pairCategoryList && pairCategoryList[coinFilter] ? (
        <DivElement height="58px" scrollX flexAlignCenter paddingSm="0 16px">
          {pairCategoryList[coinFilter].map((data) => (
            <Tab onClick={() => setFilter(data)} active={filter === data ? true : null} key={data}>
              {data}
            </Tab>
          ))}
        </DivElement>
      ) : (
        <DivElement height="16px" width="100%"></DivElement>
      )}
    </Fragment>
  );
};

export default CategoryFilter;
