import React from "react";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../constants/Images/imageIndex";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { compose } from "redux";
import { connect } from "react-redux";
import { FigureTag } from "../../assets/StyleComponents/FigureTag.style";
import { ImgElement } from "../Reusable";
import moment from "moment";

const HalfFooter = ({ isDarkTheme }) => {
  // const { width } = useWindowSize();
  return (
    <>
        <>
          <BannerBox width="100%" display="flex" margin="10px 0px" directionMd="column" gapMd="15px">
            <BannerBox width="50%" widthMd="100%">
              <ParaTag weight="300" size="16px" txtGrey>
                {" "}
                LCX AG &copy; {moment().format("YYYY")}. All Rights Reserved{" "}
              </ParaTag>
            </BannerBox>

            <BannerBox width="50%" widthMd="100%">
              <BannerBox 
                display="flex"
                justifyContent="flex-end"
                justifyContentMd="start"
                flex="1 1 0"
                flexWrap="wrap"
                gap="10px"
              >
                <BannerHeadingSpan mr="4px" ml="4px" size="17px">
                  <a
                    href="https://t.me/JoinLCX"
                    target="blank"
                  >
                    <FigureTag>
                      <ImgElement
                        src={
                          !isDarkTheme ? IMAGE_PREFIX.TelegramIcon : IMAGE_PREFIX_DARK.TelegramIcon
                        }
                        alt="telegram"
                      />
                    </FigureTag>
                  </a>
                </BannerHeadingSpan>
                <BannerHeadingSpan mr="4px" ml="4px" size="17px" >
                  <a
                    href="https://twitter.com/lcx"
                    target="blank"
                  >
                    <FigureTag className="mb-0">
                      <ImgElement
                        src={
                          !isDarkTheme ? IMAGE_PREFIX.TwitterIcon : IMAGE_PREFIX_DARK.TwitterIcon
                        }
                        alt="twitter"
                      />
                    </FigureTag>
                  </a>
                </BannerHeadingSpan>
                <BannerHeadingSpan mr="4px" ml="4px" size="17px">
                  <a
                    href="https://www.instagram.com/lcx.exchange/"
                    target="blank"
                  >
                    <FigureTag className="mb-0">
                      <ImgElement
                        src={
                          !isDarkTheme
                            ? IMAGE_PREFIX.InstagramIcon
                            : IMAGE_PREFIX_DARK.InstagramIcon
                        }
                        alt="Insta"
                      />
                    </FigureTag>
                  </a>
                </BannerHeadingSpan>

                <BannerHeadingSpan mr="4px" ml="4px" size="17px">
                  <a
                    href="https://www.linkedin.com/company/lcx"
                    target="blank"
                  >
                    <FigureTag className="mb-0">
                      <ImgElement
                        src={
                          !isDarkTheme ? IMAGE_PREFIX.LinkdinIcon : IMAGE_PREFIX_DARK.LinkdinIcon
                        }
                        alt="Linkdin"
                      />
                    </FigureTag>
                  </a>
                </BannerHeadingSpan>

                <BannerHeadingSpan mr="4px" ml="4px" size="17px">
                  <a
                    href="https://www.youtube.com/channel/UCNj78acRE-ywQPim-wZ8RTQ"
                    target="blank"
                  >
                    <FigureTag>
                      <ImgElement
                        src={
                          !isDarkTheme ? IMAGE_PREFIX.YoutubeIcon : IMAGE_PREFIX_DARK.YoutubeIcon
                        }
                        alt="youtube"
                      />
                    </FigureTag>
                  </a>
                </BannerHeadingSpan>

                <BannerHeadingSpan mr="4px" ml="4px" size="17px">
                  <a
                    href="https://www.facebook.com/LCX.exchange/"
                    target="blank"
                  >
                    <FigureTag>
                      <ImgElement
                        src={
                          !isDarkTheme ? IMAGE_PREFIX.FacebookIcon : IMAGE_PREFIX_DARK.FacebookIcon
                        }
                        alt="Facebook"
                      />
                    </FigureTag>
                  </a>
                </BannerHeadingSpan>
              </BannerBox>
            </BannerBox>
          </BannerBox>
        </>
    </>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(HalfFooter);
