/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";
import { PhoneInputStyles } from "../../../assets/StyleComponents/DatePicker.style";
// import axios from "axios";

const FormPhoneInput = ({
  title,
  name,
  value,
  inputProps,
  errorProps,
  setFieldValue,
  handleBlur,
  error,
  setDialCode,
  containerClass
}) => {
  const [defaultCountry, setDefaultCountry] = useState("");
  // const [dailCode, setDailingCode] = useState("");

  useEffect(() => {
    const fetchCountryCode = () => {
      fetch("https://ipapi.co/json/", {
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          const countryCode = data.country_code.toLowerCase();
          setDefaultCountry(countryCode);
        })
        .catch(() => {
          return;
          // console.log("Error fetching country code:", error); // eslint-disable-line no-console
        });
    };

    if (value && value.charAt(0) === "+") {
      return;
    } else {
      fetchCountryCode();
    }
  }, []);

  const handlePhoneNumber = (value, data) => {
    setDialCode(data.dialCode);
    setFieldValue(name, value);
  };
  return (
    <BannerBox width="100%" className={`fInput__form__field ${containerClass || ""}`} mb="32px">
      <Label txtGrey size="14px" width="100%" mb="8px">
        {title}
      </Label>
      <BannerBox
        FormContainer
        className={`fInput__form__input fInput__form__input--phone ${
          error ? "fInput__form__input--bRed" : ""
        }`}
      >
        <PhoneInputStyles
          name={name}
          country={defaultCountry}
          value={value}
          onBlur={handleBlur}
          onChange={handlePhoneNumber}
          masks={{ ci: ".. .. ... ..." }}
          {...inputProps}
        />
      </BannerBox>
      <ErrorMessageDiv
        name={name}
        component="div"
        mt="3px"
        size="14px"
        sizeMd="12px"
        weight="300"
        txtDanger
        {...errorProps}
      />
    </BannerBox>
  );
};

export default FormPhoneInput;
