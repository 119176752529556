import React from "react";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../../assets/StyleComponents/FontSize/headingFourth.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { SuccessfullIconRefral, TotalEarningsIcon2, TotalReferal } from "../../../assets/svgs";
// import Skeleton from "react-loading-skeleton";
import FilterEarningOverview from "../../../components/Modules/Referral/FilterEarningOverview";
import { HeadingThird } from "../../../assets/StyleComponents/FontSize/headingThird.style";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";

// import { RouterLink } from "../../../components/Reusable";
import EarningOverviewTable from "./EarningOverviewtable";
import { formatNumbers } from "../../../utils/helpers";
import { numberWithCommas } from "../../../utils/general";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";

function DashboardReferal({
  isLoggedIn,
  referralData,
  page,
  setPage,
  setAscending,
  ascending,
  onApplyFilterHandler,
  isLoading
}) {
  const { totalEarnings, successfulCount, totalCount } = referralData;
  const { width } = useWindowSize();
  const history = useHistory();
  const userData = useSelector((state) => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");
  const Data = [
    {
      title: "Total",
      last: "Earnings",
      numbers: totalEarnings || 0,
      icon: <TotalEarningsIcon2 width="80px" height="80px" widthMd="48px" heightMd="48px" />
    },
    {
      title: "Successful",
      last: "Referrals",
      numbers: successfulCount || 0,
      icon: <SuccessfullIconRefral width="80px" height="80px" widthMd="48px" heightMd="48px" />
    },
    {
      title: "Total",
      last: "Referrals",
      numbers: totalCount || 0,
      icon: <TotalReferal width="80px" height="80px" widthMd="48px" heightMd="48px" />
    }
  ];

  // Use a variable to hold the JSX output
  let content;

  if (!isLoggedIn) {
    content = (
      <BannerBox
        direction="column"
        alignItems="center"
        display="flex"
        CardWhite
        padding="40px"
        margin="16px 0px"
        paddingMd="24px"
        radius="8px"
        gap="24px"
      >
        <HeadingThird size="24px" weight="500">
          Start Earning Now
        </HeadingThird>
        <ButtonComponent
          width="194px"
          height="64px"
          size="20px"
          onClick={() =>
            history.push(
              `${
                prevLang
                  ? `/${prevLang}`
                  : userData.preferredLanguage
                  ? `/${userData.preferredLanguage}`
                  : ""
              }/register`,
              { fromReferral: true }
            )
          }
        >
          Login or Register
        </ButtonComponent>
      </BannerBox>
    );
  } else {
    content = (
      <BannerBox CardWhite padding="40px" margin="16px 0px" radius="8px" paddingMd="24px">
        <BannerBox
          bottomBorder={width > 767}
          mb="32px"
          pb="32px"
          pbMd="0px"
          mbMd="10px"
          display="flex"
          justifyContent="space-between"
          alignItemsMd="flex-start"
          alignItems="center"
          directionMd="column"
          gapMd="20px"
        >
          <HeadingFourth weight="500" size="24px" BodyColor sizeLg="20px" sizeMd="16px">
            Earning Overview
          </HeadingFourth>
          <BannerBox width="60%" widthMd="100%">
            <FilterEarningOverview onApplyFilterHandler={onApplyFilterHandler} />
          </BannerBox>
        </BannerBox>
        <BannerBox
          display="grid"
          gridColumn="repeat(3,1fr)"
          gridTemplateColums
          gridColumnMd="1fr"
          bottomBorder={width > 767}
          pb="32px"
          pbMd="12px"
        >
          {Data.map((values, index) => {
            return (
              <BannerBox
                key={index}
                display="flex"
                alignItems="center"
                alignItemsLg="flex-end"
                justifyContent="space-between"
                leftBorder={index === 1 && width > 767}
                rightBorder={index === 1 && width > 767}
                bottomBorder={width < 767 && index !== Data.length - 1}
                pl="24px"
                pr="24px"
                gap="56px"
                gapMd="16px"
                paddingMd="16px 0px"
              >
                <BannerBox
                  display="flex"
                  gap="18px"
                  alignItems="center"
                  directionLg="column-reverse"
                  directionMd="row"
                >
                  {values.icon}
                  <BannerHeading size="18px" weight="500" txtGrey sizeLg="14px">
                    {values.title}
                    <br />
                    {values.last}
                  </BannerHeading>
                </BannerBox>
                {!isLoading ? (
                  <HeadingFourth size="30px" weight="700" sizeLg="26px" sizeMd="22px">
                    {values.last === "Earnings"
                      ? `$${numberWithCommas(formatNumbers(values.numbers, 2))}`
                      : numberWithCommas(formatNumbers(values.numbers, 2))}
                  </HeadingFourth>
                ) : (
                  <SkeletonLoader width="80px" height="40px" />
                )}
              </BannerBox>
            );
          })}
        </BannerBox>
        <EarningOverviewTable
          referralData={referralData}
          setAscending={setAscending}
          setPage={setPage}
          page={page}
          ascending={ascending}
          isLoading={isLoading}
          width={width}
        />
      </BannerBox>
    );
  }

  // Return the content variable
  return content;
}

export default DashboardReferal;
