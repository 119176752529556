import React from "react";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { H1Tag } from "../../../assets/StyleComponents/FontSize/fonts.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { HeadingThird } from "../../../assets/StyleComponents/FontSize/headingThird.style";
import { ArrowIllustration, ReferralIllustration } from "../../../assets/svgs";

function ReferralBanner() {
  return (
    <>
      <ArrowIllustration
        displaySm="none"
        absolute
        width="156px"
        height="226px"
        top="-58px"
        left="-9px"
        leftMd="-19px"
      />
      <BannerBox
        display="flex"
        justifyContent="center"
        direction="column"
        width="100%"
        height="100%"
        alignItems="center"
      >
        <BannerBox>
          <H1Tag
            size="128px"
            weight="600"
            txtPrimary
            sizeLg="88px"
            sizeMd="40px"
            textAlignMd="center"
          >
            40%
          </H1Tag>
          <BannerHeading
            size="24px"
            weight="400"
            txtGrey
            sizeLg="16px"
            sizeMd="14px"
            textAlignMd="center"
          >
            Earnings on Your Friends' Every Trade at LCX!
          </BannerHeading>
          <HeadingThird
            size="36px"
            weight="500"
            mt="20px"
            mb="20px"
            sizeLg="24px"
            sizeMd="20px"
            textAlignMd="center"
          >
            Your Friends !! Your Rewards
          </HeadingThird>
        </BannerBox>
        <BannerBox>
          <ReferralIllustration
            width="345px"
            height="345px"
            widthMd="220px"
            heightMd="220px"
            widthSm="80px"
            heightSm="80px"
          />
        </BannerBox>
      </BannerBox>
    </>
  );
}

export default ReferralBanner;
