import themeSwitcher from "../../assets/images/moon.svg";
import logo_grey from "../../assets/img/logo.png";
import Lcx_logo from "../../assets/svg/lcx_logo_light.svg";
import WhiteLcxLogo from "../../assets/svg/lcx_logo_White.svg";
import BlueLcxLogo from "../../assets/svg/logo_blue.svg";
import logo_white from "../../assets/images/logos/lcx_logo_white1.svg";
import StarIcon from "../../assets/svg/StarIcon.svg";
import BorderStarIcon from "../../assets/svg/Bordered_Star.svg";
import ChangeLayoutIcon from "../../assets/svg/changeLayoutIcon.svg";
import SupportLightIcon from "../../assets/svg/support.svg";
import WebIcon from "../../assets/svg/web.svg";
import MoonIcon from "../../assets/svg/moon.svg";
import ChevronIcon from "../../assets/svg/chevron-down.svg";
import ChevronBlueIcon from "../../assets/svg/chevron-right.svg";
import EyeOffIcon from "../../assets/svg/eye-off.svg";
import EyeOnIcon from "../../assets/svg/eye-on.svg";
import DotIcon from "../../assets/svg/dot.svg";
import CheckIcon from "../../assets/svg/checkIcon.svg";
import SuccessCheckIcon from "../../assets/svg/successCheck.svg";
import LightThemeIcon from "../../assets/img/Light_Theme.png";
import DarkThemeIcon from "../../assets/img/Dark_Theme.svg";
import DashboardOpenOrderIconLight from "../../assets/svg/Dashboard-Openorders.svg";
import ThreeDotIcon from "../../assets/svg/three-dot.svg";
import CrossIcon from "../../assets/svg/crossIcon.svg";
import CryptoIcon from "../../assets/img/crypto.png";
import FingerPrintIcon from "../../assets/svg/fingerprint.svg";
import CopyIcon from "../../assets/svg/copy.svg";
import CopyIconWhite from "../../assets/svg/copy-white.svg";
import CopyIconBlue from "../../assets/svg/copyBlue.svg";
import SearchIcon from "../../assets/svg/search.svg";
import ETHIcon from "../../assets/img/eth.png";
import Icon404 from "../../assets/svg/404.svg";
import Icon500 from "../../assets/svg/500.svg";
import Group343Icon from "../../assets/img/Group 343.png";
import MailIcon from "../../assets/svg/mail.svg";
import MapPinIcon from "../../assets/svg/map-pin.svg";
import TelegramIcon from "../../assets/svg/telegram.svg";
import TwitterIcon from "../../assets/svg/twitter.svg";
import InstagramIcon from "../../assets/svg/instagram.svg";
import LinkdinIcon from "../../assets/svg/linkdin.svg";
import YoutubeIcon from "../../assets/svg/youtube.svg";
import FacebookIcon from "../../assets/svg/facebook.svg";
import PlusIcon from "../../assets/svg/plus.svg";
import Slider2Icon from "../../assets/img/slider/slide2.jpg";
import WaveIconLight from "../../assets/svg/layered-peaks-haikei.svg";
import NoRecordFirstIconLight from "../../assets/svg/no-record-found.svg";
import NoRecordIconLight from "../../assets/svg/no-record-found1.svg";
import AvatarIconLight from "../../assets/svg/21-avatar-outline.svg";
import CameraIconLight from "../../assets/svg/61-camera-outline.svg";
import CoinIconLight from "../../assets/svg/298-coins-outline.svg";
import ArrowIconLight from "../../assets/svg/220-arrow-9.svg";
import AvatarIconGif from "../../assets/gif/Avatar.gif";
import CameraIconGif from "../../assets/gif/Camera.gif";
import CoinIconGif from "../../assets/gif/Coins.gif";
import ArrowIconGif from "../../assets/gif/arrow.gif";
import QuoteIcon from "../../assets/svg/Icon awesome-quote-left.svg";
import TestimonialIcon from "../../assets/img/Testimonial.png";
import LeftQuoteIcon from "../../assets/svg/Icon awesome-quote-left.svg";
import Group568Icon from "../../assets/img/Group 568.png";
import CalendarIcon from "../../assets/svg/calendar.svg";
import PreviousIcon from "../../assets/svg/previous.svg";
import NextIcon from "../../assets/svg/next.svg";
import FileTextIcon from "../../assets/svg/file-text.svg";
import ShieldIcon from "../../assets/svgs/all/shieldBlue.svg";
import LockIcon from "../../assets/svg/lock.svg";
import StarPasswordIcon from "../../assets/img/Star_Password.png";
import SecurityIcon from "../../assets/img/Security.png";
import EditIcon from "../../assets/svg/Edit.svg";
import EditDisabledIcon from "../../assets/svg/editDisabled.svg";
import DeleteIcon from "../../assets/svg/Delete.svg";
import ArrowLeftIcon from "../../assets/svg/arrow-left.svg";
import TradeCrossIcon from "../../assets/svg/tradeCross.svg";
import Blur1Icon from "../../assets/images/blur1.png";
import Blur2Icon from "../../assets/images/blur2.png";
import LoginIcon from "../../assets/images/Login.svg";
import Comp1Icon from "../../assets/svg/Comp1.svg";
import Comp2Icon from "../../assets/svg/Comp2.svg";
import BlueSearchIcon from "../../assets/svg/blueSearch.svg";
import HelpCircleIcon from "../../assets/svg/help-circle.svg";
import BlueTransferIcon from "../../assets/svg/blueTransferIcon.svg";
import CorrectIcon from "../../assets/images/correct.png";
import TransferIconLight from "../../assets/svg/Transfer.svg";
import TradeIconLight from "../../assets/svg/Trade.svg";
import DepositIconLight from "../../assets/svg/Deposit.svg";
import WithdrawIconLight from "../../assets/svg/Withdraw.svg";
import ProgressSuccess from "../../assets/svg/ProgressSuccess.svg";
import HackingIcon from "../../assets/svg/hacking.svg";
import TwoFaIcon from "../../assets/svg/2fa.svg";
import Lock1Icon from "../../assets/svg/lock1.svg";
import PasswordPageIcon from "../../assets/svg/pwdIcon.svg";
import SearchInFileIcon from "../../assets/svg/search-in-file.svg";
import FeePageIcon from "../../assets/img/feePage.png";
import FeeDiscountIcon from "../../assets/svg/feeDiscount.svg";
import NotificationBannerIcon from "../../assets/svg/notificationBanner.svg";
import InfoIcon from "../../assets/svg/info.svg";
import ArrowLeftRightIcon from "../../assets/svg/arrow-left-right.svg";
import ArrowDown from "../../assets/svg/arrow-down.svg";
import ArrowUp from "../../assets/svg/arrow-up.svg";
import WalletIcon from "../../assets/svg/walletIcon.svg";
import CheckCircleIcon from "../../assets/svg/check-circle.svg";
import SuccessCheckCircleIcon from "../../assets/svg/checkSuccessCircle.svg";
import FillCircleIcon from "../../assets/svg/check-circle1.svg";
import SignalLogoIcon from "../../assets/svg/signal_logo.svg";
import LinkLogoIcon from "../../assets/svg/link.svg";
import BlueLinkLogoIcon from "../../assets/svg/blueLink.svg";
import HourGlassLogoIcon from "../../assets/svg/hour_glass.svg";
import UpDownIcon from "../../assets/svg/up-down.svg";
import LaptopIcon from "../../assets/svg/laptop1.svg";
import UniswapIcon from "../../assets/img/uniswap.png";
import LiquidIcon from "../../assets/img/coin/liquid.png";
import LCXIcon from "../../assets/img/coin/LCX.png";
import CoinBaseProIcon from "../../assets/img/coin/coinbasePro.png";
import NotificationIcon from "../../assets/svg/notification.svg";
import HelpIcon from "../../assets/svg/help.svg";
import AlertTriangle from "../../assets/svg/alert-triangle.svg";
import HamburgerIcon from "../../assets/svg/hamburger.svg";
import SwapIcon from "../../assets/svg/swap.svg";
import MoneriumBankLogo from "../../assets/img/moneriumBank.png";
import DeleteBoxIcon from "../../assets/svg/delete-box.svg";
import ApplicationIcon from "../../assets/svg/appreciationIcon.svg";
import SnowBoardingIcon from "../../assets/svg/Snowboarding.svg";
import FeeTextSmallIcon from "../../assets/img/fee-text.png";
import KycIcon from "../../assets/svg/kyc.svg";
import NotabeanIcon from "../../assets/svg/notabean.svg";
import SecurityFileIcon from "../../assets/svgs/all/securityFile.svg";
import SecurityLockIcon from "../../assets/svgs/all/securityLock.svg";
import SecurityAccountIcon from "../../assets/svgs/all/securityAccount.svg";
import SecurityWalletIcon from "../../assets/svg/securityWallet.svg";
import SettingsIcon from "../../assets/svg/settings.svg";
import UserIcon from "../../assets/svg/profile.svg";
import LogoutIcon from "../../assets/svg/logout.svg";
import InfoBlueIcon from "../../assets/svg/infoBlue.svg";
import ExclamIcon from "../../assets/svg/exclam.svg";
import ExclamFillIcon from "../../assets/svg/exclamFill.svg";
import ExclamationIcon from "../../assets/svg/exclamation.svg";
import FingerPrintGreyIcon from "../../assets/svg/fingerprint_grey.svg";
import FingerPrintOrangeIcon from "../../assets/svg/fingerprint_orange.svg";
import FingerPrintRedIcon from "../../assets/svg/fingerprint_red.svg";
import DepositDropDownIcon from "../../assets/svg/depositDrop.svg";
import GraphDropDownIcon from "../../assets/svg/graphDrop.svg";
import TransferDropDownIcon from "../../assets/svg/transferDrop.svg";
import WithdrawDropDownIcon from "../../assets/svg/withdrawDrop.svg";
import KrakenIcon from "../../assets/svg/kraken.svg";
import DollarSignIcon from "../../assets/svg/dollarSign.svg";
import DollarSignVolumeIcon from "../../assets/svg/dollarSignVolume.svg";
import DollarSignMobileIcon from "../../assets/svg/dollarSignMobile.svg";
import DollarSignBoldIcon from "../../assets/svg/dollarSignBold.svg";
import BagIcon from "../../assets/svg/bag.svg";
import DoubleDownArrowIcon from "../../assets/svg/doubleDownArrow.svg";
import NetworkIcon from "../../assets/svg/networkSignal.svg";
import ChatSocketIcon from "../../assets/svg/chatSocket.svg";
import NoNetworkIcon from "../../assets/svg/noNetworkSignal.svg";
import MobileAppIcon from "../../assets/svg/mobileImage.svg";
import GoogleAppIcon from "../../assets/svg/googleApp.svg";
import IphoneAppIcon from "../../assets/svg/iphoneApp.svg";
import FiatCardIcon from "../../assets/svg/fiat_card.svg";
import ToastRedInfo from "../../assets/svg/ToastRedInfo.svg";
import CoinDCX from "../../assets/svg/CoinDCX.svg";
import MaestroWrappedIcon from "../../assets/svg/maestro_wrapped.svg";
import MaestroLogo from "../../assets/svg/maestro_logo.svg";
import MastercardWrappedIcon from "../../assets/svg/mastercard_wrapped.svg";
import MastercardLogo from "../../assets/svg/mastercard_logo.svg";
import VisacardWrappedIcon from "../../assets/svg/visa_wrapped.svg";
import VisaLogo from "../../assets/svg/visa_logo.svg";
import NewTagIcon from "../../assets/svg/new_tag.svg";
import AlertCircleRedIcon from "../../assets/svg/alert_circle.svg";
import GreenCheckIcon from "../../assets/svg/green-check.svg";
import LinkBlueIcon from "../../assets/svg/link_blue.svg";
import Web from "../../assets/images/web.svg";
import Tablet from "../../assets/images/tablet.svg";
import Mobile from "../../assets/images/mobile.svg";
import Mobile_light from "../../assets/images/mobile_light.svg";
import FeeInfo from "../../assets/img/fee-info.svg";
import DepositeNew from "../../assets/svg/depositeNew.svg";
import WithdrawNew from "../../assets/svg/WithdrawNew.svg";
import TransferNew from "../../assets/svg/TransferNew.svg";
import TabDeposit from "../../assets/svg/tabdeposit.svg";
import TabWidthraw from "../../assets/svg/tabwithdraw.svg";
import Tabtransfer from "../../assets/svg/tabTransfer.svg";
import Share from "../../assets/img/share.svg";
import Bluefacebook from "../../assets/svg/bluefacebook.svg";
import BlueLinkdin from "../../assets/svg/blueLinkdin.svg";
import BlueTelegram from "../../assets/svg/bluetelegram.svg";
import BlueMail from "../../assets/svg/blueMail.svg";
import BlueLink from "../../assets/svg/blueLinkNew.svg";
import TimerLink from "../../assets/svg/timer.svg";
import Trophy from "../../assets/svg/trophy.svg";
import TradingmainAni from "../../assets/images/tradingMain.jpg";
import LeaderBoardwhite from "../../assets/images/leaderboardWhite.jpg";
import dot2 from "../../assets/svg/dot2.svg";
import TickCompedition from "../../assets/svg/tickComp.svg";
import FileIcon from "../../assets/img/fileIcon.svg";
import BinIcon from "../../assets/svg/Biin.svg";
import BackIconMain from "../../assets/svg/back_light.svg";
import HammerHome from "../../assets/svg/hammer.svg";
import SecurityHome from "../../assets/svg/securityHome.svg";
import AdvancedTradingTool from "../../assets/svg/AdvancedTradingTool.svg";
import HammerGif from "../../assets/gif/hammerLight.gif";
import SecurityGif from "../../assets/gif/securityLight.gif";
import AdvancedTradingToolGif from "../../assets/gif/advancedLight.gif";
import OuterBorder from "../../assets/svg/outer_border.svg";
import ExclamationCircleRedIcon from "../../assets/svg/exclamation_circle_red.svg";
import NewTagOrangeIcon from "../../assets/svg/new_tag_orange.svg";
import DepositOrangeIcon from "../../assets/svg/deposit_orange.svg";
import LightningIcon from "../../assets/svg/Lightning.svg";
import WithdrawalOrangeIcon from "../../assets/svg/withdrawal_orange.svg";
import PermotionImg from "../../assets/img/permotion.jpg";

const IMAGE_PREFIX = {
  CoinDCX,
  BackIconMain,
  AdvancedTradingToolGif,
  SecurityGif,
  HammerGif,
  TickCompedition,
  HammerHome,
  SecurityHome,
  AdvancedTradingTool,
  dot2,
  TradingmainAni,
  LeaderBoardwhite,
  Trophy,
  DepositeNew,
  Bluefacebook,
  BlueLinkdin,
  BlueTelegram,
  BlueMail,
  BlueLink,
  BinIcon,
  TabDeposit,
  TabWidthraw,
  themeSwitcher,
  Tabtransfer,
  WithdrawNew,
  TransferNew,
  UpDownIcon,
  LaptopIcon,
  UniswapIcon,
  LiquidIcon,
  NotificationIcon,
  logo_grey,
  logo_white,
  StarIcon,
  ChangeLayoutIcon,
  SupportLightIcon,
  LCXIcon,
  WhiteLcxLogo,
  WebIcon,
  MoonIcon,
  ChevronIcon,
  ChevronBlueIcon,
  EyeOffIcon,
  EyeOnIcon,
  SuccessCheckIcon,
  LightThemeIcon,
  DarkThemeIcon,
  CheckIcon,
  DashboardOpenOrderIconLight,
  GraphDropDownIcon,
  ThreeDotIcon,
  CrossIcon,
  CryptoIcon,
  Lcx_logo,
  BlueLcxLogo,
  HelpIcon,
  HamburgerIcon,
  FingerPrintIcon,
  CopyIcon,
  CopyIconBlue,
  SearchIcon,
  ETHIcon,
  Icon404,
  Icon500,
  Group343Icon,
  MailIcon,
  MapPinIcon,
  TelegramIcon,
  TwitterIcon,
  InstagramIcon,
  LinkdinIcon,
  YoutubeIcon,
  FacebookIcon,
  PlusIcon,
  Slider2Icon,
  WaveIconLight,
  BorderStarIcon,
  NoRecordIconLight,
  NoRecordFirstIconLight,
  AvatarIconLight,
  SwapIcon,
  CoinBaseProIcon,
  CameraIconLight,
  CoinIconLight,
  ArrowIconLight,
  DeleteBoxIcon,
  DepositDropDownIcon,
  AvatarIconGif,
  CameraIconGif,
  CoinIconGif,
  ArrowIconGif,
  QuoteIcon,
  TestimonialIcon,
  LeftQuoteIcon,
  Group568Icon,
  CalendarIcon,
  PreviousIcon,
  NextIcon,
  FileTextIcon,
  ShieldIcon,
  LockIcon,
  StarPasswordIcon,
  SecurityIcon,
  EditIcon,
  DeleteIcon,
  ArrowLeftIcon,
  TradeCrossIcon,
  Blur1Icon,
  Blur2Icon,
  LoginIcon,
  Comp1Icon,
  Comp2Icon,
  BlueSearchIcon,
  HelpCircleIcon,
  BlueTransferIcon,
  CorrectIcon,
  TransferIconLight,
  ApplicationIcon,
  KycIcon,
  TransferDropDownIcon,
  TradeIconLight,
  DepositIconLight,
  WithdrawIconLight,
  SnowBoardingIcon,
  WithdrawDropDownIcon,
  ProgressSuccess,
  HackingIcon,
  TwoFaIcon,
  Lock1Icon,
  PasswordPageIcon,
  SuccessCheckCircleIcon,
  SearchInFileIcon,
  CopyIconWhite,
  FeePageIcon,
  FeeDiscountIcon,
  NotificationBannerIcon,
  InfoIcon,
  ArrowLeftRightIcon,
  ArrowUp,
  WalletIcon,
  CheckCircleIcon,
  SignalLogoIcon,
  LinkLogoIcon,
  BlueLinkLogoIcon,
  HourGlassLogoIcon,
  ArrowDown,
  AlertTriangle,
  MoneriumBankLogo,
  FeeTextSmallIcon,
  NotabeanIcon,
  SecurityFileIcon,
  SecurityLockIcon,
  SecurityAccountIcon,
  SecurityWalletIcon,
  SettingsIcon,
  UserIcon,
  LogoutIcon,
  InfoBlueIcon,
  ExclamIcon,
  ExclamFillIcon,
  ExclamationIcon,
  FillCircleIcon,
  FingerPrintGreyIcon,
  FingerPrintOrangeIcon,
  FingerPrintRedIcon,
  DotIcon,
  KrakenIcon,
  DollarSignIcon,
  DollarSignMobileIcon,
  DollarSignVolumeIcon,
  DollarSignBoldIcon,
  BagIcon,
  EditDisabledIcon,
  DoubleDownArrowIcon,
  NetworkIcon,
  ChatSocketIcon,
  NoNetworkIcon,
  MobileAppIcon,
  GoogleAppIcon,
  IphoneAppIcon,
  FiatCardIcon,
  ToastRedInfo,
  MaestroLogo,
  MaestroWrappedIcon,
  MastercardLogo,
  MastercardWrappedIcon,
  VisaLogo,
  VisacardWrappedIcon,
  NewTagIcon,
  AlertCircleRedIcon,
  GreenCheckIcon,
  LinkBlueIcon,
  Web,
  Mobile,
  Tablet,
  Mobile_light,
  FeeInfo,
  Share,
  TimerLink,
  FileIcon,
  OuterBorder,
  ExclamationCircleRedIcon,
  NewTagOrangeIcon,
  DepositOrangeIcon,
  LightningIcon,
  WithdrawalOrangeIcon,
  PermotionImg
};

export { IMAGE_PREFIX };
