import { connect } from "react-redux";
import { compose } from "redux";
import styled, { css } from "styled-components";

const Thead = styled.thead`
  height: ${(props) => (props.height ? props.height : "40px")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "0px")};
  background-color: ${(props) => (props.isDarkTheme ? "#1B2631" : "#f5f6fa")};
  color: ${(props) => (props.isDarkTheme ? "#a7a9ab" : "#606f80")};
  display: ${(props) => props.display};
  border-bottom: ${(props) => props.borderBottom} !important;

  @media (max-width: 1024px) {
    height: ${(props) => (props.heightMd ? props.heightMd : "")};
  }
  @media (max-width: 767px) {
    height: ${(props) => (props.heightSm ? props.heightSm : "")};
  }

  tr th {
    vertical-align: ${(props) => (props.verticalAlign ? props.verticalAlign : "")};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "")};

    @media (max-width: 1024px) {
      vertical-align: ${(props) => (props.verticalAlignMd ? props.verticalAlignMd : "")};
      font-weight: ${(props) => (props.fontWeightMd ? props.fontWeightMd : "")};
      font-size: ${(props) => (props.fontSizeMd ? props.fontSizeMd : "")};
      padding-top: ${(props) => (props.paddingTopMd ? props.paddingTopMd : "")};
    }

    @media (max-width: 767px) {
      vertical-align: ${(props) => (props.verticalAlignSm ? props.verticalAlignSm : "")};
      font-weight: ${(props) => (props.fontWeightSm ? props.fontWeightSm : "")};
      font-size: ${(props) => (props.fontSizeSm ? props.fontSizeSm : "")};
      padding-top: ${(props) => (props.paddingTopSm ? props.paddingTopSm : "")};
    }

    ${(props) =>
      props.txtGrey &&
      css`
        color: ${(props) => props.color || props.theme.subText} !important;
      `};
    ${(props) =>
      props.BodyColor &&
      css`
        color: ${(props) => props.color || props.theme.bodyText} !important;
      `};

    ${(props) =>
      props.nowrap &&
      css`
        white-space: nowrap;
      `}
  }
`;

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isTheme
});

export default compose(connect(mapStateToProps))(Thead);
