/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../../constants/Images/imageIndex";
import BadgeComponent from "./badge";
import CountTimerInWords from "../../../components/CountDownTimer/countDownTimer";
import { useHistory, useParams } from "react-router-dom";
import { registorUser } from "../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { getLeaderBoard, getPrivateLeaderBoard } from "../../../actions/competition";
import moment from "moment";
import { TradeSocketContext } from "../../../components/Socket/Public/tradeSocket";
import { GetKycVerified } from "../../../components/Alert";
import { debounce } from "../../../utils/helpers";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ChevronLeftBlueIcon } from "../../../assets/svgs";
import { ImgElement } from "../../../components/Reusable";
import { Skeleton } from "../../../components/Loader";
// import { ImgElement } from "../../../components/Reusable";

function TimerDiv(props) {
  const { isTheme, selectedPairName, singleComptitionData, status } = props;
  // const [share, setShare] = useState(false);
  const [loading, setLoading] = useState(false);
  const [participated, setParticipated] = useState(singleComptitionData?.Participated || false);
  const [upcomingParticipated, setUpcomingParticipated] = useState(
    singleComptitionData?.Participated || false
  );
  const [kycModal, setKycModal] = useState(true);
  const contextValue = useContext(TradeSocketContext);
  let { socketDisconnect } = contextValue || {};
  const history = useHistory();
  const param = useParams();
  const { width } = useWindowSize();
  let id = param.id;
  const userLogin = useSelector((state) => state.auth.isLoggedIn);
  const useData = useSelector((state) => state.dashboard.getUserData);
  // const userData = useSelector((state) => state.dashboard.userData);
  const KYCStatus = useData?.KYC?.STATUS;
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    setParticipated(singleComptitionData?.Participated);
    setUpcomingParticipated(singleComptitionData?.Participated);
  }, [singleComptitionData]);

  const handleLink = (givenLink) => {
    // contextValue.unsubscribeEvents(selectedPairName?.symbol);
    history.push(`${prevLang ? `/${prevLang}` : "/en"}/trade/${givenLink.split("/").join("-")}`);
  };

  const getLeaderBoard = (offset) => {
    setLoading(true);
    const data = {
      competitionId: id,
      offset: 1
      // limit: 6
    };
    userLogin ? props.getPrivateLeaderBoard(data) : props.getLeaderBoard(data);
    setLoading(false);
  };

  let participate = false;

  const registorUserInCompetition = debounce(() => {
    // setLoading(true);
    if (KYCStatus === "LEVEL2") {
      participate = true;
      const data = {
        competitionId: id
      };
      registorUser(data)
        .then((res) => {
          if (res) {
            getLeaderBoard();
            props.setapihitter((state) => state + 1);
            showSuccessMsg("User Registration Success");
          }
          setTimeout(() => {
            // setLoading(false);
            participate = false;
          }, 2000);
        })
        .catch((err) => showErrorMsg("User Registration Failed"));
    } else {
      setKycModal(true);
      // showErrorMsg("Please Update Your KYC To Participate");
    }
  }, 700);

  function compeditionTextPredictor(status) {
    switch (status) {
      case "live":
        return "Competition Ends in";
      case "upcoming":
        return "Competition Starts in";
      case "completed":
        return "Competition Ended on";
      default:
        return "";
    }
  }

  const onCloseModal = debounce(() => {
    setKycModal(false);
  }, 700);

  return (
    <>
      {userLogin && useData && (KYCStatus === "LEVEL0" || KYCStatus === "LEVEL1") && kycModal && (
        <GetKycVerified open={kycModal} onClose={onCloseModal} />
      )}

      <BannerBox
        CardWhite2
        radius={"10px"}
        padding={"32px 32px 32px"}
        paddingLg={"24px 20px 48px"}
        paddingMd={"16px 12px 8px"}
        margin="1.1rem 0px"
        display={width > 767 && "flex"}
      >
        <BannerBox width={width > 1024 ? "50%" : width > 767 && width < 1024 ? "65%" : "100%"}>
          <BannerBox width="100%">
            <Link to={`${prevLang ? `/${prevLang}`: "/en"}/trading-competition`} replace={true}>
              <ButtonComponent OutlineOnlyPrimary display="flex" alignItems="center">
                <ChevronLeftBlueIcon />
                Back
              </ButtonComponent>
            </Link>
          </BannerBox>

          {!singleComptitionData.Pairs ? (
            <BannerBox mb="10px">
              <Skeleton height={60} width={"90%"} />
            </BannerBox>
          ) : (
            <BannerBox
              display="flex"
              alignItems={width < 767 ? "start" : "center"}
              gap="7px"
              direction={width < 767 ? "column-reverse" : "row"}
            >
              <BannerHeading size="36px" weight="500" sizeMd="20px" sizeLg="24px">
                {`${singleComptitionData?.Token} Trading Competition`}
              </BannerHeading>
              {(participated || upcomingParticipated) && (
                <BadgeComponent title="Participated" borderColor="success" />
              )}
            </BannerBox>
          )}

          {!singleComptitionData?.Pairs ? (
            <>
              <Skeleton height={35} width={200} />
            </>
          ) : (
            <BannerHeading
              mt="7px"
              sizeMd="12px"
              sizeLg="14px"
              display="flex"
              style={{ flexWrap: "wrap" }}
              lineHeight="26px"
            >
              Eligible {singleComptitionData?.Pairs?.length > 1 ? "Pairs" : "Pair"} :
              {singleComptitionData &&
                singleComptitionData.Pairs &&
                singleComptitionData.Pairs.length > 0 &&
                singleComptitionData.Pairs.map((item, index) => (
                  <BannerHeadingSpan
                    key={item}
                    cursorPointer
                    txtPrimary
                    sizeMd="12px"
                    sizeLg="14px"
                    margin="0 4px"
                    onClick={() => handleLink(item.trim())}
                  >
                    {item}
                    {index < singleComptitionData.Pairs.length - 1 && ","}
                  </BannerHeadingSpan>
                ))}
            </BannerHeading>
          )}

          {/* compedition counter */}
          <BannerBox>
            <BannerHeading size="16px" weight="400" mt="16px" sizeMd="12px" sizeLg="14px">
              {compeditionTextPredictor(status)}
            </BannerHeading>
            {singleComptitionData.To && status === "live" && (
              <CountTimerInWords eventTime={singleComptitionData.To} />
            )}
            {singleComptitionData.From && status === "upcoming" && (
              <CountTimerInWords eventTime={singleComptitionData.From} />
            )}
            {status === "completed" && (
              <BannerHeadingSpan weight="500" BodyColor>
                {moment(singleComptitionData.To * 1000).format("DD/MM/YYYY")}
              </BannerHeadingSpan>
            )}
          </BannerBox>
          {/* ++++++++++++++button div++++++++++++++++++++++/+ */}
          <BannerBox
            margin="32px 0px"
            marginMd="24px 0px"
            display="flex"
            alignItems={width > 767 ? "center" : "start"}
            gap="10px"
            direction={width > 767 ? "row" : "column"}
          >
            {status === "live" && participated && userLogin && (
              <ButtonComponent
                TradingButton
                width="232px"
                btnHeight="48px"
                btnHeightMd="44px"
                onClick={() => handleLink(singleComptitionData.Pairs[0])}
              >
                Start Trading
              </ButtonComponent>
            )}
            {status === "live" && !participated && userLogin && (
              <ButtonComponent
                PrimaryButton
                width="232px"
                btnHeight="48px"
                btnHeightMd="44px"
                disabled={participate}
                onClick={() => registorUserInCompetition()}
              >
                Participate
              </ButtonComponent>
            )}
            {upcomingParticipated && status === "upcoming" && userLogin && (
              <ButtonComponent
                DisabledButton
                width="232px"
                btnHeight="48px"
                btnHeightMd="44px"
                disabled={true}
              >
                Competition yet to start
              </ButtonComponent>
            )}
            {!upcomingParticipated && status === "upcoming" && userLogin && (
              <ButtonComponent
                OutlinePrimary
                width="232px"
                btnHeight="48px"
                btnHeightMd="44px"
                disabled={participate}
                onClick={() => registorUserInCompetition()}
              >
                Participate
              </ButtonComponent>
            )}
            {!userLogin && (
              <ButtonComponent
                OutlineOnlyPrimary2
                width="232px"
                btnHeight="48px"
                btnHeightMd="44px"
                onClick={() => history.push(`${prevLang ? `/${prevLang}` : "/en"}/login`)}
              >
                Login or Register
              </ButtonComponent>
            )}
            {/* {share && status !== "completed" && (
              <BannerBox
                display="flex"
                alignItems="center"
                cursorPointer="pointer"
                bg={!isTheme ? "#ECF5FE" : "#263645"}
                width="272px"
                height="48px"
                BorderRadius="5px"
              >
                <span
                  className="mx-2 font-17"
                  style={{ borderRight: "1px solid #CCCED0", paddingRight: "9px" }}
                  onClick={() => setShare(!share)}
                >
                  <figure className="mb-0">
                    <img
                      src={!isTheme ? IMAGE_PREFIX.Share : IMAGE_PREFIX_DARK.DarkShareBtn}
                      alt="ShareIcon"
                      className="img-fluid"
                      color="#0470E1"
                    />
                  </figure>
                </span>
                <span className="mx-2 font-17">
                  <a
                    href="https://www.facebook.com/LCX.exchange/"
                    target="blank"
                    className="exch-footer-link txt-grey cursorPointer"
                  >
                    <figure className="mb-0">
                      <img
                        src={!isTheme ? IMAGE_PREFIX.Bluefacebook : IMAGE_PREFIX_DARK.BlueFacebook}
                        alt="Facebook"
                        className="img-fluid"
                        stroke="#0470E1"
                        style={{ stroke: "#0470E1" }}
                      />
                    </figure>
                  </a>
                </span>
                <span className="mx-2 font-17">
                  <a
                    href="https://www.linkedin.com/company/lcx"
                    target="blank"
                    className="exch-footer-link txt-grey cursorPointer"
                  >
                    <figure className="mb-0">
                      <img
                        src={!isTheme ? IMAGE_PREFIX.BlueLinkdin : IMAGE_PREFIX_DARK.BlueLinkdin}
                        alt="Linkdin"
                        className="img-fluid"
                      />
                    </figure>
                  </a>
                </span>
                <span className="mx-2 font-17">
                  <a href="/" target="blank" className="exch-footer-link txt-grey cursorPointer">
                    <figure className="mb-0">
                      <img
                        src={!isTheme ? IMAGE_PREFIX.BlueTelegram : IMAGE_PREFIX_DARK.Bluetelegram}
                        alt="ShareIcon"
                        className="img-fluid"
                      />
                    </figure>
                  </a>
                </span>
                <span className="mx-2 font-17">
                  <a
                    href="https://twitter.com/lcx"
                    target="blank"
                    className="exch-footer-link txt-grey cursorPointer"
                  >
                    <figure className="mb-0">
                      <img
                        src={!isTheme ? IMAGE_PREFIX.BlueMail : IMAGE_PREFIX_DARK.Bluemail}
                        alt="twitter"
                        className="img-fluid"
                      />
                    </figure>
                  </a>
                </span>
                <span className="mx-2 font-17">
                  <a
                    href="https://www.youtube.com/channel/UCNj78acRE-ywQPim-wZ8RTQ"
                    target="blank"
                    className="exch-footer-link txt-grey cursorPointer"
                  >
                    <figure className="mb-0">
                      <img
                        src={!isTheme ? IMAGE_PREFIX.BlueLink : IMAGE_PREFIX_DARK.BlueLinkNew}
                        alt="youtube"
                        className="img-fluid"
                      />
                    </figure>
                  </a>
                </span>
              </BannerBox>
            )}
            {!share && status !== "completed" && (
              <BannerBox
                className="d-flex"
                alignItems="center"
                justifyContent="center"
                bg={!isTheme ? "#ECF5FE" : "#263645"}
                height="48px"
                width="48px"
                BorderRadius="5px"
                cursorPointer="pointer"
              >
                <span className="mx-2 font-17" onClick={() => setShare(!share)}>
                  <figure className="mb-0">
                    <img
                      src={!isTheme ? IMAGE_PREFIX.Share : IMAGE_PREFIX_DARK.DarkShareBtn}
                      alt="ShareIcon"
                      className="img-fluid"
                      color="#0470E1"
                    />
                  </figure>
                </span>
              </BannerBox>
            )} */}
            {/* </div> */}
          </BannerBox>
          {upcomingParticipated && status === "upcoming" && (
            <BannerBox display="flex" alignItems="center" gap="5px" margin="12px 0px">
              <BannerHeadingSpan>
                <ImgElement
                  src={IMAGE_PREFIX.TickCompedition}
                  alt="tick"
                  width="17px"
                  height="17px"
                />
              </BannerHeadingSpan>
              <BannerHeading size="12px" weight="400">
                You will be notified 1 hour before trading competition
              </BannerHeading>
            </BannerBox>
          )}
        </BannerBox>
        {width > 1025 && (
          <BannerBox width="49%" display="flex" alignItems="center" justifyContent="center">
            {singleComptitionData && singleComptitionData.Image && (
              <ImgElement
                src={singleComptitionData.Image}
                alt="animi"
                width="100%"
                height="100%"
                objectFit="contain"
              />
            )}
            {singleComptitionData && !singleComptitionData.Image && (
              <ImgElement
                src={require("../../../assets/img/Card_5-03.jpg")}
                alt="animi"
                width="100%"
                height="100%"
                objectFit="cover"
              />
            )}
          </BannerBox>
        )}
        {width <= 1024 && width > 767 && (
          // <BannerBox width="35%" display="flex" alignItems="center" justifyContent="center" bgImg={singleComptitionData.TabletImage} style={{ backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
          // </BannerBox>
          <BannerBox width="35%" display="flex" alignItems="center" justifyContent="center">
            <ImgElement
              src={singleComptitionData.TabletImage}
              alt="animi"
              width="100%"
              style={{ maxHeight: "300px" }}
            />
          </BannerBox>
        )}
      </BannerBox>
    </>
  );
}

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme
});

const mapDispatchToProps = (dispatch) => ({
  getLeaderBoard: (data) => dispatch(getLeaderBoard(data)),
  getPrivateLeaderBoard: (data) => dispatch(getPrivateLeaderBoard(data))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(TimerDiv);
