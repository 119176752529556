import React, { useMemo } from "react";

import configs from "../../../constants/config";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { GridBox } from "../../../assets/StyleComponents/GridBox.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import DollarImageComponent from "../../../components/DollarImage/dollarImage";
// import { WalletContext } from "../../../contexts/WalletPage/BalancePage/wallet";
import { formatNumbers } from "../../../utils/helpers";
// import { checkThousand, numberWithCommas } from "../../../utils/general";
import SkeletonLoader from "../../../components/SkeletonLoader";
import DashbaordDataCard from "../Components/DashboardDataCards";
import SharedNumberComponent from "../../../components/ReusedComponent/SharedNumberComponent";
import { ImgElement } from "../../../components/Reusable";

const DashboardTableList = (props) => {
  let { rowData, sent, isLoaderWallet, isLoaderExch } = props;

  // let { hideStatus } = useContext(WalletContext);

  let isLoading = sent === "exchange" ? isLoaderWallet : isLoaderExch;

  return useMemo(() => {
    return (
      <DashbaordDataCard {...props}>
        <BannerBox position="relative" zIndex="1" mt="16px">
          <GridBox
            TableGrid
            position="sticky"
            cursor="default"
            borderRadius="5px"
            tablePadding="12px 16px"
            tablePaddingLg="12px 12px"
            tablePaddingMd="9px 12px"
            gridTempColumn="1fr 1fr"
            justifyItems="stretch"
          >
            <BannerHeading size="14px" sizeMd="12px" txtGrey>
              {" "}
              Asset{" "}
            </BannerHeading>
            <BannerHeading size="14px" sizeMd="12px" txtGrey textAlign="end">
              {" "}
              Total{" "}
            </BannerHeading>
          </GridBox>

          <GridBox
            BottomBorder
            minHeight="170px"
            maxHeight="151px"
            overflow="hidden"
            gridTempColumn="1fr"
            padding="0 0 12px"
            alignItems="center"
            gridAutoRow="minmax(55px, 56px)"
            justifyItems="stretch"
          >
            {isLoading &&
              [0, 1, 2].map((item) => (
                <GridBox
                  BottomBorder
                  // cursor="pointer"
                  key={item}
                  padding="0 16px"
                  gridTempColumn="minmax(55px, 100%) minmax(55px, 100%)"
                  alignItems="center"
                  gridGap="30px"
                  gridAutoRow="minmax(55px, 56px)"
                >
                  <SkeletonLoader
                    height={10}
                    givenClass={`d-flex flex-column wth-per-100 ${item === 0 ? "mt-3" : ""}`}
                  />
                  <SkeletonLoader
                    height={10}
                    givenClass={`d-flex flex-column wth-per-100 ${item === 0 ? "mt-3" : ""}`}
                  />
                </GridBox>
              ))}

            {!isLoading && (
              <>
                {(!rowData || rowData.length === 0) &&
                  [0, 1, 2].map((item) => (
                    <GridBox
                      BottomBorder
                      // cursor="pointer"
                      key={item}
                      padding="0 16px"
                      gridTempColumn="1fr 1fr"
                      alignItems="center"
                      gridGap="30px"
                      gridAutoRow="minmax(55px, 56px)"
                    >
                      <SkeletonLoader
                        height={10}
                        givenClass={`d-flex flex-column wth-per-100 ${item === 0 ? "mt-3" : ""}`}
                      />
                      <SkeletonLoader
                        height={10}
                        givenClass={`d-flex flex-column wth-per-100 ${item === 0 ? "mt-3" : ""}`}
                      />
                    </GridBox>
                  ))}

                {rowData &&
                  rowData &&
                  rowData.length > 0 &&
                  rowData.slice(0, 3).map((item, index) => {
                    let { equivalentUSDBalance, coin, balance } = item;
                    return (
                      <GridBox
                        BottomBorder
                        cursor="default"
                        key={index}
                        padding="0 16px"
                        paddingLg="0 12px"
                        gridTempColumn="1fr 1fr"
                        alignItems="start"
                        gridAutoRow="55px"
                      >
                        <BannerBox
                          display="flex"
                          columnGap="8px"
                          alignItems="center"
                          size="14px"
                          sizeMd="12px"
                          txtGrey
                          mt="10px"
                        >
                          <ImgElement
                            src={`${
                              !props.isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                            }${coin.toLowerCase()}.svg`}
                            alt={coin}
                            width="24px"
                            widthMd="22px"
                            height="24px"
                            heightMd="22px"
                            loading="lazy"
                            marginRight="5px"
                          />
                          <BannerHeadingSpan txtGrey weight="500">
                            {" "}
                            {coin}{" "}
                          </BannerHeadingSpan>
                        </BannerBox>

                        <BannerBox display="flex" direction="column" mt="10px">
                          <BannerHeading size="14px" sizeMd="12px" txtGrey textAlign="end">
                            <SharedNumberComponent
                              prevValue={formatNumbers(balance.totalBalance, 8)}
                              digit={5}
                              status={false}
                            />
                          </BannerHeading>

                          <BannerHeading
                            size="14px"
                            sizeLg="12px"
                            txtGrey
                            textAlign="end"
                            weight={300}
                          >
                            <DollarImageComponent
                              givenClass="hght-10 hght-lg-10"
                              userHideStatus={true}
                              DollarAmount={
                                <SharedNumberComponent
                                  prevValue={formatNumbers(equivalentUSDBalance.totalBalance, 2)}
                                  digit={5}
                                  thousandStatus={true}
                                />
                              }
                              {...props}
                            />
                          </BannerHeading>
                        </BannerBox>
                      </GridBox>
                    );
                  })}
              </>
            )}
          </GridBox>
        </BannerBox>
      </DashbaordDataCard>
    );
  }, [props, isLoading, rowData]);
};

export default DashboardTableList;
