import React from "react";
// import { toast } from "react-toastify";
import { toast } from "sonner";
import { CloseIcon, ErrorIcon, InfoIcon, SuccessIcon } from "../assets/svgs";
import { BannerBox } from "../assets/StyleComponents/Exchange.style";
import { HeadingSix } from "../assets/StyleComponents/FontSize/HeadingSix.style";
import { getLocalStorage } from "./helpers";

const config = {
  position: "bottom-right",
  duration: 2500,
  className: "Toast-Number",
  expand: false,
  icon: (props) => {
    if (props.type === "success") {
      return <SuccessIcon width="20px" height="20px" />;
    } else if (props.type === "error") {
      return <ErrorIcon width="20px" height="20px" />;
    } else {
      return <InfoIcon width="20px" height="20px" />;
    }
  }
};

export const showSuccessMsg = (msg, option) => {
  let alert = getLocalStorage("hiddenToast");
  let accessToken = getLocalStorage("accessToken");
  if (accessToken) {
    if (!alert) {
      toast.custom(
        (t) => {
          let getLength = document.querySelectorAll(".Toast-Number");
          if (getLength && getLength.length > 10) toast.dismiss();
          return (
            <BannerBox
              className="full-width"
              // minWidth="380px"
              // width="auto"
              // flex="1 1 380px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              BorderRadius="5px"
              padding="12px"
              ToastNotificationSucess
            >
              <BannerBox
                display="flex"
                width="80%"
                gap="8px"
                flex="1 1 330px"
                mr="8px"
                alignItems="center"
              >
                <SuccessIcon width="20px" height="20px" />
                <HeadingSix size="14px" sizeSm="12px" NotificationSuccessTxt>
                  {msg}
                </HeadingSix>
              </BannerBox>
              <CloseIcon onClick={() => toast.dismiss(t)} />
            </BannerBox>
          );
        },
        { ...config, option }
      );
    } else return;
  } else {
    toast.custom(
      (t) => {
        let getLength = document.querySelectorAll(".Toast-Number");
        if (getLength && getLength.length > 10) toast.dismiss();
        return (
          <BannerBox
            className="full-width"
            // minWidth="380px"
            // width="auto"
            // flex="1 1 380px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            BorderRadius="5px"
            padding="12px"
            ToastNotificationSucess
          >
            <BannerBox
              display="flex"
              width="80%"
              gap="8px"
              flex="1 1 330px"
              mr="8px"
              alignItems="center"
            >
              <SuccessIcon width="20px" height="20px" />
              <HeadingSix size="14px" sizeSm="12px" NotificationSuccessTxt>
                {msg}
              </HeadingSix>
            </BannerBox>
            <CloseIcon onClick={() => toast.dismiss(t)} />
          </BannerBox>
        );
      },
      { ...config, option }
    );
  }
};

export const showErrorMsg = (msg, option) => {
  // toast.error(msg, { ...config, option });
  let alert = getLocalStorage("hiddenToast");
  let accessToken = getLocalStorage("accessToken");
  if (accessToken) {
    if (!alert) {
      toast.custom(
        (t) => {
          let getLength = document.querySelectorAll(".Toast-Number");
          if (getLength && getLength.length > 10) toast.dismiss();
          return (
            <BannerBox
              className="full-width"
              // minWidth="380px"
              // width="auto"
              // flex="1 1 380px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              BorderRadius="5px"
              padding="12px"
              ToastNotificationDanger
            >
              <BannerBox display="flex" width="80%" gap="8px" flex="1 1 330px" mr="8px">
                <ErrorIcon width="20px" height="20px" />
                <HeadingSix size="14px" sizeSm="12px" txtDanger>
                  {msg}
                </HeadingSix>
              </BannerBox>
              <CloseIcon onClick={() => toast.dismiss(t)} />
            </BannerBox>
          );
        },
        { ...config, option }
      );
    } else return;
  } else {
    toast.custom(
      (t) => {
        let getLength = document.querySelectorAll(".Toast-Number");
        if (getLength && getLength.length > 10) toast.dismiss();
        return (
          <BannerBox
            className="full-width"
            // minWidth="380px"
            // width="auto"
            // flex="1 1 380px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            BorderRadius="5px"
            padding="12px"
            ToastNotificationDanger
          >
            <BannerBox display="flex" width="80%" gap="8px" flex="1 1 330px" mr="8px">
              <ErrorIcon width="20px" height="20px" />
              <HeadingSix size="14px" sizeSm="12px" txtDanger>
                {msg}
              </HeadingSix>
            </BannerBox>
            <CloseIcon onClick={() => toast.dismiss(t)} />
          </BannerBox>
        );
      },
      { ...config, option }
    );
  }
};

export const showWarningMsg = (msg, option) => {
  // toast.warn(msg, { ...config, option });
  let alert = getLocalStorage("hiddenToast");
  let accessToken = getLocalStorage("accessToken");
  if (accessToken) {
    if (!alert) {
      toast.custom(
        (t) => {
          let getLength = document.querySelectorAll(".Toast-Number");
          if (getLength && getLength.length > 10) toast.dismiss();
          return (
            <BannerBox
              className="full-width"
              // minWidth="380px"
              // width="auto"
              // flex="1 1 380px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              BorderRadius="5px"
              padding="12px"
              ToastNotificationWarning
            >
              <BannerBox display="flex" width="80%" gap="8px" flex="1 1 330px" mr="8px">
                <ErrorIcon width="20px" height="20px" fillYellow={true} />
                <HeadingSix size="14px" sizeSm="12px" txtWarning>
                  {msg}
                </HeadingSix>
              </BannerBox>
              <CloseIcon onClick={() => toast.dismiss(t)} />
            </BannerBox>
          );
        },
        { ...config, option }
      );
    } else return;
  } else {
    toast.custom(
      (t) => {
        let getLength = document.querySelectorAll(".Toast-Number");
        if (getLength && getLength.length > 10) toast.dismiss();
        return (
          <BannerBox
            className="full-width"
            // minWidth="380px"
            // width="auto"
            // flex="1 1 380px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            BorderRadius="5px"
            padding="12px"
            ToastNotificationWarning
          >
            <BannerBox display="flex" width="80%" gap="8px" flex="1 1 330px" mr="8px">
              <ErrorIcon width="20px" height="20px" fillYellow={true} />
              <HeadingSix size="14px" sizeSm="12px" txtWarning>
                {msg}
              </HeadingSix>
            </BannerBox>
            <CloseIcon onClick={() => toast.dismiss(t)} />
          </BannerBox>
        );
      },
      { ...config, option }
    );
  }
};
