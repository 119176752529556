import React from "react";
import DivElement from "../DivElement";

const Pagination = ({
  page,
  totalCount,
  itemPerPage,
  setPage,
  marginTop,
  marginTopMd,
  marginTopSm
}) => {
  if (!itemPerPage) {
    itemPerPage = 10;
  }
  const totalPage = Math.ceil(totalCount / itemPerPage);
  return (
    <DivElement
      PaginationContainer
      marginTop={marginTop}
      marginTopMd={marginTopMd}
      marginTopSm={marginTopSm}
    >
      <div
        className={`pc__pageends ${page <= 1 ? "disabled" : ""}`}
        onClick={() => {
          if (page <= 1) return;
          setPage(1);
        }}
      >
        First
      </div>

      {page > 1 && (
        <div className="pc__pagebtn" onClick={() => setPage(page - 1)}>
          {page - 1}
        </div>
      )}

      <div className="pc__pagebtn active">{page}</div>

      {totalPage > page && (
        <div className="pc__pagebtn" onClick={() => setPage(page + 1)}>
          {page + 1}
        </div>
      )}

      {totalPage > page + 1 && page === 1 && (
        <div className="pc__pagebtn" onClick={() => setPage(page + 2)}>
          {page + 2}
        </div>
      )}

      {(totalPage > page + 2 || (page === 2 && totalPage > page + 1)) && (
        <div className="pc__pagebtn" style={{ fontWeight: "300" }}>
          ...
        </div>
      )}

      <div
        className={`pc__pageends ${totalPage === page || totalPage === 0 ? "disabled" : ""}`}
        onClick={() => {
          if (totalPage === page || totalPage === 0) return;
          setPage(totalPage);
        }}
      >
        Last
      </div>
    </DivElement>
  );
};

export default Pagination;
