import React, { useEffect, useMemo } from "react";
import { MainElement } from "../../assets/StyleComponents/Exchange.style";
import TopBannerTS from "./Components/TopBannerTS";
import ParticipatedHistory from "./Components/ParticipatedHistory";
// import TSFaq from "./Components/TSFaqs";
import TokenSaleList from "./Components/TokenSaleList";
import { useDispatch, useSelector } from "react-redux";
import { HalfFooterCommon } from "../../components/Footer/common";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
// import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

function TokenSaleIndexPage() {
  const isLoggedin = useSelector((state) => state.auth.isLoggedIn);
  // const userData = useSelector((state) => state.dashboard.userData);
  const { transalationList } = useSelector(GenericReducer);
  // const location = useLocation();
  // const history = useHistory();
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (isLoggedin) {
      dispatch(fetchAvailableLanguagesRequest());
      // let checkPath = location.pathname?.split("/")[1];
      // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
      //   history.push(`/${userData.preferredLanguage}${location.pathname}`);
      // }
    }
  }, []);

  const SEOContents = {
    name: `TOKEN SALE`,
    link: `token-sale`,
    title: "Crypto Token Sales Today | LCX Exchange",
    description:
      "Discover new crypto token sales on our platform. Invest securely with trusted transactions and exciting opportunities in the crypto market. Join us today!",
    keywords: `token sales crypto, crypto token sales, crypto tokens for sale, token sale crypto, tokens for sale`,
    canonical: `https://exchange.lcx.com/token-sale`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}/token-sale`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: "Crypto Token Sales Today | LCX Exchange",
    OgDescription:
      "Discover new crypto token sales on our platform. Invest securely with trusted transactions and exciting opportunities in the crypto market. Join us today!",
    OgURL: `https://www.exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/token-sale`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Crypto Token Sales Today | LCX Exchange",
    TwDescription:
      "Discover new crypto token sales on our platform. Invest securely with trusted transactions and exciting opportunities in the crypto market. Join us today!"
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/token-sale`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <MainElement
        ExchangeContainer
        margin="32px auto"
        mb="80px"
        minHeight="72vh"
        minHeightXL="83.5vh"
      >
        <TopBannerTS />
        <TokenSaleList isLoggedin={isLoggedin} />
        {isLoggedin && <ParticipatedHistory />}
        {/* <TSFaq /> */}
      </MainElement>

      <HalfFooterCommon />
    </>
  );
}

export default TokenSaleIndexPage;
