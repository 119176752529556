import { connect } from "react-redux";

import TradingDashboard from "../views/dashboard/Dashboard.jsx";
import { logout } from "../actions/auth";
import { getUserDetails, getWalletBalance, getRecentActivity } from "../actions/dashboard";
import { getOpenOrders, selectedPair } from "../actions/trade";
import { generate2AuthToken, clearUserErrors } from "../actions/setting";
// import LanguageHoc from "../components/LanguageHOC/LanguageHoc.jsx";
// import LangLayoutCheck from "../pages/Auth/LangLayoutCheck.jsx";

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  isThemeFecthing: state.theme.isThemeFetching,
  getUserData: state.dashboard.getUserData,
  walletBalance: state.dashboard.walletBalance,
  walletTotalBalance: state.dashboard.walletTotalBalance,
  isFetchingGetWalletBalance: state.dashboard.isFetchingGetWalletBalance,
  recentActivity: state.dashboard.recentActivity,
  isFetchingRecentActivity: state.dashboard.isFetchingRecentActivity,
  openOrders: state.trade.openOrders,
  isOpenOrderFetching: state.trade.isOpenOrderFetching,
  openOrdersCount: state.trade.openOrdersCount,
  isLoggedIn: state.auth.isLoggedIn,
  userData: state.dashboard.getUserData,
  isWholeLoading: state.dashboard.isWholeLoading,
  walletErrorGet: state.wallet.errorGet,
  marketErrorGet: state.market.errorGet,
  tradeErrorGet: state.trade.errorGet,
  dashErrorGet: state.dashboard.errorGet,
  settingErrorGet: state.setting.errorGet,
  twoAuthToken: state.setting.twoAuthToken,
  error: state.setting.error,
  apiData: state.setting.apiData,
  loader: state.setting.apiLoader,
  isLogoutFetching: state.dashboard.isLogoutFetching
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
  getUserDetails: () => dispatch(getUserDetails()),
  getWalletBalance: () => dispatch(getWalletBalance()),
  getRecentActivity: () => dispatch(getRecentActivity()),
  getOpenOrders: (data) => dispatch(getOpenOrders(data)),
  generate2AuthToken: () => dispatch(generate2AuthToken()),
  selectedPair: (user) => dispatch(selectedPair(user)),
  clearUserErrors: () => dispatch(clearUserErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(TradingDashboard);
