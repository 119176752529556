/* eslint-disable no-console */
import styled, { css } from "styled-components";
import { MarginStyle } from "./Reusable/Margin.style";
import { PaddingStyle } from "./Reusable/Padding.style";
import { PositionStyleCommon } from "./Position/Position";
import { DisplayStyle } from "./Reusable/Display.style";
import { Level0Card, level1Card } from "./Reusable/Cards.style";
import { BorderCommanStyle } from "./Reusable/Border.style";
import { CommanHeightStyle } from "./Reusable/Height.style";
import { CommanWidthStyle } from "./Reusable/Width.style";

const MainTag = styled.main`
  ${MarginStyle}
  ${PaddingStyle}
  ${PositionStyleCommon}
  ${DisplayStyle}
  ${BorderCommanStyle}
  
  ${CommanWidthStyle}
  ${CommanHeightStyle}

  ${({ ExchangeContainer }) => ExchangeContainer && `
    max-width: 1124px !important;
    width: 91% !important;
    overflow: hidden !important;
    @media (max-width: 1024px) {
      border-radius: 20px;
      width: 95% !important;
      max-width: 992px !important;
    }
    @media (max-width: 767px) {
      border-radius: 10px;
    }
  `}
  
  ${({ ParentCard }) => ParentCard && css`${Level0Card}`};
  ${({ MainCardLevel1 }) => MainCardLevel1 && css`${level1Card}`};
`;

export { MainTag };