import styled, { css } from "styled-components";

const Tr = styled.tr`
  height: ${(props) => (props.height ? props.height : "40px")};
  display: ${(props) => (props.display ? props.display : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "")};

  @media (max-width: 1024px) {
    height: ${(props) => (props.heightMd ? props.heightMd : "")};
  }
  @media (max-width: 767px) {
    height: ${(props) => (props.heightSm ? props.heightSm : "")};
  }

  ${(props) =>
    props.border &&
    css`
      border-bottom: 0.5px solid ${(props) => props.theme.sepColor};
    `}

  ${(props) =>
    props.borderBottom &&
    css`
      td {
        border-bottom: 0.5px solid ${(props) => props.theme.sepColor};
      }
    `}



  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
`;

export default Tr;
