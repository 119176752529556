/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import { withRouter, useLocation } from "react-router";
import IndexFooter from "../../components/Footer/footer";
import { getTutorialsData } from "../../lib/api";
import { formatAPIErrors } from "../../utils/general";
import SlideImages from "./slider";
import { showErrorMsg } from "../../utils/notification";
import { trackPage } from "../../tracking/track";
import { BannerBox, MainElement, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const TutorialComponent = (props) => {
  const [isLoader, setIsloader] = useState(false);
  let location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.dashboard.userData);
  const { transalationList } = useSelector(GenericReducer);

  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchAvailableLanguagesRequest());
      // let checkPath = location.pathname?.split("/")[1];
      // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
      //   history.push(`/${userData.preferredLanguage}${location.pathname}`);
      // }
    }
    let page = location.pathname + location.search;
    trackPage(page);
  }, []);

  useEffect(() => {
    setIsloader(true);
    getTutorialsData()
      .then((res) => {
        if (res.status === 200) {
          let { BEGINER, HOWTO, BASICS } = res.data.data.data || {};

          setEventSlider({
            ...eventSlider,
            slideImage: BEGINER
          });

          setTutorialEventSlider({
            ...tutorialEventSlider,
            slideImage: HOWTO
          });

          setCryptoEventSlider({
            ...cryptoEventSlider,
            slideImage: BASICS
          });
          setIsloader(false);
        }
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 5000 });
        setIsloader(false);
      });
  }, []);

  const [eventSlider, setEventSlider] = useState({
    startValue: 0,
    showItems: 3,
    slideImage: []
  });

  const [tutorialEventSlider, setTutorialEventSlider] = useState({
    startValue: 0,
    showItems: 3,
    slideImage: []
  });

  const [cryptoEventSlider, setCryptoEventSlider] = useState({
    startValue: 0,
    showItems: 3,
    slideImage: []
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SEOContents = {
    name: `TUTORIALS`,
    link: `tutorials`,
    title: "Crypto Trading Tutorial: Your Complete Guide | LCX Exchange",
    description:
      "Learn the essentials of crypto trading with our tutorial. Gain insights, strategies, and tips to navigate the cryptocurrency market effectively. Start Today!",
    keywords: `crypto exchange tutorial,crypto trading tutorial, trading crypto tutorial, crypto trade tutorial, crypto trading tutorials, tutorial crypto trading, Crypto trading strategies`,
    canonical: `https://exchange.lcx.com/tutorials`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}/tutorials`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: "Crypto Trading Tutorial: Your Complete Guide | LCX Exchange",
    OgDescription:
      "Learn the essentials of crypto trading with our tutorial. Gain insights, strategies, and tips to navigate the cryptocurrency market effectively. Start Today!",
    OgURL: `https://www.exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/tutorials`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Crypto Trading Tutorial: Your Complete Guide | LCX Exchange",
    TwDescription:
      "Learn the essentials of crypto trading with our tutorial. Gain insights, strategies, and tips to navigate the cryptocurrency market effectively. Start Today!"
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/tutorials`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <BannerBox position="relative" minHeight="100vh">
        {!isLoader && (
          <>
            <MainElement display="block">
              <BannerSection
                TutorialBg
                paddBottom="88px"
                paddTop="64px"
                paddBottomLg="40px"
                paddTopLg="30px"
              >
                <BannerBox
                  position="relative"
                  maxWidth="1124px"
                  maxWidthLg="992px"
                  widthMd="95%"
                  boxSizing="border-box"
                  margin="0px auto"
                >
                  <BannerBox display="flex" direction="column">
                    <BannerBox widthLg="100%" textAlign="center">
                      <BannerHeading
                        size="44px"
                        sizeMd="24px"
                        sizeLg="32px"
                        BodyColor
                        weight="bold"
                      >
                        {" "}
                        Tutorials{" "}
                      </BannerHeading>
                    </BannerBox>

                    <BannerBox widthLg="100%" textAlign="center" mt="16px">
                      <ParaTag
                        size="24px"
                        sizeLg="20px"
                        sizeMd="16px"
                        BodyColor
                        weight="300"
                        paddingLg="0px"
                        padding="0 16px"
                      >
                        Discover everything you need to know to kickstart your journey on LCX
                        exchange with our easy, practical and step-by-step tutorials.
                      </ParaTag>
                    </BannerBox>
                  </BannerBox>
                </BannerBox>
              </BannerSection>

              <BannerSection
                BeginerBg
                paddTop="4rem"
                paddBottom="80px"
                paddBottomLg="0px"
                paddBottomMd="48px"
                widthLg="100%"
                maxWidthLg="992px"
              >
                <BannerBox
                  position="relative"
                  maxWidth="1124px"
                  maxWidthLg="992px"
                  widthMd="95%"
                  boxSizing="border-box"
                  margin="0px auto"
                  pb="3rem"
                >
                  <BannerBox pb="30px" pbLg="16px" widthMd="100%" display="flex" alignItems="end">
                    <BannerHeading
                      size="44px"
                      sizeMd="24px"
                      sizeLg="32px"
                      BodyColor
                      weight="500"
                      width="100%"
                      textAlign="center"
                      mb="0px"
                    >
                      Beginners Guide
                    </BannerHeading>
                  </BannerBox>

                  <SlideImages onSliderData={eventSlider} {...props} />

                  <BannerBox
                    display="flex"
                    pb="80px"
                    pbLg="30px"
                    pt="99px"
                    ptLg="48px"
                    justifyContent="center"
                  >
                    <BannerBox widthMd="100%" display="flex" alignItems="end">
                      <BannerHeading
                        size="44px"
                        sizeMd="24px"
                        sizeLg="32px"
                        BodyColor
                        weight="500"
                        width="100%"
                        textAlign="center"
                        mb="0px"
                      >
                        How to Tutorials
                      </BannerHeading>
                    </BannerBox>
                  </BannerBox>

                  <SlideImages onSliderData={tutorialEventSlider} {...props} />

                  <BannerBox
                    display="flex"
                    pb="80px"
                    pbLg="30px"
                    pt="99px"
                    ptLg="48px"
                    justifyContent="center"
                  >
                    <BannerBox widthMd="100%" display="flex" alignItems="end">
                      <BannerHeading
                        size="44px"
                        sizeMd="24px"
                        sizeLg="32px"
                        BodyColor
                        weight="500"
                        width="100%"
                        textAlign="center"
                        mb="0px"
                      >
                        Crypto Basics
                      </BannerHeading>
                    </BannerBox>
                  </BannerBox>

                  <SlideImages onSliderData={cryptoEventSlider} {...props} />
                </BannerBox>
              </BannerSection>
              {/* <hr style={{ margin: "0px auto" }} /> */}
            </MainElement>

            {/* Footer Index */}
            <IndexFooter {...props} />
          </>
        )}
      </BannerBox>
    </>
  );
};

export default withRouter(TutorialComponent);
