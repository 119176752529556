/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import moment from "moment";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import CountTimerInWords from "../../../components/CountDownTimer/countDownTimer";
import { JoyStickIcon, TimerGreenIcon, TimerGreyIcon } from "../../../assets/svgs";
import { ImgElement } from "../../../components/Reusable";
import { HeadingFourth } from "../../../assets/StyleComponents/FontSize/headingFourth.style";
import { HeadingSix } from "../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { capitalize } from "../../../utils";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";

const TokenSaleCard = ({ data, activeState }) => {
  const history = useHistory();
  const {
    logo,
    type,
    coin,
    endAt,
    startAt,
    tokenSaleId,
    description,
    category,
    isReminder,
    endingSoon,
    categoryIcon,
    name
  } = data;

  const isTheme = useSelector((state) => state.theme.isTheme);
  const userData = useSelector((state) => state.dashboard.userData);
  const { width } = useWindowSize();
  const prevLang = localStorage.getItem("ljs-lang");

  const handleCompetitionLink = () => {
    history.push(`${prevLang ? `/${prevLang}` : "/en"}/token-sale/${activeState}/${tokenSaleId}`);
  };

  return (
    <BannerBox
      CardWhite
      CardWhiteShadow
      CardBorderPrimary
      width="332px"
      height="210px"
      heightLg="248px"
      widthLg="100%"
      radius="10px"
      // widthLg="100%"
      // heightLg="auto"
      display="flex"
      direction="column"
      justifyContent="space-evenly"
      padding="0px 15px"
      position="relative"
      cursorPointer={"pointer"}
      onClick={handleCompetitionLink}
    >
      {endingSoon && (
        <BannerHeadingSpan
          size="12px"
          weight="400"
          width="84px"
          height="32px"
          position="absolute"
          top="0"
          right="10px"
          display="flex"
          alignItems="start"
          justifyContent="center"
          FlagShapeTc
        >
          Ending Soon
        </BannerHeadingSpan>
      )}
      <BannerBox display="flex" alignItems="center" gap="16px" mt="10px" mtLg="5px">
        <BannerBox>
          <ImgElement
            src={data?.logo ? logo : require("../../../assets/img/Card_5-03.jpg")}
            alt="Lcx Competition"
            width="64px"
            height="64px"
            ObjectFit="cover"
          />
        </BannerBox>
        <BannerBox display="flex" direction="column" gap="5px">
          <HeadingFourth size="18px" weight="500">
            {coin} Token
          </HeadingFourth>
          <HeadingSix size="14px" weight="400" textTransform="capitalize">
            {name} ({capitalize(type)})
          </HeadingSix>
          {width < 1024 && width > 767 && (
            <BannerBox
              width="90px"
              height="34px"
              BadgeBG
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="5px"
              BorderRadius="5px"
              mt="2px"
            >
              {categoryIcon && <ImgElement src={categoryIcon} width="16px" height="16px" />}
              <BannerHeadingSpan size="12px" weight="500" txtGrey>
                {category}
              </BannerHeadingSpan>
            </BannerBox>
          )}
        </BannerBox>
      </BannerBox>
      <BannerBox>
        <ParaTag weight="400" size="14px" lineHeight="20px">
          {description.length > 50 ? description.slice(0, 45) + "..." : description}
        </ParaTag>
      </BannerBox>
      <BannerBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="5px"
      >
        {width > 1024 && (
          <BannerBox
            width="90px"
            height="34px"
            BadgeBG
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="5px"
            BorderRadius="5px"
          >
            {categoryIcon && <ImgElement src={categoryIcon} width="16px" height="16px" />}
            <BannerHeadingSpan size="12px" weight="500" txtGrey>
              {category}
            </BannerHeadingSpan>
          </BannerBox>
        )}
        {width < 767 && (
          <BannerBox
            width="81px"
            height="34px"
            BadgeBG
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="5px"
            BorderRadius="5px"
          >
            <ImgElement
              src={categoryIcon ? categoryIcon : JoyStickIcon}
              width="16px"
              height="16px"
            />
            <BannerHeadingSpan size="12px" weight="500" txtGrey>
              {category}
            </BannerHeadingSpan>
          </BannerBox>
        )}
        {activeState === "participated" ? (
          <>
            <ButtonComponent
              width="147px"
              btnHeight="34px"
              TCClaim
              size="12px"
              weight="500"
              widthLg="100%"
              widthMd="147px"
              onClick={handleCompetitionLink}
            >
              Claim Now
            </ButtonComponent>
          </>
        ) : (
          <BannerBox display="inline-flex" alignItems="center">
            <TimerGreyIcon width="20px" height="20px" />
            <ParaTag
              size="12px"
              ml="6px"
              display="flex"
              alignItems="center"
              color={isTheme ? "#A7A9AB" : "#606F80"}
            >
              {activeState === "ongoing"
                ? "Ends in "
                : activeState === "upcoming"
                ? "Starts in "
                : "Ended on "}

              <BannerHeading ml="4px" display="flex" alignItems="center">
                {activeState === "completed" ? (
                  <BannerHeadingSpan size="12px" weight="400">
                    {moment(endAt * 1000).format("DD/MM/YYYY")}
                  </BannerHeadingSpan>
                ) : (
                  <CountTimerInWords
                    eventTime={activeState === "ongoing" ? endAt : startAt}
                    type="Cardcounter"
                  />
                )}
              </BannerHeading>
            </ParaTag>
          </BannerBox>
        )}
      </BannerBox>
    </BannerBox>
  );
};

export default TokenSaleCard;
