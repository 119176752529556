/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { MultiLangIcon } from "../../assets/svgs/index";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox, BannerSection } from "../../assets/StyleComponents/Exchange.style";
// import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import InputSwitch from "../../components/Reusable/InputSwitch";
import { modifyPreferedLanguage } from "../../lib/api";
import { showSuccessMsg } from "../../utils/notification";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getUserDetails } from "../../actions/dashboard";
import { getLocalStorage, setLocalStorage } from "../../utils/helpers";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList, getUserSelectedLang } from "../../Modules/Generic/selectors";
// import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";
// import Localize from "localizejs";

// const localize = new Localize({
//   key: "s8te1R8F3yjTB",
//   rememberLanguage: true
// });

const LanguageHocProps = createStructuredSelector({
  transalationList: getTranslationLanguageList(),
  userSelectedLang: getUserSelectedLang()
});

const LanguagePreference = () => {
  // const [alerts, setAlerts] = useState({});
  // const [alertsLoading, setIsAlertLoading] = useState();
  const [allLanguages, setAllLanguages] = useState([]);
  const value = localStorage.getItem("ljs-lang");
  let prevLang = getLocalStorage("ljs-lang");
  const userData = useSelector((state) => state.dashboard.userData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [language, setLanguage] = useState(prevLang || userData?.preferredLanguage || "en");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  let { transalationList, userSelectedLang } = useSelector(LanguageHocProps);
  let transalationListMemo = useMemo(() => transalationList, [transalationList]);

  useEffect(() => {
    window.Localize.getAvailableLanguages(function (err, data) {
      if (err) return console.log(err);
      setAllLanguages(data);
    });
    localStorage.setItem("ljs-lang", prevLang || userData?.preferredLanguage || "en");
    setLocalStorage(new Date().getTime(), "last-lang-update-time");
  }, []);

  // const changeLanguage = (lang) => {
  //   setLanguage(lang);
  //   localize.setLanguage(lang);
  // };

  // const setLocalize = (data) => {
  //   console.log("setLanguage: from '" + data.from + "' to '" + data.to + "'");
  //   localStorage.setItem("ljs-lang", "en");
  //   window.Localize.translatePage();
  // };

  // useEffect(() => {
  //   if (window.Localize) {
  //     window.Localize.on("setLanguage", setLocalize);
  //   } else {
  //     console.log("<<<<<<No Data JI>>>>>>");
  //   }
  // }, [language]);

  // Localize.on("setLanguage", function (data) {
  //   // Persist the currently selected language choice
  //   localStorage.setItem("lz_currentLanguage", data.to);
  // });

  useEffect(() => {
    if (isLoggedIn) {
      let checkPath = location.pathname?.split("/")[1];
      if(transalationListMemo && transalationListMemo.length > 0){
        if (checkPath && userData.preferredLanguage && checkPath !== language) {
          setTimeout(() => {
            dispatch(getUserDetails());
          }, 700);
        }
      }
    }
  }, [language]);

  if (userData && !userData.hasMultilingualSupport)
    return (
      <Redirect
        to={`${language ? `/${language}` : ""}/setting/profile`}
      />
    );
  return (
    <BannerBox display="flex" direction="column" paddingMd="20px 16px" padding="0px 16px">
      <div className="element-wrapper mb-0 pb-0">
        {/* {!alertsLoading && ( */}
        <BannerBox display="flex" direction="column" paddingMd="20px 0px">
          <BannerBox position="relative" widthLg="100%">
            <BannerHeading size={"24px"} sizeLg={"20px"} sizeMd={"18px"} mb={"8px"} weight="bold">
              Language Preference
            </BannerHeading>
            {/* <BannerHeading txtGrey size={"16px"} sizeLg={"18px"} sizeMd={"12px"}>
              Alerts notify you of account-related activity, ensuring you stay well-informed
            </BannerHeading> */}
          </BannerBox>

          <BannerSection width="100%" display="flex" directionLg="column" direction="row">
            <BannerBox
              display="flex"
              width="40%"
              widthLg="100%"
              direction="column"
              widthMd="100%"
              mt="36px"
              gap="35px"
            >
              {allLanguages.length > 0 &&
                allLanguages.map((lan) => {
                  return (
                    <>
                      <BannerBox
                        display="flex"
                        direction="row"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <BannerBox display="flex" direction="column" gap="6px">
                          <BannerHeading size="16px" weight="500">
                            {lan.name}
                          </BannerHeading>
                        </BannerBox>

                        <InputSwitch
                          checked={language === lan.code}
                          setChecked={() => {
                            setLanguage(lan.code);

                            localStorage.setItem("ljs-lang", lan.code);
                            setLocalStorage(new Date().getTime(), "last-lang-update-time");
                            const data = {
                              prefLanguage: String(lan.code)
                            };

                            modifyPreferedLanguage(data).then((res) => {
                              if (res.data.data) {
                                showSuccessMsg(`Language Updated to ${lan.name}`);
                                setLanguage(res.data.data.preferredLanguage);
                              }
                            });
                            window.Localize.setLanguage(lan.code);
                            // window.location.reload();
                          }}
                        />
                      </BannerBox>
                    </>
                  );
                })}
            </BannerBox>

            <BannerBox width="60%" justifyContent="center" display="flex" widthLg="100%">
              <MultiLangIcon width="316.5px" height="263px" />
            </BannerBox>
          </BannerSection>
        </BannerBox>
        {/* )} */}
      </div>
    </BannerBox>
  );
};

export default withRouter(LanguagePreference);
