import React from "react";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import { useEffect } from "react";
import { useState } from "react";
import { ZapIcon } from "../../../../../assets/svgs";

const getDetailsFromBondSummary = (bondSummary) => {
  const { issuanceAmount, perUnitCost, minimumAmount, isin, bondName } = bondSummary;
  return [
    { title: "7%", subtitle: "Fixed yield p.a." },
    { title: issuanceAmount, subtitle: "Issuance amount" },
    { title: perUnitCost, subtitle: `1 ${bondName}` },
    { title: "Quarterly", subtitle: "Payout period" },
    { title: minimumAmount, subtitle: "Minimum amount" },
    { title: isin, subtitle: "ISIN" },
    { title: "Two Months", subtitle: "Notice period" },
    { title: "Quarterly", subtitle: "Termination" },
    { title: "1st Feb 2030", subtitle: "Expires on" },
    { title: "LCX AG, Liechtenstein", subtitle: "Issuer" }
  ];
};

const BondDetailPoints = ({ bondSummary }) => {
  const windowDimension = useWindowSize();
  const [bondDetails, setBondDetais] = useState(() => getDetailsFromBondSummary(bondSummary));

  useEffect(() => {
    const detail1 = { title: "LCX AG, Liechtenstein", subtitle: "Issuer" };
    const detail2 = { title: "1st Feb 2030", subtitle: "Expires on" };

    if (windowDimension.width >= 768 && bondDetails[9] !== detail1) {
      let arr = [...bondDetails];
      arr[9] = detail1;
      arr[8] = detail2;
      setBondDetais(arr);
    } else {
      let arr = [...bondDetails];
      arr[9] = detail2;
      arr[8] = detail1;
      setBondDetais(arr);
    }
    // eslint-disable-next-line
  }, [windowDimension]);

  return (
    <DivElement
      width="100%"
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      EarnPageStyles
      displaySm="grid"
      GridColsSm="1fr 1fr"
    >
      {bondDetails.map((item, i) => (
        <div key={i} className="bondsumm__point">
          <ZapIcon width="14.9px" height="16.5px" />
          <div className="bondsumm__point__content">
            <TextWrapper primary fontSize="14px">
              {item.title}
            </TextWrapper>
            <TextWrapper secondary fontSize="12px" lineHeight="2">
              {item.subtitle}
            </TextWrapper>
          </div>
        </div>
      ))}
    </DivElement>
  );
};

export default BondDetailPoints;
