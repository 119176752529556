/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from "react";
import { DeleteIcon, EditIcon } from "../../../../../assets/svgs";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import CancelAllOrders from "./CancelAllOrders";
import moment from "moment";
import { numberFormatter } from "../../../../../utils";
import { updateSelectedPair } from "../../../../../actions/trading";
import { useDispatch } from "react-redux";
import { setPrecision } from "../../../../../utils/math";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  SpanElement,
  ThreeDotDropdown,
  ThDropdown,
  DivElement
} from "../../../../../components/Reusable";

const SingleRow = ({
  order,
  onVisitPair,
  type,
  isMobile,
  activeCol,
  index,
  onCancelOrder,
  onEditHandler
}) => {
  const ref = useRef();

  const onEdit = (data) => {
    let rect = ref?.current?.getBoundingClientRect();
    onEditHandler(data, rect);
  };

  return (
    <Tr height="48px" key={order.id} borderBottom>
      <Td pointer textAlign="left" paddingLeftMd="12px" onClick={() => onVisitPair(order.Pair)}>
        {order?.Pair?.split("/")[0] || ""}
        <SpanElement fontSize="inherit" secondary>
          /{order?.Pair?.split("/")[1] || ""}
        </SpanElement>{" "}
        <DivElement ref={ref}></DivElement>
      </Td>
      {!isMobile && (
        <Td textAlign="right" paddingRightMd="16px">
          {numberFormatter(order?.Price)}
        </Td>
      )}
      <Td
        red={order.Side === "SELL" ? true : false}
        green={order.Side === "BUY" ? true : null}
        textAlign="left"
      >
        {order?.Side || ""}
      </Td>

      {(!isMobile || (isMobile && activeCol === "Amount")) && (
        <Td textAlign="right" paddingRightMd="12px" paddingRightSm="12px">
          {numberFormatter(order.Amount)}
        </Td>
      )}

      {activeCol === "Status" && (
        <Td textAlign="right" paddingRightMd="12px" paddingRightSm="12px">
          {order.Status}
        </Td>
      )}

      {activeCol === "Price" && (
        <Td textAlign="right" paddingRightMd="12px" paddingRightSm="12px">
          {numberFormatter(order?.Price)}
        </Td>
      )}

      {activeCol === "Time" && (
        <Td
          textAlign="right"
          paddingLeft={type !== "openOrders" ? "12px" : ""}
          paddingRightMd="12px"
          paddingRightSm="12px"
        >
          {moment(
            type === "orderExecutions" ? order["CreatedAt"] * 1000 : order["UpdatedAt"] * 1000
          ).format("DD/MM/YY, HH:mm")}
        </Td>
      )}

      {type === "orderExecutions" ? (
        <Fragment>
          {activeCol === "Fee" && (
            <Td textAlign="right" paddingRightMd="12px" paddingRightSm="12px">
              {numberFormatter(order?.Fee || "")}
            </Td>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {activeCol === "Filled" && (
            <Td textAlign="right" paddingRightMd="12px" paddingRightSm="12px">
              {setPrecision(order?.FilledPer, 2)}%
            </Td>
          )}
        </Fragment>
      )}

      {type === "openOrders" && (
        <Td textAlign="right" paddingRightMd="10px">
          <ThreeDotDropdown
            activeContainerProps={index === 0 ? { top: "36px", bottom: "initial" } : ""}
          >
            {order?.Status !== "PARTIAL" && (
              <ThreeDotDropdown.ThreeDotOption onClick={() => onEdit(order)}>
                <DivElement flexAlignCenter>
                  <EditIcon /> Edit
                </DivElement>
              </ThreeDotDropdown.ThreeDotOption>
            )}
            <ThreeDotDropdown.ThreeDotOption onClick={() => onCancelOrder(order)}>
              <DivElement flexAlignCenter>
                <DeleteIcon /> Delete
              </DivElement>
            </ThreeDotDropdown.ThreeDotOption>
          </ThreeDotDropdown>
        </Td>
      )}
    </Tr>
  );
};

const OrdersTableMobile = ({ ordersList, type, onCancelOrder, onEditHandler }) => {
  let option = type === "orderExecutions" ? "Fee" : "Filled";
  const [activeCol, setActiveCol] = useState("Status");
  const [options, setOptions] = useState(["Status", "Time", option]);
  const { width } = useWindowSize();
  const isMobile = width <= 767 ? true : false;
  const dispatch = useDispatch();

  const onVisitPair = (pair) => {
    dispatch(updateSelectedPair(pair));
  };

  useEffect(() => {
    if (isMobile && options.length === 3) {
      setOptions(["Amount", "Price", "Status", "Time", option]);
    }

    if (!isMobile && options.length === 5) {
      setOptions(["Status", "Time", option]);
    }
    if (!isMobile && activeCol === "Amount") {
      setActiveCol("Status");
    }
  }, [isMobile]);

  return (
    <Table headerStickyTop>
      <Thead height="36px">
        <Tr height="36px">
          <Th textAlign="left" paddingLeftMd="12px" paddingLeftSm="12px">
            Pair
          </Th>
          {!isMobile && (
            <Th textAlign="right" paddingRightMd="16px">
              Price
            </Th>
          )}
          <Th textAlign="left" paddingLeftSm="0">
            Side
          </Th>
          {!isMobile && <Th textAlign="right">Amount</Th>}
          <ThDropdown
            paddingRightMd="12px"
            paddingRightSm="12px"
            right="0"
            activeCol={activeCol}
            selectOption={setActiveCol}
            OPTIONS={options}
          />
          {type === "openOrders" && (
            <Th textAlign="right" paddingRightMd="12px">
              <CancelAllOrders orders={ordersList} />
            </Th>
          )}
        </Tr>
      </Thead>
      {ordersList && ordersList.length > 0 && (
        <Tbody height="48px" fontSize="12px" lineHeight="18px">
          {ordersList.map((order, index) => (
            <SingleRow
              key={order.id}
              index={index}
              order={order}
              type={type}
              isMobile={isMobile}
              activeCol={activeCol}
              onVisitPair={onVisitPair}
              onCancelOrder={onCancelOrder}
              onEditHandler={onEditHandler}
            />
          ))}
        </Tbody>
      )}
    </Table>
  );
};

export default OrdersTableMobile;
