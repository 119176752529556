import React from "react";
import moment from "moment";
// import Datetime from "react-datetime";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { DatePickerStyles } from "../../../assets/StyleComponents/DatePicker.style";
// import DatePickerComponent from "../../../components/DatePicker/DatePicker";

const DateTimeInput = ({
  title,
  name,
  value,
  inputProps,
  errorProps,
  setFieldValue,
  error,
  containerClass,
  left
}) => {
  return (
    <BannerBox mb="32px" width="100%">
      <Label txtGrey size="14px" width="100%">
        {title}
      </Label>
      <BannerBox DatePickerContainer mt="8px">
        {/* <DatePickerComponent
          DobStatus={true}
          selectedDate={value}
          setQuickFilterValue={(value) => setFieldValue(name, value)}
          name={"DD/MM/YYYY"}
          left={left}
        /> */}
        <DatePickerStyles
          name={name}
          timeFormat={false}
          inputProps={{
            placeholder: "YYYY/MM/DD",
            readOnly: true,
            className: "inputMain"
          }}
          closeOnSelect={true}
          value={value}
          isValidDate={(current) => {
            const isBeforeEighteenYearsAgo = current.isBefore(moment().subtract(18, "years"));
            const isAfterOneHundredTenYearsAgo = current.isAfter(moment().subtract(120, "years"));
            return isBeforeEighteenYearsAgo && isAfterOneHundredTenYearsAgo;
          }}
          onChange={(value) => setFieldValue(name, value.format("YYYY/MM/DD"))}
          {...inputProps}
        />
      </BannerBox>
      <ErrorMessageDiv
        name={name}
        component="div"
        mt="6px"
        size="14px"
        sizemd="12px"
        weight="300"
        txtDanger
        {...errorProps}
      />
    </BannerBox>
  );
};

export default DateTimeInput;
