import styled, { css } from "styled-components";

const FormInput = styled.input`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "46px"};
  background: ${(props) => (props.bck ? props.bck : props.theme.inputBackground)};
  position: ${(props) => props.position};
  border: 0;
  border-bottom: ${(props) => `1px solid ${props.theme.inputBorder}`};
  border-radius: ${(props) => props.radius || "2px"};
  color: ${(props) => props.theme.bodyText};
  outline: none;
  font-size: ${(props) => props.size || "16px"};
  font-weight: ${(props) => props.weight};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-right: ${(props) => props.mr};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding || "0.56rem 0.7rem"};
  padding-right: ${(props) => props.paddingRight || "0.56rem 0.7rem"};
  ${(props) => props.boxShadow && `box-shadow: ${props.boxShadow}`};
  &:focus,
  &:focus-visible {
    border-bottom: 1px solid ${(props) => props.theme.primary};
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.CheckBoxInput &&
    css`
      &:disabled {
        color: #636c72;
      }
    `}
  ${(props) =>
    props.MarketsInput &&
    css`
      border: 1px solid ${(props) => props.theme.borderColor} !important;
      border-radius: 5px;
    `}
  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.subText};
    `};
  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText};
    `};
  ${(props) =>
    props.SearchBarCOlors &&
    css`
      color: rgba(255, 255, 255, 0.7);
    `};

  ${(props) =>
    props.AddBenificaryInput &&
    css`
      position: relative;
      padding: 0rem 0.7rem;
      border: 0px;
      border-bottom: 1px solid ${(props) => props.theme.inputBorder} !important;
      background: ${(props) => props.theme.inputBackground} !important;
      border-radius: 5px 5px 0 0;
      color: ${(props) => props.theme.bodyText} !important;
    `}

  @media (max-width: 1024px) {
    ${(props) =>
      props.borderLg &&
      `border: ${
        props.borderLg !== true ? props.borderLg : ` 1px solid ${props.theme.borderColor}`
      }`};
    ${(props) => props.displayLg && `display: ${props.displayLg}`};
    ${(props) => props.alignItemsLg && `align-items: ${props.alignItemsLg}`};
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg}`};
    ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg}`};
    ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`};
    ${(props) => props.mtLg && `margin-top: ${props.mtLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mbLg}`};
    ${(props) => props.mlLg && `margin-left: ${props.mlLg}`};
    ${(props) => props.mrLg && `margin-right: ${props.mrLg}`};
    ${(props) => props.mrLg && `margin-right: ${props.mrLg}`};
    ${(props) => props.widthLg && `width: ${props.widthLg}`};
  }
  @media (max-width: 767px) {
    ${(props) =>
      props.borderMd &&
      `border: ${
        props.borderMd !== true ? props.borderMd : ` 1px solid ${props.theme.borderColor}`
      }`};
    ${(props) => props.displayMd && `display: ${props.displayMd}`};
    ${(props) => props.alignItemsMd && `align-items: ${props.alignItemsMd}`};
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd}`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mbMd}`};
    ${(props) => props.mlMd && `margin-left: ${props.mlMd}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`}
    ${(props) => props.widthMd && `width: ${props.widthMd}`};
  }
`;

export { FormInput };
