import React, { useContext, useMemo } from "react";

import { GridBox } from "../../../assets/StyleComponents/GridBox.style";
import { WalletContext } from "../../../contexts/WalletPage/BalancePage/wallet";
import DashbaordDataCard from "../Components/DashboardDataCards";
import ChartDashboard from "../ChartDashboard";
import SkeletonLoader from "../../../components/SkeletonLoader";

const DashboardChartData = (props) => {
  let { rowData, sent, activePlan, isLoaderWallet, isLoaderExch, isTheme } = props;
  let option = {
    legend: {
      display: false,
      position: "right"
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    }
  };
  let isLoading = sent === "exchange" ? isLoaderWallet : isLoaderExch;

  let { hideStatus } = useContext(WalletContext);
  
  return useMemo(() => {
    return (
      <DashbaordDataCard {...props}>
        <GridBox
          position="relative"
          gridTempColumn="1fr"
          mt={activePlan === "chart" &&  "20px"}
          height="100%"
        >

          {(isLoading || (!isLoading && !rowData)) &&
            <GridBox
              gridTempColumn="260px 1fr"
              gridTempColumnLg="2fr 1fr"
              gridTempColumnMd="2fr 1fr"
              // mt="34px"
              mlLg="-12px"
              mrLg="-12px"
              gridGap="30px"
              gridGapLg="10px"
            >
              <SkeletonLoader height={200} givenClass={"d-flex flex-column"} />
              <SkeletonLoader height={200} givenClass={"d-flex flex-column"} />
            </GridBox>
          }

          {!isLoading && activePlan === "chart" && 
            <ChartDashboard
              hideValue={!hideStatus}
              data={rowData}
              options={option}
              activePlan={activePlan}
            />
          }
        </GridBox>
      </DashbaordDataCard>
    );
  }, [ rowData, hideStatus, isLoaderWallet, isLoaderExch, activePlan, isTheme ]);
};

export default DashboardChartData;