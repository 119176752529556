import styled, { css } from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { MarginStyle } from "../../../assets/StyleComponents/Reusable/Margin.style";
import { PaddingStyle } from "../../../assets/StyleComponents/Reusable/Padding.style";

const Th = styled.th`
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")} !important;
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : "")}!important;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "")};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
  flex: ${(props) => (props.flex ? props.flex : "")};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "")};
  ${MarginStyle}
  ${PaddingStyle}
  @media (max-width: 1024px) {
    text-align: ${(props) => (props.textAlignMd ? props.textAlignMd : "")};
    vertical-align: ${(props) => (props.verticalAlignMd ? props.verticalAlignMd : "")};
    font-weight: ${(props) => (props.fontWeightMd ? props.fontWeightMd : "")};
    font-size: ${(props) => (props.fontSizeMd ? props.fontSizeMd : "")};
    width: ${(props) => (props.widthMd ? props.widthMd : "")};
    height: ${(props) => (props.heightMd ? props.heightMd : "")};
    padding: ${(props) => (props.paddingMd ? props.paddingMd : "")};
    padding-top: ${(props) => (props.paddingTopMd ? props.paddingTopMd : "")};
    padding-right: ${(props) => (props.paddingRightMd ? props.paddingRightMd : "")};
    padding-bottom: ${(props) => (props.paddingBottomMd ? props.paddingBottomMd : "")};
    padding-left: ${(props) => (props.paddingLeftMd ? props.paddingLeftMd : "")};
    min-width: ${(props) => (props.minWidthMd ? props.minWidthMd : "")};
    flex: ${(props) => (props.flexMd ? props.flexMd : "")};
  }

  @media (max-width: 767px) {
    text-align: ${(props) => (props.textAlignSm ? props.textAlignSm : "")};
    vertical-align: ${(props) => (props.verticalAlignSm ? props.verticalAlignSm : "")};
    font-weight: ${(props) => (props.fontWeightSm ? props.fontWeightSm : "")};
    font-size: ${(props) => (props.fontSizeSm ? props.fontSizeSm : "")};
    width: ${(props) => (props.widthSm ? props.widthSm : "")};
    height: ${(props) => (props.heightSm ? props.heightSm : "")};
    padding: ${(props) => (props.paddingSm ? props.paddingSm : "0 8px")};
    padding-top: ${(props) => (props.paddingTopSm ? props.paddingTopSm : "")};
    padding-right: ${(props) => (props.paddingRightSm ? props.paddingRightSm : "")};
    padding-bottom: ${(props) => (props.paddingBottomSm ? props.paddingBottomSm : "")};
    padding-left: ${(props) => (props.paddingLeftSm ? props.paddingLeftSm : "")};
    min-width: ${(props) => (props.minWidthSm ? props.minWidthSm : "")};
    flex: ${(props) => (props.flexSm ? props.flexSm : "")};
  }

  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `}

  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.subText} !important;
    `};
  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText} !important;
    `};

  ${(props) =>
    props.Row1Market &&
    css`
      height: 2px !important;
    `}

  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `}
`;

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isTheme
});

export default compose(connect(mapStateToProps))(Th);
