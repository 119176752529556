import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { BorderCommanStyle } from "./Border.style";

export const TabsLink = styled(Link)`
  color: ${(props) => props.theme.subText};
  padding: 14px 16px;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  height: 52px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;

  @media (max-width: 1024px) {
  }
  @media (max-width: 767px) {
    height: 52px;
    padding: 0px 16px !important;
    font-size: 12px;
  }
  :hover {
    color: ${(props) => props.theme.primaryBlue};
    background: ${(props) => props.theme.TabsSelection};
    font-weight: 500;
    transition: all 600ms;
  }
  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.primaryBlue};
      background: ${(props) => props.theme.TabsSelection};
      font-weight: 500;
    `}
`;
export const TabsWithoutLink = styled.span`
  color: ${(props) => props.theme.subText};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: ${(props) => `${props.padding || "14px 16px"}`};
  height: 100%;
  font-weight: 500;
  font-size: ${(props) => `${props.size || "16px"}`};
  height: ${(props) => `${props.height || "52px"}`};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;

  ${BorderCommanStyle}

  @media (max-width: 1024px) {
  }
  @media (max-width: 767px) {
    height: 52px;
    padding: ${(props) => props.paddingSm || "0px 16px"} !important;
    font-size: 12px;
  }
  :hover {
    color: ${(props) => props.theme.primaryBlue};
    background: ${(props) => props.theme.TabsSelection};
    font-weight: 500;
    transition: all 600ms;
  }
  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.primaryBlue};
      background: ${(props) => props.theme.TabsSelection};
      font-weight: 500;
    `}
`;