import { useContext } from "react";
import { DivElement, TextWrapper } from "../../Reusable";
import { DepositContext } from "../../../contexts/WalletPage/DepositPage/depositContext";
import { DepositOrangeIcon, LightningIcon, WithdrawalOrangeIcon } from "../../../assets/svgs";
import { NewTagOrangeIllustration } from "../../../assets/svgs/illustrations";

const LightningBanner = ({ type }) => {
  const { setIsCardSelected } = useContext(DepositContext);

  const onClickHandler = () => {
    setIsCardSelected(true);
  };

  return (
    <DivElement
      cursor
      flex
      relative
      width="100%"
      height="100%"
      maxWidth="424px"
      maxWidthSm="100%"
      maxHeight="156px"
      maxHeightSm="154px"
      bdLightOrange
      border="1px solid"
      borderRadius="10px"
      onClick={onClickHandler}
    >
      <DivElement absolute right="-1px" top="-2px">
        <NewTagOrangeIllustration />
      </DivElement>
      <DivElement
        width="84px"
        widthSm="56px"
        height="100%"
        displayCenter
        bgOrange
        borderRadius="10px 0 0 10px"
        alt="Lightning"
      >
        <LightningIcon width="84px" widthSm="56px" height="84px" heightSm="56px" />
      </DivElement>
      <DivElement
        width="100%"
        height="100%"
        padding="32px 0 32px 29px"
        paddingSm="22px 20px 10px 20px"
      >
        <TextWrapper
          secondary
          fontSize="14px"
          fontSizeSm="10px"
          lineHeight="20px"
          lineHeightSm="18px"
        >
          Fast, affordable, and easy transactions with
        </TextWrapper>
        <DivElement dAlignCenter marginTop="8px">
          <TextWrapper fontSize="18px" fontSizeSm="14px" lineHeight="21.6px" lineHeightSm="20px">
            Bitcoin Lightning Network
          </TextWrapper>
          <LightningIcon
            width="24px"
            widthSm="20px"
            height="24px"
            heightSm="20px"
            marginLeft="8px"
          />
        </DivElement>
        <DivElement dAlignCenter marginTop="17px" marginBottom="10px">
          {type === "Deposit" ? (
            <DepositOrangeIcon width="20px" height="20px" marginRight="6px" />
          ) : (
            <WithdrawalOrangeIcon width="20px" height="20px" marginRight="6px" />
          )}
          <TextWrapper
            fontSize="16px"
            fontSizeSm="14px"
            lineHeight="24px"
            lineHeightSm="20px"
            orange
          >
            Instant {type}
          </TextWrapper>
        </DivElement>
      </DivElement>
    </DivElement>
  );
};

export default LightningBanner;
