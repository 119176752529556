import React from "react";
import "react-phone-input-2/lib/style.css";
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";
import OtpInput from "react-otp-input";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";

const FormOtpInput = ({
  title,
  name,
  value,
  inputProps,
  errorProps,
  setFieldValue,
  error,
  containerClass,
  separator,
  inputStyle
}) => {
  return (
    <div>
      <Label size="14px" weight="300" sizeMd="12px" txtGrey>
        {title}
      </Label>
      <BannerBox mt="8px">
        <OtpInput
          // containerStyle={"otp-box-container"}
          inputStyle={inputStyle ? inputStyle : "otp-box twofa-input"}
          value={value}
          onChange={(value) => setFieldValue(name, value)}
          numInputs={6}
          isInputNum={true}
          separator={separator}
          {...inputProps}
          // separator={<span className="twofa-input-sep"></span>}
        />
      </BannerBox>
      <ErrorMessageDiv
        name={name}
        component="div"
        mt="6px"
        size="14px"
        sizemd="12px"
        weight="300"
        txtDanger
      />
    </div>
  );
};

export default FormOtpInput;
