/* eslint-disable no-console */

/* eslint-disable no-unused-vars */
import { fromJS } from "immutable";
import { UPDATE_LAYOUTS_RESPONSIVE, UPDATE_LAYOUT_RESPONSIVE } from "./const.js";

import { filterUSDVolume, getLocalStorageData } from "../../../utils/general.js";
import { getLocalStorage, setLocalStorage } from "../../../utils/helpers.js";

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-9")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-9",
      JSON.stringify({
        [key]: value
      })
    );
  }
}

function generateLayout() {
  let layoutCheck, checkSettingStatus;
  layoutCheck = JSON.parse(localStorage.getItem("rgl-9"));
  checkSettingStatus =
    localStorage.getItem("tradeSetting") !== null
      ? JSON.parse(localStorage.getItem("tradeSetting"))
      : false;
  if (layoutCheck === null) {
    let gridValue = [
      {
        i: "OrderForm",
        x: 0,
        y: 0,
        w: 15,
        h: 23,
        minH: 22,
        maxH: 26,
        minW: 2,
        static: !checkSettingStatus
      },
      {
        i: "orderBook",
        x: 15,
        y: 0,
        w: 15,
        h: 14,
        minH: 13,
        minW: 13,
        static: !checkSettingStatus
      },
      { i: "trades", x: 15, y: 14, w: 15, h: 9, static: !checkSettingStatus },
      { i: "Chart", x: 35, y: 0, w: 34, h: 14, static: !checkSettingStatus },
      { i: "userInfo", x: 35, y: 14, w: 34, h: 9, static: !checkSettingStatus }
    ];
    saveToLS("layout", gridValue);
    return gridValue.map(function (item, i) {
      return {
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
        i: item.i,
        static: item.static
      };
    });
  } else {
    return layoutCheck?.layout?.map(function (item, i) {
      return {
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
        i: item.i,
        static: !checkSettingStatus
      };
    });
  }
}

export function createInitialState() {
  return fromJS({
    tradeLayout: {
      lg: generateLayout(),
      md: generateLayout(),
      sm: generateLayout(),
      xs: generateLayout(),
      xxs: generateLayout()
    },
    tradeLayouts: {
      lg: generateLayout(),
      md: generateLayout(),
      sm: generateLayout(),
      xs: generateLayout(),
      xxs: generateLayout()
    },
    tradeMounted: false,
    tradeBreakpoints: { lg: 1200, md: 996, sm: 768 },
    tradeCols: { lg: 64, md: 64, sm: 64, xxs: 64 },
    tradeCompactType: "vertical",
    tradeCurrentBreakpoint: "lg"
  });
}

export function TradeResponsiveLayoutReducer(state = createInitialState(), action) {
  switch (action.type) {
    case UPDATE_LAYOUTS_RESPONSIVE:
      // saveToLS("layout", action.payload);
      return fromJS({
        ...state.toJS(),
        tradeLayouts: {
          lg: action.payload
        }
      });

    case UPDATE_LAYOUT_RESPONSIVE:
      return fromJS({
        ...state.toJS(),
        tradeLayout: {
          lg: action.payload
        }
      });

    default:
      return state;
  }
}
