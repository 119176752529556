import { useRef } from "react";
import moment from "moment";
import { DeleteIcon, EditIcon } from "../../../../../assets/svgs";
import CancelAllOrders from "./CancelAllOrders";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  SpanElement,
  DivElement
} from "../../../../../components/Reusable";
import { numberFormatter } from "../../../../../utils";
import { useDispatch } from "react-redux";
import { updateSelectedPair } from "../../../../../actions/trading";
import { setPrecision } from "../../../../../utils/math";

const SingleRow = ({ order, type, onCancelOrder, onEditHandler, borderBottom }) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const onVisitPair = (pair) => {
    dispatch(updateSelectedPair(pair));
  };

  const onEdit = (data) => {
    let rect = ref?.current?.getBoundingClientRect();
    onEditHandler(data, rect);
  };

  return (
    <Tr key={order.Id} height="24px" borderBottom={borderBottom} position="relative">
      {type === "openOrders" && (
        <Td textAlign="left" paddingLeft="8px">
          <DivElement flex>
            <DeleteIcon
              pointer
              width="16px"
              height="16px"
              marginRight="16px"
              onClick={() => onCancelOrder(order)}
            />
            {order?.Status !== "PARTIAL" && (
              <EditIcon width="16px" height="16px" pointer onClick={() => onEdit(order)} />
            )}
          </DivElement>
        </Td>
      )}
      <Td textAlign="left" paddingLeft={type !== "openOrders" ? "12px" : ""}>
        {moment(
          type === "orderExecutions" ? order["CreatedAt"] * 1000 : order["UpdatedAt"] * 1000
        ).format("DD/MM/YY, HH:mm")}
      </Td>
      <Td textAlign="left" onClick={() => onVisitPair(order.Pair)} pointer>
        {order?.Pair?.split("/")[0] || ""}
        <SpanElement fontSize="inherit" secondary>
          /{order?.Pair?.split("/")[1] || ""}
        </SpanElement>{" "}
        <DivElement ref={ref}></DivElement>
      </Td>
      <Td
        red={order.Side === "SELL" ? true : false}
        green={order.Side === "BUY" ? true : null}
        textAlign="left"
      >
        {order?.Side || ""}
      </Td>
      <Td textAlign="left">{numberFormatter(order?.Price)}</Td>
      <Td textAlign="left">{numberFormatter(order?.Amount)}</Td>
      {type === "orderExecutions" ? (
        <Td textAlign="right" paddingRight="12px">
          {numberFormatter(order?.Fee)}
        </Td>
      ) : (
        <Td textAlign="left">{setPrecision(order?.FilledPer, 2)}%</Td>
      )}
      <Td textAlign="left">{order?.Status || ""}</Td>
    </Tr>
  );
};

const OrdersTableWeb = ({ ordersList, type, onCancelOrder, onEditHandler }) => {
  return (
    <Table headerStickyTop>
      <Thead height="28px" fontSize="12px">
        <Tr height="28px">
          {type === "openOrders" && (
            <Th textAlign="left" paddingLeft="8px">
              <CancelAllOrders orders={ordersList} />
            </Th>
          )}
          <Th textAlign="left" paddingLeft={type !== "openOrders" ? "12px" : ""}>
            Time <SpanElement fontSize="10px">(DD/MM/YY, 24h)</SpanElement>
          </Th>
          <Th textAlign="left">Pair</Th>
          <Th textAlign="left">Side</Th>
          <Th textAlign="left">Price</Th>
          <Th textAlign="left">Amount</Th>
          {type === "orderExecutions" ? (
            <Th textAlign="right" paddingRight="12px">
              Fee
            </Th>
          ) : (
            <Th textAlign="left">Filled(%)</Th>
          )}
          <Th textAlign="left">Status</Th>
        </Tr>
      </Thead>
      {ordersList && ordersList.length > 0 && (
        <Tbody fontSize="12px" height="24px">
          {ordersList.map((order, index) => (
            <SingleRow
              key={index}
              order={order}
              index={index}
              type={type}
              onCancelOrder={onCancelOrder}
              onEditHandler={onEditHandler}
              borderBottom={index + 1 === ordersList.length ? null : true}
            />
          ))}
        </Tbody>
      )}
    </Table>
  );
};

export default OrdersTableWeb;
