import React, { useEffect } from "react";
import Lottie from "lottie-web";
import PulseAnimationJson from "./morePulse.json";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";

const PulseAnimation = ({ type }) => {
  const { width } = useWindowSize();
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector(`#animation-${type}`),
      animationData: PulseAnimationJson,
      renderer: "svg",
      loop: true,
      autoplay: true,
      accentColor: "red"
    });
  }, [type]);

  return <div id={`animation-${type}`} style={{ width: "14px", height: "17px", left: width > 1024 ? "29px" : "35px", position: "absolute", top: width > 1024 ? "-9px" : "-1px" }} />;
};

export default PulseAnimation;
