import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import config from "../../constants/config";
import { connect } from "react-redux";
import { clearRedirectToVerifyEmail } from "../../actions/auth";
import { compose } from "redux";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { TextInput } from "../../components/Reusable/FormInput";
import { formatAPIErrors } from "../../utils/general";
import { sendVerificationEmail } from "../../lib/api";
import { NonAuthButton } from "../../components/Modules/Authentication";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { trackPage } from "../../tracking/track";
import { DivElement } from "../../components/Reusable";
import { ChevronLeftBlueIcon } from "../../assets/svgs";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { RouterLink } from "../../components/Reusable";
import { HeadingSix } from "../../assets/StyleComponents/FontSize/HeadingSix.style";

const verifyEmailValidation = Yup.object().shape({
  email: Yup.string()
    .email("Email Address must be a valid email")
    .required("Email Address is required")
});

const getInitialValues = () => {
  return {
    email: ""
  };
};

const VerifyEmail = ({ clearRedirectToVerifyEmail, googleReCaptchaProps, isTheme }) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    trackPage("/verify/email", "Verify Email Page");

    clearRedirectToVerifyEmail();
    //  eslint-disable-next-line
  }, []);

  const onSubmit = (values) => {
    const { executeRecaptcha } = googleReCaptchaProps;
    if (!executeRecaptcha) {
      return;
    }

    setLoader(true);
    let data = {
      email: values.email
    };

    if (config.debug) data.debug = true;

    executeRecaptcha("verifyEmail")
      .then((token) => {
        return sendVerificationEmail({ ...data, "g-recaptcha-response": token });
      })
      .then((res) => {
        setLoader(false);
        showSuccessMsg(res.data.message);
      })
      .catch((err) => {
        setLoader(false);
        showErrorMsg(formatAPIErrors(err));
      });
  };

  return (
    <DivElement pageContainer display="flex" flexDirection="column" alignItems="center">
      <BannerHeading size="28px" weight="bold" sizeSm="20px">
        Verify your Email
      </BannerHeading>
      <HeadingSix size="14px" weight="300" mt="8px" BodyColor>
        We will send you a link on your email address, click on the same to verify
      </HeadingSix>

      <BannerBox LoginFormBg>
        <Formik
          initialValues={getInitialValues()}
          onSubmit={onSubmit}
          validateOnMount={true}
          validationSchema={verifyEmailValidation}
        >
          {({ values }) => (
            <Form>
              <TextInput
                title="Email Address"
                name="email"
                value={values.email}
                placeholder="satoshi@lcx.com"
                containerClass="fInput__form__field--w100"
              />

              <NonAuthButton loader={loader} label="Send Verification Email" />

              <BannerBox display="flex" justifyContent="center" mt="24px">
                <HeadingSix size="14px" BodyColor>
                  {"Don't have an account?"}&nbsp;
                </HeadingSix>
                <RouterLink to="/register" txtPrimary underline fontSize="14px">
                  Register
                </RouterLink>
              </BannerBox>

              <BannerBox display="flex" justifyContent="center" alignItems="center" mt="12px">
                <RouterLink
                  to="/login"
                  txtPrimary
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="14px"
                >
                  <ChevronLeftBlueIcon />
                  Back to Log in
                </RouterLink>
              </BannerBox>
            </Form>
          )}
        </Formik>
      </BannerBox>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme
});

const mapDispatchToProps = (dispatch) => ({
  clearRedirectToVerifyEmail: () => dispatch(clearRedirectToVerifyEmail())
});

export default compose(
  withGoogleReCaptcha,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(VerifyEmail);
