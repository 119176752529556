import React, { useRef, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../assets/svgs";
import { ScrollBarDiv } from "../../../assets/StyleComponents/Scrollbar.style";

const ScrollBar = ({ children }) => {
  const [scrollCount, setScrollCount] = useState(0);
  const ref = useRef();
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
    setScrollCount(ref.current.scrollLeft);
  };

  return (
    <ScrollBarDiv ScrollbarContainer height="100%">
      <div className="scrollbar">
        <div
          className={`scrollbar__btn scrollbar__btn--left ${scrollCount === 0 ? "scrollbar__btn--none" : ""
            }`}
          onClick={() => scroll(-50)}
        >
          <ChevronLeftIcon />
        </div>
        <div className="scrollbar__container" ref={ref}>
          {children}
        </div>
        <div className="scrollbar__btn scrollbar__btn--right" onClick={() => scroll(50)}>
          <ChevronRightIcon />
        </div>
      </div>
    </ScrollBarDiv>
  );
};

export default ScrollBar;
