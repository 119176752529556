import { Route, Switch, Redirect } from "react-router-dom";
import walletPage from "../containers/walletPage";
import orderPage from "../containers/orderPage";
import MarketContainer from "../containers/marketContainer";
// import TradePageIndex from "../views/tradePage/TradePageIndex.jsx";
// import TradingCompetition from "../containers/tradingCompetition";
import DigitalAssetsComponent from "../containers/assets";
import LcxTokenComponent from "../containers/lcxToken";
import FeeStructureComponent from "../containers/fee";
// import Error500 from "../containers/Error500";
import TradingDashboard from "../containers/dashboardPage";
import SettingPage from "../containers/SettingPage";
import SecurityComponent from "../containers/Security";
import TutorialsComponent from "../containers/Tutorial";
import MobileTvChart from "../views/MobileTvChart";
import MainTradingComp from "../views/NewtradingCompetition";
import MainPage from "../views/NewtradingCompetition/mainPage";
// import Referral from "../pages/Auth/Referral";
import { EarnDashboard, EarnInvest } from "../pages/Auth/Earn";
// import Verify from "../pages/Auth/Verify";
import KycProcess from "../pages/Auth/KycProcess";
import {
  // UserDashboard,
  BuyToken,
  ParticipatedSale,
  SaleAnalytics,
  AdminDashboard,
  PrivateTokenSale
  // PrivateTokenSale
} from "../pages/Auth/TokenSale";
import KycLastProcess from "../pages/Auth/KycLastProcess.jsx";
import WireFramingLandingPage from "../views/campaigns/index.jsx";
import RewardsHubHomePage from "../views/RewardsHub/index.jsx";
import TradePage from "../pages/Auth/TradePage/index.js";
import TokenSaleIndexPage from "../views/NewTokenSale/index.jsx";
import MobileDepthChart from "../views/MobileDepthChart/MobileDepthChart.jsx";
import NewReferal from "../views/NewRefer/index.jsx";
import MyRewardsSection from "../views/RewardsHub/MyRewardsSection/MyRewardsSection.jsx";
import PromotionsPage from "../views/Promotions/Promotions.jsx";

const AuthRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={TradingDashboard} />
      <Route exact path="/:lang/" component={TradingDashboard} />
      <Route exact path="/:lang/promotions" component={PromotionsPage} />

      <Route exact path="/markets" component={MarketContainer} />
      <Route exact path="/:lang/markets" component={MarketContainer} />

      <Route exact path="/setting/:tab" component={SettingPage} />
      <Route exact path="/:lang/setting/:tab" component={SettingPage} />

      <Route exact path="/setting/security/auth" component={SettingPage} />
      <Route exact path="/:lang/setting/security/auth" component={SettingPage} />

      <Route exact path="/setting/security/phishing" component={SettingPage} />
      <Route exact path="/:lang/setting/security/phishing" component={SettingPage} />

      <Route exact path="/trading-competition" component={MainPage} />
      <Route exact path="/:lang/trading-competition" component={MainPage} />

      <Route exact path="/trading-competition/:id" component={MainTradingComp} />
      <Route exact path="/:lang/trading-competition/:id" component={MainTradingComp} />

      <Route exact path="/order/:tab" component={orderPage} />
      <Route exact path="/:lang/order/:tab" component={orderPage} />

      <Route exact path="/wallet/:tab" component={walletPage} />
      <Route exact path="/:lang/wallet/:tab" component={walletPage} />

      <Route exact path="/trade/:pair?" component={TradePage} />
      <Route exact path="/:lang/trade/:pair?" component={TradePage} />
      {/* <Route  exact path="/competition" component={TradingCompetition} /> */}
      <Route exact path="/assets" component={DigitalAssetsComponent} />
      <Route exact path="/:lang/assets" component={DigitalAssetsComponent} />

      <Route exact path="/lcx-token" component={LcxTokenComponent} />
      <Route exact path="/:lang/lcx-token" component={LcxTokenComponent} />

      <Route exact path="/fee" component={FeeStructureComponent} />
      <Route exact path="/:lang/fee" component={FeeStructureComponent} />

      <Route exact path="/security" component={SecurityComponent} />
      <Route exact path="/:lang/security" component={SecurityComponent} />

      <Route exact path="/tutorials" component={TutorialsComponent} />
      <Route exact path="/:lang/tutorials" component={TutorialsComponent} />

      <Route exact path="/mobile-trade/:pair" component={MobileTvChart} />
      <Route exact path="/:lang/mobile-trade/:pair" component={MobileTvChart} />

      <Route exact path="/mobile-depth-chart/:pair" component={MobileDepthChart} />
      <Route exact path="/:lang/mobile-depth-chart/:pair" component={MobileDepthChart} />
      {/* <Route  exact path="/error500" component={Error500} /> */}
      <Route
        path="/support"
        component={() => {
          window.location.href = "https://support.lcx.com/en/";
        }}
      />
      <Route
        path="/:lang/support"
        component={() => {
          window.location.href = "https://support.lcx.com/en/";
        }}
      />
      <Route exact path="/login" render={() => <Redirect to="/" />} />
      <Route exact path="/register" render={() => <Redirect to="/" />} />

      <Route exact path="/referral" component={NewReferal} />
      <Route exact path="/:lang/referral" component={NewReferal} />

      <Route exact path="/earn/invest/:id" component={EarnInvest} />
      <Route exact path="/:lang/earn/invest/:id" component={EarnInvest} />
      <Route exact path="/earn" component={EarnDashboard} />
      <Route exact path="/:lang/earn" component={EarnDashboard} />
      {/* <Route exact path="/verify" component={Verify} /> */}
      <Route exact path="/verify/kyc" component={KycProcess} />
      <Route exact path="/:lang/verify/kyc" component={KycProcess} />

      <Route exact path="/campaigns" component={WireFramingLandingPage} />
      <Route exact path="/:lang/campaigns" component={WireFramingLandingPage} />

      <Route exact path="/rewards" component={RewardsHubHomePage} />
      <Route exact path="/:lang/rewards" component={RewardsHubHomePage} />

      <Route exact path="/rewards/bonus" component={MyRewardsSection} />
      <Route exact path="/:lang/rewards/bonus" component={MyRewardsSection} />

      <Route exact path="/verify/verification-plus" component={KycLastProcess} />
      <Route exact path="/:lang/verify/verification-plus" component={KycLastProcess} />
      <Route exact path="/private-sale" component={PrivateTokenSale} />
      <Route exact path="/:lang/private-sale" component={PrivateTokenSale} />
      <Route exact path="/token-sale/admin/ongoing/:id" component={SaleAnalytics} />
      <Route exact path="/:lang/token-sale/admin/ongoing/:id" component={SaleAnalytics} />

      <Route exact path="/token-sale/admin/upcoming/:id" component={SaleAnalytics} />
      <Route exact path="/:lang/token-sale/admin/upcoming/:id" component={SaleAnalytics} />

      <Route exact path="/token-sale/admin/completed/:id" component={SaleAnalytics} />
      <Route exact path="/:lang/token-sale/admin/completed/:id" component={SaleAnalytics} />

      <Route exact path="/token-sale/admin/:tab" component={AdminDashboard} />
      <Route exact path="/:lang/token-sale/admin/:tab" component={AdminDashboard} />

      <Route exact path="/token-sale" component={TokenSaleIndexPage} />
      <Route exact path="/:lang/token-sale" component={TokenSaleIndexPage} />
      <Route
        exact
        path="/token-sale/admin"
        render={() => <Redirect to="/token-sale/admin/ongoing" />}
      />
      <Route exact path="/:lang/join/:token" render={() => <Redirect to="/en" />} />
      <Route
        exact
        path="/:lang/token-sale/admin"
        render={() => <Redirect to="/:lang/token-sale/admin/ongoing" />}
      />
      
      <Route exact path=":lang?/rewards" component={RewardsHubHomePage} />
      <Route exact path="/token-sale/ongoing/:id" component={BuyToken} />
      <Route exact path="/:lang/token-sale/ongoing/:id" component={BuyToken} />

      <Route exact path="/token-sale/upcoming/:id" component={BuyToken} />
      <Route exact path="/:lang/token-sale/upcoming/:id" component={BuyToken} />

      <Route exact path="/token-sale/completed/:id" component={BuyToken} />
      <Route exact path="/:lang/token-sale/completed/:id" component={BuyToken} />

      <Route exact path="/token-sale/participated/:id" component={ParticipatedSale} />
      <Route exact path="/:lang/token-sale/participated/:id" component={ParticipatedSale} />

      <Route exact path="/token-sale/:tab" component={TokenSaleIndexPage} />
      <Route exact path="/:lang/token-sale/:tab" component={TokenSaleIndexPage} />

      <Route exact path="/token-sale" render={() => <Redirect to="/token-sale" />} />
      <Route exact path="/:lang/token-sale" render={() => <Redirect to="/token-sale" />} />
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default AuthRouter;
