/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { getKycContent } from "../../actions/kyc";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { KycStep1, KycStep3, KycStep4, SubmittedModal } from "../../components/Modules/Kyc";
import { withRouter } from "react-router-dom";
import { trackPage } from "../../tracking/track";
import { DivElement } from "../../components/Reusable";
// import { CloseIcon } from "../../assets/svgs";
import { KycSection } from "../../assets/StyleComponents/kyc.style";
import Step1c from "../../components/Modules/Kyc/Steps/KycStep1c";
import { getSourceList } from "../../lib/api";
import { showErrorMsg } from "../../utils/notification";
import { formatAPIErrors } from "../../utils/general";
import NewStep3 from "../../components/Modules/Kyc/Steps/NewFlows/NewStep3";
import { HeadingSix } from "../../assets/StyleComponents/FontSize/HeadingSix.style";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { HeadingSecond } from "../../assets/StyleComponents/FontSize/headsecond.style";
import { useLocation } from "react-router-dom";
// import { HeadingFourth } from "../../assets/StyleComponents/FontSize/headingFourth.style";

const KycProcess = ({ kycContent, getKycContent, history }) => {
  const [step, setStep] = useState(1);
  const [openGuidlines, setOpenGuidlines] = useState(false);
  const [isKycSubmitted, setIsKycSubmitted] = useState(false);
  const windowDimension = useWindowSize();
  const location = useLocation();
  const prevLang = localStorage.getItem("ljs-lang");

  const [sourceList, setSourceList] = useState("");
  const userData = useSelector((state) => state.dashboard.getUserData);
  useEffect(() => {
    // document.title = "LCX Exchange - KYC Verification";
    trackPage("/verify/kyc", "KYC Verification Page");
  }, []);

  useEffect(() => {
    // stopPageLoader();
    if (!kycContent) {
      let data = {
        type: "KYC",
        product: "FAQ",
        key: "STEP"
      };
      getKycContent(data);
    }

    // return () => {
    //   resumePageLoader();
    // };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSourceList()
      .then((res) => {
        setSourceList(res && res.data.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  }, []);

  useEffect(() => {
    if (windowDimension.width >= 1024 && openGuidlines) {
      setOpenGuidlines(false);
    }
    // eslint-disable-next-line
  }, [windowDimension]);

  useEffect(() => {
    if (userData) {
      changeStep();
    }
    // eslint-disable-next-line
  }, [userData]);

  const changeStep = () => {
    if (userData && userData["KYC"]) {
      if (
        userData["KYC"]["STATUS"] === "LEVEL2" ||
        userData["KYC"]["STATUS"] === "LEVEL3" ||
        userData["KYC"]["LEVEL2"]["status"] === "APPROVED"
      ) {
        setStep(2);
        history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/dashboard`);
      } else if (userData["KYC"]["LEVEL2"]["status"] === "SUBMITTED") {
        setStep(3);
        setIsKycSubmitted(true);
      } else if (
        userData["KYC"]["LEVEL2"]["step1"]["status"] !== "APPROVED" &&
        userData["KYC"]["LEVEL2"]["step1"]["status"] !== "SUBMITTED"
      ) {
        setStep(1);
      } else if (
        userData["KYC"]["LEVEL2"]["step2"]["status"] !== "APPROVED" &&
        userData["KYC"]["LEVEL2"]["step2"]["status"] !== "SUBMITTED"
      ) {
        setStep(3);
      } else if (
        userData["KYC"]["LEVEL2"]["step3"]["status"] !== "APPROVED" &&
        userData["KYC"]["LEVEL2"]["step3"]["status"] !== "SUBMITTED"
      ) {
        setStep(3);
      } else if (
        userData["KYC"]["LEVEL2"]["step4"]["status"] !== "APPROVED" &&
        userData["KYC"]["LEVEL2"]["step4"]["status"] !== "SUBMITTED"
      ) {
        setStep(3);
      } else if (
        userData["KYC"]["LEVEL2"] &&
        (userData["KYC"]["LEVEL2"].nextStep === "STEP2" ||
          userData["KYC"]["LEVEL2"].nextStep === "STEP3" ||
          userData["KYC"]["LEVEL2"].nextStep === "STEP4")
      ) {
        setStep(3);
      }
    }
  };

  const onClose = () => {
    if (
      userData["KYC"]["transactionRiskInfo"]["status"] !== "PARTIALLY_REJECTED" ||
      userData["KYC"]["transactionRiskInfo"]["status"] !== "REJECTED" ||
      userData["KYC"]["transactionRiskInfo"]["status"] !== "NOT_REQUIRED" ||
      userData["KYC"]["transactionRiskInfo"]["status"] !== "REQUESTED" ||
      userData["KYC"]["transactionRiskInfo"]["status"] !== "PARTIALLY_SUBMITTED"
    ) {
      history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/dashboard`);
    }
    setIsKycSubmitted(false);
  };

  return (
    <Fragment>
      {isKycSubmitted && <SubmittedModal onClose={onClose} />}

      <DivElement pageContainerNew className="parent">
        <KycSection StepWrapperSection>
          <BannerBox
            paddingMd="0px 20px"
            display="flex"
            alignItems="center"
            alignItemsMd="start"
            justifyContent="center"
            direction="column"
            mb="36px"
            mbMd="16px"
          >
            <HeadingSecond
              // className="stepWrapper__header__title"
              sizeMd="14px"
              weight="500"
              size="20px"
              mb="4px"
            >
              Step {step}/3:
              {step === 1 && " Identity"}
              {step === 2 && " Financial Details"}
              {step === 3 && " Verification"}
            </HeadingSecond>
            <HeadingSix
              // className="stepWrapper__header__subtitle"
              weightMd="400"
              sizeMd="12px"
              size="14px"
              weight="500"
            >
              {step === 1 && "Fill your identity details—it's completely secure with us."}
              {step === 2 && "Fill your financial details —it's completely secure with us."}
              {step === 3 && "Share your ID documents —it's completely secure with us."}
            </HeadingSix>
          </BannerBox>

          <Fragment>
            {step === 1 && (
              <KycStep1
                guidlinesModal={setOpenGuidlines}
                userData={userData}
                Parentstep={step}
                ParentsetStep={setStep}
              />
            )}
            {step === 2 && (
              <Step1c sourceList={sourceList} Parentstep={step} ParentsetStep={setStep} />
            )}
            {step === 3 && <NewStep3 Parentstep={step} />}
          </Fragment>
        </KycSection>
      </DivElement>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.getUserData,
  kycContent: state.kyc.kycContent,
  kycContentLoader: state.kyc.loader
});

const mapDispatchToProps = (dispatch) => ({
  getKycContent: (data) => dispatch(getKycContent(data))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(KycProcess);
