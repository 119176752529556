/* eslint-disable no-undef */
import styled, { css } from "styled-components";

const KycSection = styled.section`
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.margin && `margin: ${props.margin}`} !important;
  border-radius: 10px;

  @media (max-width: 767px) {
    ${(props) => props.marginMd && `margin: ${props.marginMd}`} !important;
    ${(props) => props.widthMd && `width: ${props.widthMd}`} !important;
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`} !important;
  }

  ${(props) =>
    props.VerifySection &&
    css`
    color: ${({ theme }) => theme.bodyText};

    .verify__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    
      @media (max-width: 767px) {
        align-items: flex-start;
        text-align: left;
      }
    }

    .verify__header__title {
      font-size: 28px;
      font-weight: bold;
      text-align: center;
    
      @media (max-width: 767px) {
        text-align: left;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .verify__header__subtitle {
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      margin-top: 17.5px;
    
      @media (max-width: 1024px) {
        margin-top: 0;
        .para_break {
          display: none;
        }
      }
    
      @media (max-width: 767px) {
        font-size: 14px;
        text-align: left;
        margin-top: 6px;
      }
    }

    .verify__container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 43.7px;
    
      @media (max-width: 1024px) {
        margin-top: 48.5px;
      }
      @media (max-width: 767px) {
        margin-top: 23px;
      }
    }
    .verify__box {
      width: 621px;
      min-height: 450px;
      padding: 32px 45px 38px 41.8px !important;
      background:${({ theme }) => theme.level1Card};
    
      @media (max-width: 1024px) {
        width: 100%;
        padding: 50px 39.2px 46.9px 39px !important;
      }
      @media (max-width: 767px) {
        padding: 24px 21px 26px 22px !important;
      }
    }
    .verify__box__title {
      font-size: 18px;
      font-weight: bold;
    
      @media (max-width: 1024px) {
        font-size: 20px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    .verify__steps__wrapper {
      margin-top: 5px;
      margin-bottom: 57.3px;
    
      @media (max-width: 1024px) {
        margin-bottom: 48px;
      }
      @media (max-width: 767px) {
        margin-top: 24px;
        margin-bottom: 32px;
      }
    }
    .verify__steps__container {
      height: 75px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid ${({ theme }) => theme.borderColor};
      border-width: 0 0 1px 0;
    
      @media (max-width: 1024px) {
        height: 64px;
      }
      @media (max-width: 767px) {
        height: 60px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: initial;
        padding-top: 11.2px;
      }
    }
    .verify__steps__type {
      font-size: 16px;
      font-weight:500;
      @media (max-width: 1024px) {
        font-size: 18px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    .verify__steps__status {
      font-size: 14px;
      font-weight:500;
  
        color: ${({ theme }) => theme.subText};
    
      @media (max-width: 1024px) {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
    .verify__steps__status--completed {
        color: ${({ theme }) => theme.success};
    }
    .verify__steps__status--rejected {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      color:  ${({ theme }) => theme.danger};
    }
    .verify__steps__skiplink {
      display: flex;
      justify-content: center;
      margin-top: 23px;
      text-decoration: underline;
      color: ${({ theme }) => theme.primary};
    
      @media (max-width: 1024px) {
        margin-top: 24px;
      }
      @media (max-width: 767px) {
        margin-top: 16px;
      }
    
      a {
        color: inherit;
      }
    
      a:hover {
        text-decoration: none;
        outline: none;
        color: ${({ theme }) => theme.primary};
      }

  `}
  ${(props) =>
    props.StepWrapperSection &&
    css`
      width: 100%;
      font-weight: 500;
      color: ${({ theme }) => theme.bodyText};

      .stepWrapper__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 36px;

        @media (max-width: 1024px) {
          margin-bottom: 32px;
        }

        @media (max-width: 767px) {
          align-items: center;
          margin-bottom: 24px;
        }
      }

      .stepWrapper__header__title {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: ${({ theme }) => theme.bodyText};

        @media (max-width: 767px) {
          font-size: 20px;
          text-align: left;
        }
      }

      .stepWrapper__header__subtitle {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        max-width: 606px;
        margin-top: 13.5px;
        color: ${({ theme }) => theme.subText};

        @media (max-width: 1024px) {
          margin-top: 2.5px;
          max-width: 100%;

          .para_break {
            display: none;
          }
        }

        @media (max-width: 767px) {
          text-align: left;
          font-size: 14px;
          margin-top: 6px;
        }
      }

      .stepWrapper__containerLastLevel {
        width: 604px;
        margin: 0 auto !important;
        @media (max-width: 1024px) {
          width: 100%;
        }
      }

      .stepWrapper__guidlines {
        width: 33.33%;
        min-height: 540px;
        padding: 32px 24px;
        border-left: 1px solid ${({ theme }) => theme.borderColor};

        @media (max-width: 1024px) {
          display: none;
          border-left: none !important;
        }
      }

      .stepWrapper__havingIssue {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 40px;
        color: ${({ theme }) => theme.bodyText};
      }
    `}

  ${(props) =>
    props.StepContainer &&
    css`
      width: 100%;
      min-height: 540px;
      display: flex;
      padding: 0 !important;
      margin: 0 !important;
      background: ${({ theme }) => theme.level1Card};

      .stepWrapper__form {
        width: 66.66%;
        padding: 32px 40px;
        min-height: 540px;
        display: flex;
        flex-direction: column;
        position: relative;

        @media (max-width: 1024px) {
          width: 100%;
          border-right: none !important;
        }
        @media (max-width: 767px) {
          padding: 16px;
        }
      }

      .stepWrapper__form__guidline {
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 24px;
        color: ${({ theme }) => theme.primary};

        @media (min-width: 1024px) {
          display: none;
        }
        @media (max-width: 767px) {
          font-size: 14px;
        }

        u {
          cursor: pointer;
        }
      }

      .guidline__modal {
        @media (min-width: 1024px) {
          display: none;
        }
      }
      .guidline__modal__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
      }
      .guidline__modal__title {
        font-size: 20px;
        font-weight: bold;
        color: ${({ theme }) => theme.borderColor};
      }
      .guidline__modal__img {
        width: 24px;
        height: 24px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .guidline__modal__points {
        font-size: 16px;
        padding-left: 16px;
        color: ${({ theme }) => theme.subText};

        li {
          margin-bottom: 24px;
        }
      }
      .guidline__modal__points--s1 {
        @media (min-width: 1024px) {
          margin-top: 135px;
        }
      }
      .guidline__modal__points li a {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: none;
      }
    `}
  ${(props) =>
    props.StepContainerLVL3 &&
    css`
      width: 100%;
      min-height: 540px;
      display: flex;
      padding: 0 !important;
      margin: 0;
      background: ${({ theme }) => theme.level1Card};

      .stepWrapper__form {
        width: 100%;
        padding: 32px 40px;
        min-height: 540px;
        display: flex;
        flex-direction: column;
        position: relative;
        border-right: 0px;

        @media (max-width: 1024px) {
          width: 100%;
          border-right: none !important;
        }
        @media (max-width: 767px) {
          padding: 16px;
        }
      }

      .stepWrapper__form__guidline {
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 24px;
        color: ${({ theme }) => theme.primary};

        @media (min-width: 1024px) {
          display: none;
        }
        @media (max-width: 767px) {
          font-size: 14px;
        }

        u {
          cursor: pointer;
        }
      }

      .guidline__modal {
        @media (min-width: 1024px) {
          display: none;
        }
      }
      .guidline__modal__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
      }
      .guidline__modal__title {
        font-size: 20px;
        font-weight: bold;
        color: ${({ theme }) => theme.borderColor};
      }
      .guidline__modal__img {
        width: 24px;
        height: 24px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .guidline__modal__points {
        font-size: 16px;
        padding-left: 16px;
        color: ${({ theme }) => theme.subText};

        li {
          margin-bottom: 24px;
        }
      }
      .guidline__modal__points--s1 {
        @media (min-width: 1024px) {
          margin-top: 135px;
        }
      }
      .guidline__modal__points li a {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: none;
      }
    `}
    
  ${(props) =>
    props.HavingIssueSectionStyles &&
    css`
      width: 33.33%;
      min-height: 540px;
      padding: 32px 24px;

      @media (max-width: 1024px) {
        display: none;
        border-left: none !important;
      }
      .stepWrapper__havingIssue {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 40px;
        color: ${({ theme }) => theme.bodyText};
      }

      .guidline__modal__points {
        font-size: 16px;
        padding-left: 16px;
        color: ${({ theme }) => theme.subText};

        li {
          margin-bottom: 24px;
        }
      }
      .guidline__modal__points--s1 {
        @media (min-width: 1024px) {
          margin-top: 135px;
        }
      }
      .guidline__modal__points li a {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: none;
      }
    `}
  ${(props) =>
    props.S1ProgressBar &&
    css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px;

      .s1ProgressBar__seperator {
        width: calc(50% - 148px);
        height: 0;
        border-bottom: 2px dashed ${({ theme }) => theme.subText};
      }

      .s1ProgressBar__circle {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: ${({ theme }) => theme.mainBackground};

        svg,
        img {
          width: 80%;
          height: 80%;
        }
      }
      .s1ProgressBar__circle.completed {
        background-color: ${({ theme }) => theme.success};
      }

      .s1ProgressBar__circle__step {
        font-size: 16px;
        font-weight: 500;
        position: absolute;
        color: ${({ theme }) => theme.bodyText};
      }
      .s1ProgressBar__current__circle {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid rgb(255, 184, 62);
        // outline: 4px solid rgb(255, 184, 62, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
        background-color: ${({ theme }) => theme.level1Card};

        // Style For Safari browser support of outline with border radius
        &::before {
          content: "";
          position: absolute;
          top: -3px;
          right: -3px;
          bottom: -3px;
          left: -3px;
          border: 4px solid rgb(255, 184, 62, 0.3);
          border-radius: 50%;
        }
      }
    `}
`;

const KycDivBox = styled.div`
  ${(props) =>
    props.KycStepsContainerCommon &&
    css`
      width: 100%;
      padding: 56px 0;
      display: flex;
      flex-direction: column;

      @media (max-width: 1024px) {
        padding: 48px 0;
      }

      @media (max-width: 767px) {
        padding: 32px 0;
      }

      .kycsteps__uploader {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .kycsteps__uploader__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 110px;

        @media (max-width: 767px) {
          min-width: 95px;
        }

        label {
          cursor: pointer;
          margin: 0 !important;
        }
      }
      .kycsteps__uploader__circle {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background-color: rgba(4, 112, 225, 0.05);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 2px solid ${(props) => props.theme.primary};

        @media (max-width: 1024px) {
          width: 80px;
          height: 80px;
        }

        @media (max-width: 767px) {
          width: 56px;
          height: 56px;
        }
      }
      .kycsteps__uploader__title {
        font-size: 14px;
        text-align: center;
        margin-top: 12px;
        color: ${({ theme }) => theme.subText};

        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
      .kycsteps__seperator {
        height: 96px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        padding: 0 41px;
        color: ${({ theme }) => theme.subText};

        @media (max-width: 1024px) {
          height: 80px;
          padding: 0 37px;
        }

        @media (max-width: 767px) {
          padding: 0 21px;
        }
      }
      .kycsteps__seperator__line {
        height: 25px;
        border-left: 2px dashed ${({ theme }) => theme.ProgesssBarSettings};
      }

      .kyc__form__btn {
        width: 100%;
        height: 50px;

        @media (max-width: 1024px) {
          height: 48px;
        }

        @media (max-width: 767px) {
          margin-top: 16px;
        }

        button {
          width: 100%;
          height: 100%;
          font-weight: 500;
          color: white !important;
          border-color: ${({ theme }) => theme.primary};
          background-color: ${({ theme }) => theme.primary} !important;

          &:active {
            color: white !important;
          }
        }
      }
      .kycS2__upload__msg {
        font-size: 16px;
        font-weight: 500;
        margin: 40px 0 8px 0;
        text-align: center;
        color: ${({ theme }) => theme.subText};

        &.kycS2__upload__msg--m0 {
          margin-top: 0;
        }
      }
      .kycS2__msg {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
        text-align: center;
        color: ${({ theme }) => theme.subText};
      }
    `};
  ${(props) =>
    props.KYCS1 &&
    css`
      width: 100%;

      .kycS1__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        @media (max-width: 1024px) {
          margin-bottom: 32px;
        }

        @media (max-width: 767px) {
          margin-bottom: 16px;
        }
      }
      .kycS1__header__title {
        font-size: 18px;
        font-weight: 500;
        color: ${(props) => props.theme.bodyText};

        @media (max-width: 1024px) {
          font-size: 20px;
        }

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .kycS1__header__impPoints {
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
        color: ${(props) => props.theme.primary};

        @media (min-width: 1023px) {
          display: none;
        }

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      .kycS1__form__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 767px) {
          flex-direction: column;
          justify-content: initial;
        }
      }
    `};
  ${(props) =>
    props.KYC2Captured &&
    css`
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .kycS2__captured__img {
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-bottom: 32px;

        &:nth-child(1) {
          @media (max-width: 767px) {
            margin-bottom: 12px;
          }
        }

        img {
          width: 228px;
          height: auto;
        }
      }
      .kycS2__captureAgain {
        position: absolute;
        bottom: 0;
        height: 32px;
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.primary};

        u {
          cursor: pointer;
        }
      }
    `}

  ${(props) =>
    props.KYC2FFailed &&
    css`
      display: flex;

      .kycS2__uploadfailed img {
        width: 52px;
        height: 52px;
        margin-right: 20px;

        @media (max-width: 767px) {
          width: 40px;
          height: 40px;
          margin-right: 12px;
        }
      }
      .uploadfailed__msg__title {
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.bodyText};

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      .uploadfailed__msg__subtitle {
        font-size: 14px;
        font-weight: 500;
        margin-top: 8px;
        color: ${({ theme }) => theme.subText};

        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    `}
${(props) =>
    props.KYC3 &&
    css`
      width: 100%;
      font-weight: 500;
      width: 100%;
      min-height: 330px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-bottom: 40px;
      color: ${(props) => props.theme.bodyText};
      border: 2px solid ${(props) => props.theme.subText};

      @media (max-width: 767px) {
        margin-bottom: 16px;
        min-height: 210px;
      }

      .kycS3__container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .kycS3__uploader__img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(4, 112, 225, 0.05);
        border: 1px solid ${(props) => props.theme.primary};

        @media (max-width: 767px) {
          width: 96px;
          height: 96px;
        }
        svg,
        img {
          width: 29px;
          height: 29px;
        }
      }
      .kycS3__uploader__title {
        font-size: 16px;
        text-align: center;
        margin-top: 12px;
        color: ${(props) => props.theme.subText};

        @media (max-width: 1024px) {
          font-size: 14px;
        }
      }
    `};
  ${(props) =>
    props.KYC3Capture &&
    css`
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 408px;
      border-radius: 5px;
      margin-bottom: 40px;
      border: 2px solid ${(props) => props.theme.success};

      @media (max-width: 767px) {
        margin-bottom: 16px;
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px 0 40px 0;
      }

      .kycS3__captured__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:nth-child(1) {
          padding-right: 39px;
        }
        &:nth-child(2) {
          padding-left: 39px;
        }
        @media (max-width: 1024px) {
          &:nth-child(1) {
            padding-right: 27.5px;
          }
          &:nth-child(2) {
            padding-left: 27.5px;
          }
        }

        @media (max-width: 767px) {
          padding: 0 !important;

          &:nth-child(2) {
            margin-top: 40px;
          }
        }
      }
      .kycS3__captured__title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 14px;
        color: ${(props) => props.theme.bodyText};

        @media (max-width: 1024px) {
          font-size: 18px;
        }

        @media (max-width: 767px) {
          margin-bottom: 11px;
        }
      }
      .kycS3__captured__img {
        width: 150px;
        height: 150px;
        border-radius: 50%;

        @media (max-width: 1024px) {
          width: 173px;
          height: 173px;
        }

        @media (max-width: 767px) {
          width: 96px;
          height: 96px;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .kycS3__captured__status {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 23px;
        font-size: 14px;
        font-weight: 300;
        color: ${(props) => props.theme.success};

        @media (max-width: 1024px) {
          margin-top: 41px;
          font-size: 18px;
        }

        @media (max-width: 767px) {
          margin-top: 11px;
        }
      }
      .kycS3__captured__status .captured__status__img {
        width: 32px;
        height: 32px;
        margin-right: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: ${(props) => props.theme.success};

        @media (max-width: 1024px) {
          width: 34.1px;
          height: 34.1px;
        }

        @media (max-width: 767px) {
          width: 24px;
          height: 24px;
        }
        svg,
        img {
          width: 90%;
          height: 90%;
        }
      }
      .kycS3__captureAgain {
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
        margin-top: 18px;
        cursor: pointer;
        color: ${(props) => props.theme.primary};

        @media (max-width: 1024px) {
          font-size: 18px;
          margin-top: 24px;
        }

        @media (max-width: 767px) {
          font-size: 14px;
          margin-top: 14px;
        }
      }
    `}

  ${(props) =>
    props.SelfieGuide &&
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 0 !important;

      @media (max-width: 1024px) {
        height: fit-content;
        padding: 40px !important;
      }

      @media (max-width: 767px) {
        padding: 16px !important;
      }
      .selfieGuide__title {
        width: 100%;
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        color: ${(props) => props.theme.bodyText};
      }
      .selfieGuide__container {
        padding-top: 32px;
        width: 100%;
        display: flex;

        @media (max-width: 1024px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .selfieGuide__step {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:nth-child(1) {
          border-right: 0.5px solid ${(props) => props.theme.subText};
        }
        &:nth-child(2) {
          border-left: 0.5px solid ${(props) => props.theme.subText};
        }

        @media (max-width: 1024px) {
          border: none !important;
          width: 100%;
          align-items: flex-start;
        }
      }

      .selfieGuide__step__header {
        @media (max-width: 1024px) {
          display: flex;
          align-items: flex-start;
          margin-bottom: 24px;
        }
      }
      .selfieGuide__step__title {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        color: ${(props) => props.theme.subText};

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      .selfieGuide__step__subtitle {
        font-size: 18px;
        font-weight: 500;
        max-width: 333px;
        text-align: center;
        margin-top: 8px;
        color: ${(props) => props.theme.bodyText};

        @media (max-width: 1024px) {
          margin-top: 0;
          max-width: 100%;
          margin-left: 10px;
        }

        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      .selfieGuide__step__img {
        width: 226px;
        height: 131px;
        margin-top: 16px;

        video,
        img {
          width: 100%;
          height: 100%;
        }

        @media (max-width: 1024px) {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: center;
          margin: 0 0 24px 0;

          video,
          img {
            width: 226px;
            height: 131px;
          }
        }
      }
      .selfieGuide__step__instruction {
        margin-top: 82.5px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1024px) {
          margin-top: 36px;
          justify-content: flex-start;
        }

        label {
          display: flex;
          font-size: 14px;
          font-weight: 300;
          color: ${(props) => props.theme.bodyText};

          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
      .selfieGuide__step__btns {
        margin-top: 24px;
        display: flex;
        justify-content: center;
      }
      .selfieGuide__failure__btn {
        max-width: 115px;
        height: 48px;
        font-weight: 500;
        margin-right: 24px;
        font-size: 16px !important;
        color: ${(props) => props.theme.danger} !important;
        border-color: ${(props) => props.theme.danger} !important;

        @media (max-width: 1024px) {
          max-width: 241px;
        }

        @media (max-width: 767px) {
          width: auto;
          max-width: 120px;
          margin-right: 8px;
          font-size: 14px !important;
        }

        &:active {
          border-color: ${(props) => props.theme.danger} !important;
          color: ${(props) => props.theme.bodyText} !important;
        }
        &:focus {
          color: ${(props) => props.theme.danger} !important;
          border-color: ${(props) => props.theme.danger} !important;
        }

        &:hover {
          color: ${(props) => props.theme.bodyText2} !important;
          background-color: ${(props) => props.theme.danger} !important;
          border-color: ${(props) => props.theme.danger} !important;
        }
      }
      .selfieGuide__success__btn {
        width: 200px;
        height: 48px;
        font-weight: 500;
        font-size: 16px !important;
        // color: ${(props) => props.theme.bodyText} !important;
        // border-color: ${(props) => props.theme.primary} !important;
        // background-color: ${(props) => props.theme.primary} !important;

        @media (max-width: 1024px) {
          max-width: 274px;
        }

        @media (max-width: 767px) {
          width: auto;
          max-width: 160px;
          margin-left: 8px;
          font-size: 14px !important;
        }

        &:active {
          color: ${(props) => props.theme.bodyText2} !important;
        }
      }
    `}
${(props) =>
    props.KYCBioIdContainer &&
    css`
      height: 100%;
      overflow: scroll;
      width: 100%;
      z-index: 2;
      background: ${(props) => props.theme.level1Card};
    `}

${(props) =>
    props.KYCBioId &&
    css`
      height: 100%;
      overflow: scroll;
      width: 100%;
      z-index: 2;
      background: ${(props) => props.theme.level1Card};

      .bioid_instruction {
        font-size: 16px;
        font-weight: 300;
        height: fit-content;
        margin-top: 0;
        margin-bottom: 32px;
        color: ${(props) => props.theme.bodyText};
      }
      .bioid_canvasview {
        max-width: 480px;
        height: auto;

        @media (max-width: 767px) {
          width: 100%;
          height: 100%;
        }
      }
    `}
${(props) =>
    props.KYCModalContainer &&
    css`
      .kycsubmittedmodal {
        font-weight: 500;
      }
      .kycsubmittedmodal__header {
        display: flex;
        justify-content: space-between;
      }
      .kycsubmittedmodal__title {
        font-size: 20px;
        font-weight: bold;

        @include themed() {
          color: t($primary-text);
        }
      }
      .kycsubmittedmodal__container {
        display: flex;
        justify-content: center;
      }
      .kycsubmittedmodal__animation {
        width: 248px;
        height: 248px;

        @media (max-width: 1024px) {
          width: 240px;
          height: 240px;
        }

        @media (max-width: 767px) {
          width: 152px;
          height: 152px;
        }
      }
      .kycsubmittedmodal__msg {
        font-size: 16px;
        @include themed() {
          color: t($primary-text);
        }

        @media (max-width: 1024px) {
          font-size: 14px;
        }

        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    `}
${(props) =>
    props.KYC4 &&
    css`
      width: 100%;
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      border: 2px solid ${(props) => props.theme.success};

      .kycS4__captured {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          max-width: 100%;
          height: auto;
        }

        iframe {
          width: 100%;
          height: 500px;

          @media (max-width: 767px) {
            height: auto;
          }
        }
      }
      .kycS4__captureAgain {
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
        margin-top: 18px;
        cursor: pointer;
        color: ${(props) => props.theme.primary};

        @media (max-width: 1024px) {
          font-size: 18px;
          margin-top: 24px;
        }

        @media (max-width: 767px) {
          font-size: 14px;
          margin-top: 14px;
        }
      }
    `}
`;

export { KycSection, KycDivBox };
