import moment from "moment-timezone";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { BannerHeading } from "../../../../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";

const TimerCET = (props) => {
  const [timer, setTimer] = useState("");
  let timerId = useRef(null);

  const getCetTimeZone = () => {
    const dates = new Date().getTime();
    return dates;
  };

  useEffect(() => {
    setTimer(getCetTimeZone());
  }, []);

  const tick = () => {
    setTimer(getCetTimeZone() + 1000);
  };

  useEffect(() => {
    timerId.current = setInterval(() => {
      tick();
    }, 1000);

    return () => clearInterval(timerId.current);
  }, [timer]);

  return useMemo(() => {
    return (
      <BannerBox display="flex" pr="8px">
        <BannerHeading size="12px" width="50px" ml="6px">
          {moment.tz(timer, "Europe/Zurich").format("HH:mm:ss")}
        </BannerHeading>

        <BannerHeading size="12px" ml="6px">
          CET
        </BannerHeading>
      </BannerBox>
    );
  }, [timer]);
};

export default TimerCET;
