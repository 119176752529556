import { css } from "styled-components";

const PositionStyleCommon = css`
  ${({ position }) => position && `position: ${position && position }`};
  ${({ top }) => top && `top: ${top && top }`};
  ${({ bottom }) => bottom && `bottom: ${bottom && bottom }`};
  ${({ left }) => left && `left: ${left && left }`};
  ${({ right }) => right && `right: ${right && right }`};
  ${({ zIndex }) => zIndex && `z-index: ${zIndex && zIndex }`};

  @media (max-width: 1024px) {
    ${({ positionLg }) => positionLg && `position: ${positionLg && positionLg }`};
    ${({ topLg }) => topLg && `top: ${topLg && topLg }`};
    ${({ bottomLg }) => bottomLg && `bottom: ${bottomLg && bottomLg }`};
    ${({ leftLg }) => leftLg && `left: ${leftLg && leftLg }`};
    ${({ rightLg }) => rightLg && `right: ${rightLg && rightLg }`};
    ${({ zIndexLg }) => zIndexLg && `z-index: ${zIndexLg && zIndexLg }`};
  }

  @media (max-width: 767px) {
    ${({ positionMd }) => positionMd && `position: ${positionMd && positionMd }`};
    ${({ topMd }) => topMd && `top: ${topMd && topMd }`};
    ${({ bottomMd }) => bottomMd && `bottom: ${bottomMd && bottomMd }`};
    ${({ leftMd }) => leftMd && `left: ${leftMd && leftMd }`};
    ${({ rightMd }) => rightMd && `right: ${rightMd && rightMd }`};
    ${({ zIndexMd }) => zIndexMd && `z-index: ${zIndexMd && zIndexMd }`};
  }

  @media (max-width: 568px) {
    ${({ positionSm }) => positionSm && `position: ${positionSm && positionSm }`};
    ${({ topSm }) => topSm && `top: ${topSm && topSm }`};
    ${({ bottomSm }) => bottomSm && `bottom: ${bottomSm && bottomSm }`};
    ${({ leftSm }) => leftSm && `left: ${leftSm && leftSm }`};
    ${({ rightSm }) => rightSm && `right: ${rightSm && rightSm }`};
    ${({ zIndexSm }) => zIndexSm && `z-index: ${zIndexSm && zIndexSm }`};
  }
`;

export { PositionStyleCommon };