import { connect } from "react-redux";
import AddEditBeneficiary from "../../components/Wallets/BankAccounts/AddEditBeneficiary";
import { clearWalletErrors } from "../../actions/user";
import { getBeneficiary, getBeneficiarySingle } from "../../actions/Wallet/bankAccount";

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  userData: state.dashboard.getUserData,
  user: state.dashboard.getUserData,
  loader: state.wallet.apiLoader,
  error: state.wallet.error,
  apiData: state.wallet.apiData,
  wallets: state.wallet.wallets,
  deposits: state.wallet.deposits,
  withdrawals: state.wallet.withdrawals,
  errorGet: state.wallet.errorGet,
  getBeneficiaryAll: state.bank.getBeneficiaryAll,
  getBeneficiaryCount: state.bank.getBeneficiaryCount,
  isfetchingBeneficiary: state.bank.isfetchingBeneficiary,
  getSingleBeneficiary: state.bank.getSingleBeneficiary,
  isfetchingSingleBeneficiary: state.bank.isfetchingSingleBeneficiary
});

const mapDispatchToProps = (dispatch) => ({
  getBeneficiary: () => dispatch(getBeneficiary()),
  getBeneficiarySingle: (data) => dispatch(getBeneficiarySingle(data)),
  clearWalletErrors: () => dispatch(clearWalletErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBeneficiary);
