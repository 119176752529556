/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
} from "../../../../assets/StyleComponents/Exchange.style";
import styled from "styled-components";
import { HeadingFourth } from "../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { HeadingSix } from "../../../../assets/StyleComponents/FontSize/HeadingSix.style";
import {
  CakeIcon,
  CautionIcon,
  CoorporateAccIcon,
  KYCRejectedIcon,
  MobileIconBlue,
  MobileWhiteIcon,
  PendingScreenIcon,
  PhoneWithTickIcon,
  ProgressSuccessIcon,
  UserIcon,
  UserIconWhite
} from "../../../../assets/svgs";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { LiTag, ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import moment from "moment";
import { capitalize } from "../../../../utils";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { QRCode } from "react-qrcode-logo";
import useOnClickOutside from "../../../../Hooks/CheckOutSideClick/checkOutSideClick";
import { useSelector } from "react-redux";

const ProfileBadgeName = styled(BannerBox)`
  background: ${(props) => props.theme.primaryBlue};
  color: ${(props) => props.theme.cardBg};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const LitagsForProfile = styled(LiTag)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 6px;
  font-weight: 500;
  border-right: 0.5px solid ${(props) => props.theme.borderColor};
  padding-right: 12px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

// Main Function Starts h/ere
function TopBanner(props) {
  const { userData } = props;
  let { firstName, lastName, DOB, Phone, email, createdAt, accountType } = userData;
  // Props ends
  const { width } = useWindowSize();
  const StatusLVL2 = userData?.KYC?.LEVEL2?.status;
  const LVL3Status = userData?.KYC?.transactionRiskInfo?.status;
  const [showCode, setShowcode] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const isTheme = useSelector((state) => state.theme.isTheme);
  const [onHover2, setOnHover2] = useState(false);
  const QrRef = useRef();

  useOnClickOutside(QrRef, () => {
    setShowcode(false);
  });

  const UserValues = {
    verifiedPlus: true,
    vipMember: true,
    accountType: `${accountType} Account`,
    contactDetails: Phone,
    birthday: DOB
  };

  const Ulstyle = {
    display: "flex",
    listStyle: "none",
    gap: "10px",
    alignItems: "center",
    paddingLeft: "8px",
    flexWrap: "wrap",
    marginBottom: width < 767 && "0px"
  };

  const FinalizeNames = () => {
    return `${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`;
  };

  const CommonSection = () => {
    return (
      <>
        <ul style={Ulstyle}>
          <LitagsForProfile txtSuccess>
            <ProgressSuccessIcon />
            {userData?.KYC?.STATUS === "LEVEL2" && LVL3Status !== "APPROVED" && "KYC Verified"}
            {userData?.KYC?.STATUS === "LEVEL2" && LVL3Status === "APPROVED" && "Verified Plus"}
          </LitagsForProfile>
          {/* <LitagsForProfile color="#FCBE37" borderRight={width <= 1024 && "0px"}>
            <DiamondIcon />
            VIP Member,{" "}
            <BannerHeadingSpan size="14px" weight="500" txtGrey style={{ fontStyle: "italic" }}>
              50% OFF
            </BannerHeadingSpan>
          </LitagsForProfile> */}
          {width > 1025 && (
            <>
              <LitagsForProfile txtGrey>
                {accountType === "CORPORATE" ? (
                  <CoorporateAccIcon />
                ) : (
                  <UserIcon width="20px" height="20px" />
                )}

                {UserValues["accountType"] ? capitalize(UserValues["accountType"]) : "-"}
              </LitagsForProfile>
              <LitagsForProfile txtGrey className="Contact_number_user_localise">
                <PhoneWithTickIcon width="20px" height="19px" />
                <var>{UserValues["contactDetails"] ? UserValues["contactDetails"] : ""}</var>
              </LitagsForProfile>
              <LitagsForProfile txtGrey borderRight="0px">
                <CakeIcon />
                {moment(UserValues["birthday"]).format("MMMM DD, YYYY")}
              </LitagsForProfile>
            </>
          )}
        </ul>
      </>
    );
  };

  const KYCLvl3Rejected =
    userData.KYC.isHighRiskCountry &&
    userData?.KYC?.STATUS !== "LEVEL2" &&
    StatusLVL2 !== "REJECTED" &&
    (userData?.KYC?.transactionRiskInfo?.status === "REJECTED" ||
      userData?.KYC?.transactionRiskInfo?.status === "PARTIALLY_REJECTED");

  const KYCLvl3INProcess =
    userData.KYC.isHighRiskCountry &&
    userData?.KYC?.STATUS !== "LEVEL2" &&
    StatusLVL2 !== "REJECTED" &&
    (userData?.KYC?.transactionRiskInfo?.status === "SUBMITTED" ||
      userData?.KYC?.transactionRiskInfo?.status === "RESUBMITTED");

  const WhenVerficationRejectedLVL3 =
    userData.KYC.isHighRiskCountry &&
    userData?.KYC?.STATUS !== "LEVEL2" &&
    StatusLVL2 === "REJECTED" &&
    (userData?.KYC?.transactionRiskInfo?.status === "SUBMITTED" ||
      userData?.KYC?.transactionRiskInfo?.status === "RESUBMITTED");

  function SectionBasedOnKYC(kycType) {
    if (kycType === "LEVEL2") {
      return CommonSection();
    } else if (kycType === "LEVEL0" && !StatusLVL2) {
      return (
        <BannerBox
          BodyColor
          display="flex"
          alignItems="center"
          gap="6px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <CautionIcon />
          KYC Unverified
        </BannerBox>
      );
    } else if (KYCLvl3INProcess) {
      return (
        <BannerBox
          color="#FCBE37"
          display="flex"
          alignItems="center"
          gap="6px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <PendingScreenIcon />
          KYC Plus In-process
        </BannerBox>
      );
    } else if (WhenVerficationRejectedLVL3) {
      return (
        <BannerBox
          color="#E65252"
          display="flex"
          alignItems="center"
          gap="6px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <KYCRejectedIcon />
          KYC Rejected
        </BannerBox>
      );
    } else if (KYCLvl3Rejected) {
      return (
        <BannerBox
          color="#E65252"
          display="flex"
          alignItems="center"
          gap="6px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <KYCRejectedIcon />
          KYC Plus Rejected
        </BannerBox>
      );
    } else if (kycType === "LEVEL1" && !StatusLVL2) {
      return (
        <BannerBox
          BodyColor
          display="flex"
          alignItems="center"
          gap="6px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <CautionIcon />
          KYC Unverified
        </BannerBox>
      );
    } else if (kycType === "LEVEL0" && StatusLVL2 === "SUBMITTED") {
      return (
        <BannerBox
          color="#FCBE37"
          display="flex"
          alignItems="center"
          gap="6px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <PendingScreenIcon />
          KYC In-process
        </BannerBox>
      );
    } else if (kycType === "LEVEL1" && StatusLVL2 === "SUBMITTED") {
      return (
        <BannerBox
          color="#FCBE37"
          display="flex"
          alignItems="center"
          gap="6px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <PendingScreenIcon />
          KYC In-process
        </BannerBox>
      );
    } else if (kycType === "LEVEL0" && StatusLVL2 === "REJECTED") {
      return (
        <BannerBox
          color="#E65252"
          display="flex"
          alignItems="center"
          gap="6px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <KYCRejectedIcon />
          KYC Rejected
        </BannerBox>
      );
    } else if (kycType === "LEVEL1" && StatusLVL2 === "REJECTED") {
      return (
        <BannerBox
          color="#E65252"
          display="flex"
          alignItems="center"
          gap="6px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <KYCRejectedIcon />
          KYC Rejected
        </BannerBox>
      );
    } else if (kycType === "LEVEL1" && StatusLVL2 !== "REJECTED" && StatusLVL2 !== "SUBMITTED") {
      return (
        <BannerBox
          BodyColor
          display="flex"
          alignItems="center"
          gap="4px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <CautionIcon />
          KYC Unverified
        </BannerBox>
      );
    } else if (kycType === "LEVEL0" && StatusLVL2 === "PARTIALLY_SUBMITTED") {
      return (
        <BannerBox
          BodyColor
          display="flex"
          alignItems="center"
          gap="4px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <CautionIcon />
          KYC Unverified
        </BannerBox>
      );
    } else if (kycType === "LEVEL1" && StatusLVL2 === "PARTIALLY_SUBMITTED") {
      return (
        <BannerBox
          BodyColor
          display="flex"
          alignItems="center"
          gap="4px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <CautionIcon />
          KYC Unverified
        </BannerBox>
      );
    } else if (kycType === "LEVEL0" && StatusLVL2 !== "REJECTED" && StatusLVL2 !== "SUBMITTED") {
      return (
        <BannerBox
          BodyColor
          display="flex"
          alignItems="center"
          gap="4px"
          pl="8px"
          size="16px"
          weight="500"
          sizeMd="14px"
          mbMd="12px"
        >
          <CautionIcon />
          KYC Unverified
        </BannerBox>
      );
    } else {
      return CommonSection();
    }
  }

  return (
    <BannerSection>
      {/* Name + Btn Section */}
      <BannerBox display="flex" alignItems="center" justifyContent="space-between">
        <BannerBox display="flex" gap="8px" alignItems="center" mtMd="20px">
          <ProfileBadgeName
            width="64px"
            height="64px"
            widthMd="40px"
            heightMd="40px"
            sizeMd="18px"
            className="badge_user_localise"
          >
            <var>
              {firstName && lastName ? FinalizeNames()?.toLocaleUpperCase() : <UserIconWhite />}
            </var>
          </ProfileBadgeName>
          <BannerBox display="flex" direction="column" gap="6px">
            <HeadingFourth
              bodyColor
              size="18px"
              weight="500"
              sizeMd="16px"
              className="email_address_user_localise"
            >
              <var>{email}</var>
            </HeadingFourth>
            <BannerHeading
              txtGrey
              weight="400"
              size="14px"
              style={{ textTransform: "capitalize" }}
              className="name_user_localise"
            >
              <var>{firstName && lastName && `${firstName} ${lastName}`}</var>
            </BannerHeading>
          </BannerBox>
        </BannerBox>
        {width > 767 && (
          <>
            <BannerBox position="relative">
              <ButtonComponent
                OutlinePrimaryWithTransationHover
                display="flex"
                alignItems="center"
                gap="8px"
                justifyContent="center"
                size="18px"
                sizeLg="16px"
                weight="500"
                width="180px"
                onMouseLeave={() => {
                  setOnHover2(true);
                  setOnHover(false);
                  setShowcode(false);
                  // }, [1000]);
                }}
                onMouseOver={() => {
                  setShowcode(true);
                  setOnHover(true);
                }}
                onClick={() => {
                  setShowcode(true);
                  setOnHover(true);
                }}
              >
                {!onHover ? <MobileIconBlue /> : <MobileWhiteIcon />}
                Get LCX App
              </ButtonComponent>

              {showCode && (
                <>
                  <BannerBox
                    ref={QrRef}
                    width="360px"
                    height="267px"
                    position="absolute"
                    border="1px solid #969799"
                    radius="5px"
                    bck="#263645"
                    opacity={showCode ? "1" : "0"}
                    // top="-4px"
                    // topLg="-72px"
                    right="197px"
                    BorderRadius="5px"
                    className={`showCode ${onHover2 && "animation-back"}`}
                    // rightLg="107%"
                    // display="none"
                    CardWhite2
                    // AnimationMainReverse={showCode}
                    AnimationMain
                  >
                    <BannerHeadingSpan Tooltip></BannerHeadingSpan>
                    <BannerBox padding="16px 0 0 16px">
                      <BannerHeading size="16px" weight="500" BodyColor>
                        Get LCX App
                      </BannerHeading>
                    </BannerBox>
                    <BannerBox
                      display="flex"
                      direction="column"
                      padding="0 16px 16px 16px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {/* <QRCode
                        value="https://lcxapp.page.link/dapp"
                        size={136}
                        renderAs="svg"
                        bgColor={"#263645"}
                        fgColor={"#fff"}
                        level={"H"}
                        includeMargin="false"
                        imageSettings={{
                          src: IMAGE_PREFIX.WhiteLcxLogo,
                          x: undefined,
                          y: undefined,
                          height: 24,
                          width: 24,
                          excavate: true
                        }}
                        style={{
                          borderRadius: "4px",
                          width: "136px",
                          height: "136px",
                          margin: "10px 0px"
                        }}
                      /> */}
                      <BannerBox margin="15px 0px">
                        <QRCode
                          value="https://lcxapp.page.link/dapp"
                          size={100}
                          logoHeight={30}
                          logoWidth={30}
                          ecLevel="H"
                          logoImage="https://lcx-exchange.s3.amazonaws.com/coins/dark/svg/lcx.svg"
                          logoOpacity={1}
                          qrStyle="dots"
                          eyeRadius={10}
                          bgColor={isTheme ? "#151e26" : "#FFFFFF"} // Dark or white background
                          fgColor={isTheme ? "#FFFFFF" : "#000000"} // Light or dark foreground
                          id={"QR"}
                          style={{
                            borderRadius: "13px",
                            border: `1px solid ${isTheme ? "#ffffff" : "#CCCED0"}`
                          }}
                        />
                      </BannerBox>
                      <BannerBox>
                        <HeadingSix size="14px" weight="500" BodyColor textAlign="center">
                          Enhance convenience with the LCX app
                        </HeadingSix>
                        <ParaTag size="12px" weight="400" subText textAlign="center" mt="8px">
                          Scan to download the LCX app for seamless experience.
                        </ParaTag>
                      </BannerBox>
                    </BannerBox>
                  </BannerBox>
                </>
              )}
            </BannerBox>
          </>
        )}
      </BannerBox>
      {/* Grid Section For Account Type, Birthday, Verified */}
      <BannerBox
        display="flex"
        alignItems="center"
        alignItemsMd="start"
        mt="24px"
        mtMd="17px"
        justifyContent="space-between"
        directionMd="column-reverse"
      >
        {SectionBasedOnKYC(userData?.KYC?.STATUS)}
        {/* Joining date */}
        {width > 767 && (
          <BannerBox>
            <HeadingSix size="14px" weight="400" txtGrey>
              Joined: {moment(createdAt).format("MMMM DD, YYYY")}
            </HeadingSix>
          </BannerBox>
        )}
      </BannerBox>
      {/* Only For Tab and Mobile */}
      {width <= 1024 && userData?.KYC?.STATUS === "LEVEL2" && (
        <BannerBox mbMd="20px">
          <ul style={Ulstyle}>
            <LitagsForProfile txtGrey>
              <UserIcon width="20px" height="20px" />
              {UserValues["accountType"] ? capitalize(UserValues["accountType"]) : "-"}
            </LitagsForProfile>

            <LitagsForProfile txtGrey>
              <PhoneWithTickIcon height="19px" />
              {UserValues["contactDetails"]}
            </LitagsForProfile>

            <LitagsForProfile txtGrey className="birth_user_localise">
              <CakeIcon />
              {moment(UserValues["birthday"]).format("DD MMMM, YYYY")}
            </LitagsForProfile>

            {width < 767 && (
              <LitagsForProfile txtGrey borderRight="0px" mlMd="auto">
                {/* <BannerBox> */}
                <HeadingSix size="14px" weight="400" txtGrey sizeMd="12px">
                  Joined {moment(createdAt).format("MMMM DD, YYYY")}
                </HeadingSix>
                {/* </BannerBox> */}
              </LitagsForProfile>
            )}
          </ul>
        </BannerBox>
      )}
    </BannerSection>
  );
}

export default TopBanner;
