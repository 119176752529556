/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Fragment } from "react";
import { BuyTokenBannerLoader } from "../../../../components/Loader";
import { numberWithCommas } from "../../../../utils/general";
import { capitalize, addZeroes, setPrecision } from "../../../../utils";
import { useEffect } from "react";
import { trackPage } from "../../../../tracking/track";
import { ElementBox, ImgElement } from "../../../../components/Reusable";
import { BannerBox, BannerSection } from "../../../../assets/StyleComponents/Exchange.style";
import { TimerGreyIcon, WhitePaperTcIll } from "../../../../assets/svgs";
import { BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { HeadingSecond } from "../../../../assets/StyleComponents/FontSize/headsecond.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import BadgeComponent from "../../../../views/NewtradingCompetition/components/badge";
import CountTimerInWords from "../../../../components/CountDownTimer/countDownTimer";
import { AnchorTag, ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { useSelector } from "react-redux";
import moment from "moment";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { WebsiteTcIll } from "../../../../assets/svgs/index";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../../../constants/Images/imageIndex";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LinkDinTS, TelegramTS, TwitterTS, YoutubeTS } from "../../../../assets/svgs/illustrations";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import MobileBuyTokenBanner from "../../../../views/NewTokenSale/Components/MobileBuyTokenBanner";
import { ToolTip } from "../../../../components/Reusable";

const BuyTokenBanner = ({ saleDetails, loader }) => {
  const [saleData, setSaleData] = useState([]);
  const isLoaded = !loader && saleDetails;
  const isTheme = useSelector((state) => state.theme.isTheme);
  const userData = useSelector((state) => state.dashboard.userData);
  const history = useHistory();
  const { width } = useWindowSize();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    // document.title = "LCX Exchange - Buy Token";
    trackPage(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/token-sale`, "Buy Token");
  }, []);

  useEffect(() => {
    if (!saleDetails) return;

    if (saleDetails && saleDetails.round && Object.keys(saleDetails.round).length === 0) {
      setSaleData("");
      return;
    }
    let data = [];
    if (saleDetails.type === "PUBLIC") {
      data.push({ label: "Price(Public) $", value: addZeroes(saleDetails?.round?.publicUsdPrice) });
    } else {
      data.push({ label: "Price($)", value: addZeroes(saleDetails?.round?.usdPrice) });
    }
    data.push({
      label: "Vesting",
      value: saleDetails?.round?.isVesting ? "Yes" : "No",
      tipContent: saleDetails?.round?.lockingMessage || "",
      showTip: true
    });
    if (saleDetails.type === "PUBLIC") {
      data.push({
        label: `Price(${saleDetails?.round?.roundName}) $`,
        value: addZeroes(saleDetails?.round?.usdPrice)
      });

      data.push({
        label: "Round Cap",
        value: `${numberWithCommas(+saleDetails.round?.totalCap)} ${saleDetails.coin}`
      });
    } else {
      data.push({
        label: "Total Cap",
        value: `${numberWithCommas(+saleDetails.round?.totalCap)} ${saleDetails.coin}`
      });
    }

    data.push({ label: "Discount", value: `${+saleDetails?.round?.discount}%` });
    setSaleData(data);
  }, [saleDetails]);

  const GridData = [
    {
      title: `Price (${
        saleDetails?.round?.roundName
          ? saleDetails?.round?.roundName?.length > 15
            ? saleDetails?.round?.roundName?.slice(0, 14) + "..."
            : saleDetails?.round?.roundName
          : "Private"
      })`,
      output: numberWithCommas(setPrecision(saleDetails?.round?.usdPrice, 2)) + " USD"
    },
    {
      title: "Public Sale Price",
      output: numberWithCommas(setPrecision(saleDetails?.round?.publicUsdPrice, 2)) + " USD"
    },
    {
      title: "Round Cap",
      output: `${
        saleDetails?.round?.totalCap ? numberWithCommas(saleDetails?.round?.totalCap) : 0
      } ${saleDetails.coin}`
    },
    { title: "Vesting", output: saleDetails.round?.isVesting === true ? "Yes" : "No" },
    {
      title: "Discount",
      output: saleDetails?.round?.discount
        ? setPrecision(saleDetails?.round?.discount, 1) + "%"
        : "0%"
    },
    {
      title: "Minimum Investment",
      output: `${
        saleDetails.round?.minAmount ? numberWithCommas(saleDetails.round?.minAmount) : 0
      } ${saleDetails.coin}`
    },
    {
      title: "Maximum Investment",
      output: `${
        saleDetails.round?.maxAmount ? numberWithCommas(saleDetails.round?.maxAmount) : 0
      } ${saleDetails.coin}`
    },
    { title: "Category", output: saleDetails.category }
  ];

  return (
    <ElementBox flexColumn flexAlignCenter padding="24px 16px 52px 16px" paddingMd="0px" relative>
      {isLoaded ? (
        <Fragment>
          {width > 767 ? (
            <BannerSection width="100%">
              {width > 1024 && saleDetails.endingSoon && (
                <BannerHeadingSpan
                  size="12px"
                  weight="400"
                  bg="#0470E1"
                  color="white"
                  width="84px"
                  height="32px"
                  position="absolute"
                  top="0"
                  right="10px"
                  display="flex"
                  alignItems="start"
                  justifyContent="center"
                  FlagShapeTc
                >
                  Ending Soon
                </BannerHeadingSpan>
              )}
              {width > 1024 && (
                <ButtonComponent
                  OutlineOnlyPrimary
                  display="flex"
                  onClick={() => history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/token-sale`)}
                  alignItems="center"
                >
                  <ImgElement
                    src={!isTheme ? IMAGE_PREFIX.BackIconMain : IMAGE_PREFIX_DARK.BackIcondark}
                    alt=""
                  />
                  <BannerHeadingSpan ml="-6px"> Back </BannerHeadingSpan>
                </ButtonComponent>
              )}
              {/* Image Section */}
              <BannerBox display="flex" gap="16px">
                <BannerBox
                  width="256px"
                  height="172px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  CardGrey
                >
                  {width < 1024 && saleDetails?.endingSoon && (
                    <BannerHeadingSpan
                      size="12px"
                      weight="400"
                      bg="#0470E1"
                      color="white"
                      width="84px"
                      height="32px"
                      position="absolute"
                      top="0"
                      right="10px"
                      display="flex"
                      alignItems="start"
                      justifyContent="center"
                      FlagShapeTc
                    >
                      Ending Soon
                    </BannerHeadingSpan>
                  )}
                  <ImgElement src={saleDetails.logo} width="64px" height="64px" />
                </BannerBox>

                {/* Text Div */}
                <BannerBox display="flex" direction="column" gap="35px" width="61%">
                  <BannerBox display="flex" justifyContent="space-between">
                    <div>
                      <HeadingSecond size="24px" weight="500">
                        {saleDetails?.coin}
                      </HeadingSecond>
                      <BannerHeading size="14px" weight="400">
                        {saleDetails?.round?.roundName?.length > 40
                          ? saleDetails?.round?.roundName?.slice(0, 35) + "..."
                          : saleDetails?.round?.roundName}{" "}
                        ({capitalize(saleDetails?.type ? saleDetails?.type : "")})
                      </BannerHeading>
                      <br />
                      <BadgeComponent
                        title={capitalize(saleDetails?.status ? saleDetails?.status : "")}
                        borderColor={saleDetails?.status === "ONGOING" ? "success" : "primary"}
                        radius="5px"
                        // maxWidth="100px"
                      />
                    </div>
                    <div>
                      <BannerBox display="inline-flex" alignItems="center" mt="5px">
                        <TimerGreyIcon width="20px" height="20px" fillGrey={true} />
                        <ParaTag
                          size="12px"
                          ml="6px"
                          display="flex"
                          alignItems="center"
                          color={isTheme ? "#A7A9AB" : "#606F80"}
                        >
                          {saleDetails?.status === "ONGOING"
                            ? "Ends in "
                            : saleDetails?.status === "UPCOMING"
                            ? "Starts in "
                            : "Ended on "}

                          <BannerHeading ml="4px" display="flex" alignItems="center">
                            {saleDetails.status === "COMPLETED" ? (
                              <BannerHeadingSpan size="12px" weight="400">
                                {moment(saleDetails?.endAt * 1000).format("DD/MM/YYYY")}
                              </BannerHeadingSpan>
                            ) : (
                              <CountTimerInWords
                                eventTime={
                                  saleDetails.status === "ONGOING"
                                    ? saleDetails?.endAt
                                    : saleDetails?.startAt
                                }
                                type="Cardcounter"
                              />
                            )}
                          </BannerHeading>
                        </ParaTag>
                      </BannerBox>
                    </div>
                  </BannerBox>

                  {/* whitepaper div */}
                  <BannerBox display="flex" gap="20px">
                    <AnchorTag href={saleDetails?.publicData?.about?.websiteLink} target="_blank">
                      <ButtonComponent
                        size="14px"
                        weight="500"
                        TokenSaleBannerbuttons
                        width="113px"
                        btnHeight="32px"
                        display="flex"
                        gap="5px"
                        justify="center"
                        alignItems="center"
                      >
                        <WebsiteTcIll width="20px" height="10px" />
                        Website
                      </ButtonComponent>
                    </AnchorTag>

                    <AnchorTag
                      href={saleDetails?.publicData?.about?.whitepaperLink}
                      target="_blank"
                    >
                      <ButtonComponent
                        size="14px"
                        weight="500"
                        TokenSaleBannerbuttons
                        width="113px"
                        btnHeight="32px"
                        display="flex"
                        justify="center"
                        gap="5px"
                        alignItems="center"
                      >
                        <WhitePaperTcIll width="16px" height="20px" />
                        Whitepaper
                      </ButtonComponent>
                    </AnchorTag>
                    {/* Social media links+++++++++++++ */}
                    <BannerBox display="flex" alignItems="center" cursorPointer="pointer">
                      <BannerHeadingSpan margin="0px 10px">
                        <AnchorTag href={saleDetails?.publicData?.socials?.telegram} target="blank">
                          <TelegramTS />
                        </AnchorTag>
                      </BannerHeadingSpan>
                      <BannerHeadingSpan margin="0px 10px">
                        <AnchorTag href={saleDetails?.publicData?.socials?.twitter} target="_blank">
                          <TwitterTS />
                        </AnchorTag>
                      </BannerHeadingSpan>
                      <BannerHeadingSpan margin="0px 10px">
                        <AnchorTag href={saleDetails?.publicData?.socials?.youtube} target="blank">
                          <YoutubeTS />
                        </AnchorTag>
                      </BannerHeadingSpan>
                      <BannerHeadingSpan margin="0px 10px">
                        <AnchorTag href={saleDetails?.publicData?.socials?.linkedin} target="blank">
                          <LinkDinTS />
                        </AnchorTag>
                      </BannerHeadingSpan>
                    </BannerBox>
                  </BannerBox>

                  {/* end+++++++++++++ */}
                </BannerBox>
              </BannerBox>
            </BannerSection>
          ) : (
            <MobileBuyTokenBanner saleDetails={saleDetails} />
          )}
          <BannerSection
            display="grid"
            width="100%"
            GridCols="1fr 1fr 1fr 0.5fr"
            GridColsMd="1fr"
            Gridgap="24px"
            padding="10px"
            mt="16px"
            GridBorder
          >
            {GridData.map((values) => {
              return (
                <>
                  {values.title === "Category" && (
                    <>
                      <BannerBox
                        displayMd={width === 768 ? "block" : "flex"}
                        justifyContentMd="space-between"
                        alignItemsMd="center"
                      >
                        <BannerHeading size="14px" weight="400" txtGrey>
                          {values.title}
                        </BannerHeading>
                        <BannerBox
                          size="14px"
                          weight="500"
                          BodyColor
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          {saleDetails?.categoryIcon && (
                            <ImgElement
                              src={saleDetails?.categoryIcon}
                              width="17px"
                              height="17px"
                            />
                          )}{" "}
                          {saleDetails?.category}
                        </BannerBox>
                      </BannerBox>
                    </>
                  )}
                  {values.title === "Vesting" && (
                    <>
                      <BannerBox
                        displayMd={width === 768 ? "block" : "flex"}
                        justifyContentMd="space-between"
                        alignItemsMd="center"
                      >
                        <BannerHeading size="14px" weight="400" txtGrey>
                          {values.title}
                        </BannerHeading>
                        <BannerBox
                          size="14px"
                          weight="500"
                          BodyColor
                          display="flex"
                          alignItems="center"
                          gap="5px"
                          mrMd="13px"
                        >
                          {values.output === "Yes" ? (
                            <ToolTip
                              id="Vesting"
                              title={saleDetails.round?.isVesting === true ? "Yes" : "No"}
                              tipContent={
                                saleDetails?.round?.lockingMessage
                                  ? saleDetails?.round?.lockingMessage
                                  : ""
                              }
                              imgProps={{
                                height: "16px",
                                width: "16px",
                                absolute: true,
                                top: "2px",
                                right: "-18px"
                              }}
                              titleProps={{ secondary: true, marginBottom: "4px" }}
                            />
                          ) : (
                            values.output
                          )}
                        </BannerBox>
                      </BannerBox>
                    </>
                  )}
                  {values.title !== "Category" && values.title !== "Vesting" && (
                    <>
                      <BannerBox
                        NavbarLink
                        displayMd={width === 768 ? "block" : "flex"}
                        justifyContentMd="space-between"
                        alignItemsMd="center"
                      >
                        <BannerHeading size="14px" weight="400" txtGrey>
                          {values.title}
                        </BannerHeading>
                        <BannerHeading size="14px" weight="500" BodyColor>
                          {values.output}
                        </BannerHeading>
                      </BannerBox>
                    </>
                  )}
                </>
              );
            })}
          </BannerSection>
        </Fragment>
      ) : (
        <BuyTokenBannerLoader />
      )}
    </ElementBox>
  );
};

export default BuyTokenBanner;
