import React, { Suspense, useEffect, useMemo } from "react";
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
  // useHistory,
  useParams
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import { BannerBox, MainElement, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import AddEditBeneficiary from "../../containers/Wallet/AddEditBeneficiary";
import GetBankDetails from "../../components/Wallets/BankAccounts/getBankAccount";
import BalancePageLoader from "./WalletLoaders/BalanceLoader";
import WalletNavbar from "./WalletNavbar/walletNavbar";
import BalancePageComponent from "./BalancePage/index";
import DepositPageComponent from "./DepositPage/depositPage";
import WithdrawalPageComponent from "./WithdrawalPage/withdrawalPage";
import DepositWithdrawalPageComponent from "./DepositWithdrawal/depositWithdrawal";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle/index";
import { trackPage } from "../../tracking/track";
import TokenSaleTransactions from "../../components/Modules/Wallets/TokenSaleTransactions";
import { HalfFooterCommon } from "../../components/Footer/common";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const WalletPageComponent = (props) => {
  const location = useLocation();
  // const history = useHistory();
  const dispatch = useDispatch();
  const param = useParams();
  // const isLoggedin = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.dashboard.userData);
  const { transalationList } = useSelector(GenericReducer);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    // let checkPath = location.pathname?.split("/")[1];
    // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
    //   history.push(`/${userData.preferredLanguage}/wallet/${param?.tab}`);
    // }
  }, []);

  useEffect(() => {
    let page = location.pathname + location.search;
    trackPage(page);
    dispatch(fetchAvailableLanguagesRequest());
  }, [location]);

  function capitalizeFirstLetter(string) {
    if (string.length === 0) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  let SEOContents = {
    name: `Wallets`,
    link: `wallet/${param?.tab}`,
    title: `${capitalizeFirstLetter(param?.tab)} | LCX Exchange`,
    description:
      "Manage your crypto assets securely with LCX Exchange. Access your wallet, view balances, and handle transactions with ease. Your crypto, your control.",
    // keywords: `uk regulated crypto exchanges, crypto exchange regulation, crypto exchanges regulated in uk, regulated exchanges crypto, regulated crypto exchanges uk, crypto asset exchange, cryptoassets exchange, european crypto exchange, crypto exchange with lowest fees`,
    canonical: `https://exchange.lcx.com/wallet/${param?.tab}`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}/wallet/${param?.tab}`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: `${capitalizeFirstLetter(param?.tab)} | LCX Exchange`,
    OgDescription:
      "Manage your crypto assets securely with LCX Exchange. Access your wallet, view balances, and handle transactions with ease. Your crypto, your control.",
    OgURL: `https://www.exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/wallet/${param?.tab}`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: `${capitalizeFirstLetter(param?.tab)} | LCX Exchange`,
    TwDescription:
      "Manage your crypto assets securely with LCX Exchange. Access your wallet, view balances, and handle transactions with ease. Your crypto, your control."
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/wallet/${param?.tab}`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <MainElement>
        <BannerSection
          SectionGrey
          width="100%"
          padd="56px 0px"
          paddMd="32px 8px"
          paddLg="32px 64px"
        >
          <BannerBox ExchangeContainer widthMd="97%">
            <BannerBox minHeight="100vh" borderRadius="10px">
              <WalletNavbar />
              <BannerBox
                minHeight="600px"
                boxShadow="none"
                padding="27px 1rem"
                paddingMd="0px 10px"
                CardWhite
                DashboardBordersdark
                radius={"0 0 10px 10px"}
              >
                <Switch>
                  <Route
                    exact
                    path="/wallet/balances"
                    render={() => (
                      <Suspense fallback={<BalancePageLoader />}>
                        {" "}
                        <BalancePageComponent {...props} />{" "}
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/:lang/wallet/balances"
                    render={() => (
                      <Suspense fallback={<BalancePageLoader />}>
                        {" "}
                        <BalancePageComponent {...props} />{" "}
                      </Suspense>
                    )}
                  />

                  <Route
                    exact
                    path="/wallet/deposits"
                    render={() => <DepositPageComponent {...props} />}
                  />
                  <Route
                    exact
                    path="/:lang/wallet/deposits"
                    render={() => <DepositPageComponent {...props} />}
                  />

                  <Route
                    exact
                    path="/wallet/withdrawals"
                    render={() => <WithdrawalPageComponent {...props} />}
                  />
                  <Route
                    exact
                    path="/:lang/wallet/withdrawals"
                    render={() => <WithdrawalPageComponent {...props} />}
                  />

                  <Route
                    exact
                    path="/:lang/wallet/account_log"
                    render={() => <DepositWithdrawalPageComponent {...props} />}
                  />

                  <Route exact path="/wallet/bank_accounts" component={GetBankDetails} />
                  <Route exact path="/:lang/wallet/bank_accounts" component={GetBankDetails} />
                  {/* <Route exact path="/settings/reports" component={ReportsPage} /> */}
                  <Route exact path="/wallet/edit_accounts" component={AddEditBeneficiary} />
                  <Route exact path="/:lang/wallet/edit_accounts" component={AddEditBeneficiary} />
                  <Route exact path="/:lang/wallet/add_accounts" component={AddEditBeneficiary} />
                  <Route
                    exact
                    path="/wallet/token_sale_history"
                    component={TokenSaleTransactions}
                  />
                  <Route
                    exact
                    path="/:lang/wallet/token_sale_history"
                    component={TokenSaleTransactions}
                  />
                  <Route
                    path="*"
                    render={() => (
                      <Redirect
                        to={`${
                          userData?.preferredLanguage ? `/${userData?.preferredLanguage}` : ""
                        }/wallet/balances`}
                      />
                    )}
                  />
                </Switch>
              </BannerBox>
            </BannerBox>
          </BannerBox>
        </BannerSection>

        <HalfFooterCommon />
      </MainElement>
    </>
  );
};

export default withRouter(WalletPageComponent);
