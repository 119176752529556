/**
 * Intialize Market Reducer
 */

import apiConstants from "../constants/apiConstants";

const favList = localStorage.getItem("fav");

const initialStateSchema = {
  isMarketLists: [],
  isMarketListFetching: false,
  isMarketTickerLists: [],
  isMarketTickerListFetching: false,
  errorGet: false,
  topMarketData: {},
  isTopMarketFetching: false,
  favPairWatchList: favList ? JSON.parse(favList) : ""
};

let initialState = initialStateSchema;

const MarketDataReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GET_MARKET_TOP_DATA:
      newState = {
        ...state,
        isTopMarketFetching: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_TOP_DATA_SUCCESS:
      for (let key in action.response.data) {
        action.response.data[key]["base_pair"] = action.response.data[key]["symbol"].split("/")[0];
        action.response.data[key]["quote_pair"] = action.response.data[key]["symbol"].split("/")[1];
      }

      newState = {
        ...state,
        topMarketData: action.response.data,
        isTopMarketFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_TOP_DATA_FAIL:
      newState = {
        ...state,
        isTopMarketFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_LISTS:
      newState = {
        ...state,
        isMarketListFetching: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_LISTS_SUCCESS:
      for (let key in action.response) {
        action.response[key]["base_pair"] = action.response[key]["symbol"].split("/")[0];
        action.response[key]["quote_pair"] = action.response[key]["symbol"].split("/")[1];
      }

      newState = {
        ...state,
        isMarketLists: Object.values(action.response),
        isMarketListFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_LISTS_FAIL:
      newState = {
        ...state,
        isMarketListFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_TICKER_LISTS:
      newState = {
        ...state,
        isMarketTickerListFetching: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_TICKER_LISTS_SUCCESS:
      for (let key in action.response) {
        action.response[key]["base_pair"] = action.response[key]["symbol"].split("/")[0];
        action.response[key]["quote_pair"] = action.response[key]["symbol"].split("/")[1];
      }

      newState = {
        ...state,
        isMarketTickerLists: Object.values(action.response),
        isMarketTickerListFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_TICKER_LISTS_FAIL:
      newState = {
        ...state,
        isMarketTickerListFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.ERROR_500:
      newState = {
        ...state,
        errorGet: true
      };
      return newState;

    case apiConstants.UPDATE_FAV_PAIRS_WATCH_LIST:
      newState = {
        ...state,
        favPairWatchList: action.payload
      };
      return newState;

    default:
      return state;
  }
};

export default MarketDataReducer;
