import styled from "styled-components";

export const MissingProfileDiv = styled.div`
  .mpDetails {
    width: 100%;
    font-weight: 500;
    color: ${({ theme }) => theme.primaryText};
  }
  .mpDetails__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .mpDetails__header__title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
  .mpDetails__header__imgtag {
    width: 71px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    margin-left: 8px;
    color: #fff;
    background-image: linear-gradient(51deg, #70a6e0 14%, #40a3c7 87%);
  }
  .mpDetails__header__subtitle {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
    color: ${({ theme }) => theme.subText};

    @media (max-width: 767px) {
      font-size: 12px;

      .para_break {
        display: none;
      }
    }
  }
  .mpDetails__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @media (max-width: 767px) {
      margin-top: 16px;
    }
  }
  .mpDetails__box {
    width: 720px;
    min-height: 308px;
    padding: 50px 40px !important;
    background:${({ theme }) => theme.level1Card};

    @media (max-width: 1024px) {
      width: 100%;
      padding: 32px 40px !important;
    }
    @media (max-width: 767px) {
      padding: 26px 16px 24px !important;
    }
  }
  .mpDetails__form__container {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 50px);
    flex-wrap: wrap;

    @media (max-width: 1024px) {
      height: calc(100% - 48px);
    }

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: initial;
    }
  }
  .mpDetails__form__field {
    margin-bottom: 32px;
    width: calc(50% - 16px);

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  .mpDetails__form__field--w100 {
    margin-bottom: 32px;
    width: 100% !important;

    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }
  .mpDetails__form__field label {
    font-size: 14px;
    margin-bottom: 6px;
    color: ${({ theme }) => theme.subText};

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .mpDetails__form__input {
    display: flex;
    align-items: center;
    height: 44px;
    width: 100%;
    border: none !important;
    border-radius: 5px 5px 0 0;
    background-color: ${({ theme }) => theme.inputBackground};
    border-bottom: 1px solid ${({ theme }) => theme.inputBorder} !important;
    }
  }
  .mpDetails__form__input--phone {
    padding: 0 12px;
  }
  .mpDetails__form__input select,
  .mpDetails__form__input input {
    width: 100%;
    text-decoration: none;
    border: none !important;
    height: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    border-radius: 5px 5px 0 0;
    color: ${({ theme }) => theme.primaryText};
    background-color: ${({ theme }) => theme.inputBackground};

    &:focus {
      text-decoration: none;
      outline: none;
      border: none;
      background-color: ${({ theme }) => theme.inputBackground};
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 300;
      color:  ${({ theme }) => theme.subText};

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
`;
