import OrderBookTable from "../../OrderBookTable";
import orderRowHoc from "./orderRowHoc";

const SingleOrderRow = ({
  order,
  side,
  index,
  currRowIndex,
  displayAvgSum,
  rowChildren,
  price,
  amount,
  total,
  SIDES,
  onClickHandler,
  onMouseOver
}) => {
  return (
    <OrderBookTable.Tr
      key={`order-${order[0]}`}
      active={
        displayAvgSum
          ? side === SIDES.SELL
            ? currRowIndex <= index && currRowIndex !== -1
            : currRowIndex >= index
          : null
      }
      pointer
      data-tip
      data-tip-disable={!displayAvgSum}
      data-for={`avgSum${side}-${index}`}
      data-background-color="#FFC955"
      onClick={onClickHandler}
      onMouseOver={onMouseOver}
      bdDash={displayAvgSum && currRowIndex === index ? side : null}
      relative
    >
      <OrderBookTable.Td
        width="33.33%"
        green={side === SIDES.BUY ? true : null}
        red={side === SIDES.SELL ? true : null}
        textAlign="left"
        paddingLeftMd="12px"
      >
        {price}
      </OrderBookTable.Td>
      <OrderBookTable.Td width="28.67%" textAlign="right">
        {amount}
      </OrderBookTable.Td>
      <OrderBookTable.Td width="38%" textAlign="right" paddingRightMd="12px">
        {total}
      </OrderBookTable.Td>

      {rowChildren}
    </OrderBookTable.Tr>
  );
};

export default orderRowHoc(SingleOrderRow);
