/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import {
  BannerBox,
  BannerHeadingSpan,
  BannerSection
} from "../../../assets/StyleComponents/Exchange.style";
import { getTradingCompetitionFaq } from "../../../lib/api";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { AnchorTag, ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { CampaginsFaqs } from "../../campaigns/Components/Faqs";
import { ExpandMoreIcon } from "../../../assets/svgs";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RewardsFaqs } from "../../RewardsHub/components/Faqs/Faqs";

function Faq(props) {
  const [faqArray, setfaqArray] = useState([]);
  const isTheme = useSelector((state) => state.theme.isTheme);
  const getFaqs = async () => {
    if (props.type === "campaigins") {
      setfaqArray(CampaginsFaqs);
    } else if(props.type === "rewardsHub") {
      setfaqArray(RewardsFaqs);
    } else {
      const faqsdata = await getTradingCompetitionFaq();
      setfaqArray(faqsdata.data.data.data);
    }
  };

  const ItemWithChevron = ({ header, ...rest }) => (
    <Item
      {...rest}
      header={
        <>
          {header}
          <ExpandMoreIcon className="chevron-down" />
        </>
      }
    />
  );

  const AccordionItemDiv = styled(ItemWithChevron)`
    border-bottom: 1px solid #ccc;
    color: ${isTheme ? "#DEDFE0" : "#012243"};
    .szh-accordion__item {
      &-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 1rem;
        font-size: 1rem;
        font-weight: 500;
        text-align: left;
        color: ${isTheme ? "#DEDFE0" : "#012243"};
        background-color: transparent;
        border: none;
      }

      &-content {
        transition: height 0.25s cubic-bezier(0, 0, 0, 1);
      }

      &-panel {
        padding: 1rem;
      }
    }

    .chevron-down {
      margin-left: auto;
      transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
    }

    &.szh-accordion__item--expanded {
      .szh-accordion__item-btn {
        background-color: ${(props) => props.theme.AccorianBg};
      }
      .chevron-down {
        transform: rotate(180deg);
      }
    }
  `;

  useEffect(() => {
    getFaqs();
  }, []);
  
  return (
    <>
      {faqArray?.length > 0 && (
        <BannerSection
          SectionWhite
          radius="5px"
          padd={"24px 36px 32px 40px"}
          paddMd={"16px 16px 21px 16px"}
          mt="16px"
          mtMd="12px"
        >
          <BannerHeading size="20px" sizeLg="18px" sizeMd="16px" margin="20px 0px">
            FAQ
          </BannerHeading>
          <BannerBox display="flex" direction="column" gap="8px">
            {faqArray?.length > 0 &&
              faqArray.map((value, index) => {
                return (
                  <>
                    {(props.type === "campaigins" || props.type === "rewardsHub") ? (
                      <Accordion transition transitionTimeout={350}>
                        <AccordionItemDiv header={value?.question}>
                          {value?.answer}
                          {value?.answerHtml && 
                            <ParaTag dangerouslySetInnerHTML={{ __html: value?.answerHtml }}></ParaTag>
                          }
                          {value?.url && (
                            <>
                              <br />
                              <AnchorTag href={value?.url} target="_blank" txtPrimary>
                                {value?.url}
                              </AnchorTag>
                            </>
                          )}
                        </AccordionItemDiv>
                      </Accordion>
                    ) : (
                      <>
                        <AnchorTag
                          BodyColorHover
                          BodyColor
                          href={value?.link && value?.link}
                          target="_blank"
                          rel="noreferrer"
                          BorderBottomTradingComp
                          padding="12px 0px"
                        >
                          <BannerHeadingSpan weight="500" size="18px" sizeLg="16px" sizeMd="14px">
                            {" "}
                            {value?.question}
                          </BannerHeadingSpan>
                        </AnchorTag>
                        {/* <hr style={{ margin: "5px 0px" }} /> */}
                      </>
                    )}
                  </>
                );
              })}
          </BannerBox>
        </BannerSection>
      )}
    </>
  );
}

export default Faq;
