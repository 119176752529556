/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState, Fragment } from "react";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import { formatAPIErrors } from "../../../../utils/general";
import { createWallet } from "../../../../lib/api";
import { WalletContext } from "../../../../contexts/WalletPage/BalancePage/wallet";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import LightiningNetwork from "../../../../components/Wallets/Deposits/LightiningNetwork";
import {
  DivElement,
  TextWrapper,
  AddressContainer,
  QrContainer
} from "../../../../components/Reusable";
import { Skeleton } from "../../../../components/Loader";

const NonFiatAddressComponent = () => {
  let { exchWallet, callAllWallet } = useContext(WalletContext);
  let { selectedCoin, selectedNetwork, setSelectedCoin } = useContext(DepositContext);
  const [isFetchingData, setIsFetchingData] = useState(false);

  useEffect(() => {
    if (
      selectedCoin &&
      selectedNetwork &&
      selectedNetwork.label !== "Lightning" &&
      selectedCoin.coin &&
      (!selectedCoin.address ||
        (selectedCoin.isMemo && !selectedCoin.memo) ||
        selectedCoin.coin === "USDT")
    ) {
      setIsFetchingData(true);

      createWallet({ coin: selectedCoin.coin, network_id: selectedNetwork.id })
        .then((res) => {
          if (selectedCoin.coin === "USDT" || selectedCoin.coin === "DOGS") {
            if (selectedNetwork.label === "Ton") {
              let resData = res?.data?.data;
              selectedCoin.address = resData?.address;
              selectedCoin.memo = resData?.memo;
              selectedCoin.isMemo = true;
            } else {
              selectedCoin.address = "";
              selectedCoin.memo = "";
              selectedCoin.isMemo = false;
              callAllWallet();
            }
          } else {
            callAllWallet();
          }
        })
        .catch((err) => {
          showErrorMsg(formatAPIErrors(err));
        })
        .finally(() => {
          setIsFetchingData(false);
        });
    }
  }, [selectedNetwork, selectedCoin]);

  useEffect(() => {
    if (
      !selectedCoin.address ||
      (selectedCoin.isMemo && !selectedCoin.memo) ||
      selectedCoin.coin === "USDT"
    ) {
      let result = exchWallet?.data?.filter((coin) => selectedCoin.coin === coin.coin);
      selectedCoin.address = (result && result[0]?.address) || "";
      selectedCoin.memo = (result && result[0]?.memo) || "";
      selectedCoin.isMemo = result && result[0]?.isMemo;
    }
  }, [exchWallet]);

  return (
    <Fragment>
      {selectedCoin.coin === "BTC" && selectedNetwork.label === "Lightning" ? (
        <LightiningNetwork selectedNetwork={selectedNetwork} selectedCoin={selectedCoin} />
      ) : (
        <DivElement marginTop="32px">
          {!isFetchingData &&
            selectedCoin &&
            (selectedCoin.address || (selectedCoin.isMemo && selectedCoin.memo)) && (
              <Fragment>
                <TextWrapper secondary fontSize="14px" fontWeight="300">
                  *Submit a deposit using the following address or the QR code*
                </TextWrapper>

                {selectedCoin.isMemo && (
                  <DivElement>
                    <TextWrapper
                      secondary
                      fontSize="14px"
                      fontWeight="400"
                      marginTop="24px"
                      marginBottom="6px"
                    >
                      Memo ID
                    </TextWrapper>
                    <AddressContainer
                      text={selectedCoin.memo}
                      onCopy={() => showSuccessMsg("Successfully copied Memo ID")}
                    />
                  </DivElement>
                )}

                <TextWrapper
                  secondary
                  fontSize="14px"
                  fontWeight="300"
                  marginTop="24px"
                  marginBottom="6px"
                >
                  Copy the address below
                </TextWrapper>
                <AddressContainer
                  text={selectedCoin.address}
                  onCopy={() => showSuccessMsg("Successfully copied Address")}
                />
                <DivElement displayCenter>
                  <TextWrapper fontSize="16px" fontWeight="400" margin="12px 0">
                    or
                  </TextWrapper>
                </DivElement>
                <DivElement displayCenter>
                  <QrContainer value={selectedCoin.address || ""} />
                </DivElement>
              </Fragment>
            )}

          {(isFetchingData ||
            (selectedCoin &&
              (!selectedCoin.address || (selectedCoin.isMemo && !selectedCoin.memo)))) && (
            <Skeleton width="100%" height="48px" />
          )}
        </DivElement>
      )}
    </Fragment>
  );
};

export default NonFiatAddressComponent;
