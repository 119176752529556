import { createGlobalStyle, css } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    font-family: 'IBM Plex Sans' !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: none;
    /* scrollbar-width: none; */
  }
  
  *::-webkit-scrollbar {
    display: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  body{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  #scroll-bar {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: absolute;
    &:before, &:after{
      scrollbar-width: auto!important;
    }
  }
  #scroll-bar::-webkit-scrollbar {
    position: absolute;
    scrollbar-width: auto!important;
    display: block !important;
    width: 12px;
    top: 60px;
  }
  /* Track */
  #scroll-bar::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.NewSpans}; 
  }
  
  /* Handle */
  #scroll-bar::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.cardBg};
    border: 3px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }

  /* Handle on hover */
  #scroll-bar::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.cardBg};
  }
  
  .custom_loader {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
  
    @include themed() {
      background-color: t($primary-bg);
    }
  
    svg {
      fill: #047bf8;
    }
  }
  
  .loader-11 {
    width: 200px;
    height: 200px;
    -webkit-animation: loader-11 1.2s infinite ease-in-out;
    animation: loader-11 1.2s infinite ease-in-out;
  }
  
  @-webkit-keyframes loader-11 {
    0% {
      transform: perspective(360px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(360px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      transform: perspective(360px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  
  @keyframes loader-11 {
    0% {
      transform: perspective(360px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(360px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      transform: perspective(360px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }

  a {
    text-decoration: none;
  }

  .dFlexJustifyContentBetween{ 
    display: flex !important; 
    justify-content: space-between;
  }
  .dFlexJustifyContentCenter{ 
    display: flex !important; 
    justify-content: center;
  }
  .dFlexJustifyContentStart{ 
    display: flex !important; 
    justify-content: flex-start;
  }
  .dFlexJustifyContentEnd{ 
    display: flex !important; 
    justify-content: flex-end;
  }

  .title-popup-red {
    color: #fff !important;
  }
  body[class~="dark"] .depth-chart-module_canvasContainer__25jGd,
  body[class~="dark"] .non-ideal-state {
    background-color: #151e26 !important;
  }
  body[class~="dark"] .spinner-track {
    stroke: #151e26 !important;
  }

  body[class~="dark"] #activeDepthChart.depth-chart-module_canvasContainer__25jGd,
  body[class~="dark"] .non-ideal-state {
    background-color: #21282F !important;
  }
  body[class~="dark"] .spinner-track {
    stroke: #151e26 !important;
  }
`;

const dFlex = css`
  display: flex !important;
`;
const dFlexJustifyContentBetween = css`
  ${dFlex}
  justify-content: space-between;
`;

const dFlexJustifyContentCenter = css`
  ${dFlex}
  justify-content: center;
`;

const dFlexJustifyContentStart = css`
  ${dFlex}
  justify-content: flex-start;
`;

const dFlexJustifyContentEnd = css`
  ${dFlex}
  justify-content: flex-end;
`;

export {
  GlobalStyle,
  dFlex,
  dFlexJustifyContentBetween,
  dFlexJustifyContentCenter,
  dFlexJustifyContentStart,
  dFlexJustifyContentEnd
};
