/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { LoadingButton } from "../../../../../components/Loader";
import { numberWithCommas } from "../../../../../utils/general";
import { Button, DivElement, ModalWrapper, TextWrapper } from "../../../../../components/Reusable";
import { CloseIcon } from "../../../../../assets/svgs";
import { ErrorMessage } from "formik";
import { Checkbox } from "../../../../../components/Reusable";
import { AnchorTag } from "../../../../../assets/StyleComponents/FontSize/para.style";
// import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";

const Step3 = ({
  saleDetails,
  selectedCoinRate,
  onBackHandler,
  purchaseAmt,
  paymentAmt,
  saleRoundData,
  purchaseFee,
  onConfirmAndPayHandler,
  confirmPayLoader,
  setStep
}) => {
  const [fieldvalue, setFieldValue] = useState(false);
  const DETAILS = [
    { type: "Amount", value: `${numberWithCommas(+purchaseAmt)} ${saleDetails.coin}` },
    {
      type: "Currency to be paid",
      value: `${numberWithCommas(+paymentAmt)} ${selectedCoinRate.coin}`
    },
    {
      type: "Fee (1%)",
      value: `${numberWithCommas(+purchaseFee)} ${selectedCoinRate.feeCoin || "LCX"}`
    }
  ];

  return (
    <ModalWrapper>
      <DivElement ModalContainer className="modal-container">
        <DivElement displayBtw TSBorderDashed paddingBottom="12px">
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
            Confirmation
          </TextWrapper>
          <CloseIcon pointer onClick={() => setStep(2)} />
        </DivElement>

        <DivElement bdGrey padding="8px 0px" marginTop="10px" borderRadius="5px">
          {DETAILS.map((data, i) => {
            return (
              <DivElement key={i} flexJustifyBtwAlignCenter padding="8px 0" BorderBottom>
                <TextWrapper fontSizeMd="12px">{data.type}</TextWrapper>
                <TextWrapper fontSizeMd="12px">{data.value}</TextWrapper>
              </DivElement>
            );
          })}
          <DivElement display="flex" marginTop="28px">
            <Checkbox checked={fieldvalue} setChecked={() => setFieldValue(!fieldvalue)} />
            <TextWrapper fontWeight="300" fontSize="14px" marginLeft="12px">
              I have read the{" "}
              <AnchorTag
                className="blue-font"
                // txtPrimary
                href={saleDetails?.publicData?.legal?.downloadLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Basic Information
              </AnchorTag>{" "}
              and accept the{" "}
              <AnchorTag
                // txtPrimary
                className="blue-font"
                href={saleDetails?.publicData.legal?.termsOfSale}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Token Sale.
              </AnchorTag>
            </TextWrapper>
          </DivElement>
        </DivElement>
        <Button
          primaryBlue
          marginTop="20px"
          width="100%"
          height="48px"
          disabled={confirmPayLoader}
          onClick={onConfirmAndPayHandler}
        >
          {confirmPayLoader ? <LoadingButton color="#fff" /> : "Confirm"}
        </Button>
      </DivElement>
    </ModalWrapper>
  );
};

export default Step3;
