import { useState } from "react";
import { DivElement, ElementBox } from "../../../../components/Reusable";
import TradePageNavbar from "./TradePageNavbar";
import OrderFormSection from "./OrderFormSection";
import TradingViewChart from "../../../../components/TradingViewChart";
import BalancesTab from "../Components/BalancesTab";
import OrderBookTab from "./OrderBookTab";
import TradesTab from "./TradesTab";
import DepthChart from "../Components/DepthChart";
import UserAllOrders from "../Components/UserAllOrders";

const TABS = [
  { label: "Chart", value: "chart" },
  { label: "Depth", value: "depth" },
  { label: "Order Book", value: "orderBook" },
  { label: "Trades", value: "trades" },
  { label: "My Orders", value: "myOrders" },
  { label: "Balances", value: "balances" }
];

const TradePageMobileLayout = () => {
  const [activeTab, setActiveTab] = useState("chart");
  return (
    <DivElement marginBottomSm="60px">
      <TradePageNavbar tabs={TABS} activeTab={activeTab} setActiveTab={setActiveTab} />

      <ElementBox
        boxShadow="none"
        borderRadius="0 0 5px 5px"
        height="510px"
        padding="20px 40px"
        paddingSm="20px 16px"
      >
        {activeTab === TABS[0].value && <TradingViewChart />}
        {activeTab === TABS[1].value && <DepthChart />}
        {activeTab === TABS[2].value && <OrderBookTab />}
        {activeTab === TABS[3].value && <TradesTab />}
        {activeTab === TABS[4].value && <UserAllOrders />}
        {activeTab === TABS[5].value && <BalancesTab />}
      </ElementBox>
      <OrderFormSection />
    </DivElement>
  );
};

export default TradePageMobileLayout;
