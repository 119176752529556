/* eslint-disable no-console */

/* eslint-disable no-unused-vars */
import { fromJS } from "immutable";
import {
  UPDATE_TRADE_SELECTED_TAB,
  UPDATE_CHECK_TRADING_PAIR,
  UPDATE_HISTORY_FILTER_SLAB,
  UPDATE_TAB_HISTORY_TAB,
  UPDATE_MOBILE_HISTORY_TAB,
  UPDATE_ORDERS_OFFSET,
  UPDATE_CHECK_OPEN_ORDERS,
  UPDATE_ORDER_BALANCE_SLAB
} from "./const.js";

import { failure, success } from "../../../utils/redux.js";
import { filterUSDVolume, getLocalStorageData } from "../../../utils/general.js";
import { getLocalStorage, setLocalStorage } from "../../../utils/helpers.js";

export function createInitialState() {
  return fromJS({
    tradeSelectValue: 1,
    checkBoxAllTradingPair: JSON.parse(getLocalStorage("orderFilter")) || false,
    checkBoxAllOpenOrders: JSON.parse(getLocalStorage("openOrderFilter")) || false,
    orderHistoryFilterSlab: "1d",
    offset: 1,
    activeMobileHistoryTab: "Amount",
    activeMobileTab: window.innerWidth < 768 ? "Price" : "Status",
    orderBalanceSlab: "",
    setting:
      getLocalStorageData("tradeSetting") !== null
        ? JSON.parse(getLocalStorageData("tradeSetting"))
        : false
  });
}

export function TradeOrderRecordsReducer(state = createInitialState(), action) {
  switch (action.type) {
    case UPDATE_TRADE_SELECTED_TAB:
      return fromJS({
        ...state.toJS(),
        tradeSelectValue: action.payload
      });

    case UPDATE_CHECK_TRADING_PAIR:
      setLocalStorage(JSON.stringify(action.payload), "orderFilter");
      return fromJS({
        ...state.toJS(),
        checkBoxAllTradingPair: action.payload
      });

    case UPDATE_CHECK_OPEN_ORDERS:
      setLocalStorage(JSON.stringify(action.payload), "openOrderFilter");
      return fromJS({
        ...state.toJS(),
        checkBoxAllOpenOrders: action.payload
      });

    case UPDATE_HISTORY_FILTER_SLAB:
      return fromJS({
        ...state.toJS(),
        orderHistoryFilterSlab: action.payload
      });

    case UPDATE_ORDER_BALANCE_SLAB:
      return fromJS({
        ...state.toJS(),
        orderBalanceSlab: action.payload
      });

    case UPDATE_ORDERS_OFFSET:
      return fromJS({
        ...state.toJS(),
        offset: action.payload
      });

    case UPDATE_TAB_HISTORY_TAB:
      return fromJS({
        ...state.toJS(),
        activeMobileHistoryTab: action.payload
      });

    case UPDATE_MOBILE_HISTORY_TAB:
      return fromJS({
        ...state.toJS(),
        activeMobileTab: action.payload
      });

    default:
      return state;
  }
}
