let common = {
  alert: {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    DANGER: "danger"
  },
  screenNames: {
    login: "Log in",
    forgotPassword: "Forgot Password",
    register: "Register",
    resetPassword: "Reset Password",
    dashboard: "Dashboard",
    trades: "Trades",
    markets: "Markets",
    profile: "Settings",
    news: "News",
    alerts: "Alerts",
    smartTrade: "Smart Order"
  },
  messages: {
    VERIFIED_SUCCESSFULLY: "You have successfully verified. You can now Log in.",
    REGISTERED_SUCCESSFULLY:
      "Thanks for registering. Please verify your email to activate your account.",
    RESET_SUCCESSFULLY:
      "Your password has been reset successfully. You can now Log in using your new password."
  }
};

export default common;
