import React, { useState } from "react";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { AccordianCard } from "../../../assets/StyleComponents/AccordionCard.style";
import { BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { ExpandMoreIcon, ExpandLessIcon } from "../../../assets/svgs";
import { Tbody, Thead, Th, Td, Table, Tr } from "../../../components/Reusable";
import { LiTag } from "../../../assets/StyleComponents/FontSize/para.style";

function Rules(props) {
  const { Rules, Rewards, ParticipationRewards } = props;
  const { width } = useWindowSize();
  // const collapse1 = useRef(null);
  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const isTheme = useSelector((state) => state.theme.isTheme);
  const olStyles = {
    fontSize: width < 1024 ? "12px" : "14px",
    fontWeight: "400",
    lineHeight: "20px",
    color: isTheme ? "#DEDFE0" : "#012243"
  };

  return (
    <>
      <BannerBox
        CardWhite
        radius={"10px"}
        padding={"32px 32px 48px"}
        paddingLg={"24px 20px 48px"}
        paddingMd={"16px 12px 40px"}
      >
        <BannerHeading size="20px" weight="500" sizeMd="16px" sizeLg="18px">
          Rewards & Rules
        </BannerHeading>
        <BannerBox margin="15px 0px">
          {/* +++++++++++++++++++++++++++++++++++++++++++++ */}
          <AccordianCard
            NoBackground
            className="mt-3"
            onClick={() => {
              setCollapse1(!collapse1);
              setCollapse2(false);
            }}
          >
            <BannerBox
              style={{ background: !collapse1 ? "transparent" : isTheme ? "#1F2C38" : "#F5F6FA" }}
              width="100%"
              display="flex"
              justifyContent="space-between"
              height="60px"
              alignItems="center"
              padding="0px 10px"
            >
              <BannerHeadingSpan weight="500" size="18px" sizeLg="16px" sizeMd="14px" BodyColor>
                {" "}
                Rewards{" "}
              </BannerHeadingSpan>
              {!collapse1 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              {/* <ExpandMoreIcon className="mr-3" /> */}
            </BannerBox>
            {collapse1 && (
              <Accordion.Collapse eventKey="1" style={{ padding: "0px" }}>
                <Card.Body style={{ padding: "0px" }}>
                  <BannerBox margin="15px 0px" BorderBottomAccordian>
                    <BannerHeading
                      weight="500"
                      size="18px"
                      sizeLg="16px"
                      sizeMd="14px"
                      margin="10px 1rem"
                    >
                      {" "}
                      Participation Reward{" "}
                    </BannerHeading>
                    <ol style={olStyles}>
                      {ParticipationRewards?.length > 0 ? (
                        ParticipationRewards?.map((val) => {
                          return <li key={val}>{val}</li>;
                        })
                      ) : (
                        <BannerHeading size="16px" weight="400" sizeLg="14px" sizeMd="12px">
                          No Rewards Found
                        </BannerHeading>
                      )}
                    </ol>
                  </BannerBox>

                  <BannerBox margin="15px 0px">
                    <BannerHeading
                      weight="500"
                      size="18px"
                      sizeLg="16px"
                      sizeMd="14px"
                      margin="10px 1rem"
                    >
                      {" "}
                      Leader board Reward{" "}
                    </BannerHeading>
                    {Rewards?.length > 0 ? (
                      <Table
                        width={width > 1024 ? "75%" : "100%"}
                        bRadius
                        margin="0px 1rem"
                        borderCollapse="collapse"
                      >
                        <Thead>
                          <Tr>
                            <Th fontSize="14px" fontWeight="500">
                              Rank
                            </Th>
                            <Th fontSize="14px" fontWeight="500">
                              Reward
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {Rewards?.length > 0 &&
                            Rewards?.map((val, index) => {
                              return (
                                <>
                                  <Tr border={Rewards?.length !== index - 1}>
                                    <Td BodyColor fontSize="14px" fontWeight="500">
                                      {val.rank}
                                    </Td>
                                    <Td BodyColor fontSize="14px" fontWeight="500">
                                      {val.reward}
                                    </Td>
                                  </Tr>
                                </>
                              );
                            })}
                        </Tbody>
                      </Table>
                    ) : (
                      <BannerHeading
                        size="16px"
                        weight="400"
                        sizeMd="12px"
                        sizeLg="14px"
                        ml="40px"
                        mlMd="35px"
                      >
                        No Leader Board Rewards Found
                      </BannerHeading>
                    )}
                  </BannerBox>
                </Card.Body>
              </Accordion.Collapse>
            )}
          </AccordianCard>
          {/* ++++++++++++++++++++++++++++++++++++++++++++++++ */}
          <AccordianCard
            NoBackground
            margin="10px 0px"
            onClick={() => {
              setCollapse2(!collapse2);
              setCollapse1(false);
            }}
          >
            <BannerBox
              style={{ background: !collapse2 ? "transparent" : isTheme ? "#1F2C38" : "#F5F6FA" }}
              width="100%"
              display="flex"
              justifyContent="space-between"
              height="60px"
              alignItems="center"
              padding="0px 10px"
            >
              <BannerHeadingSpan weight="500" size="18px" sizeLg="16px" sizeMd="14px" BodyColor>
                {" "}
                Rules & Regulations{" "}
              </BannerHeadingSpan>
              {!collapse2 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              {/* <ExpandMoreIcon className="mr-3" /> */}
            </BannerBox>
            {collapse2 && (
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <ol style={olStyles}>
                    {Rules?.length > 0 ? (
                      Rules?.map((val) => {
                        return (
                          <LiTag key={val} lineHeight="20px">
                            {val}
                          </LiTag>
                        );
                      })
                    ) : (
                      <BannerHeading size="16px" weight="400" sizeLg="14px" sizeMd="12px">
                        No Rules Found
                      </BannerHeading>
                    )}
                  </ol>
                </Card.Body>
              </Accordion.Collapse>
            )}
          </AccordianCard>
        </BannerBox>
      </BannerBox>
    </>
  );
}

export default Rules;
