import styled from "styled-components";
import { TableRowComponent } from "../TableHead.style";

const LeaderboardRow = styled(TableRowComponent)`
  th,
  td {
    display: flex;
    font-size: 14px !important;
    font-weight: 500;
    height: 40px !important;
    line-height: 18px;
    text-right: right;

    &.symb-padd {
      justify-content: center !important;
    }
    &.text-right {
      justify-content: flex-end !important;
    }
    &.heavy-weight {
      font-weight: 700 !important;
    }

    color: ${({ theme }) => theme.bodyText};
    flex: none;
    flex-grow: 0;
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start !important;

    &:nth-child(1) {
      flex: 0 0 212px;
      text-transform: capitalize;
    }

    &:nth-child(2) {
      flex: 1 1 300px;
    }

    &:nth-child(4) {
      flex: 0 0 150px !important;
      justify-content: flex-start !important;
    }

    &:nth-child(3) {
      flex: 0 0 250px;
      justify-content: flex-end !important;
    }

    &:nth-child(5) {
      flex: 0 0 120px;
      justify-content: flex-start !important;
    }

    &:nth-child(6) {
      flex: 0 0 155px;
      justify-content: flex-start !important;
    }

    &.text-center {
      justify-content: center !important;
    }

    @media (max-width: 1024px) {
      &:nth-child(1) {
        flex: 0 0 193px;
        text-transform: capitalize;
      }

      &:nth-child(2) {
        justify-content: flex-end !important;
        flex: 0 0 193px;
      }
      &:nth-child(3) {
        justify-content: flex-end !important;
        flex: 1 1 193px !important;
      }
      &:nth-child(4) {
        justify-content: flex-start !important;
        flex: 1 1 161px !important;
      }
    }

    @media (max-width: 767px) {
      &:nth-child(1) {
        flex: 0 0 105px;
        text-transform: capitalize;
      }

      &:nth-child(2) {
        justify-content: flex-start !important;
        flex: 0 0 80px;
        padding-left: 10px;
      }
      &:nth-child(3) {
        justify-content: flex-end !important;
        flex: 1 1 105px !important;
      }
      &:nth-child(4) {
        justify-content: flex-start !important;
        flex: 1 1 108px !important;
      }
    }
  }
  th {
    line-height: 16px;
    justify-content: center;
    text-transform: capitalize !important;
    cursor: pointer;
    border: none !important;
    font-weight: 400 !important;
    color: ${({ theme }) => theme.tableHeaderColor} !important;
    p,
    h5,
    button,
    span {
      font-weight: 500 !important;
      color: ${({ theme }) => theme.bodyText} !important;
    }
    @media (max-width: 1024px) {
      font-size: 14px !important;
    }
    @media (max-width: 767px) {
      font-size: 12px !important;
    }
  }
  td {
    font-size: 14px !important;
    @media (max-width: 1024px) {
      font-size: 14px !important;
    }
    @media (max-width: 767px) {
      font-size: 12px !important;
    }
    font-weight: 500;
    h5,
    button {
      flex-grow: 1;
    }

    @media (max-width: 1024px) {
      align-items: center !important;
    }
  }
`;

export { LeaderboardRow };
