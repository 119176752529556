import { DivElement, ElementBox, TextWrapper } from "../../../../../components/Reusable";
import ArticleContainer from "./ArticleContainer";
import BondDetailPoints from "./BondDetailPoints";

const DesktopView = ({ bondSummary }) => {
  return (
    <ElementBox border minHeight="581px" height="calc(100% - 16px)" padding="0" EarnPageHeader>
      <div className="bondsumm__header">
        <span>{bondSummary.bondName}</span> Tokenized Bond
      </div>
      <DivElement padding="18px 34px 32px 38px" height="100%">
        <TextWrapper fontSize="20px" primary marginBottom="26.5px">
          Summary
        </TextWrapper>
        <BondDetailPoints bondSummary={bondSummary} />
        <ArticleContainer />
      </DivElement>
    </ElementBox>
  );
};

export default DesktopView;
