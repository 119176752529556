import { useEffect, useRef } from "react";

export const useMarquee = (speed = 10) => {
  const marqueeRef = useRef(null);

  useEffect(() => {
    const marqueeElement = marqueeRef.current;
    if (!marqueeElement) return;

    const contentWidth = marqueeElement.scrollWidth / 2;
    const containerWidth = marqueeElement.parentElement.offsetWidth;
    const animationDuration = (contentWidth + containerWidth) / speed;
    marqueeElement.style.setProperty("--animation-duration", `${animationDuration}s`);
  }, [speed]);

  return marqueeRef;
};
