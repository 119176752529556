import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import config from "../../../../../constants/config";
import { numberWithCommas } from "../../../../../utils/general";
import { TableLoader } from "../../../../../components/Loader";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import {
  Button,
  DivElement,
  ImgElement,
  Table,
  Tbody,
  Td,
  TextWrapper,
  Th,
  ThDropdown,
  Thead,
  Tr,
  TableNoRecord
} from "../../../../../components/Reusable";
import LoginMessage from "../LoginMessage";
import { updateActiveWallet } from "../../../../../actions/trading";

const BalancesTab = ({
  isDarkTheme,
  mainWallet,
  exchangeWallet,
  isFetchingMainWallet,
  isFetchingExchangeWallet,
  activeWallet
}) => {
  const [userWallet, setUserWallet] = useState("");
  const [loader, setLoader] = useState(false);
  const [activeCol, setActiveCol] = useState("Available");
  const { width } = useWindowSize();
  const isMobile = width <= 767 ? true : false;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeWallet === "exchange" && isFetchingExchangeWallet) {
      setLoader(true);
    } else if (activeWallet === "main" && isFetchingMainWallet) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isFetchingMainWallet, isFetchingExchangeWallet, activeWallet]);

  useEffect(() => {
    let walletData = "";
    if (activeWallet === "exchange" && exchangeWallet) {
      walletData = exchangeWallet?.data;
    }
    if (activeWallet === "main" && mainWallet) {
      walletData = mainWallet?.data;
    }
    setUserWallet(walletData);
  }, [mainWallet, exchangeWallet, activeWallet]);

  const sortWalletCallback = (data1, data2) => {
    if (data1.balance.totalBalance !== data2.balance.totalBalance) {
      return data2.balance.totalBalance - data1.balance.totalBalance;
    }
    // If totalBalance is equal, sort based on coin alphabetically
    return data1.coin.localeCompare(data2.coin);
  };

  const setWallet = (value) => {
    dispatch(updateActiveWallet(value));
  };

  return (
    <DivElement column height="100%">
      <DivElement flex margin="0 -12px" marginMd="0 -8px">
        <Button
          width="116px"
          fontSize="12px"
          height="24px"
          padding="0"
          margin="0 12px"
          marginMd="0 8px"
          onClick={() => setWallet("exchange")}
          primaryBlue={activeWallet === "exchange" ? true : null}
          secondarySubText={activeWallet === "exchange" ? null : true}
          data-before="Exchange balance"
        >
          Exchange balance
        </Button>
        <Button
          width="88px"
          fontSize="12px"
          height="24px"
          padding="0"
          margin="0 12px"
          marginMd="0 8px"
          onClick={() => setWallet("main")}
          primaryBlue={activeWallet === "main" ? true : null}
          secondarySubText={activeWallet === "main" ? null : true}
        >
          Main balance
        </Button>
      </DivElement>
      {isLoggedIn ? (
        <DivElement flexGrow="1" marginTop="12px" marginTopMd="16px" scrollY>
          {!loader && userWallet ? (
            <Fragment>
              <Table bRadiusTop headerStickyTop>
                <Thead height="28px" heightMd="36px" heightSm="40px" fontSize="12px">
                  <Tr height="28px" heightMd="36px" heightSm="40px">
                    <Th textAlign="left" paddingLeft="20px">
                      Asset
                    </Th>
                    <Th textAlign="right">Total</Th>
                    {!isMobile && (
                      <Fragment>
                        <Th textAlign="right">Available</Th>
                        <Th textAlign="right" paddingRight="20px">
                          Occupied
                        </Th>
                      </Fragment>
                    )}
                    {isMobile && (
                      <ThDropdown
                        textAlign="right"
                        right="-8px"
                        paddingRight="12px"
                        selectOption={setActiveCol}
                        activeCol={activeCol}
                        OPTIONS={["Available", "Occupied"]}
                      />
                    )}
                  </Tr>
                </Thead>
                <Tbody fontSize="12px" lineHeight="18px">
                  {userWallet &&
                    userWallet.sort(sortWalletCallback).map((data, index) => (
                      <Tr
                        height="24px"
                        heightMd="48px"
                        key={data.coin}
                        borderBottom={index + 1 === userWallet.length ? null : true}
                      >
                        <Td textAlign="left" paddingLeft="20px">
                          <DivElement flexAlignCenter>
                            <ImgElement
                              width="16px"
                              height="16px"
                              marginRight="4px"
                              src={`${
                                isDarkTheme ? config.darkCoinUrl : config.lightCoinUrl
                              }${data.coin.toLowerCase()}.svg`}
                            />
                            <TextWrapper fontSize="inherit">{data.coin}</TextWrapper>
                          </DivElement>
                        </Td>
                        <Td textAlign="right">{numberWithCommas(data?.balance?.totalBalance)}</Td>
                        {(!isMobile || (isMobile && activeCol === "Available")) && (
                          <Td textAlign="right" paddingRightSm="12px">
                            {numberWithCommas(data?.balance?.freeBalance)}
                          </Td>
                        )}
                        {(!isMobile || (isMobile && activeCol === "Occupied")) && (
                          <Td textAlign="right" paddingRight="20px" paddingRightSm="12px">
                            {numberWithCommas(data?.balance?.occupiedBalance)}
                          </Td>
                        )}
                      </Tr>
                    ))}
                </Tbody>
              </Table>

              {userWallet && userWallet.length === 0 && <TableNoRecord />}
            </Fragment>
          ) : (
            <TableLoader webCol={4} />
          )}
        </DivElement>
      ) : (
        <LoginMessage />
      )}
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  activeWallet: state.trading.activeWallet,
  isDarkTheme: state.theme.isDarkTheme,
  mainWallet: state.wallet.wallets,
  exchangeWallet: state.dashboard.exchangeWallet,
  isFetchingMainWallet: state.wallet.apiLoader,
  isFetchingExchangeWallet: state.dashboard.isFetchingGetWalletBalance
});

export default connect(mapStateToProps)(BalancesTab);
