import React from "react";
import { DivElement, ModalWrapper, TextWrapper } from "../../../../Reusable";
import { SnowboardingIllustration } from "../../../../../assets/svgs/illustrations";
import styled from "styled-components";

const IllustrationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 28px;
  margin-bottom: 32px;

  svg {
    max-width: 305.5px;
    max-height: 226.1px;
  }

  @media (max-width: 1024px) {
    margin-top: 33px;
    svg {
      width: 305.5px;
      height: 226.1px;
    }
  }
  @media (max-width: 767px) {
    margin-top: 36px;
    svg {
      width: 240.5px;
      height: 178px;
    }
  }
`;

const PasswordChangeModal = () => {
  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <TextWrapper fontSize="20px" fontSizeSm="16px" fontWeight="bold">
          Password Changed Successfully
        </TextWrapper>
        <IllustrationContainer>
          <SnowboardingIllustration />
        </IllustrationContainer>
        <TextWrapper secondary fontSize="14px" fontSizeSm="12px">
          This account will log out automatically and requests you to login with new generated
          password
        </TextWrapper>
      </DivElement>
    </ModalWrapper>
  );
};

export default PasswordChangeModal;
