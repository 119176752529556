import { connect } from "react-redux";
import { compose } from "redux";
import styled, { css } from "styled-components";

const Span = styled.span`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  color: ${(props) => props.color || props.theme.bodyText};
  display: ${(props) => (props.display ? props.display : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};

  @media (max-width: 1024px) {
    font-size: ${(props) => (props.fontSizeMd ? props.fontSizeMd : "")};
    font-weight: ${(props) => (props.fontWeightMd ? props.fontWeightMd : "")};
  }

  @media (max-width: 767px) {
    font-size: ${(props) => (props.fontSizeSm ? props.fontSizeSm : "")};
    font-weight: ${(props) => (props.fontWeightSm ? props.fontWeightSm : "")};
  }

  ${(props) =>
    props.primary &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#dedfe0" : "#012243")};
    `}

  ${(props) =>
    props.secondary &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#a7a9ab" : "#606f80")};
    `}

  ${(props) =>
    props.blue &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#70a6e0" : "#0470e1")};
    `}

  ${(props) =>
    props.red &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#e66767" : "#e65252")};
    `}

  ${(props) =>
    props.green &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#51B348" : "#006B00")};
    `}
`;

const SpanElement = (props) => {
  return <Span {...props} />;
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(SpanElement);
