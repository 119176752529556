import React from "react";
import ReturnCalculator from "../ReturnCalculator";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import Prospectus from "../Prospectus";

const AboutTab = ({ bondDetails, bondSummary }) => {
  const { bondName, isin, aboutIssuanceAmount, coin, min, max } = bondSummary;
  return (
    bondDetails && (
      <DivElement padding="32px 39px">
        <DivElement display="flex" justifyContent="space-between" marginBottom="54px">
          <DivElement width="100%" marginRight="44px">
            <TextWrapper fontSize="16px">
              LCX issues a {aboutIssuanceAmount} Tokenized Bond. Security prospectus published and
              endorsed by the regulator. Available in the European Economic Area (EEA), making the
              new fixed-yield tokenized bond available to verified LCX users in 30 countries
              including Austria, Belgium, Bulgaria, Croatia, Cyprus, Denmark, Estonia, Finland,
              France, Germany, Greece, Hungary, Iceland, Ireland, Italy, Latvia, Liechtenstein,
              Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania,
              Slovakia, Slovenia, Spain, Sweden, and Czech Republic.
            </TextWrapper>
            <TextWrapper fontSize="16px" marginTop="25px">
              {bondName} Tokenized Bond Summary Issuer: <br />
              LCX AG, Liechtenstein Issuance Amount: {max} <br />
              {coin} Coupon: 7% per annum <br />
              ISIN: {isin} <br />
              Denomination: {coin} {min}. The minimum consideration is {coin} {min}. <br />1{" "}
              {bondName} = {min} {coin}, Purchase only in whole, no fractional. <br />
              Maturity / Term: 7 Years max. Quarterly termination possible. <br />
              Governing Law: Liechtenstein <br />
              Blockchain: Ethereum public blockchain <br />
            </TextWrapper>
          </DivElement>
          <DivElement minWidth="414px">
            <ReturnCalculator bondDetails={bondDetails} />
          </DivElement>
        </DivElement>
        <Prospectus bondName={bondDetails ? bondDetails.bondName : ""} />
      </DivElement>
    )
  );
};

export default AboutTab;
