import styled, { css } from "styled-components";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import { numberInputOnWheelPreventChange } from "../../../../../utils";
import { memo } from "react";

const Wrapper = styled(DivElement)`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "38px"};
  margin: ${(props) => props.margin || "16px 0"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  background-color: ${(props) => props.theme.inputFieldBg};
  border-bottom: 1px solid ${(props) => props.theme.inputBorder};

  ${(props) =>
    props.isError &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.primaryRed};
    `}

  input {
    background-color: ${(props) => props.theme.inputFieldBg};
    text-decoration: none;
    outline: none;
    border: none;
    text-align: right;
    color: ${(props) => props.theme.primaryText};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    flex-grow: 1;
    width: 100%;
    padding: 0 4px;

    ${(props) =>
      props.isError &&
      css`
        color: ${(props) => props.theme.primaryRed};
      `};
  }

  div {
    width: fit-content;
  }
`;

const InputWrapper = ({
  label,
  value,
  coin,
  onChange,
  name,
  containerProps,
  type = "number",
  isError
}) => {
  return (
    <Wrapper {...containerProps} isError={isError}>
      <TextWrapper secondary fontWeight="400" lineHeight="20px" red={isError ? true : null}>
        {label}
      </TextWrapper>
      <input
        min={0}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
        onWheel={numberInputOnWheelPreventChange}
      />
      <TextWrapper secondary fontWeight="400" lineHeight="20px" red={isError ? true : null}>
        {coin}
      </TextWrapper>
    </Wrapper>
  );
};

export default memo(InputWrapper);
