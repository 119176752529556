/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, Fragment, useMemo, memo } from "react";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import { formatAPIErrors, noExponents, numberWithCommas } from "../../../../utils/general";
import { withdrawCoin, decodeLightningInvoice } from "../../../../lib/api";
import { WalletContext } from "../../../../contexts/WalletPage/BalancePage/wallet";
import WithdrawalModal from "../Component/Modal/withdrawalModal";
import { debounce } from "../../../../utils/helpers";
import { LoadingButton } from "../../../../components/Loader";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import ShowErrorMessage from "../../../../components/Reusable/ShowErrorMessage";
import { DivElement, TextWrapper, Button } from "../../../../components/Reusable";
import BtcLightningPreview from "../../../../components/Wallets/Withdrawals/BtcLightningPreview";
import { BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import {
  checkEmailPattern,
  numberInputOnWheelPreventChange,
  setPrecision
} from "../../../../utils";
import BigNumber from "bignumber.js";
import { FormInput } from "../../../../assets/StyleComponents/Input.style";
import { getEmailVerify } from "../../../../lib/Wallet/walletApi";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { divide, multiply } from "../../../../utils/bigNumber";

const NonFiatAddressComponent = (props) => {
  let { exchWallet } = useContext(WalletContext);
  let { selectedCoin, selectedNetwork } = useContext(DepositContext);
  const [email, setEmail] = useState("");
  const [address, set_address] = useState("");
  const [memoId, setMemoId] = useState("");
  const [amount, set_amount] = useState("");
  const [loading, set_loading] = useState(false);
  const [step, setStep] = useState(1);
  const [otpId, setOtpId] = useState("");
  const [withdrawalRes, setWithdrawalRes] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErroMsg] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [isDecodingInvoice, setIsDecodingInvoice] = useState(false);
  const [decodedInvoiceData, setDecodedInvoiceData] = useState("");
  const [isBalanceInsufficient, setIsBalanceInsufficient] = useState(false);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState({
    type: false,
    inviteStatus: false,
    disabledStatus: false,
    msg: ""
  });
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [finalAmt, setFinalAmt] = useState(0);

  useEffect(() => {
    if (props.apiError && props.apiError.message) {
      showErrorMsg(props.apiError.message);
      props.clearWalletErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiError]);

  useEffect(() => {
    setStep(step + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiData]);

  useEffect(() => {
    if (decodedInvoiceData) setDecodedInvoiceData("");
    if (isBalanceInsufficient) setIsBalanceInsufficient(false);
    if (error) setError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    setError("");
    set_address("");
    set_amount("");
    setErroMsg("");

    if (selectedCoin && selectedCoin.coin === "USDT") {
      if (selectedNetwork?.label === "Ton") {
        selectedCoin.isMemo = true;
      } else {
        selectedCoin.isMemo = false;
      }
    }
    if (selectedCoin.coin === "LCX" && selectedNetwork.label === "LCX Pay")
      setShowVerifyButton(true);
  }, [selectedNetwork, selectedCoin]);

  useEffect(() => {
    if (!selectedCoin) return;
    if (!amount || errorMsg) {
      setFinalAmt("");
      return;
    }
    let totalAmount;
    let amt = new BigNumber(amount);
    if (selectedCoin.coin !== selectedCoin.withdrawalFeeCoin) {
      totalAmount = amt;
    } else {
      let fee = new BigNumber(+selectedCoin?.fees);
      totalAmount = amt.minus(fee);
    }
    if (totalAmount < 0 || !totalAmount) totalAmount = 0;
    setFinalAmt(totalAmount);
  }, [amount, errorMsg]);

  const validateAmount = (value) => {
    let valueSplit = value.split(".");
    if (valueSplit && valueSplit.length === 2 && valueSplit[1].length > 8) {
      setErroMsg("Enter correct number");
      set_amount(value);
    } else {
      let amount = isNaN(value) || Number(value) < 0 ? "" : value;
      let feeAmt = Number(selectedCoin?.fees);
      let minAmt = Number(selectedCoin?.minWithdrawalLimit);
      let balance = Number(selectedCoin?.balance?.freeBalance);
      if (amount === "") {
        setErroMsg("");
      } else if (Number(amount) > balance) {
        setErroMsg("Insufficient balance");
      } else if (Number(amount) < minAmt) {
        setErroMsg(`Amount should be greater than or equal to ${minAmt}`);
      } else if (selectedCoin.coin !== selectedCoin.withdrawalFeeCoin) {
        let feeCoin = exchWallet?.data?.find(
          (data) => data.coin === selectedCoin.withdrawalFeeCoin
        );
        let feeBalance = Number(feeCoin?.balance?.freeBalance);

        if (feeBalance < feeAmt) {
          setErroMsg(`Insufficient ${selectedCoin.withdrawalFeeCoin}(transaction fee)`);
        } else {
          setErroMsg("");
        }
      } else {
        setErroMsg("");
      }
      set_amount(amount);
    }
  };

  const withdrawBtnHandler = debounce(() => {
    if (errorMsg !== "") return;

    if (!address && selectedCoin.coinType !== "fiat_token") {
      showErrorMsg("Invalid Address", { autoClose: 2000 });
      return;
    }

    if (selectedNetwork.label !== "Lightning") {
      if (selectedCoin.coin !== selectedCoin.withdrawalFeeCoin) {
        let feecoinBal = 0;
        if (exchWallet) {
          let feeCoin = exchWallet.data.find((ele) => {
            return ele.coin === selectedCoin.withdrawalFeeCoin;
          });
          if (feeCoin) {
            feecoinBal = feeCoin.balance.freeBalance;
          }
        }
        if (Number(feecoinBal) < Number(selectedCoin.fees)) {
          showErrorMsg(`Insufficient ${selectedCoin.withdrawalFeeCoin} balance`, {
            autoClose: 2000
          });
          return;
        }
      } else {
        if (Number(amount) < Number(selectedCoin.fees)) {
          showErrorMsg(
            `Minimum withdrawal amount is ${selectedCoin.minWithdrawalLimit} ${selectedCoin.coin}`,
            { autoClose: 2000 }
          );
          return;
        }
      }
    }

    if (selectedCoin.isMemo && !memoId) {
      showErrorMsg("Memo is required", { autoClose: 2000 });
      return;
    }

    if (
      Number(amount) < Number(selectedCoin.minWithdrawalLimit) &&
      selectedNetwork.label !== "Lightning"
    ) {
      showErrorMsg(
        `Minimum withdrawal amount is ${selectedCoin.minWithdrawalLimit} ${selectedCoin.coin}`,
        { autoClose: 2000 }
      );
      return;
    }

    if (!amount || isNaN(amount)) {
      showErrorMsg("Invalid Amount", { autoClose: 2000 });
      return false;
    }

    if (selectedCoin.balance && Number(selectedCoin.balance.freeBalance) < Number(amount)) {
      showErrorMsg("Insufficient balance", { autoClose: 2000 });
      return;
    }

    let data = {
      coin: selectedCoin.coin,
      amount: Number(amount),
      toAddress: address,
      source: "ACCOUNTS"
    };

    if (selectedCoin.isMemo) {
      data.memo = memoId;
    }

    set_loading(true);

    const params = {
      data: data,
      isBtcLightning: selectedCoin.coin === "BTC" && selectedNetwork.label === "Lightning",
      provider:
        selectedNetwork.label === "Clear Junction" ? "CLEAR_JUNCTION" : selectedNetwork.label
    };

    withdrawCoin(params)
      .then((res) => {
        showSuccessMsg(res.data.message);
        selectedCoin.supportNotabene = res.data.data.notabeneSupport;
        setOtpId(res.data.data.id);
        setWithdrawalRes(res.data.data);
        setShowModal(true);
        set_loading(false);
        setShowPreview(false);
      })
      .catch((err) => {
        set_loading(false);
        showErrorMsg(formatAPIErrors(err));
      });
  }, 700);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleLcxPay = () => {
    if (!email || !amount) {
      setError("Please enter fields");
      return;
    }
  };

  const onPreviewLightningWithdrawal = () => {
    if (!address) {
      setError("Please enter invoice");
      return;
    }

    setIsDecodingInvoice(true);

    const data = {
      invoice: address
    };

    decodeLightningInvoice(data)
      .then((res) => {
        let resData = res?.data;
        if (resData && resData.validation) {
          let amt = resData?.data?.amount + resData?.data?.fee;
          if (selectedCoin.balance && Number(selectedCoin.balance.freeBalance) < Number(amt)) {
            setIsBalanceInsufficient(true);
            return;
          } else {
            set_amount(amt);
            setShowPreview(true);
          }
        }
        setDecodedInvoiceData(resData);
      })
      .catch((err) => {
        let message = formatAPIErrors(err);
        if (message === "Something went wrong") {
          showErrorMsg(message);
        } else {
          setError(message);
        }
      })
      .finally(() => {
        setIsDecodingInvoice(false);
      });
  };

  const onClickMaxBtn = () => {
    let balance = setPrecision(selectedCoin?.balance?.freeBalance, 8);
    validateAmount(noExponents(balance));
  };

  const checkuserEmail = debounce(() => {
    let param = {
      coin: selectedCoin.coin,
      email: email
    };
    getEmailVerify(param)
      .then((res) => {
        if (res.data?.status === "SUCCESS") {
          setEmailError({
            ...emailError,
            type: false,
            disabledStatus: true,
            msg: res?.data?.message || ""
          });
        } else if (res.data?.status === "FAILED") {
          setEmailError({
            type: true,
            inviteStatus: true,
            disabledStatus: false,
            msg: res?.data?.message || ""
          });
        } else {
          setEmailError({
            ...emailError,
            type: true,
            disabledStatus: false,
            msg: res?.data?.message || ""
          });
        }
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  }, 600);

  const isBtcLightning = selectedCoin.coin === "BTC" && selectedNetwork.label === "Lightning";
  let isLcxPay = useMemo(
    () => selectedCoin.coin === "LCX" && selectedNetwork.label === "LCX Pay",
    [selectedNetwork]
  );

  let FinalCalculatedFee = useMemo(() => {
    let result = 0;
    if (selectedNetwork.transactionFeePercent) {
      result = multiply(divide(selectedNetwork.transactionFeePercent, 100), Number(amount), 2);
    }
    return result;
  }, [amount, selectedNetwork]);

  // const FinalCalculatedFee = (
  //   Number(selectedNetwork.transactionFeePercent / 100) * Number(amount)
  // ).toFixed(2);

  return (
    <Fragment>
      <Fragment>
        <DivElement className="fInput__form__field" width="100%" marginTop="24px" marginBottom="0">
          <TextWrapper
            secondary
            fontSize="14px"
            fontWeight="300"
            lineHeight="20px"
            marginBottom="6px"
          >
            {isLcxPay ? `LCX's User E-mail` : isBtcLightning ? "Invoice" : "Withdrawal Address"}
          </TextWrapper>
          <DivElement
            className={` fInput__form__input ${
              isLcxPay && emailError.type ? "fInput__form__input--bRed" : ""
            }`}
            position="relative"
          >
            <FormInput
              className="form-control"
              type={isLcxPay ? "email" : "text"}
              required="required"
              sizeMd="12px"
              placeholder={`Enter ${isLcxPay ? "email" : isBtcLightning ? "invoice" : "address"}`}
              value={isLcxPay ? email : address}
              onChange={(e) => {
                if (isLcxPay) {
                  setEmailError({
                    type: false,
                    inviteStatus: false,
                    disabledStatus: false,
                    msg: ""
                  });
                  setEmail(e.target.value);
                } else set_address(e.target.value);
              }}
            />
            {showVerifyButton && (
              <Button
                secondaryBlue
                padding="0 16px"
                height="32px"
                marginRight="12px"
                disabled={emailError.disabledStatus}
                onClick={checkuserEmail}
              >
                {emailError.inviteStatus ? "Invite" : "verify"}
              </Button>
            )}
          </DivElement>

          {emailError.msg && (
            <ShowErrorMessage
              type={emailError.type ? "error" : "success"}
              message={emailError.msg}
            />
          )}

          {((decodedInvoiceData && !decodedInvoiceData.validation) ||
            isBalanceInsufficient ||
            error) && (
            <ShowErrorMessage
              message={
                error ? error : isBalanceInsufficient ? "Insufficient balance" : "Invalid invoice"
              }
            />
          )}
        </DivElement>

        {!isBtcLightning && (
          <Fragment>
            {selectedCoin.isMemo && (
              <DivElement
                className="fInput__form__field"
                width="100%"
                marginTop="24px"
                marginBottom="0"
              >
                <TextWrapper
                  secondary
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  marginBottom="2px"
                >
                  Memo
                </TextWrapper>
                <DivElement className="fInput__form__input">
                  <FormInput
                    className="form-control"
                    type="text"
                    sizeMd="12px"
                    required="required"
                    placeholder="Enter Memo ID"
                    value={memoId}
                    onChange={(e) => setMemoId(e.target.value)}
                  />
                </DivElement>
              </DivElement>
            )}

            <DivElement
              className="fInput__form__field"
              width="100%"
              marginTop="24px"
              marginBottom="0"
            >
              <TextWrapper
                secondary
                fontSize="14px"
                fontWeight="300"
                lineHeight="20px"
                marginBottom="6px"
              >
                {isLcxPay ? "Transfer amount" : "Withdrawal amount"}
              </TextWrapper>

              <DivElement className="fInput__form__input" position="relative">
                <FormInput
                  width="calc(100% - 80px)"
                  className="form-control"
                  type="number"
                  required="required"
                  placeholder="Enter the amount"
                  sizeMd="12px"
                  value={amount}
                  onChange={(e) => validateAmount(e.target.value)}
                  onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  onWheel={numberInputOnWheelPreventChange}
                />

                {isLcxPay && <ParaTag px="16px"> {selectedCoin.coin} </ParaTag>}

                {!isLcxPay && (
                  <Button
                    secondaryBlue
                    padding="0 16px"
                    height="32px"
                    marginRight="12px"
                    onClick={onClickMaxBtn}
                  >
                    max
                  </Button>
                )}
              </DivElement>

              {errorMsg && (
                <ShowErrorMessage
                  message={errorMsg}
                  containerProps={{ marginTop: "4px", absolute: true }}
                />
              )}
            </DivElement>

            {selectedCoin?.fees && (
              <DivElement marginTop="34px">
                <TextWrapper secondary fontSize="14px">
                  Transaction Fee:{" "}
                  <BannerHeadingSpan headerText className="f-14 font-weight-500">
                    <var>
                      {numberWithCommas(
                        selectedNetwork.transactionFeePercent > 0
                          ? FinalCalculatedFee
                          : selectedCoin?.fees
                      )}{" "}
                      {selectedCoin.withdrawalFeeCoin
                        ? selectedCoin.withdrawalFeeCoin
                        : selectedCoin?.coin}
                    </var>
                  </BannerHeadingSpan>
                </TextWrapper>
                <TextWrapper secondary fontSize="14px" marginTop="10px">
                  You Will Get:{" "}
                  <BannerHeadingSpan headerText className="f-14 font-weight-500">
                    <var>
                      {selectedNetwork.transactionFeePercent > 0
                        ? numberWithCommas(Number(amount) - FinalCalculatedFee)
                        : `${numberWithCommas(finalAmt)} `}{" "}
                      {selectedCoin?.coin}
                    </var>
                  </BannerHeadingSpan>
                </TextWrapper>
              </DivElement>
            )}
          </Fragment>
        )}

        <Button
          primaryBlue
          width="100%"
          height="48px"
          marginTop="48px"
          disabled={
            isBtcLightning
              ? isDecodingInvoice || error || isBalanceInsufficient
              : loading || errorMsg
          }
          onClick={
            isLcxPay
              ? handleLcxPay
              : isBtcLightning
              ? onPreviewLightningWithdrawal
              : withdrawBtnHandler
          }
        >
          {isLcxPay ? (
            "Make Transfer"
          ) : isBtcLightning ? (
            isDecodingInvoice ? (
              <LoadingButton />
            ) : (
              "Preview withdrawal"
            )
          ) : loading ? (
            <LoadingButton />
          ) : (
            "Withdraw"
          )}
        </Button>
      </Fragment>

      {showModal && (
        <WithdrawalModal
          onClose={closeModal}
          show={showModal}
          otpId={otpId}
          withdrawalRes={withdrawalRes}
          coin={selectedCoin}
          amount={amount}
          address={address}
          setBtnLoading={set_loading}
          goToStep={!selectedCoin.supportNotabene ? 2 : 1}
        />
      )}

      {showPreview && decodedInvoiceData && (
        <BtcLightningPreview
          onClose={() => setShowPreview(false)}
          onConfirm={withdrawBtnHandler}
          loader={loading}
          invoiceData={decodedInvoiceData?.data}
        />
      )}
    </Fragment>
  );
};

export default memo(NonFiatAddressComponent);
