import React, { useState, Fragment, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { Pagination } from "../../../../components/Reusable";
import { Table, Thead, Tr, Th, Td, Tbody, ThDropdown } from "../../../../components/Reusable";
import moment from "moment";
import { TableLoader } from "../../../../components/Loader";
import { formatAPIErrors, numberWithCommas } from "../../../../utils/general";
import NoRecord from "../../../../components/Reusable/NoRecord";
import { clearEarnErrors, getBondInvestHistory } from "../../../../actions/earn";
import { showErrorMsg } from "../../../../utils/notification";
import styledConstants from "../../../../constants/styledConstants";
import BondTransactionStatus from "./BondTransactionStatus";
import HistoryTableFilter from "./HistoryTableFilter";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";

const InvestmentHistoryTable = ({
  investHistory,
  getBondInvestHistory,
  error,
  loader,
  showFilters,
  clearErrors
}) => {
  const [page, setPage] = useState(1);
  const [activeCol, setActiveCol] = useState("Status");
  const [dropdownOptions, setDropdownOptions] = useState(["Status", "Type"]);
  const { width } = useWindowSize();
  const location = useLocation();
  const params = useParams();
  const isDesktop = width > styledConstants.BREAKING_POINT_TABLET;
  const isTablet = width <= styledConstants.BREAKING_POINT_TABLET;
  const isMobile = width <= styledConstants.BREAKING_POINT_MOBILE;

  useEffect(() => {
    getHistory();
  }, [page]);

  const getHistory = () => {
    let pathname = location?.pathname;
    let bondId = params?.id;
    let data = {
      page: page - 1
    };
    if (pathname.includes("/earn/invest")) {
      data.bondId = bondId;
    }
    getBondInvestHistory(data);
  };

  useEffect(() => {
    if (error) {
      showErrorMsg(formatAPIErrors(error));
    }

    return () => {
      clearErrors();
    };
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (isMobile && dropdownOptions.length !== 3) {
      setDropdownOptions(["Amount", "Status", "Type"]);
    }

    if (!isMobile && activeCol === "Amount") {
      setActiveCol("Status");
      setDropdownOptions(["Status", "Type"]);
    }

    // eslint-disable-next-line
  }, [width]);

  const onApplyFilterHandler = (filters) => {
    let data = {
      page: page - 1,
      ...filters
    };

    if (filters.page) {
      data.page = filters.page;
    }

    getBondInvestHistory(data);
  };

  return (
    <Fragment>
      {showFilters && <HistoryTableFilter onApplyFilterHandler={onApplyFilterHandler} />}

      {investHistory && investHistory.data && !loader && (
        <Table bRadius>
          <Thead height="42px" fontSize="14px" fontSizeMd="12px">
            <Tr>
              {isDesktop && (
                <Th
                  whiteSpace="nowrap"
                  textAlign="left"
                  paddingLeft="20px"
                  paddingLeftMd="16px"
                  paddingLeftSm="12px"
                >
                  Txn
                </Th>
              )}
              <Th
                whiteSpace="nowrap"
                textAlign="left"
                paddingLeft="20px"
                paddingLeftMd="16px"
                paddingLeftSm="12px"
              >
                Bond
              </Th>

              <Th textAlign="left">
                Date <span className="date-cell">(DD/MM/YY, 24h)</span>
              </Th>

              {!isMobile && <Th textAlign="left">Amount</Th>}

              {isDesktop && (
                <Fragment>
                  <Th width="120px"></Th>
                  <Th textAlign="left">Type</Th>
                  <Th textAlign="left">Status</Th>
                </Fragment>
              )}

              {isTablet && (
                <ThDropdown
                  textAlign="right"
                  OPTIONS={dropdownOptions}
                  selectOption={setActiveCol}
                  activeCol={activeCol}
                />
              )}
            </Tr>
          </Thead>
          <Tbody fontSizeMd="14px" fontSizeSm="12px">
            {investHistory &&
              investHistory.data.map((item, i) => (
                <Tr key={i} height="44px">
                  {isDesktop && (
                    <Td
                      textAlign="left"
                      paddingLeft="20px"
                      paddingLeftMd="16px"
                      paddingLeftSm="12px"
                    >
                      {(page - 1) * 10 + i + 1}
                    </Td>
                  )}

                  <Td textAlign="left" paddingLeft="20px" paddingLeftMd="16px" paddingLeftSm="12px">
                    {item.bondName}
                  </Td>

                  <Td textAlign="left">
                    <var>{moment(item.createdAt).format("DD/MM/YY, HH:mm")}</var>
                  </Td>

                  {(!isMobile || activeCol === "Amount") && (
                    <Td
                      textAlign={activeCol === "Amount" && isMobile ? "right" : "left"}
                      paddingRightMd={activeCol === "Amount" ? "36px" : ""}
                      paddingRightSm={activeCol === "Amount" ? "32px" : ""}
                      whiteSpace="nowrap"
                    >
                      <var>{`${numberWithCommas(item.quoteAmount)} ${item.quoteCoin}`}</var>
                    </Td>
                  )}

                  {isDesktop && <Td width="120px"></Td>}
                  {(isDesktop || activeCol === "Type") && (
                    <Td
                      textAlign={activeCol === "Type" && isTablet ? "right" : "left"}
                      paddingRightMd={activeCol === "Type" ? "36px" : ""}
                      paddingRightSm={activeCol === "Type" ? "32px" : ""}
                    >
                      {item.type}
                    </Td>
                  )}
                  {(isDesktop || activeCol === "Status") && (
                    <BondTransactionStatus
                      txnDetails={item}
                      textProps={{
                        textAlign: activeCol === "Status" && isTablet ? "right" : "left",
                        paddingRightMd: activeCol === "Status" ? "36px" : "",
                        paddingRightSm: activeCol === "Status" ? "32px" : ""
                      }}
                    />
                  )}
                </Tr>
              ))}
          </Tbody>
        </Table>
      )}

      {(!investHistory || loader) && (
        <TableLoader webCol={6} tabletCol={4} mobileCol={3} rows={5} />
      )}

      {!loader && investHistory && investHistory.count === 0 && <NoRecord />}

      {investHistory && investHistory.count !== 0 && (
        <Pagination
          page={page}
          totalCount={(investHistory && investHistory.count) || 0}
          setPage={setPage}
          itemPerPage={10}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loader: state.earn.loader,
  investHistory: state.earn.investHistory,
  error: state.earn.error
});

const mapDispatchToProps = (dispatch) => ({
  getBondInvestHistory: (data) => dispatch(getBondInvestHistory(data)),
  clearErrors: () => dispatch(clearEarnErrors())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InvestmentHistoryTable);
