import { useEffect, useState } from "react";
import { connect } from "react-redux";
import BigNumber from "bignumber.js";
import { compose } from "redux";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { DivElement, ElementBox, TextWrapper } from "../../../../../components/Reusable";
import { getAllWallets } from "../../../../../actions/wallet";
import { ArrowLeftIcon } from "../../../../../assets/svgs";

const InvestmentSteps = ({ wallets, getAccWallets, bondDetails }) => {
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState(BigNumber(1));
  const [checked, setChecked] = useState(false);

  const onClickBack = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    if (!wallets) {
      getAccWallets();
    }
    // eslint-disable-next-line
  }, []);

  const onClose = () => {
    setStep(1);
    setAmount("");
  };

  return (
    <ElementBox border padding="0" height="calc(100% - 16px)" relative>
      <TextWrapper
        bgCream
        fontSize="20px"
        fontSizeSm="16px"
        lineHeight="1.2"
        padding={`${step === 1 ? "15px 38px" : "15px 24px"}`}
        paddingMd="15px 29px"
        paddingSm={`${step === 1 ? "19px 15px" : "16.5px 11px"}`}
        borderRadius="6px 6px 0 0"
      >
        {step === 1 && "Start Investing"}
        {step === 2 && (
          <DivElement dAlignCenter>
            <ArrowLeftIcon pointer onClick={onClickBack} />
            <TextWrapper
              fontSize="20px"
              fontSizeSm="16px"
              lineHeight="1.2"
              marginLeft="16px"
              marginLeftSm="6px"
            >
              Confirmation Screen
            </TextWrapper>
          </DivElement>
        )}
      </TextWrapper>

      {step === 1 && (
        <Step1
          amount={amount}
          setAmount={setAmount}
          setStep={setStep}
          checked={checked}
          setChecked={setChecked}
          bondDetails={bondDetails}
        />
      )}
      {step === 2 && (
        <Step2
          checked={checked}
          setChecked={setChecked}
          amount={amount}
          setStep={setStep}
          bondDetails={bondDetails}
        />
      )}
      {step === 3 && <Step3 amount={amount} onClose={onClose} bondDetails={bondDetails} />}
    </ElementBox>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  wallets: state.wallet.wallets
});

const mapDispatchToProps = (dispatch) => ({
  getAccWallets: () => dispatch(getAllWallets())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InvestmentSteps);
