import { compose } from "redux";
import DivElement from "../DivElement";
import ImgElement from "../ImgElement";
import TextWrapper from "../TextWrapper";
import { connect } from "react-redux";
import configs from "../../../constants/config";

const CoinLabel = (props) => {
  let { label, isDarkTheme, imgProps, textProps } = props;

  //   Styles for insufficient funds flow
  if (props.insufficientFunds) {
    imgProps = {
      width: "19px",
      widthSm: "16px",
      height: "19px",
      heightSm: "16px",
      marginRightSm: "6px"
    };
    textProps = {
      fontSize: "16px",
      fontSizeSm: "14px",
      lineHeight: "24px",
      lineHeightSm: "20px"
    };
  }
  return (
    <DivElement dAlignCenter height="100%">
      <ImgElement
        marginRight="10px"
        {...imgProps}
        src={`${isDarkTheme ? configs.darkCoinUrl : configs.lightCoinUrl
          }${label.toLowerCase()}.svg`}
      />
      <TextWrapper {...textProps}>{label}</TextWrapper>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(CoinLabel);
