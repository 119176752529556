import React, { memo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  BannerBox,
  BannerSection
} from "../../../../assets/StyleComponents/Exchange.style";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { RewardsBlockicon, RewardsHubIcon } from "../../../../assets/svgs";
import { HeadingSecond } from "../../../../assets/StyleComponents/FontSize/headsecond.style";
import { HeadingFourth } from "../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";

function PermotionBanner() {
  const MainElement = styled(BannerBox)`
    position: relative;
    display: flex;
    align-items: center;
    height: 330px;
    background: #15212a;
    border-radius: 10px;
    background-size: cover;
    padding: ${(props) => props.padding};
    overflow: hidden;
  `;
  let isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const history = useHistory();
  const { width } = useWindowSize();
  const prevLang = localStorage.getItem("ljs-lang");

  return (
    <MainElement 
      height="330px"
      heightMd="auto"
      padding="56px 40px"
      paddingLg="36px 24px"
      paddingMd="20px 16px"
    > 
      <BannerBox position="absolute" right="0" top="0" widthSm="60%" topSm="auto" bottom="0px" zIndex="1">
        <BannerBox position="relative" width="100%" height="100%">
          <RewardsHubIcon width={width > 767 ? "590px" : "250px"} height={width > 767 ? "330px" : "155px"} preserveAspectRatio="xMidYMid meet" />
        </BannerBox>
      </BannerBox>

      <BannerSection display="flex" direction="column" width="100%" gap="90px" gapMd="24px" position="relative" zIndex="2">
        <BannerBox display="flex" direction="column" width="100%" gap="8px">
          <HeadingSecond weight="500" size="44px" sizeLg="24px" sizeMd="16px" color="white">
            Get Rewarded at Each Step
          </HeadingSecond>
          <HeadingFourth weight="400" sizeLg="16px" sizeMd="12px" color="#CCCED0">
            {`Multiply Your Rewards`}
          </HeadingFourth>
        </BannerBox>

        <BannerBox>
          {isLoggedIn && 
            <ButtonComponent
              Primary
              padding="16px 32px"
              paddingLg="16px 32px"
              paddingMd="8px 16px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="8px"
              size="16px"
              sizeMd="14px"
              onClick={() => history.push(`/${prevLang}/rewards/bonus`)}
            >
              <RewardsBlockicon />
              My Rewards
            </ButtonComponent>
          }

          {!isLoggedIn && 
            <HeadingFourth color={"#fff"} sizeLg="16px" sizeMd="12px">
              Please sign in to view your rewards
            </HeadingFourth>
          }
        </BannerBox>
      </BannerSection>
    </MainElement>
  );
}

export default memo(PermotionBanner);
