/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getAntiPhisingCode } from "../../../../actions/Setting/setting";
import TwoFaModal from "./TwoFaModal";
import GenerateCode from "./GenerateCode";
import { formatAPIErrors } from "../../../../utils/general";
import { Skeleton } from "../../../Loader";
import DisableAntiPhisingModal from "./DisableAntiPhising";
import { showErrorMsg } from "../../../../utils/notification";
import { ExpandLessIcon, ExpandMoreIcon, EyeCloseIcon, EyeOpenIcon } from "../../../../assets/svgs";
import { AntiPhisingIllustration } from "../../../../assets/svgs/illustrations";
import { Button, DivElement, TextWrapper } from "../../../Reusable";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../../constants/styledConstants";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { Link } from "react-router-dom";
import { ChevronLeftBlueIcon } from "../../../../assets/svgs";
import {
  BannerHeadingSpan,
  MainElement,
  BannerSection
} from "../../../../assets/StyleComponents/Exchange.style";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { FormInput } from "../../../../assets/StyleComponents/Input.style";
import { Label } from "../../../../assets/StyleComponents/FontSize/para.style";

const QnA = ({ ques, children }) => {
  let [display, setDisplay] = useState(false);
  const { width } = useWindowSize();

  const isMobile = width <= styledConstants.BREAKING_POINT_MOBILE;

  return (
    <DivElement
      bdGrey
      borderRadius="5px"
      transition="all 0.3s ease"
      margin={isMobile ? "0 0 16px 0" : "12px 0 28px 0"}
      border={isMobile ? "1px solid" : null}
      padding={isMobile ? "14px 12px" : null}
      onClick={() => setDisplay(!display)}
      cursor={isMobile ? true : null}
    >
      <DivElement displayBtw>
        <TextWrapper fontSize="16px" fontWeight="bold">
          {ques}
        </TextWrapper>
        {isMobile && <Fragment>{display ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Fragment>}
      </DivElement>
      {((isMobile && display) || !isMobile) && (
        <TextWrapper fontSize="16px" fontWeight="300" marginTopMd="7px">
          {children}
        </TextWrapper>
      )}
    </DivElement>
  );
};

const AntiPhisingCode = (props) => {
  let { user, antiPhisingCode, isAntiPhisingFetching, error } = props;
  let [generateCode, setGenerateCode] = useState(false);
  let [twoFaModal, setTwoFaModal] = useState(false);
  let [disableModal, setDisableModal] = useState(false);
  let [isCodeVisible, setIsCodeVisible] = useState(false);
  const { width } = useWindowSize();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    props.getAntiPhisingCode();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      showErrorMsg(formatAPIErrors(error));
    }
    // eslint-disable-next-line
  }, [error]);

  const onGenerate = () => {
    if (user.twoFactorAuth.status) {
      setGenerateCode(true);
    } else {
      setTwoFaModal(true);
    }
  };

  return (
    <MainElement paddingMd="20px 16px" padding="0px 16px">
      {twoFaModal && <TwoFaModal onClose={() => setTwoFaModal(!twoFaModal)} />}

      {generateCode && <GenerateCode onClose={() => setGenerateCode(!generateCode)} />}

      {disableModal && <DisableAntiPhisingModal onClose={() => setDisableModal(!disableModal)} />}

      <BannerSection>
        <BannerBox width="100%">
          <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/security`} replace={true}>
            <ButtonComponent OutlineOnlyPrimary display="flex" alignItems="center">
              <ChevronLeftBlueIcon />
              Back
            </ButtonComponent>
          </Link>
        </BannerBox>

        <BannerBox display="flex" justifyContent="space-between" width="100%" directionLg="column">
          <BannerBox width="40%" widthLg="100%">
            <BannerBox position="relative" width="100%" mb="40px">
              <BannerHeading size={"24px"} sizeLg={"20px"} sizeMd={"18px"} mb={"8px"} weight="bold">
                Anti-phishing Code
              </BannerHeading>
              <BannerHeading txtGrey size={"16px"} sizeLg={"18px"} sizeMd={"12px"}>
                Protects your profile from frauds.
              </BannerHeading>
            </BannerBox>

            {width > 1025 && (
              <BannerBox>
                <QnA ques="What is an Anti-phishing code ?">
                  Safeguards your profile against fraudulent activities.
                </QnA>
                <QnA ques="How does it work ?">
                  You will create a unique code, and all communication from LCX will exclusively
                  contain that code.
                </QnA>
              </BannerBox>
            )}

            {!isAntiPhisingFetching ? (
              <Fragment>
                {antiPhisingCode.exists && (
                  // {true && (
                  <BannerBox>
                    <Label size="14px" weight="300" BodyColor>
                      Old Anti-phishing Code
                    </Label>
                    <BannerBox position="relative" mt="6px">
                      <FormInput
                        type={isCodeVisible ? "text" : "password"}
                        value={antiPhisingCode.code}
                      />
                      <BannerHeadingSpan
                        cursorPointer
                        position="absolute"
                        top="23%"
                        right="3%"
                        onClick={() => setIsCodeVisible(!isCodeVisible)}
                      >
                        {isCodeVisible ? <EyeOpenIcon /> : <EyeCloseIcon />}
                      </BannerHeadingSpan>
                    </BannerBox>
                    <BannerHeadingSpan size="14px" weight="300" BodyColor>
                      *We recommend changing code every 30 days*
                    </BannerHeadingSpan>
                  </BannerBox>
                )}

                <DivElement flex margin="30px 0px">
                  {antiPhisingCode.exists && (
                    <Button
                      secondaryRed
                      padding="0"
                      width="200px"
                      widthMd="176px"
                      height="48px"
                      marginRight="16px"
                      onClick={() => setDisableModal(true)}
                    >
                      Disable
                    </Button>
                  )}
                  <Button
                    primaryBlue
                    padding="0"
                    width="200px"
                    widthMd="176px"
                    height="48px"
                    onClick={() => onGenerate()}
                  >
                    {antiPhisingCode.exists ? "Change" : "Generate"} Code
                  </Button>
                </DivElement>
              </Fragment>
            ) : (
              <Skeleton style={{ width: "100%", height: "48px" }} />
            )}
          </BannerBox>
          <BannerBox width="60%" justifyContent="center" display="flex" widthLg="100%">
            <AntiPhisingIllustration />
          </BannerBox>

          {width < 1024 && (
            <BannerBox mt="24px">
              <QnA ques="What is an Anti-phishing code ?">
                Safeguards your profile against fraudulent activities.
              </QnA>
              <QnA ques="How does it work ?">
                You will create a unique code, and all communication from LCX will exclusively
                contain that code.
              </QnA>
            </BannerBox>
          )}
        </BannerBox>
      </BannerSection>
    </MainElement>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAntiPhisingCode: () => dispatch(getAntiPhisingCode())
});

const mapStateToProps = (state) => ({
  user: state.dashboard.userData,
  error: state.setting.error,
  antiPhisingCode: state.setting.antiPhising,
  isAntiPhisingFetching: state.setting.isAntiPhisingFetching
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(AntiPhisingCode);
