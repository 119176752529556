import { Fragment } from "react";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { DivElement, LinkButton, RouterLink, TextWrapper } from "../Reusable";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { changeTheme } from "../../actions/theme";
import WatchList from "./WatchList";
import {
  BurgerMenuIcon,
  CloseIcon,
  DarkModeIcon,
  LayoutIcon,
  LightModeIcon,
  SupportIcon
} from "../../assets/svgs";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import styledConstants from "../../constants/styledConstants";
import { tradeSetting } from "../../actions/trade";
import { performActionOnLayout } from "../../actions/trading";
import LanguageDropDown from "./LanguageDropDown";

const NavRightButtons = ({
  isDarkTheme,
  isLoggedIn,
  openHamburger,
  setOpenHamburger,
  changeTheme,
  tradeSetting,
  toggleChatSupport,
  actionsRequired
}) => {
  const { width } = useWindowSize();
  const location = useLocation();
  const path = location?.pathname;
  const dispatch = useDispatch();
  let prevLang = localStorage.getItem("ljs-lang");

  const isDesktop = width > styledConstants.BREAKING_POINT_TABLET;
  const isTablet = width <= styledConstants.BREAKING_POINT_TABLET;
  const isMobile = width <= styledConstants.BREAKING_POINT_MOBILE;
  const isNoActionRequired =
    !isLoggedIn || (isLoggedIn && actionsRequired && actionsRequired.length === 0);

  return (
    <Fragment>
      <DivElement height="100%" dAlignCenter flexDirection={isTablet ? "row-reverse" : "row"}>
        <DivElement
          height="100%"
          bdGrey
          borderLeft={isLoggedIn ? "1px solid" : null}
          borderRight={width > 1024 ? "1px solid" : null}
          marginRight={!isLoggedIn ? "12px" : null}
          padding={isLoggedIn ? "0 0 0 16px" : isTablet ? "0 0 0 40px" : "0px 16px"}
          paddingSm={"0 0 0 16px"}
          displayCenter
        >
          {!isMobile && <SupportIcon pointer marginRight="8px" onClick={toggleChatSupport} />}

          {!path.includes("/trade/") && isLoggedIn && isNoActionRequired && !isMobile && (
            <WatchList />
          )}

          {path.includes("/trade/") && isDesktop && (
            <LayoutIcon
              pointer
              margin="0 8px"
              onClick={() => dispatch(performActionOnLayout("CHANGE"))}
            />
          )}

          <DivElement
            displayCenter
            marginRight={isLoggedIn ? "16px" : ""}
            marginRightMd="0"
            marginLeft="8px"
            marginLeftSm="0"
            onClick={changeTheme}
            cursor
          >
            {isDarkTheme ? <LightModeIcon /> : <DarkModeIcon />}
          </DivElement>

          {!isLoggedIn && <LanguageDropDown />}

          {width <= 1024 && (
            <DivElement
              cursor
              displayCenter
              marginLeft={width > 500 ? "16px" : "5px"}
              onClick={() => setOpenHamburger(!openHamburger)}
            >
              {openHamburger ? <CloseIcon /> : <BurgerMenuIcon />}
            </DivElement>
          )}
        </DivElement>
        {!isLoggedIn && (
          <Fragment>
            {width > 1024 && !path.includes("/login") && !path.includes("/register") && (
              <RouterLink to={`${prevLang ? `/${prevLang}` : ""}/login`} primary>
                <TextWrapper fontSize="14px" lineHeight="20px">
                  Log in
                </TextWrapper>
              </RouterLink>
            )}
            {!path.includes("/register") && (
              <LinkButton
                to={`${prevLang ? `/${prevLang}` : ""}/register`}
                primaryBlue
                height="32px"
                padding={width > 373 ? "0 24px": "0 18px"}
                marginLeft={path.includes("/login") ? "" : "16px"}
                marginLeftSm="0"
              >
                Register
              </LinkButton>
            )}
            {path.includes("/register") && (
              <LinkButton
                to={`${prevLang ? `/${prevLang}` : ""}/login`}
                primaryBlue
                height="32px"
                padding="0 24px"
                marginLeft={path.includes("/register") ? "" : "16px"}
                nowrap
              >
                Log in
              </LinkButton>
            )}
          </Fragment>
        )}
      </DivElement>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  isLoggedIn: state.auth.isLoggedIn,
  isTradeSetting: state.trade.isTradeSetting,
  actionsRequired: state.auth.actionsRequired
});

const mapDispatchToProps = (dispatch) => ({
  changeTheme: () => dispatch(changeTheme()),
  tradeSetting: (status) => dispatch(tradeSetting(status))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(NavRightButtons);
