/* eslint-disable no-unused-vars */

/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "../../assets/svgs";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { AnchorTag, ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { ImgElement } from "../../components/Reusable";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";

const SliderComponent = (props) => {
  //FIXME: remove unused code, unused methods, comment out code, no extra next lines, no un used variable declearation
  // const slideImage= [ Robotics, AIIcon, IOTIcon, FIntech, LawTech, CyberSecurity, PostIcon];
  const [imgData, setImgData] = useState({
    startValue: 0,
    showItems: 3,
    slideImage: props?.onSliderData?.slideImage
  });
  const [presentItems, setPresentItems] = useState(0);
  // const [mobileView, setMobileView] = useState(false);
  // const [desktopView, setDesktopView] = useState(false);

  const { width } = useWindowSize();

  // Check screen Width
  const checkWidth = () => {
    if (width > 1024) {
      setImgData({
        ...imgData,
        showItems: 3
      });
      setPresentItems(3);
      // setMobileView(false);
      // setDesktopView(true);
    } else if (width > 767) {
      setImgData({
        ...imgData,
        showItems: 2
      });
      setPresentItems(2);
      // setMobileView(true);
      // setDesktopView(false);
    } else {
      setImgData({
        ...imgData,
        showItems: 1
      });
      setPresentItems(1);
      // setMobileView(true);
      // setDesktopView(false);
    }
  };

  useEffect(() => {
    checkWidth();
  }, [width]);

  // componentDidMount() {
  //   this.checkWidth();
  //   window.addEventListener("resize", this.checkWidth);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.checkWidth);
  // }

  const nextProperty = () => {
    if (imgData.showItems < imgData.slideImage.length) {
      // this.setState({
      //   imageData: {
      //     startValue: this.state.imageData.startValue + this.state.presentItems,
      //     showItems: this.state.imageData.showItems + this.state.presentItems,
      //     slideImage: this.state.imageData.slideImage
      //   }
      // });
      setImgData({
        startValue: imgData.startValue + presentItems,
        showItems: imgData.showItems + presentItems,
        slideImage: imgData.slideImage
      });
    }
  };

  const prevProperty = () => {
    if (imgData.startValue > 0) {
      setImgData({
        startValue: imgData.startValue - presentItems,
        showItems: imgData.showItems - presentItems,
        slideImage: imgData.slideImage
      });
    }
  };
  return (
    <BannerBox position="relative" maxWidth="100%" padding="1rem 0rem">
      <BannerBox display="flex">
        <BannerBox flex="1 1 670px">
          <BannerBox
            display="grid"
            gridCols="1fr 1fr 1fr"
            gridColsMd="1fr"
            gridColsLg="1fr 1fr"
            justifyContent="space-between"
            gap="25px"
            gridRows="1fr"
            width="100%"
            widthLg="96%"
            margin="0px auto"
          >
            {imgData?.slideImage.slice(imgData.startValue, imgData.showItems).map((imageData) => (
              <AnchorTag
                rel="noreferrer"
                // className="card exch-slider-card"
                Card
                key={imageData.id}
                href={imageData.LINK}
                target="_blank"
                maxWidth="100%"
                height="auto"
                paddBottom="2rem"
                // width="31.4%"
                // widthLg="100%"
              >
                {/* <div className="card exch-slider-card" > */}
                <ImgElement
                  width="100%"
                  height="222px"
                  maxHeight="250px"
                  borderTopLeftRadius="calc(.25rem - 1px)"
                  borderTopRightRadius="calc(.25rem - 1px)"
                  src={imageData.IMAGE}
                  alt="Card imageCap"
                />

                <BannerBox padding="2rem" flex="1">
                  <BannerHeading size="20px" sizeLg="18px" weight="bold" BodyColor>
                    {" "}
                    {imageData.TOPIC}{" "}
                  </BannerHeading>
                  <ParaTag size="18px" sizeLg="16px" weight="300" BodyColor mt="20px">
                    {" "}
                    {imageData.DESCRIPTION}{" "}
                  </ParaTag>
                </BannerBox>
                {/* </div> */}
              </AnchorTag>
            ))}
          </BannerBox>

          {imgData.startValue > 0 && (
            <BannerBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              LeftSidebar
              onClick={prevProperty}
            >
              <ChevronLeftIcon aria-hidden width="18px" name="prev" />
            </BannerBox>
          )}

          {imgData.showItems < imgData.slideImage.length && (
            <BannerBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              RightSidebar
              onClick={nextProperty}
            >
              <ChevronRightIcon aria-hidden name="next" width="18px" />
            </BannerBox>
          )}
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
};

export default withRouter(SliderComponent);
