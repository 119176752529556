import styled, { css } from "styled-components";
import { DivElement } from "../../../../../components/Reusable";

const ALL_FILTERS = [
  { label: "1 Day", value: "1" },
  { label: "1 Week", value: "7" },
  { label: "1 Month", value: "30" },
  { label: "3 Months", value: "90" },
  { label: "All time", value: "" }
];

const DaysFilter = styled.div`
  min-width: 66px;
  height: 24px;
  border: 1px solid ${(props) => props.theme.sepColor};
  color: ${(props) => props.theme.subText};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 8px;
  white-space: nowrap;

  @media (max-width: 767px) {
    margin: 0 6px;
  }

  &:hover {
    font-weight: 500;
    color: ${(props) => props.theme.primaryBlue};
    border: 1px solid ${(props) => props.theme.primaryBlue};
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: 500;
      color: ${(props) => props.theme.primaryBlue};
      border: 1px solid ${(props) => props.theme.primaryBlue};
    `}
`;

const OrdersFilter = ({ containerProps, selectedDaysFilter, setSelectedDaysFilter }) => {
  return (
    <DivElement {...containerProps}>
      <DivElement flex margin="0 -8px" marginSm="0 -6px">
        {ALL_FILTERS.map((filter) => (
          <DaysFilter
            onClick={() => setSelectedDaysFilter(filter.value)}
            active={selectedDaysFilter === filter.value ? true : null}
            key={filter.label}
          >
            {filter.label}
          </DaysFilter>
        ))}
      </DivElement>
    </DivElement>
  );
};

export default OrdersFilter;
