/**
 * Import Node Modules
 */
import React, { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { IMAGE_PREFIX } from "../../constants/Images/imageIndex";

/**
 * Import Other Modules
 */
import IndexFooter from "../../components/Footer/footer";
import { BannerBox, MainElement, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import { trackPage } from "../../tracking/track";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { SecurityFileIcon, SecurityLockIcon, ShieldIconBlue } from "../../assets/svgs";
import { ImgElement } from "../../components/Reusable";
import { Star_Password } from "../../assets/png";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";

const SecurityComponent = (props) => {
  let location = useLocation();
  const { width } = useWindowSize();

  useEffect(() => {
    let page = location.pathname + location.search;
    trackPage(page);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BannerBox position="relative" minHeight="100vh">
      {/* Main Part */}
      <MainElement>
        <BannerSection
          TutorialBg
          paddBottom="10rem"
          paddTop="4rem"
          paddBottomLg="3rem"
          paddTopLg="2rem"
        >
          <BannerBox ExchangeContainer position="relative">
            <BannerBox
              display="flex"
              direction="column"
              widthLg="90%"
              marginLg="0px auto"
              width="100%"
            >
              <BannerHeading
                weight="bold"
                textAlign="center"
                BodyColor
                sizeMd="36px"
                size="2.75rem"
              >
                We keep platform secure
              </BannerHeading>

              <BannerHeading
                size="1.5rem"
                BodyColor
                sizeMd="18px"
                weight="300"
                textAlign="center"
                mt="24px"
                mb="24px"
              >
                LCX understands your security concerns and maintains the security of the digital
                assets on LCX.
              </BannerHeading>
            </BannerBox>

            <BannerBox
              position="absolute"
              positionLg="relative"
              mt="3rem"
              width="100%"
              padding="auto 1rem"
              mtMd="18px"
            >
              <BannerBox
                flexWrapLg="wrap"
                display="flex"
                gapLg="30px"
                justifyContent="space-between"
                width="100%"
                flexWrap="wrap"
              >
                <BannerBox SecurityCard>
                  <SecurityFileIcon
                    margin={width > 1024 && "0 auto"}
                    display="block"
                    width={width > 1024 && "60px"}
                    height={width > 1024 && "60px"}
                    widthMd="26px"
                    fill="transparent"
                  />
                  <BannerHeading
                    weight="300"
                    textAlign="center"
                    mt={width > 1024 && "30px"}
                    BodyColor
                    size="1.5rem"
                    mtMd="0px"
                    sizeMd="18px"
                  >
                    {" "}
                    Regulated Platform{" "}
                  </BannerHeading>
                </BannerBox>

                <BannerBox SecurityCard>
                  <ShieldIconBlue
                    margin={width > 1024 && "0 auto"}
                    display="block"
                    width={width > 1024 && "60px"}
                    height={width > 1024 && "60px"}
                    widthMd="26px"
                  />
                  <BannerHeading
                    weight="300"
                    textAlign="center"
                    mt={width > 1024 && "30px"}
                    BodyColor
                    size="1.5rem"
                    mtMd="0px"
                    sizeMd="18px"
                  >
                    {" "}
                    Account Authentication{" "}
                  </BannerHeading>
                </BannerBox>

                <BannerBox SecurityCard>
                  <SecurityLockIcon
                    margin={width > 1024 && "0 auto"}
                    display="block"
                    width={width > 1024 && "60px"}
                    height={width > 1024 && "60px"}
                    widthMd="26px"
                  />
                  <BannerHeading
                    weight="300"
                    textAlign="center"
                    mt={width > 1024 && "45px"}
                    BodyColor
                    size="1.5rem"
                    mtMd="0px"
                    sizeMd="18px"
                  >
                    {" "}
                    Wallet Security{" "}
                  </BannerHeading>
                </BannerBox>

                <BannerBox SecurityCard>
                  <ImgElement
                    src={Star_Password}
                    alt="document"
                    margin={width > 1024 && "0 auto"}
                    display="block"
                    width={width > 1024 ? "150px" : "24px"}
                    height={width > 1024 ? "60px" : "24px"}
                  />
                  <BannerHeading
                    weight="300"
                    textAlign="center"
                    mt={width > 1024 && "45px"}
                    BodyColor
                    size="1.5rem"
                    mtMd="0px"
                    sizeMd="18px"
                  >
                    {" "}
                    Access Control{" "}
                  </BannerHeading>
                </BannerBox>
              </BannerBox>
            </BannerBox>
          </BannerBox>
        </BannerSection>

        <BannerSection
          BeginerBg
          paddTop="14rem"
          paddBottom="10rem"
          paddTopLg="4rem"
          paddBottomLg="32px"
          paddTopMd="2.5rem"
        >
          <BannerBox ExchangeContainer pb="3rem">
            <BannerHeading
              BodyColor
              weight="500"
              sizeMd="1.125rem"
              sizeLg="2.25rem"
              size="2.75rem"
              textAlign="center"
              margin="20px 0px"
            >
              {" "}
              Account Security{" "}
            </BannerHeading>

            <BannerBox
              display="flex"
              directionLg="column"
              pt="35px"
              ptMd="0px"
              width="100%"
              ptLg="16px"
            >
              <BannerBox
                pt="16px"
                width="50%"
                widthLg="100%"
                display="flex"
                justifyContentLg="center"
                direction="column"
              >
                <BannerBox display="flex" justifyContent="center" pt="1rem">
                  <BannerBox ExchShowLine></BannerBox>
                  <BannerHeading ml="12px" size="20px" BodyColor weight="300" sizeMd="16px">
                    Mandatory Email Verification provides an additional layer of security to help
                    prevent malicious attempts such as account theft and data theft.
                  </BannerHeading>
                </BannerBox>

                <BannerBox display="flex" justifyContent="center" pt="1rem">
                  <BannerBox ExchShowLine></BannerBox>
                  <BannerHeading ml="12px" size="20px" BodyColor weight="300" sizeMd="16px">
                    Transactions are secured by 2FA, which requires users to enter a verification
                    code when withdrawing funds or changing account security settings.
                  </BannerHeading>
                </BannerBox>
              </BannerBox>

              <BannerBox mt="15px" width="50%" widthLg="100%">
                <ImgElement
                  src={IMAGE_PREFIX.SecurityAccountIcon}
                  alt=""
                  margin={width > 1024 && "0 auto"}
                  display="block"
                />

                {/* <SecurityAccountIcon width="100%" height="100%"/> */}
              </BannerBox>
            </BannerBox>
          </BannerBox>

          <BannerBox ExchangeContainer pb="3rem" pt="5rem" ptLg="32px">
            <BannerHeading
              BodyColor
              weight="500"
              sizeMd="1.125rem"
              sizeLg="2.25rem"
              size="2.75rem"
              textAlign="center"
            >
              {" "}
              Wallet Security{" "}
            </BannerHeading>

            <BannerBox
              display="flex"
              directionLg="column"
              pt="35px"
              ptMd="0px"
              width="100%"
              ptLg="16px"
            >
              <BannerBox mt="15px" width="50%" widthLg="100%">
                <ImgElement
                  src={IMAGE_PREFIX.SecurityWalletIcon}
                  alt=""
                  margin={width > 1024 && "0 auto"}
                  display="block"
                />
              </BannerBox>

              <BannerBox
                pt="16px"
                width="50%"
                widthLg="100%"
                display="flex"
                justifyContent="center"
                direction="column"
              >
                <BannerBox display="flex" justifyContent="center" pt="1rem">
                  <BannerBox ExchShowLine></BannerBox>
                  <BannerHeading ml="12px" size="20px" BodyColor weight="300" sizeMd="16px">
                    The large proportion of your digital assets are kept in our offline, air-gapped
                    Cold Storage system. Only a small percentage of digital assets are safely stored
                    in hot wallets.
                  </BannerHeading>
                </BannerBox>
                <BannerBox display="flex" justifyContent="center" pt="1rem">
                  <BannerBox ExchShowLine></BannerBox>
                  <BannerHeading ml="12px" size="20px" BodyColor weight="300" sizeMd="16px">
                    Over 90% of digital assets stored with LCX are stored in a cold wallet, which is
                    not connected to the Internet. To ensure the security of the cold wallet, LCX
                    uses hardware security modules (HSM) and multi-signature technologies.
                  </BannerHeading>
                </BannerBox>
                <BannerBox display="flex" justifyContent="center" pt="1rem">
                  <BannerBox ExchShowLine></BannerBox>
                  <BannerHeading ml="12px" size="20px" BodyColor weight="300" sizeMd="16px">
                    Only 10% of digital assets are stored in the hot wallet for day-to-day
                    operations, our hot wallets are secured with multiple technical solutions to
                    ensure maximum protection and safety.
                  </BannerHeading>
                </BannerBox>
              </BannerBox>
            </BannerBox>
          </BannerBox>

          <BannerBox ExchangeContainer pb="3rem" pt="5rem" ptLg="32px">
            <BannerHeading
              BodyColor
              weight="500"
              sizeMd="1.125rem"
              sizeLg="2.25rem"
              size="2.75rem"
              textAlign="center"
            >
              {" "}
              Compliance and Regulations{" "}
            </BannerHeading>

            <BannerBox
              display="flex"
              directionLg="column"
              pt="35px"
              ptMd="0px"
              width="100%"
              ptLg="16px"
              gap="50px"
            >
              <BannerBox
                pt="16px"
                width="100%"
                widthLg="100%"
                display="flex"
                justifyContent="center"
                direction="column"
              >
                <BannerBox display="flex" justifyContent="center" pt="1rem" textAlign="center">
                  {/* <BannerBox ExchShowLine></BannerBox> */}
                  <BannerHeading size="20px" BodyColor weight="300" sizeMd="16px">
                    LCX AG is a Liechtenstein based company that undergoes regular audits conducted
                    by the Financial Market Authority of Liechtenstein. LCX is registered under the
                    registration No. 288159 as a trusted technology service provider.
                  </BannerHeading>
                </BannerBox>
              </BannerBox>
            </BannerBox>
          </BannerBox>

          <BannerBox ExchangeContainer pb="3rem" pt="5rem" ptLg="32px">
            <BannerHeading
              BodyColor
              weight="500"
              sizeMd="1.125rem"
              sizeLg="2.25rem"
              size="2.75rem"
              textAlign="center"
            >
              {" "}
              Access Control{" "}
            </BannerHeading>

            <BannerBox
              display="flex"
              directionLg="column"
              pt="35px"
              ptMd="0px"
              width="100%"
              ptLg="16px"
              gap="50px"
            >
              <BannerBox
                pt="16px"
                width="100%"
                widthLg="100%"
                display="flex"
                justifyContent="center"
                direction="column"
              >
                <BannerBox
                  display="flex"
                  justifyContent="flex-start"
                  pt="1rem"
                  textAlign="start"
                  textAlignLg="center"
                >
                  {/* <BannerBox ExchShowLine></BannerBox> */}
                  <BannerHeading size="20px" BodyColor weight="300" sizeMd="16px">
                    Multiple signatures authorisation: To transfer cryptocurrency from our cold
                    storage system multiple signatories will be required.
                  </BannerHeading>
                </BannerBox>
                <BannerBox
                  display="flex"
                  justifyContent="flex-start"
                  pt="1rem"
                  textAlign="start"
                  textAlignLg="center"
                >
                  {/* <BannerBox ExchShowLine></BannerBox> */}
                  <BannerHeading size="20px" BodyColor weight="300" sizeMd="16px">
                    No Single Point of Failure: All private keys are stored offsite and
                    geographically distributed in monitored and access-controlled facilities.
                  </BannerHeading>
                </BannerBox>
                <BannerBox
                  display="flex"
                  justifyContent="flex-start"
                  pt="1rem"
                  textAlign="start"
                  textAlignLg="center"
                >
                  {/* <BannerBox ExchShowLine></BannerBox> */}
                  <BannerHeading size="20px" BodyColor weight="300" sizeMd="16px">
                    Remote Access: All remote-access necessitates public-key authentication via
                    passwords saved on hardware tokens.
                  </BannerHeading>
                </BannerBox>
              </BannerBox>
            </BannerBox>
          </BannerBox>
        </BannerSection>
        {/* <hr style={{ margin: "0px auto" }} /> */}
      </MainElement>

      {/* Footer Index */}
      <IndexFooter {...props} />
    </BannerBox>
  );
};

export default withRouter(SecurityComponent);
