import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { LoadingButton } from "../../../../../../../components/Loader";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { showErrorMsg, showSuccessMsg } from "../../../../../../../utils/notification";
import { formatAPIErrors } from "../../../../../../../utils/general";
import { verifyAccessCode } from "../../../../../../../lib/api";
import {
  Button,
  DivElement,
  LinkButton,
  TextWrapper
} from "../../../../../../../components/Reusable";
import { TextInput } from "../../../../../../../components/Reusable/FormInput";
import Field from "../../../../../../../components/Reusable/Field";

const BeneficiarySchema = Yup.object().shape({
  accessCode: Yup.string().required("Please Enter Access Code")
});

const PrivateStep1 = ({ isPrivate, saleDetails, onVerifyAccessCode }) => {
  const [loader, setLoader] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const params = useParams();
  let prevLang = localStorage.getItem("ljs-lang");

  const getInitialValue = () => {
    return { accessCode: "" };
  };

  const verifyAccess = (values, { resetForm }) => {
    setLoader(true);
    let data = {
      accessCode: values.accessCode.toUpperCase(),
      tokenSaleId: params?.id
    };

    if (!isPrivate) {
      data.tokenSaleId = params?.id;
    }

    verifyAccessCode(data, isPrivate)
      .then((res) => {
        showSuccessMsg("Successfully verified access code.");
        onVerifyAccessCode(res?.data?.data, data.accessCode);
      })
      .catch((error) => {
        showErrorMsg(formatAPIErrors(error));
      })
      .finally(() => {
        resetForm(getInitialValue());
        setLoader(false);
      });
  };

  return (
    <DivElement width="100%">
      <TextWrapper fontSize="20px" fontWeight="700" lineHeight="24px">
        {isPrivate ? "Enter Access Code" : `Buy ${saleDetails?.coin}`}
      </TextWrapper>
      <DivElement marginTop="56px" marginBottom="16px">
        <Formik
          initialValues={getInitialValue()}
          validationSchema={BeneficiarySchema}
          onSubmit={verifyAccess}
        >
          {() => (
            <Form>
              <TextInput
                title="Enter Access Code"
                type="text"
                name="accessCode"
                disabled={!isLoggedIn ? true : false}
                containerProps={{ width: "100%" }}
              />
              <Field />

              {isLoggedIn ? (
                <Button
                  type="submit"
                  primaryGreen
                  width="100%"
                  height="48px"
                  disabled={
                    loader || !isLoggedIn || (!isPrivate && saleDetails.status !== "ONGOING")
                  }
                >
                  {loader ? (
                    <LoadingButton color="#fff" />
                  ) : isPrivate || saleDetails?.status === "ONGOING" ? (
                    "Participate"
                  ) : saleDetails?.status === "UPCOMING" ? (
                    "Sale has not started yet"
                  ) : (
                    "Sale Ended"
                  )}
                </Button>
              ) : (
                <LinkButton to={`${prevLang ? `/${prevLang}` : ""}/login`} width="100%" height="48px" secondaryBlue>
                  Log in or Register
                </LinkButton>
              )}
            </Form>
          )}
        </Formik>
      </DivElement>
    </DivElement>
  );
};

export default PrivateStep1;
