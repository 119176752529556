import React from "react";
import { numberWithCommas } from "../../../../../utils/general";
import {
  Animation,
  DivElement,
  ModalWrapper,
  TextWrapper,
  Button
} from "../../../../../components/Reusable";
// import { ArrowLeftIcon } from "../../../../../assets/svgs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";
import { useSelector } from "react-redux";

const Step4 = ({
  step,
  saleDetails,
  successfulPurchaseAmt,
  onBackHandler,
  purchaseAmt,
  paymentAmt,
  purchaseFee,
  selectedCoinRate
}) => {
  const presetdate = new Date();
  const prevLang = localStorage.getItem("ljs-lang");

  const DETAILS = [
    { type: "Amount", value: `${numberWithCommas(+purchaseAmt)} ${saleDetails?.coin}` },
    {
      type: "Currency to be paid",
      value: `${numberWithCommas(+paymentAmt)} ${selectedCoinRate?.coin}`
    },
    {
      type: "Fee (1%)",
      value: `${numberWithCommas(+purchaseFee)} ${selectedCoinRate?.feeCoin || "LCX"}`
    },
    {
      type: "Date",
      value: String(presetdate).slice(4, 21).replace(" ", "-")
    }
  ];
  const history = useHistory();
  const userData = useSelector(state => state.dashboard.userData);
  return (
    <ModalWrapper>
      <DivElement ModalContainer className="modal-container">
        <DivElement displayCenter marginBottom="10px">
          <DivElement maxWidth="332px">
            <BannerBox display="flex" alignItems="center" justifyContent="center">
              <Animation type={step === 4 ? "SUCCESS" : "FAILURE"} width="50%" />
            </BannerBox>
            <TextWrapper
              textAlign="center"
              fontWeight="500"
              fontSize="20px"
              green={step === 4 && "SUCCESS"}
              red={step === 4 && "FAILURE"}
            >
              Investment {step === 4 ? "Confirmed" : "Failed"}
            </TextWrapper>
          </DivElement>
        </DivElement>

        {DETAILS.map((data, i) => {
          return (
            <DivElement key={i} flexJustifyBtwAlignCenter padding="8px 0" BorderBottom>
              <TextWrapper fontSizeMd="12px">{data.type}</TextWrapper>
              <TextWrapper fontSizeMd="12px">{data.value}</TextWrapper>
            </DivElement>
          );
        })}
        <DivElement flexJustifyCenter>
          <Button
            primaryBlue
            marginTop="20px"
            width="100%"
            height="48px"
            onClick={() => history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/token-sale`)}
          >
            Done
          </Button>
        </DivElement>
      </DivElement>
    </ModalWrapper>
  );
};

export default Step4;
