/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { uploadSelfie } from "../../../../lib/api";
import { getUserDetails } from "../../../../actions/dashboard";
import { formatAPIErrors } from "../../../../utils/general";
import Bioid from "../Bioid";
import "../bws.capture";
// import SelfieUploadGuide from "../SelfieUploadGuide";

import { dataURLtoFile } from "../../../../utils";
import KycStepButton from "./KycStepBtn";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import {
  CameraBlueIcon,
  CheckIcon,
  ChevronLeftIcon,
  CloseRedIcon,
  MobileWhiteIcon
} from "../../../../assets/svgs";
// import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { KycDivBox } from "../../../../assets/StyleComponents/kyc.style";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { HeadingFourth } from "../../../../assets/StyleComponents/FontSize/headingFourth.style";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
// import { FaceKYC } from "../../../../assets/png";
import { ImgElement } from "../../../Reusable";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { HeadingSix } from "../../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { LoadingButton } from "../../../Loader";
import styled from "styled-components";
import KYCLightVideo from "../../../../assets/videos/LightKYCgirl.mp4";
import KYCDarkVideo from "../../../../assets/videos/DarkKYCgirl.mp4";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import BarcodeSection from "./NewFlows/BarcodeSection";

const KycStep3 = ({ getUserData, isTheme, setSteps, steps, userData }) => {
  const [firstImage, setFirstImage] = useState("");
  const [secondImage, setSecondImage] = useState("");
  const [firstImageUpload, setFirstImageUpload] = useState("");
  const [secondImageUpload, setSecondImageUpload] = useState("");
  // const [loader, setLoader] = useState(false);
  const [openBarCode, setOpenBarCode] = useState(false);
  const [openWebcam, setOpenWebcam] = useState(false);
  const [bioid, setBioid] = useState(false);
  const { width } = useWindowSize();
  // const [openGuide, setOpenGuide] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState("notStarted");
  const [statusofKYC, setStatusofKYC] = useState("notStarted");

  useEffect(() => {
    if (!localStorage.getItem("selfie_instruction")) {
      localStorage.setItem("selfie_instruction", "YES");
    }

    // eslint-disable-next-line
  }, []);

  const submitData = () => {
    // setLoader(true);
    // onCapture();
    uploadSelfie(firstImageUpload, secondImageUpload, "SELFIE", (progress) => {})
      .then((res) => {
        setFirstImageUpload("");
        setSecondImageUpload("");
        getUserData();
        showSuccessMsg(res?.data?.message);
        localStorage.removeItem("selfie_instruction");
      })
      .catch((error) => {
        setStatusofKYC("failed");
        setOpenWebcam(false);
        showErrorMsg(formatAPIErrors(error));
      });
  };

  const bioid_completed = (first, second) => {
    if (!first || !second) return;
    setLoading("processing");
    setStatusofKYC("processing");
    const firstFile = dataURLtoFile(first, "firstImage.png");
    const secondFile = dataURLtoFile(second, "secondImage.png");
    setFirstImage(first);
    setSecondImage(second);
    setFirstImageUpload(firstFile);
    setSecondImageUpload(secondFile);
    setBioid(false);
    // submitData();
    // setSteps(3);
  };
  useEffect(() => {
    if (firstImageUpload && secondImageUpload) submitData();
  }, [firstImageUpload, secondImageUpload]);

  const onCapture = () => {
    // const open = localStorage.getItem("selfie_instruction");
    // if (open === "YES") {
    //   setOpenGuide(1);
    // } else {
    setBioid(true);
    // }
  };

  const ImageScanningCss = styled(BannerBox)`
    position: relative;
    &::after {
      content: "";
      background: linear-gradient(90deg, #f37335 0%, #fdc830 50%, #f37335 100%);
      width: 170px;
      height: 4px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: -8%;
      bottom: 0;
      margin: auto;
      opacity: 7;
      z-index: 2;
      animation: 2s infinite ease-in-out roll;
      box-shadow: 0px 0px 8px 0px #fdc83080;
      box-shadow: 0px 0px 3px 0px #fdc830 inset;

      @keyframes roll {
        0% {
          transform: translateY(-70px);
        }
        10% {
          transform: translateY(-40px);
        }
        20% {
          transform: translateY(10px);
        }
        30% {
          transform: translateY(40px);
        }
        40% {
          transform: translateY(70px);
        }
        50% {
          transform: translateY(80px);
        }
        60% {
          transform: translateY(70px);
        }
        70% {
          transform: translateY(40px);
        }
        80% {
          transform: translateY(10px);
        }
        90% {
          transform: translateY(-40px);
        }
        100% {
          transform: translateY(-70px);
        }
      }

      @media (max-width: 1024px) {
        left: -2%;
      }
      @media (max-width: 767px) {
        width: 100px;

        @keyframes roll {
          0% {
            transform: translateY(-50px);
          }
          25% {
            transform: translateY(20px);
          }
          50% {
            transform: translateY(60px);
          }
          60% {
            transform: translateY(60px);
          }
          75% {
            transform: translateY(20px);
          }
          100% {
            transform: translateY(-50px);
          }
        }
      }
    }
  `;

  return (
    <>
      {statusofKYC === "notStarted" && (
        <BannerBox display="flex" gap="5px">
          {bioid && (
            <ButtonComponent
              bck="transparent"
              onClick={() => {
                setBioid(false);
                setOpenWebcam(false);
              }}
            >
              <ChevronLeftIcon />
            </ButtonComponent>
          )}
          <BannerBox>
            <HeadingFourth size="18px" weight="500">
              Face Verification{" "}
            </HeadingFourth>
            <ParaTag size="14px" weight="500" mt="6px">
              Face enclosed in circle, find balanced light and remove any glasses or hats.
            </ParaTag>
          </BannerBox>
        </BannerBox>
      )}

      {statusofKYC === "failed" && (
        <BannerBox display="flex" gap="5px" direction="column">
          <HeadingFourth size="18px" weight="500" txtDanger>
            Issues detected{" "}
          </HeadingFourth>
          <ParaTag size="14px" weight="500" mt="6px" txtDanger>
            Try again! Blurry selfie.
          </ParaTag>
        </BannerBox>
      )}
      {statusofKYC === "processing" && (
        <BannerBox display="flex" gap="5px" direction="column">
          <HeadingFourth size="18px" weight="500">
            Processing Selfie <LoadingButton color={!isTheme && "#012243"} />
          </HeadingFourth>
          <ParaTag size="14px" weight="500" mt="6px">
            Wait as we process and review your selfie securely.
          </ParaTag>
        </BannerBox>
      )}
      {!openWebcam && !bioid && (
        <Fragment>
          <div>
            {!(firstImage && secondImage) && (
              // <KycDivBox KYC3>
              //   <div className="kycS3__container">
              //     <div className="kycS3__uploader__img" onClick={() => onCapture()}>
              //       <CameraBlueIcon />
              //     </div>
              //     <div className="kycS3__uploader__title">Use Web Camera</div>
              //   </div>
              // </KycDivBox>
              <>
                <BannerBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  margin="24px 0px"
                >
                  {!isTheme ? (
                    <video
                      autoPlay
                      loop
                      muted
                      preload="metadata"
                      type="video/mp4"
                      style={{ width: "250px", height: "286px", objectFit: "cover" }}
                      src={KYCLightVideo}
                      // eslint-disable-next-line react/no-unknown-property
                      playsinline
                    ></video>
                  ) : (
                    <video
                      autoPlay
                      loop
                      muted
                      preload="metadata"
                      type="video/mp4"
                      style={{ width: "250px", height: "286px", objectFit: "cover" }}
                      src={KYCDarkVideo}
                      // eslint-disable-next-line react/no-unknown-property
                      playsinline
                    ></video>
                  )}
                </BannerBox>
              </>
            )}

            {firstImage && secondImage && (
              <BannerBox
                KYC3Capture
                style={{ marginTop: "32px" }}
                display="flex"
                gap="40px"
                alignItems="center"
                justifyContent="center"
                PrimaryCardDottedBorder={statusofKYC === "processing"}
                DangerCardDottedBorder={statusofKYC === "failed"}
                padding="24px"
                directionMd="column"
                gapMd="32px"
              >
                <BannerBox
                  className="kycS3__captured__container"
                  gap="8px"
                  display="flex"
                  direction="column"
                >
                  <HeadingSix size="14px" weight="500" mb="8px">
                    Selfie 1
                  </HeadingSix>

                  {statusofKYC === "processing" && (
                    <ImageScanningCss className="kycS3__captured__img">
                      <ImgElement
                        src={firstImage}
                        alt="selfie1"
                        width="150px"
                        height="150px"
                        ObjectFitSm="contain"
                      />
                    </ImageScanningCss>
                  )}

                  {statusofKYC !== "processing" && (
                    <BannerBox className="kycS3__captured__img">
                      <ImgElement
                        src={firstImage}
                        alt="selfie1"
                        width="150px"
                        height="150px"
                        ObjectFitSm="contain"
                      />
                    </BannerBox>
                  )}

                  <BannerBox display="flex" gap="5px" alignItems="center">
                    {statusofKYC === "failed" && (
                      <>
                        <CloseRedIcon />
                        <BannerHeading txtDanger>Failed !</BannerHeading>
                      </>
                    )}
                    {statusofKYC === "success" && (
                      <>
                        <CheckIcon />
                        <BannerHeading txtSuccess>Looks Good!</BannerHeading>
                      </>
                    )}
                  </BannerBox>
                </BannerBox>

                <BannerBox
                  className="kycS3__captured__container"
                  gap="8px"
                  display="flex"
                  direction="column"
                >
                  <HeadingSix size="14px" weight="500" mb="8px">
                    Selfie 2
                  </HeadingSix>

                  {statusofKYC === "processing" && (
                    <ImageScanningCss className="kycS3__captured__img">
                      <ImgElement
                        src={secondImage}
                        alt="selfie2"
                        width="150px"
                        height="150px"
                        ObjectFitSm="contain"
                      />
                    </ImageScanningCss>
                  )}

                  {statusofKYC !== "processing" && (
                    <BannerBox className="kycS3__captured__img">
                      <ImgElement
                        src={secondImage}
                        alt="selfie2"
                        width="150px"
                        height="150px"
                        ObjectFitSm="contain"
                      />
                    </BannerBox>
                  )}

                  <BannerBox display="flex" gap="5px" alignItems="center">
                    {statusofKYC === "failed" && (
                      <>
                        <CloseRedIcon />
                        <BannerHeading txtDanger>Failed !</BannerHeading>
                      </>
                    )}

                    {statusofKYC === "success" && (
                      <>
                        <CheckIcon />
                        <BannerHeading txtSuccess>Looks Good!</BannerHeading>
                      </>
                    )}
                  </BannerBox>
                </BannerBox>
              </BannerBox>
            )}
            {!firstImage && !secondImage && (
              <BannerBox border="0px" mb="32px">
                <BannerHeading
                  size="14px"
                  weight="500"
                  PrimaryCard
                  textAlign="center"
                  padding="2px 16px"
                  width="266px"
                  margin="0px auto"
                >
                  Pro Tip -Nod your head up and down
                </BannerHeading>
              </BannerBox>
            )}
            {!firstImage && !secondImage && (
              <KycStepButton onClick={() => onCapture()} label="Start" />
            )}
            {firstImage && secondImage && statusofKYC === "failed" && !openBarCode && (
              <BannerBox>
                {width > 767 && (
                  <ButtonComponent
                    height="48px"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="8px"
                    position="relative"
                    mt="32px"
                    onClick={() => setOpenBarCode(true)}
                  >
                    <BannerHeadingSpan
                      RadioBtnIconBg
                      ml="6px"
                      height="20px"
                      txtPrimary
                      padding="0px 6px 2px 6px"
                      BorderRadius="0px 5px 0px 5px"
                      position="absolute"
                      top="0px"
                      right="0px"
                      size="12px"
                      weight="500"
                    >
                      recommended
                    </BannerHeadingSpan>
                    <MobileWhiteIcon />
                    Continue with mobile app
                  </ButtonComponent>
                )}

                <ButtonComponent
                  height="48px"
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="8px"
                  OutlinePrimaryWithoutHover
                  mt="24px"
                  onClick={() => onCapture()}
                >
                  <CameraBlueIcon />
                  {statusofKYC === "failed" ? "Re-take photo" : "Take photo using webcam"}
                </ButtonComponent>
              </BannerBox>
            )}
          </div>
        </Fragment>
      )}

      {openBarCode && (
        <BarcodeSection
          setState={setOpenBarCode}
          backbtn="no"
          setSteps={setSteps}
          steps={steps}
          userData={userData}
        />
      )}

      {openBarCode && (
        <ButtonComponent
          width="100%"
          OutlinePrimary
          margin="20px 0px"
          onClick={() => {
            setStatusofKYC("notStarted");
            setOpenBarCode(false);
            setOpenWebcam(false);
            setBioid(false);
            setFirstImage("");
            setSecondImage("");
          }}
        >
          Back To Start
        </ButtonComponent>
      )}

      {bioid && (
        <KycDivBox KYCBioIdContainer>
          <Bioid bioid_completed={bioid_completed} bioid_cancel={() => setBioid(false)} />
        </KycDivBox>
      )}
    </>
  );
};

const maptoStateProps = (state) => ({
  userData: state.dashboard.userData,
  isTheme: state.theme.isTheme
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: () => dispatch(getUserDetails())
});

export default compose(connect(maptoStateProps, mapDispatchToProps))(KycStep3);
