import React, { useEffect, useRef } from "react";
import Lottie from "lottie-web";

import rewardsBoxAnimation from "./OpenBoxAnimation.json";

const OpenBoxAnimation = ({ type, width, onComplete }) => {
  const containerRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      animationRef.current = Lottie.loadAnimation({
        container: document.querySelector(`#animation-${type}`),
        animationData: rewardsBoxAnimation,
        renderer: "svg",
        loop: false,
        autoplay: true,
        path: rewardsBoxAnimation
      });
      // Listen for completion
      animationRef.current.addEventListener('complete', () => {
        // Remove the container from DOM
        if (containerRef.current) {
          containerRef.current.remove();
          onComplete();
          animationRef.current = null;
        }
      });
    }

    // Cleanup
    return () => {
      if (animationRef.current) {
        onComplete();
        animationRef.current.destroy();
        animationRef.current = null;
      }
    };
  }, []);

  return <div ref={containerRef} id={`animation-${type}`} style={{ width: width ? width : "100%" }} />;
};

export default OpenBoxAnimation;
