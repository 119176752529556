import styled, { css } from "styled-components";
import { SearchIcon } from "../../../assets/svgs";

const Container = styled.div`
  height: 28px;
  position: relative;
  display: flex;
  align-items: center;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "fit-content")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  border-bottom: 1px solid ${(props) => (props.theme.isDarkTheme ? "#8c9797" : "#969799")};

  ${props => props.fullBorder && css`
    border: 1px solid ${props.theme.borderColor};
    border-radius: 5px;
    input{
      padding: 0 8px 0;
    }
  `};
  @media (max-width: 1024px) {
    max-width: ${(props) => (props.maxWidthMd ? props.maxWidthMd : "")};
  }
  @media (max-width: 767px) {
    max-width: ${(props) => (props.maxWidthSm ? props.maxWidthSm : "")};
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 4px;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 12px;
  background: transparent;
  color: ${(props) => (props.theme.isDarkTheme ? "#dedfe0" : "#012243")};

  ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    font-size: ${(props) => (props.pFontSize ? props.pFontSize : "")};
    font-weight: ${(props) => (props.pFontWeight ? props.pFontWeight : "")};
    color: ${(props) => (props.theme.isDarkTheme ? "#bbbdbf" : "#706f6f")};
  }
`;

const SearchBar = (props) => {
  return (
    <Container {...props}>
      <Input
        {...props.inputProps}
        value={props.searchQuery}
        onChange={(e) => props.setSearchQuery(e.target.value)}
      />
      <SearchIcon width="16px" height="16px" marginBottom="12px" {...props.imgProps} />
    </Container>
  );
};

export default SearchBar;
