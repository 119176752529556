/* eslint-disable no-debugger */
/* eslint-disable no-console */
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getKycContent } from "../../actions/kyc";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { KycStep1, KycStep2, KycStep3 } from "../../components/Modules/KycLastLevel";
import { SubmittedModal } from "../../components/Modules/Kyc";
import { withRouter } from "react-router-dom";
import { DivElement } from "../../components/Reusable";
import { HeadingFourth } from "../../assets/StyleComponents/FontSize/headingFourth.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { showErrorMsg } from "../../utils/notification";

const KycLastProcess = ({ kycContent, getKycContent, userData, history, kycContentLoader }) => {
  const [step, setStep] = useState(1);
  const [openGuidlines, setOpenGuidlines] = useState(false);
  const [isKycSubmitted, setIsKycSubmitted] = useState(false);
  const { width } = useWindowSize();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    // stopPageLoader();
    if (!kycContent) {
      let data = {
        type: "KYC",
        product: "FAQ",
        key: "STEP"
      };
      getKycContent(data);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (width >= 1024 && openGuidlines) {
      setOpenGuidlines(false);
    }
    // eslint-disable-next-line
  }, [width]);

  useEffect(() => {
    if (userData) {
      changeStep();
    }
    // eslint-disable-next-line
  }, [userData]);

  const KYCHighRisk = userData.KYC.isHighRiskCountry;
  // let Access = false;

  const changeStep = () => {
    if (
      (userData["KYC"] &&
        userData["KYC"]["transactionRiskInfo"] &&
        (((userData.KYC.STATUS === "LEVEL0" || userData.KYC.STATUS === "LEVEL1") &&
          userData.KYC.LEVEL2.status === "PARTIALLY_SUBMITTED") ||
          ((userData.KYC.STATUS === "LEVEL0" || userData.KYC.STATUS === "LEVEL1") &&
            userData.KYC.LEVEL2.status === "APPROVED") ||
          ((userData.KYC.STATUS === "LEVEL0" || userData.KYC.STATUS === "LEVEL1") &&
            userData.KYC.LEVEL2.status === "SUBMITTED"))) ||
      (userData.KYC.STATUS === "LEVEL2" && !KYCHighRisk)
    ) {
      if (userData["KYC"]["transactionRiskInfo"]) {
        if (
          userData["KYC"]["transactionRiskInfo"]["status"] === "PARTIALLY_REJECTED" ||
          userData["KYC"]["transactionRiskInfo"]["status"] === "REJECTED" ||
          userData["KYC"]["transactionRiskInfo"]["status"] === "NOT_REQUIRED" ||
          userData["KYC"]["transactionRiskInfo"]["status"] === "REQUESTED" ||
          userData["KYC"]["transactionRiskInfo"]["status"] === "PARTIALLY_SUBMITTED"
        ) {
          if (Array.isArray(userData["KYC"]["transactionRiskInfo"]["stepsToComplete"])) {
            if (userData["KYC"]["transactionRiskInfo"]["stepsToComplete"][0] === "STEP1")
              setStep(1);
            else if (userData["KYC"]["transactionRiskInfo"]["stepsToComplete"][0] === "STEP2")
              setStep(2);
            else if (userData["KYC"]["transactionRiskInfo"]["stepsToComplete"][0] === "STEP3")
              setStep(3);
          }
        } else if (
          (userData["KYC"]["transactionRiskInfo"]["status"] === "SUBMITTED" ||
            userData["KYC"]["transactionRiskInfo"]["status"] === "RESUBMITTED") &&
          userData["KYC"]["transactionRiskInfo"]["stepsToComplete"].length === 0
        ) {
          setIsKycSubmitted(true);
          // return;
        }
        // else if (
        //   userData["KYC"]["transactionRiskInfo"]["status"] === "SUBMITTED" ||
        //   userData["KYC"]["transactionRiskInfo"]["status"] === "RESUBMITTED"
        // ) {
        //   history.push("/dashboard");
        //   showErrorMsg("Your Verifaiction Plus is already submitted.");
        // }
        // userData["KYC"]["transactionRiskInfo"]["status"] === "SUBMITTED" ||
        // userData["KYC"]["transactionRiskInfo"]["status"] === "RESUBMITTED"
        else {
          history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/dashboard`);
          showErrorMsg("Your Verifaiction Plus is already submitted.");
        }
      }
    } else {
      history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/dashboard`);
      showErrorMsg("You have to be verified user to access verification plus.");
    }
  };

  const onClose = () => {
    history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/dashboard`);
    setIsKycSubmitted(false);
  };

  return (
    <Fragment>
      {isKycSubmitted && <SubmittedModal onClose={onClose} type="Verification Plus" />}

      <DivElement pageContainerNew>
        <div className="stepWrapper">
          <BannerBox className="stepWrapper__header" paddingMd="0px 20px">
            <HeadingFourth
              display="flex"
              alignItems="center"
              size="20px"
              weight="500"
              sizeMd="14px"
            >
              Step {step}/3:
              {step === 1 && " Employment Status (verification plus)"}
              {step === 2 && " Funds Proof (verification plus)"}
              {step === 3 && " Wealth Proof (verification plus) "}
            </HeadingFourth>

            <ParaTag
              size="14px"
              sizeMd="12px"
              display="flex"
              weight="400"
              justifyContent={width > 767 ? "center" : "flex-start"}
              mt="4px"
              width="100%"
              // className="stepWrapper__header__subtitle"
            >
              Regulations require us to collect this information.
            </ParaTag>
          </BannerBox>

          <Fragment>
            {step === 1 && <KycStep1 guidlinesModal={setOpenGuidlines} />}
            {step === 2 && <KycStep2 guidlinesModal={setOpenGuidlines} />}
            {step === 3 && (
              <KycStep3 guidlinesModal={setOpenGuidlines} setIsKycSubmitted={setIsKycSubmitted} />
            )}
          </Fragment>
        </div>
      </DivElement>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.getUserData,
  kycContent: state.kyc.kycContent,
  kycContentLoader: state.kyc.loader
});

const mapDispatchToProps = (dispatch) => ({
  getKycContent: (data) => dispatch(getKycContent(data))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(KycLastProcess);

// if (

//   userData?.KYC?.STATUS === "LEVEL2" &&
//   (userData["KYC"]["transactionRiskInfo"]["status"] === "REQUESTED" ||
//     userData["KYC"]["transactionRiskInfo"]["status"] === "SUBMITTED")
// ) {
//   history.push("/dashboard");
//   showErrorMsg("Please wait, Your Verification Plus is under Process.");
// }
