/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import InputWrapper from "./InputWrapper";
import {
  Button,
  DivElement,
  LinkButton,
  TextWrapper
  // ToolTip
} from "../../../../../components/Reusable";
import Slider from "./Slider";
import { capitalize } from "../../../../../utils";
import { multiplyNumbers, divideNumbers, setPrecision } from "../../../../../utils/math";
import { LoadingButton } from "../../../../../components/Loader";
import { placeOrder } from "../../../../../lib/api";
import { formatAPIErrors, noExponents } from "../../../../../utils/general";
import { showErrorMsg } from "../../../../../utils/notification";
import ShowFunds from "./ShowFunds";
import { ErrorIcon } from "../../../../../assets/svgs";
import { checkDecimalPrecision } from "../../../../../utils/trading";
import { GetKycVerified, RestrictedUser } from "../../../../../components/Alert";
import SecondRoot from "../../../../../components/SecondRoot/secondRoot";
import MarketOrderAlert from "../../../../../views/tradePage/MarketOrderAlert";
// import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";
// import { BannerHeading } from "../../../../../assets/StyleComponents/FontSize/Heading.style";
// import { HeadingSix } from "../../../../../assets/StyleComponents/FontSize/HeadingSix.style";
// import { createStructuredSelector } from "reselect";
// import { getFeeStatus } from "../../../../../Modules/Trades/selectors";
// import { multiply } from "../../../../../utils/bigNumber";

const ERROR_MSGS = {
  HALT_MODE: "*Pair is temporarily unavailable for trading*",
  POST_MODE: "*Post Only Mode is active, try limit orders*",
  LOW_FUNDS: "Insufficient funds"
};

const BuyForm = ({
  selectedPair,
  lastPrice,
  orderFormSide,
  orderFormType,
  isLoggedIn,
  selectedPairConfig,
  orderDataFromOrderBook,
  exchangeWallet,
  userData,
  selectedPairTicker
}) => {
  const [showMarketAlert, setShowMarketAlert] = useState(false);
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [coinBalance, setCoinBalance] = useState(0);
  const [showAddFunds, setShowAddFunds] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [startedTyping, setStartedTyping] = useState(false);
  const bestAsk = useSelector((state) => state.trading.bestAsk);
  const bestBid = useSelector((state) => state.trading.bestBid);
  // const { feeStatus } = useSelector(mapStateToProps2);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (!exchangeWallet) return;

    const balanceCoin = orderFormSide === "buy" ? selectedPair.quote : selectedPair.base;
    const coinData =
      exchangeWallet && exchangeWallet.data.find((data) => data.coin === balanceCoin);

    setCoinBalance(coinData?.balance?.freeBalance);
  }, [exchangeWallet, selectedPair, orderFormSide]);

  useEffect(() => {
    let errMsg = "";
    let amt = orderFormSide === "buy" ? totalAmount : amount;
    if (selectedPairConfig && selectedPairConfig.mode === "halt") {
      errMsg = ERROR_MSGS.HALT_MODE;
    } else if (
      selectedPairConfig &&
      selectedPairConfig.mode === "post_only" &&
      orderFormType === "market"
    ) {
      errMsg = ERROR_MSGS.POST_MODE;
    } else if (isLoggedIn && amt && coinBalance < Number(amt)) {
      errMsg = ERROR_MSGS.LOW_FUNDS;
    } else if (amount && Number(amount) < selectedPairConfig.minBaseOrder) {
      errMsg = `Amount should be greater than ${selectedPairConfig.minBaseOrder}`;
    } else if (amount && Number(amount) > selectedPairConfig.maxBaseOrder) {
      errMsg = `Amount should be less than ${selectedPairConfig.maxBaseOrder}`;
    } else if (totalAmount && Number(totalAmount) < selectedPairConfig.minQuoteOrder) {
      errMsg = `Total value should be greater than ${selectedPairConfig.minQuoteOrder}`;
    } else if (totalAmount && Number(totalAmount) > selectedPairConfig.maxQuoteOrder) {
      errMsg = `Total value should be less than ${selectedPairConfig.maxQuoteOrder}`;
    }

    setErrorMessage(errMsg);
  }, [selectedPairConfig, orderFormType, amount, coinBalance, totalAmount]);

  useEffect(() => {
    if (!orderDataFromOrderBook) return;
    setPrice(noExponents(orderDataFromOrderBook.price));
    setAmount(noExponents(orderDataFromOrderBook.amount));
    calculateTotal(orderDataFromOrderBook.price, orderDataFromOrderBook.amount);
    calculatePercentOnAmtChange(orderDataFromOrderBook.amount);
  }, [orderDataFromOrderBook]);

  useEffect(() => {
    setAmount("");
    setTotalAmount("");
    onPercentageChange("");
  }, [orderFormSide, selectedPair]);

  useEffect(() => {
    if (startedTyping) return;
    let bestPrice = lastPrice;
    if (orderFormSide === "buy" && bestAsk) {
      bestPrice = bestAsk;
    }

    if (orderFormSide === "sell" && bestBid) {
      bestPrice = bestBid;
    }

    setPrice(noExponents(bestPrice));
  }, [orderFormSide, selectedPair, bestAsk, bestBid]);

  const onPriceChangeHandler = (e) => {
    let value = e.target.value;
    if (checkDecimalPrecision(value, selectedPairConfig?.orderPrecision?.Price)) {
      setPrice(value);
      calculateTotal(value, amount);
    }
  };

  const onAmountChangeHandler = (e) => {
    let value = e.target.value;
    if (checkDecimalPrecision(value, selectedPairConfig?.orderPrecision?.Amount)) {
      setAmount(value);
      calculateTotal(price, value);
      calculatePercentOnAmtChange(value);
    }
  };

  const onTotalChangeHandler = (e) => {
    let value = e.target.value;
    if (checkDecimalPrecision(value, selectedPairConfig?.orderPrecision?.Total)) {
      setTotalAmount(value);
      let amt = divideNumbers(value, price);
      let finalAmt = setPrecision(amt, selectedPairConfig?.orderPrecision?.Amount);
      setAmount(finalAmt);
      calculatePercentOnAmtChange(finalAmt);
    }
  };

  const calculateTotal = (currPrice, amt) => {
    let totalAmt = "";
    if (currPrice && amt) {
      let total = multiplyNumbers(currPrice, amt);
      totalAmt = setPrecision(total, selectedPairConfig?.orderPrecision?.Total);
    }
    setTotalAmount(totalAmt);
    setStartedTyping(true);
  };

  const onExecuteTradeHandler = () => {
    if(orderFormType === "market") {
      return setShowMarketAlert(true);
    }

    if (!amount || !price) {
      showErrorMsg("Please enter all fields!");
      return;
    }

    if (userData?.KYC?.STATUS === "LEVEL0" || userData?.KYC?.STATUS === "LEVEL1") {
      setShowModal("KYC");
      return;
    }

    if (userData?.status === "RESTRICTED") {
      setShowModal("RESTRICTED");
      return;
    }

    if (loader) return;
    setLoader(true);

    let data = {
      Pair: selectedPair.symbol,
      Amount: Number(amount),
      Price: Number(price) || 0,
      OrderType: orderFormType.toUpperCase(),
      Side: orderFormSide.toUpperCase()
    };

    placeOrder(data)
      .then((res) => {
        setAmount("");
        setTotalAmount("");
        setPercentage(0);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const calculateAmtOnPercentageChange = (percent) => {
    if (coinBalance && price && percent) {
      let finalAmt;
      if (orderFormSide === "buy") {
        let quantity = divideNumbers(coinBalance, price);
        let oneUnitAmt = divideNumbers(quantity, 100);
        finalAmt = multiplyNumbers(oneUnitAmt, percent);
        finalAmt = setPrecision(finalAmt, selectedPairConfig?.orderPrecision?.Amount);
      } else {
        let oneUnitAmt = divideNumbers(coinBalance, 100);
        finalAmt = multiplyNumbers(oneUnitAmt, percent);
        finalAmt = setPrecision(finalAmt, selectedPairConfig?.orderPrecision?.Amount);
      }
      setAmount(finalAmt);
      calculateTotal(price, finalAmt);
    }
  };

  const calculatePercentOnAmtChange = (amt) => {
    if (coinBalance && price && amt) {
      let finalPercent;
      if (orderFormSide === "buy") {
        let quantity = divideNumbers(coinBalance, price);
        let oneUnitAmt = divideNumbers(quantity, 100);
        finalPercent = divideNumbers(amt, oneUnitAmt);
      } else {
        let oneUnitAmt = divideNumbers(coinBalance, 100);
        finalPercent = divideNumbers(amt, oneUnitAmt);
      }
      setPercentage(finalPercent > 100 ? 100 : finalPercent);
    } else {
      setPercentage(0);
    }
  };

  const onPercentageChange = (percent) => {
    if (percent) calculateAmtOnPercentageChange(percent);
    setPercentage(percent);
  };

  const onMarketOrderClose = () => {
    setShowMarketAlert(false);
  };

  const onApprove = () => {
    if (!amount || !price) {
      showErrorMsg("Please enter all fields!");
      return;
    }

    if (userData?.KYC?.STATUS === "LEVEL0" || userData?.KYC?.STATUS === "LEVEL1") {
      setShowModal("KYC");
      return;
    }

    if (userData?.status === "RESTRICTED") {
      setShowModal("RESTRICTED");
      return;
    }

    if (loader) return;
    setLoader(true);

    let data = {
      Pair: selectedPair.symbol,
      Amount: Number(amount),
      Price: Number(price) || 0,
      OrderType: orderFormType.toUpperCase(),
      Side: orderFormSide.toUpperCase()
    };

    placeOrder(data)
      .then((res) => {
        setAmount("");
        setTotalAmount("");
        setPercentage(0);
        setShowMarketAlert(false);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
        setShowMarketAlert(false);
      })
      .finally(() => {
        setLoader(false);
        setShowMarketAlert(false);
      });
  };

  // const LCXFreeBalance = exchangeWallet?.data?.filter((data) => data?.coin === "LCX")[0]?.balance
  //   ?.freeBalance;

  // const amountBySide = orderFormSide === "buy" ? Number(totalAmount) : Number(amount);

  // const fees = Number(multiply(Number(amountBySide), divideNumbers(feeStatus ? 0.15 : 0.3, 100)));

  // const orderSideBuyCondition = orderFormSide === "buy";

  // const order = orderSideBuyCondition ? selectedPair.quote : selectedPair.base;

  // const tickersData = useSelector((state) => state.trading.tickersData);
  // const tickers = useMemo(() => {
  //   let keys = Object.keys(tickersData);
  //   if (keys.length === 0) {
  //     return [];
  //   } else {
  //     let data = keys.map((item) => {
  //       return { ...tickersData[item] };
  //     });
  //     return data;
  //   }
  // }, [tickersData]);

  // const lastPriceUSDC =
  //   order?.toUpperCase() === "USDC"
  //     ? fees
  //     : Number(
  //         multiply(
  //           Number(
  //             tickers?.filter((data) => data?.symbol === `${order?.toUpperCase()}/USDC`)[0]
  //               ?.equivalent
  //           ),
  //           Number(fees)
  //         )
  //       );

  // const lastPriceLCX = Number(
  //   multiply(
  //     Number(lastPriceUSDC),
  //     Number(1 / tickers?.filter((data) => data?.symbol === `LCX/USDC`)[0]?.equivalent)
  //   )
  // );

  // const imgProps = {
  //   height: "16px",
  //   width: "16px",
  //   absolute: true,
  //   top: "1px",
  //   right: "-18px"
  // };

  // const TipContent =
  //   selectedPair.quote !== "LCX"
  //     ? `Insufficient LCX balance. Fee will be deducted in ${
  //         orderSideBuyCondition ? selectedPair.quote : selectedPair.base
  //       }`
  //     : "Insufficient LCX balance.";

  return (
    <Fragment>
      {showModal === "KYC" && <GetKycVerified onClose={() => setShowModal("")} />}
      {showModal === "RESTRICTED" && (
        <RestrictedUser open={true} onClose={() => setShowModal("")} />
      )}

      {showMarketAlert && (
        <SecondRoot>
          <MarketOrderAlert
            onClose={() => onMarketOrderClose()}
            onApprove={() => onApprove()}
            marketAlert={{ Side: orderFormSide.toUpperCase() }}
          />
        </SecondRoot>
      )}

      <DivElement marginTop="24px" marginTopMd="18px">
        <ShowFunds
          coin={orderFormSide === "buy" ? selectedPair.quote : selectedPair.base}
          requiredFunds={orderFormSide === "buy" ? totalAmount : amount}
          isError={errorMessage === ERROR_MSGS.LOW_FUNDS ? true : null}
          showModal={showAddFunds}
          setShowModal={setShowAddFunds}
        />

        {orderFormType === "limit" && (
          <InputWrapper
            name="price"
            value={price}
            label={orderFormSide === "buy" ? "Bid" : "Ask"}
            coin={selectedPair.quote}
            onChange={onPriceChangeHandler}
          />
        )}

        <InputWrapper
          name="amount"
          value={amount}
          label="Amount"
          coin={selectedPair.base}
          onChange={onAmountChangeHandler}
        />

        <DivElement relative>
          <Slider percentage={percentage} setPercentage={onPercentageChange} />

          {orderFormType === "limit" && (
            <InputWrapper
              name="totalAmount"
              value={totalAmount}
              label="Total"
              coin={selectedPair.quote}
              onChange={onTotalChangeHandler}
              isError={
                orderFormSide === "buy" && errorMessage === ERROR_MSGS.LOW_FUNDS ? true : null
              }
            />
          )}

          {errorMessage && (
            <DivElement absolute top="calc(100% + 2px)" flexAlignCenter>
              <ErrorIcon width="16px" height="16px" marginRight="8px" />
              <TextWrapper red fontSize="12px" fontWeight="400" lineHeight="18px">
                {errorMessage}
              </TextWrapper>
              {errorMessage === ERROR_MSGS.LOW_FUNDS && (
                <Button
                  blueTextBtn
                  fontSize="12px"
                  lineHeight="18px"
                  marginLeft="8px"
                  onClick={() => setShowAddFunds(true)}
                >
                  Add {orderFormSide === "buy" ? selectedPair.quote : selectedPair.base}
                </Button>
              )}
            </DivElement>
          )}
        </DivElement>

        {/* Estimate Fees Section */}

        {isLoggedIn ? (
          <Button
            width="100%"
            height="44px"
            primaryRed={orderFormSide === "sell" ? true : null}
            primaryGreen={orderFormSide === "buy" ? true : null}
            marginTop="32px"
            onClick={onExecuteTradeHandler}
            disabled={loader || errorMessage}
          >
            {loader ? (
              <LoadingButton color="#fff" />
            ) : (
              `${capitalize(orderFormSide)} ${selectedPair.base}`
            )}
          </Button>
        ) : (
          <LinkButton secondaryBlue to={`${prevLang ? `/${prevLang}` : ""}/login`} width="100%" height="44px" padding="0 20px" marginTop="32px">
            Log in or Register
          </LinkButton>
        )}
      </DivElement>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  orderFormType: state.trading.orderFormType,
  selectedPair: state.trading.selectedPair,
  selectedPairTicker: state.trading.selectedPairTicker,
  lastPrice: state.trading.lastPrice,
  isLoggedIn: state.auth.isLoggedIn,
  selectedPairConfig: state.trading.selectedPairConfig,
  orderDataFromOrderBook: state.trading.orderDataFromOrderBook,
  exchangeWallet: state.dashboard.exchangeWallet,
  userData: state.dashboard.userData
});

// const mapStateToProps2 = createStructuredSelector({
//   feeStatus: getFeeStatus()
// });

export default connect(mapStateToProps)(BuyForm);
