/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { PageLoader } from "../../components/Loader";
import Security from "./Security/Security";
import SecurityConfirm from "./Security/index";
import PasswordComponent from "./password";
import ApiKeyManagementComponent from "./ApiKey/ApiKeyManagment";
import NotificationComponent from "./Notification/index";
import FeesComponent from "./fees";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import { BannerBox, MainElement, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import { trackPage } from "../../tracking/track";
import Reports from "./Reports";
import AccountVerification from "../../components/Modules/Settings/AccountVerification";
import Activity from "../../components/Modules/Settings/RecentActivity";
import AntiPhisingCode from "../../components/Modules/Settings/AntiPhising/AntiPhisingCode";
import { HalfFooterCommon } from "../../components/Footer/common";
import ProfileIndexSection from "./ProfileNew";
import ProfileMainContainer from "./ProfileNew/ProfileMainContainer";
import LanguagePreference from "./languagePreference";
import SettingNavbar from "./SettingNavbar/settingNavbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";
import { getLocalStorage } from "../../utils/helpers";
// import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";
// import UserDetailsSection from "./ProfileNew/UserDetailsSection";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

function SettingPage(props) {
  let {
    alert,
    loader,
    error,
    changePassword,
    apiData,
    twoAuthToken,
    generate2AuthToken,
    setup2FA,
    clearUserErrors,
    userData,
    changePasswordSuccess,
    isLogoutFetching,
    countriesList,
    getListOfCountries
    // walletErrorGet,
    // marketErrorGet,
    // tradeErrorGet,
    // dashErrorGet,
    // settingErrorGet
  } = props;
  let location = useLocation();
  // const history = useHistory();
  // const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { transalationList } = useSelector(GenericReducer);

  let prevLang = getLocalStorage("ljs-lang");
  const language = prevLang ? prevLang : userData?.preferredLanguage;

  useEffect(() => {
    // if (isLoggedIn) {
    //   dispatch(fetchAvailableLanguagesRequest());
    //   let checkPath = location.pathname?.split("/")[1];
    //   if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
    //     history.push(`/${userData.preferredLanguage}${location.pathname}`);
    //   }
    // }
    let page = location.pathname + location.search;
    localStorage.removeItem("profileData1");
    localStorage.removeItem("profileData2");
    trackPage(page);
  }, [location]);

  useEffect(() => {
    // setUserLoader(true);
    callGetApi();
  }, []);

  useEffect(() => {
    if (!countriesList) {
      getListOfCountries();
    }
  }, [countriesList, getListOfCountries]);

  const callGetApi = () => {
    // setUserLoader(true);
    // props.getUserDetails();
    // dispatch(updateUserData());
  };

  const SEOContents = {
    name: `Settings`,
    link: `setting`,
    title: "Buy, Sell & Trade Bitcoin and Altcoins | Cryptocurrency Exchange",
    description:
      "LCX Exchnage: Your secure, fast, and reliable crypto exchange platform for trading Bitcoin, Ethereum, and 500+ coins with 24/7 live support.",
    // keywords: `uk regulated crypto exchanges, crypto exchange regulation, crypto exchanges regulated in uk, regulated exchanges crypto, regulated crypto exchanges uk, crypto asset exchange, cryptoassets exchange, european crypto exchange, crypto exchange with lowest fees`,
    canonical: `https://exchange.lcx.com/setting`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${isLoggedIn && language ? `/${language}` : ""}/${location.pathname
      ?.split("/")
      .slice(2)
      .join("/")}`,
    lang: `${isLoggedIn && language ? `/${language}` : ""}`,
    OgTitle: "Buy, Sell & Trade Bitcoin, and Altcoins  | Cryptocurrency Exchange - LCX",
    OgDescription:
      "LCX Exchnage: Your secure, fast, and reliable crypto exchange platform for trading Bitcoin, Ethereum, and 500+ coins with 24/7 live support.",
    OgURL: `https://www.exchange.lcx.com${
      language && isLoggedIn ? `/${language}` : ""
    }/${location.pathname?.split("/").slice(2).join("/")}`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Buy, Sell & Trade Bitcoin, and Altcoins | Cryptocurrency Exchange - LCX",
    TwDescription:
      "LCX Exchnage: Your secure, fast, and reliable crypto exchange platform for trading Bitcoin, Ethereum, and 500+ coins with 24/7 live support."
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== language)
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/${location.pathname?.split("/").slice(2).join("/")}`
      };
      return obj;
    });
  }
  if (isLogoutFetching && !language) return <PageLoader />;
  // if (walletErrorGet || marketErrorGet || tradeErrorGet || dashErrorGet || settingErrorGet) {
  //   return <Redirect to="/error500" />;
  // }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      {/* {depositShow && (
        <DepositModal onClose={onCloseModal} coin="BTC" selectedNetwork={selectedNetwork} />
      )}
      {withdrawShow && <WithdrawalModal onClose={onCloseModal} coin={"BTC"} />} */}
      <MainElement>
        <BannerSection
          SectionGrey
          width="100%"
          padd="56px 0px"
          paddMd="32px 8px"
          paddLg="32px 64px"
        >
          {/* Main code Start Here */}
          <BannerBox ExchangeContainer widthMd="97%" minHeight="100vh">
            <BannerBox borderRadius="10px">
              <SettingNavbar />
              <BannerBox
                minHeight={
                  location.pathname === `${language ? `/${language}` : ""}/setting/profile`
                    ? "auto"
                    : "600px"
                }
                boxShadow="none"
                padding="27px 1rem"
                paddingMd="0px 10px"
                CardWhite
                DashboardBordersdark
                radius={"0 0 10px 10px"}
              >
                <Switch>
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/profile`}
                    render={() => <ProfileIndexSection {...props} />}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/password`}
                    render={() => (
                      // Object.keys(user).length > 0 && (
                      <PasswordComponent
                        loader={loader}
                        error={error}
                        alert={alert}
                        user={userData}
                        getApiCalling={callGetApi}
                        changePassword={changePassword}
                        twoAuthToken={twoAuthToken}
                        apiData={apiData}
                        generate2AuthToken={generate2AuthToken}
                        setup2FA={setup2FA}
                        clearUserErrors={clearUserErrors}
                        changePasswordSuccess={changePasswordSuccess}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/security`}
                    render={() => <SecurityConfirm />}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/security/phishing`}
                    render={() => <AntiPhisingCode />}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/security/auth`}
                    render={() => (
                      <Security
                        loader={loader}
                        error={error}
                        alert={alert}
                        user={userData}
                        getApiCalling={callGetApi}
                        changePassword={changePassword}
                        twoAuthToken={twoAuthToken}
                        apiData={apiData}
                        generate2AuthToken={generate2AuthToken}
                        setup2FA={setup2FA}
                        clearUserErrors={clearUserErrors}
                        changePasswordSuccess={changePasswordSuccess}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/notification`}
                    render={() => <NotificationComponent {...props} />}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/api_management`}
                    render={() => <ApiKeyManagementComponent {...props} />}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/recent_actvity`}
                    render={() => <Activity />}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/reports`}
                    render={() => <Reports />}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/fees`}
                    render={() => <FeesComponent />}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/account-verification`}
                    render={() => <AccountVerification />}
                  />
                  <Route
                    exact
                    path={`${language ? `/${language}` : ""}/setting/language-preference`}
                    render={() => <LanguagePreference />}
                  />
                  <Route
                    path="*"
                    render={() => (
                      <Redirect to={`${language ? `/${language}` : ""}/setting/profile`} />
                    )}
                  />
                </Switch>
              </BannerBox>
            </BannerBox>
            {location.pathname === `${language ? `/${language}` : ""}/setting/profile` && (
              <ProfileMainContainer userData={userData} countriesList={countriesList} />
            )}
          </BannerBox>
          {/* Main code Endz Here */}
        </BannerSection>

        <HalfFooterCommon />
      </MainElement>
    </>
  );
}

export default SettingPage;
