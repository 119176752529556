/* eslint-disable no-console */
// eslint-disable-next-line no-unused-vars
import styled, { css } from "styled-components";

const BadgeStyle = styled.div`
  cursor: pointer;
  padding: 3px 10px;
  border-radius: 55px;

  // min-width: 100px;

  ${(props) => props.size && `font-size: ${props.size}`};
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius}`} !important;
  ${(props) => props.color && `color: ${props.color}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.radius && `border-radius: ${props.radius}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};
  ${(props) => props.ml && `margin-left: ${props.ml}`};
  ${(props) => props.mr && `margin-right: ${props.mr}`};
  ${(props) => props.cursor && `cursor: ${props.cursor}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.bck && `background-color: ${props.bck}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.paddingRight && `padding-right: ${props.paddingRight}`};

  @media (max-width: 1024px) {
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg}`};
    ${(props) => props.displayLg && `display: ${props.displayLg}`};
    ${(props) => props.alignItemsLg && `align-items: ${props.alignItemsLg}`};
    ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg}`};
    ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`};
    ${(props) => props.mtLg && `margin-top: ${props.mtLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mbLg}`};
    ${(props) => props.mlLg && `margin-left: ${props.mlLg}`};
    ${(props) => props.mrLg && `margin-right: ${props.mrLg}`};
  }
  @media (max-width: 767px) {
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd}`};
    ${(props) => props.displayMd && `display: ${props.displayMd}`};
    ${(props) => props.alignItemsMd && `align-items: ${props.alignItemsMd}`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mbMd}`};
    ${(props) => props.mlMd && `margin-left: ${props.mlMd}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`};
  }

  ${(props) =>
    props.PrimaryBorder &&
    css`
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
    `};

  ${(props) =>
    props.DangerBorder &&
    css`
      border: 1px solid ${(props) => props.theme.danger};
      color: ${(props) => props.theme.danger};
    `};

  ${(props) =>
    props.SuccessBorder &&
    css`
      border: 1px solid ${(props) => props.theme.success};
      color: ${(props) => props.theme.success};
    `};

  ${(props) =>
    props.WarningBorder &&
    css`
      border: 1px solid ${(props) => props.theme.warning};
      color: ${(props) => props.theme.warning};
    `};
`;

export { BadgeStyle };
