import styled, { css } from "styled-components";
import { ElementBox, TextWrapper } from "../../../../../components/Reusable";
import ScrollBar from "../../../../../components/Reusable/ScrollBar";

const NavContainer = styled(ElementBox)`
  height: 60px;
  width: 100%;
  position: relative;
  margin: 0;
  box-shadow: none;
  border-bottom: 1px solid ${(props) => props.theme.sepColor};
  border-radius: 5px 5px 0 0;
  padding: 0;
  @media (max-width: 767px) {
    height: 44px !important;
  }
`;

const NavLink = styled(TextWrapper)`
  padding: 0 8px;
  margin: 0 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:hover {
    color: ${(props) => props.theme.primaryBlue};
    border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.primaryBlue};
      border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
    `}
`;

const TradePageNavbar = ({ activeTab, setActiveTab, tabs }) => {
  return (
    <NavContainer>
      <ScrollBar>
        {tabs &&
          tabs.map((tab) => (
            <NavLink
              active={activeTab === tab.value ? true : null}
              key={tab.value}
              onClick={() => setActiveTab(tab.value)}
            >
              {tab.label}
            </NavLink>
          ))}
      </ScrollBar>
    </NavContainer>
  );
};

export default TradePageNavbar;
