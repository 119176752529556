import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useEffect } from "react";
import { trackPage } from "../../tracking/track";
import { DivElement } from "../../components/Reusable";
import {
  MaintenanceDesktopIllustration,
  MaintenanceMobileIllustration
} from "../../assets/svgs/illustrations";
import { LcxDarkLogo } from "../../assets/png";

const UnderMaintenance = (props) => {
  let { maintenanceError } = props;

  useEffect(() => {
    // document.title = "LCX Exchange - Page Under Maintenance";
    trackPage("/maintenance", "Under Maintenance Page");
    document.querySelector("body").classList.remove("dark");
    document.querySelector("body").classList.add("light");
  }, []);

  return (
    <div className="undermaintenance-container theme--lcx_light">
      <div className="accounts-navbar">
        <div className="lcx-container h-100">
          <div className="d-flex justify-content-between align-items-center h-100">
            <span className="navlink p-0" style={{ margin: "0 40px 0 0" }}>
              <a className="logo" href="https://www.lcx.com">
                <img style={{ maxWidth: "82px" }} src={LcxDarkLogo} alt="lcx_logo" />
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="undermaintenance">
        <div className="undermaintenance__bg">
          <MaintenanceDesktopIllustration />
          <div className="undermaintenance__container">
            <div className="lcx-container">
              <div className="undermaintenance__box">
                <div className="undermaintenance__title">Site is Under Maintenance</div>
                <div className="undermaintenance__subtitle">
                  {maintenanceError ? maintenanceError.message : "Site is under maintenance."}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DivElement isDarkTheme={false} pageContainer>
          <div className="undermaintenance__mbg element-box">
            <div className="undermaintenance__banner">
              <MaintenanceMobileIllustration />
            </div>

            <div className="undermaintenance__box">
              <div className="undermaintenance__title">Site is Under Maintenance</div>
              <div className="undermaintenance__subtitle">
                {maintenanceError ? maintenanceError.message : "Site is under maintenance."}
              </div>
            </div>
          </div>
        </DivElement>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  maintenanceError: state.maintenance.maintenanceError
});

export default compose(connect(mapStateToProps))(UnderMaintenance);
