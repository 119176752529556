import { connect } from "react-redux";
import SecurityComponent from "../views/security/index.jsx";
import { getMarketLists, getMarketTickerData } from "../actions/marketLists";
import { selectedPair } from "../actions/trade";
import { logout } from "../actions/auth";

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  isThemeFecthing: state.theme.isThemeFetching,
  isMarketLists: state.market.isMarketLists,
  isMarketListFetching: state.market.isMarketListFetching,
  selectedPairName: state.trade.selectedPair,
  isSelectedPairFecthing: state.trade.isSelectedPairFecthing,
  isMarketTickerLists: state.market.isMarketTickerLists,
  isMarketTickerListFetching: state.market.isMarketTickerListFetching,
  isLoggedIn: state.auth.isLoggedIn,
  userData: state.dashboard.getUserData,
  isWholeLoading: state.dashboard.isWholeLoading,
  isLogoutFetching: state.dashboard.isLogoutFetching,
  marketErrorGet: state.market.errorGet,
  tradeErrorGet: state.trade.errorGet,
  dashErrorGet: state.dashboard.errorGet,
  settingErrorGet: state.setting.errorGet
});

const mapDispatchToProps = (dispatch) => ({
  getMarketLists: (user) => dispatch(getMarketLists(user)), // Call for Market List From redux
  selectedPair: (user) => dispatch(selectedPair(user)), // Call for Selected Pair From redux
  getMarketTickerData: () => dispatch(getMarketTickerData()), // Call for Market Ticker From  redux
  logoutUser: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityComponent);
