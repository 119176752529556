import styled, { css } from "styled-components";
import { TableRowComponent } from "../TableHead.style";

const MarketTableRow = styled(TableRowComponent)`
  th,
  td {
    p {
      font-size: ${(props) => (props.TradeDropdown ? "12px" : "14px")};
    }
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-right: right;

    &.symb-padd {
      justify-content: center;
    }
    &.text-right {
      justify-content: flex-end;
    }

    color: ${({ theme }) => theme.bodyText};
    flex: none;
    flex-grow: 0;
    align-items: center;
    box-sizing: border-box;
    height: ${(props) => props.theadHeight || "auto"} !important;

    &:nth-child(1) {
      flex: ${(props) => (props.TradeDropdown ? "0 0 50px" : "0 0 83px")} !important;
      justify-content: center;
    }
    &:nth-child(2) {
      padding-left: 8px;
      flex: 1 1 154px;
    }
    &:nth-child(3) {
      flex: 0 0 118px;
      justify-content: flex-end;
      padding-right: 16px;
    }
    &:nth-child(4) {
      // min-width: 135px;
      flex: 1 1 135px;
      padding-right: ${(props) => (props.TradeDropdown ? "0px" : "24px")};
      justify-content: flex-end;
    }
    &:nth-child(5) {
      flex: 0 0 118px;
      justify-content: flex-end;
      padding: 0;
      p,
      h5 {
        padding-right: 16px;
      }
    }
    &:nth-child(6),
    &:nth-child(7) {
      // min-width: 135px;
      flex: 1 1 131px;
      padding-left: 8px;
      padding-right: 16px;
      justify-content: flex-end;
    }
    &:nth-child(8) {
      padding-left: 8px;
      flex: 0 0 103px;
      justify-content: center;
    }
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(7) {
      align-self: stretch;
      flex-grow: 1;
    }

    @media (max-width: 1024px) {
      &:nth-child(1) {
        flex: 0 0 50px;
        padding-right: 8px;
        justify-content: flex-end;
      }
      &:nth-child(3) {
        // padding-right: 40px;
        flex: 1 1 135px;
      }
      &:nth-child(4) {
        flex: 0 0 103px;
        justify-content: flex-end;
      }
      &:nth-child(5) {
        justify-content: center;
      }
    }

    @media (max-width: 767px) {
      &:nth-child(1) {
        flex: 0 0 30px;
        padding-right: 4px;
      }
      &:nth-child(2) {
        padding-left: 4px;
      }
      &:nth-child(3) {
        padding-right: 8px;
      }
      &:nth-child(4) {
        flex: 0 0 25px;
        padding-left: 4px;
        padding-right: 4px;
        justify-content: flex-start;
      }
    }
  }

  th {
    line-height: 16px;
    justify-content: center;
    cursor: pointer;
    color: ${({ theme }) => theme.tableHeaderColor} !important;
    p,
    span {
      color: ${({ theme }) => theme.tableHeaderColor} !important;
    }
    &:nth-child(2) {
      // padding-left: 32px;
      justify-content: flex-start;
    }
    &:nth-child(3),
    &:nth-child(7) {
      // padding-right: 16px;
    }
    &:nth-child(8) {
      justify-content: center;
    }
    &:nth-last-child {
      justify-content: center;
    }

    @media (max-width: 1024px) {
      &:nth-child(2) {
        // padding-left: 34px;
      }
      &:nth-child(3) {
        flex: 1 1 135px;
      }
      &:nth-child(4) {
        justify-content: center;
      }
      &:nth-last-child {
        justify-content: center;
      }
      p {
        font-size: 14px !important;
      }
      font-size: 14px !important;
    }

    @media (max-width: 767px) {
      &:nth-child(2) {
        // padding-left: 30px;
      }
      &:nth-child(3) {
        // padding-right: 8px;
      }
      &:nth-child(4) {
        justify-content: center;
      }
      p {
        font-size: 12px !important;
      }
      font-size: 12px !important;
    }
  }
  td {
    p {
      font-size: 16px;
    }
    font-size: 16px;
    font-weight: 500;

    ${(props) =>
      props.TradeDropdown &&
      css`
        font-size: 14px !important;
      `}
    h5, button {
      flex-grow: 1;
    }
    &:nth-child(1) {
      flex: ${(props) => (props.TradeDropdown ? "0 0 50px" : "0 0 83px")};
      justify-content: center;
      button {
        flex-grow: 0;
        width: 50px;
      }
    }
    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(8) {
      flex-grow: 0;
      h5,
      button {
        flex-grow: 0;
      }
    }
    &.txt-success {
      h5 {
        color: ${({ theme }) => theme.success};
      }
    }
    &.txt-red {
      h5 {
        color: ${({ theme }) => theme.danger};
      }
    }

    @media (max-width: 1024px) {
      p {
        font-size: 14px !important;
      }
      font-size: 14px !important;
    }

    @media (max-width: 767px) {
      p {
        font-size: 12px !important;
      }
      font-size: 12px !important;
    }
  }
`;

export { MarketTableRow };
