import apiConstants from "../constants/apiConstants";

export const getBondInvestHistory = (data) => ({
  type: apiConstants.GET_BOND_INVEST_HISTORY,
  payload: data
});

export const clearEarnErrors = () => ({
  type: apiConstants.CLEAR_EARN_ERRORS
});
