// Breakpoints in pixels
const breakpoints = {
  Xl: 1200,
  Lg: 1024,
  Md: 767,
  Sm: 560
};

// Media query generator
const mediaQuery = size => `@media (max-width: ${breakpoints[size]}px)`;

// Generate responsive styles based on prop and breakpoints
const createResponsiveStyles = (
  propName,
  cssProperty,
  valueTransformer = value => value
) => {
  return props => {
    const breakpointKeys = Object.keys(breakpoints);
    let styles = '';

    // Base style (no breakpoint)
    if (props[propName]) {
      styles += `${cssProperty}: ${valueTransformer(props[propName])};`;
    }

    // Responsive styles for each breakpoint
    breakpointKeys.forEach(breakpoint => {
      const breakpointProp = `${propName}${breakpoint.charAt(0).toUpperCase() + breakpoint.slice(1)}`;
      if (props[breakpointProp]) {
        styles += `
          ${mediaQuery(breakpoint)} {
            ${cssProperty}: ${valueTransformer(props[breakpointProp])};
          }
        `;
      }
    });
    return styles;
  };
};

export { createResponsiveStyles, breakpoints, mediaQuery };
