import { Fragment } from "react";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import DesktopView from "./DesktopView";
import NonDesktopView from "./NonDesktopView";
import { DivElement } from "../../../../../components/Reusable";

const BondSummary = ({ bondDetails, bondSummary }) => {
  const { width } = useWindowSize();

  return (
    <Fragment>
      {width >= 1024 ? (
        <DivElement width="calc(50% - 8px)" widthMd="100%">
          <DesktopView bondSummary={bondSummary} />
        </DivElement>
      ) : (
        <NonDesktopView bondDetails={bondDetails} bondSummary={bondSummary} />
      )}
    </Fragment>
  );
};

export default BondSummary;
