import React from 'react';

import PromotionText from './PromotionText';
import { SectionTag } from '../../../assets/StyleComponents/SectionTag.style';
import PromotionCarousel from './PromotionCarousel';
import { MainTag } from '../../../assets/StyleComponents/Main.style';
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";

const PermotionParent = ({ children }) => {
  const { width } = useWindowSize();
  return (
    <MainTag
      style={{
        background: width > 767 && "linear-gradient(0deg, rgba(151, 201, 253, 0.00) 0%, rgba(151, 201, 253, 0.32) 100%)"
      }}
    >
      <SectionTag
        position="relative"
        zIndex="2"
        left="0.3%"
        pt="48px"
        ptLg="46px"
        ptMd="24px"
        pbMd="46px"
        mx="auto"
        // padding={"46px 0 "}
        ExchangeContainer
      >
        {children}
      </SectionTag>
    </MainTag>
  );
};

PermotionParent.PromotionText = PromotionText;
PermotionParent.PromotionCarousel = PromotionCarousel;

export default PermotionParent;