import styled, { css } from "styled-components";
import {
  // Button,
  CoinLabel,
  DivElement,
  ImgElement,
  TextWrapper
} from "../../../components/Reusable";
import { ErrorMessage, Field } from "formik";
import { useSelector } from "react-redux";
// import { noExponents, numberWithCommas } from "../../../../utils/general";
import Select from "react-select";
import { reactDropdownStyles } from "../../../utils";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { HeadingSix } from "../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { noExponents, numberWithCommas } from "../../../utils/general";
import { new_BN } from "../../../utils/bigNumber";

const formattedArray = (array) => {
  return array.map((item) => {
    return {
      label: item.coin,
      value: item.coin
    };
  });
};

const InputContainer = styled(DivElement)`
  height: 106px;
  background-color: ${(props) => props.theme.inputFieldBg};
  border-color: ${(props) => props.theme.inputBorder};
  display: flex;
  flex-direction: column;

  input {
    outline: none !important;
    font-size: 16px;
    line-height: 20px;
    border: none !important;
    padding: 0px;
    background-color: ${(props) => props.theme.inputFieldBg};
    color: ${(props) => props.theme.primaryText};
    min-width: 94px;
  }

  input:focus {
    background-color: ${(props) => props.theme.inputFieldBg};
  }

  ${(props) =>
    props.showRedBorder &&
    css`
      border-color: ${(props) => props.theme.primaryRed};
    `}
`;

const NewAmountInput = ({
  saleDetails,
  onAmountChangeHandler,
  setFieldValue,
  title,
  balance,
  name,
  onCoinChangeHandler,
  selectedPaymentCoin,
  showRedBorder,
  onClickAddFunds,
  isPurchaseBalanceEnough,
  errors,
  containerProps,
  purchaseAmt
}) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const purchaseAmountInUsd = saleDetails?.round?.usdPrice * purchaseAmt;

  return (
    <DivElement relative paddingBottom="26px" {...containerProps}>
      <InputContainer
        // flexJustifyBtwAlignCenter
        border="1px solid"
        borderRadius="5px"
        padding="12px 16px"
        showRedBorder={isLoggedIn ? (errors && errors[name]) || showRedBorder : null}
      >
        <BannerBox>
          <BannerHeading size="14px" weight="500" BodyColor>
            {name === "lcxAmount" ? "Amount" : "Currency For payment"}
          </BannerHeading>
        </BannerBox>
        <BannerBox display="flex" justifyContent="space-between">
          <Field
            type="number"
            name={name}
            placeholder="0"
            disabled={saleDetails?.tokenSoldOut}
            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            onChange={(e) => onAmountChangeHandler(e.target.value, setFieldValue, name)}
          />
          {name === "lcxAmount" && (
            <DivElement
              bgPopup
              width="100px"
              height="32px"
              displayCenter
              bdGrey
              border="1px solid"
              borderRadius="16px"
              padding="0px 5px"
            >
              <ImgElement
                width="24px"
                height="24px"
                borderRadius="50%"
                src={saleDetails?.logo}
                alt={saleDetails?.coin}
              />
              <TextWrapper secondary marginLeft="6px" fontSize="14px" fontWeight="400">
                {saleDetails?.coin}
              </TextWrapper>
            </DivElement>
          )}
          {name === "lcxPayment" && (
            <DivElement flexAlignCenter justifyContent="flex-end">
              {selectedPaymentCoin && (
                <Select
                  styles={reactDropdownStyles({
                    isDarkTheme,
                    height: "32px",
                    width: "126px",
                    isTokenSale: true,
                    valueContainerProps: { height: "100%" },
                    containerProps: { borderRadius: "16px" },
                    indicatorContainerProps: { padding: "2px !important" }
                  })}
                  onChange={(coin) => onCoinChangeHandler(coin.value, setFieldValue)}
                  options={formattedArray(saleDetails?.round?.coins)}
                  formatOptionLabel={(val) => <CoinLabel {...val} tokenSale={true} />}
                  classNamePrefix="select"
                  isSearchable={false}
                  defaultValue={{
                    label: selectedPaymentCoin,
                    value: selectedPaymentCoin
                  }}
                />
              )}
            </DivElement>
          )}
        </BannerBox>
        {name === "lcxAmount" && purchaseAmt > 0 && (
          <BannerBox>
            <HeadingSix size="12px" weight="500" txtGrey>
              ${" "}
              {new_BN(purchaseAmountInUsd, 2).length > 18
                ? new_BN(purchaseAmountInUsd, 2).slice(0, 17) + "..."
                : new_BN(purchaseAmountInUsd, 2)}
            </HeadingSix>
          </BannerBox>
        )}

        {name === "lcxPayment" && isLoggedIn && (
          <TextWrapper secondary fontSize="14px" fontWeight="400" textAlign="end" marginTop="5px">
            Main balance:{" "}
            <BannerHeadingSpan weight="500">
              <var>
                {isLoggedIn ? numberWithCommas(noExponents(balance || 0)) : "00"}{" "}
                {selectedPaymentCoin}
              </var>
            </BannerHeadingSpan>
          </TextWrapper>
        )}
      </InputContainer>
      {/* {name !== "lcxPayment" && ( */}
      <TextWrapper red position="absolute">
        <ErrorMessage name={name} component="div" />
      </TextWrapper>
      {/* )} */}
    </DivElement>
  );
};

export default NewAmountInput;
