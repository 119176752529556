/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import config from "../../../../../constants/config";
import { chartTimeZone } from "../../../../../utils/chartTimeZone";
var axios = require("axios");
const api_root2 = `${config.klineApiUrl}market/kline`;
const history = {};
let prevBarRecord = {};

export default {
  history: history,
  prevBarRecord: prevBarRecord,
  getBars: function (symbolInfo, resolution, from, to, first, limit) {
    const qs = {
      pair: symbolInfo.name,
      resolution: resolution,
      // from: from < 100000000 ? from * 1000 : from,
      to: !first ? prevBarRecord.prevTime / 1000 - 1 || to : to < 100000000 ? to * 1000 : to,
      Limit: 320
    };
    delete axios.defaults.headers.common["deviceId"];
    return axios.post(api_root2, qs).then((data) => {
      if (!data || data.data.status != "success") return [];
      if (data && data.data) data = data.data.data.reverse();
      let bars = [],
        length = data.length;

      for (let i = 0; i < length; i++) {
        if (!data[i].pair) continue;
        const getTimeZone = chartTimeZone();
        const convertMillisecond = getTimeZone * 60 * 1000;
        const offsetSign = convertMillisecond < 0 ? "-" : "+";
        const offsetSignPoistive = Math.abs(convertMillisecond);
        const timestamp = data[i].timestamp * 1000 + offsetSignPoistive;
        bars.push({
          // time: (data[i].timestamp * 1000) + (getTimeZone * 60000),
          // time: data[i].timestamp * 1000,
          time: timestamp,
          prevTime: data[i].timestamp * 1000,
          low: data[i].low,
          high: data[i].high,
          open: data[i].open,
          close: data[i].close,
          volume: data[i].volume
        });
      }
      if (first) {
        let length = bars.length;
        let lastBar = {};
        if (length === 0)
          lastBar = {
            time: Date.now(),
            low: 0,
            high: 0,
            open: 0,
            close: 0,
            volume: 0
          };
        else lastBar = bars[length - 1];
        history[symbolInfo.name] = { lastBar: lastBar };
      }
      if (bars && bars.length > 0) {
        let reverseBar = [];
        reverseBar = bars[0];
        prevBarRecord = reverseBar;
      }
      return bars;
    });
  }
};
