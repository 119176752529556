import React from "react";
import { Helmet } from "react-helmet";

const SetTradeTitleComponent = (props) => {
  const jsonLdData = {
    "@context": "http://schema.org/",
    "@type": "WebPage",
    name: `${props.base_pair}/${props.quote_pair}`,
    speakable: {
      "@type": "SpeakableSpecification",
      xpath: ["/html/head/title", "/html/head/meta[@name='description']/@content"]
    },
    url: `https://exchange.lcx.com/trade/${props.base_pair}-${props.quote_pair}`
  };
  const jsonLdData2 = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "LCX",
    url: "https://exchange.lcx.com/",
    email: "hello@lcx.com",
    logo: "https://www.lcx.com/wp-content/uploads/LCX_logo_black-trademark%C2%AE.png",
    address: {
      "@type": "PostalAddress",
      addressCountry: "Liechtenstein",
      addressLocality: "Vaduz",
      addressRegion: "Vaduz",
      streetAddress: "Herrengasse 6 9490"
    },
    sameAs: [
      "https://twitter.com/lcx",
      "https://www.youtube.com/@LCXcom",
      "https://facebook.com/lcx.exchange",
      "https://www.linkedin.com/company/lcx/",
      "https://www.instagram.com/lcx.exchange/"
    ]
  };
  const jsonLdData3 = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://exchange.lcx.com/"
      },
      {
        "@type": "ListItem",
        position: 2,
        name: `${props.base_pair}/${props.quote_pair}`,
        item: `https://exchange.lcx.com/trade/${props.base_pair}-${props.quote_pair}`
      }
    ]
  };

  const prevLang = localStorage.getItem("ljs-lang");

  return (
    <Helmet>
      {/* Meta Tags */}
      <meta
        name="description"
        content={`Buy or Sell ${props.base_pair} for ${props.quote_pair}: ✓ The best ${props.base_pair} rate ✓ Low Fees ✓ Secure transactions ✓ Trading View from LCX Exchange the most popular cryptocurrency exchange in Europe.`}
        data-react-helmet="true"
      />
      <meta
        name="keywords"
        content={`${props.base_pair} to ${props.quote_pair}, ${props.base_pair} ${props.quote_pair}, ${props.base_pair} to ${props.quote_pair}, ${props.base_pair}/${props.quote_pair},${props.base_pair} trading pair, ${props.base_pair}/${props.quote_pair} Charts, ${props.base_pair} to ${props.quote_pair}, ${props.base_pair} to ${props.quote_pair} exchange rate`}
        data-react-helmet="true"
      />
      <link
        rel="canonical"
        href={`https://exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/trade/${props.base_pair}-${props.quote_pair}`}
      />
      <title>
        {`${props.lastPrice} | ${props.base_pair}/${props.quote_pair} | ${props.base_pair} price chart | LCX Exchange`}
      </title>

      {/* Open Graph Meta Tags */}
      <meta
        property="og:title"
        content={`${props.lastPrice} | ${props.base_pair}/${props.quote_pair} | ${props.base_pair} price chart | LCX Exchange`}
      />
      <meta
        property="og:description"
        content={`Buy or Sell ${props.base_pair} for ${props.quote_pair}: ✓ The best ${props.base_pair} rate ✓ Low Fees ✓ Secure transactions ✓ Trading View from LCX Exchange the most popular cryptocurrency exchange in Europe.`}
      />
      <meta
        property="og:url"
        content={`https://exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/trade/${props.base_pair}/${props.quote_pair}`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="LCX" />
      <meta property="og:image" content="https://exchange.lcx.com/img/demo.jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={`${props.lastPrice} | ${props.base_pair}/${props.quote_pair} | ${props.base_pair} price chart | LCX Exchange`}
      />
      <meta
        name="twitter:description"
        content={`Buy or Sell ${props.base_pair} for ${props.quote_pair}: ✓ The best ${props.base_pair} rate ✓ Low Fees ✓ Secure transactions ✓ Trading View from LCX Exchange the most popular cryptocurrency exchange in Europe.`}
      />
      <meta name="twitter:site" content="@lcx" />
      <meta name="twitter:image" content="https://exchange.lcx.com/img/demo.jpg" />
      <meta name="twitter:image:width" content="1200" />
      <meta name="twitter:image:height" content="675" />

      {/* JSON-LD Script */}
      <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
      <script type="application/ld+json">{JSON.stringify(jsonLdData2)}</script>
      <script type="application/ld+json">{JSON.stringify(jsonLdData3)}</script>
    </Helmet>
  );
};

export default SetTradeTitleComponent;
