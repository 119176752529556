import styled, { css } from "styled-components";

const OrderBookTable = styled.div`
  width: 100%;
  direction: ${(props) => props.direction || ""};
`;

const Tr = styled.div`
  margin: ${(props) => props.margin || ""};
  margin-bottom: ${(props) => props.marginBottom || ""};
  height: ${(props) => props.height || "20px"};
  z-index: ${(props) => props.zIndex || ""};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.padding || ""};
  width: 100%;
  border: dashed transparent;
  border-width: 1px 0 1px 0;

  @media (max-width: 1024px) {
    height: ${(props) => props.heightMd || ""};
    margin: ${(props) => props.marginMd || ""};
  }

  @media (max-width: 767px) {
    height: ${(props) => props.heightSm || ""};
    margin: ${(props) => props.marginSm || ""};
  }

  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `}

  ${(props) =>
    props.active &&
    css`
      background: ${(props) =>
        props.theme.isDarkTheme ? "rgba(222, 223, 224, 0.12)" : "rgba(1, 34, 67, 0.12)"};
    `}
  
  ${(props) =>
    props.bdDash === "buy" &&
    css`
      border-bottom: 1px dashed ${(props) => props.theme.subText};
    `}

  ${(props) =>
    props.bdDash === "sell" &&
    css`
      border-top: 1px dashed ${(props) => props.theme.subText};
    `}

    ${(props) =>
    props.bgHeader &&
    css`
      @media (max-width: 1024px) {
        background-color: ${(props) => props.theme.tableHeader};
        height: 40px !important;
        vertical-align: middle;
        border-radius: 5px;
      }
    `}
`;

const Th = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  padding: ${(props) => (props.padding ? props.padding : "0")};
  text-align: ${(props) => props.textAlign || ""};
  width: ${(props) => props.width || ""};
  color: ${(props) => props.theme.subText};
  vertical-align: middle;

  @media (max-width: 1024px) {
    padding-top: ${(props) => props.paddingTopMd || ""};
    padding-right: ${(props) => props.paddingRightMd || ""};
    padding-bottom: ${(props) => props.paddingBottomMd || ""};
    padding-left: ${(props) => props.paddingLeftMd || ""};
    color: ${(props) => props.theme.primaryText};
    font-weight: 400;
  }
`;

const Td = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: ${(props) => (props.padding ? props.padding : "0")};
  text-align: ${(props) => props.textAlign || ""};
  width: ${(props) => props.width || ""};
  color: ${(props) => props.theme.primaryText};
  height: 100%;
  vertical-align: middle;

  @media (max-width: 1024px) {
    padding: ${(props) => props.paddingMd || ""};
  }

  ${(props) =>
    props.green &&
    css`
      color: ${(props) => props.theme.primaryGreen};
    `}

  ${(props) =>
    props.red &&
    css`
      color: ${(props) => props.theme.primaryRed};
    `}
`;

OrderBookTable.Tr = Tr;
OrderBookTable.Th = Th;
OrderBookTable.Td = Td;

export default OrderBookTable;
