import React from "react";
import { components } from "react-select";
import configs from "../../constants/config";
import { IMAGE_PREFIX } from "../../constants/Images/image";
import { ExpandLessIcon, ExpandMoreIcon, SignalIcon } from "../../assets/svgs";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { ImgElement } from "../Reusable";
// import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { DivElement, TextWrapper } from "../Reusable";

let inputBg = "#f5f6fa";
let inputBg_dark = "#1f2c38";
let inputBgFocus = "#cde2f9";
let inputBgFocus_dark = "#112d51";
let inputBorder = "1px solid #8c9797 !important";
let inputBorder_Dark = "1px solid #969799 !important";
let inputBorderFocus = "1px solid #0470e1!important";
let textColor = "#012243";
let textColor_Dark = "#dedfe0";
let primaryBlue = "#0470e1";
let primaryBlue_dark = "#70a6e0";
let primaryWhite = "#fff";
let primaryWhite_dark = "#012243";

const formattedArray = (array) => {
  return array.map((item) => {
    return {
      id: item,
      label: item.coin,
      value: item.coin
    };
  });
};

const CustomOptionsForCurrency = ({ label }) => {
  let isTheme = JSON.parse(localStorage.getItem("darkTheme"));
  return (
    <BannerBox key={label} display="flex" alignItems="center">
      <ImgElement
        alt="coin"
        src={`${!isTheme ? configs.lightCoinUrl : configs.darkCoinUrl}${label?.toLowerCase()}.svg`}
        style={{ verticalAlign: "middle" }}
        width="25px"
      />
      <BannerHeadingSpan> {label} </BannerHeadingSpan>
    </BannerBox>
  );
};

const CustomOptionsForNetwork = ({ label }) => (
  <div className="d-flex algn-items-center" key={label}>
    <SignalIcon width="20px" height="20px" />
    <span className="d-flex align-items-center"> {label} </span>
  </div>
);

const CustomOptionsForProviders = ({ label }) => (
  <div className="d-flex algn-items-center" key={label}>
    <img
      alt="coin"
      className="mr-2"
      src={IMAGE_PREFIX.MoneriumBankLogo}
      style={{ verticalAlign: "middle" }}
      width="20px"
    />
    <span className="d-flex align-items-center"> {label} </span>
  </div>
);

const CustomOptionsForBeneficiary = ({ label }) => {
  return (
    <DivElement dAlignCenter key={label}>
      <SignalIcon width="20px" height="auto" marginRight="10px" />
      <TextWrapper>{label}</TextWrapper>
    </DivElement>
  );
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props} style={{ border: "0" }}>
        {props.selectProps.menuIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </components.DropdownIndicator>
    )
  );
};
const DropdownIndicator2 = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props} style={{ border: "0", paddingRight: "10px" }}>
        {props.selectProps.menuIsOpen ? (
          <ExpandLessIcon color="white" />
        ) : (
          <ExpandMoreIcon color="white" />
        )}
      </components.DropdownIndicator>
    )
  );
};

export const CustomOptionsForTransferFunds = ({ label }) => {
  let isTheme = JSON.parse(localStorage.getItem("darkTheme"));
  return (
    <BannerBox display="flex" alignItems="center" key={label}>
      <ImgElement
        loading="lazy"
        alt="coin"
        marginRight="7px"
        src={`${!isTheme ? configs.lightCoinUrl : configs.darkCoinUrl}${label?.toLowerCase()}.svg`}
        width="16px"
        height="16px"
      />
      <h4 style={{ fontWeight: "500" }}>{label}</h4>
    </BannerBox>
  );
};

const customStylesTransferFunds = (isDarkTheme) => {
  return {
    control: (base, { isFocused }) => ({
      ...base,
      // position: "realtive",
      border: "0 !important",
      borderBottom: !isDarkTheme
        ? `${isFocused ? inputBorderFocus : inputBorder}`
        : `${isFocused ? inputBorderFocus : inputBorder_Dark}`,
      "font-size": "0.875rem",
      "min-height": "48px",
      "max-height": "48px",
      display: "flex",
      width: "100%",
      minWidth: "100%",
      boxShadow: "none !important",
      background: !isDarkTheme ? primaryBlue : primaryBlue_dark,
      borderRadius: "0 5px 5px 0"
    }),
    placeholder: (base, { isFocused }) => ({
      ...base,
      color: !isDarkTheme ? "white" : "white",
      "font-size": "1rem",
      "font-weight": "300"
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: "100%",
      "min-height": "40px",
      "max-height": "40px",
      background: !isDarkTheme ? primaryBlue : primaryBlue_dark,
      paddingTop: "0",
      paddingBottom: "0"
      // height:"200px !important"
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      width: "0%",
      minHeight: "0px",
      background: "transparent !important",
      padding: "0px !important"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: "8px !important",
      color: !isDarkTheme ? primaryWhite : primaryWhite_dark,
      "&:hover": {
        color: !isDarkTheme ? primaryWhite : primaryWhite_dark
      }
    }),
    menu: (base) => ({
      ...base,
      "z-index": "999",
      height: "20px",
      background: !isDarkTheme ? "#fff" : "#1f2c38"
    }),
    menuList: (base) => ({
      ...base,
      background: !isDarkTheme ? "#fff" : "#101726",
      "max-height": "200px"
    }),
    singleValue: () => ({
      position: "absolute",
      width: "100%",
      height: "20px",
      display: "flex",
      alignItems: "center",
      color: !isDarkTheme ? primaryWhite : primaryWhite_dark
    }),
    input: () => ({
      position: "absolute",
      color: !isDarkTheme ? "#f00" : "#f5f6fa",
      "&:focus": {
        color: !isDarkTheme ? "#fff !important" : "#f5f6fa !important",
        textDecoration: "none!important",
        background: "transparent !important"
      }
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      background: !isDarkTheme ? inputBg : inputBg_dark,
      color: !isDarkTheme ? textColor : textColor_Dark,
      // background: !isDarkTheme ? "#fff" : "#1f2c38",
      // color: !isDarkTheme ? "#101726" : "#DEDFE0",
      "&:hover": {
        background: !isDarkTheme ? inputBgFocus : inputBgFocus_dark,
        color: !isDarkTheme ? textColor : textColor_Dark
      }
    })
  };
};

export {
  formattedArray,
  CustomOptionsForCurrency,
  CustomOptionsForNetwork,
  CustomOptionsForProviders,
  DropdownIndicator,
  DropdownIndicator2,
  customStylesTransferFunds,
  CustomOptionsForBeneficiary
};
