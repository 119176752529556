import React from 'react';

import { SectionTag } from '../../../assets/StyleComponents/SectionTag.style';


const SectionConatiner = ({ children, extraStyle, cardStyle }) => {
  return (
    <SectionTag
      position={extraStyle?.position ? extraStyle?.position : "relative"}
      zIndex={extraStyle?.zIndex ? extraStyle?.zIndex : "1"}
      mx="auto"
      {...extraStyle}
      cardStyle={cardStyle}
      ExchangeContainer
      ParentCard={(cardStyle && cardStyle === "ParentCard") ? true : false}
      MainCardLevel1={(cardStyle && cardStyle === "MainCardLevel1") ? true : false}
    >
      {children}
    </SectionTag>
  );
};

export default SectionConatiner;