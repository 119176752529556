import React, { useState, useEffect } from "react";
import { BannerSection, MainElement } from "../../../assets/StyleComponents/Exchange.style";
import UserDetailsSection from "./components/UserDetailsSection";
import DocumentsUploaded from "./components/DocumentsUploaded";
import ProfileFaqSection from "./components/FaqSection";
import Verificationtab from "./components/Verificationtab";
import { getUserAddressStatus } from "../../../lib/api";
import { postAddressUpdate, resetAddressUpdate } from "../../../actions/setting";
import { showErrorMsg } from "../../../utils/notification";
import { useDispatch, useSelector } from "react-redux";
import { TextWrapper, ModalWrapper, DivElement, Animation } from "../../../components/Reusable";
import { CloseIcon } from "../../../assets/svgs";

const ShowSuccessPopup = ({ onClose }) => {
  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <DivElement display="flex" justifyContent="space-between" alignItems="center">
          <TextWrapper fontSize="18px">Details Submitted Successfully</TextWrapper>
          <span role="presentation" onClick={onClose}>
            <CloseIcon />
          </span>
        </DivElement>
        <DivElement flex justifyContent="center" alignItems="center" flexDirection="column">
          <Animation type="SUCCESS" width={150} />
          <TextWrapper textAlign="center">
            Thank you for providing your address details!
          </TextWrapper>
          <TextWrapper>We'll keep you updated on the status shortly.</TextWrapper>
        </DivElement>
      </DivElement>
    </ModalWrapper>
  );
};

export const ADDRESS_STATUS_CONSTANTS = {
  CANNOT_BE_CHANGED: "CANNOT_BE_CHANGED",
  CAN_BE_CHANGED: "CAN_BE_CHANGED",
  SUBMITTED: "SUBMITTED",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED"
};

function ProfileMainContainer(props) {
  const KYCStatus = props.userData.KYC.STATUS;
  const { addressChangeStatus } = props.userData.KYC;
  const dispatch = useDispatch();
  const { addressUpdateStatus } = useSelector((state) => state.setting);
  const [showAddressUpdateModal, setShowAddressUpdateModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [reviewStatus, setReviewStatus] = useState({
    message: "",
    status: ""
  });

  useEffect(() => {
    if (addressUpdateStatus.type === "SUCCESS") {
      setShowAddressUpdateModal(false);
      setReviewStatus({
        message: "",
        status: ""
      });
      dispatch(resetAddressUpdate());
      setShowSuccessPopup(true);
    } else if (addressUpdateStatus.type === "ERROR") {
      showErrorMsg(addressUpdateStatus.message || "Unable to upload address");
    }
  }, [addressUpdateStatus]);

  useEffect(() => {
    if (
      addressChangeStatus === ADDRESS_STATUS_CONSTANTS.APPROVED ||
      addressChangeStatus === ADDRESS_STATUS_CONSTANTS.REJECTED
    ) {
      getUserAddressStatus()
        .then((response) => {
          const { data } = response;

          setReviewStatus({
            status: data?.data?.status,
            message: data?.data?.comment
              ? data?.data?.comment
              : "The address has been updated successfully"
          });
        })
        .catch((e) => {
          setReviewStatus({
            message: "",
            status: ""
          });
        });
    }
  }, [addressChangeStatus]);

  const handleShowUpdateAddressModal = () => {
    setShowAddressUpdateModal((prev) => !prev);
  };

  const handleCloseMsgPopup = () => {
    setShowSuccessPopup(false);
  };

  const handleUpdateAddressSubmit = (data, country, image, documentType) => {
    const formData = new FormData();
    formData.append("AddressLine1", data?.street);
    formData.append("AddressLine2", data?.flatNo);
    formData.append("City", data?.city);
    formData.append("Country", country);
    formData.append("State", data?.state);
    formData.append("ZipCode", data?.zipcode);
    formData.append("documentType", "ADDRESSPROOF");
    formData.append("typeOfDocument", documentType);
    formData.append("image", image[0]);

    dispatch(postAddressUpdate(formData));
  };

  return (
    <MainElement display="flex" mt="16px" gap="16px" directionLg="column">
      <BannerSection width="65%" gap="24px" display="flex" direction="column" widthLg="100%">
        <Verificationtab {...props} />
        {KYCStatus === "LEVEL2" && (
          <UserDetailsSection
            {...props}
            onShowUpdateAddressModal={handleShowUpdateAddressModal}
            onUpdateAddressSubmit={handleUpdateAddressSubmit}
            showAddressUpdateModal={showAddressUpdateModal}
            reviewStatus={reviewStatus}
          />
        )}
        {KYCStatus === "LEVEL2" && <DocumentsUploaded {...props} />}
      </BannerSection>
      <BannerSection width="35%" widthLg="100%">
        <ProfileFaqSection />
      </BannerSection>
      {showSuccessPopup && <ShowSuccessPopup onClose={handleCloseMsgPopup} />}
    </MainElement>
  );
}

export default ProfileMainContainer;
