import { useEffect, lazy, Suspense, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import { BannerBox, MainElement, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import MarketCapComponent from "../../components/MarketCap/marketCapComponent";
import { trackPage } from "../../tracking/track";
import MarketHOC from "./MarketHOC";
import { HalfFooterCommon } from "../../components/Footer/common";
import { getTranslationLanguageList, getUserSelectedLang } from "../../Modules/Generic/selectors";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";
// import MarqueeComponent from "../indexPage/subHeader";

const MarketTableComponent = lazy(() => import("../../components/MarketTable/marketTable"));

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList(),
  userSelectedLang: getUserSelectedLang()
});

const MarketComponent = ({ isLoggedIn }) => {
  const location = useLocation();
  // const history = useHistory();
  const dispatch = useDispatch();
  // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // const userData = useSelector((state) => state.dashboard.userData);
  const { transalationList } = useSelector(GenericReducer);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    dispatch(fetchAvailableLanguagesRequest());
    // let checkPath = location.pathname?.split("/")[1];
    // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
    //   history.push(isLoggedIn ? `/${userData.preferredLanguage}/markets` : "/markets");
    // }

    let page = location?.pathname + location?.search;
    trackPage(page);
  }, []);

  let SEOContents = {
    name: `MARKETS`,
    link: `markets`,
    title: "Explore Dynamic Crypto Trading Markets Today | LCX Exchange",
    description:
      "Discover diverse crypto trading markets. Trade Bitcoin, Ethereum, and more securely with fast transactions and expert support. Start trading today!",
    keywords: `crypto trading market, crypto trade market`,
    canonical: `https://exchange.lcx.com/markets`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}/markets`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: "Explore Dynamic Crypto Trading Markets Today | LCX Exchange",
    OgDescription:
      "Discover diverse crypto trading markets. Trade Bitcoin, Ethereum, and more securely with fast transactions and expert support. Start trading today!",
    OgURL: `https://exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/markets`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Explore Dynamic Crypto Trading Markets Today | LCX Exchange",
    TwDescription:
      "Discover diverse crypto trading markets. Trade Bitcoin, Ethereum, and more securely with fast transactions and expert support. Start trading today!"
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${prevLang}/markets`
      };
      return obj;
    });
  }

  // const handleOnClickMarquee = () => {
  //   window.open("https://www.lcx.com/cardano-summit-dubai/");
  // };

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <BannerSection SectionGrey position="relative" minHeight="89vh">
        {/* {!isLoggedIn && (
          <MarqueeComponent
            text={new Array(10).fill("LCX AT CARDANO SUMMIT 2024", 0, 10)}
            onClick={handleOnClickMarquee}
          />
        )} */}
        <MainElement paddingMd="0px 8px" paddingLg="32px 64px" padding="56px 0px">
          <BannerBox ExchangeContainer widthLg="100%">
            <BannerBox display="flex" direction="column" gap="32px" gapMd="24px">
              <MarketCapComponent />
              <BannerBox mbMd="15px">
                <Suspense fallback={null}>
                  <MarketTableComponent />
                </Suspense>
              </BannerBox>
            </BannerBox>
          </BannerBox>
        </MainElement>
      </BannerSection>
      <BannerBox width="100%">
        <HalfFooterCommon />
      </BannerBox>
    </>
  );
};

export default MarketHOC(MarketComponent);
