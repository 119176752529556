import React from "react";
import { BannerSection, BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { DocumentIcon } from "../../../../assets/svgs";
import { HeadingThird } from "../../../../assets/StyleComponents/FontSize/headingThird.style";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { capitalize } from "../../../../utils";

function DocumentsUploaded({ userData }) {
  const { idType, addressProofType } = userData;

  function ResidenceProofConvert(value) {
    switch (value) {
      case "bankStatement":
        return "Bank Statement";
      case "electricityBill":
        return "Electricity Bill";
      case "otherUtilityBill":
        return "Other Utility Bill";
      case "cableBill":
        return "Cable Bill";
      default:
        return value;
    }
  }
  return (
    <BannerSection SectionWhite padding="24px" radius="10px">
      {/* Title Section */}
      <BannerBox display="flex" width="100%" gap="4px" mb="40px">
        <DocumentIcon marginRight="6px" />
        <HeadingThird size="18px" weight="500" sizeMd="16px">
          Documents Uploaded
        </HeadingThird>
      </BannerBox>

      <BannerSection display="flex" alignItems="center" gap="24px" mt="24px">
        <BannerBox
          rightBorder="0.5px solid"
          borderColor
          display="flex"
          direction="column"
          gap="12px"
          pr="20px"
        >
          <HeadingThird size="16px" weight="500" display="flex" alignItems="center" gap="6px">
            ID Verification
          </HeadingThird>
          <ParaTag size="14px" weight="400" bodyColor>
            {idType === "NATIONALID" ? "National ID" : capitalize(idType ? idType : "-")}
          </ParaTag>
        </BannerBox>
        <BannerBox display="flex" direction="column" gap="12px">
          <HeadingThird size="16px" weight="500" display="flex" alignItems="center" gap="6px">
            Residence Proof
          </HeadingThird>
          <ParaTag size="14px" weight="400" bodyColor>
            {ResidenceProofConvert(addressProofType)}
          </ParaTag>
        </BannerBox>
      </BannerSection>
    </BannerSection>
  );
}

export default DocumentsUploaded;
