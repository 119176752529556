import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton
} from "react-share";
import {
  DownloadRefralIcon,
  TelegramReferalIcon,
  TwitterReferalIcon,
  WhatsAppIcon
} from "../../../../assets/svgs";
// import { AnchorTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { DivElement } from "../../../../components/Reusable";

export default function ShareButton({ referalLink, content, enabled, images, onClickDownload }) {
  return (
    <>
      <DivElement onClick={onClickDownload}>
        <DownloadRefralIcon width="48px" height="48px" pointer />
      </DivElement>
      <FacebookShareButton url={referalLink} quote={content}>
        <FacebookIcon size={48} round={true} />
      </FacebookShareButton>
      <TwitterShareButton title={`${content}`} url={referalLink}>
        <TwitterReferalIcon width="48px" height="48px" style={{ borderRadius: "50%" }} />
      </TwitterShareButton>
      <WhatsappShareButton title={`${content}`} url={referalLink}>
        <WhatsAppIcon width="48px" height="48px" style={{ borderRadius: "50%" }} />
      </WhatsappShareButton>
      <TelegramShareButton title={`${content} ${referalLink}`} url={referalLink}>
        <TelegramReferalIcon width="48px" height="48px" style={{ borderRadius: "50%" }} />
      </TelegramShareButton>
    </>
  );
}
