import React, { useState } from "react";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { QuestionMark, RewardsHubCardIcon } from "../../../../assets/svgs";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { useSelector } from "react-redux";
import { Skeleton } from "../../../../components/Loader";
import ShowRulesModal from "./ShowRulesModal";
import ShowSingleButton from "../ShowSingleButton/ShowSingleButton";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
// import RewardsBadgeComponent from "../BadgeComponent";

const ListTimeOffer = () => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [singleRecords, setSingleRecords] = useState("");
  const rewardsOnboardingList = useSelector(state => state.rewards.rewardsOnboardingList);
  const { width } = useWindowSize();
  const handleRulesStatus = (givenItem) => {
    setSingleRecords(givenItem);
    setShowModal(true);
  };

  const onClose = () => {
    setSingleRecords("");
    setShowModal(false);
  };

  return (
    <BannerBox display="flex" gap="20px" direction="column">
      {showModal && <ShowRulesModal onClose={onClose} rulesRecords={singleRecords} />}
      {rewardsOnboardingList &&
        rewardsOnboardingList.length === 0 && [1,2,3,4].map(item => (
          <BannerBox
            key={item}
            CardGrey
            padding="20px"
            radius="8px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton height="40px" width="100%" marginBottom="20px" direction="ltr" />
          </BannerBox>
      ))}
      {rewardsOnboardingList &&
        rewardsOnboardingList.length > 0 &&
        rewardsOnboardingList.map((item, index) => {
          return (
            <>
              <BannerBox
                key={index}
                CardGrey={mouseEnter === index ? false : true}
                CardGrey40Less={mouseEnter === index ? true : false}
                // border={(!isTheme && mouseEnter === index) ? true : false}
                BorderOpacity0={true}
                CardBorderPrimary={(mouseEnter === index ) ? true : false }
                cursorPointer="pointer"
                padding="20px"
                radius="8px"
                display="flex"
                directionMd="column"
                justifyContent="space-between"
                alignItems="center"
                alignItemsMd="flex-start"
                gapMd="12px"

                onMouseEnter={() => setMouseEnter(index)}
                onMouseLeave={() => setMouseEnter(null)}
              >
                <BannerBox display="flex" gap="8px" direction="column">
                  <BannerHeading size="20px" weight="500">
                    {item.name}
                    {(item?.description || item?.additionalRule?.length > 0) && (
                      <BannerHeadingSpan ml="6px" display="inline-flex" verticalAlign="middle" cursorPointer onClick={() => handleRulesStatus(item)}>
                        <QuestionMark width="20px" />
                      </BannerHeadingSpan>
                    )}
                  </BannerHeading>
                  <BannerHeading display="flex" gap="8px" size="14px" weight="500">
                    <RewardsHubCardIcon width="20px" height="20px" />
                    <BannerHeadingSpan size="14px" weight="500" txtPrimary>
                      {`${item.rewardType === "mysterybox" && 'upto '}${item.rewardAmount || 0} ${item.rewardCoin}`}
                    </BannerHeadingSpan>
                    {item.rewardType === "fixed" ? "Fixed Amount" : "Mystery box"}
                  </BannerHeading>
                </BannerBox>
                
                {width > 767 && 
                  <ShowSingleButton records={item} MouseStatus={mouseEnter === index} index={mouseEnter} />
                }
              </BannerBox>
              {width < 768 && 
                <ShowSingleButton records={item} MouseStatus={mouseEnter === index} />
              }
            </>
          );
        })}
    </BannerBox>
  );
};

export default ListTimeOffer;
