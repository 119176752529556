/* eslint-disable no-debugger */
import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  isMarketLists: [],
  isMarketListFetching: false,
  isMarketListError: "",
  selectedPair: {},
  selectedPairData:
    localStorage.getItem("pairData") !== null ? JSON.parse(localStorage.getItem("pairData")) : {},
  isSelectedPairFecthing: false,
  isSelectedPairError: "",
  askPairData: [],
  isAskPairDataFecthing: false,
  isAskPairDataError: "",
  bidPairData: [],
  isBidPairDataFecthing: false,
  isBasePairDataError: "",
  tradePairData: [],
  isTradePairDataFecthing: false,
  isTradePairDataError: "",
  isFetchingTradeSetting: false,
  isTradeSetting:
    localStorage.getItem("tradeSetting") !== null
      ? JSON.parse(localStorage.getItem("tradeSetting"))
      : false,
  isFetchingResetTable: false,
  isResetTable: false,
  isFetchingCancelTradeData: false,
  isCancelData: false,
  pairConfig: {},
  isPairConfigFecthing: false,
  pairsConfig: {},
  isPairsConfigFecthing: false,
  selectedOrderPrice: null,
  selectedOrderSum: null,
  selectedOrderType: "",
  isOpenOrderFetching: true,
  filtersOrders: {
    side: "",
    pairs: "",
    fromDate: "",
    toDate: ""
  },

  openOrdersOffSet: 1,
  openOrders: [],
  openOrdersCount: 0,
  isUserTradesFetching: true,
  userTradesOffSet: 1,
  userTrades: [],
  userTradesCount: 0,
  isTradeBalanceFetching: false,
  tradeBalance: {},
  isTradeHistoryFetching: false,
  tradeOpenHistory: null,
  isOrderHistoryListOffSet: 1,
  isOrderHistoryList: [],
  isOrderHistoryListCount: 0,
  isOrderHistoryListFetching: false,
  walletBalance: "",
  walletBalanceCopy: "",
  errorGet: false,
  timeZone: "",
  isTimeZoneFecthing: false,
  favPairList:
    localStorage.getItem("Activetabs") !== null
      ? JSON.parse(localStorage.getItem("Activetabs"))
      : [],
  isPairListFecthing: false,
  socketTickerData: {},
  isTickerData: false
};

let initialState = initialStateSchema;

const TradeReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.CANCEL_TRADE_SETTING:
      newState = {
        ...state,
        isFetchingCancelTradeData: true,
        errorGet: false
      };
      return newState;

    case apiConstants.CANCEL_TRADE_SETTING_SUCCESS:
      newState = {
        ...state,
        isCancelData: action.response,
        isFetchingCancelTradeData: false,
        errorGet: false
      };
      return newState;

    case apiConstants.CANCEL_TRADE_SETTING_FAIL:
      newState = {
        ...state,
        isFetchingCancelTradeData: false,
        errorGet: false
      };
      return newState;

    case apiConstants.RESET_TRADE_SETTING:
      newState = {
        ...state,
        isFetchingResetTable: true,
        errorGet: false
      };
      return newState;

    case apiConstants.RESET_TRADE_SETTING_SUCCESS:
      newState = {
        ...state,
        isResetTable: action.response,
        isFetchingResetTable: false,
        errorGet: false
      };
      return newState;

    case apiConstants.RESET_TRADE_SETTING_FAIL:
      newState = {
        ...state,
        isFetchingResetTable: false,
        errorGet: false
      };
      return newState;

    case apiConstants.TRADE_SETTING:
      newState = {
        ...state,
        isFetchingTradeSetting: true,
        errorGet: false
      };
      return newState;

    case apiConstants.TRADE_SETTING_SUCCESS:
      newState = {
        ...state,
        isTradeSetting: action.response,
        isFetchingTradeSetting: false,
        errorGet: false
      };
      return newState;

    case apiConstants.TRADE_SETTING_FAIL:
      newState = {
        ...state,
        isFetchingTradeSetting: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_LISTS:
      newState = {
        ...state,
        isMarketListFetching: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_LISTS_SUCCESS:
      var getTradeData = action.response;
      if (getTradeData)
        for (let key in getTradeData)
          for (let sub in getTradeData[key])
            if (typeof getTradeData[key][sub] == "number")
              getTradeData[key][sub] = getTradeData[key][sub].noExponents();

      newState = {
        ...state,
        isMarketLists: Object.values(getTradeData),
        isMarketListFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_MARKET_LISTS_FAIL:
      newState = {
        ...state,
        isMarketListFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_ORDER_HISTORY:
      newState = {
        ...state,
        filtersOrders: {
          side: "",
          pairs: "",
          fromDate: "",
          toDate: ""
        },
        isOrderHistoryListCount: 0,
        isOrderHistoryListFetching: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_ORDER_HISTORY_SUCCESS:
      if (action.payload) {
        newState = {
          ...state,
          filtersOrders: {
            side: action.payload?.side || "",
            pairs: action.payload?.pairs || "",
            fromDate: action.payload?.fromDate || "",
            toDate: action.payload?.toDate || ""
          },
          isOrderHistoryList: action.response.data.data,
          isOrderHistoryListCount: action.response.data.totalCount,
          isOrderHistoryListFetching: false,
          errorGet: false
        };
      } else {
        newState = {
          ...state,
          filtersOrders: {
            side: "",
            pairs: "",
            fromDate: "",
            toDate: ""
          },
          isOrderHistoryList: action.response.data.data,
          isOrderHistoryListCount: action.response.data.totalCount,
          isOrderHistoryListFetching: false,
          errorGet: false
        };
      }
      return newState;

    case apiConstants.GET_ORDER_HISTORY_FAIL:
      newState = {
        ...state,
        filtersOrders: {
          side: "",
          pairs: "",
          fromDate: "",
          toDate: ""
        },
        isOrderHistoryListCount: 0,
        isOrderHistoryListFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.SELECTED_PAIR:
      newState = {
        ...state,
        isSelectedPairFecthing: true,
        errorGet: false
      };
      return newState;

    case apiConstants.SELECTED_PAIR_SUCCESS: // Call Shuffle Array Function For Base Bid
      newState = {
        ...state,
        selectedPair: action.response,
        isSelectedPairFecthing: false,
        errorGet: false
      };
      return newState;

    case apiConstants.SELECTED_PAIR_FAIL:
      newState = {
        ...state,
        isSelectedPairFecthing: false,
        errorGet: false
      };
      return newState;

    case apiConstants.ASK_PAIR_LIST:
      newState = {
        ...state,
        askPairData: [],
        bidPairData: [],
        isAskPairDataFecthing: true,
        errorGet: false
      };
      return newState;

    case apiConstants.ASK_PAIR_LIST_SUCCESS:
      newState = {
        ...state,
        bidPairData: action.response.buy
          ? action.response.buy.sort((a, b) => {
              return b[0] - a[0];
            })
          : [],
        askPairData: action.response.sell
          ? action.response.sell.sort((a, b) => {
              return b[0] - a[0];
            })
          : [],
        isAskPairDataFecthing: false,
        errorGet: false
      };
      return newState;

    case apiConstants.ASK_PAIR_LIST_FAIL:
      newState = {
        ...state,
        isAskPairDataFecthing: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_TRADE_PAIR_CONFIG:
      newState = {
        ...state,
        isPairConfigFecthing: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_TRADE_PAIR_CONFIG_SUCCESS:
      newState = {
        ...state,
        pairConfig: action.response,
        isPairConfigFecthing: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_TRADE_PAIR_CONFIG_FAIL:
      newState = {
        ...state,
        isPairConfigFecthing: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_TRADE_PAIRS_CONFIG:
      newState = {
        ...state,
        isPairsConfigFecthing: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_TRADE_PAIRS_CONFIG_SUCCESS:
      newState = {
        ...state,
        pairsConfig: action.response,
        isPairsConfigFecthing: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_TRADE_PAIRS_CONFIG_FAIL:
      newState = {
        ...state,
        isPairsConfigFecthing: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_RECENT_TRADE_HISTROY:
      newState = {
        ...state,
        isTradePairDataFecthing: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_RECENT_TRADE_HISTROY_SUCCESS:
      newState = {
        ...state,
        tradePairData: action.response,
        isTradePairDataFecthing: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_RECENT_TRADE_HISTROY_FAIL:
      newState = {
        ...state,
        isTradePairDataFecthing: false,
        errorGet: false
      };
      return newState;

    case apiConstants.SET_SELECTED_PRICE:
      newState = {
        ...state,
        errorGet: false
      };
      return newState;

    case apiConstants.SET_SELECTED_PRICE_SUCCESS:
      newState = {
        ...state,
        selectedOrderPrice: action.response.price,
        selectedOrderSum: action.response.sum,
        selectedOrderType: action.response.type || "",
        errorGet: false
      };
      return newState;

    case apiConstants.SET_SELECTED_PRICE_FAIL:
      newState = {
        ...state
      };
      return newState;

    case apiConstants.SET_PAIR_DATA:
      newState = {
        ...state,
        errorGet: false
      };
      return newState;

    case apiConstants.SET_PAIR_DATA_SUCCESS:
      newState = {
        ...state,
        selectedPairData: action.response,
        errorGet: false
      };
      return newState;

    case apiConstants.SET_PAIR_DATA_FAIL:
      newState = {
        ...state
      };
      return newState;

    case apiConstants.GET_OPEN_ORDERS:
      newState = {
        ...state,
        filtersOrders: {
          side: "",
          pairs: "",
          fromDate: "",
          toDate: ""
        },
        openOrdersOffSet: 1,
        openOrders: [],
        errorGet: false,
        isOpenOrderFetching: true
      };
      return newState;

    case apiConstants.GET_OPEN_ORDERS_SUCCESS:
      if (action.payload) {
        newState = {
          ...state,
          filtersOrders: {
            side: action.payload?.side || "",
            pairs: action.payload?.pairs || "",
            fromDate: action.payload?.fromDate || "",
            toDate: action.payload?.toDate || ""
          },
          openOrdersOffSet: action.payload.offset || 1,
          openOrders: action.response.data,
          openOrdersCount: action.response.totalCount,
          errorGet: false,
          isOpenOrderFetching: false
        };
      } else {
        newState = {
          ...state,
          filtersOrders: {
            side: "",
            pairs: "",
            fromDate: "",
            toDate: ""
          },
          openOrders: action.response.data,
          openOrdersCount: action.response.totalCount,
          isOpenOrderFetching: false,
          errorGet: false
        };
      }
      return newState;

    case apiConstants.GET_OPEN_ORDERS_FAIL:
      newState = {
        ...state,
        filtersOrders: {
          side: "",
          pairs: "",
          fromDate: "",
          toDate: ""
        },
        openOrdersOffSet: 1,
        openOrders: [],
        openOrdersCount: 0,
        isOpenOrderFetching: false,
        errorGet: false
      };
      return newState;

    // case apiConstants.GET_RECENT_TRADE_HISTROY:
    //   newState = {
    //     ...state,
    //     isUserTradesFetching: true,
    //     userTrades: [],
    //     userTradesCount: 0,
    //     errorGet: false
    //   };
    //   return newState;

    // case apiConstants.GET_RECENT_TRADE_HISTROY_SUCCESS:
    //   newState = {
    //     ...state,
    //     isUserTradesFetching: false,
    //     userTrades: action.response.orders,
    //     userTradesCount: action.response.totalCount,
    //     errorGet: false
    //   };
    //   return newState;

    // case apiConstants.GET_RECENT_TRADE_HISTROY_FAIL:
    //   newState = {
    //     ...state,
    //     isUserTradesFetching: false,
    //     userTrades: [],
    //     userTradesCount: 0,
    //     errorGet: false
    //   };
    //   return newState;

    case apiConstants.GET_TRADE_HISTROY:
      newState = {
        ...state,
        filtersOrders: {
          side: "",
          pairs: "",
          fromDate: "",
          toDate: ""
        },
        userTrades: [],
        userTradesCount: 1,
        isUserTradesFetching: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_TRADE_HISTROY_SUCCESS:
      if (action.payload) {
        newState = {
          ...state,
          filtersOrders: {
            side: action.payload?.side || "",
            pairs: action.payload?.pairs || "",
            fromDate: action.payload?.fromDate || "",
            toDate: action.payload?.toDate || ""
          },
          userTrades: action.response.data,
          userTradesCount: action.response.totalCount,
          isUserTradesFetching: false,
          errorGet: false
        };
      } else {
        newState = {
          ...state,
          filtersOrders: {
            side: "",
            pairs: "",
            fromDate: "",
            toDate: ""
          },
          userTrades: action.response.data,
          userTradesCount: action.response.totalCount,
          isUserTradesFetching: false,
          errorGet: false
        };
      }
      return newState;

    case apiConstants.GET_TRADE_HISTROY_FAIL:
      newState = {
        ...state,
        filtersOrders: {
          side: "",
          pairs: "",
          fromDate: "",
          toDate: ""
        },
        userTrades: [],
        userTradesCount: 0,
        isUserTradesFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_WALLET_BALANCE:
      newState = {
        ...state,
        isTradeBalanceFetching: true
      };
      return newState;

    case apiConstants.GET_WALLET_BALANCE_SUCCESS:
      var tradeBalance = state.tradeBalance;
      if (action.response.data && action.response.data.Coin)
        tradeBalance[action.response.data.Coin] = action.response.data;

      newState = {
        ...state,
        tradeBalance: tradeBalance,
        isTradeBalanceFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_WALLET_BALANCE_FAIL:
      newState = {
        ...state,
        isTradeBalanceFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.SET_ORDER_HISTORY:
      newState = {
        ...state,
        isTradeHistoryFetching: true,
        errorGet: false
      };
      return newState;

    case apiConstants.SET_ORDER_HISTORY_SUCCESS:
      newState = {
        ...state,
        tradeOpenHistory: action.response,
        isTradeHistoryFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.SET_ORDER_HISTORY_FAIL:
      newState = {
        ...state,
        isTradeHistoryFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_WALLET_BALANCE_DATA:
      newState = {
        ...state,
        isTradeBalanceFetching: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_WALLET_BALANCE_DATA_SUCCESS:
      var newbalance = {};
      var array = action.response.data;
      for (let i = 0; i < array.length; i++) {
        newbalance[array[i].coin] = {
          Coin: array[i].coin,
          Decimals: array[i].balance.decimals,
          FreeBalance: array[i].balance.freeBalance,
          OccupiedBalance: array[i].balance.occupiedBalance,
          TotalBalance: array[i].balance.totalBalance
        };
      }
      newState = {
        ...state,
        tradeBalance: newbalance,
        isTradeBalanceFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_WALLET_BALANCE_DATA_FAIL:
      newState = {
        ...state,
        tradeBalance: {},
        isTradeBalanceFetching: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_TIME_ZONE:
      newState = {
        ...state,
        isTimeZoneFecthing: true
      };
      return newState;

    case apiConstants.GET_TIME_ZONE_SUCCESS:
      newState = {
        ...state,
        timeZone: action.response?.data,
        isTimeZoneFecthing: false
      };
      return newState;

    case apiConstants.GET_TIME_ZONE_FAIL:
      newState = {
        ...state,
        isTimeZoneFecthing: false
      };
      return newState;

    case apiConstants.FAV_PAIR_LIST:
      newState = {
        ...state,
        isPairListFecthing: true
      };
      return newState;

    case apiConstants.FAV_PAIR_LIST_SUCCESS:
      newState = {
        ...state,
        favPairList: action.response,
        isPairListFecthing: false
      };
      return newState;

    case apiConstants.FAV_PAIR_LIST_FAIL:
      newState = {
        ...state,
        isPairListFecthing: false
      };
      return newState;

    case apiConstants.UPDATE_TICKER_DATA:
      newState = {
        ...state,
        isTickerData: true
      };
      return newState;

    case apiConstants.UPDATE_TICKER_DATA_SUCCESS:
      newState = {
        ...state,
        socketTickerData: action.response,
        isTickerData: false
      };
      return newState;

    case apiConstants.UPDATE_TICKER_DATA_FAIL:
      newState = {
        ...state,
        isTickerData: false
      };
      return newState;

    case apiConstants.UPDATE_SOCKET_BALANCE_SUCCESS:
      var newBalance = {};
      var newWalletBalance = state.walletBalance ? { ...state.walletBalance } : "";
      var newWalletBalanceCopy = state.walletBalanceCopy ? { ...state.walletBalanceCopy } : "";
      if (action.response.wallet) {
        newWalletBalance = action.response.walletBalance || newWalletBalance;
        newWalletBalanceCopy = action.response.walletBalanceCopy || newWalletBalanceCopy;
      } else {
        let array = action.response.data;
        let hash = {};
        for (let i = 0; i < array.length; i++) {
          newBalance[array[i].coin] = {
            Coin: array[i].coin,
            Decimals: array[i].balance.decimals,
            FreeBalance: array[i].balance.freeBalance,
            OccupiedBalance: array[i].balance.occupiedBalance,
            TotalBalance: array[i].balance.totalBalance
          };
          hash[array[i].coin] = array[i];
        }

        if (newWalletBalance) {
          for (let i = 0; i < newWalletBalance.data.length; i++)
            newWalletBalance.data[i] = hash[newWalletBalance.data[i].coin];
          newWalletBalance.totalBalance = action.response.totalBalance;
        }

        if (newWalletBalanceCopy) {
          for (let i = 0; i < newWalletBalanceCopy.data.length; i++)
            newWalletBalanceCopy.data[i] = hash[newWalletBalanceCopy.data[i].coin];
          newWalletBalanceCopy.totalBalance = action.response.totalBalance;
        }
      }
      newState = {
        ...state,
        walletBalance: newWalletBalance,
        walletBalanceCopy: newWalletBalanceCopy,
        tradeBalance: action.response.wallet ? state.tradeBalance : newBalance,
        errorGet: false
      };
      return newState;

    case apiConstants.UPDATE_SOCKET_ORDERS_SUCCESS:
      var url = window.location.href;
      var order = action.response;
      var pair = state.pairConfig;
      var ordersOp = state.openOrders;
      var ordersOpCount = state.openOrdersCount;
      var orderHi = state.isOrderHistoryList;
      var orderHiCount = state.isOrderHistoryListCount;
      var count = false;
      if (order.Status == "OPEN" || order.Status == "PARTIAL") {
        count = false;
        for (let i = 0; i < ordersOp.length; i++)
          if (ordersOp[i].Id === order.Id) {
            count = true;
            ordersOp[i] = order;
          }
        if (
          !count &&
          (!url.includes("trade") || (url.includes("trade") && order.Pair === pair.symbol))
        ) {
          ordersOpCount = ordersOpCount + 1;
          ordersOp.unshift(order);
        }
      } else {
        ordersOp = ordersOp.filter((o) => {
          return o.Id !== order.Id;
        });
        let count = false;
        for (let i = 0; i < orderHi.length; i++)
          if (orderHi[i].Id === order.Id) {
            count = true;
            orderHi[i] = order;
          }

        if (
          !count &&
          (!url.includes("trade") || (url.includes("trade") && order.Pair === pair.symbol))
        ) {
          if (ordersOpCount > 1) ordersOpCount = ordersOpCount - 1;
          orderHiCount = orderHiCount + 1;
          orderHi.unshift(order);
        }
      }
      newState = {
        ...state,
        openOrders: ordersOp.slice(0, 25),
        openOrdersCount: ordersOpCount,
        isOrderHistoryList: orderHi.slice(0, 25),
        isOrderHistoryListCount: orderHiCount,
        errorGet: false
      };
      return newState;

    case apiConstants.ERROR_500:
      newState = {
        ...state,
        errorGet: true
      };
      return newState;

    case apiConstants.RESET_FILTER_ORDERS_SUCCESS:
      newState = {
        ...state,
        filtersOrders: {
          side: "",
          pairs: "",
          fromDate: "",
          toDate: ""
        }
      };
      return newState;

    default:
      return state;
  }
};

export default TradeReducer;

// Shuffle Array Function
// const arrayShuffle = (arr) => {
//   let newPos, temp;
//   for (let i = arr.length - 1; i > 0; i--) {
//     newPos = Math.floor(Math.random() * (i + 1));
//     temp = arr[i];
//     arr[i] = arr[newPos];
//     arr[newPos] = temp;
//   }
//   return arr;
// };
