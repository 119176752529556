/**
 * Import Node Modules
 */

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { BannerBox, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../constants/Images/imageIndex";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import MobileAppComponent from "./mobileAppComponent/mobileComponent";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ImgElement } from "../../components/Reusable";
import { FigureCaptionComponent, FigureComponent } from "../../assets/StyleComponents/Figure.style";

/**
 * Import Other Dependices
 */

// class IntroPart extends Component {
const IntroPart = (props) => {
  const { width } = useWindowSize();
  const [firstImg, setFirstImg] = useState(IMAGE_PREFIX.AvatarIconLight);
  const [secondImg, setSecondImg] = useState(IMAGE_PREFIX.CameraIconLight);
  const [thirdImg, setThirdImg] = useState(IMAGE_PREFIX.CoinIconLight);
  const [fourthImg, setFourthImg] = useState(IMAGE_PREFIX.ArrowIconLight);
  const [dark, setDark] = useState({
    firstImg: IMAGE_PREFIX_DARK.AvatarIconDark,
    secondImg: IMAGE_PREFIX_DARK.CameraIconDark,
    thirdImg: IMAGE_PREFIX_DARK.CoinIconDark,
    fourthImg: IMAGE_PREFIX_DARK.ArrowIconDark
  });
  const history = useHistory();

  const Redirect = () => {
    history.push("/register");
  };

  let { isTheme } = props;
  return (
    <BannerSection MainPageSectionBg>
      <BannerBox
        paddingLg="0px 52px"
        paddingMd="0px 6px"
        position="relative"
        width="100%"
        maxWidth="1124px"
        margin="0 auto"
        boxSizing="border-box"
        widthXl="95%"
        widthLg="95%"
        maxWidthLg="992px"
        widthMd="95%"
      >
        <BannerBox display="flex" textAlign="center">
          <BannerBox width="100%">
            <BannerHeading BodyColor size="44px" sizeMd="24px" sizeLg="36px" textAlign="center">
              Why should you choose LCX Exchange?
            </BannerHeading>
          </BannerBox>
        </BannerBox>

        <BannerBox
          display="grid"
          displayLg="flex"
          directionLg="column"
          mt="48px"
          mtLg="32px"
          mtMd="24px"
          gridCols="repeat(3,1fr)"
          width="100%"
        >
          <BannerBox
            display="flex"
            direction="column"
            directionLg="row"
            alignItems="center"
            alignItemsLg="start"
            gapLg="32px"
            gapMd="16px"
            directionMd="row"
          >
            <BannerBox display="flex" justifyContent="center" justifyContentLg="start">
              <ImgElement
                src={!isTheme ? IMAGE_PREFIX.HammerGif : IMAGE_PREFIX_DARK.HammerdarkGif}
                alt="regulated platform"
                width={width > 767 ? "96px" : "48px"}
                height={width > 767 ? "96px" : "48px"}
              />
            </BannerBox>
            <div>
              <BannerHeading
                BodyColor
                size="20px"
                sizeMd="16px"
                mbMd="16px"
                textAlign="center"
                textAlignLg="start"
                mt="24px"
                mtLg="0px"
              >
                Regulated Platform
              </BannerHeading>

              <ParaTag
                txtGrey
                weight="300"
                textAlign="center"
                mtMd="8px"
                mt="12px"
                textAlignLg="start"
                sizeMd="12px"
                size="16px"
              >
                LCX, licensed in Liechtenstein and regulated by its Financial Market Authority (No.
                288159), is a trusted technology service provider.
              </ParaTag>
            </div>
          </BannerBox>

          <BannerBox
            display="flex"
            direction="column"
            directionLg="row"
            alignItems="center"
            alignItemsLg="start"
            gapLg="32px"
            mtLg="64px"
            mtMd="24px"
            gapMd="16px"
          >
            <BannerBox display="flex" justifyContent="center" justifyContentLg="start">
              <ImgElement
                src={!isTheme ? IMAGE_PREFIX.SecurityGif : IMAGE_PREFIX_DARK.SecurityDarkGif}
                alt="regulated platform"
                width={width > 767 ? "96px" : "48px"}
                height={width > 767 ? "96px" : "48px"}
              />
            </BannerBox>
            <div>
              <BannerHeading
                size="20px"
                sizeMd="16px"
                mbMd="16px"
                textAlign="center"
                textAlignLg="start"
                mt="24px"
                mtLg="0px"
                BodyColor
              >
                Security
              </BannerHeading>

              <ParaTag
                txtGrey
                weight="300"
                textAlign="center"
                textAlignLg="start"
                mtMd="8px"
                mt="12px"
                sizeMd="12px"
                size="16px"
              >
                The LCX platform is crafted with cutting-edge security layers from the ground up.
              </ParaTag>
            </div>
          </BannerBox>

          <BannerBox
            display="flex"
            direction="column"
            directionLg="row"
            alignItems="center"
            alignItemsLg="start"
            gapLg="32px"
            mtLg="64px"
            mtMd="24px"
            gapMd="16px"
          >
            <BannerBox display="flex" justifyContent="center" justifyContentLg="start">
              <ImgElement
                src={
                  !isTheme
                    ? IMAGE_PREFIX.AdvancedTradingToolGif
                    : IMAGE_PREFIX_DARK.AdvancedTradingToolGif
                }
                alt="advanced Tradingf tool"
                width={width > 767 ? "96px" : "48px"}
                height={width > 767 ? "96px" : "48px"}
              />
            </BannerBox>
            <div>
              <BannerHeading
                BodyColor
                size="20px"
                sizeMd="16px"
                mbMd="16px"
                textAlign="center"
                textAlignLg="start"
                mt="24px"
                mtLg="0px"
              >
                Advanced Trading Tools
              </BannerHeading>

              <ParaTag
                txtGrey
                weight="300"
                textAlign="center"
                textAlignLg="start"
                mtMd="8px"
                mt="12px"
                sizeMd="12px"
                size="16px"
              >
                LCX offers investors a suite of advanced trading tools to maximize their trading
                strategies.
              </ParaTag>
            </div>
          </BannerBox>
        </BannerBox>

        {/* Mobile App section starts Here */}
        <MobileAppComponent {...props} />

        <BannerBox display="flex" alignItems="center" justifyContent="center">
          <BannerBox width="100%">
            <BannerHeading
              BodyColor
              size="44px"
              textAlign="center"
              sizeLg="36px"
              sizeMd="24px"
              weight="500"
            >
              Get started in a few simple steps
            </BannerHeading>
          </BannerBox>
        </BannerBox>

        <BannerBox
          display="flex"
          direction="row"
          directionLg="column"
          pt="48px"
          ptLg="32px"
          ptMd="24px"
          pbLg="0px"
          pbMd="0px"
        >
          <FigureComponent Circleparent width="25%" widthLg="100%" widthMd="100%" margin="0 0 1rem">
            <BannerBox CircleParentBox margin="0 auto">
              <BannerBox ImageParent>
                <ImgElement
                  FrontPageBanners
                  alt=""
                  src={!isTheme ? firstImg : dark.firstImg}
                  onMouseEnter={() => {
                    setFirstImg(IMAGE_PREFIX.AvatarIconGif);
                    setDark({
                      ...dark,
                      firstImg: IMAGE_PREFIX_DARK.AvatarIconGif
                    });
                  }}
                  onMouseOut={() => {
                    setFirstImg(IMAGE_PREFIX.AvatarIconLight);
                    setDark({
                      ...dark,
                      firstImg: IMAGE_PREFIX_DARK.AvatarIconDark
                    });
                  }}
                />
              </BannerBox>
            </BannerBox>

            <FigureCaptionComponent
              BodyColor
              textAlign="center"
              size="1.25rem"
              paddTop="24px"
              paddBottom="10px"
              paddTopLg="16px"
              paddBottomLg="0px"
            >
              <ButtonComponent
                SuccessButton
                minWidth="173px"
                height="44px"
                size="20px"
                widthLg="200px"
                onClick={Redirect}
                sizeMd="14px"
                sizeLg="16px"
              >
                Create Account
              </ButtonComponent>
            </FigureCaptionComponent>
          </FigureComponent>

          <FigureComponent
            Circleparent
            width="25%"
            widthLg="100%"
            widthMd="100%"
            margin="0 0 1rem"
            paddTopLg="54px"
            paddTopMd="38px"
            paddBottomLg="0px"
          >
            <BannerBox CircleParentBox margin="0 auto">
              <BannerBox ImageParent>
                <ImgElement
                  FrontPageBanners
                  alt=""
                  src={!isTheme ? secondImg : dark.secondImg}
                  onMouseEnter={() => {
                    setSecondImg(IMAGE_PREFIX.CameraIconGif);
                    setDark({
                      ...dark,
                      secondImg: IMAGE_PREFIX_DARK.CameraIconGif
                    });
                  }}
                  onMouseOut={() => {
                    setSecondImg(IMAGE_PREFIX.CameraIconLight);
                    setDark({
                      ...dark,
                      secondImg: IMAGE_PREFIX_DARK.CameraIconDark
                    });
                  }}
                />
              </BannerBox>
            </BannerBox>

            <FigureCaptionComponent
              BodyColor
              textAlign="center"
              size="1.25rem"
              paddTop="24px"
              paddBottom="10px"
              paddTopLg="16px"
              paddBottomLg="0px"
            >
              <ButtonComponent
                DisabledButton
                minWidth="173px"
                height="44px"
                size="20px"
                widthLg="200px"
                sizeMd="14px"
                sizeLg="16px"
              >
                Get KYC Done
              </ButtonComponent>
            </FigureCaptionComponent>
          </FigureComponent>

          <FigureComponent
            Circleparent
            width="25%"
            widthLg="100%"
            widthMd="100%"
            margin="0 0 1rem"
            paddTopLg="54px"
            paddTopMd="38px"
            paddBottomLg="0px"
          >
            <BannerBox CircleParentBox margin="0 auto">
              <BannerBox ImageParent>
                <ImgElement
                  FrontPageBanners
                  alt=""
                  src={!isTheme ? thirdImg : dark.thirdImg}
                  onMouseEnter={() => {
                    setThirdImg(IMAGE_PREFIX.CoinIconGif);
                    setDark({
                      ...dark,
                      thirdImg: IMAGE_PREFIX_DARK.CoinIconGif
                    });
                  }}
                  onMouseOut={() => {
                    setThirdImg(IMAGE_PREFIX.CoinIconLight);
                    setDark({
                      ...dark,
                      thirdImg: IMAGE_PREFIX_DARK.CoinIconDark
                    });
                  }}
                />
              </BannerBox>
            </BannerBox>

            <FigureCaptionComponent
              BodyColor
              textAlign="center"
              size="1.25rem"
              paddTop="24px"
              paddBottom="10px"
              paddTopLg="16px"
              paddBottomLg="0px"
            >
              <ButtonComponent
                DisabledButton
                minWidth="173px"
                height="44px"
                size="20px"
                widthLg="200px"
                sizeMd="14px"
                sizeLg="16px"
              >
                Deposit Fund
              </ButtonComponent>
            </FigureCaptionComponent>
          </FigureComponent>

          <FigureComponent
            width="25%"
            widthLg="100%"
            widthMd="100%"
            margin="0 0 1rem"
            paddTopLg="54px"
            paddTopMd="38px"
            paddBottomLg="0px"
          >
            <BannerBox CircleParentBox margin="0 auto">
              <BannerBox ImageParent>
                <ImgElement
                  FrontPageBanners
                  alt=""
                  src={!isTheme ? fourthImg : dark.fourthImg}
                  onMouseEnter={() => {
                    setFourthImg(IMAGE_PREFIX.ArrowIconGif);
                    setDark({
                      ...dark,
                      fourthImg: IMAGE_PREFIX_DARK.ArrowIconGif
                    });
                  }}
                  onMouseOut={() => {
                    setFourthImg(IMAGE_PREFIX.ArrowIconLight);
                    setDark({
                      ...dark,
                      fourthImg: IMAGE_PREFIX_DARK.ArrowIconDark
                    });
                  }}
                />
              </BannerBox>
            </BannerBox>

            <FigureCaptionComponent
              BodyColor
              textAlign="center"
              size="1.25rem"
              paddTop="24px"
              paddBottom="10px"
              paddTopLg="16px"
              paddBottomLg="0px"
            >
              <ButtonComponent
                DisabledButton
                minWidth="173px"
                height="44px"
                size="20px"
                widthLg="200px"
                sizeMd="14px"
                sizeLg="16px"
              >
                Start Trading
              </ButtonComponent>
            </FigureCaptionComponent>
          </FigureComponent>
        </BannerBox>
      </BannerBox>
    </BannerSection>
  );
};

export default withRouter(IntroPart);
