import { DivElement, ImgElement, TextWrapper } from "../../../../components/Reusable";
import SaleCountdown from "./SaleCountdown";
import { capitalize } from "../../../../utils";

const SaleCard = ({ data, onClick }) => {
  return (
    <DivElement
      bgCard
      width="269px"
      widthMd="calc(50% - 24px)"
      widthSm="calc(100% - 16px)"
      minWidthMd="310px"
      minWidthSm="328px"
      height="264px"
      heightSm="250px"
      margin="8px"
      marginMd="12px"
      marginSm="8px"
      borderRadius="5px"
      cursor
      onClick={onClick}
    >
      <DivElement
        padding="12px 12px 0 16px"
        height="63px"
        bdGrey
        borderBottom="1px solid"
        marginBottom="55px"
        relative
      >
        <DivElement flex justifyContent="flex-end" alignItems="flex-start">
          <SaleCountdown saleDetails={data} isDashboard={true} />
        </DivElement>
        <DivElement absolute left="16px" bottom="-39px">
          <ImgElement width="78px" height="78px" borderRadius="50%" src={data.logo} alt="logo" />
        </DivElement>
      </DivElement>
      <DivElement padding="0 16px 30px 16px">
        <TextWrapper
          fontSize="24px"
          fontSizesM="18px"
          fontWeight="bold"
          lineHeight="normal"
        >{`${data?.coin} Token`}</TextWrapper>
        <TextWrapper
          secondary
          fontSize="16px"
          marginTop="6px"
          marginTopSm="4px"
          lineHeight="normal"
        >
          {capitalize(data?.name)}
        </TextWrapper>
        <TextWrapper
          secondary
          fontSize="14px"
          marginTop="16px"
          marginTopSm="20px"
          lineHeight="normal"
        >
          Buy With:
        </TextWrapper>
        <TextWrapper fontSize="14px" marginTop="6px" lineHeight="normal">
          {data?.coins &&
            data.coins.map((coin, i) => `${coin}${i === data?.coins?.length - 1 ? "" : ","} `)}
        </TextWrapper>
      </DivElement>
    </DivElement>
  );
};

export default SaleCard;
