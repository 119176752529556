import { Range, getTrackBackground } from "react-range";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { DivElement } from "../../../../../components/Reusable";
import { setPrecision } from "../../../../../utils";

const MIN = 0;
const MAX = 100;
const STEP = 1;
const MARKS = [0, 25, 50, 75, 100];

const TrackContainer = styled(DivElement)`
  width: calc(100% - 10px);
  height: 12px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 5px;

  .slider-line {
    height: 2px;
    width: 100%;
  }

  .marker {
    border: 2px solid ${(props) => props.theme.primaryIcon};
    outline: none;
    position: absolute;
    z-index: 0;
    cursor: grab;
    user-select: none;
    touch-action: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .slider-percentage {
    display: none;
    position: absolute;
    bottom: 15px;
    padding: 4px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.primaryText};
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    background-color: ${(props) => props.theme.bgActiveTable};

    &:before {
      content: "";
      height: 6px;
      width: 6px;
      background-color: inherit;
      position: absolute;
      bottom: -3px;
      border-radius: 1px;
      transform: rotate(45deg);
    }
  }

  &:hover {
    .slider-percentage {
      display: flex;
    }
  }

  .marks-container {
    width: calc(100% + 10px);
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: -5px;
  }

  .marks {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.cardBg};
    border: 2px solid ${(props) => props.theme.sepColor};

    &.active {
      border-color: ${(props) => props.theme.primaryBlue};
    }
  }
`;

const thumbRenderer = (props) => (
  <DivElement {...props} className="marker" displayCenter bgCard>
    <DivElement className="slider-percentage">
      {setPrecision(props["aria-valuenow"] || 0, 2)}%
    </DivElement>
  </DivElement>
);

const Slider = ({ percentage, setPercentage }) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const colors = isDarkTheme ? ["#70a6e0", "#5c5f66"] : ["#0470e1", "#ccced0"];

  return (
    <DivElement paddingTop="16px">
      <Range
        step={STEP}
        min={MIN}
        max={MAX}
        values={[percentage]}
        onChange={(val) => setPercentage(val)}
        renderThumb={({ props }) => thumbRenderer({ ...props, style: null })}
        renderTrack={({ props, children }) => (
          <TrackContainer {...props}>
            <DivElement
              className="slider-line"
              style={{
                ...props.style,
                background: getTrackBackground({
                  values: [percentage],
                  colors: colors,
                  min: MIN,
                  max: MAX
                })
              }}
            ></DivElement>
            <DivElement className="marks-container">
              {MARKS &&
                MARKS.map((num) => (
                  <DivElement key={num} className={`marks ${num <= percentage ? "active" : ""}`} />
                ))}
            </DivElement>
            {children}
          </TrackContainer>
        )}
      />
    </DivElement>
  );
};

export default Slider;
