import HalfFooter from "./halfFooter";
import { FooterPart } from "../../assets/StyleComponents/Fotter.style";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";

export function HalfFooterCommon(props) {
  return (
    <FooterPart
      DashboardFotter
      displayLg="none"
      width="100%"
      {...props}
      position="relative"
      bottom="0px"
      zIndex="1"
    >
      <BannerBox
        boxShadow="0px 5px 15px rgba(22, 22, 35, 0.1)"
        bgRepeat="repeat-x"
        padding="1.5rem 2rem"
        width="100%"
        mb="0px"
        CardSky
      >
        <BannerBox ExchangeContainer>
          <HalfFooter {...props} />
        </BannerBox>
      </BannerBox>
    </FooterPart>
  );
}
