import OrderBookTable from "../../OrderBookTable";
import orderRowHoc from "./orderRowHoc";

const SingleOrderRowTablet = ({
  order,
  side,
  index,
  activeSide,
  currRowIndex,
  displayAvgSum,
  rowChildren,
  price,
  amount,
  total,
  SIDES,
  onClickHandler,
  onMouseOver
}) => {
  const isSell = side === "sell" ? true : false;
  const isAll = activeSide === "all" ? true : false;

  return (
    <OrderBookTable.Tr
      key={`order-${order[0]}`}
      active={displayAvgSum && currRowIndex >= index ? true : null}
      pointer
      data-tip
      data-tip-disable={!displayAvgSum}
      data-for={`avgSum${side}-${index}`}
      data-background-color="#FFC955"
      onClick={onClickHandler}
      onMouseOver={onMouseOver}
      bdDash={displayAvgSum && currRowIndex === index ? "buy" : null}
      direction="rtl"
      relative
    >
      {activeSide !== "all" && (
        <OrderBookTable.Td
          width="38%"
          textAlign={isSell && isAll ? "left" : "right"}
          padding="0 16px"
        >
          {total}
        </OrderBookTable.Td>
      )}
      <OrderBookTable.Td
        width={activeSide === "all" ? "50%" : "28.67%"}
        textAlign={isSell && isAll ? "left" : "right"}
        padding="0 16px"
      >
        {amount}
      </OrderBookTable.Td>

      <OrderBookTable.Td
        width={activeSide === "all" ? "50%" : "33.33%"}
        green={side === SIDES.BUY ? true : null}
        red={side === SIDES.SELL ? true : null}
        textAlign={isSell && isAll ? "right" : "left"}
        padding="0 28px"
      >
        {price}
      </OrderBookTable.Td>

      {rowChildren}
    </OrderBookTable.Tr>
  );
};

export default orderRowHoc(SingleOrderRowTablet);
