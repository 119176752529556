import { useState } from "react";
import { DivElement, TextWrapper, Button } from "../../Reusable";
import { numberInputOnWheelPreventChange } from "../../../utils";
import { LoadingButton } from "../../Loader";
import QrContainer from "../../Reusable/QrContainer";
import AddressContainer from "../../Reusable/AddressContainer";
import { createWallet } from "../../../lib/api";
import { formatAPIErrors, noExponents } from "../../../utils/general";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import Countdown from "react-countdown";
import ShowErrorMessage from "../../Reusable/ShowErrorMessage";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { FormInput } from "../../../assets/StyleComponents/Input.style";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";
import { BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";

const validationSchema = ({ min, max }) =>
  Yup.object().shape({
    amount: Yup.number()
      .min(min, `Deposit amount should not be less than ${noExponents(min)} BTC`)
      .max(max, `Deposit amount should not be greater than ${noExponents(max)} BTC`)
      .required("Please enter amount")
  });

const renderer = ({ hours, minutes, seconds, completed, setIsExpired }) => {
  minutes = hours * 60 + minutes + Math.ceil(seconds / 60);
  if (completed) {
    setIsExpired(true);
    return null;
  } else {
    return (
      <TextWrapper
        bgYellow
        fontSize="16px"
        fontSizeSm="12px"
        lineHeight="24px"
        lineHeightSm="18px"
        fontWeight="400"
        padding="4px 16px"
        paddingSm="2px 8px"
        borderRadius="5px"
        display="inline-block"
      >
        Please note that this invoice is valid for {minutes} minutes
      </TextWrapper>
    );
  }
};

const LightiningNetwork = ({ selectedNetwork, selectedCoin }) => {
  const [loader, setLoader] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  const [isExpired, setIsExpired] = useState(false);

  const onCreateInvoiceHandler = (values) => {
    setLoader(true);
    let data = {
      amount: values.amount,
      coin: selectedCoin.coin,
      network_id: selectedNetwork.id
    };
    createWallet(data)
      .then((res) => {
        let data = res?.data?.data;
        if (data && !data.invoice) {
          throw new Error();
        } else {
          setInvoiceData(data);
        }
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setLoader(false));
  };

  return (
    <DivElement marginTop="24px">
      {(!invoiceData || isExpired) && (
        <Formik
          validationSchema={validationSchema(selectedNetwork)}
          initialValues={{ amount: "" }}
          validateOnMount={true}
          onSubmit={onCreateInvoiceHandler}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <DivElement width="100%" className="fInput__form__field">
                <Label>{`Amount to deposit (<${selectedNetwork.max} ${selectedCoin.coin})`}</Label>
                <DivElement
                  className="fInput__form__input"
                  height="48px"
                  // paddingRight="16px"
                  fontSize="16px"
                  fontSizeSm="14px"
                  position="relative"
                >
                  <FormInput
                    name="amount"
                    type="number"
                    placeholder="Enter Amount"
                    value={values.amount}
                    onChange={(e) => setFieldValue("amount", e.target.value)}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    onWheel={numberInputOnWheelPreventChange}
                  />
                  <BannerHeadingSpan
                    secondary
                    fontSize="16px"
                    position="absolute"
                    right="12px"
                    top="12px"
                  >
                    {selectedCoin.coin}
                  </BannerHeadingSpan>
                </DivElement>
                <ErrorMessage name="amount">
                  {(msg) => <ShowErrorMessage message={msg} />}
                </ErrorMessage>
              </DivElement>

              <Button
                type="submit"
                primaryBlue
                marginTop="47.5px"
                width="100%"
                height="48px"
                disabled={loader}
              >
                {loader ? <LoadingButton /> : "Create invoice"}
              </Button>
            </Form>
          )}
        </Formik>
      )}

      {invoiceData && !isExpired && (
        <DivElement marginTop="24px">
          <DivElement width="fit-content">
            <Countdown
              date={invoiceData.expiryTime}
              renderer={(e) => renderer({ ...e, setIsExpired })}
            />
            <DivElement displayCenter padding="24px 0">
              <QrContainer value={invoiceData?.invoice || ""} />
            </DivElement>
          </DivElement>
          <DivElement>
            <TextWrapper secondary fontSize="14px" fontWeight="400" lineHeight="20px">
              Invoice
            </TextWrapper>
            <AddressContainer
              text={invoiceData.invoice}
              showThreeDot={true}
              maxLength={40}
              onCopy={() => showSuccessMsg("Successfully copied Lightning invoice")}
            />
            <TextWrapper
              secondary
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              marginTop="2px"
            >
              Submit a deposit using the QR code or copy the Invoice
            </TextWrapper>
          </DivElement>
        </DivElement>
      )}
    </DivElement>
  );
};

export default LightiningNetwork;
