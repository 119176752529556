import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  error: {},
  getBeneficiaryAll: [],
  getBeneficiaryCount: null,
  isfetchingBeneficiary: false,
  getSingleBeneficiary: {},
  isfetchingSingleBeneficiary: false,
  mtc:false
};

let initialState = initialStateSchema;
const localData = localStorage.getItem("accessToken");

if (localData && localData !== undefined) {
  try {
    initialState.authToken = localData;
  } catch (e) {
    initialState = initialStateSchema;
  }
}

const bankReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GET_BENEFICIARY_ALL:
      newState = {
        ...state,
        isfetchingBeneficiary: true
      };
      return newState;

    case apiConstants.GET_BENEFICIARY_ALL_SUCCESS:
      var array = action.response.data.data || [];
      if(array.length){
        array[0]["mtc"] = action.response.data.mtc;
      }
      newState = {
        ...state,
        getBeneficiaryAll: action.response.data.data,
        getBeneficiaryCount: action.response.data.count,
        isfetchingBeneficiary: false,
        errorGet: false,
        mtc:action.response.data.mtc
      };
      return newState;

    case apiConstants.GET_BENEFICIARY_ALL_FAIL:
      newState = {
        ...state,
        isfetchingBeneficiary: false,
        errorGet: true
      };
      return newState;

    case apiConstants.GET_BENEFICIARY_SINGLE:
      newState = {
        ...state,
        isfetchingSingleBeneficiary: true
      };
      return newState;

    case apiConstants.GET_BENEFICIARY_SINGLE_SUCCESS:
      newState = {
        ...state,
        getSingleBeneficiary: action.response.data.data,
        isfetchingSingleBeneficiary: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_BENEFICIARY_SINGLE_FAIL:
      newState = {
        ...state,
        isfetchingSingleBeneficiary: false,
        errorGet: true
      };
      return newState;

    case apiConstants.CLEAR_WALLET_ERRORS:
      newState = {
        ...state,
        error: {}
      };
      return newState;
    default:
      return state;
  }
};

export default bankReducer;
