import DivElement from "../DivElement";
import TextWrapper from "../TextWrapper";
import { ErrorIcon, SuccessIcon, WarningIcon } from "../../../assets/svgs";

const ShowErrorMessage = ({ message, containerProps, type = "error", textProps }) => {
  // const isWarning = type === "success" ? "success" : type === "warning";
  return (
    <DivElement flex alignItems="flex-start" marginTop="7.5px" {...containerProps}>
      {type === "warning" ? (
        <WarningIcon width="16px" height="16px" marginRight="8px" />
      ) : type === "success" ? (
        <SuccessIcon width="16px" height="16px" marginRight="8px" marginTop="2px" />
      ) : (
        <ErrorIcon width="16px" height="16px" marginRight="8px" marginTop="2px" />
      )}
      <TextWrapper
        red={type === "error" ? true : null}
        fontWeight="300"
        fontSize={type === "warning" ? "12px" : "14px"}
        lineHeight={type === "warning" ? "16px" : ""}
        {...textProps}
      >
        {message}
      </TextWrapper>
    </DivElement>
  );
};

export default ShowErrorMessage;
