import { DivElement, LinkButton, TextWrapper } from "../../../../../components/Reusable";
import { Line } from "react-chartjs-2";
import { compose } from "redux";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { numberWithCommas } from "../../../../../utils/general";
import InputContainer from "../../Components/InputContainer";
import { CalculatorGreenIcon } from "../../../../../assets/svgs";

const ReturnCalculator = ({ isDarkTheme, bondDetails, isLoggedIn }) => {
  const [amount, setAmount] = useState(1);
  const [chartData, setChartData] = useState("");
  let prevLang = localStorage.getItem("ljs-lang");
  
  useEffect(() => {
    changeData();
    // eslint-disable-next-line
  }, [amount]);

  // Formula for calculating simple interest annually
  const changeData = () => {
    let newData = [];
    for (let year = 1; year <= 7; year++) {
      let factor = 1 + 0.07 * year;
      newData.push(
        +((amount || 1) * (bondDetails ? bondDetails.denomination : 0) * factor).toFixed(8)
      );
    }
    setChartData(newData);
  };

  return (
    <DivElement borderLightGreen width="100%" borderRadius="10px" padding="20px">
      <DivElement dAlignCenter marginBottom="32px">
        <CalculatorGreenIcon />
        <TextWrapper fontSize="20px" lineHeight="1.2" marginLeft="12px">
          Return Calculator
        </TextWrapper>
      </DivElement>
      <DivElement maxWidth="308px">
        <InputContainer
          amount={amount}
          setAmount={setAmount}
          bondDetails={bondDetails}
          name="returnCalculator"
          balance=""
        />
      </DivElement>
      <DivElement marginTop="45px">
        {chartData && (
          <Line
            data={{
              labels: ["1Y", "2Y", "3Y", "4Y", "5Y", "6Y", "7Y"],
              datasets: [
                {
                  fill: true,
                  data: chartData,
                  borderColor: isDarkTheme ? "#7dbd6b" : "#2c922d",
                  backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, isDarkTheme ? "#527b4e" : "#cee6ce");
                    gradient.addColorStop(1, isDarkTheme ? "#2b4035" : "#eef6ee");
                    return gradient;
                  }
                }
              ]
            }}
            options={{
              responsive: true,
              legend: {
                display: false
              },
              title: {
                display: false
              },
              scales: {
                xAxes: [
                  {
                    gridLines: { display: false },
                    ticks: {
                      fontColor: isDarkTheme ? "#a7a9ab" : "#606f80",
                      fontStyle: 500
                    }
                  }
                ],
                yAxes: [
                  {
                    gridLines: {
                      color: isDarkTheme ? "rgba(125, 189, 107, 0.2)" : "#e6f0e6",
                      drawBorder: false
                    },
                    ticks: {
                      fontColor: isDarkTheme ? "#a7a9ab" : "#606f80",
                      fontStyle: 500,
                      callback: function (label, index, labels) {
                        return numberWithCommas(+label);
                      }
                    }
                  }
                ]
              }
            }}
          />
        )}
      </DivElement>

      {!isLoggedIn && (
        <LinkButton to={`${prevLang ? `/${prevLang}` : ""}/login`} width="100%" height="48px" secondaryGreen marginTop="32px">
          Log in or Register
        </LinkButton>
      )}
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  isLoggedIn: state.auth.isLoggedIn
});

export default compose(connect(mapStateToProps))(ReturnCalculator);
