import React, { Fragment, useState } from "react";
import { Button, DivElement, ModalWrapper } from "../../../Reusable";
import { Animation } from "../../../Reusable";
import OtpInput from "react-otp-input";
import { disableAntiPhisingCode } from "../../../../lib/api";
import { compose } from "redux";
import { connect } from "react-redux";
import { formatAPIErrors } from "../../../../utils/general";
import { getAntiPhisingCode } from "../../../../actions/Setting/setting";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { CloseIcon } from "../../../../assets/svgs";
import { LoadingButton } from "../../../Loader";
import { Label, ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { TextWrapper } from "../../../Reusable";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";

const DisableAntiPhisingModal = ({ onClose, getAntiPhisingCode }) => {
  let [openTwoFa, setOpenTwoFa] = useState(false);
  let [twoFa, setTwoFa] = useState("");
  let [isFetching, setIsFetching] = useState(false);
  const isTheme = useSelector((state) => state.theme.isTheme);
  const { width } = useWindowSize();

  const antiPhisingDisable = () => {
    setIsFetching(true);
    disableAntiPhisingCode({
      twoFA: twoFa,
      antiPhishingCode: ""
    })
      .then((res) => {
        getAntiPhisingCode();
        onClose();
        showSuccessMsg(res.data.message);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const OtpInputStyle = {
    width: width > 767 ? "50px" : "40px",
    height: width > 767 ? "48px" : "44px",
    background: isTheme ? "#1f2c38" : "#f5f6fa",
    color: isTheme ? "#f5f6fa" : "#000",
    border: "none",
    borderRadius: "5px 5px 0 0",
    fontSize: width > 767 ? "24px" : "16px",
    borderBottom: isTheme ? "1px solid #969799" : "1px solid #8c9797",
    outline: "none"
  };

  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <DivElement displayBtw>
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px" fontWeight="bold">
            Generate Anti-phishing Code
          </TextWrapper>
          <CloseIcon pointer onClick={onClose} />
        </DivElement>

        {!openTwoFa ? (
          <Fragment>
            <BannerBox width="100%" display="flex" justifyContent="center">
              <BannerBox width="200px" height="200px">
                <Animation type="FAILURE" />
              </BannerBox>
            </BannerBox>

            <ParaTag size="14px" weight="500" BodyColor>
              This action will <strong>compromise</strong> with your account security, do you still
              want to continue?
            </ParaTag>

            <DivElement displayBtw marginTop="24px">
              <Button
                secondaryBlue
                padding="0"
                width="calc(50% - 8px)"
                height="48px"
                marginRight="16pz"
                onClick={onClose}
              >
                Go Back
              </Button>
              <Button
                secondaryBlue
                padding="0"
                width="calc(50% - 8px)"
                height="48px"
                onClick={() => setOpenTwoFa(true)}
              >
                Continue
              </Button>
            </DivElement>
          </Fragment>
        ) : (
          <div style={{ margin: "10px 0px" }}>
            <Label txtGrey>Enter 2FA Code</Label>
            <OtpInput
              value={twoFa}
              onChange={(e) => setTwoFa(e)}
              numInputs={6}
              isInputNum={true}
              separator={<BannerHeadingSpan mr="8px" mrLg="10px"> </BannerHeadingSpan>}
              inputStyle={OtpInputStyle}
            />
            <Button
              secondaryBlue
              onClick={antiPhisingDisable}
              disabled={isFetching || !twoFa}
              marginTop="32px"
              marginTopSm="24px"
            >
              {isFetching ? <LoadingButton /> : "Disable"}
            </Button>
          </div>
        )}
      </DivElement>
    </ModalWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAntiPhisingCode: () => dispatch(getAntiPhisingCode())
});

export default compose(connect(null, mapDispatchToProps))(DisableAntiPhisingModal);
