import { Button } from "../../../Reusable";
import { LoadingButton } from "../../../Loader";

const KycStepButton = (props) => {
  return (
    <Button
      primaryBlue
      type="submit"
      width="100%"
      height="50px"
      heightMd="48px"
      marginTopSm="16px"
      {...props}
    >
      {props.loader ? <LoadingButton color="white" /> : props.label}
    </Button>
  );
};

export default KycStepButton;
