/**
 * Import Node Modules
 */
import React from "react";
import { DivElement, ModalWrapper } from "../../../components/Reusable";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";

/**
 * Import Other Modules
 */

export default function ConfirmModal(props) {
  return (
    <ModalWrapper>
      <DivElement ModalContainer paddingSm="19px 16px 17px">
        <DivElement padding="15px 0">
          <ParaTag size="20px" weight="500" BodyColor textAlign="left" sizeMd="16px" sizeLg="18px">
            Make sure you have scanned the code through Google Authenticator
          </ParaTag>

          <ParaTag
            padding="0.5rem 0 0 0"
            cursor="pointer"
            weight="500"
            textAlign="right"
            size="20px"
            txtPrimary
            onClick={props.handleOk}
          >
            OK
          </ParaTag>
        </DivElement>
      </DivElement>
    </ModalWrapper>
  );
}