import React, { useContext, useMemo } from "react";
import NumberWithCommaComponent from "../NumberWithComma/numberWithComma";
import StarTextComponent from "../StarTextComponent/starText";
import { WalletContext } from "../../contexts/WalletPage/BalancePage/wallet";

const SharedNumberComponent = ({ prevValue, digit, status = false, thousandStatus = false }) => {
  let { hideStatus } = useContext(WalletContext);

  return useMemo(() => {
    return !hideStatus ? (
      <StarTextComponent starNumber={prevValue} starDigit={digit} />
    ) : (
      <NumberWithCommaComponent
        value={prevValue}
        millionStatus={status}
        thousandStatus={thousandStatus}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue, hideStatus]);
};

export default SharedNumberComponent;
