import OrderTypes from "./OrderType";
import TradeTypeDropdown from "./TradeTypeDropdown";
import BuyForm from "../BuyForm";
import PayFeeInLCX from "../PayFeeInLCX";
import { DivElement } from "../../../../../components/Reusable";

const OrderForm = ({ orderFormSide }) => {
  return (
    <DivElement>
      <DivElement width="100%" displayBtw>
        <OrderTypes />
        <TradeTypeDropdown />
      </DivElement>
      <BuyForm orderFormSide={orderFormSide} />
      <PayFeeInLCX />
    </DivElement>
  );
};

export default OrderForm;
