import { LoadingButton } from "../../../Loader";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";

const KycStepButton = (props) => {
  return (
    <ButtonComponent
      // primaryBlue
      type="submit"
      width="100%"
      height="50px"
      heightMd="48px"
      marginTopSm="16px"
      DisabledButton={props.disabled}
      {...props}
    >
      {props.loader ? <LoadingButton color="white" /> : props.label}
    </ButtonComponent>
  );
};

export default KycStepButton;
