/* eslint-disable no-console */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getMarketLists } from "../../lib/api";
import { updateAllTickerMarket } from "../../Modules/Trades/MarketData/action";
import { TradeSocketContext } from "../../components/Socket/Public/tradeSocket";

function MarketHOC(Element) {
  return (props) => {
    let contextValue = useContext(TradeSocketContext);
    // let { StartAllSocket } = contextValue || {};
    const dispatch = useDispatch();

    const callTradingMarketaCallApi = () => {
      getMarketLists()
        .then((res) => {
          dispatch(updateAllTickerMarket(res));
        })
        .catch((err) => {
          dispatch(updateAllTickerMarket({}));
        });
    };

    useEffect(() => {
      // StartAllSocket();
      callTradingMarketaCallApi();
    }, []);

    return <Element {...props} />;
  };
}

export default MarketHOC;
