import historyProvider from "./historyProvider";

var _subs = [];

const stream = {
  subscribeBars: function (symbolInfo, resolution, updateCb, uid, resetCache) {
    let channelString = symbolInfo.name; // ETH/BTC

    var newSub = {
      channelString,
      uid,
      resolution,
      symbolInfo,
      lastBar: historyProvider.history[symbolInfo.name].lastBar,
      listener: updateCb
    };
    _subs.push(newSub);
  },
  unsubscribeBars: function (uid) {
    var subIndex = _subs.findIndex((e) => e.uid == uid);
    if (subIndex == -1) {
      return;
    }
    _subs.splice(subIndex, 1);
  }
};

export default stream;
