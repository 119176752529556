import React, { Fragment } from "react";
import {
  ComingSoonIllustration,
  LinkedinIllustration
} from "../../../../../assets/svgs/illustrations";
import { DivElement, ImgElement, TextWrapper } from "../../../../../components/Reusable";
import styled from "styled-components";

const ParagraphWrapper = styled(TextWrapper)`
  p {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    margin: 0;
  }
`;

const MDEXdata = [
  "The MasterDEX team, comprised of 20 full-time professionals, encapsulates a breadth of expertise crucial for navigating the blockchain and digital asset arenas. Predominantly, the team is made up of blockchain developers, each boasting significant experience in decentralized systems and harboring a pronounced interest in the DeFi sector. Their collective technical proficiency and pragmatic experience lay the foundation for the technical development and evolution of the MasterDEX platform.",
  "Beyond technical development, the MasterDEX team also sustains specialized roles dedicated to various pivotal operational areas. Business development is directed by an individual specializing in strategic alignment and partnership development, whereas the marketing efforts are orchestrated by a professional geared towards optimally situating MasterDEX within the market landscape. Community management and customer support are each anchored by individuals well-versed in the crypto environment, ensuring robust community interaction and customer relations.",
  "Although the MasterDEX team is distinct, it's pivotal to note that all its members originate from LCX, as MasterDEX is an ecosystem project developed and initiated by the experienced LCX team. Together, the team combines technical and specialized skills, contributing to the continual development, marketing, and support of MasterDEX in its journey through the dynamically shifting digital finance terrain."
];

const Team = ({ team, advisors, teamMessage, name }) => {
  const createMarkup = (text) => {
    return { __html: text };
  };

  return (
    <Fragment>
      <TextWrapper
        textAlign="center"
        fontSize="30px"
        fontSizeMd="20px"
        fontSizeSm="18px"
        fontWeight="500"
        marginBottom="24px"
      >
        Core Team
      </TextWrapper>
      {(teamMessage || team) && name !== "MDEX" && (
        <Fragment>
          {teamMessage && (
            <ParagraphWrapper
              dangerouslySetInnerHTML={createMarkup(teamMessage)}
              fontSize="16px"
              fontSizeSm="14px"
            />
          )}
          {!teamMessage && team && (
            <DivElement margin="0 -12px">
              <DivElement flex flexWrap="wrap">
                {team.map((data, index) => (
                  <DivElement
                    key={index}
                    width="calc(25% - 24px)"
                    widthMd="calc(33.33% - 24px)"
                    widthSm="calc(50% - 24px)"
                    margin="12px"
                    flexColumn
                    flexAlignCenter
                  >
                    <DivElement relative width="70%">
                      <ImgElement
                        src={data.image}
                        alt={data.name}
                        width="130px"
                        height="130px"
                        aspectRatio="1"
                        borderRadius="50%"
                      />
                      {data.linkedIn && (
                        <DivElement absolute right="-15px" bottom="-15px">
                          <a href={data.linkedIn} target="_blank" rel="noopener noreferrer">
                            <LinkedinIllustration />
                          </a>
                        </DivElement>
                      )}
                    </DivElement>
                    <TextWrapper
                      fontSize="16px"
                      fontWeight="bold"
                      textAlign="center"
                      marginTop="8px"
                    >
                      {data.name}
                    </TextWrapper>
                    <TextWrapper secondary textAlign="center">
                      {data.designation}
                    </TextWrapper>
                  </DivElement>
                ))}
              </DivElement>
            </DivElement>
          )}
        </Fragment>
      )}

      {name === "MDEX" && (
        <>
          {MDEXdata.map((alldata, i) => {
            return (
              <>
                <ParagraphWrapper
                  dangerouslySetInnerHTML={createMarkup(alldata)}
                  fontSize="16px"
                  fontSizeSm="14px"
                />
                <br />
              </>
            );
          })}
        </>
      )}

      {!teamMessage && advisors && advisors.length > 0 && name !== "MDEX" && (
        <Fragment>
          <TextWrapper
            textAlign="center"
            fontSize="30px"
            fontSizeMd="20px"
            fontSizeSm="18px"
            fontWeight="bold"
            marginBottom="24px"
          >
            Advisors
          </TextWrapper>

          <DivElement margin="0 -12px">
            <DivElement flex flexWrap="wrap">
              {advisors.map((data, index) => (
                <DivElement
                  key={index}
                  width="calc(25% - 24px)"
                  widthMd="calc(33.33% - 24px)"
                  widthSm="calc(50% - 24px)"
                  margin="12px"
                  flexColumn
                  flexAlignCenter
                >
                  <DivElement relative width="70%">
                    <ImgElement
                      src={data.image}
                      alt={data.name}
                      width="100%"
                      aspectRatio="1"
                      borderRadius="50%"
                    />
                    {data.linkedIn && (
                      <DivElement absolute right="-15px" bottom="-15px">
                        <a href={data.linkedIn} target="_blank" rel="noopener noreferrer">
                          <LinkedinIllustration />
                        </a>
                      </DivElement>
                    )}
                  </DivElement>
                  <TextWrapper fontSize="16px" fontWeight="bold" textAlign="center" marginTop="8px">
                    {data.name}
                  </TextWrapper>
                  <TextWrapper secondary textAlign="center">
                    {data.designation}
                  </TextWrapper>
                </DivElement>
              ))}
            </DivElement>
          </DivElement>
        </Fragment>
      )}

      {((!team && !advisors && !teamMessage) || (team.length === 0 && advisors.length === 0)) && (
        <DivElement width="100%" displayCenter>
          <ComingSoonIllustration />
        </DivElement>
      )}
    </Fragment>
  );
};

export default Team;
