/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteBeneficieryData } from "../../../lib/api";
import ConfirmDeleteBenficiary from "./ConfirmDeleteBenficiary";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import {
  BannerBox,
  BannerHeadingSpan,
  MainElement,
  BannerSection
} from "../../../assets/StyleComponents/Exchange.style";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { ManageBankRow } from "../../../assets/StyleComponents/Table/Wallet/ManageBank.style";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DeleteIcon, EditIcon, ThreeDotsIcon } from "../../../assets/svgs";
import { NoRecordIllustration } from "../../../assets/svgs/illustrations";
import { connect, useSelector } from "react-redux";
import { getBeneficiary, getBeneficiarySingle } from "../../../actions/Wallet/bankAccount";
import TableLoader from "../../Loader/TableLoader";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { Table, Tbody, Th, Thead, Td, RouterLink, ThDropdown } from "../../Reusable";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { DropDownButtonComponent } from "../../../assets/StyleComponents/DropDown.style";
import { Dropdown } from "react-bootstrap";
import NoRecordsFound from "../../NoRecordsFound/noRecordsFound";

function GetBankDetails(props) {
  const [name, setName] = useState("");
  const [modal, setModal] = useState(false);
  const { width } = useWindowSize();
  const [dropdownOptions, setDropdownOptions] = useState(["Bank Account", "IBAN Number"]);
  const [activeCol, setActiveCol] = useState("Bank Account");
  const [userId, setuserId] = useState("");
  const userData = useSelector(state => state.dashboard.userData);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    props.getBeneficiary();
  }, []);

  const showModal = (data) => {
    setModal(true);
    setName(data);
  };

  const closeModal = (data) => {
    setModal(false);
  };

  let { isfetchingBeneficiary, getBeneficiaryAll } = props;

  return (
    <MainElement>
      {modal && (
        <ConfirmDeleteBenficiary
          name={name}
          onClose={closeModal}
          setModal={setModal}
          id={userId}
          setName={setName}
          getBeneficiary={getBeneficiary}
          {...props}
        />
      )}

      <BannerSection paddMd="20px 0px">
        <BannerBox
          padding={"0 0px 0px 16px"}
          paddingMd={"0 16px"}
          position="relative"
          width="50%"
          widthLg="100%"
        >
          <BannerHeading size={"24px"} sizeLg={"20px"} sizeMd={"18px"} mb={"12px"}>
            Manage Bank Accounts
          </BannerHeading>
          <BannerHeading txtGrey size={"16px"} sizeLg={"18px"} sizeMd={"12px"}>
            You can manage your Bank Accounts here
          </BannerHeading>
        </BannerBox>

        {!isfetchingBeneficiary && (
          <Fragment>
            <BannerBox
              display="flex"
              flex="0 0 100%"
              direction="column"
              padding={"0 0px 0px 16px"}
              paddingMd={"0 16px"}
              mb="35px"
              mbLg="25px"
              mtMd="25px"
              mt="48px"
            >
              <Table bRadius width="100%" marginBottom="16px" TableBodyRowHeight>
                <Thead display="flex" height="40px" fontSize="14px" fontSizeMd="12px">
                  <ManageBankRow displayFlex OrdersPagesHeadRow>
                    <Th scope="col" BodyColor>
                      Description
                    </Th>
                    {width > 1025 && <Th scope="col">Bank Account</Th>}
                    {width > 1025 && <Th scope="col">IBAN Number</Th>}
                    {width > 1025 && <Th scope="col">Action</Th>}
                    {width < 1025 && (
                      <ThDropdown
                        textAlign={"right"}
                        OPTIONS={dropdownOptions}
                        activeCol={activeCol}
                        selectOption={setActiveCol}
                      />
                    )}
                    {width < 1025 && <Th scope="col"> </Th>}
                  </ManageBankRow>
                </Thead>

                <Tbody>
                  {!isfetchingBeneficiary &&
                    getBeneficiaryAll.map((data, i) => (
                      <ManageBankRow
                        displayFlex
                        key={i}
                        OrdersPagesHeadRow={i === getBeneficiaryAll.length - 1 && true}
                      >
                        {width > 1024 && (
                          <CopyToClipboard
                            onCopy={() => showSuccessMsg("Description copied!")}
                            text={data.beneficiaryName}
                          >
                            <Td>
                              {data.beneficiaryName.length > 35
                                ? data.beneficiaryName.slice(0, 25) + "..."
                                : data.beneficiaryName.length > 30
                                ? data.beneficiaryName.substr(0, 20) +
                                  "..." +
                                  data.beneficiaryName.substr(
                                    data.beneficiaryName.length - 10,
                                    data.beneficiaryName.length - 4
                                  )
                                : data.beneficiaryName}
                            </Td>
                          </CopyToClipboard>
                        )}

                        {width > 767 && width < 1025 && (
                          <CopyToClipboard
                            onCopy={() => showSuccessMsg("Description copied!")}
                            text={data.beneficiaryName}
                          >
                            <Td>
                              {data.beneficiaryName.length > 20
                                ? data.beneficiaryName.slice(0, 15) + "..."
                                : data.beneficiaryName.length > 15
                                ? data.beneficiaryName.substr(0, 10) +
                                  "..." +
                                  data.beneficiaryName.substr(
                                    data.beneficiaryName.length - 10,
                                    data.beneficiaryName.length - 4
                                  )
                                : data.beneficiaryName}
                            </Td>
                          </CopyToClipboard>
                        )}

                        {width < 768 && (
                          <CopyToClipboard
                            onCopy={() => showSuccessMsg("Description copied!")}
                            text={data.beneficiaryName}
                          >
                            <Td>
                              {data.beneficiaryName.length > 15
                                ? data.beneficiaryName.slice(0, 7) + "..."
                                : data.beneficiaryName.length > 10
                                ? data.beneficiaryName.substr(0, 7) + "..."
                                : data.beneficiaryName}
                            </Td>
                          </CopyToClipboard>
                        )}

                        {width > 1025 && (
                          <CopyToClipboard
                            onCopy={() => showSuccessMsg("Bank Account copied!")}
                            text={data.nameOnAccount}
                          >
                            <Td>
                              {data.nameOnAccount.length > 15
                                ? data.nameOnAccount.slice(0, 15) + "..."
                                : data.nameOnAccount}
                            </Td>
                          </CopyToClipboard>
                        )}
                        {width < 1025 && activeCol === "Bank Account" && (
                          <CopyToClipboard
                            onCopy={() => showSuccessMsg("Bank Account copied!")}
                            text={data.nameOnAccount}
                          >
                            <Td>
                              {width > 767 &&
                                data.nameOnAccount &&
                                data.nameOnAccount.slice(0, 25) +
                                  (data.nameOnAccount.length > 25 ? "..." : "")}
                              {width <= 767 &&
                                data.nameOnAccount &&
                                data.nameOnAccount.slice(0, 15) +
                                  (data.nameOnAccount.length > 12 ? "..." : "")}
                            </Td>
                          </CopyToClipboard>
                        )}

                        {width > 1025 && (
                          <CopyToClipboard
                            onCopy={() => showSuccessMsg("IBAN copied!")}
                            text={data.iban}
                          >
                            <Td>
                              {data.iban.length > 32 ? data.iban.slice(0, 32) + "..." : data.iban}
                            </Td>
                          </CopyToClipboard>
                        )}
                        {width < 1025 && activeCol === "IBAN Number" && (
                          <CopyToClipboard
                            onCopy={() => showSuccessMsg("IBAN copied!")}
                            text={data.iban}
                          >
                            <Td>
                              {width > 767 &&
                                data.iban &&
                                data.iban.slice(0, 20) + (data.iban.length > 20 ? "..." : "")}
                              {width <= 767 &&
                                data.iban &&
                                data.iban.slice(0, 7) + (data.iban.length > 7 ? "..." : "")}
                            </Td>
                          </CopyToClipboard>
                        )}

                        {width > 1025 && (
                          <Td>
                            <RouterLink
                              to={`${prevLang ? `/${prevLang}`: "/en"}/wallet/edit_accounts?id=${data.id}`}
                              marginRight="24px"
                            >
                              <EditIcon />
                            </RouterLink>

                            <DeleteIcon
                              pointer
                              onClick={() => {
                                showModal(data);
                                setuserId(data.id);
                              }}
                            />
                          </Td>
                        )}

                        {width < 1025 && (
                          <Td display="flex" justifyContent="end">
                            <DropDownButtonComponent
                              // TradeOrder
                              // InnerLevel
                              // RemoveBtnPadding
                              // NoCaret
                              HoverListActive
                              RemoveBtnPadding
                              NoCaret
                              variant="outline-secondary"
                              title={
                                <ThreeDotsIcon
                                  width="20px"
                                  height="20px"
                                  marginTop="5px"
                                  margin="0px auto"
                                />
                              }
                              id="input-group-dropdown-1"
                            >
                              <Dropdown.Item as={Link} to={`${prevLang ? `/${prevLang}`: "/en"}/wallet/edit_accounts?id=${data.id}`}>
                                <EditIcon width="20px" height="20px" marginRight="8px" />
                                Edit
                              </Dropdown.Item>

                              <Dropdown.Item as={"div"} onClick={() => showModal(data)}>
                                <DeleteIcon width="20px" height="20px" marginRight="8px" />
                                Delete
                              </Dropdown.Item>
                            </DropDownButtonComponent>
                          </Td>
                        )}
                      </ManageBankRow>
                    ))}
                </Tbody>
              </Table>

              {!isfetchingBeneficiary && getBeneficiaryAll.length < 1 && (
                <BannerBox margin="15px 0px">
                  <NoRecordsFound text="No Record Found" />
                </BannerBox>
              )}
            </BannerBox>

            <BannerBox display="flex" alignItems="center" padding="0px 16px">
              <Link to={`${prevLang ? `/${prevLang}`: "/en"}/wallet/add_accounts`}>
                <ButtonComponent
                  PrimaryButton
                  width={"221px"}
                  widthMd="180px"
                  maxWidth={"100%"}
                  weight="500"
                  givenMargnLg="32px auto auto auto"
                  givenMargnMd="32px auto auto auto"
                >
                  Add Bank Account
                </ButtonComponent>
              </Link>
            </BannerBox>
          </Fragment>
        )}
        {isfetchingBeneficiary && (
          <BannerBox
            display="flex"
            flex="0 0 100%"
            direction="column"
            padding={"0 0px 0px 16px"}
            mt="48px"
          >
            <TableLoader rows={5} webCol={4} tabletCol={2} mobileCol={2} />
          </BannerBox>
        )}
      </BannerSection>
    </MainElement>
  );
}

const mapStateToProps = (state) => ({
  getBeneficiaryAll: state.bank.getBeneficiaryAll,
  isfetchingBeneficiary: state.bank.isfetchingBeneficiary
});

const mapDispatchToProps = (dispatch) => ({
  getBeneficiary: () => dispatch(getBeneficiary()),
  getBeneficiarySingle: (data) => dispatch(getBeneficiarySingle(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(GetBankDetails);
