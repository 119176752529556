import Skeleton from "./Skeleton";

const TokenSaleStepsLoader = () => {
  return (
    <div className="saleloader">
      <div className="saleloader__header">
        <Skeleton style={{ width: "98px", height: "23px" }} />
        <Skeleton
          className="saleloader__header__timer"
          style={{ width: "132px", height: "16px" }}
        />
      </div>
      <div className="saleloader__fields">
        <Skeleton style={{ width: "129px", height: "16px" }} />
        <Skeleton style={{ width: "76px", height: "16px" }} />
      </div>
      <div className="saleloader__input">
        <Skeleton style={{ width: "100%", height: "56px" }} />
      </div>
      <div className="saleloader__fields">
        <Skeleton style={{ width: "129px", height: "16px" }} />
        <Skeleton style={{ width: "76px", height: "16px" }} />
      </div>
      <div className="saleloader__input">
        <Skeleton style={{ width: "100%", height: "56px" }} />
      </div>
      <div className="saleloader__btn">
        <Skeleton style={{ width: "100%", height: "48px" }} />
      </div>
    </div>
  );
};

export default TokenSaleStepsLoader;
