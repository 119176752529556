import ReactGA from "react-ga4";
import { customDecode } from "../utils/envScript/envCrypto";


export const initializeGA4 = () => {
  const trackingId = customDecode(process.env.REACT_APP_GOOGLE_ANALYTICS_GA4);

  ReactGA.initialize(trackingId);
};

export const trackPage = (pageUrl, title) => {
  if(customDecode(process.env.REACT_APP_NODE_ENV) === "production") {
    ReactGA.send({ hitType: "pageview", page: pageUrl, title: title || document.title });
  }
  return false;
};
