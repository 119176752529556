/* eslint-disable no-unused-vars */
import { Fragment, useEffect, useMemo, useState } from "react";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { formatAPIErrors, noExponents } from "../../../../utils/general";
import { getAllWallets } from "../../../../actions/wallet";
import { compose } from "redux";
import { connect } from "react-redux";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { withdrawFiat } from "../../../../lib/api";
import WithdrawalModal from "../../../../views/walletPageCopy/WithdrawalPage/Component/Modal/withdrawalModal";
import { numberWithCommas } from "../../../../utils/general";
import { formatNumbers } from "../../../../utils/helpers";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { FormInput } from "../../../../assets/StyleComponents/Input.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { numberInputOnWheelPreventChange } from "../../../../utils";
import { divide, multiply, subtract } from "../../../../utils/bigNumber";
import { getWalletBalance } from "../../../../actions/dashboard";

const WithdrawalFiatCoinDetails = (props) => {
  let { selectedCoin, selectedProvider, MainOnClose, CloseAllModal, selectedBeneficiary } = props;

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [server_val, setServer_val] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [amount, set_amount] = useState("");
  const [step, setStep] = useState(1);
  const [token, setToken] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, set_loading] = useState(false);
  const [otpId, setOtpId] = useState("");
  const [errorMsg, setErroMsg] = useState("");

  useEffect(() => {
    let { getBeneficiaryAll } = props;
    if (getBeneficiaryAll && getBeneficiaryAll.length > 0) {
      const value = getBeneficiaryAll[0].mtc || false;
      setIsTermsChecked(value);
      setIsDeclarationChecked(value);
      setServer_val(value);
    }
  }, []);

  useEffect(() => {
    if (props.apiError && props.apiError.message) {
      showErrorMsg(props.apiError.message, { autoClose: 7000 });
      props.clearWalletErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiError]);

  const checkInvalidInput = (value) => {
    let reg = /^(\d{1,8}|\d{0,8}\.\d{1,8})$/;
    return value.match(reg);
  };

  const Condition =
    selectedProvider?.label === "BCB" ||
    selectedProvider?.label === "Clear Junction" ||
    selectedProvider?.label === "BLINC" ||
    selectedProvider?.label === "Monerium";

  //=> Check Validation on positve and dicimal
  const validateAmount = (event) => {
    let reg = checkInvalidInput(event.target.value);

    const minAmount = selectedProvider?.label === "Monerium" ? 20 : selectedProvider.min;
    let balance = Number(selectedCoin?.balance?.freeBalance);
    if (!reg) {
      let amount = event.target.value;
      let valueSplit = amount.split(".");
      if (valueSplit && valueSplit.length === 2 && valueSplit[1].length > 8) return true;
      if (valueSplit && valueSplit.length === 2) {
        if (isNaN(valueSplit[1])) return true;
        setError(true);
        setErroMsg("Enter correct number");
        set_amount(amount);
      } else {
        if (amount === "") set_amount("");
        return true;
      }
      setError(true);
      setErroMsg("Enter correct number");
      set_amount(amount);
    } else {
      let amount = event.target.value;
      setDisabled(true);

      if (amount && Number(amount) > 10000000 && !Condition) {
        setErroMsg(`Amount should be less than or equal to 10000000`);
        return set_amount(amount);
      } else if (Condition && Number(amount) < minAmount) {
        setErroMsg(`Amount should be greater than or equal to ${minAmount}`);
        set_amount(amount);
        return;
      }
      if (amount && Number(amount) < 25000 && selectedProvider.value === "Wire Transfer") {
        setErroMsg(`Amount should be greater than or equal to 25000 Euro`);
        setDisabled(true);
        return set_amount(amount);
      }
      if (amount && Number(amount) > Number(selectedProvider?.max) && Condition) {
        setErroMsg(`Amount should be greater than or equal to ${selectedProvider?.max}`);
        setDisabled(true);
        return set_amount(amount);
      }
      if (selectedCoin.balance && selectedCoin.balance.freeBalance < Number(amount)) {
        setErroMsg("Insufficient balance");
        setDisabled(true);
        return set_amount(amount);
      } else setError(false);
      if (amount < 0 || amount === 0) {
        setDisabled(false);
        set_amount(Number(amount));
      } else if ((amount > 0 || amount !== 0) && token) {
        setDisabled(false);
        set_amount(Number(amount));
      }
      setErroMsg("");
      setDisabled(false);
      return set_amount(amount);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    CloseAllModal();
  };

  useEffect(() => {
    setStep(step + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiData]);

  const validateCredentials = () => {
    let total = Number(amount) - Number(YourFees(selectedProvider.fee));
    if (selectedCoin.fees && total <= 0) {
      showErrorMsg(`Minimum withdrawal amount is ${selectedCoin.fees} ${selectedCoin.coin}`);
      return false;
    }

    if (!amount || isNaN(amount)) {
      showErrorMsg("Invalid Amount");
      return false;
    }

    if (selectedCoin.balance && selectedCoin.balance.freeBalance < Number(amount)) {
      showErrorMsg("Insufficient balance");
      return false;
    }

    if (!selectedProvider && !selectedProvider.value && !selectedProvider.value.id) {
      showErrorMsg("Please select bank");
      return false;
    }

    let data = {
      coin: selectedCoin.coin,
      amount: Number(amount),
      beneficiaryId: selectedBeneficiary.value.id,
      source: "ACCOUNTS"
    };

    withdrawFiat(data)
      .then((res) => {
        showSuccessMsg(res.data.message, { autoClose: 7000 });
        setOtpId(res.data.data.id);
        MainOnClose(true);
        setShowModal(true);
        // props.getMainWallet();
        // props.getExchangeWallet();
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      });
  };

  // function YouGet() {
  //   if (selectedProvider.value === "Monerium") {
  //     return amount === "" ? 0 : amount - 2;
  //   } else {
  //     return selectedProvider.transactionFeePercent
  //       ? numberWithCommas(Number(amount) - FinalCalculatedFee)
  //       : selectedProvider.value === "Wire Transfer"
  //       ? amount
  //       : numberWithCommas(formatNumbers(totalAmount, 8));
  //   }
  // }

  function YourFees(data) {
    if (data?.type === "FIXED") {
      const DataFromValue = Number(data?.value);
      return DataFromValue;
    } else if (data?.type === "PERCENTAGE") {
      const DataFromPercentage = Number(
        multiply(divide(Number(data?.value), 100), Number(amount), 2)
      );
      const DataFromPrecentage2 = Number(data?.min);
      return DataFromPercentage > DataFromPrecentage2 ? DataFromPercentage : DataFromPrecentage2;
    }
  }

  function YouGet() {
    const value = subtract(Number(amount), Number(YourFees(selectedProvider.fee)), 6);
    return value;
  }

  let minAmt = YourFees(selectedProvider?.fee);

  let totalAmount;
  if (selectedCoin?.fees) totalAmount = Number(amount) - Number(selectedCoin?.fees);

  if (totalAmount < 0) totalAmount = 0;
  return (
    <BannerBox mt={"16px"}>
      {showModal && (
        <WithdrawalModal
          isTheme={props.isDarkTheme}
          onClose={closeModal}
          show={showModal}
          otpId={otpId}
          coin={selectedCoin}
          goToStep={2}
        />
      )}
      {selectedCoin.coinType === "fiat_token" && (
        <div className="">
          <BannerHeading size="14px" txtGrey mt="12px" mb="8px" weight="300">
            {" "}
            Withdrawal Amount{" "}
          </BannerHeading>

          <div className="form-group exch-profile-box exch-profile-input-box-new hgh-56 mb-0">
            <FormInput
              style={{ borderWidth: 1, top: "-2px" }}
              className="form-control f-rem-9-0 bck-greyed borderNone exch-light-black-placeholder txt-light-black p-0 pt-1 profile-input-padding position-relative pd-l-14"
              type="number"
              placeholder="Enter the amount"
              required="required"
              value={amount}
              onChange={validateAmount}
              onWheel={numberInputOnWheelPreventChange}
            />
            {/* <label> {selectedCoin?.coin} Address </label> */}
          </div>

          {errorMsg && (
            <BannerHeadingSpan className="pt-2 f-14 txt-red mt-2" txtDanger mt="7px" size="14px">
              {errorMsg}
            </BannerHeadingSpan>
          )}

          {selectedCoin?.fees && (
            <BannerBox
              className="pl-md-2 pl-0 element-box-content mb-0 pd-b-32 mt-4"
              mt="20px"
              mb="20px"
            >
              <ParaTag className="text-left mb-1" weight="300" size="1rem">
                Transaction Fee:{" "}
                <BannerHeadingSpan headerText size="14px" weight="500">
                  <b>
                    <var>
                      {amount >= minAmt
                        ? numberWithCommas(
                            selectedProvider.value === "Wire Transfer"
                              ? 0
                              : noExponents(YourFees(selectedProvider.fee))
                          )
                        : 0}{" "}
                      {selectedCoin.withdrawalFeeCoin
                        ? selectedCoin.withdrawalFeeCoin
                        : selectedCoin?.coin}
                    </var>
                  </b>
                </BannerHeadingSpan>
              </ParaTag>
              <ParaTag weight="300" size="1rem" textAlign="left">
                You Will Get:{" "}
                <BannerHeadingSpan headerText weight="500" size="14px">
                  <b>
                    <var>
                      {amount > minAmt
                        ? numberWithCommas(formatNumbers(noExponents(YouGet(selectedProvider.fee))))
                        : 0}{" "}
                      {selectedCoin?.coin}
                      {/* {numberWithCommas(formatNumbers(totalAmount, 8))} {selectedCoin?.coin} */}
                    </var>
                  </b>
                </BannerHeadingSpan>
              </ParaTag>
            </BannerBox>
          )}

          <div className="text-right">
            <ButtonComponent
              style={{ borderWidth: 1, width: "100%", height: "48px" }}
              PrimaryButton
              disabled={errorMsg ? true : false}
              opacity={disabled ? "0.4" : 1}
              onClick={() => {
                if (!errorMsg) validateCredentials();
              }}
              className="mr-3 mb-0 btn btn-primary bck-primary"
              type="button"
              // disabled={disabled}
            >
              {loading && <i className="fa fa-spinner fa-spin"></i>} Withdraw
            </ButtonComponent>
          </div>
        </div>
      )}
    </BannerBox>
  );
};

const mapStatetoProps = (state) => ({
  isDarkTheme: state.wallet.isDarkTheme
});

const mapDispatchToProps = (dispatch) => ({
  getMainWallet: () => dispatch(getAllWallets()),
  getExchangeWallet: () => dispatch(getWalletBalance())
});

export default compose(connect(mapStatetoProps, mapDispatchToProps))(WithdrawalFiatCoinDetails);
