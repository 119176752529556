import { Fragment } from "react";
import Skeleton from "./Skeleton";

const InternalTransferLoader = () => {
  return (
    <Fragment>
      <Skeleton height="24px" width="50%" marginBottom="6px" />
      <Skeleton height="20px" width="75%" marginBottom="32px" />
      <Skeleton height="20px" width="100px" />
      <Skeleton height="48px" width="100%" marginBottom="20px" />
      <Skeleton height="20px" width="100px" />
      <Skeleton height="48px" width="100%" marginBottom="32px" />
      <Skeleton width="100%" height="48px" />
    </Fragment>
  );
};

export default InternalTransferLoader;
