import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { setPrecision } from "../../../../../../utils/math";

const Blink = styled.div`
  position: absolute;
  left: 0;
  z-index: 0;
  width: 100%;
  height: calc(100% - 2px);
  opacity: 0;

  ${(props) =>
    props.showAnimation &&
    css`
      animation: blink 1s linear 1;
    `}

  ${(props) =>
    props.side === "BUY" &&
    css`
      background-color: ${(props) => props.theme.buyAnimationBg2};
    `};

  ${(props) =>
    props.side === "SELL" &&
    css`
      background-color: ${(props) => props.theme.sellAnimationBg2};
    `}

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

const getPrice = (orderData, precision, side) => {
  if (!orderData) return;
  let price;
  if (side === "BUY") {
    price = +orderData[0].toFixed(precision);
  } else {
    price = setPrecision(orderData[0], precision);
  }

  return price;
};

const OrderRowBlink = ({ side, price }) => {
  const lastOrderUpdate = useSelector((state) => state.trading.lastOrderUpdate);
  const selectedPricePrecision = useSelector((state) => state.trading.selectedPricePrecision);

  const updatedPrice = getPrice(lastOrderUpdate, selectedPricePrecision, side);
  const updatedSide = lastOrderUpdate[2];

  return (
    <Blink side={side} showAnimation={updatedPrice == price && updatedSide == side ? true : null} />
  );
};

export default OrderRowBlink;
