// eslint-disable
import React from "react";
import { DivElement, TextWrapper, ElementBox } from "../../../../components/Reusable";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import EarnBannerImage from "./../../../../assets/img/LCX-Earn.jpg";

const EarnBanner = () => {
  const { width } = useWindowSize();
  return (
    <ElementBox
      blackBox
      EarnBanner
      height="366px"
      heightMd="332px"
      heightSm="fit-content"
      padding="0"
    >
      <DivElement
        display={width >= 768 ? "flex" : "block"}
        justifyContent="space-between"
        height="100%"
        className="earn-banner-wrapper"
      >
        <DivElement
          padding="73px 0 73px 39px"
          paddingMd="66px 0 66px 30px"
          paddingSm="27px 16px 28px 16px"
          className="earn-banner"
          bg={`url(${EarnBannerImage})`}
          width="100%"
        >
          <TextWrapper
            primaryDark
            lineHeight="normal"
            maxWidth="452px"
            maxWidthMd="330px"
            maxWidthSm="100%"
            fontSize="44px"
            fontSizeMd="32px"
            fontSizeSm="28px"
            textAlignSm="center"
          >
            Earn 7% Fixed Yield
          </TextWrapper>
          <TextWrapper
            primaryDark
            fontWeight="bold"
            textAlignSm="center"
            fontSize="20px"
            lineHeight="1.2"
            lineHeightMd="1.57"
            fontSizeMd="14px"
            marginTop="13px"
            marginTopMd="10px"
            marginTopSm="7px"
          >
            Regulated. Approved. Secure
          </TextWrapper>
          <DivElement className="earn-banner__border"></DivElement>
          <TextWrapper
            secondaryDark
            maxWidth="508px"
            maxWidthMd="362px"
            maxWidthSm="100%"
            fontSize="16px"
            fontSizeMd="14px"
            lineHeight="1.5"
            lineHeightMd="1.57"
            textAlignSm="center"
          >
            <TextWrapper primaryDark display="inline" lineHeight="1.5" fontSize="16px">
              LCX Tokenized Bonds
            </TextWrapper>{" "}
            are based on{" "}
            <TextWrapper primaryDark display="inline" lineHeight="1.5" fontSize="16px">
              security prospectuses
            </TextWrapper>{" "}
            that have been endorsed by the Financial Market Authority Liechtenstein and passported
            across the European economic area (EEA) -
            <TextWrapper primaryDark display="inline" lineHeight="1.5" fontSize="16px">
              {" "}
              available to retail investors in 30 European countries
            </TextWrapper>
            .
          </TextWrapper>
        </DivElement>
      </DivElement>
    </ElementBox>
  );
};

export default EarnBanner;
