/* eslint-disable no-console */
/* eslint-disable no-debugger */
import apiConstants from "../constants/apiConstants";
import { getCookie } from "../utils/cookies";

const initialStateSchema = {
  loginToken: "",
  authState: getCookie("lcx-csrf") || "",
  isFetching: true,
  authToken: "",
  isLoggedIn: false,
  error: "",
  registerEmailError: "",
  apiData: "",
  isTwoAuth: false,
  isVerified: false,
  errorGet: "",
  changePasswordSuccess: "",
  generatedAuthCode: "",
  isRedirect: false,
  domainName: "",
  sessionExpired: false,
  authCode: "",
  generatedAuthCodeTerminalDomain: "",
  generatedAuthCodeExch: "",
  generatedAuthCodeExchDomain: "",
  isOtp: false,
  otpCode: "",
  regisgterOtpCode: "",
  actionsRequired: null,
  redirectUrl: "",
  recentLogins: "",
  isFetchingRecentLogins: false,
  redirectToVerifyEmail: false
};

let initialState = initialStateSchema;
const localData = localStorage.getItem("accessToken");

if (localData && localData !== undefined) {
  try {
    initialState.authToken = localData;
  } catch (e) {
    initialState = initialStateSchema;
  }
}

const authReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.LOGIN_USER:
      newState = {
        ...state,
        authLoader: true,
        isVerified: false
      };
      return newState;

    case apiConstants.LOGIN_USER_SUCCESS:
      newState = {
        ...state,
        loginToken: action.response.data.authCode,
        loginProfileStatus: action.response.data.profileStatus,
        authState: action.response.data.state,
        authLoader: false,
        isLoggedIn: true,
        isTwoAuth: false,
        actionsRequired: action.response.data.actionsRequired
      };
      return newState;

    case apiConstants.LOGIN_USER_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        authLoader: false,
        isFetching: false
      };
      return newState;

    case apiConstants.NEED_TWO_AUTH_FOR_LOGIN:
      newState = {
        ...state,
        codeData: action.response?.data?.code,
        isTwoAuth: true,
        authLoader: false
      };
      return newState;

    case apiConstants.NEED_OTP_FOR_LOGIN:
      newState = {
        ...state,
        isOtp: true,
        authLoader: false,
        otpCode: action.response?.data?.otpCode
      };
      return newState;
    case apiConstants.REGISTER_EMAIL:
      newState = {
        ...state,
        authLoader: true,
        regisgterOtpCode: "",
        isVerified: false
      };
      return newState;

    case apiConstants.REGISTER_EMAIL_SUCCESS:
      newState = {
        ...state,
        authLoader: false,
        regisgterOtpCode: action.response.data.otpCode
      };
      return newState;

    case apiConstants.REGISTER_EMAIL_FAIL:
      newState = {
        ...state,
        regisgterOtpCode: "",
        registerEmailError: action.response.data,
        authLoader: false,
        isFetching: false
      };
      return newState;

    case apiConstants.RESET_REGISTER_AUTH_CODE_SUCCESS:
      newState = {
        ...state,
        regisgterOtpCode: ""
      };
      return newState;

    case apiConstants.CLEAR_TWO_AUTH:
      newState = {
        ...state,
        codeData: "",
        isTwoAuth: false
      };
      return newState;

    case apiConstants.CLEAR_OTP:
      newState = {
        ...state,
        otpCode: "",
        isOtp: false
      };
      return newState;

    case apiConstants.REDIRECT_BACK:
      newState = {
        ...state,
        isRedirect: true,
        domainName: action.response.domain,
        redirectUrl: action.response.redirectUrl
      };
      return newState;

    case apiConstants.REDIRECT_TO_VERIFY_EMAIL:
      newState = {
        ...state,
        error: action.response.data,
        authLoader: false,
        isFetching: false,
        redirectToVerifyEmail: true
      };
      return newState;

    case apiConstants.CLEAR_REDIRECT_TO_VERIFY_EMAIL:
      newState = {
        ...state,
        redirectToVerifyEmail: false
      };
      return newState;

    case apiConstants.TWO_FA_AND_LOGIN_WITH_RECAPTCHA_EMAIL_OTP:
      newState = {
        ...state,
        authLoader: true,
        isVerified: false
      };
      return newState;

    case apiConstants.LOGIN_WITH_TWO_FA:
      newState = {
        ...state,
        authLoader: true,
        isVerified: false
      };
      return newState;

    case apiConstants.LOGIN_WITH_TWO_FA_SUCCESS:
      newState = {
        ...state,
        loginToken: action.response.data.authCode,
        loginProfileStatus: action.response.data.profileStatus,
        authState: action.response.data.state,
        regisgterOtpCode: "",
        authLoader: false,
        isLoggedIn: true,
        isTwoAuth: false,
        isOtp: false,
        actionsRequired: action.response.data.actionsRequired,
        sessionExpired: false
      };
      return newState;

    case apiConstants.LOGIN_WITH_TWO_FA_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        authLoader: false,
        isFetching: false
      };
      return newState;

    case apiConstants.TWO_FA_OR_OTP_EXPIRE_REDIRECT:
      newState = {
        ...state,
        authLoader: false,
        isLoggedIn: false,
        isTwoAuth: false,
        isOtp: false,
        error: action.response.data
      };
      return newState;

    case apiConstants.REMOVE_PROFILE_STATUS:
      newState = {
        ...state,
        authLoader: true
      };
      return newState;

    case apiConstants.REMOVE_PROFILE_STATUS_SUCCESS:
      newState = {
        ...state,
        loginProfileStatus: true,
        authLoader: false
      };
      return newState;

    case apiConstants.REMOVE_PROFILE_STATUS_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        authLoader: false
      };
      return newState;

    case apiConstants.REGISTER_WITH_EMAIL:
      newState = {
        ...state,
        authLoader: true
      };
      return newState;

    case apiConstants.REGISTER_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        authLoader: false
      };

      return newState;

    case apiConstants.REGISTER_WITH_EMAIL_SUCCESS:
      return {
        ...state,
        registerSuccess: true,
        authLoader: false,
        apiData: action.response
      };

    case apiConstants.CHECK_IS_USER_LOGGED_IN:
      newState = {
        ...state,
        isFetching: true,
        isVerified: false
      };
      return newState;

    case apiConstants.CHECK_IS_USER_LOGGED_IN_SUCCESS:
      newState = {
        ...state,
        isLoggedIn: true,
        isFetching: false,
        isVerified: false,
        actionsRequired: action?.response?.data?.actionsRequired
      };
      return newState;

    case apiConstants.CHECK_IS_USER_LOGGED_IN_FAIL:
      newState = {
        ...state,
        isLoggedIn: false,
        isFetching: false
      };
      return newState;

    case apiConstants.LOGOUT_USER:
      newState = {
        ...state,
        isFetching: true
      };
      return newState;

    case apiConstants.LOGOUT_USER_SUCCESS:
      newState = {
        ...state,
        isFetching: false,
        isLoggedIn: false,
        codeData: "",
        authCode: ""
      };
      return newState;

    case apiConstants.LOGOUT_USER_FAIL:
      newState = {
        ...state,
        error: action?.response?.data,
        codeData: "",
        authCode: "",
        isFetching: false
      };

      return newState;

    case apiConstants.VERIFY_EMAIL:
      newState = {
        ...state,
        isFetching: true
      };
      return newState;

    case apiConstants.VERIFY_EMAIL_SUCCESS:
      newState = {
        ...state,
        isFetching: false,
        isVerified: true
      };
      return newState;

    case apiConstants.VERIFY_EMAIL_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        isFetching: false,
        isVerified: false
      };

      return newState;

    case apiConstants.CHANGE_PASSWORD:
      newState = {
        ...state,
        authLoader: true
      };
      return newState;

    case apiConstants.CHANGE_PASSWORD_SUCCESS:
      newState = {
        ...state,
        authLoader: false,
        changePasswordSuccess: action.response.message
      };

      return newState;

    case apiConstants.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        authLoader: false,
        error: action.response.data
      };

    case apiConstants.FORGOT_PASSWORD:
      newState = {
        ...state,
        authLoader: true
      };
      return newState;

    case apiConstants.FORGOT_PASSWORD_SUCCESS:
      newState = {
        ...state,
        authLoader: false,
        apiData: action.response
      };

      return newState;

    case apiConstants.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        authLoader: false,
        error: action.response.data
      };

    case apiConstants.RESET_PASSWORD:
      newState = {
        ...state,
        authLoader: true
      };
      return newState;

    case apiConstants.RESET_PASSWORD_SUCCESS:
      newState = {
        ...state,
        authLoader: false,
        apiData: action.response,
        isFetching: false
      };

      return newState;

    case apiConstants.RESET_PASSWORD_FAIL:
      return {
        ...state,
        authLoader: false,
        error: action.response.data,
        isFetching: false
      };

    case apiConstants.CLEAR_AUTH_ERRORS:
      newState = {
        ...state,
        error: ""
      };
      return newState;

    case apiConstants.GET_RECENT_LOGINS:
      newState = {
        ...state,
        isFetchingRecentLogins: true
      };

      return newState;

    case apiConstants.GET_RECENT_LOGINS_SUCCESS:
      return {
        ...state,
        isFetchingRecentLogins: false,
        recentLogins: action.response
      };

    case apiConstants.GET_RECENT_LOGINS_FAIL:
      newState = {
        ...state,
        errorGet: action.response.data,
        isFetchingRecentLogins: false
      };
      return newState;

    case apiConstants.GET_AUTH_CODE:
      newState = {
        ...state,
        authLoader: true
      };

      return newState;

    case apiConstants.GET_AUTH_CODE_SUCCESS:
      newState = {
        ...state,
        authCode: action.response,
        isRedirect: true,
        authLoader: false
      };
      return newState;

    case apiConstants.SET_AUTH_CODE_STATUS_SUCCESS:
      return {
        ...state,
        loginProfileStatus: action.response
      };

    case apiConstants.GET_AUTH_CODE_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        isRedirect: false,
        authLoader: false
      };
      return newState;

    case apiConstants.GENERATE_AUTH_CODE:
      newState = {
        ...state
      };

      return newState;

    case apiConstants.GENERATE_AUTH_CODE_SUCCESS:
      return {
        ...state,
        generatedAuthCode: action.response.token,
        loginProfileStatus: action.response.status,
        generatedAuthCodeTerminalDomain: action.response.domain
      };

    case apiConstants.GENERATE_AUTH_CODE_EXCH_SUCCESS:
      return {
        ...state,
        generatedAuthCodeExch: action.response.token,
        loginProfileStatus: action.response.status,
        generatedAuthCodeExchDomain: action.response.domain
      };

    case apiConstants.GENERATE_AUTH_CODE_FAIL:
      newState = {
        ...state,
        error: action.response.data
      };
      return newState;
    case apiConstants.VERIFY_REDIRECT_URL_SUCCESS:
      newState = {
        ...state,
        isRedirect: true,
        domainName: action.response.domain,
        redirectUrl: action.response.redirectUrl
      };
      return newState;
    case apiConstants.VERIFY_REDIRECT_URL_FAIL:
      newState = {
        ...state,
        isRedirect: false,
        domainName: "",
        redirectUrl: ""
      };
      return newState;

    case apiConstants.SESSION_EXPIRED:
      newState = {
        ...state,
        sessionExpired: true
      };
      return newState;

    default:
      return state;
  }
};

export default authReducer;
