import { Fragment, useState } from "react";
import { Button, DivElement, RouterLink, TextWrapper } from "../../Reusable";
import InputSwitch from "../../Reusable/InputSwitch";
import { acceptTCMonerium, createWallet } from "../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { formatAPIErrors } from "../../../utils/general";
import { getAllWallets } from "../../../actions/wallet";
import { compose } from "redux";
import { connect } from "react-redux";
import { LoadingButton } from "../../Loader";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyGreyIcon } from "../../../assets/svgs";

const ShowDetails = ({ hide, value, label, showCopy }) => {
  const onCopy = () => {
    showSuccessMsg(`Successfully copied ${label}`);
  };
  return (
    <DivElement displayBtw marginBottom="8px" cursor={showCopy || null}>
      <TextWrapper
        fontSize="14px"
        fontSizeSm="12px"
        lineHeight="20px"
        lineHeightSm="18px"
        secondary
      >
        {label}
      </TextWrapper>
      {hide ? (
        <TextWrapper fontSize="14px" fontSizeSm="12px" lineHeight="20px" lineHeightSm="18px">
          **** **** ****
        </TextWrapper>
      ) : (
        <Fragment>
          {showCopy ? (
            <CopyToClipboard text={value} onCopy={onCopy}>
              <DivElement dAlignCenter>
                <TextWrapper
                  fontSize="14px"
                  fontSizeSm="12px"
                  lineHeight="20px"
                  lineHeightSm="18px"
                >
                  {value}
                </TextWrapper>
                <CopyGreyIcon width="16px" height="16px" marginLeft="8px" />
              </DivElement>
            </CopyToClipboard>
          ) : (
            <TextWrapper fontSize="14px" fontSizeSm="12px" lineHeight="20px" lineHeightSm="18px">
              {value}
            </TextWrapper>
          )}
        </Fragment>
      )}
    </DivElement>
  );
};

const FiatCoinDetails = ({ selectedCoin, selectedProvider, getMainWallet }) => {
  const [isAcceptedTandC, setIsAcceptedTandC] = useState(selectedCoin?.address ? true : false);
  const [isAcceptedSelfDeclaration, setIsAcceptedSelfDeclaration] = useState(
    selectedCoin?.address ? true : false
  );
  const [loader, setLoader] = useState(false);

  const onAcceptTandCHandler = () => {
    setLoader(true);
    acceptTCMonerium()
      .then((res) => {
        let data = {
          coin: selectedCoin.coin,
          network_id: selectedProvider.id
        };

        return createWallet(data);
      })
      .then((res) => {
        getMainWallet();
      })
      .catch((err) => showErrorMsg(formatAPIErrors(err)))
      .finally(() => setLoader(false));
  };

  const hide = !(isAcceptedTandC && isAcceptedSelfDeclaration && selectedCoin?.address);

  const onClickMoneriumLink = (e) => {
    e.preventDefault();
    window.open("https://monerium.com/policies/business-terms-of-service/", "_blank");
  };

  return (
    <DivElement
      bdGrey
      border="1px solid"
      minHeight="150px"
      padding="16px"
      paddingSm="8px"
      borderRadius="5px"
    >
      <ShowDetails
        showCopy={false}
        label="Account Name"
        value={
          selectedProvider.label === "BLINC"
            ? selectedProvider.value.accountName
            : selectedProvider.value.name
        }
        hide={false}
      />
      <ShowDetails
        showCopy={false}
        label={selectedProvider?.label === "BLINC" ? "Account ID" : "Address"}
        value={
          selectedProvider.label === "BLINC"
            ? selectedProvider.value.accountId
            : selectedProvider.value.address
        }
        hide={false}
      />
      <ShowDetails
        showCopy={true}
        label={selectedProvider?.label === "BLINC" ? "BLINC ID" : "Iban"}
        value={
          selectedProvider?.label === "BLINC"
            ? selectedProvider?.value?.blincId
            : selectedProvider?.value?.iban
        }
        hide={hide}
      />

      <ShowDetails showCopy={true} label="Reference" value={selectedCoin?.address} hide={hide} />

      <ShowDetails
        showCopy={false}
        label={"LCX Fee"}
        value={selectedProvider?.depositFee?.LCX?.value}
        hide={hide}
      />
      <ShowDetails
        showCopy={false}
        label={"Provider Fee"}
        value={selectedProvider?.depositFee?.PROVIDER?.value}
        hide={hide}
      />

      {selectedProvider.label === "Monerium" && (
        <DivElement flex marginBottom="16px">
          <DivElement width="50px" paddingTop="3px">
            <InputSwitch
              checked={isAcceptedTandC}
              setChecked={() => setIsAcceptedTandC(!isAcceptedTandC)}
              disabled={selectedCoin?.address ? true : null}
            />
          </DivElement>
          <TextWrapper width="calc(100% - 50px)" fontSize="12px" lineHeight="18px" fontWeight="400">
            I hereby confirm the LCX Terms of Service and acknowledge the{" "}
            <RouterLink onClick={onClickMoneriumLink}>Monerium Terms of service</RouterLink>.
          </TextWrapper>
        </DivElement>
      )}

      {selectedProvider.label === "Monerium" && (
        <DivElement flex marginBottom="16px" marginTop="15px">
          <DivElement width="50px" paddingTop="3px">
            <InputSwitch
              checked={isAcceptedSelfDeclaration}
              setChecked={() => setIsAcceptedSelfDeclaration(!isAcceptedSelfDeclaration)}
              disabled={selectedCoin?.address ? true : null}
            />
          </DivElement>
          <TextWrapper width="calc(100% - 50px)" fontSize="12px" lineHeight="18px" fontWeight="400">
            I hereby confirm that I perform this transaction on my own account. I was neither
            contacted nor instructed by a third party to do this transaction.{" "}
          </TextWrapper>
        </DivElement>
      )}

      {!selectedCoin.address && (
        <Button
          primaryBlue
          width="100%"
          height="48px"
          disabled={!isAcceptedSelfDeclaration || !isAcceptedTandC || loader}
          onClick={onAcceptTandCHandler}
        >
          {loader ? <LoadingButton /> : "Confirm"}
        </Button>
      )}
    </DivElement>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMainWallet: () => dispatch(getAllWallets())
});

export default compose(connect(null, mapDispatchToProps))(FiatCoinDetails);
