import styled, { css } from "styled-components";
import { MarginStyle } from "../Reusable/Margin.style";
import { PaddingStyle } from "../Reusable/Padding.style";

const TableHeadComponent = styled.thead`
  display: flex;
  width: 100%;
  height: ${(props) => props.theadHeight || "40px"} !important;
  background: ${({ theme }) => theme.tableHead} !important;
  border: none;
  border-radius: 5px;
  ${(props) =>
    props.TradeOpenOrders &&
    css`
      tr {
        height: 24px;
      }
    `}
`;

const TableBodyComponent = styled.tbody`
  display: block;
  width: 100%;
  border: none;
  height: ${(props) => props.theadHeight || "auto"} !important;
  overflow: ${(props) => props.overflow || "visible"} !important;

  #activeclass2 {
    background: ${(props) => props.theme.level3CardTC} !important;
  }

  tr {
    height: 56px;
    &:last-child() {
      border: none;
    }

    &.exch-full-width {
      width: 100%;
      height: auto !important;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &.trade-exch-full-width {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      height: 100% !important;
      padding-top: 0px;
      td {
        min-height: 200px;
        flex-direction: column;
        padding-top: 30px !important;
        padding-bottom: 60px !important;
      }
    }
    &.trade-exch-full-width2 {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      height: 100% !important;
      padding-top: 0px;
      td {
        min-height: 140px;
        flex-direction: column;
        padding-top: 30px !important;
        padding-bottom: 60px !important;
      }
    }
  }

  ${(props) =>
    props.TradeWalletBalance &&
    css`
      tr {
        height: 24px;
      }
    `}

  ${(props) =>
    props.WalletBalance &&
    css`
      tr {
        height: 64px;
      }
    `}
  ${(props) =>
    props.LeaderBoard &&
    css`
      tr {
        height: 40px;
      }
    `}
    ${(props) =>
    props.MarketsTableMobileScreen &&
    css`
      &::nth-child(odd) {
        background: green;
      }
      &::nth-child(even) {
        background: blue;
      }
    `}

  ${(props) =>
    props.TradeOpenOrders &&
    css`
      overflow: auto !important;
      height: 300px !important;
      tr {
        height: 28px;
      }
    `}
  ${(props) =>
    props.TradeOpenOrders2 &&
    css`
      overflow: auto !important;
      height: 21.5vh !important;
      tr {
        height: 28px;
      }
    `}
  ${(props) =>
    props.TradeOpenOrdersMain &&
    css`
      overflow: auto !important;
      height: 23vh !important;
      tr {
        height: 28px;
      }
    `}
  ${(props) =>
    props.TradeOrderExecution &&
    css`
      overflow: auto !important;
      height: calc(100% - 20px) !important;
      tr {
        height: 28px;
      }
    `}
  ${(props) =>
    props.TradeOrderExecutionNew &&
    css`
      overflow: auto !important;
      height: 200px !important;

      @media (max-width: 1024px) {
        height: calc(100% - 20px) !important;
      }
      @media (max-width: 768px) {
        // height: 100% !important;
        height: calc(100% - 20px) !important;
      }
      tr {
        height: 28px;
      }
    `}
  ${(props) =>
    props.NoMaxHeight &&
    css`
      max-height: auto;
    `}
  ${(props) =>
    props.MarketHeight &&
    css`
      max-height: ${props.MarketHeight || 600}px;
    `}
  ${(props) =>
    props.TradeOpenExecution &&
    css`
      overflow: auto !important;
      height: calc(100% - 20px) !important;
      tr {
        height: 28px;
      }
    `}
  ${(props) =>
    props.TableAlternateBGDesign &&
    css`
      tr:nth-child(even) {
        background-color: ${(props) => props.theme.inputBackground};
      }
    `}
`;

const TableRowComponent = styled.tr`
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`} !important;
  
  ${MarginStyle}
  ${PaddingStyle}

  &:last-child() {
    border: none;
  }
  ${(props) =>
    props.TradeOpenOrders &&
    css`
      height: 28px;
    `}
  ${(props) =>
    props.TableBodyRowHeight &&
    css`
      height: 56px;
    `}
    ${(props) =>
    props.APIKeyRow &&
    css`
      height: auto !important;
      min-height: 40px !important;
    `}
  
  ${(props) =>
    props.TableBodyRowHeightWallets &&
    css`
      height: 64px !important;
    `}
  ${(props) =>
    props.OrdersPagesHeadRow &&
    css`
      border-bottom: none !important;
    `}
  ${(props) =>
    props.displayFlex &&
    css`
      display: flex;
      align-items: center;
      justify-content: flex-start;
    `}
  ${(props) =>
    props.selectedBgRow &&
    css`
      background: ${({ theme }) => theme.bgActiveTable} !important;
      border-bottom: ${({ theme }) => `0.5px solid ${theme.primary} !important`};
    `}
  
  @media (max-width: 1024px) {
    ${({ heightLg }) => heightLg && css `height: ${heightLg}`}
  }

  @media (max-width: 767px) {
    ${({ heightMd }) => heightMd && css `height: ${heightMd}`}
  }
`;

export { TableHeadComponent, TableBodyComponent, TableRowComponent };
