import React from "react";
import { NavLink } from "react-router-dom";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import AllStyle from "../../../assets/scss/pages/all.module.scss";
import { useSelector } from "react-redux";

const OrderNavbar = () => {
  const userData = useSelector(state => state.dashboard.userData);

  return (
    <BannerBox bottomBorder CardWhite height={"53px"} radius={"10px 10px 0 0 "} className={`${AllStyle.sub_navbar_link}`} display="flex" alignItems="center">
      <NavLink 
        to={`${userData?.preferredLanguage ? `/${userData?.preferredLanguage}`: ""}/order/open`}
        className={isActive => isActive ? 
          `${AllStyle.active_link}` : 
          ""
        }
      >
        Open Orders
      </NavLink>

      <NavLink 
        to={`${userData?.preferredLanguage ? `/${userData?.preferredLanguage}`: ""}/order/history`}
        className={isActive => isActive ? 
          `${AllStyle.active_link}` : 
          ""
        }
      >
        Order History
      </NavLink>

      <NavLink 
        to={`${userData?.preferredLanguage ? `/${userData?.preferredLanguage}`: ""}/order/trade-history`}
        className={isActive => isActive ? 
          `${AllStyle.active_link}` : 
          ""
        }
      >
        Order Executions
      </NavLink>
    </BannerBox>
  );
};

export default OrderNavbar;