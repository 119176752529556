import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class Pagination extends Component {
  constructor(props) {
    super(props);
    const { totalRecords = null, pageLimit = 30, pageNeighbours = 0 } = props;

    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
    this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0;

    this.pageNeighbours =
      typeof pageNeighbours === "number"
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

    this.state = { currentPage: props.offset || 1, width: window.innerWidth };
  }

  componentDidMount(){
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  gotoPage = page => {
    const { onPageChanged = f => f } = this.props;

    const currentPage = Math.max(0, Math.min(page, this.totalPages));

    const paginationData = {
      offset: currentPage
    };
    this.setState({ currentPage }, () => onPageChanged(paginationData));
  };

  handleClick = (page, evt) => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
  };

  handleMoveRight = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
  };

  fetchPageNumbers = () => {
    const totalPages = this.totalPages;
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.pageNeighbours;

    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [ leftSpillPage, ...extraPages, ...pages ];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [ ...pages, ...extraPages, rightSpillPage ];
      } else if (leftSpill && rightSpill) {
        pages = [ leftSpillPage, ...pages, rightSpillPage ];
      }

      return [ 1, ...pages, totalPages ];
    }

    return range(1, totalPages);
  };

  render() {
    if (!this.totalRecords) return null;

    if (this.totalPages === 1) return null;

    const { currentPage, width } = this.state;
    const pages = this.fetchPageNumbers();

    return (
      <Fragment>
        <nav aria-label="pagination-buttons mt-3 pull-right Pagination">
          <ul className="pagination pull-right">
            {pages.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <li key={index} className="page-item">
                    <ButtonComponent
                      OutlineOnlyPrimary
                      className="page-link txt-light-black f-16 f-lg-14 f-md-12 hght-46 hght-md-35"
                      aria-label="Previous"
                      onClick={this.handleMoveLeft}
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </ButtonComponent>
                  </li>
                );

              if (page === RIGHT_PAGE)
                return (
                  <li key={index} className="page-item">
                    <ButtonComponent
                      OutlineOnlyPrimary
                      className="page-link txt-light-black f-16 f-lg-14 f-md-12 hght-46 hght-md-35"
                      aria-label="Next"
                      onClick={this.handleMoveRight}
                    >
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </ButtonComponent>
                  </li>
                );

              return (
                <li
                  key={index}
                  className={`page-item${
                    currentPage === page ? " active" : " txt-light-black f-16 f-lg-14 f-md-12"
                  }`}
                >
                  {currentPage === page ?
                    <ButtonComponent
                      OutlinePrimary
                      className="page-link f-16 f-lg-14 f-md-12 hght-46 hght-md-35"
                      padd="8px 0"
                      width={(page === 1) ? `${width > 767 ? "55px" : "50px"}` : (page === this.props.last) ? `${width > 767 ? "55px" : "50px"}` : "40px"}
                      onClick={e => this.handleClick(page, e)}
                    >
                      {(page === 1) ? "First" :
                        (page === this.props.last) ? "Last" :
                        page
                      }
                    </ButtonComponent>
                    :
                    <ButtonComponent
                      OutlineOnlyPrimary
                      className="page-link txt-light-black f-16 f-lg-14 f-md-12 hght-46 hght-md-35"
                      padd="8px 0"
                      width={(page === 1) ? `${width > 767 ? "55px" : "50px"}` : (page === this.props.last) ? `${width > 767 ? "55px" : "50px"}` : "40px"}
                      onClick={e => this.handleClick(page, e)}
                    >
                      {(page === 1) ? "First" :
                        (page === this.props.last) ? "Last" :
                        page
                      }
                    </ButtonComponent>
                  }
                </li>
              );
            })}
          </ul>
        </nav>
      </Fragment>
    );
  }
}

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func
};

export default Pagination;
