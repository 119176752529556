/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { changePassword } from "../../lib/api";
import { trackPage } from "../../tracking/track";
import { catchAll401Errors, formatAPIErrors } from "../../utils/general";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { ChevronLeftBlueIcon, EyeCloseIcon, EyeOpenIcon, LockIcon } from "../../assets/svgs";
import { PasswordProtectedIllustration } from "../../assets/svgs/illustrations";
import { isUserLoggedIn } from "../../actions/auth";
import { connect } from "react-redux";
import PasswordChangeModal from "../../components/Modules/Settings/Security/Password/PasswordChangeModal";
import { LoadingButton } from "../../components/Loader";
import { MainElement, BannerBox, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import InputFieldFormik from "../../components/Reusable/Field/index";
import ErrorMessageDiv from "../../components/Reusable/Field/ErrorMessage";
import { checkPasswordRegExp } from "../../utils";

const changePasswordValidation = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  newPassword: Yup.string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(32, "Password must be at most 32 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,32}$/,
      "Password 8+ characters long using letters (both big and small), numbers, and symbols."
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "New Passwords must match")
    .required("Confirm Password is required")
});

function validateNewPassword(value) {
  let error;
  if (!value) {
    error = 'Required';
  } else if (!checkPasswordRegExp(value)) {
    error = 'Password 8+ characters long using letters (both big and small), numbers, and symbols.';
  }
  return error;
}

const getInitialValues = () => {
  return {
    password: "",
    newPassword: "",
    confirmNewPassword: ""
  };
};

function PasswordComponent(props) {
  const [old_pass_hide, setOld_pass_hide] = useState(false);
  const [c_new_pass_hide, setC_new_pass_hide] = useState(false);
  const [new_pass_hide, setNew_pass_hide] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  // const userData = useSelector(state => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    let page = location.pathname + location.search;
    trackPage(page);
  }, []);

  const updatePassword = (values, { setSubmitting, isValid }) => {
    setLoader(true);
    setSubmitting(true);
    let data = {
      password: values.password,
      newPassword: values.newPassword
    };

    changePassword(data)
      .then((res) => {
        showSuccessMsg(res.data.message);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
          catchAll401Errors();
          setSubmitting(false);
          props.isUserLoggedIn();
          props.history.push(`/${prevLang ? `/${prevLang}` : "/en"}`);
        }, 5000);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setLoader(false);
        setSubmitting(false);
      });
  };

  const handleEye = (value) => {
    if (value === "old_pass") {
      setOld_pass_hide(!old_pass_hide);
    } else if (value === "c_new_pass") {
      setC_new_pass_hide(!c_new_pass_hide);
    } else if (value === "new_pass") {
      setNew_pass_hide(!new_pass_hide);
    }
  };

  useEffect(() => {
    if (props.changePasswordSuccess)
      showSuccessMsg(props.changePasswordSuccess, { autoClose: 7000 });

    if (props.apiData.message) {
      showSuccessMsg(props.apiData.message, { autoClose: 7000 });
    }
  }, [props.changePasswordSuccess, props.apiData.message]);

  return (
    <MainElement paddingMd="20px 16px" padding="0px 16px">
      {showSuccessModal && <PasswordChangeModal />}
      <BannerBox width="100%">
        <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/security`} replace={true}>
          <ButtonComponent OutlineOnlyPrimary display="flex" alignItems="center">
            <ChevronLeftBlueIcon />
            Back
          </ButtonComponent>
        </Link>
      </BannerBox>

      <BannerBox position="relative" width="100%">
        <BannerHeading size={"24px"} sizeLg={"20px"} sizeMd={"18px"} mb={"8px"} weight="bold">
          Change Password
        </BannerHeading>
        <BannerHeading txtGrey size={"16px"} sizeLg={"18px"} sizeMd={"12px"}>
          You can change your password here.
        </BannerHeading>
      </BannerBox>

      <BannerSection
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        directionLg="column"
        gapLg="20px"
        marginLg="20px 0px"
      >
        <BannerBox width="40%" widthLg="100%">
          <Formik
            initialValues={getInitialValues()}
            onSubmit={updatePassword}
            validateOnMount={true}
            validationSchema={changePasswordValidation}
          >
            {({ values }) => (
              <Form>
                <BannerBox margin="25px 0px">
                  <BannerHeading size="14px" weight="300" sizeMd="12px" BodyColor margin="6px 0px">
                    {" "}
                    Old Password{" "}
                  </BannerHeading>
                  <BannerBox position="relative">
                    <LockIcon absolute zIndex="10" left="2%" top="15%" />

                    <BannerBox
                      position="absolute"
                      top="23%"
                      right="2%"
                      zIndex="10"
                      onClick={(e) => handleEye("old_pass")}
                    >
                      {old_pass_hide ? <EyeOpenIcon /> : <EyeCloseIcon />}
                    </BannerBox>

                    <InputFieldFormik
                      type={old_pass_hide ? "text" : "password"}
                      autoComplete="off"
                      name={"password"}
                      value={values.password}
                      placeholder={"**********"}
                      required="required"
                      height="44px"
                      width="100%"
                      AddBenificaryInput
                      fontSize="14px"
                      fontWeight="500"
                      BodyColor
                      padding="0rem 2.5rem"
                    />

                    <ErrorMessageDiv
                      name={"password"}
                      component="div"
                      mt="3px"
                      size="14px"
                      sizeMd="12px"
                      weight="300"
                      txtDanger
                    />
                  </BannerBox>
                </BannerBox>

                <BannerBox margin="25px 0px">
                  <BannerHeading size="14px" weight="300" sizeMd="12px" BodyColor margin="6px 0px">
                    {" "}
                    New Password{" "}
                  </BannerHeading>

                  <BannerBox position="relative">
                    <LockIcon absolute zIndex="10" left="2%" top="15%" />

                    <BannerBox
                      position="absolute"
                      top="23%"
                      right="2%"
                      zIndex="10"
                      onClick={(e) => handleEye("c_new_pass")}
                    >
                      {c_new_pass_hide ? <EyeOpenIcon /> : <EyeCloseIcon />}
                    </BannerBox>

                    <InputFieldFormik
                      type={c_new_pass_hide ? "text" : "password"}
                      autoComplete="off"
                      name={"newPassword"}
                      value={values.newPassword}
                      validate={validateNewPassword}
                      placeholder={"**********"}
                      required="required"
                      height="44px"
                      width="100%"
                      AddBenificaryInput
                      fontSize="14px"
                      fontWeight="500"
                      BodyColor
                      padding="0rem 2.5rem"
                    />

                    <ErrorMessageDiv
                      name={"newPassword"}
                      component="div"
                      mt="3px"
                      size="14px"
                      sizeMd="12px"
                      weight="300"
                      txtDanger
                    />
                  </BannerBox>
                </BannerBox>

                <BannerBox margin="25px 0px">
                  <BannerHeading size="14px" weight="300" sizeMd="12px" BodyColor margin="6px 0px">
                    Confirm Password{" "}
                  </BannerHeading>

                  <BannerBox position="relative">
                    <LockIcon absolute zIndex="10" left="2%" top="15%" />

                    <BannerBox
                      position="absolute"
                      top="23%"
                      right="2%"
                      zIndex="10"
                      onClick={(e) => handleEye("new_pass")}
                    >
                      {new_pass_hide ? <EyeOpenIcon /> : <EyeCloseIcon />}
                    </BannerBox>

                    <InputFieldFormik
                      type={new_pass_hide ? "text" : "password"}
                      autoComplete="off"
                      name={"confirmNewPassword"}
                      value={values.confirmNewPassword}
                      placeholder={"**********"}
                      required="required"
                      height="44px"
                      width="100%"
                      AddBenificaryInput
                      fontSize="14px"
                      fontWeight="500"
                      BodyColor
                      padding="0rem 2.5rem"
                    />
                    <ErrorMessageDiv
                      name={"confirmNewPassword"}
                      component="div"
                      mt="3px"
                      size="14px"
                      sizeMd="12px"
                      weight="300"
                      txtDanger
                    />
                  </BannerBox>
                </BannerBox>

                <BannerBox>
                  <ButtonComponent
                    mt="12px"
                    PrimaryButton
                    width="195px"
                    btnHeight="48px"
                    type="submit"
                    disabled={loader}
                  >
                    {loader ? <LoadingButton /> : "Change Password"}
                  </ButtonComponent>
                </BannerBox>
              </Form>
            )}
          </Formik>
        </BannerBox>

        <BannerBox
          width="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          widthLg="100%"
        >
          <PasswordProtectedIllustration />
        </BannerBox>
      </BannerSection>
    </MainElement>
  );
}

const mapDispatchToProps = (dispatch) => ({
  isUserLoggedIn: () => dispatch(isUserLoggedIn())
});

export default connect(null, mapDispatchToProps)(PasswordComponent);
