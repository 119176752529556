import React, { useState, useEffect, useMemo } from "react";
import { withRouter, useLocation } from "react-router-dom";
// import { AuthHeader, NonAuthHeader } from "../../components/Header";
import IndexFooter from "../../components/Footer/footer";
import ExchangeListing from "./ExchListing/exchangeListing";
import LcxTokenText from "./LcxTokenText/lcxToken";
import OverviewText from "./OverviewText/overviewText";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import MobileLcxTokenText from "./LcxTokenText/MobileLcxToken";
import MobileExchangeListing from "./ExchListing/mobileExchListing";
import MobileOverviewText from "./OverviewText/mobileOverviewText";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { trackPage } from "../../tracking/track";
import { useDispatch, useSelector } from "react-redux";
import { checkPairName } from "../../utils/trade";
import {
  BannerAside,
  BannerBox,
  MainElement,
  BannerSection
} from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { RouterLink } from "../../components/Reusable";
import { AnchorTag } from "../../assets/StyleComponents/FontSize/para.style";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import { H1Tag } from "../../assets/StyleComponents/FontSize/fonts.style";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const LcxTokenComponent = (props) => {
  const [activeState, setActiveState] = useState("overview");
  const { width } = useWindowSize();
  let location = useLocation();
  // const history = useHistory();
  const dispatch = useDispatch();
  const { transalationList } = useSelector(GenericReducer);
  const { isLoggedIn, userData, isTheme } = props;
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    let page = location.pathname + location.search;
    trackPage(page);
  }, []);

  let selectedPairName = useSelector((state) => state.trade.selectedPair);

  const [selectedPairActive, setSelectedPairActive] = useState("");

  useEffect(() => {
    if (location.pathname.includes("/trade") && location.pathname !== "/order/trade-history") {
      let pairActive =
        checkPairName(selectedPairName || { base_pair: "LCX", quote_pair: "EUR" }, location) ||
        "LCX/EUR";
      setSelectedPairActive("/" + pairActive.split("/").join("-"));
    }
  }, [location]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchAvailableLanguagesRequest());
      // let checkPath = location.pathname?.split("/")[1];
      // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
      //   history.push(`/${userData.preferredLanguage}${location.pathname}`);
      // }
    }
    window.scrollTo(0, 0);
  }, []);

  const SEOContents = {
    name: `LCX TOKEN`,
    link: `lcx-token`,
    title: "LCX Token ($LCX): Fueling the LCX.com Blockchain Platform & Exchange",
    description:
      "The LCX Token ($LCX) powers the LCX.com blockchain platform and cryptocurrency exchange. Use $LCX for transactions, fees, and ecosystem participation. Explore its benefits today!",
    keywords: `LCX Token`,
    canonical: `https://exchange.lcx.com/lcx-token`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}/lcx-token`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: "LCX Token ($LCX): Fueling the LCX.com Blockchain Platform & Exchange",
    OgDescription:
      "The LCX Token ($LCX) powers the LCX.com blockchain platform and cryptocurrency exchange. Use $LCX for transactions, fees, and ecosystem participation. Explore its benefits today!",
    OgURL: `https://exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/lcx-token`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "LCX Token ($LCX): Fueling the LCX.com Blockchain Platform & Exchange",
    TwDescription:
      "The LCX Token ($LCX) powers the LCX.com blockchain platform and cryptocurrency exchange. Use $LCX for transactions, fees, and ecosystem participation. Explore its benefits today!"
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/lcx-token`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <BannerBox>
        <MainElement>
          <BannerSection TutorialBg paddBottom="5.5rem" paddTop="4rem">
            <BannerBox ExchangeContainer>
              <BannerBox display="flex" direction="column">
                <BannerBox textAlign="center" flexLg="1">
                  <H1Tag BodyColor weight="bold" sizeMd="36px" size="2.75rem">
                    {" "}
                    LCX Token{" "}
                  </H1Tag>
                </BannerBox>

                <BannerBox mt="24px" textAlign="center" widthMd="100%">
                  <BannerHeading size="1.5rem" BodyColor sizeMd="18px" weight="300">
                    {" "}
                    Powering The LCX Ecosystem{" "}
                  </BannerHeading>
                </BannerBox>
              </BannerBox>

              <BannerBox
                position="absolute"
                mt="45px"
                mtLg="55px"
                width="100%"
                textAlign="center"
                left="0%"
              >
                {!isLoggedIn ? (
                  <RouterLink paddingTop="2rem" paddingBottom="2rem" to={"/register"}>
                    <ButtonComponent
                      SuccessButton
                      type="button"
                      width="477px"
                      widthMd="312px"
                      btnHeight="72px"
                      btnHeightMd="48px"
                      btnHeightLg="48px"
                      size="1.25rem"
                      sizeMd="18px"
                    >
                      Start trading in LCX Exchange
                    </ButtonComponent>
                  </RouterLink>
                ) : (
                  Object.keys(userData).length > 0 && (
                    <RouterLink
                      to={`/trade${selectedPairActive}`}
                      paddingTop="2rem"
                      paddingBottom="2rem"
                    >
                      <ButtonComponent
                        SuccessButton
                        type="button"
                        width="477px"
                        widthMd="312px"
                        btnHeight="72px"
                        btnHeightMd="48px"
                        btnHeightLg="48px"
                        size="1.25rem"
                        sizeMd="18px"
                      >
                        Start trading in LCX Exchange
                      </ButtonComponent>
                    </RouterLink>
                  )
                )}
              </BannerBox>
            </BannerBox>
          </BannerSection>

          <BannerSection BeginerBg paddBottom="5rem" paddTop="6rem" paddBottomMd="2rem">
            <BannerBox ExchangeContainer>
              <BannerBox display="flex">
                {width > 1024 && (
                  <BannerBox width="30%">
                    <BannerAside position="sticky" top="0px">
                      <BannerBox position="relative">
                        <AnchorTag
                          href="#overview"
                          onClick={() => setActiveState("overview")}
                          textDecoration="none"
                          margin="5px 0px"
                        >
                          <BannerHeading
                            size="16px"
                            Beforetab
                            txtGrey={activeState !== "overview"}
                            ActiveToken={activeState === "overview"}
                            display="flex"
                            alignItems="center"
                            height="30px"
                          >
                            {" "}
                            Overview{" "}
                          </BannerHeading>
                        </AnchorTag>
                        <AnchorTag
                          href="#lcxToken"
                          onClick={() => setActiveState("lcxToken")}
                          textDecoration="none"
                          margin="5px 0px"
                        >
                          <BannerHeading
                            size="16px"
                            Beforetab
                            txtGrey={activeState !== "lcxToken"}
                            ActiveToken={activeState === "lcxToken"}
                            display="flex"
                            alignItems="center"
                            height="30px"
                          >
                            {" "}
                            Why should I buy LCX Token?{" "}
                          </BannerHeading>
                        </AnchorTag>
                        <AnchorTag
                          href="#exchListen"
                          onClick={() => setActiveState("exchListen")}
                          textDecoration="none"
                          margin="5px 0px"
                        >
                          <BannerHeading
                            size="16px"
                            Beforetab
                            txtGrey={activeState !== "exchListen"}
                            ActiveToken={activeState === "exchListen"}
                            display="flex"
                            alignItems="center"
                            height="30px"
                          >
                            {" "}
                            Current Exchange Listing{" "}
                          </BannerHeading>
                        </AnchorTag>
                      </BannerBox>
                    </BannerAside>
                  </BannerBox>
                )}

                <BannerBox width={width > 1024 ? "70%" : "100%"} mt="1.2rem" overflow="scroll">
                  {width > 767 && (
                    <BannerBox id="overview" display="flex" direction="column" width="100%">
                      <OverviewText isTheme={isTheme} {...props} />
                    </BannerBox>
                  )}

                  {width > 1024 && (
                    <BannerBox
                      id="lcxToken"
                      display="flex"
                      direction="column"
                      width="100%"
                      paddingTop="25px"
                    >
                      <LcxTokenText isTheme={isTheme} {...props} />
                    </BannerBox>
                  )}

                  {width > 1024 && (
                    <BannerBox
                      id="exchListen"
                      display="flex"
                      direction="column"
                      width="100%"
                      paddingTop="25px"
                    >
                      <ExchangeListing isTheme={isTheme} {...props} />
                    </BannerBox>
                  )}

                  {width < 1025 && (
                    <>
                      {width < 768 && <MobileOverviewText isTheme={isTheme} {...props} />}
                      <MobileLcxTokenText isTheme={isTheme} {...props} />
                      <MobileExchangeListing isTheme={isTheme} {...props} />
                    </>
                  )}
                </BannerBox>
              </BannerBox>
            </BannerBox>
          </BannerSection>
          {/* <hr/> */}
        </MainElement>

        {/* Footer Index */}
        <IndexFooter {...props} />
      </BannerBox>
    </>
  );
};

export default withRouter(LcxTokenComponent);
