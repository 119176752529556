/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { compose } from "redux";
import { useLocation } from "react-router-dom";
import { getUserDetails } from "../../actions/dashboard";
import { AuthRouter } from "../../router";
import { connect, useDispatch, useSelector } from "react-redux";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PrivateSocketConnection } from "../../components/Socket/privateSocket";
import ActionRequired from "../../components/Modules/ActionRequired";
import { Fragment } from "react";
import { getAuthCode, verifyRedirectUrl } from "../../actions/auth";
import { config } from "../../constants";
import { PageLoader } from "../../components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DepositContext } from "../../contexts/WalletPage/DepositPage/depositContext";
import { WalletContext } from "../../contexts/WalletPage/BalancePage/wallet";
import DectaTransactionStatus from "../../components/Wallets/Deposits/DectaTransactionStatus";
import LangCheckComponent from "../../Layouts/LangCheckComponent";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList, getUserSelectedLang } from "../../Modules/Generic/selectors";
import { modifyPreferedLanguage } from "../../lib/api";
import { setLocalStorage } from "../../utils/helpers";

const MainAuthProps = createStructuredSelector({
  transalationList: getTranslationLanguageList(),
  userSelectedLang: getUserSelectedLang()
});

const MainAuth = ({
  getUserDetails,
  userData,
  isFetchingUserData,
  actionsRequired,
  verifyRedirectUrl,
  domainName,
  getAuthCode,
  authCode,
  authState,
  isRedirect,
  redirectUrl,
  authToken
}) => {
  const [txnIdToShowStatus, setTxnIdToShowStatus] = useState("");
  const contextValue = useContext(PrivateSocketConnection);
  const { socketStatus, startSocketConection, socketDisconnect } = contextValue || {};
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  let { callAllWallet } = useContext(WalletContext);
  let { callGetDeposits } = useContext(DepositContext);
  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  let { transalationList, userSelectedLang } = useSelector(MainAuthProps);
  let transalationListMemo = useMemo(() => transalationList, [transalationList]);

  useEffect(() => {
    if (
      location.pathname !== "/" ||
      location.pathname !== "/setting/profile" ||
      location.pathname !== "/verify/verification-plus"
    )
      getUserDetails();
    const params = new URLSearchParams(location.search);
    const redirect = params.get("redirectUrl");
    if (redirect !== null && redirect !== "") {
      verifyRedirectUrl(redirect);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchAvailableLanguagesRequest());
      let checkPath = location.pathname?.split("/")[1];
      const prevLang = localStorage.getItem("ljs-lang");
      if (!isRedirect) {
        if(transalationListMemo && transalationListMemo.length > 0){
          let setLang = "";
          let checkPathRouteIsValid = transalationListMemo && transalationListMemo.length > 0 && transalationListMemo.find(d => d.code === checkPath);
          let prevLangRouteIsValid = transalationListMemo && transalationListMemo.length > 0 && transalationListMemo.find(d => d.code === prevLang);
          // eslint-disable-next-line no-console
          // console.log(checkPath, "check Path", prevLang);
          // eslint-disable-next-line no-debugger
          // debugger;
          if(checkPathRouteIsValid && checkPathRouteIsValid.code && checkPath) {
            // eslint-disable-next-line no-console
            // console.log(checkPathRouteIsValid, "calling", location.pathname, "current Deekho ji ", `${location.pathname}`);
            // setLang = checkPath;
            // history.push(
            //   `${location.pathname.replace(checkPath, prevLang)}`
            // );
          } else if(prevLangRouteIsValid && prevLangRouteIsValid.code){
            setLang = prevLang;
            history.push(
              `/${prevLang || "en"}${location.pathname}`
            );
          }
          else {
            // if(checkPath)
            history.push(
              `/${prevLang || checkPath || "en"}${location.pathname}`
            );
          }
        }
        // if(location.pathname === `/${prevLang || checkPath}`){
        //   console.log(
        //     `${location.pathname}`
        //   );
        // } else {
        //   console.log(`${location.pathname}`);
        // }
        // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
          // history.push(
          //   `${checkPath ? `/${checkPath}` : prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}${
          //     location.pathname
          //   }`
          // );
        // }
      }
      localStorage.setItem("ljs-lang", prevLang ? prevLang || checkPath : userData.preferredLanguage);
      setLocalStorage(new Date().getTime(), "last-lang-update-time");
      window.Localize.setLanguage(prevLang ? prevLang || checkPath : userData.preferredLanguage);
    }
  }, [location, userData, isRedirect]);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/setting/profile" ||
      location.pathname === "/verify/verification-plus"
    )
      callUserDetailApi();
  }, [location && location.pathname]);

  const callUserDetailApi = useCallback(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (socketStatus && socketStatus.readyState && socketStatus.readyState !== 1 && userData) {
      startSocketConection(authToken, userData.userid);
    }
    // eslint-disable-next-line
  }, [socketStatus, userData]);

  useEffect(() => {
    if (actionsRequired && actionsRequired.length === 0 && domainName) {
      getAuthCode(domainName);
    }
    // eslint-disable-next-line
  }, [actionsRequired, domainName]);

  useEffect(() => {
    if (authCode && authState && isRedirect) {
      if (domainName === "tiamonds") {
        window.location = `${redirectUrl}?token=${authCode}&state=${authState}`;
      }
    }
    // eslint-disable-next-line
  }, [authCode, authState, isRedirect]);

  useEffect(() => {
    if (userData) {
      localStorage.setItem("user", JSON.stringify(userData));
      localStorage.setItem("balanceValue", JSON.stringify(userData.dashboard));

      getDectaTransactionId();
    }
  }, [userData]);

  useEffect(() => {
    if (!socketStatus || !userData) return;
    let accessToken = localStorage.getItem("accessToken");

    if (Object.keys(socketStatus).length === 0) {
      if (socketStatus?.readyState !== 1) {
        startSocketConection(accessToken, userData.userid);
      }
    }

    return () => {
      socketDisconnect(accessToken, userData.userid);
    };
    // eslint-disable-next-line
  }, [socketStatus, userData]);

  const getDeposits = () => {
    let data = {
      coin: "",
      order: "DESC",
      type: "DEPOSIT",
      page: 0
    };

    callGetDeposits(data);
  };

  const getDectaTransactionId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const transactionId = urlParams.get("txnId");
    const dectaId = localStorage.getItem(transactionId);
    if (dectaId && dectaId !== undefined) {
      urlParams.delete("txnId");
      history.replace({
        search: urlParams.toString()
      });
      localStorage.removeItem(transactionId);
      setTxnIdToShowStatus(dectaId);
    }
  };

  const onCloseHandler = () => {
    setTxnIdToShowStatus("");
    getDeposits();
    callAllWallet();
  };
  return (
    <Fragment>
      {isFetchingUserData ? (
        <PageLoader />
      ) : (
        <Fragment>
          {actionsRequired && actionsRequired.length !== 0 ? (
            <ActionRequired />
          ) : (
            <Fragment>
              {/* <LangCheckComponent /> */}
              <AuthRouter />
              {txnIdToShowStatus && (
                <DectaTransactionStatus txnId={txnIdToShowStatus} onClose={onCloseHandler} />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData,
  isFetchingUserData: state.dashboard.isFetchingUserData,
  isLoggedIn: state.auth.isLoggedIn,
  isRedirect: state.auth.isRedirect,
  actionsRequired: state.auth.actionsRequired,
  redirectUrl: state.auth.redirectUrl,
  domainName: state.auth.domainName,
  error: state.auth.error,
  authCode: state.auth.authCode,
  authState: state.auth.authState,
  authToken: state.auth.authToken
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: () => dispatch(getUserDetails()),
  verifyRedirectUrl: (url) => dispatch(verifyRedirectUrl(url)),
  getAuthCode: (domain) => dispatch(getAuthCode(domain))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MainAuth);
