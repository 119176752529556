import styled, { css } from "styled-components";
import { Button, DivElement, TextWrapper } from "../../../../../components/Reusable";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useState } from "react";
import { CloseIcon } from "../../../../../assets/svgs";
import OrderForm from "../../Components/OrderForm";
import { updateOrderFormSide } from "../../../../../actions/trading";
import { BannerHeadingSpan } from "../../../../../assets/StyleComponents/Exchange.style";

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(1px);
  padding: 4px 16px 40px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`;

const FormWrapper = styled.div`
  position: fixed;
  bottom: -100vh;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(1, 34, 67, 0.4);
  z-index: 10001;
  display: flex;
  align-items: flex-end;
  transition: bottom 0.5s ease;

  ${(props) =>
    props.show &&
    css`
      bottom: 0;
    `}
`;

const MobileOrderForm = () => {
  const [showModal, setShowModal] = useState(false);
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const orderFormSide = useSelector((state) => state.trading.orderFormSide);
  const dispatch = useDispatch();

  const onOpenModal = (side) => {
    dispatch(updateOrderFormSide(side));
    setShowModal(true);
  };

  return (
    <Fragment>
      <Container>
        <Button
          width="calc(50% - 8px)"
          height="48px"
          primaryGreen
          onClick={() => onOpenModal("buy")}
          padding="0"
        >
          <BannerHeadingSpan mr="4px">
            Buy 
          </BannerHeadingSpan>
          <var>{selectedPair.base}</var>
        </Button>
        <Button
          width="calc(50% - 8px)"
          height="48px"
          primaryRed
          onClick={() => onOpenModal("sell")}
          padding="0"
        >
          <BannerHeadingSpan mr="4px">
            Sell 
          </BannerHeadingSpan>
          <var>{selectedPair.base}</var>
        </Button>
      </Container>
      <FormWrapper show={showModal ? true : null}>
        <DivElement width="100%" maxHeight="528px" bgCard padding="16px">
          <DivElement displayBtw marginBottom="24px">
            <TextWrapper fontSize="16px" lineHeight="24px">
              Order Form
            </TextWrapper>
            <CloseIcon onClick={() => setShowModal(false)} />
          </DivElement>
          <OrderForm orderFormSide={orderFormSide} />
        </DivElement>
      </FormWrapper>
    </Fragment>
  );
};

export default MobileOrderForm;
