/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { LeaderboardRow } from "../../../assets/StyleComponents/Table/Comptition/Leaderboard.style";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { Pagination } from "../../../components/Reusable";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../../constants/Images/imageIndex";
import { connect, useSelector } from "react-redux";
import { getLeaderBoard, getPrivateLeaderBoard } from "../../../actions/competition";
import { useParams } from "react-router-dom";
import DollarImageComponent from "../../../components/DollarImage/dollarImage";
import { checkThousand, numberWithCommas } from "../../../utils/general";
// import { TrophyIllustration } from "../../../assets/svgs/illustrations";
import NoRecordsFound from "../../../components/NoRecordsFound/noRecordsFound";
import { new_BN } from "../../../utils/bigNumber";
import { ImgElement } from "../../../components/Reusable";
import { TableLoader } from "../../../components/Loader";
import { Table, Thead, Tbody, Td, Th } from "../../../components/Reusable";
import { LeaderBoardLogo } from "../../../assets/svgs/illustrations";

function LeaderBoard(props) {
  const [leaderboard, setLeaderBoard] = useState([]);
  const [userRank, setuserRank] = useState({});
  const [offset, setOffset] = useState(1);
  const { width } = useWindowSize();
  const isTheme = useSelector((state) => state.theme.isTheme);
  const {
    leaderboardList,
    leaderboardFetching,
    singleComptitionData,
    privateLeaderboardList,
    privateLeaderboardFetching
  } = props;
  const param = useParams();
  let id = param.id;

  useEffect(() => {
    if (props.userLogin) {
      setLeaderBoard(privateLeaderboardList?.data?.leaderboard);
      setuserRank(privateLeaderboardList?.data?.userRank);
    } else {
      setLeaderBoard(leaderboardList?.data?.leaderboard);
    }
  }, [leaderboardList, privateLeaderboardFetching]);

  const getLeaderBoard = () => {
    const data = {
      competitionId: id,
      offset: offset,
      limit: 5
    };
    props.userLogin ? props.getPrivateLeaderBoard(data) : props.getLeaderBoard(data);
  };

  useEffect(() => {
    getLeaderBoard();
  }, [offset]);

  return (
    <>
      <BannerBox
        CardWhite
        radius={"10px"}
        padding={"32px 32px 48px"}
        paddingLg={"24px 20px 48px"}
        paddingMd={"16px 12px 40px"}
        margin="16px 0px"
      >
        <BannerHeading size="20px" weight="500" sizeMd="16px" sizeLg="18px">
          Leader Board 🔥
        </BannerHeading>
        <BannerBox display="flex" gap="2%">
          <BannerBox margin="1rem 0px" width={width > 1024 ? "50%" : "100%"}>
            <BannerBox>
              <Table bRadius width="100%" marginBottom="16px" TableBodyRowHeight>
                <Thead height="40px" fontSize="14px" fontSizeMd="12px">
                  <LeaderboardRow displayFlex OrdersPagesHeadRow>
                    <Th scope="col" textAlign="left" pl="8px">
                      Name
                    </Th>
                    <Th scope="col" textAlign="left">
                      Rank
                    </Th>
                    <Th scope="col" textAlign="left" pr="8px">
                      Trading Volume
                    </Th>
                  </LeaderboardRow>
                </Thead>

                {props.status !== "upcoming" && (
                  <Tbody LeaderBoard fontSizeMd="14px" fontSizeSm="12px" fontSize="14px">
                    {leaderboardFetching && (
                      <TableLoader rows={3} webCol={3} tabletCol={3} mobileCol={3} />
                    )}
                    {privateLeaderboardFetching && (
                      <TableLoader rows={3} webCol={3} tabletCol={3} mobileCol={3} />
                    )}

                    {!privateLeaderboardFetching && leaderboard && props.userLogin && (
                      <>
                        {singleComptitionData &&
                          singleComptitionData.Participated &&
                          props.userLogin && (
                            <LeaderboardRow displayFlex key={"main"} selectedBgRow>
                              <Td pl="8px">YOU</Td>

                              <Td
                                display="flex"
                                fontWeight={
                                  (userRank?.rank === 1 ||
                                    userRank?.rank === 2 ||
                                    userRank?.rank === 3) &&
                                  "bold"
                                }
                              >
                                {userRank?.rank}
                                {/* {(userRank?.rank === 1 ||
                                userRank?.rank === 2 ||
                                userRank?.rank === 3) && (
                                  <BannerHeadingSpan ml="8px">
                                    <TrophyIllustration width="20px" height="20px" />
                                  </BannerHeadingSpan>
                                )} */}
                              </Td>

                              <Td pr="8px">
                                {/* ${userRank?.volumeTradedUSD} */}
                                <DollarImageComponent
                                  volumeStatus={true}
                                  givenClass={"hght-14 ml-1"}
                                  DollarAmount={numberWithCommas(
                                    checkThousand(new_BN(userRank?.volumeTradedUSD || 0))
                                  )}
                                />
                              </Td>
                            </LeaderboardRow>
                          )}
                        {leaderboard.length > 0 &&
                          leaderboard.map((item, index) => {
                            return (
                              <LeaderboardRow displayFlex key={index}>
                                <Td pl="8px" paddingLeft="8px">
                                  {item.userName}
                                </Td>

                                <Td
                                  display="flex"
                                  fontWeight={
                                    (item?.rank === 1 || item?.rank === 2 || item?.rank === 3) &&
                                    "bold"
                                  }
                                >
                                  {item?.rank}
                                  {/* {(item?.rank === 1 || item?.rank === 2 || item?.rank === 3) && (
                                    <BannerHeadingSpan ml="8px">
                                      <TrophyIllustration width="20px" height="20px" />
                                    </BannerHeadingSpan>
                                  )} */}
                                </Td>

                                <Td pr="8px">
                                  {/* ${item.volumeTradedUSD} */}
                                  <DollarImageComponent
                                    volumeStatus={true}
                                    givenClass={"hght-14 ml-1"}
                                    DollarAmount={numberWithCommas(
                                      checkThousand(item?.volumeTradedUSD)
                                    )}
                                  />
                                </Td>
                              </LeaderboardRow>
                            );
                          })}
                      </>
                    )}
                    {!leaderboardFetching && leaderboard && !props.userLogin && (
                      <>
                        {leaderboard.length > 0 &&
                          leaderboard.map((item, index) => {
                            return (
                              <LeaderboardRow displayFlex key={index} style={{ height: "40px" }}>
                                <Td pl="8px">{item.userName}</Td>

                                <Td
                                  display="flex"
                                  fontWeight={
                                    (item?.rank === 1 || item?.rank === 2 || item?.rank === 3) &&
                                    "bold"
                                  }
                                >
                                  {item?.rank}
                                  {/* {(item?.rank === 1 || item?.rank === 2 || item?.rank === 3) && (
                                    <BannerHeadingSpan ml="8px">
                                      <TrophyIllustration width="20px" height="20px" />
                                    </BannerHeadingSpan>
                                  )} */}
                                </Td>

                                <Td pr="8px">
                                  {/* ${item.volumeTradedUSD} */}
                                  <DollarImageComponent
                                    volumeStatus={true}
                                    givenClass={"hght-14 ml-1"}
                                    DollarAmount={numberWithCommas(
                                      checkThousand(item?.volumeTradedUSD)
                                    )}
                                  />
                                </Td>
                              </LeaderboardRow>
                            );
                          })}
                      </>
                    )}
                    {!leaderboardFetching && leaderboard?.length === 0 && (
                      <BannerBox mt="15px">
                        <NoRecordsFound text={"Leader Board Not Found"} />
                      </BannerBox>
                    )}
                  </Tbody>
                )}
                {props.status === "upcoming" && (
                  <BannerBox
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                  >
                    <LeaderBoardLogo width="200px" height="200px" />
                    <BannerHeading textAlign="center" margin="1rem 0px">
                      Competition not started yet
                    </BannerHeading>
                  </BannerBox>
                )}
              </Table>
              {leaderboard && leaderboard.length > 0 && props?.status !== "upcoming" && (
                <Pagination
                  page={offset}
                  setPage={setOffset}
                  totalCount={
                    !props.userLogin
                      ? leaderboardList?.data?.totalCount || 0
                      : privateLeaderboardList?.data?.totalCount || 0
                  }
                  itemPerPage={5}
                />
              )}
            </BannerBox>
          </BannerBox>
          {width > 1025 && (
            <BannerBox width="50%">
              <ImgElement
                src={
                  !props.isTheme ? IMAGE_PREFIX.LeaderBoardwhite : IMAGE_PREFIX_DARK.LeaderBoardDark
                }
                width="100%"
                alt="animi"
              />
            </BannerBox>
          )}
        </BannerBox>
      </BannerBox>
    </>
  );
}

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  leaderboardList: state.competition.leaderboardList,
  privateLeaderboardList: state.competition.privateLeaderboardList,
  leaderboardFetching: state.competition.leaderboardFetching,
  privateLeaderboardFetching: state.competition.privateLeaderboardFetching,
  userLogin: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
  getLeaderBoard: (data) => dispatch(getLeaderBoard(data)),
  getPrivateLeaderBoard: (data) => dispatch(getPrivateLeaderBoard(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoard);
