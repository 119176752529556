/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import { ErrorMessage, Field } from "formik";
import { useState } from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import Select, { components } from "react-select";
import { reactDropdownStyles } from "../../../utils";
import { DropdownIndicator } from "../../ReusedComponent/SelectReused";
import { compose } from "redux";
import { connect } from "react-redux";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { Label, ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import InputFieldFormik from "../../../components/Reusable/Field/index";
import Checkbox from "../Checkbox";

const getOtherFromValue = (value) => {
  if (value && value.includes("Other")) return "Other";
  return value;
};

const MultipleDropdownWithSpecifyOtherInput = ({
  title,
  name,
  value,
  errorProps,
  setFieldValue,
  placeholder,
  error,
  containerClass,
  isOtherTitle,
  isPlaceHolderTitle,
  options,
  disabled,
  isMultiStatus,
  isDarkTheme,
  isOtherText,
  SetisOtherText
}) => {
  const [isOther, setIsOther] = useState(false);
  const [isOtherName, setIsOtherName] = useState("");
  const [isOtherTextProps, setIsOtherTextProps] = useState(isOtherText ? isOtherText : "");
  const [selected, setSelected] = useState(
    value ? { label: getOtherFromValue(value), value: getOtherFromValue(value) } : ""
  );

  // useEffect(() => {
  //   SetisOtherText(isOtherTextProps);
  // }, [isOtherText]);

  useEffect(() => {
    if (value && value.length > 0) {
      let checkOtherField = value.filter((data) => data.label === "Other");
      if (!value && !isOther) {
        setIsOther(false);
      } else if (checkOtherField && checkOtherField.length > 0) {
        setIsOtherName(`${name}[${value.findIndex((val) => val.label === "Other")}]`);
        setIsOther(true);
        // setFieldValue(name, "");
      } else if (checkOtherField && checkOtherField.length === 0) {
        setIsOther(false);
      } else {
        let index = options.findIndex((val) => val.value === value);
        if (index < 0) {
          setIsOtherName(`${name}[${value.findIndex((val) => val.label === "Other")}]`);
          setIsOther(true);
        } else {
          setIsOther(false);
        }
      }
    } else {
      setIsOther(false);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    // if (!selected && options) {
    if (selected && options) {
      setSelected(options.find((option) => option.value === value));
    }
    // eslint-disable-next-line
  }, [value, options]);

  useEffect(() => {
    if (isOther && !selected) {
      setSelected(value);
      // setSelected({ label: "Other", value: "Other" });
    }
    // eslint-disable-next-line
  }, [isOther, selected]);

  const onChangeHandler = (value) => {
    if (isMultiStatus) {
      if (Array.isArray(value)) {
        let resultData = [];
        value.map((item, index) => {
          let quotient = Math.floor(100 / value.length);
          let remainder = 100 % value.length;
          let resultArray = Array(value.length).fill(quotient);

          for (let i = 0; i < remainder; i++) {
            value.sourceWealth = item.value;
            value.percentage = resultArray[i];
            value.imgUrl = "";
            resultArray[i]++;
          }

          let obj = {
            label: item.label,
            value: item.value,
            sourceWealth: item.value,
            percentage: resultArray[index],
            imgUrl: item.imgUrl ? item.imgUrl : ""
          };

          resultData.push(obj);
        });

        setSelected(resultData || value);
        setFieldValue(name, resultData || value);
      }
    } else {
      setSelected(value);
      setFieldValue(name, isMultiStatus ? value : value.label);
    }
  };

  const removeOther = (val) => {
    if (val && val.length > 0) {
      let findOtherLabel = val.find((d) => d.label === "Other");
      if (findOtherLabel) {
        const otherValue = findOtherLabel.value.split(" - ");
        if (otherValue.length <= 1) {
          return otherValue[0] === "Other" ? "" : otherValue[0];
        } else {
          return otherValue[1];
        }
      }
    }
  };

  const handleUpdateOtherValue = (currentEvent, setFieldValue) => {
    let findCurrentIndex = value.findIndex((d) => d.label === "Other");
    // if(!findCurrentIndex) return;
    // else {
    setFieldValue(`${isOtherName}.value`, currentEvent);
    setFieldValue(`${isOtherName}.sourceWealth`, currentEvent);
    // }
  };

  const MoreSelectedBadge = ({ items }) => {
    const title = items.join(", ");
    const length = items.length;
    const label = `+${length}`;

    return (
      <BannerBox BadgeForDropDown size={`${length > 9 ? "10px" : "12px"}`} title={title}>
        {label}
      </BannerBox>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = props.maxToShow ? props.maxToShow : 2;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);

    return index < maxToShow ? (
      <components.MultiValue {...props} />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      display: "flex "
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <Checkbox id="lcx-checkbox" checked={isSelected} setChecked={() => {}} />
        <BannerHeadingSpan ml="8px">
          <label htmlFor="lcx-checkbox"> {children} </label>
        </BannerHeadingSpan>
      </components.Option>
    );
  };

  return (
    <Fragment>
      <BannerBox
        width="100%"
        mb="32px"
        mbMd="25px"
        className={`fInput__form__field ${containerClass || ""}`}
      >
        <Label txtGrey size="14px" width="100%">
          {title}
        </Label>
        <Select
          className={`${
            error && !isOther ? "fInput__form__input--bRed" : "fInput__form__input--bGrey"
          } ${disabled ? "fInput__form__input--Disabled" : ""}`}
          components={{
            DropdownIndicator,
            MultiValue,
            Option: InputOption
          }}
          name={name}
          isSearchable={true}
          placeholder={placeholder}
          onChange={onChangeHandler}
          styles={reactDropdownStyles({ isDarkTheme, disabled, isMultiStatus })}
          options={options}
          value={selected || value || ""}
          isDisabled={disabled}
          minMenuHeight={200}
          maxMenuHeight={200}
          menuPlacement="auto"
          closeMenuOnSelect={isMultiStatus ? !isMultiStatus : true}
          hideSelectedOptions={isMultiStatus ? !isMultiStatus : true}
          isMulti={isMultiStatus ? isMultiStatus : false}
          isClearable={isMultiStatus ? !isMultiStatus : false}
        />
        {!isOther && (
          <ErrorMessage
            name={name}
            component="div"
            className="fInput__form__error"
            {...errorProps}
          />
        )}
      </BannerBox>

      {isOther && (
        <BannerBox
          width="100%"
          mb="32px"
          mbMd="25px"
          className={`fInput__form__field ${isOther ? "w-100" : ""} ${containerClass || ""}`}
        >
          <Label txtGrey size="14px" width="100%">
            {isOtherTitle || title}
          </Label>
          <BannerBox
            width="100%"
            className={`${isOtherTitle ? "fInput__form__textAreaInput" : "fInput__form__input"} ${
              disabled ? "fInput__form__input--Disabled" : ""
            } ${error ? "fInput__form__input--bRed" : ""}`}
          >
            <InputFieldFormik
              BodyColor
              type={"textarea"}
              as="textarea"
              className="form-control"
              name={`${isOtherName}.value`}
              disabled={disabled}
              value={removeOther(value)}
              style={
                isOtherTitle
                  ? {
                      resize: "none",
                      width: "100%",
                      height: "100%",
                      border: "none",
                      outline: "none"
                      // , color: disabled ? `${isDarkTheme ? "#dedfe0" : "#012243"}` : ""
                    }
                  : {}
              }
              placeholder={isPlaceHolderTitle || "Enter the source"}
              onChange={(e) => {
                handleUpdateOtherValue(`Other - ${e.target.value}`, setFieldValue);
                // setIsOtherTextProps(e.target.value ? `Other - ${e.target.value}` : "");
                // SetisOtherText(e.target.value ? `Other - ${e.target.value}` : "");
              }}
            />
          </BannerBox>
          <ErrorMessage
            name={name}
            component="div"
            className={"fInput__form__error"}
            {...errorProps}
          />
        </BannerBox>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(MultipleDropdownWithSpecifyOtherInput);
