import { connect } from "react-redux";
import { compose } from "redux";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import styledConstants from "../../../constants/styledConstants";

const Btn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
  text-decoration: ${(props) => (props.textDecoration ? props.textDecoration : "none")};
  width: ${(props) => (props.width ? props.width : "fit-content")};
  height: ${(props) => (props.height ? props.height : "44px")};
  padding: ${(props) => (props.padding ? props.padding : "0 40px")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "5px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  background: ${(props) => (props.bg ? props.bg : "")} !important;
  color: ${(props) => (props.color ? props.color : "")} !important;

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 1024px) {
    width: ${(props) => (props.widthMd ? props.widthMd : "")};
    height: ${(props) => (props.heightMd ? props.heightMd : "")};
    padding: ${(props) => (props.paddingMd ? props.paddingMd : "")};
    margin: ${(props) => (props.marginMd ? props.marginMd : "")};
    margin-top: ${(props) => (props.marginTopMd ? props.marginTopMd : "")};
    margin-right: ${(props) => (props.marginRightMd ? props.marginRightMd : "")};
    margin-bottom: ${(props) => (props.marginBottomMd ? props.marginBottomMd : "")};
    margin-left: ${(props) => (props.marginLeftMd ? props.marginLeftMd : "")};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.widthSm ? props.widthSm : "")};
    height: ${(props) => (props.heightSm ? props.heightSm : "")};
    padding: ${(props) => (props.paddingSm ? props.paddingSm : "")};
    margin: ${(props) => (props.marginSm ? props.marginSm : "")};
    margin-top: ${(props) => (props.marginTopSm ? props.marginTopSm : "")};
    margin-right: ${(props) => (props.marginRightSm ? props.marginRightSm : "")};
    margin-bottom: ${(props) => (props.marginBottomSm ? props.marginBottomSm : "")};
    margin-left: ${(props) => (props.marginLeftSm ? props.marginLeftSm : "")};
  }

  /* Primary Blue Button */
  ${(props) =>
    props.primaryBlue &&
    css`
      background-color: ${props.isDarkTheme ? "#70a6e0" : "#0470e1"};
      color: ${props.isDarkTheme ? "#012243 !important" : "#fff !important"};
    `}

  /* Secondary Blue Button */
  ${(props) =>
    props.secondaryBlue &&
    css`
      background-color: transparent;
      border: 2px solid ${props.isDarkTheme ? "#70a6e0" : "#0470e1"};
      color: ${props.isDarkTheme ? "#70a6e0" : "#0470e1"} !important;

      &:hover {
        background-color: ${props.isDarkTheme ? "#70a6e0" : "#0470e1"};
        color: ${props.isDarkTheme ? "#012243" : "#fff"} !important;
      }
    `}

  /* Primary Red Button */
  ${(props) =>
    props.primaryRed &&
    props.isTheme === "lcx_light" &&
    css`
      background-color: #e65252;
      color: #fff !important;
    `}

  ${(props) =>
    props.primaryRed &&
    props.isTheme === "lcx_dark" &&
    css`
      background-color: #e66767;
      color: #012243 !important;
    `}

  /* Secondary Red Button */
  ${(props) =>
    props.secondaryRed &&
    props.isTheme === "lcx_light" &&
    css`
      background-color: transparent;
      border: 2px solid #e65252;
      color: #e65252 !important;

      &:hover {
        background-color: #e65252;
        color: #fff !important;
      }
    `}

  ${(props) =>
    props.secondaryRed &&
    props.isTheme === "lcx_dark" &&
    css`
      background-color: transparent;
      border: 2px solid #e66767;
      color: #e66767 !important;

      &:hover {
        background-color: #e66767;
        color: #012243 !important;
      }
    `}

  ${(props) =>
    props.primaryWhite &&
    css`
      background-color: #fff;
      border: 2px solid #fff;
      color: #0470e1 !important;

      &:hover {
        background-color: #fff;
        color: #0470e1 !important;
      }
    `}

  /* Secondary Grey Button */
  ${(props) =>
    props.primaryGrey &&
    css`
      border: 2px solid ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
      background-color: ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
      color: ${(props) => (props.isDarkTheme ? "#012243" : "#fff")};
    `}

  ${(props) =>
    props.secondaryGrey &&
    css`
      background-color: transparent;
      border: 2px solid ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
      color: ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};

      &:hover {
        background-color: ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
        color: #012243;
      }
    `}

  ${(props) =>
    props.secondaryGreyDisabled &&
    css`
      background-color: transparent;
      border: 2px solid ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
      color: ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}

  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `}
  ${(props) =>
    props.ReferalButton &&
    css`
      color: ${(props) => props.theme.primary};
    `}

    ${(props) =>
    props.blueTextBtn &&
    css`
      padding: 0;
      text-decoration: underline !important;
      background-color: transparent;
      color: ${props.isDarkTheme ? "#70a6e0" : "#0470e1"} !important;

      &:hover {
        background-color: transparent;
        color: ${props.isDarkTheme ? "#70a6e0" : "#0470e1"} !important;
      }
    `}

    
  /* Secondary Green Button */
  ${(props) =>
    props.secondaryGreen &&
    css`
      background-color: transparent;
      border: 2px solid
        ${(props) =>
          props.isDarkTheme ? styledConstants.GREEN_COLOR_DARK : styledConstants.GREEN_COLOR_LIGHT};
      color: ${(props) =>
        props.isDarkTheme ? styledConstants.GREEN_COLOR_DARK : styledConstants.GREEN_COLOR_LIGHT};

      &:hover {
        background-color: ${(props) =>
          props.isDarkTheme ? styledConstants.GREEN_COLOR_DARK : styledConstants.GREEN_COLOR_LIGHT};
        color: ${(props) =>
          props.isDarkTheme ? styledConstants.TEXT_COLOR_LIGHT : styledConstants.WHITE_COLOR_LIGHT};
      }
    `}
`;

const LinkButton = (props) => {
  const onClickHandler = (e) => {
    if (props.disabled) {
      e.preventDefault();
    }

    if (props.onClick) {
      props.onClick();
    }
  };
  return <Btn {...props} onClick={onClickHandler}></Btn>;
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme ? "lcx_dark" : "lcx_light",
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(LinkButton);
