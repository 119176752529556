import React, { Fragment } from "react";
import ModalWrapper from "../Reusable/ModalWrapper";
import { LinkButton } from "../Reusable";
import { GetKycVerifiedIllustration } from "../../assets/svgs/illustrations";
import { CloseIcon } from "../../assets/svgs";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { compose } from "redux";
import { connect } from "react-redux";

const GetKycVerified = ({ onClose, open = true, userData }) => {
  const Status = userData?.KYC?.LEVEL2?.status === "SUBMITTED";

  return (
    <Fragment>
      {open && (
        <ModalWrapper>
          <BannerBox ModalContainer BodyColor>
            <BannerBox display="flex" justifyContent="space-between">
              <BannerHeadingSpan size="20px" weight="bold" sizeMd="16px" BodyColor>
                {Status ? "KYC Under Review" : "Get KYC Verified"}
              </BannerHeadingSpan>
              <CloseIcon pointer onClick={onClose} />
            </BannerBox>

            <BannerBox display="flex" justifyContent="center" padding="32px 0px">
              <GetKycVerifiedIllustration />
            </BannerBox>

            <ParaTag size="14px" txtGrey>
              {Status
                ? "Your KYC application is under review. You'll receive an update once it's finalised."
                : `Please complete your KYC "Verification" level.`}
            </ParaTag>

            <LinkButton
              to="/setting/profile"
              height="48px"
              width="100%"
              primaryBlue
              marginTop="24px"
              onClick={onClose}
            >
              {Status ? "Check Status" : "Get Verified"}
            </LinkButton>
          </BannerBox>
        </ModalWrapper>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData
});

export default compose(connect(mapStateToProps))(GetKycVerified);
