import React, { useEffect } from "react";
import Step1a from "./KycStep1a";
import KycFAQ from "../KycFAQ";
import { formatAPIErrors } from "../../../../utils/general";
import { getCountriesList } from "../../../../actions/kyc";
import { compose } from "redux";
import { connect } from "react-redux";
import { showErrorMsg } from "../../../../utils/notification";
import { KycDivBox, KycSection } from "../../../../assets/StyleComponents/kyc.style";
import { AnchorTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { HeadingFourth } from "../../../../assets/StyleComponents/FontSize/headingFourth.style";

const KycStep1 = ({ getCountries, countriesError, ParentsetStep, userData }) => {
  const { width } = useWindowSize();
  useEffect(() => {
    let step = localStorage.getItem("kycStep");

    if (!step) {
      localStorage.setItem("kycStep", 1);
    }

    getCountries();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (countriesError) {
      showErrorMsg(formatAPIErrors(countriesError));
    }
    // eslint-disable-next-line
  }, [countriesError]);

  return (
    <KycSection StepContainer>
      <div className="stepWrapper__form">
        <KycDivBox KYCS1>
          <div className="kycS1__header">
            {width > 767 && (
              <HeadingFourth size="18px" weight="500">
                Profile
              </HeadingFourth>
            )}
            {width > 767 && width < 1024 && (
              <AnchorTag
                txtPrimary
                href="https://www.lcx.com/info/lcx-accounts/kyc-verification/"
                target="_blank"
                textDecoration="underline"
              >
                Important Points
              </AnchorTag>
            )}
          </div>
          <Step1a ParentsetStep={ParentsetStep} />
        </KycDivBox>
      </div>

      <KycFAQ step={1} />
    </KycSection>
  );
};

const mapStateToProps = (state) => ({
  countriesError: state.kyc.countriesError
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(getCountriesList())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(KycStep1);
