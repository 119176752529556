import styled, { css } from "styled-components";
import Datetime from "react-datetime";
import PhoneInput from "react-phone-input-2";

export const DatePickerStyles = styled(Datetime)`
  background: ${(props) => props.theme.inputBackground} !important;
  border-bottom: 1px solid ${(props) => props.theme.inputBorder};
  position: relative;

  &: .font-bold {
    font-weight: bold;
  }

  .inputMain {
    width: 100% !important;
    text-decoration: none;
    border: none;
    height: 44px;
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
    color: ${(props) => props.theme.bodyText};

    &:focus {
      outline: 0px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }

  .rdtPicker {
    background: ${(props) => props.theme.level1Card};
    color: ${(props) => props.theme.bodyText};
    ${(props) =>
      props.inputProps.placeholder === "From" &&
      css`
        @media (max-width: 560px) {
          left: 0;
        }
      `}
    ${(props) =>
      props.inputProps.placeholder === "To" &&
      css`
        @media (max-width: 560px) {
          right: 0;
        }
      `}
  }

    td.rdtYear:hover,
    td.rdtMonth:hover,
    .rdtPrev:hover,
    .rdtNext:hover,
    .rdtSwitch:hover,
    .rdtPicker td.rdtDay:hover {
      background: ${(props) => props.theme.primary} !important;
      color: white !important;
    }
  }
  .rdtPicker td.rdtOld {
    color: ${(props) => props.theme.bodyText};
  }
  .rdtPicker td.rdtActive {
    background: ${(props) => props.theme.primary} !important;
    color: white !important;
  }
  .rdtDisabled {
    color: ${(props) => props.theme.subText} !important;
  }

  // Light and Dark theme for react-phone-input-2 Country picker
`;

export const PhoneInputStyles = styled(PhoneInput)`
  background: ${(props) => props.theme.inputBackground} !important;
  border-bottom: 1px solid ${(props) => props.theme.inputBorder};

  .form-control {
    width: 100% !important;
    text-decoration: none;
    border: none;
    height: 44px;
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
    color: ${(props) => props.theme.bodyText};
    padding-left: 45px;

    &:focus {
      outline: 0px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }

  .react-tel-input .country-list {
    background: ${(props) => props.theme.inputBorder} !important;
    border: 1px solid ${(props) => props.theme.subText};
  }
  .react-tel-input .country-list .country:hover,
  .react-tel-input .country-list .country.highlight {
    color: #012243;
    background-color: ${(props) => props.theme.buttonActive} !important;
  }

  .disable-btn-font {
    color: #ffffff !important;
  }
  .flag-dropdown {
    background: ${(props) => props.theme.level2Card};
    color: ${(props) => props.theme.bodyText};
    border: 0px;
  }
  .country-list {
    background: ${(props) => props.theme.level1Card};
    color: ${(props) => props.theme.bodyText};
  }
`;
