import { css } from "styled-components";
import { createResponsiveStyles } from "../GlobalStyle/DynamicResponsiveDesign/DynamicResponsiveDesign.style";

const CommanWidthStyle = css`
  ${({ width, widthXl, widthLg, widthMd, widthSm }) => (width || widthXl || widthLg || widthMd || widthSm) && 
    createResponsiveStyles("width", "width", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : width 
    )
  }
  
  ${({ minWidth, minWidthXl, minWidthLg, minWidthMd, minWidthSm }) => (minWidth || minWidthXl || minWidthLg || minWidthMd || minWidthSm) && 
    createResponsiveStyles("minWidth", "min-width", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : minWidth 
    )
  }

  ${({ maxWidth, maxWidthXl, maxWidthLg, maxWidthMd, maxWidthSm }) => (maxWidth || maxWidthXl || maxWidthLg || maxWidthMd || maxWidthSm) && 
    createResponsiveStyles("maxWidth", "max-height", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : maxWidth 
    )
  }
`;

export { CommanWidthStyle };