import React from "react";
import { ElementBox, TextWrapper } from "../../../../components/Reusable";
import { InvestmentHistoryTable } from "../Components";

const InvestmentHistory = () => {
  return (
    <ElementBox
      padding="40px 40px 48px 36px"
      paddingMd="32px 30px 40px 30px"
      paddingSm="24px 16px 36px 16px"
    >
      <TextWrapper
        fontSize="24px"
        lineHeight="28.8px"
        marginBottom="32px"
        marginBottomMd="24px"
        marginBottomSm="16px"
      >
        Investment History
      </TextWrapper>

      <InvestmentHistoryTable showFilters={true} />
    </ElementBox>
  );
};

export default InvestmentHistory;
