import { createSelector } from "reselect";

export const GenericReducer = () => (state) => state.generic;

// Get Translation List ...................
export const getTranslationLanguageList = () =>
  createSelector(GenericReducer(), (state) => {
    // return state.get("translationList");
    const listing = state.get("translationList").toJS();
    return listing && listing.length > 0 ? listing : [];
  });

// Get User Selected Lang ...................
export const getUserSelectedLang = () =>
  createSelector(GenericReducer(), (state) => {
    return state.get("userSelectedLang");
  });