import styled from "styled-components";
import { TableRowComponent } from "../TableHead.style";

const ApiKeysRow = styled(TableRowComponent)`
  th,
  td {
    display: flex;
    font-size: 14px !important;
    font-weight: 500;
    // min-height: 40px !important;
    height: 100% !important;
    line-height: 18px;
    text-right: right;
    &.symb-padd {
      justify-content: center;
    }
    &.text-right {
      justify-content: flex-end;
    }

    color: ${({ theme }) => theme.bodyText};

    flex: none;
    flex-grow: 0;
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start !important;

    &:nth-child(1) {
      flex: 0 0 240px;
      padding-left: 16px !important;
    }

    &:nth-child(2),
    &:nth-child(3) {
      flex: 0 0 170px;
      padding-left: 16px !important;
    }

    &:nth-child(4) {
      flex: 0 0 184px;
      padding-left: 8px;
    }

    &:nth-child(5) {
      flex: 0 0 155px;
      padding-left: 8px !important;
    }

    &:nth-child(6) {
      flex: 1 1 110px;
      padding-left: 16px !important;
      padding-right: 16px !important;
      justify-content: flex-end !important;
    }
    &:nth-child(3) {
      padding-left: 8px !important;
    }

    @media (max-width: 1024px) {
      &:nth-child(1) {
        flex: 1 1 33%;
      }

      &:nth-child(2) {
        flex: 1 1 117px;
        justify-content: flex-end !important;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }

      &:nth-child(3) {
        flex: 0 0 25px;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
  }
  th {
    line-height: 16px;
    justify-content: center;
    text-transform: capitalize !important;
    cursor: pointer;
    border: none !important;
    font-weight: 500 !important;
    color: ${({ theme }) => theme.bodyText} !important;
    p,
    h5,
    button,
    span {
      font-weight: 500 !important;
      color: ${({ theme }) => theme.bodyText} !important;
    }
    @media (max-width: 767px) {
      font-size: 12px !important;
    }
    &:nth-child(4) {
      justify-content: center !important;
      padding-right: 56px;
    }
  }
  td {
    font-size: 16px !important;
    align-items: center !important;
    @media (max-width: 1024px) {
      font-size: 14px !important;
    }
    @media (max-width: 767px) {
      font-size: 12px !important;
    }
    font-weight: 500;
    h5,
    button {
      flex-grow: 1;
    }
  }
`;

export { ApiKeysRow };
