import apiConstants from "../constants/apiConstants";
import { failure, success } from "../utils/redux";

const initialStateSchema = {
  promotionList: [],
  promotionTextList: [],
  promotionOngoingList: [],
  promotionCompletedList: [],
  dashboardPromotionList: [],
  isPromotionFetching: false,
  isPromotionError: ""
};

const PromotionReducer = (state = initialStateSchema, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GET_PROMOTION_LIST:
      newState = {
        ...state,
        isPromotionFetching: true,
        isPromotionError: ""
      };

      return newState;

    case success(apiConstants.GET_PROMOTION_LIST):
      newState = {
        ...state,
        promotionList: action?.response || [],
        promotionTextList: action?.response?.reduce((acc, curr) => [...acc, curr.topbar_text], []) || [],
        promotionOngoingList: action?.responseWithoutFilter?.filter(d => d.enable_homepage) || [],
        promotionCompletedList: action?.responseWithoutFilter?.filter(d => !d.enable_homepage) || [],
        dashboardPromotionList: action?.dashboardResponse?.filter(d => d.isActive) || [],
        isPromotionFetching: false
      };
      return newState;

    case failure(apiConstants.GET_PROMOTION_LIST):
      newState = {
        ...state,
        isPromotionFetching: false,
        isPromotionError: action?.response?.data
      };
      return newState;

    case apiConstants.CLEAR_PROMOTION_ERRORS:
      newState = {
        ...state,
        isPromotionError: ""
      };
      return newState;

    default:
      return state;
  }
};

export default PromotionReducer;
