import { useState, useEffect } from "react";
import { config } from "../../constants";
import "./style.scss";

export const useHubspotChat = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const portalId = config.hubspotPortalId;

  useEffect(() => {
    // Create script component.
    let script = document.createElement("script");
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [portalId]);

  const openHandler = () => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    }
  };

  const closeHandler = () => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.close();
    }
  };

  const toggleChatSupport = () => {
    if (isChatOpen) {
      setIsChatOpen(false);
      closeHandler();
      document.getElementById("hubspot-messages-iframe-container").style.visibility = "hidden";
    } else {
      setIsChatOpen(true);
      openHandler();
      document.getElementById("hubspot-messages-iframe-container").style.visibility = "visible";
      document.getElementById("hubspot-messages-iframe-container").style.marginBottom = "20px";
    }
  };

  return {
    toggleChatSupport
  };
};
