import { Fragment } from "react";
import { TextWrapper, DivElement, Button } from "../../../../../components/Reusable";
import { ErrorIcon, SuccessIcon } from "../../../../../assets/svgs";

const Message = ({ type, message }) => {
  return (
    <DivElement dAlignCenter marginBottom="7px">
      {type === "success" ? (
        <SuccessIcon width="16px" height="16px" marginRight="8px" />
      ) : (
        <ErrorIcon width="16px" height="16px" marginRight="8px" />
      )}

      {message}
    </DivElement>
  );
};

const ValidationMessage = ({
  amount,
  bondDetails,
  isEnoughFee,
  isEnoughBalance,
  isKycApproved,
  isCountryAccess,
  onClickAddFunds
}) => {
  return (
    <Fragment>
      <DivElement
        paddingTop="6px"
        paddingTopSm="8px"
        paddingBottom="16px"
        paddingBottomSm="20px"
        minHeight="85px"
        minHeightMd="70px"
      >
        {!isCountryAccess || !isKycApproved ? (
          <Message
            type="error"
            message={
              <TextWrapper
                fontSize="12px"
                fontWeight="400"
                lineHeight="18px"
                width="calc(100% - 24px)"
                red={true}
              >
                {!isCountryAccess && isCountryAccess !== null
                  ? "Sorry, investment opportunity is not for your region"
                  : "Sorry, you need to be LEVEL2 verified to participate in this investment opportunity"}
              </TextWrapper>
            }
          />
        ) : (
          amount && (
            <Fragment>
              <Message
                type={isEnoughBalance ? "success" : "error"}
                message={
                  isEnoughBalance ? (
                    <TextWrapper green fontSize="12px" fontWeight="400" lineHeight="18px">
                      Enough {bondDetails.bondCoin} in main balance
                    </TextWrapper>
                  ) : (
                    <DivElement dAlignCenter>
                      <TextWrapper red fontSize="12px" fontWeight="400" lineHeight="18px">
                        Insufficient {bondDetails.bondCoin} in main balance
                      </TextWrapper>
                      <Button
                        blueTextBtn
                        fontSize="12px"
                        lineHeight="18px"
                        height="18px"
                        marginLeft="8px"
                        onClick={() => onClickAddFunds("BALANCE")}
                      >
                        Add {bondDetails.bondCoin}
                      </Button>
                    </DivElement>
                  )
                }
              />
              {bondDetails.bondCoin !== bondDetails.feeCoin && (
                <Message
                  type={isEnoughFee ? "success" : "error"}
                  message={
                    isEnoughFee ? (
                      <TextWrapper green fontSize="12px" fontWeight="400" lineHeight="18px">
                        Enough {bondDetails.feeCoin} in main balance
                      </TextWrapper>
                    ) : (
                      <DivElement dAlignCenter>
                        <TextWrapper red fontSize="12px" fontWeight="400" lineHeight="18px">
                          Insufficient {bondDetails.feeCoin} in main balance
                        </TextWrapper>
                        <Button
                          blueTextBtn
                          fontSize="12px"
                          lineHeight="18px"
                          height="18px"
                          marginLeft="8px"
                          onClick={() => onClickAddFunds("FEE")}
                        >
                          Add {bondDetails.feeCoin}
                        </Button>
                      </DivElement>
                    )
                  }
                />
              )}
            </Fragment>
          )
        )}
      </DivElement>
    </Fragment>
  );
};

export default ValidationMessage;
