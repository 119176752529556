/* eslint-disable no-console */

import { failure, success } from "../../utils/redux";
import { SET_AVAILABLE_LANGUAGES, UPDATE_USER_LANGUAGES } from "./const";


export const fetchAvailableLanguagesRequest = () => ({
  type: SET_AVAILABLE_LANGUAGES
});

export const updateCurrentLanguage = (data) => ({
  type: UPDATE_USER_LANGUAGES,
  payload: data
});

export const fetchAvailableLanguagesSuccess = (data) => {
  return ({
    type: success(SET_AVAILABLE_LANGUAGES),
    payload: data
  });
};

export const fetchAvailableLanguagesFailure = (error) => ({
  type: failure(SET_AVAILABLE_LANGUAGES),
  payload: error
});

// export const fetchAvailableLanguages = () => {
//   return new Promise((resolve, reject) => {
//     window.Localize.getAvailableLanguages((err, data) => {
//       if (err) {
//         console.log(err);
//         reject({
//           type: failure(SET_AVAILABLE_LANGUAGES),
//           payload: err
//         });
//         // You might want to dispatch an error action here
//       } else {
//         console.log("Action Calling", data);
//         resolve({
//           type: success(SET_AVAILABLE_LANGUAGES),
//           payload: data
//         });
//       }
//     });
//   });
// };

// export const fetchAvailableLanguages = () => {
//   return {
//     type: SET_AVAILABLE_LANGUAGES
//   };
//   // window.Localize.getAvailableLanguages((err, data) => {
//   //   if (err) {
//   //     console.log(err);
//   //     return {
//   //       type: failure(SET_AVAILABLE_LANGUAGES),
//   //       payload: data
//   //     };
//   //     // You might want to dispatch an error action here
//   //   } else {
//   //     console.log("Action Calling", data);
//   //     return {
//   //       type: SET_AVAILABLE_LANGUAGES,
//   //       payload: data
//   //     };
//   //   }
//   // });
// };