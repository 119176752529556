import React from "react";
import { useHistory, Link } from "react-router-dom";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
// import { IMAGE_PREFIX } from "../../constants/Images/imageIndex";
import { compose } from "redux";
import { connect } from "react-redux";
import { CloseIcon } from "../../assets/svgs";
import { DivElement, ModalWrapper, TextWrapper } from "../../components/Reusable";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { WalletsModalIconsWallet } from "../../assets/svgs/illustrations";

const InitialDepositModal = ({ showDepositModal, userData, onClose }) => {
  let history = useHistory();
  const prevLang = localStorage.getItem("ljs-lang");

  const handleRedirect = () => {
    if (userData?.KYC?.STATUS === "LEVEL2") localStorage.setItem("deposit", false);
    history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/wallet/deposits`);
  };

  let getStatus = userData?.KYC?.STATUS === "LEVEL2" ? true : false;

  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <DivElement displayBtw>
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
            Welcome to LCX Exchange!{" "}
          </TextWrapper>
          <CloseIcon pointer onClick={onClose} />
        </DivElement>

        <BannerBox width="100%">
          <>
            <BannerBox display="flex" justifyContent="center" margin="20px 0px">
              <WalletsModalIconsWallet width="200px" height="200px" />
            </BannerBox>

            <BannerHeading size="14px" sizeMd="12px" mb="24px" weight="500" textAlign="center">
              {getStatus && "Explore a variety of Digital Assets"}
              {!getStatus && "Start your crypto journey with LCX Exchange. "}
              {getStatus && "Deposit your preferred currency to start trading at the LCX exchange"}
              {!getStatus && "Complete your KYC verification process."}
            </BannerHeading>

            <div>
              {getStatus && (
                <ButtonComponent
                  PrimaryButton
                  width={"100%"}
                  widthMd={"100%"}
                  onClick={() => handleRedirect()}
                >
                  Deposit
                </ButtonComponent>
              )}
              {!getStatus && (
                <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/profile"`}>
                  <ButtonComponent PrimaryButton width={"100%"} widthMd={"100%"}>
                    Verify
                  </ButtonComponent>
                </Link>
              )}
            </div>
          </>
        </BannerBox>
      </DivElement>
    </ModalWrapper>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData
});

export default compose(connect(mapStateToProps))(InitialDepositModal);
