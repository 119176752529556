import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import AllStyle from "../../../assets/scss/pages/all.module.scss";
import { getLocalStorage } from "../../../utils/helpers";

const SettingNavbar = () => {
  const userData = useSelector(state => state.dashboard.userData);
  let memoHasMultilingualSupport = useMemo(() => userData.hasMultilingualSupport || false, [userData.hasMultilingualSupport]);
  let prevLang = getLocalStorage("ljs-lang");
  const language = prevLang ? prevLang : userData?.preferredLanguage;

  return (
    <BannerBox
      bottomBorder
      CardWhite
      height={"53px"}
      radius={"10px 10px 0 0 "}
      className={`${AllStyle.sub_navbar_link}`}
      display="flex"
      alignItems="center"
    >
      <NavLink
        to={`${language ? `/${language}` : ""}/setting/profile`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Profile
      </NavLink>

      <NavLink
        to={`${language ? `/${language}` : ""}/setting/security`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Security
      </NavLink>

      {/* <NavLink
        to="/setting/account-verification"
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Account Verification
      </NavLink> */}

      <NavLink
        to={`${language ? `/${language}` : ""}/setting/api_management`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        API Keys
      </NavLink>

      <NavLink
        to={`${language ? `/${language}` : ""}/setting/fees`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Fees
      </NavLink>

      <NavLink
        to={`${language ? `/${language}` : ""}/setting/notification`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Notification
      </NavLink>
      <NavLink
        to={`${language ? `/${language}` : ""}/setting/reports`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Reports
      </NavLink>

      <NavLink
        to={`${language ? `/${language}` : ""}/setting/recent_actvity`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Activity
      </NavLink>
      {memoHasMultilingualSupport && 
        <NavLink
          to={`${language ? `/${language}` : ""}/setting/language-preference`}
          className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
        >
          Language Preference
        </NavLink>
      }
    </BannerBox>
  );
};

export default SettingNavbar;
