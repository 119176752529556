import Countdown from "react-countdown";
import { TimerGreenIcon, TimerOrangeIcon } from "../../../../assets/svgs";
import "./style.css";
import { DivElement } from "../../../../components/Reusable";
import { BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";

const SaleCountdown = ({ saleDetails, isDashboard = false }) => {
  const renderer = ({ hours, minutes, days, seconds, completed }) => {
    let string = "";
    if (days) string += days + "d ";
    if (days || hours) string += hours + "h ";
    if (days || hours || minutes) string += minutes + "m ";
    if (days || hours || minutes || seconds) string += seconds + "s";
    return (
      <div
        className={`sale-countdown ${saleDetails.status === "ONGOING" ? "ongoing" : "upcoming"}`}
      >
        <DivElement display="flex" style={{ padding: "2px 16px" }} alignItems="center">
          {saleDetails.status === "ONGOING" ? (
            <TimerGreenIcon marginRight="4px" />
          ) : (
            <TimerOrangeIcon marginRight="4px" />
          )}

          <div>
            {completed ? (
              <span>{saleDetails.status === "UPCOMING" ? "Started" : "Ended"}</span>
            ) : (
              <BannerHeadingSpan size="14px">
                {isDashboard ? "" : saleDetails.status === "UPCOMING" ? " Starts in" : " Ends in "}{" "}
                {string}
              </BannerHeadingSpan>
            )}
          </div>
        </DivElement>
      </div>
    );
  };

  return (
    <Countdown
      date={
        (saleDetails.startAt * 1000 < Date.now() ? saleDetails.endAt : saleDetails.startAt) * 1000
      }
      autoStart={true}
      renderer={renderer}
    />
  );
};

export default SaleCountdown;
