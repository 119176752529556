import React from "react";
import { DivElement, ImgElement, ModalWrapper } from "../../../components/Reusable";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { UKModalPost1, UKModalPost2 } from "../../../assets/png";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { HeadingThird } from "../../../assets/StyleComponents/FontSize/headingThird.style";
import { useState } from "react";
import { CloseIcon } from "../../../assets/svgs";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import DownBarWrapper from "../../../components/Reusable/ModalWrapper/BottomBar";
import { debounce } from "../../../utils/helpers";
import { userWaitListApi } from "../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { formatAPIErrors } from "../../../utils/general";

function OnholdModal({ onClose, country, givenEmail = "" }) {
  const [accepted, setAccepted] = useState(false);
  const { width } = useWindowSize();

  const handleUserWaitList = debounce(() => {
    let param = {
      country: country
    };
    if (givenEmail) param.email = givenEmail;
    userWaitListApi(param, true)
      .then((res) => {
        setAccepted(true);
        showSuccessMsg(res.data.message);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  }, 700);

  return (
    <>
      {width > 767 && (
        <>
          <ModalWrapper>
            <DivElement paddingSm="19px 16px 17px" ModalContainer>
              <DivElement displayBtw>
                <HeadingThird size="20px" fontSizeSm="16px" lineHeight="24px">
                  {country || "UK"} Registrations on Hold
                </HeadingThird>
                <CloseIcon pointer onClick={onClose} />
              </DivElement>
              {!accepted ? (
                <>
                  <BannerBox display="flex" justifyContent="center">
                    <ImgElement src={UKModalPost1} width="285px" height="200px" mt="16px" />
                  </BannerBox>
                  <ParaTag size="14px" weight="400" textAlign="center" mt="16px" mb="24px">
                    Thank you for signing up. Currently, we are not onboarding users from the{" "}
                    {country || "UK"}, but we will inform you as soon as we open registrations for{" "}
                    {country || "UK"} users.
                  </ParaTag>
                  <ButtonComponent width="100%" height="48px" onClick={() => setAccepted(true)}>
                    Put me on the wait list and notify
                  </ButtonComponent>
                </>
              ) : (
                <>
                  <BannerBox display="flex" justifyContent="center">
                    <ImgElement src={UKModalPost2} width="229px" height="200px" mt="16px" />
                  </BannerBox>
                  <ParaTag size="14px" weight="400" textAlign="center" mt="16px" mb="24px">
                    Your details are successfully added to the waiting list. We will inform you as
                    soon as we open registrations for {country || "UK"} users.
                  </ParaTag>
                </>
              )}
            </DivElement>
          </ModalWrapper>
        </>
      )}
      {width < 767 && (
        <>
          <DownBarWrapper>
            <DivElement paddingSm="19px 16px 17px" DownBarContainer>
              <DivElement displayBtw>
                <HeadingThird size="16px" fontSizeSm="16px" lineHeight="24px">
                  {country || "UK"} Registrations on Hold
                </HeadingThird>
                <CloseIcon pointer onClick={onClose} />
              </DivElement>
              {!accepted ? (
                <>
                  <BannerBox display="flex" justifyContent="center">
                    <ImgElement src={UKModalPost1} width="172px" height="120px" mt="16px" />
                  </BannerBox>
                  <ParaTag
                    size="14px"
                    weight="400"
                    textAlign="center"
                    mt="16px"
                    mb="24px"
                    sizeMd="12px"
                  >
                    We're excited you're interested! While we can't welcome users from the{" "}
                    {country || "UK"} just yet, we'll be sure to let you know as soon as
                    registrations open. In the meantime, feel free to explore our website and learn
                    more about what we offer.
                  </ParaTag>
                  <ButtonComponent width="100%" height="48px" onClick={handleUserWaitList}>
                    Put me on the wait list and notify
                  </ButtonComponent>
                </>
              ) : (
                <>
                  <BannerBox display="flex" justifyContent="center">
                    <ImgElement src={UKModalPost2} width="137px" height="120px" mt="16px" />
                  </BannerBox>
                  <ParaTag size="12px" weight="400" textAlign="center" mt="16px" mb="24px">
                    Thanks for your interest in joining LCX! You've secured your spot on our
                    exclusive waiting list for {country || "UK"} users. We'll notify you directly
                    when registrations open up.
                  </ParaTag>
                </>
              )}
            </DivElement>
          </DownBarWrapper>
        </>
      )}
    </>
  );
}

export default OnholdModal;
