import React, { useState, useCallback, memo, useRef } from "react";
import styled, { css } from "styled-components";
import Carousel from 'react-multi-carousel';

import "react-multi-carousel/lib/styles.css";
import { FlexCard } from "../../../assets/StyleComponents/FlexDiv.style";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../assets/svgs";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight/index";
import { BannerSection } from "../../../assets/StyleComponents/Exchange.style";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 568 },
    items: 2,
    slidesToSlide: 2 // optional, default to 2.
  },
  smallMobile: {
    breakpoint: { max: 568, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const CarouselWrapper = styled(FlexCard)`
  position: relative;
  width: 100%;
`;

const DotButton = styled(FlexCard)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &.no-active{
    border: ${({ theme }) => `1px solid ${theme.inputBoxText}`};
    background: none;
  }
  &.active{
    border: none;
    background: ${({ theme }) => theme.primary};
  }
`;

const Card = styled(FlexCard)`
  max-width: 290px;
  border-radius: 12px;
  color: white;
  flex-shrink: 0;
  aspect-ratio: 16/9;

  @media (max-width: 480px) {
    width: 140px;
  }

  img {
    height: 132px;
    aspect-ratio: 16/9;
    border-radius: 5px;
    border-radius: 12px;
    object-fit: contain;

    @media (max-width: 1200px) {
      height: 115px;
    }

    @media (max-width: 767px) {
      height: 100px;
    }

    @media (max-width: 568px) {
      height: 150px;
    }
  }
`;

const CustomDot = ({ onClick, active, index, carouselState }) => {
  const { totalItems } = carouselState;
  
  // Custom styles for active and inactive states
  return (
    <DotButton 
      className={`
        ${active ? 
          'active' : // Active dot is wider and blue
          'no-active' // Inactive dot is gray
        }
      `}
      aria-label={`Go to slide ${index + 1} of ${totalItems}`} 
      aria-current={active ? 'true' : 'false'} 
      key={index} 
      mx={"4px"} mt="8px" mtSm="30px" 
      onClick={() => onClick()}
    />
  );
};

const ArrowButton = styled(ButtonComponent)`
  position: absolute;
  top: calc(124px - 70px);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${({ theme }) => theme.tableHeader};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  color: white;
  flex-shrink: 0;
  z-index: 10;
  display:flex;
  align-items: center;

  @media (max-width: 1200px) {
    top: calc(124px - 70px);
  }

  @media (max-width: 767px) {
    top: calc(100px - 40px);
  }

  @media (max-width: 480px) {
    min-width: 40px;
    min-height: 30px;
    top: calc(80px - 30px);
  }

  ${({ ButtonDirection }) =>
    ButtonDirection &&
    css`
      ${ButtonDirection === "prev" && `left: 0px;`}
      ${ButtonDirection === "next" && `right: 0px;`}
    `}
`;

const NavigationButton = memo(function NavigationButton({ direction, onClick }) {
  return (
    <ArrowButton
      onClick={() => onClick()}
      ButtonDirection={direction}
      className={`absolute top-1/2 -translate-y-1/2 ${direction === "prev" ? "left-2" : "right-2"}
        flex items-center justify-center text-white transition-colors`}
      aria-label={`${direction === "prev" ? "Previous" : "Next"} slide`}
    >
      {direction === "prev" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </ArrowButton>
  );
});

function ImageCarousel({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { width } = useWindowSize();
  const carouselRef = useRef();

  const goToPrevious = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.previous(); // Move to the previous slide
    }
  }, []);

  const goToNext = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.next(); // Move to the next slide
    }
  }, []);

  return (
    <CarouselWrapper width="100%" height="200px">
      <BannerSection MainPageSectionBg maxWidth="1124px" width="100%" margin="auto" px="30px" ptMd="30px">
        <Carousel
          swipeable={true}
          ref={carouselRef}
          draggable={false}
          showDots={width < 768 ? true : false}
          responsive={responsive}
          additionalTransfrom={0}
          infinite={false}
          autoPlay={true}
          autoPlaySpeed={15000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          renderDotsOutside={true}
          renderButtonGroupOutside={true}
          minimumTouchDrag={80}
          rtl={false}
          arrows={(width > 767 && images && images.length > 4)? true : false}
          // arrows={true}
          customRightArrow={<NavigationButton direction="next" onClick={goToNext} />}
          customLeftArrow={
            <NavigationButton direction="prev" onClick={goToPrevious} />
          }
          customDot={<CustomDot />}
          rewind
          rewindWithAnimation={false}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="d-block relative my-2 dark"
          beforeChange={(previousSlide, nextSlide) => {
            setCurrentIndex(nextSlide?.currentSlide);
          }}
        >
          {images.map((card, index) => (
            <Card
              as="a"
              key={`${index}-${card.topbar_link}`}
              target="_blank"
              href={card.topbar_link}
            >
              <img
                src={card.popup_image_web}
                alt="LCX"
                loading={Math.abs(index - currentIndex) < responsive.desktop.items ? "eager" : "lazy"}
              />
            </Card>
          ))}
        </Carousel>
      </BannerSection>
    </CarouselWrapper>
  );
}

export default memo(ImageCarousel);