import styled from "styled-components";
import { TableRowComponent } from "../TableHead.style";

const OpenOrdersRow = styled(TableRowComponent)`
  th,
  td {
    display: flex;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 18px;
    text-right: right;
    &.symb-padd {
      justify-content: center;
    }
    &.text-right {
      justify-content: flex-end;
    }

    color: ${({ theme }) => theme.bodyText};
    flex: none;
    flex-grow: 0;
    align-items: center;
    box-sizing: border-box !important;

    &:nth-child(1),
    &:nth-child(6) {
      flex: 1 1 163px;
      justify-content: flex-start;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    &:nth-child(2) {
      flex: 0 0 134px;
      justify-content: flex-start;
      padding-left: 8px !important;
      padding-right: 16px !important;
    }

    &:nth-child(3) {
      flex: 0 0 86px;
      justify-content: flex-start;
      padding-left: 8px !important;
      padding-right: 16px !important;
    }

    &:nth-child(4) {
      flex: 0 0 132px;
      justify-content: flex-end;
      padding-right: 16px !important;
      padding-left: 0px !important;
    }

    &:nth-child(5) {
      flex: 0 0 58px;
      justify-content: flex-start;
      padding-left: 8px !important;
      padding-right: 16px !important;
    }

    &:nth-child(6) {
      justify-content: flex-end;
    }

    &:nth-child(7) {
      flex: 0 0 77px;
      justify-content: flex-start;
      padding-right: 0px !important;
      padding-left: 8px !important;
    }

    &:nth-child(8) {
      flex: 0 0 85px;
      justify-content: flex-start;
      padding-right: 16px !important;
      padding-left: 8px !important;
    }

    &:nth-child(9) {
      flex: 0 0 79px;
      justify-content: flex-start;
      padding-right: 16px !important;
      padding-left: 8px !important;
    }

    @media (max-width: 1024px) {
      &:nth-child(1) {
        flex: 0 0 130px;
      }
      &:nth-child(2),
      &:nth-child(4) {
        flex: 1 1 150px;
      }
      &:nth-child(5) {
        flex: 0 0 25px;
        justify-content: center;
        padding-right: 8px !important;
        padding-left: 0px !important;
      }
    }
    @media (max-width: 767px) {
      &:nth-child(1) {
        flex: 0 0 93px;
      }
      &:nth-child(2) {
        flex: 0 0 60px;
        justify-content: flex-start !important;
      }
      &:nth-child(3) {
        flex: 1 1 100px;
        justify-content: flex-end !important;
        padding-left: 0px !important;
        padding-right: 8px !important;
      }
      &:nth-child(4) {
        flex: 0 0 25px;
        justify-content: center;
        padding-right: 0px !important;
        padding-left: 0px !important;
      }
      font-size: 12px !important;
    }
  }
  th {
    line-height: 16px;
    justify-content: center;
    text-transform: capitalize !important;
    cursor: pointer;
    border: none !important;
    font-weight: 500 !important;
    color: ${({ theme }) => theme.bodyText} !important;
    p,
    h5,
    button,
    span {
      font-weight: 500 !important;
      color: ${({ theme }) => theme.bodyText} !important;
    }
  }
  td {
    font-size: 16px !important;
    @media (max-width: 1024px) {
      font-size: 14px !important;
    }
    @media (max-width: 767px) {
      font-size: 12px !important;
      &:nth-child(3) {
        flex: 1 1 100px;
        justify-content: flex-end !important;
        padding-left: 0px !important;
        padding-right: 16px !important;
      }
    }
    font-weight: 500;
    h5,
    button {
      flex-grow: 1;
    }
  }
`;

export { OpenOrdersRow };
