/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { CircleLoader, LoadingButton } from "../../../../../components/Loader";
import { compose } from "redux";
import { connect } from "react-redux";
import { numberWithCommas } from "../../../../../utils/general";
import { setDecimal, setPrecision, sleep } from "../../../../../utils";
import {
  DivElement,
  TextWrapper,
  Checkbox,
  Button,
  LinkButton,
  ImgElement
} from "../../../../../components/Reusable";
import { RestrictedUser } from "../../../../../components/Alert";
import InsufficientBalance from "../../../../../components/InsufficientBalance";
import { TimerGreyIcon, RedExclamationIcon } from "../../../../../assets/svgs";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../../../constants/styledConstants";
import Countdown from "react-countdown";
import * as Yup from "yup";
import NewAmountInput from "../../../../../views/NewTokenSale/Components/NewAmountInput";
import { BannerBox, BannerHeadingSpan } from "../../../../../assets/StyleComponents/Exchange.style";
import { TSInvest } from "../../../../../assets/png";
import { new_BN } from "../../../../../utils/bigNumber";

const Step2Timer = () => {
  const { width } = useWindowSize();
  // const isMobile = width <= styledConstants.BREAKING_POINT_MOBILE;

  const renderer = ({ seconds }) => {
    let string = seconds + "s";
    return (
      <DivElement flexAlignCenter>
        <TimerGreyIcon marginRight="8px" />
        <TextWrapper>{string}</TextWrapper>
      </DivElement>
    );
  };

  return <Countdown autoStart renderer={renderer} date={Date.now() + 30000} />;
};

const validationSchema = (min, max) =>
  Yup.object().shape({
    lcxAmount: Yup.number()
      .nullable(true)
      .min(min, `Min Amount is ${numberWithCommas(min)}`)
      .max(max, `Max Amount is ${numberWithCommas(max)}`)
      .required("Amount to purchase"),
    lcxPayment: Yup.number()
      .nullable(true)
      .positive("Only positive number allowed")
      .min(0, "Min value is 0")
      .required("Please enter the valid amount "),
    isChecked: Yup.bool().oneOf([true], "* Accept Terms & Conditions is required")
  });

const Step2 = ({
  saleDetails,
  onBuyHandler,
  paymentCoinBalance,
  selectedPaymentCoin,
  onCoinChangeHandler,
  selectedCoinRate,
  feeCoinBalance,
  purchaseFee,
  purchaseAmt,
  paymentAmt,
  userData,
  isLoggedIn,
  onAmountChangeHandler
}) => {
  const [showRestrictedModal, setShowRestrictedModal] = useState(false);
  const [showAddFunds, setShowAddFunds] = useState(false);
  const [coinWithLowBalance, setCoinWithLowBalance] = useState("");
  const [requiredFunds, setRequiredFunds] = useState("");
  const min = saleDetails?.round ? saleDetails.round.minAmount : 0;
  const max = saleDetails?.round ? saleDetails.round.maxAmount : 0;
  const isFeeEnough = feeCoinBalance >= purchaseFee;
  const isPurchaseBalanceEnough = paymentCoinBalance >= paymentAmt;
  const isRestricted = userData?.status === "RESTRICTED";
  const prevLang = localStorage.getItem("ljs-lang");

  const getInitialForm = () => {
    return {
      lcxAmount: purchaseAmt,
      lcxPayment: paymentAmt,
      isChecked: false
    };
  };

  useEffect(() => {
    if (isRestricted) {
      setShowRestrictedModal(true);
    } else {
      setShowRestrictedModal(false);
    }
  }, [isRestricted]);

  const onClickAddFunds = (type) => {
    if (type === "FEE_COIN") {
      setCoinWithLowBalance(saleDetails?.round?.feeCoin || "LCX");
      setRequiredFunds(purchaseFee);
    } else {
      setCoinWithLowBalance(selectedCoinRate?.coin);
      setRequiredFunds(paymentAmt);
    }
    setShowAddFunds(true);
  };

  const validateAmount = (value) => {
    return sleep(0).then(() => {
      if (value < 0) {
        return "Only positive number allowed";
      }
      if (value < min) {
        return `Min Amount is ${numberWithCommas(min)}`;
      }
      if (value > max) {
        return `Max Amount is ${numberWithCommas(max)}`;
      }
      if (value === null) {
        return "Please enter the value";
      }
    });
  };

  const errorHandling = async (value, data) => {
    if (value) {
      let error = await validateAmount(value, min, max);
      if (error && data.errors.lcxAmount !== error) {
        if (!data.touched.lcxAmount) {
          data.setTouched({ ...data.touched, lcxAmount: true });
        }
        data.setFieldError("lcxAmount", error);
      } else if (data.errors.lcxAmount && !error) {
        data.setFieldError("lcxAmount", undefined);
      }
    }
  };

  return (
    <Fragment>
      <DivElement flexJustifyBtwAlignCenter>
        <TextWrapper fontSize="16px" fontWeight="500">
          Invest Now
        </TextWrapper>
      </DivElement>
      <DivElement marginTop="20px">
        <Formik
          initialValues={getInitialForm}
          validationSchema={validationSchema(min, max)}
          onSubmit={onBuyHandler}
        >
          {(data) => {
            const { values, setFieldValue, errors } = data;
            errorHandling(values["lcxAmount"], data);
            return (
              <Form>
                <NewAmountInput
                  name="lcxAmount"
                  title="Amount to purchase"
                  onAmountChangeHandler={onAmountChangeHandler}
                  setFieldValue={setFieldValue}
                  saleDetails={saleDetails}
                  balance={saleDetails?.coinBalance}
                  errors={errors}
                  purchaseAmt={purchaseAmt}
                />
                {!saleDetails?.tokenSoldOut && (
                  <DivElement
                    position="relative"
                    display="flex"
                    flexDirection="column"
                    gap="12px"
                    marginBottom="16px"
                  >
                    <ImgElement src={TSInvest} position="absolute" left="0px" />
                    <BannerBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      ml="22px"
                    >
                      {saleDetails.status === "ONGOING" && !saleDetails.tokenSoldOut && (
                        <DivElement>
                          {/* <CircleLoader /> */}
                          <TextWrapper marginLeft="6px" secondary>
                            <var>
                              {`1 ${saleDetails.coin} ≈ ${
                                selectedCoinRate
                                  ? selectedCoinRate.rate < 1
                                    ? setDecimal(selectedCoinRate.rate, 8)
                                    : +selectedCoinRate.rate
                                  : ""
                              } ${selectedCoinRate.coin || ""}`}
                            </var>
                          </TextWrapper>
                        </DivElement>
                      )}
                      <BannerBox leftBorder paddingLeft="5px">
                        <Step2Timer />
                      </BannerBox>
                    </BannerBox>
                    <TextWrapper fontSize="14px" fontWeight="400" marginLeft="28px">
                      <var>
                        Fee ≈{" "}
                        <BannerHeadingSpan weight="500" size="14px">
                          {new_BN(purchaseFee, 8).length > 15
                            ? numberWithCommas(new_BN(purchaseFee, 8).slice(0, 13)) + "..."
                            : numberWithCommas(new_BN(purchaseFee, 8).slice(0, 13))}{" "}
                          {selectedCoinRate.feeCoin || "LCX"}
                        </BannerHeadingSpan>
                      </var>
                    </TextWrapper>
                  </DivElement>
                )}

                <NewAmountInput
                  name="lcxPayment"
                  title="Currency for payment"
                  onAmountChangeHandler={onAmountChangeHandler}
                  setFieldValue={setFieldValue}
                  saleDetails={saleDetails}
                  balance={paymentCoinBalance}
                  onCoinChangeHandler={onCoinChangeHandler}
                  selectedPaymentCoin={selectedPaymentCoin}
                  showRedBorder={!isPurchaseBalanceEnough && saleDetails.status === "ONGOING"}
                  onClickAddFunds={onClickAddFunds}
                  isPurchaseBalanceEnough={isPurchaseBalanceEnough}
                  errors={errors}
                  containerProps={{ paddingBottom: "0px" }}
                />

                <DivElement minHeight="20px">
                  {purchaseAmt && saleDetails.status === "ONGOING" && !saleDetails.tokenSoldOut && (
                    <>
                      <DivElement>
                        {isLoggedIn && !isPurchaseBalanceEnough && (
                          <DivElement displayBtw marginTop="9px">
                            <TextWrapper
                              fontSize="12px"
                              lineHeight="normal"
                              fontWeight="400"
                              display="flex"
                              red={isFeeEnough ? null : true}
                            >
                              <RedExclamationIcon marginRight="5px" width="16px" height="16px" />
                              Insufficient {selectedPaymentCoin} in main balance
                            </TextWrapper>
                            <DivElement dAlignCenter>
                              {!isPurchaseBalanceEnough && (
                                <DivElement paddingRight="9px">
                                  <Button
                                    type="button"
                                    onClick={() => onClickAddFunds("other")}
                                    blueTextBtn
                                    height="initial"
                                    fontSize="12px"
                                  >
                                    Add {selectedPaymentCoin}
                                  </Button>
                                </DivElement>
                              )}
                            </DivElement>
                          </DivElement>
                        )}
                      </DivElement>
                      <DivElement>
                        {isLoggedIn && !isFeeEnough && (
                          <DivElement
                            displayBtw
                            marginTop="9px"
                            display="flex"
                            flexWrap="wrap"
                            gap="5px"
                          >
                            {/* <TextWrapper
                              fontSize="12px"
                              lineHeight="normal"
                              fontWeight="400"
                              display="flex"
                              red={isFeeEnough ? null : true}
                            >
                              <RedExclamationIcon marginRight="5px" width="16px" height="16px" />
                              Your Main balance = {setDecimal(feeCoinBalance, 3)}{" "}
                              {Number(selectedCoinRate.feeCoin) || "LCX"} is low
                            </TextWrapper> */}
                            <TextWrapper
                              fontSize="12px"
                              lineHeight="normal"
                              fontWeight="400"
                              red={isFeeEnough ? null : true}
                            >
                              <RedExclamationIcon marginRight="5px" width="16px" height="16px" />
                              {/* Your Main balance {Number(selectedCoinRate.feeCoin) || "LCX"} is low */}
                              Insufficient {Number(selectedCoinRate.feeCoin) || "LCX"} in main
                              balance
                            </TextWrapper>

                            <DivElement dAlignCenter>
                              {!isFeeEnough && (
                                <DivElement paddingRight="9px">
                                  <Button
                                    type="button"
                                    onClick={() => onClickAddFunds("FEE_COIN")}
                                    blueTextBtn
                                    height="initial"
                                    fontSize="12px"
                                  >
                                    Add {selectedCoinRate.feeCoin || "LCX"}
                                  </Button>
                                </DivElement>
                              )}
                            </DivElement>
                          </DivElement>
                        )}
                      </DivElement>
                    </>
                  )}
                </DivElement>

                {isLoggedIn ? (
                  <Fragment>
                    <Button
                      type="submit"
                      width="100%"
                      height="48px"
                      marginTop="16px"
                      primaryGreen
                      primaryRed={saleDetails.tokenSoldOut ? true : null}
                      primaryGrey={
                        saleDetails.status === "UPCOMING" ||
                        !isPurchaseBalanceEnough ||
                        !isFeeEnough ||
                        isRestricted
                          ? true
                          : null
                      }
                      disabled={
                        saleDetails.status !== "ONGOING" ||
                        saleDetails.tokenSoldOut ||
                        !isPurchaseBalanceEnough ||
                        !isFeeEnough ||
                        !values.lcxPayment ||
                        isRestricted
                      }
                    >
                      {saleDetails.tokenSoldOut
                        ? "SOLD OUT"
                        : saleDetails.status === "UPCOMING"
                        ? "Sale has not started yet"
                        : !isPurchaseBalanceEnough || !isFeeEnough
                        ? "Insufficient Balance"
                        : saleDetails.status !== "ONGOING"
                        ? "Sale Ended"
                        : `Buy Now`}
                    </Button>
                  </Fragment>
                ) : (
                  <LinkButton
                    to={`${prevLang ? `/${prevLang}` : ""}/login`}
                    width="100%"
                    height="48px"
                    secondaryBlue
                  >
                    Log in or Register
                  </LinkButton>
                )}
              </Form>
            );
          }}
        </Formik>
      </DivElement>

      {showRestrictedModal && (
        <RestrictedUser open={showRestrictedModal} onClose={() => setShowRestrictedModal(false)} />
      )}

      {showAddFunds && (
        <InsufficientBalance
          onClose={() => setShowAddFunds(false)}
          coin={coinWithLowBalance}
          requiredFunds={requiredFunds}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData,
  isLoggedIn: state.auth.isLoggedIn
});

export default compose(connect(mapStateToProps))(Step2);
