/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  USE_OF_LCX,
  GENDER,
  ID_DOCUMENTS,
  ANNUAL_NET_INCOME,
  HEAR_ABOUT_US
} from "../../../constants/kycOptions";
import OCCUPATIONS from "../../../constants/occupation";
import { connect } from "react-redux";
import { compose } from "redux";
import * as Yup from "yup";
import { isUserLoggedIn } from "../../../actions/auth";
import { formatAPIErrors } from "../../../utils/general";
import { completeProfileStatusData, getSourceList } from "../../../lib/api";
import { LoadingButton } from "../../Loader";
import {
  DateTimeInput,
  DropdownInput,
  TextInput,
  FormPhoneInput,
  CountriesDropdown,
  DropdownWithSpecifyOtherInput
} from "../../Reusable/FormInput";
import { Button, DivElement } from "../../Reusable";
import { getCountriesList } from "../../../actions/kyc";
import { formatCountriesArray, formatDropdownOptions, getGenderLabel } from "../../../utils";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { MissingProfileDiv } from "../../../assets/StyleComponents/Missing.style";

const getValidationSchema = (fieldsObj) => {
  const schema = {};
  if (fieldsObj.firstName)
    schema.firstName = Yup.string()
      .trim()
      .min(1)
      .max(32, "First Name must be at most 16 characters")
      // eslint-disable-next-line
      .matches(/^(?![0-9]*$)([ \u00C0-\u01FFa-zA-Z0-9'\-])+$/, "Please enter a valid first name")
      .required("First Name is required");
  if (fieldsObj.lastName)
    schema.lastName = Yup.string()
      .trim()
      .min(1)
      .max(32, "Last Name must be at most 32 characters")
      // eslint-disable-next-line
      .matches(/^(?![0-9]*$)([ \u00C0-\u01FFa-zA-Z0-9'\-])+$/, "Please enter a valid last name")
      .required("Last Name is required");
  if (fieldsObj.AddressLine1)
    schema.AddressLine1 = Yup.string().trim().required("Street Address is required");
  if (fieldsObj.City) schema.City = Yup.string().trim().required("City is required");
  if (fieldsObj.Country)
    schema.Country = Yup.string().trim().required("Country of residence is required");
  if (fieldsObj.BirthCountry)
    schema.BirthCountry = Yup.string().trim().required("Country of birth is required");
  if (fieldsObj.State) schema.State = Yup.string().trim().required("State/Region is required");
  if (fieldsObj.Gender) schema.Gender = Yup.string().trim().required("Gender is required");
  if (fieldsObj.DOB) schema.DOB = Yup.date().required("Date of Birth is required");
  if (fieldsObj.ZipCode) schema.ZipCode = Yup.string().trim().required("Zipcode is required");
  if (fieldsObj.Phone)
    schema.Phone = Yup.number()
      .required("Phone number is required")
      .test("test-phone", "Please enter a valid Phone Number", (value) =>
        isPossiblePhoneNumber(`+${value}`)
      );
  if (fieldsObj.idType) schema.idType = Yup.string().trim().required("ID Type is required");
  if (fieldsObj.idNumber) schema.idNumber = Yup.string().trim().required("ID Number is required");
  if (fieldsObj.Nationality)
    schema.Nationality = Yup.string().trim().required("Nationality is required");
  if (fieldsObj.usesFor) schema.usesFor = Yup.string().trim().required("This field is required");
  if (fieldsObj.Occupation)
    schema.Occupation = Yup.string().trim().required("Occupation is required");
  if (fieldsObj.SourceOfFunds)
    schema.SourceOfFunds = Yup.string().trim().required("Source of fund is required");
  if (fieldsObj.SourceOfWealth)
    schema.SourceOfWealth = Yup.string()
      .trim()
      .required("Source of wealth is required")
      .test("is-other", "Source of wealth is required", (value) => {
        return !(value === "Other -");
      });

  if (fieldsObj.howDidYouHearAboutUs)
    schema.howDidYouHearAboutUs = Yup.string().trim().required("Hear about us is required");
  if (fieldsObj.annualIncome)
    schema.annualIncome = Yup.string().trim().required("Annual net income is required");
  if (fieldsObj.networth)
    schema.networth = Yup.string().trim().required("Total net worth is required");
  if (fieldsObj.SourceOfFundsComment)
    schema.SourceOfFundsComment = Yup.string()
      .trim()
      .min(30, "Minimum 30 characters required")
      .max(250, "Maximun 250 characters required")
      .required("Description on source of funds is required");
  if (fieldsObj.SourceOfWealthComment)
    schema.SourceOfWealthComment = Yup.string()
      .trim()
      .min(30, "Minimum 30 characters required")
      .max(250, "Maximun 250 characters required")
      .required("Description on source of wealth is required");

  return Yup.object().shape(schema);
};

const getInitialValues = (missingFields, userData) => {
  let data = {};
  if (!missingFields) return data;
  if (missingFields.firstName) data.firstName = userData.firstName || "";
  if (missingFields.lastName) data.lastName = userData.lastName || "";
  if (missingFields.DOB) data.DOB = userData.DOB || "";
  if (missingFields.Gender) data.Gender = userData.Gender || "";
  if (missingFields.Nationality) data.Nationality = userData.Nationality || "";
  if (missingFields.Country) data.Country = userData.Country || "";
  if (missingFields.BirthCountry) data.BirthCountry = userData.BirthCountry || "";
  if (missingFields.Phone) data.Phone = userData.Phone || "";
  if (missingFields.AddressLine1) data.AddressLine1 = userData.AddressLine1 || "";
  if (missingFields.City) data.City = userData.City || "";
  if (missingFields.State) data.State = userData.State || "";
  if (missingFields.ZipCode) data.ZipCode = userData.ZipCode || "";
  if (missingFields.usesFor) data.usesFor = userData.usesFor || "";
  if (missingFields.Occupation) data.Occupation = userData.Occupation || "";
  if (missingFields.SourceOfFunds) data.SourceOfFunds = userData.SourceOfFunds || "";
  if (missingFields.SourceOfWealth) data.SourceOfWealth = userData.SourceOfWealth || "";
  if (missingFields.idType) data.idType = userData.idType || "";
  if (missingFields.idNumber) data.idNumber = userData.idNumber || "";
  if (missingFields.howDidYouHearAboutUs)
    data.howDidYouHearAboutUs = userData.howDidYouHearAboutUs || "";
  if (missingFields.annualIncome) data.annualIncome = userData.annualIncome || "";
  if (missingFields.networth) data.networth = userData.networth || "";
  if (missingFields.SourceOfFundsComment)
    data.SourceOfFundsComment = userData.SourceOfFundsComment || "";
  if (missingFields.SourceOfWealthComment)
    data.SourceOfWealthComment = userData.SourceOfWealthComment || "";

  return data;
};

const MissingProfileDetails = ({
  isFetchingCountries,
  getListOfCountries,
  countriesError,
  countriesList,
  invalidFields,
  userData,
  checkUserLoggedIn
}) => {
  const { width } = useWindowSize();
  const [missingFieldsCount, setMissingFieldsCount] = useState(0);
  const [sourceList, setSourceList] = useState("");
  const [missingFields, setMissingFields] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getListOfCountries();
    getSources();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (countriesError) {
      showErrorMsg(formatAPIErrors(countriesError));
    }
    // eslint-disable-next-line
  }, [countriesError]);

  useEffect(() => {
    if (missingFields) {
      let count = 0;

      if (missingFields.firstName) count++;
      if (missingFields.lastName) count++;
      if (missingFields.DOB) count++;
      if (missingFields.Gender) count++;
      if (missingFields.Nationality) count++;
      if (missingFields.Country) count++;
      if (missingFields.BirthCountry) count++;
      if (missingFields.Phone) count++;
      if (missingFields.AddressLine1) count++;
      if (missingFields.City) count++;
      if (missingFields.State) count++;
      if (missingFields.ZipCode) count++;
      if (missingFields.usesFor) count++;
      if (missingFields.Occupation) count++;
      if (missingFields.SourceOfFunds) count++;
      if (missingFields.SourceOfWealth) count++;
      if (missingFields.idType) count++;
      if (missingFields.idNumber) count++;
      if (missingFields.howDidYouHearAboutUs) count++;
      if (missingFields.annualIncome) count++;
      if (missingFields.networth) count++;
      if (missingFields.SourceOfFundsComment) count++;
      if (missingFields.SourceOfWealthComment) count++;

      setMissingFieldsCount(count);
    }
    // eslint-disable-next-line
  }, [missingFields]);

  useEffect(() => {
    if (invalidFields) {
      let fields = {};
      // eslint-disable-next-line
      invalidFields.map((data) => {
        fields[data] = true;
      });
      setMissingFields(fields);
    }
    // eslint-disable-next-line
  }, [invalidFields]);

  const getSources = () => {
    getSourceList()
      .then((res) => {
        setSourceList(res && res.data.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const setAllFieldsToTouched = () => {
    const touched = { ...getInitialValues(missingFields, userData) };
    Object.keys(touched).forEach((i) => (touched[i] = true));
    return touched;
  };

  const formSubmit = (values) => {
    setLoader(true);
    let data = {
      ...values
    };

    completeProfileStatusData(data)
      .then((res) => {
        if (res?.data?.message) showSuccessMsg(res.data.message);
        checkUserLoggedIn();
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <DivElement pageContainer>
      <MissingProfileDiv>
        <div className="mpDetails">
          <div className="mpDetails__header">
            <div className="mpDetails__header__title">
              Missing Profile Details <div className="mpDetails__header__imgtag">Important</div>{" "}
            </div>
            <div className="mpDetails__header__subtitle">
              Some of your profile information is missing, please update them to enjoy our{" "}
              <br className="para_break" /> services hassle free at full capabilities
            </div>
          </div>
          <div className="mpDetails__container">
            <div className="mpDetails__box element-box">
              {userData && countriesList ? (
                <Formik
                  initialValues={getInitialValues(missingFields, userData)}
                  validationSchema={getValidationSchema(missingFields)}
                  validateOnMount={true}
                  initialTouched={setAllFieldsToTouched()}
                  onSubmit={formSubmit}
                >
                  {({ setFieldValue, values, handleBlur, errors }) => (
                    <Form style={{ height: "100%" }}>
                      <DivElement
                      // className="mpDetails__form__container"
                      // minHeight="calc(100% - 50px)"
                      // minHeightMd="calc(100% - 48px)"
                      >
                        {(missingFields.SourceOfFunds || missingFields.SourceOfFundsComment) && (
                          <DropdownInput
                            title="Source of funds"
                            name="SourceOfFunds"
                            placeholder="Select an option"
                            value={values.SourceOfFunds}
                            setFieldValue={setFieldValue}
                            error={errors.SourceOfFunds}
                            disabled={!sourceList}
                            options={sourceList && formatDropdownOptions(sourceList.funds)}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {width < 768 && missingFields.SourceOfFundsComment && (
                          <TextInput
                            type="textarea"
                            title="Comment on source of fund"
                            name="SourceOfFundsComment"
                            disabled={values.SourceOfFunds || userData.SourceOfFunds ? false : true}
                            value={values.SourceOfFundsComment}
                            placeholder="Describe on source of fund"
                            error={errors.SourceOfFundsComment}
                          />
                        )}

                        {(missingFields.SourceOfWealth || missingFields.SourceOfWealthComment) &&
                          sourceList &&
                          sourceList.wealth && (
                            <DropdownWithSpecifyOtherInput
                              title="Source of wealth"
                              isOtherTitle="Name source of wealth"
                              name="SourceOfWealth"
                              placeholder="Select an option"
                              missingProfileStatus={true}
                              value={values.SourceOfWealth}
                              setFieldValue={setFieldValue}
                              options={sourceList && formatDropdownOptions(sourceList.wealth)}
                              error={errors.SourceOfWealth}
                              containerClass={
                                missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                              }
                            />
                          )}
                        <br />

                        {width < 768 && missingFields.SourceOfWealthComment && (
                          <TextInput
                            type="textarea"
                            title="Comment on source of wealth"
                            name="SourceOfWealthComment"
                            disabled={
                              values.SourceOfWealth || userData.SourceOfWealth ? false : true
                            }
                            value={values.SourceOfWealthComment}
                            placeholder="Describe on source of wealth"
                          />
                        )}

                        {(missingFields.SourceOfFundsComment ||
                          missingFields.SourceOfWealthComment) && (
                          <>
                            {/* {!missingFields.SourceOfFunds && 
                            <>
                              <DropdownInput
                                title="Source of funds"
                                name="SourceOfFunds"
                                placeholder="Select an option"
                                value={userData.SourceOfFunds}
                                setFieldValue={setFieldValue}
                                error={errors.SourceOfFunds}
                                options={sourceList && formatDropdownOptions(sourceList.funds || [])}
                                containerClass={
                                  missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                                }
                              />

                              {width < 768 && 
                                <TextInput
                                  type="textarea"
                                  title="Comment on source of fund"
                                  name="SourceOfFundsComment"
                                  disabled={(values.SourceOfFunds || userData.SourceOfFunds) ? false : true}
                                  value={values.SourceOfFundsComment}
                                  placeholder="Describe on source of fund"
                                />
                              }
                            </>
                          } */}

                            {/* {!missingFields.SourceOfWealth && sourceList && sourceList.wealth &&
                            <>
                              <DropdownWithSpecifyOtherInput
                                title="Source of wealth"
                                isOtherTitle="Name source of wealth"
                                isPlaceHolderTitle="Enter the source"
                                name="SourceOfWealth"
                                placeholder="Select an option"
                                value={userData.SourceOfWealth}
                                setFieldValue={setFieldValue}
                                options={sourceList && formatDropdownOptions(sourceList.wealth)}
                              />
                       
                              {width < 768 &&
                                <TextInput
                                  type="textarea"
                                  title="Comment on source of wealth"
                                  name="SourceOfWealthComment"
                                  disabled={(values.SourceOfWealth || userData.SourceOfWealth) ? false : true}
                                  value={values.SourceOfWealthComment}
                                  placeholder="Describe on source of wealth"
                                />
                              }
                            </>
                          } */}

                            {width > 767 && (
                              <BannerBox
                                width="100%"
                                display="flex"
                                flexWrap="wrap"
                                justifyContent="space-between"
                              >
                                <TextInput
                                  type="textarea"
                                  title="Comment on source of fund"
                                  name="SourceOfFundsComment"
                                  disabled={
                                    values.SourceOfFunds || userData.SourceOfFunds ? false : true
                                  }
                                  value={values.SourceOfFundsComment}
                                  placeholder="Describe on source of fund"
                                  error={errors.SourceOfFundsComment}
                                />

                                <TextInput
                                  type="textarea"
                                  title="Comment on source of wealth"
                                  name="SourceOfWealthComment"
                                  disabled={
                                    values.SourceOfWealth || userData.SourceOfWealth ? false : true
                                  }
                                  value={values.SourceOfWealthComment}
                                  placeholder="Describe on source of wealth"
                                  error={errors.SourceOfWealthComment}
                                />
                              </BannerBox>
                            )}
                          </>
                        )}

                        {missingFields.firstName && (
                          <TextInput
                            title="First Name"
                            name="firstName"
                            value={values.firstName}
                            error={errors.firstName}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.lastName && (
                          <TextInput
                            title="Last Name"
                            name="lastName"
                            value={values.lastName}
                            error={errors.lastName}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.DOB && (
                          <DateTimeInput
                            title="Date of Birth"
                            name="DOB"
                            value={values.DOB}
                            setFieldValue={setFieldValue}
                            error={errors.DOB}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.Gender && (
                          <DropdownInput
                            title="Gender"
                            name="Gender"
                            placeholder="Select gender"
                            value={getGenderLabel(values.Gender)}
                            setFieldValue={setFieldValue}
                            error={errors.Gender}
                            options={GENDER}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.Phone && (
                          <FormPhoneInput
                            title="Mobile Phone"
                            name="Phone"
                            value={values.Phone}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            error={errors.Phone}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.Country && (
                          <CountriesDropdown
                            title="Country of Residency"
                            name="Country"
                            value={values.Country}
                            setFieldValue={setFieldValue}
                            disabled={isFetchingCountries || !countriesList}
                            countryList={formatCountriesArray(countriesList.Country)}
                            placeholder="Select Country of Residency"
                            error={errors.Country}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.BirthCountry && (
                          <CountriesDropdown
                            title="Country of Birth"
                            name="BirthCountry"
                            value={values.BirthCountry}
                            disabled={isFetchingCountries || !countriesList}
                            setFieldValue={setFieldValue}
                            countryList={formatCountriesArray(countriesList.BirthCountry)}
                            placeholder="Select Country of Birth"
                            error={errors.BirthCountry}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.Nationality && (
                          <CountriesDropdown
                            title="Nationality"
                            name="Nationality"
                            value={values.Nationality}
                            setFieldValue={setFieldValue}
                            disabled={isFetchingCountries || !countriesList}
                            countryList={formatCountriesArray(countriesList.Nationality)}
                            placeholder="Select Nationality"
                            error={errors.Nationality}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.AddressLine1 && (
                          <TextInput
                            containerClass="fInput__form__field--w100"
                            title="Street Address"
                            name="AddressLine1"
                            value={values.AddressLine1}
                            error={errors.AddressLine1}
                          />
                        )}

                        {missingFields.City && (
                          <TextInput
                            title="City"
                            name="City"
                            value={values.City}
                            placeholder="Enter City"
                            error={errors.City}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.State && (
                          <TextInput
                            title="State"
                            name="State"
                            value={values.State}
                            placeholder="Enter State"
                            error={errors.State}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.ZipCode && (
                          <TextInput
                            title="Pincode"
                            name="ZipCode"
                            value={values.ZipCode}
                            placeholder="Enter Pincode"
                            error={errors.ZipCode}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.annualIncome && (
                          <DropdownInput
                            title="Annual net income"
                            name="annualIncome"
                            placeholder="Select income range"
                            value={values.annualIncome}
                            setFieldValue={setFieldValue}
                            options={ANNUAL_NET_INCOME}
                            style={{ width: "100%" }}
                          />
                        )}

                        {missingFields.networth && (
                          <DropdownInput
                            title="Total net worth"
                            name="networth"
                            placeholder="Select range"
                            value={values.networth}
                            setFieldValue={setFieldValue}
                            options={ANNUAL_NET_INCOME}
                          />
                        )}

                        {missingFields.usesFor && (
                          <DropdownInput
                            title="What will you use LCX for?"
                            name="usesFor"
                            placeholder="Select an option"
                            value={values.usesFor}
                            setFieldValue={setFieldValue}
                            error={errors.usesFor}
                            options={USE_OF_LCX}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.Occupation && (
                          <DropdownInput
                            title="Current Occupation"
                            name="Occupation"
                            placeholder="Select an option"
                            value={values.Occupation}
                            setFieldValue={setFieldValue}
                            error={errors.Occupation}
                            options={OCCUPATIONS}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.howDidYouHearAboutUs && (
                          <DropdownInput
                            title="How did you hear about us?"
                            name="howDidYouHearAboutUs"
                            placeholder="Select an option"
                            value={values.howDidYouHearAboutUs}
                            setFieldValue={setFieldValue}
                            options={HEAR_ABOUT_US}
                          />
                        )}

                        {missingFields.idType && (
                          <DropdownInput
                            title="ID Document for verification"
                            name="idType"
                            placeholder="Select Id Type"
                            value={values.idType}
                            setFieldValue={setFieldValue}
                            error={errors.idType}
                            options={ID_DOCUMENTS}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}

                        {missingFields.idNumber && (
                          <TextInput
                            title="ID Document Number"
                            name="idNumber"
                            value={values.idNumber}
                            placeholder="Type here"
                            error={errors.idNumber}
                            containerClass={
                              missingFieldsCount === 1 ? "fInput__form__field--w100" : ""
                            }
                          />
                        )}
                      </DivElement>
                      <Button
                        primaryBlue
                        type="submit"
                        width="100%"
                        height="50px"
                        heightMd="48px"
                        disabled={loader}
                      >
                        {loader ? <LoadingButton /> : "Submit"}
                      </Button>
                    </Form>
                  )}
                </Formik>
              ) : (
                <DivElement displayCenter width="100%" height="100%">
                  <LoadingButton color="#047bf8" />
                </DivElement>
              )}
            </div>
          </div>
        </div>
      </MissingProfileDiv>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData,
  invalidFields: state.dashboard.invalidFields,
  missingProfileLoader: state.auth.missingProfileLoader,
  countriesList: state.kyc.countriesList,
  isFetchingCountries: state.kyc.isFetchingCountries,
  countriesError: state.kyc.countriesError
});

const mapDispatchToProps = (dispatch) => ({
  getListOfCountries: () => dispatch(getCountriesList()),
  checkUserLoggedIn: () => dispatch(isUserLoggedIn())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MissingProfileDetails);
