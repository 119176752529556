import { css } from "styled-components";
import { lighten } from "polished";

const ButtonStyleGlobal = css`
  ${(props) =>
    props.OutlineOnlyPrimary &&
    css`
      background-color: transparent !important;
      border: 0 !important;
      color: ${(props) => props.theme.primary} !important;
      box-shadow: none !important;
      &:hover {
        color: ${(props) => props.theme.primary} !important;
      }
    `};

  ${(props) =>
    props.OutlineOnlyPrimary2 &&
    css`
      background-color: transparent !important;
      border: 1px solid ${(props) => props.theme.primary} !important;
      color: ${(props) => props.theme.primary} !important;
      box-shadow: none !important;
      &:hover {
        color: ${(props) => props.theme.primary} !important;
      }
    `};

  ${(props) =>
    props.OutlineOnlySuccess &&
    css`
      background-color: transparent !important;
      border: 0 !important;
      color: ${(props) => props.theme.success} !important;
      &:hover {
        color: ${(props) => props.theme.success} !important;
      }
    `};

  ${(props) =>
    props.OutlineOnlyDanger &&
    css`
      background-color: transparent !important;
      border: 0 !important;
      color: ${(props) => props.theme.danger} !important;
      &:hover {
        text-decoration: underline;
        color: ${(props) => props.theme.danger} !important;
      }
    `};

  ${(props) =>
    props.OutlinePrimary &&
    css`
      background-color: transparent !important;
      border: 1px solid ${(props) => props.theme.primary} !important;
      color: ${(props) => props.theme.primary} !important;
      &:hover {
        background-color: ${props.theme.primary} !important;
        // border: 0 !important;
        color: ${props.theme.mainBackground} !important;
      }
    `};

  ${(props) =>
    props.OutlineLightPrimary &&
    css`
      background-color: ${props.theme.level3CardTC} !important;
      border: 1px solid ${(props) => props.theme.primary} !important;
      color: ${(props) => props.theme.primary} !important;
      &:hover {
        background-color: ${props.theme.level3CardGrey} !important;
        color: ${props.theme.primary} !important;
      }
    `};
  ${(props) =>
    props.OutlineLightPrimary2 &&
    css`
      background-color: ${props.theme.level3CardTC} !important;
      border: 1px solid ${(props) => props.theme.primary} !important;
      color: ${(props) => props.theme.primary} !important;
      &:hover {
        background-color: ${(props) => props.theme.level3CardGrey} !important;
        color: ${props.theme.primary} !important;
      }
    `};

  ${(props) =>
    props.OutlineLightDefaultPrimary &&
    css`
      background-color: transparent !important;
      border: 1px solid ${(props) => props.theme.borderColor} !important;
      color: ${(props) => props.theme.subText} !important;
      &:hover {
        background-color: ${lighten(0.5, props.theme.primary)} !important;
        border: 1px solid ${props.theme.primary} !important;
        color: ${props.theme.primary} !important;
      }
    `};

  ${(props) =>
    props.OutlineSuccess &&
    css`
      background-color: transparent !important;
      border: 1px solid ${(props) => props.theme.success} !important;
      color: ${(props) => props.theme.success} !important;
      &:hover {
        background-color: ${(props) => props.theme.success} !important;
        border: 0 !important;
        color: #fff !important;
      }
    `};

  ${(props) =>
    props.OutlineDanger &&
    css`
      background-color: transparent !important;
      border: 1px solid ${(props) => props.theme.danger} !important;
      color: ${(props) => props.theme.danger} !important;
      &:hover {
        background-color: ${(props) => props.theme.danger} !important;
        border: 0 !important;
        color: #fff !important;
      }
    `};
`;

export { ButtonStyleGlobal };
