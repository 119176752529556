/**
 * Intialize Banner Reducer
 */

import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  contestDetails: null,
  userList: {},
  userListFetching: false
};

let initialState = initialStateSchema;

const CompetitionReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GET_TRADING_COMPETITION:
      newState = {
        ...state,
        contestDetails: null
      };
      return newState;

    case apiConstants.GET_TRADING_COMPETITION_SUCCESS:
      newState = {
        ...state,
        contestDetails: action.response.data
      };
      return newState;

    case apiConstants.GET_TRADING_COMPETITION_FAIL:
      newState = {
        ...state,
        contestDetails: null
      };
      return newState;

    case apiConstants.GET_CONTEST_USER_LIST:
      newState = {
        ...state,
        userList: {},
        userListFetching: true
      };
      return newState;

    case apiConstants.GET_CONTEST_USER_LIST_SUCCESS:
      newState = {
        ...state,
        userList: action.response,
        userListFetching: false
      };
      return newState;

    case apiConstants.GET_CONTEST_USER_LIST_FAIL:
      newState = {
        ...state,
        userList: {},
        userListFetching: false
      };
      return newState;

    case apiConstants.LEADERBOARD_PAIR_LIST:
      newState = {
        ...state,
        // leaderboard: {},
        leaderboardFetching: true
      };
      return newState;
    case apiConstants.LEADERBOARD_PRIVATE_PAIR_LIST:
      newState = {
        ...state,
        // leaderboard: {},
        privateLeaderboardFetching: true
      };
      return newState;

    case apiConstants.LEADERBOARD_PAIR_LIST_SUCCESS:
      newState = {
        ...state,
        leaderboardList: action.response,
        leaderboardFetching: false
      };
      return newState;

    case apiConstants.LEADERBOARD_PAIR_LIST_FAIL:
      newState = {
        ...state,
        // userList: {},
        leaderboardFetching: false
      };
      return newState;
    case apiConstants.LEADERBOARD_PRIVATE_PAIR_LIST_SUCCESS:
      newState = {
        ...state,
        privateLeaderboardList: action.response,
        privateLeaderboardFetching: false
      };
      return newState;

    case apiConstants.LEADERBOARD_PRIVATE_PAIR_LIST_FAIL:
      newState = {
        ...state,
        // userList: {},
        privateLeaderboardFetching: false
      };
      return newState;

    default:
      return state;
  }
};

export default CompetitionReducer;
