import React from "react";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ExclamationIcon } from "../../assets/svgs";

const SuspensionAlert = ({ coin, type }) => {
  return (
    <BannerBox
      CardYellow
      radius={"10px"}
      mt="32px"
      padding={"24px 20px 24px 12px"}
      display="flex"
      alignItems="flex-start"
    >
      <ExclamationIcon marginRight="1rem" mt="5px" />

      <BannerBox>
        <BannerHeading txtDanger> {`${coin} ${type} Suspended`} </BannerHeading>
        <BannerHeading txtGrey>
          {`The wallet is currently undergoing maintenance. ${type} for this asset will be resumed
            shortly.`}
        </BannerHeading>
      </BannerBox>
    </BannerBox>
  );
};

export default SuspensionAlert;
