import React, { useState, useEffect } from "react";
import { ThreeDotsIcon } from "../../../assets/svgs";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";

const OptionsDropdown = (props) => {
  const { containerClass, borderColor } = props;

  const [showDropdown, setShowDropDown] = useState(false);
  let dropdown = React.createRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleClickOutside = (event) => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  const handleDropdown = () => {
    setShowDropDown((showDropdown) => {
      return !showDropdown;
    });
  };
  return (
    <BannerBox className={`${containerClass}`} OptionsDropdown>
      <div className="optionsdropdown__container" onClick={handleDropdown} ref={dropdown}>
        {showDropdown && (
          <div className={`optionsdropdown__options ${borderColor}`}>
            <ul>{props.children}</ul>
          </div>
        )}
        <ThreeDotsIcon />
      </div>
    </BannerBox>
  );
};

export default OptionsDropdown;
