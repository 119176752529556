import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

const KycFAQ = ({ kycContent, kycContentLoader }) => {
  return (
    <div className="stepWrapper__guidlines">
      <div className="stepWrapper__havingIssue">Having Issues?</div>
      <ul className="guidline__modal__points">
        {!kycContentLoader &&
          kycContent &&
          kycContent.data.map((data, i) => {
            return (
              <li key={i}>
                <a
                  className="kyc__faq"
                  href={data.link || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.question}
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  kycContent: state.kyc.kycContent,
  kycContentLoader: state.kyc.kycContentLoader
});

export default compose(connect(mapStateToProps))(KycFAQ);
