/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BannerBox, BannerHeadingSpan } from "../../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../../assets/StyleComponents/FontSize/Heading.style";
import { ArrowRightIcon, ChevronRightIcon, ProgressSuccessIcon } from "../../../../../assets/svgs";
import KycStep4 from "../KycStep4";
import { KycSection } from "../../../../../assets/StyleComponents/kyc.style";
import KycFAQ from "../../KycFAQ";
import ResidenceProof from "./ResidenceProof";
import KycStep3 from "../KycStep3";
import { AnchorTag } from "../../../../../assets/StyleComponents/FontSize/para.style";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import { useSelector } from "react-redux";

function NewStep3() {
  const [steps, setSteps] = useState(1);
  const { width } = useWindowSize();
  const userData = useSelector((state) => state.dashboard.getUserData);
  const VerticationSteps = [
    {
      StepName: "ID Verification",
      alpha: "a",
      active: steps >= 1
    },
    {
      StepName: width > 767 ? "Face Verification" : "Face",
      alpha: "b",
      active: steps >= 2
    },
    {
      StepName: width > 767 ? "Residence Verification" : "Residence",
      alpha: "c",
      active: steps === 3
    }
  ];

  useEffect(() => {
    if (userData) {
      changeStep();
    }
    // eslint-disable-next-line
  }, [userData]);

  const changeStep = () => {
    if (userData && userData["KYC"]) {
      if (
        userData["KYC"]["STATUS"] === "LEVEL2" ||
        userData["KYC"]["STATUS"] === "LEVEL3" ||
        userData["KYC"]["LEVEL2"]["status"] === "APPROVED"
      ) {
        setSteps(1);
        // history.push("/dashboard");
      } else if (userData["KYC"]["LEVEL2"]["status"] === "SUBMITTED") {
        setSteps(3);
        // setIsKycSubmitted(true);
      } else if (
        userData["KYC"]["LEVEL2"]["step1"]["status"] !== "APPROVED" &&
        userData["KYC"]["LEVEL2"]["step1"]["status"] !== "SUBMITTED"
      ) {
        setSteps(1);
      } else if (
        userData["KYC"]["LEVEL2"]["step2"]["status"] !== "APPROVED" &&
        userData["KYC"]["LEVEL2"]["step2"]["status"] !== "SUBMITTED"
      ) {
        setSteps(1);
      } else if (
        userData["KYC"]["LEVEL2"]["step3"]["status"] !== "APPROVED" &&
        userData["KYC"]["LEVEL2"]["step3"]["status"] !== "SUBMITTED"
      ) {
        setSteps(2);
      } else if (
        userData["KYC"]["LEVEL2"]["step4"]["status"] !== "APPROVED" &&
        userData["KYC"]["LEVEL2"]["step4"]["status"] !== "SUBMITTED"
      ) {
        setSteps(3);
      } else if (userData["KYC"]["LEVEL2"] && userData["KYC"]["LEVEL2"].nextStep === "STEP2") {
        setSteps(1);
      } else if (userData["KYC"]["LEVEL2"] && userData["KYC"]["LEVEL2"].nextStep === "STEP3") {
        setSteps(2);
      } else if (userData["KYC"]["LEVEL2"] && userData["KYC"]["LEVEL2"].nextStep === "STEP4") {
        setSteps(3);
      }
    }
  };
  return (
    <>
      <KycSection StepContainer>
        {/* {!openWebcam && ( */}
        {/* <Fragment> */}
        <div className="stepWrapper__form">
          <BannerBox display="flex" alignItems="center" gap="16px" mb="24px" gapMd="5px">
            {VerticationSteps.map((data, index) => {
              return (
                <>
                  <BannerBox display="flex" gap="6px">
                    {index + 1 < steps ? (
                      <ProgressSuccessIcon width="22px" height="22px" />
                    ) : (
                      <>
                        <BannerHeadingSpan
                          width="22px"
                          height="22px"
                          size="14px"
                          weight="500"
                          BorderRadius="50%"
                          BodyColorBg
                          opacity={data.active ? 1 : 0.4}
                        >
                          {data.alpha}
                        </BannerHeadingSpan>
                      </>
                    )}
                    <BannerHeading
                      size="14px"
                      weight="500"
                      opacity={data.active ? 1 : 0.4}
                      txtSuccess={index + 1 < steps}
                    >
                      {data.StepName}
                    </BannerHeading>
                  </BannerBox>
                  {data.alpha !== "c" && <ChevronRightIcon />}
                </>
              );
            })}
          </BannerBox>
          {width > 767 && width < 1024 && (
            <AnchorTag
              txtPrimary
              href="https://www.lcx.com/info/lcx-accounts/kyc-verification/"
              target="_blank"
              textDecoration="underline"
              ml="auto"
              mb="24px"
            >
              Important Points
            </AnchorTag>
          )}
          {/* Nation id */}
          {steps === 1 && <KycStep4 setSteps={setSteps} steps={steps} />}
          {/* Selfie */}
          {steps === 2 && <KycStep3 setSteps={setSteps} steps={steps} />}
          {/* Residence  */}
          {steps === 3 && <ResidenceProof />}
        </div>
        <KycFAQ step={3} />
      </KycSection>
    </>
  );
}

export default NewStep3;
