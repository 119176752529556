import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import NonAuthButton from "../NonAuthButton";
import NumberFormat from "react-number-format";
import { getEmailOtp } from "../../../../lib/api";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { formatAPIErrors } from "../../../../utils/general";
import Countdown from "react-countdown";
import config from "../../../../constants/config";
import {
  twoFaAndrecaptchaLoginOtp,
  recaptchaLoginOtp,
  twoFa,
  clearAuthErrors
} from "../../../../actions/auth";
import { useEffect } from "react";
import { HANDLE_2FA_ERROR } from "../../../../constants/response";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { ToolTip } from "../../../Reusable";
import { HeadingSix } from "../../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { RouterLink } from "../../../Reusable";
import { AnchorTag } from "../../../../assets/StyleComponents/FontSize/para.style";

const LoginStep2 = ({
  authLoader,
  otpCode,
  isOtp,
  isTwoAuth,
  twoFaAndrecaptchaLoginOtp,
  recaptchaLoginOtp,
  twoFa,
  codeData,
  error,
  clearAuthErrors
}) => {
  const [otp, setOtp] = useState("");
  const [auth, setAuth] = useState("");
  const [timerRunning, setTimerRunning] = useState(false);
  const [dateTimer, setDateTimer] = useState("");
  const [otpType, setOtpType] = useState("Get Code");
  const [domainName, setDomainName] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [getOtpLoader, setGetOtpLoader] = useState(false);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    let domain = new URLSearchParams(window.location.search).get("location");
    let url = new URLSearchParams(window.location.search).get("redirectUrl");
    setDomainName(domain);
    setRedirectUrl(url);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (otpCode) getOtp();
  }, [otpCode]);

  useEffect(() => {
    if (error) {
      if (error.message === HANDLE_2FA_ERROR) {
        setAuth("");
      } else {
        setAuth("");
        setOtp("");
      }
      clearAuthErrors();
    }
    // eslint-disable-next-line
  }, [error]);

  const getOtp = () => {
    if (timerRunning || getOtpLoader) return;
    setGetOtpLoader(true);

    let data = {
      otpCode: otpCode
    };
    getEmailOtp(data)
      .then((res) => {
        showSuccessMsg(res.data.message);
        setOtpType(otpType === "Get Code" ? "Resend" : otpType);
        setDateTimer(Date.now() + 30000);
        setTimerRunning(true);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setGetOtpLoader(false));
  };

  const submit2FA = (e) => {
    e.preventDefault();
    let data = {
      domain: domainName ? domainName : "exchange",
      debug: config.debug ? "true" : null,
      redirectUrl: redirectUrl
    };
    if (authLoader) return;
    if (isOtp && isTwoAuth) {
      data.token = auth;
      data.code = codeData;
      data.otp = otp;
      data.otpCode = otpCode;
      return twoFaAndrecaptchaLoginOtp(data);
    }
    if (isTwoAuth) {
      data.token = auth;
      data.code = codeData;
      return twoFa(data);
    }
    if (isOtp) {
      data.otp = otp;
      data.otpCode = otpCode;
      return recaptchaLoginOtp(data);
    }
  };

  return (
    <Fragment>
      <form onSubmit={submit2FA}>
        {isOtp && (
          <BannerBox>
            <ToolTip
              id="emailOtp"
              title="Enter Email OTP"
              tipContent="Please use the latest login OTP received on your email"
              imgProps={{
                height: "16px",
                width: "16px",
                absolute: true,
                top: "2px",
                right: "-23px"
              }}
              titleProps={{ secondary: true, marginBottom: "4px" }}
            />

            <BannerBox FormNumberContainer mt="6px" mb="2px">
              <NumberFormat
                format="### ###"
                autoComplete="totp"
                mask="_"
                name="totp"
                placeholder="***********"
                value={otp}
                onValueChange={(e) => setOtp(e.value)}
                id="otp"
              />
              <BannerHeadingSpan
                OTPSpan
                className={timerRunning || getOtpLoader ? "disabled" : null}
                onClick={() => getOtp()}
              >
                {otpType}
              </BannerHeadingSpan>
            </BannerBox>
            <BannerHeadingSpan size="14px" weight="300" mt="6px" sizeMd="12px" BodyColor>
              {timerRunning ? (
                <Countdown
                  date={dateTimer}
                  renderer={({ seconds }) => (
                    <span>Didn't receive email? resend code in {seconds} seconds</span>
                  )}
                  onComplete={() => setTimerRunning(false)}
                />
              ) : otpType === "Get Code" ? (
                "Press 'Get Code' to receive 6 digit code"
              ) : (
                "Didn't receive email? resend code now"
              )}
            </BannerHeadingSpan>
          </BannerBox>
        )}

        {isTwoAuth && (
          <BannerBox mt="20px">
            <ToolTip
              id="twoFactorAuth"
              title="Enter 2FA Code"
              tipContent="Google Authenticator generates 2-Step Verification codes on your phone."
              imgProps={{
                height: "16px",
                width: "16px",
                absolute: true,
                top: "2px",
                right: "-23px"
              }}
              titleProps={{ secondary: true, marginBottom: "4px" }}
            />
            <BannerBox FormNumberContainer mt="6px">
              <NumberFormat
                format="### ###"
                autoComplete="totp"
                mask="_"
                name="totp"
                placeholder="***********"
                value={auth}
                onValueChange={(e) => setAuth(e.value)}
              />
            </BannerBox>
          </BannerBox>
        )}

        {isTwoAuth && (
          <BannerBox display="flex" justifyContent="flex-end" mt="10px">
            <AnchorTag
              href="https://www.lcx.com/info/faq/lcx-accounts/how-to-reset-2fa/"
              target="_blank"
              rel="noopener noreferrer"
              txtPrimary
              textDecoration="underline"
              weight="500"
              size="14px"
            >
              Having Trouble?
            </AnchorTag>
          </BannerBox>
        )}

        <NonAuthButton loader={authLoader} label="Log in" />

        <BannerBox display="flex" justifyContent="center" mt="24px">
          <HeadingSix size="14px">Already have an LCX account?&nbsp;</HeadingSix>
          <RouterLink to={`${prevLang ? `/${prevLang}` : "/en"}/register`} txtPrimary fontSize="14px" underline>
            Register
          </RouterLink>
        </BannerBox>
      </form>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  authLoader: state.auth.authLoader,
  otpCode: state.auth.otpCode,
  codeData: state.auth.codeData,
  isTwoAuth: state.auth.isTwoAuth,
  isOtp: state.auth.isOtp,
  error: state.auth.error
});

const mapDispatchToProps = (dispatch) => ({
  recaptchaLoginOtp: (user) => dispatch(recaptchaLoginOtp(user)),
  twoFaAndrecaptchaLoginOtp: (user) => dispatch(twoFaAndrecaptchaLoginOtp(user)),
  twoFa: (user) => dispatch(twoFa(user)),
  clearAuthErrors: () => dispatch(clearAuthErrors())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(LoginStep2);
