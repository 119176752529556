import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { DivElement } from "../../../components/Reusable";
import { Skeleton, TableLoader } from "../../../components/Loader";
import styledConstants from "../../../constants/styledConstants";

const BalancePageLoader = () => {
  const { width } = useWindowSize();
  const isTablet = width <= styledConstants.BREAKING_POINT_TABLET;
  const isMobile = width <= styledConstants.BREAKING_POINT_MOBILE;
  return (
    <DivElement padding="0 10px 16px 10px" paddingMd="16px">
      <DivElement
        width="100%"
        height="190px"
        // marginTop="22px"
        marginTopMd="0"
        flex
        justifyContent="space-between"
      >
        <DivElement width="calc(50% - 64px)" widthMd="30%" widthSm="100%" height="100%">
          <Skeleton width="150px" widthMd="120px" height="26px" marginBottom="26px" />
          <Skeleton width="150px" widthMd="120px" height="26px" />
          <Skeleton width="200px" widthMd="170px" height="47px" heightMd="32px" marginTop="8px" />
          <Skeleton width="200px" widthMd="170px" height="30px" marginTop="8px" />
        </DivElement>
        {!isMobile && (
          <DivElement width="50%" widthMd="350px" height="100%" flex>
            {!isTablet && <Skeleton width="262px" height="100%" marginRight="16px" />}
            <Skeleton width="262px" height="100%" widthMd="350px" heightMd="160px" />
          </DivElement>
        )}
      </DivElement>
      {isMobile && <Skeleton width="100%" height="160px" />}
      <Skeleton
        width="100%"
        height="40px"
        marginTop="80px"
        marginTopSm="48px"
        marginBottom="32px"
      />
      <TableLoader rows={10} webCol={5} tabletCol={3} mobileCol={2} />
    </DivElement>
  );
};

export default BalancePageLoader;
