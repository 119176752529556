/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useSelector, useDispatch } from "react-redux";
import { updateUserExchangeWallet, updateUserOrders } from "../../actions/trading";
import { showSuccessMsg } from "../../utils/notification";
import { customDecode } from "../../utils/envScript/envCrypto";

const privateSocketUrl = customDecode(process.env.REACT_APP_PRIVATE_SOCKET_URL);

const TOPICS = {
  SNAPSHOT: "snapshot",
  UPDATE: "update"
};

const EVENTS = {
  USER_WALLETS: "user_wallets",
  USER_ORDERS: "user_orders"
};

const usePrivateWebSocket = () => {
  const [socketUrl, setSocketUrl] = useState(null);
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => true,
    heartbeat: {
      message: JSON.stringify({ Topic: "ping" }),
      interval: 25000
    }
  });
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      let accessToken = localStorage.getItem("accessToken");
      setSocketUrl(`${privateSocketUrl}?authorization=${accessToken}`);
    } else {
      setSocketUrl(null);
    }
  }, [isLoggedIn]);

  // If Web Socket connection is successfull, it will subscribe to all events
  useEffect(() => {
    if (readyState === ReadyState.OPEN) subscribleAllEvents();
  }, [readyState]);

  useEffect(() => {
    if (!lastJsonMessage) return;
    updateExchangeWallet();
    updateOrders();
  }, [lastJsonMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated"
  }[readyState];

  const subscribleAllEvents = () => {
    sendJsonMessage({ Topic: "subscribe", Type: EVENTS.USER_WALLETS });
    sendJsonMessage({ Topic: "subscribe", Type: EVENTS.USER_ORDERS });
  };

  const updateExchangeWallet = () => {
    if (lastJsonMessage.topic === TOPICS.UPDATE && lastJsonMessage.type === EVENTS.USER_WALLETS) {
      dispatch(updateUserExchangeWallet(lastJsonMessage.data));
    }
  };

  const updateOrders = () => {
    if (lastJsonMessage.type === EVENTS.USER_ORDERS) {
      dispatch(updateUserOrders(lastJsonMessage.data));
      showNotification(lastJsonMessage);
    }
  };

  const showNotification = (socketData) => {
    let notification = "";
    if (socketData.data.Status === "CLOSED") {
      notification = `${socketData.data.OrderType === "LIMIT" ? "Limit" : "Market"} ${
        socketData.data.Side == "BUY" ? "Buy" : "Sell"
      } Order of ${socketData.data.Amount} ${socketData.data.Pair.split("/")[0]} is Executed`;
    } else if (socketData.data.Status === "PARTIAL") {
      notification = `${socketData.data.OrderType === "LIMIT" ? "Limit" : "Market"} ${
        socketData.data.Side == "BUY" ? "Buy" : "Sell"
      } Order of ${socketData.data.Amount} ${
        socketData.data.Pair.split("/")[0]
      } is Partially Executed`;
    } else if (socketData.data.Status === "OPEN") {
      notification = `${socketData.data.OrderType === "LIMIT" ? "Limit" : "Market"} ${
        socketData.data.Side == "BUY" ? "Buy" : "Sell"
      } Order ${socketData?.method === "modify" ? "Updated" : "Created"} `;
      // notification = 'Order Placed Successfully.';
    } else if (socketData.data.Status === "CANCEL") {
      // notification = 'Order Cancelled.';
    }

    if (notification) {
      showSuccessMsg(notification, {
        limit: "3"
      });
    }
  };

  return { sendJsonMessage, connectionStatus, readyState };
};

export default usePrivateWebSocket;
