import React, { createContext, useState, useEffect, useCallback, useMemo, useRef } from "react";
import config from "../../../constants/apiConstants";
import { recentTicker } from "../../../lib/api";
import historyProvider from "../../trading/OHLVC/TVChartContainer/api/historyProvider";
import UseWebSocketStatus from "../../../Hooks/WebSocketHook/webSocketStatus";
import { debounce, getLocalStorage } from "../../../utils/helpers";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateSocketMarketData, updateSocketTickerData } from "../../../actions/marketLists";
import { updateLastPrice, updateLastPriceSell, updateOrderAmount, updateOrderAmountSell, updatePercentageSell, updateSelectedPairName, updateTotalAmount, updateTotalAmountSell } from "../../../Modules/Trades/action";
import { createStructuredSelector } from "reselect";
import { askPairList } from "../../../actions/trade";
import { chartTimeZone } from "../../../utils/chartTimeZone";
import { getAllRecentTrades } from "../../../Modules/Trades/RecentTrade/selectors";
import { callAllRecentTrades } from "../../../Modules/Trades/RecentTrade/action";
import { updateSingleTickerData } from "../../../Modules/Trades/MarketData/action";

Number.prototype.noExponents = function () {
  var data = String(this).split(/[eE]/);
  if (data.length == 1) return data[0];

  var z = "", sign = this < 0 ? "-" : "",
    str = data[0].replace(".", ""),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + "0.";
    while (mag++) z += "0";
    return z + str.replace(/^\-/, "");
  }
  mag -= str.length;
  while (mag--) z += "0";
  return str + z;
};

Number.prototype.toCeil = function (decimal) {
  var data = String(this);
  return Math.ceil(Number(data) * (10 ** decimal)) / (10 ** decimal);
};

Number.prototype.toFloor = function (decimal) {
  var data = String(this);
  return Math.floor(Number(data) * (10 ** decimal)) / (10 ** decimal);
};


export const stream = {
  subscribeBars: function (symbolInfo, resolution, updateCb, uid, resetCache) {
    let channelString = symbolInfo.name; // ETH/BTC

    var newSub = {
      channelString,
      uid,
      resolution,
      symbolInfo,
      lastBar: historyProvider.history[symbolInfo.name].lastBar,
      listener: updateCb
    };
    _subs.push(newSub);
  },
  unsubscribeBars: function (uid) {
    var subIndex = _subs.findIndex(e => e.uid == uid);
    if (subIndex == -1) {
      return;
    };
    _subs.splice(subIndex, 1);
  }
};

// Take a single trade, and subscription record, return updated bar
function updateBar(data, sub) {
  var lastBar = sub.lastBar;
  let resolution = sub.resolution;
  if (resolution.includes("D")) {
    resolution = 1440;
  } else if (resolution.includes("W")) {
    resolution = 10080;
  }
  var coeff = resolution * 60;
  var rounded = Math.floor(data.ts / coeff) * coeff;
  var lastBarSec = lastBar.time / 1000;
  var _lastBar;
  
  if (rounded > lastBarSec) {
    _lastBar = {
      // time: rounded * 1000,
      time: rounded,
      open: lastBar.close,
      high: lastBar.close,
      low: lastBar.close,
      close: data.price,
      volume: data.volume,
      usdVolume: data.usdVolume
    };
    if (data.price < lastBar.low) {
      _lastBar.low = data.price;
    } else if (data.price > lastBar.high) {
      _lastBar.high = data.price;
    }
  } else {
    // update lastBar candle!
    if (data.price < lastBar.low) {
      lastBar.low = data.price;
    } else if (data.price > lastBar.high) {
      lastBar.high = data.price;
    }
    lastBar.volume += data.volume;
    lastBar.close = data.price;
    _lastBar = lastBar;
  }
  return _lastBar;
}

function createBar(_data, channelString, exchange) {
  if (_data.topic == "update") {
    const getTimeZone = chartTimeZone();
    const convertMillisecond = getTimeZone * 60 * 1000;
    const offsetSign = convertMillisecond < 0 ? "-" : "+";
    const offsetSignPoistive = Math.abs(convertMillisecond);
    let data = {
      exchange: exchange,
      to_sym: (_data?.data[2] == "SELL") ? _data.pair.split("/")[1] : _data.pair.split("/")[0],
      from_sym: (_data?.data[2] == "SELL") ? _data.pair.split("/")[0] : _data.pair.split("/")[1],
      trade_id: _data.data[3],
      ts: (_data.data[3] * 1000) + offsetSignPoistive,
      volume: parseFloat(_data.data[1]),
      price: parseFloat(_data.data[0])
    };
    const sub = _subs.find(e => e.channelString == channelString);
    if (sub && sub.lastBar) {
      // disregard the initial catchup snapshot of trades for already closed candles
      if (data.ts < sub.lastBar.time / 10000) {
        return;
      }

      var _lastBar = updateBar(data, sub);
      // send the most recent bar back to TV"s realtimeUpdate callback
      sub.listener(_lastBar);
      // update our own record of lastBar
      sub.lastBar = _lastBar;
    }
  }
}

const zeroFilterLoop = (filterData) => {
  let result = [];
  if(filterData && filterData.length > 0){
    for (let i = 0; i < filterData.length; i++) {
      const x = filterData[i];
      if (x && x.length && +x[1] > 0) {
        result.push(x);
      }
    }
  }
  return result;
};

export const TradeSocketContext = createContext();

let base = "", quote = "", last_price = undefined, exchange = "", watchListId;
var _subs = [];

const TradeSocketProvider = ({ children }) => {
  const wsRef = useRef(null);
  const retryServer = useRef(null);

  const [socketUrl , setSocketUrlRes] = useState("");
  const [isOpen , setIsOpenRes] = useState(false);
  const [tradeData , setTradeDataRes] = useState([]);
  const [orderbookData , setOrderbookDataRes] = useState({});
  const [asks , setAsksRes] = useState([]);
  const [asksCopy , setAsksCopyRes] = useState([]);
  const [bids , setBidsRes] = useState([]);
  const [bidsCopy , setBidsCopyRes] = useState([]);
  const [tickerData , setTickerDataRes] = useState({});
  const [change , setChangeRes] = useState(null);
  const [high , setHighRes] = useState(null);
  const [last24Price , setLast24PriceRes] = useState(null);
  const [lastPrice , setLastPriceRes] = useState(null);
  const [low , setLowRes] = useState(null);
  const [volume , setVolumeRes] = useState(null);
  const [usdVolume , setUsdVolumeRes] = useState(null);
  const [allTickerData , setAllTickerDataRes] = useState({});
  const [socketConnection , setSocketConnectionRes] = useState(false);
  const [basePair , setBasePairRes] = useState("");
  const [quotePair , setQuotePairRes] = useState("");
  const [tickerSocketStatus , setTickerSocketStatusRes] = useState(true);
  const [depthSocketStatus , setDepthSocketStatusRes] = useState(false);
  const [tradesSocketStatus , setTradesSocketStatusRes] = useState(false);
  const [watchListStatus , setWatchListStatusRes] = useState(false);
  const [oldPair , setOldPairRes] = useState("");
  const [selectedExchange , setSelectedExchangeRes] = useState("");
  const [orderbookActive , setOrderbookActiveRes] = useState(false);
  
  let { apiRecentTrades } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const [ 
    ready, val, send, socketStatus, connectWebSocket, closeWebSocket, pairName, setPairName
  ] =  UseWebSocketStatus();

  // => when Socket Status Changed
  useEffect(() => {
    if(ready) {
      if(socketStatus && socketStatus.readyState === 1) handleOpen();
    }
    if(!ready) setIsOpenRes(false);
  }, [ready]);
  
  // => when recived any update from socket server
  useEffect(() => {
    if(ready && val) handleMessage(val);
  }, [val]);

  // => Add Ticker Data
  const addTickerData = (getTradeData) => {
    let def = getLocalStorage("default");
    let tabActive = "buy";
    if (def) {
      def = def.split("_");
      tabActive = def[0];
    }

    if (getTradeData)
      for (let key in getTradeData)
        for (let sub in getTradeData[key])
          if (typeof getTradeData[key][sub] == "number")
            getTradeData[key][sub] = getTradeData[key][sub].noExponents();

    let tickerData = getTradeData[pairName || "LCX/EUR"] || {};
    
    let lastPrice = tabActive === "buy" ? 
      (tickerData?.bestAsk === "0" ? tickerData.lastPrice : tickerData?.bestAsk || tickerData.lastPrice) : 
      (tickerData?.bestBid === "0" ? tickerData.lastPrice : tickerData?.bestBid || tickerData.lastPrice);
    
    // dispatch(updateSocketMarketData(getTradeData));
    // dispatch(updateSocketTickerData(tickerData));
    // => TODO: Initial Page Load add Price
    dispatch(updateLastPrice(lastPrice)); 
    dispatch(updateLastPriceSell((tickerData?.bestBid === "0" ? tickerData.lastPrice : tickerData?.bestBid || tickerData.lastPrice))); 
    dispatch(updateOrderAmount("")); 
    dispatch(updateTotalAmount("")); 
    dispatch(updateSingleTickerData(tickerData));
    setAllTickerDataRes(getTradeData);
    setTickerDataRes(tickerData);
    setChangeRes(tickerData.change);
    setHighRes(tickerData.high);
    setLast24PriceRes(tickerData.last24Price);
    setLastPriceRes(tickerData.lastPrice);
    setLowRes(tickerData.low);
    setVolumeRes(tickerData.volume);
    setUsdVolumeRes(tickerData.usdVolume);
    setTickerSocketStatusRes(true);
  };

  // => Update the Ticker Data
  const updateTickerData = (getTradeData, pair) => {
    let trade_data = { ...allTickerData };
    if (getTradeData)
      for (let key in getTradeData)
        if (typeof getTradeData[key] == "number")
          getTradeData[key] = getTradeData[key].noExponents();

    trade_data[pair] = { ...getTradeData, symbol: pair };

    if (pairName == pair) {
      setChangeRes(getTradeData.change);
      setHighRes(getTradeData.high);
      setLast24PriceRes(getTradeData.last24Price);
      setLastPriceRes(getTradeData.lastPrice);
      setLowRes(getTradeData.low);
      setVolumeRes(getTradeData.volume);
      setUsdVolumeRes(getTradeData?.usdVolume);
    }
    // dispatch(updateSocketMarketData(trade_data));
    // dispatch(updateSocketTickerData(trade_data[pairName]));
    setAllTickerDataRes(trade_data);
    setTickerDataRes(trade_data[pairName]);
    dispatch(updateSingleTickerData(trade_data[pairName]));
    setTickerSocketStatusRes(true);
  };

  //=> Add Trade Data
  const addTradeData = getTradeData => {
    setTradeDataRes(getTradeData || apiRecentTrades || []);
    dispatch(callAllRecentTrades(getTradeData || apiRecentTrades || []));
    setTickerSocketStatusRes(true);
  };

  //=> Update Trade Data
  const updateTradeData = getTradeData => {
    setTradeDataRes(tradeData ? [ getTradeData, ...tradeData ] : getTradeData ? getTradeData : apiRecentTrades || []);
    dispatch(callAllRecentTrades(tradeData ? [ getTradeData, ...tradeData ] : getTradeData ? getTradeData : apiRecentTrades || []));
    setTickerSocketStatusRes(true);
  };

  //=> Add Order Book Data
  const addOrderBookData = getTradeData => {

    let filteredSell = zeroFilterLoop(getTradeData.sell || []);
    getTradeData.sell = filteredSell;
    getTradeData.sell.sort((a, b) => +b[0] - +a[0]);

    let filteredBuy = zeroFilterLoop(getTradeData.buy || []);
    getTradeData.buy = filteredBuy;
    getTradeData.buy.sort((a, b) => +b[0] - +a[0]);

    let startSliceValue = getTradeData.sell.length > 25 ? getTradeData.sell.length - 26 : 0;
    let endSliceValue = getTradeData.sell.length > 25 ? getTradeData.sell.length : 25;

    setAsksRes(getTradeData.sell.slice(startSliceValue, endSliceValue));
    setAsksCopyRes(getTradeData.sell);
    setBidsRes(getTradeData.buy.slice(0, 25));
    setBidsCopyRes(getTradeData.buy);
    setTickerSocketStatusRes(true);
    setOrderbookActiveRes(true);
  };

  // Update Order Book Data
  const updateOrderBookData = getTradeData => {
    if(getTradeData && getTradeData.length > 0) {
      if (getTradeData && getTradeData[2] == "BUY") {
        let bidCopy = [ ...bidsCopy ];
        let bidModified = false;

        if (bidCopy && bidCopy.length > 0) {
          for (let i = 0; i < bidCopy.length; i++) {
            if (getTradeData[0] == bidCopy[i][0]) {
              bidCopy[i][1] = getTradeData[1];
              bidModified = true;
            }
          }

          if (!bidModified) {
            bidCopy.unshift([getTradeData[0], getTradeData[1]]);
          }
  
          let filterZero = zeroFilterLoop(bidCopy || []);
          filterZero.sort((a, b) => +b[0] - +a[0]);
          
          let startSliceValue = asksCopy.length > 25 ? asksCopy.length - 26 : 0;
          let endSliceValue = asksCopy.length > 25 ? asksCopy.length : 25;

          setAsksRes(asksCopy.slice(startSliceValue, endSliceValue));
          setAsksCopyRes(asksCopy);
          setBidsRes(filterZero.slice(0, 25));
          setBidsCopyRes(filterZero);
          setTickerSocketStatusRes(true);
          setOrderbookActiveRes(true);
        }
        else {
          let startSliceValue = asksCopy.length > 25 ? asksCopy.length - 26 : 0;
          let endSliceValue = asksCopy.length > 25 ? asksCopy.length : 25;

          bidCopy.unshift([ getTradeData[0], getTradeData[1] ]);
          bidCopy.sort((a, b) => +b[0] - +a[0]);

          setAsksRes(asksCopy.slice(startSliceValue, endSliceValue));
          setAsksCopyRes(asksCopy);
          setBidsRes(bidCopy.slice(0, 25));
          setBidsCopyRes(bidCopy);
          setTickerSocketStatusRes(true);
          setOrderbookActiveRes(true);
        }
      }
      else if (getTradeData && getTradeData[2] == "SELL") {
        let askCopy = [ ...asksCopy ];
        let bidModified = false;

        if (askCopy && askCopy.length > 0) {
          for (let i = 0; i < askCopy.length; i++) {
            if (getTradeData[0] == askCopy[i][0]) {
              askCopy[i][1] = getTradeData[1];
              bidModified = true;
            }
          }
          if (!bidModified) {
            askCopy.unshift([getTradeData[0], getTradeData[1]]);
          }
  
          let filterZero = zeroFilterLoop(askCopy || []);
          filterZero.sort((a, b) => +b[0] - +a[0]);
          
          let startSliceValue = filterZero.length > 25 ? filterZero.length - 26 : 0;
          let endSliceValue = filterZero.length > 25 ? filterZero.length : 25;
          
          setAsksRes(filterZero.slice(startSliceValue, endSliceValue));
          setAsksCopyRes(filterZero);
          setBidsRes(bids.slice(0, 25));
          setBidsCopyRes(bidsCopy);
          setTickerSocketStatusRes(true);
          setOrderbookActiveRes(true);
        }
        else {
          let startSliceValue = askCopy.length > 25 ? askCopy.length - 26 : 0;
          let endSliceValue = askCopy.length > 25 ? askCopy.length : 25;

          askCopy.unshift([ getTradeData[0], getTradeData[1] ]);
          askCopy.sort((a, b) => +b[0] - +a[0]);

          setAsksRes(askCopy.slice(startSliceValue, endSliceValue));
          setAsksCopyRes(askCopy);
          setTickerSocketStatusRes(true);
          setOrderbookActiveRes(true);
        }
      }
    }
  };


  //=> Handle when Socket is open 
  const handleOpen = () => {
    if(!isOpen){
      // send(JSON.stringify({ "Topic": "subscribe", "Type": "ticker" }));
      setIsOpenRes(true);
    }
  };

  //=> Handling all Socket Messages recieved from WebSocket.
  const handleMessage = (event) => {
    let parseData = {};
    try {
      parseData = JSON.parse(event.data);
      if (parseData.type === "ticker") {
        let tradeData = parseData.data;

        if (parseData.topic === "update") {
          updateTickerData(tradeData, parseData.pair);
        } else {
          addTickerData(tradeData);
        }
      }
      if ((parseData.type == "trade") && (parseData.pair == pairName)) {
        let tradeData = parseData.data;
        createBar(parseData, parseData.pair, "lcx");
        if ((parseData.topic == "update") && (parseData.pair == pairName)) {
          updateTradeData(tradeData);
        }
        else {
          addTradeData(tradeData);
        }
      }
      if (parseData.type === "orderbook") {
        let tradeData = parseData.data;
        if ((parseData.topic == "update") && (parseData.pair == pairName)) {
          updateOrderBookData(tradeData);
        } else if (parseData.topic != "update") {
          addOrderBookData(tradeData);
        }
      }
    } catch (e) { return; }
  };

  // => Manually Subscribe the Start All Public Sockets
  const StartAllSocket = (pair) => {
    if(ready){
      if(socketStatus && (socketStatus.readyState === 0 || socketStatus.readyState === 2 || socketStatus.readyState === 3)) {
        retryServer.current = setTimeout(() => {
          StartAllSocket(pair);
        }, 200); 
      } else {
        if(pair) {
          send(JSON.stringify({ "Topic": "subscribe", "Type": "trade", "Pair": pair }));
          send(JSON.stringify({ "Topic": "subscribe", "Type": "orderbook", "Pair": pair }));
          send(JSON.stringify({ "Topic": "subscribe", "Type": "ticker" }));
          let data = pair.split("/");
          // automaticallyCallOrderBookApi(pair);
          setSelectedExchangeRes(pair);
          dispatch(updateOrderAmountSell(""));
          dispatch(updateTotalAmountSell(""));
          dispatch(updatePercentageSell(0));
          dispatch(updateSelectedPairName({
            base: data[0],
            qoute: data[1],
            symbol: pair
          }))
          setPairName(pair);
          setOldPairRes(pair);
        }
        else send(JSON.stringify({ "Topic": "subscribe", "Type": "ticker" }));
        clearTimeout(retryServer.current);
        retryServer.current = null;
      }
    } 
    else { 
      // if(socketStatus && socketStatus.readyState !== 1){ 
        retryServer.current = setTimeout(() => {
          StartAllSocket(pair);
        }, 200); 
        // return;
      // }
    }
  };

  // => Unsubscribe Events
  const unsubscribeEvents = (pair) => {
    if(ready) {
      if (pair && (socketStatus && socketStatus.readyState)) {
        send(JSON.stringify({ "Topic": "unsubscribe", "Type": "trade", "Pair": pair }));
        send(JSON.stringify({ "Topic": "unsubscribe", "Type": "orderbook", "Pair": pair }));
        setSelectedExchangeRes("");
        setPairName("");
        setOldPairRes("");
      }
      // if (pair != "") send(JSON.stringify({ "Topic": "unsubscribe", "Type": "ticker" }));
      // setSelectedExchangeRes("");
    } 
    // else {
    //   unsubscribeEvents(pair);
    // }
  };

  const stopTickerChannel = () => {
    if(ready){
      send(JSON.stringify({ "Topic": "unsubscribe", "Type": "ticker" }));
      // dispatch(updateSocketMarketData({}));
      // dispatch(updateSocketTickerData({}));
      // setAllTickerDataRes({});
      // setTickerDataRes({});
    }
  };

  //=> Default Call for initally
  const automaticallyCallOrderBookApi = (pair) => {
    let data = {
      pair
    };
    dispatch(askPairList(data));
  };

  // => Default Recent Ticker API CAll
  const setDefaultAPIData = async (pair) => {
    let def = getLocalStorage("default");
    let tabActive = "buy";
    if (def) {
      def = def.split("_");
      tabActive = def[0];
    }
    let data = {
      pair
    };
    // let tickerData = await recentTicker(data);
    // if (tickerData)
    //   for (let key in tickerData)
    //     if (typeof tickerData[key] == "number")
    //       tickerData[key] = tickerData[key].noExponents();

    // let lastPrice = tabActive === "buy" ? 
    //   (tickerData?.bestAsk === "0" ? tickerData.lastPrice : tickerData?.bestAsk || tickerData.lastPrice) : 
    //   (tickerData?.bestBid === "0" ? tickerData.lastPrice : tickerData?.bestBid || tickerData.lastPrice);

    // => TODO: Initial Page Load add Price
    // dispatch(updateLastPrice(lastPrice)); 
    // dispatch(updateLastPriceSell((tickerData?.bestAsk === "0" ? tickerData.lastPrice : tickerData?.bestAsk || tickerData.lastPrice))); 
    setTickerDataRes(tickerData);
    setChangeRes(tickerData.change);
    setHighRes(tickerData.high);
    setLast24PriceRes(tickerData.last24Price);
    setLastPriceRes(tickerData.lastPrice);
    setLowRes(tickerData.low);
    setVolumeRes(tickerData.volume);
    setUsdVolumeRes(tickerData.usdVolume);
  };

  const ret = {
    socketStatus,
    allTickerData,
    selectedExchange: pairName,
    tickerSocketStatus,
    tradeData,
    orderbookData,
    asks,
    bids,
    asksCopy,
    bidsCopy,
    tickerData,
    change,
    high,
    last24Price,
    lastPrice,
    low,
    volume,
    usdVolume,
    orderbookActive,
    setDefaultAPIData,
    connectWebSocket,
    StartAllSocket,
    unsubscribeEvents,
    stopTickerChannel
  };
  // return (useMemo(() => {
  return (
    <TradeSocketContext.Provider
      value={ret}
    >
      {children}
    </TradeSocketContext.Provider>
  );
  // }, [ ready , val, send, allTickerData ]));
};

const mapStateToProps = createStructuredSelector({
  apiRecentTrades: getAllRecentTrades()
});

export default connect(mapStateToProps, {
  updateLastPrice,
  updateOrderAmount,
  updateTotalAmount
})(TradeSocketProvider);