import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { createApiManagement, updateApiManagement } from "../../../lib/api.js";
import { getApiKeyManagment } from "../../../actions/setting.js";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style.js";
import { showErrorMsg, showSuccessMsg } from "../../../utils/notification.js";
import { CloseIcon } from "../../../assets/svgs/index.js";
import { Checkbox, ModalWrapper } from "../../../components/Reusable/index.js";
import { DivElement, TextWrapper } from "../../../components/Reusable";
import { Label, ParaTag } from "../../../assets/StyleComponents/FontSize/para.style.js";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style.js";
import InputFieldFormik from "../../../components/Reusable/Field/index";
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style.js";
import Step2Modal from "./component/Step2Modal.js";

const AddEditKeyManagementSchema = Yup.object().shape({
  ipWhiteList: Yup.string(),
  twoFAToken: Yup.string().required("Google Authentication Code Must!")
});

function AddEditKeyManagementComponent(props) {
  const { mode, editData } = props;
  let { IpAddresses, Read, Write } = editData;
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [tokenData, setTokenData] = useState({});
  const [read, setRead] = useState(Read || false);
  const [write, setWrite] = useState(Write || false);

  const getFormikInitVal = () => {
    if (mode === "Edit") {
      return {
        ipWhiteList: IpAddresses,
        twoFAToken: "",
        read: read,
        write: Write
      };
    }
    return {
      ipWhiteList: "",
      twoFAToken: "",
      read: read,
      write: write
    };
  };

  const checkStatus = (ipValue) => {
    var ipformat =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (ipValue.match(ipformat)) {
      return true;
    } else {
      return false;
    }
  };

  function removeSpaces(str) {
    return Array.from(str)
      .filter((char) => char !== " ")
      .join("");
  }

  const verifyIpAddress = (props) => {
    let array = props;
    let result = [];

    if (array && array.length > 0) {
      array.map((item) => {
        let data = checkStatus(removeSpaces(item));
        result.push(data);
        return false;
      });
    }
    if (result && result.length > 0) {
      let data = result.filter((d) => d === false);

      if (data && data.length === 0 && data !== undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const addEditKeyManag = (values, { setSubmitting, resetForm }) => {
    let checkIpAddress;
    if (values?.ipWhiteList?.split(",").length === 1) {
      values?.ipWhiteList.split(",").map((item) => {
        if (item === "" || item === undefined) {
          return checkIpAddress === false;
        } else {
          return (checkIpAddress = verifyIpAddress(values?.ipWhiteList?.split(",")));
        }
      });
    } else {
      checkIpAddress = verifyIpAddress(values?.ipWhiteList?.split(","));
    }

    if (checkIpAddress === true) {
      showErrorMsg("Please Add Correct IP Address to WhiteList", { autoClose: 5000 });
      setSubmitting(false);
    } else {
      let params = {
        Read: read,
        Write: write,
        IPAddresses: values.ipWhiteList.split(",").map((values) => removeSpaces(values))
      };

      if (mode === "Edit") {
        params.ApiKey = editData.ApiKey;
        updateApiManagement(params, values.twoFAToken).then(
          (res) => {
            setTokenData(res?.data?.data);
            setLoader(false);
            props.getApiKeyManagment({ offset: 1 });
            setSubmitting(false);
            showSuccessMsg(res?.data?.message);
            resetForm(getFormikInitVal());
            props.onClose();
          },
          (err) => {
            showErrorMsg(`${err?.response?.data?.message}`, { autoClose: 5000 });
            setLoader(false);
            setSubmitting(false);
          }
        );
      } else {
        setLoader(true);
        createApiManagement(params, values.twoFAToken).then(
          (res) => {
            setStep(2);
            setLoader(false);
            setTokenData(res.data.data);
            props.getApiKeyManagment({ Offset: 1 });
            setSubmitting(false);
            showSuccessMsg(res?.data?.message);
            resetForm(getFormikInitVal());
          },
          (err) => {
            showErrorMsg(`${err?.response?.data?.message}`);
            setLoader(false);
            setSubmitting(false);
          }
        );
      }
    }
  };

  return (
    <>
      <ModalWrapper>
        <DivElement ModalContainer>
          <DivElement displayBtw>
            <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
              {step === 1 && (mode === "Edit" ? "Update API Key" : "Create API Key")}
              {step === 2 && "API Key Generated"}
            </TextWrapper>
            <CloseIcon pointer onClick={() => props.onClose()} />
          </DivElement>

          <div>
            {step === 1 && (
              <DivElement marginTop="20px">
                <Formik
                  initialValues={getFormikInitVal()}
                  validationSchema={AddEditKeyManagementSchema}
                  onSubmit={addEditKeyManag}
                >
                  {({ isSubmitting, values }) => (
                    <Form>
                      <DivElement margin="15px 0px">
                        <BannerHeading weight="bold" mb="4px" size="16px" sizeMd="14px">
                          Permissions
                        </BannerHeading>
                        <ParaTag txtGrey size="14px" weight="300">
                          Select permissions for the new API Key.
                        </ParaTag>
                      </DivElement>

                      <BannerBox
                        display="flex"
                        margin="1rem 0px"
                        mt="15px"
                        alignItems="center"
                        justifyContent="flex-start"
                        gap="25px"
                      >
                        <BannerHeading weight="bold" size="1rem" BodyColor>
                          Trading:
                        </BannerHeading>

                        <BannerBox display="flex" alignItems="center" gap="15px">
                          <DivElement
                            position="relative"
                            display="flex"
                            alignItems="center"
                            gap="7px"
                          >
                            {/* <InputFieldFormik
                              type="checkbox"
                              name="read"
                              // CheckBox
                              // className="form-check-input"
                              // FormCheckBox
                            /> */}
                            <Checkbox
                              isSmall={true}
                              name="read"
                              checked={read}
                              setChecked={setRead}
                            />
                            <Label size="1rem" BodyColor CheckboxBefore mb="0px">
                              Read
                            </Label>
                          </DivElement>
                          <DivElement
                            position="relative"
                            display="flex"
                            alignItems="center"
                            gap="7px"
                          >
                            {/* <InputFieldFormik
                              type="checkbox"
                              name="write"
                              // CheckBox
                              // className="form-check-input"
                              // FormCheckBox
                            /> */}
                            <Checkbox
                              isSmall={true}
                              name="write"
                              checked={write}
                              setChecked={setWrite}
                            />
                            <Label size="1rem" BodyColor CheckboxBefore mb="0px">
                              Write
                            </Label>
                          </DivElement>
                        </BannerBox>
                      </BannerBox>

                      <hr />

                      <DivElement margin="10px 0px" width="100%">
                        <DivElement margin="15px 0px" marginBottom="20px">
                          <BannerHeading size="18px" weight="bold" sizeMd="14px" BodyColor>
                            IP Whitelist
                          </BannerHeading>
                          <BannerHeading size="14px" weight="300" txtGrey>
                            {"If mutiple IP'S added with comma separated."}
                          </BannerHeading>
                        </DivElement>

                        <DivElement marginTop="5px">
                          <BannerHeading size="14px" sizeMd="12px" BodyColor margin="10px 0px">
                            IP Whitelist (Optional)
                          </BannerHeading>
                          <InputFieldFormik
                            type="text"
                            name="ipWhiteList"
                            height="44px"
                            width="100%"
                            AddBenificaryInput
                            fontSize="14px"
                            fontWeight="500"
                            BodyColor
                            placeholder="Ip Whitelist"
                          />
                          <ErrorMessageDiv
                            name="ipWhiteList"
                            component="div"
                            mt="6px"
                            size="14px"
                            sizemd="12px"
                            weight="300"
                            txtDanger
                          />
                        </DivElement>

                        <DivElement marginTop="5px">
                          <BannerHeading size="14px" sizeMd="12px" BodyColor margin="15px 0px">
                            {" "}
                            2FA Code{" "}
                          </BannerHeading>

                          <InputFieldFormik
                            type="number"
                            name="twoFAToken"
                            placeholder="Enter 2FA"
                            required="required"
                            height="44px"
                            width="100%"
                            AddBenificaryInput
                            fontSize="14px"
                            fontWeight="500"
                            BodyColor
                          />

                          <ErrorMessageDiv
                            name="twoFAToken"
                            component="div"
                            mt="6px"
                            size="14px"
                            sizemd="12px"
                            weight="300"
                            txtDanger
                          />
                        </DivElement>

                        <DivElement width="100%" marginTop="20px">
                          <ButtonComponent
                            type="submit"
                            disabled={isSubmitting}
                            PrimaryButton
                            btnHeightMd={"46px"}
                            width="100%"
                            weight="500"
                          >
                            {mode === "Edit" ? "Update" : "Create"} API Keys
                          </ButtonComponent>
                        </DivElement>
                      </DivElement>
                    </Form>
                  )}
                </Formik>
              </DivElement>
            )}
            {step === 2 && <Step2Modal loader={loader} tokenData={tokenData} />}
          </div>
        </DivElement>
      </ModalWrapper>
    </>
  );
}

const maptoStateProps = (state) => ({
  keyManagementData: state.setting.keyManagementData,
  isFetchingKeyManagementData: state.setting.isFetchingKeyManagementData
});

const mapDispatchToProps = (dispatch) => ({
  getApiKeyManagment: (data) => dispatch(getApiKeyManagment(data))
});

export default connect(maptoStateProps, mapDispatchToProps)(AddEditKeyManagementComponent);
