export const EXCHANGE_WALLET_UPDATE = 
  "src/trade/WalletBalance/EXCHANGE_WALLET_UPDATE";

export const ACCOUNT_WALLET_LIST = 
  "src/trade/WalletBalance/ACCOUNT_WALLET_LIST";

export const ACCOUNT_IS_FECTHING = 
  "src/trade/WalletBalance/ACCOUNT_IS_FECTHING";

export const EXCHANGE_IS_FECTHING = 
  "src/trade/WalletBalance/EXCHANGE_IS_FECTHING";