import styled, { css } from "styled-components";
import { darken } from "polished";
import Card from "react-bootstrap/Card";

const AccordianCard = styled(Card)`
  background-color: ${(props) => props.theme.mainBackground} !important; 
  border: 1px solid ${(props) => props.theme.borderColor};

  ${(props) => props.mt && `margin-top: ${props.mt};`};
  ${(props) => props.mb && `margin-bottom: ${props.mb};`};
  ${(props) => props.mr && `margin-right: ${props.mr};`};
  ${(props) => props.ml && `margin-left: ${props.ml};`};

  .card-header {
    font-size: 20px !important;
    font-weight: bold !important;
    color: ${(props) => props.theme.bodyText} !important;
    // border-bottom: 1px solid ${(props) => props.theme.borderColor} !important;
    @media(max-width: 767px) {
      font-size: 16px !important;
    }
    @media(max-width: 568px) {
      font-size: 14px !important;
    }
  }
  .collapse.show { 
    // background-color: ${(props) => props.theme.mainBackground} !important; 
    border:0px !important;
  }

  ${(props) => props.NoBackground && css`
    background-color: transparent !important;
    border: 0 !important;
    border-bottom: 1px solid ${(props) => props.theme.borderColor} !important;
    .collapse.show { 
      .card-body {
        padding-top: 0px;
      }
    }
  `};

  ${(props) => props.ErrorBackground && css`  
    background-color: ${(props) => darken(0.3, props.theme.danger)} !important;
    border: 0 !important;
    border: 1px solid ${(props) => props.theme.borderColor} !important;
    
    .card-header {
      padding: 8px 16px !important;
      font-size: 12px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .collapse, .collapsing {
      background-color: ${(props) => props.theme.level1Card} !important;
    }
    .collapse.show { 
      .card-body {
        padding: 0px 16px;
      }
    }
  `};
`;

export {
  AccordianCard
};