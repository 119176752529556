import React from "react";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import { TokenSaleMobile, TokenSalePc, TokenSaleTablet } from "../../../assets/png";
// import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { H1Tag } from "../../../assets/StyleComponents/FontSize/fonts.style";

function TopBannerTS() {
  const { width } = useWindowSize();
  return (
    <BannerSection>
      <BannerBox
        radius={"5px"}
        display="flex"
        background={`url(${TokenSalePc})`}
        bgLg={`url(${TokenSaleTablet})`}
        bgMd={`url(${TokenSaleMobile})`}
        height="330px"
        heightLg="303px"
        heightMd="112px"
        bgPosition="center"
        bgPositionLg="center"
        bgPositionMd="center"
        bgSize="cover"
        bgSizeLg="cover"
        bgSizeMd="cover"
        position="relative"
      >
        <BannerBox
          width={width >= 767 ? "55%" : "80%"}
          display="flex"
          direction="column"
          alignItems={width < 767 && "center"}
          ml={width >= 767 && "3%"}
          justifyContent="center"
          marginMd={width < 767 && "auto"}
        >
          {/* ++++++++++++++++++++ */}
          <H1Tag
            size="44px"
            weight="500"
            sizeLg="24px"
            sizeMd="16px"
            lineHeight="52px"
            lineHeightLg="43px"
            lineHeightMd="30px"
            textAlign={width < 767 && "center"}
            color="#FFFFFF"
          >
            LCX's Next Big Token Launch
          </H1Tag>

          <BannerBox
            topBorder="0.4px solid white"
            margin="16px auto"
            marginMd="5px auto"
            width="100%"
            mt="15px"
          ></BannerBox>

          <BannerBox mt="0px" mtLg="20px" mtMd="0px">
            <ParaTag
              weight="400"
              size="16px"
              color="white"
              sizeLg="14px"
              weightLg="500"
              sizeMd="10px"
              textAlignMd="center"
            >
              Where Innovation Meets Investment! Secure your chance to invest in new tokens shaping
              the future.
            </ParaTag>
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </BannerSection>
  );
}

export default TopBannerTS;
