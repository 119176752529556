import config from "./config";

let API_URL = config.newAccountsUrl;
let API_URL_V2 = config.newAccountsUrlV2;
let API_URL_V3 = config.newAccountsUrlV3;
let WALLET_URL = config.walletUrl;
let WALLET_URL_V2 = config.walletUrl2;
let WALLET_URL_V3 = config.walletUrl3;
let NOTIFICATION_URL = config.notificationUrl;
let EXCHANGE_URL = config.publicApiUrl;
let EXCHANGE_URL_V2 = config.publicApiUrlV2;
let SOKET_EXCHANGE_URL = config.socketUrl;

const apiConstants = {
  API_URL,
  API_URL_V2,
  API_URL_V3,
  WALLET_URL,
  WALLET_URL_V2,
  WALLET_URL_V3,
  EXCHANGE_URL,
  EXCHANGE_URL_V2,
  NOTIFICATION_URL,
  SOKET_EXCHANGE_URL,

  RESET_REGISTER_AUTH_CODE: "RESET_REGISTER_AUTH_CODE",
  RESET_REGISTER_AUTH_CODE_SUCCESS: "RESET_REGISTER_AUTH_CODE_SUCCESS",

  CHECK_IS_USER_LOGGED_IN: "CHECK_IS_USER_LOGGED_IN",
  CHECK_IS_USER_LOGGED_IN_SUCCESS: "CHECK_IS_USER_LOGGED_IN_SUCCESS",
  CHECK_IS_USER_LOGGED_IN_FAIL: "CHECK_IS_USER_LOGGED_IN_FAIL",
  LOGIN_WITH_EMAIL: "LOGIN_WITH_EMAIL",
  CLEAR_USER_ERRORS: "CLEAR_USER_ERRORS",
  CLEAR_WALLET_ERRORS: "CLEAR_WALLET_ERRORS",

  // FIND COIN
  GET_BASE_COIN_PAIR: "GET_BASE_COIN_PAIR",
  GET_BASE_COIN_PAIR_SUCCESS: "GET_BASE_COIN_PAIR_SUCCESS",
  GET_BASE_COIN_PAIR_FAIL: "GET_BASE_COIN_PAIR_FAIL",

  // FIND COIN
  ALL_LOADING: "ALL_LOADING",
  ALL_LOADING_SUCCESS: "ALL_LOADING_SUCCESS",
  ALL_LOADING_FAIL: "ALL_LOADING_FAIL",

  // Market Top Data
  GET_MARKET_TOP_DATA: "GET_MARKET_TOP_DATA",
  GET_MARKET_TOP_DATA_SUCCESS: "GET_MARKET_TOP_DATA_SUCCESS",
  GET_MARKET_TOP_DATA_FAIL: "GET_MARKET_TOP_DATA_FAIL",

  // Market Data
  GET_MARKET_LISTS: "GET_MARKET_LISTS",
  GET_MARKET_LISTS_SUCCESS: "GET_MARKET_LISTS_SUCCESS",
  GET_MARKET_LISTS_FAIL: "GET_MARKET_LISTS_FAIL",

  // Update Fav pairs Watch list
  UPDATE_FAV_PAIRS_WATCH_LIST: "UPDATE_FAV_PAIRS_WATCH_LIST",

  // Selected Pair on Exchange Id
  SELECTED_PAIR: "SELECTED_PAIR",
  SELECTED_PAIR_SUCCESS: "SELECTED_PAIR_SUCCESS",
  SELECTED_PAIR_FAIL: "SELECTED_PAIR_FAIL",

  // Ask Pair List
  ASK_PAIR_LIST: "ASK_PAIR_LIST",
  ASK_PAIR_LIST_SUCCESS: "ASK_PAIR_LIST_SUCCESS",
  ASK_PAIR_LIST_FAIL: "ASK_PAIR_LIST_FAIL",

  // Ask Pair List
  SET_PAIR_DATA: "SET_PAIR_DATA",
  SET_PAIR_DATA_SUCCESS: "SET_PAIR_DATA_SUCCESS",
  SET_PAIR_DATA_FAIL: "SET_PAIR_DATA_FAIL",

  // Ask Pair List
  SET_SELECTED_PRICE: "SET_SELECTED_PRICE",
  SET_SELECTED_PRICE_SUCCESS: "SET_SELECTED_PRICE_SUCCESS",
  SET_SELECTED_PRICE_FAIL: "SET_SELECTED_PRICE_FAIL",

  // Trade Pair List
  LEADERBOARD_PAIR_LIST: "TRADE_PAIR_LIST",
  LEADERBOARD_PAIR_LIST_SUCCESS: "TRADE_PAIR_LIST_SUCCESS",
  LEADERBOARD_PAIR_LIST_FAIL: "TRADE_PAIR_LIST_FAIL",
  // Trade Pair List
  LEADERBOARD_PRIVATE_PAIR_LIST: "LEADERBOARD_PRIVATE_PAIR_LIST",
  LEADERBOARD_PRIVATE_PAIR_LIST_SUCCESS: "LEADERBOARD_PRIVATE_PAIR_LIST_SUCCESS",
  LEADERBOARD_PRIVATE_PAIR_LIST_FAIL: "LEADERBOARD_PRIVATE_PAIR_LIST_FAIL",

  // Trade Pair List
  TRADE_SETTING: "TRADE_SETTING",
  TRADE_SETTING_SUCCESS: "TRADE_SETTING_SUCCESS",
  TRADE_SETTING_FAIL: "TRADE_SETTING_FAIL",

  // Cancel Trade Pair List
  CANCEL_TRADE_SETTING: "CANCEL_TRADE_SETTING",
  CANCEL_TRADE_SETTING_SUCCESS: "CANCEL_TRADE_SETTING_SUCCESS",
  CANCEL_TRADE_SETTING_FAIL: "CANCEL_TRADE_SETTING_FAIL",

  // Trade Pair List
  RESET_TRADE_SETTING: "RESET_TRADE_SETTING",
  RESET_TRADE_SETTING_SUCCESS: "RESET_TRADE_SETTING_SUCCESS",
  RESET_TRADE_SETTING_FAIL: "RESET_TRADE_SETTING_FAIL",

  // Trade Pair List
  GET_TRADE_PAIR_CONFIG: "GET_TRADE_PAIR_CONFIG",
  GET_TRADE_PAIR_CONFIG_SUCCESS: "GET_TRADE_PAIR_CONFIG_SUCCESS",
  GET_TRADE_PAIR_CONFIG_FAIL: "GET_TRADE_PAIR_CONFIG_FAIL",

  // Trade Pair List
  GET_TRADE_PAIRS_CONFIG: "GET_TRADE_PAIRS_CONFIG",
  GET_TRADE_PAIRS_CONFIG_SUCCESS: "GET_TRADE_PAIRS_CONFIG_SUCCESS",
  GET_TRADE_PAIRS_CONFIG_FAIL: "GET_TRADE_PAIRS_CONFIG_FAIL",

  // Trade Pair List
  GET_RECENT_TRADE_HISTROY: "GET_RECENT_TRADE_HISTROY",
  GET_RECENT_TRADE_HISTROY_SUCCESS: "GET_RECENT_TRADE_HISTROY_SUCCESS",
  GET_RECENT_TRADE_HISTROY_FAIL: "GET_RECENT_TRADE_HISTROY_FAIL",

  // User Trades Open Orders
  GET_OPEN_ORDERS: "GET_RECENT_TRADE_HISTROY",
  GET_OPEN_ORDERS_SUCCESS: "GET_OPEN_ORDERS_SUCCESS",
  GET_OPEN_ORDERS_FAIL: "GET_OPEN_ORDERS_FAIL",

  // Reset Filter Orders
  RESET_FILTER_ORDERS: "RESET_FILTER_ORDERS",
  RESET_FILTER_ORDERS_SUCCESS: "RESET_FILTER_ORDERS_SUCCESS",
  RESET_FILTER_ORDERS_FAIL: "RESET_FILTER_ORDERS_FAIL",

  // User Trades Open Orders
  GET_USER_TRADE_HISTORY: "GET_USER_TRADE_HISTORY",
  GET_USER_TRADE_HISTORY_SUCCESS: "GET_USER_TRADE_HISTORY_SUCCESS",
  GET_USER_TRADE_HISTORY_FAIL: "GET_USER_TRADE_HISTORY_FAIL",

  // Logout User
  LOGOUT_USER: "LOGOUT_USER",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAIL: "LOGOUT_USER_FAIL",

  // GET USER DETAILS
  GET_USER_DETAILS_DATA: "GET_USER_DETAILS_DATA",
  GET_USER_DETAILS_DATA_SUCCESS: "GET_USER_DETAILS_DATA_SUCCESS",
  GET_USER_DETAILS_DATA_FAIL: "GET_USER_DETAILS_DATA_FAIL",

  // GET WALLET BALANCE DATA
  GET_WALLET_BALANCE_DATA: "GET_WALLET_BALANCE_DATA",
  GET_WALLET_BALANCE_DATA_SUCCESS: "GET_WALLET_BALANCE_DATA_SUCCESS",
  GET_WALLET_BALANCE_DATA_FAIL: "GET_WALLET_BALANCE_DATA_FAIL",

  // GET Recent Activity DATA
  GET_RECENT_ACTIVITY_DATA: "GET_RECENT_ACTIVITY_DATA",
  GET_RECENT_ACTIVITY_DATA_SUCCESS: "GET_RECENT_ACTIVITY_DATA_SUCCESS",
  GET_RECENT_ACTIVITY_DATA_FAIL: "GET_RECENT_ACTIVITY_DATA_FAIL",

  // GET Open Order DATA
  GET_OPEN_ORDERS_DATA: "GET_OPEN_ORDERS_DATA",
  GET_OPEN_ORDERS_DATA_SUCCESS: "GET_OPEN_ORDERS_DATA_SUCCESS",
  GET_OPEN_ORDERS_DATA_FAIL: "GET_OPEN_ORDERS_DATA_FAIL",

  // GET Setup 2FA
  SETUP_2FA: "SETUP_2FA",
  SETUP_2FA_SUCCESS: "SETUP_2FA_SUCCESS",
  SETUP_2FA_FAIL: "SETUP_2FA_FAIL",

  // GET Generate Two Auth token
  GENERATE_TWO_AUTH_TOKEN: "GENERATE_TWO_AUTH_TOKEN",
  GENERATE_TWO_AUTH_TOKEN_SUCCESS: "GENERATE_TWO_AUTH_TOKEN_SUCCESS",
  GENERATE_TWO_AUTH_TOKEN_FAIL: "GENERATE_TWO_AUTH_TOKEN_FAIL",

  // GET Change Password
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

  // GET Change Password
  GET_USER_REPORTS: "GET_USER_REPORTS",
  GET_USER_REPORTS_SUCCESS: "GET_USER_REPORTS_SUCCESS",
  GET_USER_REPORTS_FAIL: "GET_USER_REPORTS_FAIL",

  // Create Report Request
  SETUP_REPORT_REQUEST: "SETUP_REPORT_REQUES",
  SETUP_REPORT_REQUEST_SUCCESS: "SETUP_REPORT_REQUEST_SUCCESS",
  SETUP_REPORT_REQUEST_FAIL: "SETUP_REPORT_REQUEST_FAIL",

  // Create Report Request
  CANCEL_REPORT_REQUEST: "SETUP_REPORT_REQUES",
  CANCEL_REPORT_REQUEST_SUCCESS: "SETUP_REPORT_REQUEST_SUCCESS",
  CANCEL_REPORT_REQUEST_FAIL: "SETUP_REPORT_REQUEST_FAIL",

  // Download Report Request
  DOWNLOAD_REPORT_REQUEST: "SETUP_REPORT_REQUES",
  DOWNLOAD_REPORT_REQUEST_SUCCESS: "SETUP_REPORT_REQUEST_SUCCESS",
  DOWNLOAD_REPORT_REQUEST_FAIL: "SETUP_REPORT_REQUEST_FAIL",

  // GET API MANAGEMENT
  GET_KEY_MANAGEMENT: "GET_KEY_MANAGEMENT",
  GET_KEY_MANAGEMENT_SUCCESS: "GET_KEY_MANAGEMENT_SUCCESS",
  GET_KEY_MANAGEMENT_FAIL: "GET_KEY_MANAGEMENT_FAIL",

  // GET API MANAGEMENT
  GET_TRADE_HISTROY: "GET_TRADE_HISTROY",
  GET_TRADE_HISTROY_SUCCESS: "GET_TRADE_HISTROY_SUCCESS",
  GET_TRADE_HISTROY_FAIL: "GET_TRADE_HISTROY_FAIL",

  // GET API MANAGEMENT
  GET_WALLET_BALANCE: "GET_WALLET_BALANCE",
  GET_WALLET_BALANCE_SUCCESS: "GET_WALLET_BALANCE_SUCCESS",
  GET_WALLET_BALANCE_FAIL: "GET_WALLET_BALANCE_FAIL",

  // SET ORDER HISTORY
  SET_ORDER_HISTORY: "SET_ORDER_HISTORY",
  SET_ORDER_HISTORY_SUCCESS: "SET_ORDER_HISTORY_SUCCESS",
  SET_ORDER_HISTORY_FAIL: "SET_ORDER_HISTORY_FAIL",

  // GET ORDER HISTORY
  GET_ORDER_HISTORY: "GET_ORDER_HISTORY",
  GET_ORDER_HISTORY_SUCCESS: "GET_ORDER_HISTORY_SUCCESS",
  GET_ORDER_HISTORY_FAIL: "GET_ORDER_HISTORY_FAIL",

  // GET Market Ticker Data
  GET_MARKET_TICKER_LISTS: "GET_MARKET_TICKER_LISTS",
  GET_MARKET_TICKER_LISTS_SUCCESS: "GET_MARKET_TICKER_LISTS_SUCCESS",
  GET_MARKET_TICKER_LISTS_FAIL: "GET_MARKET_TICKER_LISTS_FAIL",

  // GET FEES LIST
  GET_FEES_LIST: "GET_FEES_LIST",
  GET_FEES_LIST_SUCCESS: "GET_FEES_LIST_SUCCESS",
  GET_FEES_LIST_FAIL: "GET_FEES_LIST_FAIL",

  // GET API  LIST
  GET_API_LIST: "GET_API_LIST",
  GET_API_LIST_SUCCESS: "GET_API_LIST_SUCCESS",
  GET_API_LIST_FAIL: "GET_API_LIST_FAIL",

  // UPDATE FEE lIST
  UPDATE_FEES_LIST: "UPDATE_FEES_LIST",
  UPDATE_FEES_LIST_SUCCESS: "UPDATE_FEES_LIST_SUCCESS",
  UPDATE_FEES_LIST_FAIL: "UPDATE_FEES_LIST_FAIL",

  // WITHDRAW_TOKEN
  WITHDRAW_TOKEN: "WITHDRAW_TOKEN",
  WITHDRAW_TOKEN_SUCCESS: "WITHDRAW_TOKEN_SUCCESS",
  WITHDRAW_TOKEN_FAIL: "WITHDRAW_TOKEN_FAIL",

  //=> Get Accounts Wallet
  GET_ALL_WALLETS: "GET_ALL_WALLETS",
  GET_ALL_WALLETS_SUCCESS: "GET_ALL_WALLETS_SUCCESS",
  GET_ALL_WALLETS_FAIL: "GET_ALL_WALLETS_FAIL",

  //=> Create Wallet
  CREATE_WALLET: "CREATE_WALLET",
  CREATE_WALLET_SUCCESS: "CREATE_WALLET_SUCCESS",
  CREATE_WALLET_FAIL: "CREATE_WALLET_FAIL",

  //=> Get Transaction
  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAIL: "GET_TRANSACTIONS_FAIL",

  //=> Get Transaction
  GET_CONVERSION_RATE: "GET_CONVERSION_RATE",
  GET_CONVERSION_RATE_SUCCESS: "GET_CONVERSION_RATE_SUCCESS",
  GET_CONVERSION_RATE_FAIL: "GET_CONVERSION_RATE_FAIL",

  //=> withdrwal Token 2fa
  WITHDRAW_TOKEN_2FA: "WITHDRAW_TOKEN_2FA",
  WITHDRAW_TOKEN_2FA_SUCCESS: "WITHDRAW_TOKEN_2FA_SUCCESS",
  WITHDRAW_TOKEN_2FA_FAIL: "WITHDRAW_TOKEN_2FA_FAIL",

  UPDATE_SOCKET_BALANCE: "UPDATE_SOCKET_BALANCE",
  UPDATE_SOCKET_BALANCE_SUCCESS: "UPDATE_SOCKET_BALANCE_SUCCESS",
  UPDATE_SOCKET_BALANCE_FAIL: "UPDATE_SOCKET_BALANCE_FAIL",

  UPDATE_SOCKET_ORDERS: "UPDATE_SOCKET_ORDERS",
  UPDATE_SOCKET_ORDERS_SUCCESS: "UPDATE_SOCKET_ORDERS_SUCCESS",
  UPDATE_SOCKET_ORDERS_FAIL: "UPDATE_SOCKET_ORDERS_FAIL",

  //=> GET TIME ZONE
  GET_TIME_ZONE: "GET_TIME_ZONE",
  GET_TIME_ZONE_SUCCESS: "GET_TIME_ZONE_SUCCESS",
  GET_TIME_ZONE_FAIL: "GET_TIME_ZONE_FAIL",

  //=> Fav Trade Pairs List
  FAV_PAIR_LIST: "FAV_PAIR_LIST",
  FAV_PAIR_LIST_SUCCESS: "FAV_PAIR_LIST_SUCCESS",
  FAV_PAIR_LIST_FAIL: "FAV_PAIR_LIST_FAIL",

  // Get trading competition details
  GET_TRADING_COMPETITION: "GET_TRADING_COMPETITION",
  GET_TRADING_COMPETITION_SUCCESS: "GET_TRADING_COMPETITION_SUCCESS",
  GET_TRADING_COMPETITION_FAIL: "GET_TRADING_COMPETITION_FAIL",

  // Get BENEFICIARY ALL Details
  GET_BENEFICIARY_ALL: "GET_BENEFICIARY_ALL",
  GET_BENEFICIARY_ALL_SUCCESS: "GET_BENEFICIARY_ALL_SUCCESS",
  GET_BENEFICIARY_ALL_FAIL: "GET_BENEFICIARY_ALL_FAIL",

  // Get BENEFICIARY ALL Details
  GET_BENEFICIARY_SINGLE: "GET_BENEFICIARY_SINGLE",
  GET_BENEFICIARY_SINGLE_SUCCESS: "GET_BENEFICIARY_SINGLE_SUCCESS",
  GET_BENEFICIARY_SINGLE_FAIL: "GET_BENEFICIARY_SINGLE_FAIL",

  // Add BENEFICIARY ALL Details
  ADD_BENEFICIARY_DATA: "ADD_BENEFICIARY_DATA",
  ADD_BENEFICIARY_DATA_SUCCESS: "ADD_BENEFICIARY_DATA_SUCCESS",
  ADD_BENEFICIARY_DATA_FAIL: "ADD_BENEFICIARY_DATA_FAIL",

  // Get trading competition User List
  GET_CONTEST_USER_LIST: "GET_CONTEST_USER_LIST",
  GET_CONTEST_USER_LIST_SUCCESS: "GET_CONTEST_USER_LIST_SUCCESS",
  GET_CONTEST_USER_LIST_FAIL: "GET_CONTEST_USER_LIST_FAIL",

  // Get watchList Data
  GET_WATTCHLIST_DATA: "GET_WATTCHLIST_DATA",
  GET_WATTCHLIST_DATA_SUCCESS: "GET_WATTCHLIST_DATA_SUCCESS",
  GET_WATTCHLIST_DATA_FAIL: "GET_WATTCHLIST_DATA_FAIL",

  // Get AntiPhising Code
  GET_ANTIPHISING_CODE: "GET_ANTIPHISING_CODE",
  GET_ANTIPHISING_CODE_SUCCESS: "GET_ANTIPHISING_CODE_SUCCESS",
  GET_ANTIPHISING_CODE_FAIL: "GET_ANTIPHISING_CODE_FAIL",

  // Update AntiPhising Code
  UPDATE_ANTIPHISING_CODE: "UPDATE_ANTIPHISING_CODE",
  UPDATE_ANTIPHISING_CODE_SUCCESS: "UPDATE_ANTIPHISING_CODE_SUCCESS",
  UPDATE_ANTIPHISING_CODE_FAIL: "UPDATE_ANTIPHISING_CODE_FAIL",

  CLEAR_ANTIPHISING_API_DATA: "CLEAR_ANTIPHISING_API_DATA",

  // Get Active Code
  GET_ACTIVESESSIONS_CODE: "GET_ACTIVESESSIONS_CODE",
  GET_ACTIVESESSIONS_CODE_SUCCESS: "GET_ACTIVESESSIONS_CODE_SUCCESS",
  GET_ACTIVESESSIONS_CODE_FAIL: "GET_ACTIVESESSIONS_CODE_FAIL",

  // Update Active Code
  UPDATE_ACTIVESESSIONS_CODE: "UPDATE_ACTIVESESSIONS_CODE",
  UPDATE_ACTIVESESSIONS_CODE_SUCCESS: "UPDATE_ACTIVESESSIONS_CODE_SUCCESS",
  UPDATE_ACTIVESESSIONS_CODE_FAIL: "UPDATE_ACTIVESESSIONS_CODE_FAIL",

  // Get ORDER FEES Code
  GET_ORDER_FEES: "GET_ORDER_FEES",
  GET_ORDER_FEES_SUCCESS: "GET_ORDER_FEES_SUCCESS",
  GET_ORDER_FEES_FAIL: "GET_ORDER_FEES_FAIL",

  // Update Ticker Data
  UPDATE_TICKER_DATA: "UPDATE_TICKER_DATA",
  UPDATE_TICKER_DATA_SUCCESS: "UPDATE_TICKER_DATA_SUCCESS",
  UPDATE_TICKER_DATA_FAIL: "UPDATE_TICKER_DATA_FAIL",

  // Get bond investment history
  GET_BOND_INVEST_HISTORY: "GET_BOND_INVEST_HISTORY",
  GET_BOND_INVEST_HISTORY_SUCCESS: "GET_BOND_INVEST_HISTORY_SUCCESS",
  GET_BOND_INVEST_HISTORY_FAIL: "GET_BOND_INVEST_HISTORY_FAIL",

  // Clear errors
  CLEAR_EARN_ERRORS: "CLEAR_EARN_ERRORS",

  // Kyc FAQ content
  GET_KYC_FAQ_CONTENT: "GET_KYC_FAQ_CONTENT",
  GET_KYC_FAQ_CONTENT_SUCCESS: "GET_KYC_FAQ_CONTENT_SUCCESS",
  GET_KYC_FAQ_CONTENT_FAIL: "GET_KYC_FAQ_CONTENT_FAIL",

  // Get Countries List for KYC
  GET_COUNTRIES_LIST: "GET_COUNTRIES_LIST",
  GET_COUNTRIES_LIST_SUCCESS: "GET_COUNTRIES_LIST_SUCCESS",
  GET_COUNTRIES_LIST_FAIL: "GET_COUNTRIES_LIST_FAIL",

  // To clear Authentication error
  CLEAR_AUTH_ERRORS: "CLEAR_AUTH_ERRORS",

  // To clear 2FA errors
  CLEAR_TWO_AUTH: "CLEAR_TWO_AUTH",

  // To clear otp code
  CLEAR_OTP: "CLEAR_OTP",

  // Login user using email and password
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL: "LOGIN_USER_FAIL",

  // Register Email
  REGISTER_EMAIL: "REGISTER_EMAIL",
  REGISTER_EMAIL_SUCCESS: "REGISTER_EMAIL_SUCCESS",
  REGISTER_EMAIL_FAIL: "REGISTER_EMAIL_FAIL",

  NEED_TWO_AUTH_FOR_LOGIN: "NEED_TWO_AUTH_FOR_LOGIN",
  NEED_OTP_FOR_LOGIN: "NEED_OTP_FOR_LOGIN",

  LOGIN_WITH_RECAPTCHA_EMAIL_OTP: "LOGIN_WITH_RECAPTCHA_EMAIL_OTP",

  REDIRECT_BACK: "REDIRECT_BACK",

  TWO_FA_OR_OTP_EXPIRE_REDIRECT: "TWO_FA_OR_OTP_EXPIRE_REDIRECT",
  TWO_FA_AND_LOGIN_WITH_RECAPTCHA_EMAIL_OTP: "TWO_FA_AND_LOGIN_WITH_RECAPTCHA_EMAIL_OTP",

  LOGIN_WITH_TWO_FA: "LOGIN_WITH_TWO_FA",
  LOGIN_WITH_TWO_FA_SUCCESS: "LOGIN_WITH_TWO_FA_SUCCESS",
  LOGIN_WITH_TWO_FA_FAIL: "LOGIN_WITH_TWO_FA_FAIL",

  HANDLE_401: "HANDLE_401",

  SESSION_EXPIRED: "SESSION_EXPIRED",

  REDIRECT_TO_VERIFY_EMAIL: "REDIRECT_TO_VERIFY_EMAIL",
  CLEAR_REDIRECT_TO_VERIFY_EMAIL: "CLEAR_REDIRECT_TO_VERIFY_EMAIL",

  // Site Under Maintenance Status
  SITE_MAINTENANCE: "SITE_MAINTENANCE",
  SITE_MAINTENANCE_SUCCESS: "SITE_MAINTENANCE_SUCCESS",
  SITE_MAINTENANCE_FAIL: "SITE_MAINTENANCE_FAIL",
  SITE_UNDER_MAINTENANCE: "SITE_UNDER_MAINTENANCE",

  // Token Sales
  GET_TOKEN_SALES: "GET_TOKEN_SALES",
  GET_TOKEN_SALES_SUCCESS: "GET_TOKEN_SALES_SUCCESS",
  GET_TOKEN_SALES_FAIL: "GET_TOKEN_SALES_FAIL",

  // All Client Token Sale
  GET_ALL_CLIENT_SALES: "GET_ALL_CLIENT_SALES",
  GET_ALL_CLIENT_SALES_SUCCESS: "GET_ALL_CLIENT_SALES_SUCCESS",
  GET_ALL_CLIENT_SALES_FAIL: "GET_ALL_CLIENT_SALES_FAIL",

  // Verify redirect url
  VERIFY_REDIRECT_URL: "VERIFY_REDIRECT_URL",
  VERIFY_REDIRECT_URL_SUCCESS: "VERIFY_REDIRECT_URL_SUCCESS",
  VERIFY_REDIRECT_URL_FAIL: "VERIFY_REDIRECT_URL_FAIL",

  // Get auth code for different products
  GET_AUTH_CODE: "GET_AUTH_CODE",
  GET_AUTH_CODE_SUCCESS: "GET_AUTH_CODE_SUCCESS",
  GET_AUTH_CODE_FAIL: "GET_AUTH_CODE_FAIL",

  // Change theme
  CHANGE_THEME: "CHANGE_THEME",

  // All changes of new trade page
  UPDATE_BEST_BID: "UPDATE_BEST_BID",
  UPDATE_BEST_ASK: "UPDATE_BEST_ASK",
  UPDATE_SELECTED_PAIR: "UPDATE_SELECTED_PAIR",
  UPDATE_ORDER_FORM_SIDE: "UPDATE_ORDER_FORM_SIDE",
  UPDATE_ORDER_FORM_TYPE: "UPDATE_ORDER_FORM_TYPE",
  UPDATE_ORDER_BOOK: "UPDATE_ORDER_BOOK",
  UPDATE_ALL_BUY_ORDERS: "UPDATE_ALL_BUY_ORDERS",
  UPDATE_ALL_SELL_ORDERS: "UPDATE_ALL_SELL_ORDERS",
  UPDATE_ALL_ASKS_AND_BIDS: "UPDATE_ALL_ASKS_AND_BIDS",
  SET_TRADE_HISTORY_SNAPSHOT: "SET_TRADE_HISTORY_SNAPSHOT",
  SET_TRADE_HISTORY_UPDATE: "SET_TRADE_HISTORY_UPDATE",
  SET_TICKER_SNAPSHOT: "SET_TICKER_SNAPSHOT",
  SET_TICKER_UPDATE: "SET_TICKER_UPDATE",
  UPDATE_SELECTED_CHART_TYPE: "UPDATE_SELECTED_CHART_TYPE",
  UPDATE_ORDER_BOOK_PRICE_PRECISION: "UPDATE_ORDER_BOOK_PRICE_PRECISION",
  UPDATE_FAV_CHART_PAIRS: "UPDATE_FAV_CHART_PAIRS",
  UPDATE_SELECTED_PAIR_BALANCE: "UPDATE_SELECTED_PAIR_BALANCE",
  UPDATE_USER_EXCHANGE_WALLET: "UPDATE_USER_EXCHANGE_WALLET",
  UPDATE_USER_ORDERS: "UPDATE_USER_ORDERS",
  UPDATE_ORDER_FROM_ORDER_BOOK: "UPDATE_ORDER_FROM_ORDER_BOOK",
  UPDATE_USER_FAV_PAIRS: "UPDATE_USER_FAV_PAIRS",
  PERFORM_ACTION_ON_LAYOUT: "PERFORM_ACTION_ON_LAYOUT",
  UPDATE_SHOW_ALL_PAIRS_ORDER: "UPDATE_SHOW_ALL_PAIRS_ORDER",
  UPDATE_SELECTED_PAIR_TICKER: "UPDATE_SELECTED_PAIR_TICKER",
  UPDATE_ORDER_BOOK_CONFIG: "UPDATE_ORDER_BOOK_CONFIG",
  UPDATE_ACTIVE_WALLET: "UPDATE_ACTIVE_WALLET",
  UPDATE_ORDER_BOOK_GRAPH_DATA: "UPDATE_ORDER_BOOK_GRAPH_DATA",
  UPDATE_ORDER_BOOK_ACTIVE_SIDE: "UPDATE_ORDER_BOOK_ACTIVE_SIDE",

  GET_SELECTED_PAIR_CONFIG: "GET_SELECTED_PAIR_CONFIG",
  GET_SELECTED_PAIR_CONFIG_SUCCESS: "GET_SELECTED_PAIR_CONFIG_SUCCESS",
  GET_SELECTED_PAIR_CONFIG_FAIL: "GET_SELECTED_PAIR_CONFIG_FAIL",

  GET_USER_MAIN_WALLET: "GET_USER_MAIN_WALLET",
  GET_USER_MAIN_WALLET_SUCCESS: "GET_USER_MAIN_WALLET_SUCCESS",
  GET_USER_MAIN_WALLET_FAIL: "GET_USER_MAIN_WALLET_FAIL",

  GET_USER_EXCHANGE_WALLET: "GET_USER_EXCHANGE_WALLET",
  GET_USER_EXCHANGE_WALLET_SUCCESS: "GET_USER_EXCHANGE_WALLET_SUCCESS",
  GET_USER_EXCHANGE_WALLET_FAIL: "GET_USER_EXCHANGE_WALLET_FAIL",

  GET_ALL_PAIRS_CATEGORIES: "GET_ALL_PAIRS_CATEGORIES",
  GET_ALL_PAIRS_CATEGORIES_SUCCESS: "GET_ALL_PAIRS_CATEGORIES_SUCCESS",
  GET_ALL_PAIRS_CATEGORIES_FAIL: "GET_ALL_PAIRS_CATEGORIES_FAIL",

  GET_USER_ORDERS: "GET_USER_ORDERS",
  GET_USER_ORDERS_SUCCESS: "GET_USER_ORDERS_SUCCESS",
  GET_USER_ORDERS_FAIL: "GET_USER_ORDERS_FAIL",

  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  UPDATE_USER_DATA_SUCCESS: "UPDATE_USER_DATA_SUCCESS",
  UPDATE_USER_DATA_FAIL: "UPDATE_USER_DATA_FAIL",

  // User Address update
  POST_USER_ADDRESS_UPDATE_REQUEST: "POST_USER_ADDRESS_UPDATE_REQUEST",
  POST_USER_ADDRESS_UPDATE_REQUEST_SUCCESS: "POST_USER_ADDRESS_UPDATE_REQUEST_SUCCESS",
  POST_USER_ADDRESS_UPDATE_REQUEST_FAIL: "POST_USER_ADDRESS_UPDATE_REQUEST_FAIL",
  RESET_USER_ADDRESS_UPDATE_STATE: "RESET_USER_ADDRESS_UPDATE_STATE",

  GET_TOKEN_INFO_DATA: " GET_TOKEN_INFO_DATA",
  GET_TOKEN_INFO_DATA_SUCCESS: "GET_TOKEN_INFO_DATA_SUCCESS",
  GET_TOKEN_INFO_DATA_FAIL: "GET_TOKEN_INFO_DATA_FAIL",

  // PRIVATE REWARDS TASKS LIST
  GET_PRIVATE_ONBOARDING_REWARDS_TASKS_LIST: " GET_PRIVATE_ONBOARDING_REWARDS_TASKS_LIST",
  GET_PRIVATE_ONBOARDING_REWARDS_TASKS_LIST_SUCCESS:
    "GET_PRIVATE_ONBOARDING_REWARDS_TASKS_LIST_SUCCESS",
  GET_PRIVATE_ONBOARDING_REWARDS_TASKS_LIST_FAIL: "GET_PRIVATE_ONBOARDING_REWARDS_TASKS_LIST_FAIL",

  GET_PRIVATE_REGULAR_REWARDS_TASKS_LIST: "GET_PRIVATE_REGULAR_REWARDS_TASKS_LIST",
  GET_PRIVATE_REGULAR_REWARDS_TASKS_LIST_SUCCESS: "GET_PRIVATE_REGULAR_REWARDS_TASKS_LIST_SUCCESS",
  GET_PRIVATE_REGULAR_REWARDS_TASKS_LIST_FAIL: "GET_PRIVATE_REGULAR_REWARDS_TASKS_LIST_FAIL",

  UPDATE_REWARDS_TAB: "UPDATE_REWARDS_TAB",
  UPDATE_REWARDS_TAB_SUCCESS: "UPDATE_REWARDS_TAB_SUCCESS",
  UPDATE_REWARDS_TAB_FAIL: "UPDATE_REWARDS_TAB_FAIL",

  POST_USER_PARTICIPATE_REQUEST: "POST_USER_PARTICIPATE_REQUEST",
  POST_USER_PARTICIPATE_REQUEST_SUCCESS: "POST_USER_PARTICIPATE_REQUEST_SUCCESS",
  POST_USER_PARTICIPATE_REQUEST_FAIL: "POST_USER_PARTICIPATE_REQUEST_FAIL",

  POST_CLAIM_REWARD_REQUEST: "POST_CLAIM_REWARD_REQUEST",
  POST_CLAIM_REWARD_REQUEST_SUCCESS: "POST_CLAIM_REWARD_REQUEST_SUCCESS",
  POST_CLAIM_REWARD_REQUEST_FAIL: "POST_CLAIM_REWARD_REQUEST_FAIL",
  RESET_REWARD_HUB_STATE: "RESET_REWARD_HUB_STATE",

  GET_TOTAL_REWARDS_COUNT: "GET_TOTAL_REWARDS_COUNT",
  GET_TOTAL_REWARDS_COUNT_SUCCESS: "GET_TOTAL_REWARDS_COUNT_SUCCESS",
  GET_TOTAL_REWARDS_COUNT_FAIL: "GET_TOTAL_REWARDS_COUNT_FAIL",

  // Get Campaign Permotion
  GET_PROMOTION_LIST: "GET_PROMOTION_LIST"
};
export default apiConstants;
