import { LoadingButton } from "../../../../components/Loader/index.js";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyWhiteIcon } from "../../../../assets/svgs/index.js";
import { showSuccessMsg } from "../../../../utils/notification.js";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style.js";
import { DivElement } from "../../../../components/Reusable/index.js";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style.js";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style.js";
import { TextWrapper } from "../../../../components/Reusable";

function Step2Modal(props) {
  const { tokenData, loader } = props;

  const selectText = () => {
    showSuccessMsg("Text Copied!");
  };
  return (
    <BannerBox mt="25px">
      {!loader ? (
        <>
          <div>
            <DivElement>
              <TextWrapper fontSize="18px" fontSizeSm="16px" lineHeight="24px" margin="10px 0px">
                {" "}
                API Key{" "}
              </TextWrapper>
            </DivElement>

            <DivElement width="100%" display="flex" alignItems="center">
              <DivElement
                width="75%"
                border="1px solid #ccced0"
                borderRight="0px"
                height="44px"
                display="flex"
                alignItems="center"
              >
                <BannerHeading BodyColor size="16px" pl="10px">
                  {tokenData?.apiKey?.slice(0, 9) +
                    "..." +
                    tokenData?.apiKey?.slice(
                      tokenData?.apiKey.length - 9,
                      tokenData?.apiKey.length
                    ) || "Not Added"}
                </BannerHeading>
              </DivElement>
              <DivElement width="25%">
                <div onClick={() => selectText(tokenData && tokenData.apiKey)}>
                  <CopyToClipboard text={tokenData && tokenData.apiKey}>
                    <ButtonComponent
                      width="100%"
                      btnHeight="44px"
                      display="flex"
                      alignItems="center"
                      PrimaryButton
                      style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0
                      }}
                    >
                      <CopyWhiteIcon marginRight="8px" />
                      Copy
                    </ButtonComponent>
                  </CopyToClipboard>
                </div>
              </DivElement>
            </DivElement>

            <DivElement width="100%" display="flex" alignItems="center" margin="10px 0px">
              <TextWrapper fontSize="18px" fontSizeSm="16px" lineHeight="24px">
                {" "}
                Secret Key{" "}
              </TextWrapper>
            </DivElement>

            <DivElement width="100%" display="flex" alignItems="center">
              <DivElement
                width="75%"
                border="1px solid #ccced0"
                height="44px"
                borderRight="0px"
                display="flex"
                alignItems="center"
              >
                <BannerHeading BodyColor size="16px" pl="10px">
                  {tokenData && tokenData.secretKey
                    ? tokenData.secretKey.slice(0, 9) +
                      "..." +
                      tokenData.secretKey.slice(
                        tokenData.secretKey.length - 9,
                        tokenData.secretKey.length
                      )
                    : "Not Added"}
                </BannerHeading>
              </DivElement>

              <DivElement width="25%">
                <div onClick={() => selectText(tokenData && tokenData.secretKey)}>
                  <CopyToClipboard text={tokenData && tokenData.secretKey}>
                    <ButtonComponent
                      width="100%"
                      btnHeight="44px"
                      display="flex"
                      alignItems="center"
                      PrimaryButton
                      style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0
                      }}
                    >
                      <CopyWhiteIcon marginRight="8px" />
                      Copy
                    </ButtonComponent>
                  </CopyToClipboard>
                </div>
              </DivElement>
            </DivElement>
          </div>
        </>
      ) : (
        <BannerBox display="flex" justifyContent="center" width="100%">
          <LoadingButton color="#047bf8" />
        </BannerBox>
      )}
    </BannerBox>
  );
}

export default Step2Modal;
