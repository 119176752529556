import React from "react";
import { DivElement, ImgElement, TextWrapper } from "../../../../../components/Reusable";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import { compose } from "redux";
import { connect } from "react-redux";
// import { ArrowRightBlueIcon } from "../../../../../assets/svgs";

const ImgUrl = "https://lcx-exchange.s3.amazonaws.com/others/lcx_earn_blog_desktop.jpg";

const ArticleContainer = () => {
  const windowDimension = useWindowSize();
  return (
    <DivElement display="flex">
      {windowDimension.width >= 768 && (
        <ImgElement
          width="200px"
          widthMd="250px"
          height="125.5px"
          heightMd="156.5px"
          src={ImgUrl}
          alt="poster"
        />
      )}
      <DivElement
        dColCenter
        alignItemsSm="center"
        width="100%"
        paddingLeft="24px"
        paddingLeftSm="0"
      >
        <TextWrapper fontSize="16px" primary textAlignSm="center">
          “LCX Launches Groundbreaking Tokenized Bonds: A Revolution in Compliant Staking”
        </TextWrapper>
        {/* <a
          href="https://www.bloomberg.com/press-releases/2023-03-29/lcx-launches-groundbreaking-tokenized-bonds-a-revolution-in-compliant-staking"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DivElement display="inline-flex" alignItems="center">
            <TextWrapper blue underline>
              Read article at Bloomberg
            </TextWrapper>
            <ArrowRightBlueIcon marginLeft="12px" />
          </DivElement>
        </a> */}
      </DivElement>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(ArticleContainer);
