import React, { useEffect, useMemo, useState } from "react";
import { withRouter, Link, useLocation } from "react-router-dom";
import AssetsList from "./AssetsList";
// import { AuthHeader, NonAuthHeader } from "../../components/Header";
import IndexFooter from "../../components/Footer/footer";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { trackPage } from "../../tracking/track";
import { useDispatch, useSelector } from "react-redux";
import { checkPairName } from "../../utils/trade";
import { BannerBox, MainElement, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { RouterLink } from "../../components/Reusable";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import { H1Tag } from "../../assets/StyleComponents/FontSize/fonts.style";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const DigitalAssetsComponent = (props) => {
  let location = useLocation();
  // const history = useHistory();
  let dispatch = useDispatch();

  const { isLoggedIn, userData } = props;
  const isTheme = useSelector((state) => state.theme.isTheme);
  const { transalationList } = useSelector(GenericReducer);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchAvailableLanguagesRequest());
      // let checkPath = location.pathname?.split("/")[1];
      // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
      //   history.push(`/${userData.preferredLanguage}${location.pathname}`);
      // }
    }
    let page = location.pathname + location.search;
    trackPage(page);
  }, []);

  let selectedPairName = useSelector((state) => state.trade.selectedPair);

  const [selectedPairActive, setSelectedPairActive] = useState("");

  useEffect(() => {
    if (location.pathname.includes("/trade") && location.pathname !== "/order/trade-history") {
      let pairActive =
        checkPairName(selectedPairName || { base_pair: "LCX", quote_pair: "EUR" }, location) ||
        "LCX/EUR";
      setSelectedPairActive("/" + pairActive.split("/").join("-"));
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SEOContents = {
    name: `DIGITAL ASSETS`,
    link: `assets`,
    title: "Supported Cryptocurrencies & Tokens at LCX: Explore Our Platform",
    description:
      "Discover the wide range of cryptocurrencies and tokens supported at LCX. Explore our platform for secure trading and account management. Start trading today!",
    keywords: `Cryptocurrencies & Tokens, cryptocurrency coin and token `,
    canonical: `https://exchange.lcx.com/assets`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}/assets`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: "Supported Cryptocurrencies & Tokens at LCX: Explore Our Platform",
    OgDescription:
      "Discover the wide range of cryptocurrencies and tokens supported at LCX. Explore our platform for secure trading and account management. Start trading today!",
    OgURL: `https://www.exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/assets`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Supported Cryptocurrencies & Tokens at LCX: Explore Our Platform",
    TwDescription:
      "Discover the wide range of cryptocurrencies and tokens supported at LCX. Explore our platform for secure trading and account management. Start trading today!"
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/assets`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <BannerBox>
        {/* Main Part */}
        <MainElement>
          <BannerSection TutorialBg paddBottom="5.5rem" paddTop="4rem">
            <BannerBox ExchangeContainer>
              <BannerBox display="flex" direction="column">
                <BannerBox textAlign="center" flexLg="1">
                  <H1Tag BodyColor weight="bold" sizeMd="36px" size="2.75rem">
                    {" "}
                    Digital Assets{" "}
                  </H1Tag>
                </BannerBox>

                <BannerBox mt="24px" textAlign="center" widthMd="95%">
                  <BannerHeading size="1.5rem" BodyColor sizeMd="18px" weight="300">
                    {" "}
                    Cryptocurrencies and Cryptoassets available at LCX.com{" "}
                  </BannerHeading>
                </BannerBox>
              </BannerBox>

              <BannerBox
                position="absolute"
                mt="45px"
                mtLg="55px"
                width="100%"
                textAlign="center"
                left="0%"
              >
                {!isLoggedIn ? (
                  <RouterLink paddingTop="2rem" paddingBottom="2rem" to={"/register"}>
                    <ButtonComponent
                      SuccessButton
                      type="button"
                      width="477px"
                      widthMd="312px"
                      btnHeight="72px"
                      btnHeightMd="48px"
                      btnHeightLg="48px"
                      sizeLg="16"
                      sizeMd="16"
                      className="btn btn-success bck-success f-rem-2-1"
                    >
                      Start trading in LCX Exchange
                    </ButtonComponent>
                  </RouterLink>
                ) : (
                  Object.keys(userData).length > 0 && (
                    <Link to={`${prevLang ? `/${prevLang}`: "/en"}/trade${selectedPairActive}`} paddingTop="2rem" paddingBottom="2rem">
                      <ButtonComponent
                        SuccessButton
                        type="button"
                        width="477px"
                        widthMd="312px"
                        btnHeight="72px"
                        btnHeightMd="48px"
                        btnHeightLg="48px"
                        sizeLg="16"
                        sizeMd="16"
                        className="btn btn-success bck-success f-rem-2-1"
                      >
                        Start trading in LCX Exchange
                      </ButtonComponent>
                    </Link>
                  )
                )}
              </BannerBox>
            </BannerBox>
          </BannerSection>

          <BannerSection BeginerBg paddTop="6rem" paddBottom="4rem" paddingLg="48px 0px">
            <BannerBox ExchangeContainer paddBottom="3rem">
              <BannerBox
                widthLg="100%"
                display="flex"
                direction="column"
                justifyContent="center"
                position="relative"
              >
                <BannerBox
                  display="flex"
                  direction="column"
                  justifyContent="center"
                  position="realtive"
                  width="100%"
                >
                  <ParaTag
                    txtGrey
                    weight="300"
                    textAlign="center"
                    size="1.25rem"
                    sizeMd="1rem"
                    padding="0px 8%"
                  >
                    LCX provides varying levels of support for different cryptocurrencies. Below are
                    the cryptocurrencies we support on our platform, such as at LCX Accounts and LCX
                    Exchange.
                  </ParaTag>

                  {!isLoggedIn && (
                    <RouterLink textDecoration="none" BodyColor padding="0px auto" to={"/register"}>
                      <ParaTag
                        weight="bold"
                        textAlign="center"
                        mt="16px"
                        size="1.25rem"
                        sizeMd="1.1rem"
                      >
                        {" "}
                        New to LCX?{" "}
                        <u style={{ color: !isTheme ? "#0470e1" : "#70A6E0", marginLeft: "5px" }}>
                          {" "}
                          Create an Account Here{" "}
                        </u>{" "}
                      </ParaTag>
                    </RouterLink>
                  )}
                </BannerBox>
              </BannerBox>

              <BannerBox pt="3.5rem" pb="3rem" pbLg="2rem">
                <AssetsList />
              </BannerBox>
            </BannerBox>
          </BannerSection>
          {/* <hr style={{ margin: "0px auto" }} /> */}
        </MainElement>

        {/* Footer Index */}
        <IndexFooter {...props} />
      </BannerBox>
    </>
  );
};

export default withRouter(DigitalAssetsComponent);
