import React from "react";
import { DivElement, LinkButton, ModalWrapper, TextWrapper } from "../Reusable";
import { CloseIcon } from "../../assets/svgs";
import { GetKycVerifiedIllustration } from "../../assets/svgs/illustrations";

const AddBeneficiary = ({ onClose }) => {
  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <DivElement displayBtw>
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
            Add Beneficiary Account
          </TextWrapper>
          <CloseIcon pointer onClick={onClose} />
        </DivElement>

        <DivElement displayCenter padding="32px 0">
          <GetKycVerifiedIllustration />
        </DivElement>

        <TextWrapper textAlign="center" fontSize="14px" fontWeight="400" lineHeight="20px">
          Please add the beneficiary account
        </TextWrapper>

        <LinkButton
          primaryBlue
          width="100%"
          height="48px"
          to="/wallet/add_accounts"
          marginTop="24px"
          onClick={onClose}
        >
          Add Beneficiary
        </LinkButton>
      </DivElement>
    </ModalWrapper>
  );
};

export default AddBeneficiary;
