import { compose } from "redux";
import { DivElement, DropdownWrapper, RouterLink, TextWrapper } from "../../Reusable";
import { connect } from "react-redux";
import { Fragment } from "react";

import { getAuthCode } from "../../../lib/api";
import { showErrorMsg } from "../../../utils/notification";
import { config } from "../../../constants";
import { BlueNewMoreNavbarTag, ExpandMoreIcon, PromotionIcon } from "../../../assets/svgs";
import CategoryContainer from "./CategoryContainer";
import NAV_DATA from "./navData";
// import { MorePulseMenu } from "../../../assets/svgs";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import PulseAnimation from "../../Reusable/Animation/Pulse";
import { FlexCard } from "../../../assets/StyleComponents/FlexDiv.style";

const Label = () => {
  return (
    <Fragment>
      <BannerBox position="relative" display="flex">
        <TextWrapper secondary>More</TextWrapper>
        {/* <MorePulseMenu position="absolute" width="8px" height="8px" PulseAnimation marginLeft="8px"/> */}
        <PulseAnimation />
        <ExpandMoreIcon marginLeft="5px" />
      </BannerBox>
    </Fragment>
  );
};

const MoreDropdownDesktop = ({ isLoggedIn, authState }) => {
  const prevLang = localStorage.getItem("ljs-lang");

  const onClickHandler = (e, data) => {
    if (data?.isOpenInOtherTab) {
      e.preventDefault();
      if (isLoggedIn && data.title === "Tiamonds") {
        let productDomain = "tiamonds";
        getAuthCode(productDomain)
          .then((res) => {
            let code = res && res.data ? res.data.data.authCode : "";
            if (!code) throw new Error("");

            window.open(`${config.tiamondsUrl}/?token=${code}&state=${authState}`, "_self");
          })
          .catch((err) => {
            showErrorMsg(`Error in generating ${productDomain} auth code.`);
          });
      } else {
        window.open(data.path, "_blank");
      }
    } else if (data.noShowinLogin) {
      if (!isLoggedIn && data.title === "Reports") {
        window.open("https://exchange.lcx.com/en/login");
      }
    }
  };

  return (
    <DropdownWrapper label={<Label />} alignLeft="24px">
      <DivElement width="536px" padding="0 16px" displayBtw flexWrap="wrap">
        {NAV_DATA.map((data) => (
          <CategoryContainer key={data.type} data={data} onClickHandler={onClickHandler} />
        ))}

        <FlexCard pb="24px" width="100%" mb="16px"
          LightPrimaryBackground padding="8px 12px" borderRadius="5px" border
        >
          <RouterLink to={`/${prevLang}/promotions`}>
            <DivElement flex justifyContent="space-between" alignItem="flex-start">
              <DivElement width="100%" height="38px" dAlignCenter>
                <DivElement marginRight="12px">
                  <PromotionIcon />
                </DivElement>

                <DivElement height="100%">
                  <TextWrapper 
                    fontSize="14px" lineHeight="20px"
                  >
                    Announcement Centre {" "}
                    <BlueNewMoreNavbarTag
                      color="white"
                      width="33px"
                      height="20px"
                      marginLeft="8px"
                    />
                  </TextWrapper>
                  <TextWrapper
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="18px"
                  >
                    LCX is the top exchange for trading for more check the link below
                  </TextWrapper>
                </DivElement>
              </DivElement>
              {/* {nav?.tag && <Tag tag={nav.tag} />} */}
            </DivElement>
          </RouterLink>
        </FlexCard>
      </DivElement>

    </DropdownWrapper>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  authState: state.auth.authState
});

export default compose(connect(mapStateToProps))(MoreDropdownDesktop);
