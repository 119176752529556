import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import {
  getSelectedPairConfig,
  setTickerSnapshot,
  setTickerUpdate,
  setTradeHistorySnapshot,
  setTradeHistoryUpdate,
  updateAllBuyOrders,
  updateAllSellOrders,
  updateOrderBook
} from "../../actions/trading";
import { useDispatch, useSelector } from "react-redux";
import { customDecode } from "../../utils/envScript/envCrypto";

const socketUrl = customDecode(process.env.REACT_APP_SOCKET_URL);

const TOPICS = {
  SNAPSHOT: "snapshot",
  UPDATE: "update"
};

const EVENTS = {
  ORDERBOOK: "orderbook",
  TRADE: "trade",
  TICKER: "ticker"
};

const usePublicWebSocket = () => {
  const [currSelectedPair, setCurrSelectedPair] = useState("");
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => true,
    heartbeat: {
      message: JSON.stringify({ Topic: "ping" }),
      interval: 25000
    }
  });
  const dispatch = useDispatch();
  const selectedPair = useSelector((state) => state.trading.selectedPair);

  // If Web Socket connection is successfull, it will subscribe to all events
  useEffect(() => {
    if (readyState === ReadyState.OPEN) subscribleAllEvents();
  }, [readyState]);

  useEffect(() => {
    if (selectedPair && readyState === ReadyState.OPEN) {
      let prevPair = currSelectedPair;
      if (prevPair !== selectedPair.symbol) {
        unsubscribeToEventsWithPair(prevPair);
        subscribeToEventsWithPair();
      }
    }
  }, [selectedPair]);

  useEffect(() => {
    if (!lastJsonMessage) return;

    updateOrderBookData();
    updateTradeHistoryData();
    updateTickersData();
  }, [lastJsonMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated"
  }[readyState];

  const subscribleAllEvents = () => {
    subscribeToEventsWithPair();
    sendJsonMessage({ Topic: "subscribe", Type: EVENTS.TICKER });
  };

  const subscribeToEventsWithPair = () => {
    if (!selectedPair) return;
    setCurrSelectedPair(selectedPair.symbol);
    sendJsonMessage({ Topic: "subscribe", Type: EVENTS.TRADE, Pair: selectedPair.symbol });
    sendJsonMessage({ Topic: "subscribe", Type: EVENTS.ORDERBOOK, Pair: selectedPair.symbol });

    // API call to get the pair config
    dispatch(getSelectedPairConfig({ pair: selectedPair.symbol }));
  };

  const unsubscribeToEventsWithPair = (pair) => {
    sendJsonMessage({ Topic: "unsubscribe", Type: EVENTS.TRADE, Pair: pair });
    sendJsonMessage({ Topic: "unsubscribe", Type: EVENTS.ORDERBOOK, Pair: pair });
  };

  const updateOrderBookData = () => {
    if (lastJsonMessage?.type === EVENTS.ORDERBOOK && lastJsonMessage?.pair === currSelectedPair) {
      if (lastJsonMessage?.topic === TOPICS.SNAPSHOT) {
        dispatch(updateOrderBook(lastJsonMessage.data));
      } else {
        let orderData = lastJsonMessage?.data;
        let orderSide = orderData[2];

        if (orderSide === "BUY") {
          dispatch(updateAllBuyOrders(orderData));
        } else {
          dispatch(updateAllSellOrders(orderData));
        }
      }
    }
  };

  const updateTradeHistoryData = () => {
    if (lastJsonMessage?.type === EVENTS.TRADE && lastJsonMessage?.pair === currSelectedPair) {
      if (lastJsonMessage.topic === TOPICS.SNAPSHOT) {
        dispatch(setTradeHistorySnapshot(lastJsonMessage.data));
      } else {
        dispatch(setTradeHistoryUpdate(lastJsonMessage.data));
      }
    }
  };

  const updateTickersData = () => {
    if (lastJsonMessage?.type === EVENTS.TICKER) {
      if (lastJsonMessage.topic === TOPICS.SNAPSHOT) {
        dispatch(setTickerSnapshot(lastJsonMessage.data));
      } else {
        let data = {
          pairData: lastJsonMessage.data,
          pair: lastJsonMessage.pair
        };
        dispatch(setTickerUpdate(data));
      }
    }
  };

  return { sendJsonMessage, connectionStatus, readyState };
};

export default usePublicWebSocket;
