/* eslint-disable react/no-unknown-property */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { H1Tag } from "../../../assets/StyleComponents/FontSize/fonts.style";

function Banner(props) {
  const { width } = useWindowSize();
  const { title, subTitle, description, videoSrc } = props;
  return (
    <BannerSection>
      <BannerBox
        radius={"5px"}
        display="flex"
        bg="#151E26"
        padding={"44px 32px 59px"}
        paddingLg={"24px 20px 48px"}
        paddMd={"16px 16px 21px 16px"}
        gap="2%"
        position="relative"
      >
        <BannerBox width={width > 1024 ? "50%" : "100%"}>
          <H1Tag
            size="33px"
            weight="500"
            color="#DEDFE0"
            margin="12px 0px"
            marginMd="8px 0px"
            sizeLg="24px"
            sizeMd="20px"
            lineHeight="45px"
            lineHeightLg="32px"
            textAlign={width < 767 && "center"}
          >
            {title}
          </H1Tag>

          <BannerHeading
            size="20px"
            weight="500"
            color="#DEDFE0"
            margin="12px 0px"
            marginMd="8px 0px"
            sizeLg="18px"
            sizeMd="16px"
            textAlign={width < 767 && "center"}
          >
            {subTitle}
          </BannerHeading>

          <hr style={{ border: "0.5px solid #38393D" }} />

          <ParaTag
            txtGrey
            size="16px"
            weight="300"
            mt="8px"
            color="#DEDFE0"
            sizeLg="14px"
            sizeMd="12px"
            textAlign={width < 767 && "center"}
            width={width > 767 ? "98%" : "100%"}
          >
            {description}
          </ParaTag>
        </BannerBox>
        {width > 1024 && (
          <BannerBox width="50%" TradingCompVideoWrapper>
            <video id="vid" loop autoPlay muted src={videoSrc}></video>
          </BannerBox>
        )}
      </BannerBox>
    </BannerSection>
  );
}

export default Banner;
