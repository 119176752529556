import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";
import { getTokenInfoData } from "../../../../../actions/wallet";
import CopyToClipboard from "react-copy-to-clipboard";
import { showSuccessMsg } from "../../../../../utils/notification";
import { CopyBlueIcon } from "../../../../../assets/svgs";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";

const DropdownContent = styled(DivElement)`
  width: 280px;
  min-height: 200px;
  position: absolute;
  top: calc(100% + 10px);
  z-index: 100;
  border-radius: 5px;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  padding: 12px;

  @media (max-width: 767px) {
    width: 273px;
  }

  &::before {
    width: 16px;
    height: 16px;
    content: "";
    position: absolute;
    top: -8px;
    left: 18px;
    transform: rotate(45deg);
    border: solid ${(props) => props.theme.borderColor};
    border-width: 1px 0 0 1px;
    background-color: inherit;
    z-index: -1;
  }
`;

const TokenInfoComponent = () => {
  const ref = useRef();
  const [showTokenInfo, setShowTokenInfo] = useState(false);
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const { tokenInfoData, isTokenInfoLoading } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const width = useWindowSize();

  const isMobile = width <= 767 ? true : false;

  const onCopy = () => {
    showSuccessMsg(`Successfully copied`);
  };

  useEffect(() => {
    dispatch(getTokenInfoData({ base: selectedPair?.base || "BTC" }));
  }, [selectedPair, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowTokenInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const onClickLink = (link) => {
    window.open(link);
  };

  const dropDownComponent = () => {
    if (!tokenInfoData) {
      return (
        <DropdownContent bgCard bdGrey>
          <DivElement marginTop="70px" flex alignItems="center" justifyContent="center">
            <TextWrapper fontSize="14px" fontWeight="400" primary>
              No Data Found
            </TextWrapper>
          </DivElement>
        </DropdownContent>
      );
    }
    return (
      <DropdownContent bgCard bdGrey>
        <DivElement marginBottom="16px">
          <TextWrapper fontSize="16px" fontWeight="400" primary>
            {`${tokenInfoData?.name} (${tokenInfoData?.symbol})`}
          </TextWrapper>
        </DivElement>

        {tokenInfoData?.totalTokenSupply && (
          <BannerBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            paddingBottom="12px"
          >
            <DivElement>
              <TextWrapper fontSize="10px" fontWeight="400" lineHeight="18px" secondary>
                Total Supply
              </TextWrapper>
            </DivElement>
            <DivElement>
              <TextWrapper fontSize="10px" fontWeight="400" lineHeight="18px">
                {/* {formatAmount(tokenInfoData?.totalTokenSupply)} */}
                {tokenInfoData?.totalTokenSupply}
              </TextWrapper>
            </DivElement>
          </BannerBox>
        )}

        {tokenInfoData?.whitepaperUrl && (
          <BannerBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            paddingBottom="12px"
          >
            <DivElement>
              <TextWrapper fontSize="10px" fontWeight="400" lineHeight="18px" secondary>
                Whitepaper
              </TextWrapper>
            </DivElement>
            <DivElement flex alignItems="center">
              <TextWrapper
                fontSize="12px"
                fontWeight="400"
                underline
                blue
                maxWidth="130px"
                textWrap="wrap"
                textAlign="right"
                onClick={() => onClickLink(tokenInfoData?.whitepaperUrl)}
                textOverflow="hidden"
                cursor
              >
                {tokenInfoData?.whitepaperUrl?.length > 15
                  ? tokenInfoData?.whitepaperUrl?.slice(0, 5) +
                    "..." +
                    tokenInfoData?.whitepaperUrl?.slice(
                      tokenInfoData?.whitepaperUrl?.length - 10,
                      tokenInfoData?.whitepaperUrl?.length
                    )
                  : tokenInfoData?.whitepaperUrl}
              </TextWrapper>
              <DivElement cursor>
                <CopyToClipboard text={tokenInfoData?.whitepaperUrl} onCopy={onCopy}>
                  <CopyBlueIcon width="14px" height="14px" marginLeft="8px" />
                </CopyToClipboard>
              </DivElement>
            </DivElement>
          </BannerBox>
        )}

        {tokenInfoData?.legalInformation && (
          <BannerBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <DivElement>
              <TextWrapper fontSize="10px" fontWeight="400" lineHeight="18px" secondary>
                Legal Information
              </TextWrapper>
            </DivElement>
            <DivElement flex alignItems="center">
              <TextWrapper
                fontSize="12px"
                fontWeight="400"
                underline
                blue
                maxWidth="130px"
                textWrap="wrap"
                textAlign="right"
                onClick={() => onClickLink(tokenInfoData?.legalInformation)}
                textOverflow="hidden"
                cursor
              >
                {tokenInfoData?.legalInformation?.length > 10
                  ? tokenInfoData?.legalInformation?.slice(0, 5) +
                    "..." +
                    tokenInfoData?.legalInformation?.slice(
                      tokenInfoData?.legalInformation?.length - 10,
                      tokenInfoData?.legalInformation.length
                    )
                  : tokenInfoData?.legalInformation}
              </TextWrapper>
              <DivElement cursor>
                <CopyToClipboard text={tokenInfoData?.legalInformation} onCopy={onCopy}>
                  <CopyBlueIcon width="14px" height="14px" marginLeft="8px" cursor />
                </CopyToClipboard>
              </DivElement>
            </DivElement>
          </BannerBox>
        )}

        {tokenInfoData?.description && (
          <DivElement marginTop="10px">
            <TextWrapper fontSize="10px" fontWeight="400" primary lineHeight="18px">
              {tokenInfoData?.description}
            </TextWrapper>
          </DivElement>
        )}
      </DropdownContent>
    );
  };

  return (
    <DivElement
      flex
      alignItems="flex-end"
      marginTop="2px"
      height="10px"
      relative
      width="100%"
      ref={ref}
    >
      {isMobile ? (
        <span
          style={{ cursor: "pointer" }}
          ref={ref}
          onClick={() => setShowTokenInfo(!showTokenInfo)}
        >
          <TextWrapper
            fontSize="12px"
            fontWeight="500"
            dashed
            secondary
            display="inline-flex"
            borderBottom="1px dashed #a7a9ab"
            highlight
          >
            <var>{selectedPair?.base}</var>
          </TextWrapper>
        </span>
      ) : (
        <span
          onMouseEnter={() => {
            setShowTokenInfo(true);
          }}
          style={{ cursor: "pointer" }}
          ref={ref}
          onClick={() => setShowTokenInfo(!showTokenInfo)}
        >
          <TextWrapper
            fontSize="12px"
            fontWeight="500"
            dashed
            secondary
            display="inline-flex"
            borderBottom="1px dashed #a7a9ab"
            highlight
          >
            <var>{selectedPair?.base}</var>
          </TextWrapper>
        </span>
      )}

      {showTokenInfo && !isTokenInfoLoading && dropDownComponent()}
    </DivElement>
  );
};

export default TokenInfoComponent;
