import React, { useEffect, useState } from 'react';

import SectionConatiner from '../../components/Reusable/SectionConatiner/SectionConatiner';
// import { ParaTag } from '../../assets/StyleComponents/FontSize/para.style';
import { HeadingSecond } from '../../assets/StyleComponents/FontSize/headsecond.style';
import { TabsWithoutLink } from '../../assets/StyleComponents/Reusable/Tabs.style';
import { FlexCard } from '../../assets/StyleComponents/FlexDiv.style';
import { useDispatch, useSelector } from 'react-redux';
import { getPromotionList } from '../../actions/LandingPage/LandingPage';
import SinglePromitionsList from './SinglePromitionsList';
import { HalfFooterCommon } from "../../components/Footer/common";
import MainTagContainer from '../../components/Reusable/MainTag/MainTag';
import { useWindowSize } from '../../Hooks/CheckWidthHeight';

const mapStateToProps = (state) => ({
  promotionOngoingList: state.promotion.promotionOngoingList, 
  promotionCompletedList: state.promotion.promotionCompletedList
});

const PromotionsPage = () => {
  const { width } = useWindowSize();
  const [activeTab, setActiveTab] = useState("ongoing");
  const dispatch = useDispatch();
  let { promotionOngoingList, promotionCompletedList } = useSelector(mapStateToProps);

  useEffect(() => {
    dispatch(getPromotionList());
  }, []);

  return (
    <MainTagContainer flexJustifyBetween 
      extraStyle={{ 
        flexJustifyBetween: true,
        direction: "column",
        minHeight: width > 1024 ? "100%": "768px"
      }}
    >
      <SectionConatiner 
        extraStyle={{
          mt: "64px",
          my: "48px",
          padding: "40px",
          paddingMd: "24px 16px",
          borderRadius: "5px",
          minHeight: width > 1024 ? `767px` : `560px`
        }}
        cardStyle="MainCardLevel1"
      >
        <HeadingSecond sizeMd="32px"> Announcement Centre </HeadingSecond> 

        <FlexCard borderBottom GreyBorderColor>
          <TabsWithoutLink
            active={activeTab === "ongoing"}
            onClick={() => setActiveTab("ongoing")}
          >
            Ongoing
          </TabsWithoutLink>
          <TabsWithoutLink
            active={activeTab === "completed"}
            onClick={() => setActiveTab("completed")}
          >
            Completed
          </TabsWithoutLink>
        </FlexCard>

        <SinglePromitionsList list={activeTab === "ongoing" ? promotionOngoingList : promotionCompletedList } />
      </SectionConatiner>

      <HalfFooterCommon />
    </MainTagContainer>
  );
};

export default PromotionsPage;