import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled, { css } from "styled-components";
import { MarginStyle } from "../../assets/StyleComponents/Reusable/Margin.style";
import { PaddingStyle } from "../../assets/StyleComponents/Reusable/Padding.style";

const SkeletonWrapper = styled(Skeleton)`
  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `};
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction};
    `};
  ${MarginStyle}
  ${PaddingStyle}
`;

const SkeletonLoader = (props) => {
  let { count, width, givenClass, height, circle, isTheme, style } = props;
  return (
    <SkeletonTheme
      baseColor={isTheme ? "#263645" : "#EFEFEF"}
      highlightColor={isTheme ? "#406180" : "#e5e5e5"}
    >
      <SkeletonWrapper
        className={givenClass ? givenClass : ""}
        style={style}
        circle={circle}
        count={count}
        // duration={2}
        width={width}
        height={height}
        {...props}
      />
    </SkeletonTheme>
  );
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  isThemeFecthing: state.theme.isThemeFetching
});

// const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, null)(withRouter(SkeletonLoader));
