import styled, { css } from "styled-components";

const Container = styled.div`
  width: ${(props) => props.width || "24px"};
  height: ${(props) => props.height || "24px"};
  padding: ${(props) => props.padding || "3px"};
  font-size: ${(props) => props.fontSize || "22px"};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CheckMark = styled.div`
  position: absolute;
  width: ${(props) => props.width || "18px"};
  height: ${(props) => props.height || "18px"};
  border-radius: ${(props) => props.borderRadius || "3px"};
  border: solid ${(props) => props.theme.primaryIcon};
  border-width: ${(props) => props.borderWidth || "2px"};

  &::after {
    content: "";
    position: absolute;
    display: none;
    top: 1px;
    left: ${(props) => (props.width ? `calc(${props.width}*0.28)` : "5px")};
    width: ${(props) => (props.width ? `calc(${props.width}*0.28)` : "5px")};
    height: ${(props) => (props.height ? `calc(${props.height}*0.56)` : "10px")};
    border-width: ${(props) =>
      props.borderWidthMark ? props.borderWidthMark : "0 3px 3px 0 !important"};
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border: solid ${(props) => props.theme.primaryBlue};
  }

  ${(props) =>
    props.checked &&
    css`
      border-color: ${(props) => props.theme.primaryBlue};
      background-color: transparent;
      ::after {
        display: block;
      }
    `}
`;

const Checkbox = ({ checked, setChecked, containerProps, checkMarkProps }) => {
  const onChangeHandler = () => {
    if (setChecked) {
      setChecked(!checked);
    }
  };

  return (
    <Container {...containerProps}>
      <CheckMark {...checkMarkProps} checked={checked} onClick={onChangeHandler} />
    </Container>
  );
};

export default Checkbox;
