import { PendingCircleIllustration } from "../../../assets/svgs/illustrations";
import DivElement from "../DivElement";
import TextWrapper from "../TextWrapper";

const LineStatusBar = ({ step, status = "success", isLast = false }) => {
  return (
    <DivElement
      flexAlignCenter
      flexColumn
      marginRight={status === "pending" ? "10px" : "12px"}
      marginRightSm={status === "pending" ? "8px" : "10px"}
    >
      {status === "pending" ? (
        <DivElement width="28px" height="28px" borderRadius="50%" displayCenter>
          <PendingCircleIllustration width="inherit" height="inherit" />
          <TextWrapper position="absolute" orange>
            {step}
          </TextWrapper>
        </DivElement>
      ) : (
        <DivElement
          bgGreen={status === "failed" ? null : true}
          bgRed={status === "failed" ? true : null}
          width="24px"
          height="24px"
          borderRadius="50%"
          displayCenter
        >
          <TextWrapper fontWhite fontSize="16px" fontWeight="400">
            {step}
          </TextWrapper>
        </DivElement>
      )}

      {!isLast && (
        <DivElement bdGrey borderLeft="1px dashed" height="calc(100% - 24px)"></DivElement>
      )}
    </DivElement>
  );
};

export default LineStatusBar;
