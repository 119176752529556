import React from "react";
import styled from "styled-components";
import { RewardsHubSocialMedia } from "../../../assets/png";
import { HeadingThird } from "../../../assets/StyleComponents/FontSize/headingThird.style";
import { HeadingSix } from "../../../assets/StyleComponents/FontSize/HeadingSix.style";
import { InstagramIcon, LinkedinIcon, TelegramReferalIcon, TwitterReferalIcon, YoutubeIcon } from "../../../assets/svgs";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { AnchorTag } from "../../../assets/StyleComponents/FontSize/para.style";

function SocialMedia() {
  const SocialConatiner = styled(BannerBox)`
    display: flex;
    // align-items: center;
    flex-direction: column;
    height: 240px;
    background: url(${(props) => props.bgImg}) no-repeat center;
    background-size: cover;
    padding: ${(props) => props.padding};
  `;
  return (
    <SocialConatiner bgImg={RewardsHubSocialMedia} padding="56px 40px">
      <HeadingThird size="36px" sizeLg="24px" sizeMd="18px" weight="500">
        Follow LCX On Social Media{" "}
      </HeadingThird>
      <HeadingSix size="18px" sizeLg="16px" sizeMd="14px" weight="400" txtGrey>
        & unlock more benefits
      </HeadingSix>

      <BannerBox display="flex" width="100%" direction="row" mt="24px">
        <AnchorTag href="https://t.me/JoinLCX" target="_blank" cursor="pointer" textDecoration="none" BodyColor>
          <TelegramReferalIcon width="40px" height="40px" widthMd="20px" heightMd="20px" borderRadius="6px" />
        </AnchorTag>
        
        <BannerBox rightBorder mx="20px" mxLg="16px" mxMd="12px" />

        <AnchorTag href="https://www.instagram.com/LCX.exchange/" target="_blank" cursor="pointer" textDecoration="none" BodyColor>
          <InstagramIcon width="40px" height="40px" widthMd="20px" heightMd="20px" fillWhite background="#F00073" borderRadius="6px" padding="5px" />
        </AnchorTag>
        
        <BannerBox rightBorder mx="20px" mxLg="16px" mxMd="12px" />

        <AnchorTag href="https://x.com/lcx" target="_blank" cursor="pointer" textDecoration="none" BodyColor>
          <TwitterReferalIcon width="40px" height="40px" widthMd="20px" heightMd="20px"  background="#000000" borderRadius="6px" padding="5px" />
        </AnchorTag>

        <BannerBox rightBorder mx="20px" mxLg="16px" mxMd="12px" />
        
        <AnchorTag href="https://www.linkedin.com/company/lcx" target="_blank" cursor="pointer" textDecoration="none" BodyColor>
          <LinkedinIcon width="40px" height="40px" widthMd="20px" heightMd="20px" />
        </AnchorTag>

        <BannerBox rightBorder mx="20px" mxLg="16px" mxMd="12px" />
        
        <AnchorTag href="https://www.youtube.com/channel/UCNj78acRE-ywQPim-wZ8RTQ" target="_blank" cursor="pointer" textDecoration="none" BodyColor>
          <YoutubeIcon width="40px" height="40px" widthMd="20px" heightMd="20px" fillWhite background="#FF0000" borderRadius="6px" padding="9px" />
        </AnchorTag>
      </BannerBox>
    </SocialConatiner>
  );
}

export default SocialMedia;
