/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { useHistory, useLocation } from "react-router-dom";
import { LoadingButton } from "../../components/Loader";
import { getOpenOrders, resetFilterOrders } from "../../actions/trade";
import { cancelOpenOrder } from "../../lib/api";
import { trackPage } from "../../tracking/track";
import OpenOrderPageLoader from "./OrderLoader/OpenOrderLoader";
import { noExponents, numberWithCommas } from "../../utils/general";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import Error500 from "../ErrorPages/500";
import { DropDownButtonComponent } from "../../assets/StyleComponents/DropDown.style";
import { formatNumbers } from "../../utils/helpers";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { selectedPair } from "../../actions/trade";
import { OpenOrdersRow } from "../../assets/StyleComponents/Table/Order/OpenOrder.style";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { ThreeDotsIcon } from "../../assets/svgs";
import { Pagination, Table, Thead, Th, Tbody, Td, ThDropdown } from "../../components/Reusable";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import NoRecordsFound from "../../components/NoRecordsFound/noRecordsFound";
import FilterOrders from "./FilterOrders/FilterOrders";
// import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";

function OpenOrder(props) {
  let { isOpenOrderFetching, openOrdersCount } = props;
  const param = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(1);
  const [openOrders, setOpenOrders] = useState([]);
  const { width } = useWindowSize();
  const [dropdownOptions, setDropdownOptions] = useState([
    "Amount",
    "Date",
    "Status",
    "Filled (%)"
  ]);
  const [activeCol, setActiveCol] = useState("Amount");
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const userData = useSelector(state => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    // if(isLoggedIn) {
    //   dispatch(fetchAvailableLanguagesRequest());
    //   let checkPath = location.pathname?.split("/")[1];
    //   if(checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage){
    //     history.push(`/${userData.preferredLanguage}/order/${param.tab}`);
    //   }
    // }
    let page = location?.pathname + location?.search;
    trackPage(page);
    return () => dispatch(resetFilterOrders());
  }, []);

  useEffect(() => {
    setOpenOrders(props.openOrders);
  }, [props.openOrders]);

  const cancelOrder = (props) => {
    if (props !== undefined) {
      let params = {
        OrderId: props
      };

      let order = openOrders;
      let cOrder = {};
      order = order.filter((a) => {
        if (a.Id === props) {
          a.Loading = true;
          cOrder = a;
          return a;
        } else return a;
      });
      setOpenOrders(order);

      cancelOpenOrder(params)
        .then((res) => {
          showSuccessMsg(
            `${cOrder.OrderType === "LIMIT" ? "Limit" : "Market"} ${
              cOrder.Side === "BUY" ? "Buy" : "Sell"
            } Order Cancelled`,
            { autoClose: 7000 }
          );
          let data = {
            offset: 1
          };
          let order = openOrders;
          order = order.filter((a) => {
            if (a.Id !== props) {
              return a;
            }
          });
          setOpenOrders(order);
          if (order.length === 0) {
            setOffset(1);
            props.getOpenOrders(data);
          }
        })
        .catch((err) => {
          order = openOrders;
          order = order.filter((a) => {
            if (a.Id === props) {
              a.Loading = false;
              return a;
            } else return a;
          });
          setOpenOrders(order);
          showErrorMsg(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    onApplyFilter();
  }, [offset]);

  useEffect(() => {
    if (width < 768) {
      setDropdownOptions([...dropdownOptions, "Price"]);
    }
  }, [width]);

  const onApplyFilter = () => {
    let filter = {};
    let { pairs, fromDate, toDate, side } = props.filtersOrders;
    if (pairs) filter.pairs = pairs;
    if (fromDate && toDate) {
      filter.fromDate = fromDate;
      filter.toDate = toDate;
    }
    if (side) filter.side = side;
    if (offset) filter.offset = offset;
    if (Object.keys(filter).length > 0) {
      onApplyFilterHanlder(filter);
    }
  };

  const changePairName = (pairData) => {
    props.selectedPair(pairData);
    history.push(`${prevLang ? `/${prevLang}` : "/en"}/trade/${pairData.base_pair}-${pairData.quote_pair}`);
  };

  const onApplyFilterHanlder = (givenFilterData) => {
    props.getOpenOrders(givenFilterData);
  };

  let buttonDisabled = false;
  let ListCount = 25;
  if (openOrders && (openOrders.length < 1 || openOrders.length % ListCount !== 0))
    // eslint-disable-next-line no-unused-vars
    buttonDisabled = true;
  if (typeof openOrders === "undefined") return <Error500 />;
  return (
    <>
      {isOpenOrderFetching && <OpenOrderPageLoader status={"openOrder"} />}

      {!isOpenOrderFetching && (
        <>
          <BannerBox width="100%" mb="35px" mt="14px" padding="0px 10px" mbLg="25px" mtMd="25px">
            <BannerHeading
              weight="bold"
              BodyColor
              size="1.5rem"
              mb="8px"
              sizeLg="24px"
              sizeMd="18px"
            >
              Open Orders
            </BannerHeading>
            <ParaTag txtGrey weight="500" sizeMd="12px" size="16px">
              Track your active orders here!
            </ParaTag>
          </BannerBox>

          <FilterOrders onApplyFilterHanlder={onApplyFilterHanlder} offset={offset} />

          <BannerBox display="flex" flex="0 0 100%" direction="column">
            <Table bRadius width="100%" marginBottom="16px" TableBodyRowHeight>
              <Thead display="flex" height="40px" fontSize="14px" fontSizeMd="12px">
                <OpenOrdersRow displayFlex OrdersPagesHeadRow>
                  {width > 1025 && (
                    <Th scope="col">
                      Time{" "}
                      <BannerHeadingSpan size="10px" ml="4px">
                        {" "}
                        (DD/MM/YY, 24h){" "}
                      </BannerHeadingSpan>
                    </Th>
                  )}
                  <Th scope="col">Pair</Th>
                  {width > 1025 && <Th scope="col">Type</Th>}
                  {width > 767 && <Th scope="col">Price</Th>}
                  <Th scope="col">Side</Th>
                  {width > 1025 && <Th scope="col">Amount </Th>}
                  {width > 1025 && <Th scope="col">Filled (%) </Th>}
                  {width > 1025 && <Th scope="col">Status </Th>}
                  {width > 1025 && <Th scope="col">Action </Th>}
                  {width < 1025 && (
                    <ThDropdown
                      textAlign={"right"}
                      OPTIONS={dropdownOptions}
                      activeCol={activeCol}
                      selectOption={setActiveCol}
                    />
                  )}
                  {width < 1025 && <Th scope="col"> </Th>}
                </OpenOrdersRow>
              </Thead>
              <Tbody fontSizeMd="14px" fontSizeSm="12px" fontSize="14px">
                {!isOpenOrderFetching &&
                  openOrders &&
                  openOrders.length > 0 &&
                  openOrders.map((item, index) => {
                    let {
                      Pair,
                      Price,
                      Side,
                      Amount,
                      OrderType,
                      Status,
                      FilledPer,
                      Id,
                      UpdatedAt,
                      Loading
                    } = item;
                    const splitPair = Pair.split("/");
                    const base = splitPair[0];
                    FilledPer = FilledPer.toFloor(2);
                    const quote = splitPair[1];
                    item.base_pair = base.toUpperCase();
                    item.quote_pair = quote.toUpperCase();
                    item.base = base.toUpperCase();
                    item.quote = quote.toUpperCase();
                    return (
                      <OpenOrdersRow displayFlex key={index} TableBodyRowHeight>
                        {width > 1025 && (
                          <Td>{moment(UpdatedAt * 1000).format("DD/MM/YY, HH:mm")}</Td>
                        )}
                        <Td textAlign="left" fontSizeMd="14px" onClick={() => changePairName(item)}>
                          {base}/
                          <BannerHeadingSpan weight="500" txtGrey>
                            {quote}{" "}
                          </BannerHeadingSpan>
                        </Td>
                        {width > 1025 && <Td textAlign="left"> {OrderType} </Td>}
                        {width > 767 && (
                          <Td textAlign="right" fontSizeMd="14px">
                            {numberWithCommas(noExponents(Price))}
                          </Td>
                        )}
                        <Td
                          textAlign="left"
                          green={Side === "BUY" && true}
                          red={Side === "SELL" && true}
                        >
                          {Side}
                        </Td>
                        {width > 1025 && (
                          <Td fontWeight="500" fontSize="14px" BodyColor textAlign="right">
                            {numberWithCommas(Amount.noExponents())}
                          </Td>
                        )}
                        {/* dropdown selection starts */}
                        {width < 1025 && activeCol === "Amount" && (
                          <Td textAlign="right">{numberWithCommas(noExponents(Amount))}</Td>
                        )}
                        {width < 767 && activeCol === "Price" && (
                          <Td textAlign="right">{numberWithCommas(noExponents(Price))}</Td>
                        )}
                        {width < 1025 && activeCol === "Status" && (
                          <Td textAlign="right">{Status}</Td>
                        )}
                        {width < 1025 && activeCol === "Filled (%)" && (
                          <Td textAlign="right">
                            {numberWithCommas(formatNumbers(FilledPer || 0, 2))} %{" "}
                          </Td>
                        )}
                        {width < 1025 && activeCol === "Date" && (
                          <Td textAlign="right">
                            {moment(UpdatedAt * 1000).format("DD/MM/YY, HH:mm")}
                          </Td>
                        )}
                        {width > 1025 && (
                          <Td textAlign="right">
                            {" "}
                            {numberWithCommas(formatNumbers(FilledPer || 0, 2))} %{" "}
                          </Td>
                        )}
                        {width > 1025 && <Td textAlign="left"> {Status} </Td>}
                        {width > 1025 ? (
                          <Td
                            // onClick={() => {
                            //   if (!Loading) cancelOrder(Id);
                            // }}
                            textAlign="center"
                          >
                            {Loading ? (
                              <BannerBox style={{ zoom: "36%", padding: "6px auto" }}>
                                <LoadingButton />
                              </BannerBox>
                            ) : (
                              <ButtonComponent
                                OutlineOnlyDanger
                                btnHeight="max-content"
                                givenMargn="0 0 0 0"
                                size="14px"
                                onClick={() => {
                                  cancelOrder(Id);
                                }}
                              >
                                Cancel
                              </ButtonComponent>
                            )}
                          </Td>
                        ) : (
                          <>
                            <Td>
                              <DropDownButtonComponent
                                TradeOrder
                                InnerLevel
                                RemoveBtnPadding
                                NoCaret
                                variant="outline-secondary"
                                title={<ThreeDotsIcon width="20px" height="20px" marginTop="5px" />}
                                id="input-group-dropdown-1"
                              >
                                <Dropdown.Item
                                  as={"div"}
                                  style={{ fontSize: "12px", textAlign: "left" }}
                                  onClick={() => cancelOrder(Id)}
                                >
                                  Cancel
                                </Dropdown.Item>
                              </DropDownButtonComponent>
                            </Td>
                          </>
                        )}
                      </OpenOrdersRow>
                    );
                  })}
                {!isOpenOrderFetching && openOrders.length === 0 && openOrders && (
                  <BannerBox margin="15px 0px">
                    <NoRecordsFound text="No Record Found" />
                  </BannerBox>
                )}
              </Tbody>
            </Table>
            <BannerBox mb="40px" mbMd="30px">
              {!isOpenOrderFetching && openOrders && openOrders.length > 0 && (
                <Pagination
                  page={offset}
                  totalCount={openOrdersCount}
                  setPage={setOffset}
                  itemPerPage={25}
                />
              )}
            </BannerBox>
          </BannerBox>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  filtersOrders: state.trade.filtersOrders,
  openOrdersOffSet: state.trade.openOrdersOffSet,
  openOrders: state.trade.openOrders,
  isOpenOrderFetching: state.trade.isOpenOrderFetching,
  openOrdersCount: state.trade.openOrdersCount
});

const mapDispatchToProps = (dispatch) => ({
  getOpenOrders: (data) => dispatch(getOpenOrders(data)),
  selectedPair: (data) => dispatch(selectedPair(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenOrder);
