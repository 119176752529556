import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  investHistory: "",
  loader: false,
  error: ""
};

let initialState = initialStateSchema;
const localData = localStorage.getItem("accessToken");

if (localData && localData !== undefined) {
  try {
    initialState.authToken = localData;
  } catch (e) {
    initialState = initialStateSchema;
  }
}

const earnReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GET_BOND_INVEST_HISTORY:
      newState = {
        ...state,
        loader: true,
        error: ""
      };

      return newState;

    case apiConstants.GET_BOND_INVEST_HISTORY_SUCCESS:
      newState = {
        ...state,
        loader: false,
        investHistory: action?.response?.data
      };
      return newState;

    case apiConstants.GET_BOND_INVEST_HISTORY_FAIL:
      newState = {
        ...state,
        loader: false,
        error: action?.response?.data
      };
      return newState;

    case apiConstants.CLEAR_EARN_ERRORS:
      newState = {
        ...state,
        error: ""
      };
      return newState;

    default:
      return state;
  }
};

export default earnReducer;
