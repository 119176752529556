/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { DivElement, Pagination, TableNoRecord } from "../../../../../components/Reusable";
import OrdersFilter from "./OrdersFilter";
import OrdersTableMobile from "./OrdersTableMobile";
import OrdersTableWeb from "./OrdersTableWeb";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import { TableLoader } from "../../../../../components/Loader";
import { cancelOpenOrder } from "../../../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../../../utils/notification";
import { formatAPIErrors } from "../../../../../utils/general";
import ModifyOrder from "./ModifyOrder";
import { getUserOrders } from "../../../../../actions/trading";

const getDateFilters = (days, type) => {
  let toDate = moment().valueOf();
  let fromDate = moment().subtract(days, "days").valueOf();
  if (type === "orderExecutions") {
    toDate = parseInt(toDate / 1000);
    fromDate = parseInt(fromDate / 1000);
  }

  return { fromDate, toDate };
};

const OrdersTab = ({
  type,
  selectedPair,
  showAllPairsOrder,
  openOrdersData,
  isFetchingOpenOrders,
  orderExecutionsData,
  isFetchingOrderExecutions,
  orderHistoryData,
  isFetchingOrderHistory
}) => {
  const [selectedDaysFilter, setSelectedDaysFilter] = useState("1");
  const [ordersList, setOrdersList] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [orderDataToModify, setOrderDataToModify] = useState("");
  const [modifyModalPosition, setModifyModalPosition] = useState({ x: 0, y: 0 });
  const { width } = useWindowSize();
  const isDesktop = width > 1024 ? true : false;
  const dispatch = useDispatch();

  useEffect(() => {
    getOrders();
  }, [selectedPair, selectedDaysFilter, showAllPairsOrder]);

  useEffect(() => {
    let flag;
    if (type === "orderExecutions") {
      flag = isFetchingOrderExecutions;
    } else if (type === "orderHistory") {
      flag = isFetchingOrderHistory;
    } else {
      flag = isFetchingOpenOrders;
    }

    setLoader(flag);
  }, [isFetchingOpenOrders, isFetchingOrderHistory, isFetchingOrderHistory]);

  useEffect(() => {
    let orderData;
    if (type === "orderExecutions") {
      orderData = orderExecutionsData;
    } else if (type === "orderHistory") {
      orderData = orderHistoryData;
    } else {
      orderData = openOrdersData;
    }

    if (orderData) {
      setOrdersList(orderData?.data);
      setTotalCount(orderData?.totalCount);
    }
  }, [openOrdersData, orderHistoryData, orderExecutionsData]);

  const getOrders = (currPage = 1) => {
    let data = {
      offset: currPage
    };

    if (!showAllPairsOrder) {
      data.pair = selectedPair.symbol;
    }

    if (type !== "openOrders" && selectedDaysFilter) {
      const { fromDate, toDate } = getDateFilters(+selectedDaysFilter, type);
      data.fromDate = fromDate;
      data.toDate = toDate;
    }

    let body = {
      type,
      data
    };

    dispatch(getUserOrders(body));
  };

  const onCancelOrder = (orderData) => {
    let data = {
      OrderId: orderData?.Id
    };

    cancelOpenOrder(data)
      .then((res) => {
        showSuccessMsg(
          `${orderData.OrderType === "LIMIT" ? "Limit" : "Market"} ${
            orderData.Side === "BUY" ? "Buy" : "Sell"
          } Order Cancelled`
        );
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const onEditHandler = (data, position) => {
    setOrderDataToModify(data);
    setModifyModalPosition({
      x: position.x,
      y: position.y
    });
  };

  const onCloseModify = () => {
    setOrderDataToModify("");
  };

  const onPageChangeHandler = (p) => {
    getOrders(p);
    setPage(p);
  };

  return (
    <Fragment>
      {type !== "openOrders" && (
        <OrdersFilter
          selectedDaysFilter={selectedDaysFilter}
          setSelectedDaysFilter={setSelectedDaysFilter}
          containerProps={{ paddingBottom: "12px", paddingBottomSm: "16px" }}
        />
      )}

      <DivElement
        height={type === "openOrders" ? "100%" : "calc(100% - 36px)"}
        heightSm={type === "openOrders" ? "100%" : "calc(100% - 40px)"}
      >
        {!loader && ordersList ? (
          <Fragment>
            {isDesktop ? (
              <DivElement height="100%" scrollY>
                <OrdersTableWeb
                  onCancelOrder={onCancelOrder}
                  onEditHandler={onEditHandler}
                  ordersList={ordersList}
                  type={type}
                />
                {ordersList && ordersList.length === 0 && (
                  <TableNoRecord containerProps={{ margin: "20px 0 0 0" }} />
                )}
              </DivElement>
            ) : (
              <DivElement relative height="100%" paddingBottom="56px">
                <DivElement scrollY height="100%">
                  <OrdersTableMobile
                    onCancelOrder={onCancelOrder}
                    onEditHandler={onEditHandler}
                    ordersList={ordersList}
                    type={type}
                  />
                  {ordersList && ordersList.length === 0 && <TableNoRecord />}
                </DivElement>

                <DivElement absolute bottom="0" right="0">
                  <Pagination
                    page={page}
                    totalCount={totalCount}
                    itemPerPage={25}
                    setPage={onPageChangeHandler}
                    marginTopMd="16px !important"
                    marginTopSm="16px !important"
                  />
                </DivElement>
              </DivElement>
            )}
          </Fragment>
        ) : (
          <DivElement width="100%" height="100%" scrollY>
            <TableLoader rows={5} webCol={5} tabletCol={5} mobileCol={3} />
          </DivElement>
        )}
      </DivElement>

      {orderDataToModify && (
        <ModifyOrder
          orderData={orderDataToModify}
          position={modifyModalPosition}
          onClose={onCloseModify}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  selectedPair: state.trading.selectedPair,
  userOrderUpdate: state.trading.userOrderUpdate,
  showAllPairsOrder: state.trading.showAllPairsOrder,
  openOrdersData: state.trading.openOrdersData,
  isFetchingOpenOrders: state.trading.isFetchingOpenOrders,
  orderHistoryData: state.trading.orderHistoryData,
  isFetchingOrderHistory: state.trading.isFetchingOrderHistory,
  orderExecutionsData: state.trading.orderExecutionsData,
  isFetchingOrderExecutions: state.trading.isFetchingOrderExecutions
});

export default connect(mapStateToProps)(OrdersTab);
