import React, { useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";

// import { compose } from "redux";
// import { withRouter } from "react-router-dom";
import { loginWithEmailOtp } from "../../lib/api";
import { clearAuthErrors, clearTwoAuth, clearOTP } from "../../actions/auth";
// import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { formatAPIErrors } from "../../utils/general";
import { LoginStep1, LoginStep2 } from "../../components/Modules/Authentication";
import { HANDLE_2FA_ERROR } from "../../constants/response";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { trackPage } from "../../tracking/track";
import { DivElement } from "../../components/Reusable";
import { LockGreenIcon } from "../../assets/svgs";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import { H1Tag } from "../../assets/StyleComponents/FontSize/fonts.style";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const Login = ({
  error,
  clearAuthErrors,
  clearTwoAuth,
  clearOTP,
  isTwoAuth,
  isOtp,
  redirectToVerifyEmail
}) => {
  const userData = useSelector((state) => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");
  const { transalationList } = useSelector(GenericReducer);
  const history = useHistory();

  useEffect(() => {
    let otp = new URLSearchParams(window.location.search).get("otp");
    let type = new URLSearchParams(window.location.search).get("type");

    if (otp && type === "email-authorization") {
      loginWithEmailOtp(otp)
        .then((res) => {
          showSuccessMsg(res?.data?.message);
        })
        .catch((err) => {
          showErrorMsg(formatAPIErrors(err));
        })
        .finally(() => {
          history.push(
            `/${prevLang ? prevLang : userData && userData.preferredLanguage ? `${userData.preferredLanguage}` : ""}`
          );
        });
    }
    trackPage(`${prevLang ? `/${prevLang}` : "/en"}/login`, "Login Page");

    return () => {
      clearTwoAuth();
      clearOTP();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      showErrorMsg(formatAPIErrors(error));
      if (error.message !== HANDLE_2FA_ERROR) {
        clearAuthErrors();
      }
    }

    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (redirectToVerifyEmail) {
      history.push(
        `${
          userData && prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""
        }/verify/email`
      );
    }
    // eslint-disable-next-line
  }, [redirectToVerifyEmail]);

  const SEOContents = {
    name: `LOGIN`,
    link: `login`,
    title: "Signin | Start Your Crypto Journey | LCX Exchange",
    description:
      "LCX Exchange: Your Secure Online Platform for Buying, Selling, Transferring, and Storing Cryptocurrency. Sign in!",
    keywords: `sign in to exchange,exchange sign in`,
    canonical: `https://exchange.lcx.com/login`,
    canonicalUrl: "https://exchange.lcx.com",
    afterLink: `/${prevLang || "en"}/login`,
    lang: `/${prevLang || "en"}`,
    OgTitle: "Signin | Start Your Crypto Journey | LCX Exchange",
    OgDescription:
      "LCX Exchange: Your Secure Online Platform for Buying, Selling, Transferring, and Storing Cryptocurrency. Sign in!",
    OgURL: `https://www.exchange.lcx.com/${prevLang || "en"}/login`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Signin | Start Your Crypto Journey | LCX Exchange",
    TwDescription:
      "LCX Exchange: Your Secure Online Platform for Buying, Selling, Transferring, and Storing Cryptocurrency. Sign in!"
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/login`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <DivElement pageContainer display="flex" flexDirection="column" alignItems="center">
        <H1Tag size="28px" weight="bold" sizeSm="20px">
          Log in
        </H1Tag>
        <BannerBox LoginPageDomainDiv gap="12px">
          <LockGreenIcon />
          <BannerHeading size="14px" txtGrey sizeSm="12px">
            Check URL:&nbsp;<BannerHeadingSpan txtSuccess>https://</BannerHeadingSpan>
            exchange.lcx.com
          </BannerHeading>
        </BannerBox>

        <BannerBox LoginFormBg>
          {!isTwoAuth && !isOtp && <LoginStep1 />}

          {(isTwoAuth || isOtp) && <LoginStep2 />}
        </BannerBox>
      </DivElement>
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.auth.error,
  isTwoAuth: state.auth.isTwoAuth,
  isOtp: state.auth.isOtp,
  redirectToVerifyEmail: state.auth.redirectToVerifyEmail
});

const mapDispatchToProps = (dispatch) => ({
  clearAuthErrors: () => dispatch(clearAuthErrors()),
  clearTwoAuth: () => dispatch(clearTwoAuth()),
  clearOTP: () => dispatch(clearOTP())
});

// export default compose(
//   withGoogleReCaptcha,
//   withRouter,
//   connect(mapStateToProps, mapDispatchToProps)
// )(Login);
export default connect(mapStateToProps, mapDispatchToProps)(Login);
