import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { BannerBox, BannerSection } from "../../assets/StyleComponents/Exchange.style";
// import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import { Error500Illustration } from "../../assets/svgs/illustrations";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";

class Error500 extends Component {
  render() {
    const prevLang = localStorage.getItem("ljs-lang");
    
    return (
      <>
        {/* <SetHeaderTitleComponent /> */}
        <div
          className="all-wrapper with-side-panel solid-bg-all"
          style={{ background: "#f2f4f8", boxShadow: "none" }}
        >
          {/* <DocumentTitle title={"LCX Echange"}> */}
          <div className="layout-w">
            <BannerSection
              SectionGrey
              className="content-w"
              style={{ position: "relative", zIndex: "0" }}
            >
              <BannerBox ExchangeContainer>
                <BannerBox direction="column" display="flex" alignItems="center" padding="10px">
                  <div width="100%">
                    <Error500Illustration width="100%" />
                  </div>

                  <ParaTag
                    txtGrey
                    textAlign="center"
                    size="20px"
                    margin="10px 0px"
                    sizelg="18px"
                    sizeMd="16px"
                  >
                    Something went wrong!
                  </ParaTag>

                  <BannerBox mb="24px">
                    <Link to={`/${prevLang ? `${prevLang}`: "en"}`} className="">
                      <ButtonComponent Primary type="button" size="1.2rem" padding="0 5rem">
                        Back to home
                      </ButtonComponent>
                    </Link>
                  </BannerBox>
                </BannerBox>
              </BannerBox>
            </BannerSection>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Error500);
