/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Datetime from "react-datetime";
import { getMarketLists } from "../../actions/marketLists";
import configs from "../../constants/config";
import { getOrderHistory, resetFilterOrders } from "../../actions/trade";
import { trackPage } from "../../tracking/track";
import { getAllWallets } from "../../lib/api";
import {
  formatAPIErrors,
  formattedSymbol,
  noExponents,
  numberWithCommas
} from "../../utils/general";
import OpenOrderPageLoader from "./OrderLoader/OpenOrderLoader";
import { BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { formatNumbers } from "../../utils/helpers";
import { showErrorMsg } from "../../utils/notification";
import { selectedPair } from "../../actions/trade";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { OrderHistoryRow } from "../../assets/StyleComponents/Table/Order/OrderHistory.style";
import {
  Pagination,
  Table,
  Thead,
  Th,
  Td,
  ThDropdown,
  Tbody,
  ImgElement
} from "../../components/Reusable";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import NoRecordsFound from "../../components/NoRecordsFound/noRecordsFound";
// import OrderHistoryFilters from "./Filters";
import FilterOrders from "./FilterOrders/FilterOrders";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

var yesterday = Datetime.moment();
var valid = function (current) {
  return current.isBefore(yesterday);
};

function OpenHistory(props) {
  let { isOrderHistoryListFetching, isOrderHistoryList, isOrderHistoryListCount, isMarketLists, userData } =
    props;
  const [offset, setOffset] = useState(1);
  const [isCoinLoader, setIsCoinLoader] = useState(false);
  const [pair, setPair] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [fromTimeStamp, setFromTimeStamp] = useState("");
  const [toDate, setToDate] = useState("");
  const [toDateTimeStamp, setToDateTimeStamp] = useState("");
  const [side, setSide] = useState("");
  const [coins, setCoins] = useState([]);
  const location = useLocation();
  const param = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [dropdownOptions, setDropdownOptions] = useState(["Amount", "Date", "Type", "Filled (%)"]);
  const [activeCol, setActiveCol] = useState("Amount");
  const prevLang = localStorage.getItem("ljs-lang");

  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  useEffect(() => {
    // if (isOrderHistoryListFetching) {
    setIsCoinLoader(true);
    // }
    // let data = {
    //   offset: 1
    // };
    // props.getOrderHistory(data);
    // props.getMarketLists();
    if(isLoggedIn) {
      dispatch(fetchAvailableLanguagesRequest());
      // let checkPath = location.pathname?.split("/")[1];
      // if(checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage){
      //   history.push(`/${userData.preferredLanguage}/order/${param.tab}`);
      // }
    }
    getAllWallets()
      .then((res) => {
        let coins = {};
        res.data &&
          res.data.data.map((data) => {
            coins[data.coin] = data.coin;
          });
        setCoins(coins);
        setIsCoinLoader(false);
      })
      .catch((err) => {
        setIsCoinLoader(false);
        showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
      });

    let page = location.pathname + location.search;
    trackPage(page);
    return () => dispatch(resetFilterOrders());
  }, []);

  // useEffect(() => {
  //   setIsCoinLoader(!isCoinLoader);
  // }, [isOrderHistoryListFetching]);

  // useEffect(() => {
  //   // let params = {
  //   //   offset: offset
  //   // };
  //   // props.getOrderHistory(params);
  //   onApplyFilter();
  // }, [offset]);

  const onApplyFilter = () => {
    let filter = {};
    let { pairs, fromDate, toDate, side } = props.filtersOrders;
    if (pairs) filter.pairs = pairs;
    if (fromDate && toDate) {
      filter.fromDate = fromDate;
      filter.toDate = toDate;
    }
    if (side) filter.side = side;
    if (offset) filter.offset = offset;
    if (Object.keys(filter).length > 0) {
      onApplyFilterHanlder(filter);
    }
  };

  const handleFilterData = () => {
    setOffset(1);
    let params = {
      offset: 1
    };
    if (side) params.side = side;
    if (pair && Object.keys(pair).length > 0) params.pair = pair.symbol;
    if (fromTimeStamp) params.fromDate = fromTimeStamp;
    if (toDateTimeStamp) params.toDate = toDateTimeStamp;
    props.getOrderHistory(params);
  };

  // const selectFromDate = (fromDate) => {
  //   setFromDate(moment(fromDate).format("DD-MM-YYYY"));
  //   setFromTimeStamp(moment(fromDate).unix());
  // };

  // const selectToDate = (toDate) => {
  //   setToDate(moment(toDate).format("DD-MM-YYYY"));
  //   setToDateTimeStamp(moment(toDate).endOf("day").unix());
  // };

  // const CustomOptionsForCurrency = ({ id, label }) => {
  //   return (
  //     <BannerBox key={label} display="flex" alignItems="center" gap="5%">
  //       {width > 767 && (
  //         <ImgElement
  //           alt="coin"
  //           width="25px"
  //           widthSm="10px"
  //           verticalAlign="middle"
  //           src={`${
  //             !isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
  //           }${id?.base_pair.toLowerCase()}.svg`}
  //         />
  //       )}
  //       <BannerHeadingSpan sizeMd="12px" paddingLeft="5%">
  //         {" "}
  //         {label}{" "}
  //       </BannerHeadingSpan>
  //     </BannerBox>
  //   );
  // };

  // const validToDate = (current) => {
  //   if (fromTimeStamp) {
  //     const max = moment(fromTimeStamp * 1000).add(89, "days");
  //     return (
  //       valid(current) &&
  //       (moment(current).isAfter(moment(fromTimeStamp) * 1000) ||
  //         moment(current).isSame(moment(fromTimeStamp) * 1000)) &&
  //       moment(current).isBefore(max)
  //     );
  //   }
  //   return valid(current);
  // };

  // const validFromDate = (current) => {
  //   if (toDateTimeStamp) {
  //     const max = moment(toDateTimeStamp * 1000).subtract(89, "days");
  //     return (
  //       valid(current) &&
  //       (moment(current).isBefore(moment(toDateTimeStamp) * 1000) ||
  //         moment(current).isSame(moment(toDateTimeStamp) * 1000)) &&
  //       moment(current).isAfter(max)
  //     );
  //   }
  //   return valid(current);
  // };

  // useEffect(() => {
  //   if (side) {
  //     handleFilterData();
  //   }
  //   if (pair) {
  //     if (pair === "" && fromDate === "" && toDate === "") return false;
  //     handleFilterData();
  //   }
  //   if (fromDate) {
  //     if (pair === "" && fromDate === "" && toDate === "") return false;
  //     handleFilterData();
  //   }
  //   if (toDate) {
  //     if (pair === "" && fromDate === "" && toDate === "") return false;
  //     handleFilterData();
  //   }
  // }, [side, pair, toDate, fromDate]);

  const selectPairName = (coinData) => {
    setPair(coinData);
  };

  const clearAll = () => {
    setFromDate("");
    setToDate("");
    setFromTimeStamp("");
    setToDateTimeStamp("");
    setPair("");
    setOffset(1);
    let data = {
      offset: 1
    };
    props.getOrderHistory(data);
  };

  const changePairName = (pairData) => {
    props.selectedPair(pairData);
    history.push(`${prevLang ? `/${prevLang}` : "/en"}/trade/${pairData.base_pair}-${pairData.quote_pair}`);
  };

  const onApplyFilterHanlder = (givenFilterData) => {
    props.getOrderHistory(givenFilterData);
  };

  let optionList =
    (isMarketLists && isMarketLists.length > 0 && formattedSymbol(isMarketLists)) || [];

  let buttonDisabled = false;
  let ListCount = 25;
  if (
    isOrderHistoryList &&
    (isOrderHistoryList.length < 1 || isOrderHistoryList.length % ListCount !== 0)
  )
    // eslint-disable-next-line no-unused-vars
    buttonDisabled = true;
  if (isCoinLoader) return <OpenOrderPageLoader status={"openHistory"} />;

  return (
    <>
      {isCoinLoader && <OpenOrderPageLoader status={"openHistory"} />}

      {!isCoinLoader && (
        <>
          <BannerBox width="100%" mb="24px" mt="14px" padding="0px 10px" mbLg="25px" mtMd="25px">
            <BannerHeading
              weight="bold"
              BodyColor
              size="1.5rem"
              mb="8px"
              sizeLg="24px"
              sizeMd="18px"
            >
              {" "}
              Order History{" "}
            </BannerHeading>
            <ParaTag txtGrey weight="500" sizeMd="12px" size="16px">
              Monitor your complete order history
            </ParaTag>
          </BannerBox>

          {/* {width > 1025 && ( */}
          <FilterOrders onApplyFilterHanlder={onApplyFilterHanlder} offset={offset} />
          {/* <OrderHistoryFilters
            pair={pair}
            fromDate={fromDate}
            toDate={toDate}
            clearAll={clearAll}
            isCoinLoader={isCoinLoader}
            coins={coins}
            CustomOptionsForCurrency={CustomOptionsForCurrency}
            selectPairName={selectPairName}
            optionList={optionList}
            selectFromDate={selectFromDate}
            selectToDate={selectToDate}
            validToDate={validToDate}
            validFromDate={validFromDate}
          /> */}

          <BannerBox display="flex" flex="0 0 100%" direction="column">
            <Table bRadius width="100%" marginBottom="16px" TableBodyRowHeight>
              <Thead display="flex" height="40px" fontSize="14px" fontSizeMd="12px">
                <OrderHistoryRow displayFlex OrdersPagesHeadRow>
                  {width > 1025 && (
                    <Th scope="col">
                      Time{" "}
                      <BannerHeadingSpan size="10px" ml="4px">
                        {" "}
                        (DD/MM/YY, 24h){" "}
                      </BannerHeadingSpan>
                    </Th>
                  )}
                  <Th scope="col">Pair</Th>
                  {width > 1025 && <Th scope="col">Type</Th>}
                  {width > 767 && <Th scope="col">Price</Th>}
                  <Th scope="col">Side</Th>
                  {width > 1025 && <Th scope="col">Amount </Th>}
                  {width > 1025 && <Th scope="col">Filled (%) </Th>}
                  {width < 1025 && (
                    <ThDropdown
                      textAlign={"right"}
                      OPTIONS={dropdownOptions}
                      activeCol={activeCol}
                      selectOption={setActiveCol}
                    />
                  )}
                  {width > 767 && <Th scope="col">Status </Th>}
                </OrderHistoryRow>
              </Thead>
              <Tbody fontSizeMd="14px" fontSizeSm="12px" fontSize="14px">
                {!isOrderHistoryListFetching &&
                  isOrderHistoryList &&
                  isOrderHistoryList.length > 0 &&
                  isOrderHistoryList.map((item, index) => {
                    let { Pair, Price, Side, Amount, OrderType, Status, FilledPer, UpdatedAt } =
                      item;
                    const splitPair = Pair.split("/");
                    const base = splitPair[0];
                    FilledPer = FilledPer.toFloor(2);
                    const quote = splitPair[1];
                    item.base_pair = base.toUpperCase();
                    item.quote_pair = quote.toUpperCase();
                    item.base = base.toUpperCase();
                    item.quote = quote.toUpperCase();
                    return (
                      <OrderHistoryRow displayFlex key={index} TableBodyRowHeight>
                        {width > 1025 && (
                          <Td>{moment(UpdatedAt * 1000).format("DD/MM/YY, HH:mm")}</Td>
                        )}
                        <Td textAlign="left" fontSizeMd="14px" onClick={() => changePairName(item)}>
                          {base}/
                          <BannerHeadingSpan weight="500" txtGrey>
                            {quote}{" "}
                          </BannerHeadingSpan>
                        </Td>
                        {width > 1025 && <Td textAlign="left"> {OrderType} </Td>}
                        {width > 767 && (
                          <Td textAlign="right" fontSizeMd="14px">
                            {numberWithCommas(noExponents(Price))}
                          </Td>
                        )}
                        <Td
                          textAlign="left"
                          green={Side === "BUY" && true}
                          red={Side === "SELL" && true}
                        >
                          {Side}
                        </Td>
                        {width > 1025 && (
                          <Td fontWeight="500" fontSize="14px" BodyColor textAlign="right">
                            {numberWithCommas(Amount.noExponents())}
                          </Td>
                        )}
                        {/* dropdown selection starts */}
                        {width < 1025 && activeCol === "Amount" && (
                          <Td textAlign="right">{numberWithCommas(noExponents(Amount))}</Td>
                        )}
                        {width < 767 && activeCol === "Price" && (
                          <Td textAlign="right">{numberWithCommas(noExponents(Price))}</Td>
                        )}
                        {width < 1025 && activeCol === "Type" && (
                          <Td textAlign="right">{OrderType}</Td>
                        )}
                        {width < 1025 && activeCol === "Filled (%)" && (
                          <Td textAlign="right">
                            {numberWithCommas(formatNumbers(FilledPer || 0, 2))} %{" "}
                          </Td>
                        )}
                        {width < 1025 && activeCol === "Date" && (
                          <Td textAlign="right">
                            {moment(UpdatedAt * 1000).format("DD/MM/YY, HH:mm")}
                          </Td>
                        )}
                        {width > 1025 && (
                          <Td textAlign="right">
                            {" "}
                            {numberWithCommas(formatNumbers(FilledPer || 0, 2))} %{" "}
                          </Td>
                        )}
                        {width > 767 && <Td textAlign="left"> {Status} </Td>}
                      </OrderHistoryRow>
                    );
                  })}
                {!isOrderHistoryListFetching &&
                  isOrderHistoryList &&
                  isOrderHistoryList.length === 0 && (
                    <BannerBox margin="15px 0px">
                      <NoRecordsFound text="No Record Found" />
                    </BannerBox>
                  )}
              </Tbody>
            </Table>
            <BannerBox mb="40px" mbLg="30px">
              {!isOrderHistoryListFetching &&
                isOrderHistoryList &&
                isOrderHistoryList.length > 0 &&
                isOrderHistoryListCount > 1 && (
                  <Pagination
                    page={offset}
                    totalCount={isOrderHistoryListCount}
                    setPage={setOffset}
                    itemPerPage={25}
                  />
                )}
            </BannerBox>
          </BannerBox>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  isTheme: state.theme.isTheme,
  isThemeFecthing: state.theme.isThemeFetching,
  getUserData: state.dashboard.getUserData,
  userData: state.dashboard.userData,
  filtersOrders: state.trade.filtersOrders,
  isOrderHistoryList: state.trade.isOrderHistoryList,
  isOrderHistoryListFetching: state.trade.isOrderHistoryListFetching,
  openOrdersCount: state.trade.openOrdersCount,
  isOrderHistoryListCount: state.trade.isOrderHistoryListCount,
  isMarketLists: state.market.isMarketLists,
  isMarketListFetching: state.market.isMarketListFetching
});

const mapDispatchToProps = (dispatch) => ({
  getOrderHistory: (data) => dispatch(getOrderHistory(data)),
  getMarketLists: (user) => dispatch(getMarketLists(user)),
  selectedPair: (data) => dispatch(selectedPair(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenHistory);
