/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartTypeDropdown from "./ChartTypeDropdown";
import SinglePairTab from "./SinglePairTab";
import { DivElement } from "../../../../../../components/Reusable";
import { updateFavChartPairs, updateSelectedPair } from "../../../../../../actions/trading";
import MarketDropdown from "../../../Components/MarketDropdown";

const ChartFavPairs = () => {
  const favChartPairs = useSelector((state) => state.trading.favChartPairs);
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const selectedPairConfig = useSelector((state) => state.trading.selectedPairConfig);
  const tickersData = useSelector((state) => state.trading.tickersData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedPair && selectedPairConfig && selectedPair.symbol === selectedPairConfig.symbol) {
      onAddPair(selectedPair.symbol);
    }
  }, [selectedPair, selectedPairConfig]);

  const onRemovePair = (symbol) => {
    if (favChartPairs && favChartPairs.length <= 1) {
      return;
    }
    let currPairIndex = favChartPairs.findIndex((data) => data.symbol === symbol);
    let favPairs = [...favChartPairs];
    favPairs.splice(currPairIndex, 1);
    if (selectedPair.symbol === symbol) {
      dispatch(updateSelectedPair(favPairs[0].symbol));
    }
    dispatch(updateFavChartPairs(favPairs));
  };

  const onAddPair = (symbol) => {
    let currPairIndex = favChartPairs.findIndex((data) => data.symbol === symbol);
    if (currPairIndex === -1) {
      let favPairs = [...favChartPairs];
      if (favPairs.length >= 4) {
        favPairs.shift();
      }
      favPairs.push({ symbol });
      dispatch(updateFavChartPairs(favPairs));
    } else if (symbol !== selectedPair.symbol) {
      dispatch(updateSelectedPair(symbol));
    }
  };

  return (
    <DivElement width="100%" flexJustifyBtwAlignCenter padding="0 12px" height="52px">
      <DivElement margin="0 -4px">
        <DivElement flexAlignCenter>
          {favChartPairs &&
            favChartPairs.map((data, index) => (
              <SinglePairTab
                key={index}
                data={data}
                lastPrice={tickersData[data.symbol]?.lastPrice || ""}
                isActive={data.symbol === selectedPair.symbol ? true : false}
                onRemovePair={onRemovePair}
              />
            ))}
          <MarketDropdown type="favChartPairs" />
        </DivElement>
      </DivElement>
      <ChartTypeDropdown />
    </DivElement>
  );
};

export default ChartFavPairs;
