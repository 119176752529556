import { useSelector } from "react-redux";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import OrderForm from "../../Components/OrderForm";

const OrderFormSection = () => {
  const orderFormSide = useSelector((state) => state.trading.orderFormSide);
  return (
    <DivElement bgCard width="100%" height="100%" padding="12px">
      <DivElement displayBtw marginBottom="16px">
        <TextWrapper fontSize="12px" lineHeight="18px" secondary>
          Order Form
        </TextWrapper>
      </DivElement>
      <OrderForm orderFormSide={orderFormSide} />
    </DivElement>
  );
};

export default OrderFormSection;
