import { connect } from "react-redux";
import MarketComponent from "../views/marketPage/marketComponent";
import { getMarketLists, getMarketTickerData } from "../actions/marketLists";
import { selectedPair } from "../actions/trade";
import { logout } from "../actions/auth";
import { watchList } from "../actions/WatchList/watchlist";
import { getUserDetails } from "../actions/dashboard";
// import LangLayoutCheck from "../pages/Auth/LangLayoutCheck";

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  isThemeFecthing: state.theme.isThemeFetching,
  isMarketLists: state.market.isMarketLists,
  isMarketListFetching: state.market.isMarketListFetching,
  isFavWatchList: state.watchList.isFavWatchList,
  isFavWatchListFetching: state.watchList.isFavWatchListFetching,
  selectedPairName: state.trade.selectedPair,
  isSelectedPairFecthing: state.trade.isSelectedPairFecthing,
  isMarketTickerLists: state.market.isMarketTickerLists,
  isMarketTickerListFetching: state.market.isMarketTickerListFetching,
  isLoggedIn: state.auth.isLoggedIn,
  userData: state.dashboard.getUserData,
  isWholeLoading: state.dashboard.isWholeLoading,
  isLogoutFetching: state.dashboard.isLogoutFetching,
  marketErrorGet: state.market.errorGet,
  tradeErrorGet: state.trade.errorGet,
  dashErrorGet: state.dashboard.errorGet,
  settingErrorGet: state.setting.errorGet
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: () => dispatch(getUserDetails()),
  getMarketLists: (user) => dispatch(getMarketLists(user)),
  selectedPair: (user) => dispatch(selectedPair(user)), // Call for Selected Pair From redux
  getMarketTickerData: () => dispatch(getMarketTickerData()), // Call for Market Ticker From  redux
  watchList: (user) => dispatch(watchList(user)),
  logoutUser: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketComponent);
