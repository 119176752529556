import React from "react";
import Skeleton from "./Skeleton";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { DivElement } from "../Reusable";

const TableLoader = ({ rows, webCol, tabletCol, mobileCol }) => {
  const { width } = useWindowSize();
  return (
    <DivElement TableLoader>
      <div className="tableLoader__rowHead">
        {Array.from(
          Array(width >= 1024 ? webCol : width <= 767.5 ? mobileCol : tabletCol).keys()
        ).map((y, j) => (
          <Skeleton key={j} className="tableLoader__cell" />
        ))}
      </div>
      {Array.from(Array(rows).keys()).map((x, i) => (
        <div key={i} className="tableLoader__row">
          {Array.from(
            Array(width >= 1024 ? webCol : width <= 767.5 ? mobileCol : tabletCol).keys()
          ).map((y, j) => (
            <Skeleton key={j} className="tableLoader__cell" />
          ))}
        </div>
      ))}
    </DivElement>
  );
};

export default TableLoader;
