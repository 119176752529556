/* eslint-disable no-console */
/* eslint-disable no-extend-native */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { getMarketLists, recentTicker } from "../../lib/api";
import config from "../../constants/apiConstants";
import configs from "../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { getTimeZoneCET, selectedPair, askPairList } from "../../actions/trade";
import { callAllTickerMarket } from "../../Modules/Trades/MarketData/action";
import UseParamHook from "../UseParamHook/useParamHook";
import { updateSelectedPairName } from "../../Modules/Trades/action";

const UseWebSocketStatus = () => {

  const location = useLocation();
  let history = useParams();
  let match = useRouteMatch();
  const [ isReady, setIsReady ] = useState(null);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ val, setVal ] = useState(null);
  const [ pairName, setPairName ] = useState("");
  let selectedPairName = useSelector(state => state.trade.selectedPair);

  const dispatch = useDispatch();

  const ws = useRef(null);
  let pingInterval = null;
  let reconnectInterval = null;
  let publicSocketConnectionCount = 0;
  let privateSocketConnectionCount = 0;
  const maxTryConnection = 3;
  const reconnectIntervalTime = 3000;
  const reconnectIntervalTimePing = 20000;

  //=> Default Call for initally
  const automaticallyCallOrderBookApi = (pair) => {
    let data = {
      pair
    };
    dispatch(askPairList(data));
  };



  // => Check Current Pair.
  const checkCurrentPair = () => {
    // let obj = {
    //   base_pair: baseHook,
    //   quote_pair: qouteHook,
    //   symbol: pairNameHook
    // };
    // setPairName(pairNameHook);

    // dispatch(selectedPair(obj));
    let pathName = location.pathname;
    if(pathName) {
      let splitPathName = pathName.split("/");
      if(splitPathName[2]) {
        let pairCheckUnderDash = splitPathName[2]?.includes("_");
        let pairCheckDash = splitPathName[2]?.includes("-");
        let name = "LCX/EUR";
        if(pairCheckUnderDash) name = splitPathName[2]?.split("_")?.join("/") || "LCX/EUR";
        else if(pairCheckDash) name = splitPathName[2]?.split("-")?.join("/") || "LCX/EUR";
        
        let splitName = name?.split("/");
        let obj = {
          base_pair: splitName[0],
          quote_pair: splitName[1],
          symbol: name
        };
        let Obj1 = {
          base: splitName[0],
          qoute: splitName[1],
          symbol: name
        };
        setPairName(name);
        dispatch(selectedPair(obj));
        dispatch(updateSelectedPairName(Obj1));
        return obj;
      } else {
        let splitName = configs.defaultPair?.split("/");
        
        let obj = {
          base_pair: splitName[0],
          quote_pair: splitName[1],
          symbol: configs.defaultPair
        };
        let Obj1 = {
          base: splitName[0],
          qoute: splitName[1],
          symbol: configs.defaultPair
        };
        setPairName(configs.defaultPair);
        dispatch(selectedPair(obj));
        dispatch(updateSelectedPairName(Obj1));
        return obj;
      }
    } else if(selectedPairName && Object.keys(selectedPairName).length > 0) {
      let obj = {
        base_pair: selectedPairName.base_pair,
        quote_pair: selectedPairName.quote_pair,
        symbol: `${selectedPairName.base_pair}/${selectedPairName.quote_pair}`
      };
      return obj;
    } 
    else {
      let obj = {
        base_pair: "LCX",
        quote_pair: "EUR",
        symbol: "LCX/EUR"
      };
      return obj;
      // let pathName = location.pathname;
    }
  };


  const connectWebSocket = () => {
    let socket = new WebSocket(config.SOKET_EXCHANGE_URL);
    

    const CallReconnectPairs = () => {
      if (socket && socket.readyState === 1) {
        if(reconnectInterval) clearInterval(reconnectInterval);
        let pairName = checkCurrentPair();
        privateSocketConnectionCount = 0;
        socket.send(JSON.stringify({ "Topic": "subscribe", "Type": "trade", "Pair": pairName.symbol || "LCX/EUR" }));
        socket.send(JSON.stringify({ "Topic": "subscribe", "Type": "orderbook", "Pair": pairName.symbol || "LCX/EUR" }));
        socket.send(JSON.stringify({ "Topic": "subscribe", "Type": "ticker" }));
        dispatch(getTimeZoneCET());
      } else {
        if(privateSocketConnectionCount < maxTryConnection) {
          CallReconnectPairs();
          privateSocketConnectionCount = privateSocketConnectionCount + 1;
        }
      }
    };

    const CallReconnectWithoutPairs = () => {
      if (socket && socket.readyState === 1) {
        if(reconnectInterval) clearInterval(reconnectInterval);
        publicSocketConnectionCount = 0;
        socket.send(JSON.stringify({ "Topic": "subscribe", "Type": "ticker" }));
        dispatch(getTimeZoneCET());
      } else {
        if(publicSocketConnectionCount < maxTryConnection) {
          CallReconnectWithoutPairs();
          publicSocketConnectionCount = publicSocketConnectionCount + 1;
        }
      }
    };

    const handleOpen = () => {
      setIsReady(true);
      
      if(reconnectInterval) {
        if((location.pathname.includes("/mobile-trade")) || (location.pathname.includes("/trade") && location.pathname !== "/order/trade-history")){
          CallReconnectPairs();
        } else {
          if(publicSocketConnectionCount < maxTryConnection) {
            CallReconnectWithoutPairs();
            publicSocketConnectionCount = publicSocketConnectionCount + 1;
          }
        }
      }
      pingInterval = setInterval(function () {
        if (socket && socket.readyState === 1) {
          socket.send(JSON.stringify({ "Topic": "ping" }));
        }
      }, reconnectIntervalTimePing);
      if((location.pathname.includes("/mobile-trade")) || (location.pathname.includes("/trade") && location.pathname !== "/order/trade-history")){
        if (socket && socket.readyState === 1) {
          CallReconnectPairs();
          // let pairName = checkCurrentPair();
          // socket.send(JSON.stringify({ "Topic": "subscribe", "Type": "trade", "Pair": pairName.symbol || "LCX/EUR" }));
          // socket.send(JSON.stringify({ "Topic": "subscribe", "Type": "orderbook", "Pair": pairName.symbol || "LCX/EUR" }));
          // socket.send(JSON.stringify({ "Topic": "subscribe", "Type": "ticker" }));
          // automaticallyCallOrderBookApi(pairName.symbol || "LCX/EUR" );
        } 
        // else {
        //   CallReconnectWithoutPairs();
        // }
      } else {
        if(publicSocketConnectionCount < maxTryConnection) {
          CallReconnectWithoutPairs();
          publicSocketConnectionCount = publicSocketConnectionCount + 1;
        }
      }
      clearInterval(reconnectInterval);
    };


    const handleMessage = (event) => {
      setVal(event);
    };

    const handleClose = () => {
      setIsReady(false);
      clearInterval(pingInterval);
      clearInterval(reconnectInterval);

      reconnectInterval = true;
      // Reconnect the WebSocket after 3 second
      reconnectInterval = setInterval(() => {
        socket = new WebSocket(config.SOKET_EXCHANGE_URL);
        socket.onopen = handleOpen;
        socket.onclose = handleClose;
        socket.onmessage = handleMessage;
        ws.current = socket;
      }, reconnectIntervalTime);
    };

    socket.onopen = handleOpen;
    socket.onclose = handleClose;
    socket.onmessage = handleMessage;

    ws.current = socket;
  };

  const closeWebSocket = () => {
    if (ws.current) ws.current.close();
    setIsReady(false);
    clearInterval(pingInterval);
    clearInterval(reconnectInterval);
  };

  useEffect(() => {
    connectWebSocket();
    
    // return () => closeWebSocket();
  }, []);

  // bind is needed to make sure "send" refrence correct "this"
  return [ 
    isReady, val, ws.current?.send.bind(ws.current), 
    ws.current, connectWebSocket, closeWebSocket, pairName, setPairName
  ];
};

export default UseWebSocketStatus;