import { Fragment, useState } from "react";
import { DivElement, RouterLink, TextWrapper } from "../../Reusable";
import { connect } from "react-redux";
import { BlueNewMoreNavbarTag } from "../../../assets/svgs";

const Tag = ({ tag }) => {
  return (
    <DivElement bgGreen height="20px" displayCenter padding="0 4px" borderRadius="5px">
      <TextWrapper fontWhite fontSize="10px" lineHeight="16px" letterSpacing="1.2px">
        {tag}
      </TextWrapper>
    </DivElement>
  );
};

const MoreLink = ({ nav, onClickHandler }) => {
  const [isHover, setIsHover] = useState(false);
  // const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  // const userData = useSelector(state => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");
  let splitNav =  !nav?.isOpenInOtherTab && nav.path.split("/");
  
  return (
    <DivElement
      marginBottom="16px"
      onMouseOut={() => setIsHover(false)}
      onMouseOver={() => setIsHover(true)}
    >
      <RouterLink to={
        (!nav?.isOpenInOtherTab) ?
        `${nav.path.replace(splitNav[1], prevLang)}` : nav.path
      } onClick={(e) => { 
        // if (isLoggedIn && (nav.path === "/campaigns" || nav.path === "/referral" || nav.path === "/trading-competition" || nav.path === "/setting/reports")){
        if ((!nav?.isOpenInOtherTab)){
          nav.path = `${nav.path.replace(splitNav[1], prevLang)}`;
          onClickHandler(e, nav);
        }else {
          onClickHandler(e, nav);
        }
      }}>
        <DivElement flex justifyContent="space-between" alignItem="flex-start">
          <DivElement width="100%" height="38px" dAlignCenter>
            <DivElement marginRight="12px">
              {nav.icon({ fillBlue: isHover ? true : null })}
            </DivElement>

            <DivElement height="100%">
              <TextWrapper blue={isHover ? true : null} fontSize="14px" lineHeight="20px">
                {nav.title}
              </TextWrapper>
              <TextWrapper
                blue={isHover ? true : null}
                fontSize="12px"
                fontWeight="400"
                lineHeight="18px"
              >
                {nav.subtitle}
              </TextWrapper>
            </DivElement>
          </DivElement>
          {/* {nav.title === "Trading competition" && (
            <BlueNewMoreNavbarTag color="white" width="33px" height="20px" />
          )} */}
          {nav.title === "Rewards Hub" && (
            <BlueNewMoreNavbarTag color="white" width="33px" height="20px" />
          )}
          {nav?.tag && <Tag tag={nav.tag} />}
        </DivElement>
      </RouterLink>
    </DivElement>
  );
};

const CategoryContainer = ({ data, onClickHandler, isLoggedIn }) => {
  return (
    <DivElement width="236px" padding="16px 0">
      <TextWrapper secondary fontSize="12px" fontWeight="400" lineHeight="18px" marginBottom="12px">
        {data.type}
      </TextWrapper>
      {data.value.map((nav, i) => (
        <Fragment key={i}>
          {(isLoggedIn || !nav?.hideOnLogout) && (
            <MoreLink nav={nav} onClickHandler={onClickHandler} />
          )}
        </Fragment>
      ))}
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps)(CategoryContainer);
