/**
 * Import Other Modules
*/
import apiConstants from "../../constants/apiConstants";

/**
*
*  @param {Logout The User} action
*/
export function watchList(data) {
  return {
    type: apiConstants.GET_WATTCHLIST_DATA,
    payload: data
  };
} 