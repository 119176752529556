/* eslint-disable no-console */
import { Fragment } from "react";

import { Pagination } from "../../../components/Reusable";
import { TableLoader } from "../../../components/Loader";
import moment from "moment";
import { formatNumbers } from "../../../utils/helpers";
import {
  SpanElement,
  TextWrapper,
  DivElement,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td
} from "../../../components/Reusable";
import { ReferralNotFoundIcon, UpDownIcon } from "../../../assets/svgs";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";

const EarningOverviewTable = ({
  referralData,
  isLoading,
  setAscending,
  ascending,
  width,
  page,
  setPage
}) => {
  return (
    <BannerBox CardWhite>
      <DivElement minHeight="300px">
        {!isLoading && (
          <Table bRadiusTop="5px" bRadiusBottom="5px">
            <Thead fontSize="14px" fontSizeMd="12px" paddingTopMd="8px" verticalAlignMd="top">
              <Tr height="42px">
                <Th
                  width="348px"
                  widthMd="137px"
                  widthSm="88px"
                  textAlign="left"
                  paddingLeft="23px"
                  paddingLeftMd="15px"
                  paddingLeftSm="12px"
                >
                  User ID
                </Th>
                <Th textAlign="left" textAlignMd="center">
                  <DivElement
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    justifyContentMd="center"
                    width="348px"
                    gap="10px"
                    widthMd="100%"
                    widthSm="100%"
                  >
                    <DivElement>
                      Trading Date{" "}
                      <SpanElement fontSize="12px" fontSizeMd="10px">
                        (DD/MM/YY, 24h)
                      </SpanElement>
                    </DivElement>
                    {width > 767 && (
                      <UpDownIcon
                        width="19px"
                        height="19px"
                        pointer
                        onClick={() => setAscending(!ascending)}
                      />
                    )}
                  </DivElement>
                </Th>

                <Fragment>
                  <Th
                    width="348px"
                    widthMd="188px"
                    widthSm="115px"
                    textAlign="right"
                    paddingRight="32px"
                    paddingRightMd="20px"
                    paddingRightSm="7px"
                  >
                    Value
                  </Th>
                </Fragment>
              </Tr>
            </Thead>
            <Tbody fontSize="16px" fontSizeMd="14px" fontSizeSm="12px">
              {referralData &&
                referralData.data &&
                referralData.data
                  .sort((a, b) =>
                    ascending
                      ? a.CreatedAt.valueOf() - b.CreatedAt.valueOf()
                      : b.CreatedAt.valueOf() - a.CreatedAt.valueOf()
                  )
                  .map((item, i) => (
                    <Tr key={i} height="56px">
                      <Td textAlign="left" paddingLeft="23px" paddingLeftMd="15px">
                        {item.Tsource || ""}
                      </Td>
                      <Td textAlign="left" textAlignMd="center">
                        <TextWrapper fontSize="16px" fontSizeMd="14px" fontSizeSm="12px">
                          {moment(item.CreatedAt * 1000).format("DD/MM/YY")}
                        </TextWrapper>
                        <TextWrapper fontSize="14px" fontSizeMd="12px" secondary>
                          {moment(item.CreatedAt * 1000).format("HH:mm")}
                        </TextWrapper>
                      </Td>

                      <Td
                        textAlign="right"
                        paddingRight="32px"
                        paddingRightMd="20px"
                        widthSm="115px"
                        paddingRightSm={"7px"}
                      >
                        <span style={{ marginRight: "2px" }}>$</span>
                        {formatNumbers(item.AmountUsd)}
                      </Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        )}

        {!isLoading && referralData?.data?.length === 0 && (
          <BannerBox display="flex" alignItems="center" justifyContent="center" mtMd="20px">
            <ReferralNotFoundIcon
              height={"180px"}
              width={"160px"}
              heightMd={"120px"}
              widthMd={"120px"}
              widthSm={"80px"}
              heightSm={"60px"}
            />
          </BannerBox>
        )}

        {isLoading && <TableLoader rows={5} webCol={3} tabletCol={3} mobileCol={3} />}
      </DivElement>
      {referralData && referralData.data && (
        <DivElement width="100%">
          <Pagination
            page={page}
            totalCount={referralData.count || 0}
            setPage={setPage}
            itemPerPage={5}
            marginTop="47px"
            marginTopMd="36px"
            marginTopSm="32px"
          />
        </DivElement>
      )}
    </BannerBox>
  );
};

export default EarningOverviewTable;
