/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingButton } from "../../components/Loader";
import { getUserTradeHistory, resetFilterOrders } from "../../actions/trade";
import { trackPage } from "../../tracking/track";
import OpenOrderPageLoader from "./OrderLoader/OpenOrderLoader";
import { noExponents, numberWithCommas } from "../../utils/general";
import { BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
// import { formatNumbers } from "../../utils/helpers";
import { selectedPair } from "../../actions/trade";
import { useWindowSize } from "../../Hooks/CheckWidthHeight";
import { OrderExecutionRow } from "../../assets/StyleComponents/Table/Order/OpenExecution.style";
import { Pagination } from "../../components/Reusable";
// import { Fragment } from "react";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { Table, ThDropdown, Td, Tbody, Th, Thead } from "../../components/Reusable";
import NoRecordsFound from "../../components/NoRecordsFound/noRecordsFound";
import FilterOrders from "./FilterOrders/FilterOrders";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";

function TradeHistory(props) {
  let { isUserTradesFetching, userTrades, userTradesCount, isTheme } = props;
  const [initialLoad, setInitialLoad] = useState(true);
  const [offset, setOffset] = useState(1);
  const location = useLocation();
  const history = useHistory();
  const param = useParams();
  const { width } = useWindowSize();
  const [dropdownOptions, setDropdownOptions] = useState(["Amount", "Date", "Fee"]);
  const [activeCol, setActiveCol] = useState("Amount");
  const dispatch = useDispatch();
  const userData = useSelector(state => state.dashboard.userData);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    let data = {
      // pair: "ETH/BTC",
      offset: 1
    };
    // props.getUserTradeHistory(data);
    if(isLoggedIn) {
      dispatch(fetchAvailableLanguagesRequest());
      // let checkPath = location.pathname?.split("/")[1];
      // if(checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage){
      //   history.push(`/${userData.preferredLanguage}/order/${param.tab}`);
      // }
    }
    setInitialLoad(false);
    let page = location.pathname + location.search;
    trackPage(page);

    return () => dispatch(resetFilterOrders());
  }, []);

  useEffect(() => {
    if (width < 768) {
      setDropdownOptions([...dropdownOptions, "Price", "Type"]);
    }
  }, [width]);

  useEffect(() => {
    // let data = {
    //   // pair: "ETH/BTC",
    //   offset: page
    // };
    // props.getUserTradeHistory(data);
    onApplyFilter();
  }, [offset]);

  const onApplyFilter = () => {
    let filter = {};
    let { pairs, fromDate, toDate, side } = props.filtersOrders;
    if (pairs) filter.pairs = pairs;
    if (fromDate && toDate) {
      filter.fromDate = fromDate;
      filter.toDate = toDate;
    }
    if (side) filter.side = side;
    if (offset) filter.offset = offset;
    if (Object.keys(filter).length > 0) {
      onApplyFilterHanlder(filter);
    }
  };

  const onApplyFilterHanlder = (givenFilterData) => {
    props.getUserTradeHistory(givenFilterData);
  };

  // const handleHeader = (props) => {
  //   setTimeout(() => {
  //     setActiveMobileTab(props);
  //     setShowDropDown(false);
  //   }, 0);
  // };

  const changePairName = (pairData) => {
    props.selectedPair(pairData);
    history.push(`${prevLang ? `/${prevLang}` : "/en"}/trade/${pairData.base_pair}-${pairData.quote_pair}`);
  };

  let buttonDisabled = false;
  let ListCount = 25;

  if (userTrades && (userTrades?.length < 1 || userTrades?.length % ListCount !== 0))
    // eslint-disable-next-line no-unused-vars
    buttonDisabled = true;
  // eslint-disable-next-line no-unused-vars
  let last = userTradesCount > 2 ? Math.floor((userTradesCount - 1) / ListCount) + 1 : 1;

  return (
    <>
      {!initialLoad && (
        <>
          <BannerBox width="100%" mb="35px" mt="14px" padding="0px 10px" mbLg="25px" mtMd="25px">
            <BannerHeading
              weight="bold"
              BodyColor
              size="1.5rem"
              mb="8px"
              sizeLg="24px"
              sizeMd="18px"
            >
              {" "}
              Order Executions{" "}
            </BannerHeading>
            <ParaTag txtGrey weight="500" sizeMd="12px" size="16px">
              Track your executed orders here!
            </ParaTag>
          </BannerBox>
          <FilterOrders onApplyFilterHanlder={onApplyFilterHanlder} />
        </>
      )}

      {isUserTradesFetching && <OpenOrderPageLoader status={"openExec"} />}

      {!isUserTradesFetching && (
        <>
          <BannerBox display="flex" flex="0 0 100%" direction="column">
            <Table bRadius width="100%" marginBottom="16px" TableBodyRowHeight>
              <Thead display="flex" height="40px" fontSize="14px" fontSizeMd="12px">
                <OrderExecutionRow displayFlex OrdersPagesHeadRow>
                  {width > 1025 && (
                    <Th scope="col">
                      Time{" "}
                      <BannerHeadingSpan size="10px" ml="4px">
                        {" "}
                        (DD/MM/YY, 24h){" "}
                      </BannerHeadingSpan>
                    </Th>
                  )}
                  <Th scope="col">Pair</Th>
                  {width > 767 && <Th scope="col">Type</Th>}
                  {width > 1024 && <Th scope="col">Price</Th>}
                  <Th scope="col">Side</Th>
                  {width > 767 && width < 1025 && <Th scope="col">Price</Th>}
                  {width > 1025 && <Th scope="col">Amount </Th>}
                  {width > 1025 && <Th scope="col">Fees </Th>}
                  {width < 1025 && (
                    <ThDropdown
                      textAlign={"right"}
                      OPTIONS={dropdownOptions}
                      activeCol={activeCol}
                      selectOption={setActiveCol}
                    />
                  )}
                </OrderExecutionRow>
              </Thead>
              <Tbody fontSizeMd="14px" fontSizeSm="12px" fontSize="14px">
                {!isUserTradesFetching &&
                  userTrades &&
                  userTrades?.length > 0 &&
                  userTrades.map((item, index) => {
                    let { Pair, Price, Side, Amount, OrderType, CreatedAt, Fee, FeeCoin } = item;
                    const splitPair = Pair.split("/");
                    const base = splitPair[0];
                    // FilledPer = FilledPer.toFloor(2);
                    const quote = splitPair[1];
                    item.base_pair = base.toUpperCase();
                    item.quote_pair = quote.toUpperCase();
                    item.base = base.toUpperCase();
                    item.quote = quote.toUpperCase();
                    return (
                      <OrderExecutionRow displayFlex key={index} TableBodyRowHeight>
                        {width > 1025 && (
                          <Td>{moment(CreatedAt * 1000).format("DD/MM/YY, HH:mm")}</Td>
                        )}
                        <Td textAlign="left" fontSizeMd="14px" onClick={() => changePairName(item)}>
                          {base}/
                          <BannerHeadingSpan weight="500" txtGrey>
                            {quote}{" "}
                          </BannerHeadingSpan>
                        </Td>
                        {width > 767 && <Td textAlign="left"> {OrderType} </Td>}
                        {width > 1024 && (
                          <Td textAlign="right" fontSizeMd="14px">
                            {numberWithCommas(noExponents(Price))}
                          </Td>
                        )}
                        <Td
                          textAlign="left"
                          green={Side === "BUY" && true}
                          red={Side === "SELL" && true}
                        >
                          {Side}
                        </Td>
                        {width > 767 && width < 1025 && (
                          <Td textAlign="right" fontSizeMd="14px">
                            {numberWithCommas(noExponents(Price))}
                          </Td>
                        )}
                        {width > 1025 && (
                          <Td fontWeight="500" fontSize="14px" BodyColor textAlign="right">
                            {numberWithCommas(Amount.noExponents())}
                          </Td>
                        )}
                        {width > 1025 && (
                          <Td fontWeight="500" fontSize="14px" BodyColor textAlign="right">
                            {numberWithCommas(noExponents(Fee))} {FeeCoin}
                          </Td>
                        )}
                        {/* dropdown selection starts */}
                        {width < 1025 && activeCol === "Amount" && (
                          <Td textAlign="right">{numberWithCommas(noExponents(Amount))}</Td>
                        )}
                        {width < 767 && activeCol === "Price" && (
                          <Td textAlign="right">{numberWithCommas(noExponents(Price))}</Td>
                        )}
                        {width < 767 && activeCol === "Type" && (
                          <Td textAlign="right">{OrderType}</Td>
                        )}
                        {width < 1025 && activeCol === "Fee" && (
                          <Td textAlign="right">
                            {numberWithCommas(noExponents(Fee))} {FeeCoin}
                          </Td>
                        )}
                        {width < 1025 && activeCol === "Date" && (
                          <Td textAlign="right">
                            {moment(CreatedAt * 1000).format("DD/MM/YY, HH:mm")}
                          </Td>
                        )}
                      </OrderExecutionRow>
                    );
                  })}
                {!isUserTradesFetching && userTrades?.length === 0 && userTrades && (
                  <BannerBox margin="15px 0px">
                    <NoRecordsFound text="No Record Found" />
                  </BannerBox>
                )}
              </Tbody>
            </Table>
            <BannerBox mb="40px" mbMd="30px">
              {!isUserTradesFetching && userTrades && userTrades.length > 0 && (
                <Pagination
                  page={offset}
                  setPage={setOffset}
                  totalCount={userTradesCount}
                  itemPerPage={25}
                />
              )}
            </BannerBox>
          </BannerBox>
        </>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  filtersOrders: state.trade.filtersOrders,
  userTrades: state.trade.userTrades,
  userTradesCount: state.trade.userTradesCount,
  isUserTradesFetching: state.trade.isUserTradesFetching
});

const mapDispatchToProps = (dispatch) => ({
  getUserTradeHistory: (data) => dispatch(getUserTradeHistory(data)),
  selectedPair: (data) => dispatch(selectedPair(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TradeHistory);
