import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const ReactSkeleton = styled(Skeleton)`
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};

  @media (max-width: 1024px) {
    width: ${(props) => (props.widthMd ? `${props.widthMd} !important` : "")};
    height: ${(props) => (props.heightMd ? `${props.heightMd} !important` : "")};
    margin: ${(props) => (props.marginMd ? props.marginMd : "")};
    margin-top: ${(props) => (props.marginTopMd ? props.marginTopMd : "")};
    margin-right: ${(props) => (props.marginRightMd ? props.marginRightMd : "")};
    margin-bottom: ${(props) => (props.marginBottomMd ? props.marginBottomMd : "")};
    margin-left: ${(props) => (props.marginLeftMd ? props.marginLeftMd : "")};
  }
  @media (max-width: 767px) {
    width: ${(props) => (props.widthSm ? `${props.widthSm} !important` : "")};
    height: ${(props) => (props.heightSm ? `${props.heightSm} !important` : "")};
    margin: ${(props) => (props.marginSm ? props.marginSm : "")};
    margin-top: ${(props) => (props.marginTopSm ? props.marginTopSm : "")};
    margin-right: ${(props) => (props.marginRightSm ? props.marginRightSm : "")};
    margin-bottom: ${(props) => (props.marginBottomSm ? props.marginBottomSm : "")};
    margin-left: ${(props) => (props.marginLeftSm ? props.marginLeftSm : "")};
  }
`;

const LoadingSkeleton = (props) => {
  return (
    <ReactSkeleton
      {...props}
      baseColor={props.isDarkTheme ? "#263645" : "#ebebeb"}
      highlightColor={props.isDarkTheme ? "#406180" : "#f5f5f5"}
    />
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(LoadingSkeleton);
