import apiConstants from "../../constants/apiConstants";

// Get Private Onboarding Tasks List
export const getPrivateRewardsTasksList = (param) => ({
  type: apiConstants.GET_PRIVATE_ONBOARDING_REWARDS_TASKS_LIST,
  payload: param
});

// Get Private Regular Rewards Tasks List
export const getPrivateReglarRewardsTasksList = (param) => ({
  type: apiConstants.GET_PRIVATE_REGULAR_REWARDS_TASKS_LIST,
  payload: param
});

// Get Private Regular Rewards Tasks List
export const updateRewardsTab = (param) => ({
  type: apiConstants.UPDATE_REWARDS_TAB,
  payload: param
});

// Post user participated in a reward hub task
export const postUserParticipatedRequest = (param, cardStatus) => ({
  type: apiConstants.POST_USER_PARTICIPATE_REQUEST,
  payload: param,
  payloadCard: cardStatus
});

// Post claim reward
export const postClaimRewardRequest = (param, cardStatus) => ({
  type: apiConstants.POST_CLAIM_REWARD_REQUEST,
  payload: param,
  payloadCard: cardStatus
});

// Get Private Regular Rewards Tasks List
export const resetRewardHubState = () => ({
  type: apiConstants.RESET_REWARD_HUB_STATE
});

// Get Private Regular Rewards Tasks List
export const fetchTotalRewardsData = () => ({
  type: apiConstants.GET_TOTAL_REWARDS_COUNT
});
