/* eslint-disable no-unused-vars */
import React from "react";
import { createPortal } from "react-dom";
import { ToastWrapperStyle } from "../../assets/StyleComponents/Notification.style";

const ToastWrapper = () => {
  return createPortal(<ToastWrapperStyle />, document.getElementById("ToastNotification"));
};

export default ToastWrapper;
