/**
 * Import Other Dependicies
 */
import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  error: {},
  apiLoader: false,
  twoAuthToken: "",
  apiData: "",
  errorGet: false,
  changePasswordSuccess: "",
  keyManagementData: [],
  isFetchingKeyManagementData: false,
  feeListData: {},
  isFetchingFeeListData: false,
  antiPhising: "",
  isAntiPhisingFetching: true,
  // isLoadingAPIList:false,
  activeSession: [],
  isLoadingActiveSession: true,
  isAddressUpdateLoading: false,
  addressUpdateStatus: {
    type: "INITIAL",
    message: ""
  }
};

let initialState = initialStateSchema;
const localData = localStorage.getItem("accessToken");

if (localData && localData !== undefined) {
  try {
    initialState.authToken = localData;
  } catch (e) {
    initialState = initialStateSchema;
  }
}

const settingReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GENERATE_TWO_AUTH_TOKEN:
      newState = {
        ...state,
        apiLoader: true,
        error: {},
        errorGet: false
      };
      return newState;

    case apiConstants.GENERATE_TWO_AUTH_TOKEN_SUCCESS:
      newState = {
        ...state,
        apiLoader: false,
        twoAuthToken: action.response,
        errorGet: false
      };
      return newState;

    case apiConstants.GENERATE_TWO_AUTH_TOKEN_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        apiLoader: false,
        errorGet: false
      };
      return newState;
    case apiConstants.GEN:
      newState = {
        ...state,
        apiLoader: true,
        error: {},
        errorGet: false
      };
      return newState;

    case apiConstants.GET_API_LIST:
      newState = {
        ...state,
        isLoadingAPIList: true,
        errorGet: false,
        apiListsCount: 0
      };
      return newState;

    case apiConstants.GET_API_LIST_SUCCESS:
      newState = {
        ...state,
        isLoadingAPIList: false,
        apiLists: action.response.data.data,
        apiListsCount: action.response.data.totalCount,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_API_LIST_FAIL:
      newState = {
        ...state,
        isLoadingAPIList: false,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_ACTIVESESSIONS_CODE:
      newState = {
        ...state,
        isLoadingActiveSession: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_ACTIVESESSIONS_CODE_SUCCESS:
      newState = {
        ...state,
        isLoadingActiveSession: false,
        activeSession: action.response.data.data,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_ACTIVESESSIONS_CODE_FAIL:
      newState = {
        ...state,
        isLoadingActiveSession: false,
        errorGet: false
      };
      return newState;

    case apiConstants.SETUP_2FA_SUCCESS:
      newState = {
        ...state,
        apiLoader: false,
        user: action.response,
        errorGet: false
      };
      return newState;

    case apiConstants.SETUP_2FA_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        apiLoader: false,
        errorGet: false
      };
      return newState;

    case apiConstants.CHANGE_PASSWORD:
      newState = {
        ...state,
        authLoader: true,
        errorGet: false
      };
      return newState;

    case apiConstants.CHANGE_PASSWORD_SUCCESS:
      newState = {
        ...state,
        authLoader: false,
        changePasswordSuccess: action.response.message,
        errorGet: false
      };

      return newState;

    case apiConstants.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        authLoader: false,
        error: action.response.data,
        errorGet: false
      };

    case apiConstants.GET_KEY_MANAGEMENT:
      newState = {
        ...state,
        isFetchingKeyManagementData: true,
        errorGet: false
      };
      return newState;

    case apiConstants.GET_KEY_MANAGEMENT_SUCCESS:
      newState = {
        ...state,
        keyManagementData: action?.response?.data,
        isFetchingKeyManagementData: false,
        errorGet: false
      };

      return newState;

    case apiConstants.GET_KEY_MANAGEMENT_FAIL:
      return {
        ...state,
        isFetchingKeyManagementData: false,
        error: action?.response?.data,
        errorGet: false
      };

    case apiConstants.UPDATE_FEES_LIST:
    case apiConstants.GET_FEES_LIST:
      newState = {
        ...state,
        isFetchingFeeListData: true,
        errorGet: false
      };
      return newState;

    case apiConstants.UPDATE_FEES_LIST_SUCCESS:
    case apiConstants.GET_FEES_LIST_SUCCESS:
      newState = {
        ...state,
        feeListData: action.response.data,
        isFetchingFeeListData: false,
        errorGet: false
      };

      return newState;

    case apiConstants.UPDATE_FEES_LIST_FAIL:
    case apiConstants.GET_FEES_LIST_FAIL:
      return {
        ...state,
        isFetchingFeeListData: false,
        error: action.response.data,
        errorGet: false
      };

    case apiConstants.CLEAR_USER_ERRORS:
      newState = {
        ...state,
        error: {},
        errorGet: false
      };
      return newState;

    case apiConstants.ERROR_500:
      newState = {
        ...state,
        errorGet: true
      };
      return newState;

    case apiConstants.GET_ANTIPHISING_CODE:
      newState = {
        ...state,
        isAntiPhisingFetching: true,
        error: ""
      };
      return newState;

    case apiConstants.GET_ANTIPHISING_CODE_SUCCESS:
      newState = {
        ...state,
        antiPhising: {
          exists: action.response && action.response.data && action.response.data !== false,
          code: action.response && action.response.data
        },
        isAntiPhisingFetching: false
      };
      return newState;

    case apiConstants.GET_ANTIPHISING_CODE_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        isAntiPhisingFetching: false
      };
      return newState;

    // Order Fees
    case apiConstants.GET_ORDER_FEES:
      newState = {
        ...state,
        isOrderFeesFetching: true,
        orderFees: action.response?.data.data,
        error: ""
      };
      return newState;

    case apiConstants.GET_ORDER_FEES_SUCCESS:
      newState = {
        ...state,
        isOrderFeesFetching: false,
        orderFees: action.response?.data.data,
        error: ""
      };
      return newState;

    case apiConstants.GET_ORDER_FEES_FAIL:
      newState = {
        ...state,
        error: "",
        isOrderFeesFetching: false
      };
      return newState;

    case apiConstants.UPDATE_ANTIPHISING_CODE:
      newState = {
        ...state,
        apiLoader: true,
        error: ""
      };
      return newState;

    case apiConstants.UPDATE_ANTIPHISING_CODE_SUCCESS:
      newState = {
        ...state,
        apiLoader: false,
        apiData: action.response && action.response.data,
        updateApiData: action.response && action.response.data
      };
      return newState;

    case apiConstants.UPDATE_ANTIPHISING_CODE_FAIL:
      newState = {
        ...state,
        error: action.response && action.response.data,
        apiLoader: false
      };
      return newState;

    case apiConstants.CLEAR_ANTIPHISING_API_DATA:
      newState = {
        ...state,
        updateApiData: "",
        apiLoader: false
      };
      return newState;

    case apiConstants.UPDATE_ACTIVESESSIONS_CODE:
      newState = {
        ...state,
        apiLoader: true,
        error: ""
      };
      return newState;

    case apiConstants.UPDATE_ACTIVESESSIONS_CODE_SUCCESS:
      newState = {
        ...state,
        apiLoader: false,
        apiData: action.response.data
      };
      return newState;

    case apiConstants.UPDATE_ACTIVESESSIONS_CODE_FAIL:
      newState = {
        ...state,
        error: action.response.data,
        apiLoader: false
      };
      return newState;

    case apiConstants.POST_USER_ADDRESS_UPDATE_REQUEST:
      newState = {
        ...state,
        isAddressUpdateLoading: true
      };
      return newState;
    case apiConstants.POST_USER_ADDRESS_UPDATE_REQUEST_FAIL:
      newState = {
        ...state,
        isAddressUpdateLoading: false,
        addressUpdateStatus: {
          type: "ERROR",
          message: action?.response?.message
        }
      };
      return newState;
    case apiConstants.POST_USER_ADDRESS_UPDATE_REQUEST_SUCCESS:
      newState = {
        ...state,
        isAddressUpdateLoading: false,
        addressUpdateStatus: {
          type: "SUCCESS",
          message: ""
        }
      };
      return newState;
    case apiConstants.RESET_USER_ADDRESS_UPDATE_STATE:
      newState = {
        ...state,
        isAddressUpdateLoading: false,
        addressUpdateStatus: {
          type: "INITIAL",
          message: ""
        }
      };
      return newState;

    default:
      return state;
  }
};

export default settingReducer;
