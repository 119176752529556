/* eslint-disable no-console */
import React, { Fragment, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import Select from "react-select";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { Animation, Button, CoinLabel, DivElement, TextWrapper } from "../Reusable";
import { TextInput } from "../Reusable/FormInput";
import {
  DropdownIndicator2,
  CustomOptionsForTransferFunds,
  customStylesTransferFunds,
  formattedArray
} from "../ReusedComponent/SelectReused";
import { formatAPIErrors, noExponents, numberWithCommas } from "../../utils/general";
import { formatNumbers } from "../../utils/helpers";
import { transferFundToAccount, transferFundToExchange } from "../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { LoadingButton } from "../Loader";
import { CloseIcon } from "../../assets/svgs";
import { SwapWallet } from "./Components";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
// import { WalletContext } from "../../contexts/WalletPage/BalancePage/wallet";
// import { BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
// import NumberFormat from "react-number-format";
// import { ToolTip } from "../Reusable";

const validationSchema = Yup.object().shape({
  amount: Yup.number().required("Amount is required"),
  twoFa: Yup.string().required("2FA is required")
});

const AmountInputContainer = styled(DivElement)`
  width: 100%;
  position: relative;
  .amount-input {
    position: relative;
    width: 100% !important;
    margin-bottom: 0 !important;

    .fInput__form__input {
      height: 48px !important;
    }
  }
`;

const InternalTransfer = ({
  mainWallet,
  exchWallet,
  setActiveModal,
  coin,
  onClose,
  modalType,
  defaultActiveWallet
}) => {
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(coin);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeWallet, setActiveWallet] = useState(
    defaultActiveWallet ? defaultActiveWallet : "EXCHANGE"
  );
  const [activeWalletData, setActiveWalletData] = useState("");
  // const { updateMainWallet, updateMainWalletCopy } = useContext(WalletContext);
  const isTheme = useSelector((state) => state.theme.isTheme);
  const location = useLocation();
  const path = location.pathname;
  // const history = useHistory();

  useEffect(() => {
    if (path.includes("/trade") && !path.includes("/trade-history")) {
      setActiveWallet("MAIN");
    }
  }, [path]);

  useEffect(() => {
    let walletData = activeWallet === "EXCHANGE" ? exchWallet : mainWallet;
    let coinData = walletData.find((data) => data.coin === selectedCoin.coin);
    setSelectedCoin(coinData);
    setActiveWalletData(walletData);

    // eslint-disable-next-line
  }, [activeWallet]);

  const getInitialValues = () => {
    return {
      amount: "",
      twoFa: ""
    };
  };

  const onSubmitHandler = (values) => {
    if (!selectedCoin) {
      showErrorMsg("Please select the coin");
      return;
    }
    setLoader(true);
    let data = {
      type: "exchange",
      coin: selectedCoin.coin,
      amount: Number(formatNumbers(values.amount, 8)),
      token: values.twoFa
    };
    let transfer;
    if (activeWallet === "MAIN") {
      transfer = transferFundToExchange;
    } else {
      data.type = "main account";
      transfer = transferFundToAccount;
    }

    transfer(data)
      .then((res) => {
        showSuccessMsg(res?.data?.message);
        setSuccessMessage(res?.data?.message);
        setStep(2);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
        setStep(3);
      })
      .finally(() => setLoader(false));
  };

  const onMaxClickHandler = (setFieldValue, name) => {
    let balance = selectedCoin?.balance?.freeBalance;
    if (!balance || balance <= 0) return;
    setFieldValue(name, noExponents(balance));
  };

  const onSwapHandler = (setFieldValue) => {
    if (activeWallet === "EXCHANGE") {
      setActiveWallet("MAIN");
    } else {
      setActiveWallet("EXCHANGE");
    }
    setFieldValue("amount", "");
  };

  const dropdownChangeHandler = (setFieldValue, value) => {
    setFieldValue("amount", "");
    setSelectedCoin(value);
  };

  return (
    <Fragment>
      <DivElement displayBtw>
        <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
          {`Internal Transfer ${
            step === 1 ? selectedCoin.coin : step === 2 ? "Success" : "Failed"
          }`}
        </TextWrapper>
        <CloseIcon
          pointer
          onClick={() => {
            onClose();
          }}
        />
      </DivElement>
      {step === 1 && (
        <DivElement bdGrey borderBottom="1px solid" paddingBottom="8px">
          <TextWrapper fontSize="14px" fontSizeSm="12px" fontWeight="300" lineHeight="20px">
            No transfer fee will be charged for internal transfers
          </TextWrapper>
        </DivElement>
      )}
      {step === 1 && (
        <Fragment>
          {activeWalletData && (
            <Formik
              initialValues={getInitialValues()}
              onSubmit={onSubmitHandler}
              validateOnMount={true}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <SwapWallet
                    activeWallet={activeWallet}
                    onClickHandler={() => onSwapHandler(setFieldValue)}
                  />
                  <DivElement displayBtw marginBottom="2px">
                    <TextWrapper fontSize="14px" fontWeight="400" lineHeight="20px">
                      Amount{" "}
                    </TextWrapper>
                    <TextWrapper
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="20px"
                      fontSizeSm="12px"
                    >
                      {/* {selectedCoin?.balance.freeBalance === 0 && (
                        <BannerHeadingSpan
                          txtPrimary
                          textDecoration="underline"
                          cursorPointer
                          mr="10px"
                          onClick={() => {
                            history.push("/wallet/deposits", {
                              state: { getValue: selectedCoin }
                            });
                            onClose();
                          }}
                        >
                          Deposit {selectedCoin.coin}
                        </BannerHeadingSpan>
                      )} */}
                      <var>{`${
                        activeWallet === "EXCHANGE" ? "Exchange" : "Main"
                      } bal. = ${numberWithCommas(
                        noExponents(selectedCoin?.balance.freeBalance)
                      )} ${selectedCoin.coin}`}</var>
                    </TextWrapper>
                  </DivElement>
                  <DivElement flex alignItems="flex-start" marginBottom="15px" position="relative">
                    <AmountInputContainer>
                      <TextInput
                        name="amount"
                        type="number"
                        value={values.amount}
                        containerClass="amount-input"
                        placeholder="Enter Amount"
                        paddingRight={"80px"}
                        paddingRightMd={"60px"}
                      >
                        <DivElement absolute right="12px" top="14px">
                          <Button
                            type="button"
                            secondaryBlue
                            padding="0"
                            width="60px"
                            widthSm="45px"
                            heightSm="26px"
                            height="32px"
                            fontSize="14px"
                            onClick={() => onMaxClickHandler(setFieldValue, "amount")}
                          >
                            max
                          </Button>
                        </DivElement>
                      </TextInput>
                    </AmountInputContainer>
                    <DivElement width="175px" height="48px" marginTop="5px">
                      <Select
                        components={{ DropdownIndicator2 }}
                        menuPlacement="bottom"
                        chooseLabel={(val) => <CoinLabel {...val} insufficientFunds={true} />}
                        name="selectAsset"
                        isSearchable={false}
                        formatOptionLabel={CustomOptionsForTransferFunds}
                        onChange={(val) => dropdownChangeHandler(setFieldValue, val.id)}
                        styles={customStylesTransferFunds(isTheme)}
                        useStyles={true}
                        options={formattedArray(activeWalletData)}
                        defaultValue={
                          selectedCoin
                            ? {
                                label: selectedCoin.coin,
                                value: selectedCoin
                              }
                            : ""
                        }
                      />
                    </DivElement>
                  </DivElement>

                  <TextInput
                    title="2FA Code"
                    type="number"
                    name="twoFa"
                    value={values.twoFa}
                    placeholder="Enter the code"
                  />

                  <ButtonComponent
                    type="submit"
                    primaryBlue
                    height="48px"
                    width="100%"
                    disabled={Number(selectedCoin?.balance.freeBalance) === 0}
                    opacity={Number(selectedCoin?.balance.freeBalance) === 0 ? "0.4" : "1"}
                    cursor={Number(selectedCoin?.balance.freeBalance) === 0 ? "no-drop" : "pointer"}
                  >
                    {loader ? <LoadingButton /> : `Transfer ${selectedCoin.coin}`}
                  </ButtonComponent>
                </Form>
              )}
            </Formik>
          )}
          {!modalType && (
            <Fragment>
              <DivElement displayCenter margin="6px 0">
                <TextWrapper fontSize="14px" fontWeight="400" lineHeight="20px">
                  or
                </TextWrapper>
              </DivElement>
              <ButtonComponent
                secondaryBlue
                height="48px"
                width="100%"
                fontWeight="500"
                onClick={() => {
                  setActiveModal("DEPOSIT");
                }}
              >
                {`Deposit ${selectedCoin.coin}`}
              </ButtonComponent>
            </Fragment>
          )}
        </Fragment>
      )}

      {(step === 2 || step === 3) && (
        <Fragment>
          <Animation type={step === 2 ? "SUCCESS" : "FAILURE"} />
          <TextWrapper fontSize="14px" fontSizeSm="12px" red={step === 3 ? true : null}>
            {successMessage}
          </TextWrapper>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  mainWallet: state.wallet.wallets?.data,
  exchWallet: state.dashboard.walletBalance,
  loader: state.wallet.apiLoader
});

export default compose(connect(mapStateToProps))(InternalTransfer);
