import React from "react";

import { MainElement } from "../../../assets/StyleComponents/Exchange.style";
import PermotionBanner from "./PermotionBanner/PermotionBanner";
import RewardsTimeOffer from "./RewardsTimeOffer/RewardsTimeOffer";
import RewardsTasks from "./RewardsTasks/RewardsTasks";
import MyRewardsPermotionBanner from "./PermotionBanner/MyRewardsPermotionBanner";

const RewardsHubParent = ({ children }) => {
  return (
    <MainElement ExchangeContainer margin="40px auto">
      {children}
    </MainElement>
  );
};

RewardsHubParent.PermotionBanner = PermotionBanner;
RewardsHubParent.LimitedTimeOffer = RewardsTimeOffer;
RewardsHubParent.RewardsTasks = RewardsTasks;
RewardsHubParent.MyRewardsPermotionBanner = MyRewardsPermotionBanner;

export default RewardsHubParent;
