/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useWindowSize } from '../../../../Hooks/CheckWidthHeight';
import { useSelector } from 'react-redux';


const DynamicAnimatedBorders = () => {
  const [dimensions, setDimensions] = useState({ width: 300, height: 120 });
  const text = "5000€";
  const { width } = useWindowSize();
  const fontSize = useMemo(() => width > 1024 ? "64" : width > 767 ? "48" : "20", [width]);
  const lineGap = width > 767 ? 12 : 32;
  const isDarkTheme = useSelector(state => state.theme.isDarkTheme);
  let memoColor = useMemo(() => isDarkTheme ? "#70a6e0" : "#0470e1" ,  [isDarkTheme]);

  useEffect(() => {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
    textElement.setAttribute("font-size", width > 1024 ? 74 : width > 767 ? 55 : 53);
    textElement.textContent = text;
    svg.appendChild(textElement);
    document.body.appendChild(svg);
    
    const bbox = textElement.getBBox();
    const padding = { 
      x: lineGap * 2,
      y: lineGap * 2
    };
    
    if(width > 767) {
      setDimensions({
        width: bbox.width + padding.x,
        height: bbox.height - padding.y
      });
    } else {
      setDimensions({
        width: 250,
        height: 40
      });
    }
    
    document.body.removeChild(svg);
  }, [text, width]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="relative">
        <svg 
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            minWidth: dimensions.width,
            minHeight: dimensions.height
          }}
           preserveAspectRatio="xMidYMid meet"
        >
          {/* Center Text */}
          <text
            x="50%"
            y="57%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={fontSize}
            fontWeight={"bold"}
            fill={memoColor}
            strokeLinecap='round'
          >
            {text}
          </text>

          {/* Top line: left to right */}
          <path 
            d={`M7 4 H${dimensions.width - 10}`}
            stroke={memoColor}
            strokeWidth="2"
            strokeDasharray={dimensions.width}
            strokeDashoffset={dimensions.width}
            strokeLinecap='round'
          >
            <animate
              attributeName="stroke-dashoffset"
              values={`${dimensions.width};0;0;${dimensions.width};${dimensions.width}`}
              dur="4s"
              repeatCount="indefinite"
              id="topAnim"
            />
            <animate
              attributeName="opacity"
              values="0;1;1;1;0"
              dur="4s"
              repeatCount="indefinite"
            />
          </path>

          {/* Left line: bottom to top (starts with top) */}
          <path 
            d={`M2 ${dimensions.height -2} V4`}
            stroke={memoColor}
            strokeWidth="2"
            strokeDasharray={dimensions.height}
            strokeDashoffset={dimensions.height}
            strokeLinecap='round'
          >
            <animate
              attributeName="stroke-dashoffset"
              values={`${dimensions.height};0;0;${dimensions.height};${dimensions.height}`}
              dur="4s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="opacity"
              values="0;1;1;1;0"
              dur="4s"
              repeatCount="indefinite"
            />
          </path>

          {/* Right line: top to bottom (starts when top reaches middle) */}
          <path 
            d={`M${dimensions.width - 2} 6 V${dimensions.height - 2}`}
            stroke={memoColor}
            strokeWidth="2"
            strokeDasharray={dimensions.height}
            strokeDashoffset={dimensions.height}
            strokeLinecap='round'
          >
            <animate
              attributeName="stroke-dashoffset"
              values={`${dimensions.height};${dimensions.height};${dimensions.height};0;0;${dimensions.height};${dimensions.height}`}
              dur="4s"
              begin="topAnim.begin + 1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="opacity"
              values="0;0;0;1;1;1;0"
              dur="4s"
              begin="topAnim.begin + 1s"
              repeatCount="indefinite"
            />
          </path>

          {/* Bottom line: right to left (starts when top reaches middle) */}
          <path 
            d={`M${dimensions.width - 6} ${dimensions.height - 2} H6`}
            stroke={memoColor}
            strokeWidth="2"
            strokeDasharray={dimensions.width}
            strokeDashoffset={dimensions.width}
            strokeLinecap='round'
          >
            <animate
              attributeName="stroke-dashoffset"
              values={`${dimensions.width};${dimensions.width};${dimensions.width};0;0;${dimensions.width};${dimensions.width}`}
              dur="4s"
              begin="topAnim.begin + 1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="opacity"
              values="0;0;0;1;1;1;0"
              dur="4s"
              begin="topAnim.begin + 1s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    </div>
  );
};

export default DynamicAnimatedBorders;