/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ExpandLessIcon, ExpandMoreIcon } from "../../../assets/svgs";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";

function ApiKeyRow({ ipList }) {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <BannerBox width="30px" onClick={() => setExpand(!expand)}>
        {ipList.length > 1 && <>{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>}
      </BannerBox>

      <BannerBox
        display="flex"
        direction="column"
        gap="10px"
        alignItems="center"
        // onClick={() => setExpand(!expand)}
      >
        {ipList && ipList !== ""
          ? ipList.length > 1 && expand
            ? ipList.map((values, index) => {
                return (
                  <BannerHeadingSpan key={index} py="10px">
                    {" "}
                    {values}
                  </BannerHeadingSpan>
                );
              })
            : ipList[0]
          : "No IP Added Yet"}
      </BannerBox>
    </>
  );
}

export default ApiKeyRow;
