import { DivElement } from "../../../../../components/Reusable";
import OpenOrders from "../../Components/OpenOrders";
import OrderBookFilters from "../../Components/OrderBookFilters";

const OrderBookTab = () => {
  return (
    <DivElement column width="100%" height="100%">
      <OrderBookFilters />
      <OpenOrders containerProps={{ height: "calc(100% - 36px)" }} />
    </DivElement>
  );
};

export default OrderBookTab;
