import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import TradeHistoryTable from "../../Components/TradeHistoryTable";

const TradesSection = () => {
  return (
    <DivElement column bgCard width="100%" height="100%" padding="12px">
      <DivElement displayBtw marginBottom="16px">
        <TextWrapper fontSize="12px" lineHeight="18px" secondary>
          Trade History
        </TextWrapper>
      </DivElement>
      <DivElement flexGrow="1" scrollY>
        <TradeHistoryTable />
      </DivElement>
    </DivElement>
  );
};

export default TradesSection;
