/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Datetime from "react-datetime";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import SelectDropDownComponent, {
  CustomOptionsForCurrency,
  customStylesContinentsForOrders,
  DropdownIndicator,
  MyOptions
} from "../../../../components/SelectDropDown/selectDropDown";
import { WalletContext } from "../../../../contexts/WalletPage/BalancePage/wallet";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { EditCalendarIcon } from "../../../../assets/svgs";
import { useSelector } from "react-redux";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import CustimizeMultiSelectDropDown from "../../../../components/Reusable/ReactMultiSelectDropdown/CustimizeMultiSelectDropDown";
import { DropdownInput } from "../../../../components/Reusable/FormInput";
import SingleDropDownValue from "../../../../components/Reusable/SingleDropDown/SingleDropDown";
import InputWrapper from "../../../../components/Reusable/InputWrapper/InputWrapper";
import { DatePickerStyles } from "../../../../assets/StyleComponents/DatePicker.style";
import DatePickerComponent from "../../../../components/DatePicker/DatePicker";

var yesterday = Datetime.moment();
var valid = function (current) {
  return current.isBefore(yesterday);
};
const Tabs = [
  { id: 1, label: "Deposit", value: "Deposit" },
  { id: 2, label: "Withdrawal", value: "Withdrawal" }
];

function FilterComponent(props) {
  let { exchWallet, callAllWallet } = useContext(WalletContext);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const { width } = useWindowSize();
  const [selectedAssets, setSelectedAssets] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [date, setDate] = useState({ fromDate: null, toDate: null });

  let {
    activeTab,
    currencyList,
    selectedCoin,
    setToDate,
    setFrom,
    setPage,
    from,
    to,
    coinChanged,
    setSelectedCoin,
    callGetDeposits,
    callGetWithdrawal,
    callGetAllList,
    FilterCallApi,
    setCurrencyList,
    setActiveTab
  } = useContext(DepositContext);

  let optionList = useMemo(() => setCurrencyList(exchWallet.data, false), [exchWallet]);

  useEffect(() => {
    callAllWallet();
  }, []);

  useEffect(() => {
    if (selectedCoin !== "") {
      // callApi(selectedCoin, from, to);
      onApplyFilter();
    }
  }, [selectedCoin]);

  useEffect(() => {
    if (date.fromDate && date.toDate) onApplyFilter();
  }, [date]);

  useEffect(() => {
    if (selectedType) {
      setActiveTab(selectedType.label || "All");
      onApplyFilter();
    }
  }, [selectedType]);

  useEffect(() => {
    setFrom(date.fromDate);
    setToDate(date.toDate);
  }, [date]);

  const onApplyFilter = () => {
    let filterData = {};
    if (selectedAssets && selectedAssets?.length > 0) {
      let filterAssets = [];
      selectedAssets.forEach((item) => filterAssets.push(item.value));
      filterData.coins = filterAssets;
    }

    if (selectedType) {
      filterData.type = selectedType.value.toUpperCase();
    }

    if (date.fromDate && date.toDate) {
      if (moment(date.fromDate).format() === moment(date.toDate).format()) {
        filterData.fromDate = moment(date.fromDate).format();
        let toDateSetTime = moment(date.toDate).set({ hour: 23, minute: 59 });
        filterData.toDate = toDateSetTime.format();
      } else {
        filterData.fromDate = moment(date.fromDate).format();
        // filterData.toDate = moment(date.toDate).endOf("day").format();
        filterData.toDate = moment(date.toDate).format();
      }
    }

    if (Object.keys(filterData)?.length >= 1) {
      onApplyFilterHandler(filterData);
    }
  };

  const onApplyFilterHandler = (givenFilterData) => {
    setPage(0);
    FilterCallApi(givenFilterData);
  };

  const handleDepositCurrency = (coinData) => {
    setPage(0);
    coinChanged(coinData);
  };

  const clearAll = () => {
    setFrom("");
    setToDate("");
    setSelectedCoin("");
    setActiveTab("All");
    setSelectedType("");
    setSelectedAssets("");
    setDate({ fromDate: null, toDate: null });
    setPage(0);
    // if (activeTab === "All") {
    let param = {
      coin: "",
      page: 0
    };
    callGetAllList(param);
    // }
    // if (activeTab === "Deposit") {
    //   let param = {
    //     coin: "",
    //     page: 0
    //   };
    //   callGetDeposits(param);
    // }
    // if (activeTab === "Withdrawal") {
    //   let param = {
    //     coin: "",
    //     page: 0
    //   };
    //   callGetWithdrawal(param);
    // }
  };

  // useEffect(() => {
  //   if (!(from === "" && to === "")) {
  //     setPage(0);
  //     callApi(selectedCoin, from, to);
  //   }
  // }, [from, to]);

  // const validToDate = (current) => {
  //   if (from) {
  //     return (
  //       valid(current) &&
  //       (moment(current).isAfter(moment(from)) || moment(current).isSame(moment(from)))
  //     );
  //   }
  //   return valid(current);
  // };

  // const validFromDate = (current) => {
  //   if (to) {
  //     return (
  //       valid(current) &&
  //       (moment(current).isBefore(moment(to)) || moment(current).isSame(moment(to)))
  //     );
  //   }
  //   return valid(current);
  // };

  // const selectFromDate = (fromDate) => {
  //   setFrom(moment(fromDate).format());
  //   setDate({
  //     ...date,
  //     fromDate: moment(fromDate).format()
  //   });
  // };

  // const selectToDate = (toDate) => {
  //   setToDate(moment(toDate).endOf("day").format());
  //   setDate({
  //     ...date,
  //     toDate: moment(toDate).endOf("day").format()
  //   });
  // };

  // const callApi = (coinData, fromDate, toDate) => {
  //   if (activeTab === "Withdrawal") {
  //     let param = {
  //       page: 0
  //     };
  //     if (coinData) param.coin = coinData.coin || "";
  //     if (fromDate) param.from = fromDate;
  //     if (toDate) param.to = toDate;
  //     callGetWithdrawal(param);
  //   }
  //   if (activeTab === "All") {
  //     let param = {
  //       page: 0
  //     };
  //     if (coinData) param.coin = coinData.coin || "";
  //     if (fromDate) param.from = fromDate;
  //     if (toDate) param.to = toDate;
  //     callGetAllList(param);
  //   } else if (activeTab === "Deposit") {
  //     let param = {
  //       page: 0
  //     };
  //     if (coinData) param.coin = coinData.coin || "";
  //     if (fromDate) param.from = fromDate;
  //     if (toDate) param.to = toDate;
  //     callGetDeposits(param);
  //   }
  // };

  useEffect(() => {
    let param = {
      coin: selectedAssets
    };
    coinChanged(selectedAssets);
    setSelectedAssets(selectedAssets);
    setSelectedCoin(param);
  }, [selectedAssets]);

  return (
    <BannerBox width="100%" mb="32px">
      <BannerBox display="flex" width="75%" widthLg="100%" justifyContent="space-between">
        <ParaTag size="14px" txtGrey>
          {" "}
          Filters{" "}
        </ParaTag>
        {((selectedCoin && selectedCoin.coin?.length > 0) ||
          date.fromDate ||
          date.toDate ||
          selectedType.label) && (
          <ParaTag size="14px" txtDanger cursor="pointer" onClick={clearAll}>
            Clear All
          </ParaTag>
        )}
      </BannerBox>

      <BannerBox
        width="75%"
        widthLg="100%"
        display="flex"
        flexWrapLg="wrap"
        justifyContent="space-between"
        alignItems="center"
        flex="1"
        gap="24px"
        // className="col-md-12 d-flex flex-fill px-0 col-lg-9"
      >
        <BannerBox width="180px" widthLg="45%" mt="6px">
          <CustimizeMultiSelectDropDown
            WalletStatus={true}
            // setSelectedOptions={handleDepositCurrency}
            setSelectedOptions={setSelectedAssets}
            options={currencyList}
            placeholder={width > 767 ? "Select Asset" : "Asset"}
            value={selectedAssets}
          />
        </BannerBox>

        {width < 1025 && (
          <BannerBox mt={"6px"} mtMd={"6px"} width="180px" widthLg="45%">
            <SingleDropDownValue
              menuCloseOnSelect="true"
              setSelectedOptions={setSelectedType}
              options={Tabs}
              placeholder={"Type"}
              value={selectedType}
            />
          </BannerBox>
        )}

        <BannerBox
          mt={"6px"}
          mtMd={"6px"}
          width="180px"
          widthLg="45%"
          height="44px"
          position="relative"
          // className="form-group pd-rem-l-5-3 pd-rem-md-l-1 wth-md-per-36 position-relative mb-0 from-part"
        >
          <DatePickerComponent
            quickFilterStatus="true"
            threeMonthCheck={false}
            givenDate={date}
            selectedDate={date.fromDate}
            setQuickFilterValue={({ fromDate, toDate }) => {
              setDate({ fromDate, toDate });
            }}
            name="From"
          />
          {/* <DatePickerStyles
            inputProps={{
              readOnly: true,
              placeholder: "From",
              className: "inputMain"
            }}
            renderInput={(props) => {
              return (
                <InputWrapper
                  {...props}
                  // InputBottomBorder
                  value={from && moment(from).format("DD-MM-YYYY")}
                  style={{ width: "100%" }}
                />
              );
            }}
            closeOnSelect={true}
            dateFormat="DD-MM-YYYY"
            isValidDate={validFromDate}
            timeFormat={false}
            onChange={(fromDate) => {
              if (typeof fromDate !== "object") return;
              selectFromDate(fromDate);
            }}
            selected={from !== "" ? from : ""}
            // value={fromDate !== "" ? fromDate : ""}
            isClearable={from !== "" ? false : true}
          /> */}
          <BannerBox
            position="absolute"
            top="7px"
            right="16px"
            zIndex="1"
            className="position-absolute exch-wallet-date-icon3"
          >
            <EditCalendarIcon className="img-fluid wth-md-16" />
          </BannerBox>
        </BannerBox>

        <BannerBox
          mt={"6px"}
          mtMd={"6px"}
          // paddLeftLg="20px"
          // paddLeftMd="8px"
          width="180px"
          widthLg="45%"
          height="44px"
          position="relative"
          // className="form-group pd-rem-l-5-3 pd-md-l-4 wth-md-per-36 position-relative mb-0"
        >
          <DatePickerComponent
            quickFilterStatus="true"
            threeMonthCheck={false}
            givenDate={date}
            selectedDate={date.toDate}
            setQuickFilterValue={({ fromDate, toDate }) => {
              setDate({ fromDate, toDate });
            }}
            name="To"
          />
          {/* <DatePickerStyles
            inputProps={{
              readOnly: true,
              placeholder: "To",
              className: "inputMain"
            }}
            renderInput={(props) => {
              return (
                <InputWrapper
                  {...props}
                  // InputBottomBorder
                  value={to && moment(to).format("DD-MM-YYYY")}
                  style={{ width: "100%" }}
                />
              );
            }}
            closeOnSelect={true}
            dateFormat="DD-MM-YYYY"
            timeFormat={false}
            isValidDate={validToDate}
            onChange={(toDate) => {
              if (typeof toDate !== "object") return;
              selectToDate(toDate);
              // this.setState({ toDate: moment(toDate).format() });
            }}
            value={to}
          /> */}
          <BannerBox
            position="absolute"
            top="7px"
            right="16px"
            zIndex="1"
            className="position-absolute exch-wallet-date-icon3"
          >
            <EditCalendarIcon className="img-fluid wth-md-16" />
          </BannerBox>
        </BannerBox>

        {width > 1024 && (
          <BannerBox mt={"6px"} mtMd={"6px"} width="180px" widthLg="45%">
            <SingleDropDownValue
              menuCloseOnSelect="true"
              setSelectedOptions={setSelectedType}
              options={Tabs}
              placeholder={"Type"}
              value={selectedType}
            />
          </BannerBox>
        )}
      </BannerBox>
    </BannerBox>
  );
}

export default FilterComponent;
