import { DivElement, TextWrapper } from "../../Reusable";
import { SwapIcon } from "../../../assets/svgs";

const SwapWallet = ({ activeWallet, onClickHandler }) => {
  return (
    <DivElement flex marginBottom="40px" paddingTop="24px">
      <DivElement width="100%">
        <DivElement displayBtw marginBottom="16px">
          <TextWrapper
            fontSize="14px"
            fontWeight="400"
            lineHeight="20px"
            minWidth="44px"
            minWidthSm="41px"
          >
            From
          </TextWrapper>
          <DivElement
            padding="8px 16px"
            height="40px"
            width="100%"
            bdGrey
            border="1px solid"
            borderRadius="5px"
          >
            {activeWallet === "EXCHANGE" ? "Exchange" : "Main"} Balance
          </DivElement>
        </DivElement>
        <DivElement displayBtw>
          <TextWrapper
            fontSize="14px"
            fontWeight="400"
            lineHeight="20px"
            minWidth="44px"
            minWidthSm="41px"
          >
            To
          </TextWrapper>
          <DivElement
            padding="8px 16px"
            height="40px"
            width="100%"
            bdGrey
            border="1px solid"
            borderRadius="5px"
          >
            {activeWallet === "EXCHANGE" ? "Main" : "Exchange"} Balance
          </DivElement>
        </DivElement>
      </DivElement>
      <DivElement dAlignCenter paddingLeft="8px">
        <SwapIcon
          width="32px"
          widthSm="24px"
          height="32px"
          heightSm="24px"
          pointer
          onClick={onClickHandler}
        />
      </DivElement>
    </DivElement>
  );
};

export default SwapWallet;
