let initialChartData = localStorage.getItem("chartData");
if (!initialChartData) {
  initialChartData = {
    charts: [],
    studyTemplates: [],
    drawingTemplates: [],
    chartTemplates: []
  };
} else {
  initialChartData = JSON.parse(initialChartData);
}

const saveLoadAdapter = {
  chartData: initialChartData,

  saveChartDataToLocalStorage: function () {
    localStorage.setItem("chartData", JSON.stringify(this.chartData));
  },

  getAllCharts: function () {
    return Promise.resolve(this.chartData.charts);
  },

  removeChart: function (id) {
    for (var i = 0; i < this.chartData.charts.length; ++i) {
      if (this.chartData.charts[i].id === id) {
        this.chartData.charts.splice(i, 1);
        this.saveChartDataToLocalStorage();
        return Promise.resolve();
      }
    }

    return Promise.reject();
  },

  saveChart: function (data) {
    if (!data.id) {
      data.id = Math.random().toString();
    } else {
      this.removeChart(data.id);
    }

    this.chartData.charts.push(data);
    this.saveChartDataToLocalStorage();
    return Promise.resolve(data.id);
  },

  getChartContent: function (id) {
    for (var i = 0; i < this.chartData.charts.length; ++i) {
      if (this.chartData.charts[i].id === id) {
        this.saveChartDataToLocalStorage();
        return Promise.resolve(this.chartData.charts[i].content);
      }
    }

    return Promise.reject();
  },

  removeStudyTemplate: function (studyTemplateData) {
    for (var i = 0; i < this.chartData.studyTemplates.length; ++i) {
      if (this.chartData.studyTemplates[i].name === studyTemplateData.name) {
        this.chartData.studyTemplates.splice(i, 1);
        this.saveChartDataToLocalStorage();
        return Promise.resolve();
      }
    }

    return Promise.reject();
  },

  getStudyTemplateContent: function (studyTemplateData) {
    for (var i = 0; i < this.chartData.studyTemplates.length; ++i) {
      if (this.chartData.studyTemplates[i].name === studyTemplateData.name) {
        this.saveChartDataToLocalStorage();
        return Promise.resolve(this.chartData.studyTemplates[i]);
      }
    }

    return Promise.reject();
  },

  saveStudyTemplate: function (studyTemplateData) {
    for (var i = 0; i < this.chartData.studyTemplates.length; ++i) {
      if (this.chartData.studyTemplates[i].name === studyTemplateData.name) {
        this.chartData.studyTemplates.splice(i, 1);
        break;
      }
    }

    this.chartData.studyTemplates.push(studyTemplateData);
    this.saveChartDataToLocalStorage();
    return Promise.resolve();
  },

  getAllStudyTemplates: function () {
    return Promise.resolve(this.chartData.studyTemplates);
  },

  removeDrawingTemplate: function (toolName, templateName) {
    for (var i = 0; i < this.chartData.drawingTemplates.length; ++i) {
      if (this.chartData.drawingTemplates[i].name === templateName) {
        this.chartData.drawingTemplates.splice(i, 1);
        this.saveChartDataToLocalStorage();
        return Promise.resolve();
      }
    }

    return Promise.reject();
  },

  loadDrawingTemplate: function (toolName, templateName) {
    for (var i = 0; i < this.chartData.drawingTemplates.length; ++i) {
      if (this.chartData.drawingTemplates[i].name === templateName) {
        this.saveChartDataToLocalStorage();
        return Promise.resolve(this.chartData.drawingTemplates[i].content);
      }
    }

    return Promise.reject();
  },

  saveDrawingTemplate: function (toolName, templateName, content) {
    for (var i = 0; i < this.chartData.drawingTemplates.length; ++i) {
      if (this.chartData.drawingTemplates[i].name === templateName) {
        this.chartData.drawingTemplates.splice(i, 1);
        break;
      }
    }

    this.chartData.drawingTemplates.push({ name: templateName, content: content });
    this.saveChartDataToLocalStorage();
    return Promise.resolve();
  },

  getDrawingTemplates: function () {
    return Promise.resolve(
      this.chartData.drawingTemplates.map(function (template) {
        return template.name;
      })
    );
  },

  async getAllChartTemplates() {
    return this.chartData.chartTemplates.map((x) => x.name);
  },

  async saveChartTemplate(templateName, content) {
    const theme = this.chartData.chartTemplates.find((x) => x.name === templateName);

    if (theme) {
      theme.content = content;
    } else {
      this.chartData.chartTemplates.push({ name: templateName, content });
    }

    this.saveChartDataToLocalStorage();
  },

  async removeChartTemplate(templateName) {
    this.chartData.chartTemplates = this.chartData.chartTemplates.filter(
      (x) => x.name !== templateName
    );
    this.saveChartDataToLocalStorage();
  },

  async getChartTemplateContent(templateName) {
    const theme = {};

    const content = this.chartData.chartTemplates.find((x) => x.name === templateName)?.content;

    if (content) {
      theme.content = structuredClone(content);
    }

    return theme;
  }
};

export default saveLoadAdapter;
