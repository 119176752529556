import * as React from "react";
import "./index.css";
import Datafeed from "./api/";
import { widget } from "./charting_library/";
import saveLoadAdapter from "./api/saveLoadAdapter";
import {
  darkThemeOverrides,
  disabled_features,
  enabled_features,
  lightThemeOverrides,
  studies_overrides
} from "./constants";
import { LoadingButton } from "../Loader";
import { DivElement } from "../Reusable";
import { connect } from "react-redux";

const SAVED_CHART_NAME = "LCX_CHART";

const DEFAULT_PAIR = "LCX/EUR";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
}

class TradingViewChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loader: true
    };
  }

  static defaultProps = {
    symbol: DEFAULT_PAIR,
    interval: "60",
    containerId: "tv_chart_container",
    libraryPath: "/charting_library/",
    datafeedUrl: "https://demo_feed.tradingview.com",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    disabled_features: disabled_features,
    enabled_features: enabled_features,
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    theme: "dark"
  };

  tvWidget = null;

  componentDidMount() {
    this.initializeTVChart();
  }

  componentDidUpdate() {
    const { isDarkTheme } = this.props;
    if (this.props.selectedPair && this.tvWidget && this.tvWidget._ready) {
      this.tvWidget.chart().setSymbol(this.props.selectedPair.symbol);
      this.saveChart();
    }

    if (this.tvWidget && this.tvWidget.changeTheme && this.tvWidget._ready) {
      this?.tvWidget?.changeTheme(isDarkTheme ? "dark" : "light");
      setTimeout(() => {
        this?.tvWidget?.applyOverrides(isDarkTheme ? darkThemeOverrides : lightThemeOverrides);
      }, 0);
    }
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  initializeTVChart() {
    let { isDarkTheme } = this.props;
    const widgetOptions = {
      debug: false,
      symbol: this.props.selectedPair.symbol,
      interval: this.props.interval,
      datafeed: Datafeed,
      theme: isDarkTheme ? "dark" : "light",
      container: this.props.containerId,
      library_path: this.props.libraryPath,
      autosize: true,
      custom_css_url: "./index.css",
      locale: getLanguageFromURL() || "en",
      disabled_features: this.props.disabled_features,
      enabled_features: this.props.enabled_features,
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      save_load_adapter: saveLoadAdapter,
      load_last_chart: true,
      auto_save_delay: 0,
      hide_side_toolbar: true,
      studies_overrides: studies_overrides,
      overrides: isDarkTheme ? darkThemeOverrides : lightThemeOverrides,
      loading_screen: { backgroundColor: "#red" }
    };

    if (this.props.mobileTv) {
      widgetOptions.disabled_features = [
        ...widgetOptions.disabled_features,
        "header_screenshot",
        "header_fullscreen_button"
      ];
    }

    // mobile view functionality
    if (window.innerWidth < 700) {
      widgetOptions.enabled_features.push("hide_left_toolbar_by_default");
    }
    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      this.setState({ loader: false });
    });

    // Whenever there's a change in the tv chart, this will get executed after 5s
    tvWidget.subscribe("onAutoSaveNeeded", () => {
      this.saveChart();
    });

    // When any changes in indicator, this will get executed immediately
    tvWidget.subscribe("study", () => {
      this.saveChart();
    });

    this.tvWidget = tvWidget;
  }

  saveChart() {
    if (!this.tvWidget) return;
    this.tvWidget.saveChartToServer(
      // Callback for success
      () => {},
      // Callback for failure
      () => {},
      { chartName: SAVED_CHART_NAME }
    );
  }

  render() {
    let { loader } = this.state;
    let { isDarkTheme } = this.props;

    return (
      <DivElement relative width="100%" height="100%">
        <DivElement id={this.props.containerId} height="100%" />
        {loader && (
          <DivElement bgCard displayCenter top="0" absolute width="100%" height="100%">
            <LoadingButton blue={isDarkTheme ? null : true} />
          </DivElement>
        )}
      </DivElement>
    );
  }
}

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  selectedPair: state.trading.selectedPair
});

export default connect(mapStateToProps)(TradingViewChart);
