import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { residentialProof } from "../../../../lib/api";
import { formatAPIErrors } from "../../../../utils/general";
import WebCamCapture from "../WebCamCapture";
import Uploader from "../Uploader";
import KycFAQ from "../KycFAQ";
import { dataURLtoFile } from "../../../../utils";
import KycStepButton from "./KycStepBtn";
import { getUserDetails } from "../../../../actions/dashboard";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";

const KycStep4 = ({ getUserData, guidlinesModal }) => {
  const [addressProof, setAddressProof] = useState("");
  const [selectType, setSelectType] = useState("");
  const [documentType, setDocumentType] = useState("pdf");
  const [uploadLoader, setUploadLoader] = useState(false);
  const [img, setImg] = useState({});
  const [openWebcam, setOpenWebcam] = useState(false);
  const [capturedImg, setCapturedImg] = useState("");

  const onUpload = async (img) => {
    let imgUrl = URL.createObjectURL(img.target.files[0]);
    if (
      img.target.files[0].name.includes("jpg") ||
      img.target.files[0].name.includes("png") ||
      img.target.files[0].name.includes("jpeg") ||
      img.target.files[0].name.includes("pdf")
    ) {
      setDocumentType(img.target.files[0].name.includes("pdf") ? "pdf" : "image");
      setImg(img.target.files[0]);
      setAddressProof(imgUrl);
    } else {
      showErrorMsg("Invalid file format");
    }
  };

  const onCapture = () => {
    let image = dataURLtoFile(capturedImg, "selfie.jpeg");
    setDocumentType("image");
    setImg(image);
    setAddressProof(capturedImg);
  };

  const submitData = () => {
    setUploadLoader(true);
    residentialProof(img, "ADDRESSPROOF", selectType, (progress) => {})
      .then(async (res) => {
        showSuccessMsg("Address proof uploaded successfully");
        getUserData();
      })
      .catch((error) => {
        showErrorMsg(formatAPIErrors(error));
      })
      .finally(() => {
        setUploadLoader(false);
      });
  };

  const onCaptureAgain = () => {
    if (uploadLoader) {
      return;
    }
    const inputs = document.querySelectorAll(".kycsteps__uploader__circle input");
    inputs.forEach((input) => {
      input.value = "";
    });
    setAddressProof("");
  };

  return (
    <div className="stepWrapper__container element-box">
      {!openWebcam && (
        <Fragment>
          <div className="stepWrapper__form">
            <div className="stepWrapper__form__guidline">
              <u onClick={() => guidlinesModal(true)}>Having Issues?</u>
            </div>
            <Fragment>
              <div className="fInput__form__field fInput__form__field--w100">
                <label>Select Document type</label>
                <div className="fInput__form__input fInput__form__input--select">
                  <select
                    className="form-control"
                    required
                    onChange={(e) => setSelectType(e.target.value)}
                    value={selectType}
                  >
                    <option value="bankStatement">Bank Statement</option>
                    <option value="electricityBill"> Electricity Bill </option>
                    <option value="cableBill"> Cable Bill </option>
                    <option value="otherUtilityBill"> Other Utility Bill </option>
                  </select>
                </div>
              </div>

              <div className="kycsteps__container">
                {addressProof && (
                  <div className="kycS4">
                    <div className="kycS4__captured">
                      {documentType === "pdf" ? (
                        <iframe
                          src={encodeURI(addressProof)}
                          title="Address proof document"
                        ></iframe>
                      ) : (
                        <img src={addressProof} alt="address_proof" />
                      )}
                    </div>
                    <div
                      className="kycS4__captureAgain"
                      onClick={() => onCaptureAgain("addressProof")}
                    >
                      Capture it again
                    </div>
                  </div>
                )}

                {!addressProof && (
                  <Uploader
                    onUpload={onUpload}
                    handleCaptureId={() => setOpenWebcam(true)}
                    step={4}
                  />
                )}
              </div>

              <KycStepButton
                onClick={submitData}
                disabled={addressProof.length > 0 && selectType.length > 0}
                loader={uploadLoader}
                label="Submit"
              />
            </Fragment>
          </div>

          <KycFAQ />
        </Fragment>
      )}

      {openWebcam && (
        <WebCamCapture
          documentName={"capturedImg"}
          setCapturedImg={setCapturedImg}
          captureImages={onCapture}
          setOpenWebcam={setOpenWebcam}
          kycStep="STEP4"
        />
      )}
    </div>
  );
};

const maptoStateProps = (state) => ({
  userData: state.dashboard.userData
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: () => dispatch(getUserDetails())
});

export default compose(connect(maptoStateProps, mapDispatchToProps))(withRouter(KycStep4));
