import styled from "styled-components";
import { OpenOrdersRow } from "../Order/OpenOrder.style";

const DashBoardOpenOrderRow = styled(OpenOrdersRow)`
  th,
  td {
    &:nth-child(1) {
      flex: 0 0 162px;
      justify-content: flex-start !important;
      padding-left: 8px !important;
    }

    &:nth-child(2) {
      flex: 0 0 134px;
      justify-content: flex-start !important;
      padding-left: 8px !important;
    }

    &:nth-child(4) {
      flex: 0 0 58px;
      justify-content: flex-start !important;
      padding-left: 8px !important;
    }

    &:nth-child(6) {
      flex: 0 0 79px;
      justify-content: center !important;
      padding-left: 8px !important;
    }

    &:nth-child(3),
    &:nth-child(5) {
      flex: 1 1 142px;
      justify-content: flex-end !important;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    @media (max-width: 1024px) {
      &:nth-child(1) {
        flex: 0 0 130px;
        justify-content: flex-start !important;
        padding-left: 8px !important;
      }

      &:nth-child(2) {
        flex: 0 0 134px;
        justify-content: flex-start !important;
        padding-left: 8px !important;
      }

      &:nth-child(3) {
        flex: 0 0 58px;
        justify-content: flex-start !important;
        padding-left: 8px !important;
      }

      &:nth-child(4) {
        flex: 1 1 142px;
        justify-content: flex-end !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
      }

      &:nth-child(5) {
        flex: 0 0 79px;
        justify-content: center !important;
        padding-left: 8px !important;
      }
    }

    @media (max-width: 767px) {
      &:nth-child(1) {
        flex: 0 0 90px;
        justify-content: flex-start !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }

      &:nth-child(2) {
        flex: 0 0 58px;
        justify-content: flex-start !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }

      &:nth-child(3) {
        flex: 1 1 110px;
        justify-content: flex-end !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }

      &:nth-child(4) {
        flex: 0 0 25px;
        justify-content: center !important;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
  }
`;

export { DashBoardOpenOrderRow };
