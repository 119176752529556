import React, { Fragment } from "react";
import { Skeleton } from "../../../../components/Loader";
import { DivElement, SpanElement, TextWrapper } from "../../../../components/Reusable";

const TokenInfoData = ({ fieldType, isLoaded, saleDetails, field, children }) => {
  return (
    <Fragment>
      {isLoaded ? (
        <Fragment>
          {saleDetails.publicData.tokenInfo[field] && (
            <DivElement flexJustifyBtwAlignCenter margin="8px 0">
              <TextWrapper secondary fontSize="14px" fontWeight="500">
                {fieldType}
              </TextWrapper>
              <TextWrapper fontSize="14px" fontWeight="500">
                {field === "auditSmartContractLink" ? (
                  <a
                    href={saleDetails.publicData.tokenInfo[field]}
                    alt="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SpanElement fontWeight="bold" blue>
                      Link
                    </SpanElement>
                  </a>
                ) : (
                  saleDetails.publicData.tokenInfo[field]
                )}
              </TextWrapper>
            </DivElement>
          )}
        </Fragment>
      ) : (
        <Skeleton width="100%" height="16px" margin="8px 0" />
      )}
    </Fragment>
  );
};

const TokenInfo = ({ saleDetails, loader }) => {
  const DATA = [
    { fieldType: "Token Name", field: "tokenName" },
    { fieldType: "Token Type", field: "tokenType" },
    { fieldType: "Token Symbol", field: "tokenSymbol" },
    { fieldType: "Decimals", field: "decimals" },
    { fieldType: "Audit Smart Contract", field: "auditSmartContractLink" }
  ];
  return (
    <DivElement padding="24px 16px 32px 16px" bdGrey border="1px solid" borderRadius="5px">
      <TextWrapper fontWeight="700" fontSize="20px">
        Token Info
      </TextWrapper>
      <DivElement margin="-8px 0" paddingTop="32px">
        {DATA.map((data) => (
          <TokenInfoData
            key={data.field}
            fieldType={data.fieldType}
            field={data.field}
            saleDetails={saleDetails}
            isLoaded={saleDetails && !loader}
          />
        ))}
      </DivElement>
    </DivElement>
  );
};

export default TokenInfo;
