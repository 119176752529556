import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FavouriteFilledIcon, FavouriteIcon, UpDownIcon } from "../../../../../assets/svgs";
import config from "../../../../../constants/config";
import { updateUserFavPairs } from "../../../../../actions/trading";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import {
  DivElement,
  ImgElement,
  SpanElement,
  Table,
  TableNoRecord,
  Tbody,
  Td,
  TextWrapper,
  Th,
  ThDropdown,
  Thead,
  Tr
} from "../../../../../components/Reusable";
import { numberFormatter } from "../../../../../utils";
import { checkThousand } from "../../../../../utils/general";

const ThWrapper = (props) => {
  return (
    <Th {...props}>
      <DivElement display="inline-flex" alignItems="center">
        {props.children} <UpDownIcon onClick={props.onSort} pointer width="12px" height="12px" />
      </DivElement>
    </Th>
  );
};

const AllPairsTable = ({ coinFilter, categoryFilter, onSelectPair, searchText }) => {
  const [showNoRecords, setShowNoRecords] = useState(true);
  const [activeCol, setActiveCol] = useState("Last Price");
  const [sorting, setSorting] = useState("");
  const tickersData = useSelector((state) => state.trading.tickersData);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const userFavPairs = useSelector((state) => state.trading.userFavPairs);
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isMobile = width <= 767 ? true : false;

  useEffect(() => {
    setShowNoRecords(true);
  }, [coinFilter, categoryFilter, searchText]);

  const tickers = useMemo(() => {
    let keys = Object.keys(tickersData);
    if (keys.length === 0) {
      return [];
    } else {
      let data = keys.map((item) => {
        return { ...tickersData[item] };
      });
      return data;
    }
  }, [tickersData]);

  const pairFilter = (data) => {
    let pair = data.symbol.replace("/", "");
    let baseName = data.baseFullName.toUpperCase();
    let quoteName = data.quoteFullName.toUpperCase();
    let searchQuery = searchText.toUpperCase();

    let coinFlag = false,
      categoryFlag = false,
      searchFlag = false;
    if (
      coinFilter === "ALL" ||
      data?.symbol?.includes(coinFilter) ||
      (coinFilter === "FAVOURITES" && userFavPairs[data.symbol])
    ) {
      coinFlag = true;
    }
    if (categoryFilter === "All" || data?.categories?.includes(categoryFilter)) {
      categoryFlag = true;
    }
    if (
      !searchText ||
      (searchText && data.symbol.includes(searchQuery)) ||
      pair.includes(searchQuery) ||
      baseName.includes(searchQuery) ||
      quoteName.includes(searchQuery)
    ) {
      searchFlag = true;
    }
    let flag = coinFlag && categoryFlag && searchFlag;

    if (flag && showNoRecords) {
      setShowNoRecords(false);
    }

    return flag;
  };

  const onAddRemoveFavPair = (symbol) => {
    let favs = { ...userFavPairs };
    if (favs[symbol]) {
      delete favs[symbol];
    } else {
      favs[symbol] = true;
    }
    dispatch(updateUserFavPairs(favs));
  };

  const customSort = (a, b) => {
    if (sorting) {
      let key = Object.keys(sorting)[0];
      const sortOrder = sorting[key] ? -1 : 1;
      if (a[key] >= b[key]) {
        return 1 * sortOrder;
      } else {
        return -1 * sortOrder;
      }
    } else {
      return b.change - a.change;
    }
  };

  const onSorting = (field) => {
    let sort;
    if (sorting) {
      // eslint-disable-next-line no-prototype-builtins
      if (sorting.hasOwnProperty(field)) {
        sort = {
          [field]: !sorting[field]
        };
      } else {
        sort = {
          [field]: true
        };
      }
    } else {
      sort = {
        [field]: true
      };
    }
    setSorting(sort);
  };

  const onClickFav = (e, symbol) => {
    e.stopPropagation();
    onAddRemoveFavPair(symbol);
  };

  return (
    <DivElement flexGrow="1" scrollY padding="0 8px">
      <Table headerStickyTop>
        <Thead height="32px" fontSize="12px" fontWeight="400" lineHeight="18px">
          <Tr height="32px">
            <Th width="60px" widthSm="48px"></Th>
            <ThWrapper onSort={() => onSorting("symbol")} textAlign="left" paddingLeft="28px">
              Pair
            </ThWrapper>
            {isMobile ? (
              <ThDropdown
                activeCol={activeCol}
                OPTIONS={["Last Price", "24h Volume"]}
                paddingRight="12px"
                selectOption={setActiveCol}
              />
            ) : (
              <Fragment>
                <ThWrapper onSort={() => onSorting("lastPrice")} textAlign="right">
                  Last Price
                </ThWrapper>
                <ThWrapper onSort={() => onSorting("change")} textAlign="right">
                  24h Change
                </ThWrapper>
                <ThWrapper
                  onSort={() => onSorting("usdVolume")}
                  textAlign="right"
                  paddingRight="17px"
                >
                  24h Volume
                </ThWrapper>
              </Fragment>
            )}
          </Tr>
        </Thead>
        <Tbody height="32px" fontSize="12px" lineHeight="18px">
          {tickers &&
            tickers.sort(customSort).map((item) => (
              <Fragment key={item.symbol}>
                {pairFilter(item) && (
                  <Tr pointer borderBottom height="32px" onClick={() => onSelectPair(item.symbol)}>
                    <Td
                      paddingLeft="16px"
                      paddingLeftSm="12px"
                      paddingRight="20px"
                      onClick={(e) => onClickFav(e, item.symbol)}
                    >
                      {userFavPairs[item.symbol] ? <FavouriteFilledIcon /> : <FavouriteIcon />}
                    </Td>
                    <Td textAlign="left">
                      <DivElement flexAlignCenter>
                        <ImgElement
                          width="20px"
                          height="20px"
                          src={`${
                            !isDarkTheme ? config.lightCoinUrl : config.darkCoinUrl
                          }${item.symbol.split("/")[0].toLowerCase()}.svg`}
                        />
                        <TextWrapper fontSize="inherit" fontWeight="inherit" marginLeft="8px">
                          <var>
                            {item.symbol.split("/")[0]}
                            <SpanElement fontSize="inherit" fontWeight="inherit" secondary>
                              /{item.symbol.split("/")[1]}{" "}
                            </SpanElement>
                          </var>
                        </TextWrapper>
                      </DivElement>
                    </Td>
                    {!isMobile && (
                      <Td paddingRight={isMobile ? "16px" : ""} textAlign="right">
                        {numberFormatter(item.lastPrice)}
                      </Td>
                    )}
                    {!isMobile && (
                      <Td
                        paddingRight={isMobile ? "16px" : ""}
                        textAlign="right"
                        green={item.change >= 0 ? true : null}
                        red={item.change < 0 ? true : null}
                      >
                        <var>{numberFormatter(item.change)}%</var>
                      </Td>
                    )}

                    {(!isMobile || (isMobile && activeCol === "24h Volume")) && (
                      <Td paddingRight={isMobile ? "16px" : "19px"} textAlign="right">
                        <var>$&nbsp;{numberFormatter(checkThousand(item.usdVolume))}</var>
                      </Td>
                    )}

                    {isMobile && activeCol === "Last Price" && (
                      <Td paddingRight={isMobile ? "16px" : ""} textAlign="right">
                        <TextWrapper fontSize="12px" lineHeight="18px">
                          <var>{numberFormatter(item.lastPrice)}</var>
                        </TextWrapper>
                        <TextWrapper
                          green={item.change >= 0 ? true : null}
                          red={item.change < 0 ? true : null}
                          fontSize="10px"
                          lineHeight="16px"
                        >
                          <var>{numberFormatter(item.change)}%</var>
                        </TextWrapper>
                      </Td>
                    )}
                  </Tr>
                )}
              </Fragment>
            ))}
        </Tbody>
      </Table>
      {showNoRecords && <TableNoRecord />}
    </DivElement>
  );
};

export default AllPairsTable;
