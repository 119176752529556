import React, { useContext } from "react";
import { BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { WalletContext } from "../../contexts/WalletPage/BalancePage/wallet";

const DollarImageComponent = (props) => {
  let { DollarAmount, userHideStatus = false } = props;

  let { hideStatus } = useContext(WalletContext);

  return (
    <BannerHeadingSpan>
      <var>
        {userHideStatus ? (
          hideStatus && (
            <BannerHeadingSpan margin={"0 2px 0 0"} position="relative">
              $
            </BannerHeadingSpan>
          )
        ) : (
          <BannerHeadingSpan margin={"0 2px 0 0"} position="relative">
            $
          </BannerHeadingSpan>
        )}
        {DollarAmount}
      </var>
    </BannerHeadingSpan>
  );
};

export default DollarImageComponent;
