import React, { Fragment, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { TokenSaleLoader } from "../../../../components/Loader";
import { NoRecord, DivElement } from "../../../../components/Reusable";
import SaleNavbar from "./SaleNavbar";
import { RestrictedUser } from "../../../../components/Alert";
import { trackPage } from "../../../../tracking/track";
import SaleCard from "./SaleCard";
import { showErrorMsg } from "../../../../utils/notification";
import { formatAPIErrors } from "../../../../utils/general";
import { HalfFooterCommon } from "../../../../components/Footer/common";

const SaleDashboard = ({ salesData, loader, dashboardType, location, userData, error }) => {
  const [activeTab, setActiveTab] = useState("ONGOING");
  const [searchFilter, setSearchFilter] = useState("");
  const [sales, setSales] = useState("");
  const [showRestrictedModal, setShowRestrictedModal] = useState(false);
  const history = useHistory();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    document.title = `LCX Exchange - Token Sale${
      dashboardType === "ADMIN" ? " Admin" : ""
    } Dashboard`;

    trackPage(
      `${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/token-sale${dashboardType === "ADMIN" ? "/admin" : ""}`,
      `Token Sale${dashboardType === "ADMIN" ? " Admin" : ""} Dashboard`
    );
  }, []);

  useEffect(() => {
    if (error) {
      showErrorMsg(formatAPIErrors(error));
    }
  }, [error]);

  useEffect(() => {
    if (salesData) {
      setSales(salesData[activeTab]);
    }
  }, [salesData, activeTab]);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("ongoing")) {
      setActiveTab("ONGOING");
    } else if (path.includes("upcoming")) {
      setActiveTab("UPCOMING");
    } else if (path.includes("participated")) {
      setActiveTab("PARTICIPATED");
    } else if (path.includes("completed")) {
      setActiveTab("COMPLETED");
    }
  }, [location]);

  const onClickHandler = (data) => {
    if (userData.status === "RESTRICTED" && activeTab === "ONGOING") {
      setShowRestrictedModal(true);
    } else {
      history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/token-sale/${dashboardType === "ADMIN" ? "admin/" : ""}${activeTab.toLowerCase()}/${data.tokenSaleId}`);
    }
  };

  return (
    <>
      <DivElement height="100%" minHeight="76vh">
        <DivElement pageContainer>
          <SaleNavbar
            setSearchFilter={setSearchFilter}
            dashboardType={dashboardType}
            activeTab={activeTab}
          />

          {!loader && sales && sales.length > 0 && (
            <DivElement
              flex
              paddingTop="50px"
              paddingBottom="100px"
              paddingBottomMd="120px"
              paddingTopMd="24px"
              paddingTopSm="0"
              flexWrap="wrap"
              margin="-8px"
              marginMd="-12px"
              marginSm="-8px"
            >
              {sales.map((data, i) => (
                <Fragment key={i}>
                  {((searchFilter &&
                    data.name.toUpperCase().includes(searchFilter.toUpperCase())) ||
                    data.coin.toUpperCase().includes(searchFilter.toUpperCase()) ||
                    !searchFilter) && <SaleCard onClick={() => onClickHandler(data)} data={data} />}
                </Fragment>
              ))}
            </DivElement>
          )}

          {!loader && sales && sales.length === 0 && (
            <NoRecord
              tokenSale
              message={
                activeTab === "PARTICIPATED"
                  ? "Visit Live Token Sale and Participate now!"
                  : "New token sale coming up.... Stay Tuned!"
              }
            />
          )}

          {loader && <TokenSaleLoader />}
        </DivElement>

        {showRestrictedModal && (
          <RestrictedUser
            open={showRestrictedModal}
            onClose={() => setShowRestrictedModal(false)}
          />
        )}
      </DivElement>
      <HalfFooterCommon
        bottom="0px"
        position={loader ? "fixed" : "absolute"}
        // position="absolute"
        width="100%"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData,
  error: state.sale.error
});

export default compose(connect(mapStateToProps), withRouter)(SaleDashboard);
