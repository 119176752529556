import React, { Fragment } from "react";
import RecentOrders from "./RecentOrders";
import DashboardChart from "./DashboardChart";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { TokenSaleAdminContainer } from "../../../../../assets/StyleComponents/TokenSaleAdmin.style";
import "../../../../../style/accounts/main-accounts.scss";
import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";

const SaleAnalyticsRow4 = (props) => {
  let { saleDetails, loader } = props;
  return (
    <TokenSaleAdminContainer SaleAnalyticsRow4>
      <Fragment>
        {!loader && saleDetails && (
          <BannerBox
            width="100%"
            mt="35px"
            display="flex"
            directionLg="column"
            className="saleanalytics__row4"
          >
            <BannerBox CardWhite className="saleanalytics__row4__col2 element-box">
              <RecentOrders id={props.match.params.id} saleType={saleDetails.tokenSaleType} />
            </BannerBox>
            <BannerBox CardWhite className="saleanalytics__row4__col1 element-box">
              <BannerBox className="row4__col1__header">
                <BannerBox className="row4__col1__title">
                  {saleDetails.coin} Token Sale Statistic
                </BannerBox>
              </BannerBox>
              <BannerBox className="row4__col1__chart">
                <DashboardChart id={props.match.params.id} />
              </BannerBox>
            </BannerBox>
          </BannerBox>
        )}
      </Fragment>
    </TokenSaleAdminContainer>
  );
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme
});

export default compose(withRouter, connect(mapStateToProps))(SaleAnalyticsRow4);
