import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ElementBox, SpanElement } from "../../Reusable";

const MaintenanceNotice = ({ maintenanceData }) => {
  return (
    <Fragment>
      {maintenanceData && maintenanceData.data && maintenanceData.data.notice && (
        <ElementBox padding="18px 20px 17px" marginBottom="40px" marginTopSm="10px">
          <SpanElement fontSize="18px" red>
            Notice -&nbsp;
          </SpanElement>
          <SpanElement fontSize="16px">{maintenanceData.data.maintenanceNoticeMessage}</SpanElement>
        </ElementBox>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  maintenanceData: state.maintenance.maintenanceData
});

export default compose(connect(mapStateToProps))(MaintenanceNotice);
