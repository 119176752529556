import React from 'react';
import { useSelector } from 'react-redux';

import ImageCarousel from "../../../components/Reusable/ImageSlider/ImageSlider";
import { FlexCard } from '../../../assets/StyleComponents/FlexDiv.style';

const PromotionCarousel = () => {
  let imageList = useSelector(state => state.promotion.promotionList);
  
  return (
    <FlexCard>
      <ImageCarousel
        images={imageList}
        interval={5000}
      />
    </FlexCard>
  );
};

export default PromotionCarousel;