import styled from "styled-components";
import { TableRowComponent } from "../../assets/StyleComponents/Table/TableHead.style";

const MarketRow = styled(TableRowComponent)`
  th,
  td {
    &:nth-child(1) {
      flex: 0 0 24px;
      justify-content: flex-start !important;
      padding-left: 4px !important;
    }

    &:nth-child(2) {
      flex: 0 0 118px;
      justify-content: flex-start !important;
      padding-left: 8px !important;
    }

    &:nth-child(4) {
      flex: 1 1 95px;
      justify-content: flex-end !important;
      padding-left: 8px !important;
      padding-right: 16px !important;
    }

    &:nth-child(3) {
      flex: 1 1 118px;
      justify-content: flex-end !important;
      padding-right: 8px !important;
    }

    @media (max-width: 1024px) {
      &:nth-child(1) {
        flex: 1 1 120px;
        justify-content: flex-start !important;
        padding-left: 8px !important;
      }

      &:nth-child(2) {
        flex: 0 0 91px;
        justify-content: flex-end !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }

      &:nth-child(3) {
        flex: 0 0 58px;
        justify-content: flex-start !important;
        padding-left: 8px !important;
      }

      &:nth-child(4) {
        flex: 1 1 142px;
        justify-content: flex-end !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
      }

      &:nth-child(5) {
        flex: 0 0 79px;
        justify-content: center !important;
        padding-left: 8px !important;
      }
    }

    @media (max-width: 767px) {
      &:nth-child(1) {
        flex: 0 0 90px;
        justify-content: flex-start !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }

      &:nth-child(2) {
        flex: 0 0 58px;
        justify-content: flex-start !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }

      &:nth-child(3) {
        flex: 1 1 110px;
        justify-content: flex-end !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }

      &:nth-child(4) {
        flex: 0 0 25px;
        justify-content: center !important;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
  }
`;

export { MarketRow };
