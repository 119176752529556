/* eslint-disable react-hooks/exhaustive-deps */
import { DivElement } from "../../../../components/Reusable";
import { Responsive, WidthProvider } from "react-grid-layout";
import OrderBookSection from "./OrderBookSection";
import OrderFormSection from "./OrderFormSection";
import TradesSection from "./TradesSection";
import ChartSection from "./ChartSection";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { performActionOnLayout } from "../../../../actions/trading";
import UserAllOrders from "../Components/UserAllOrders";

const ResponsiveGridLayout = WidthProvider(Responsive);

// Minimum height of the layout
const HEIGHT = 646;

const DEFAULT_LAYOUT = [
  { i: "OrderForm", x: 0, y: 0, w: 15, h: 1, minH: 1, minW: 2 },
  { i: "OrderBook", x: 15, y: 0, w: 15, h: 0.66, minH: 0.66, minW: 13 },
  { i: "Trades", x: 15, y: 14, w: 15, h: 0.34 },
  { i: "Chart", x: 35, y: 0, w: 34, h: 0.66 },
  { i: "UserInfo", x: 35, y: 14, w: 34, h: 0.34 }
];

const getLayoutFromLS = () => {
  let lsLayout = localStorage.getItem("rgl-layout");
  if (lsLayout && lsLayout !== "undefined" && lsLayout !== undefined) {
    return JSON.parse(lsLayout);
  } else {
    return DEFAULT_LAYOUT;
  }
};

const TradePageWebLayout = () => {
  const [layout, setLayout] = useState(getLayoutFromLS());
  const { height } = useWindowSize();
  const actionOnLayout = useSelector((state) => state.trading.actionOnLayout);
  const dispatch = useDispatch();

  const onLayoutChange = (layoutData, saveToLs) => {
    setLayout(layoutData);
    if (saveToLs) {
      localStorage.setItem("rgl-layout", JSON.stringify(layoutData));
    }
  };

  useEffect(() => {
    if (actionOnLayout) {
      if (actionOnLayout === "RESET") {
        onLayoutChange(DEFAULT_LAYOUT, true);
        dispatch(performActionOnLayout(""));
      }
      if (actionOnLayout === "SAVE") {
        onLayoutChange(layout, true);
        dispatch(performActionOnLayout(""));
      }
    }
  }, [actionOnLayout]);

  const isChangingLayout = actionOnLayout === "CHANGE" ? true : false;

  return (
    <DivElement>
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: layout }}
        margin={[2, 2]}
        breakpoints={{ lg: 1200, md: 996, sm: 768 }}
        cols={{ lg: 64, md: 64, sm: 64, xxs: 64 }}
        rowHeight={height < HEIGHT ? HEIGHT : height}
        measureBeforeMount={false}
        useCSSTransforms={false}
        isResizable={isChangingLayout}
        isDraggable={isChangingLayout}
        onLayoutChange={(data) => onLayoutChange(data, false)}
      >
        <div key="OrderForm">
          <OrderFormSection />
        </div>
        <div key="OrderBook">
          <OrderBookSection />
        </div>
        <div key="Trades">
          <TradesSection />
        </div>
        <div key="Chart">
          <ChartSection />
        </div>
        <div key="UserInfo">
          <UserAllOrders containerProps={{ bgCard: true, padding: "12px" }} />
        </div>
      </ResponsiveGridLayout>
    </DivElement>
  );
};

export default TradePageWebLayout;
