import styled from "styled-components";
import { SearchIcon } from "../../../../../assets/svgs";
import { DivElement } from "../../../../../components/Reusable";

const InputWrapper = styled.div`
  width: 160px;
  height: 40px;
  padding: 8px 12px;
  display: flex;
  background-color: ${(props) => props.theme.inputFieldBg};
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 5px;

  @media (max-width: 767px) {
    position: relative;
    width: 220px;
  }

  input {
    flex-grow: 1;
    background-color: ${(props) => props.theme.inputFieldBg};
    border: none;
    outline: none;
    color: ${(props) => props.theme.primaryText};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    max-width: calc(100% - 24px);

    &::placeholder {
      color: ${(props) => props.theme.subText};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`;

const PairSearchBar = ({ searchText, setSearchText }) => {
  return (
    <DivElement widthSm="100%" heightSm="64px" paddingSm="16px 0">
      <InputWrapper>
        <DivElement width="24px" height="24px">
          <SearchIcon />
        </DivElement>
        <input
          name="searchText"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search pairs"
          autoFocus={true}
        />
      </InputWrapper>
    </DivElement>
  );
};

export default PairSearchBar;
