export const FAV_TAB_LIST = "src/trade/reducer/FAV_TAB_LIST";

export const UPDATE_LAST_PRICE = "src/trade/reducer/UPDATE_LAST_PRICE";

export const UPDATE_LAST_PRICE_SELL = "src/trade/reducer/UPDATE_LAST_PRICE_SELL";

export const UPDATE_ORDER_AMOUNT_BUY = "src/trade/reducer/UPDATE_ORDER_AMOUNT_BUY";

export const UPDATE_ORDER_AMOUNT_SELL = "src/trade/reducer/UPDATE_ORDER_AMOUNT_SELL";

export const UPDATE_PRICE_AMOUNT_ORDERBOOK = "src/trade/reducer/UPDATE_PRICE_AMOUNT_ORDERBOOK";

export const UPDATE_TOTAL_AMOUNT_BUY = "src/trade/reducer/UPDATE_TOTAL_AMOUNT_BUY";

export const UPDATE_TOTAL_AMOUNT_SELL = "src/trade/reducer/UPDATE_TOTAL_AMOUNT_SELL";

export const UPDATE_SILDER_PERCENTAGE = "src/trade/reducer/UPDATE_SILDER_PERCENTAGE";

export const UPDATE_SILDER_PERCENTAGE_SELL = "src/trade/reducer/UPDATE_SILDER_PERCENTAGE_SELL";

export const UPDATE_CURRENT_PAIR_DETAILS = "src/trade/reducer/UPDATE_CURRENT_PAIR_DETAILS";

export const TABLET_UPDATE_ACTIVE_TAB = "src/trade/reducer/TABLET_UPDATE_ACTIVE_TAB";

export const UPDATE_ACTIVE_TAB = "src/trade/reducer/UPDATE_ACTIVE_TAB";

export const UPDATE_ACTIVE_KEY = "src/trade/reducer/UPDATE_ACTIVE_KEY";

export const UPDATE_SELECTED_PAIR_NAME = "src/trade/reducer/UPDATE_SELECTED_PAIR_NAME";

export const ORDER_LOADING_STATUS = "src/trade/reducer/ORDER_LOADING_STATUS";

export const KYC_STATUS = "src/trade/reducer/KYC_STATUS";

export const RESTRICTED_STATUS = "src/trade/reducer/RESTRICTED_STATUS";

export const MARKET_CONFIRM_MODAL = "src/trade/reducer/MARKET_CONFIRM_MODAL";

export const GET_ERROR_MSG_STATUS = "src/trade/reducer/GET_ERROR_MSG_STATUS";

export const GET_ERROR_MSG_STATUS_SELL = "src/trade/reducer/GET_ERROR_MSG_STATUS_SELL";

export const MARKET_ALERT_CONFIRMATION = "src/trade/reducer/MARKET_ALERT_CONFIRMATION";

export const MARKET_ALERT_CONFIRMATION_SELL = "src/trade/reducer/MARKET_ALERT_CONFIRMATION_SELL";

export const UPDATE_CHART_VALUE = "src/trade/reducer/UPDATE_CHART_VALUE";

export const UPDATE_FEE_STATUS = "src/trade/reducer/UPDATE_FEE_STATUS";
