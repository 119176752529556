/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { DepositContext } from "../../../contexts/WalletPage/DepositPage/depositContext";
import FilterComponent from "./FilterPart/filterComponent";
import TransactionsTable from "../../../components/Wallets/Components/TransactionsTable";
import { HeadingSix } from "../../../assets/StyleComponents/FontSize/HeadingSix.style";
import moment from "moment";

const DepositWithdrawalPageComponent = () => {
  const [transactionsList, setTransactionsList] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  // const [activeTab, setActiveTab] = useState("All");
  let {
    resetValue,
    setPage,
    selectedCoin,
    callGetDeposits,
    callGetWithdrawal,
    callGetAllList,
    setSelectedCoin,
    from,
    to,
    setDepositList,
    setAllList,
    setWithdrawalList,
    setDepositCount,
    setAllCount,
    setWithdrawalCount,
    depositList,
    withdrawalList,
    allList,
    page,
    allCount,
    depositCount,
    withdrawalCount,
    setSelectedNetwork,
    activeTab
  } = useContext(DepositContext);

  useEffect(() => {
    setSelectedCoin("");
    setSelectedNetwork("");
    resetValue();
    setDepositList("");
    setAllList("");
    setWithdrawalList("");
    setDepositCount(0);
    setAllCount(0);
    setWithdrawalCount(0);
    let param = {
      coins: selectedCoin?.coin || "",
      page: 0,
      from,
      to
    };

    callGetAllList(param);
    return () => {
      setSelectedCoin("");
      setSelectedNetwork("");
      resetValue();
    };
  }, []);

  // useEffect(() => {
  //   handleChange();
  // }, [page, activeTab]);

  useEffect(() => {
    handleChange();
  }, [page]);

  useEffect(() => {
    if (activeTab === "Deposit") {
      setTransactionsList(depositList);
      setTotalCount(depositCount);
    } else if (activeTab === "Withdrawal") {
      setTransactionsList(withdrawalList);
      setTotalCount(withdrawalCount);
    } else {
      setTransactionsList(allList);
      setTotalCount(allCount);
    }
  }, [activeTab, allList, depositList, withdrawalList, allCount, depositCount, withdrawalCount]);

  const handleChange = () => {
    let param = {
      page: page,
      from,
      to
    };
    if (selectedCoin?.coin && selectedCoin?.coin.length > 0) {
      let filterAssets = [];
      selectedCoin?.coin.forEach((item) => filterAssets.push(item.value));
      param.coins = filterAssets;
    }
    if (from && to) {
      if (moment(from).format() === moment(to).format()) {
        param.from = moment(from).format();
        let toDateSetTime = moment(to).set({ hour: 23, minute: 59 });
        param.to = toDateSetTime.format();
      }
    }
    // function isValidDate(dateString) {
    //   const date = new Date(dateString);
    //   return !isNaN(date.getTime());
    // }
    // for (const key in param) {
    //   if (param[key] === isValidDate) {
    //     delete param[key];
    //   }
    // }

    if (activeTab.toLowerCase() === "all" || activeTab === "All") {
      callGetAllList(param);
    }
    if (activeTab === "Deposit") {
      callGetDeposits(param);
    }
    if (activeTab === "Withdrawal") {
      callGetWithdrawal(param);
    }
  };

  return (
    <BannerSection paddMd="20px 0px">
      <BannerBox display="flex" justifyContent="space-between" directionLg="column">
        <BannerBox
          padding={"0 0px 0px 16px"}
          paddingMd={"0 16px"}
          position="relative"
          width="50%"
          widthLg="100%"
        >
          <BannerHeading size={"24px"} sizeLg={"20px"} sizeMd={"18px"} mb={"8px"}>
            {"Deposit & Withdrawal History"}
          </BannerHeading>

          <HeadingSix weight="300">Get history on your deposits & withdrawals</HeadingSix>

          {/* <div className="deposit-withdraw mb-3 mt-3">
            <div className="deposit-withdraw-tabs align-items-center">
              {Tabs.map((tab) => (
                <BannerHeadingSpan
                  cursorPointer
                  size="16px"
                  key={tab}
                  BodyColor
                  weight="500"
                  WalletActiveTab={activeTab === tab}
                  onClick={() => changeTab(tab)}
                >
                  {tab}
                </BannerHeadingSpan>
              ))}
            </div>
          </div> */}
        </BannerBox>
      </BannerBox>

      <BannerBox
        mt={"48px"}
        mtLg={"20px"}
        padding={"0 32px"}
        paddingLg={"0 16px"}
        paddingMd="0 8px"
      >
        <FilterComponent />
      </BannerBox>

      <BannerBox mt={"48px"} mtLg={"24px"} mtMd={"18px"} padding={"0 16px"} paddingLg={"0 16px"}>
        <TransactionsTable
          transactionsList={transactionsList}
          page={page + 1}
          setPage={(page) => setPage(page - 1)}
          totalCount={totalCount}
        />
      </BannerBox>
    </BannerSection>
  );
};

export default DepositWithdrawalPageComponent;
