import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import momentTimeZone from "moment-timezone";

import { LedgerDepositRow } from "../../../../assets/StyleComponents/Table/Wallet/Ledger.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
// import { DropDownButtonComponent } from "../../../../assets/StyleComponents/DropDown.style";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
// import { Dropdown } from "react-bootstrap";
import { getUserReports } from "../../../../actions/wallet";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { showErrorMsg } from "../../../../utils/notification";
import Pagination from "../../../../components/Pagination/pagination";
import { debounce } from "../../../../utils/helpers";
import CancelReportsModal from "../component/CancelModal";
import { downloadReports } from "../../../../lib/api";
import { NoRecord, ThDropdown } from "../../../../components/Reusable";
// import { ThreeDotsIcon } from "../../../../assets/svgs";
import { Table, Th, Thead, Td, Tbody } from "../../../../components/Reusable";
import { TableLoader } from "../../../../components/Loader";
import OptionsDropdown from "../../../../components/Reusable/Dropdown/OptionsDropdown";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { CloseIcon, DownloadIcon } from "../../../../assets/svgs";

const dropdownOptions = [
  "Tax report via",
  "Date Range",
  "Format",
  "Submitted"
];

const ExportTableComponent = (props) => {
  let { isTheme, reports } = props;
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [activeCol, setActiveCol] = useState("Tax report via");
  const [downloadLoader, setdownloadloader] = useState({
    state: false,
    id: ""
  });
  const { width } = useWindowSize();
  const [modal, setModal] = useState(false);
  const [cancelId, setCancelId] = useState("");
  let interval = useRef();

  const FetchReports = (page) => {
    const data = {
      offset: page || 1
    };
    props.getUserReports(data);
  };

  useEffect(() => {
    FetchReports(page || 1);
    setLoader(true);
    // if (window.href === "/wallet/reports") {
    interval.current = setInterval((page) => {
      FetchReports(page || 1);
    }, 100000);
    // }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReport = debounce((data) => {
    setdownloadloader({
      id: data?.Id,
      state: true
    });
    if (data?.Status === "PROCESSED") {
      // const From = moment(data?.From * 1000).format("DD/MM/YY");
      // const To = moment(data?.To * 1000).format("DD/MM/YY");
      const Type = data?.Type;
      downloadReports(data?.Id)
        .then((res) => {
          if (res.data) {
            downloadFile({
              data: res?.data,
              fileName: `${Type}_${data?.Id}.csv`,
              fileType: "text/csv"
            });
          }
        })
        .catch((e) => {
          setdownloadloader({
            id: data?.Id,
            state: false
          });
        });
    }
  }, 700);

  let ListCount = 25;
  let last = reports?.count > 1 ? Math.floor((reports?.count - 1) / ListCount) + 1 : 1;

  const onPageChanged = (data) => {
    const { offset } = data;
    setPage(offset);
    FetchReports(offset);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setdownloadloader({
      id: data?.Id,
      state: false
    });
  };

  const CloseModal = () => {
    setModal(false);
    FetchReports(page);
  };

  const CancelModalHit = (id) => {
    setModal(true);
    setCancelId(id);
  };

  return (
    <>
      {modal && (
        <CancelReportsModal
          modal={modal}
          closemodal={CloseModal}
          id={cancelId}
          filterreports={FetchReports}
          page={page}
        />
      )}
      <BannerBox>
        <Table bRadius width="100%" marginBottom="16px" TableBodyRowHeight>
          <Thead display="flex" height="40px" fontSize="14px" fontSizeMd="12px">
            <LedgerDepositRow displayFlex OrdersPagesHeadRow>
              <Th scope="col" ml="6px">
                {" "}
                Status
              </Th>
              <Th scope="col">Type</Th>
              {width > 767 && <Th scope="col">Tax report via</Th>}
              {width > 767 && <Th scope="col">Date Range</Th>}
              {width > 1024 && <Th scope="col">Format</Th>}
              {width > 767 && <Th scope="col">Submitted</Th> }
              {width > 1024 && <Th scope="col"></Th>}
              {width < 768 && 
                <Th scope="col">
                  <ThDropdown
                    textAlign={"right"}
                    OPTIONS={dropdownOptions}
                    activeCol={activeCol}
                    selectOption={setActiveCol}
                  />
                </Th>
              }
            </LedgerDepositRow>
          </Thead>

          {/* {width > 1024 && ( */}
          <Tbody>
            {!loader && <TableLoader rows={5} webCol={4} tabletCol={4} mobileCol={3} />}
            {loader &&
              reports &&
              reports.data &&
              reports.data.length > 0 &&
              reports.data.map((data, i) => (
                <LedgerDepositRow heightLg="auto" pyLg="12px" displayFlex key={i} TableBodyRowHeight>
                  <Td
                    txtSuccess={data.Status === "PROCESSED"}
                    txtWarning={data.Status !== "PROCESSED"}
                    ml="6px"
                  >
                    {data.Status === "PROCESSED" ? "Generated" : "In Progress"}
                  </Td>
                  
                  <Td textAlign="left">
                    {data?.Type === "LedgerExchange" ? (
                      <div>
                        <BannerHeading size="16px" sizeLg="14px" sizeMd="12px">
                          Ledger{" "}
                          <BannerHeadingSpan size="12px"> (Exchange Wallet) </BannerHeadingSpan>
                        </BannerHeading>
                        <BannerHeadingSpan color="#606F80" size="14px">
                          {data?.Currency}
                        </BannerHeadingSpan>
                      </div>
                    ) : data?.Type === "LedgerMain" ? (
                      <div>
                        <BannerHeading  size="16px" sizeLg="14px" sizeMd="12px" >
                          Ledger <BannerHeadingSpan size="12px"> (Main Wallet) </BannerHeadingSpan>
                        </BannerHeading>
                        <BannerHeadingSpan color="#606F80" size="14px" sizeMd="12px">
                          {data?.Currency === "" ? "All Assets" : data?.Currency}
                        </BannerHeadingSpan>
                      </div>
                    ) : (
                      <div>
                        <BannerHeading size="16px" sizeLg="14px" sizeMd="12px" >{data?.Type}</BannerHeading>
                        <BannerHeadingSpan color="#606F80" size="14px" sizeMd="12px">
                          {data?.Currency === "" ? "All Pairs" : data?.Currency}
                        </BannerHeadingSpan>
                      </div>
                    )}
                  </Td>

                  {width > 767 && <Td textAlign="left">{data.Template}</Td>}

                  {width > 767 && (
                    <Td textAlign="left">
                      <BannerBox display="flex" directionLg="column">
                        <BannerBox>
                          <BannerHeading size="16px">
                            {momentTimeZone.tz(data.From * 1000, "CET").format("DD/MM/YY")}
                          </BannerHeading>
                          <BannerHeadingSpan color="#606F80" size="14px">
                            00:00
                          </BannerHeadingSpan>
                          {width < 1025 &&  
                            <BannerHeadingSpan mx="6px" mxMd="4px">
                              to
                            </BannerHeadingSpan>
                          }
                        </BannerBox>
                        {width > 1024 && 
                          <BannerHeadingSpan mx="6px" mxMd="4px">
                            to
                          </BannerHeadingSpan>
                        }
                        <div>
                          <BannerHeading size="16px">
                            {momentTimeZone.tz(data.To * 1000, "CET").format("DD/MM/YY")}
                          </BannerHeading>
                          <BannerHeadingSpan color="#606F80" size="14px">
                            23:59
                          </BannerHeadingSpan>
                        </div>
                      </BannerBox>
                    </Td>
                  )}
                  {width > 1024 && <Td textAlign="left">CSV</Td>}
                  
                  {width > 1024 && (
                    <Td textAlign="left">{moment(data?.CreatedAt * 1000).fromNow()}</Td>
                  )}

                  {width > 1024 ? (
                    <Td textAlign="left">
                      <BannerBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gap="5px"
                      >
                        {data?.Status !== "PROCESSED" && (
                          <>
                            <ButtonComponent
                              // OutlineDanger
                              CloseIconWithBackground
                              flexCenter
                              width={"32px"}
                              btnHeight={"32px"}
                              borderRadius={"50%"}
                              onClick={() => CancelModalHit(data?.Id)}
                              size={"14px"}
                              className="mx-2"
                            >
                              <CloseIcon fillRed  />
                            </ButtonComponent>
                            <BannerBox rightBorder mx="4px" height="20px"  />
                          </>
                        )}
                        {data?.Status === "PROCESSED" &&
                        (data?.Id === downloadLoader.id ? !downloadLoader.state : true) ? (
                          <ButtonComponent
                            // PrimaryButton
                            TokenSaleBannerbuttons
                            flexCenter
                            width={"32px"}
                            btnHeight={"32px"}
                            borderRadius={"50%"}
                            size={"14px"}
                            onClick={() => handleReport(data)}
                          >
                            <DownloadIcon borderRadius="50%" fillBlue />
                          </ButtonComponent>
                        ) : (
                          <ButtonComponent
                            DisabledBTN={isTheme !== true ? true : false}
                            DisabledBTNDark={isTheme === true ? true : false}
                            flexCenter
                            width={"32px"}
                            btnHeight={"32px"}
                            borderRadius={"50%"}
                            size={"14px"}
                            style={{ cursor: "no-drop" }}
                            onClick={() => showErrorMsg("File In Process")}
                          >
                            <DownloadIcon borderRadius="50%" />
                          </ButtonComponent>
                        )}
                      </BannerBox>
                    </Td>
                  ) : (
                    <Td textAlign="right">
                      {width > 767 ? 
                        moment(data?.CreatedAt * 1000)
                          .fromNow()
                          .replace("minutes", "mins")
                          .replace("seconds", "sec")
                          .replace("minute", "min")
                          .replace("hours", "hrs")
                          .replace("hour", "hr") === "a few sec ago"
                          ? "a sec ago"
                          : moment(data?.CreatedAt * 1000)
                              .fromNow()
                              .replace("minutes", "mins")
                              .replace("seconds", "sec")
                              .replace("minute", "min")
                              .replace("hours", "hrs")
                              .replace("hour", "hr")
                        : 
                        activeCol === "Tax report via" ?
                          data.Template :
                        activeCol === "Date Range" ? 
                          <BannerBox display="flex" directionMd="column">
                            <BannerBox>
                              <BannerHeading sizeLg="14px" sizeMd="12px">
                                {momentTimeZone.tz(data.From * 1000, "CET").format("DD/MM/YY")}
                              </BannerHeading>
                              <BannerHeadingSpan color="#606F80" size="14px" sizeLg="12px">
                                00:00
                              </BannerHeadingSpan>
                              {width < 1025 &&  
                                <BannerHeadingSpan mx="6px" mxMd="4px">
                                  to
                                </BannerHeadingSpan>
                              }
                            </BannerBox>
                            <BannerBox>
                              <BannerHeading sizeLg="14px" sizeMd="12px">
                                {momentTimeZone.tz(data.To * 1000, "CET").format("DD/MM/YY")}
                              </BannerHeading>
                              <BannerHeadingSpan color="#606F80" size="14px" sizeLg="12px">
                                23:59
                              </BannerHeadingSpan>
                            </BannerBox>
                          </BannerBox>
                          :
                        activeCol === "Submitted" ? 
                          moment(data?.CreatedAt * 1000)
                            .fromNow()
                            .replace("minutes", "mins")
                            .replace("seconds", "sec")
                            .replace("minute", "min")
                            .replace("hours", "hrs")
                            .replace("hour", "hr") === "a few sec ago"
                            ? "a sec ago"
                            : moment(data?.CreatedAt * 1000)
                                .fromNow()
                                .replace("minutes", "mins")
                                .replace("seconds", "sec")
                                .replace("minute", "min")
                                .replace("hours", "hrs")
                                .replace("hour", "hr")
                          : `CSV`
                      }
                      <OptionsDropdown
                        containerClass="tablet-mobile"
                      >
                        {data?.Status === "PROCESSED" && (
                          <ParaTag
                            cursorPointer={"pointer"}
                            onClick={() => handleReport(data)}
                            width="150px"
                            py="8px"
                            textAlign="center"
                          >
                            Download
                          </ParaTag>
                        )}
                        {data?.Status !== "PROCESSED" && (
                          <ParaTag
                            cursorPointer={"pointer"}
                            onClick={() => CancelModalHit(data?.Id)}
                            width="150px"
                            py="8px"
                            textAlign="center"
                          >
                            Cancel
                          </ParaTag>
                        )}
                      </OptionsDropdown>
                    </Td>
                  )}
                </LedgerDepositRow>
              ))}
          </Tbody>
          {reports && (!reports.data || (reports.data && reports.data.length < 1)) && <NoRecord />}
        </Table>
        {loader && reports && reports?.count > 1 && (
          <Pagination
            totalRecords={reports?.count}
            last={last}
            pageLimit={25}
            pageNeighbours={1}
            offset={page}
            onPageChanged={onPageChanged}
          />
        )}
      </BannerBox>
    </>
  );
};

// Call To Map To Props
const maptoStateProps = (state) => ({
  isTheme: state.theme.isTheme,
  allList: state.wallet.allList,
  totalAllCount: state.wallet.totalAllCount,
  reports: state.wallet.reports?.data,
  isReportsLoader: state.wallet.isReportsLoader,
  csv: state.wallet.csv
  // headers: state.wallet.headers
});

const mapDispatchToProps = (dispatch) => ({
  getUserReports: (data) => dispatch(getUserReports(data)),
  downloadReports: (data) => dispatch(downloadReports(data))
});

export default connect(maptoStateProps, mapDispatchToProps)(ExportTableComponent);
