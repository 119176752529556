import React from "react";
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";
import { Fragment } from "react";
import OCCUPATIONS from "../../../constants/occupation";
import Select from "react-select";
import { compose } from "redux";
import { connect } from "react-redux";
import { reactDropdownStyles } from "../../../utils";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: "bold"
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const OccupationInput = ({
  title,
  name,
  errorProps,
  setFieldValue,
  error,
  containerClass,
  isDarkTheme,
  value
}) => {
  return (
    <Fragment>
      <BannerBox width="100%" mb="24px">
        <Label txtGrey size="14px" width="100%">
          {title}
        </Label>
        <BannerBox bottomBorder="2px solid" borderColor>
          <Select
            onChange={(value) => setFieldValue(name, value.value)}
            name={name}
            options={OCCUPATIONS}
            formatGroupLabel={formatGroupLabel}
            styles={reactDropdownStyles({ isDarkTheme })}
            placeholder="Select an option"
            defaultValue={
              value
                ? {
                    label: value,
                    value: value
                  }
                : ""
            }
            value={
              value
                ? {
                    label: value,
                    value: value
                  }
                : ""
            }
          />
        </BannerBox>
        {/* <ErrorMessage name={name} component="div" className="fInput__form__error" {...errorProps} /> */}
        <ErrorMessageDiv
          name={name}
          component="div"
          mt="6px"
          size="14px"
          sizemd="12px"
          weight="300"
          txtDanger
        />
      </BannerBox>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(OccupationInput);
