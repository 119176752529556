/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import { cancelOpenOrder, updateFeeList, getFeesList } from "../../lib/api";
import { getLocalStorageData } from "../../utils/general";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import { trackPage } from "../../tracking/track";
import InitialDepositModal from "./InitialDepositModal";
import FeeComponent from "./Fee/index";
import SecurityComponent from "./Security/security";
import OrderComponents from "./MyOrders/order";
import RecentActivity from "./RecentActivity/recentActivity";
import NotificationComponent from "./Notification/notification";
import { BannerBox, MainElement, BannerSection } from "../../assets/StyleComponents/Exchange.style";
import DashboardBalance from "./DashboardBalance/dashboardBalance";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { LevelThreeNotice, MaintenanceNotice, Welcome } from "../../components/Modules/Dashboard";
import ProfileSection from "./ProfileSection";
import { HalfFooterCommon } from "../../components/Footer/common";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";
import {
  fetchAvailableLanguages,
  fetchAvailableLanguagesRequest
} from "../../Modules/Generic/action";
import { getPromotionList } from "../../actions/LandingPage/LandingPage";
import DashboardPermotionComponent from "./Promotion/Permotion";
import { setLocalStorage } from "../../utils/helpers";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

export default function TradingDashboard(props) {
  let {
    isTheme,
    getUserData,
    recentActivity,
    isFetchingRecentActivity,
    isOpenOrderFetching,
    alert,
    walletErrorGet,
    marketErrorGet,
    tradeErrorGet,
    dashErrorGet,
    settingErrorGet,
    userData
  } = props;

  const [openOrders, setOpenOrders] = useState([]);
  const [promotionModal, setPromotionModal] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState();
  const [feeCheck, setFeeCheck] = useState(false);
  const [maker, setMaker] = useState(0);
  const [taker, setTaker] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  // const [feeData, setfeeData] = useState({});
  const [currentFeePair, setCurrentFeepair] = useState({});
  const { transalationList } = useSelector(GenericReducer);
  const dashboardPromotionList = useSelector(state => state.promotion.dashboardPromotionList);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem("ljs-lang");
  const dashboardPromotionStatus = localStorage.getItem("dashboard-promotion");
  
  useEffect(() => {
    let data = {
      offset: 1
    };
    props.getOpenOrders(data);
    dispatch(fetchAvailableLanguagesRequest());

    dispatch(getPromotionList());

    props.getRecentActivity();
    let getDespsoitStatus = getLocalStorageData("deposit");
    if (getDespsoitStatus === null) localStorage.setItem("deposit", true);
    // this.setState({ showDepositModal: getDespsoitStatus === null ? true : JSON.parse(getDespsoitStatus) });
    // setShowDepositModal(getDespsoitStatus === null ? true : JSON.parse(getDespsoitStatus));
    let page = props.location.pathname + props.location.search;
    trackPage(page);

    // get fees+++++++++
    getFeesList()
      .then((res) => {
        let alldata = res.data.data;
        let feeInLCX = res.data.data.feeInLCX;
        setFeeCheck(feeInLCX);
        setIsLoader(false);
        // setfeeData(alldata);
        setCurrentFeepair(alldata?.current);
        setMaker(alldata?.MakerFees);
        setTaker(alldata?.TakerFees);
        // initalLoad.current = false;
      })
      .catch((err) => {
        // initalLoad.current = false;
        setIsLoader(false);
      });
  }, []);

  useEffect(() => {
    if(dashboardPromotionList && dashboardPromotionList.length > 0) {
      if(dashboardPromotionStatus !== dashboardPromotionList[0].whatsNewUrl){
        setPromotionModal(true);
      }
    }
  }, [dashboardPromotionList]);

  // const updateDimensions = () => {
  //   setWidth(window.innerWidth);
  // };

  useEffect(() => {
    setOpenOrders(props.openOrders);
  }, [props.openOrders]);

  const cancelOrder = (props) => {
    if (props !== undefined) {
      let params = {
        OrderId: props
      };

      let order = openOrders;
      let cOrder = {};
      order = order.filter((a) => {
        if (a.Id === props) {
          a.Loading = true;
          cOrder = a;
          return a;
        } else return a;
      });
      setOpenOrders(order);

      cancelOpenOrder(params)
        .then((res) => {
          showSuccessMsg(
            `${cOrder.OrderType === "LIMIT" ? "Limit" : "Market"} ${
              cOrder.Side === "BUY" ? "Buy" : "Sell"
            } Order Cancelled`,
            { autoClose: 7000 }
          );
          let data = {
            offset: 1
          };
          let order = openOrders;
          order = order.filter((a) => {
            if (a.Id !== props) {
              return a;
            }
          });
          setOpenOrders(order);
          if (order.length === 0) props.getOpenOrders(data);
        })
        .catch((err) => {
          order = openOrders;
          order = order.filter((a) => {
            if (a.Id === props) {
              a.Loading = false;
              return a;
            } else return a;
          });
          setOpenOrders(order);
          showErrorMsg(`${err.response.data.message}`, { autoClose: 5000 });
        });
    }
  };

  const handleCheckBox = () => {
    // setFeeCheck(!feeCheck);
    let data = {
      FeeInLCX: String(!feeCheck)
    };
    setIsLoader(true);
    updateFeeList(data)
      .then((res) => {
        setFeeCheck(res?.data?.data?.feeInLCX);
        setIsLoader(false);
        showSuccessMsg("Fee updated successfully");
      })
      .catch((err) => {
        showErrorMsg(err.response.data.message, { autoClose: 5000 });
        setIsLoader(false);
      });
  };

  const closeDepositModal = () => {
    setShowDepositModal(false);
    localStorage.setItem("deposit", false);
  };

  const levelStatus = getUserData?.KYC?.STATUS;
  const DisplayMessage = getUserData?.KYC?.transactionRiskInfo?.display || false;

  let SEOContents = {
    name: ``,
    link: ``,
    title: "Crypto Portfolio Dashboard | Manage & Analyze | LCX Exchange",
    description:
      "View and analyze your crypto portfolio with LCX Exchange. Access real-time data, track performance, and manage assets on your personalized dashboard.",
    // keywords: `crypto trading tutorial, trading crypto tutorial, crypto trade tutorial, crypto trading tutorials, tutorial crypto trading, Crypto trading strategies`,
    canonical: `https://exchange.lcx.com/`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: "Crypto Portfolio Dashboard | Manage & Analyze | LCX Exchange",
    OgDescription:
      "View and analyze your crypto portfolio with LCX Exchange. Access real-time data, track performance, and manage assets on your personalized dashboard.",
    OgURL: `https://www.exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Crypto Portfolio Dashboard | Manage & Analyze | LCX Exchange",
    TwDescription:
      "View and analyze your crypto portfolio with LCX Exchange. Access real-time data, track performance, and manage assets on your personalized dashboard."
  };
  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== prevLang || "en")
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}`
      };
      return obj;
    });
  }

  const ClosePromotionModal = () => {
    setLocalStorage(dashboardPromotionList[0].whatsNewUrl, "dashboard-promotion");
    setPromotionModal(false);
  };

  // FIXME this need to be changed
  if (walletErrorGet || marketErrorGet || tradeErrorGet || dashErrorGet || settingErrorGet)
    return <Redirect to="/error500" />;

  return (
    <>
      {showDepositModal && (
        <InitialDepositModal
          showDepositModal={showDepositModal}
          onClose={closeDepositModal}
          {...props}
        />
      )}

      {promotionModal && dashboardPromotionList && dashboardPromotionList.length > 0 &&
        <DashboardPermotionComponent 
          onClose={ClosePromotionModal}
          open={promotionModal}
          // Reset24hrs={Reset24hrs}
          srcImage={dashboardPromotionList[0]?.whatsNewUrl}
          link={dashboardPromotionList[0].topbar_link}
          btnText={dashboardPromotionList[0].button_text}
        />
      }

      <SetHeaderTitleComponent Content={SEOContents} />
      <BannerBox
        position="relative"
        margin="0px auto"
        minHeight="100%"
        boxShadow="none"
        bg="#f2f4f8"
      >
        <MainElement>
          <BannerSection SectionGrey verticalAlign="top">
            <BannerBox
              ContentBox
              padding="0px"
              pt="0.5rem"
              paddingMd="5px"
              width="100%"
              paddingLg="1rem"
            >
              <MainElement
                paddingLg="auto 20px"
                paddingBottom="2rem"
                paddingTop="1rem"
                paddingMd="auto 0px"
              >
                <BannerBox ExchangeContainer>
                  <MaintenanceNotice />
                  {DisplayMessage && <LevelThreeNotice />}
                  <Welcome />
                  {/* Balance Compoent */}
                  <BannerBox width="100%" ptLg="0px" paddingMd="0px" order="1">
                    <DashboardBalance {...props} />
                  </BannerBox>

                  <BannerBox mt="16px" mtLg="20px" mtMd="24px">
                    <BannerBox display="flex" flex="1" direction="row" directionLg="column">
                      {levelStatus === "LEVEL2" && (
                        <OrderComponents
                          isLoader={isLoader}
                          getUserData={getUserData}
                          isOpenOrderFetching={isOpenOrderFetching}
                          openOrders={openOrders}
                          levelStatus={levelStatus}
                          cancelOrder={cancelOrder}
                          {...props}
                        />
                      )}
                      <ProfileSection levelStatus={levelStatus} {...props} />

                      <SecurityComponent
                        isLoader={isLoader}
                        maker={maker}
                        taker={taker}
                        feeCheck={feeCheck}
                        getUserData={getUserData}
                        levelStatus={levelStatus}
                        isTheme={isTheme}
                      />
                    </BannerBox>
                    <BannerBox display="flex" flex="1" directionLg="column">
                      {levelStatus === "LEVEL2" && (
                        <NotificationComponent
                          isLoader={isLoader}
                          maker={maker}
                          taker={taker}
                          feeCheck={feeCheck}
                          alert={alert}
                          getUserData={getUserData}
                          levelStatus={levelStatus}
                          isTheme={isTheme}
                        />
                      )}

                      <FeeComponent
                        isLoader={isLoader}
                        maker={maker}
                        taker={taker}
                        feeCheck={feeCheck}
                        handleCheckBox={handleCheckBox}
                        currentFeePair={currentFeePair}
                        getUserData={getUserData}
                        levelStatus={levelStatus}
                        isFetchingRecentActivity={isFetchingRecentActivity}
                        recentActivity={recentActivity}
                        isTheme={isTheme}
                      />

                      {levelStatus !== "LEVEL2" && (
                        <OrderComponents
                          isLoader={isLoader}
                          getUserData={getUserData}
                          isOpenOrderFetching={isOpenOrderFetching}
                          openOrders={openOrders}
                          levelStatus={levelStatus}
                          cancelOrder={cancelOrder}
                          {...props}
                        />
                      )}

                      {levelStatus === "LEVEL2" && (
                        <RecentActivity
                          isLoader={isLoader}
                          getUserData={getUserData}
                          isFetchingRecentActivity={isFetchingRecentActivity}
                          recentActivity={recentActivity}
                          levelStatus={levelStatus}
                          isTheme={isTheme}
                        />
                      )}
                    </BannerBox>
                  </BannerBox>
                </BannerBox>
              </MainElement>
            </BannerBox>
          </BannerSection>
        </MainElement>
      </BannerBox>

      <HalfFooterCommon />
    </>
  );
}
