import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { DivElement } from "../../../../../components/Reusable";
import CoinsFilter from "./CoinsFilter";
import CategoryFilter from "./CategoryFilter";
import AllPairsTable from "./AllPairsTable";
import { SpanElement, TextWrapper } from "../../../../../components/Reusable";
import { ExpandMoreIcon, PlusIcon } from "../../../../../assets/svgs";
import { updateSelectedPair } from "../../../../../actions/trading";
import TokenInfo from "../MarketDropdown/TokenInfo";

const DropdownContent = styled(DivElement)`
  width: 554px;
  height: 632px;
  position: absolute;
  top: calc(100% + 10px);
  z-index: 100;
  border-radius: 5px;
  border: 1px solid;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    height: 316px;
  }

  @media (max-width: 767px) {
    width: 328px;
    height: 464px;
  }

  &::before {
    width: 16px;
    height: 16px;
    content: "";
    position: absolute;
    top: -8px;
    left: 24px;
    transform: rotate(45deg);
    border: solid ${(props) => props.theme.borderColor};
    border-width: 1px 0 0 1px;
    background-color: inherit;
    z-index: -1;
  }

  ${(props) =>
    props.type === "favChartPairs" &&
    css`
      top: calc(100% + 12px);
      right: -24px;

      &::before {
        left: initial;
        right: 28px;
      }
    `}
`;

const MarketDropdown = ({ type = "header" }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [coinFilter, setCoinFilter] = useState("ALL");
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [searchText, setSearchText] = useState("");
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const dispatch = useDispatch();
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const onSelectPair = (symbol) => {
    dispatch(updateSelectedPair(symbol));
    setShowDropdown(false);
  };

  const isFav = coinFilter === "FAVOURITES" ? true : false;

  return (
    <DivElement relative width="100%" height="100%" ref={ref}>
      <DivElement flex cursorPointer onClick={() => setShowDropdown(!showDropdown)}>
        {type === "header" ? (
          <Fragment>
            <TextWrapper fontSize="16px" fontWeight="700">
              <var>
                {selectedPair.base}
                <SpanElement secondary fontSize="inherit" fontWeight="400" lineHeight="24px">
                  /{selectedPair.quote}
                </SpanElement>
              </var>
            </TextWrapper>
            <ExpandMoreIcon />
          </Fragment>
        ) : (
          <DivElement
            displayCenter
            width="28px"
            height="28px"
            borderRadius="2px"
            bdInputColor
            border="1px solid"
          >
            <PlusIcon width="20px" height="20px" />
          </DivElement>
        )}
      </DivElement>

      {type === "header" && <TokenInfo />}

      {showDropdown && (
        <DropdownContent type={type} bgCard bdGrey>
          <DivElement height={isFav ? "" : "122px"} heightSm={isFav ? "" : "168px"}>
            <CoinsFilter
              filter={coinFilter}
              setFilter={setCoinFilter}
              searchText={searchText}
              setSearchText={setSearchText}
            />
            <CategoryFilter
              coinFilter={coinFilter}
              filter={categoryFilter}
              setFilter={setCategoryFilter}
            />
          </DivElement>

          <AllPairsTable
            coinFilter={coinFilter}
            categoryFilter={categoryFilter}
            searchText={searchText}
            onSelectPair={onSelectPair}
          />
        </DropdownContent>
      )}
    </DivElement>
  );
};

export default MarketDropdown;
