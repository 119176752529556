/* eslint-disable no-console */
import { css } from "styled-components";
import { createResponsiveStyles } from "../GlobalStyle/DynamicResponsiveDesign/DynamicResponsiveDesign.style";

const BorderCommanStyle = css`
  ${({ border, borderXl, borderLg, borderMd, borderSm, theme }) => (border || borderXl || borderLg || borderMd || borderSm) && 
    createResponsiveStyles("border", "border", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? theme.commonBorder : value : theme.commonBorder 
    )
  }
  ${({ borderTop, borderTopXl, borderTopLg, borderTopMd, borderTopSm, theme }) => (borderTop || borderTopXl || borderTopLg || borderTopMd || borderTopSm) && 
    createResponsiveStyles('borderTop', 'border-top', 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? theme.commonBorder : value : theme.commonBorder 
    )
  }
  ${({ borderLeft, borderLeftXl, borderLeftLg, borderLeftMd, borderLeftSm, theme }) => (borderLeft || borderLeftXl || borderLeftLg || borderLeftMd || borderLeftSm) && 
    createResponsiveStyles('borderLeft', 'border-left', 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? theme.commonBorder : value : theme.commonBorder 
    )
  }
  ${({ borderRight, borderRightXl, borderRightLg, borderRightMd, borderRightSm, theme }) => (borderRight || borderRightXl || borderRightLg || borderRightMd || borderRightSm) && 
    createResponsiveStyles('borderRight', 'border-right', 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? theme.commonBorder : value : theme.commonBorder 
    )
  }
  ${({ borderBottom, borderBottomXl, borderBottomLg, borderBottomMd, borderBottomSm, theme }) => (borderBottom || borderBottomXl || borderBottomLg || borderBottomMd || borderBottomSm) && 
    createResponsiveStyles('borderBottom', 'border-bottom', 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? theme.commonBorder : value : theme.commonBorder 
    )
  }
  ${({ border_x, border_xXl, border_xLg, border_xMd, border_xSm, theme }) => (border_x || border_xXl || border_xLg || border_xMd || border_xSm) && css`
    ${createResponsiveStyles('border_x', 'border-left', (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? theme.commonBorder : value : theme.commonBorder )}
    ${createResponsiveStyles('border_x', 'border-right', (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? theme.commonBorder : value : theme.commonBorder )}
  `}
  ${({ border_y, border_yXl, border_yLg, border_yMd, border_ySm, theme }) => (border_y || border_yXl || border_yLg || border_yMd || border_ySm) && css`
    ${createResponsiveStyles('border_y', 'border-top', 
        (value) => !(value === false || value === "null" || typeof value === "undefined") ? 
          value === true ? theme.commonBorder : value
          : 
          theme.commonBorder 
      )
    }
    ${createResponsiveStyles('border_y', 'border-bottom', 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? theme.commonBorder : value === true ? theme.commonBorder : value : theme.commonBorder 
    )
    }
  `}
  
  ${({ borderRadius, borderRadiusXl, borderRadiusLg, borderRadiusMd, borderRadiusSm }) => (borderRadius || borderRadiusXl || borderRadiusLg || borderRadiusMd || borderRadiusSm) && 
    createResponsiveStyles('borderRadius', 'border-radius', 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? borderRadius : value : borderRadius 
    )
  }
  ${({ borderWidth, borderWidthXl, borderWidthLg, borderWidthMd, borderWidthSm }) => (borderWidth || borderWidthXl || borderWidthLg || borderWidthMd || borderWidthSm) && 
    createResponsiveStyles('borderWidth', 'border-width', 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? borderWidth : value : borderWidth 
    )
  }
  ${({ borderColor, borderColorXl, borderColorLg, borderColorMd, borderColorSm }) => (borderColor || borderColorXl || borderColorLg || borderColorMd || borderColorSm) && 
    createResponsiveStyles('borderColor', 'border-color', 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value === true ? borderColor : value : borderColor 
    )
  }

  ${({ GreyBorderColor, theme }) => GreyBorderColor && css `border-color: ${theme.sepColor} !important;`}
`;

export { BorderCommanStyle };