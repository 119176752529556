import { createContext, useState, useEffect } from "react";
import { getPromotionUpdate } from "../../lib/api";
// import { useDispatch } from "react-redux";

export const PromotionContext = createContext({});
function PromotionContextContainer({ children }) {
  const [activePromotion, setActivePermotion] = useState({});
  // const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getPromotionUpdate());
    getPromotionUpdate()
      .then((res) => {
        const allPromotion = res?.data?.data;
        const active = allPromotion.filter((values) => values.isActive === true);
        setActivePermotion(active);
      })
      .catch((err) => {
        return;
      });
  }, []);
  // return null;
  return <PromotionContext.Provider value={activePromotion}>{children}</PromotionContext.Provider>;
}

export default PromotionContextContainer;
