import { useState } from "react";
import { useSelector } from "react-redux";
import OrderBookTable from "../OrderBookTable";
import { DivElement } from "../../../../../components/Reusable";
import { SingleOrderRow } from "./SingleOrderRow";

const SIDES = {
  BUY: "buy",
  SELL: "sell"
};

const OpenOrdersList = ({ side }) => {
  let dataType = side === SIDES.SELL ? "orderBookAsks" : "orderBookBids";
  const ordersList = useSelector((state) => state.trading[dataType]);
  const [currRowIndex, setCurrRowIndex] = useState(-1);
  const activeSide = useSelector((state) => state.trading.orderBookActiveSide);
  const orderBookConfig = useSelector((state) => state.trading.orderBookConfig);

  const onMouseOut = () => {
    if (orderBookConfig.displayAvgSum) {
      setCurrRowIndex(-1);
    }
  };

  return (
    <DivElement
      flex
      scrollY
      overflowX="hidden"
      flexDirection={side === SIDES.SELL ? "column-reverse" : "column"}
      height={activeSide === side ? "calc(100% - 31px)" : "calc(50% - 15.5px)"}
      paddingBottom={side === SIDES.SELL ? "4px" : ""}
    >
      <OrderBookTable onMouseOut={onMouseOut}>
        {ordersList &&
          ordersList.map((order, index) => (
            <SingleOrderRow
              setCurrRowIndex={setCurrRowIndex}
              side={side}
              key={index}
              order={order}
              index={index}
              currRowIndex={currRowIndex}
            />
          ))}
      </OrderBookTable>
    </DivElement>
  );
};

export default OpenOrdersList;
