/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { DivElement, ImgElement, ModalWrapper, TextWrapper } from '../../../../components/Reusable';
import { CloseIcon, RewardsFailureIcon } from '../../../../assets/svgs';
import { RewardsBackgroundWeb } from '../../../../assets/png';
import BoxAnimation from '../../../../components/Reusable/Animation/RewardsHub/BoxAnimation';
import { BannerBox } from '../../../../assets/StyleComponents/Exchange.style';
import { HeadingThird } from '../../../../assets/StyleComponents/FontSize/headingThird.style';
import { ParaTag } from '../../../../assets/StyleComponents/FontSize/para.style';
import useOnClickOutside from '../../../../Hooks/CheckOutSideClick/checkOutSideClick';
import { useSelector } from 'react-redux';
import OpenBoxAnimation from '../../../../components/Reusable/Animation/RewardsHub/OpenBoxAnimation';
import { ButtonComponent } from '../../../../assets/StyleComponents/Button.style';
import { H1Tag } from '../../../../assets/StyleComponents/FontSize/fonts.style';
import configs from "../../../../constants/config";
import { HeadingSecond } from '../../../../assets/StyleComponents/FontSize/headsecond.style';
import styled from 'styled-components';
import { useWindowSize } from '../../../../Hooks/CheckWidthHeight';

const AnimatedContent = styled(BannerBox)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 75px;
  margin-bottom: 85px;
  opacity: ${props => props.show ? 1 : 0};
  transform: translateY(${props => props.show ? '0' : '10px'});
  transition: opacity 3s ease-in-out, transform 3s ease-in-out;
  visibility: ${props => props.show ? 'visible' : 'hidden'};
`;

const RewardsModal = ({ onClose }) => {
  const ref = useRef();
  const [showContent, setShowContent] = useState(false);
  const { width } = useWindowSize();

  const rewardStatus = useSelector(state => state.rewards.claimData);
  const isDarkTheme = useSelector(state => state.theme.isDarkTheme);
  const rewardSuccessStatus = useSelector(state => state.rewards.success);
  const rewardErrorStatus = useSelector(state => state.rewards.claimErrorStatus);

  useOnClickOutside(ref, () => {
    onClose();
  });
  
  return (
    <ModalWrapper>
      <DivElement 
        width="720px"
        height="685px"
        position="relative"
        bg={!rewardErrorStatus ? `url(${RewardsBackgroundWeb}) center center no-repeat` : `#ECF5FE`} 
        ModalContainer
        ref={ref}
      >
        <DivElement displayBtw>
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px"></TextWrapper>
          <CloseIcon pointer 
            onClick={onClose} 
          />
        </DivElement>

        <TextWrapper textAlign="center" fontWeight="400" mt={(!rewardErrorStatus && !rewardStatus.status) ? width > 1024 ? "110px" : width > 767 ? "52px" : "81px" : ""} lineHeight="20px" position="relative">
          {rewardErrorStatus &&
            <RewardsFailureIcon width="400px" height="279px" />
          }
          {!rewardErrorStatus && !rewardStatus.status &&
            <BoxAnimation type="SUCCESS" />
          }
        </TextWrapper>
        {!rewardErrorStatus && rewardStatus.status === "success" &&
          <>
            <BannerBox position="relative">
              {/* {!showContent &&  */}
                <OpenBoxAnimation type="SUCCESS" onComplete={() => setShowContent(true)} />
              {/* } */}
              <AnimatedContent show={showContent}>
                {showContent && 
                  <>
                    <H1Tag size="128px" color="#4E17CF">
                      {rewardStatus.data.rewardAmount}
                    </H1Tag>
                    <ImgElement
                      loading="lazy"
                      alt="coin"
                      src={`${
                        !isDarkTheme ? configs.lightCoinUrl : configs.darkCoinUrl
                      }${rewardStatus?.data?.rewardCoin?.toLowerCase()}.svg`}
                      width="120px"
                      height="120px"
                    />
                    <HeadingSecond size="40px" color="#706F6F">
                      {rewardStatus?.data?.rewardCoin}
                    </HeadingSecond>
                  </>
                }
              </AnimatedContent>
            </BannerBox>
          </>
        }

        <BannerBox display="flex" direction="column" gap="12px">
          {rewardErrorStatus && 
            <>
              <ParaTag color="#606F80" textAlign="center">
                We are facing some issues at the moment! Please retry to claim your reward
              </ParaTag>
              <ButtonComponent width="100%" mt="119px" onClick={onClose}>
                Retry Claim
              </ButtonComponent>
            </>
          }
          {!rewardErrorStatus && 
            <BannerBox position="absolute" bottom="20px" left="0" width="100%">
              <HeadingThird color="#012243" textAlign="center">
                Reward Claimed
              </HeadingThird>
              <ParaTag color="#606F80" textAlign="center">
                The claimed reward will be transferred to your Main Balance.
              </ParaTag>
            </BannerBox>
          }
        </BannerBox>
      </DivElement>
    </ModalWrapper>
  );
};

export default RewardsModal;