import { compose } from "redux";
import DivElement from "../DivElement";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { useState } from "react";

const Dropdown = styled(DivElement)`
  position: absolute;
  top: 56px;
  border: 1px solid ${(props) => (props.isDarkTheme ? "#38393D" : "#ccced0")};
  z-index: 100;
  padding: ${(props) => (props.padding ? props.padding : "")};
  border-radius: 5px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "210px")};

  ${(props) =>
    props.alignRight &&
    css`
      right: -12px;
    `}

  ${(props) =>
    props.dropdownAlignRight &&
    css`
      right: ${(props) => props.dropdownAlignRight};
    `}

  ${(props) =>
    props.alignLeft &&
    css`
      left: 12px;
    `}
`;

const Pointer = styled(DivElement)`
  z-index: -1;
  position: absolute;
  width: 24px;
  height: 24px;
  top: -12px;
  transform: rotate(45deg);
  border: 1px solid ${(props) => (props.isDarkTheme ? "#38393D" : "#ccced0")};
  border-width: 1px 0 0 1px;

  ${(props) =>
    props.alignRight &&
    css`
      right: ${(props) => props.alignRight || "24px"};
    `}

  ${(props) =>
    props.alignLeft &&
    css`
      left: ${(props) => props.alignLeft || "24px"};
    `}

  ${(props) =>
    props.alignCenter &&
    css`
      right: ${(props) => props.alignCenter || "calc(50% - 12px)"};
    `}
`;

const DropdownWrapper = ({
  isDarkTheme,
  children,
  label,
  minWidth,
  padding,
  alignLeft,
  alignRight,
  alignCenter,
  containerProps,
  dropdownAlignRight
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <DivElement
      cursor
      relative
      displayCenter
      height="100%"
      padding="0 12px"
      onMouseOut={() => setShowDropdown(false)}
      onMouseOver={() => setShowDropdown(true)}
      {...containerProps}
    >
      <DivElement displayCenter height="100%" onClick={() => setShowDropdown(!showDropdown)}>
        {label}
      </DivElement>
      <Dropdown
        bgCard
        padding={padding}
        minWidth={minWidth}
        alignLeft={alignLeft}
        alignRight={alignRight}
        alignCenter={alignCenter}
        isDarkTheme={isDarkTheme}
        dropdownAlignRight={dropdownAlignRight}
        display={showDropdown ? "initial" : "none"}
      >
        <Pointer
          bgCard
          bdGrey
          isDarkTheme={isDarkTheme}
          alignLeft={alignLeft}
          alignRight={alignRight}
          alignCenter={alignCenter}
        />
        {children}
      </Dropdown>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(DropdownWrapper);
