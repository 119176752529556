import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { PageLoader } from "../../Loader";
import MissingProfileDetails from "./MissingProfileDetails";
import TermsAndConditions from "./TermsAndCondition";

const ActionRequired = ({ isFetchingUserData, userData, actionsRequired }) => {
  return (
    <Fragment>
      {!isFetchingUserData && userData ? (
        <Fragment>
          {actionsRequired.includes("PROFILE_STATUS") ? (
            <MissingProfileDetails />
          ) : (
            (actionsRequired.includes("BNC_OWNER") || actionsRequired.includes("TNC_STATUS")) && (
              <TermsAndConditions />
            )
          )}
        </Fragment>
      ) : (
        <PageLoader />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData,
  isFetchingUserData: state.dashboard.isFetchingUserData,
  actionsRequired: state.auth.actionsRequired
});

export default compose(connect(mapStateToProps))(ActionRequired);
