import React from "react";
import styled from "styled-components";
import { LiTag, ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { MarginStyle } from "../../../assets/StyleComponents/Reusable/Margin.style";
import { DisplayStyle } from "../../../assets/StyleComponents/Reusable/Display.style";

const UlList = styled.ul`
  ${MarginStyle}
  ${DisplayStyle}
  max-height: 250px;
  overflow: auto;
  padding: ${(props) => props.padding || "0 0 0 25px"};
`;

const UnorderList = ({ givenList, noListPadding, extraUlStyle }) => {
  if(givenList && givenList.length === 0) return null;
  return (
    <UlList {...extraUlStyle}  mb="8px">
      {givenList.map((item, index) => (
        <LiTag DotGrey my={!noListPadding &&  "16px"} key={index}>
          <ParaTag txtGrey> {item} </ParaTag>
        </LiTag>
      ))}
    </UlList>
  );
};

export default UnorderList;