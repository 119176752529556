import Cookies from "js-cookie";
import { customDecode } from "./envScript/envCrypto";

const env = customDecode(process.env.REACT_APP_NODE_ENV);

let isProductionEnv = true,
  isStaging = true;
if (env !== "production") isProductionEnv = false;
if (env !== "staging") isStaging = false;

export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  let result = name + (isStaging ? "-dev" : "") + "=" + (value || "") + expires + "; path=/";
  if (isProductionEnv || isStaging) result += "; domain=lcx.com";
  document.cookie = result;
}

export function getCookie(name) {
  return (
    Cookies.get(
      name + (isStaging ? "-dev" : ""),
      isProductionEnv || isStaging ? { domain: "lcx.com" } : {}
    ) || ""
  );
}

export function eraseCookie(name) {
  document.cookie =
    name + (isStaging ? "-dev" : "") + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
