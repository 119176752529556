import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";
import { DocumentBlueIcon, InfoBlueIcon } from "../../../../../assets/svgs";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: ${(props) => (props.isDoc ? "100%" : "289px")};
  min-height: ${(props) => (props.isDiff ? "" : "86px")};
  margin-bottom: 49px;

  @media (max-width: 1024px) {
    max-width: 276px;
    margin-bottom: 25px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    min-height: ${(props) => (props.isDiff ? "" : "58px")};
    margin-bottom: 15px;
  }

  a {
    color: ${(props) => (props.isDarkTheme ? "#70a6e0 !important" : "#0470e1 !important")};
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProspectusDoc = ({ isDarkTheme, data }) => {
  return (
    <DivElement
      width={data.isDoc ? "100%" : "33.33%"}
      widthMd={data.isDoc ? "100%" : "50%"}
      widthSm="100%"
    >
      <Container
        isDiff={!data.subtitle || null}
        isDoc={data.isDoc ? true : null}
        isDarkTheme={isDarkTheme}
      >
        <DivElement
          width="18px"
          height="18px"
          widthSm="14.5px"
          heightSm="14.5px"
          margin="4px 12px 0 0"
          marginSm="4px 8px 0 0"
        >
          {data.isDoc ? (
            <InfoBlueIcon width="inherit" height="inherit" />
          ) : (
            <DocumentBlueIcon width="inherit" height="inherit" />
          )}
        </DivElement>

        <Text>
          <TextWrapper
            fontSize="16px"
            fontSizeSm="14px"
            lineHeight="1.5"
            dangerouslySetInnerHTML={{ __html: data.link }}
          />

          {data.subtitle && (
            <TextWrapper
              fontSize="14px"
              fontSizeSm="12px"
              secondary
              marginTop="8px"
              marginTopSm="6px"
              lineHeight="1.43"
              lineHeightSm="1.5"
            >
              {data.subtitle}
            </TextWrapper>
          )}
        </Text>
      </Container>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(ProspectusDoc);
