import React, { useEffect, useState } from "react";
import moment from "moment";
import SkeletonLoader from "../../../components/SkeletonLoader/index";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { DropDownButtonComponent } from "../../../assets/StyleComponents/DropDown.style";
import { Dropdown } from "react-bootstrap";
import { debounce } from "../../../utils/helpers";
import { numberWithCommas } from "../../../utils/general";
import { ChevronRightIcon, ThreeDotsIcon } from "../../../assets/svgs";
import TableLoader from "../../../components/Loader/TableLoader";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { RouterLink, Table, Thead, Th, ThDropdown, Tbody, Td } from "../../../components/Reusable";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { NoOpenOrdersIllustration } from "../../../assets/svgs/illustrations";
import { DashBoardOpenOrderRow } from "../../../assets/StyleComponents/Table/Dashboard/dashboard.style";
import { useSelector } from "react-redux";

const OrderComponents = (props) => {
  const { width } = useWindowSize();
  const { isOpenOrderFetching, openOrders, levelStatus } = props;
  const [dropdownOptions, setDropdownOptions] = useState([
    "Amount",
    "Time(24h)",
    "Filled",
    "Status",
    "Type"
  ]);
  const [activeCol, setActiveCol] = useState("Amount");
  const userData = useSelector(state => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");

  //=> Change Header

  const cancelOrder = debounce((id) => {
    props.cancelOrder(id);
  }, 800);

  const changePairName = (pairData) => {
    props.selectedPair(pairData);
    props.history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/trade/${pairData.base_pair}-${pairData.quote_pair}`);
  };

  useEffect(() => {
    if (width < 768) {
      setDropdownOptions([...dropdownOptions, "Price"]);
    }
  }, [width]);

  return (
    <BannerBox
      flex="0 0 66.66%"
      flexLg="1"
      padding={`
        ${levelStatus !== "LEVEL2" ? "16px" : "0px"} 8px 0 0
      `}
      paddingLg={`${levelStatus !== "LEVEL2" ? "10px" : "0px"} 0px 0 0px`}
      paddingMd={`${levelStatus !== "LEVEL2" ? "12px" : "0px"} 0px 0`}
      order={levelStatus !== "LEVEL2" ? "3" : "1"}
      mbLg="10px"
      mbMd="12px"
    >
      <BannerBox
        CardWhite
        DashboardBordersdark
        width="100%"
        padding="16px 24px 0"
        display="flex"
        direction="column"
        overflow="hidden"
        radius="10px"
        height={levelStatus !== "LEVEL2" ? "544px" : "360px"}
        heightLg="345px"
        heightMd="278px"
      >
        <BannerBox display="flex" justifyContent="space-between" mt="8px" mb="25px">
          <BannerHeading size="18px" sizeMd="16px" weight="500">
            My Open Orders
          </BannerHeading>

          <RouterLink to="/order/open">
            <ChevronRightIcon />
          </RouterLink>
        </BannerBox>

        {!isOpenOrderFetching && openOrders && openOrders.length > 0 && (
          <>
            <BannerBox overflow="scroll" height="80%" width="100%">
              <Table bRadius borderCollapse="collapse">
                <Thead height="40px" heightMd="40px" fontSize="14px" fontSizeMd="12px">
                  <DashBoardOpenOrderRow displayFlex>
                    {width > 1025 && (
                      <Th scope="col" textAlign="left" paddingLeft="8px">
                        {" "}
                        Time(24h){" "}
                      </Th>
                    )}
                    <Th scope="col" textAlign="left">
                      {" "}
                      Pair{" "}
                    </Th>
                    {width > 767 && (
                      <Th scope="col" textAlign="right" width="118px">
                        {" "}
                        Price{" "}
                      </Th>
                    )}
                    <Th scope="col"> Side </Th>
                    {width > 1025 && (
                      <Th scope="col" textAlign="right" width="118px">
                        {" "}
                        Amount{" "}
                      </Th>
                    )}
                    {width < 1024 && (
                      <ThDropdown
                        textAlign={"right"}
                        OPTIONS={dropdownOptions}
                        activeCol={activeCol}
                        selectOption={setActiveCol}
                        width="118px"
                        height="25px"
                      />
                    )}
                    {width > 767 ? (
                      <Th scope="col" width="85px">
                        {" "}
                        Action{" "}
                      </Th>
                    ) : (
                      <Th scope="col" width="85px"></Th>
                    )}
                  </DashBoardOpenOrderRow>
                </Thead>
                <Tbody fontSizeMd="14px" fontSizeSm="12px" fontSize="14px">
                  {!isOpenOrderFetching &&
                    openOrders &&
                    openOrders.length > 0 &&
                    openOrders.map((item, index) => {
                      let {
                        Pair,
                        Price,
                        Side,
                        Amount,
                        Id,
                        UpdatedAt,
                        Loading,
                        Status,
                        FilledPer,
                        OrderType
                      } = item;
                      const splitPair = Pair.split("/");
                      const base = splitPair[0];
                      const quote = splitPair[1];
                      item.base_pair = base.toUpperCase();
                      item.quote_pair = quote.toUpperCase();
                      return (
                        <DashBoardOpenOrderRow displayFlex key={index}>
                          {width > 1025 && (
                            <Td
                              textAlign="left"
                              fontWeight="500"
                              fontSize="14px"
                              BodyColor
                              paddingLeft="8px"
                            >
                              {" "}
                              {moment(UpdatedAt * 1000).format("HH:mm")}{" "}
                            </Td>
                          )}
                          <Td textAlign="left">
                            <ParaTag
                              weight="700"
                              size="14px"
                              sizeMd="12px"
                              mb="0px"
                              cursor="pointer"
                              onClick={() => changePairName(item)}
                            >
                              {`${base}/`}
                              <BannerHeadingSpan txtGrey weight="500">
                                {quote}
                              </BannerHeadingSpan>
                            </ParaTag>
                          </Td>

                          {width > 767 && (
                            <Td fontWeight="500" fontSize="14px" BodyColor textAlign="right">
                              {numberWithCommas(Price.noExponents())}
                            </Td>
                          )}
                          <Td
                            fontWeight="500"
                            fontSize="14px"
                            green={Side === "BUY" && true}
                            red={Side === "SELL" && true}
                          >
                            {Side}
                          </Td>

                          {/* all for the dropdowns */}
                          {width < 1024 && activeCol === "Amount" && (
                            <Td fontWeight="500" fontSize="14px" BodyColor textAlign="right">
                              {numberWithCommas(Amount.noExponents())}
                            </Td>
                          )}
                          {width < 1024 && activeCol === "Time(24h)" && (
                            <Td textAlign="right">{moment(UpdatedAt * 1000).format("HH:mm")}</Td>
                          )}
                          {width < 1024 && activeCol === "Status" && (
                            <Td textAlign="right" fontWeight="300" fontSize="14px">
                              {Status}
                            </Td>
                          )}
                          {width < 1024 && activeCol === "Filled" && (
                            <Td textAlign="right" fontWeight="300" fontSize="14px">
                              {FilledPer.toFloor(2) + "%"}
                            </Td>
                          )}
                          {width < 1024 && activeCol === "Type" && (
                            <Td textAlign="right" fontWeight="300" fontSize="14px">
                              {OrderType}
                            </Td>
                          )}
                          {width < 767 && activeCol === "Price" && (
                            <Td textAlign="right" fontWeight="300" fontSize="14px">
                              {numberWithCommas(Price.noExponents())}
                            </Td>
                          )}
                          {width > 1025 && (
                            <Td fontWeight="500" fontSize="14px" BodyColor textAlign="right">
                              {numberWithCommas(Amount.noExponents())}
                            </Td>
                          )}
                          {width > 767 ? (
                            <Td fontWeight="500" fontSize="14px" width="85px">
                              {Loading ? (
                                <SkeletonLoader width={60} />
                              ) : (
                                <ButtonComponent
                                  OutlineOnlyDanger
                                  btnHeight="max-content"
                                  givenMargn="0 0 0 0"
                                  size="14px"
                                  onClick={() => {
                                    cancelOrder(Id);
                                  }}
                                >
                                  Cancel
                                </ButtonComponent>
                              )}
                            </Td>
                          ) : (
                            <>
                              <Td fontWeight="500" fontSize="14px">
                                <DropDownButtonComponent
                                  TradeOrder
                                  InnerLevel
                                  RemoveBtnPadding
                                  NoCaret
                                  variant="outline-secondary"
                                  title={<ThreeDotsIcon width="20px" height="20px" />}
                                  id="input-group-dropdown-1"
                                >
                                  <Dropdown.Item
                                    as={"div"}
                                    style={{ fontSize: "12px", textAlign: "left" }}
                                    onClick={() => props.cancelOrder(Id)}
                                  >
                                    Cancel
                                  </Dropdown.Item>
                                </DropDownButtonComponent>
                              </Td>
                            </>
                          )}
                        </DashBoardOpenOrderRow>
                      );
                    })}
                </Tbody>
              </Table>
            </BannerBox>
          </>
        )}
        {!isOpenOrderFetching && openOrders && openOrders.length === 0 && (
          <>
            <Td>
              <NoOpenOrdersIllustration margin="10px 0px" height={width > 1024 ? "85%" : "50%"} />
              <br />
              <BannerHeadingSpan mtLg="10px">No Record Found</BannerHeadingSpan>
            </Td>
          </>
        )}
        {!isOpenOrderFetching && !openOrders && (
          <>
            <Td>
              <NoOpenOrdersIllustration margin="10px 0px" height={width > 1024 ? "85%" : "50%"} />
              <br />
              <BannerHeadingSpan mtLg="10px">No Record Found</BannerHeadingSpan>
            </Td>
          </>
        )}

        {isOpenOrderFetching && <TableLoader rows={5} webCol={6} tabletCol={5} mobileCol={3} />}
      </BannerBox>
    </BannerBox>
  );
};

export default React.memo(OrderComponents);
