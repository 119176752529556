/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { useSelector } from "react-redux";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { ErrorIcon } from "../../../../assets/svgs";

const ShowErrorListing = ({ list }) => {
  return (
    <ul className="guidline__modal__points" style={{ marginLeft: "8px" }}>
      {list.map((data, i) => {
        return (
          <li key={i} style={{ marginBottom: "12px" }}>
            <ParaTag size="14px"> {data} </ParaTag>
            {/* <a
                className="kyc__faq"
                href={data.link || "#"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.question}
              </a> */}
          </li>
        );
      })}
    </ul>
  );
};

const ErrorList = ({ submissionList, errorType }) => {
  let userData = useSelector((state) => state.dashboard.userData);
  const [missingProofList, setMissingProofList] = useState([]);
  let transactionRiskStatus = userData["KYC"]["transactionRiskInfo"] || "";

  useEffect(() => {
    if (submissionList.partialRejectionReason && submissionList.partialRejectionReason.length > 0) {
      submissionList.partialRejectionReason.map((item) => {
        if (item.type === errorType) {
          setMissingProofList(item.key);
        }
      });
    }
  }, [submissionList]);

  return (
    <BannerBox CardWhite width="322px" className="stepWrapper__guidlines">
      <ParaTag txtDanger size="16px">
        Please resubmit this step. Identify errors in the last submission.
      </ParaTag>

      <BannerBox mt="16px">
        {missingProofList &&
          missingProofList.length > 0 &&
          missingProofList.map((item, index) => {
            return (
              <BannerBox key={index}>
                <ParaTag txtDanger display="flex" alignSelf="center" gap="8px" size="14px">
                  <BannerHeadingSpan>
                    <ErrorIcon width="20px" height="20px" />{" "}
                  </BannerHeadingSpan>
                  {item.value}
                </ParaTag>

                {item.comment && item.comment.length > 0 && (
                  <ShowErrorListing list={item.comment} />
                )}
              </BannerBox>
            );
          })}
      </BannerBox>
    </BannerBox>
  );
};

export default ErrorList;
