import styled, { css } from "styled-components";
import {
  Button,
  CoinLabel,
  DivElement,
  ImgElement,
  TextWrapper
} from "../../../../components/Reusable";
import { ErrorMessage, Field } from "formik";
import { useSelector } from "react-redux";
import { noExponents, numberWithCommas } from "../../../../utils/general";
import Select from "react-select";
import { reactDropdownStyles } from "../../../../utils";

const formattedArray = (array) => {
  return array.map((item) => {
    return {
      label: item.coin,
      value: item.coin
    };
  });
};

const InputContainer = styled(DivElement)`
  height: 56px;
  background-color: ${(props) => props.theme.inputFieldBg};
  border-color: ${(props) => props.theme.inputBorder};

  input {
    outline: none !important;
    font-size: 16px;
    line-height: 20px;
    border: none !important;
    padding: 0px;
    background-color: ${(props) => props.theme.inputFieldBg};
    color: ${(props) => props.theme.primaryText};
    min-width: 94px;
  }

  input:focus {
    background-color: ${(props) => props.theme.inputFieldBg};
  }

  ${(props) =>
    props.showRedBorder &&
    css`
      border-color: ${(props) => props.theme.primaryRed};
    `}
`;

const AmountInput = ({
  saleDetails,
  onAmountChangeHandler,
  setFieldValue,
  title,
  balance,
  name,
  onCoinChangeHandler,
  selectedPaymentCoin,
  showRedBorder,
  onClickAddFunds,
  isPurchaseBalanceEnough,
  errors,
  containerProps
}) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <DivElement relative paddingBottom="32px" {...containerProps}>
      <DivElement flexJustifyBtwAlignCenter marginBottom="10px">
        <TextWrapper secondary fontSizeSm="12px">
          {title}
        </TextWrapper>
        <TextWrapper secondary fontSize="12px">
          Main balance:
          <var>{`${isLoggedIn ? numberWithCommas(noExponents(balance || 0)) : "00"}`}</var>
        </TextWrapper>
      </DivElement>
      <InputContainer
        flexJustifyBtwAlignCenter
        border="1px solid"
        borderRadius="5px"
        padding="12px 16px"
        showRedBorder={isLoggedIn ? (errors && errors[name]) || showRedBorder : null}
      >
        <Field
          type="number"
          name={name}
          placeholder="00"
          onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
          onChange={(e) => onAmountChangeHandler(e.target.value, setFieldValue, name)}
        />
        {name === "lcxAmount" && (
          <DivElement
            bgPopup
            width="86px"
            height="32px"
            displayCenter
            bdGrey
            border="1px solid"
            borderRadius="5px"
          >
            <ImgElement
              width="24px"
              height="24px"
              borderRadius="50%"
              src={saleDetails?.logo}
              alt={saleDetails?.coin}
            />
            <TextWrapper secondary marginLeft="6px">
              {saleDetails?.coin}
            </TextWrapper>
          </DivElement>
        )}

        {name === "lcxPayment" && (
          <DivElement flexAlignCenter justifyContent="flex-end">
            {isLoggedIn && !isPurchaseBalanceEnough && (
              <Button
                type="button"
                onClick={() => onClickAddFunds("PAYMENT_COIN")}
                blueTextBtn
                height="initial"
                margin="0 8px"
                fontSize="14px"
              >
                Add
              </Button>
            )}
            {selectedPaymentCoin && (
              <Select
                styles={reactDropdownStyles({
                  isDarkTheme,
                  height: "32px",
                  width: "126px",
                  isTokenSale: true,
                  valueContainerProps: { height: "100%" },
                  containerProps: { borderRadius: "5px" },
                  indicatorContainerProps: { padding: "2px !important" }
                })}
                onChange={(coin) => onCoinChangeHandler(coin.value, setFieldValue)}
                options={formattedArray(saleDetails?.round?.coins)}
                formatOptionLabel={(val) => <CoinLabel {...val} tokenSale={true} />}
                classNamePrefix="select"
                isSearchable={false}
                defaultValue={{
                  label: selectedPaymentCoin,
                  value: selectedPaymentCoin
                }}
              />
            )}
          </DivElement>
        )}
      </InputContainer>
      {name !== "lcxPayment" && (
        <TextWrapper red position="absolute">
          <ErrorMessage name={name} component="div" />
        </TextWrapper>
      )}
    </DivElement>
  );
};

export default AmountInput;
