import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { PaddingStyle } from "../../StyleComponents/Reusable/Padding.style";
import { MarginStyle } from "../../StyleComponents/Reusable/Margin.style";

const commonStyles = (props) => {
  return css`
    height: ${props.height ? props.height : ""};
    width: ${props.width ? props.width : ""};
    margin: ${props.margin || ""};
    margin-top: ${props.marginTop || ""};
    margin-right: ${props.marginRight || ""};
    margin-bottom: ${props.marginBottom || ""};
    margin-left: ${props.marginLeft || ""};
    cursor: ${props.pointer ? "pointer" : props.cursor};
    position: ${props.absolute ? "absolute" : ""};
    top: ${props.top || ""};
    right: ${props.right || ""};
    bottom: ${props.bottom || ""};
    left: ${props.left || ""};
    min-height: ${props.minHeight ? props.minHeight : ""};
    min-width: ${props.minWidth ? props.minWidth : ""};
    ${props.background && css `background: ${props.background }`};
    ${props.color && css `color: ${props.color}`};
    ${props.fill && css `fill: ${props.fill}`};

    ${MarginStyle}
    ${PaddingStyle}

    @media (max-width: 1024px) {
      height: ${props.heightMd || ""};
      width: ${props.widthMd || ""};
      margin: ${props.marginMd || ""};
      margin-top: ${props.marginTopMd || ""};
      margin-right: ${props.marginRightMd || ""};
      margin-bottom: ${props.marginBottomMd || ""};
      margin-left: ${props.marginLeftMd || ""};
      ${props.backgroundMd && css `background: ${props.backgroundMd }`};
    }

    @media (max-width: 768px) {
      height: ${props.heightSm ? props.heightSm : ""};
      width: ${props.widthSm ? props.widthSm : ""};
      margin: ${props.marginSm || ""};
      margin-top: ${props.marginTopSm || ""};
      margin-right: ${props.marginRightSm || ""};
      margin-bottom: ${props.marginBottomSm || ""};
      margin-left: ${props.marginLeftSm || ""};
      ${props.backgroundSm && css `background: ${props.backgroundSm }`};
    }

    &:active,
    &:focus {
      outline: none;
    }
  `;
};

const SvgWrapper = styled(({ component, ...props }) => React.cloneElement(component, props))`
  ${(props) => commonStyles({ ...props })};
`;

const Wrapper = ({ light, dark, styledProps }) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  return <SvgWrapper component={isDarkTheme && dark ? dark : light} {...styledProps} />;
};

export default Wrapper;
