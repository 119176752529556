import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const FcmCalling = () => {
  let location = useLocation();

  useEffect(() => {
    let mobileTradePath = location.pathname.includes("/mobile-trade");
    if(!mobileTradePath) {
      import("../../utils/push-notification").then(( e ) => {
        e.requestPermissionForNotification();
      });
    }
  }, []);

  return null;
};

export default FcmCalling;