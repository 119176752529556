import React from "react";
import styled, { css } from "styled-components";

const InputField = styled.input`
  width: 100%;
  height: ${({ height }) => (height ? height : "44px")};
  border: none;
  outline: none;
  border-radius: 5px 5px 0 0 !important;
  background: ${({ theme }) => theme.inputBackground} !important;
  color: ${({ theme }) => theme.subText} !important;

  &::placeholder {
    color: ${({ theme }) => theme.subText} !important;
  }
  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.subText} !important;
  }
  ${(props) =>
    props.TransparentBackground &&
    css`
      background: transparent !important;
    `}
  ${(props) =>
    props.InputBottomBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.inputBorder} !important;
    `}
  ${(props) =>
    props.InputBorderWidth &&
    css`
      border-width: ${({ InputBorderWidth }) => InputBorderWidth};
    `};
  ${(props) =>
    props.paddingLeft &&
    css`
      padding-left: 6px;
    `};

  &:focus,
  &:focus-visible {
    border-color: ${(props) => props.theme.primary} !important;
    outline: none;
  }
`;

const InputWrapper = (props) => {
  return <InputField {...props} />;
};

export default InputWrapper;
