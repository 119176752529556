import { useSelector } from "react-redux";
import ChartFavPairs from "./ChartFavPairs";
import { DivElement } from "../../../../../components/Reusable";
import TradingViewChart from "../../../../../components/TradingViewChart";
import DepthChart from "../../Components/DepthChart";

const ChartSection = () => {
  const selectedChartType = useSelector((state) => state.trading.selectedChartType);

  return (
    <DivElement bgCard width="100%" height="100%">
      <ChartFavPairs />
      <DivElement padding="0 12px" height="calc(100% - 52px)">
        {selectedChartType === "depth" ? <DepthChart /> : <TradingViewChart />}
      </DivElement>
    </DivElement>
  );
};

export default ChartSection;
