import { Fragment, useEffect, useState } from "react";
import { capitalize } from "../../../../utils";
import { CloseIcon, SuccessIcon } from "../../../../assets/svgs";
import { HourGlassIllustration } from "../../../../assets/svgs/illustrations";
import {
  DivElement,
  TextWrapper,
  ModalWrapper,
  Td,
  ToolTip
} from "../../../../components/Reusable";
import moment from "moment";

const TXN_STATUS = {
  success: { label: "Successful", value: "success" },
  initiated: { label: "Initiated", value: "initiated" }
};

const BondTransactionStatus = ({ txnDetails, textProps }) => {
  const [showModal, setShowModal] = useState(false);
  const [txnData, setTxnData] = useState(false);
  let status = txnDetails?.status?.toLowerCase();

  useEffect(() => {
    if (!txnDetails) return;

    let data = [
      { label: "Amount", value: `${txnDetails?.quoteAmount} ${txnDetails?.quoteCoin}` },
      { label: "Type", value: capitalize(txnDetails?.type) },
      { label: "Status", value: capitalize(txnDetails?.status) }
    ];
    if (txnDetails?.type === "Cancellation") {
      data.push({
        label: "Termination date",
        value: moment(txnDetails?.cancellationDate).format("DD/MM/YYYY")
      });
      data.push({
        label: "Amount release date",
        value: moment(txnDetails?.releaseDate).format("DD/MM/YYYY"),
        tipContent: txnDetails.next
          ? "Cancellations in the 1st month of this quarter: Released on the first day of the next quarter."
          : "Cancellations in the 2nd or 3rd month of this quarter: Held until the first day of the next to next quarter."
      });
    }

    if (txnDetails?.type === "Investment") {
      data.push({
        label: "Investment date",
        value: moment(txnDetails?.createdAt).format("DD/MM/YYYY")
      });
      data.push({
        label: "First payout date",
        value: moment(txnDetails?.releaseDate).format("DD/MM/YYYY")
      });
    }
    data.push({ label: "Source", value: txnDetails?.source });

    setTxnData(data);
  }, [txnDetails]);

  return (
    <Fragment>
      <Td
        pointer
        green={status === "success" ? true : null}
        orange={status !== "success" ? true : null}
        textDecoration="underline"
        {...textProps}
        onClick={() => setShowModal(true)}
      >
        {txnDetails?.status}
      </Td>

      {showModal && (
        <ModalWrapper>
          <DivElement ModalContainer padding="0">
            <DivElement
              bgCream
              flexJustifyBtwAlignCenter
              height="48px"
              padding="0 24px"
              radius="10px 10px 0 0"
            >
              <DivElement flexAlignCenter>
                {status === "success" ? (
                  <SuccessIcon marginRight="12px" />
                ) : (
                  <HourGlassIllustration width="24px" height="24px" marginRight="12px" />
                )}

                <TextWrapper fontSize="20px" fontSizeSm="16px">
                  {capitalize(txnDetails?.type)} {TXN_STATUS[status].label}
                </TextWrapper>
              </DivElement>
              <CloseIcon onClick={() => setShowModal(false)} />
            </DivElement>
            <DivElement padding="24px 24px 26px 24px" paddingSm="16px 16px 26px 16px">
              {txnData &&
                txnData.map((data, index) => (
                  <DivElement
                    key={index}
                    flexJustifyBtwAlignCenter
                    marginBottom={index === txnData.length - 1 ? "0" : "12px"}
                  >
                    {data.tipContent ? (
                      <ToolTip
                        title={data.label}
                        titleProps={{
                          secondary: true,
                          lineHeight: "20px",
                          fontSizeSm: "12px",
                          nowrap: true
                        }}
                        imgProps={{
                          width: "20px",
                          height: "20px",
                          absolute: null,
                          marginLeft: "6px"
                        }}
                        tipContent={data.tipContent}
                      />
                    ) : (
                      <TextWrapper secondary lineHeight="20px" fontSizeSm="12px" nowrap>
                        {data.label}
                      </TextWrapper>
                    )}

                    <TextWrapper lineHeight="20px" fontSizeSm="12px">
                      {data.value}
                    </TextWrapper>
                  </DivElement>
                ))}
            </DivElement>
          </DivElement>
        </ModalWrapper>
      )}
    </Fragment>
  );
};

export default BondTransactionStatus;
