import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSaleDetails } from "../../../../../lib/api";
import { formatAPIErrors } from "../../../../../utils/general";
// import TokenInfo from "../../Components/TokenInfo";
import SaleDetails from "../../Components/SaleDetails";
import Steps from "../Steps";
import BuyTokenBanner from "../../Components/BuyTokenBanner";
import { DivElement, ElementBox } from "../../../../../components/Reusable";
import { showErrorMsg } from "../../../../../utils/notification";
import { GetKycVerified } from "../../../../../components/Alert";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import styledConstants from "../../../../../constants/styledConstants";
import TokenInfo from "../../Components/TokenInfo";
import SetHeaderTitleComponent from "../../../../../components/ChangeHeaderTitle";
import { fetchAvailableLanguagesRequest } from "../../../../../Modules/Generic/action";
// import { useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "../../../../../Modules/Generic/selectors";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

const BuyToken = () => {
  const [showKycModal, setShowKycModal] = useState(false);
  const [saleDetails, setSaleDetails] = useState("");
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const history = useHistory();
  // const location = useLocation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const isLoggedin = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.dashboard.userData);
  const { transalationList } = useSelector(GenericReducer);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    getDetails();
    if (isLoggedin) {
      dispatch(fetchAvailableLanguagesRequest());
      // let checkPath = location.pathname?.split("/")[1];
      // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
      //   history.push(`${userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/earn`);
      // }
    }
  }, []);

  useEffect(() => {
    if (userData && userData?.KYC.STATUS !== "LEVEL2" && isLoggedin) {
      setShowKycModal(true);
    }
  }, [userData]);

  const getDetails = () => {
    setLoader(true);
    let data = {
      tokenSaleId: params?.id
    };

    getSaleDetails(data)
      .then((res) => {
        setSaleDetails(res?.data?.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
        history.push(
          `${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/token-sale`
        );
      })
      .finally(() => setLoader(false));
  };

  const isDesktop = width > styledConstants.BREAKING_POINT_TABLET;
  const path = history?.location?.pathname;

  const SEOContents = {
    name: `${saleDetails.coin} Token Sale`,
    link: `token-sale/${saleDetails?.status?.toLowerCase()}/${params?.id}`,
    title: `${saleDetails.coin} Token Sale at LCX Exchange | Regulated Crypto Exchange`,
    description: `Participate in the ${saleDetails.coin} token sale at LCX Exchange. Secure transactions, low fees, and seamless integration make it the ideal choice for crypto investors.`,
    keywords: ``,
    canonical: `https://exchange.lcx.com${prevLang ? `/${prevLang}` : "/en" }/${saleDetails?.status?.toLowerCase()}/${params?.id}`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}/${saleDetails?.status?.toLowerCase()}/${params?.id}`,
    lang: `${prevLang ? `/${prevLang}` : "/en"}`,
    OgTitle: `${saleDetails.coin} Token Sale at LCX Exchange | Regulated Crypto Exchange`,
    OgDescription: `Participate in the ${saleDetails.coin} token sale at LCX Exchange. Secure transactions, low fees, and seamless integration make it the ideal choice for crypto investors.`,
    OgURL: "https://www.exchange.lcx.com/token-sale",
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: `${saleDetails.coin} Token Sale at LCX Exchange | Regulated Crypto Exchange`,
    TwDescription: `Participate in the ${saleDetails.coin} token sale at LCX Exchange. Secure transactions, low fees, and seamless integration make it the ideal choice for crypto investors.`
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang ? `/${prevLang}` : userData.preferredLanguage))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/${params?.id}`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <DivElement pageContainer>
        <DivElement width="100%" justifyContent="center">
          <DivElement
            display={width == 1024 ? "" : "flex"}
            flexDirectionMd="column"
            justifyContent="space-between"
            minWidth={width > 1024 && "1124px"}
            minWidthMd="644px"
            minWidthSm="328px"
          >
            {width > 1024 && (
              <DivElement
                width="calc(66.66% - 8px)"
                widthMd="100%"
                padding="0"
                marginBottomMd="0px"
                marginBottomSm="16px"
                borderRadiusMd="5px 5px 0 0"
                borderRadiusSm="5px"
                paddingBottomSm="24px"
              >
                {isDesktop && <BuyTokenBanner saleDetails={saleDetails} loader={loader} />}
                {isDesktop && <SaleDetails saleDetails={saleDetails} />}
              </DivElement>
            )}

            {width <= 1024 && (
              <ElementBox paddingMd="24px" paddingSm="8px">
                <SaleDetails saleDetails={saleDetails} />
              </ElementBox>
            )}

            <DivElement width={width > 1024 && "calc(33.33% - 8px)"} widthMd="100%">
              {(path.includes("ongoing") || path.includes("upcoming")) && (
                <Steps saleDetails={saleDetails} setSaleDetails={setSaleDetails} loader={loader} />
              )}
              {isDesktop && <TokenInfo saleDetails={saleDetails} loader={loader} />}
            </DivElement>
          </DivElement>
        </DivElement>

        {showKycModal && (
          <GetKycVerified
            open={showKycModal}
            onClose={() => {
              setShowKycModal(false);
              history.push(
                `${userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/token-sale`
              );
            }}
          />
        )}
      </DivElement>
    </>
  );
};

export default BuyToken;
