import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useState } from "react";
import { EyeCloseIcon, EyeOpenIcon } from "../../../assets/svgs";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import InputFieldFormik from "../../../components/Reusable/Field/index";
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";

const PasswordInput = ({
  title,
  name,
  value,
  inputProps,
  errorProps,
  placeholder,
  subText,
  onPasteDisable,
  padding,
  margin,
  width
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const onPasteHandler = (e) => {
    if (!onPasteDisable) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  };

  return (
    <BannerBox margin={margin ? margin : "20px 0px"} width={width ? width : ""}>
      <Label size="14px" weight="500" sizeMd="12px" txtGrey>
        {title}
      </Label>

      <BannerBox position="relative" mt="8px">
        <InputFieldFormik
          type={passwordVisible ? "text" : "password"}
          name={name}
          value={value}
          placeholder={placeholder || ""}
          {...inputProps}
          onPaste={onPasteHandler}
          height="44px"
          width="100%"
          AddBenificaryInput
          fontSize={width > 767 ? "14px" : "12px"}
          fontWeight="500"
          padding={padding}
          BodyColor
        />

        <BannerBox
          position="absolute"
          top="23%"
          right="2%"
          zIndex="10"
          onClick={() => setPasswordVisible(!passwordVisible)}
        >
          {passwordVisible ? <EyeOpenIcon /> : <EyeCloseIcon />}
        </BannerBox>
      </BannerBox>

      {subText ? (
        <BannerHeadingSpan size="14px" weight="300" sizeMd="12px" BodyColor margin="6px 0px">
          {subText}
        </BannerHeadingSpan>
      ) : (
        <ErrorMessageDiv
          name={name}
          component="div"
          mt="3px"
          size="14px"
          sizeMd="12px"
          weight="300"
          txtDanger
          {...errorProps}
        />
      )}
    </BannerBox>
  );
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme
});

export default compose(connect(mapStateToProps))(PasswordInput);
