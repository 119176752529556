/* eslint-disable no-unused-vars */
import styled from "styled-components";
import svgStyles from "./style";
import { ReactComponent as Add } from "./all/add.svg";
import { ReactComponent as Agender } from "./all/agender.svg";
import { ReactComponent as Alarm } from "./all/alarm.svg";
import { ReactComponent as Android } from "./all/android.svg";
import { ReactComponent as Apple } from "./all/apple.svg";
import { ReactComponent as ArrowDown } from "./all/arrow_down.svg";
import { ReactComponent as ArrowLeft } from "./all/arrow_left.svg";
import { ReactComponent as ArrowRight } from "./all/arrow_right.svg";
import { ReactComponent as ArrowReferal } from "./all/arrowRefer.svg";
import { ReactComponent as ArrowUp } from "./all/arrow_up.svg";
import { ReactComponent as Bag } from "./all/bag.svg";
import { ReactComponent as Bank } from "./all/bank.svg";
import { ReactComponent as BlueDot } from "./all/BlueDot.svg";
import { ReactComponent as BurgerMenu } from "./all/burger_menu.svg";
import { ReactComponent as BlueNewIcon } from "./all/new_blue.svg";
// import { ReactComponent as BarcodeBtn } from "./all/barcodeButton.svg";
import { ReactComponent as BarChat } from "./all/bar_chart.svg";
import { ReactComponent as Calculator } from "./all/calculator.svg";
import { ReactComponent as Calendar } from "./all/calendar.svg";
import { ReactComponent as Camera } from "./all/camera.svg";
import { ReactComponent as Campaign } from "./all/campaign.svg";
import { ReactComponent as Caution } from "./all/caution.svg";
import { ReactComponent as CautionTriangle } from "./all/cautionYellowTriangle.svg";
import { ReactComponent as Cake } from "./all/cake.svg";
import { ReactComponent as CableBill } from "./all/cable.svg";
import { ReactComponent as CheckboxFill } from "./all/check_box_fill.svg";
import { ReactComponent as CheckboxOutline } from "./all/check_box_outline.svg";
import { ReactComponent as Check } from "./all/check.svg";
import { ReactComponent as CircleCheck } from "./all/circle-check.svg";
import { ReactComponent as ChevronLeft } from "./all/chevron_left.svg";
import { ReactComponent as ChevronRight } from "./all/chevron_right.svg";
import { ReactComponent as Close } from "./all/close.svg";
import { ReactComponent as Computer } from "./all/computer.svg";
import { ReactComponent as Copy } from "./all/copy.svg";
import { ReactComponent as CopyGIcon } from "./all/greyCopyIcon.svg";
import { ReactComponent as CopyRounded } from "./all/copyRounded.svg";
import { ReactComponent as CreditDebitCard } from "./all/credit_debit_card.svg";
import { ReactComponent as Crypto } from "./all/crypto.svg";
import { ReactComponent as CorporateAcc } from "./all/Corporate Acc.svg";
import { ReactComponent as CheckCircle } from "./all/check_circle.svg";
import { ReactComponent as CampaginsLogoNavbar } from "./all/campaignLogo.svg";
import { ReactComponent as DarkMode } from "./all/dark_mode.svg";
import { ReactComponent as Dashboard } from "./all/dashboard.svg";
import { ReactComponent as Defi } from "./all/defi.svg";
import { ReactComponent as DeleteSweep } from "./all/delete_sweep.svg";
import { ReactComponent as Delete } from "./all/delete.svg";
import { ReactComponent as Deposit } from "./all/deposit.svg";
import { ReactComponent as Diamond } from "./all/diamond.svg";
import { ReactComponent as Document } from "./all/document.svg";
import { ReactComponent as DoubleArrowDown } from "./all/double_arrow_down.svg";
import { ReactComponent as DoubleQuote } from "./all/double_quote.svg";
import { ReactComponent as DownloadProcessing } from "./all/download_processing.svg";
import { ReactComponent as Download } from "./all/download.svg";
import { ReactComponent as Discord } from "./all/discord.svg";
import { ReactComponent as DownloadIconReferal } from "./all/download_icon_invite.svg";
import { ReactComponent as EditCalendar } from "./all/edit_calendar.svg";
import { ReactComponent as Edit } from "./all/edit.svg";
import { ReactComponent as Error } from "./all/error.svg";
import { ReactComponent as EmptyBox } from "./all/empty_box.svg";
import { ReactComponent as ExchangeBalance } from "./all/exchange_balance.svg";
import { ReactComponent as Exclamation } from "./all/exclamation.svg";
import { ReactComponent as ExpandLess } from "./all/expand_less.svg";
import { ReactComponent as ExpandMore } from "./all/expand_more.svg";
import { ReactComponent as ExternalLink } from "./all/external_link.svg";
import { ReactComponent as EyeClose } from "./all/eye_close.svg";
import { ReactComponent as EyeOpen } from "./all/eye_open.svg";
import { ReactComponent as Electricity } from "./all/electricity.svg";
import { ReactComponent as Facebook } from "./all/facebook.svg";
import { ReactComponent as Faq } from "./all/faq.svg";
import { ReactComponent as FavouriteFilled } from "./all/favourite_filled.svg";
import { ReactComponent as FamilyHouse } from "./all/familyGrey.svg";
import { ReactComponent as Favourite } from "./all/favourite.svg";
import { ReactComponent as Female } from "./all/female.svg";
import { ReactComponent as Fingerprint } from "./all/fingerprint.svg";
import { ReactComponent as FlipCamera } from "./all/flip_camera.svg";
import { ReactComponent as FeeLogo } from "./all/Fee.svg";
import { ReactComponent as Flag } from "./all/flag.svg";
import { ReactComponent as Gallery } from "./all/gallery.svg";
import { ReactComponent as Home } from "./all/home.svg";
import { ReactComponent as IdIcon } from "./all/idIcon.svg";
import { ReactComponent as Info } from "./all/info.svg";
import { ReactComponent as Instagram } from "./all/instagram.svg";
import { ReactComponent as InternalTransfer } from "./all/internal_transfer.svg";
import { ReactComponent as ItunesAppStoreLogo } from "../svgs/all/itunelogo.svg";
import { ReactComponent as IdVerification } from "./all/id_card.svg";
import { ReactComponent as GooglePlayStoreLogo } from "../svgs/all/google-play-store.svg";
import { ReactComponent as GreyTickIcon } from "./all/greyTick.svg";
import { ReactComponent as Joystick } from "./all/joystick.svg";
import { ReactComponent as KrakenLight } from "./illustrations/light/kraken.svg";
import { ReactComponent as KrakenDark } from "./illustrations/dark/kraken.svg";
import { ReactComponent as KYCInprogress } from "./all/kycInProcess.svg";
import { ReactComponent as KYCRejected } from "./all/KycRejected.svg";
import { ReactComponent as Layout } from "./all/layout.svg";
import { ReactComponent as Learn } from "./all/learn.svg";
import { ReactComponent as LightMode } from "./all/light_mode.svg";
import { ReactComponent as Lightning } from "./all/lightning.svg";
import { ReactComponent as Link } from "./all/link.svg";
import { ReactComponent as Linkedin } from "./all/linkedin.svg";
import { ReactComponent as LocationOn } from "./all/location_on.svg";
import { ReactComponent as Langugae } from "./all/language.svg";
import { ReactComponent as Lock } from "./all/lock.svg";
import { ReactComponent as Logout } from "./all/logout.svg";
import { ReactComponent as MaestroCard } from "./all/maestro_card_icon.svg";
import { ReactComponent as Mail } from "./all/mail.svg";
import { ReactComponent as Male } from "./all/male.svg";
import { ReactComponent as MasterCard } from "./all/master_card_icon.svg";
import { ReactComponent as Mobile } from "./all/mobile.svg";
import { ReactComponent as MorePulse } from "./all/more_puse.svg";
import { ReactComponent as MultiLang } from "./all/MultiLang.svg";
import { ReactComponent as Negative } from "./all/negative.svg";
import { ReactComponent as NationalId } from "./all/greyNationalId.svg";
import { ReactComponent as NationalIdWhite } from "./all/whiteIconIds.svg";
import { ReactComponent as Notification } from "./all/notification.svg";
import { ReactComponent as NotCompleted } from "./all/notComplted.svg";
import { ReactComponent as NotificationOTP } from "./all/notificationOtp.svg";
import { ReactComponent as OneDot } from "./all/one_dot.svg";
import { ReactComponent as Orders } from "./all/orders.svg";
import { ReactComponent as OuterBorder } from "./all/outer_border.svg";
import { ReactComponent as otherUtilityBill } from "./all/otherUtility.svg";
import { ReactComponent as Passport } from "./all/passport.svg";
import { ReactComponent as PendingKYC } from "./all/pendingIcon.svg";
import { ReactComponent as Play } from "./all/play.svg";
import { ReactComponent as Plus } from "./all/plus.svg";
import { ReactComponent as Profile } from "./all/profile.svg";
import { ReactComponent as PhoneWithTickProfile } from "./all/phone_profile.svg";
import { ReactComponent as Promotion } from "./all/promotion.svg";
import { ReactComponent as ProgressSuccess } from "../svgs/all/ProgressSuccess.svg";
import { ReactComponent as QrCode } from "./all/qr_code.svg";
import { ReactComponent as Refer } from "./all/refer.svg";
import { ReactComponent as ReferalNotFound } from "./all/referalHistory.svg";
import { ReactComponent as NoRewardsFound } from "./all/no-records-rewards.svg";
import { ReactComponent as Reports } from "./all/reports.svg";
import { ReactComponent as RewardsHub } from "./all/rewardsHub.svg";
import { ReactComponent as RewardsBlock } from "./all/codesandbox.svg";
import { ReactComponent as RewardsGift } from "./all/rewardsGift.svg";
import { ReactComponent as RewardsHubCard } from "./all/rewardsHubCard.svg";
import { ReactComponent as RewardsFailure } from "./all/RewardsFailure.svg";
import { ReactComponent as ReferralMain } from "./all/referalMain.svg";
import { ReactComponent as ResidenceProof } from "./all/family_home.svg";
import { ReactComponent as RightWay } from "./all/rightWay.svg";
import { ReactComponent as RoadMapLeftBorderIcon } from "./all/RoadmapLeftIcon (2).svg";
import { ReactComponent as RoundExclamation } from "./all/exclamation 1.svg";
import { ReactComponent as QuestionMarkIcon } from "./all/questionMark.svg";
import { ReactComponent as Search } from "./all/search.svg";
import { ReactComponent as SuccessFulRefral } from "./all/successful_refral.svg";
import { ReactComponent as SelfieVerification } from "./all/selfie_verify.svg";
import { ReactComponent as Settings } from "./all/settings.svg";
import { ReactComponent as Share } from "./all/share.svg";
import { ReactComponent as Shield } from "./all/shield.svg";
import { ReactComponent as ShiledBlue } from "./all/shieldBlue.svg";
import { ReactComponent as Signal } from "./all/signal.svg";
import { ReactComponent as Success } from "./all/success.svg";
import { ReactComponent as SuccessfullReferral } from "./all/sucessfull_referral.svg";
import { ReactComponent as Support } from "./all/support.svg";
import { ReactComponent as Swap } from "./all/swap.svg";
import { ReactComponent as SecurityAccount } from "./all/securityAccount.svg";
import { ReactComponent as SecurityFile } from "./all/securityFile.svg";
import { ReactComponent as SecurityLock } from "./all/securityLock.svg";
import { ReactComponent as ThreeDots } from "./all/three_dots.svg";
import { ReactComponent as Tiamonds } from "./all/tiamonds.svg";
import { ReactComponent as Timer } from "./all/timer.svg";
import { ReactComponent as Torch } from "./all/torch.svg";
import { ReactComponent as TotalreferalIll } from "./all/total_referal.svg";
import { ReactComponent as TotalEarnings2 } from "./all/total_earnings2.svg";
import { ReactComponent as TotalEarnings } from "./all/total_earnings.svg";
import { ReactComponent as Trade } from "./all/trade.svg";
import { ReactComponent as TradingCompetition } from "./all/trading_competition.svg";
import { ReactComponent as Twitter } from "./all/twitter.svg";
import { ReactComponent as TwitterReferal } from "./all/twitter_referal.svg";
import { ReactComponent as TelegramReferal } from "./all/telegram_referal.svg";
import { ReactComponent as UpDown } from "./all/up_down.svg";
import { ReactComponent as Upload } from "./all/upload.svg";
import { ReactComponent as User } from "./all/user.svg";
import { ReactComponent as VisaCard } from "./all/visa_card_icon.svg";
import { ReactComponent as Wallet } from "./all/wallet.svg";
import { ReactComponent as WorksReferal1 } from "./all/works1.svg";
import { ReactComponent as WorksReferal2 } from "./all/works2.svg";
import { ReactComponent as WorksReferal3 } from "./all/works3.svg";
import { ReactComponent as Warning } from "./all/warning.svg";
import { ReactComponent as Whitepaper } from "./all/whitepaper.svg";
import { ReactComponent as Windows } from "./all/windows.svg";
import { ReactComponent as WireTransfer } from "./all/wire_transfer.svg";
import { ReactComponent as Withdrawal } from "./all/withdrawal.svg";
import { ReactComponent as WhitepaperTc } from "./all/whitepaperTc.svg";
import { ReactComponent as WebsiteTC } from "./all/webiteTc.svg";
import { ReactComponent as WhatsApp } from "./all/whatsapp.svg";
import { ReactComponent as WrongWay } from "./all/wrongWay.svg";
import { ReactComponent as Youtube } from "./all/youtube.svg";
import { ReactComponent as YellowCircle } from "./all/yellow_circle.svg";
import { ReactComponent as YellowCircleDisabled } from "./all/yellow_circle_disabled.svg";
import { ReactComponent as Zap } from "./all/zap.svg";

export const AgenderIcon = styled(Agender)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const AlarmIcon = styled(Alarm)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const AndroidIcon = styled(Android)`
  ${(props) => svgStyles({ ...props })};
`;

export const AppleIcon = styled(Apple)`
  ${(props) => svgStyles({ ...props })};
`;

export const AddIcon = styled(Add)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ArrowDownIcon = styled(ArrowDown)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ArrowDownRedIcon = styled(ArrowDown)`
  ${(props) => svgStyles({ ...props, fillRed: true })};
`;

export const ArrowLeftIcon = styled(ArrowLeft)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const ArrowLeftWhiteIcon = styled(ArrowLeft)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;
export const ArrowIllustration = styled(ArrowReferal)`
  ${(props) => svgStyles({ ...props })};
`;

export const ArrowRightIcon = styled(ArrowRight)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const ArrowRightWhiteIcon = styled(ArrowRight)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;

export const ArrowRightBlueIcon = styled(ArrowRight)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const ArrowUpIcon = styled(ArrowUp)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ArrowUpGreenIcon = styled(ArrowUp)`
  ${(props) => svgStyles({ ...props, fillGreen: true })};
`;

export const BagIcon = styled(Bag)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const BarChartKycIcon = styled(BarChat)`
  ${(props) => svgStyles({ ...props })};
`;
export const BankIcon = styled(Bank)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const BankWhiteIcon = styled(Bank)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;
export const BlueDotIcon = styled(BlueDot)`
  ${(props) => svgStyles({ ...props })};
`;

export const BurgerMenuIcon = styled(BurgerMenu)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const BlueNewMoreNavbarTag = styled(BlueNewIcon)`
  ${(props) => svgStyles({ ...props })};
`;

export const CalculatorIcon = styled(Calculator)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CalculatorGreenIcon = styled(Calculator)`
  ${(props) => svgStyles({ ...props, fillGreen: true })};
`;

export const CalendarIcon = styled(Calendar)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const CableIcon = styled(CableBill)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const CableWhiteIcon = styled(CableBill)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;

export const CameraIcon = styled(Camera)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CampaignIcon = styled(Campaign)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CameraBlueIcon = styled(Camera)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const CautionIcon = styled(Caution)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const CautionTriangleIcon = styled(CautionTriangle)`
  ${(props) => svgStyles({ ...props })};
`;
export const CakeIcon = styled(Cake)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CheckboxFillIcon = styled(CheckboxFill)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CheckboxOutlineIcon = styled(CheckboxOutline)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CheckIcon = styled(Check)`
  ${(props) => svgStyles({ ...props, width: "initial", height: "initial" })};
`;

export const CircleCheckIcon = styled(CircleCheck)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ChevronLeftIcon = styled(ChevronLeft)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ChevronLeftBlueIcon = styled(ChevronLeft)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const ChevronRightIcon = styled(ChevronRight)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CheckCircleIcon = styled(CheckCircle)`
  ${(props) => svgStyles({ ...props })};
`;

export const ChevronRightBlueIcon = styled(ChevronRight)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const CloseIcon = styled(Close)`
  ${(props) => svgStyles({ ...props, fillGrey: true, pointer: true })};
`;
export const CloseRedIcon = styled(Close)`
  ${(props) => svgStyles({ ...props, fillRed: true, pointer: true })};
`;

export const ComputerIcon = styled(Computer)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CopyGreyIcon = styled(Copy)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CoorporateAccIcon = styled(CorporateAcc)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CopyWhiteIcon = styled(Copy)`
  ${(props) => svgStyles({ ...props, fillWhiteBlack: true })};
`;

export const CopyBlueIcon = styled(Copy)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;
export const CopyBlackIcon = styled(Copy)`
  ${(props) => svgStyles({ ...props, fillBlack: true })};
`;
export const CopyRoundedIcon = styled(CopyRounded)`
  ${(props) => svgStyles({ ...props })};
`;
export const CopyRoundedGreyIcon = styled(CopyGIcon)`
  ${(props) => svgStyles({ ...props })};
`;
export const CopyRoundedBlackIcon = styled(CopyRounded)`
  ${(props) => svgStyles({ ...props, fillBlack: true })};
`;

export const CreditDebitCardIcon = styled(CreditDebitCard)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const CryptoIcon = styled(Crypto)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const DarkModeIcon = styled(DarkMode)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const DashboardIcon = styled(Dashboard)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const DefiIcon = styled(Defi)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const DeleteSweepIcon = styled(DeleteSweep)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const DeleteSweepRedIcon = styled(DeleteSweep)`
  ${(props) => svgStyles({ ...props, fillRed: true })};
`;

export const DeleteIcon = styled(Delete)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const DeleteRedIcon = styled(Delete)`
  ${(props) => svgStyles({ ...props, fillRed: true })};
`;

export const DepositIcon = styled(Deposit)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const DepositOrangeIcon = styled(Deposit)`
  ${(props) => svgStyles({ ...props, fillOrangeSecondary: true })};
`;
export const DiamondIcon = styled(Diamond)`
  ${(props) => svgStyles({ ...props })};
`;
export const DiscordIcon = styled(Discord)`
  ${(props) => svgStyles({ ...props })};
`;

export const DocumentIcon = styled(Document)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const DocumentBlueIcon = styled(Document)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const DoubleArrowDownIcon = styled(DoubleArrowDown)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const DoubleQuoteIcon = styled(DoubleQuote)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const DownloadProcessingIcon = styled(DownloadProcessing)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const DownloadIcon = styled(Download)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const DownloadRefralIcon = styled(DownloadIconReferal)`
  ${(props) => svgStyles({ ...props })};
`;

export const EditCalendarIcon = styled(EditCalendar)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const EditIcon = styled(Edit)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const EditIconBlue = styled(Edit)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const EditIconRed = styled(Edit)`
  ${(props) => svgStyles({ ...props, fillRed: true })};
`;

export const ElectricityBIllIcon = styled(Electricity)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const ElectricityBIllWhiteIcon = styled(Electricity)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;

export const ErrorIcon = styled(Error)`
  ${(props) => svgStyles({ ...props, fillRed: true })};
`;

export const ExchangeBalanceIcon = styled(ExchangeBalance)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ErrorOrangeIcon = styled(Error)`
  ${(props) => svgStyles({ ...props, fillOrange: true })};
`;

export const EmptyBoxIcon = styled(EmptyBox)`
  ${(props) => svgStyles({ ...props })};
`;

export const ExclamationIcon = styled(Exclamation)`
  ${(props) => svgStyles({ ...props })};
`;

export const ExpandLessIcon = styled(ExpandLess)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ExpandMoreIcon = styled(ExpandMore)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ExternalLinkIcon = styled(ExternalLink)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const EyeCloseIcon = styled(EyeClose)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const EyeOpenIcon = styled(EyeOpen)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const FacebookIcon = styled(Facebook)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const FaqIcon = styled(Faq)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const FaqRedIcon = styled(Faq)`
  ${(props) => svgStyles({ ...props, fillRed: true })};
`;

export const FaqYellowIcon = styled(Faq)`
  ${(props) => svgStyles({ ...props, fillYellow: true })};
`;
export const FeeIcon = styled(FeeLogo)`
  ${(props) => svgStyles({ ...props })};
`;

export const FavouriteFilledIcon = styled(FavouriteFilled)`
  ${(props) => svgStyles({ ...props, fillYellow: true })};
`;

export const FavouriteIcon = styled(Favourite)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const FemaleIcon = styled(Female)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const FingerprintGreyIcon = styled(Fingerprint)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const FingerprintOrangeIcon = styled(Fingerprint)`
  ${(props) => svgStyles({ ...props, fillOrange: true })};
`;

export const FingerprintRedIcon = styled(Fingerprint)`
  ${(props) => svgStyles({ ...props, fillRed: true })};
`;

export const FlipCameraIcon = styled(FlipCamera)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const FlagIcon = styled(Flag)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const FlagWhiteIcon = styled(Flag)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;

export const GalleryIcon = styled(Gallery)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const GooglePlayStore = styled(GooglePlayStoreLogo)`
  ${(props) => svgStyles({ ...props })};
`;

export const HomeIcon = styled(Home)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const IdIconGrey = styled(IdIcon)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const IdIconWhite = styled(IdIcon)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;
export const InfoIcon = styled(Info)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const IdVerificatiionIcon = styled(IdVerification)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const InfoBlueIcon = styled(Info)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const InstagramIcon = styled(Instagram)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const ItunesAppStore = styled(ItunesAppStoreLogo)`
  ${(props) => svgStyles({ ...props })};
`;

export const InternalTransferIcon = styled(InternalTransfer)`
  ${(props) => svgStyles({ ...props, lineStrokeGrey: true, pathStrokeGrey: true })};
`;

export const JoyStickIcon = styled(Joystick)`
  ${(props) => svgStyles({ ...props })};
`;

export const KrakenDarkIcon = styled(KrakenDark)`
  ${(props) => svgStyles({ ...props })};
`;
export const KrakenLightIcon = styled(KrakenLight)`
  ${(props) => svgStyles({ ...props })};
`;
export const KYCInprogressIcon = styled(KYCInprogress)`
  ${(props) => svgStyles({ ...props })};
`;
export const KYCRejectedIcon = styled(KYCRejected)`
  ${(props) => svgStyles({ ...props })};
`;

export const LayoutIcon = styled(Layout)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const LearnIcon = styled(Learn)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const LightModeIcon = styled(LightMode)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const LightningIcon = styled(Lightning)`
  ${(props) => svgStyles({ ...props })};
`;

export const LinkIcon = styled(Link)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const LinkedinIcon = styled(Linkedin)`
  ${(props) => svgStyles({ ...props, fillGrey: false })};
`;

export const LinkBlueIcon = styled(Link)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const LocationOnIcon = styled(LocationOn)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const LangugaeIcon = styled(Langugae)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const LockIcon = styled(Lock)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const LockGreenIcon = styled(Lock)`
  ${(props) => svgStyles({ ...props, fillGreen: true })};
`;

export const LogoutIcon = styled(Logout)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const MaestroCardIcon = styled(MaestroCard)`
  ${(props) => svgStyles({ ...props })};
`;

export const MailIcon = styled(Mail)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const MaleIcon = styled(Male)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const MasterCardIcon = styled(MasterCard)`
  ${(props) => svgStyles({ ...props })};
`;

export const MobileIcon = styled(Mobile)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const MobileWhiteIcon = styled(Mobile)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;
export const MobileIconBlue = styled(Mobile)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;
export const MorePulseMenu = styled(MorePulse)`
  ${(props) => svgStyles({ ...props })};
`;
export const MultiLangIcon = styled(MultiLang)`
  ${(props) => svgStyles({ ...props })};
`;
export const NegativeIcon = styled(Negative)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const NatioanlIdIcon = styled(NationalId)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const NatioanlIdWhiteIcon = styled(NationalIdWhite)`
  ${(props) => svgStyles({ ...props })};
`;

export const NotificationIcon = styled(Notification)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const NotCompleteIcon = styled(NotCompleted)`
  ${(props) => svgStyles({ ...props })};
`;
export const NavbarCampagins = styled(CampaginsLogoNavbar)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const NotificationOTPIcon = styled(NotificationOTP)`
  ${(props) => svgStyles({ ...props })};
`;

export const OneDotIcon = styled(OneDot)`
  ${(props) => svgStyles({ ...props })};
`;
export const OtherUtilityIcon = styled(otherUtilityBill)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const OtherUtilityWhiteIcon = styled(otherUtilityBill)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;

export const OrdersIcon = styled(Orders)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const OuterBorderIcon = styled(OuterBorder)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const PassportIcon = styled(Passport)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const PassportWhiteIcon = styled(Passport)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;
export const PendingScreenIcon = styled(PendingKYC)`
  ${(props) => svgStyles({ ...props })};
`;
export const PlayIcon = styled(Play)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const PlusIcon = styled(Plus)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ProfileIcon = styled(Profile)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const PhoneWithTickIcon = styled(PhoneWithTickProfile)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const PromotionIcon = styled(Promotion)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ProgressSuccessIcon = styled(ProgressSuccess)`
  ${(props) => svgStyles({ ...props })};
`;
export const ProgressSuccessGreyIcon = styled(GreyTickIcon)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const QrCodeIcon = styled(QrCode)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const QuestionMark = styled(QuestionMarkIcon)`
  ${(props) => svgStyles({ ...props })};
`;

export const ReferIcon = styled(Refer)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const ReferralIllustration = styled(ReferralMain)`
  ${(props) => svgStyles({ ...props })};
`;
export const ReferralNotFoundIcon = styled(ReferalNotFound)`
  ${(props) => svgStyles({ ...props })};
`;

export const NoRewardsFoundIcon = styled(NoRewardsFound)`
  ${(props) => svgStyles({ ...props })};
`;

export const RedExclamationIcon = styled(RoundExclamation)`
  ${(props) => svgStyles({ ...props })};
`;

export const ReportsIcon = styled(Reports)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const RightWayIcon = styled(RightWay)`
  ${(props) => svgStyles({ ...props })};
`;
export const RoadMapLeftIcon = styled(RoadMapLeftBorderIcon)`
  ${(props) => svgStyles({ ...props })};
`;
export const ResidenceProofIcon = styled(ResidenceProof)`
  ${(props) => svgStyles({ ...props })};
`;
export const ResidenceProofWhiteIcon = styled(FamilyHouse)`
  ${(props) => svgStyles({ ...props })};
`;
export const RewardsBlockicon = styled(RewardsBlock)`
  ${(props) => svgStyles({ ...props })};
`;
export const RewardsHubIcon = styled(RewardsHub)`
  ${(props) => svgStyles({ ...props })};
`;
export const RewardsGiftIllustration = styled(RewardsGift)`
  ${(props) => svgStyles({ ...props })};
`;
export const RewardsHubCardIcon = styled(RewardsHubCard)`
  ${(props) => svgStyles({ ...props })};
`;

export const RewardsFailureIcon = styled(RewardsFailure)`
  ${(props) => svgStyles({ ...props })};
`;

export const SearchIcon = styled(Search)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const SettingsIcon = styled(Settings)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const SelfieVerificationIcon = styled(SelfieVerification)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ShareIcon = styled(Share)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ShieldIcon = styled(Shield)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const ShieldIconBlue = styled(ShiledBlue)`
  ${(props) => svgStyles({ ...props, fillGrey: false })};
`;

export const SignalIcon = styled(Signal)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const SignalGreenIcon = styled(Signal)`
  ${(props) => svgStyles({ ...props, fillGreen: true })};
`;

export const SignalRedIcon = styled(Signal)`
  ${(props) => svgStyles({ ...props, fillRed: true })};
`;

export const SecurityAccountIcon = styled(SecurityAccount)`
  ${(props) => svgStyles({ ...props, fillGrey: false })};
`;

export const SecurityFileIcon = styled(SecurityFile)`
  ${(props) => svgStyles({ ...props, fillGrey: false })};
`;

export const SecurityLockIcon = styled(SecurityLock)`
  ${(props) => svgStyles({ ...props, fillGrey: false })};
`;

export const SuccessIcon = styled(Success)`
  ${(props) => svgStyles({ ...props, fillGreen: true })};
`;
export const SuccessfullIconRefral = styled(SuccessFulRefral)`
  ${(props) => svgStyles({ ...props })};
`;

export const SuccessBlueIcon = styled(Success)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const SuccessfullReferralIcon = styled(SuccessfullReferral)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const SupportIcon = styled(Support)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const SwapIcon = styled(Swap)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const TiamondsIcon = styled(Tiamonds)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const ThreeDotsIcon = styled(ThreeDots)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const TimerGreyIcon = styled(Timer)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const TimerBlueIcon = styled(Timer)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;

export const TimerOrangeIcon = styled(Timer)`
  ${(props) => svgStyles({ ...props, fillOrange: true })};
`;

export const TimerGreenIcon = styled(Timer)`
  ${(props) => svgStyles({ ...props, fillGreen: true })};
`;

export const TorchIcon = styled(Torch)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const TotalEarningsIcon = styled(TotalEarnings)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const TotalEarningsIcon2 = styled(TotalEarnings2)`
  ${(props) => svgStyles({ ...props })};
`;
export const TotalReferal = styled(TotalreferalIll)`
  ${(props) => svgStyles({ ...props })};
`;

export const TradeIcon = styled(Trade)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const TradingCompetitionIcon = styled(TradingCompetition)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const TwitterIcon = styled(Twitter)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const TwitterReferalIcon = styled(TwitterReferal)`
  ${(props) => svgStyles({ ...props })};
`;
export const TelegramReferalIcon = styled(TelegramReferal)`
  ${(props) => svgStyles({ ...props })};
`;

export const UpDownIcon = styled(UpDown)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const UploadIcon = styled(Upload)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const UploadBlueIcon = styled(Upload)`
  ${(props) => svgStyles({ ...props, fillBlue: true })};
`;
export const UserIcon = styled(User)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const UserIconWhite = styled(User)`
  ${(props) => svgStyles({ ...props, fillWhite: true })};
`;

export const VisaCardIcon = styled(VisaCard)`
  ${(props) => svgStyles({ ...props })};
`;

export const WalletIcon = styled(Wallet)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;
export const WorksReferal1Illustration = styled(WorksReferal1)`
  ${(props) => svgStyles({ ...props })};
`;
export const WorksReferalIllustration2 = styled(WorksReferal2)`
  ${(props) => svgStyles({ ...props })};
`;
export const WorksReferalIllustration3 = styled(WorksReferal3)`
  ${(props) => svgStyles({ ...props })};
`;

export const WarningIcon = styled(Warning)`
  ${(props) => svgStyles({ ...props, fillYellow: true })};
`;

export const WhitepaperIcon = styled(Whitepaper)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const WindowsIcon = styled(Windows)`
  ${(props) => svgStyles({ ...props })};
`;

export const WireTransferIcon = styled(WireTransfer)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const WithdrawalIcon = styled(Withdrawal)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const WithdrawalOrangeIcon = styled(Withdrawal)`
  ${(props) => svgStyles({ ...props, fillOrangeSecondary: true })};
`;

export const WhitePaperTcIll = styled(WhitepaperTc)`
  ${(props) => svgStyles({ ...props })};
`;
export const WebsiteTcIll = styled(WebsiteTC)`
  ${(props) => svgStyles({ ...props })};
`;
export const WrongWayIcon = styled(WrongWay)`
  ${(props) => svgStyles({ ...props })};
`;
export const WhatsAppIcon = styled(WhatsApp)`
  ${(props) => svgStyles({ ...props })};
`;

export const YoutubeIcon = styled(Youtube)`
  ${(props) => svgStyles({ ...props, fillGrey: true })};
`;

export const YellowCircleIcon = styled(YellowCircle)`
  ${(props) => svgStyles({ ...props })};
`;

export const YellowCircleDisabledIcon = styled(YellowCircleDisabled)`
  ${(props) => svgStyles({ ...props })};
`;

export const ZapIcon = styled(Zap)`
  ${(props) => svgStyles({ ...props, fillYellow: true })};
`;
