import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { DivElement, ElementBox, ImgElement, TextWrapper, Button } from "../../Reusable";
import { FlipCameraIcon } from "../../../assets/svgs";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const WebCamCapture = ({
  captureImages,
  documentName,
  setOpenWebcam,
  setFirstImage,
  setSecondImage,
  kycStep,
  setCapturedImg
}) => {
  const [documentData, setDocumentData] = useState("");
  const [isError, setIsError] = useState(false);
  const [docName, setDocName] = useState("");
  const [retake, setRetake] = useState(false);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [showWebcam, setShowWebcam] = useState(true);
  const { width } = useWindowSize();

  useEffect(() => {
    setDocName(documentName);

    // eslint-disable-next-line
  }, []);

  const webcam = React.createRef();
  const handleFaceCapture = () => {
    const imageSrc = webcam.current.getScreenshot();

    if (docName === "firstImage") {
      setFirstImage(imageSrc);
    } else if (docName === "secondImage") {
      setSecondImage(imageSrc);
    } else if (docName === "capturedImg") {
      setCapturedImg(imageSrc);
    }

    setRetake(true);
    setDocumentData(imageSrc);
  };

  const onCancelOrRetake = () => {
    if (retake) {
      if (docName === "firstImage") {
        setFirstImage("");
      } else if (docName === "secondImage") {
        setSecondImage("");
      } else if (docName === "capturedImg") {
        setCapturedImg("");
      }
      setRetake(false);
    } else {
      setOpenWebcam(false);
    }
  };

  const onDoneOrTake = () => {
    if (retake) {
      if (kycStep !== "STEP2") captureImages();
      setOpenWebcam(false);
      setRetake(false);
    } else {
      handleFaceCapture();
    }
  };

  const onFlipCamera = () => {
    setShowWebcam(false);
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    );

    setTimeout(() => {
      setShowWebcam(true);
    }, 100);
  };

  return (
    <ElementBox padding="40px 16px" minHeight="500px">
      <DivElement dAlignCenter column>
        <TextWrapper
          fontSize="16px"
          fontSizeSm="14px"
          fontWeight="300"
          lineHeight="1.38"
          lineHeightSm="1.57"
          textAlign="center"
        >
          Before you start, make sure your documents are fully enclosed in the box
        </TextWrapper>
        <DivElement
          marginTop="31.5px"
          width="540px"
          widthMd="400px"
          widthSm="100%"
          height="303.75px"
          heightMd="225px"
          heightSm="auto"
        >
          {isError ? (
            <DivElement displayCenter width="100%" height="100%">
              <span>Your browser is blocking the web cam access, please enable it.</span>
            </DivElement>
          ) : retake ? (
            <ImgElement
              width="100%"
              height="100%"
              borderRadius="8px"
              src={documentData}
              alt="id_placeholder"
            />
          ) : (
            showWebcam && (
              <DivElement relative width="fit-content" height="fit-content">
                <Webcam
                  audio={false}
                  ref={webcam}
                  screenshotFormat="image/jpeg"
                  width={width < 768 ? "100%" : width < 1024 ? "400px" : "540px"}
                  height={width < 768 ? "auto" : width < 1024 ? "225px" : "303.75px"}
                  videoConstraints={{
                    facingMode: facingMode,
                    aspectRatio: 16 / 9
                  }}
                  onUserMediaError={() => setIsError(true)}
                  onUserMedia={() => setIsError(false)}
                  style={{ borderRadius: "8px" }}
                />
                {width < 1024 && (
                  <DivElement className="kycWebcam__flip">
                    <FlipCameraIcon width="28px" height="28px" onClick={onFlipCamera} />
                  </DivElement>
                )}
              </DivElement>
            )
          )}
        </DivElement>

        <DivElement displayCenter marginTop="40px" marginTopSm="24px">
          <Button
            secondaryRed
            width="115px"
            height="48px"
            marginRight="24px"
            padding="0 16px"
            onClick={onCancelOrRetake}
          >
            {retake ? "Retake" : "Cancel"}
          </Button>
          <Button primaryBlue width="145px" height="48px" onClick={onDoneOrTake}>
            {retake ? "Done" : "Take"}
          </Button>
        </DivElement>
      </DivElement>
    </ElementBox>
  );
};

export default WebCamCapture;
