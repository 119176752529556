/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
import Webcam from "react-webcam";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { LiTag, ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";

const CameraComponent = () => {
  const [isCameraEnabled, setIsCameraEnabled] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [showInstructions, setShowInstructions] = useState(false);

  const handleStartCamera = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        setIsCameraEnabled(true);
        setStatusMessage("Camera access granted.");
        setShowInstructions(false);
      })
      .catch((err) => {
        setStatusMessage(
          "Camera access denied. Please allow camera access in your browser settings and try again."
        );
        setShowInstructions(true);
        alert(
          "Camera access denied. Please allow camera access in your browser settings and try again."
        );
      });
  }, []);

  return (
    <BannerBox
      display="flex"
      direction="column"
      gap="15px"
      justifyContent="center"
      alignItems="center"
    >
      <BannerBox display="flex" direction="column" gap="10px" justifyContent="center">
        <BannerHeading size="16px" weight="500">
          Camera Access Needed
        </BannerHeading>
        <ButtonComponent onClick={handleStartCamera} btnHeight="30px" width="100%">
          Start Camera
        </ButtonComponent>
      </BannerBox>
      {isCameraEnabled && <Webcam audio={false} />}
      <p>{statusMessage}</p>
      {showInstructions && (
        <BannerBox>
          <ParaTag>
            Camera access was denied. To enable camera access, please follow these steps:
          </ParaTag>
          <ul>
            <LiTag>Go to your browser settings.</LiTag>
            <LiTag>Find the "Privacy and Security" section.</LiTag>
            <LiTag>Locate "Site Settings" or "Permissions".</LiTag>
            <LiTag>Find the "Camera" settings.</LiTag>
            <LiTag>Allow camera access for this site.</LiTag>
            <LiTag>Refresh this page and try again.</LiTag>
          </ul>
        </BannerBox>
      )}
    </BannerBox>
  );
};

export default CameraComponent;
