import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getTokenSales } from "../../../../actions/tokenSale";
import SaleDashboard from "../Components/SaleDashboard";
// import HalfFooter from "../../../../components/Footer/halfFooter";

const UserDashboard = ({ getTokenSales, tokenSales, apiLoader }) => {
  useEffect(() => {
    getTokenSales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SaleDashboard salesData={tokenSales} loader={apiLoader} dashboardType="USER" />
      {/* <HalfFooter /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  tokenSales: state.sale.tokenSales,
  apiLoader: state.sale.apiLoader
});

const mapDispatchToProps = (dispatch) => ({
  getTokenSales: () => dispatch(getTokenSales())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
