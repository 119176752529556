import React from "react";
import SkeletonLoader from "../../../../components/SkeletonLoader/index";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";

const AddEditAccountLoader = () => {
  return (
    <BannerBox gap="30px" display="flex" direction="column" padding="0px 1rem">
      <SkeletonLoader width={"35%"} height={40} />
      {[0, 1, 2, 3, 4].map((_item, index) => (
        <BannerBox width="100%" display="flex" key={index}>
          <ParaTag width="50%" widthMd="100%">
            <SkeletonLoader width={"20%"} height={20} style={{ margin: "10px 0px" }} />
            <SkeletonLoader width={"100%"} height={35} />
          </ParaTag>
        </BannerBox>
      ))}
    </BannerBox>
  );
};

export default AddEditAccountLoader;