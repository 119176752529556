import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { compose } from "redux";
import styled, { css } from "styled-components";

const RouterLink = styled(Link)`
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  display: ${(props) => (props.display ? props.display : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "")};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : "")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "")};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  color: ${(props) => (props.color ? props.color : props.isDarkTheme ? "#70a6e0" : "#0470e1")};

  :hover {
    color: ${(props) => (props.color ? props.color : props.isDarkTheme ? "#70a6e0" : "#0470e1")};
  }

  ${(props) => props.borderLeft && `border-left: ${props.borderLeft}`};
  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText};
    `};

  ${(props) =>
    props.primary &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#dedfe0" : "#012243")};
      :hover {
        color: ${(props) => (props.isDarkTheme ? "#dedfe0" : "#012243")};
      }
    `}

  ${(props) =>
    props.alignCenter &&
    css`
      display: flex;
      align-items: center;
    `}

  ${(props) =>
    props.blueHover &&
    css`
      :hover {
        background-image: linear-gradient(
          76deg,
          rgb(4, 112, 225, 0.08) 2%,
          rgb(64, 163, 199, 0.08)
        );
        border-right: 4px solid #4595e7;
      }
    `}

  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline !important;
    `}
  ${(props) =>
    props.ActiveLink &&
    css`
      text-decoration: underline !important;
    `}
  ${(props) =>
    props.txtPrimary &&
    css`
      color: ${(props) => props.color || props.theme.primary};
  `};
  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.primaryIcon};
  `};
`;

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(RouterLink);
