/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, Fragment, useMemo, memo } from "react";
import { DepositContext } from "../../../../contexts/WalletPage/DepositPage/depositContext";
import { formatAPIErrors, noExponents, numberWithCommas } from "../../../../utils/general";
import { withdrawCoin, decodeLightningInvoice, withdrawFiat } from "../../../../lib/api";
import { WalletContext } from "../../../../contexts/WalletPage/BalancePage/wallet";
import WithdrawalModal from "../Component/Modal/withdrawalModal";
import { debounce, formatNumbers } from "../../../../utils/helpers";
import { LoadingButton } from "../../../../components/Loader";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import ShowErrorMessage from "../../../../components/Reusable/ShowErrorMessage";
import { DivElement, TextWrapper, Button } from "../../../../components/Reusable";
import BtcLightningPreview from "../../../../components/Wallets/Withdrawals/BtcLightningPreview";
import { BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { numberInputOnWheelPreventChange, setPrecision } from "../../../../utils";
import BigNumber from "bignumber.js";
import { FormInput } from "../../../../assets/StyleComponents/Input.style";
import { getVerifyBlincId } from "../../../../lib/Wallet/walletApi";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { divide, multiply, subtract } from "../../../../utils/bigNumber";

const BlincOptionsComponent = (props) => {
  let { exchWallet } = useContext(WalletContext);
  let { selectedCoin, selectedNetwork } = useContext(DepositContext);
  const [email, setEmail] = useState("");
  const [address, set_address] = useState("");
  const [memoId, setMemoId] = useState("");
  const [amount, set_amount] = useState("");
  const [loading, set_loading] = useState(false);
  const [step, setStep] = useState(1);
  const [otpId, setOtpId] = useState("");
  const [withdrawalRes, setWithdrawalRes] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErroMsg] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [isDecodingInvoice, setIsDecodingInvoice] = useState(false);
  const [decodedInvoiceData, setDecodedInvoiceData] = useState("");
  const [isBalanceInsufficient, setIsBalanceInsufficient] = useState(false);
  const [error, setError] = useState("");
  const [verifyStatus, setVerifyStatus] = useState(false);
  const [emailError, setEmailError] = useState({
    type: false,
    inviteStatus: false,
    disabledStatus: true,
    msg: ""
  });
  // eslint-disable-next-line no-unused-vars
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [finalAmt, setFinalAmt] = useState(0);

  useEffect(() => {
    if (props.apiError && props.apiError.message) {
      showErrorMsg(props.apiError.message);
      props.clearWalletErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiError]);

  useEffect(() => {
    setStep(step + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiData]);

  useEffect(() => {
    if (decodedInvoiceData) setDecodedInvoiceData("");
    if (isBalanceInsufficient) setIsBalanceInsufficient(false);
    if (error) setError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    setError("");
    set_address("");
    set_amount("");
    setErroMsg("");

    if (selectedCoin && selectedCoin.coin === "USDT") {
      if (selectedNetwork?.label === "Ton") {
        selectedCoin.isMemo = true;
      } else {
        selectedCoin.isMemo = false;
      }
    }
    if (selectedCoin.coin === "LCX" && selectedNetwork.label === "LCX Pay")
      setShowVerifyButton(true);
  }, [selectedNetwork, selectedCoin]);

  useEffect(() => {
    if (!selectedCoin) return;
    if (!amount || errorMsg) {
      setFinalAmt("");
      return;
    }
    let totalAmount;
    let amt = new BigNumber(amount);
    if (selectedCoin.coin !== selectedCoin.withdrawalFeeCoin) {
      totalAmount = amt;
    } else {
      let fee = new BigNumber(+0);
      totalAmount = amt.minus(fee);
    }
    if (totalAmount < 0 || !totalAmount) totalAmount = 0;
    setFinalAmt(totalAmount);
  }, [amount, errorMsg]);

  const checkInvalidInput = (value) => {
    let reg = /^(\d{0,8}|\d{0,8}\.\d{0,8})$/;
    return value.match(reg);
  };

  const validateAmount = (value) => {
    let reg = checkInvalidInput(value);
    let valueSplit = value.split(".");
    if (!reg) {
      if (valueSplit && valueSplit.length === 2 && valueSplit[1].length > 8) {
        setErroMsg("Enter correct number");
        set_amount(value);
      }
    } else {
      let amount = isNaN(value) || Number(value) < 0 ? "" : value;
      let feeAmt = Number(selectedCoin?.fees);
      let minAmt = selectedNetwork?.min;

      let maxAmt = Number(selectedNetwork?.max);

      let balance = Number(selectedCoin?.balance?.freeBalance);
      if (amount === "") {
        setErroMsg("");
      } else if (Number(amount) > balance) {
        setErroMsg("Insufficient balance");
      } else if (Number(amount) > maxAmt) {
        setErroMsg(`Amount should be less than or equal to ${maxAmt}`);
      } else if (Number(amount) < minAmt) {
        setErroMsg(`Amount should be greater than or equal to ${minAmt}`);
      } else if (selectedCoin.coin !== selectedCoin.withdrawalFeeCoin) {
        let feeCoin = exchWallet?.data?.find(
          (data) => data.coin === selectedCoin.withdrawalFeeCoin
        );
        let feeBalance = Number(feeCoin?.balance?.freeBalance);

        if (feeBalance < feeAmt) {
          setErroMsg(`Insufficient ${selectedCoin.withdrawalFeeCoin}(transaction fee)`);
        } else {
          setErroMsg("");
        }
      } else {
        setErroMsg("");
      }
      set_amount(amount);
    }
  };

  const withdrawBtnHandler = debounce(() => {
    if (!verifyStatus) {
      showErrorMsg(`Please verify the user.`, { autoClose: 2000 });
      return;
    }
    if (
      Number(amount) < Number(YourFees(selectedNetwork.fee)) &&
      selectedNetwork.label !== "Lightning"
    ) {
      showErrorMsg(
        `Minimum withdrawal amount is ${selectedCoin.minWithdrawalLimit} ${selectedCoin.coin}`,
        { autoClose: 2000 }
      );
      return;
    }

    if (selectedCoin.balance && Number(selectedCoin.balance.freeBalance) < Number(amount)) {
      showErrorMsg("Insufficient balance", { autoClose: 2000 });
      return;
    }

    set_loading(true);

    const params = {
      coin: selectedCoin.coin,
      amount: setPrecision(amount, 8),
      blincId: email,
      source: "ACCOUNTS"
    };

    withdrawFiat(params)
      .then((res) => {
        showSuccessMsg(res.data.message);
        selectedCoin.supportNotabene = res.data.data.notabeneSupport;
        setOtpId(res.data.data.id);
        setWithdrawalRes(res.data.data);
        setShowModal(true);
        set_loading(false);
        setShowPreview(false);
      })
      .catch((err) => {
        set_loading(false);
        showErrorMsg(formatAPIErrors(err));
      });
  }, 700);

  const closeModal = () => {
    setShowModal(false);
  };

  const onClickMaxBtn = () => {
    let balance = setPrecision(selectedCoin?.balance?.freeBalance, 8);
    validateAmount(noExponents(balance));
  };

  const checkuserEmail = debounce(() => {
    let param = {
      blinc_id: email
    };
    getVerifyBlincId(param)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setVerifyStatus(true);
          setEmailError({
            ...emailError,
            type: false,
            disabledStatus: true,
            msg: res?.data?.message || ""
          });
        } else if (res.data?.status === "FAILED") {
          setVerifyStatus(false);
          setEmailError({
            type: true,
            inviteStatus: true,
            disabledStatus: false,
            msg: "Invalid"
          });
        }
        showSuccessMsg("Blinc Id Validated");
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));

        setVerifyStatus(false);
        setEmailError({
          type: true,
          inviteStatus: true,
          disabledStatus: false,
          msg: formatAPIErrors(err)
        });
      });
  }, 600);

  const isBtcLightning = selectedCoin.coin === "BTC" && selectedNetwork.label === "Lightning";
  let isLcxPay = useMemo(
    () => selectedCoin.coin === "LCX" && selectedNetwork.label === "LCX Pay",
    [selectedNetwork]
  );

  let FinalCalculatedFee = useMemo(() => {
    let result = 0;
    if (selectedNetwork.transactionFeePercent) {
      result = multiply(divide(selectedNetwork.transactionFeePercent, 100), Number(amount), 2);
    }
    return result;
  }, [amount, selectedNetwork]);

  function YourFees(data) {
    if (data.type === "FIXED") {
      const DataFromValue = Number(data.value);
      return DataFromValue;
    } else if (data.type === "PERCENTAGE") {
      const DataFromPercentage = Number(
        multiply(divide(Number(data.value), 100), Number(amount), 2)
      );
      const DataFromPrecentage2 = Number(data.min);
      return DataFromPercentage > DataFromPrecentage2 ? DataFromPercentage : DataFromPrecentage2;
    }
  }

  function YouGet() {
    const value = subtract(Number(amount), Number(YourFees(selectedNetwork.fee)), 6);
    return value;
  }

  let minAmt = YourFees(selectedNetwork.fee);

  return (
    <Fragment>
      <Fragment>
        <DivElement className="fInput__form__field" width="100%" marginTop="24px" marginBottom="0">
          <TextWrapper
            secondary
            fontSize="14px"
            fontWeight="300"
            lineHeight="20px"
            marginBottom="6px"
          >
            BLINC ID
          </TextWrapper>
          <DivElement
            className={` fInput__form__input ${
              isLcxPay && emailError.type ? "fInput__form__input--bRed" : ""
            }`}
            position="relative"
          >
            <FormInput
              className="form-control"
              type={"email"}
              required="required"
              sizeMd="12px"
              placeholder={"Enter Blinc Id"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setVerifyStatus(false);
              }}
            />
            {/* {emailError.inviteStatus && */}
            <Button
              secondaryBlue
              padding="0 16px"
              height="32px"
              marginRight="12px"
              disabled={email.length < 3}
              onClick={() => {
                checkuserEmail();
              }}
            >
              verify
            </Button>
            {/* )} */}
          </DivElement>

          {emailError.msg && (
            <ShowErrorMessage
              type={emailError.type ? "error" : "success"}
              message={emailError.msg}
            />
          )}

          {((decodedInvoiceData && !decodedInvoiceData.validation) ||
            isBalanceInsufficient ||
            error) && (
            <ShowErrorMessage
              message={
                error ? error : isBalanceInsufficient ? "Insufficient balance" : "Invalid invoice"
              }
            />
          )}
        </DivElement>

        {!isBtcLightning && (
          <Fragment>
            {/* {selectedCoin.isMemo && (
              <DivElement
                className="fInput__form__field"
                width="100%"
                marginTop="24px"
                marginBottom="0"
              >
                <TextWrapper
                  secondary
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  marginBottom="2px"
                >
                  Memo
                </TextWrapper>
                <DivElement className="fInput__form__input">
                  <FormInput
                    className="form-control"
                    type="text"
                    sizeMd="12px"
                    required="required"
                    placeholder="Enter Memo ID"
                    value={memoId}
                    onChange={(e) => setMemoId(e.target.value)}
                  />
                </DivElement>
              </DivElement>
            )} */}

            <DivElement
              className="fInput__form__field"
              width="100%"
              marginTop="24px"
              marginBottom="0"
            >
              <TextWrapper
                secondary
                fontSize="14px"
                fontWeight="300"
                lineHeight="20px"
                marginBottom="6px"
              >
                {isLcxPay ? "Transfer amount" : "Withdrawal amount"}
              </TextWrapper>

              <DivElement className="fInput__form__input" position="relative">
                <FormInput
                  width="calc(100% - 80px)"
                  className="form-control"
                  type="number"
                  required="required"
                  placeholder="Enter the amount"
                  sizeMd="12px"
                  value={amount}
                  onChange={(e) => validateAmount(e.target.value)}
                  onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  onWheel={numberInputOnWheelPreventChange}
                />

                {isLcxPay && <ParaTag px="16px"> {selectedCoin.coin} </ParaTag>}

                {!isLcxPay && (
                  <Button
                    secondaryBlue
                    padding="0 16px"
                    height="32px"
                    marginRight="12px"
                    onClick={onClickMaxBtn}
                  >
                    max
                  </Button>
                )}
              </DivElement>

              {errorMsg && (
                <ShowErrorMessage
                  message={errorMsg}
                  containerProps={{ marginTop: "4px", absolute: true }}
                />
              )}
            </DivElement>

            {selectedCoin?.fees && (
              <DivElement marginTop="34px">
                <TextWrapper secondary fontSize="14px">
                  Transaction Fee:{" "}
                  <BannerHeadingSpan headerText className="f-14 font-weight-500">
                    <var>
                      {amount >= minAmt
                        ? noExponents(numberWithCommas(YourFees(selectedNetwork.fee)))
                        : 0}{" "}
                      {selectedCoin.withdrawalFeeCoin
                        ? selectedCoin.withdrawalFeeCoin
                        : selectedCoin?.coin}
                    </var>
                  </BannerHeadingSpan>
                </TextWrapper>
                <TextWrapper secondary fontSize="14px" marginTop="10px">
                  Recipient Will Get:{" "}
                  <BannerHeadingSpan headerText className="f-14 font-weight-500">
                    <var>
                      {amount > minAmt
                        ? numberWithCommas(formatNumbers(noExponents(YouGet(selectedNetwork.fee))))
                        : 0}{" "}
                      {selectedCoin?.coin}
                    </var>
                  </BannerHeadingSpan>
                </TextWrapper>
              </DivElement>
            )}
          </Fragment>
        )}

        <Button
          primaryBlue
          width="100%"
          height="48px"
          marginTop="48px"
          disabled={
            isBtcLightning
              ? isDecodingInvoice || error || isBalanceInsufficient || verifyStatus
              : loading || errorMsg
          }
          onClick={withdrawBtnHandler}
        >
          {isLcxPay ? (
            "Make Transfer"
          ) : isBtcLightning ? (
            isDecodingInvoice ? (
              <LoadingButton />
            ) : (
              "Preview withdrawal"
            )
          ) : loading ? (
            <LoadingButton />
          ) : (
            "Withdraw"
          )}
        </Button>
      </Fragment>

      {showModal && (
        <WithdrawalModal
          onClose={closeModal}
          show={showModal}
          otpId={otpId}
          withdrawalRes={withdrawalRes}
          coin={selectedCoin}
          amount={amount}
          address={address}
          setBtnLoading={set_loading}
          goToStep={!selectedCoin.supportNotabene ? 2 : 1}
        />
      )}

      {showPreview && decodedInvoiceData && (
        <BtcLightningPreview
          onClose={() => setShowPreview(false)}
          onConfirm={withdrawBtnHandler}
          loader={loading}
          invoiceData={decodedInvoiceData?.data}
        />
      )}
    </Fragment>
  );
};

export default memo(BlincOptionsComponent);
