import React, { useContext } from "react";
import { BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { WalletContext } from "../../contexts/WalletPage/BalancePage/wallet";
import { EyeCloseIcon, EyeOpenIcon } from "../../assets/svgs";
import { DivElement } from "../Reusable";

const HideToggleComponent = (props) => {
  let { setHideStatus, hideStatus } = useContext(WalletContext);

  return (
    <DivElement display="inline-block">
      <BannerHeading cursor={"pointer"} onClick={() => setHideStatus(!hideStatus)}>
        <BannerHeadingSpan
          weight={300}
          display="flex"
          alignItems="center"
          size={"16px"}
          sizeMd="14px"
          txtGrey
        >
          {hideStatus ? <EyeOpenIcon marginRight="8px" /> : <EyeCloseIcon marginRight="8px" />}
          {`${hideStatus ? "Hide" : "Unhide"} balance`}
        </BannerHeadingSpan>
      </BannerHeading>
    </DivElement>
  );
};

export default HideToggleComponent;
