import React, { useEffect, useState, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { TableLoader } from "../../../Loader";
import { getRecentActivity } from "../../../../actions/dashboard";
import { capitalize } from "../../../../utils";
import styled from "styled-components";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  Pagination,
  NoRecord,
  DivElement,
  Table,
  Tbody,
  Td,
  TextWrapper,
  Th,
  ThDropdown,
  Thead,
  Tr
} from "../../../Reusable";
import { showSuccessMsg } from "../../../../utils/notification";

const IPWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  max-width: 160px;

  @media (max-width: 1024px) {
    max-width: 162px;
  }
  @media (max-width: 767px) {
    max-width: 96px;
  }
`;

const SessionHistory = ({ getRecentLogins, recentLogins, errorGet, loader }) => {
  const [activeCol, setActiveCol] = useState("");
  const [page, setPage] = useState(1);
  const { width } = useWindowSize();

  useEffect(() => {
    let data = {
      page: page - 1
    };
    getRecentLogins(data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeCol !== "" && width >= 1024) setActiveCol("");
    else if (activeCol === "" && width < 768) setActiveCol("Time");
    else if (activeCol === "" && width < 1024) setActiveCol("Region");
    else if (activeCol === "Time" && width >= 768) setActiveCol("Region");
  }, [width]);

  const handlePagination = (pageNum) => {
    let data = {
      page: pageNum - 1
    };
    getRecentLogins(data);
    setPage(pageNum);
  };

  const onAddressCopy = () => {
    showSuccessMsg("Successfully copied IP address!");
  };

  return (
    <DivElement paddingTop="27px" paddingTopSm="24px" paddingBottom="32px" paddingBottomSm="24px">
      <TextWrapper fontSize="24px" fontSizeSm="18px" fontWeight="bold" lineHeight="28px">
        Session History
      </TextWrapper>
      <TextWrapper
        fontSize="16px"
        fontSizeSm="12px"
        fontWeight="300"
        lineHeight="18px"
        marginTop="10px"
      >
        Review historical session records.
      </TextWrapper>

      <DivElement marginTop="34px" marginTopMd="32px">
        {recentLogins && !loader ? (
          <Fragment>
            <Table bRadiusTop="5px" bRadiusBottom="5px" borderCollapse="collapse">
              <Thead fontSize="14px">
                <Tr>
                  {width < 1024 && width >= 768 && (
                    <Th textAlign="left" paddingLeft="16px">
                      Time(DD/MM/YY, 24h)
                    </Th>
                  )}
                  {width >= 1024 && (
                    <Th textAlign="left" paddingLeft="16px">
                      Region
                    </Th>
                  )}
                  <Th textAlign="left">{width >= 1024 ? "IP" : "IP & Device"}</Th>
                  {width >= 1024 && (
                    <Fragment>
                      <Th textAlign="left">Log in Device</Th>
                      <Th textAlign="left">Browser</Th>
                      <Th textAlign="left">Device OS</Th>
                    </Fragment>
                  )}
                  {width >= 1024 && (
                    <Th textAlign="right" paddingRight="16px">
                      Time(DD/MM/YY, 24h)
                    </Th>
                  )}

                  {width < 1024 && (
                    <ThDropdown
                      textAlign="right"
                      paddingRight="16px"
                      paddingRightSm="16px"
                      activeCol={activeCol}
                      selectOption={setActiveCol}
                      OPTIONS={
                        width >= 768
                          ? ["Region", "Device OS", "Browser"]
                          : ["Region", "Device OS", "Browser", "Time"]
                      }
                    />
                  )}
                </Tr>
              </Thead>
              <Tbody
                fontSizeMd="14px"
                fontSizeSm="12px"
                verticalAlign="top"
                paddingTop="18px"
                paddingTopMd="12px"
                paddingTopSm="10px"
              >
                {recentLogins &&
                  recentLogins.data &&
                  recentLogins.data.map((data, index) => (
                    <Tr
                      key={index}
                      height="56px"
                      border={recentLogins.data.length === index + 1 ? null : true}
                    >
                      {width < 1024 && width >= 768 && (
                        <Td textAlign="left" paddingRight="16px" plLg="12px">
                          {moment(data.createdAt).format("DD/MM/YYYY, HH:mm")}
                        </Td>
                      )}
                      {width >= 1024 && (
                        <Td textAlign="left" paddingLeft="16px" width="212px">
                          {data?.region?.fullCountry || "Unknown"}
                        </Td>
                      )}
                      <Td textAlign="left">
                        <CopyToClipboard text={data?.ipAddress || ""} onCopy={onAddressCopy}>
                          <IPWrapper>{data?.ipAddress || "Unknown"}</IPWrapper>
                        </CopyToClipboard>
                        {width < 1024 && (
                          <TextWrapper fontSize="inherit">
                            {capitalize(data?.userAgent?.device || "Unknown")}
                          </TextWrapper>
                        )}
                      </Td>
                      {width >= 1024 && (
                        <Td textAlign="left">{capitalize(data?.userAgent?.device || "Unknown")}</Td>
                      )}
                      {width < 1024 && activeCol === "Region" && (
                        <Td textAlign="right" paddingRight="30px">
                          {data?.region?.fullCountry || "Unknown"}
                        </Td>
                      )}
                      {(width >= 1024 || (width < 1024 && activeCol === "Browser")) && (
                        <Td
                          textAlign={activeCol === "Browser" ? "right" : "left"}
                          paddingRight={activeCol === "Browser" ? "30px" : "0"}
                        >
                          {capitalize(data?.userAgent?.browser?.name || "Unknown")}
                        </Td>
                      )}
                      {(width >= 1024 || (width < 1024 && activeCol === "Device OS")) && (
                        <Td
                          textAlign={activeCol === "Device OS" ? "right" : "left"}
                          paddingRight={activeCol === "Device OS" ? "30px" : "0"}
                        >
                          {data?.userAgent?.os?.name || "Unknown"}
                        </Td>
                      )}
                      {(width >= 1024 || (width < 768 && activeCol === "Time")) && (
                        <Td
                          textAlign="right"
                          paddingRight={activeCol === "Time" ? "30px" : "16px"}
                          prLg="16px"
                        >
                          {moment(data.createdAt).format("DD/MM/YYYY, HH:mm")}
                        </Td>
                      )}
                    </Tr>
                  ))}
              </Tbody>
            </Table>

            {recentLogins?.data?.length === 0 && <NoRecord />}

            {recentLogins?.data?.length > 0 && (
              <Pagination
                page={page}
                totalCount={recentLogins.count}
                setPage={(data) => handlePagination(data)}
                itemPerPage={10}
              />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <TableLoader rows={10} webCol={6} tabletCol={3} mobileCol={2} />
          </Fragment>
        )}
      </DivElement>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  loader: state.dashboard.isFetchingRecentActivity,
  errorGet: state.dashboard.errorGet,
  recentLogins: state.dashboard.recentLogins,
  isTheme: state.theme.isTheme
});

const mapDispatchToProps = (dispatch) => ({
  getRecentLogins: (data) => dispatch(getRecentActivity(data))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SessionHistory);
