import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, reactDropdownStyles } from "../../../../../utils";
import { DropdownIndicator } from "../../../../../components/ReusedComponent/SelectReused";
import { updateOrderFormType } from "../../../../../actions/trading";

const OPTIONS = [
  { label: "Limit", value: "limit" },
  { label: "Market", value: "market" }
];

const TradeTypeDropdown = () => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const orderFormType = useSelector((state) => state.trading.orderFormType);
  const dispatch = useDispatch();

  return (
    <Select
      options={OPTIONS}
      value={orderFormType ? { label: capitalize(orderFormType), value: orderFormType } : ""}
      onChange={(val) => dispatch(updateOrderFormType(val?.value))}
      styles={reactDropdownStyles({
        isDarkTheme,
        containerProps: {
          minWidth: "92px",
          height: "36px",
          padding: "0 8px",
          background: "transparent",
          fontSize: "12px"
        },
        valueContainerProps: { padding: "0" },
        dropdownIndicatorProps: { padding: "0 !important" },
        isTokenSale: true,
        optionProps: { fontSize: "12px" }
      })}
      components={{ DropdownIndicator }}
      isSearchable={false}
    />
  );
};

export default TradeTypeDropdown;
