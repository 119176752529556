import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { DivElement } from "../../../../../components/Reusable";
import { updateOrderFormType } from "../../../../../actions/trading";

const Tab = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.primaryText};
  width: ${(props) => props.width || ""};
  height: 20px;
  margin-left: ${(props) => props.marginLeft || ""};
  cursor: pointer;

  &:hover {
    font-weight: 500;
    color: ${(props) => props.theme.primaryBlue};
    text-decoration: underline;
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: 500;
      color: ${(props) => props.theme.primaryBlue};
      text-decoration: underline;
    `}
`;

const TradeTypeNavbar = () => {
  const orderFormType = useSelector((state) => state.trading.orderFormType);
  const dispatch = useDispatch();
  return (
    <DivElement flex marginTop="20px">
      <Tab
        width="33px"
        active={orderFormType === "limit" ? true : null}
        onClick={() => dispatch(updateOrderFormType("limit"))}
      >
        Limit
      </Tab>
      <Tab
        width="45px"
        active={orderFormType === "market" ? true : null}
        onClick={() => dispatch(updateOrderFormType("market"))}
        marginLeft="16px"
      >
        Market
      </Tab>
    </DivElement>
  );
};

export default TradeTypeNavbar;
