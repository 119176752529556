import styled from "styled-components";
import { DivElement } from "../Reusable";

const CircleSvg = styled.svg`
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
  display: inline-block;

  circle {
    position: absolute;
    top: 0;
    left: 0;
    stroke-dasharray: 47px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 2px;
    animation: countdown 30s linear infinite;
    stroke: ${(props) => props.theme.primaryBlue};
    fill: ${(props) => props.theme.cardBg};
  }

  @keyframes countdown {
    from {
      stroke-dashoffset: 0px;
    }

    to {
      stroke-dashoffset: 47px;
    }
  }
`;

const CircleLoader = () => {
  return (
    <DivElement relative width="24px" height="24px" display="inline-block">
      <CircleSvg>
        <circle r="8" cx="28" cy="25"></circle>
      </CircleSvg>
    </DivElement>
  );
};

export default CircleLoader;
