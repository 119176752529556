import React, { useState, useEffect } from "react";
import RewardsHubParent from "../components/RewardsHubParent";
import { DivElement, ImgElement, RouterLink, TextWrapper } from "../../../components/Reusable";
import { ChevronLeftIcon, InfoIcon } from "../../../assets/svgs";
import MyRewardsBanner from "../../../assets/images/myRewardsBanner.svg";
import ReactTooltip from "react-tooltip";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { HeadingSecond } from "../../../assets/StyleComponents/FontSize/headsecond.style";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTotalRewardsData,
  getPrivateReglarRewardsTasksList,
  resetRewardHubState,
  // updatePrivateReglarRewardsTasksList,
  updateRewardsTab
} from "../../../actions/RewardsHub/RewardsHub";
import SingleRewardTask from "../components/SingleRewardsTask/SingleRewardTask";
import { Skeleton } from "../../../components/Loader";
import { new_BN } from "../../../utils/bigNumber";

const tabList = [
  {
    key: 1,
    name: "All",
    value: "all"
  },
  {
    key: 2,
    name: "Unlocked",
    value: "unlocked"
  },
  {
    key: 3,
    name: "Claimed",
    value: "claimed"
  },
  {
    key: 4,
    name: "Expired",
    value: "expired"
  }
];

const MyRewardsSection = () => {
  const prevLang = localStorage.getItem("ljs-lang");
  let isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const [currentTab, setCurrentTab] = useState(tabList[0].value);

  const dispatch = useDispatch();
  const successMsg = useSelector((state) => state.rewards.success);
  const totalRewardsCount = useSelector((state) => state.rewards.totalRewardsCount);
  const isFetchingTotalRewardsCount = useSelector((state) => state.rewards.isFetchingTotalRewardsCount);

  let param = {
    type: currentTab,
    offset: "0",
    pageSize: 25,
    category: "REGULAR"
  };

  useEffect(() => {
    dispatch(fetchTotalRewardsData());
  }, []);

  useEffect(() => {
    dispatch(updateRewardsTab(param));
    dispatch(getPrivateReglarRewardsTasksList(param));
  }, [currentTab]);

  useEffect(() => {
    if (successMsg) {
      // dispatch(updatePrivateReglarRewardsTasksList(param));
      dispatch(resetRewardHubState());
    }
  }, [successMsg]);

  return (
    <RewardsHubParent>
      <RouterLink
        to={`/${prevLang}/rewards`}
        txtGrey
        marginBottom="27px"
        cursor="pointer"
        textDecoration="none"
        display="flex"
        alignitems="center"
      >
        <ChevronLeftIcon />
        Back
      </RouterLink>
      <BannerBox position="relative" borderRadius="10px" overflow="hidden" heightMd="141px" heightLg="180px">
        <ImgElement src={MyRewardsBanner} alt="My Rewards Page Banner" width="100%" objectFit="cover" heightMd="180px"  />
        <DivElement
          position="absolute"
          flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          top="25%"
          topMd="15%"
          topSm="10%"
        >
          <TextWrapper fontSize="52px" fontWeight="500" borderBottom="1px solid #fff" fontGradient>
            {isFetchingTotalRewardsCount ? <Skeleton /> :  `€${new_BN(totalRewardsCount || 0, 2)}`}
          </TextWrapper>
          <DivElement flex alignItems="center" justifyContent="center">
            <TextWrapper fontWhite fontSize="15px" lineHeight="24px" fontWeight="400" margin="8px">
              My Total Rewards
            </TextWrapper>
            <InfoIcon
              data-tip
              data-for="total-reward-tooltip"
              data-background-color="#cde2f9"
              SVGExtraSmallSize
            />

            <ReactTooltip
              id="total-reward-tooltip"
              type="light"
              place="right"
              className="r-tool-tip"
            >
              Total earned rewards equivalent to EURO
            </ReactTooltip>
          </DivElement>
        </DivElement>
      </BannerBox>

      <BannerBox
        CardWhite2
        radius={"10px"}
        padding={"32px 40px 32px"}
        paddingLg={"24px 20px 48px"}
        paddingMd={"16px 12px 8px"}
        margin="1.1rem 0px"
        alignItems="center"
        gap="20px"
      >
        <BannerBox 
          display="flex"
          directionMd="column"
          gapMd="20px"
          justifyContent="space-between"
          bottomBorder
          mb={tabList && tabList?.length ? "32px" : ""}
        >
          <BannerBox display="flex" alignItems="center" justifyContentMd="flex-start" justifyContent="center">
            <HeadingSecond size="20px" weight="500" mr="5px">
                My Rewards
              </HeadingSecond>
              <InfoIcon
                data-tip
                data-for="my-reward-info-tooltip"
                data-background-color="#cde2f9"
                SVGExtraSmallSize
              />

              <ReactTooltip
                id="my-reward-info-tooltip"
                type="light"
                place="right"
                className="r-tool-tip"
              >
                List of rewards you earned
              </ReactTooltip>
          </BannerBox>

          {tabList?.length && (
            <DivElement>
              {tabList?.map((tab) => (
                <ButtonComponent
                  key={tab?.key}
                  RewardHubTab
                  isDarkTheme={isDarkTheme}
                  isActive={!!(tab.value === currentTab)}
                  onClick={() => setCurrentTab(tab?.value)}
                >
                  {tab?.name}
                </ButtonComponent>
              ))}
            </DivElement>
          )}
        </BannerBox>

        <SingleRewardTask />
      </BannerBox>
    </RewardsHubParent>
  );
};

export default MyRewardsSection;
