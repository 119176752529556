import { css } from "styled-components";
import { createResponsiveStyles } from "../GlobalStyle/DynamicResponsiveDesign/DynamicResponsiveDesign.style";

const DisplayStyle = css`
  ${({ display, displayXl, displayLg, displayMd, displaySm }) => (display || displayXl || displayLg || displayMd || displaySm) && 
    createResponsiveStyles("display", "display", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : display 
    )
  }
  
  ${({ direction, directionXl, directionLg, directionMd, directionSm }) => (direction || directionXl || directionLg || directionMd || directionSm) && 
    createResponsiveStyles("direction", "flex-direction", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : direction 
    )
  }
  
  ${({ gap, gapXl, gapLg, gapMd, gapSm }) => (gap || gapXl || gapLg || gapMd || gapSm) && 
    createResponsiveStyles("gap", "gap", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : gap 
    )
  }

  ${({ alignSelf, alignSelfXl, alignSelfLg, alignSelfMd, alignSelfSm }) => (alignSelf || alignSelfXl || alignSelfLg || alignSelfMd || alignSelfSm) && 
    createResponsiveStyles("alignSelf", "align-self", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : alignSelf 
    )
  }

  ${({ alignItems, alignItemsXl, alignItemsLg, alignItemsMd, alignItemsSm }) => (alignItems || alignItemsXl || alignItemsLg || alignItemsMd || alignItemsSm) && 
    createResponsiveStyles("alignItems", "align-items", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : alignItems 
    )
  }
  
  ${({ justifyContent, justifyContentXl, justifyContentLg, justifyContentMd, justifyContentSm }) => (justifyContent || justifyContentXl || justifyContentLg || justifyContentMd || justifyContentSm) && 
    createResponsiveStyles("justifyContent", "justify-content", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : justifyContent 
    )
  }

  ${({ flexDisplay }) => flexDisplay && `
    display: flex;
  `}
  
  ${({ flexCenter }) => flexCenter && `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  
  ${({ flexJustifyCenter }) => flexJustifyCenter && `
    display: flex;
    justify-content: center;
  `}
  
  ${({ flexJustifyAround }) => flexJustifyAround && `
    display: flex;
    justify-content: space-around;
  `}
  
  ${({ flexJustifyBetween }) => flexJustifyBetween && `
    display: flex;
    justify-content: space-between;
  `}
  
  ${({ flexAlignCenter }) => flexAlignCenter && `
    display: flex;
    align-items: center;
  `}
  
  ${({ flexAlignAround }) => flexAlignAround && `
    display: flex;
    align-items: space-around;
  `}
  
  ${({ flexAlignBetween }) => flexAlignBetween && `
    display: flex;
    align-items: space-between;
  `}
  
  ${({ flexAlignSelfCenter }) => flexAlignSelfCenter && `
    display: flex;
    align-self: center;
  `}
  
  ${({ flexAlignSelfAround }) => flexAlignSelfAround && `
    display: flex;
    align-self: space-around;
  `}
  
  ${({ flexAlignSelfBetween }) => flexAlignSelfBetween && `
    display: flex;
    align-self: space-between;
  `}
  
`;
export { DisplayStyle };
