import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import ActiveSessions from "./ActiveSessions";
import SessionHistory from "./SessionHistory";

const Activity = () => {
  return (
    <BannerBox padding={"0 16px"}
      paddingMd={"20px 16px"}>
      <ActiveSessions />
      <SessionHistory />
    </BannerBox>
  );
};

export default Activity;
