import React, { Fragment } from "react";
import { DivElement } from "../Reusable";
import Skeleton from "./Skeleton";

const EarnInvestLoader = () => {
  return (
    <Fragment>
      <DivElement padding="0 20px">
        <DivElement displayBtw>
          <Skeleton width="100px" height="16px" />
          <Skeleton width="100px" height="16px" />
        </DivElement>
        <Skeleton width="100%" height="44px" />
        <DivElement displayBtw marginTop="42px" marginBottom="10px">
          <Skeleton width="100px" height="16px" />
          <Skeleton width="100px" height="16px" />
        </DivElement>
        <DivElement displayBtw marginBottom="10px">
          <Skeleton width="100px" height="16px" />
          <Skeleton width="100px" height="16px" />
        </DivElement>
      </DivElement>

      <DivElement bgCream padding="16px 20px" marginBottom="12px">
        {[1, 2, 3, 4, 5, 6].map((item, i) => (
          <DivElement key={i} displayBtw marginBottom={i === 5 ? "0" : "12px"}>
            <Skeleton height="16px" width="200px" widthSm="100px" />
            <Skeleton height="16px" width="200px" widthSm="100px" />
          </DivElement>
        ))}
      </DivElement>
      <DivElement padding="0 20px">
        <Skeleton width="100%" height="38px" />
        <Skeleton width="100%" height="48px" marginTop="20px" />
      </DivElement>
    </Fragment>
  );
};

export default EarnInvestLoader;
