import { css } from "styled-components";
import { createResponsiveStyles } from "../GlobalStyle/DynamicResponsiveDesign/DynamicResponsiveDesign.style";

const MarginStyle = css`
  ${({ mt, mtXl, mtLg, mtMd, mtSm }) => (mt || mtXl || mtLg || mtMd || mtSm) && 
    createResponsiveStyles("mt", "margin-top", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : mt 
    )
  }

  ${({ mb, mbXl, mbLg, mbMd, mbSm }) => (mb || mbXl || mbLg || mbMd || mbSm) && 
    createResponsiveStyles("mb", "margin-bottom", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : mb 
    )
  }

  ${({ ml, mlXl, mlLg, mlMd, mlSm }) => (ml || mlXl || mlLg || mlMd || mlSm) && 
    createResponsiveStyles("ml", "margin-left", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : ml 
    )
  }

  ${({ mr, mrXl, mrLg, mrMd, mrSm }) => (mr || mrXl || mrLg || mrMd || mrSm) && 
    createResponsiveStyles("mr", "margin-right", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : mr 
    )
  }
  
  ${({ mx, mxXl, mxLg, mxMd, mxSm }) => (mx || mxXl || mxLg || mxMd || mxSm) && css `
    ${createResponsiveStyles("mx", "margin-left", (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : `${mx} !important` )}
    ${createResponsiveStyles("mx", "margin-right", (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : `${mx} !important` )}
  `}

  ${({ my, myXl, myLg, myMd, mySm }) => (my || myXl || myLg || myMd || mySm) && css `
    ${createResponsiveStyles("my", "margin-top", (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : `${my} !important` )}
    ${createResponsiveStyles("my", "margin-bottom", (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : `${my} !important` )}
  `}

  ${({ margin, marginXl, marginLg, marginMd, marginSm }) => (margin || marginXl || marginLg || marginMd || marginSm) && 
    createResponsiveStyles("margin", "margin", 
      (value) => !(value === false || value === "null" || typeof value === "undefined")  ? value : `${margin} !important` 
    )
  }
`;
export { MarginStyle };
