/* eslint-disable */
export const styleConstant = (isDarkTheme) => {
  return isDarkTheme
    ? {
        primary_blue: "#70a6e0",
        primary_green: "#68bf60",
        primary_red: "#e66767",
        sep_color: "#38393D",
        primary_text: "#dedfe0",
        primary_orange: "#ffb83e",
        primary_yellow: "#ffc955",
        primary_white: "#012243",
        sub_text: "#a7a9ab",
        primary_icon: "#bbbdbf",
        tapable_input_field: "#969799",
        input_field: "#1f2c38",
        primary_bg: "#0a0d12",
        cards_bg: "#151e26",
        popups_bg: "#263645",
        popups_bg_active: "#263645",
        button_active: "#70a6e0",
        button_inactive: "#393b40",
        button_active_text: "#151e26",
        input_focus_bg: "#112d51",
        checkbox_border: "#70a6e0",
        checkbox_bg: "transparent",
        checkbox_content: "#70a6e0",
        footer_bg: "#151e26",
        table_head_bg: "#1f2c38",
        dashboard_table_head_bg: "#1f2c38",
        dashboard_border_color: "#38393d",
        linear_gradient: "linear-gradient(51deg, #70a6e0 14%, #40a3c7 87%)",
        dashboard_bg: "#151e26",
        bg_black: "#151e26",
        earn_header_bg: "#162029",
        dropdown_option_bg: "#3f5a73",
        inputActive: "#263645"
      }
    : {
        primary_blue: "#0470e1",
        primary_green: "#008000",
        primary_red: "#e65252",
        primary_text: "#012243",
        primary_orange: "#ffb83e",
        primary_yellow: "#ffc955",
        primary_white: "#fff",
        primary_icon: "#706f6f",
        sub_text: "#606f80",
        sep_color: "#ccced0",
        tapable_input_field: "#8c9797",
        input_field: "#f5f6fa",
        primary_bg: "#f5f6fa",
        cards_bg: "#ffffff",
        popups_bg: "#ffffff",
        popups_bg_active: "#e6f1fc",
        button_active: "#cde2f9",
        button_inactive: "#f5f6fa",
        button_active_text: "#0470e1",
        input_focus_bg: "#cde2f9",
        checkbox_border: "#ccced0",
        checkbox_bg: "#0470e1",
        checkbox_content: "#fff",
        footer_bg: "#e6f0fc",
        table_head_bg: "#f5f6fa",
        dashboard_table_head_bg: "#e1e9f5",
        dashboard_border_color: "#ccced0",
        linear_gradient: "linear-gradient(51deg, #0470e1 14%, rgba(64, 163, 199, 0.99) 87%)",
        dashboard_bg: "#f5faff",
        bg_black: "#0e141a",
        earn_header_bg: "#fafafa",
        dropdown_option_bg: "#f5f9fe",
        inputActive: "#ECF5FE"
      };
};
