import React, { memo } from "react";
import { Link } from "react-router-dom";
import SkeletonLoader from "../../../components/SkeletonLoader/index";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import RecentActivity from "../RecentActivity/recentActivity";
import { ChevronRightIcon } from "../../../assets/svgs";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import InputSwitch from "../../../components/Reusable/InputSwitch";

const FeeComponent = (props) => {
  const {
    isLoader,
    feeCheck,
    handleCheckBox,
    levelStatus,
    getUserData,
    isFetchingRecentActivity,
    recentActivity,
    currentFeePair
  } = props;

  const { width } = useWindowSize();
  const prevLang = localStorage.getItem("ljs-lang");
  
  return (
    <>
      <BannerBox
        // height="264px"
        display="flex"
        flex={levelStatus === "LEVEL2" ? "0 0 33.33%" : "0 0 34%"}
        flexLg="1"
        justifyContent="space-between"
        direction="column"
        mtLg="10px"
        mt="16px"
        mbLg="10px"
        mbMd="12px"
        order="4"
        padding="0px 6px 0px 13px"
        paddingLg="0px"
        mtMd="12px"
        gap={levelStatus === "LEVEL1" && "16px"}
      >
        <BannerBox
          CardWhite
          DashboardBordersdark
          height="264px"
          heightMd="219px"
          heightLg="204px"
          width="100%"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          borderRadius="10px"
          padding="0.8rem 16px"
          paddingMd="1rem 16px"
          paddingLg="10px 16px"
          mbMd={levelStatus === "LEVEL0" && "12px"}
        >
          <BannerBox
            display="flex"
            justifyContent="space-between"
            mb="26.5px"
            mbMd="27px"
            mbLg="35px"
            mtLg="10px"
            mtMd="0px"
            mt="5px"
          >
            <BannerHeading
              BodyColor
              weight="500"
              mb="0px"
              size="1.125rem"
              sizeLg="18px"
              sizeMd="16px"
            >
              Your Trading Fees
            </BannerHeading>
            <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/fees`}>
              <ChevronRightIcon />
            </Link>
          </BannerBox>

          <BannerBox
            displayLg="flex"
            direction="column"
            directionLg="row"
            directionMd="column"
            heightLg="87px"
            heightMd="95px"
            alignItemsLg="flex-end"
            gapMd="20px"
          >
            <BannerBox
              display="flex"
              justifyContent="space-between"
              width="100%"
              mb="25px"
              height="87px"
              heightMd="80px"
              mbLg="0px"
            >
              <BannerBox width="50%" display="flex" direction="column">
                <ParaTag BodyColor size="14px" lineHeight="20px">
                  {" "}
                  Maker:{" "}
                </ParaTag>
                {isLoader ? (
                  <BannerBox display="flex" direction="column" mt="1rem" height="100%">
                    <SkeletonLoader width={80} />
                  </BannerBox>
                ) : (
                  <>
                    {feeCheck && (
                      <ParaTag
                        textDecoration="line-through"
                        weight="400"
                        txtGrey
                        size="14px"
                        mt="10px"
                      >
                        {`${currentFeePair?.MakerFees ? currentFeePair?.MakerFees : "0.3"}%`}
                      </ParaTag>
                    )}
                    {feeCheck ? (
                      <ParaTag BodyColor weight="500" size="24px">
                        {currentFeePair?.MakerFeesInLCX ? currentFeePair?.MakerFeesInLCX : "0.3"}%
                      </ParaTag>
                    ) : (
                      <ParaTag BodyColor weight="500" size="30px">
                        {currentFeePair?.MakerFees ? currentFeePair?.MakerFees : "0.3"}%
                      </ParaTag>
                    )}
                  </>
                )}
              </BannerBox>
              <BannerBox width="50%" display="flex" direction="column">
                <ParaTag BodyColor size="14px" lineHeight="20px">
                  {" "}
                  Taker:{" "}
                </ParaTag>
                {isLoader ? (
                  <BannerBox display="flex" direction="column" mt="1rem" height="100%">
                    <SkeletonLoader width={80} />
                  </BannerBox>
                ) : (
                  <>
                    {feeCheck && (
                      <ParaTag
                        textDecoration="line-through"
                        weight="400"
                        txtGrey
                        size="14px"
                        mt="10px"
                      >
                        {currentFeePair?.TakerFees ? currentFeePair?.TakerFees : "0.3"}%
                      </ParaTag>
                    )}
                    {feeCheck ? (
                      <ParaTag BodyColor weight="500" size="24px">
                        {currentFeePair?.TakerFeesInLCX ? currentFeePair?.TakerFeesInLCX : "0.3"}%
                      </ParaTag>
                    ) : (
                      <ParaTag BodyColor weight="500" size="30px">
                        {currentFeePair?.TakerFees ? currentFeePair?.TakerFees : "0.3"}%
                      </ParaTag>
                    )}
                  </>
                )}
              </BannerBox>
            </BannerBox>

            {width > 1024 && <BannerBox LineThroughBorder mb="25px"></BannerBox>}

            <BannerBox
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              alignItemsMd="start"
              justifyContentMd="space-between"
            >
              <BannerHeading weight="500" BodyColor size="14px">
                Allow fees in LCX{" "}
                <BannerHeadingSpan weight="700"> (50% discount) </BannerHeadingSpan>
              </BannerHeading>

              <InputSwitch
                checked={feeCheck}
                setChecked={() => handleCheckBox()}
                disabled={currentFeePair ? false : true}
              />
            </BannerBox>
          </BannerBox>
        </BannerBox>

        {levelStatus !== "LEVEL2" && (
          <RecentActivity
            isLoader={isLoader}
            getUserData={getUserData}
            isFetchingRecentActivity={isFetchingRecentActivity}
            recentActivity={recentActivity}
            levelStatus={levelStatus}
          />
        )}
      </BannerBox>
    </>
  );
};

export default memo(FeeComponent);
