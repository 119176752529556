import { Fragment, useState } from "react";
import { CloseIcon, DeleteSweepIcon } from "../../../../../assets/svgs";
import { Button, DivElement, ModalWrapper, TextWrapper } from "../../../../../components/Reusable";
import { DeleteBoxIllustration } from "../../../../../assets/svgs/illustrations";
import { LoadingButton } from "../../../../../components/Loader";
import { cancelAllOpenOrders } from "../../../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../../../utils/notification";
import { formatAPIErrors } from "../../../../../utils/general";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrders } from "../../../../../actions/trading";

const CancelAllOrders = ({ orders }) => {
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const showAllPairsOrder = useSelector((state) => state.trading.showAllPairsOrder);
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const dispatch = useDispatch();

  const onCancelAll = () => {
    if (!orders || (orders && orders.length <= 0) || loader) return;
    setLoader(true);
    let orderIds = orders.map((data) => data.Id);
    let data = {
      orderIds
    };

    cancelAllOpenOrders(data)
      .then((res) => {
        getOrders();
        showSuccessMsg(res?.data?.message);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setLoader(false);
        setShowModal(false);
      });
  };

  const getOrders = () => {
    let data = {
      offset: 1
    };

    if (!showAllPairsOrder) {
      data.pair = selectedPair.symbol;
    }

    let body = {
      type: "openOrders",
      data
    };

    dispatch(getUserOrders(body));
  };

  const onClickHandler = () => {
    if (orders && orders.length > 0) {
      setShowModal(true);
    }
  };

  const length = orders.length;

  return (
    <Fragment>
      <DeleteSweepIcon
        pointer
        onClick={onClickHandler}
        data-tip
        data-for="cancelAll"
        data-background-color={isDarkTheme ? "#e66767" : "#e65252"}
      />

      <ReactTooltip id="cancelAll" type="light" place="top" className="r-tool-tip">
        Cancel All
      </ReactTooltip>

      {showModal && (
        <ModalWrapper>
          <DivElement ModalContainer>
            <DivElement displayBtw>
              <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
                Confirm Order Cancellation
              </TextWrapper>
              <CloseIcon onClick={() => setShowModal(false)} />
            </DivElement>
            <DivElement displayCenter marginTop="32px" marginBottom="24px">
              <DeleteBoxIllustration />
            </DivElement>
            <TextWrapper textAlign="center" fontSize="16px" fontSizeSm="14px" lineHeight="24px">
              Cancel the last {length} orders?
            </TextWrapper>
            <DivElement flexJustifyBtwAlignCenter marginTop="32px">
              <Button
                fontSize="14px"
                fontSizeSm="12px"
                lineHeight="20px"
                width="calc(50% - 12px)"
                height="48px"
                padding="0"
                secondaryBlue
                onClick={() => setShowModal(false)}
              >
                Keep all orders
              </Button>
              <Button
                fontSize="14px"
                fontSizeSm="12px"
                lineHeight="20px"
                width="calc(50% - 12px)"
                height="48px"
                padding="0"
                primaryRed
                onClick={onCancelAll}
                disabled={loader}
              >
                {loader ? <LoadingButton color="#fff" /> : "Yes, cancel"}
              </Button>
            </DivElement>
          </DivElement>
        </ModalWrapper>
      )}
    </Fragment>
  );
};

export default CancelAllOrders;
