import { css } from "styled-components";

const Level0Card = css`
  background: ${({ theme }) => theme.Level0Card} !important;
`;

const level1Card = css`
  background-color: ${({ theme }) => theme.level1Card} !important;
`;

export { 
  Level0Card,
  level1Card
};