import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeesList, updateFeeList } from "../../../../lib/api";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { DivElement, SpanElement, TextWrapper, InputSwitch } from "../../../../components/Reusable";
import { formatAPIErrors } from "../../../../utils/general";
import { Skeleton } from "../../../../components/Loader";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { updateFeeStatus } from "../../../../Modules/Trades/action";

const PayFeeInLCX = () => {
  const [checked, setChecked] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [feeData, setFeeData] = useState("");
  const { width } = useWindowSize();
  const isTablet = width > 767 && width <= 1024 ? true : false;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  useEffect(() => {
    getUserTradeFee();
  }, []);

  useEffect(() => {
    if (!feeData) return;

    setChecked(feeData.feeInLCX);
  }, [feeData]);

  const getUserTradeFee = () => {
    setIsLoader(true);
    getFeesList()
      .then((res) => {
        setFeeData(res?.data?.data);

        dispatch(updateFeeStatus(res?.data?.data.feeInLCX));
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setIsLoader(false));
  };

  const updateTradeFee = (value) => {
    let data = {
      FeeInLCX: String(value)
    };
    updateFeeList(data)
      .then((res) => {
        showSuccessMsg(res?.data?.message);

        dispatch(updateFeeStatus(res?.data?.data?.feeInLCX));
        setFeeData(res?.data?.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
  };

  const onChangeHandler = () => {
    let flag = !checked;
    setChecked(flag);
    updateTradeFee(flag);
  };

  return (
    <Fragment>
      {isLoggedIn && (
        <Fragment>
          {!isLoader && feeData ? (
            <Fragment>
              {!isTablet ? (
                <DivElement flexJustifyBtw alignItems="flex-end" paddingTop="12px">
                  <DivElement>
                    <TextWrapper secondary fontSize="12px" fontWeight="400" lineHeight="18px">
                      <var>{`Fee: Maker: ${
                        feeData.feeInLCX
                          ? feeData?.current?.MakerFeesInLCX || 0
                          : feeData?.current?.MakerFees || 0
                      }% | Taker: ${
                        feeData.feeInLCX
                          ? feeData?.current?.TakerFeesInLCX || 0
                          : feeData?.current?.TakerFees || 0
                      }%`}</var>
                    </TextWrapper>
                    <TextWrapper secondary fontSize="12px" fontWeight="400" lineHeight="18px">
                      Pay fees in LCX{" "}
                      <SpanElement fontSize="inherit" fontWeight="700">
                        (50% discount)
                      </SpanElement>{" "}
                    </TextWrapper>
                  </DivElement>
                  <InputSwitch checked={checked} setChecked={onChangeHandler} />
                </DivElement>
              ) : (
                <DivElement flexAlignCenter>
                  <TextWrapper secondary fontSize="12px" lineHeight="18px">
                    <var>{`Fee: Maker: ${
                      feeData.feeInLCX
                        ? feeData?.current?.MakerFeesInLCX || 0
                        : feeData?.current?.MakerFees || 0
                    }% | Taker: ${
                      feeData.feeInLCX
                        ? feeData?.current?.TakerFeesInLCX || 0
                        : feeData?.current?.TakerFees || 0
                    }%`}</var>
                  </TextWrapper>
                  <DivElement margin="0 12px">
                    <InputSwitch checked={checked} setChecked={onChangeHandler} />
                  </DivElement>
                  <TextWrapper secondary fontSize="12px" fontWeight="400" lineHeight="18px">
                    Pay fees in LCX (50% discount)
                  </TextWrapper>
                </DivElement>
              )}
            </Fragment>
          ) : (
            <DivElement paddingTop="12px">
              <Skeleton width="60%" height="18px" />
              <Skeleton width="60%" height="18px" />
            </DivElement>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PayFeeInLCX;
