/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { useSelector } from "react-redux";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";

const calculateDuration = (eventTime) =>
  moment.duration(Math.max(eventTime * 1000 - Math.floor(Date.now()), 0), "milliseconds");

function CountTimerInWords({ eventTime, type, gap }) {
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef(0);
  const isTheme = useSelector((state) => state.theme.isTheme);
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime, timerCallback]);

  const StyleofBumber = {
    height: "24px",
    width: "32px",
    background: isTheme ? "#1F2C38" : "#F5F6FA",
    borderRadius: "5px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    marginLeft: "4px",
    justifyContent: "center",
    color: isTheme ? "#A7A9AB" : "#606F80"
  };

  const TimeContainerStyle = {
    background: isTheme ? "#1F2C38" : "#F5F6FA",
    color: isTheme ? "#A7A9AB" : "#606F80",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "48px",
    height: "48px"
  };

  // return (
  if (type === "Cardcounter") {
    return (
      <BannerHeading
        display="flex"
        size="12px"
        alignItems="center"
        weight="400"
        gap={gap ? gap : "3px"}
        color={isTheme ? "#A7A9AB" : "#606F80"}
      >
        {Math.floor(duration.asDays()) !== 0 && `${Math.floor(duration.asDays())}days`}
        {/* {Math.floor(duration.asDays()) === 0 &&
          "0days"
        } */}
        {
          Math.floor(duration.asDays()) !== 0 && (
            // <BannerHeading size="12px" ml="3px" BorderRadius="5px">
            <>
              <BannerHeadingSpan style={StyleofBumber}>
                {Math.floor(duration.hours())}h
              </BannerHeadingSpan>
              :
              <BannerHeadingSpan style={StyleofBumber}>
                {Math.floor(duration.asDays()) !== 0 && Math.floor(duration.minutes())}m
              </BannerHeadingSpan>
            </>
          )
          // </BannerHeading>
        }
        {
          Math.floor(duration.asDays()) === 0 && (
            // <BannerHeading size="12px" ml="3px">
            <>
              <BannerHeadingSpan style={StyleofBumber}>
                {Math.floor(duration.hours())}h
              </BannerHeadingSpan>
              :
              <BannerHeadingSpan style={StyleofBumber}>
                {Math.floor(duration.minutes())}m
              </BannerHeadingSpan>
              <BannerHeadingSpan style={StyleofBumber}>
                {Math.floor(duration.seconds())}s
              </BannerHeadingSpan>
            </>
          )
          // </BannerHeading>
        }

        {/* {Math.floor(duration.asDays()) === 0 && Math.floor(duration.hours()) === 0 &&
          Math.floor(duration.minutes()) !== 0 &&
          `${Math.floor(duration.minutes())}m`
          // ${Math.floor(duration.seconds())}
        } */}

        {/* {Math.floor(duration.asDays()) === 0 && Math.floor(duration.hours()) === 0 &&
          Math.floor(duration.minutes()) === 0 && Math.floor(duration.seconds()) !== 0
          `${Math.floor(duration.seconds())}s`
        } */}
      </BannerHeading>
    );
  } else {
    return (
      <BannerBox
        width="44%"
        widthLg="36%"
        widthMd="68%"
        mt="10px"
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <BannerBox style={TimeContainerStyle}>
          <BannerHeading size="15px" weight="400">
            {Math.floor(duration.asDays()) !== 0 || Math.floor(duration.asDays()) !== 0
              ? `${Math.floor(duration.asDays())}`
              : 0}
          </BannerHeading>
          <BannerHeading size="12px" textTransform="uppercase" weight="400">
            Days
          </BannerHeading>
        </BannerBox>
        :
        <BannerBox style={TimeContainerStyle}>
          <BannerHeading size="16px" weight="400">
            {Math.floor(duration.hours()) !== 0 || Math.floor(duration.hours()) !== 0
              ? `${Math.floor(duration.hours())}`
              : 0}
          </BannerHeading>
          <BannerHeading size="12px" textTransform="uppercase" weight="400">
            Hours
          </BannerHeading>
        </BannerBox>
        :
        <BannerBox style={TimeContainerStyle}>
          <BannerHeading size="16px" weight="400">
            {Math.floor(duration.minutes()) !== 0 || Math.floor(duration.minutes()) !== 0
              ? `${Math.floor(duration.minutes())}`
              : 0}
          </BannerHeading>
          <BannerHeading size="12px" textTransform="uppercase" weight="400">
            Min
          </BannerHeading>
        </BannerBox>
        :
        <BannerBox style={TimeContainerStyle}>
          <BannerHeading size="16px" weight="400">
            {Math.floor(duration.seconds()) !== 0 || Math.floor(duration.seconds()) !== 0
              ? `${Math.floor(duration.seconds())}`
              : 0}
          </BannerHeading>
          <BannerHeading size="12px" textTransform="uppercase" weight="400">
            Sec
          </BannerHeading>
        </BannerBox>
      </BannerBox>
    );
  }
}

export default CountTimerInWords;
