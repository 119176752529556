import { Checkbox, DivElement, TextWrapper } from "../../../../../components/Reusable";
import { useDispatch, useSelector } from "react-redux";
import { updateShowAllPairsOrder } from "../../../../../actions/trading";

const AllPairFilter = () => {
  const showAllPairsOrder = useSelector((state) => state.trading.showAllPairsOrder);
  const dispatch = useDispatch();

  const setChecked = (flag) => {
    dispatch(updateShowAllPairsOrder(flag));
  };

  return (
    <DivElement flexAlignCenter>
      <Checkbox isSmall={true} checked={showAllPairsOrder} setChecked={setChecked} />{" "}
      <TextWrapper nowrap secondary fontSize="12px" lineHeight="18px">
        Show all trading pairs
      </TextWrapper>
    </DivElement>
  );
};

export default AllPairFilter;
