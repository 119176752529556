import { useState } from "react";
import { DivElement, ElementBox } from "../../../../../components/Reusable";
import InvestmentHistoryTable from "../../Components/InvestmentHistoryTable";
import AboutTab from "./AboutTab";
import { compose } from "redux";
import { connect } from "react-redux";

const BondDetails = ({ bondDetails, bondSummary, isLoggedIn }) => {
  const [activeTab, setActiveTab] = useState("About");
  return (
    <ElementBox padding="0" border minHeight="400px" EarnPageHeader>
      <div className="bonddetail__header">
        <div
          className={`bonddetail__navlink ${activeTab === "About" ? "active" : ""}`}
          onClick={() => setActiveTab("About")}
        >
          About
        </div>
        {isLoggedIn && (
          <div
            className={`bonddetail__navlink ${activeTab === "History" ? "active" : ""}`}
            onClick={() => setActiveTab("History")}
          >
            Investment History
          </div>
        )}
      </div>
      {activeTab === "About" && <AboutTab bondDetails={bondDetails} bondSummary={bondSummary} />}
      {activeTab === "History" && isLoggedIn && (
        <DivElement padding="40px 32px">
          <InvestmentHistoryTable bondDetails={bondDetails} />
        </DivElement>
      )}
    </ElementBox>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default compose(connect(mapStateToProps))(BondDetails);
