import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import AllStyle from "../../../assets/scss/pages/all.module.scss";
import { DepositContext } from "../../../contexts/WalletPage/DepositPage/depositContext";
import { useSelector } from "react-redux";

const WalletNavbar = () => {
  const location = useLocation();
  const { setSelectedCoin, setIsCardSelected } = useContext(DepositContext);
  const userData = useSelector(state => state.dashboard.userData);

  return (
    <BannerBox
      bottomBorder
      CardWhite
      height={"53px"}
      radius={"10px 10px 0 0 "}
      display="flex"
      alignItems="center"
      className={`${AllStyle.sub_navbar_link}`}
    >
      <NavLink
        onClick={() => setSelectedCoin("")}
        to={`${userData?.preferredLanguage ? `/${userData?.preferredLanguage}`: ""}/wallet/balances`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Balances
      </NavLink>

      <NavLink
        onClick={() => {
          setSelectedCoin("");
          setIsCardSelected(false);
        }}
        to={`${userData?.preferredLanguage ? `/${userData?.preferredLanguage}`: ""}/wallet/deposits`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Deposit
      </NavLink>

      <NavLink
        onClick={() => setSelectedCoin("")}
        to={`${userData?.preferredLanguage ? `/${userData?.preferredLanguage}`: ""}/wallet/withdrawals`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Withdrawal
      </NavLink>

      <NavLink
        onClick={() => setSelectedCoin("")}
        to={`${userData?.preferredLanguage ? `/${userData?.preferredLanguage}`: ""}/wallet/account_log`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Deposit & Withdrawal History
      </NavLink>

      {/* <NavLink
        onClick={() => setSelectedCoin("")}
        to="/wallet/reports"
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Reports
      </NavLink> */}

      <NavLink
        onClick={() => setSelectedCoin("")}
        to={`${userData?.preferredLanguage ? `/${userData?.preferredLanguage}`: ""}/wallet/bank_accounts`}
        className={(isActive) => {
          let trueRoute =
            location.pathname.includes("add_accounts") ||
              location.pathname.includes("edit_accounts")
              ? true
              : false;
          return isActive || trueRoute ? `${AllStyle.active_link}` : "";
        }}
      >
        Manage Bank Account
      </NavLink>
      <NavLink
        onClick={() => setSelectedCoin("")}
        to={`${userData?.preferredLanguage ? `/${userData?.preferredLanguage}`: ""}/wallet/token_sale_history`}
        className={(isActive) => (isActive ? `${AllStyle.active_link}` : "")}
      >
        Token Sale Transactions
      </NavLink>
    </BannerBox>
  );
};

export default WalletNavbar;
