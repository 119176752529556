/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import makeAnimated from "react-select/animated";

import {
  DropdownInput,
  MultipleDropdownWithSpecifyOtherInput,
  TextInput
} from "../../../Reusable/FormInput";
import * as Yup from "yup";
import { Persist } from "formik-persist";
import KycStepButton from "./KycStepBtn";
import { formatDropdownOptions } from "../../../../utils";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import {
  CircleCheckIcon,
  CloseIcon,
  NegativeIcon,
  PlusIcon,
  UploadBlueIcon
} from "../../../../assets/svgs";
import { submitAllSourceData, uploadFundProofImage } from "../../../../lib/api";
import { debounce } from "../../../../utils/helpers";
import styled from "styled-components";
import { showErrorMsg, showSuccessMsg } from "../../../../utils/notification";
import { getUserDetails } from "../../../../actions/dashboard";
import { formatAPIErrors } from "../../../../utils/general";
import { Skeleton } from "../../../Loader";
import { Checkbox } from "../../../Reusable";
import ErrorMessageDiv from "../../../Reusable/Field/ErrorMessage";
import { KycSection } from "../../../../assets/StyleComponents/kyc.style";
import InputFieldFormik from "../../../Reusable/Field/index";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SubmittedModal from "../../Kyc/SubmittedModal";

const reg = /^\d+$/;

const kycStep1cValidationSchema = Yup.object().shape({
  SourceOfWealth: Yup.array().of(
    // Yup.string()
    Yup.object().shape({
      sourceWealth: Yup.string().trim().required("Source of fund is required"),
      percentage: Yup.string().required("Required"),
      imageUrl: Yup.string(),
      loading: Yup.boolean()
      // .required('Required')
    })
  )
});

const FileInput = styled.input`
  display: none;
  // &::-webkit-file-upload-button {
  //   visibility: hidden;
  // }
`;

const Step1c = ({
  userData,
  sourceList,
  submissionList,
  increaseStep,
  countriesList,
  isFetchingCountries,
  setIsKycSubmitted
}) => {
  const formikRef = useRef();
  const animatedComponents = makeAnimated();
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  // const [isKycSubmitted, setIsKycSubmitted] = useState(false);

  useEffect(() => {
    formikRef.current?.setTouched({}, false);
    //  eslint-disable-next-line
  }, []);

  const getInitialValues = () => {
    return {
      SourceOfWealth: submissionList?.submittedInfo?.sof || ""
    };
  };

  const handleNegativeValue = debounce((name, dataValue, setFieldValue) => {
    setFieldValue(name, Number(dataValue) > 1 ? Number(dataValue - 1) : 1);
  }, 100);

  const handlePositiveValue = debounce((name, dataValue, setFieldValue, total) => {
    setFieldValue(
      name,
      Number(total) >= 100 ? Number(dataValue) : dataValue === "" ? 1 : Number(dataValue) + 1
    );
  }, 100);

  const SubmitSourceOfFund = (values, { setSubmitting }) => {
    let s1Data = JSON.parse(localStorage.getItem("lastLevelProfileData3")).values;
    if (s1Data.SourceOfWealth && s1Data.SourceOfWealth.length > 0) {
      setLoader(true);
      setDisabled(true);
      let total = 0;
      let sendPrams = [];
      s1Data.SourceOfWealth.forEach((obj) => {
        let data = {
          option: obj.label === "Other" ? obj.value : obj.label,
          percent: obj.percentage
        };
        sendPrams.push(data);
        total += Number(obj["percentage"]);
      });

      let checkImageStatus = s1Data.SourceOfWealth.filter((data) => data.imgUrl === "");
      let checkOtherField = values.SourceOfWealth.find((data) => data.label === "Other");

      if (checkImageStatus && checkImageStatus.length > 0) {
        setSubmitting(false);
        setLoader(false);
        setDisabled(false);
        return showErrorMsg(
          "Missing file upload for one or more items. Please upload the required files."
        );
      }
      if (total < 100) {
        setLoader(false);
        setDisabled(false);
        setSubmitting(false);
        setErrorStatus(true);
        return showErrorMsg(
          "The total percentage of all items must equal 100%. Please adjust your entries."
        );
      } else if (total > 100) {
        setLoader(false);
        setDisabled(false);
        setSubmitting(false);
        setErrorStatus(true);
        return showErrorMsg(
          "Total percentage already at 100%. Reduce another item’s percentage to increase this one."
        );
      }

      if (checkOtherField) {
        const otherValue = checkOtherField.value.split(" - ");
        if (!checkOtherField.imgUrl.name) {
          setSubmitting(false);
          setLoader(false);
          setDisabled(false);
          return showErrorMsg(
            "Missing file upload for one or more items. Please upload the required files."
          );
        }
        if (otherValue.length <= 1) {
          setSubmitting(false);
          setLoader(false);
          setDisabled(false);
          // return otherValue[0] === "Other" ? "" : otherValue[0];
        } else {
          if (otherValue[1] === "") {
            setSubmitting(false);
            setLoader(false);
            setDisabled(false);
            return showErrorMsg("Missing other field. Please fill the. required text.");
          }
        }
      }

      let param = {
        data: sendPrams,
        sourceType: "sow"
      };

      submitAllSourceData(param)
        .then((res) => {
          setErrorStatus(false);
          setIsKycSubmitted(true);
          setSubmitting(false);
          dispatch(getUserDetails());
          showSuccessMsg(res.data.message);
          localStorage.removeItem("kycStepLastLevel");
          localStorage.removeItem("lastLevelProfileData1");
          localStorage.removeItem("lastLevelProfileData2");
          localStorage.removeItem("lastLevelProfileData3");
          // history.push("/dashboard");
        })
        .catch((err) => {
          showErrorMsg(formatAPIErrors(err));
        })
        .finally(() => {
          setLoader(false);
          setSubmitting(false);
          setDisabled(false);
        });
    }
  };

  const handleJobImage = (event, setFieldValue, givenName, currentLabel, loadingName) => {
    setFieldValue(`${loadingName}`, true);
    setIsUploadingImage(true);
    let isOtherText = "";
    if (currentLabel.label === "Other") {
      const otherValue = currentLabel.value.split(" - ");
      if (otherValue.length <= 1) {
        isOtherText = otherValue[0] === "Other" ? "" : otherValue[0];
      } else {
        isOtherText = otherValue[1];
      }
      if (isOtherText === "") {
        setFieldValue(`${loadingName}`, false);
        return showErrorMsg("Please fill the other field");
      }
    }

    const file = event.target.files[0];
    const max_size_mb = 20;
    let currentSizeByte = file.size;
    let checkValidation = currentSizeByte / 1024 ** 2;

    if (checkValidation <= max_size_mb) {
      let formdata = new FormData();
      formdata.append("sourceType", "sow");
      formdata.append(
        "option",
        currentLabel.label === "Other"
          ? isOtherText === ""
            ? currentLabel.label
            : `Other - ${isOtherText}`
          : currentLabel.label
      );
      formdata.append("documentType", "TRANSACTION_RISK");
      formdata.append("document", file);

      uploadFundProofImage(formdata)
        .then((res) => {
          setFieldValue(givenName, file);
          setFieldValue(`${loadingName}`, false);
          setIsUploadingImage(false);
        })
        .catch((err) => {
          setIsUploadingImage(false);
          setFieldValue(`${loadingName}`, false);
          showErrorMsg(formatAPIErrors(err));
        });
    } else {
      setFieldValue(`${loadingName}`, false);
      showErrorMsg("File size exceeds the 20MB limit. Please select a file smaller than 20MB.");
    }
  };

  const fileUpload = (props) => {
    const { form, field, options, currentIndex } = props;
    if (form.values.SourceOfWealth && form.values.SourceOfWealth[currentIndex].loading) {
      return (
        <BannerBox width="100%">
          <Skeleton height={"90px"} width="100%" marginBottom="20px" />
        </BannerBox>
      );
    }
    if (field.value && field.value.name) {
      return (
        <>
          <BannerBox border radius="5px" overflow="hidden" width="100%">
            <ParaTag width="max-content" CardGrey pl={"8px"} pr={"8px"} pb={"2px"} size="12px">
              {options[currentIndex].label === "Other"
                ? options[currentIndex].value
                : options[currentIndex].label}
            </ParaTag>
            <BannerBox
              width="100%"
              radius="0 0 5px 5px"
              overflow="hidden"
              height="calc(100% - 20px)"
              display="flex"
              alignItems="center"
            >
              <BannerBox
                height="100%"
                display="flex"
                alignItems="center"
                padding="0 8px"
                CardSuccessLight
              >
                <CircleCheckIcon fillGreen />
              </BannerBox>

              <BannerBox ml="8px" width="100%" display="flex">
                <ParaTag maxWidth="472px" textTrim size="14px">
                  {" "}
                  {field.value.name}{" "}
                </ParaTag>
                <BannerBox
                  ml="8px"
                  className="selectImgCross"
                  onClick={() => form.setFieldValue(`${field.name}`, "")}
                >
                  <CloseIcon />
                </BannerBox>
              </BannerBox>
            </BannerBox>
          </BannerBox>
        </>
      );
    }
    return (
      <>
        <BannerBox BorderDashedSky radius="5px" overflow="hidden" width="100%">
          <ParaTag
            maxWidth="472px"
            textTrim
            width="max-content"
            CardGrey
            pl={"8px"}
            pr={"8px"}
            pb={"2px"}
            size="12px"
          >
            {options[currentIndex].label === "Other"
              ? options[currentIndex].value
              : options[currentIndex].label}
          </ParaTag>
          <BannerBox
            cursorPointer="pointer"
            width="100%"
            height="calc(100% - 10px)"
            display="flex"
            justifyContent="center"
            padding="16px 16px 4px"
          >
            <label
              htmlFor={`fileInput[${currentIndex}]`}
              className="d-flex flex-column align-items-center"
            >
              <BannerHeadingSpan display="flex" justifyContent="center" cursorPointer txtPrimary>
                <UploadBlueIcon />
                Upload proof
              </BannerHeadingSpan>
              <ParaTag txtGrey size="14px">
                {sourceList &&
                  sourceList.wealthLevel3Documents &&
                  `${
                    sourceList.wealthLevel3Documents[`${options[currentIndex || 0]?.label || ""}`]
                  }`}
              </ParaTag>
            </label>
            <FileInput
              id={`fileInput[${currentIndex}]`}
              type="file"
              accept="image/png, image/gif, image/jpeg, application/pdf, application/vnd.ms-excel"
              name={field.name}
              className="fileUploadImage"
              onChange={(event) =>
                handleJobImage(
                  event,
                  form.setFieldValue,
                  field.name,
                  form.values.SourceOfWealth[currentIndex],
                  `SourceOfWealth[${currentIndex}].loading`
                )
              }
            />
            <ErrorMessage
              name={field.name}
              component="div"
              className="fInput__form__error"
              // {...errorProps}
            />
          </BannerBox>
          {/* <span>Upload File</span> */}
        </BannerBox>
      </>
    );
  };

  const updatPercentage = (arrayHelpers, values, setFieldValue, currentIndex) => {
    let filterData = values.SourceOfWealth.filter(
      (val) => val.label !== values.SourceOfWealth[currentIndex].label
    );
    let quotient = Math.floor(100 / filterData.length);
    let remainder = 100 % filterData.length;
    let resultArray = Array(filterData.length).fill(quotient);

    for (let i = 0; i < remainder; i++) {
      resultArray[i]++;
    }

    arrayHelpers.remove(currentIndex);

    // Set the Value Again
    filterData.map((item, index) => {
      setFieldValue(`SourceOfWealth[${index}].percentage`, resultArray[index]);
    });
  };

  const checkBoxField = (props) => {
    const { form, field, options, currentIndex, arrayHelpers } = props;
    return (
      <Checkbox
        checked={true}
        setChecked={() =>
          updatPercentage(arrayHelpers, form.values, form.setFieldValue, currentIndex)
        }
      />
    );
  };

  const updateFundProofPercentage = (currentValue, values, setFieldValue, index) => {
    let regMatch = currentValue.match(reg);
    if (!regMatch) {
      setErrorStatus(false);
      if (currentValue === "")
        setFieldValue(
          `SourceOfWealth[${index}].percentage`,
          currentValue === "" ? Number("") : Number(currentValue)
        );
      else return;
    } else {
      setErrorStatus(false);
      setFieldValue(
        `SourceOfWealth[${index}].percentage`,
        currentValue === "" ? Number("") : Number(currentValue)
      );
    }
  };

  return (
    <>
      {/* {isKycSubmitted && <SubmittedModal onClose={onClose} type="Verification Plus" />} */}
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        onSubmit={SubmitSourceOfFund}
        validateOnMount={true}
        validationSchema={kycStep1cValidationSchema}
      >
        {({ values, setFieldValue, setValues }) => (
          <FieldArray
            name="SourceOfWealth"
            render={(arrayHelpers) => (
              <>
                <Form>
                  <KycSection KYCS1>
                    <div className="kycS1__form__container">
                      <MultipleDropdownWithSpecifyOtherInput
                        title="Source of wealth"
                        isOtherTitle="Name source of wealth"
                        isPlaceHolderTitle="Enter the source"
                        name="SourceOfWealth"
                        containerClass="w-100"
                        placeholder="Select an option"
                        value={values.SourceOfWealth}
                        setFieldValue={setFieldValue}
                        setValues={setValues}
                        options={sourceList && formatDropdownOptions(sourceList.wealthLevel3)}
                        isMultiStatus={true}
                      />

                      <BannerBox width="100%">
                        {Array.isArray(values.SourceOfWealth) &&
                          values.SourceOfWealth.length > 0 && (
                            <>
                              <BannerBox
                                width="100%"
                                mb={values.SourceOfWealth.length === 0 ? "32px" : "0"}
                              >
                                <ParaTag size="14px" sizeMd="12px" txtGrey mb="4px">
                                  Declare percentage breakdown (total should be 100%)
                                </ParaTag>
                              </BannerBox>
                              {values.SourceOfWealth.map((userValue, index) => {
                                let total = 0;
                                values.SourceOfWealth.forEach((obj) => {
                                  total += Number(obj["percentage"]);
                                });
                                // setFieldValue(`SourceOfWealth[${index}].percentage`, resultArray[index]);
                                return (
                                  <>
                                    <BannerBox
                                      key={userValue.value}
                                      display="flex"
                                      height="48px"
                                      width="100%"
                                      // alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <BannerBox
                                        bottomBorder={
                                          index === values.SourceOfWealth.length - 1 ? null : true
                                        }
                                        display="flex"
                                        padding="12px 0 12px 16px"
                                        width="75%"
                                        widthMd="50%"
                                      >
                                        <Field
                                          type="checkbox"
                                          component={checkBoxField}
                                          name={`${values.SourceOfWealth[index]}`}
                                          checked={true}
                                          arrayHelpers={arrayHelpers}
                                          currentIndex={index}
                                          // onClick={() => updatPercentage(arrayHelpers, values, setFieldValue, index)}
                                        />
                                        <ParaTag
                                          textTrim
                                          size="14px"
                                          sizeMd="12px"
                                          ml="8px"
                                          alignItemsMd="center"
                                          displayMd="flex"
                                        >
                                          {userValue.value}{" "}
                                        </ParaTag>
                                      </BannerBox>

                                      <BannerBox
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        padding="0 8px"
                                        width="25%"
                                        widthMd="50%"
                                      >
                                        <BannerBox
                                          CardGrey
                                          topBorder
                                          bottomBorder
                                          leftBorder
                                          radius={"5px 0 0 5px "}
                                          padding="0 4px"
                                          height="32px"
                                          display="flex"
                                          alignItems="center"
                                          cursorPointer={
                                            Number(values.SourceOfWealth[index].percentage) > 1
                                              ? "pointer"
                                              : "not-allowed"
                                          }
                                          onClick={() => {
                                            if (
                                              Number(values.SourceOfWealth[index].percentage) <= 1
                                            )
                                              return;
                                            handleNegativeValue(
                                              `SourceOfWealth[${index}].percentage`,
                                              Number(values.SourceOfWealth[index].percentage),
                                              setFieldValue
                                            );
                                          }}
                                        >
                                          <NegativeIcon />
                                        </BannerBox>

                                        <BannerBox
                                          topBorder
                                          bottomBorder
                                          display="flex"
                                          alignItems="center"
                                          minWidth="40px"
                                          width="50px"
                                        >
                                          <InputFieldFormik
                                            BodyColor
                                            type="text"
                                            name={`SourceOfWealth[${index}].percentage`}
                                            className="form-control px-0 pl-1 hght-30 f-md-12"
                                            width="100%"
                                            height="30px"
                                            fontSize="12px"
                                            fontWeight="500"
                                            value={`${
                                              values.SourceOfWealth[index]?.percentage || ""
                                            }`}
                                            style={{
                                              background: "transparent",
                                              width: "100%",
                                              border: "none",
                                              textAlign: "center"
                                            }}
                                            onChange={(e) => {
                                              if (Number(e.target.value) <= 100) {
                                                updateFundProofPercentage(
                                                  e.target.value,
                                                  values,
                                                  setFieldValue,
                                                  index
                                                );
                                              }
                                            }}
                                          />
                                          <ErrorMessageDiv
                                            name={`SourceOfWealth[${index}].percentage`}
                                            component="div"
                                            mt="3px"
                                            size="14px"
                                            sizeMd="12px"
                                            weight="300"
                                            txtDanger
                                            // {...errorProps}
                                          />
                                          {(values.SourceOfWealth[index]?.percentage > 0 ||
                                            values.SourceOfWealth[index]?.percentage === "") && (
                                            <BannerHeadingSpan mr="4px" size="14px" sizeMd="12px">
                                              {" "}
                                              %{" "}
                                            </BannerHeadingSpan>
                                          )}
                                        </BannerBox>

                                        <BannerBox
                                          CardGrey
                                          topBorder
                                          bottomBorder
                                          rightBorder
                                          radius={"0 5px 5px 0"}
                                          padding="0 4px"
                                          display="flex"
                                          alignItems="center"
                                          height="32px"
                                          cursorPointer={
                                            Number(total) >= 100 ||
                                            Number(values.SourceOfWealth[index].percentage) >= 100
                                              ? "not-allowed"
                                              : "pointer"
                                          }
                                          onClick={() => {
                                            handlePositiveValue(
                                              `SourceOfWealth[${index}].percentage`,
                                              values.SourceOfWealth[index].percentage,
                                              setFieldValue,
                                              total
                                            );
                                          }}
                                        >
                                          <PlusIcon />
                                        </BannerBox>
                                      </BannerBox>
                                    </BannerBox>
                                  </>
                                );
                              })}

                              {errorStatus && (
                                <BannerBox position="absolute">
                                  <ParaTag txtDanger size="14px" sizeMd="12px">
                                    {" "}
                                    Total percentage should be 100%{" "}
                                  </ParaTag>
                                </BannerBox>
                              )}

                              <BannerBox mt="24px" mb="10px">
                                <ParaTag txtGrey size="14px" sizeMd="12px">
                                  {" "}
                                  Attach {values.SourceOfWealth.length > 1
                                    ? "proofs"
                                    : "proof"}{" "}
                                  (IMAGE, PDF file upto 20 mb){" "}
                                </ParaTag>
                              </BannerBox>

                              {values.SourceOfWealth.map((userValue, index) => {
                                return (
                                  <>
                                    <BannerBox
                                      display="flex"
                                      width="100%"
                                      mb="32px"
                                      CardSky
                                      radius="5px"
                                      overflow="hidden"
                                      minHeight={
                                        values.SourceOfWealth[index].imgUrl &&
                                        values.SourceOfWealth[index].imgUrl.name
                                          ? "70px"
                                          : "90px"
                                      }
                                    >
                                      <Field
                                        type="file"
                                        component={fileUpload}
                                        options={values.SourceOfWealth}
                                        currentIndex={index}
                                        name={`SourceOfWealth[${index}].imgUrl`}
                                        className="form-control"
                                      />
                                    </BannerBox>
                                  </>
                                );
                              })}
                            </>
                          )}
                      </BannerBox>

                      <Persist name="lastLevelProfileData3" />
                    </div>

                    <KycStepButton loader={loader} label="Submit" disabled={disabled} />
                  </KycSection>
                </Form>
              </>
            )}
          />
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.getUserData,
  countriesList: state.kyc.countriesList,
  isFetchingCountries: state.kyc.isFetchingCountries
});

export default compose(connect(mapStateToProps))(withRouter(Step1c));
