/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";

import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import CustimizeMultiSelectDropDown from "../../../components/Reusable/ReactMultiSelectDropdown/CustimizeMultiSelectDropDown";
import InputWrapper from "../../../components/Reusable/InputWrapper/InputWrapper";
import { EditCalendarIcon } from "../../../assets/svgs";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import { useDispatch, useSelector } from "react-redux";
import { ReactMultiSelectDropdown } from "../../../components/Reusable";
import SingleDropDownValue from "../../../components/Reusable/SingleDropDown/SingleDropDown";
import { resetFilterOrders } from "../../../actions/trade";
import { getMarketLists } from "../../../actions/marketLists";
import { formattedSymbol } from "../../../utils/general";
import { DatePickerStyles } from "../../../assets/StyleComponents/DatePicker.style";
import DatePickerComponent from "../../../components/DatePicker/DatePicker";

var yesterday = Datetime.moment();
var valid = function (current) {
  return current.isBefore(yesterday);
};

const formatedArray = (givenArray) => {
  if (givenArray && givenArray.length > 0) {
    return givenArray.map((item) => {
      return {
        id: item,
        label: item.symbol,
        value: item.symbol
      };
    });
  }
};

let SideList = [
  { id: 1, label: "Buy", value: "Buy" },
  { id: 2, label: "Sell", value: "Sell" }
];

const FilterOrders = ({ onApplyFilterHanlder, offset }) => {
  const [date, setDate] = useState({ fromDate: null, toDate: null });
  const [dateTimeStamp, setDateTimeStamp] = useState({ fromDate: null, toDate: null });
  const [selectedSide, setSelectedSide] = useState("");
  const [selectedCoins, setSelectedCoins] = useState("");
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const pairsConfig = useSelector((state) => state.trade.pairsConfig);
  const isMarketLists = useSelector((state) => state.market.isMarketLists);

  useEffect(() => {
    dispatch(getMarketLists());
  }, []);

  useEffect(() => {
    onApplyFilter();
  }, [selectedCoins, selectedSide, offset, date]);

  // const validFromDate = (current) => {
  //   if (date.toDate) {
  //     return (
  //       valid(current) &&
  //       (moment(current).isBefore(moment(date.toDate)) ||
  //         moment(current).isSame(moment(date.toDate)))
  //     );
  //   }
  //   return valid(current);
  // };

  // const validToDate = (current) => {
  //   if (date.fromDate) {
  //     return (
  //       valid(current) &&
  //       (moment(current).isAfter(moment(date.fromDate)) ||
  //         moment(current).isSame(moment(date.fromDate)))
  //     );
  //   }
  //   return valid(current);
  // };

  // const selectFromDate = (fromDate) => {
  //   setDate({ ...date, fromDate: moment(fromDate) });
  //   setDateTimeStamp({ ...dateTimeStamp, fromDate: moment(fromDate).unix() });
  // };

  // const selectToDate = (toDate) => {
  //   setDate({ ...date, toDate: moment(toDate) });
  //   setDateTimeStamp({ ...dateTimeStamp, toDate: moment(toDate).endOf("day").unix() });
  // };

  const validToDate = (current) => {
    if (dateTimeStamp.fromDate) {
      const max = moment(dateTimeStamp.fromDate * 1000).add(89, "days");
      return (
        valid(current) &&
        (moment(current).isAfter(moment(dateTimeStamp.fromDate) * 1000) ||
          moment(current).isSame(moment(dateTimeStamp.fromDate) * 1000)) &&
        moment(current).isBefore(max)
      );
    }
    return valid(current);
  };

  const validFromDate = (current) => {
    if (dateTimeStamp.toDate) {
      const max = moment(dateTimeStamp.toDate * 1000).subtract(89, "days");
      return (
        valid(current) &&
        (moment(current).isBefore(moment(dateTimeStamp.toDate) * 1000) ||
          moment(current).isSame(moment(dateTimeStamp.toDate) * 1000)) &&
        moment(current).isAfter(max)
      );
    }
    return valid(current);
  };

  const clearAll = () => {
    setDate({ fromDate: "", toDate: "" });
    setSelectedSide("");
    setSelectedCoins("");
    dispatch(resetFilterOrders());
  };

  const onApplyFilter = () => {
    let filter = {
      offset: 1
    };

    if (selectedSide) filter.side = selectedSide.value.toUpperCase();
    if (selectedCoins && selectedCoins.length > 0) {
      let filterArray = [];
      selectedCoins.forEach((item) => filterArray.push(item.value));
      filter.pairs = filterArray;
    }
    // if (date.fromDate && date.toDate) {
    //   filter.fromDate = moment(date.fromDate).unix();
    //   filter.toDate = moment(date.toDate).unix();
    // }

    if (date.fromDate && date.toDate) {
      if (moment(date.fromDate).unix() === moment(date.toDate).unix()) {
        filter.fromDate = moment(date.fromDate).unix();
        let toDateSetTime = moment(date.toDate).set({ hour: 23, minute: 59 });
        filter.toDate = toDateSetTime.unix();
      } else {
        filter.fromDate = moment(date.fromDate).unix();
        // filter.toDate = moment(date.toDate).endOf("day").format();
        filter.toDate = moment(date.toDate).unix();
      }
    }

    if (Object.keys(filter).length > 0) {
      onApplyFilterHanlder(filter);
    }
  };

  let optionList =
    (isMarketLists && isMarketLists.length > 0 && formattedSymbol(isMarketLists)) || [];

  return (
    <BannerBox width="100%" mb="32px" flexWrap="wrap" ml="8px" prLg="8px">
      <BannerBox display="flex" width="75%" widthLg="100%" justifyContent="space-between">
        <ParaTag size="14px" txtGrey>
          Filters
        </ParaTag>
        {((selectedCoins && selectedCoins.length > 0) ||
          date.fromDate ||
          date.toDate ||
          selectedSide) && (
          <ParaTag size="14px" txtDanger cursor="pointer" onClick={clearAll}>
            Clear All
          </ParaTag>
        )}
      </BannerBox>

      <BannerBox
        display="flex"
        width="75%"
        widthLg="100%"
        justifyContent="space-between"
        flexWrap="wrap"
        gap="16px"
      >
        <BannerBox width="180px" widthLg="45%" mt="6px">
          <CustimizeMultiSelectDropDown
            noImgStatus={false}
            setSelectedOptions={setSelectedCoins}
            options={optionList}
            placeholder="Select Pair"
            value={selectedCoins}
          />
        </BannerBox>

        {width < 1025 && (
          <BannerBox width="180px" widthLg="45%" mt="6px">
            <SingleDropDownValue
              menuCloseOnSelect="true"
              setSelectedOptions={setSelectedSide}
              options={SideList}
              placeholder={"Side"}
              value={selectedSide}
            />
          </BannerBox>
        )}

        <BannerBox
          CardLevel3OnlyBackground
          InputBottomBorder
          borderWidth="2px"
          radius="5px 5px 0 0"
          mt={"6px"}
          mtMd={"6px"}
          mx="0"
          height="44px"
          maxWidth="180px"
          width="180px"
          widthLg="45%"
          top="6px"
          topMd="0px"
          position="relative"
        >
          <DatePickerComponent
            quickFilterStatus="true"
            givenDate={date}
            selectedDate={date.fromDate}
            setQuickFilterValue={({ fromDate, toDate }) => {
              setDate({ fromDate, toDate });
            }}
            name="From"
          />
          {/* <DatePickerStyles
            name="From"
            inputProps={{
              placeholder: "From",
              readOnly: true,
              className: "inputMain"
            }}
            renderInput={(props) => {
              return (
                <InputWrapper
                  {...props}
                  // InputBottomBorder
                  value={date.fromDate && moment(date.fromDate).format("DD-MM-YYYY")}
                />
              );
            }}
            closeOnSelect={true}
            dateFormat="DD-MM-YYYY"
            timeFormat={false}
            isValidDate={validFromDate}
            onChange={(fromDate) => {
              if (typeof fromDate !== "object") return;
              selectFromDate(fromDate);
              // setDate({ ...date, fromDate: fromDate });
            }}
            value={date.fromDate}
          /> */}
          <BannerBox
            position="absolute"
            top="0"
            right="16px"
            zIndex="1"
            height="100%"
            display="flex"
            alignItems="center"
          >
            <EditCalendarIcon />
          </BannerBox>
        </BannerBox>

        <BannerBox
          CardLevel3OnlyBackground
          radius="5px 5px 0 0"
          mt={"6px"}
          mtMd={"6px"}
          height="44px"
          width="180px"
          widthLg="45%"
          position="relative"
        >
          <DatePickerComponent
            quickFilterStatus="true"
            givenDate={date}
            selectedDate={date.toDate}
            setQuickFilterValue={({ fromDate, toDate }) => setDate({ fromDate, toDate })}
            name="To"
          />
          {/* <DatePickerStyles
            name="To"
            inputProps={{
              placeholder: "To",
              readOnly: true,
              className: "inputMain"
            }}
            renderInput={(props) => {
              return (
                <InputWrapper
                  {...props}
                  // InputBottomBorder
                  value={date.toDate && moment(date.toDate).format("DD-MM-YYYY")}
                />
              );
            }}
            closeOnSelect={true}
            dateFormat="DD-MM-YYYY"
            timeFormat={false}
            isValidDate={validToDate}
            onChange={(toDate) => {
              if (typeof toDate !== "object") return;
              selectToDate(toDate);
              // setDate({ ...date, toDate: toDate });
            }}
            value={date.toDate}
          /> */}
          <BannerBox
            position="absolute"
            top="0"
            right="16px"
            zIndex="1"
            height="100%"
            display="flex"
            alignItems="center"
          >
            <EditCalendarIcon />
          </BannerBox>
        </BannerBox>

        {width > 1024 && (
          <BannerBox width="180px" widthLg="45%" mt="6px">
            <SingleDropDownValue
              menuCloseOnSelect="true"
              setSelectedOptions={setSelectedSide}
              options={SideList}
              placeholder={"Side"}
              value={selectedSide}
            />
          </BannerBox>
        )}
      </BannerBox>
    </BannerBox>
  );
};

export default memo(FilterOrders);
