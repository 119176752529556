import React, { useState } from "react";
import { BannerBox, BannerSection } from "../../../../assets/StyleComponents/Exchange.style";
import ExportTableComponent from "../TableComponent/Exporttable";
import FilterComponent from "./Filters";

function Export(props) {
  const [page, setPage] = useState(1);
  return (
    <BannerSection>
      <BannerBox mt={"35px"} mtLg={"20px"}>
        <FilterComponent type={"export"} page={page} setPage={setPage}  {...props} />
      </BannerBox>
      <BannerBox margin="1.1rem 0px">
        <ExportTableComponent page={page} setPage={setPage}/>
      </BannerBox>
    </BannerSection>
  );
}

export default Export;