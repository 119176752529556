import styled from "styled-components";
import { HeadingStyleCommon } from "./fonts.style";

const HeadingFourth = styled.h4`
  ${HeadingStyleCommon}
  font-size: ${(props) => props.size || "20px"};

  @media (max-width: 767px) {
    font-size: ${(props) => props.sizeMd || "18px"};
  }
`;

export { HeadingFourth };
