import { connect } from "react-redux";
import { Field } from "formik";
import { compose } from "redux";
import styled, { css } from "styled-components";

const InputFieldFormik = styled(Field)`
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  display: ${(props) => (props.display ? props.display : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "")};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")} !important;
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : "")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "")};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  color: ${(props) => (props.color ? props.color : "")};

  &:focus {
    outline: 0px !important;
  }
  &:placeholder {
    color: ${(props) => props.subText} !important;
    font-weight: 300;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 1024px) {
    ${(props) => props.paddingRightLg && `padding-right: ${props.paddingRightLg}`}!important
  }
  @media (max-width: 767px) {
    ${(props) => props.paddingRightMd && `padding-right: ${props.paddingRightMd}`}!important
    font-size: ${(props) => (props.fontSizeMd ? props.fontSizeMd : "")};
  }

  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText};
    `};

  ${(props) =>
    props.primary &&
    css`
      color: ${(props) => (props.isDarkTheme ? "#dedfe0" : "#012243")};
      :hover {
        color: ${(props) => (props.isDarkTheme ? "#dedfe0" : "#012243")};
      }
    `}

  ${(props) =>
    props.alignCenter &&
    css`
      display: flex;
      align-items: center;
    `}
  ${(props) =>
    props.BorderBottomRed &&
    css`
      border-bottom: 1px solid #e65252;
    `}

  ${(props) =>
    props.blueHover &&
    css`
      :hover {
        background-image: linear-gradient(
          76deg,
          rgb(4, 112, 225, 0.08) 2%,
          rgb(64, 163, 199, 0.08)
        );
        border-right: 4px solid #4595e7;
      }
    `}

  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline !important;
    `}
  ${(props) =>
    props.CheckBox &&
    css`
      position: absolute;
      opacity: 0;
      z-index: 1;
      width: 13px;
      height: 13px;
      margin-left: 0 !important;
    `}
  ${(props) =>
    props.FormCheckBox &&
    css`
      position: absolute;
      opacity: 0;
      z-index: 1;
      width: 13px;
      height: 13px;
      margin-left: 0 !important;

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
      }

      // Box.
      & + label:before {
        content: "";
        margin-right: 10px;
        margin-top: 0px;
        display: inline-block;
        vertical-align: text-top;
        width: 13px;
        height: 13px;
        outline: 1px solid #708090;
        background: white;
      }

      // Box hover
      // &:hover + label:before {
      //   background: #0470e1;
      //   outline: none;
      // }

      // Box focus
      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      // Box checked
      &:checked + label:before {
        background: #0470e1;
        outline: none;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: "";
        position: absolute;
        left: 1px;
        top: 8px;
        background: #fff;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
          4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
      }
    `}
  ${(props) =>
    props.AddBenificaryInput &&
    css`
      position: relative;
      padding: ${(props) => (props.padding ? props.padding : "0rem 0.7rem")};
      border: 0px;
      border-bottom: 1px solid ${(props) => props.theme.inputBorder} !important;
      background: ${(props) => props.theme.inputBackground} !important;
      border-radius: 5px 5px 0 0;
      color: ${(props) => props.theme.bodyText} !important;
      width: 100%;
    `}
  ${(props) =>
    props.TextAreaSpecialKYC &&
    css`
      position: relative;
      padding: ${(props) => (props.padding ? props.padding : "0rem 0.7rem")};
      border: 0px;
      border: 1px solid ${(props) => props.theme.inputBorder} !important;
      background: ${(props) => props.theme.inputBackground} !important;
      border-radius: 5px;
      color: ${(props) => props.theme.bodyText} !important;
      width: 100%;
      height: 56px;

      @media (max-width: 767px) {
        height: 46px;
      }
    `}
`;

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(InputFieldFormik);
