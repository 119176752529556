import { useDispatch } from "react-redux";
import { CloseIcon } from "../../../../../../assets/svgs";
import { DivElement, SpanElement, TextWrapper } from "../../../../../../components/Reusable";
import { updateSelectedPair } from "../../../../../../actions/trading";
import { numberFormatter } from "../../../../../../utils";
// import { BannerHeading } from "../../../../../../assets/StyleComponents/FontSize/Heading.style";

const SinglePairTab = ({ data, isActive, lastPrice, onRemovePair }) => {
  const dispatch = useDispatch();
  const [basePair, quotePair] = data.symbol.split("/");

  const onChangePair = () => {
    dispatch(updateSelectedPair(data.symbol));
  };

  return (
    <DivElement
      height="28px"
      padding="0 8px"
      flexJustifyBtwAlignCenter
      bdGreyDisabled={isActive ? null : true}
      bdBlue={isActive ? true : null}
      border="1px solid"
      borderRadius="2px"
      margin="0 4px"
      cursorPointer
      bgInputActive={isActive ? true : null}
    >
      <DivElement flexAlignCenter onClick={() => onChangePair(data)}>
        <TextWrapper
          inputBorder={isActive ? null : true}
          fontSize="12px"
          lineHeight="18px"
          display="flex"
          // minWidth="50px"
        >
          <var>
            {basePair}/
            <SpanElement fontSize="inherit" fontWeight="400">
              {quotePair}
            </SpanElement>
          </var>
        </TextWrapper>
        <TextWrapper green fontSize="12px" fontWeight="400" lineHeight="18px" marginLeft="6px">
          <var>{numberFormatter(lastPrice)}</var>
        </TextWrapper>
      </DivElement>

      <CloseIcon
        width="16px"
        height="16px"
        marginLeft="8px"
        onClick={() => onRemovePair(data.symbol)}
      />
    </DivElement>
  );
};

export default SinglePairTab;
