/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LoadingButton } from "../../components/Loader";
import { RouterLink } from "../../components/Reusable";
import {
  CheckIcon,
  FingerprintGreyIcon,
  FingerprintOrangeIcon,
  FingerprintRedIcon
} from "../../assets/svgs";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../assets/StyleComponents/FontSize/para.style";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function LevelProgressBarComponent(props) {
  const [userData, setUserData] = useState(props.userData ? props.userData : "");
  const isTheme = useSelector((state) => state.theme.isTheme);
  const history = useHistory();
  const prevLang = localStorage.getItem("ljs-lang");

  let { KYC } = userData || {};
  let { LEVEL2 } = KYC || {};
  if (Object.keys(userData).length === 0) {
    return (
      <BannerBox display="flex" justifyContent="center" width="100%">
        <LoadingButton color="#047bf8" />
      </BannerBox>
    );
  }

  const StatusLVL2 = userData?.KYC?.LEVEL2?.status;
  // const KYCLvl2inProcess =
  //   userData.KYC.isHighRiskCountry &&
  //   StatusLVL2 === "PARTIALLY_SUBMITTED" &&
  //   (userData?.KYC?.transactionRiskInfo?.status === "NOT_REQUIRED" ||
  //     userData?.KYC?.transactionRiskInfo?.status === "REQUESTED");

  const WhenVerficationRejectedLVL3 =
    userData.KYC.isHighRiskCountry &&
    // !KYCLvl2inProcess &&
    StatusLVL2 === "REJECTED" &&
    (userData?.KYC?.transactionRiskInfo?.status === "SUBMITTED" ||
      userData?.KYC?.transactionRiskInfo?.status === "RESUBMITTED");

  const KYCLvl3Rejected =
    userData.KYC.isHighRiskCountry &&
    // !KYCLvl2inProcess &&
    StatusLVL2 !== "REJECTED" &&
    (StatusLVL2 === "PARTIALLY_SUBMITTED" || StatusLVL2 === "APPROVED") &&
    (userData?.KYC?.transactionRiskInfo?.status === "REJECTED" ||
      userData?.KYC?.transactionRiskInfo?.status === "PARTIALLY_REJECTED");

  const KYCLvl3INProcess =
    userData.KYC.isHighRiskCountry &&
    // !KYCLvl2inProcess &&
    StatusLVL2 !== "REJECTED" &&
    StatusLVL2 !== "PARTIALLY_SUBMITTED" &&
    (userData?.KYC?.transactionRiskInfo?.status === "SUBMITTED" ||
      userData?.KYC?.transactionRiskInfo?.status === "RESUBMITTED");

  return (
    <BannerBox width="100%" display="flex" paddTop="2rem">
      <BannerBox display="flex" width="100%" justifyContent="space-between">
        <BannerBox
          display="flex"
          direction="column"
          alignItems="center"
          padding="auto 1rem"
          width="230px"
        >
          <BannerBox width="100%" position="relative" mt="10px">
            <ParaTag ExcCircleBar ExcCircleBarRight ExcCircleBarCompleted>
              <CheckIcon className="wth-50" width="50px" margin="0px auto" />
            </ParaTag>
          </BannerBox>

          <BannerBox mt="1rem" width="100%">
            <ParaTag
              textAlign="center"
              BodyColor
              mb="0px"
              weight={
                userData.KYC["LEVEL2"] && userData.KYC["LEVEL2"]["status"] === "APPROVED"
                  ? "bold"
                  : "500"
              }
              size="1rem"
            >
              Register
            </ParaTag>

            <ParaTag weight="300" BodyColor mt="1rem" textAlign="center" size="0.875rem">
              Set up your LCX account
            </ParaTag>
          </BannerBox>
        </BannerBox>

        <BannerBox
          display="flex"
          direction="column"
          alignItems="center"
          padding="auto 1rem"
          width="230px"
        >
          <BannerBox width="100%" position="relative" mt="10px">
            <ParaTag
              ExcCircleBar
              ExcCircleBarActive={
                !userData.KYC["LEVEL2"] ||
                !userData.KYC["LEVEL2"]["status"] ||
                !userData.KYC["LEVEL1"] ||
                userData.KYC["LEVEL1"]["status"] !== "APPROVED" ||
                userData.KYC["LEVEL2"]["status"] === "RESTRICTED" ||
                userData.KYC["LEVEL2"]["status"] === "SUBMITTED" ||
                userData.KYC.LEVEL2.status === "RESUBMITTED" ||
                userData.KYC.LEVEL2.status === "PARTIALLY_SUBMITTED" ||
                KYCLvl3INProcess
                  ? true
                  : false
              }
              ExcCircleBarRejected={
                (userData.KYC["LEVEL2"] && userData.KYC["LEVEL2"]["status"] === "REJECTED") ||
                KYCLvl3Rejected
                  ? true
                  : false
              }
              ExcCircleBarCompleted={
                userData.KYC["LEVEL2"] &&
                userData.KYC["LEVEL2"]["status"] === "APPROVED" &&
                userData?.KYC?.STATUS === "LEVEL2"
                  ? true
                  : false
              }
            >
              {userData.KYC["LEVEL2"] &&
              userData.KYC["LEVEL2"]["status"] === "APPROVED" &&
              userData?.KYC?.STATUS === "LEVEL2" ? (
                <CheckIcon className="img-fluid wth-50" margin="0px auto" />
              ) : (LEVEL2 && LEVEL2?.status === "SUBMITTED") ||
                (userData.KYC.LEVEL2.status === "PARTIALLY_SUBMITTED" &&
                  userData.KYC.isHighRiskCountry &&
                  userData?.KYC?.transactionRiskInfo?.status !== "NOT_REQUIRED" &&
                  userData?.KYC?.transactionRiskInfo?.status !== "REQUESTED") ||
                userData.KYC.LEVEL2.status === "RESUBMITTED" ||
                KYCLvl3INProcess ? (
                <FingerprintOrangeIcon
                  width="59.625px"
                  height="59.625px"
                  className="img-fluid"
                  margin="0px auto"
                />
              ) : (LEVEL2 && LEVEL2?.status === "REJECTED") ||
                KYCLvl3Rejected ||
                WhenVerficationRejectedLVL3 ? (
                <FingerprintRedIcon
                  width="59.625px"
                  height="59.625px"
                  className="img-fluid"
                  margin="0px auto"
                />
              ) : (
                <FingerprintGreyIcon
                  width="59.625px"
                  height="59.625px"
                  className="img-fluid"
                  margin="0px auto"
                />
              )}
            </ParaTag>
          </BannerBox>

          <BannerBox BannerBox mt="1rem" width="100%">
            <ParaTag
              textAlign="center"
              BodyColor={userData.KYC["LEVEL2"] && userData.KYC["LEVEL2"]["status"] === "APPROVED"}
              txtPrimary={userData.KYC["LEVEL2"] && userData.KYC["LEVEL2"]["status"] !== "APPROVED"}
              mb="0px"
              weight="bold"
              size="1rem"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/setting/profile`)}
            >
              {userData.KYC.LEVEL2.status === "SUBMITTED" ||
              (userData.KYC.LEVEL2.status === "PARTIALLY_SUBMITTED" &&
                userData.KYC.isHighRiskCountry &&
                userData?.KYC?.transactionRiskInfo?.status !== "NOT_REQUIRED" &&
                userData?.KYC?.transactionRiskInfo?.status !== "REQUESTED" &&
                userData?.KYC?.transactionRiskInfo?.status !== "REJECTED" &&
                userData?.KYC?.transactionRiskInfo?.status !== "PARTIALLY_REJECTED") ||
              userData.KYC.LEVEL2.status === "RESUBMITTED" ||
              KYCLvl3INProcess ? (
                "Under Process"
              ) : userData.KYC.LEVEL2.status === "REJECTED" || KYCLvl3Rejected ? (
                <RouterLink to="/setting/profile" underline>
                  <BannerHeadingSpan txtPrimary>KYC Rejected</BannerHeadingSpan>
                </RouterLink>
              ) : (
                <Link rel="noreferrer" to={`${prevLang ? `/${prevLang}`: "/en"}/setting/profile`}>
                  <BannerHeadingSpan txtPrimary textDecoration="underline">
                    Get Verified
                  </BannerHeadingSpan>
                </Link>
              )}
            </ParaTag>

            <ParaTag weight="300" BodyColor mt="1rem" textAlign="center" size="0.875rem">
              {userData.KYC.LEVEL2.status === "SUBMITTED" ||
              (userData.KYC.LEVEL2.status === "PARTIALLY_SUBMITTED" &&
                userData.KYC.isHighRiskCountry &&
                userData?.KYC?.transactionRiskInfo?.status !== "NOT_REQUIRED" &&
                userData?.KYC?.transactionRiskInfo?.status !== "REQUESTED") ||
              userData.KYC.LEVEL2.status === "RESUBMITTED" ||
              KYCLvl3INProcess
                ? "We are verifying your documents and will update the status soon"
                : userData.KYC.LEVEL2.status === "REJECTED" || KYCLvl3Rejected
                ? "Find out documents which were not accepted and only rectify those"
                : "Upload Documents and verify your IDs"}
            </ParaTag>
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </BannerBox>
  );
}
