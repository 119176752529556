/**
 * Import Other Modules
 */

import apiConstants from "../constants/apiConstants";

/**
 *
 *  @param {Fetch Currency} action
 */
export const fetchCurrency = () => ({
  type: apiConstants.GET_BASE_COIN_PAIR
});

/**
 *
 *  @param {selected Pair for Exchange Id} action
 */
export const selectedPair = (data) => ({
  type: apiConstants.SELECTED_PAIR,
  payload: data
});

/**
 *
 *  @param {Ask Pair Array} action
 */
export const askPairList = (data) => ({
  type: apiConstants.ASK_PAIR_LIST,
  payload: data
});

/**
 *
 *  @param {Selected Price for Order Place} action
 */
export const selectedPrice = (data) => ({
  type: apiConstants.SET_SELECTED_PRICE,
  payload: data
});

/**
 *
 *  @param {Updatd Pair Data for Order Place} action
 */
export const updatePairData = (data) => ({
  type: apiConstants.SET_PAIR_DATA,
  payload: data
});

/**
 *
 *  @param {Set Trade Setting} action
 */
export const tradeSetting = (data) => ({
  type: apiConstants.TRADE_SETTING,
  payload: data
});

/**
 *
 *  @param {Cancel Trade Setting} action
 */
export const cancelTradeSetting = (data) => ({
  type: apiConstants.CANCEL_TRADE_SETTING,
  payload: data
});

/**
 *  @param {Trades Pair Config} action
 */
export const getTradePairConfig = (data) => ({
  type: apiConstants.GET_TRADE_PAIR_CONFIG,
  payload: data
});

/**
 *  @param {Trades Pairs Config} action
 */
export const getTradePairsConfig = (data) => ({
  type: apiConstants.GET_TRADE_PAIRS_CONFIG,
  payload: data
});

/**
 *
 *  @param {Recent Trades History} action
 */
export const getTradeHistory = (data) => ({
  type: apiConstants.GET_RECENT_TRADE_HISTROY,
  payload: data
});

/**
 *
 *  @param {User Open Orders History} action
 */
export const getOpenOrders = (data) => ({
  type: apiConstants.GET_OPEN_ORDERS,
  payload: data
});

/**
 *
 *  @param {Reset Trade Table} action
 */
export const resetTradeTable = (data) => ({
  type: apiConstants.RESET_TRADE_SETTING,
  payload: data
});

/**
 *  @param {Get User Trades History} action
 */
export const getUserTradeHistory = (data) => ({
  type: apiConstants.GET_TRADE_HISTROY,
  payload: data
});

/**
 *  @param {Get Wallet Balance} action
 */
export const getTradeWalletBalance = (data) => ({
  type: apiConstants.GET_WALLET_BALANCE,
  payload: data
});

/**
 *  @param {Set Order Value} action
 */
export const orderTabRecords = (data) => ({
  type: apiConstants.SET_ORDER_HISTORY,
  payload: data
});

/**
 *  @param {Setup 2fa} action
 */
export const setup2FA = (data) => ({
  type: apiConstants.SETUP_2FA,
  payload: data
});

/**
 *  @param {Get Open Order History} action
 */
export const getOrderHistory = (data) => ({
  type: apiConstants.GET_ORDER_HISTORY,
  payload: data
});

/**
 *  @param {Get Open Order History} action
 */
export const updatePrivateSocketBalance = (data) => ({
  type: apiConstants.UPDATE_SOCKET_BALANCE,
  payload: data
});

/**
 *  @param {Get Open Order History} action
 */
export const updatePrivateSocketOrder = (data) => ({
  type: apiConstants.UPDATE_SOCKET_ORDERS,
  payload: data
});

/**
 *  @param {Get Open Order History} action
 */
export const getTimeZoneCET = () => ({
  type: apiConstants.GET_TIME_ZONE
});

/**
 *  @param {Get & Update Fav Pair List} action
 */
export const favTradePairsList = (data) => ({
  type: apiConstants.FAV_PAIR_LIST,
  payload: data
});

/**
 *  @param { Reset Filter } action
 */
export const resetFilterOrders = () => ({
  type: apiConstants.RESET_FILTER_ORDERS
});


