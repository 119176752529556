/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import {
  fetchTotalRewardsData,
  getPrivateReglarRewardsTasksList,
  getPrivateRewardsTasksList,
  postClaimRewardRequest,
  postUserParticipatedRequest
} from "../../../../actions/RewardsHub/RewardsHub";
import { ArrowRightBlueIcon, CircleCheckIcon } from "../../../../assets/svgs";
import RewardsModal from "../RewardsModal/RewardsModal";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { debounce } from "../../../../utils/helpers";

const ShowSingleButton = ({ records, cardStatus = false, MouseStatus=false, index }) => {
  const [show, setShow] = useState(MouseStatus);
  const [showModal, setModal] = useState(false);
  const { pathname } = useLocation();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const rewardsTab = useSelector((state) => state.rewards.rewardsTab);
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const isTheme = useSelector((state) => state.theme.isTheme);

  useEffect(() => {
    setShow(MouseStatus);
  }, [MouseStatus]);

  const history = useHistory();
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem("ljs-lang");

  const SubCategory = () => {
    if(records.subCategory === "SIGNUP") return "Signup Now";
    if(records.subCategory === "KYC") return "Complete KYC";
    if(records.subCategory === "DEPOSIT") return "Deposit Now";
    if(records.subCategory === "TRADE") return "Trade Now";
    else return "";
  };

  let buttonText = records?.userRewardStatus;
  if (buttonText === "claimed") buttonText = `Claimed`;
  if (buttonText === "expired") buttonText = `Expired`;
  if (buttonText === "new") buttonText = cardStatus ? `Participate` : SubCategory();
  if (buttonText === "unlocked") buttonText = cardStatus ? `Participated` : SubCategory();
  if (buttonText === "readyToClaim") buttonText = cardStatus ? `Claim Now` : "Ready To Claim";
  if (buttonText === "notEligible") buttonText = `Not Eligible`;

  let outlineStatusButton =
    !records?.userRewardStatus ||
    records?.userRewardStatus === "new" ||
    records?.userRewardStatus === "claimed" ||
    records?.userRewardStatus === "readyToClaim" 
      ? true
      : false;
  let outlineGreenStatusButton =
    records?.userRewardStatus === "unlocked" || records?.userRewardStatus === "notEligible" || records?.userRewardStatus === "expired"
      ? true
      : false;

  const { width } = useWindowSize();

  const rewardsApiCalling = debounce(() => {
    if (!isLoggedIn) return history.push(`/${prevLang}/register`, { rewardsHubStatus: true });
    if(records?.userRewardStatus === "unlocked") {
      if(!cardStatus) {
        if(records.subCategory === "KYC") return history.push(`/${prevLang}/setting/profile`);
        if(records.subCategory === "DEPOSIT") return history.push(`/${prevLang}/wallet/deposits`);
        if(records.subCategory === "TRADE") return history.push(`/${prevLang}/trade/${selectedPair.base}-${selectedPair.quote}`);
      }
    }
    if (records?.userRewardStatus === "new") {
      dispatch(
        postUserParticipatedRequest({
          rewardId: records?.rewardId
        }, cardStatus)
      );

      if(!cardStatus) {
        if(records.subCategory === "KYC") return history.push(`/${prevLang}/setting/profile`);
        if(records.subCategory === "DEPOSIT") return history.push(`/${prevLang}/wallet/deposits`);
        if(records.subCategory === "TRADE") return history.push(`/${prevLang}/trade/${selectedPair.base}-${selectedPair.quote}`);
      }
    }

    if (records?.userRewardStatus === "readyToClaim") {
      if(!cardStatus) {
        return history.push(`/${prevLang}/rewards/bonus`);
      }

      if(cardStatus) {
        setModal(true);
        dispatch(
          postClaimRewardRequest({
            rewardId: records?.rewardId
          })
        );
      }
    }

    return false;
  }, 800);

  const onClose = () => {
    let param = {
      type: cardStatus ? pathname.includes("/rewards/bonus") ? rewardsTab.type : "active" : "active",
      offset: rewardsTab.offset,
      pageSize: rewardsTab.pageSize,
      category: cardStatus ? rewardsTab.category : "ONBOARDING"
    };
    if(cardStatus && param.type ) {
      dispatch(getPrivateReglarRewardsTasksList(param));
      dispatch(fetchTotalRewardsData());
    }
    if(!cardStatus && param.type) dispatch(getPrivateRewardsTasksList(param));
    setModal(false);
  };

  return (
    <BannerBox display="flex" justifyContent="center">
      {showModal && <RewardsModal onClose={onClose} />}
      {cardStatus ? (
        <ButtonComponent
          OutlinePrimary={outlineStatusButton ? true : null}
          DisabledOutlineSuccessButton={outlineGreenStatusButton ? true : null}
          disabled={outlineGreenStatusButton ? true : false}
          // width="150px"
          width="100%"
          widthLg="100%"
          widthMd="100%"
          height="48px"
          onClick={rewardsApiCalling}
        >
          {isLoggedIn ? buttonText : "Participate"}
        </ButtonComponent>
      ) : records?.userRewardStatus === "readyToClaim" ? (
        <ButtonComponent
          display="flex"
          alignItems="center"
          gap="8px"
          LightSuccessButton
          size="14px"
          px="8px"
          btnHeight="36px"
          onClick={rewardsApiCalling}
        >
          <CircleCheckIcon width="18px" height="18px" fillGreen={true} />
          {buttonText || ""}
        </ButtonComponent>
      ) : width > 767 ? (
        show ? (
          <ButtonComponent 
            Primary={records?.userRewardStatus !== "notEligible" ? true : false}
            DisabledBTN={((records?.userRewardStatus === "notEligible" || records?.userRewardStatus === "expired") && isTheme !== true) ? true : false}
            DisabledBTNDark={((records?.userRewardStatus === "notEligible" ||  records?.userRewardStatus === "expired") && isTheme === true) ? true : false}
            width="150px" 
            onClick={rewardsApiCalling}
          >
            {buttonText || SubCategory()}
          </ButtonComponent>
        ) : (
          <ButtonComponent NoDesign>
            <ArrowRightBlueIcon width="30px" height="30px" />
          </ButtonComponent>
        )
      ) : (
        <ButtonComponent OutlinePrimary={true} width="100%" onClick={rewardsApiCalling}>
          {buttonText || SubCategory()}
        </ButtonComponent>
      )}
    </BannerBox>
  );
};

export default ShowSingleButton;
