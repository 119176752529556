import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Skeleton } from "../../Loader";
import { SuccessBlueIcon } from "../../../assets/svgs";
import { DivElement, TextWrapper } from "../../Reusable";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";

const Welcome = ({ userData }) => {
  // const [untranslatedText, setUntranslatedText] = useState({ text: "" });
  const { width } = useWindowSize();

  return (
    <Fragment>
      {userData ? (
        <DivElement
          dAlignCenter
          marginBottom="32px"
          flexWrap="wrap"
          marginTopSm="10px"
          marginTop="12px"
        >
          <TextWrapper fontSize="16px" fontSizeSm="14px" fontWeight="300" blue>
            Welcome Back,&nbsp;
          </TextWrapper>
          <DivElement dAlignCenter flexWrap="nowrap" whiteSpace="nowrap">
            <TextWrapper
              fontSize="16px"
              fontSizeSm="14px"
              fontWeight="300"
              className="name_user_localise"
              blue
            >
              <var>
                {userData.accountType === "CORPORATE"
                  ? `${
                      userData?.companyName
                        ? userData?.companyName
                        : width > 767
                        ? userData.email
                        : userData.email.substring(0, 5) +
                          "......" +
                          userData.email.substring(userData.email.length - 3, userData.email.length)
                    }`
                  : `${
                      userData?.firstName
                        ? userData?.firstName
                        : width > 767
                        ? userData.email
                        : userData.email.substring(0, 5) +
                          "......" +
                          userData.email.substring(userData.email.length - 3, userData.email.length)
                    }`}
                !
              </var>
            </TextWrapper>

            {userData.KYC.STATUS === "LEVEL2" && (
              <DivElement
                verifiedTagBg
                dAlignCenter
                minWidth="101px"
                height="34px"
                borderRadius="17px"
                padding="0 8px"
                marginLeft="17px"
                bdGrey
                border="1px solid"
              >
                <SuccessBlueIcon width="18px" height="18px" />
                &nbsp;
                <TextWrapper fontSize="16px">
                  {userData.accountType === "CORPORATE" && "Corporate"} Verified
                </TextWrapper>
              </DivElement>
            )}
          </DivElement>
        </DivElement>
      ) : (
        <Skeleton width="25%" height="32px" />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData
});

export default compose(connect(mapStateToProps))(Welcome);
