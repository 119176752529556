import React, { useEffect, useState } from "react";
import Step1a from "./KycStep1a";
// import Step1b from "./KycStep1b";
// import Step1c from "./KycStep1c";
// import Step1ProgressBar from "../Step1ProgressBar";
// import KycFAQ from "../KycFAQ";
// import { getSourceList } from "../../../../lib/api";
import { formatAPIErrors } from "../../../../utils/general";
import { getCountriesList } from "../../../../actions/kyc";
import { compose } from "redux";
import { connect } from "react-redux";
import { showErrorMsg } from "../../../../utils/notification";
import { KycSection } from "../../../../assets/StyleComponents/kyc.style";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
// import KycStep1d from "./KycStep1d";

// const STEPS = ["", "Personal Information", "Provide Address Information", "Asset Information", "Other Information"];

const KycStep1 = ({ guidlinesModal, getCountries, countriesError }) => {
  const [step, setStep] = useState(+localStorage.getItem("kycStepLastLevel") || 1);
  // const [sourceList, setSourceList] = useState("");

  useEffect(() => {
    let step = localStorage.getItem("kycStepLastLevel");

    if (!step) {
      localStorage.setItem("kycStepLastLevel", 1);
    }

    getCountries();

    // getSourceList()
    //   .then((res) => {
    //     setSourceList(res && res.data.data);
    //   })
    //   .catch((err) => {
    //     showErrorMsg(formatAPIErrors(err));
    //   });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (countriesError) {
      showErrorMsg(formatAPIErrors(countriesError));
    }
    // eslint-disable-next-line
  }, [countriesError]);

  const increaseStep = () => {
    localStorage.setItem("kycStepLastLevel", step + 1);
    setStep(step + 1);
  };

  // const jumpToAnotherState = (value) => {
  //   if (value >= step) {
  //     return;
  //   } else {
  //     localStorage.setItem("kycStepLastLevel", value);
  //     setStep(value);
  //   }
  // };

  return (
    <KycSection
      className="stepWrapper__containerLastLevel element-box"
      StepContainerLVL3
      width="100%"
      maxWidth={"604px"}
      maxWidthMd="100%"
    >
      <BannerBox className="stepWrapper__form" width="100%">
        <KycSection StepContainerLVL3 margin="0px auto" marginMd="0px">
          <Step1a currentStep={step} increaseStep={increaseStep} />
        </KycSection>
      </BannerBox>
    </KycSection>
  );
};

const mapStateToProps = (state) => ({
  countriesError: state.kyc.countriesError
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(getCountriesList())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(KycStep1);
