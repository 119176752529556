import { connect } from "react-redux";
import { compose } from "redux";
import styled, { css } from "styled-components";

const Table = styled.table`
  width: ${(props) => props.width || "100%"};
  font-weight: 500;
  border-spacing: 0px;
  ${(props) => props.borderCollapse && `border-collapse: ${props.borderCollapse}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.border && `border: ${props.border}`};
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.borderCollapse && `border-collapse: ${props.borderCollapse}`};

  /* Thead Radius */
  ${(props) =>
    props.bRadius &&
    css`
      thead tr th {
        &:first-child {
          border-top-left-radius: 5px;
        }
        &:last-child {
          border-top-right-radius: 5px;
        }
      }
    `}

  ${(props) =>
    props.bRadiusTop &&
    css`
      thead tr th {
        &:first-child {
          border-top-left-radius: ${(props) => props.bRadiusTop};
        }
        &:last-child {
          border-top-right-radius: ${(props) => props.bRadiusTop};
        }
      }
    `}

  ${(props) =>
    props.bRadiusBottom &&
    css`
      thead tr th {
        &:first-child {
          border-end-start-radius: ${(props) => props.bRadiusBottom};
        }
        &:last-child {
          border-end-end-radius: ${(props) => props.bRadiusBottom};
        }
      }
    `}
  ${(props) =>
    props.TradeOrderBook &&
    css`
      .exchHoverExpo {
        cursor: pointer;
        background-color: ${(props) => props.theme.tradeHoverValue} !important;
      }

      &.exch-ask-overlay tr.exchHoverExpo,
      &.exch-bid-overlay tr.exchHoverExpo {
        cursor: pointer;
        &:hover {
          td {
            cursor: pointer;
            border-top: 1px dashed #606f80 !important;
          }
        }
      }

      &.exch-bid-overlay tr.exchHoverExpo {
        cursor: pointer;
        &:hover {
          td {
            border-top: none !important;
            border-bottom: 1px dashed #606f80 !important;
          }
        }
      }

      &.exch-ask-overlay tr.exch-border-none.exchHoverExpo {
        &:last-child {
          td {
            cursor: pointer;
            border-top: none !important;
          }
        }
      }

      &.exch-bid-overlay tr.exch-border-none.exchHoverExpo {
        &:last-child {
          td {
            cursor: pointer;
            border-bottom: none !important;
          }
        }
      }

      tr {
        cursor: pointer !important;
        height: 26px;
      }
    `}

    ${(props) =>
    props.headerStickyTop &&
    css`
      th {
        position: sticky;
        top: 0;
        background-color: ${(props) => props.theme.tableHeader};
        z-index: 1;
      }
    `}
`;

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isTheme
});

export default compose(connect(mapStateToProps))(Table);
