import { DivElement } from "../../../../../components/Reusable";
import TradeHistoryTable from "../../Components/TradeHistoryTable";

const TradesTab = () => {
  return (
    <DivElement scrollY width="100%" height="100%">
      <TradeHistoryTable />
    </DivElement>
  );
};

export default TradesTab;
