import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { DivElement } from "../Reusable";
import Skeleton from "./Skeleton";

const TokenSaleLoader = () => {
  return (
    <BannerBox display="grid" mt="40px" mtMd="10px" gap="8px" gridCols="repeat(4,1fr)" gridColsLg="repeat(2,1fr)" gridColsMd="1fr" justifyContent="center" gridTemplateColums gridColsXl="1fr 1fr 1fr">
      {["", "", "", ""].map((data, i) => {
        return (
          <DivElement key={i} bgCard width="269px"
            widthMd="calc(50% - 24px)"
            widthSm="calc(100% - 16px)"
            minWidthMd="310px"
            minWidthSm="328px"
            height="250px"
            heightSm="250px"
            margin="8px"
            marginMd="12px"
            marginSm="8px"
            padding="0 5px">
            <div>
              <DivElement className="d-flex justify-content-end pt-4 px-4" display="flex" justifyContent="flex-end" paddingTop="12px">
                <Skeleton style={{ height: "32px", width: "137px", borderRadius: "80px" }} />
              </DivElement>
              <DivElement position="relative" marginTop="12px">
                <hr
                  className="grey-border-bottom"
                  style={{
                    height: "0"
                  }}
                />
                <DivElement
                  position="absolute"
                  style={{
                    width: "78px",
                    height: "78px",
                    top: "-39px",
                    left: "1.5rem",
                    backgroundColor: "transparent",
                    borderRadius: "20px"
                  }}
                >
                  <Skeleton circle={true} style={{ width: "78px", height: "78px" }} />
                </DivElement>
              </DivElement>
              <DivElement padding="16px">
                <Skeleton style={{ height: "29px", width: "146px", marginTop: "19.4px" }} />
                <Skeleton style={{ height: "18px", width: "87px", marginTop: "4.9px" }} />
                <Skeleton style={{ height: "16px", width: "58px", marginTop: "15.8px" }} />
                <Skeleton style={{ height: "16px", width: "172px", marginTop: "8.7px" }} />
              </DivElement>
            </div>
          </DivElement>
        );
      })}
    </BannerBox>
  );
};

export default TokenSaleLoader;
