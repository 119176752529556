/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";

import { dataURLtoFile, getIdValueFromLabel } from "../../../../utils";
import { getUserDetails } from "../../../../actions/dashboard";
// import { showErrorMsg } from "../../../../utils/notification";
import { BannerBox, BannerHeadingSpan } from "../../../../assets/StyleComponents/Exchange.style";
import { Label } from "../../../../assets/StyleComponents/FontSize/para.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import {
  CameraBlueIcon,
  MobileWhiteIcon,
  NatioanlIdIcon,
  NatioanlIdWhiteIcon,
  PassportIcon,
  PassportWhiteIcon
} from "../../../../assets/svgs";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import UploadIds from "./NewFlows/UploadIds";
import BarcodeSection from "./NewFlows/BarcodeSection";
import { getAcceptedDocument } from "../../../../lib/api";
import { Skeleton } from "../../../../components/Loader";

const KycStep4 = ({ setSteps, steps }) => {
  const userData = useSelector((state) => state.dashboard.userData);
  const [addressProof, setAddressProof] = useState("");
  const [documentType, setDocumentType] = useState("pdf");
  // const [uploadLoader, setUploadLoader] = useState(false);
  const [img, setImg] = useState({});
  const [img2, setImg2] = useState({});
  const [openWebcam, setOpenWebcam] = useState(false);
  const [openWebcam2, setOpenWebcam2] = useState(false);
  const [capturedImg, setCapturedImg] = useState("");
  const [capturedImg2, setCapturedImg2] = useState("");
  const [firstImage, setFirstImage] = useState("");
  const [secondImage, setSecondImage] = useState("");
  const [allOptionsId, setAllOptionsId] = useState([]);
  const [state, setState] = useState("main");
  const [loader, setLoader] = useState(true);
  const [option, setoption] = useState("");
  const { width } = useWindowSize();

  const handleOnChange = (e) => {
    setoption(e.target.value);
  };

  // const onUpload = async (img) => {
  //   let imgUrl = URL.createObjectURL(img.target.files[0]);
  //   if (
  //     img.target.files[0].name.includes("jpg") ||
  //     img.target.files[0].name.includes("png") ||
  //     img.target.files[0].name.includes("jpeg") ||
  //     img.target.files[0].name.includes("pdf")
  //   ) {
  //     setDocumentType(img.target.files[0].name.includes("pdf") ? "pdf" : "image");
  //     setImg(img.target.files[0]);
  //     setAddressProof(imgUrl);
  //   } else {
  //     showErrorMsg("Invalid file format");
  //   }
  // };

  const onCapture = () => {
    let image = dataURLtoFile(capturedImg, "selfie.jpeg");
    setDocumentType("image");
    setImg(image);
    setCapturedImg(capturedImg);
  };
  const onCapture2 = () => {
    let image = dataURLtoFile(capturedImg2, "selfie2.jpeg");
    setDocumentType("image");
    setImg2(image);
    setCapturedImg2(capturedImg2);
  };

  // const onCaptureAgain = () => {
  //   if (uploadLoader) {
  //     return;
  //   }
  //   const inputs = document.querySelectorAll(".kycsteps__uploader__circle input");
  //   inputs.forEach((input) => {
  //     input.value = "";
  //   });
  //   setAddressProof("");
  // };

  useEffect(() => {
    if (width < 767 && option.length > 1) {
      setState("webcam");
    }
  }, [option]);

  const handleMobileSetup = () => {
    setoption("");
    setState("main");
  };

  useEffect(() => {
    getAcceptedDocument()
      .then((res) => {
        setLoader(true);
        setAllOptionsId(res.data.data);
        // setAllOptionsId([]);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  function FinalizeIcons(types) {
    if (types === "PASSPORT") {
      return option === "PASSPORT" ? (
        <PassportWhiteIcon width="16px" height="16px" />
      ) : (
        <PassportIcon width="16px" height="16px" />
      );
    } else {
      return option === types ? (
        <NatioanlIdWhiteIcon width="16px" height="16px" />
      ) : (
        <NatioanlIdIcon width="16px" height="16px" />
      );
    }
  }

  function FinalNaming(name) {
    switch (name) {
      case "PASSPORT":
        return "Passport";
      case "AADHAAR_CARD":
        return "Aadhaar Card";
      case "VOTER_ID_CARD":
        return "Voter ID Card";
      case "DRIVING_LICENSE":
        return "Driving Licence";
      case "NATIONALID":
        return "National ID";
      case "RESIDENCE_PERMIT":
        return "Residence Permit";
      default:
        return name;
    }
  }

  return (
    <>
      {state === "main" && (
        <>
          <BannerBox width="100%">
            <Label txtGrey size="14px">
              ID Document for Verification
            </Label>
            <BannerBox InputSelectKyc mt="8px" display="flex" direction="column" gap="24px">
              {allOptionsId &&
                allOptionsId.map((types) => {
                  return (
                    <>
                      <BannerBox
                        display="flex"
                        justifyContent="space-between"
                        border="1px solid #8C9797"
                        radius="5px"
                        padding="12px 16px"
                        PrimaryBorderCard={option === types}
                        onClick={() => setoption(types)}
                      >
                        <BannerBox display="flex" gap="8px" alignItems="center">
                          <BannerHeadingSpan
                            width="32px"
                            height="32px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            BorderRadius="50%"
                            RadioBtnIconBg
                            RadioBtnIconBgSelected={option === types}
                          >
                            {FinalizeIcons(types)}
                          </BannerHeadingSpan>
                          <BannerHeading
                            size="14px"
                            weight="500"
                            style={{ textTransform: "capitalize" }}
                          >
                            {FinalNaming(types)}
                            {types === "PASSPORT" && (
                              <BannerHeadingSpan
                                RadioBtnIconBg
                                ml="6px"
                                height="20px"
                                width="92px"
                                txtPrimary
                                padding="0px 6px 2px 6px"
                                BorderRadius="5px"
                                size="12px"
                                weight="500"
                                // RadioBtnIconBgSelected
                                RecommendedKycSelectedBG={option === "PASSPORT"}
                              >
                                recommended
                              </BannerHeadingSpan>
                            )}
                          </BannerHeading>
                        </BannerBox>
                        <input
                          type="radio"
                          name="verification"
                          id={types}
                          value={types}
                          checked={option === types}
                          onChange={handleOnChange}
                          style={{
                            accentColor: "#706F6F"
                            // background: "red"
                          }}
                        />
                      </BannerBox>
                    </>
                  );
                })}
              {loader && allOptionsId.length === 0 && <Skeleton width="100%" height="100px" />}
            </BannerBox>

            {width > 767 && (
              <ButtonComponent
                height="48px"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="8px"
                position="relative"
                // disabled={!loader}
                mt="32px"
                onClick={() => setState("barcode")}
              >
                <BannerHeadingSpan
                  RadioBtnIconBg
                  ml="6px"
                  height="20px"
                  txtPrimary
                  padding="0px 6px 2px 6px"
                  BorderRadius="0px 5px 0px 5px"
                  position="absolute"
                  top="0px"
                  right="0px"
                  size="12px"
                  weight="500"
                >
                  recommended
                </BannerHeadingSpan>
                <MobileWhiteIcon />
                Continue with mobile app
              </ButtonComponent>
            )}
            {width > 767 && (
              <ButtonComponent
                height="48px"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="8px"
                disabled={option.length === 0 && !loader}
                cursor={option.length === 0 ? "no-drop" : "pointer"}
                OutlinePrimaryWithoutHover
                mt="24px"
                // onClick={() => onCaptureAgain("nationalIdSelection")}
                onClick={() => setState("webcam")}
              >
                <CameraBlueIcon />
                Take photo using webcam
              </ButtonComponent>
            )}
          </BannerBox>
        </>
      )}

      {state === "barcode" && (
        <BarcodeSection setState={setState} setSteps={setSteps} steps={steps} userData={userData} />
      )}
      {state === "webcam" && (
        <UploadIds
          setState={setState}
          mobileSetup={handleMobileSetup}
          selectType={FinalNaming(option || "")?.toLowerCase()}
          openWebcam={openWebcam}
          openWebcam2={openWebcam2}
          setCapturedImg={setCapturedImg}
          setCapturedImg2={setCapturedImg2}
          onCapture={onCapture}
          onCapture2={onCapture2}
          setOpenWebcam={setOpenWebcam}
          setOpenWebcam2={setOpenWebcam2}
          img={capturedImg}
          img2={capturedImg2}
          firstImage={firstImage}
          secondImage={secondImage}
          setFirstImage={setFirstImage}
          setSecondImage={setSecondImage}
        />
      )}
    </>
  );
};

const maptoStateProps = (state) => ({
  userData: state.dashboard.userData
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: () => dispatch(getUserDetails())
});

export default compose(connect(maptoStateProps, mapDispatchToProps))(withRouter(KycStep4));
