import { DivElement } from "../../../../../components/Reusable";
import { Fragment } from "react";
import LastPrice from "./LastPrice";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import OpenOrdersList from "./OpenOrdersList";
import OpenOrdersListTablet from "./OpenOrdersListTablet";
import BuySellRatio from "../BuySellRatio";
import { useSelector } from "react-redux";
import OrderBookTable from "../OrderBookTable";

const SIDES = {
  BUY: "buy",
  SELL: "sell"
};

const OpenOrders = ({ containerProps }) => {
  const orderBookConfig = useSelector((state) => state.trading.orderBookConfig);
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const activeSide = useSelector((state) => state.trading.orderBookActiveSide);
  const buySellRatio = orderBookConfig && orderBookConfig.buySellRatio;
  const { width } = useWindowSize();
  const isTablet = width > 767 && width <= 1024;

  return (
    <DivElement {...containerProps}>
      <DivElement height={buySellRatio ? "calc(100% - 32px)" : "100%"}>
        {isTablet ? (
          <DivElement height="100%" width="100%">
            <DivElement flexJustifyBtw width="100%" height="calc(100% - 52px)">
              {activeSide !== SIDES.BUY && <OpenOrdersListTablet side={SIDES.SELL} />}
              {activeSide !== SIDES.SELL && <OpenOrdersListTablet side={SIDES.BUY} />}
            </DivElement>
            <DivElement paddingTop="16px">
              <LastPrice />
            </DivElement>
          </DivElement>
        ) : (
          <Fragment>
            <OrderBookTable.Tr bgHeader marginBottom="8px" padding="0" height="18px">
              <OrderBookTable.Th textAlign="left" width="33.33%" paddingLeftMd="12px">
                Price<var>({selectedPair.quote})</var>
              </OrderBookTable.Th>
              <OrderBookTable.Th textAlign="right" width="28.67%">
                Amount<var>({selectedPair.base})</var>
              </OrderBookTable.Th>
              <OrderBookTable.Th textAlign="right" width="38%" paddingRightMd="12px">
                Total<var>({selectedPair.quote})</var>
              </OrderBookTable.Th>
            </OrderBookTable.Tr>
            <DivElement width="100%" height="calc(100% - 26px)" heightSm="calc(100% - 48px)">
              {activeSide !== SIDES.BUY && <OpenOrdersList side={SIDES.SELL} />}
              <LastPrice />
              {activeSide !== SIDES.SELL && <OpenOrdersList side={SIDES.BUY} />}
            </DivElement>
          </Fragment>
        )}
      </DivElement>

      {buySellRatio && <BuySellRatio />}
    </DivElement>
  );
};

export default OpenOrders;
