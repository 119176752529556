/* eslint-disable no-unused-vars */
import styled from "styled-components";
// import { ToastContainer } from "react-toastify";
import { Toaster, toast } from "sonner";

const ToastWrapperStyle = styled(Toaster)`
  :where([data-sonner-toaster]) {
    min-width: 410px !important;
    max-width: 100vw !important;
    width: max-content !important;
    display: flex;
    flex: 1 1 410px !important;
    @media (max-width: 560px) {
      min-width: 100% !important;
      flex: 1 1 100% !important;
    }

    li {
      width: 100% !important;
      .full-width {
        min-width: 410px;
        max-width: 100%;
        width: auto;
        flex-grow: 1 !important;
        @media (max-width: 560px) {
          min-width: 94% !important;
          max-width: 94% !important;
          flex: 1 1 94% !important;
        }
      }
      h6 {
        // text-wrap: nowrap !important;
      }
    }
  }
`;

export { ToastWrapperStyle };
