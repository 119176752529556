import React from "react";
import { useState } from "react";
import Select from "react-select";
import { DropdownIndicator } from "../../ReusedComponent/SelectReused";
// eslint-disable-next-line no-unused-vars
import ErrorMessageDiv from "../../../components/Reusable/Field/ErrorMessage";
import { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reactDropdownStyles } from "../../../utils";
import ImgElement from "../ImgElement";
import DivElement from "../DivElement";
import { BannerBox, BannerHeadingSpan } from "../../../assets/StyleComponents/Exchange.style";
import { Label } from "../../../assets/StyleComponents/FontSize/para.style";

const CustomOptions = ({ value, label, icon }) => {
  const removeSpace = typeof label === "object" || false;
  return (
    <DivElement flex alignItems="center" key={value}>
      {icon ? (
        <ImgElement src={icon} alt={value} width="20px" marginRight="8px" />
      ) : (
        !removeSpace && <BannerBox width="20px" marginRight="8px" />
      )}
      {label}
    </DivElement>
  );
};

const CountriesDropdown = ({
  name,
  value,
  disabled,
  setFieldValue,
  placeholder,
  countryList,
  containerClass,
  title,
  isDarkTheme,
  showOptional = false,
  error,
  setSelectedCountry,
  isClearableStatus = false,
  showError = true
}) => {
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (countryList) {
      setSelected(countryList && countryList?.find((data) => data?.label === value));
    }
    // eslint-disable-next-line
  }, [countryList]);

  const onChangeHandler = (value) => {
    let label = typeof value.label === "string" ? value?.label : value?.id;
    setSelected(value);
    setFieldValue(name, label || "");
    if (setSelectedCountry) setSelectedCountry(value?.label);
  };

  return (
    <BannerBox className={`fInput__form__field ${containerClass || ""}`} mb="32px" width="100%">
      <Label size="14px" weight="500" sizeMd="12px" txtGrey>
        {title}
        {showOptional && (
          <BannerHeadingSpan txtDanger size="14px" ml="4px">
            {" "}
            (optional){" "}
          </BannerHeadingSpan>
        )}
      </Label>
      <BannerBox mt="8px" InputSelectKyc>
        {countryList ? (
          <Select
            className={`${"fInput__form__input--bGrey"}`}
            components={{ DropdownIndicator }}
            name={name}
            isSearchable={true}
            isClearable={isClearableStatus}
            placeholder={placeholder}
            formatOptionLabel={CustomOptions}
            onChange={onChangeHandler}
            styles={reactDropdownStyles({ isDarkTheme })}
            options={countryList}
            value={selected}
            isDisabled={disabled}
            minMenuHeight={200}
            maxMenuHeight={200}
            menuPlacement="auto"
          />
        ) : (
          <div className="fInput__form__input"></div>
        )}
      </BannerBox>
      {showError && (
        <ErrorMessageDiv
          name={name}
          component="div"
          mt="6px"
          size="14px"
          sizemd="12px"
          weight="300"
          txtDanger
        />
      )}
    </BannerBox>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(CountriesDropdown);
