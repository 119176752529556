import React, { useEffect, useState } from "react";
import { ModalWrapper } from "../../Reusable";
import { connect } from "react-redux";
import { compose } from "redux";
import { LoadingButton } from "../../Loader";
import { isUserLoggedIn } from "../../../actions/auth";
import { Checkbox, DivElement, TextWrapper, Button } from "../../Reusable";
import { acceptBncOwner, acceptLatestTnc } from "../../../lib/api";
import { formatAPIErrors } from "../../../utils/general";
import { showErrorMsg } from "../../../utils/notification";

const TermsAndCondition = ({ actionsRequired, checkUserLoggedIn }) => {
  const [isTncChecked, setIsTncChecked] = useState(false);
  const [isBncChecked, setIsBncChecked] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [loader, setLoader] = useState(false);
  const tncRequired = actionsRequired.includes("TNC_STATUS");
  const bncRequired = actionsRequired.includes("BNC_OWNER");

  const handleSubmit = async () => {
    setLoader(true);
    let response;
    try {
      if (tncRequired) {
        response = await acceptLatestTnc({ terms: isTncChecked });
      }
      if (bncRequired) {
        response = await acceptBncOwner({ bncOwner: isBncChecked });
      }

      if (response) {
        setLoader(false);
        checkUserLoggedIn();
      }
    } catch (err) {
      showErrorMsg(formatAPIErrors(err));
      setLoader(false);
    }
  };

  useEffect(() => {
    if (tncRequired && bncRequired && isTncChecked && isBncChecked) {
      setDisableBtn(false);
    } else if (tncRequired && !bncRequired && isTncChecked) {
      setDisableBtn(false);
    } else if (!tncRequired && bncRequired && isBncChecked) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    // eslint-disable-next-line
  }, [actionsRequired, isBncChecked, isTncChecked]);

  return (
    <ModalWrapper>
      <DivElement className="termsAndCondition" ModalContainer>
        <div className="termsAndCondition__title">Terms of Service Update</div>
        <div className="termsAndCondition__subtitle">
          We’ve updated our Terms of service. Please review the following terms carefully and tick
          the checkbox to indicate your agreement.
        </div>
        <div className="termsAndCondition__link">
          Terms of Service (
          <a href="https://www.lcx.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
            Link
          </a>
          )
        </div>
        {tncRequired && (
          <DivElement flex marginTop="8px">
            <Checkbox checked={isTncChecked} setChecked={() => setIsTncChecked(!isTncChecked)} />
            <TextWrapper marginLeft="8px" fontWeight="300">
              I have read and I agree to the latest terms of service.
            </TextWrapper>
          </DivElement>
        )}

        {bncRequired && (
          <DivElement flex marginTop="8px">
            <Checkbox checked={isBncChecked} setChecked={() => setIsBncChecked(!isBncChecked)} />
            <TextWrapper marginLeft="8px" fontWeight="300">
              I am the beneficial owner of my account.
            </TextWrapper>
          </DivElement>
        )}

        <Button
          primaryBlue
          height="48px"
          width="100%"
          marginTop="24px"
          type="button"
          onClick={handleSubmit}
          disabled={disableBtn || loader}
        >
          {loader ? <LoadingButton /> : "Confirm"}
        </Button>
      </DivElement>
    </ModalWrapper>
  );
};

const mapStateToProps = (state) => ({
  userData: state.dashboard.userData,
  actionsRequired: state.auth.actionsRequired
});

const mapDispatchToProps = (dispatch) => ({
  checkUserLoggedIn: () => dispatch(isUserLoggedIn())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(TermsAndCondition);
