import React, { useEffect, useMemo } from "react";
import { MainElement } from "../../assets/StyleComponents/Exchange.style";
import CompetitionList from "../NewtradingCompetition/competitionList";
import TopBanner from "./Components/TopBanner";
import Faq from "../NewtradingCompetition/components/Faq";
import { HalfFooterCommon } from "../../components/Footer/common";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";
import { trackPage } from "../../tracking/track";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

function CampaiginsLandingPage() {
  const location = useLocation();
  // const history = useHistory();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // const userData = useSelector((state) => state.dashboard.userData);
  const { transalationList } = useSelector(GenericReducer);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchAvailableLanguagesRequest());
      // let checkPath = location.pathname?.split("/")[1];
      // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
      //   history.push(`/${userData.preferredLanguage}${location.pathname}`);
      // }
    }

    let page = location?.pathname + location?.search;
    trackPage(page);
  }, []);

  const SEOContents = {
    name: `CAMPAIGINS`,
    link: `campaigns`,
    title: "Get Rewards for Each Step at Our Crypto Exchange | LCX Exchange",
    description:
      "Earn rewards at our crypto exchange with each step you complete. Join now to start earning while you trade securely and efficiently.",
    keywords: ``,
    canonical: `https://www.exchange.lcx.com/campaigns`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `/${prevLang || "en"}/campaigns`,
    lang: `${prevLang || "en"}`,
    OgTitle: "Get Rewards for Each Step at Our Crypto Exchange | LCX Exchange",
    OgDescription:
      "Earn rewards at our crypto exchange with each step you complete. Join now to start earning while you trade securely and efficiently.",
    OgURL: `https://www.exchange.lcx.com/${prevLang || "en"}/campaigns`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Get Rewards for Each Step at Our Crypto Exchange | LCX Exchange",
    TwDescription:
      "Earn rewards at our crypto exchange with each step you complete. Join now to start earning while you trade securely and efficiently."
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/campaigns`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <MainElement ExchangeContainer margin="32px auto" mb="80px">
        <TopBanner />
        <CompetitionList title={"Campaigins"} type="campaigins" />
        <Faq type="campaigins" />
      </MainElement>

      <HalfFooterCommon />
    </>
  );
}

export default CampaiginsLandingPage;
