/* eslint-disable no-unused-vars */
import { Fragment, useState } from "react";
import { Button, DivElement, TextWrapper } from "../../Reusable";
import QRCode from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyWhiteIcon } from "../../../assets/svgs";
import { Skeleton } from "../../Loader";
import { showSuccessMsg } from "../../../utils/notification";
import styled, { css } from "styled-components";
import { useRef } from "react";
import { useEffect } from "react";
import LightiningNetwork from "../../Wallets/Deposits/LightiningNetwork";

const QrComponent = styled(QRCode)`
  background: "#fff";
  border-radius: "3px";
  width: 32px !important;
  height: 32px !important;

  ${(props) =>
    props.active &&
    css`
      width: 108px !important;
      height: 108px !important;

      @media (max-width: 767px) {
        width: 68px !important;
        height: 68px !important;
      }
    `}
`;

const QrCodeContainer = ({ value }) => {
  const [active, setActive] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setActive(false);
    }
  };

  return (
    <div ref={containerRef}>
      <DivElement displayCenter relative height="100%">
        <TextWrapper padding="0 8px" paddingSm="0 6px">
          or
        </TextWrapper>
        <DivElement
          absolute
          bgWhite
          left={active ? "8px" : "40px"}
          leftSm={active ? "3px" : "29px"}
          width={active ? "120px" : "44px"}
          height={active ? "120px" : "44px"}
          widthSm={active ? "80px" : "44px"}
          heightSm={active ? "80px" : "44px"}
          bdBlack
          border="2px solid"
          borderRadius="5px"
          displayCenter
          // onMouseOver={() => setActive(true)}
          onClick={() => setActive(!active)}
        >
          <QrComponent value={value} active={active} />
        </DivElement>
      </DivElement>
    </div>
  );
};

const NonFiatCoinDetails = ({ selectedCoin, selectedNetwork }) => {
  const onCopyHandler = (type) => {
    showSuccessMsg(`Successfully copied ${selectedCoin?.coin} ${type}`);
  };

  return (
    <Fragment>
      {selectedCoin.coin === "BTC" && selectedNetwork.label === "Lightning" ? (
        <LightiningNetwork selectedNetwork={selectedNetwork} selectedCoin={selectedCoin} />
      ) : (
        <Fragment>
          {selectedCoin?.address ? (
            <Fragment>
              <TextWrapper
                secondary
                fontSize="14px"
                fontSizeSm="12px"
                fontWeight="400"
                lineHeight="20px"
              >
                Deposit Address
              </TextWrapper>
              <DivElement dAlignCenter>
                <DivElement
                  bdGrey
                  width="calc(100% - 128px)"
                  widthSm="calc(100% - 83px)"
                  border="1px solid"
                  minHeight="56px"
                  borderRadius="5px"
                  displayBtw
                >
                  <TextWrapper
                    wordWrap
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="20px"
                    padding="8px 16px"
                    paddingSm="8px"
                    width="calc(100% - 90px)"
                    widthSm="calc(100% - 74px)"
                  >
                    {selectedCoin?.address}
                  </TextWrapper>
                  <CopyToClipboard
                    text={selectedCoin?.address}
                    onCopy={() => onCopyHandler("address")}
                  >
                    <Button
                      width="90px"
                      widthSm="74px"
                      padding="0"
                      primaryBlue
                      borderRadius="5px 0 0 5px"
                      fontSizeSm="14px"
                    >
                      <CopyWhiteIcon width="17px" height="18px" marginRight="8px" />
                      Copy
                    </Button>
                  </CopyToClipboard>
                </DivElement>

                <QrCodeContainer value={selectedCoin?.address} />
              </DivElement>
              <TextWrapper
                secondary
                fontSize="14px"
                fontSizeSm="12px"
                fontWeight="400"
                lineHeight="20px"
                marginTop="4px"
              >
                *Copy the address or click on the QR code*
              </TextWrapper>
            </Fragment>
          ) : (
            <Fragment>
              <Skeleton width="25%" height="20px" />
              <Skeleton width="100%" height="48px" />
            </Fragment>
          )}

          {selectedCoin.isMemo && (
            <Fragment>
              {selectedCoin.memo ? (
                <DivElement paddingTop="16px">
                  <TextWrapper
                    secondary
                    fontSize="14px"
                    fontSizeSm="12px"
                    fontWeight="400"
                    lineHeight="20px"
                  >
                    Memo ID
                  </TextWrapper>
                  <DivElement
                    bdGrey
                    border="1px solid"
                    minHeight="56px"
                    borderRadius="5px"
                    displayBtw
                  >
                    <TextWrapper
                      wordWrap
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="20px"
                      padding="8px 16px"
                      paddingSm="8px"
                      width="calc(100% - 90px)"
                      widthSm="calc(100% - 74px)"
                    >
                      {selectedCoin?.memo}
                    </TextWrapper>
                    <CopyToClipboard text={selectedCoin?.memo} onCopy={() => onCopyHandler("memo")}>
                      <Button
                        width="90px"
                        widthSm="74px"
                        padding="0"
                        primaryBlue
                        borderRadius="5px 0 0 5px"
                        fontSizeSm="14px"
                      >
                        <CopyWhiteIcon width="17px" height="18px" marginRight="8px" />
                        Copy
                      </Button>
                    </CopyToClipboard>
                  </DivElement>
                </DivElement>
              ) : (
                <Fragment>
                  <Skeleton width="25%" height="20px" />
                  <Skeleton width="100%" height="48px" />
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default NonFiatCoinDetails;
