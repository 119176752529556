import { createGlobalStyle } from "styled-components";
import IBMPlexSans from "./IBM_Plex_Sans/IBMPlexSans-Regular.ttf";
import IBMPlexSansMedium from "./IBM_Plex_Sans/IBMPlexSans-Medium.ttf";
import IBMPlexSansBold from "./IBM_Plex_Sans/IBMPlexSans-Bold.ttf";

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local('IBM Plex Sans Light'), local('IBM Plex Sans Light'),
      url(${IBMPlexSans}) format('truetype');
  }
  
  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local('IBM Plex Sans Regular'), local('IBM Plex Sans Regular'),
      url(${IBMPlexSans}) format('truetype');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local('IBM Plex Sans Medium'), local('IBM Plex Sans Medium'),
      url(${IBMPlexSansMedium}) format('truetype');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local('IBM Plex Sans Bold'), local('IBM Plex Sans Bold'),
      url(${IBMPlexSansBold}) format('truetype');
  }
`;

export default FontStyles;