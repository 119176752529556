/* eslint-disable no-console */
import styled, { css } from "styled-components";
import { CommanHeightStyle } from "../../../assets/StyleComponents/Reusable/Height.style";
import { CommanWidthStyle } from "../../../assets/StyleComponents/Reusable/Width.style";

const DivElement = styled.div`
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  display: ${(props) => (props.display ? props.display : "")};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "")};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : "")};
  flex-grow: ${(props) => (props.flexGrow ? props.flexGrow : "")};
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : "")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : "")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "")};
  outline: ${(props) => (props.outline ? props.outline : "")};
  border-width: ${(props) => (props.borderWidth ? props.borderWidth : "")};
  border: ${(props) => (props.border ? props.border : "")};
  border-top: ${(props) => (props.borderTop ? props.borderTop : "")};
  border-right: ${(props) => (props.borderRight ? props.borderRight : "")};
  border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : "")};
  border-left: ${(props) => (props.borderLeft ? props.borderLeft : "")};
  border-radius: ${(props) => (props.radius ? props.radius : "")};
  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  right: ${(props) => (props.right ? props.right : "")};
  bottom: ${(props) => (props.bottom ? props.bottom : "")};
  left: ${(props) => (props.left ? props.left : "")};
  opacity: ${(props) => (props.opacity ? props.opacity : "")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  background: ${(props) => (props.bg ? props.bg : "")};
  color: ${(props) => (props.color ? props.color : "")};
  transition: ${(props) => (props.transition ? props.transition : "")};
  gap: ${(props) => (props.gap ? props.gap : "")};
  overflow-x: ${(props) => (props.overflowX ? props.overflowX : "")};
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `};

  @media (max-width: 1024px) {
    width: ${(props) => (props.widthMd ? props.widthMd : "")};
    height: ${(props) => (props.heightMd ? props.heightMd : "")};
    display: ${(props) => (props.displayMd ? props.displayMd : "")};
    justify-content: ${(props) => (props.justifyContentMd ? props.justifyContentMd : "")};
    align-items: ${(props) => (props.alignItemsMd ? props.alignItemsMd : "")};
    flex-wrap: ${(props) => (props.flexWrapMd ? props.flexWrapMd : "")};
    flex-direction: ${(props) => (props.flexDirectionMd ? props.flexDirectionMd : "")};
    max-width: ${(props) => (props.maxWidthMd ? props.maxWidthMd : "")};
    max-height: ${(props) => (props.maxHeightMd ? props.maxHeightMd : "")};
    min-width: ${(props) => (props.minWidthMd ? props.minWidthMd : "")};
    min-height: ${(props) => (props.minHeightMd ? props.minHeightMd : "")};
    margin: ${(props) => (props.marginMd ? props.marginMd : "")};
    margin-top: ${(props) => (props.marginTopMd ? props.marginTopMd : "")};
    margin-right: ${(props) => (props.marginRightMd ? props.marginRightMd : "")};
    margin-bottom: ${(props) => (props.marginBottomMd ? props.marginBottomMd : "")};
    margin-left: ${(props) => (props.marginLeftMd ? props.marginLeftMd : "")};
    border-width: ${(props) => (props.borderWidthMd ? props.borderWidthMd : "")};
    border: ${(props) => (props.borderMd ? props.borderMd : "")};
    border-top: ${(props) => (props.borderTopMd ? props.borderTopMd : "")};
    border-right: ${(props) => (props.borderRightMd ? props.borderRightMd : "")};
    border-bottom: ${(props) => (props.borderBottomMd ? props.borderBottomMd : "")};
    border-left: ${(props) => (props.borderLeftMd ? props.borderLeftMd : "")};
    border-radius: ${(props) => (props.borderRadiusMd ? props.borderRadiusMd : "")};
    position: ${(props) => (props.positionMd ? props.positionMd : "")};
    top: ${(props) => (props.topMd ? props.topMd : "")};
    right: ${(props) => (props.rightMd ? props.rightMd : "")};
    bottom: ${(props) => (props.bottomMd ? props.bottomMd : "")};
    left: ${(props) => (props.leftMd ? props.leftMd : "")};
    padding: ${(props) => (props.paddingMd ? props.paddingMd : "")};
    padding-top: ${(props) => (props.paddingTopMd ? props.paddingTopMd : "")};
    padding-right: ${(props) => (props.paddingRightMd ? props.paddingRightMd : "")};
    gap: ${(props) => (props.gapMd ? props.gapMd : "")};
    padding-bottom: ${(props) => (props.paddingBottomMd ? props.paddingBottomMd : "")};
    padding-left: ${(props) => (props.paddingLeftMd ? props.paddingLeftMd : "")};
    ${({ flexMd }) =>
      flexMd &&
      css`
        flex: ${flexMd};
      `};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.widthSm ? props.widthSm : "")};
    height: ${(props) => (props.heightSm ? props.heightSm : "")};
    display: ${(props) => (props.displaySm ? props.displaySm : "")};
    justify-content: ${(props) => (props.justifyContentSm ? props.justifyContentSm : "")};
    align-items: ${(props) => (props.alignItemsSm ? props.alignItemsSm : "")};
    flex-wrap: ${(props) => (props.flexWrapSm ? props.flexWrapSm : "")};
    flex-direction: ${(props) => (props.flexDirectionSm ? props.flexDirectionSm : "")};
    max-width: ${(props) => (props.maxWidthSm ? props.maxWidthSm : "")};
    max-height: ${(props) => (props.maxHeightSm ? props.maxHeightSm : "")};
    min-width: ${(props) => (props.minWidthSm ? props.minWidthSm : "")};
    min-height: ${(props) => (props.minHeightSm ? props.minHeightSm : "")};
    margin: ${(props) => (props.marginSm ? props.marginSm : "")} !important;
    margin-top: ${(props) => (props.marginTopSm ? props.marginTopSm : "")};
    margin-right: ${(props) => (props.marginRightSm ? props.marginRightSm : "")};
    margin-bottom: ${(props) => (props.marginBottomSm ? props.marginBottomSm : "")};
    margin-left: ${(props) => (props.marginLeftSm ? props.marginLeftSm : "")};
    border-width: ${(props) => (props.borderWidthSm ? props.borderWidthSm : "")};
    border: ${(props) => (props.borderSm ? props.borderSm : "")};
    border-top: ${(props) => (props.borderTopSm ? props.borderTopSm : "")};
    border-right: ${(props) => (props.borderRightSm ? props.borderRightSm : "")};
    border-bottom: ${(props) => (props.borderBottomSm ? props.borderBottomSm : "")};
    border-left: ${(props) => (props.borderLeftSm ? props.borderLeftSm : "")};
    border-radius: ${(props) => (props.borderRadiusSm ? props.borderRadiusSm : "")};
    position: ${(props) => (props.positionSm ? props.positionSm : "")};
    top: ${(props) => (props.topSm ? props.topSm : "")};
    right: ${(props) => (props.rightSm ? props.rightSm : "")};
    bottom: ${(props) => (props.bottomSm ? props.bottomSm : "")};
    left: ${(props) => (props.leftSm ? props.leftSm : "")};
    z-index: ${(props) => (props.zIndexSm ? props.zIndexSm : "")};
    padding: ${(props) => (props.paddingSm ? props.paddingSm : "")};
    padding-top: ${(props) => (props.paddingTopSm ? props.paddingTopSm : "")};
    padding-right: ${(props) => (props.paddingRightSm ? props.paddingRightSm : "")};
    padding-bottom: ${(props) => (props.paddingBottomSm ? props.paddingBottomSm : "")};
    padding-left: ${(props) => (props.paddingLeftSm ? props.paddingLeftSm : "")};
    grid-template-columns: ${(props) => (props.GridColsSm ? props.GridColsSm : "")};
    ${({ flexSm }) =>
      flexSm &&
      css`
        flex: ${flexSm};
      `};
  }

  ${(props) =>
    props.ModalContainer &&
    css`
      border-radius: 10px;
      padding: 10px;
      font-weight: 500;
      width: 450px;
      color: #012243;
      background-color: ${({ theme }) => props.bgColor ? `${props.bgColor}` : theme.level1Card} !important;

      @media (orientation: landscape) {
        height: fit-content;
      }

      @media (max-width: 1024px) and (min-width: 768px) {
        padding: 17px 24px 32px 24px;
        width: 408px;
      }

      @media (max-width: 767px) {
        padding: 19px 16px 32px 16px;
        width: 328px;
      }
    `}

  ${(props) =>
    props.InviteModalContainer &&
    css`
      border-radius: 10px;
      padding: 30px 40px;
      font-weight: 500;
      width: 850px;
      color: #012243;
      background-color: ${({ theme }) => theme.level1Card} !important;

      @media (orientation: landscape) {
        height: fit-content;
      }

      @media (max-width: 1024px) and (min-width: 768px) {
        padding: 17px 24px 32px 24px;
        width: 740px;
      }

      @media (max-width: 767px) {
        padding: 19px 16px 32px 16px;
        width: 328px;
      }
    `}
  ${(props) =>
    props.DownBarContainer &&
    css`
      border-radius: 10px;
      padding: 10px;
      font-weight: 500;
      width: 100%;
      color: #012243;
      margin-top: auto;
      background-color: ${({ theme }) => theme.level1Card} !important;

      @media (orientation: landscape) {
        height: fit-content;
      }

      @media (max-width: 1023.5px) and (min-width: 768px) {
        padding: 17px 24px 32px 24px;
        width: 408px;
      }

      @media (max-width: 767.5px) {
        padding: 19px 16px 32px 16px;
        width: 100%;
      }
    `}

  ${(props) =>
    props.displayCenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${(props) =>
    props.displayEnd &&
    css`
      display: flex;
      justify-content: flex-end;
      align-items: center;
    `}

  ${(props) =>
    props.dAlignCenter &&
    css`
      display: flex;
      align-items: center;
    `}

  ${(props) =>
    props.greyColor &&
    css`
      color: ${(props) => (props.theme.isDarkTheme ? "#a7a9ab" : "#606f80")};
    `}

  ${(props) =>
    props.displayBtw &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}

    ${(props) =>
    props.bgCream &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#162029" : "#fafafa")};
    `}

    ${(props) =>
    props.bgBlue &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#70a6e0" : "#0470e1")};
    `}

    ${(props) =>
    props.bgPrimary &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#0a0d12" : "#f5f6fa")};
    `}

    ${(props) =>
    props.bgGrey &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#5c5f66" : "#ccced0")};
    `}

    ${(props) =>
    props.bgCreamInput &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#19242E" : "#fafafa")};
    `}

    ${(props) =>
    props.bgCreamPopup &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#162029" : "#fafafa")};
    `}
    ${(props) =>
    props.bgCard &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#151e26" : "#ffffff")};
    `}

    ${(props) =>
    props.bgPopup &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#263645" : "#ffffff")};
    `}

    ${(props) =>
    props.bgAppBanner &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#263645" : "#ECF5FE")};
    `}

    ${(props) =>
    props.bgAppBannerClose &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#1B2631" : "#fafafa")};
    `}

    ${(props) =>
    props.bgOrange &&
    css`
      background-color: #f7931a;
    `}

    ${(props) =>
    props.bgWhite &&
    css`
      background-color: #fff;
    `}

    ${(props) =>
    props.bgGreen &&
    css`
      background-color: ${props.theme.primaryGreen};
    `}

     ${(props) =>
    props.rewardExpired &&
    css`
      background-color: ${(props) => (props?.isDarkTheme ? "#334530" : "#F0FFED")};
    `}


    ${(props) =>
    props.bgRed &&
    css`
      background-color: ${props.theme.primaryRed};
    `}

    ${(props) =>
    props.bgLightRed &&
    css`
      background-color: ${props.theme.secondaryRed};
    `}

    ${(props) =>
    props.bgInputActive &&
    css`
      background-color: ${props.theme.inputActive};
    `}

    ${(props) =>
    props.borderGreen &&
    css`
      border: 1px solid ${(props) => (props.theme.isDarkTheme ? "#68bf60" : "#008000")};
    `}

    ${(props) =>
    props.borderLightGreen &&
    css`
      border: 1px solid ${(props) => (props.theme.isDarkTheme ? "#2a3e33" : "#e6f0e6")};
    `}

    ${(props) =>
    props.bdGrey &&
    css`
      border-color: ${(props) => props.theme.sepColor} !important;
    `}

     ${(props) =>
    props.borderBlue &&
    css`
      border: 1px solid ${(props) => (props.theme.isDarkTheme ? "#70a6e0" : "#0470e1")};
    `}

    ${(props) =>
    props.bdInputColor &&
    css`
      border-color: ${(props) => props.theme.inputBorder} !important;
    `}

    ${(props) =>
    props.bdGreyDisabled &&
    css`
      border-color: ${(props) => props.theme.disabledBorder} !important;
    `}


    
    ${(props) =>
    props.bdLightOrange &&
    css`
      border-color: #f7ca94;
    `}
    ${(props) =>
    props.CardBgmain &&
    css`
      box-shadow: ${(props) =>
        props.boxShadow ? props.boxShadow : "0px 2px 4px rgba(126, 142, 177, 0.12)"};
      background-color: ${(props) => (props.theme.isDarkTheme ? "#151e26" : "white")};
    `}

    ${(props) =>
    props.bdBlack &&
    css`
      border-color: ${(props) => (props.theme.isDarkTheme ? "#dedfe0" : "#012243")};
    `}

    ${(props) =>
    props.bdBlue &&
    css`
      border-color: ${(props) => (props.theme.isDarkTheme ? "#70a6e0" : "#0470e1")};
    `}

    ${(props) =>
    (props.cursor || props.cursorPointer) &&
    css`
      cursor: pointer;
    `}

    ${(props) =>
    props.cursorDragAndDrop &&
    css`
      cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
    `}


    ${(props) =>
    props.cursorDisabled &&
    css`
      cursor: not-allowed;
    `}

    ${(props) =>
    props.dColCenter &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}

    ${(props) =>
    props.scrollY &&
    css`
      overflow-y: scroll;
    `}

    ${(props) =>
    props.scrollX &&
    css`
      overflow-x: scroll;
    `}

    ${(props) =>
    props.overflowHidden &&
    css`
      overflow: hidden;
    `}

    ${(props) =>
    props.bdRight &&
    css`
      border-right: 1px solid ${(props) => (props.theme.isDarkTheme ? "#5c5f66" : "#ccced0")};
    `}

    ${(props) =>
    props.relative &&
    css`
      position: relative;
    `}

    ${(props) =>
    props.relativeMd &&
    css`
      @media (max-width: 1024px) {
        position: relative;
      }
    `}

    ${(props) =>
    props.absolute &&
    css`
      position: absolute;
    `}

    ${(props) =>
    props.column &&
    css`
      display: flex;
      flex-direction: column;
    `}

    ${(props) =>
    props.flex &&
    css`
      display: flex;
    `}

    ${(props) =>
    props.blueHover &&
    css`
      :hover {
        background-image: linear-gradient(
          76deg,
          rgb(4, 112, 225, 0.08) 2%,
          rgb(64, 163, 199, 0.08)
        );
        border-right: 4px solid #4595e7;
      }
    `}

    ${(props) =>
    props.pageContainer &&
    css`
      position: relative;
      width: 90%;
      max-width: 1124px;
      margin: 32px auto;
      box-sizing: border-box;
      min-width: 328px;
      @media (max-width: 1366px) {
        width: 95% !important;
        max-width: 1124px !important;
        margin: 32px auto;
      }

      @media (max-width: 1920px) {
        width: 100% !important;
        max-width: 1124px !important;
        margin: 32px auto;
      }
      @media (max-width: 1124px) {
        width: 95% !important;
        max-width: 992px !important;
        margin: 32px auto;
      }
    `}
    ${(props) =>
    props.pageContainerNew &&
    css`
      position: relative;
      width: 90%;
      max-width: 1124px;
      margin: 32px auto;
      box-sizing: border-box;
      min-width: 328px;
      @media (max-width: 1366px) {
        width: 95% !important;
        max-width: 1124px;
        margin: 32px auto;
      }

      @media (max-width: 1920px) {
        width: 100% !important;
        max-width: 1124px;
        margin: 32px auto;
      }
      @media (max-width: 1124px) {
        width: 95% !important;
        max-width: 992px;
        margin: 32px auto;
      }
      @media (max-width: 767px) {
        width: 100% !important;
        margin: 32px auto;
      }
    `}

    ${(props) =>
    props.verifiedTagBg &&
    css`
      background-color: ${(props) => (props.theme.isDarkTheme ? "#012243" : "#fff")};
    `}

    ${(props) =>
    props.PaginationContainer &&
    css`
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    margin-top: 58px;
    color: ${(props) => props.theme.bodyText};
  
    @media (max-width: 1024px) {
      margin-top: 48.5px;
    }
    @media (max-width: 767px) {
      margin-top: 42px;
    }
    .pc__pagebtn {
      min-width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 6px;
      cursor: pointer;
      font-size: 14px;
    
      &.active {
        text-decoration: underline;
        color: ${(props) => props.theme.primary};
      }
    }
    .pc__pageends {
      width: auto;
      height: 24px;
      display: flex;
      align-items: center;
      margin: 0 6px;
      cursor: pointer;
      font-size: 14px;

      &.disabled {
        color: ${(props) => props.theme.subText};
        opacity: 0.6;
      }
    `}

    ${(props) =>
    props.TableLoader &&
    css`
      display: flex;
      flex-direction: column;
      .tableLoader__row {
        display: flex;
        justify-content: space-between;
        padding: 12.8px 29px;
        border-bottom: 1px solid ${(props) => props.theme.DropdownBorder};

        @media (max-width: 1024px) {
          padding: 14px 25px;
        }
        @media (max-width: 767px) {
          padding: 10px 12px;
        }

        &:last-child {
          border: none;
        }
      }
      .tableLoader__rowHead {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12.8px 29px;
        background-color: ${(props) => props.theme.mainBackground};

        @media (max-width: 1024px) {
          padding: 14px 25px;
        }
        @media (max-width: 767px) {
          padding: 10px 12px;
        }
      }
      .tableLoader__cell {
        width: 80px !important;
        height: 16px !important;

        @media (max-width: 1024px) {
          width: 70px !important;
        }
        @media (max-width: 767px) {
          width: 60px !important;
          height: 14px !important;
        }
      }
    `}
    ${(props) =>
    props.flexJustifyBtw &&
    css`
      display: flex;
      justify-content: space-between;
    `}

    ${(props) =>
    props.flexJustifyBtwAlignCenter &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}

    ${(props) =>
    props.flexJustifyCenter &&
    css`
      display: flex;
      justify-content: center;
    `}

    ${(props) =>
    props.flexAlignCenter &&
    css`
      display: flex;
      align-items: center;
    `}
    ${(props) =>
    props.TSBorderDashed &&
    css`
      border-bottom: 1px dashed grey;
    `}

    ${(props) =>
    props.flexRow &&
    css`
      display: flex;
      flex-direction: row;
    `}

    ${(props) =>
    props.flexRowReverse &&
    css`
      display: flex;
      flex-direction: row-reverse;
    `}

    ${(props) =>
    props.flexColumn &&
    css`
      display: flex;
      flex-direction: column;
    `}
    ${(props) =>
    props.InputCheckBox &&
    css`
      position: absolute !important;
      opacity: 0 !important;
      z-index: 1 !important;
      width: 13px;
      height: 13px;
      margin-left: 0 !important;
    `}

    ${(props) =>
    props.flexColumnReverse &&
    css`
      display: flex;
      flex-direction: column-reverse;
    `}

    ${(props) =>
    props.ModalContainer &&
    css`
      border-radius: 10px;
      padding: 24px 24px 24px 24px;
      font-weight: 500;
      width: 450px;
      color: ${({ theme }) => theme.bodyText} !important;
      background-color: ${({ theme }) => props.bgColor ? `${props.bgColor}` : theme.level1Card} !important;

      @media (max-width: 1024px) and (min-width: 768px) {
        padding: 17px 24px 32px 24px;
        width: 408px;
      }

      @media (max-width: 767px) {
        padding: 19px 16px 32px 16px;
        width: 328px;
      }
    `}
    ${(props) =>
    props.GuideLineModal &&
    css`
    .guidline__modal {
      @media (min-width: 1024px) {
        display: none;
      }
    }
    .guidline__modal__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
    }
    .guidline__modal__title {
      font-size: 20px;
      font-weight: bold;
      @include themed() {
        color: t($primary-text);
      }
    }
    .guidline__modal__img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    
      img {
        width: 100%;
        height: 100%;
      }
    }
    .guidline__modal__points {
      font-size: 16px;
      padding-left: 16px;
    
      @include themed() {
        color: t($sub-text);
      }
    
      li {
        margin-bottom: 24px;
      }
    }
    .guidline__modal__points--s1 {
      @media (min-width: 1024px) {
        margin-top: 135px;
      }
    }
    .guidline__modal__points li a {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      text-decoration: none;
    }
    }
    `}

    ${(props) =>
    props.LCXModal &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1000003 !important;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;
    `}
    ${(props) =>
    props.LCXDownBar &&
    css`
      position: fixed;
      bottom: 0px;
      width: 100vw;
      height: 100vh;
      z-index: 1000003 !important;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;
    `}

    ${(props) =>
    props.AddressUpdateModal &&
    css`
      margin-top: 250px;

      @media (min-width: 1600px) {
        margin-top: 5%;
      }

      @media (max-width: 767px) {
        width: 100% !important;
        margin-top: 360px;
        padding-bottom: 150px;
      }
    `}

    ${(props) =>
    props.TableOptions &&
    css`
      position: absolute;
      top: calc(100% + 15px);
      z-index: 100;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.level2Card};
      // box-shadow: 0 3px 6px 0 ${({ theme }) => theme.subText};
      border: 1px solid ${({ theme }) => theme.subText};

      .left-align {
        left: 20px;
      }

      .right-align {
        right: 20px;
      }

      .center-align {
        left: 50%;
        transform: translate(-50%, 0) rotate(45deg);
      }

      ul {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        border-radius: 5px;
        z-index: 100;
        background-color: ${({ theme }) => theme.level2Card};
      }

      ul li {
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        font-weight: 500;
        padding: 0 24px;
        white-space: nowrap;
        font-size: 12px;
        color: ${({ theme }) => theme.bodyText};
      }

      ul li:hover {
        cursor: pointer;
        width: 100%;
        border-radius: 5px;
        color: #012243;
        background-color: ${({ theme }) => theme.buttonActive};
      }
    `}

    ${(props) =>
    props.TableOptionsLeftAlign &&
    css`
      // left: -15px;
    `}
    ${(props) =>
    props.TableOptionsRightAlign &&
    css`
      right: -15px;
    `}
    ${(props) =>
    props.TableOptionsCenterAlign &&
    css`
      left: 50%;
      transform: translate(-50%, 0);
    `}
    ${(props) =>
    props.TableOptionsIndicator &&
    css`
      top: -5px;
      width: 10px;
      height: 10px;
      position: absolute;
      border-style: solid;
      border-width: 1px 0 0 1px;
      transform: rotate(45deg);
      z-index: -1;
      background-color: ${({ theme }) => theme.level2Card};
      border-color: ${({ theme }) => theme.subText};
    `}

    ${(props) =>
    props.EarnPageStyles &&
    css`
      .bondsumm__point {
        display: flex;
        align-items: flex-start;
        min-height: 32px;
        margin-bottom: 33px;
        min-width: 33.33%;

        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(9) {
          min-width: 25%;
        }

        @media (max-width: $mobileBreakingPoint) {
          margin-bottom: 24px;

          min-width: 50%;
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(6),
          &:nth-child(8),
          &:nth-child(10) {
            min-width: 40%;
          }
        }
      }
      .bondsumm__point img {
        height: 16px;
        width: auto;
        margin-top: 3px;
      }
      .bondsumm__point .bondsumm__point__content {
        display: flex;
        flex-direction: column;
        padding-left: 8px;
      }
    `}
    ${(props) =>
    props.BorderBottom &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.inputBorder};
    `}
    
  ${CommanHeightStyle}
  ${CommanWidthStyle}
`;

export default DivElement;
