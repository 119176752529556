/* eslint-disable no-unused-vars */
import { new_BN, multiply } from "./bigNumber";
import config from "../constants/config";

//=> Algo for find Limit Total
export const getLimitAmount = (percentage, balance) => {
  return (balance * percentage) / 100;
};

//=> Algo for find Limit Order Amount
export const getLimitOrderAmount = (total, price) => {
  return Number(total) / Number(price);
};

// => Check fixDigit
export const fixedDigit = (value, digit) => {
  if (!value && isNaN(Number(value))) return "";
  value = value.toString();
  let valueArray = value.toString().split(".");
  if (valueArray.length > 1 && valueArray[1].length > digit)
    return Number(Number(value).toFloor(digit));
  else return value;
};

// => When user Click on Percentage find all values
export const percentageUpdateValues = (percentage, balance, amountPrecision, price) => {
  let orderPrice = fixedDigit(getLimitAmount(percentage, balance), amountPrecision);
  let totalPrice = orderPrice * price;
  return {
    orderPrice: new_BN(orderPrice, amountPrecision),
    totalPrice: new_BN(totalPrice, 8)
  };
};

// => Algo for Find Limit Perecentage
export const getLimitPercentage = (getAmount, balance, price) => {
  return (getAmount / balance) * price;
};

//=> Update the Total Amount
export const amountUpdateTotal = (
  getAmount,
  price,
  amountPrecision,
  selectedCoin,
  tradeList,
  activeTab,
  activeKey,
  cumulativeList
) => {
  let balance = tradeList[selectedCoin];
  let findPercentage = 0;
  let amountResult = getAmount;
  let amountCopy = getAmount;
  let result = 0;
  if (price) {
    amountResult = sellOrderCondtion(Number(amountResult), Number(price));
    // amountResult =  sellOrderCondtion(Number(amountResult), Number(undefined));
    result = findAmountValue(amountResult, amountCopy, amountPrecision, balance, price);
    if (activeTab === "buy") {
      if (result.amountResult && result.percentage) {
        amountResult = result.amountResult;
        findPercentage = Math.ceil((result.amountResult / balance.FreeBalance) * 100);
      }
    } else {
      if (result.amountResult && result.percentage) {
        amountResult = result.amountResult;
        findPercentage = result.percentage;
      }
    }
  }
  return { amountResult, findPercentage, price };
};

//=> Update the Total Amount
export const totalUpdateAmount = (
  getAmount,
  price,
  amountPrecision,
  selectedCoin,
  tradeList,
  activeTab,
  activeKey,
  cumulativeList
) => {
  let balance = tradeList[selectedCoin] || 0;
  let findPercentage = 0;
  let amountResult = getAmount;
  let amountCopy = getAmount;
  let result = 0;
  if (price) {
    // amountResult =  sellOrderCondtion(Number(amountResult), Number(price));
    amountResult = checkOrderCumulative(amountResult, price, activeKey, activeTab, cumulativeList);
    result = findAmountValue(amountResult, amountCopy, amountPrecision, balance, price, true);
    if (activeTab === "buy") {
      if (result.amountResult && result.percentage) {
        amountResult = result.amountResult;
        findPercentage = Math.ceil((amountCopy / balance.FreeBalance) * 100);
      }
    } else {
      if (result.amountResult && result.percentage) {
        amountResult = result.amountResult;
        findPercentage = result.percentage;
      }
    }
  }
  return { amountResult, findPercentage, price, amountCopy, result };
};

//=> Update the Amount in Market Side
export const marketAmountUpdation = (
  getAmount,
  price,
  amountPrecision,
  selectedCoin,
  tradeList,
  activeTab,
  activeKey,
  cumulativeList
) => {
  let balance = tradeList[selectedCoin];
  let findPercentage = 0;
  let amountResult = getAmount;
  let amountCopy = getAmount;
  let errorMsgRes = "";
  let result = 0;
  if (price) {
    // amountResult = checkOrderCumulative(amountResult, price, activeKey, activeTab, cumulativeList);
    // errorMsgRes = amountResult.errorMsg;
    // if(!errorMsgRes) {
    result = findAmountValue(amountResult, amountCopy, amountPrecision, balance, price);
    if (result.amountResult && result.percentage) {
      amountResult = result.amountResult;
      findPercentage = result.percentage;
    }
    // }
  }
  return { amountResult, findPercentage, price, errorMsgRes };
};

//=> Logic for Amount Percision and Find Percentage
const findAmountValue = (
  amount,
  amountCopy,
  amountPrecision,
  balance,
  price,
  updateTotal = false
) => {
  let amountResult = amount;
  let percentage = 0;
  if (amount && amountPrecision && balance && balance.FreeBalance) {
    if (Number(amountResult) > 100000000) return true;
    if (amountResult) amountResult = amountResult.toString();
    else amountResult = "";
    let valueArray = amountResult?.toString()?.split(".");
    if (valueArray.length > 1 && valueArray[1].length > amountPrecision) {
      amountResult = Number(amountResult).toFloor(8);
      if (amountResult) amountResult = new_BN(amountResult, 8);
    }
    if (updateTotal) {
      amountResult = getLimitOrderAmount(amountCopy, price);
      percentage = Math.ceil((amountResult / balance.FreeBalance) * 100);
    } else {
      if (balance.FreeBalance > 0) {
        percentage = Math.ceil((amountCopy / balance.FreeBalance) * 100);
      }
    }
    amountResult = amountResult ? new_BN(amountResult, 8) : 0;
  } else {
    amountResult = new_BN(getLimitOrderAmount(amountCopy, price), 8);
    percentage = 0;
  }
  return { amountResult, percentage };
};

// => for Sell Orders
const sellOrderCondtion = (amount, price) => {
  if (amount && price) {
    return Number(multiply(amount, price));
  } else return 0;
};

//=> Cumulative Data
export const checkOrderCumulative = (total, price, activeKey, activeTab, cumulativeList) => {
  // let total_copy = total;
  let getTotal = total;
  let amountResult = total;
  let errorMsg = "";
  if (activeKey === "market" && (activeTab === "buy" || activeTab === "sell")) {
    let array = cumulativeList || [];
    // let count = 0;
    let price_sum = 0;
    if (array.length) {
      for (let i = array.length - 1; i >= 0; i--) {
        if (getTotal > 0) {
          // count++;
          getTotal -= Number(array[i][0]) * Number(array[i][1]);
          price_sum += Number(array[i][1]);
        }
      }
      if (getTotal > price_sum) {
        errorMsg = "Market Amount should be less than total market amount";
        amountResult = 0;
      }
    } else {
      errorMsg = "Market Amount should be less than total market amount";
      amountResult = 0;
    }
  }
  return { amountResult, errorMsg, price };
};

// => Function used for updating the PairData Object
export const getPairData = (pair) => {
  let pairData = JSON.parse(localStorage.getItem("pairData"));
  if (!pair) return pairData;
  else {
    return (pairData = pair);
  }
};

export const tabsNotOpenMoreThanFour = (givenArray, pair, ActivePairName) => {
  let result = givenArray || [];
  if (result && result.length >= 4) {
    const findIndex = result.findIndex((item) => {
      const pathname = item.basePair + "/" + item.quotePair;
      return pathname === pair;
    });

    if (findIndex !== -1) {
      let splitName = ActivePairName.split("/");
      result[findIndex].basePair = splitName[0];
      result[findIndex].quotePair = splitName[1];
    }
  }

  return result;
};
// => Function used for precision updation.
export const decimalFilter = (type, listArray, pricePrecision) => {
  let finialResult = listArray;
  let selected_decimal = pricePrecision || 8;
  if (type === "asks" && listArray && listArray.length > 0) {
    const lookup = listArray.reduce((prev, [decimal, value]) => {
      let formatted =
        Math.ceil((decimal * 10 ** selected_decimal).toFixed(3)) / 10 ** selected_decimal;
      formatted = formatted.toFixed(selected_decimal);

      if (!prev[formatted]) {
        prev[formatted] = 0;
      }
      prev[formatted] = Number(prev[formatted]) + Number(value);
      return prev;
    }, {});
    let result = Object.entries(lookup);
    return result;
  } else if (type === "bids" && listArray && listArray.length > 0) {
    const lookup = listArray.reduce((prev, [decimal, value]) => {
      let formatted =
        Math.floor((decimal * 10 ** selected_decimal).toFixed(3)) / 10 ** selected_decimal;
      formatted = formatted.toFixed(selected_decimal);
      if (!prev[formatted]) {
        prev[formatted] = 0;
      }
      prev[formatted] = Number(prev[formatted]) + Number(value);
      return prev;
    }, {});
    let result = Object.entries(lookup);
    return result;
  }
  return finialResult || [];
};

//=> Check Latest Active Pair Name
export const checkPairName = (selectedPairName, location) => {
  let result = "";
  if (location.pathname === "/trade") {
    if (selectedPairName && selectedPairName.base_pair) {
      result = `${selectedPairName.base_pair}/${selectedPairName.quote_pair}`;
    } else {
      result = config.defaultPair;
    }
  } else {
    let splitPair = location.pathname.split("/");
    if (splitPair && splitPair.length === 3) {
      let splitPairName = splitPair[2].split("-");
      result = `${splitPairName[0]}/${splitPairName[1]}`;
    } else {
      if (selectedPairName && selectedPairName.base_pair) {
        result = `${selectedPairName.base_pair}/${selectedPairName.quote_pair}`;
      } else {
        result = config.defaultPair;
      }
    }
  }
  return result;
};
