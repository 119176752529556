import styled, { css } from "styled-components";
import PairSearchBar from "./PairSearchBar";
import { DivElement, TextWrapper } from "../../../../../components/Reusable";

const COIN_FILTERS = [
  { label: "Favourites", value: "FAVOURITES" },
  { label: "All", value: "ALL" },
  { label: "BTC", value: "BTC" },
  { label: "ETH", value: "ETH" },
  { label: "USDC", value: "USDC" },
  { label: "EUR", value: "EUR" }
];

const Tab = styled(TextWrapper)`
  padding: 0 4px;
  margin: 0 6px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  line-height: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.subText};

  &:hover {
    color: ${(props) => props.theme.primaryBlue};
    border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.primaryBlue};
      border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
    `}
`;

const Container = styled(DivElement)`
  height: 100%;
  width: 100%;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding: 0;
  }
`;

const CoinsFilter = ({ filter, setFilter, searchText, setSearchText }) => {
  return (
    <DivElement height="64px" heightSm="fit-content" width="100%" paddingSm="0 16px">
      <Container bdGrey borderBottom="1px solid" displayBtw>
        <DivElement
          flex
          height="100%"
          heightSm="38px"
          margin="0 -8px"
          marginSm="0"
          widthSm="100%"
          scrollX
        >
          {COIN_FILTERS.map((data) => (
            <Tab
              onClick={() => setFilter(data.value)}
              active={data.value === filter ? true : null}
              key={data.value}
            >
              {data.label}
            </Tab>
          ))}
        </DivElement>
        <PairSearchBar searchText={searchText} setSearchText={setSearchText} />
      </Container>
    </DivElement>
  );
};
export default CoinsFilter;
