import React from "react";
import { TextWrapper } from "../../../../components/Reusable";
import ParticipatedHistoryTable from "./ParticipatedHistoryTable";
import { BannerSection } from "../../../../assets/StyleComponents/Exchange.style";

const ParticipatedHistory = () => {
  return (
    <BannerSection
      SectionWhite
      radius="5px"
      padd={"24px 36px 32px 40px"}
      paddMd={"16px 16px 21px 16px"}
      mt="16px"
      mtMd="12px"
    >
      <TextWrapper
        fontSize="24px"
        fontSizeSm="20px"
        lineHeight="28.8px"
        marginBottom="20px"
        marginBottomMd="24px"
        marginBottomSm="16px"
      >
        Participated Sale History
      </TextWrapper>

      <ParticipatedHistoryTable />
    </BannerSection>
  );
};

export default ParticipatedHistory;
