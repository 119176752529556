import React from "react";
import { BannerBox } from "../../../../../assets/StyleComponents/Exchange.style";
import SkeletonLoader from "../../../../../components/SkeletonLoader/index";

const WithdrawalLoader = () => {
  return(
    <>
      <BannerBox className="d-flex flex-column">
        <BannerBox className="d-flex pd-r-90 align-items-start pd-t-40">
          <SkeletonLoader circle="40" width={30} height={30} givenClass={""} />
          <BannerBox className="d-flex flex-column">
            <SkeletonLoader width={250} height={10} givenClass={" ml-2"} />
            <SkeletonLoader width={100} height={10} givenClass={" ml-2"} />
          </BannerBox>
        </BannerBox>
        
        <BannerBox className="d-flex pd-r-90 align-items-start mt-3">
          <SkeletonLoader circle="40" width={30} height={30} givenClass={""} />
          <BannerBox className="d-flex flex-column">
            <SkeletonLoader width={250} height={10} givenClass={" ml-2"} />
            <SkeletonLoader width={100} height={10} givenClass={" ml-2"} />
          </BannerBox>
        </BannerBox>

        <BannerBox className="d-flex pd-r-90 align-items-start mt-3">
          <SkeletonLoader circle="40" width={30} height={30} givenClass={""} />
          <BannerBox className="d-flex flex-column">
            <SkeletonLoader width={250} height={10} givenClass={" ml-2"} />
            <SkeletonLoader width={100} height={10} givenClass={" ml-2"} />
          </BannerBox>
        </BannerBox>
      </BannerBox>
    </>
  );
};

export default WithdrawalLoader;