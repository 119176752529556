import React from "react";
import { Link } from "react-router-dom";
import { DivElement, ModalWrapper } from "../../../Reusable";
import { CloseIcon } from "../../../../assets/svgs";
import { TwoFaSecuredIllustration } from "../../../../assets/svgs/illustrations";
import { TextWrapper } from "../../../Reusable";
import { BannerBox } from "../../../../assets/StyleComponents/Exchange.style";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { ButtonComponent } from "../../../../assets/StyleComponents/Button.style";

const TwoFaModal = ({ onClose }) => {
  const prevLang = localStorage.getItem("ljs-lang");

  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <DivElement displayBtw>
          <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
            Improve Security
          </TextWrapper>
          <CloseIcon pointer onClick={onClose} />
        </DivElement>

        <BannerBox display="flex" justifyContent="center" padding="32px 0">
          <TwoFaSecuredIllustration />
        </BannerBox>

        <ParaTag size="14px" txtGrey>
          Enable 2FA, including Google Authenticator, to generate anti-phishing code
        </ParaTag>

        <BannerBox mt="24px">
          <Link to={`${prevLang ? `/${prevLang}`: "/en"}/setting/security/auth`}>
            <ButtonComponent
              // className="w-100 btn btn-primary bg-blue blue-border white-font font-weight-500"
              marginTop="24px"
              primaryBlue
              width="100%"
              height="48px"
            >
              Enable 2FA
            </ButtonComponent>
          </Link>
        </BannerBox>
      </DivElement>
    </ModalWrapper>
  );
};

export default TwoFaModal;
