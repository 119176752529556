import apiConstants from "../constants/apiConstants";

const initialStateSchema = {
  rewardsOnboardingList: [],
  isFetchingRewardsOnboarding: false,
  rewardsRegularList: [],
  isFetchingRewardsRegular: false,
  isFetchingTotalRewardsCount: false,
  totalRewardsCount: 0,
  claimData: {},
  rewardsTab: {
    type: "",
    offset: "0",
    pageSize: 25,
    category: "REGULAR"
  },
  claimErrorStatus: false,
  isLoading: "",
  success: "",
  error: ""
};

const RewardsHubReducer = (state = initialStateSchema, action) => {
  let newState;
  switch (action.type) {
    case apiConstants.GET_PRIVATE_ONBOARDING_REWARDS_TASKS_LIST:
      newState = {
        ...state,
        isFetchingRewardsOnboarding: true,
        error: ""
      };

      return newState;

    case apiConstants.GET_PRIVATE_ONBOARDING_REWARDS_TASKS_LIST_SUCCESS:
      newState = {
        ...state,
        rewardsOnboardingList: action?.response?.data?.data || [],
        isFetchingRewardsOnboarding: false
      };
      return newState;

    case apiConstants.GET_PRIVATE_ONBOARDING_REWARDS_TASKS_LIST_FAIL:
      newState = {
        ...state,
        error: action?.response?.data,
        isFetchingRewardsOnboarding: false
      };
      return newState;

    case apiConstants.GET_PRIVATE_REGULAR_REWARDS_TASKS_LIST:
      newState = {
        ...state,
        isFetchingRewardsRegular: true,
        error: ""
      };

      return newState;

    case apiConstants.GET_PRIVATE_REGULAR_REWARDS_TASKS_LIST_SUCCESS:
      newState = {
        ...state,
        rewardsRegularList: action?.response?.data?.data || [],
        isFetchingRewardsRegular: false
      };
      return newState;

    case apiConstants.GET_PRIVATE_REGULAR_REWARDS_TASKS_LIST_FAIL:
      newState = {
        ...state,
        error: action?.response?.data,
        isFetchingRewardsRegular: false
      };
      return newState;

    case apiConstants.POST_USER_PARTICIPATE_REQUEST:
      newState = {
        ...state,
        isLoading: true,
        error: ""
      };

      return newState;

    case apiConstants.POST_USER_PARTICIPATE_REQUEST_SUCCESS:
      newState = {
        ...state,
        success: action?.response?.message,
        isLoading: false
      };
      return newState;

    case apiConstants.POST_USER_PARTICIPATE_REQUEST_FAIL:
      newState = {
        ...state,
        error: action?.response?.data,
        isLoading: false
      };
      return newState;

    case apiConstants.POST_CLAIM_REWARD_REQUEST:
      newState = {
        ...state,
        isLoading: true,
        claimErrorStatus: false,
        error: ""
      };

      return newState;

    case apiConstants.POST_CLAIM_REWARD_REQUEST_SUCCESS:
      newState = {
        ...state,
        isLoading: false,
        claimData: action.response,
        success: action?.response?.message
      };
      if(action.response?.status === 400) newState.claimErrorStatus = true;
      return newState;

    case apiConstants.POST_CLAIM_REWARD_REQUEST_FAIL:
      newState = {
        ...state,
        claimErrorStatus: true,
        error: action?.response?.message
      };
      return newState;

    case apiConstants.GET_TOTAL_REWARDS_COUNT:
      newState = {
        ...state,
        isFetchingTotalRewardsCount: true,
        error: ""
      };

      return newState;

    case apiConstants.GET_TOTAL_REWARDS_COUNT_SUCCESS:
      newState = {
        ...state,
        totalRewardsCount: action?.response?.data,
        isFetchingTotalRewardsCount: false
      };
      return newState;

    case apiConstants.GET_TOTAL_REWARDS_COUNT_FAIL:
      newState = {
        ...state,
        error: action?.response?.data,
        isFetchingTotalRewardsCount: false
      };
      return newState;

    case apiConstants.UPDATE_REWARDS_TAB:
      newState = {
        ...state
      };

      return newState;

    case apiConstants.UPDATE_REWARDS_TAB_SUCCESS:
      newState = {
        ...state,
        rewardsTab: {
          type: action.response.type,
          offset: action.response.offset,
          pageSize: action.response.pageSize,
          category: action.response.category
        }
      };
      return newState;

    case apiConstants.UPDATE_REWARDS_TAB_FAIL:
      newState = {
        ...state
      };
      return newState;

    case apiConstants.RESET_REWARD_HUB_STATE:
      newState = {
        ...state,
        error: "",
        success: ""
      };
      return newState;

    default:
      return state;
  }
};

export default RewardsHubReducer;
