import { useState } from "react";
import { useSelector } from "react-redux";
import OrderBookTable from "../OrderBookTable";
import { DivElement } from "../../../../../components/Reusable";
import { SingleOrderRowTablet } from "./SingleOrderRow";

const SIDES = {
  BUY: "buy",
  SELL: "sell"
};

const OpenOrdersListTablet = ({ side }) => {
  let dataType = side === SIDES.SELL ? "orderBookAsks" : "orderBookBids";
  const ordersList = useSelector((state) => state.trading[dataType]);
  const [currRowIndex, setCurrRowIndex] = useState(-1);
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const activeSide = useSelector((state) => state.trading.orderBookActiveSide);
  const orderBookConfig = useSelector((state) => state.trading.orderBookConfig);

  const onMouseOut = () => {
    if (orderBookConfig.displayAvgSum) {
      setCurrRowIndex(-1);
    }
  };

  const isSell = side === "sell" ? true : false;
  const isAll = activeSide === "all" ? true : false;

  return (
    <DivElement width={isAll ? "calc(50% - 6px)" : "100%"} height="100%">
      <OrderBookTable direction={isSell && isAll ? "" : "rtl"}>
        <OrderBookTable.Tr bgHeader>
          {activeSide !== "all" && (
            <OrderBookTable.Th
              width="38%"
              textAlign={isSell && isAll ? "left" : "right"}
              padding="0 16px"
            >
              Total<var>({selectedPair.quote})</var>
            </OrderBookTable.Th>
          )}
          <OrderBookTable.Th
            width={activeSide === "all" ? "50%" : "28.67%"}
            textAlign={isSell && isAll ? "left" : "right"}
            padding="0 16px"
          >
            Amount<var>({selectedPair.base})</var>
          </OrderBookTable.Th>

          <OrderBookTable.Th
            width={activeSide === "all" ? "50%" : "33.33%"}
            textAlign={isSell && isAll ? "right" : "left"}
            padding="0 28px"
          >
            Price<var>({selectedPair.quote})</var>
          </OrderBookTable.Th>
        </OrderBookTable.Tr>
      </OrderBookTable>

      <DivElement scrollY overflowX="hidden" height="calc(100% - 40px)" paddingTop="8px">
        <OrderBookTable onMouseOut={onMouseOut} direction={isSell && isAll ? "" : "rtl"}>
          {ordersList &&
            ordersList.map((order, index) => (
              <SingleOrderRowTablet
                setCurrRowIndex={setCurrRowIndex}
                side={side}
                key={index}
                order={order}
                index={index}
                currRowIndex={currRowIndex}
                activeSide={activeSide}
              />
            ))}
        </OrderBookTable>
      </DivElement>
    </DivElement>
  );
};

export default OpenOrdersListTablet;
