/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { CoinLabel, DivElement, SpanElement, TextWrapper } from "../Reusable";
import { CloseIcon } from "../../assets/svgs";
import SelectDropDownComponent, {
  CustomOptionsForBank,
  CustomOptionsForNetwork,
  DropdownIndicator,
  MyOptions,
  customStylesContinentsForWallets
} from "../SelectDropDown/selectDropDown";
import { reactDropdownStyles, selectProviderCustomOptions } from "../../utils";
import { compose } from "redux";
import { connect } from "react-redux";
import { getAllWallets } from "../../actions/wallet";
import { filterCoin, formatAPIErrors, noExponents, numberWithCommas } from "../../utils/general";
import {
  createWallet,
  getBeneficiary,
  getCoinNetworks,
  getFiatDepositDetails
} from "../../lib/api";
import { showErrorMsg } from "../../utils/notification";
import Select from "react-select";
import { Skeleton } from "../Loader";
import { AddBeneficiary, Enable2FA, GetKycVerified, SuspensionAlert } from "../Alert";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";

import { DepositContext } from "../../contexts/WalletPage/DepositPage/depositContext";
import { WalletContext } from "../../contexts/WalletPage/BalancePage/wallet";
import WithdrawalNonFiatCoinDetails from "./Components/Withdrawal/NonFaitCoinDetails";
import WithdrawalFiatCoinDetails from "./Components/Withdrawal/FaitCoinDetails";
import { Label } from "../../assets/StyleComponents/FontSize/para.style";
import BlincIdOptionModal from "./BlincIdOptionModal";
import LcxPayModal from "./Components/lcxPayModal";
import { CustomOptionsForBeneficiary } from "../ReusedComponent/SelectReused";

const formattedArrayNetwork = (array) => {
  return array.map((item) => {
    return {
      id: item,
      label: item.label,
      value: item.value,
      transactionFeePercent: item.transactionFeePercent ? item.transactionFeePercent : "",
      fee: item.fee ? item.fee : "",
      max: item.max ? item.max : "",
      min: item.max ? item.min : ""
    };
  });
};

const WithdrawalModal = ({
  isDarkTheme,
  mainWallet,
  getMainWallet,
  coin,
  onClose,
  apiLoader,
  userData
}) => {
  const [selectedCoin, setStateSelectedCoin] = useState(coin);
  const [selectedNetwork, setStateSelectedNetwork] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [coinNetworks, setCoinNetworks] = useState("");
  const [coinProviders, setCoinProviders] = useState("");
  const [loader, setLoader] = useState(false);
  const [isCreatedWallet, setIsCreatedWallet] = useState(false);
  const [fiatAmount, setFiatAmount] = useState("");
  // eslint-disable-next-line
  const [beneficiaryList, setBeneficaryList] = useState([]);
  const [providerList, setProviderList] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [showBeneficiaryModal, setShowBeneficiaryModal] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [show2fa, setShow2fa] = useState(false);
  const [WithdrawalProcessingModal, setWithdrawalProcessingModal] = useState(false);
  let { exchWallet } = useContext(WalletContext);
  const prevLang = localStorage.getItem("ljs-lang");

  let { setSelectedNetwork, setSelectedCoin, resetValue } = useContext(DepositContext);

  useEffect(() => {
    return () => setSelectedCoin("");
  }, []);

  useEffect(() => {
    if (mainWallet && !apiLoader) {
      let coinData = mainWallet.data.find((data) => data.coin === selectedCoin.coin);

      selectCoinHandler(coinData);
    }
    // eslint-disable-next-line
  }, [mainWallet, isCreatedWallet, apiLoader]);

  const selectCoinHandler = (coinData) => {
    if (coinData.coinType === "fiat_token") {
      //   getFiatDeposit(coinData);
      getBeneficiary()
        .then((res) => {
          let data = res && res.data;
          if (data.data && data.data.length === 0) {
            setShowBeneficiaryModal(true);
            setBeneficaryList([]);
            setProviderList([]);
          } else {
            let result = data.data.map((item, i) => {
              return {
                id: i,
                label: item.bankName,
                value: item
              };
            });
            setBeneficaryList(res?.data?.data);
            // setProviderList(res?.data?.data);
            setProviderList(result);
          }
        })
        .catch((err) => {
          showErrorMsg(formatAPIErrors(err));
        })
        .finally(() => setLoader(false));
    }
    getNetworks(coinData);

    setSelectedCoin(coinData);
    setStateSelectedCoin(coinData);
    setSelectedNetwork("");
    setStateSelectedNetwork("");
    selectProviderHandler("");
  };

  const selectNetworkHandler = (networkData) => {
    setSelectedNetwork(networkData);
    setStateSelectedNetwork(networkData);
    if (!selectedCoin.address || (selectedCoin.isMemo && !selectedCoin.memo)) {
      let data = {
        coin: selectedCoin.coin,
        network_id: networkData?.id
      };
      createWallet(data)
        .then((res) => {
          getMainWallet();
          setIsCreatedWallet(true);
        })
        .catch((err) => showErrorMsg(formatAPIErrors(err)));
    }
  };

  const selectProviderHandler = (value) => {
    setSelectedNetwork(value);
    setStateSelectedNetwork(value);
    setSelectedProvider("");
    setFiatAmount("");
    setShowBeneficiaryModal(true);
  };

  const getFiatDeposit = (coinData) => {
    setLoader(true);
    let data = {
      coin: coinData.coin,
      ttype: "WITHDRAWAL"
    };
    getFiatDepositDetails(data)
      .then((res) => {
        setCoinProviders(res?.data?.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      });
    // .finally(() => setLoader(false));

    getBeneficiary()
      .then((res) => {
        let data = res && res.data;
        if (data.data && data.data.length === 0) {
          setShowBeneficiaryModal(true);
          setBeneficaryList([]);
          setProviderList([]);
        } else {
          let result = data.data.map((item, i) => {
            return { id: i, label: item.bankName, value: item };
          });
          setBeneficaryList(res?.data?.data);
          // setProviderList(res?.data?.data);
          setProviderList(result);
        }
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setLoader(false));
  };

  const getNetworks = (coinData) => {
    setLoader(true);
    let data = {
      coin: coinData.coin,
      ttype: "WITHDRAWAL"
    };
    getCoinNetworks(data)
      .then((res) => {
        let networkList = res?.data?.data?.networks.map((val) => {
          let result = {
            id: val.id,
            label: val.name,
            value: val.name,
            fee: val.fee ? val.fee : "",
            max: val.max ? val.max : 100000,
            min: val.min,
            depositsEnabled: val.depositsEnabled || false
          };
          if (val.supports) result.supports = val.supports;
          return result;
        });

        setCoinNetworks(networkList);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => setLoader(false));
  };

  const handleProviderCurrency = (coinData) => {
    setSelectedProvider(coinData);
    setSelectedNetwork("");
    setStateSelectedNetwork("");
  };

  const handleNetworkCurrency = (coinData) => {
    setSelectedNetwork(coinData);
    setStateSelectedNetwork(coinData);
    setSelectedProvider("");
  };

  let kycStatus = useMemo(
    () => (userData && userData.KYC && userData.KYC.STATUS ? userData.KYC.STATUS : "LEVEL0"),
    [userData.KYC.STATUS]
  );
  let status2fa = useMemo(
    () =>
      userData && userData.twoFactorAuth && userData.twoFactorAuth.status
        ? userData.twoFactorAuth.status
        : false,
    [userData.twoFactorAuth.status]
  );

  const onHandleWithdrawalProcessingModal = (getValue) => {
    setWithdrawalProcessingModal(getValue || false);
    // if(getValue) onClose();
  };

  const closeParentModal = () => {
    setWithdrawalProcessingModal(false);
    onClose();
  };

  const checkKycStatus = kycStatus === "LEVEL0" || kycStatus === "LEVEL1" ? true : false;
  return (
    <Fragment>
      <DivElement displayBtw>
        <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
          Withdrawal
        </TextWrapper>
        <CloseIcon pointer onClick={closeParentModal} />
      </DivElement>
      <DivElement bdGrey borderBottom="1px solid" paddingBottom="8px">
        <TextWrapper
          fontSize="14px"
          fontSizeSm="12px"
          fontWeight="400"
          lineHeight="20px"
          marginTop="6px"
        >
          You can manage your withdrawals here
        </TextWrapper>
      </DivElement>

      {!WithdrawalProcessingModal && (
        <>
          <DivElement marginBottom="21px" paddingTop="12px">
            <TextWrapper
              secondary
              fontSize="14px"
              fontSizeSm="12px"
              fontWeight="400"
              lineHeight="20px"
              lineHeightSm="18px"
            >
              Select Asset
            </TextWrapper>
            <SelectDropDownComponent
              dataComponent={{
                DropdownIndicator,
                Option: MyOptions
              }}
              name="selectCurrency"
              placeholder="Select Asset"
              optionList={filterCoin(mainWallet.data)}
              selected_coin={selectedCoin}
              chooseLabel={(val) => <CoinLabel {...val} insufficientFunds={true} />}
              value={
                selectedCoin.coin ? { label: selectedCoin.coin, options: selectedCoin.coin } : ""
              }
              defaultValue={
                selectedCoin.coin ? { label: selectedCoin.coin, options: selectedCoin.coin } : ""
              }
              handlePairName={selectCoinHandler}
              getStyle={customStylesContinentsForWallets}
            />
            <TextWrapper
              fontWeight="400"
              marginTop="1.5px"
              fontSize="14px"
              fontSizeSm="12px"
              lineHeight="20px"
              lineHeightSm="18px"
            >
              Main balance:
              <SpanElement fontSize="inherit" lineHeight="inherit">
                <var>
                  {` ${numberWithCommas(noExponents(selectedCoin?.balance?.freeBalance || 0))} ${
                    selectedCoin?.coin
                  }`}
                </var>
              </SpanElement>
            </TextWrapper>
          </DivElement>

          {selectedCoin !== "" && !selectedCoin.enableWithdrawals && (
            <SuspensionAlert type="Withdrawal" coin={selectedCoin.coin} />
          )}

          {selectedCoin !== "" && selectedCoin.enableWithdrawals && (
            <>
              <DivElement marginBottom="20px">
                <TextWrapper
                  secondary
                  fontSize="14px"
                  fontSizeSm="12px"
                  fontWeight="400"
                  lineHeight="20px"
                  lineHeightSm="18px"
                  marginBottom="8px"
                >
                  Choose {selectedCoin.coinType === "fiat_token" ? "Provider" : "Network"}
                </TextWrapper>

                {!loader &&
                (selectedCoin.coinType === "fiat_token" ||
                  (selectedCoin.coinType !== "fiat_token" && coinNetworks)) ? (
                  <Fragment>
                    {selectedCoin.coinType === "fiat_token" ? (
                      <Fragment>
                        {checkKycStatus ? (
                          <GetKycVerified open={showModal} onClose={closeParentModal} />
                        ) : show2fa ? (
                          <Enable2FA onClose={closeParentModal} />
                        ) : (
                          <BannerBox
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            gap="10px"
                          >
                            <BannerBox width="100%" height="45px">
                              <Select
                                name="selectNetwork"
                                placeholder={
                                  selectedCoin.coinType === "fiat_token"
                                    ? "Select Provider"
                                    : "Select Network"
                                }
                                components={{ DropdownIndicator }}
                                isSearchable={true}
                                formatOptionLabel={(vals) =>
                                  selectProviderCustomOptions({ ...vals, isDarkTheme })
                                }
                                onChange={selectProviderHandler}
                                styles={reactDropdownStyles({
                                  isDarkTheme,
                                  borderBottom: true,
                                  height: ""
                                })}
                                options={formattedArrayNetwork(coinNetworks || [])}
                                value={
                                  selectedNetwork && selectedNetwork.value
                                    ? {
                                        label: selectedNetwork.label,
                                        value: selectedNetwork.value
                                      }
                                    : ""
                                }
                                minMenuHeight={200}
                                maxMenuHeight={200}
                                menuPlacement="auto"
                              />
                            </BannerBox>
                          </BannerBox>
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {checkKycStatus ? (
                          <GetKycVerified open={showModal} onClose={closeParentModal} />
                        ) : show2fa ? (
                          <Enable2FA onClose={closeParentModal} />
                        ) : (
                          <SelectDropDownComponent
                            dataComponent={{
                              DropdownIndicator,
                              Option: (props) => {
                                return <MyOptions {...props} isNetwork={true} />;
                              }
                            }}
                            name="selectNetwork"
                            placeholder="Select Network"
                            optionList={formattedArrayNetwork(coinNetworks)}
                            selected_coin={selectedNetwork}
                            chooseLabel={CustomOptionsForNetwork}
                            styles={reactDropdownStyles({
                              isDarkTheme,
                              borderBottom: true,
                              height: "48px"
                            })}
                            useStyles={true}
                            value={
                              selectedNetwork
                                ? { label: selectedNetwork.value, options: selectedNetwork.value }
                                : ""
                            }
                            defaultValue={
                              selectedNetwork
                                ? { label: selectedNetwork.value, options: selectedNetwork.value }
                                : ""
                            }
                            // handlePairName={selectNetworkHandler}
                            handlePairName={(data) => handleNetworkCurrency(data)}
                          />
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Skeleton height="48px" width="100%" marginBottom="20px" />
                  </Fragment>
                )}
              </DivElement>
            </>
          )}
        </>
      )}

      {selectedCoin.coin &&
        selectedCoin.coinType === "fiat_token" &&
        selectedNetwork.label &&
        selectedNetwork.label !== "LCX Pay" &&
        selectedNetwork.label !== "BLINC" && (
          <Fragment>
            {checkKycStatus ? (
              <GetKycVerified open={showModal} onClose={closeParentModal} />
            ) : show2fa ? (
              <Enable2FA onClose={closeParentModal} />
            ) : beneficiaryList && beneficiaryList.length === 0 ? (
              showModal && <AddBeneficiary isTheme={isDarkTheme} onClose={closeParentModal} />
            ) : (
              <Fragment>
                <Label htmlFor="Network" txtGrey size="14px" width="100%" mt="16px" mb="4px">
                  Choose Bank Name
                </Label>
                <BannerBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                >
                  <BannerBox width="75%" height="45px">
                    <Select
                      name="selectBeneficiary"
                      placeholder="Select Bank"
                      components={{ DropdownIndicator }}
                      isSearchable={true}
                      // formatOptionLabel={(vals) =>
                      //   selectProviderCustomOptions({ ...vals, isDarkTheme })
                      // }
                      formatOptionLabel={CustomOptionsForBeneficiary}
                      onChange={(val) => setSelectedBeneficiary(val)}
                      styles={reactDropdownStyles({
                        isDarkTheme,
                        borderBottom: true,
                        height: "48px"
                      })}
                      options={providerList}
                      value={
                        selectedBeneficiary && selectedBeneficiary.value
                          ? {
                              label: selectedBeneficiary.label,
                              value: selectedBeneficiary.value
                            }
                          : ""
                      }
                      minMenuHeight={200}
                      maxMenuHeight={200}
                      menuPlacement="auto"
                    />
                  </BannerBox>

                  <BannerBox textAlignMd="right" width="25%">
                    <Link
                      to={`${prevLang ? `/${prevLang}` : "/en"}/wallet/bank_accounts`}
                      onClick={() => onClose()}
                    >
                      <ButtonComponent
                        OutlinePrimary
                        btnHeight={"44px"}
                        width={"96px"}
                        widthMd={"80px"}
                      >
                        Manage
                      </ButtonComponent>
                    </Link>
                  </BannerBox>
                </BannerBox>
              </Fragment>
            )}
          </Fragment>
        )}

      {selectedNetwork.label === "LCX Pay" && (
        <LcxPayModal
          CloseAllModal={closeParentModal}
          modalStatus={WithdrawalProcessingModal}
          MainOnClose={(getValue) => onHandleWithdrawalProcessingModal(getValue)}
        />
      )}

      {selectedNetwork.label === "BLINC" && (
        <BlincIdOptionModal
          CloseAllModal={closeParentModal}
          modalStatus={WithdrawalProcessingModal}
          MainOnClose={(getValue) => onHandleWithdrawalProcessingModal(getValue)}
        />
      )}

      {selectedCoin.coin &&
        selectedCoin.coinType !== "fiat_token" &&
        selectedNetwork &&
        selectedNetwork.label !== "LCX Pay" &&
        selectedNetwork.label !== "BLINC" &&
        selectedNetwork.value && (
          <WithdrawalNonFiatCoinDetails
            modalStatus={WithdrawalProcessingModal}
            CloseAllModal={closeParentModal}
            MainOnClose={(getValue) => onHandleWithdrawalProcessingModal(getValue)}
            selectedCoin={selectedCoin}
          />
        )}

      {selectedCoin.coin &&
        selectedCoin.coinType === "fiat_token" &&
        selectedBeneficiary &&
        selectedNetwork.label !== "LCX Pay" &&
        selectedNetwork.label !== "BLINC" && (
          <WithdrawalFiatCoinDetails
            selectedCoin={selectedCoin}
            selectedProvider={selectedNetwork}
            selectedBeneficiary={selectedBeneficiary}
            CloseAllModal={closeParentModal}
            modalStatus={WithdrawalProcessingModal}
            MainOnClose={(getValue) => onHandleWithdrawalProcessingModal(getValue)}
          />
        )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme,
  userData: state.dashboard.getUserData,
  selectedPairName: state.trade.selectedPair,
  isSelectedPairFecthing: state.trade.isSelectedPairFecthing,
  getBeneficiaryAll: state.bank.getBeneficiaryAll,
  getBeneficiaryCount: state.bank.getBeneficiaryCount,
  deposits: state.wallet.deposits,
  totalDepositCount: state.wallet.totalDepositCount,
  mainWallet: state.wallet.wallets,
  apiLoader: state.wallet.apiLoader
});

const mapDispatchToProps = (dispatch) => ({
  getMainWallet: () => dispatch(getAllWallets())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(WithdrawalModal);
