import React, { Suspense } from "react";
import MarketTable from "../components/MarketTable/marketTable";
import { BannerSection } from "../assets/StyleComponents/Exchange.style";
// const MarketTableComponent = lazy(() => import("../components/MarketTable/marketTable"));

function MarketSDK() {
  return (
    <BannerSection
      zIndex="2"
      left="0.3%"
      margin="0px auto"
      ExchangemarketWidth2
      position="relative"
      BorderTop="0px"
      background="transparent"
    >
      <Suspense fallback={null}>
        <MarketTable />
      </Suspense>
    </BannerSection>
  );
}

export default MarketSDK;
