/* eslint-disable no-console */

/* eslint-disable no-unused-vars */
import { fromJS } from "immutable";
import { OREDR_BOOK_LIST, ORDER_BOOK_FETCHING } from "./const.js";

export function createInitialState() {
  return fromJS({
    apiAskList: [],
    apiBidList: [],
    isFetchingOrderBook: false
  });
}

export function TradeOrderBookReducer(state = createInitialState(), action) {
  switch (action.type) {
    case OREDR_BOOK_LIST:
      return fromJS({
        ...state.toJS(),
        apiAskList: action.payload?.sell || [],
        apiBidList: action.payload?.buy || []
      });
    case ORDER_BOOK_FETCHING:
      return fromJS({
        ...state.toJS(),
        isFetchingOrderBook: action.payload
      });
    default:
      return state;
  }
}
