import React, { useState, useEffect } from "react";
import TopBanner from "./components/TopBanner";
import { MainElement } from "../../assets/StyleComponents/Exchange.style";
import DashboardReferal from "./components/Dashboard";
import DetailedEarningsTab from "./components/DetailedEarningsTab";
import { useSelector } from "react-redux";
import { formatAPIErrors } from "../../utils/general";
import { getReferralAmountGained } from "../../lib/api";
import { showErrorMsg } from "../../utils/notification";

function NewReferal() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.dashboard.userData);
  const KYCStatus = userData?.KYC?.STATUS === "LEVEL2";
  const [referralData, setRefferalData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ascending, setAscending] = useState(true);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [page, setPage] = useState(1);

  useEffect(() => {
    getReferralData();
  }, [page]);

  const getReferralData = () => {
    setIsLoading(true);
    // let data = {
    //   Offset: page,
    //   Detailed: true
    // };
    let data = appliedFilters;
    data.Offset = page;
    data.Detailed = false;
    getReferralAmountGained(data)
      .then((res) => {
        setRefferalData(res && res.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onApplyFilterHandler = (givenFilter) => {
    setIsLoading(true);

    setAppliedFilters(givenFilter);
    getReferralAmountGained(givenFilter)
      .then((res) => {
        setRefferalData(res && res.data);
      })
      .catch((err) => {
        showErrorMsg(formatAPIErrors(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <MainElement ExchangeContainer margin="40px auto">
      <TopBanner isLoggedIn={isLoggedIn} KYCStatus={KYCStatus} />
      {/* {KYCStatus && ( */}
      <>
        <DashboardReferal
          isLoggedIn={isLoggedIn}
          onApplyFilterHandler={onApplyFilterHandler}
          referralData={referralData}
          isLoading={isLoading}
          setAscending={setAscending}
          setPage={setPage}
          page={page}
          ascending={ascending}
        />
        {isLoggedIn && (
          <>
            <DetailedEarningsTab onApplyFilterHandler={onApplyFilterHandler} />
          </>
        )}
      </>
      {/* )} */}
    </MainElement>
  );
}

export default NewReferal;
