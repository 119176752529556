import React, { Fragment, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Team from "./Team";
import {
  ComingSoonIllustration
  // WebsiteIllustration,
  // WhitepaperIllustration
} from "../../../../../assets/svgs/illustrations";
import { DivElement, ImgElement, TextWrapper } from "../../../../../components/Reusable";
import styled, { css } from "styled-components";
import { LoadingButton } from "../../../../../components/Loader";
import TokenomicsChart from "../../../../../views/NewTokenSale/Components/TokenomicsChart";
import { useWindowSize } from "../../../../../Hooks/CheckWidthHeight";
import BuyTokenBanner from "../BuyTokenBanner";
import { ButtonComponent } from "../../../../../assets/StyleComponents/Button.style";
import { BannerBox, BannerHeadingSpan } from "../../../../../assets/StyleComponents/Exchange.style";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../../../../constants/Images/imageIndex";
import { useSelector } from "react-redux";
import Roadmap from "../../../../../views/NewTokenSale/Components/Roadmap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ParticipatedBuyTokenBanner from "../../../../../views/NewTokenSale/Components/ParticipatedTokenBanner";
import {
  AnchorTag,
  LiTag,
  ParaTag
} from "../../../../../assets/StyleComponents/FontSize/para.style";

const tabsPC = ["About", "Tokenomics", "Roadmap", "Team", "Legal"];
const tabsTablet = ["Summary", "About", "Tokenomics", "Roadmap", "Team", "Legal"];

const NavLinkComponent = styled(DivElement)`
  color: ${(props) => props.theme.subText};
  padding: 0 16px;
  font-weight: 500;
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  margin: 0 24px;
  cursor: pointer;

  @media (max-width: 1024px) {
    :nth-of-type(1) {
      margin-left: 0px;
    }
  }

  @media (max-width: 767px) {
    margin: 0 5px;
  }

  :hover {
    color: ${(props) => props.theme.primaryBlue};
    border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.primaryBlue};
      border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
    `}
`;

const Player = styled(ReactPlayer)`
  width: 100% !important;
  height: 100% !important;
`;

const SaleDetails = ({ saleDetails, loader }) => {
  const { width } = useWindowSize();
  const [selectedTab, setselectedTab] = useState("");
  const publicData = (saleDetails && saleDetails.publicData) || {};
  const about = (publicData && publicData.about) || {};
  const tokenomics = publicData?.tokenEcono || publicData?.tokenEconomics;
  const roadmap = publicData?.roadmap;
  const history = useHistory();
  const { isTheme } = useSelector((state) => state.theme.isTheme);
  const userData = useSelector((state) => state.theme.userData);
  const team = (publicData && publicData.team) || {};
  const location = useLocation();
  const participatedOrNot = location.pathname.includes("participated");
  const legal_text = publicData && publicData.legal ? publicData.legal.text.split("\n") : [];
  const havingLinks = {
    "– Download PDF ": "downloadLink",
    "- View Online ": "viewOnlineLink"
  };
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    if (width > 1024) {
      setselectedTab("About");
    } else {
      setselectedTab("Summary");
    }
  }, [width]);

  return (
    <DivElement minHeight="458px" CardBgmain={width > 1024 && true}>
      {width <= 1024 && (
        <ButtonComponent
          OutlineOnlyPrimary
          display="flex"
          onClick={() => history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/token-sale`)}
          alignItems="center"
          mb="10px"
        >
          <ImgElement
            src={!isTheme ? IMAGE_PREFIX.BackIconMain : IMAGE_PREFIX_DARK.BackIcondark}
            alt=""
          />
          <BannerHeadingSpan ml="-6px"> Back </BannerHeadingSpan>
        </ButtonComponent>
      )}
      <DivElement
        relative
        height="62px"
        bdGrey
        borderBottom="1px solid"
        CardBgmain={width > 1024 && true}
        marginBottomMd="24px"
        marginBottom={width == 1024 && "24px"}
      >
        <DivElement height="100%" width="100%" flex absolute bottom="-1px" scrollX>
          {width > 1024 &&
            tabsPC.map((tab) => (
              <NavLinkComponent
                key={tab}
                active={selectedTab === tab ? true : null}
                onClick={() => setselectedTab(tab)}
              >
                {tab}
              </NavLinkComponent>
            ))}
          {width <= 1024 &&
            tabsTablet.map((tab) => (
              <NavLinkComponent
                key={tab}
                active={selectedTab === tab ? true : null}
                onClick={() => setselectedTab(tab)}
              >
                {tab}
              </NavLinkComponent>
            ))}
        </DivElement>
      </DivElement>

      {saleDetails ? (
        <DivElement
          padding={width > 1024 && "32px 39px 80px 39px"}
          paddingMd="0px"
          // paddingSm="24px 16px 32px 16px"
          CardBgmain={width > 1024 && true}
        >
          {selectedTab === (width <= 1024 && tabsTablet[0]) && (
            <DivElement width="100%">
              {participatedOrNot ? (
                <ParticipatedBuyTokenBanner saleDetails={saleDetails} loader={loader} />
              ) : (
                <BuyTokenBanner saleDetails={saleDetails} loader={loader} />
              )}
            </DivElement>
          )}
          {selectedTab === ((width > 1024 && tabsPC[0]) || (width <= 1024 && tabsTablet[1])) && (
            <Fragment>
              <DivElement
                // displayCenter
                width="100%"
                height="356px"
                heightMd="290px"
                heightSm="167px"
              >
                {about.youtubeLink ? (
                  <Player url={about.youtubeLink} controls={false} />
                ) : about.imageLink ? (
                  <ImgElement height="inherit" width="auto" alt="about" src={about.imageLink} />
                ) : (
                  <ComingSoonIllustration height="inherit" width="auto" />
                )}
              </DivElement>

              <TextWrapper
                fontSize="16px"
                fontSizeSm="12px"
                fontWeightSm="400"
                fontWeight="500"
                marginTop="38px"
                marginTopMd="32px"
                marginTopSm="25px"
              >
                <ParaTag>{about.summary && about.summary.start} </ParaTag>
                <ul>
                  {about.summary &&
                    about.summary.points &&
                    about.summary.points.map((value, i) => <LiTag key={i}>{value}</LiTag>)}
                </ul>
                <ParaTag>{about.summary && about.summary.end} </ParaTag>
              </TextWrapper>
            </Fragment>
          )}

          {selectedTab === ((width > 1024 && tabsPC[1]) || (width <= 1024 && tabsTablet[2])) && (
            <DivElement width="100%">
              {tokenomics ? (
                <TokenomicsChart
                  values={
                    saleDetails?.publicData?.tokenEconomics || saleDetails?.publicData?.tokenEcono
                  }
                />
              ) : (
                <BannerBox display="flex" justifyContent="center" alignItems="center">
                  <ComingSoonIllustration />
                </BannerBox>
              )}
            </DivElement>
          )}

          {selectedTab === ((width > 1024 && tabsPC[2]) || (width <= 1024 && tabsTablet[3])) && (
            <DivElement width="100%">
              {roadmap ? (
                <Roadmap values={saleDetails?.publicData?.roadmap} />
              ) : (
                <BannerBox display="flex" justifyContent="center" alignItems="center">
                  <ComingSoonIllustration />
                </BannerBox>
              )}
            </DivElement>
          )}

          {selectedTab === ((width > 1024 && tabsPC[3]) || (width <= 1024 && tabsTablet[4])) && (
            <Team name={saleDetails.coin} {...team} />
          )}

          {selectedTab === ((width > 1024 && tabsPC[4]) || (width <= 1024 && tabsTablet[5])) && (
            <DivElement width="100%">
              <TextWrapper fontSize="30px" fontSizeMd="20px" fontSizeSm="18px" fontWeight="500">
                Terms and Conditions
              </TextWrapper>
              <TextWrapper fontSizeSm="12px" marginTop="24px">
                {legal_text.map((value, i) => {
                  return (
                    <ParaTag key={i} margin="10px 0px" sizeMd="12px" size="16px">
                      {havingLinks[value] ? (
                        <AnchorTag
                          href={publicData.legal[havingLinks[value]]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TextWrapper blue fontSize="inherit" fontWeight="inherit">
                            {value}
                          </TextWrapper>
                        </AnchorTag>
                      ) : (
                        value
                      )}
                    </ParaTag>
                  );
                })}
              </TextWrapper>
            </DivElement>
          )}
        </DivElement>
      ) : (
        <DivElement width="100%" minHeight="500px" displayCenter>
          <LoadingButton />
        </DivElement>
      )}
    </DivElement>
  );
};

export default SaleDetails;
