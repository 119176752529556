/**
 * Import Other modules
 */

import apiConstants from "../constants/apiConstants";

export const getMarketTopData = () => ({
  type: apiConstants.GET_MARKET_TOP_DATA
});

export const getMarketLists = (data) => ({
  type: apiConstants.GET_MARKET_LISTS,
  payload: data
});

export const updateSocketMarketData = (data) => ({
  type: apiConstants.GET_MARKET_LISTS,
  payload: data
});

export const updateSocketTickerData = (data) => ({
  type: apiConstants.UPDATE_TICKER_DATA,
  payload: data
});

/**
 * Get Market Ticker Data
 */
export const getMarketTickerData = () => ({
  type: apiConstants.GET_MARKET_TICKER_LISTS
  // payload: data
});

// Update fav pairs watch list
export const updateFavPiarsWatchlist = (data) => ({
  type: apiConstants.UPDATE_FAV_PAIRS_WATCH_LIST,
  payload: data
});
