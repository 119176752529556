import apiConstants from "../constants/apiConstants";

export const getLevelStatus = (data) => ({
  type: apiConstants.GET_LEVEL_STATUS,
  payload: data
});

export const getCountriesList = () => ({
  type: apiConstants.GET_COUNTRIES_LIST
});

export const getKycContent = (data) => ({
  type: apiConstants.GET_KYC_FAQ_CONTENT,
  payload: data
});
