import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import config from "../../constants/config";
import { connect } from "react-redux";
import { forgotPassword } from "../../lib/api";
import { compose } from "redux";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { TextInput } from "../../components/Reusable/FormInput";
import { formatAPIErrors } from "../../utils/general";
import { NonAuthButton } from "../../components/Modules/Authentication";
import { useEffect } from "react";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { trackPage } from "../../tracking/track";
import { DivElement } from "../../components/Reusable";
import { ChevronLeftBlueIcon } from "../../assets/svgs";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { BannerBox } from "../../assets/StyleComponents/Exchange.style";
import { HeadingSix } from "../../assets/StyleComponents/FontSize/HeadingSix.style";
import { RouterLink } from "../../components/Reusable";

const forgotPasswordValidation = Yup.object().shape({
  email: Yup.string()
    .email("Email Address must be a valid email")
    .required("Email Address is required")
});

const getInitialValues = () => {
  return {
    email: ""
  };
};

const ForgetPassword = ({ googleReCaptchaProps, isTheme }) => {
  const [loader, setLoader] = useState(false);
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    trackPage(`${prevLang ? `/${prevLang}` : "/en"}/forgotpassword`, "Forgot Password Page");
  }, []);

  const onSubmit = (values) => {
    const { executeRecaptcha } = googleReCaptchaProps;
    if (!executeRecaptcha) {
      return;
    }

    setLoader(true);

    let data = {
      email: values.email
    };

    if (config.debug) data.debug = true;

    executeRecaptcha("forgotPassword")
      .then((token) => {
        return forgotPassword({ ...data, "g-recaptcha-response": token });
      })
      .then((res) => {
        setLoader(false);
        showSuccessMsg("Please check e-mail for resetting your password");
      })
      .catch((err) => {
        setLoader(false);
        showErrorMsg(formatAPIErrors(err));
      });
  };

  return (
    <DivElement pageContainer display="flex" flexDirection="column" alignItems="center">
      <BannerHeading size="28px" weight="bold" sizeSm="20px">
        Forgot Password
      </BannerHeading>

      <BannerBox LoginFormBg>
        <Formik
          initialValues={getInitialValues()}
          onSubmit={onSubmit}
          validateOnMount={true}
          validationSchema={forgotPasswordValidation}
        >
          {({ values }) => (
            <Form>
              <TextInput
                title="Email Address"
                name="email"
                value={values.email}
                placeholder="satoshi@lcx.com"
              />

              <NonAuthButton loader={loader} label="Reset Password" />

              <BannerBox display="flex" justifyContent="center" mt="24px">
                <HeadingSix size="14px" BodyColor>
                  {"Don't have an account?"}&nbsp;
                </HeadingSix>
                <RouterLink to={`${prevLang ? `/${prevLang}` : ""}/register`} txtPrimary underline fontSize="14px">
                  Register
                </RouterLink>
              </BannerBox>

              <BannerBox display="flex" justifyContent="center" alignItems="center" mt="12px">
                <RouterLink
                  to={`${prevLang ? `/${prevLang}` : ""}/login`}
                  txtPrimary
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="14px"
                >
                  <ChevronLeftBlueIcon />
                  Back to Log in
                </RouterLink>
              </BannerBox>
            </Form>
          )}
        </Formik>
      </BannerBox>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme
});

export default compose(
  withGoogleReCaptcha,

  withRouter,
  connect(mapStateToProps)
)(ForgetPassword);
