import React from "react";
import Select, { components } from "react-select";
import { IMAGE_PREFIX } from "../../constants/Images/image";
import configs from "../../constants/config";
import { IMAGE_PREFIX_DARK } from "../../constants/Images/imageDark";
import { numberWithCommas } from "../../utils/general";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import { formatNumbers } from "../../utils/helpers";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { ImgElement } from "../Reusable";
import { WireTransferIcon } from "../../assets/svgs";

const customStylesContinents = {
  control: (base, { data, isDisabled, isFocused, isSelected }) => ({
    ...base,
    border: "0 !important",
    borderBottom: !JSON.parse(localStorage.getItem("darkTheme"))
      ? `${isFocused ? "1px solid #0470e1!important" : "1px solid #8c9797!important"}`
      : `${isFocused ? "1px solid #70A6E0!important" : "1px solid #969799!important"}`,
    "margin-top": "6px",
    "font-size": "0.875rem",
    "padding-bottom": "4px",
    "padding-top": "1px",
    "padding-left": "10px",
    "min-height": "45px",
    "max-height": "46px",
    display: "flex",
    width: "100%",
    boxShadow: "none !important",
    background: !JSON.parse(localStorage.getItem("darkTheme"))
      ? `${isFocused ? "#cde2f9" : "#f5f6fa"}`
      : `${isFocused ? "#1f2c38" : "#1f2c38"}`,
    borderRadius: "5px 5px 0 0",
    "@media (max-width: 767px)": {
      "min-height": "40px",
      "max-height": "40px"
    },
    "@media (max-width: 500px)": {
      "padding-left": "0px"
    },
    "&:hover": {
      borderColor: !JSON.parse(localStorage.getItem("darkTheme")) ? "#f5f6fa" : "#0470e1"
    },
    "&:focus": {
      transition: "0.4 ease",
      background: !JSON.parse(localStorage.getItem("darkTheme")) ? "#f5f6fa" : "#a9a9a9 ",
      borderBottom: !JSON.parse(localStorage.getItem("darkTheme"))
        ? `${isFocused ? "1px solid #090e1b!important" : "1px solid #ccced0!important"}`
        : `${isFocused ? "1px solid #0470e1!important" : "1px solid #090e1b!important"}`
      // boxShadow: "0px 0px 0.3rem #02b3e4 !important",
    }
  }),
  placeholder: (base, { isFocused }) => ({
    ...base,
    color: !JSON.parse(localStorage.getItem("darkTheme"))
      ? `${isFocused ? "#012243" : "#012243"}`
      : `${isFocused ? "#DEDFE0" : "#DEDFE0"}`,
    "font-size": "16px",
    "font-weight": "300"
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: "90%",
    paddingTop: "0",
    paddingBottom: "0",
    "min-height": "45px",
    "max-height": "46px",
    overflow: "hidden",
    "@media (max-width: 767px)": {
      "min-height": "40px",
      "max-height": "40px"
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: "10%",
    minHeight: "1px",
    background: "transparent !important"
  }),
  input: () => ({
    color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#012243" : "#f5f6fa"
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingRight: "20px !important"
  }),
  menu: (base) => ({
    ...base,
    "z-index": "999",
    background: !JSON.parse(localStorage.getItem("darkTheme")) ? "#fff" : "#1f2c38",
    "@media (max-width: 767px)": {
      "min-width": "220px"
    }
  }),
  singleValue: () => ({
    color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#012243" : "#f5f6fa",
    "min-height": "45px",
    "max-height": "46px",
    // "margin-top": "10px",
    overflow: "hidden",
    "@media (max-width: 767px)": {
      "min-height": "40px",
      "max-height": "40px"
    }
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    width: "96%",
    "min-height": "48px",
    margin: "0 8px",
    "padding-bottom": "12px",
    background: !JSON.parse(localStorage.getItem("darkTheme")) ? "#fff" : "#1f2c38",
    color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#101726" : "#DEDFE0",
    "&:hover": {
      background: !JSON.parse(localStorage.getItem("darkTheme"))
        ? "#f5f6fa"
        : "rgba(17, 17, 17, 0.21)",
      border: !JSON.parse(localStorage.getItem("darkTheme"))
        ? "1px solid #8c9797"
        : "1px solid #8c9797",
      color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#101726" : "#DEDFE0"
    }
  })
};

export const customStylesContinentsForOrders = {
  control: (base, { data, isDisabled, isFocused, isSelected }) => ({
    ...base,
    border: "0 !important",
    borderBottom: !JSON.parse(localStorage.getItem("darkTheme"))
      ? `${isFocused ? "1px solid #0470e1!important" : "1px solid #8c9797!important"}`
      : `${isFocused ? "1px solid #70A6E0!important" : "1px solid #969799!important"}`,
    "margin-top": "6px",
    "font-size": "0.875rem",
    "padding-bottom": "4px",
    "padding-top": "1px",
    "padding-left": "10px",
    "min-height": "45px",
    "max-height": "46px",
    "font-weight": 300,
    display: "flex",
    width: "100%",
    boxShadow: "none !important",
    fontWeight: 300,
    background: !JSON.parse(localStorage.getItem("darkTheme"))
      ? `${isFocused ? "#cde2f9" : "#f5f6fa"}`
      : `${isFocused ? "#1f2c38" : "#1f2c38"}`,
    borderRadius: "5px 5px 0 0",
    "@media (max-width: 767px)": {
      "min-height": "40px",
      "max-height": "40px"
    },
    "@media (max-width: 500px)": {
      "padding-left": "0px"
    },
    "&:hover": {
      borderColor: !JSON.parse(localStorage.getItem("darkTheme")) ? "#f5f6fa" : "#0470e1"
    },
    "&:focus": {
      transition: "0.4 ease",
      background: !JSON.parse(localStorage.getItem("darkTheme")) ? "#f5f6fa" : "#a9a9a9 ",
      borderBottom: !JSON.parse(localStorage.getItem("darkTheme"))
        ? `${isFocused ? "1px solid #090e1b!important" : "1px solid #ccced0!important"}`
        : `${isFocused ? "1px solid #0470e1!important" : "1px solid #090e1b!important"}`
      // boxShadow: "0px 0px 0.3rem #02b3e4 !important",
    }
  }),
  placeholder: (base, { isFocused }) => ({
    ...base,
    color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#606F80" : "#a7a9ab",
    "font-size": "16px",
    "font-weight": "300",
    "@media (max-width: 568px)": {
      "font-size": "12px"
    },
    "@media (max-width: 767px)": {
      "font-size": "12px"
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: "90%",
    paddingTop: "0",
    paddingBottom: "0",
    "@media (max-width: 500px)": {
      position: "relative!important",
      fontSize: "10px",
      width: "95%"
    }
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    "@media (max-width: 767px)": {
      height: "40px"
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: "10%",
    minHeight: "1px",
    background: "transparent !important",
    "@media (max-width: 767px)": {
      height: "40px"
    }
  }),
  input: () => ({
    color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#012243" : "#f5f6fa"
    // fontWeight:"500 !important"
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingRight: "20px !important",
    "@media (max-width: 500px)": {
      padding: "0px 5px !important"
    }
  }),
  menu: (base) => ({
    ...base,
    "z-index": "999",
    background: !JSON.parse(localStorage.getItem("darkTheme")) ? "#fff" : "#1f2c38",
    "@media (max-width: 767px)": {
      "min-width": "220px"
    }
  }),
  singleValue: () => ({
    color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#012243" : "#f5f6fa",
    "@media (max-width: 500px)": {
      position: "relative!important",
      top: "0px!important"
    }
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    width: "96%",
    height: "48px",
    margin: "0 8px",
    background: !JSON.parse(localStorage.getItem("darkTheme")) ? "#fff" : "#1f2c38",
    color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#101726" : "#DEDFE0",
    "&:hover": {
      background: !JSON.parse(localStorage.getItem("darkTheme"))
        ? "#f5f6fa"
        : "rgba(17, 17, 17, 0.21)",
      border: !JSON.parse(localStorage.getItem("darkTheme"))
        ? "1px solid #8c9797"
        : "1px solid #8c9797",
      color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#101726" : "#DEDFE0"
    }
  })
};
// ++++++++++++++++++++++++++
export const customStylesContinentsForWallets = {
  control: (base, { data, isDisabled, isFocused, isSelected }) => ({
    ...base,
    border: "0 !important",
    borderBottom: !JSON.parse(localStorage.getItem("darkTheme"))
      ? `${isFocused ? "1px solid #0470e1!important" : "1px solid #8c9797!important"}`
      : `${isFocused ? "1px solid #70A6E0!important" : "1px solid #969799!important"}`,
    "margin-top": "6px",
    "font-size": "16px",
    "padding-bottom": "4px",
    "padding-top": "1px",
    "padding-left": "10px",
    "min-height": "45px",
    "max-height": "46px",
    "font-weight": 500,
    display: "flex",
    width: "100%",
    boxShadow: "none !important",
    fontWeight: 500,
    background: !JSON.parse(localStorage.getItem("darkTheme"))
      ? `${isFocused ? "#cde2f9" : "#f5f6fa"}`
      : `${isFocused ? "#1f2c38" : "#1f2c38"}`,
    borderRadius: "5px 5px 0 0",
    "@media (max-width: 767px)": {
      "min-height": "40px",
      "font-size": "12px !important",
      "max-height": "40px"
    },
    "@media (max-width: 1024px)": {
      "min-height": "40px",
      "font-size": "16px !important",
      "max-height": "40px"
    },
    "@media (max-width: 500px)": {
      "padding-left": "0px"
    },
    "&:hover": {
      borderColor: !JSON.parse(localStorage.getItem("darkTheme")) ? "#f5f6fa" : "#0470e1"
    },
    "&:focus": {
      transition: "0.4 ease",
      background: !JSON.parse(localStorage.getItem("darkTheme")) ? "#f5f6fa" : "#a9a9a9 ",
      borderBottom: !JSON.parse(localStorage.getItem("darkTheme"))
        ? `${isFocused ? "1px solid #090e1b!important" : "1px solid #ccced0!important"}`
        : `${isFocused ? "1px solid #0470e1!important" : "1px solid #090e1b!important"}`
      // boxShadow: "0px 0px 0.3rem #02b3e4 !important",
    }
  }),
  placeholder: (base, { isFocused }) => ({
    ...base,
    color: !JSON.parse(localStorage.getItem("darkTheme"))
      ? `${isFocused ? "#012243" : "#012243"}`
      : `${isFocused ? "#DEDFE0" : "#DEDFE0"}`,
    "font-size": "14px",
    "font-weight": "300",
    "@media (max-width: 568px)": {
      "font-size": "10px"
    },
    "@media (max-width: 767px)": {
      "font-size": "10px"
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: "90%",
    paddingTop: "0",
    paddingBottom: "0",
    overflow: "visible",
    paddingLeft: "0px",
    "@media (max-width: 500px)": {
      position: "relative!important",
      fontSize: "10px",
      width: "95%",
      paddingLeft: "7px"
    }
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    "@media (max-width: 767px)": {
      height: "40px"
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: "10%",
    minHeight: "1px",
    background: "transparent !important",
    "@media (max-width: 767px)": {
      height: "40px"
    }
  }),
  input: () => ({
    position: "absolute",
    color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#012243" : "#f5f6fa",
    fontWeight: "500 !important"
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingLeft: "5px !important",
    paddingRight: "5px !important",
    "@media (max-width: 500px)": {
      padding: "0px 5px !important"
    }
  }),
  menu: (base) => ({
    ...base,
    "z-index": "999",
    background: !JSON.parse(localStorage.getItem("darkTheme")) ? "#fff" : "#1f2c38",
    "@media (max-width: 767px)": {
      "min-width": "120px",
      "font-size": "10px"
    }
  }),
  singleValue: () => ({
    color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#012243" : "#f5f6fa",
    "@media (max-width: 500px)": {
      position: "relative!important",
      top: "0px!important"
    }
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    width: "88%",
    height: "48px",
    margin: "0 8px",
    background: !JSON.parse(localStorage.getItem("darkTheme")) ? "#fff" : "#1f2c38",
    color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#101726" : "#DEDFE0",
    "&:hover": {
      background: !JSON.parse(localStorage.getItem("darkTheme"))
        ? "#f5f6fa"
        : "rgba(17, 17, 17, 0.21)",
      border: !JSON.parse(localStorage.getItem("darkTheme"))
        ? "1px solid #8c9797"
        : "1px solid #8c9797",
      color: !JSON.parse(localStorage.getItem("darkTheme")) ? "#101726" : "#DEDFE0"
    },
    "@media (max-width: 500px)": {
      margin: "0px !important"
    }
  })
};

export const CustomOptionsForCurrency = ({ label }) => {
  let isTheme = JSON.parse(localStorage.getItem("darkTheme"));
  let width = window.innerWidth;
  if (typeof label === "object") return null;
  return (
    <BannerBox key={label} display="flex" alignItems="center" height="44px" heightLg="40px">
      {width > 767 && label !== "All Assets" && (
        <ImgElement
          alt="coin"
          marginRight="8px"
          src={`${
            !isTheme ? configs.lightCoinUrl : configs.darkCoinUrl
          }${label?.toLowerCase()}.svg`}
          style={{ verticalAlign: "middle" }}
          width="20px"
        />
      )}
      <BannerHeadingSpan size="16px" sizeLg="14px" sizeMd="12px">
        {label}
      </BannerHeadingSpan>
    </BannerBox>
  );
};

export const CustomOptionsForBlockPit = ({ label , id }) => {
  let width = window.innerWidth;
  if (typeof label === "object") return null;
  return (
    <BannerBox key={label} display="flex" alignItems="center" height="44px" heightLg="40px">
      {width > 767 && label !== "All Assets" && (
        <ImgElement
          alt="coin"
          marginRight="8px"
          src={id?.id?.Url}
          style={{ verticalAlign: "middle" }}
          width="20px"
        />
      )}
      <BannerHeadingSpan size="16px" sizeLg="14px" sizeMd="12px">
        {label}
      </BannerHeadingSpan>
    </BannerBox>
  );
};

export const CustomOptionsForWallets = ({ label }) => {
  // let isTheme = JSON.parse(localStorage.getItem("darkTheme"));
  // let width = window.innerWidth;
  const [label1, label2] = label.split("(");
  return (
    <BannerBox
      display="flex"
      alignItems="center"
      height="44px"
      heightLg="40px"
      padding="0px"
      key={label}
    >
      <BannerHeading display="flex" alignItems="center" size="16px" sizeMd="10px">
        {label1}
        <BannerHeading size="11px" ml="3px" sizeLg="10px" sizeMd="7px">
          {label2?.length > 1 && "("}
          {label2}
        </BannerHeading>
      </BannerHeading>
    </BannerBox>
  );
};

export const CustomOptionsForNetwork = ({ label }) => {
  let isTheme = JSON.parse(localStorage.getItem("darkTheme"));
  return (
    <BannerBox display="flex" alignItems="center" height="44px" heightLg="40px" key={label}>
      <BannerHeadingSpan display="flex" alignItems="center">
        <ImgElement
          alt="coin"
          marginRight="8px"
          src={`${!isTheme ? configs.lightNetworkUrl : configs.darkNetworkUrl}${label}.png`}
          style={{ verticalAlign: "middle", objectFit: "contain" }}
          width="20px"
          height="20px"
        />
      </BannerHeadingSpan>
      <BannerHeadingSpan size="16px" sizeLg="14px" sizeMd="12px">
        {" "}
        {label}{" "}
      </BannerHeadingSpan>
    </BannerBox>
  );
};
export const CustomOptionsForProvider = ({ label }) => {
  return (
    <BannerBox display="flex" alignItems="center" height="44px" heightLg="40px" key={label}>
      <ImgElement
        alt="coin"
        marginRight="8px"
        marginTop="8px"
        src={IMAGE_PREFIX.MoneriumBankLogo}
        style={{ verticalAlign: "middle", objectFit: "contain" }}
        width="20px"
        height="24px"
      />
      <BannerHeadingSpan size="16px" sizeLg="14px" sizeMd="10px">
        {" "}
        {label}{" "}
      </BannerHeadingSpan>
    </BannerBox>
  );
};

export const CustomOptionsForBank = ({ label }) => {
  let isTheme = JSON.parse(localStorage.getItem("darkTheme"));
  let checkLength = label && label.length > 24 ? true : false;
  return (
    <BannerBox display="flex" alignItems="center" height="44px" heightLg="40px" key={label}>
      <ImgElement
        alt="coin"
        marginRight="8px"
        src={!isTheme ? IMAGE_PREFIX.SignalLogoIcon : IMAGE_PREFIX_DARK.SignalIconDark}
        style={{ verticalAlign: "middle", objectFit: "contain" }}
        width="20px"
        height="20px"
      />
      <BannerHeadingSpan
        display="flex"
        size="16px"
        sizeLg="14px"
        sizeMd="12px"
        alignItems={`${checkLength ? "flex-start" : "center"}`}
      >
        {" "}
        {label}{" "}
      </BannerHeadingSpan>
    </BannerBox>
  );
};

export const DropdownIndicator = (props) => {
  let isTheme = JSON.parse(localStorage.getItem("darkTheme"));
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props} style={{ border: "0" }}>
        {props.selectProps.menuIsOpen ? (
          <ImgElement
            alt="DropDown"
            src={!isTheme ? IMAGE_PREFIX.ChevronIcon : IMAGE_PREFIX_DARK.ChevronDownIconDark}
            widthSm="15px"
            style={{ transform: "rotate(180deg)" }}
          />
        ) : (
          <ImgElement
            src={!isTheme ? IMAGE_PREFIX.ChevronIcon : IMAGE_PREFIX_DARK.ChevronDownIconDark}
            alt="DropDown"
            widthSm="15px"
            // style={{ padding: "7px" }}
          />
        )}
      </components.DropdownIndicator>
    )
  );
};

export const DobDropdownIndicator = (props) => {
  let isTheme = JSON.parse(localStorage.getItem("darkTheme"));
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props} style={{ border: "0" }}>
        {props.selectProps.menuIsOpen ? (
          <ImgElement
            alt="DropDown"
            src={!isTheme ? IMAGE_PREFIX.ChevronIcon : IMAGE_PREFIX_DARK.ChevronDownIconDark}
            width="15px"
            style={{ transform: "rotate(180deg)" }}
          />
        ) : (
          <ImgElement
            src={!isTheme ? IMAGE_PREFIX.ChevronIcon : IMAGE_PREFIX_DARK.ChevronDownIconDark}
            alt="DropDown"
            width="15px"
            // style={{ padding: "7px" }}
          />
        )}
      </components.DropdownIndicator>
    )
  );
};

export const MyOptions = (props) => {
  const { innerProps, innerRef, data, isNetwork, selectedCoin = false } = props;
  let balance = props.showBalance ? props.showBalance : false;
  const label = String(data?.label).toLowerCase();
  const isMonerium = label === "monerium";
  let isTheme = JSON.parse(localStorage.getItem("darkTheme"));
  let imgSrc = `${!isTheme ? configs.lightCoinUrl : configs.darkCoinUrl}${label}.svg`;
  if (isMonerium) {
    imgSrc = IMAGE_PREFIX.MoneriumBankLogo;
  } else if (isNetwork) {
    imgSrc = `${!isTheme ? configs.lightNetworkUrl : configs.darkNetworkUrl}${data.label}.png`;
  }
  return (
    <BannerBox
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      margin="0px 8px"
      className="optionHover"
      DropdownCardHoverEffect
      OptionHover
      padding="5px 10px"
      ref={innerRef}
      {...innerProps}
    >
      <BannerBox display="flex" alignItems="center" width="100%" gap="8px">
        {data.label === "Wire Transfer" ? (
          <WireTransferIcon width="20px" height="20px" marginTop="1px" />
        ) : (
          <ImgElement
            alt="coin"
            className=""
            src={imgSrc}
            marginTop="1px"
            style={{ verticalAlign: "middle" }}
            width="20px"
            height="20px"
            widthSm="15px"
            heightSm="15px"
          />
        )}
        <BannerHeading
          weight={selectedCoin && "500"}
          txtGrey
          size="16px"
          sizeLg="14px"
          sizeMd="12px"
        >
          {data.label}
        </BannerHeading>
      </BannerBox>

      <div>
        <BannerHeading size="16px" txtGrey sizeLg="14px" sizeMd="12px">
          {" "}
          <var>
            {!isNetwork &&
              !balance &&
              numberWithCommas(formatNumbers(data.id?.balance?.freeBalance || 0))}{" "}
          </var>
        </BannerHeading>
      </div>
    </BannerBox>
  );
};

const SelectDropDownComponent = (props) => {
  let {
    optionList,
    value,
    name,
    placeholder,
    handlePairName,
    chooseLabel,
    defaultValue,
    dataComponent,
    givenClass,
    getStyle,
    styles,
    useStyles
  } = props;

  const customFilter = (option, searchText) => {
    let fullName = option?.data?.id?.fullName?.toLowerCase();
    let value = option?.data?.value?.toLowerCase();
    let label = option?.data?.label?.toLowerCase();
    searchText = searchText?.toLowerCase();
    if (
      label?.includes(searchText) ||
      value?.includes(searchText) ||
      fullName?.includes(searchText)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Select
      components={dataComponent}
      menuPlacement="bottom"
      className={`${givenClass}`}
      // menuIsOpen={true}
      name={name}
      isSearchable={true}
      placeholder={placeholder}
      formatOptionLabel={chooseLabel}
      onChange={(value) => {
        handlePairName(value.id);
      }}
      value={value}
      defaultValue={defaultValue}
      styles={useStyles ? styles : getStyle ? getStyle : customStylesContinents}
      options={optionList}
      filterOption={customFilter}
    />
  );
};

export default SelectDropDownComponent;
