/* eslint-disable no-unused-vars */
import React, { lazy, Suspense, useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { getWalletBalance, getAllWallets } from "../../../lib/api";
import { getMarketLists } from "../../../actions/marketLists";
import { sortNumberData } from "../../../utils/general";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import { WalletContext } from "../../../contexts/WalletPage/BalancePage/wallet";
import BalancePageLoader from "../WalletLoaders/BalanceLoader";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { Error500Illustration } from "../../../assets/svgs/illustrations";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Fragment } from "react";
const ChooseWalletComponent = lazy(() => import("./webPage/chooseWallet"));
const BalanceTableComponent = lazy(() => import("./Component/Table/balanceTable"));

const BalancePageComponent = (props) => {
  const [isLoaderWallet, setisLoaderWallet] = useState(false);
  const [isLoaderExch, setisLoaderExch] = useState(false);
  const history = useHistory();
  const location = useLocation();

  let {
    exchWallet,
    mainBalance,
    updateExchWallet,
    updateMainWallet,
    setActiveWallet,
    updateExchWalletCopy,
    updateMainWalletCopy,
    updateMainWalletError,
    exchWalletError,
    mainWalletError
  } = useContext(WalletContext);

  useEffect(() => {
    const activeWallet = location?.state?.state;
    if (activeWallet) {
      setActiveWallet(activeWallet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // setisLoaderExch(true);
    // setisLoaderWallet(true);
    // getAllWallets()
    //   .then((res) => {
    //     props.getMarketLists();
    //     updateExchWallet(sortNumberData(res.data, true));
    //     updateExchWalletCopy(sortNumberData(res.data, true));
    //     setisLoaderWallet(false);
    //     updateExchWalletError(false);
    //   })
    //   .catch((err) => {
    //     setisLoaderWallet(false);
    //     updateExchWalletError(true);
    //     setActiveWallet("exchange");
    //   });

    getWalletBalance()
      .then((res) => {
        updateMainWallet(sortNumberData(res.data, true));
        updateMainWalletCopy(sortNumberData(res.data, true));
        props.updatePrivateSocketBalance({
          walletBalance: res.data,
          walletBalanceCopy: res.data,
          wallet: true
        });
        setisLoaderExch(false);
        updateMainWalletError(false);
        props.getMarketLists();
      })
      .catch((err) => {
        setisLoaderExch(false);
        updateMainWalletError(true);
        setActiveWallet("account");
      });

    if (!isLoaderExch && !isLoaderWallet) {
      if (history.location.state?.state) {
        let walletStatus = history.location.state?.state;
        setActiveWallet(walletStatus);
      } else setActiveWallet("account");
    }

    return () => {
      setActiveWallet("");
      updateExchWallet("");
      updateExchWalletCopy("");
      updateMainWallet("");
      updateMainWalletCopy("");
      const state = { ...history?.location?.state };
      delete state.deleted;
      history.replace({ ...history?.state, state });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {isLoaderExch || isLoaderWallet ? (
        <BalancePageLoader />
      ) : exchWalletError && mainWalletError ? (
        <BannerSection
          display="flex"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <BannerBox margin="0 auto">
            <Error500Illustration margin="0 auto" />
          </BannerBox>
          <BannerHeading mt={"24px"} size={"32px"} textAlign={"center"}>
            Site Under Maintenance
          </BannerHeading>
        </BannerSection>
      ) : (
        <Suspense fallback={<BalancePageLoader />}>
          <BannerSection>
            {((exchWallet && exchWallet.totalBalance) ||
              (mainBalance && mainBalance.totalBalance)) && <ChooseWalletComponent {...props} />}

            {((exchWallet && exchWallet.totalBalance) ||
              (mainBalance && mainBalance.totalBalance)) && <BalanceTableComponent {...props} />}
          </BannerSection>
        </Suspense>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMarketLists: (user) => dispatch(getMarketLists(user))
});

export default connect(null, mapDispatchToProps)(withRouter(BalancePageComponent));
