import styled, { css } from "styled-components";
import { lighten, darken } from "polished";
import { MarginStyle } from "./Reusable/Margin.style";
import { PaddingStyle } from "./Reusable/Padding.style";
import { CommanHeightStyle } from "./Reusable/Height.style";

const ButtonComponent = styled.button`
  cursor: ${(props) => props.cursor || "pointer"};
  width: ${(props) => props.width || "max-content"};
  height: ${(props) => props.btnHeight || "46px"};
  display: ${(props) => props.display};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  gap: ${(props) => props.gap};
  background-color: ${(props) => props.bck || props.theme.primaryButton};
  color: ${(props) => props.color || "#fff"};
  margin: ${(props) => props.givenMarign || 0};
  font-size: ${(props) => props.size || "16px"};
  font-weight: ${(props) => props.weight || "500"};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.radius || 5}px;
  box-shadow: ${(props) => props.shadow || "initial"};
  display: ${(props) => props.display};
  opacity: ${(props) => props.opacity};
  outline: none;

  ${CommanHeightStyle}
  ${MarginStyle}
  ${PaddingStyle}

  ${(props) => props.weight && `font-weight: ${props.weight}`};
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius}`};
  ${(props) => props.position && `position: ${props.position}`};
  ${(props) => props.padd && `padding: ${props.padd}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth} `};
  ${(props) => props.minWidth && `min-width: ${props.minWidth} `};
  ${(props) => props.borderImg && `border-image: ${props.borderImg}`};
  ${(props) => props.bckImage && `background-image: ${props.theme.buttonGradient}`};
  ${(props) => props.bckImg && `background-image: ${props.bckImg}`};
  ${(props) => props.bckOrigin && `background-origin: ${props.bckOrigin}`};
  ${(props) => props.bckClip && `background-clip: ${props.bckClip}`};
  ${(props) => props.maxHeight && `max-height: ${props.maxHeight} `};
  ${(props) => props.minHeight && `min-height: ${props.minHeight} `};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.justify && `justify-content: ${props.justify}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace}`};
  ${(props) => props.gap && `gap: ${props.gap}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${({ borderRight, theme }) => borderRight && `border-right: ${ theme.commonBorder }`};

  @media (max-width: 1024px) {
    ${(props) => props.widthLg && `width: ${props.widthLg} `};
    ${(props) => props.minWidthLg && `min-width: ${props.minWidthLg} `};
    ${(props) => props.displayLg && `display: ${props.displayLg}`};
    ${(props) => props.btnHeightLg && `height: ${props.btnHeightLg}`};
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg} `};
    ${(props) => props.givenMargnLg && `margin: ${props.givenMargnLg} `};
    ${(props) => props.paddingLg && `padding: ${props.paddingLg} `};
  }
  @media (max-width: 767px) {
    ${(props) => props.widthMd && `width: ${props.widthMd} `};
    ${(props) => props.minWidthMd && `min-width: ${props.minWidthMd} `};
    ${(props) => props.displayMd && `display: ${props.displayMd}`};
    ${(props) => props.btnHeightMd && `height: ${props.btnHeightMd}`};
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd} `};
    ${(props) => props.givenMargnMd && `margin: ${props.givenMargnMd} `};
    ${(props) => props.paddingMd && `padding: ${props.paddingMd} `};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd} `};
  }

  ${(props) =>
    props.NoDesign &&
    css`
      text-decoration: none !important;
      background: transparent !important;
      border: 0 !important;
      outline: none !important;
    `}
  ${(props) =>
    props.QRCODEBTN &&
    css`
      background: ${(props) => props.theme.level3CardTC} !important;
    `}

  ${(props) =>
    props.PlusNoDesign &&
    css`
      text-decoration: none !important;
      background: transparent !important;
      border: 1px solid ${(props) => props.theme.tabBox}!important;
      outline: none !important;
    `}

  ${(props) =>
    props.NoUnderline &&
    css`
      text-decoration: none;
    `}

  ${(props) =>
    props.OutlineOnlyPrimary &&
    css`
      background-color: transparent;
      border: 0;
      color: ${(props) => props.theme.primary};
      box-shadow: none;
      &:hover {
        color: ${(props) => props.theme.primary};
      }
    `};

  ${(props) =>
    props.OutlineOnlyPrimary2 &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
      box-shadow: none;
      &:hover {
        color: ${(props) => props.theme.primary};
      }
    `};

  ${(props) =>
    props.OutlineOnlySuccess &&
    css`
      background-color: transparent;
      border: 0;
      color: ${(props) => props.theme.success};
      &:hover {
        color: ${(props) => props.theme.success};
      }
    `};
  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.theme.subText};
    `};

  ${(props) =>
    props.OutlineOnlyDanger &&
    css`
      background-color: transparent;
      border: 0;
      color: ${(props) => props.theme.danger};
      &:hover {
        text-decoration: underline;
        color: ${(props) => props.theme.danger};
      }
    `};

  ${(props) =>
    props.OutlineOnlyDangerWithoutHover &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.danger};
      color: ${(props) => props.theme.danger};
    `};

  ${(props) =>
    props.OutlinePrimary &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
      &:hover {
        background-color: ${props.theme.primary};
        // border: 0 ;
        color: ${props.theme.mainBackground};
      }
    `};
  ${(props) =>
    props.OutlinePrimaryWithoutHover &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
    `};

  ${(props) =>
    props.OutlineWarningWithoutHover &&
    css`
      background-color: transparent;
      border: 1px solid #fcbe37;
      color: #fcbe37;
    `};
  ${(props) =>
    props.OutlinePrimaryWithTransationHover &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
      position: relative;
      overflow: hidden;
      z-index: 1;

      &:hover {
        color: white;
        transition: ease-in-out 0.8s;
      }

      &::after {
        content: "";
        background: transparent;
        position: absolute;
        z-index: -1;
        display: block;
        top: 0;
        bottom: 0;
        left: -100%;
        right: 100%;
        transition: all 1s;
      }

      &:hover::after {
        left: 0;
        right: 0;
        background: ${(props) => props.theme.primary};
        top: 0;
        bottom: 0;
        transition: all 1s;
      }
    `};

  ${(props) =>
    props.OutlineLightPrimary &&
    css`
      background-color: ${props.theme.level3CardTC};
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
      &:hover {
        background-color: ${props.theme.level3CardGrey};
        color: ${props.theme.primary};
      }
    `};
  ${(props) =>
    props.OutlineLightPrimary2 &&
    css`
      background-color: ${props.theme.level3CardTC};
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
      &:hover {
        background-color: ${(props) => props.theme.level3CardGrey};
        color: ${props.theme.primary};
      }
    `};
  ${(props) =>
    props.TokenSaleBannerbuttons &&
    css`
      background-color: ${props.theme.level3CardTC} !important;
      // border: 1px solid ${(props) => props.theme.primary} !important;
      color: ${(props) => props.theme.primary} !important;
      text-decoration: underline;
    `};

  ${({ CloseIconWithBackground, theme }) =>
    CloseIconWithBackground &&
    css`
      background-color: ${theme.dangerNotification} !important;
      color: ${theme.primaryRed} !important;
      text-decoration: underline;
  `};


  ${(props) =>
    props.OutlineLightDefaultPrimary &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.borderColor};
      color: ${(props) => props.theme.subText};
      &:hover {
        background-color: ${lighten(0.5, props.theme.primary)};
        border: 1px solid ${props.theme.primary};
        color: ${props.theme.primary};
      }
    `};

  ${(props) =>
    props.OutlineSuccess &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.success};
      color: ${(props) => props.theme.success};
      &:hover {
        background-color: ${(props) => props.theme.success};
        border: 0;
        color: #fff;
      }
    `};

  ${(props) =>
    props.OutlineDanger &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.danger};
      color: ${(props) => props.theme.danger};
      &:hover {
        background-color: ${(props) => props.theme.danger};
        border: 0;
        color: #fff;
      }
    `};

  ${(props) =>
    props.PrimaryButton &&
    css`
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.mainBackground};
    `};
  ${(props) =>
    props.ParticipateBtn &&
    css`
      background-color: ${({ theme }) => theme.primaryButton};
      color: ${({ theme }) => theme.mainBackground};
    `};
  ${(props) =>
    props.HoverScreen &&
    css`
      display: none;
      &:hover {
        diaplay: block;
      }
    `};

  ${(props) =>
    props.DangerButton &&
    css`
      background-color: ${({ theme }) => theme.danger};
      color: ${({ theme }) => theme.mainBackground};
    `};
  ${(props) =>
    props.DangerButtonFees &&
    css`
      border: 1px solid ${({ theme }) => theme.danger};
      color: ${({ theme }) => theme.danger};
      background: transparent;
    `};

  ${(props) =>
    props.DiabledDangerButton &&
    css`
      background-color: ${({ theme }) => darken(0.1, theme.danger)};
      color: ${({ theme }) => darken(0.1, theme.mainBackground)};
    `};

  ${(props) =>
    props.SuccessButton &&
    css`
      background-color: ${({ theme }) => theme.success};
      color: ${({ theme }) => theme.mainBackground};
    `};

  ${(props) =>
    props.LightSuccessButton &&
    css`
      background-color: ${({ theme }) => theme.successLight};
      /* color: ${({ theme }) => theme.mainBackground}; */
      color: ${({ theme }) => theme.primaryGreen};
    `};

  ${(props) =>
    props.ButtonHollow &&
    css`
      font-weight: 500;
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.borderColor};
      color: ${({ theme }) => theme.subText};
    `};

  ${(props) =>
    props.DisabledSuccessButton &&
    css`
      background-color: ${({ theme }) => darken(0.1, theme.success)};
      color: ${({ theme }) => darken(0.1, theme.mainBackground)};
    `};

  ${(props) =>
    props.DisabledOutlineSuccessButton &&
    css`
      cursor: not-allowed;
      border: ${({ theme }) => `1px solid ${theme.successNotificationText}`};
      background-color: transparent;
      color: ${({ theme }) => theme.successNotificationText};
    `};

  ${(props) =>
    props.DisabledBTN &&
    css`
      background-color: #ccced0;
      color: #606f80;
      cursor: no-drop;

      &:disable {
        cursor: no-drop;
      }
    `};
  ${(props) =>
    props.DisabledBTNWhiteText &&
    css`
      background-color: ${(props) => props.theme.borderColor};
      color: ${(props) => props.theme.level1Card};
      cursor: no-drop;

      &:disable {
        cursor: no-drop;
      }
    `};
  ${(props) =>
    props.DisabledBTNDark &&
    css`
      color: #a7a9ab;
      background-color: #38393d;
      cursor: not-drop;

      &:disable {
        cursor: not-drop;
      }
    `};
  ${(props) =>
    props.DisabledCancelBtn &&
    css`
      color: #a7a9ab;
      background-color: transparent;
      border: 1px solid #a7a9ab;
    `};
  ${(props) =>
    props.DisabledCancelBtn2 &&
    css`
      color: ${({ theme }) => theme.tableHeaderColor};
      background-color: transparent;
      border: 1px solid #a7a9ab;
    `};
  ${(props) =>
    props.DisabledHomepage &&
    css`
      color: ${({ theme }) => theme.tableHeaderColor};
      background-color: transparent;
      border: ${({ theme }) => theme.borderColor};
      cursor: no-drop;
    `};
  ${(props) =>
    props.TradingButton &&
    css`
      background-color: ${({ theme }) => theme.toatSuccess};
      color: ${({ theme }) => theme.bodyText3};
    `};
  ${(props) =>
    props.DisabledButton &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.inputBorder};
      border: 1px solid #8c9797;
      cursor: no-drop;
    `};

  ${({ OutlineOnlyDisabled, theme }) =>
    OutlineOnlyDisabled &&
    css`
      background-color: transparent;
      color: ${theme.inputBorder};
      border: ${theme.commonBorder};
      cursor: no-drop;
    `};


  ${(props) =>
    props.TCClaim &&
    css`
      background-color: ${({ theme }) => theme.AccorianBg} !important;
      color: ${({ theme }) => theme.bodyText} !important;
    `};

  ${(props) =>
    props.RewardHubTab &&
    css`
      background-color: transparent;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      border-bottom: 1.5 px solid white;
      margin-left: 10px;
      color: ${(props) => {
        const { isActive, isDarkTheme } = props;
        if (isActive) {
          return isDarkTheme ? "#70a6e0" : "#0470e1";
        } else {
          return isDarkTheme ? "#dedfe0" : "#012243";
        }
      }};
      ${(props) => {
        const { isActive, isDarkTheme } = props;
        if (isActive) {
          return isDarkTheme
            ? "border-bottom: 1.5px solid #70a6e0"
            : "border-bottom: 1.5px solid #0470e1";
        }
      }};
      border-radius: 0px;
    `}

  ${({ flexCenter }) => flexCenter && css `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export { ButtonComponent };
