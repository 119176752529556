import { fromJS } from "immutable";
import { SET_AVAILABLE_LANGUAGES, UPDATE_USER_LANGUAGES } from "./const";
import { failure, success } from "../../utils/redux";
import { getLocalStorage } from "../../utils/helpers";

export function createInitialState() {
  return fromJS({
    translationList: [],
    userSelectedLang: getLocalStorage("ljs-lang")
  });
}

export function GenericPageReducer(state = createInitialState(), action) {
  switch (action.type) {
    case UPDATE_USER_LANGUAGES:
      return fromJS({
        ...state.toJS(),
        userSelectedLang: action.payload
      });

    case success(SET_AVAILABLE_LANGUAGES):
      // return state.set('translationList', action?.payload);
      return fromJS({
        ...state.toJS(),
        translationList: action.payload
      });

    case failure(SET_AVAILABLE_LANGUAGES):
      // debugger;
      return state;
      // return fromJS({
      //   ...state.toJS(),
      //   translationList: action.payload
      // });

    default:
      return state;
  }
};