import React from "react";
import { Animation, DivElement, ModalWrapper, TextWrapper } from "../../Reusable";
import { CloseIcon } from "../../../assets/svgs";
import { KycDivBox } from "../../../assets/StyleComponents/kyc.style";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";

const SubmittedModal = ({ onClose, type }) => {
  return (
    <ModalWrapper>
      <DivElement ModalContainer>
        <KycDivBox KYCModalContainer>
          <DivElement displayBtw>
            <TextWrapper fontSize="20px" fontSizeSm="16px" lineHeight="24px">
              {type ? "Verification Plus" : "KYC"} Submitted Successfully
            </TextWrapper>
            <CloseIcon pointer onClick={onClose} />
          </DivElement>

          <BannerBox
            className="kycsubmittedmodal__container"
            display="flex"
            justifyContent="center"
          >
            <div className="kycsubmittedmodal__animation">
              <Animation type="SUCCESS" />
            </div>
          </BannerBox>
          <div className="kycsubmittedmodal__msg">
            Thanks for submitting the documents, our team will take 24-48 hours to verify your
            account.
          </div>
        </KycDivBox>
      </DivElement>
    </ModalWrapper>
  );
};

export default SubmittedModal;
