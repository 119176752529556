import { compose } from "redux";
import { DivElement, TextWrapper } from "../../../../components/Reusable";
import { connect } from "react-redux";
import styled from "styled-components";
import { useRef } from "react";
import BigNumber from "bignumber.js";
import { numberWithCommas } from "../../../../utils/general";

const Container = styled(DivElement)`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  border: 0.5px solid ${(props) => (props.isDarkTheme === true ? "#38393d" : "#ccced0")};
  background-color: ${(props) => (props.isDarkTheme === true ? "#151e26" : "#f5f6fa")};
  height: 40px;
  align-items: center;
  padding-right: 16px;
  cursor: text;

  @media (max-width: 767px) {
    padding-right: 8px;
  }
`;

const InputWrapper = styled.div`
  width: 71px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    width: 64px;
  }
`;

const Input = styled.input`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 5px;
  background-color: transparent;
  background-color: ${(props) => (props.isDarkTheme ? "#1f2c38" : "#fff")};
  color: ${(props) => (props.isDarkTheme ? "#dedfe0" : "#012243")};
  border: 0.5px solid ${(props) => (props.isDarkTheme ? "#969799" : "#8c9797")};
  text-align: center;
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 1.71;
  }

  :hover,
  :active,
  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${(props) => (props.isDarkTheme === true ? "#969799" : "#8c9797")};
  }
`;

const InputContainer = ({
  isDarkTheme,
  amount,
  bondDetails,
  setAmount,
  balance,
  name,
  children
}) => {
  const inputRef = useRef();

  const changeValue = (val) => {
    let amt = isNaN(val) || Number(val) <= 0 ? "" : parseInt(val);
    if (amt === "") {
      setAmount("");
    } else if (
      amt > 0 &&
      ((name === "modifyAmount" && amt <= balance / bondDetails.denomination) ||
        (name !== "modifyAmount" && amt <= bondDetails.maxAmount / bondDetails.denomination))
    ) {
      setAmount(amt > 0 ? BigNumber(amt) : "");
    }
  };

  const focusInput = () => {
    inputRef.current.focus();
  };

  return (
    <Container width="100%" isDarkTheme={isDarkTheme} onClick={focusInput}>
      <DivElement dAlignCenter height="100%">
        <InputWrapper>
          <Input
            isDarkTheme={isDarkTheme}
            placeholder="1"
            value={amount}
            type="number"
            min="1"
            step="1"
            onChange={(e) => changeValue(e.target.value)}
            name={name}
            ref={inputRef}
            onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
          />
        </InputWrapper>

        <TextWrapper fontSize="14px" fontSizeSm="12px" lineHeight="1.71" lineHeightSm="2">
          &nbsp;&nbsp; x&nbsp; {numberWithCommas(bondDetails ? bondDetails.denomination : 0)}
        </TextWrapper>
        <TextWrapper fontSize="16px" fontSizeSm="14px" lineHeight="1.5" lineHeightSm="1.71">
          &nbsp;={" "}
          <var>
            {amount ? (
              numberWithCommas(BigNumber(amount).multipliedBy(bondDetails.denomination))
            ) : (
              <span className="grey-font">
                {numberWithCommas(bondDetails ? bondDetails.denomination : 0)}
              </span>
            )}
          </var>{" "}
          {bondDetails && bondDetails.bondCoin}
        </TextWrapper>
      </DivElement>

      {children}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(InputContainer);
