/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
// Carousel.js

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowLeftWhiteIcon, ArrowRightWhiteIcon } from "../../../assets/svgs";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import ImgElement from "../ImgElement";

import { useSelector } from "react-redux";
import LCXReferal from "../../../assets/png/all/LCX BRANDING.png";
import QRCode from "react-qr-code";

const CarouselContainer = styled.div`
  position: relative;
  border-radius: 10px;
  width: 280px;
  height: 360px;
  aspect-ratio: 0.78/1;
  margin-left: 40px;
  @media only screen and (max-width: 480px) {
    height: 430px;
  }
`;
const ArrowContainer = styled(CarouselContainer)`
  position: absolute;
  top: calc(360px - 150px);
  width: 370px;
  margin-left: -5px;
  margin-right: -30px;
`;

const CarouselImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 360px;
  aspect-ratio: 0.78/1;
  object-fit: contain;
  transition: transform 0.5s ease-in-out;
`;

const CarouselWrapper = styled.div`
  display: block;
  overflow: hidden;
  width: 280px;
  height: 360px;
  aspect-ratio: 0.78/1;
  transition: transform 0.5s ease;
  margin: 0px auto;
  // transform: ${({ currentIndex }) => `translateX(-${currentIndex * 100}%)`};
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const PrevButton = styled(Button)`
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NextButton = styled(Button)`
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Carousel = ({ ImageArray, enabled, setEnabled, referalLink, imageRef }) => {
  ImageArray = ImageArray?.filter((data) => data?.status === "ENABLED") || [];
  const [imgUrl, setImgUrl] = useState("");
  const isTheme = useSelector((state) => state.theme.isTheme);

  const handlePrev = () => {
    setEnabled((prevIndex) => (prevIndex === 0 ? ImageArray?.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setEnabled((prevIndex) => (prevIndex === ImageArray?.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const ref = setInterval(() => {
      setEnabled((prev) => (prev === ImageArray.length - 1 ? 0 : prev + 1));
    }, 6000);

    return () => {
      clearInterval(ref);
    };
  }, []);

  function convertImageToBase64(url, callback) {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // This is important for CORS
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png"); // You can specify the image format
      callback(dataURL);
    };
    img.src = url;
  }

  useEffect(() => {
    convertImageToBase64(ImageArray[enabled]?.link, function (base64) {
      setImgUrl(base64);
    });
  }, [enabled]);

  return (
    <>
      <CarouselContainer>
        <BannerBox ref={imageRef}>
          <CarouselWrapper currentIndex={enabled}>
            <CarouselImage
              key={enabled}
              src={imgUrl}
              alt={`Slide ${enabled + 1}`}
              currentIndex={enabled}
              index={enabled}
            />
          </CarouselWrapper>
          <BannerBox
            width="280px"
            height="78px"
            display="flex"
            margin="0px auto"
            justifyContent="space-between"
            alignItems="center"
            bck="#262626"
            padding="0px 10px"
          >
            <ImgElement src={LCXReferal} />

            <BannerBox background="white" padding="5px 5px 2px 5px">
              <QRCode value={referalLink} size={55} />
            </BannerBox>
          </BannerBox>
        </BannerBox>
      </CarouselContainer>
      <ArrowContainer>
        <PrevButton onClick={handlePrev}>
          <ArrowLeftWhiteIcon />
        </PrevButton>
        <NextButton onClick={handleNext}>
          <ArrowRightWhiteIcon />
        </NextButton>
      </ArrowContainer>
    </>
  );
};

export default Carousel;
