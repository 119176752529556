import { Button } from "../../Reusable";

const NonAuthButton = (props) => {
  return (
    <Button
      type="submit"
      primaryBlue
      width="100%"
      height="50px"
      heightMd="48px"
      marginTop="32px"
      marginTopSm="24px"
      DisabledBTN={props.loader}
      {...props}
    >
      {props.label}
    </Button>
  );
};

export default NonAuthButton;
