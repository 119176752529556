import styled, { css } from "styled-components";
import { MarginStyle } from "../Reusable/Margin.style";
import { PaddingStyle } from "../Reusable/Padding.style";

const HeadingThird = styled.h3`
  font-size: ${(props) => props.size || "24px"};
  color: ${(props) => props.color || props.theme.bodyText};
  font-weight: ${(props) => props.weight || "500"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};

  ${MarginStyle}
  ${PaddingStyle}

  ${(props) => props.position && `position: ${props.position}`};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.radius && `border-radius: ${props.radius}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};
  ${(props) => props.ml && `margin-left: ${props.ml}`};
  ${(props) => props.mr && `margin-right: ${props.mr}`};
  ${(props) => props.cursor && `cursor: ${props.cursor}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.flexDirection && `flex-direction: ${props.flexDirection}`};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.bck && `background-color: ${props.bck}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.verticalAlign && `vertical-align: ${props.verticalAlign}`};
  ${(props) => props.borderTop && `border-top: ${props.borderTop}`};
  @media (max-width: 1024px) {
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg}`};
    ${(props) => props.displayLg && `display: ${props.displayLg}`};
    ${(props) => props.alignItemsLg && `align-items: ${props.alignItemsLg}`};
    ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg}`};
    ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`};
    ${(props) => props.mtLg && `margin-top: ${props.mtLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mbLg}`};
    ${(props) => props.mlLg && `margin-left: ${props.mlLg}`};
    ${(props) => props.mrLg && `margin-right: ${props.mrLg}`};
    ${(props) => props.verticalAlignLg && `vertical-align: ${props.verticalAlignLg}`};
  }
  @media (max-width: 767px) {
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd}`};
    ${(props) => props.displayMd && `display: ${props.displayMd}`};
    ${(props) => props.alignItemsMd && `align-items: ${props.alignItemsMd}`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mbMd}`};
    ${(props) => props.mlMd && `margin-left: ${props.mlMd}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`};
    ${(props) => props.verticalAlignMd && `vertical-align: ${props.verticalAlignMd}`};
  }

  ${(props) =>
    props.SubTag &&
    css`
      position: relative;
      bottom: -0.25em;
      line-height: 0 !important;
      vertical-align: baseline;
    `};

  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.subText};
    `};

  ${(props) =>
    props.txtDanger &&
    css`
      color: ${(props) => props.color || props.theme.danger};
    `};

  ${(props) =>
    props.txtSuccess &&
    css`
      color: ${(props) => props.color || props.theme.success};
    `};

  ${(props) =>
    props.txtPrimary &&
    css`
      color: ${(props) => props.color || props.theme.primary};
    `};

  ${(props) =>
    props.TruncateThreeDot &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export { HeadingThird };
