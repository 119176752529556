/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { BannerBox, BannerHeadingSpan } from "../../assets/StyleComponents/Exchange.style";
import { FormInput } from "../../assets/StyleComponents/Input.style";
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from "../../assets/svgs";
import { getAllcategoriesMarket } from "../../lib/api";
import { showErrorMsg } from "../../utils/notification";
import { BannerHeading } from "../../assets/StyleComponents/FontSize/Heading.style";
import styled from "styled-components";
import { formatAPIErrors } from "../../utils/general";
import { ButtonComponent } from "../../assets/StyleComponents/Button.style";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import Skeleton from "react-loading-skeleton";
const MarketFilterSpan = styled(BannerHeadingSpan)`
  font-size: 16px;
  vertical-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
  margin: 0 16px 0 32px;
  @media (max-width: 1024px) {
    margin: 0 8px;
    font-size: 14px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    margin: 0 12px;
    padding: 0 2px;
  }
`;

const FilterPairsComponent = ({
  filter_top_quote_pair,
  setFilterTopQuotePair,
  filterData,
  tradeStatusDropdown,
  filter_Category,
  setFilterCategory,
  width
}) => {
  const [categories, setCategories] = useState({});
  const scrollContainerRef = useRef(null);
  const [scroll, setScroll] = useState(0);
  const [leftShowScrollOption, setLeftShowScrollOption] = useState(false);
  const [rightShowScrollOption, setRightShowScrollOption] = useState(false);

  useEffect(() => {
    getAllcategoriesMarket()
      .then((res) => {
        setCategories(res?.data?.data?.categories);
      })
      .catch((err) => showErrorMsg(formatAPIErrors(err)));
  }, []);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = 0;
      if (scrollContainerRef.current.scrollLeft === 0) setLeftShowScrollOption(false);
    }
  };

  const widthFinal = scrollContainerRef?.current?.offsetWidth - 60;
  // const widthFinal = 60;
  const scrollRight = () => {
    if (scrollContainerRef?.current) {
      setLeftShowScrollOption(true);
      scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollWidth;

      if (scrollContainerRef.current.scrollLeft > 280) {
        setRightShowScrollOption(false);
      }
    }
  };

  const RequiredCategories = categories[filter_top_quote_pair];

  useEffect(() => {
    if (RequiredCategories && RequiredCategories.length > 8) {
      setRightShowScrollOption(true);
    } else {
      setScroll(0);
      setRightShowScrollOption(false);
      setLeftShowScrollOption(false);
    }
  }, [categories, RequiredCategories]);

  useEffect(() => {
    if (
      scrollContainerRef?.current?.scrollLeft === 0 &&
      RequiredCategories &&
      RequiredCategories.length > 8
    ) {
      setRightShowScrollOption(true);
    }
  }, [scrollContainerRef?.current?.scrollLeft]);

  const location = useLocation();

  return (
    <>
      <BannerBox
        display="flex"
        justifyContent="space-between"
        direction={tradeStatusDropdown && width < 768 && "column-reverse"}
        directionLg={"row-reverse"}
        directionMd={"column-reverse"}
        // alignItemsLg="baseline"
        alignItemsMd="start"
        bottomBorder
        gapMd={"16px"}
      >
        <BannerBox
          order={"1"}
          heightMd="40px"
          orderLg={"2"}
          orderMd={"1"}
          // width={tradeStatusDropdown && "63%"}
          // widthLg={tradeStatusDropdown && "63%"}
          padding={"0 8px 0px 0"}
          paddingLg={"0 8px 0px 0"}
          paddingMd={"0 8px 0px 0"}
          mbMd={"0px"}
          display="flex"
          alignItems="center"
        >
          {location.pathname.includes("/en/sdk") ? (
            ""
          ) : (
            <BannerHeadingSpan
              cursorPointer
              size={"16px"}
              sizeLg="14px"
              sizeMd="12px"
              margin={"0 16px 0 0px"}
              padding="0"
              paddingMd="0 2px"
              verticalAlign="center"
              display="flex"
              alignItems="center"
              height="100%"
              marginLg="0 8px 0 0"
              marginMd="0 10px 0 0"
              ProgressLineActive={filter_top_quote_pair === "FAVOURITES"}
              weight={filter_top_quote_pair === "FAVOURITES" ? "500" : "400"}
              txtPrimary={filter_top_quote_pair === "FAVOURITES"}
              txtGrey={filter_top_quote_pair !== "FAVOURITES"}
              onClick={() => setFilterTopQuotePair("FAVOURITES")}
            >
              Favorites
            </BannerHeadingSpan>
          )}

          <MarketFilterSpan
            cursorPointer
            size={tradeStatusDropdown ? "14px" : "16px"}
            txtPrimary={filter_top_quote_pair === "ALL"}
            ProgressLineActive={filter_top_quote_pair === "ALL"}
            txtGrey={filter_top_quote_pair !== "ALL"}
            weight={filter_top_quote_pair === "ALL" ? "500" : "400"}
            onClick={() => setFilterTopQuotePair("ALL")}
          >
            ALL
          </MarketFilterSpan>

          <MarketFilterSpan
            cursorPointer
            txtPrimary={filter_top_quote_pair === "BTC"}
            size={"16px"}
            ProgressLineActive={filter_top_quote_pair === "BTC"}
            txtGrey={filter_top_quote_pair !== "BTC"}
            weight={filter_top_quote_pair === "BTC" ? "500" : "400"}
            onClick={() => setFilterTopQuotePair("BTC")}
          >
            BTC
          </MarketFilterSpan>

          <MarketFilterSpan
            cursorPointer
            txtPrimary={filter_top_quote_pair === "ETH"}
            size={"16px"}
            ProgressLineActive={filter_top_quote_pair === "ETH"}
            txtGrey={filter_top_quote_pair !== "ETH"}
            weight={filter_top_quote_pair === "ETH" ? "500" : "400"}
            onClick={() => setFilterTopQuotePair("ETH")}
          >
            ETH
          </MarketFilterSpan>

          <MarketFilterSpan
            cursorPointer
            txtPrimary={filter_top_quote_pair === "USDC"}
            size={"16px"}
            ProgressLineActive={filter_top_quote_pair === "USDC"}
            txtGrey={filter_top_quote_pair !== "USDC"}
            weight={filter_top_quote_pair === "USDC" ? "500" : "400"}
            onClick={() => setFilterTopQuotePair("USDC")}
          >
            USDC
          </MarketFilterSpan>

          <MarketFilterSpan
            cursorPointer
            size={"16px"}
            ProgressLineActive={filter_top_quote_pair === "EUR"}
            txtPrimary={filter_top_quote_pair === "EUR"}
            txtGrey={filter_top_quote_pair !== "EUR"}
            weight={filter_top_quote_pair === "EUR" ? "500" : "400"}
            onClick={() => setFilterTopQuotePair("EUR")}
          >
            EUR
          </MarketFilterSpan>
        </BannerBox>

        <BannerBox
          order={"2"}
          orderLg={tradeStatusDropdown ? "2" : "1"}
          orderMd={"2"}
          width={tradeStatusDropdown && (width < 768 ? "70%" : "35%")}
          widthLg={tradeStatusDropdown && (width < 768 ? "45%" : "35%")}
          // mlLg={!tradeStatusDropdown ? "32px" : "0px"}
          mlMd={tradeStatusDropdown ? "16px" : "10px"}
          // mbLg={!tradeStatusDropdown ? "16px" : "0px"}
          justifyContent={"flex-end"}
          justifyContentLg={tradeStatusDropdown ? "end" : "flex-start"}
          justifyContentMd={"flex-start"}
          display="flex"
          alignItems="center"
        >
          <BannerBox
            width={tradeStatusDropdown && "80%"}
            widthLg={tradeStatusDropdown ? "80%" : "40%"}
            widthMd={tradeStatusDropdown ? "80%" : "90%"}
            position="relative"
            right={!tradeStatusDropdown ? "10px" : "0px"}
            // display="flex"
            alignItems="center"
            mb={"10px"}
            height={tradeStatusDropdown && width < 767 && "45px"}
          >
            <BannerHeadingSpan
              position="absolute"
              right={tradeStatusDropdown && "5px"}
              left={!tradeStatusDropdown && "6px"}
              top={tradeStatusDropdown ? "17px" : "11px"}
            >
              <SearchIcon width="18px" height="18px" />
            </BannerHeadingSpan>
            <FormInput
              type="text"
              id="search-box-trade-page"
              autoFocus={tradeStatusDropdown ? true : false}
              onChange={(e) => filterData(e.target.value)}
              placeholder="Search Pairs"
              bck="transparent"
              SearchBarCOlor
              height={tradeStatusDropdown ? "36px" : "40px"}
              weight="300"
              size={tradeStatusDropdown && "14px"}
              mt={tradeStatusDropdown && "10px"}
              padding={tradeStatusDropdown ? "0px" : "0 0 0 28px"}
              width={tradeStatusDropdown ? "140px" : "208px"}
              MarketsInput={!tradeStatusDropdown}
            />
          </BannerBox>
        </BannerBox>
      </BannerBox>
      <BannerBox display="flex" alignItems="center">
        {leftShowScrollOption && (
          <ButtonComponent
            // OutlinePrimary
            onClick={scrollLeft}
            bck="transparent"
            display="flex"
            alignItems="center"

            // position="absolute"
            // width="500px"
            // HoverScreen
          >
            <ChevronLeftIcon />
          </ButtonComponent>
        )}
        <BannerBox
          display="flex"
          overflow="auto scroll"
          margin="12px 0px"
          marginMd={"16px 0px"}
          width="100%"
          flexWrap="nowrap"
          ref={scrollContainerRef}
          // id="referDiv"
        >
          {RequiredCategories &&
            RequiredCategories?.map((categories) => {
              return (
                <BannerHeading
                  size={"12px"}
                  key={categories}
                  sizeLg="12px"
                  height="34px"
                  sizeMd="12px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  // minWidth={categories?.length > 10 && "150px"}
                  textWrap={"nowrap"}
                  ActiveTabStylesMarkets={filter_Category === categories && true}
                  cursor="pointer"
                  margin={"0 16px"}
                  marginLg="0 8px"
                  marginMd="0 4px"
                  padding="0 8px"
                  paddingMd="0 8px"
                  weight="400"
                  txtPrimary={filter_Category === categories}
                  txtGrey={filter_Category !== categories}
                  onClick={() => setFilterCategory(categories)}
                >
                  {categories === "Cardano" ? <>{categories} 🔥</> : categories}
                </BannerHeading>
              );
            })}
        </BannerBox>

        {rightShowScrollOption && (
          <ButtonComponent
            // OutlinePrimary
            onClick={scrollRight}
            bck="transparent"
            display="flex"
            alignItems="center"
          >
            <ChevronRightIcon />
          </ButtonComponent>
        )}
      </BannerBox>
    </>
  );
};

export default FilterPairsComponent;
