import React, { Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import CheckList from "./CheckList";
import { LinkButton } from "../../../Reusable";
import {
  // CheckIcon,
  FaqRedIcon,
  FaqYellowIcon,
  FingerprintGreyIcon,
  FingerprintOrangeIcon,
  FingerprintRedIcon
} from "../../../../assets/svgs";
import {
  BoyStandingIllustration,
  BoyWithBagIllustration,
  SuccessIllustration
} from "../../../../assets/svgs/illustrations";
import {
  MainElement,
  BannerBox,
  BannerHeadingSpan
} from "../../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../../assets/StyleComponents/FontSize/Heading.style";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";
import { HeadingSix } from "../../../../assets/StyleComponents/FontSize/HeadingSix.style";

const TransactionLimit = ({ limit }) => {
  return (
    <BannerBox display="flex" direction="column" gap="16px" padding="20px 10px" bottomBorder>
      <HeadingSix size="14px">
        <strong>Deposit Limit: </strong>
        {limit}
      </HeadingSix>
      <HeadingSix size="14px">
        <strong>Withdrawal Limit: </strong>
        {limit}
      </HeadingSix>
      <HeadingSix size="14px">
        <strong>Trading Limit: </strong>
        {limit}
      </HeadingSix>
    </BannerBox>
  );
};

const AccountVerification = ({ userData }) => {
  let isRejected = userData && userData.KYC.LEVEL2.status === "REJECTED";
  let isKycApproved = userData && userData.KYC.LEVEL2.status === "APPROVED";
  let isKycSubmitted = userData && userData.KYC.LEVEL2.status === "SUBMITTED";
  const { width } = useWindowSize();

  return (
    <MainElement width="100%" padding={"0 16px"} paddingMd={"20px 16px"}>
      <BannerBox>
        <BannerHeading size={"24px"} sizeMd={"18px"} mb={"8px"} weight="bold">
          Account Verification
        </BannerHeading>
        <ParaTag size={"16px"} sizeLg={"16px"} sizeMd={"12px"} weight="300">
          Please complete your profile to upgrade your account
        </ParaTag>
      </BannerBox>

      {width > 1025 && (
        <BannerBox
          width="100%"
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          margin="30px 0px"
        >
          <BannerBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            direction="column"
            gap="24px"
          >
            <BannerBox
              height="200px"
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              direction="column"
            >
              <SuccessIllustration width="80px" height="80px" />

              <BannerBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                direction="column"
                gap="6px"
              >
                <HeadingSix weight="bold" size="16px">
                  Register
                </HeadingSix>
                <ParaTag size="16px" weight="300" textAlign="center">
                  Sign Up to build your LCX account
                </ParaTag>
              </BannerBox>
            </BannerBox>

            <BannerBox
              width="315px"
              padding="10px"
              position="relative"
              CardGrey
              BorderCardSettings
              BorderRadius="10px"
              height="618px"
            >
              <BannerHeadingSpan ArrowTopSettings></BannerHeadingSpan>

              <BannerBox
                padding="20px 10px"
                gap="18px"
                display="flex"
                direction="column"
                bottomBorder
              >
                <CheckList label="View LCX Exchange" />
                <CheckList label="View LCX Earn" />
                <CheckList label="View LCX Launchpad" />
                <CheckList label="View LCX Wallets" />
              </BannerBox>

              <TransactionLimit limit="0" />

              <BannerBox
                display="flex"
                direction="column"
                padding="20px 10px"
                position="relative"
                height="255px"
              >
                <BannerBox>
                  <HeadingSix size="14px" weight="bold" mb="16px">
                    Requirements:
                  </HeadingSix>
                  <HeadingSix size="14px">First Name,</HeadingSix>
                  <HeadingSix size="14px">Last Name,</HeadingSix>
                  <HeadingSix size="14px">Email</HeadingSix>
                </BannerBox>

                <BoyWithBagIllustration absolute bottom="0" right="0" />
              </BannerBox>
            </BannerBox>
          </BannerBox>

          {/* <BannerBox StatusLine></BannerBox> */}

          <BannerBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            direction="column"
            gap="24px"
          >
            <BannerBox
              height="200px"
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              direction="column"
            >
              {isKycApproved ? (
                <SuccessIllustration width="80px" height="80px" />
              ) : isKycSubmitted ? (
                <FingerprintOrangeIcon width="80px" height="80px" />
              ) : isRejected ? (
                <FingerprintRedIcon width="80px" height="80px" />
              ) : (
                <FingerprintGreyIcon width="80px" height="80px" />
              )}

              <div>
                {isKycApproved ? (
                  <Fragment>
                    <BannerBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      gap="6px"
                    >
                      <HeadingSix weight="bold" size="16px">
                        Get KYC Verified
                      </HeadingSix>
                      <ParaTag size="16px" weight="300" textAlign="center">
                        Upload documents for the KYC verification
                      </ParaTag>
                    </BannerBox>
                  </Fragment>
                ) : (
                  <Fragment>
                    {isRejected && (
                      <BannerBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="8px"
                        margin="12px 0px"
                      >
                        <FaqRedIcon />
                        <BannerHeading size="14px" color="#E65252" textDecoration="underline">
                          Rejected
                        </BannerHeading>
                      </BannerBox>
                    )}
                    {isKycSubmitted && (
                      <BannerBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="8px"
                        margin="12px 0px"
                      >
                        <FaqYellowIcon />
                        <BannerHeading size="14px" color="#FFC955" textDecoration="underline">
                          Under Process
                        </BannerHeading>
                      </BannerBox>
                    )}
                    <LinkButton
                      to="/setting/profile"
                      primaryBlue
                      width="100%"
                      height="48px"
                      primaryRed={isRejected || null}
                      primaryGrey={isKycSubmitted || null}
                      disabled={isKycSubmitted || null}
                    >
                      Get KYC Verified
                    </LinkButton>
                  </Fragment>
                )}
              </div>
            </BannerBox>
            <BannerBox
              BannerBox
              width="315px"
              padding="10px"
              position="relative"
              CardGrey
              BorderRadius="10px"
              height="618px"
              BorderCardSettings
              BorderCardSettingsRejected={isRejected}
              BorderCardSettingsOrange={isKycSubmitted}
              BorderCardSettingsUnderApproval={!isKycSubmitted && !isRejected}
            >
              <BannerHeadingSpan
                ArrowTopSettings
                BorderCardSettings
                BorderCardSettingsRejected={isRejected}
                BorderCardSettingsOrange={isKycSubmitted}
                BorderCardSettingsUnderApproval={!isKycSubmitted && !isRejected}
                borderWidth="1px 0 0 1px"
              ></BannerHeadingSpan>

              <BannerBox
                padding="20px 10px"
                gap="18px"
                display="flex"
                direction="column"
                bottomBorder
              >
                <CheckList label="Deposit and Withdraw digital assets" />
                <CheckList label="Unlimited Trade on LCX Exchange" />
                <CheckList label="Invest in LCX Earn and Launchpad" />
              </BannerBox>

              <TransactionLimit limit="Unlimited" />

              <BannerBox display="flex" direction="column" padding="20px 10px" position="relative">
                <BannerBox>
                  <HeadingSix size="14px" weight="bold" mb="16px">
                    Requirements:
                  </HeadingSix>
                  <HeadingSix>Government Issued ID,</HeadingSix>
                  <HeadingSix>Selfies,</HeadingSix>
                  <HeadingSix>Proof of Residence,</HeadingSix>
                  <HeadingSix>AML Verification</HeadingSix>
                </BannerBox>
              </BannerBox>
              <BoyStandingIllustration absolute bottom="0" right="0" />
            </BannerBox>
          </BannerBox>
        </BannerBox>
      )}

      {width <= 1024 && (
        <div>
          <BannerBox margin="30px 0px">
            <BannerBox display="flex" width="100%" gap="30px">
              <BannerBox display="flex" direction="column" alignItems="center">
                <SuccessIllustration
                  width={width > 767 ? "55px" : "24px"}
                  height={width > 767 ? "50px" : "24px"}
                />
                <BannerHeadingSpan StatusLineMain></BannerHeadingSpan>
              </BannerBox>
              <BannerBox>
                <BannerHeading size="16px" weight="bold">
                  Register
                </BannerHeading>
                <ParaTag size="16px" weight="300" sizeMd="14px">
                  Sign Up to build your LCX account
                </ParaTag>
              </BannerBox>
            </BannerBox>

            <BannerBox display="flex" width="100%" gap="30px">
              <BannerBox>
                {isKycSubmitted ? (
                  <FingerprintOrangeIcon
                    width={width > 767 ? "50px" : "24px"}
                    height={width > 767 ? "55px" : "24px"}
                  />
                ) : isKycApproved ? (
                  <SuccessIllustration
                    width={width > 767 ? "50px" : "24px"}
                    height={width > 767 ? "55px" : "24px"}
                  />
                ) : isRejected ? (
                  <FingerprintRedIcon
                    width={width > 767 ? "50px" : "24px"}
                    height={width > 767 ? "55px" : "24px"}
                  />
                ) : (
                  <FingerprintGreyIcon
                    width={width > 767 ? "50px" : "24px"}
                    height={width > 767 ? "55px" : "24px"}
                  />
                )}
              </BannerBox>
              <BannerBox>
                <BannerHeading size="16px" weight="bold">
                  Get KYC Verified
                </BannerHeading>
                <ParaTag size="16px" weight="300" sizeMd="14px">
                  Upload documents for the KYC verification
                </ParaTag>
              </BannerBox>
            </BannerBox>
          </BannerBox>

          {(!isKycApproved || isKycSubmitted) && (
            <BannerBox
              display="flex"
              direction="column"
              alignItems="start"
              gap="10px"
              ml="14%"
              mlMd="0%"
              mb="30px"
            >
              {isRejected && (
                <BannerBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="8px"
                  margin="12px 0px"
                  mlMd="14%"
                >
                  <FaqRedIcon />
                  <BannerHeading size="14px" color="#E65252" textDecoration="underline">
                    Rejected
                  </BannerHeading>
                </BannerBox>
              )}
              {isKycSubmitted && (
                <BannerBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="8px"
                  margin="12px 0px"
                  mlMd="14%"
                >
                  <FaqYellowIcon />
                  <BannerHeading size="14px" color="#FFC955" textDecoration="underline">
                    Under Process
                  </BannerHeading>
                </BannerBox>
              )}
              <LinkButton
                to="/setting/profile"
                primaryBlue
                width="313px"
                widthSm="95%"
                height="48px"
                primaryRed={isRejected || null}
                primaryGrey={isKycSubmitted || null}
                disabled={isKycSubmitted || null}
              >
                Get KYC Verified
              </LinkButton>
            </BannerBox>
          )}

          <BannerBox
            width="95%"
            padding="10px"
            position="relative"
            CardGrey
            BorderRadius="10px"
            margin="20px auto"
            BorderCardSettings
            BorderCardSettingsRejected={isRejected}
            BorderCardSettingsOrange={isKycSubmitted}
            BorderCardSettingsUnderApproval={!isKycSubmitted && !isRejected}
          >
            <BannerHeadingSpan
              ArrowTopSettings
              BorderCardSettings
              BorderCardSettingsRejected={isRejected}
              BorderCardSettingsOrange={isKycSubmitted}
              BorderCardSettingsUnderApproval={!isKycSubmitted && !isRejected}
              borderWidth="1px 0 0 1px"
            ></BannerHeadingSpan>

            <BannerBox
              padding="20px 10px"
              gap="18px"
              display="flex"
              direction="column"
              bottomBorder
            >
              <CheckList label="Deposit and Withdraw digital assets" />
              <CheckList label="Unlimited Trade on LCX Exchange" />
              <CheckList label="Invest in LCX Earn and Launchpad" />
            </BannerBox>
            <TransactionLimit limit="Unlimited" />

            <BannerBox padding="20px 10px" position="relative" height="255px" heightMd="300px">
              <HeadingSix size="14px" weight="bold" mb="16px">
                Requirements:
              </HeadingSix>
              <HeadingSix>Government Issued ID,</HeadingSix>
              <HeadingSix>Selfies,</HeadingSix>
              <HeadingSix>Proof of Residence,</HeadingSix>
              <HeadingSix>AML Verification</HeadingSix>
            </BannerBox>
            {width > 767 && <BoyStandingIllustration absolute width="280px" bottom="0" right="0" />}
            {width < 768 && <BoyStandingIllustration absolute width="214px" bottom="0" right="0" />}
          </BannerBox>
        </div>
      )}
    </MainElement>
  );
};

const maptoStateProps = (state) => ({
  userData: state.dashboard.userData
});

export default compose(connect(maptoStateProps))(AccountVerification);
