/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import TimerDiv from "./components/TimerDiv";
import LeaderBoard from "./components/LeaderBoard";
import Rules from "./components/Rules";
import { useHistory, useParams } from "react-router-dom";
import { showErrorMsg } from "../../utils/notification";
import { formatAPIErrors } from "../../utils/general";
import { getSingleCompetition, getPrivateSingleCompetition } from "../../lib/api";
import { useSelector } from "react-redux";
import { MainElement } from "../../assets/StyleComponents/Exchange.style";
import { HalfFooterCommon } from "../../components/Footer/common";

const MainTradingComp = () => {
  const [singleComptitionData, setSingleComptitionData] = useState("");
  const [apihitter, setapihitter] = useState(0);
  const param = useParams();
  const selectedPairName = useSelector((state) => state.trade.selectedPair);
  const history = useHistory();
  let compId = param.id;
  const userLogin = useSelector((state) => state.auth.isLoggedIn);

  const callApi = (id) => {
    if (id) {
      let data = {
        competitionId: id
      };
      if (userLogin) {
        getPrivateSingleCompetition(data)
          .then((res) => {
            setSingleComptitionData(res.data.data);
          })
          .catch((e) => {
            showErrorMsg(formatAPIErrors(e));
          });
      } else {
        getSingleCompetition(data)
          .then((res) => {
            setSingleComptitionData(res.data.data);
          })
          .catch((e) => {
            showErrorMsg(formatAPIErrors(e));
          });
      }
    } else history.pushState("/trading-competition");
  };

  useEffect(() => {
    callApi(compId);
  }, [apihitter]);

  return (
    <>
      <MainElement
        ExchangeContainer
        margin="32px auto"
        marginMd="16px auto"
        mb="80px"
        mbLg="104px"
        mbMd="64px"
      >
        <section>
          <TimerDiv
            selectedPairName={selectedPairName}
            singleComptitionData={singleComptitionData}
            id={compId}
            setapihitter={setapihitter}
            status={singleComptitionData?.CompetitionStatus}
          />
        </section>
        <section>
          <LeaderBoard
            singleComptitionData={singleComptitionData}
            status={singleComptitionData?.CompetitionStatus}
          />
        </section>
        <section>
          <Rules
            Rules={singleComptitionData.Rules}
            Rewards={singleComptitionData.Rewards}
            ParticipationRewards={singleComptitionData.ParticipationRewards}
          />
        </section>
      </MainElement>
      <HalfFooterCommon />
    </>
  );
};

export default MainTradingComp;
