export const GENDER = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" }
];

export const ID_DOCUMENTS = [
  { label: "Passport", value: "Passport" },
  { label: "National ID", value: "National ID" }
];

export const USE_OF_LCX = [
  { label: "Buy LCX Tokens", value: "Buy LCX Tokens" },
  { label: "Use LCX Exchange", value: "Use LCX Exchange" },
  { label: "Participate in Token Sale", value: "Participate in Token Sale" }
];

export const ANNUAL_NET_INCOME = [
  { label: "Up to CHF 25,000", value: "Up to CHF 25,000" },
  { label: "CHF 25,001 - 100,000", value: "CHF 25,001 - 100,000" },
  { label: "More than CHF 100,000", value: "More than CHF 100,000" }
];

export const HEAR_ABOUT_US = [
  { label: "Friend Recommendation", value: "Friend Recommendation" },
  { label: "Facebook", value: "Facebook" },
  { label: "Twitter", value: "Twitter" },
  { label: "Instagram", value: "Instagram" },
  { label: "LinkedIn", value: "LinkedIn" },
  { label: "Crypto Forums", value: "Crypto Forums" },
  { label: "Crypto Communities", value: "Crypto Communities" },
  { label: "Crypto News Websites", value: "Crypto News Websites" },
  { label: "Blogs", value: "Blogs" }
  // { label: "Other (Specify)", value: "Other (Specify)" }
];

export const NATURE_OF_BUSINESS = [
  { label: "Accommodation and Food Services", value: "Accommodation and Food Services" },
  { label: "Accounting and Tax Services", value: "Accounting and Tax Services" },
  { label: "Agriculture, Forestry, Fishing, and Hunting", value: "Agriculture, Forestry, Fishing, and Hunting" },
  { label: "Architecture and Engineering", value: "Architecture and Engineering" },
  { label: "Arts, Design, Entertainment, Media, and Recreation", value: "Arts, Design, Entertainment, Media, and Recreation" },
  { label: "Automotive or Related", value: "Automotive or Related" },
  { label: "Blockchain Technology Services", value: "Blockchain Technology Services" },
  { label: "Construction", value: "Construction" },
  { label: "Education", value: "Education" },
  { label: "Financial Services", value: "Financial Services" },
  { label: "Healthcare and Social Assistance", value: "Healthcare and Social Assistance" },
  { label: "Information Technology", value: "Information Technology" },
  { label: "Law Firm or Legal Services", value: "Law Firm or Legal Services" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Non-Profit Organization", value: "Non-Profit Organization" },
  { label: "Professional Services", value: "Professional Services" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Retail", value: "Retail" },
  { label: "Technology", value: "Technology" },
  { label: "Transportation and Logistics", value: "Transportation and Logistics" },
  { label: "Other", value: "Other" }
];

export const EMPLOYEMENT_STATUS = [
  { label: "Employed", value: "Employed" },
  { label: "Self Employed", value: "Self Employed" }
];
