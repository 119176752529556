/* eslint-disable no-useless-escape */

export const RewardsFaqs = [
  {
    question: "What is Rewards Hub?",
    answer: `Reward Hub is a feature on our platform that allows users to earn exciting rewards by participating in various activities. These activities could include completing specific tasks on a daily/weekly basis. It’s our way of giving back and keeping your journey fun and rewarding!`
  },
  {
    question: "How can I earn rewards as a new user?",
    answerHtml: `Click on Rewards Hub at the top of the home page to get started. </br>
      You can earn rewards by participating in the following tasks:
      </br>
      <ul>
        <li> Once you Sign up, you are eligible for a Signup bonus which you can Claim. </li>
        <li> To complete the KYC Verification and earn reward kindly click on Complete KYC tab and then Verify & Earn the reward. </li>
        <li> Make your first deposit by clicking on the Deposit now tab and then Deposit & Earn the reward. </li>
        <li> Make your first trade by clicking on the Trade Now tab and then Trade & Earn the reward. </li>
      </ul>
      </br>
      To know how you can claim your eligible rewards kindly check FAQ- <strong> How to Claim my rewards? </strong>
    `
  },
  {
    question: "How can a registered user participate in a task?",
    answerHtml: `To take part, please ensure that you have completed the full verification process on LCX Exchange.
      </br>
      <ul>
        <li> Log in to your LCX account or Simply visit to https://exchange.lcx.com/en/ </li>
        <li> Click on <strong> Rewards Hub </strong> on the top of the page to get started. </li>
        <li> Locate the <strong> Regular Task </strong> section. </li>
        <li> Click the <strong> Participate </strong> button next to the task you wish to join. </li>
      </ul>
    `
  },
  {
    question: "Where can I see my rewards?",
    answer: `You can view your rewards in the My Rewards section of the Rewards Hub. This will show all the tasks you’ve participated in, along with the rewards you’ve earned. Once you claim these rewards, the balance would immediately reflect in your main wallet.`
  },
  {
    question: "How long does it take to receive rewards?",
    answer: `Rewards are processed and credited immediately as soon as you successfully claim it after completing a task.`
  },
  {
    question: "Can I participate in multiple tasks at the same time?",
    answer: `Yes, you have the option to engage in several ongoing tasks at the same time. Just remember to click "Participate" for each task before you finish them.`
  },
  {
    question: "Can I rejoin a task if I leave it incomplete? ",
    answerHtml: `By clicking on <strong> Participate </strong>, you become eligible to join the task until its completion. Be sure to review and adhere to the task's Terms & Conditions or Rules.`
  },
  {
    question: "How to Claim my rewards?",
    answerHtml: `
      <ul>
        <li> Log in to your account. </li>
        <li> Click on Rewards Hub.  </li>
        <li> Click on My Rewards  </li>
        <li> Click on the <strong> 'Ready to Claim' </strong> button on the task you've participated in. </li>
      </ul>
    `
  }
];
