/* eslint-disable no-console */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bool } from "prop-types";
import {
  isFetchingAccountWallet,
  isFetchingExchangeWallet,
  updateAccountWallet,
  updateExchangeWallet
} from "../../Modules/Wallet/WalletBalance/action";
import { formatAPIErrors, sortNumberData } from "../../utils/general";
import { showErrorMsg } from "../../utils/notification";
import { updatePrivateSocketBalance } from "../../actions/trade";
import { createStructuredSelector } from "reselect";
import {
  getisFetchingAccountWallet,
  getisFetchingExchangeWallet
} from "../../Modules/Wallet/WalletBalance/selectors";
import { getAllWallets, getWalletBalance } from "../../lib/api";
import { WalletContext } from "../../contexts/WalletPage/BalancePage/wallet";

function BalanceDropDownHoc(Element) {
  return (props) => {
    const location = useLocation();
    let { pathname } = location || {};
    const dispatch = useDispatch();
    let { isFetchingAccounts, isFetchingExchange } = useSelector(mapStateToProps);
    let {
      updateExchWallet,
      updateMainWallet,
      setActiveWallet,
      updateExchWalletCopy,
      updateMainWalletCopy,
      updateMainWalletError,
      updateExchWalletError
    } = useContext(WalletContext);

    useEffect(() => {
      dispatch(isFetchingExchangeWallet(true));
      dispatch(isFetchingAccountWallet(true));
      //=> account Api Calling
      getAllWallets()
        .then((res) => {
          updateExchWallet(sortNumberData(res.data, true));
          updateExchWalletCopy(sortNumberData(res.data, true));
          updateExchWalletError(false);
          dispatch(updateAccountWallet(sortNumberData(res.data, true)));
          dispatch(isFetchingAccountWallet(false));
        })
        .catch((err) => {
          updateExchWalletError(true);
          setActiveWallet("exchange");
          dispatch(isFetchingAccountWallet(false));
          showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
        });

      //=> Exchange Api Calling
      getWalletBalance()
        .then((res) => {
          updateMainWallet(sortNumberData(res.data, true));
          updateMainWalletCopy(sortNumberData(res.data, true));
          dispatch(updateExchangeWallet(sortNumberData(res.data, true)));
          dispatch(isFetchingExchangeWallet(false));
          dispatch(
            updatePrivateSocketBalance({
              walletBalance: res.data,
              walletBalanceCopy: res.data,
              wallet: true
            })
          );
        })
        .catch((err) => {
          updateMainWalletError(true);
          setActiveWallet("account");
          dispatch(isFetchingExchangeWallet(false));
          showErrorMsg(formatAPIErrors(err), { autoClose: 7000 });
        });
    }, [pathname]);

    return useMemo(() => {
      return <Element {...props} />;
    }, [pathname, isFetchingAccounts, isFetchingExchange]);
  };
}

BalanceDropDownHoc.propTypes = {
  isFetchingAccounts: bool.isRequired,
  isFetchingExchange: bool.isRequired
};

const mapStateToProps = createStructuredSelector({
  isFetchingAccounts: getisFetchingAccountWallet(),
  isFetchingExchange: getisFetchingExchangeWallet()
});

export default BalanceDropDownHoc;
