import { compose } from "redux";
import { DivElement, ImgElement, TextWrapper } from "../../Reusable";
import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
import styled from "styled-components";
import ModalWrapper from "../../Reusable/ModalWrapper";
import { showSuccessMsg } from "../../../utils/notification";
import { capitalize } from "../../../utils";
import { noExponents, numberWithCommas } from "../../../utils/general";
import config from "../../../constants/config";
import {
  CloseIcon,
  CopyBlueIcon,
  ErrorIcon,
  LinkBlueIcon,
  SuccessIcon
} from "../../../assets/svgs";
import { HourGlassIllustration } from "../../../assets/svgs/illustrations";
import { Skeleton } from "../../Loader";

const TxnIdWrapper = styled(TextWrapper)`
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;

  @media (max-width: 1024px) {
    max-width: 250px;
  }

  @media (max-width: 768px) {
    max-width: 200px;
  }
`;

const FiatTransactionStatus = ({ isDarkTheme, onClose, txnDetails, loader = false }) => {
  const [tnxData, setTxnData] = useState("");

  useEffect(() => {
    if (!txnDetails) return;
    let data = [];
    if (txnDetails.txStatus) {
      data.push({ label: "Status", value: capitalize(txnDetails.txStatus) });
    }

    if (txnDetails.createdAt) {
      data.push({
        label: "Date & time",
        value: moment(txnDetails.createdAt).format("DD/MM/YY, hh:mm a")
      });
    }

    data.push({
      label: txnDetails.txSource.toLowerCase().includes("card") ? "Fiat" : "Coin",
      value: txnDetails.txSource.toLowerCase().includes("card")
        ? `${txnDetails.coin} (${txnDetails.txSource})`
        : txnDetails.coin
    });

    data.push({
      label: `Transfer amount`,
      value: `${numberWithCommas(
        noExponents(txnDetails.amount + (txnDetails.txFee || txnDetails.userFee))
      )} ${txnDetails.coin}`
    });
    data.push({
      label: `${capitalize(txnDetails.txType)} amount`,
      value: `${numberWithCommas(noExponents(txnDetails.amount))} ${txnDetails.coin}`
    });

    if (txnDetails.txFee || txnDetails.userFee) {
      data.push({
        label: txnDetails.txSource.toLowerCase().includes("card")
          ? "Card processing fee"
          : "Network Fee",
        value: `${
          txnDetails.txType === "DEPOSIT"
            ? numberWithCommas(txnDetails.txFee || 0)
            : numberWithCommas(txnDetails.userFee || 0)
        } ${txnDetails.coin}`
      });
    }

    if (txnDetails.memo) {
      data.push({
        label: "Memo ID",
        value: txnDetails.memo
      });
    }

    if (txnDetails.awayAddress || txnDetails.cardInfo) {
      data.push({
        label: txnDetails.txSource.toLowerCase().includes("card")
          ? "Card number"
          : txnDetails.txSource === "LCX Pay"
          ? "E-mail address"
          : "Address",
        value: txnDetails.txSource.toLowerCase().includes("card")
          ? txnDetails.cardInfo
          : txnDetails.awayAddress
      });
    }

    if (txnDetails.transactionId) {
      data.push({
        label: txnDetails.txStatus?.toLowerCase().includes("refund") ? "Refund ID" : "Tx Id",
        value: txnDetails.transactionId
      });
    }

    setTxnData(data);
  }, [txnDetails]);

  const onTxnIdCopy = (label) => {
    showSuccessMsg(
      `Successfully copied ${
        label === "Address" ? "Address" : label === "Memo" ? "Memo ID" : "Transaction Id"
      }`
    );
  };

  let status = txnDetails?.txStatus?.toLowerCase();
  const isLoading = loader || !tnxData;

  return (
    <ModalWrapper>
      <DivElement ModalContainer padding="0">
        <DivElement displayBtw bgCream height="48px" padding="0 10px" radius="10px 10px 0 0">
          {!isLoading ? (
            <DivElement flexAlignCenter>
              {status.includes("success") ||
              status.includes("approve") ||
              status.includes("confirm") ? (
                <SuccessIcon marginRight="12px" />
              ) : status.includes("fail") ||
                status.includes("cancel") ||
                status.includes("reject") ||
                status.includes("decline") ? (
                <ErrorIcon marginRight="12px" />
              ) : (
                <HourGlassIllustration marginRight="12px" />
              )}

              <TextWrapper fontSize="20px" fontSizeSm="16px">
                {capitalize(txnDetails.txType)} {capitalize(txnDetails.txStatus)}
              </TextWrapper>
            </DivElement>
          ) : (
            <Skeleton height="30px" width="150px" />
          )}
          <CloseIcon onClick={onClose} />
        </DivElement>
        <DivElement padding="10px" paddingSm="16px 16px 26px 16px">
          {!isLoading ? (
            <Fragment>
              {tnxData &&
                tnxData.map((data, index) => (
                  <DivElement
                    key={index}
                    displayBtw
                    gap="15px"
                    marginBottom={index === tnxData.length - 1 ? "0" : "12px"}
                  >
                    <TextWrapper secondary lineHeight="20px" fontSizeSm="12px" nowrap>
                      {data.label === "Coin" ? "Asset" : data.label}
                    </TextWrapper>
                    {data.label === "Address" ||
                    data.label === "Memo ID" ||
                    data.label === "Tx Id" ||
                    data.label === "Refund ID" ? (
                      <DivElement dAlignCenter>
                        <CopyToClipboard text={data.value} onCopy={() => onTxnIdCopy(data.label)}>
                          <DivElement dAlignCenter cursor>
                            <TxnIdWrapper lineHeight="20px" fontSize="12px" fontSizeSm="10px">
                              {data.value.slice(0, 22) + "..."}
                            </TxnIdWrapper>
                            <CopyBlueIcon marginLeft="4px" width="20px" height="20px" />
                          </DivElement>
                        </CopyToClipboard>

                        {txnDetails.link &&
                          data.label !== "Memo ID" &&
                          data.label !== "Address" && (
                            <a href={txnDetails.link} target="_blank" rel="noopener noreferrer">
                              <LinkBlueIcon pointer marginLeft="4px" width="20px" height="20px" />
                            </a>
                          )}
                      </DivElement>
                    ) : (
                      <TextWrapper lineHeight="20px" fontSizeSm="12px">
                        {data.label === "Coin" ? (
                          <DivElement dAlignCenter>
                            <ImgElement
                              width="16px"
                              height="16px"
                              marginRight="8px"
                              src={
                                data.value === "BNB"
                                  ? "https://etherscan.io/token/images/bnb_28_2.png"
                                  : `${
                                      isDarkTheme ? config.darkCoinUrl : config.lightCoinUrl
                                    }${data.value.toLowerCase()}.svg`
                              }
                            />
                            {data.value}
                          </DivElement>
                        ) : (
                          data.value
                        )}
                      </TextWrapper>
                    )}
                  </DivElement>
                ))}
            </Fragment>
          ) : (
            <Fragment>
              {[1, 2, 3, 4, 5, 6].map((el) => (
                <DivElement displayBtw marginBottom="12px" key={el}>
                  <Skeleton height="20px" width="100px"></Skeleton>
                  <Skeleton height="20px" width="150px"></Skeleton>
                </DivElement>
              ))}
            </Fragment>
          )}
        </DivElement>
      </DivElement>
    </ModalWrapper>
  );
};

const maptoStateProps = (state) => ({
  isDarkTheme: state.theme.isTheme
});

export default compose(connect(maptoStateProps))(FiatTransactionStatus);
