import React, { useRef, useState } from "react";
import QRCode from "qrcode.react";
import { BannerBox } from "../../../assets/StyleComponents/Exchange.style";
import { IMAGE_PREFIX, IMAGE_PREFIX_DARK } from "../../../constants/Images/imageIndex";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight";
import useOnClickOutside from "../../../Hooks/CheckOutSideClick/checkOutSideClick";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { ImgElement } from "../../../components/Reusable";

const MobileAppComponent = (props) => {
  const ref = useRef();
  const [showQrCode, setShowQrCode] = useState(false);

  let { isTheme } = props;

  const { width } = useWindowSize();

  useOnClickOutside(ref, () => {
    setShowQrCode(false);
  });

  return (
    <BannerBox
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      padding="128px 0px"
      paddingLg="120px 0px"
      paddingMd="104px 0px"
      justifyContentLg="center"
      // lineHeight="60px"
    >
      <BannerBox
        width="50%"
        widthLg="100%"
        alignItemsLg="center"
        displayLg="flex"
        directionLg="column"
      >
        <BannerHeading size="44px" sizeLg="36px" sizeMd="24px" textAlignLg="center">
          One app. Unlimited possibilities.
        </BannerHeading>

        <ParaTag
          txtGrey
          size="20px"
          justifyContentLg="center"
          maxWidthLg="70%"
          maxWidthMd="100%"
          sizeLg="20px"
          sizeMd="12px"
          textAlignLg="center"
          mt="26px"
          mtLg="19px"
          mtMd="16px"
        >
          Buy and trade all your favourite tokens on LCX App effortlessly, anytime and anywhere.
        </ParaTag>

        <BannerBox
          display="flex"
          justifyContentLg="center"
          width="83%"
          widthLg="100%"
          mt="43px"
          mtLg="40px"
          mtMd="32px"
        >
          <a
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.lcx.app&hl=en_IN&gl=US&pli=1"
            target="_blank"
          >
            <ImgElement
              loading="lazy"
              src={!isTheme ? IMAGE_PREFIX.GoogleAppIcon : IMAGE_PREFIX_DARK.GoogleAppIcon}
              alt="App Icon"
              paddingRight="44px"
              paddingRightSm="24px"
              heightSm="40px"
            />
            {/* <GoogleAppIcon className="pd-r-40 pd-md-r-24 hght-md-40"/> */}
          </a>

          <a
            rel="noreferrer"
            href="https://apps.apple.com/us/app/lcx-regulated-crypto-exchange/id1604594068"
            target="_blank"
          >
            <ImgElement
              loading="lazy"
              src={!isTheme ? IMAGE_PREFIX.IphoneAppIcon : IMAGE_PREFIX_DARK.IphoneAppIcon}
              alt="Iphone App Icon"
              paddingRight="44px"
              paddingRightSm="24px"
              heightSm="40px"
            />
            {/* <AppleAppIcon className="pd-r-40 pd-md-r-24 hght-md-40"/> */}
          </a>

          <BannerBox
            border="1px solid #969799"
            radius="5px"
            position="relative"
            cursorPointer="pointer"
            height={width > 767 ? "58px" : "42px"}
          >
            <QRCode
              onClick={() => setShowQrCode(true)}
              value="https://lcxapp.page.link/dapp"
              size={width > 767 ? "56" : "40"}
              renderAs="svg"
              bgColor={"#263645"}
              fgColor={"#fff"}
              level={"H"}
              includeMargin="false"
              imageSettings={{
                src: IMAGE_PREFIX.WhiteLcxLogo,
                x: undefined,
                y: undefined,
                height: 12,
                width: 12,
                excavate: true
              }}
              style={{
                borderRadius: "4px"
              }}
            />

            {showQrCode && (
              <BannerBox
                ref={ref}
                width="164px"
                height="160px"
                widthLg="108px"
                heightLg="108px"
                widthMd="96px"
                heightMd="100px"
                position="absolute"
                border="1px solid #969799"
                radius="5px"
                display="block"
                bck="#263645"
                top="-52px"
                left="68px"
                BorderRadius="5px"
                topLg="auto"
                bottomLg="-113px"
                leftLg="auto"
                rightLg="0"
              >
                <QRCode
                  value="https://lcxapp.page.link/dapp"
                  size={width > 1024 ? "164" : width > 767 ? "108" : "96"}
                  renderAs="svg"
                  bgColor={"#263645"}
                  fgColor={"#fff"}
                  level={"H"}
                  includeMargin="false"
                  imageSettings={{
                    src: IMAGE_PREFIX.WhiteLcxLogo,
                    x: undefined,
                    y: undefined,
                    height: 24,
                    width: 24,
                    excavate: true
                  }}
                  style={{
                    borderRadius: "4px",
                    width: "100%",
                    height: "100%"
                  }}
                />
              </BannerBox>
            )}
          </BannerBox>
        </BannerBox>
      </BannerBox>

      <BannerBox
        width="50%"
        widthLg="100%"
        widthMd="80%"
        mtLg="64px"
        displayLg="flex"
        justifyContentLg="center"
      >
        <figure>
          <ImgElement
            loading="lazy"
            src={!isTheme ? IMAGE_PREFIX.MobileAppIcon : IMAGE_PREFIX_DARK.IphoneIcondark}
            alt="Mobile App"
            maxWidth="100%"
            height="auto"
          />
        </figure>
      </BannerBox>
    </BannerBox>
  );
};

export default MobileAppComponent;
