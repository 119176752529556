import styled, { css } from "styled-components";
import { MarginStyle } from "../Reusable/Margin.style";
import { PaddingStyle } from "../Reusable/Padding.style";
import { CommanHeightStyle } from "../Reusable/Height.style";
import { BorderCommanStyle } from "../Reusable/Border.style";

const H1Tag = styled.h1`
  font-size: ${(props) => props.size || "52px"};
  color: ${(props) => props.color || props.theme.bodyText};
  font-weight: ${(props) => props.weight || "bold"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.radius && `border-radius: ${props.radius}`};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};
  ${(props) => props.ml && `margin-left: ${props.ml}`};
  ${(props) => props.mr && `margin-right: ${props.mr}`};
  ${(props) => props.cursor && `cursor: ${props.cursor}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.bck && `background-color: ${props.bck}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.textOverflow && `text-overflow: ${props.textOverflow}`};

  @media (max-width: 1024px) {
    font-size: ${(props) => props.sizeLg || "44px"};
    ${(props) => props.displayLg && `display: ${props.displayLg}`};
    ${(props) => props.alignItemsLg && `align-items: ${props.alignItemsLg}`};
    ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg}`};
    ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`};
    ${(props) => props.mtLg && `margin-top: ${props.mtLg}`};
    ${(props) => props.mbLg && `margin-bottom: ${props.mbLg}`};
    ${(props) => props.mlLg && `margin-left: ${props.mlLg}`};
    ${(props) => props.mrLg && `margin-right: ${props.mrLg}`};
    ${(props) => props.lineHeightLg && `line-height: ${props.lineHeightLg}`};
  }
  @media (max-width: 767px) {
    font-size: ${(props) => props.sizeMd || "24px"};
    ${(props) => props.displayMd && `display: ${props.displayMd}`};
    ${(props) => props.alignItemsMd && `align-items: ${props.alignItemsMd}`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd}`};
    ${(props) => props.mbMd && `margin-bottom: ${props.mbMd}`};
    ${(props) => props.mlMd && `margin-left: ${props.mlMd}`};
    ${(props) => props.mrMd && `margin-right: ${props.mrMd}`};
    ${(props) => props.lineHeightMd && `line-height: ${props.lineHeightMd}`};
  }

  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.subText};
    `};

  ${(props) =>
    props.txtDanger &&
    css`
      color: ${(props) => props.color || props.theme.danger};
    `};

  ${(props) =>
    props.txtSuccess &&
    css`
      color: ${(props) => props.color || props.theme.success};
    `};

  ${(props) =>
    props.txtPrimary &&
    css`
      color: ${(props) => props.color || props.theme.primary};
    `};

  ${(props) =>
    props.wordWrap &&
    css`
      word-break: break-all;
      word-wrap: break-word;
    `};

  ${(props) =>
    props.TruncateThreeDot &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}

  ${({ PrimaryGradientColor, theme }) => PrimaryGradientColor && `
    background: ${theme.PrimaryGradientColor};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}
`;

const HeadingStyleCommon = css`
  color: ${(props) => props.color || props.theme.bodyText};
  font-weight: ${(props) => props.weight || "500"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  
  ${MarginStyle}
  ${PaddingStyle}
  ${CommanHeightStyle}
  ${BorderCommanStyle}

  ${(props) =>
    props.border &&
    `border: ${props.border !== true ? props.border : ` 1px solid ${props.theme.borderColor}`}`};
  ${(props) =>
    props.borderBottom &&
    `border-bottom: ${
      props.borderBottom !== true ? props.borderBottom : ` 1px solid ${props.theme.borderColor}`
    }`};

  ${({ PrimaryGradientColor, theme }) => PrimaryGradientColor && `
    background: ${theme.PrimaryGradientColor};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}

  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.radius && `border-radius: ${props.radius}`};
  ${(props) => props.cursor && `cursor: ${props.cursor}`};
  ${(props) => props.display && `display: ${props.display}`};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent}`};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.bck && `background-color: ${props.bck}`};
  ${(props) => props.minWidth && `min-width: ${props.minWidth}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.textWrap && `text-wrap: ${props.textWrap}`};
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace}`};
  ${(props) => props.textOverflow && `text-overflow: ${props.textOverflow}`};
  ${(props) => props.overflow && `overflow: ${props.overflow}`};
  ${(props) => props.gap && `gap: ${props.gap}`};
  ${(props) => props.opacity && `opacity: ${props.opacity}`};
  ${(props) =>
    props.ColGap &&
    `column-gap: ${props.ColGap}`}; // The Column-Gap property specifies the gap between the columns in grid, flexbox or multi-column layout.

  @media (max-width: 1024px) {
    ${(props) =>
      props.borderLg &&
      `border: ${
        props.borderLg !== true ? props.borderLg : ` 1px solid ${props.theme.borderColor}`
      }`};
    ${(props) => props.displayLg && `display: ${props.displayLg}`};
    ${(props) => props.alignItemsLg && `align-items: ${props.alignItemsLg}`};
    ${(props) => props.textAlignLg && `text-align: ${props.textAlignLg}`};
    ${(props) => props.maxWidthLg && `max-width: ${props.maxWidthLg}`};
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg} !important`};
    ${(props) => props.lineHeightLg && `line-height: ${props.lineHeightLg}`};
    ${(props) => props.weightLg && `font-weight: ${props.weightLg}`};
  }

  @media (max-width: 767px) {
    ${(props) =>
      props.borderMd &&
      `border: ${
        props.borderMd !== true ? props.borderMd : ` 1px solid ${props.theme.borderColor}`
      }`};
    ${(props) => props.displayMd && `display: ${props.displayMd}`};
    ${(props) => props.alignItemsMd && `align-items: ${props.alignItemsMd}`};
    ${(props) => props.maxWidthMd && `max-width: ${props.maxWidthMd}`};
    ${(props) => props.textAlignMd && `text-align: ${props.textAlignMd}`};
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd} !important`};
    ${(props) => props.lineHeightMd && `line-height: ${props.lineHeightMd}`};
  }

  ${(props) =>
    props.txtOrderBookHover &&
    css`
      color: ${(props) => props.theme.orderBookHoverValue};
    `};
  ${(props) =>
    props.NotificationSuccessTxt &&
    css`
      color: #012243;
    `};

  ${(props) =>
    props.txtGrey &&
    css`
      color: ${(props) => props.color || props.theme.subText};
    `};

  ${(props) =>
    props.onHoverTxtGrey &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.subText};
      }
    `};

  ${(props) =>
    props.BodyColor &&
    css`
      color: ${(props) => props.color || props.theme.bodyText};
    `};

  ${(props) =>
    props.OnHoverBodyColor &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.bodyText};
      }
    `};

  ${(props) =>
    props.txtDanger &&
    css`
      color: ${(props) => props.color || props.theme.danger};
    `};

  ${(props) =>
    props.onHoverTxtDanger &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.danger};
      }
    `};

  ${(props) =>
    props.txtSuccess &&
    css`
      color: ${(props) => props.color || props.theme.success};
    `};

  ${(props) =>
    props.OnHoverTxtSuccess &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.success};
      }
    `};

  ${(props) =>
    props.txtPrimary &&
    css`
      color: ${(props) => props.color || props.theme.primary};
    `};

  ${(props) =>
    props.onHoverTxtPrimary &&
    css`
      &:hover {
        color: ${(props) => props.color || props.theme.primary};
      }
    `};

  ${(props) =>
    props.txtWarning &&
    css`
      color: ${(props) => props.color || props.theme.warningTxt};
    `};

  ${(props) =>
    props.txtToolTipRed &&
    css`
      color: ${(props) => props.theme.dangerToolTip};
    `};

  ${(props) =>
    props.CardGrey &&
    css`
      background: ${(props) => props.theme.mainBackground};
    `};

  ${(props) =>
    props.TruncateThreeDot &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}

  ${(props) =>
    props.ExcCircleBar &&
    css`
      position: relative;
      width: 88px !important;
      height: 88px !important;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      z-index: 1;
    `};
  ${(props) =>
    props.ExcCircleBarCompleted &&
    css`
      background: #008000 !important;
    `};
  ${(props) =>
    props.ExcCircleBarActive &&
    css`
      border: 1px solid #707070 !important;
    `};
  ${(props) =>
    props.ExcCircleBarRejected &&
    css`
      border: 1px solid ${(props) => props.theme.rejectedRed} !important;
    `};

  ${(props) =>
    props.ExcCircleBarRight &&
    css`
    &:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 112%;
    border-bottom: 4px dotted #8ed0a0;
    width: 21rem;
    height: 4px;
    z-index: 2;
    @media (max-width: 1180px) {
     width: 27vw;
    }
  `};

  ${(props) =>
    props.OutlineOnlyPrimary &&
    css`
      background-color: transparent;
      border: 0;
      color: ${(props) => props.theme.primary};
      box-shadow: none;
      &:hover {
        color: ${(props) => props.theme.primary};
      }
    `};

  ${(props) =>
    props.OutlineOnlyPrimary2 &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
      box-shadow: none;
      &:hover {
        color: ${(props) => props.theme.primary};
      }
    `};

  ${(props) =>
    props.OutlineOnlySuccess &&
    css`
      background-color: transparent;
      border: 0;
      color: ${(props) => props.theme.success};
      &:hover {
        color: ${(props) => props.theme.success};
      }
    `};

  ${(props) =>
    props.OutlineOnlyDanger &&
    css`
      background-color: transparent;
      border: 0;
      color: ${(props) => props.theme.danger};
      &:hover {
        text-decoration: underline;
        color: ${(props) => props.theme.danger};
      }
    `};

  ${(props) =>
    props.OutlinePrimary &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
      &:hover {
        background-color: ${props.theme.primary};
        // border: 0 ;
        color: ${props.theme.mainBackground};
      }
    `};

  ${(props) =>
    props.OutlineLightPrimary &&
    css`
      background-color: ${props.theme.level3CardTC};
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
      &:hover {
        background-color: ${props.theme.level3CardGrey};
        color: ${props.theme.primary};
      }
    `};
  ${(props) =>
    props.OutlineLightPrimary2 &&
    css`
      background-color: ${props.theme.level3CardTC};
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
      &:hover {
        background-color: ${(props) => props.theme.level3CardGrey};
        color: ${props.theme.primary};
      }
    `};

  ${(props) =>
    props.PrimaryButton &&
    css`
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.mainBackground};
    `};
  ${(props) =>
    props.ParticipateBtn &&
    css`
      background-color: ${({ theme }) => theme.primaryButton};
      color: ${({ theme }) => theme.mainBackground};
    `};

  ${(props) =>
    props.DangerButton &&
    css`
      background-color: ${({ theme }) => theme.danger};
      color: ${({ theme }) => theme.mainBackground};
    `};
  ${(props) =>
    props.DangerButtonFees &&
    css`
      border: 1px solid ${({ theme }) => theme.danger};
      color: ${({ theme }) => theme.danger};
      background: transparent;
    `};

  ${(props) =>
    props.SuccessButton &&
    css`
      background-color: ${({ theme }) => theme.success};
      color: ${({ theme }) => theme.mainBackground};
    `};

  ${(props) =>
    props.ButtonHollow &&
    css`
      font-weight: 500;
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.borderColor};
      color: ${({ theme }) => theme.subText};
    `};

  ${({ backgroundSky, theme }) => backgroundSky && css `background: ${theme.bgActiveTable};`}
`;

export { H1Tag, HeadingStyleCommon };
