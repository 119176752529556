import React, { useState } from "react";
import { BannerBox, BannerSection } from "../../../assets/StyleComponents/Exchange.style";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style";
import { HeadingSecond } from "../../../assets/StyleComponents/FontSize/headsecond.style";
import { LiTag } from "../../../assets/StyleComponents/FontSize/para.style";
import { RoadMapLeftIcon } from "../../../assets/svgs";

function Roadmap(props) {
  const { values } = props;
  const RoadMapArray = values;
  const [presetYear, setPresentYear] = useState(RoadMapArray[0]?.year || "2024");
  let DataofYear =
    RoadMapArray?.length > 0
      ? RoadMapArray?.filter((data) => data?.year === presetYear)[0]
      : RoadMapArray;

  return (
    <>
      <BannerSection width="100%" display="flex" position="relative" minHeight="500px">
        <BannerBox
          width="10%"
          widthMd="20%"
          display="flex"
          direction="column"
          gap="25px"
          position="sticky"
          mr="7px"
        >
          {RoadMapArray?.map((data) => {
            return (
              <>
                <BannerHeading
                  cursor="pointer"
                  ActiveRoadmap={presetYear === data?.year && true}
                  onClick={() => setPresentYear(data?.year)}
                  padding={presetYear === data?.year ? "0 0 0 5px" : "0 0 0 9px"}
                >
                  {data?.year}
                </BannerHeading>
              </>
            );
          })}
        </BannerBox>
        {/* <BannerBox> */}
        <BannerBox
          width="90%"
          maxHeight="500px"
          overflow="visible scroll"
          paddingTop="12px"
          paddingLeft="12px"
          // display="flex"
        >
          <BannerBox width="8px" BorderTimeLine position="absolute" minHeight="500px"></BannerBox>
          {DataofYear?.data?.map((values) => {
            return (
              <>
                <BannerBox
                  key={values?.points}
                  padding="12px 0 12px 18px"
                  PrimaryBorderCardTimeline
                  position="relative"
                >
                  <RoadMapLeftIcon
                    absolute
                    left="-9px"
                    top="-12px"
                    zIndex="100000000"
                    overflow="visible"
                  />
                  <HeadingSecond
                    GradientColor
                    size="20px"
                    weight="500"
                    style={{ color: "linear-gradient(294.72deg, #FF4581 9.05%, #4388DD 79.28%)" }}
                  >
                    {values?.quarter}
                  </HeadingSecond>
                  <ul>
                    {values?.points?.map((data) => {
                      return (
                        <LiTag key={data} size="16px" weight="400" BodyColor>
                          {data}
                        </LiTag>
                      );
                    })}
                  </ul>
                </BannerBox>
              </>
            );
          })}
        </BannerBox>
      </BannerSection>
    </>
  );
}

export default Roadmap;
