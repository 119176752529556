import { addNumbers, multiplyNumbers, setPrecision } from "./math";

export const updateOrders = (allOrders, type, orderData) => {
  let orders = [...allOrders];

  if (orderData) {
    let index = orders.findIndex((data) => data[0] === orderData[0]);
    if (index === -1) {
      orders.unshift([orderData[0], orderData[1]]);
    } else {
      orders[index][1] = orderData[1];
    }
  }

  orders = orders.filter((order) => order[1] !== 0);
  orders.sort((a, b) => (type === "buy" ? b[0] - a[0] : a[0] - b[0]));
  return orders;
};

export const getAllBids = (orders, precision) => {
  let count = 0;
  let priceMap = {};
  let i = 0;
  let len = orders.length;
  let bids = [];

  while (i < len) {
    let data = orders[i];
    let price = +data[0].toFixed(precision);
    if (price > 0) {
      if (priceMap[price]) {
        let index = +priceMap[price];
        bids[index][1] = addNumbers(bids[index][1], data[1]);
      } else {
        priceMap[price] = `${count}`;
        bids.push([price, data[1]]);
        count += 1;
      }

      if (count >= 25) {
        break;
      }
    }

    i++;
  }

  return bids;
};

export const getAllAsks = (orders, precision) => {
  let count = 0;
  let priceMap = {};
  let i = 0;
  let len = orders.length;
  let asks = [];

  while (i < len) {
    let data = orders[i];
    let price = setPrecision(data[0], precision);
    if (price > 0) {
      if (priceMap[price]) {
        let index = +priceMap[price];
        asks[index][1] = addNumbers(asks[index][1], data[1]);
      } else {
        priceMap[price] = `${count}`;
        asks.push([price, data[1]]);
        count += 1;
      }

      if (count >= 25) {
        break;
      }
    }

    i++;
  }

  return asks;
};

export const updateTickers = (allPairTickers, singleTickerData) => {
  let pair = singleTickerData.pair;
  let pairData = singleTickerData.pairData;
  let prevPairData = allPairTickers[pair];
  let tickers = { ...allPairTickers };
  tickers[pair] = { ...prevPairData, ...pairData };

  return tickers;
};

export const getPairDetail = (symbol) => {
  let base = null,
    quote = null;
  if (symbol) {
    let pair = symbol.split("/");
    if (pair.length === 2) {
      base = pair[0];
      quote = pair[1];
    }
  }

  return { base, quote, symbol };
};

export const checkDecimalPrecision = (value, precision = 8) => {
  if (value) {
    let decimals = value.split(".")[1];
    if (decimals && decimals.length > precision) {
      return false;
    }
  }

  return true;
};

export const getOrderBookGraphData = (orders, side) => {
  let price = 0,
    amount = 0,
    total = 0,
    count = 0,
    product,
    priceSumArray = [],
    amountSumArray = [],
    totalSumArray = [],
    maxOfTotals = 0;

  for (let i = 0; i < orders.length; i++) {
    price = +addNumbers(price, orders[i][0]);
    amount = +addNumbers(amount, orders[i][1]);
    product = +multiplyNumbers(+orders[i][0], +orders[i][1]);
    total = +addNumbers(total, product);

    priceSumArray.push(price);
    amountSumArray.push(amount);
    totalSumArray.push(total);
    maxOfTotals = Math.max(maxOfTotals, product);
    count += 1;
  }

  let data = {
    type: side,
    data: {
      priceSumArray,
      amountSumArray,
      totalSumArray,
      maxOfTotals,
      sumOfTotals: total,
      sumOfAmounts: amount,
      count
    }
  };

  return data;
};
