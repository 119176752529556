import React from "react";
import { DivElement, ModalWrapper, TextWrapper } from "../../../../components/Reusable";
import { CloseIcon } from "../../../../assets/svgs";
import UnorderList from "../../../../components/ReusedComponent/UnorderList/UnorderList";
import { ParaTag } from "../../../../assets/StyleComponents/FontSize/para.style";

const ShowRulesModal = ({ onClose, rulesRecords }) => {
  return (    
    <ModalWrapper>
      <DivElement ModalContainer>
        <DivElement displayBtw>
          <TextWrapper fontSize="16px" fontSizeSm="16px" lineHeight="24px">
            {rulesRecords.description ? `Task Description` : `Task Rules`}
          </TextWrapper>
          <CloseIcon pointer onClick={onClose} />
        </DivElement>
        {rulesRecords.description && (
          <ParaTag txtGrey displayBtw padding="16px 0 24px"> {rulesRecords.description} </ParaTag>
        )}

        {rulesRecords.description && rulesRecords.additionalRules && rulesRecords.additionalRules.length > 0 && (
          <>
            <DivElement displayBtw padding={!rulesRecords.description && "0 0 0 0"}>
              <TextWrapper fontSize="16px" fontSizeSm="16px" lineHeight="24px">
                Task Rules
              </TextWrapper>
            </DivElement>
            {rulesRecords.additionalRules && 
              <UnorderList 
                extraUlStyle={{
                  display:"flex", gap:"16px", 
                  direction:"column"
                }} 
                noListPadding givenList={rulesRecords.additionalRules} 
              />
            }
          </>
        )}
      </DivElement>
    </ModalWrapper>
  );
};

export default ShowRulesModal;