import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { useWindowSize } from "../../../../Hooks/CheckWidthHeight";
import { DivElement, ElementBox } from "../../../../components/Reusable";
import ScrollBar from "../../../../components/Reusable/ScrollBar";
import { SearchIcon } from "../../../../assets/svgs";
import styled, { css } from "styled-components";

const NavLink = styled(Link)`
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.primaryText};

  &:hover {
    color: ${(props) => props.theme.primaryBlue};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.primaryBlue};
    `}

  @media (min-width: 768px) {
    width: 161px;
    height: 36px;
    border-radius: 18px;
    margin: 0 9px;
    border: 1px solid ${(props) => props.theme.inputBorder};

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: ${(props) => props.theme.primaryWhite};
      background-color: ${(props) => props.theme.primaryBlue};
      border-color: ${(props) => props.theme.primaryBlue};
    }

    ${(props) =>
      props.active &&
      css`
        color: ${(props) => props.theme.primaryWhite};
        background-color: ${(props) => props.theme.primaryBlue};
        border-color: ${(props) => props.theme.primaryBlue};
      `}
  }

  @media (max-width: 767px) {
    height: 100%;
    padding: 0 8px;
    margin: 0 11px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
    }

    ${(props) =>
      props.active &&
      css`
        border-bottom: 2px solid ${(props) => props.theme.primaryBlue};
      `}
  }
`;

const SearchInput = styled.input`
  width: 268px;
  height: 46px;
  border-radius: 5px;
  padding: 0 16px;
  color: ${(props) => props.theme.primaryText};
  background-color: ${(props) => props.theme.cardBg};
  border: 1px solid ${(props) => props.theme.inputBorder};

  &::placeholder {
    font-size: 14px;
    color: ${(props) => props.theme.subText};
  }

  &:focus,
  &:active {
    outline: none;
    border: 1px solid ${(props) => props.theme.inputBorder};
  }

  @media (max-width: 1024px) {
    width: 224px;
    height: 48px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 0;
  }
`;

const TABS = [
  { label: "Live Token Sale 🔥", tab: "ONGOING" },
  { label: "Upcoming Sale", tab: "UPCOMING" },
  { label: "Participated", tab: "PARTICIPATED" },
  { label: "Completed", tab: "COMPLETED" }
];

const SaleNavlinks = ({ activeTab, dashboardType, isLoggedIn }) => {
  return (
    <Fragment>
      {TABS.map((tab) => (
        <Fragment key={tab.tab}>
          {((tab.tab === "PARTICIPATED" && dashboardType === "USER" && isLoggedIn) ||
            tab.tab !== "PARTICIPATED") && (
            <NavLink
              to={`${tab.tab.toLocaleLowerCase()}`}
              active={tab.tab === activeTab ? true : null}
              key={tab.tab}
            >
              {tab.label}
            </NavLink>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

const SaleNavbar = ({ setSearchFilter, dashboardType, activeTab, isLoggedIn }) => {
  const window = useWindowSize();

  return (
    <DivElement
      flexJustifyBtw
      flexDirection="row-reverse"
      flexDirectionMd="column-reverse"
      flexDirectionSm="column"
    >
      <DivElement
        relative
        flexAlignCenter
        justifyContentMd="flex-end"
        widthSm="100%"
        flexMd="1 1 30%"
        marginTopMd="24px"
        marginTopSm="0"
        marginBottomSm="20px"
      >
        <SearchInput
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchFilter(e.target.value)}
        />
        <DivElement absolute right="16px" displayCenter bgCard>
          <SearchIcon />
        </DivElement>
      </DivElement>
      <DivElement flex widthSm="100%">
        {window.width >= 767 ? (
          <SaleNavlinks
            activeTab={activeTab}
            dashboardType={dashboardType}
            isLoggedIn={isLoggedIn}
          />
        ) : (
          <ElementBox
            width="100%"
            height="53px"
            borderRadius="6px 6px 0 0"
            padding="0 0 0 37px"
            paddingSm="0"
            bdGrey
            borderBottom="1px solid"
            margin="0"
            marginBottom="24px"
          >
            <ScrollBar>
              <SaleNavlinks
                activeTab={activeTab}
                dashboardType={dashboardType}
                isLoggedIn={isLoggedIn}
              />
            </ScrollBar>
          </ElementBox>
        )}
      </DivElement>
    </DivElement>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default compose(connect(mapStateToProps))(SaleNavbar);
