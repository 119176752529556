import { connect } from "react-redux";
import OrderPage from "../views/orderPage/index.jsx";
import { logout } from "../actions/auth";

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme,
  isThemeFecthing: state.theme.isThemeFetching,
  isLoggedIn: state.auth.isLoggedIn,
  userData: state.dashboard.getUserData,
  isWholeLoading: state.dashboard.isWholeLoading,
  isLogoutFetching: state.dashboard.isLogoutFetching,
  marketErrorGet: state.market.errorGet,
  tradeErrorGet: state.trade.errorGet,
  dashErrorGet: state.dashboard.errorGet,
  settingErrorGet: state.setting.errorGet
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
