import styled from "styled-components";
import { HeadingStyleCommon } from "./fonts.style";

const HeadingSix = styled.h6`
  ${HeadingStyleCommon}
  font-size: ${(props) => props.size || "16px"};

  @media (max-width: 768px) {
    font-size: ${(props) => props.sizeLg || "16px"};
  }

  @media (max-width: 767px) {
    font-size: ${(props) => props.sizeMd || "14px"};
  }

  @media (max-width: 560px) {
    font-size: ${(props) => props.sizeSm || "14px"};
  }
`;

export { HeadingSix };
