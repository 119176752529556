export const bondSummary = [
  {
    bondName: "EURt7",
    yeild: "7%",
    issuanceAmount: "10 Million EUR",
    perUnitCost: "1,000 EUR",
    payoutPeriod: "Quarterly",
    minimumAmount: "1,000 EUR",
    isin: "LI1164320387",
    noticePeriod: "Two Months",
    termination: "Quarterly",
    expiresOn: "1st Feb 2030",
    issuer: "LCX AG, Liechtenstein",
    aboutIssuanceAmount: "10 Million EUR",
    coin: "EUR",
    min: "1,000",
    max: "10 Million",
    fullCoinName: "Euro"
  },
  {
    bondName: "BTCt7",
    yeild: "7%",
    issuanceAmount: "500 BTC",
    perUnitCost: "0.01 BTC",
    payoutPeriod: "Quarterly",
    minimumAmount: "0.01 BTC",
    isin: "LI1251378645",
    noticePeriod: "Two Months",
    termination: "Quarterly",
    expiresOn: "1st Feb 2030",
    issuer: "LCX AG, Liechtenstein",
    aboutIssuanceAmount: "500 Bitcoin",
    coin: "BTC",
    min: "0.01",
    max: "500",
    fullCoinName: "Bitcoin"
  },
  {
    bondName: "ETHt7",
    yeild: "7%",
    issuanceAmount: "7000 ETH",
    perUnitCost: "0.1 ETH",
    payoutPeriod: "Quarterly",
    minimumAmount: "0.1 ETH",
    isin: "LI1251379593",
    noticePeriod: "Two Months",
    termination: "Quarterly",
    expiresOn: "1st Feb 2030",
    issuer: "LCX AG, Liechtenstein",
    aboutIssuanceAmount: "7000 Ethereum",
    coin: "ETH",
    min: "0.1",
    max: "7000",
    fullCoinName: "Ethereum"
  },
  {
    bondName: "LCXt7",
    yeild: "7%",
    issuanceAmount: "100 Million LCX",
    perUnitCost: "1 LCX",
    payoutPeriod: "Quarterly",
    minimumAmount: "1 LCX",
    isin: "LI1251379643",
    noticePeriod: "Two Months",
    termination: "Quarterly",
    expiresOn: "1st Feb 2030",
    issuer: "LCX AG, Liechtenstein",
    aboutIssuanceAmount: "100 Million LCX",
    coin: "LCX",
    min: "1",
    max: "100 Million",
    fullCoinName: "LCX"
  }
];

const emptyBondDetails = {
  bondName: "",
  yeild: "",
  issuanceAmount: "",
  perUnitCost: "",
  payoutPeriod: "",
  minimumAmount: "",
  isin: "",
  noticePeriod: "",
  termination: "",
  expiresOn: "",
  issuer: "",
  aboutIssuanceAmount: "",
  coin: "",
  min: "",
  max: "",
  fullCoinName: ""
};

export const getBondInfo = (bondId) => {
  const id = Number(bondId);
  if (isNaN(id)) {
    return emptyBondDetails;
  }
  if (id < 1 || id > 4) {
    return emptyBondDetails;
  }
  return bondSummary[id - 1];
};
