import { DivElement, DropdownWrapper, RouterLink, TextWrapper } from "../Reusable";
import {
  DepositIcon,
  EyeCloseIcon,
  EyeOpenIcon,
  InternalTransferIcon,
  WithdrawalIcon
} from "../../assets/svgs";
import { Fragment, useContext, useMemo, useState } from "react";
import { WalletContext } from "../../contexts/WalletPage/BalancePage/wallet";
import SharedNumberComponent from "../ReusedComponent/SharedNumberComponent";
import { add, new_BN } from "../../utils/bigNumber";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { createStructuredSelector } from "reselect";
import {
  getAccountTotalUSD,
  getExchangeTotalUSD,
  getisFetchingAccountWallet,
  getisFetchingExchangeWallet
} from "../../Modules/Wallet/WalletBalance/selectors";
import { useSelector } from "react-redux";
import { bool } from "prop-types";
import BalanceDropDownHoc from "./BalanceDropDownHoc";
import InsufficientBalance from "../InsufficientBalance";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { ExpandMoreIcon } from "../../assets/svgs";

const BalanceWrapper = styled(DivElement)`
  padding: 6px 16px;
  margin-bottom: 4px;
  :hover {
    background-image: linear-gradient(76deg, rgb(4, 112, 225, 0.08) 2%, rgb(64, 163, 199, 0.08));
    border-right: 4px solid #4595e7;
  }
`;

const TotalWalletBalance = ({ isLoggedIn, icons }) => {
  let { exchWalletError, mainWalletError, hideStatus, exchWallet, mainBalance } =
    useContext(WalletContext);
  const { apiAccountTotalUSD, apiExchangeTotalUSD, isFetchingAccounts, isFetchingExchange } =
    useSelector(mapStateToProps);

  return useMemo(() => {
    let TotalUSDValue = 0;
    if (mainWalletError) TotalUSDValue = add(0, mainBalance?.totalBalance?.inUSD || 0);
    else if (exchWalletError) TotalUSDValue = add(exchWallet?.totalBalance?.inUSD || 0, 0);
    else
      TotalUSDValue = add(
        exchWallet?.totalBalance?.inUSD || 0,
        mainBalance?.totalBalance?.inUSD || 0
      );

    if (isNaN(TotalUSDValue)) TotalUSDValue = 0;
    return (
      <>
        <TextWrapper fontSize="14px" lineHeight="20px" display="flex" alignItems="end">
          {hideStatus ? "$" : ""}
          <SharedNumberComponent
            prevValue={TotalUSDValue}
            digit={7}
            status={true}
            checkNew={true}
          />

          {icons && <ExpandMoreIcon />}
        </TextWrapper>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiAccountTotalUSD.inUSD,
    apiExchangeTotalUSD.inUSD,
    isFetchingAccounts,
    isFetchingExchange,
    hideStatus
  ]);
};

const BalanceDropdown = ({ openHamburger, setOpenHamburger }) => {
  const [modalType, setModalType] = useState("");
  const [selectedCoin, setSelectedCoin] = useState("LCX");
  const { hideStatus, setHideStatus } = useContext(WalletContext);
  // const { apiAccountTotalUSD, apiExchangeTotalUSD } = useSelector(mapStateToProps);
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;
  const selectedPair = useSelector((state) => state.trading.selectedPair);
  const userData = useSelector((state) => state.dashboard.userData);
  const prevLang = localStorage.getItem("ljs-lang");

  const sendToWalletPage = (givenName) => {
    history.push(`${prevLang ? `/${prevLang}` : userData.preferredLanguage ? `/${userData.preferredLanguage}` : ""}/wallet/balances`, { state: givenName });
  };

  const onRouteClickHandler = (e, type) => {
    e.preventDefault();
    if (path.includes("/trade")) {
      setSelectedCoin(selectedPair.base);
    }
    setModalType(type);
  };

  const onClickHandler = () => {
    if (openHamburger) {
      setOpenHamburger(false);
    }
  };
  let { exchWallet, mainBalance } = useContext(WalletContext);

  return (
    <Fragment>
      <DropdownWrapper
        label={<TotalWalletBalance icons={true} />}
        minWidth="160px"
        alignRight="24px"
        containerProps={{
          padding: "0 16px",
          paddingSm: "12px",
          paddingRight: "0px",
          onClick: onClickHandler
        }}
      >
        <BalanceWrapper
          bgPrimary
          borderRadius="5px 5px 0 0"
          onClick={() => sendToWalletPage("exchange")}
        >
          <TextWrapper fontSize="12px" lineHeight="18px" secondary>
            Total balance
          </TextWrapper>
          <TextWrapper fontSize="12px" lineHeight="18px">
            <TotalWalletBalance icons={false} />
          </TextWrapper>
        </BalanceWrapper>
        <BalanceWrapper onClick={() => sendToWalletPage("account")}>
          <TextWrapper fontSize="12px" lineHeight="18px" secondary>
            Main balance
          </TextWrapper>
          <TextWrapper fontSize="12px" lineHeight="18px">
            {hideStatus ? "$" : ""}
            <SharedNumberComponent
              prevValue={new_BN(exchWallet?.totalBalance?.inUSD || 0)}
              digit={7}
              status={true}
            />
          </TextWrapper>
        </BalanceWrapper>
        <BalanceWrapper onClick={() => sendToWalletPage("exchange")}>
          <TextWrapper fontSize="12px" lineHeight="18px" secondary>
            Exchange balance
          </TextWrapper>
          <TextWrapper fontSize="12px" lineHeight="18px">
            {hideStatus ? "$" : ""}
            <SharedNumberComponent
              prevValue={new_BN(mainBalance?.totalBalance?.inUSD || 0)}
              digit={7}
              status={true}
            />
          </TextWrapper>
        </BalanceWrapper>
        <RouterLink
          padding="6px 16px"
          primary
          alignCenter
          whiteSpace="nowrap"
          fontSize="12px"
          marginBottom="4px"
          blueHover
          onClick={(e) => onRouteClickHandler(e, "DEPOSIT")}
        >
          <DepositIcon width="20px" height="20px" marginRight="8px" />
          Deposit
        </RouterLink>
        <RouterLink
          to="/wallet/withdrawals"
          padding="6px 16px"
          primary
          alignCenter
          whiteSpace="nowrap"
          fontSize="12px"
          marginBottom="4px"
          blueHover
          onClick={(e) => onRouteClickHandler(e, "WITHDRAWAL")}
        >
          <WithdrawalIcon width="20px" height="20px" marginRight="8px" />
          Withdraw
        </RouterLink>
        <DivElement
          padding="6px 16px"
          dAlignCenter
          whiteSpace="nowrap"
          fontSize="12px"
          marginBottom="4px"
          blueHover
          onClick={(e) => onRouteClickHandler(e, "TRANSFER")}
        >
          <InternalTransferIcon width="20px" height="20px" marginRight="8px" />
          <TextWrapper fontSize="12px" fontWeight="300" nowrap>
            Internal transfer
          </TextWrapper>
        </DivElement>
        <DivElement
          padding="6px 16px"
          dAlignCenter
          whiteSpace="nowrap"
          fontSize="12px"
          marginBottom="4px"
          blueHover
          onClick={() => setHideStatus(!hideStatus)}
        >
          {hideStatus ? (
            <EyeOpenIcon width="20px" height="20px" marginRight="8px" />
          ) : (
            <EyeCloseIcon width="20px" height="20px" marginRight="8px" />
          )}
          <TextWrapper fontSize="12px" fontWeight="300" nowrap>
            {`${hideStatus ? "Hide" : "Unhide"} balance`}
          </TextWrapper>
        </DivElement>
      </DropdownWrapper>

      {modalType && (
        <InsufficientBalance
          coin={selectedCoin}
          modalType={modalType}
          onClose={() => setModalType("")}
        />
      )}
    </Fragment>
  );
};

BalanceDropdown.propTypes = {
  isFetchingAccounts: bool.isRequired,
  isFetchingExchange: bool.isRequired
};

const mapStateToProps = createStructuredSelector({
  isFetchingAccounts: getisFetchingAccountWallet(),
  isFetchingExchange: getisFetchingExchangeWallet(),
  apiAccountTotalUSD: getAccountTotalUSD(),
  apiExchangeTotalUSD: getExchangeTotalUSD()
});

export default BalanceDropDownHoc(BalanceDropdown);
