import styled, { css } from "styled-components";

const FigureComponent = styled.figure`
  ${(props) => props.weight && `font-weight: ${props.weight};`};
  ${(props) => props.width && `width: ${props.width};`};
  ${(props) => props.margin && `margin: ${props.margin};`};
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`};
  ${(props) => props.position && `position: ${props.position};`};
  ${(props) => props.padd && `padding: ${props.padd};`};
  ${(props) => props.paddTop && `padding-top: ${props.paddTop};`};
  ${(props) => props.paddBottom && `padding-bottom: ${props.paddBottom};`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth} !important;`};
  ${(props) => props.minWidth && `min-width: ${props.minWidth} !important;`};
  ${(props) => props.borderImg && `border-image: ${props.borderImg};`};
  ${(props) => props.bckImage && `background-image: ${props.theme.buttonGradient};`};
  ${(props) => props.bckImg && `background-image: ${props.bckImg};`};
  ${(props) => props.bckOrigin && `background-origin: ${props.bckOrigin};`};
  ${(props) => props.bckClip && `background-clip: ${props.bckClip};`};
  ${(props) => props.maxHeight && `max-height: ${props.maxHeight} !important;`};
  ${(props) => props.minHeight && `min-height: ${props.minHeight} !important;`};
  ${(props) => props.top && `top: ${props.top};`};
  ${(props) => props.bottom && `bottom: ${props.bottom};`};
  ${(props) => props.left && `left: ${props.left};`};
  ${(props) => props.right && `right: ${props.right};`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex};`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`};
  ${(props) => props.justify && `justify-content: ${props.justify};`};
  ${(props) => props.mt && `margin-top: ${props.mt};`};
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace};`};
  ${(props) => props.size && `font-size: ${props.size};`};

  @media (max-width: 1024px) {
    ${(props) => props.widthLg && `width: ${props.widthLg} !important;`};
    ${(props) => props.minWidthLg && `min-width: ${props.minWidthLg} !important;`};
    ${(props) => props.displayLg && `display: ${props.displayLg};`};
    ${(props) => props.btnHeightLg && `height: ${props.btnHeightLg};`};
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg}px !important;`};
    ${(props) => props.givenMargnLg && `margin: ${props.givenMargnLg} !important`};
    ${(props) => props.paddingLg && `padding: ${props.paddingLg} !important`};
    ${(props) => props.paddTopLg && `padding-top: ${props.paddTopLg};`};
    ${(props) => props.paddBottomLg && `padding-bottom: ${props.paddBottomLg};`};
  }
  @media (max-width: 767px) {
    ${(props) => props.widthMd && `width: ${props.widthMd} !important;`};
    ${(props) => props.minWidthMd && `min-width: ${props.minWidthMd} !important;`};
    ${(props) => props.displayMd && `display: ${props.displayMd};`};
    ${(props) => props.btnHeightMd && `height: ${props.btnHeightMd};`};
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd} !important;`};
    ${(props) => props.givenMargnMd && `margin: ${props.givenMargnMd} !important`};
    ${(props) => props.paddingMd && `padding: ${props.paddingMd} !important`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd} !important`};
    ${(props) => props.paddTopMd && `padding-top: ${props.paddTopMd};`};
    ${(props) => props.paddBottomMd && `padding-bottom: ${props.paddBottomMd};`};
  }
  ${(props) => props.Circleparent && css`
  position: relative;
  &:after {
   content: '';
   position: absolute;
   top: 19%;
   right: -13%;
   border-bottom: 2px dotted #38393D;
   width: 26%;
   height: 46px;
   display: flex;
   justify-content: center;

   @media (max-width: 1024px) {
    border-bottom: 0;
    border-left: 2px dotted #38393D;
    top: 103%;
    height: 70px !important;
    right: 0;
    margin: 0 auto;
    left: 0;
    width: 1%;
   }
   @media (max-width: 767px) {
    border-bottom: 0;
    border-left: 2px dotted #38393D;
    top: 103%;
    right: 0;
    margin: 0 auto;
    left: 0;
    width: 1%;
   }
   &:last-child {
    margin-bottom: 0rem !important;
    &:after {
     border: none;
    }
   }
  `};
  };

`;
const FigureCaptionComponent = styled.figcaption`
  ${(props) => props.size && `font-size: ${props.size};`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`};
  ${(props) => props.weight && `font-weight: ${props.weight};`};
  ${(props) => props.width && `width: ${props.width};`};
  ${(props) => props.margin && `margin: ${props.margin};`};
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`};
  ${(props) => props.position && `position: ${props.position};`};
  ${(props) => props.padd && `padding: ${props.padd};`};
  ${(props) => props.paddTop && `padding-top: ${props.paddTop};`};
  ${(props) => props.paddBottom && `padding-bottom: ${props.paddBottom};`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth} !important;`};
  ${(props) => props.minWidth && `min-width: ${props.minWidth} !important;`};
  ${(props) => props.borderImg && `border-image: ${props.borderImg};`};
  ${(props) => props.bckImage && `background-image: ${props.theme.buttonGradient};`};
  ${(props) => props.bckImg && `background-image: ${props.bckImg};`};
  ${(props) => props.bckOrigin && `background-origin: ${props.bckOrigin};`};
  ${(props) => props.bckClip && `background-clip: ${props.bckClip};`};
  ${(props) => props.maxHeight && `max-height: ${props.maxHeight} !important;`};
  ${(props) => props.minHeight && `min-height: ${props.minHeight} !important;`};
  ${(props) => props.top && `top: ${props.top};`};
  ${(props) => props.bottom && `bottom: ${props.bottom};`};
  ${(props) => props.left && `left: ${props.left};`};
  ${(props) => props.right && `right: ${props.right};`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex};`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`};
  ${(props) => props.justify && `justify-content: ${props.justify};`};
  ${(props) => props.mt && `margin-top: ${props.mt};`};
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace};`};

  @media (max-width: 1024px) {
    ${(props) => props.widthLg && `width: ${props.widthLg} !important;`};
    ${(props) => props.minWidthLg && `min-width: ${props.minWidthLg} !important;`};
    ${(props) => props.displayLg && `display: ${props.displayLg};`};
    ${(props) => props.btnHeightLg && `height: ${props.btnHeightLg};`};
    ${(props) => props.sizeLg && `font-size: ${props.sizeLg}px !important;`};
    ${(props) => props.givenMargnLg && `margin: ${props.givenMargnLg} !important`};
    ${(props) => props.paddingLg && `padding: ${props.paddingLg} !important`};
    ${(props) => props.paddTopLg && `padding-top: ${props.paddTopLg};`};
    ${(props) => props.paddBottomLg && `padding-bottom: ${props.paddBottomLg};`};
  }
  @media (max-width: 767px) {
    ${(props) => props.widthMd && `width: ${props.widthMd} !important;`};
    ${(props) => props.minWidthMd && `min-width: ${props.minWidthMd} !important;`};
    ${(props) => props.displayMd && `display: ${props.displayMd};`};
    ${(props) => props.btnHeightMd && `height: ${props.btnHeightMd};`};
    ${(props) => props.sizeMd && `font-size: ${props.sizeMd} !important;`};
    ${(props) => props.givenMargnMd && `margin: ${props.givenMargnMd} !important`};
    ${(props) => props.paddingMd && `padding: ${props.paddingMd} !important`};
    ${(props) => props.mtMd && `margin-top: ${props.mtMd} !important`};
    ${(props) => props.paddTopMd && `padding-top: ${props.paddTopMd};`};
    ${(props) => props.paddBottomMd && `padding-bottom: ${props.paddBottomMd};`};
  }
  ${(props) => props.BodyColor && css`
  color: ${(props) => props.color || props.theme.bodyText};
`};
  };

`;



export { FigureComponent, FigureCaptionComponent };