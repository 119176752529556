/* eslint-disable no-debugger */
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory, useLocation } from "react-router-dom";

import Banner from "./components/Banner";
import CompetitionList from "./competitionList";
import ParticipatedComponent from "./ParticipatedComptition/participated";
import { MainElement } from "../../assets/StyleComponents/Exchange.style";
import Faq from "./components/Faq";
import { HalfFooterCommon } from "../../components/Footer/common";
import SetHeaderTitleComponent from "../../components/ChangeHeaderTitle";
import { fetchAvailableLanguagesRequest } from "../../Modules/Generic/action";
import { createStructuredSelector } from "reselect";
import { getTranslationLanguageList } from "../../Modules/Generic/selectors";

const GenericReducer = createStructuredSelector({
  transalationList: getTranslationLanguageList()
});

function MainPage() {
  const userLogin = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.dashboard.userData);
  const { transalationList } = useSelector(GenericReducer);

  const dispatch = useDispatch();
  // const location = useLocation();
  // const history = useHistory();
  const prevLang = localStorage.getItem("ljs-lang");

  useEffect(() => {
    dispatch(fetchAvailableLanguagesRequest());
    // let checkPath = location.pathname?.split("/")[1];
    // if (checkPath && userData.preferredLanguage && checkPath !== userData.preferredLanguage) {
    //   history.push(
    //     `${
    //       userLogin ? `/${userData.preferredLanguage}/trading-competition` : `/trading-competition`
    //     }`
    //   );
    // }
  }, []);

  const SEOContents = {
    name: `TRADING COMPETITION`,
    link: `trading-competition`,
    title: "Join LCX Exchange Crypto Trading Competition Now!",
    description:
      "Compete in the LCX Exchange Crypto Trading Competition. Win prizes while honing your trading skills with secure and efficient transactions. Join now!",
    keywords: `trading competition,trade competition, trading competitions, crypto trading competition, trading competition crypto`,
    canonical: `https://exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/trading-competition`,
    canonicalUrl: `https://exchange.lcx.com`,
    afterLink: `${prevLang ? `/${prevLang}` : "/en"}/trading-competition`,
    lang: `${userLogin && userData.preferredLanguage ? `${userData.preferredLanguage}` : ""}`,
    OgTitle: "Join LCX Exchange Crypto Trading Competition Now!",
    OgDescription:
      "Compete in the LCX Exchange Crypto Trading Competition. Win prizes while honing your trading skills with secure and efficient transactions. Join now!",
    OgURL: `https://exchange.lcx.com${prevLang ? `/${prevLang}` : "/en"}/trading-competition`,
    OgImage: "https://exchange.lcx.com/img/demo.jpg",
    TwTitle: "Join LCX Exchange Crypto Trading Competition Now!",
    TwDescription:
      "Compete in the LCX Exchange Crypto Trading Competition. Win prizes while honing your trading skills with secure and efficient transactions. Join now!"
  };

  let memorizedList = useMemo(() => {
    return (
      transalationList &&
      transalationList.length > 0 &&
      transalationList.filter((data) => data.code !== (prevLang || "en"))
    );
  }, [transalationList]);

  if (memorizedList && memorizedList.length > 0) {
    SEOContents.alternate = memorizedList.map((d) => {
      let obj = {
        hrefLang: d.code,
        lastParam: `/${d.code}/trading-competition`
      };
      return obj;
    });
  }

  return (
    <>
      <SetHeaderTitleComponent Content={SEOContents} />
      <MainElement ExchangeContainer margin="32px auto" mb="80px">
        <Banner
          title={"Trade Battle - Are you ready to compete?"}
          subTitle={`Trade. Win & Conquer`}
          description={`🚀 Get ready for an exciting trading competition - the Trade Battle. Show off your trading skills to become the trading champion. Take control of markets, boost your trading volume, and compete for substantial cash prizes in this unique opportunity!`}
          videoSrc={"https://lcx-exchange.s3.amazonaws.com/others/Comp_1+4k.mp4"}
        />

        <CompetitionList title={"Competitions list 🔥"} type="tradingcompetition" />

        {userLogin && <ParticipatedComponent />}
        <Faq />
      </MainElement>
      <HalfFooterCommon />
    </>
  );
}

export default MainPage;
