/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import AddEditKeyManagementComponent from "./index.jsx";
import DeleteManagmentComponent from "../delete";
import CreateApiModalComponent from "./CreateAPIModal";
import { getApiKeyManagment } from "../../../actions/setting.js";
import { trackPage } from "../../../tracking/track";
import { ButtonComponent } from "../../../assets/StyleComponents/Button.style.js";
import { DropDownButtonComponent } from "../../../assets/StyleComponents/DropDown.style.js";
import {
  BannerBox,
  BannerHeadingSpan,
  MainElement
} from "../../../assets/StyleComponents/Exchange.style.js";
import { Dropdown } from "react-bootstrap";
import { ApiKeysRow } from "../../../assets/StyleComponents/Table/Setting/ApiKeys.style.js";
import CopyToClipboard from "react-copy-to-clipboard";
import { showSuccessMsg } from "../../../utils/notification";
import { Enable2FA } from "../../../components/Alert";
import { DeleteRedIcon, EditIconBlue, ThreeDotsIcon } from "../../../assets/svgs/index.js";
import { Table, Th, Thead, Tbody, Td, ThDropdown, Pagination } from "../../../components/Reusable";
import NoRecordsFound from "../../../components/NoRecordsFound/noRecordsFound.jsx";
import { EditIcon, DeleteIcon } from "../../../assets/svgs/index.js";
import { BannerHeading } from "../../../assets/StyleComponents/FontSize/Heading.style.js";
import TableLoader from "../../../components/Loader/TableLoader.js";
import { useWindowSize } from "../../../Hooks/CheckWidthHeight/index.js";
import { ParaTag } from "../../../assets/StyleComponents/FontSize/para.style.js";
import ApiKeyRow from "./ApiKeyRow.jsx";
import { HeadingSix } from "../../../assets/StyleComponents/FontSize/HeadingSix.style.js";
import Skeleton from "../../../components/Loader/Skeleton.js";

function ApiKeyManagementComponent(props) {
  let { userData, isLoadingAPIList, apiLists } = props;
  let { width } = useWindowSize();
  const [isVisible, setIsVisible] = useState(false);
  const [selectMode, setSelectMode] = useState("");
  const [tokenData, setTokenData] = useState("");
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [offset, setOffset] = useState(1);
  const rowRefs = useRef(new Map());

  useEffect(() => {
    let data = {
      Offset: offset
    };

    props.getApiKeyManagment(data);
    // props.getUserDetails();
    let page = props.location.pathname + props.location.search;
    trackPage(page);
  }, []);

  useEffect(() => {
    const data2 = {
      Offset: offset
    };
    props.getApiKeyManagment(data2);
  }, [offset]);

  const deleteToken = (data) => {
    setDeleteVisible(true);
    setTokenData(data);
  };

  const showModal = (modeValue, data) => {
    setIsVisible(true);
    setSelectMode(modeValue);
    setTokenData(data);
  };

  const closeModal = () => {
    setSelectMode("");
    setIsVisible(false);
    setDeleteVisible(false);
  };

  //=> Functionality for Edit & Delete
  const handleEditAndDelete = (stepName, itemValue) => {
    if (stepName === "Edit") {
      showModal("edit", itemValue);
    } else if (stepName === "Delete") {
      deleteToken(itemValue);
    }
  };

  const MappingFunc = (ipResullt) => {
    if (ipResullt && ipResullt?.length > 0) {
      const result = ipResullt.map((item) => item);
      return result;
    }
  };

  let { twoFactorAuth } = userData;
  let { status } = twoFactorAuth;
  return (
    <>
      {/* {isConfirmModal &&
        (!status ? (
          <Enable2FA onClose={closeModal} />
        ) : (
          <CreateApiModalComponent
            isVisible={isConfirmModal}
            editData={tokenData}
            onClose={closeModal}
            {...props}
          />
        ))} */}

      {deleteVisible &&
        Object.keys(tokenData)?.length > 0 &&
        (!status ? (
          <Enable2FA onClose={closeModal} />
        ) : (
          <DeleteManagmentComponent
            isVisible={deleteVisible}
            editData={tokenData}
            onClose={closeModal}
            {...props}
          />
        ))}
      {isVisible &&
        (!status ? (
          <Enable2FA onClose={closeModal} />
        ) : (
          <AddEditKeyManagementComponent
            isVisible={isVisible}
            mode={selectMode}
            editData={tokenData}
            onClose={closeModal}
            {...props}
          />
        ))}

      <MainElement padding={"0 16px"} paddingMd={"20px 16px"}>
        <BannerBox>
          <BannerHeading size={"24px"} sizeMd={"18px"} mb={"8px"} weight="bold">
            API Keys
          </BannerHeading>
          <ParaTag size={"18px"} sizeLg={"16px"} sizeMd={"12px"} weight="300">
            Exchange APIs are designed to allow access to all of the features of the Exchange
            platform.
          </ParaTag>
        </BannerBox>

        {/* View For Apis */}
        {!isLoadingAPIList && (
          <BannerBox width="100%" mt="36px" mtMd="10px" mtLg="20px">
            {width > 1024 && (
              <Table bRadius width="100%" marginBottom="16px" TableBodyRowHeight>
                <Thead display="flex" height="40px" fontSize="14px" fontSizeMd="12px">
                  <ApiKeysRow displayFlex OrdersPagesHeadRow APIKeyRow>
                    <Th> API Key </Th>
                    <Th> Read Status </Th>
                    <Th> Write Status </Th>
                    <Th> IP List </Th>
                    <Th> Date </Th>
                    <Th> Actions </Th>
                  </ApiKeysRow>
                </Thead>

                <Tbody fontSizeMd="14px" fontSizeSm="12px" fontSize="14px">
                  {!isLoadingAPIList &&
                    apiLists?.length > 0 &&
                    apiLists.map((item, index) => {
                      let { ApiKey, Read, Write, IpAddresses, CreatedAt } = item;
                      let ipResullt =
                        IpAddresses && IpAddresses?.length > 0
                          ? IpAddresses?.length > 1
                            ? IpAddresses.split(",")
                            : IpAddresses
                          : [];
                      let mainIp = ipResullt && ipResullt?.length > 0 ? MappingFunc(ipResullt) : "";

                      return (
                        <ApiKeysRow
                          displayFlex
                          APIKeyRow
                          key={index}
                          OrdersPagesHeadRow={index === apiLists.length - 1 ? true : false}
                          style={{ padding: "10px 0px" }}
                        >
                          <Td>
                            {ApiKey.substr(0, 7) +
                              "..." +
                              ApiKey.substr(ApiKey?.length - 7, ApiKey?.length)}
                          </Td>
                          <Td>{Read ? "Allowed" : "Not Allowed"}</Td>
                          <Td>{Write ? "Allowed" : "Not Allowed"}</Td>
                          <Td
                            display="flex"
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            gap="3%"
                          >
                            <ApiKeyRow ipList={mainIp} />
                          </Td>
                          <Td>{moment(CreatedAt).format("DD/MM/YY, HH:mm")}</Td>

                          <Td>
                            <BannerBox display="flex" gap="16px">
                              <DeleteRedIcon onClick={() => deleteToken(item)} cursor="pointer" />
                              <EditIconBlue
                                onClick={() => showModal("Edit", item)}
                                cursor="pointer"
                              />
                            </BannerBox>
                          </Td>
                        </ApiKeysRow>
                      );
                    })}

                  {!isLoadingAPIList && apiLists?.length === 0 && (
                    <BannerBox margin="15px 0px">
                      <NoRecordsFound />
                    </BannerBox>
                  )}
                </Tbody>
              </Table>
            )}
            {width < 1024 && (
              <>
                <BannerBox>
                  {apiLists?.length > 0 &&
                    apiLists.map((item, index) => {
                      let { ApiKey, Read, Write, IpAddresses, CreatedAt } = item;
                      let ipResullt =
                        IpAddresses && IpAddresses?.length > 0
                          ? IpAddresses?.length > 1
                            ? IpAddresses.split(",")
                            : IpAddresses
                          : [];
                      let mainIp = ipResullt && ipResullt?.length > 0 ? MappingFunc(ipResullt) : "";

                      const Datatable = [
                        {
                          heading: "API Key",
                          value:
                            ApiKey.substr(0, 7) +
                            "..." +
                            ApiKey.substr(ApiKey?.length - 7, ApiKey?.length)
                        },
                        {
                          heading: "Read Status",
                          value: Read ? "Allowed" : "Not Allowed"
                        },
                        {
                          heading: "Write Status",
                          value: Write ? "Allowed" : "Not Allowed"
                        },
                        {
                          heading: "IP List",
                          value: <ApiKeyRow ipList={mainIp} />
                        },
                        {
                          heading: moment(CreatedAt).format("DD/MM/YY, HH:mm"),
                          value: "actions"
                        }
                      ];

                      return (
                        <>
                          <BannerBox
                            bottomBorder
                            padding="20px 0px"
                            display="flex"
                            direction="column"
                            gap="10px"
                          >
                            {Datatable.map((values) => {
                              return (
                                <>
                                  <BannerBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <HeadingSix size="14px" weight="400" txtGrey>
                                      {values.heading}
                                    </HeadingSix>
                                    <BannerHeading
                                      size="14px"
                                      weight="500"
                                      BodyColor
                                      display="flex"
                                    >
                                      {values.value === "actions" ? (
                                        <>
                                          <BannerBox display="flex" gap="16px">
                                            <DeleteRedIcon
                                              onClick={() => deleteToken(item)}
                                              cursor="pointer"
                                            />
                                            <EditIconBlue
                                              onClick={() => showModal("Edit", item)}
                                              cursor="pointer"
                                            />
                                          </BannerBox>
                                        </>
                                      ) : (
                                        values.value
                                      )}
                                    </BannerHeading>
                                  </BannerBox>
                                </>
                              );
                            })}
                          </BannerBox>
                        </>
                      );
                    })}
                </BannerBox>
              </>
            )}
            <BannerBox mb="40px" mbMd="30px">
              {!isLoadingAPIList && apiLists?.length > 0 && apiLists && (
                <Pagination
                  page={offset}
                  totalCount={props?.apiListsCount}
                  setPage={setOffset}
                  itemPerPage={25}
                />
              )}
            </BannerBox>
          </BannerBox>
        )}
        {isLoadingAPIList && width > 1024 && (
          <BannerBox
            display="flex"
            flex="0 0 100%"
            direction="column"
            padding={"0 0px 0px 16px"}
            mt="48px"
            mb="40px"
          >
            <TableLoader rows={5} webCol={4} tabletCol={2} mobileCol={2} />
          </BannerBox>
        )}

        {isLoadingAPIList && width < 1024 && (
          <BannerBox
            display="flex"
            padding={"0 0px 0px 16px"}
            mt="48px"
            mb="40px"
            gap="10px"
            direction="column"
          >
            {[0, 1, 2, 3].map((value, index) => {
              return (
                <>
                  <BannerBox key={value} gap="10px" display="flex" direction="column">
                    <Skeleton height="120px" width="200" />
                  </BannerBox>
                </>
              );
            })}
          </BannerBox>
        )}

        <BannerBox width="100%">
          <ButtonComponent
            PrimaryButton
            width="160px"
            widthMd="120px"
            btnHeight="48px"
            btnHeightMd="40px"
            sizeMd="12px"
            weight="500"
            onClick={() => setIsVisible(true)}
          >
            Create API
          </ButtonComponent>
        </BannerBox>
      </MainElement>
    </>
  );
}

const maptoStateProps = (state) => ({
  isTheme: state.theme.isTheme,
  userData: state.dashboard.getUserData,
  isLoadingAPIList: state.setting.isLoadingAPIList,
  apiLists: state.setting.apiLists,
  apiListsCount: state.setting.apiListsCount
});

const mapDispatchToProps = (dispatch) => ({
  getApiKeyManagment: (data) => dispatch(getApiKeyManagment(data))
});

export default connect(maptoStateProps, mapDispatchToProps)(ApiKeyManagementComponent);
