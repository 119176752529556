/* eslint-disable no-unused-vars */
import axios from "axios";
import moment from "moment";
import { API } from "../../constants/api";
import apiURL from "../../constants/apiConstants";
import globalConfig from "../../constants/config";
import { formatAPIErrors, getLocalStorageData } from "../../utils/general";
import { getCookie } from "../../utils/cookies";

axios.interceptors.response.use(
  (r) => r,
  function handle401Errors(error) {
    if (error && error.response && error.response.status === 401) {
      window.location = globalConfig.exchangeUrl;
      window.dispatchEvent(new Event("LOGOUT"));
    }
    return Promise.reject(error);
  }
);

export const walletAPI = axios.create({
  baseURL: apiURL.WALLET_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

export const walletV2API = axios.create({
  baseURL: apiURL.WALLET_URL_V2,
  headers: {
    "Content-Type": "application/json"
  }
});
export const walletV3API = axios.create({
  baseURL: apiURL.WALLET_URL_V3,
  headers: {
    "Content-Type": "application/json"
  }
});

/**
 * @param {Update User data Details  } param
 */

export async function updateUserData(param) {
  if (param) return param;
  else return "noData";
}
