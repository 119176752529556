import { connect } from "react-redux";
import { compose } from "redux";
import styled, { css } from "styled-components";
import styledConstants from "../../../constants/styledConstants";

const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
  width: ${(props) => (props.width ? props.width : "fit-content")};
  height: ${(props) => (props.height ? props.height : "44px")};
  padding: ${(props) => (props.padding ? props.padding : "0 40px")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "5px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "")};
  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  right: ${(props) => (props.right ? props.right : "")};
  background: ${(props) => (props.bg ? props.bg : "")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "")};

  &:active,
  &:focus {
    outline: none;
  }

  @media (max-width: 1024px) {
    width: ${(props) => (props.widthMd ? props.widthMd : "")};
    height: ${(props) => (props.heightMd ? props.heightMd : "")};
    padding: ${(props) => (props.paddingMd ? props.paddingMd : "")};
    margin: ${(props) => (props.marginMd ? props.marginMd : "")};
    margin-top: ${(props) => (props.marginTopMd ? props.marginTopMd : "")};
    margin-right: ${(props) => (props.marginRightMd ? props.marginRightMd : "")};
    margin-bottom: ${(props) => (props.marginBottomMd ? props.marginBottomMd : "")};
    margin-left: ${(props) => (props.marginLeftMd ? props.marginLeftMd : "")};
    font-size: ${(props) => (props.fontSizeMd ? props.fontSizeMd : "")};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.widthSm ? props.widthSm : "")};
    height: ${(props) => (props.heightSm ? props.heightSm : "")};
    padding: ${(props) => (props.paddingSm ? props.paddingSm : "")};
    margin: ${(props) => (props.marginSm ? props.marginSm : "")};
    margin-top: ${(props) => (props.marginTopSm ? props.marginTopSm : "")};
    margin-right: ${(props) => (props.marginRightSm ? props.marginRightSm : "")};
    margin-bottom: ${(props) => (props.marginBottomSm ? props.marginBottomSm : "")};
    margin-left: ${(props) => (props.marginLeftSm ? props.marginLeftSm : "")};
    font-size: ${(props) => (props.fontSizeSm ? props.fontSizeSm : "")};
  }

  /* Primary Blue Button */
  ${(props) =>
    props.primaryBlue &&
    css`
      background-color: ${(props) => props.theme.primaryBlue};
      color: ${(props) => props.theme.primaryWhite};
    `}
  ${(props) =>
    props.primaryBlueWithHover &&
    css`
      background-color: ${(props) => props.theme.primaryBlue};
      color: ${(props) => props.theme.primaryWhite};

      &:hover {
        background-color: transparent;
        color: ${(props) => props.theme.primaryBlue};
        border: 1px solid ${(props) => props.theme.primaryBlue};
      }
    `}

  /* Secondary Blue Button */
  ${(props) =>
    props.secondaryBlue &&
    props.isTheme === "lcx_light" &&
    css`
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.primaryBlue};
      color: #0470e1;

      &:hover {
        background-color: #0470e1;
        color: #fff;
      }
    `}

  ${(props) =>
    props.secondaryBlue &&
    props.isTheme === "lcx_dark" &&
    css`
      background-color: transparent;
      border: 2px solid #70a6e0;
      color: #70a6e0;

      &:hover {
        background-color: #70a6e0;
        color: #012243;
      }
    `}

  /* Primary Red Button */
  ${(props) =>
    props.primaryRed &&
    props.isTheme === "lcx_light" &&
    css`
      background-color: #e65252;
      color: #fff;
    `}

  ${(props) =>
    props.primaryRed &&
    props.isTheme === "lcx_dark" &&
    css`
      background-color: #e66767;
      color: #012243;
    `}

  /* Secondary Red Button */
  ${(props) =>
    props.secondaryRed &&
    props.isTheme === "lcx_light" &&
    css`
      background-color: transparent;
      border: 2px solid #e65252;
      color: #e65252;

      &:hover {
        background-color: #e65252;
        color: #fff;
      }
    `}

  ${(props) =>
    props.secondaryRed &&
    props.isTheme === "lcx_dark" &&
    css`
      background-color: transparent;
      border: 2px solid #e66767;
      color: #e66767;

      &:hover {
        background-color: #e66767;
        color: #012243;
      }
    `}

  /* Secondary Grey Button */
  ${(props) =>
    props.primaryGrey &&
    css`
      border: 2px solid ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
      background-color: ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
      color: ${(props) => (props.isDarkTheme ? "#012243" : "#fff")};
    `}

  ${(props) =>
    props.secondaryGrey &&
    css`
      background-color: transparent;
      border: 2px solid ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
      color: ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};

      &:hover {
        background-color: ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
        color: #012243;
      }
    `}

  ${(props) =>
    props.secondaryGreyDisabled &&
    css`
      background-color: transparent;
      border: 2px solid ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
      color: ${(props) => (props.isDarkTheme ? "#5c5f66" : "#ccced0")};
    `}

    ${(props) =>
    props.primaryGreen &&
    css`
      background-color: ${(props) => props.theme.primaryGreen};
      border: 2px solid ${(props) => props.theme.primaryGreen};
      color: ${(props) => props.theme.primaryWhite};
    `}

  /* Secondary Green Button */
  ${(props) =>
    props.secondaryGreen &&
    css`
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.primaryGreen};
      color: ${(props) => props.theme.primaryGreen};

      &:hover {
        background-color: ${(props) => props.theme.primaryGreen};
        color: ${(props) =>
          props.isDarkTheme ? styledConstants.TEXT_COLOR_LIGHT : styledConstants.WHITE_COLOR_LIGHT};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
  ${(props) =>
    props.PulseEffectWebCamSubmit &&
    css`
      opacity: 1;
      transition: 0.2s all;
      background-color: ${(props) => props.theme.primaryBlue};
      border: 1px solid ${(props) => props.theme.primaryBlue};
      color: ${(props) => props.theme.primaryWhite};

      &.active {
        transform: scale(0.9);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
      }
      &:hover {
        background-color: transparent;
        color: ${(props) => props.theme.primaryBlue};
        border: 1px solid ${(props) => props.theme.primaryBlue};
      }
    `}
  ${(props) =>
    props.PulseEffect &&
    css`
      opacity: 1;
      transition: 0.2s all;

      &.active {
        transform: scale(0.9);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
      }
    `}

    ${(props) =>
    props.blueTextBtn &&
    css`
      padding: 0;
      height: fit-content;
      width: fit-content;
      text-decoration: underline !important;
      background-color: transparent;
      color: ${props.isDarkTheme ? "#70a6e0" : "#0470e1"} !important;

      &:hover {
        background-color: transparent;
        color: ${props.isDarkTheme ? "#70a6e0" : "#0470e1"} !important;
      }
    `}

    ${(props) =>
    props.secondarySubText &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.sepColor};
      color: ${(props) => props.theme.subText};
      font-weight: 300;

      &:hover {
        font-weight: 500;
        border: 1px solid ${(props) => props.theme.primaryBlue};
        background-color: ${(props) => props.theme.primaryBlue};
        color: ${(props) => props.theme.primaryWhite};
      }
    `}

    ${(props) =>
    props.fontWhite &&
    css`
      color: #fff !important;
    `}

    ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `}
`;

const Button = (props) => {
  return <Btn {...props}></Btn>;
};

const mapStateToProps = (state) => ({
  isTheme: state.theme.isTheme ? "lcx_dark" : "lcx_light",
  isDarkTheme: state.theme.isDarkTheme
});

export default compose(connect(mapStateToProps))(Button);
